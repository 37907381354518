import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import * as _ from 'lodash';

import { GoogleAttributes } from '../../../constants/google/attributes';
import { ModalAttributeUrlComponent } from '../../modal-attribute-url/modal-attribute-url.component';
import { ModalService } from '../../../services/modal.service';

@Component({
  selector: 'app-location-url-attributes',
  templateUrl: './location-url-attributes.component.html',
  styleUrls: ['./location-url-attributes.component.scss']
})
export class LocationUrlAttributesComponent implements OnInit {

  @Input() attributes;
  @Input() isEdit = true;
  @Output() onUpdate: EventEmitter<boolean> = new EventEmitter();

  title: string = null;

  constructor(private dialog: ModalService) {
  }

  ngOnInit() {
    this.attributes = _.filter(this.attributes, (o) => { return _.startsWith(o.attributeId, 'url'); })
  }

  setTitle(title) {
    this.title = title;
    return title;
  }

  openUrlAttributes(attributeUrl: GoogleAttributes) {

    const dialogRef = this.dialog.openGenericModal(ModalAttributeUrlComponent,
      { attribute: attributeUrl, title: this.title },
      () => null,
      890);

    dialogRef.afterClosed().subscribe(res => {
      if (res!= '') this.onUpdate.emit(true);
    });
  }

}
