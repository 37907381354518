<div class="trial-message js-trial-message row" *ngIf="isImpersonating">
    <div class="col">

        <p class="m--4">
            Impersonating user:
            <strong class="m--4" *ngIf="user">
                {{user.email}}
            </strong>
        </p>
    </div>
    <div class="col-auto">
        <button mat-button class="btn btn--white btn--xs ml--10" (click)="handleLogOut()">
            <strong>Stop Impersonation...</strong>
        </button>
    </div>
</div>
