<div class="dialog__header txt--center">
  <h2 mat-dialog-title class="m--0 txt--xl">
    <strong *ngIf="isNew">Add User</strong>
    <strong *ngIf="!isNew">Edit User</strong>
  </h2>
</div>
<div class="dialog__content" mat-dialog-content>
  <div class="dialog-row pb--0">
    <form [formGroup]="userForm">
      <div class="dialog-row pb--0">
        <mat-form-field class="input-field">
          <input matInput placeholder="Full Name" formControlName="name" required>
          <mat-error *ngIf="form.get('name').errors">
            <p *ngIf="form.get('name').errors['required']">Name is required</p>
            <p *ngIf="form.get('name').errors['minlength']">Name min is 5 characters</p>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="dialog-row pb--0">
        <mat-form-field class="input-field">
          <input matInput placeholder="Email" formControlName="email" required>
          <mat-error *ngIf="form.get('email').errors">
            <p *ngIf="form.get('email').errors['required']">Email is required</p>
            <p *ngIf="form.get('email').errors['email']">Email is invalid</p>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="dialog-row">
        <div class="row">
          <div class="col-12">
            <p class="fw--medium">Roles</p>
          </div>
          <div class="col-6">
            <mat-radio-group aria-label="Select an option" formControlName="role">
              <mat-radio-button [value]="'admin'" matTooltip="All Permissions" matTooltipClass="tooltip tooltip--white">Admin</mat-radio-button>
              <mat-radio-button [value]="'member'" matTooltip="Limited permissions" matTooltipClass="tooltip tooltip--white">Member</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

    </form>
  </div>

</div>
<div mat-dialog-actions class="dialog__footer">
    <span class="alternate-theme d-inline-block mr--10">
        <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
    </span>
  <button mat-flat-button tabIndex="-1" color="accent" (click)="save()" class="btn btn--action" [disabled]="!form.valid">Save</button>
</div>
