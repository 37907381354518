
    <div class="dialog__header dialog__header--warn txt--center">
      <h2 mat-dialog-title class="m--0 txt--xl"><strong>Auto Responder Error</strong></h2>
    </div>
    <div class="dialog__content" mat-dialog-content>

      <div class="dialog-row txt--center" >
        <ng-container [ngSwitch]="review?.error">
          <div *ngSwitchCase="'UNAUTHENTICATED'">
            <read-more text="You need re-authenticate your account in order to fix this." [maxLength]="300"></read-more>
            <button tabIndex="-1" mat-flat-button color="accent" class="btn btn--action" (click)="openReauth(review)"> Re-authenticate</button>
          </div>
          <div *ngSwitchCase="'INTERNAL'">
            <p>An unexpected error happened while trying to reply. Please try again.</p>
            <button tabIndex="-1" mat-flat-button color="accent" class="btn btn--action" (click)="replyReview(review)"> Try again</button>
          </div>
          <div *ngSwitchCase="'NOT_FOUND'">
            <p>The location was not found in GBP. Did you move it or delete it from GBP?</p>
          </div>
          <div *ngSwitchDefault>
            <read-more [text]="review.error" [maxLength]="300"></read-more>
          </div>
        </ng-container>
      </div>
    </div>

    <div mat-dialog-actions class="dialog__footer">
    <button tabIndex="-1" mat-button color="primary" [mat-dialog-close]="false" class="btn btn-cancel">Cancel</button>
    </div>
  