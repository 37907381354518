import { __assign, __awaiter, __generator, __values } from "tslib";
// dep
import { OnInit, OnDestroy, ChangeDetectorRef, ViewRef, Renderer2 } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';
var FlexSearch = require('flexsearch');
// app
import { ReportService } from '../../services/report.service';
import { ModalCreateReportComponent } from '../modal-create-report/modal-create-report.component';
import { AuthService } from '../../services/auth.service';
import { ModalDeleteComponent } from '../modal-delete/modal-delete.component';
import { ModalShareComponent } from '../modal-share/modal-share.component';
import { ModalService } from '../../services/modal.service';
import { SnackbarService } from '../../services/snackbar.service';
import { LocationService } from '../../services/location.service';
import { GROUP_SUBSCRIPTION_TYPE, LOCATION_SUBSCRIPTION_TYPE } from '../../constants/firestore/account-location';
import { GroupService } from '../../services/group.service';
import { SubscriptionService } from '../../services/subscription.service';
import { DatesService } from '../../services/dates.service';
import { AlertType } from 'src/app/components/alert.component';
import { SpinnerService } from 'src/app/services/spinner.service';
import { IS_IMPERSONATING } from 'src/app/constants/session';
import { FeaturedReportComponent } from 'src/app/components/featured-report.component';
var ReportListComponent = /** @class */ (function () {
    function ReportListComponent(router, _dialog, _authService, _reportService, _modalService, _snackS, _locationService, _groupService, _apiSubscription, _dateService, _spinnerService, _cdRef, _renderer2) {
        this.router = router;
        this._dialog = _dialog;
        this._authService = _authService;
        this._reportService = _reportService;
        this._modalService = _modalService;
        this._snackS = _snackS;
        this._locationService = _locationService;
        this._groupService = _groupService;
        this._apiSubscription = _apiSubscription;
        this._dateService = _dateService;
        this._spinnerService = _spinnerService;
        this._cdRef = _cdRef;
        this._renderer2 = _renderer2;
        this.hasToggleMode = false;
        this.checked = false;
        this.flexSearch = new FlexSearch({
            encode: 'advanced',
            tokenize: 'reverse',
            suggest: true,
            cache: true,
            doc: {
                id: 'id',
                field: [
                    'accountName',
                    'locName',
                    'address',
                    'reportName',
                    'labels',
                ]
            }
        });
        this.sort = {
            sortBy: 'createdAt',
            direction: 'desc'
        };
        this.progress = true;
        this.reportsExists = false;
        this.selectedSearch = new FormControl();
        this.filtered = false;
        this.reportData = {};
        this.isSliderOpened = false;
        this.viewModeChecked = 'new';
        this.hasLegacy = false;
        this.isImpersonating = false;
        this.loadingLegacyFooter = false;
        this.subscription$ = this._authService.subscription$;
        this.GROUP_SUBSCRIPTION_TYPE = GROUP_SUBSCRIPTION_TYPE;
        this.timeout = null;
        this._backupData = [];
        this._paginate = { page: 1, size: 10 };
        this._destroySubs$ = new Subject();
    }
    ReportListComponent.prototype.ngOnInit = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_c) {
                if ((_a = this.reportType) === null || _a === void 0 ? void 0 : _a.includes('-report')) {
                    this.reportType = this.reportType.replace('-report', '');
                }
                switch (this.reportType) {
                    case 'qanda':
                        this.nameReportType = 'Questions And Answers';
                        break;
                    case 'performance-rollup':
                        this.nameReportType = 'Performance';
                        break;
                    default:
                        this.nameReportType = (_b = this.reportType) === null || _b === void 0 ? void 0 : _b.replace('-', ' ');
                        break;
                }
                this.viewModeChecked = this.router.url.includes('performance') || this.router.url.includes('keywords') || this.router.url.includes('qanda') || this.router.url.includes('review') ? 'new' : 'legacy';
                this._groupService.getReportIndexes(this._authService.session.gid, this.reportType).pipe(takeUntil(this._destroySubs$), filter(function (result) { var _a; return !!((_a = result) === null || _a === void 0 ? void 0 : _a.length); })).subscribe(function (result) {
                    var e_1, _a;
                    _this.results = result;
                    try {
                        for (var result_1 = __values(result), result_1_1 = result_1.next(); !result_1_1.done; result_1_1 = result_1.next()) {
                            var index = result_1_1.value;
                            index.id = JSON.stringify(index);
                            index.address = _this._locationService.formatAddress(index.address);
                            _this.addDoc(index);
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (result_1_1 && !result_1_1.done && (_a = result_1.return)) _a.call(result_1);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                    if (_this.filtered)
                        _this.applyFilter(_this.searchText);
                });
                if (!this.filtered)
                    this.getData();
                this.isImpersonating = !!localStorage.getItem(IS_IMPERSONATING);
                return [2 /*return*/];
            });
        });
    };
    ReportListComponent.prototype.closeSliderReport = function () {
        var _a;
        this.isSliderOpened = false;
        this.getData();
        var body = (_a = document.getElementsByTagName('body')) === null || _a === void 0 ? void 0 : _a[0];
        this._renderer2.removeClass(body, 'overflow--hidden');
    };
    ReportListComponent.prototype.ngOnDestroy = function () {
        this._destroySubs$.next(true);
        this._destroySubs$.unsubscribe();
        var content = document.getElementsByClassName('content');
        if (content.length > 0) {
            this._renderer2.removeClass(content[0], 'pr--0');
        }
    };
    ReportListComponent.prototype.addDoc = function (doc) {
        this.flexSearch.add(doc);
    };
    ReportListComponent.prototype.selected = function () {
        var value = this.selectedSearch.value;
        this.router.navigateByUrl("/report/" + value.source).then();
    };
    ReportListComponent.prototype.isMember = function () {
        return this._authService.isMember;
    };
    ReportListComponent.prototype.getData = function ($event) {
        var searchKeywords = null;
        var last = null;
        var next = null;
        if (this._previousPageable) {
            if (this._previousPageable.page < this.pagination.page) {
                if (this.dataSource.data) {
                    last = this.dataSource.data[this.pagination.per_page - 1];
                }
            }
            else if (this._previousPageable.page > this.pagination.page) {
                if (this.dataSource.data) {
                    next = this.dataSource.data[0];
                }
            }
        }
        if ($event) {
            searchKeywords = $event;
        }
        if (this._authService.session) {
            this.progress = true;
            if (this.reportType == 'keyword' || this.viewModeChecked == 'new') {
                this.getLegacy();
                this.getDataFromMongo();
            }
            else {
                this.getDataFromFire(last, next, searchKeywords);
            }
        }
    };
    ReportListComponent.prototype.getDataFromMongo = function (reportName) {
        var _this = this;
        if (reportName === void 0) { reportName = null; }
        this.progress = true;
        var sort = this.sort.direction == 'asc' ? 1 : -1;
        this._reportService.getReportsFromMongo(this._authService.session.gid, this.reportType, this._paginate, this.sort.sortBy, sort, reportName).subscribe(function (res) {
            if (res && res['items']) {
                res['items'].forEach(function (el) {
                    el.fromDate = _this.builDate(el.startDatetime),
                        el.toDate = _this.builDate(el.endDatetime);
                });
                _this.dataSource = new MatTableDataSource(res['items']);
                _this._previousPageable = { size: res['per_page'], page: res['page'] };
                _this.pagination = res;
                _this.errorMessage = false;
                _this.formatBasiclocations(res['items']);
            }
            else {
                _this.dataSource = new MatTableDataSource([]);
            }
            _this.progress = false;
        });
    };
    ReportListComponent.prototype.getDataFromFire = function (last, next, searchKeywords) {
        var _this = this;
        this._reportService.getByGIdAndType(this._authService.session.gid, this.reportType, this._paginate, last, next, searchKeywords, this.sort.sortBy, this.sort.direction).pipe(takeUntil(this._destroySubs$)).subscribe(function (result) {
            result['items'].forEach(function (el) {
                el.fromDate = _this.builDate(el.startDatetime),
                    el.toDate = _this.builDate(el.endDatetime);
            });
            _this._previousPageable = { size: result.per_page, page: result.page };
            _this.pagination = result;
            if (!_this.filtered) {
                _this.formatBasiclocations(result.items);
            }
            _this.manualPage = 1;
            _this.errorMessage = false;
        });
    };
    ReportListComponent.prototype.getLegacy = function () {
        var _this = this;
        this.loadingLegacyFooter = true;
        var reportTypeLegacy = this._getReportType();
        this._reportService.getByGIdAndTypeCount(this._authService.session.gid, reportTypeLegacy)
            .pipe(takeUntil(this._destroySubs$))
            .subscribe(function (res) {
            _this.hasLegacy = (res.size > 0);
            _this.loadingLegacyFooter = false;
        });
    };
    ReportListComponent.prototype.builDate = function (date) {
        var data = '-';
        if (date) {
            date = date.includes('T') ? date.split('T')[0] : date.split(' ')[0];
            var dateSplit = date.split('-');
            data = dateSplit[1] + "-" + dateSplit[2] + "-" + dateSplit[0];
        }
        return data;
    };
    ReportListComponent.prototype.handleReload = function ($event) {
        this._paginate = $event;
        this.getData();
    };
    ReportListComponent.prototype.formatBasiclocations = function (items) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._updateTable(items);
                return [2 /*return*/];
            });
        });
    };
    ReportListComponent.prototype.checkLocations = function (items) {
        var e_2, _a, e_3, _b;
        try {
            for (var items_1 = __values(items), items_1_1 = items_1.next(); !items_1_1.done; items_1_1 = items_1.next()) {
                var item = items_1_1.value;
                if (!_.isEmpty(item.accounts)) {
                    try {
                        for (var _c = (e_3 = void 0, __values(item.accounts)), _d = _c.next(); !_d.done; _d = _c.next()) {
                            var account = _d.value;
                            var hasInvalidAccounts = !_.isEmpty(_.filter(account.locations, { accountId: 'accounts' }));
                            var hasInvalidLocations = !_.isEmpty(_.filter(account.locations, { locationId: 'locations' }));
                            if (hasInvalidAccounts || hasInvalidLocations) {
                                item.invalidLocations = true;
                            }
                            var hasDuplicated = _.size(_.uniqBy(account.locations, 'locationId')) < _.size(account.locations);
                            if (hasDuplicated) {
                                item.duplicatedLocations = true;
                            }
                        }
                    }
                    catch (e_3_1) { e_3 = { error: e_3_1 }; }
                    finally {
                        try {
                            if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
                        }
                        finally { if (e_3) throw e_3.error; }
                    }
                }
                else {
                    delete item.invalidLocations;
                    delete item.duplicatedLocations;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (items_1_1 && !items_1_1.done && (_a = items_1.return)) _a.call(items_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    ReportListComponent.prototype.filterByLocationMember = function (items) {
        var e_4, _a;
        var user = this._authService.session;
        var memberLocations = [];
        user.accounts.forEach(function (account) {
            account.locations.forEach(function (location) {
                memberLocations.push(location.locationId);
            });
        });
        var validReports = [];
        var _loop_1 = function (item) {
            var e_5, _a;
            var reportLocations = [];
            if (!_.isEmpty(item.accounts)) {
                try {
                    for (var _b = (e_5 = void 0, __values(item.accounts)), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var account = _c.value;
                        account.locations.forEach(function (location) {
                            reportLocations.push(location.locationId);
                        });
                    }
                }
                catch (e_5_1) { e_5 = { error: e_5_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_5) throw e_5.error; }
                }
                if (reportLocations.every(function (locationId) { return memberLocations.includes(locationId); })) {
                    validReports.push(item);
                }
            }
        };
        try {
            for (var items_2 = __values(items), items_2_1 = items_2.next(); !items_2_1.done; items_2_1 = items_2.next()) {
                var item = items_2_1.value;
                _loop_1(item);
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (items_2_1 && !items_2_1.done && (_a = items_2.return)) _a.call(items_2);
            }
            finally { if (e_4) throw e_4.error; }
        }
        return validReports;
    };
    ReportListComponent.prototype.sortData = function (sort) {
        this.sort = {
            sortBy: sort.active,
            direction: sort.direction,
        };
        var data = this._backupData.slice();
        if (!sort.active || sort.direction === '') {
            this.dataSource.data = data;
            return;
        }
        if (this.filtered) {
            this.dataSource.data = _.orderBy(data, [sort.active], [sort.direction]);
        }
        else {
            return this.getData(null);
        }
    };
    ReportListComponent.prototype._updateTable = function (items) {
        if (!this._authService.isAdmin) {
            items = this.filterByLocationMember(items);
        }
        // data for table
        if (this.reportType === 'Review Assistant') {
            this.displayedColumns = ['name', 'created', 'modified', 'locations', 'actions'];
        }
        else {
            this.displayedColumns = ['name', 'created', 'modified', 'locations', 'date', 'actions'];
        }
        this.progress = false;
        this.dataSource = new MatTableDataSource(items);
        this._backupData = this.dataSource.data;
        this.dataSource.paginator = this.paginator;
        this.reportsExists = !!(this.dataSource.data.length);
        this.progress = false;
    };
    // apply filter from search  
    // Legacy reports do not support filtering due to the unmaintained Firestore index. 
    ReportListComponent.prototype.applyFilter = function (inputValue) {
        var _this = this;
        var _a;
        clearTimeout(this.timeout);
        // Use a trimmed version to avoid trailing and leading spaces
        var trimmedValue = (_a = inputValue) === null || _a === void 0 ? void 0 : _a.trim();
        this.timeout = setTimeout(function () {
            if (!trimmedValue) {
                _this.getData();
                _this.filtered = false;
            }
            else {
                _this.getDataFromMongo(trimmedValue.toLowerCase());
            }
        }, 350);
    };
    ReportListComponent.prototype.openCreateReportDialog = function () {
        var dialogRef = this._dialog.open(ModalCreateReportComponent, {
            width: '680px',
            data: { reportType: this.reportType }
        });
        dialogRef.disableClose = true;
    };
    ReportListComponent.prototype.openSliderReport = function (drawer, element, stepLocations) {
        var _a;
        var body = (_a = document.getElementsByTagName('body')) === null || _a === void 0 ? void 0 : _a[0];
        this._renderer2.addClass(body, 'overflow--hidden');
        this.reportData = element ? { reportType: this.reportType, edit: element, stepLocations: stepLocations } : { reportType: this.reportType };
        this.isSliderOpened = true;
        drawer.toggle();
    };
    ReportListComponent.prototype.showDeleteDialog = function (element) {
        var _this = this;
        var data = { name: element.reportName };
        var dialog = this._modalService.openGenericModal(ModalDeleteComponent, data, function () { }, 680);
        dialog.disableClose = true;
        var subscription = dialog.componentInstance.deleted.pipe(filter(function (r) { return r; })).subscribe(function () {
            if (_this.reportType !== 'keyword' && _this.viewModeChecked != 'new') {
                _this.delete(element.gid, element.id).then(function () {
                    dialog.componentInstance.complete();
                    setTimeout(function () {
                        _this.ngOnInit();
                        dialog.close();
                        subscription.unsubscribe();
                    }, 800);
                });
            }
            else {
                _this._reportService.deleteFromMongo(element['_id']).subscribe(function (res) {
                    dialog.componentInstance.complete();
                    setTimeout(function () {
                        _this.ngOnInit();
                        dialog.close();
                        subscription.unsubscribe();
                    }, 800);
                });
            }
        });
    };
    ReportListComponent.prototype.delete = function (gid, id) {
        return this._reportService.delete(gid, id);
    };
    ReportListComponent.prototype.countLocation = function (element) {
        return this._reportService.countLocation(element);
    };
    ReportListComponent.prototype.handleShared = function (element) {
        var _a;
        var id = element.id || element._id;
        var dataPicker = this._reportService.reportToDataPicker(element, id, false, this.viewModeChecked);
        var dialogRef = this._modalService.openGenericModal(ModalShareComponent, {
            createNew: false,
            dataPicker: dataPicker,
            placeId: dataPicker.locations,
            reportType: this.reportType,
            reportName: (_a = element) === null || _a === void 0 ? void 0 : _a.reportName,
            report: __assign({}, element)
        }, function () {
            // Do nothing
        });
        dialogRef.disableClose = true;
    };
    ReportListComponent.prototype.copy = function (element, type) {
        var _this = this;
        if (type === void 0) { type = null; }
        var _a, _b, _c, _d, _e;
        var copyElement;
        if (!element) {
            return;
        }
        else {
            copyElement = _.clone(element);
        }
        if (type) {
            copyElement.reportType = type;
        }
        copyElement.reportName += '(Copy)';
        copyElement.accounts = this._locationService.deleteServiceArea(copyElement.accounts);
        copyElement.accounts = this._locationService.deleteAddress(copyElement.accounts);
        delete copyElement['id'];
        delete copyElement['_id'];
        if (type && type != 'keyword' && (!type && this.viewModeChecked != 'new')) {
            this.prepareToSave(copyElement);
            this._reportService.save(this._authService.session.gid, copyElement).then(function (result) {
                _this._snackS.openSuccess('The report was copied successfully', 3000);
                _this.ngOnInit();
            });
        }
        else {
            if (this.reportType == 'performance-comparison') {
                copyElement['metrics'] = copyElement['metricsName'];
                delete copyElement['metricsName'];
            }
            else {
                copyElement['metrics'] = [];
                copyElement['metricsName'] = [];
            }
            if (type == "performance-comparison") {
                copyElement.dynamicRange = null;
                if (!((_a = copyElement) === null || _a === void 0 ? void 0 : _a.dynamic)) {
                    var data = this._reportService.periodChange('period', (_b = element) === null || _b === void 0 ? void 0 : _b.startDatetime, (_c = element) === null || _c === void 0 ? void 0 : _c.endDatetime);
                    copyElement.period = 'period';
                    copyElement.startDatetimeB = (_d = data) === null || _d === void 0 ? void 0 : _d.startDateB;
                    copyElement.endDatetimeB = (_e = data) === null || _e === void 0 ? void 0 : _e.endDateB;
                }
            }
            if (type == 'keyword') {
                var startDatetime = moment(element.startDatetime).startOf('month');
                copyElement.startDatetime = startDatetime.format('YYYY-MM-DD') + " 00:00:00";
                var endDatetime = moment(element.endDatetime).endOf('month');
                copyElement.endDatetime = endDatetime.format('YYYY-MM-DD') + " 00:00:00";
            }
            this._reportService.saveReportInMongo(copyElement).subscribe(function (res) {
                var _a;
                if ((_a = res) === null || _a === void 0 ? void 0 : _a.error) {
                    _this._snackS.openError('There was an error while trying to copy the report', 3000);
                }
                else {
                    _this._snackS.openSuccess('The report was copied successfully', 3000);
                    _this.ngOnInit();
                }
            });
        }
    };
    ReportListComponent.prototype.action = function (element, drawer) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this._authService.subscription$.take(1).subscribe(function (res) { return __awaiter(_this, void 0, void 0, function () {
                    var location, isAllLocationsUltimate, response, locations_1, message;
                    var _this = this;
                    var _a;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                this._spinnerService.loading$.next(true);
                                location = this.formatLocationsPath(element.accounts);
                                return [4 /*yield*/, this._locationService.isAllLocationsUltimate(location)];
                            case 1:
                                isAllLocationsUltimate = _b.sent();
                                this.checkLocations([element]);
                                this._spinnerService.loading$.next(false);
                                if (!(((_a = res) === null || _a === void 0 ? void 0 : _a.status) !== GROUP_SUBSCRIPTION_TYPE.TRIAL && !isAllLocationsUltimate)) return [3 /*break*/, 6];
                                if (!this._authService.isAdmin) {
                                    this._modalService.openAlertModal('Contact your administrator', "To access this report, you must upgrade all locations to the Ultimate plan or remove them from the report.\n            Your user doesn\u2019t have permissions to do this.", AlertType.INFO, null, 'Close');
                                    return [2 /*return*/];
                                }
                                return [4 /*yield*/, this._modalService.openModal(FeaturedReportComponent, null)];
                            case 2:
                                response = _b.sent();
                                if (!(response === 'upgrade')) return [3 /*break*/, 4];
                                return [4 /*yield*/, this.getLocationsToUpgrade(element.accounts)];
                            case 3:
                                locations_1 = _b.sent();
                                message = (locations_1.length === 1 ?
                                    'Are you sure you want to upgrade this location?' :
                                    "Are you sure you want to upgrade these " + locations_1.length + " locations?");
                                this._modalService.openConfirmModal('Upgrade', message, function (result) {
                                    if (!result) {
                                        return;
                                    }
                                    delete element.needToUpgrade;
                                    // tslint:disable-next-line: no-shadowed-variable
                                    locations_1.forEach(function (location, i) {
                                        _this._apiSubscription.upgradeLocation(location.locationId, location.accountId, element.gid).then(function () {
                                            return i === locations_1.length - 1 && _this._snackS.openSuccess('Locations successfully upgraded', 2000);
                                        }, function (err) {
                                            _this._snackS.openError('There was a problem upgrading locations', 2000);
                                        });
                                    });
                                });
                                return [3 /*break*/, 5];
                            case 4:
                                if (response === 'edit') {
                                    this.openSliderReport(drawer, element, false);
                                }
                                _b.label = 5;
                            case 5: return [2 /*return*/];
                            case 6:
                                if (this.reportType !== 'Review Assistant') {
                                    if ((!element.startDatetime || !element.endDatetime) && this.reportType != 'qanda') {
                                        this._modalService.openWarningModal('Alert', 'Please edit this report and set a valid start and end date.');
                                    }
                                    else if (this.reportType == 'performance-comparison' && (!element.startDatetimeB || !element.endDatetimeB)) {
                                        this._modalService.openWarningModal('Alert', 'Please edit this report and set a valid start and end date.');
                                    }
                                    else if (this.reportType == 'performance-comparison' && !element.metricsName.length) {
                                        this._modalService.openWarningModal('Alert', 'Please edit this report and select the metrics you wish to compare.');
                                    }
                                    else if (this.countLocation(element) == 0) {
                                        this._modalService.openWarningModal('Heads up', 'This report has no locations selected. Please edit the report and choose at least 1 location before trying to access the report again.');
                                    }
                                    else if (_.has(element, 'invalidLocations')) {
                                        this._modalService.openConfirmModal('Alert', 'This report contains locations that are no longer available. They will be removed from the report.', function (response) {
                                            if (!response) {
                                                return;
                                            }
                                            _this.validateLocations(element, 'invalid');
                                        });
                                    }
                                    else if (_.has(element, 'duplicatedLocations')) {
                                        this._modalService.openConfirmModal('Alert', 'This report contains duplicated locations. They will be unified.', function (response) {
                                            if (!response) {
                                                return;
                                            }
                                            _this.validateLocations(element, 'duplicated');
                                        });
                                    }
                                    else {
                                        // FIXME: Both urls are the same (?)
                                        if (this.viewModeChecked == 'new') {
                                            this.router.navigateByUrl("/report/" + element.gid + "/" + (element.id || element._id) + "/" + this.reportType).then();
                                        }
                                        else {
                                            this.router.navigateByUrl("/report/" + element.gid + "/" + (element.id || element._id) + "/" + this.reportType).then();
                                        }
                                    }
                                }
                                else {
                                    if (_.has(element, 'invalidLocations')) {
                                        this._modalService.openConfirmModal('Alert', 'This report contains locations that are no longer available. They will be removed from the report.', function (response) {
                                            if (!response) {
                                                return;
                                            }
                                            _this.validateLocations(element, 'invalid');
                                        });
                                    }
                                    else if (_.has(element, 'duplicatedLocations')) {
                                        this._modalService.openConfirmModal('Alert', 'This report contains duplicated locations. They will be unified.', function (response) {
                                            if (!response) {
                                                return;
                                            }
                                            _this.validateLocations(element, 'duplicated');
                                        });
                                    }
                                    else {
                                        this.router.navigate(['/report/' + element.gid + '/' + element.id]);
                                    }
                                }
                                _b.label = 7;
                            case 7: return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        });
    };
    ReportListComponent.prototype.formatLocationsPath = function (accounts) {
        var e_6, _a, e_7, _b;
        var formated = [];
        try {
            for (var accounts_1 = __values(accounts), accounts_1_1 = accounts_1.next(); !accounts_1_1.done; accounts_1_1 = accounts_1.next()) {
                var account = accounts_1_1.value;
                try {
                    for (var _c = (e_7 = void 0, __values(account.locations)), _d = _c.next(); !_d.done; _d = _c.next()) {
                        var location_1 = _d.value;
                        formated.push(account.gid + "/" + account.accountId + "/" + location_1.locationId);
                    }
                }
                catch (e_7_1) { e_7 = { error: e_7_1 }; }
                finally {
                    try {
                        if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
                    }
                    finally { if (e_7) throw e_7.error; }
                }
            }
        }
        catch (e_6_1) { e_6 = { error: e_6_1 }; }
        finally {
            try {
                if (accounts_1_1 && !accounts_1_1.done && (_a = accounts_1.return)) _a.call(accounts_1);
            }
            finally { if (e_6) throw e_6.error; }
        }
        return formated;
    };
    ReportListComponent.prototype.getLocationsToUpgrade = function (accounts) {
        return __awaiter(this, void 0, void 0, function () {
            var locations;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._locationService.basicLocations([{ accounts: accounts }])];
                    case 1:
                        _a.sent();
                        locations = [];
                        accounts.forEach(function (account) {
                            var free = _.pull(account.locationsBasics.map(function (location) { return (location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE ||
                                location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL ||
                                location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.BASIC) && {
                                locationId: location.locationId,
                                accountId: account.accountId
                            }; }), false);
                            if (!_.isEmpty(free)) {
                                locations = Object.assign(locations, free);
                            }
                        });
                        return [2 /*return*/, locations];
                }
            });
        });
    };
    ReportListComponent.prototype.validateLocations = function (element, action) {
        var e_8, _a, e_9, _b;
        var _c;
        if (action === 'invalid') {
            try {
                for (var _d = __values(element.accounts), _e = _d.next(); !_e.done; _e = _d.next()) {
                    var account = _e.value;
                    if (_.isEqual(_.get(account, 'accountId'), 'accounts')) {
                        element.accounts = _.pull(element.accounts, account);
                    }
                    else {
                        var validLocations = _.filter(account.locations, function (o) {
                            return o.accountId !== 'accounts' && o.locationId !== 'locations';
                        });
                        if (_.isEmpty(validLocations)) {
                            element.accounts = _.pull(element.accounts, account);
                        }
                        else {
                            account.locations = validLocations;
                        }
                    }
                }
            }
            catch (e_8_1) { e_8 = { error: e_8_1 }; }
            finally {
                try {
                    if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
                }
                finally { if (e_8) throw e_8.error; }
            }
            delete element.invalidLocations;
        }
        else {
            try {
                for (var _f = __values(element.accounts), _g = _f.next(); !_g.done; _g = _f.next()) {
                    var account = _g.value;
                    account.locations = _.uniqBy(account.locations, 'locationId');
                    account.locationsBasics = _.uniqBy(account.locationsBasics, 'locationId');
                }
            }
            catch (e_9_1) { e_9 = { error: e_9_1 }; }
            finally {
                try {
                    if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
                }
                finally { if (e_9) throw e_9.error; }
            }
            delete element.duplicatedLocations;
        }
        element.startDatetime && (element.startDatetime = moment(element.startDatetime).utc().toISOString());
        element.endDatetime && (element.endDatetime = moment(element.endDatetime).utc().toISOString());
        if (((_c = element) === null || _c === void 0 ? void 0 : _c.reportType) === 'comparison' && element.startDatetimeB && element.endDatetimeB) {
            element.startDatetimeB = moment(element.startDatetimeB).utc().toISOString();
            element.endDatetimeB = moment(element.endDatetimeB).utc().toISOString();
        }
        this._reportService.update(false, this._authService.session.gid, element.id, element);
    };
    ReportListComponent.prototype.display = function (value) {
        if (!value) {
            return null;
        }
        return value.type + ": " + value.reportName;
    };
    ReportListComponent.prototype.getSearchReports = function (reports) {
        var result = [];
        reports.forEach(function (report) {
            var source = _.split(report.source, '/');
            var value = __assign(__assign({}, report), { id: source[1] });
            result = result.concat(value);
        });
        return result;
    };
    ReportListComponent.prototype.prepareToSave = function (report) {
        var e_10, _a;
        var accounts = report.accounts;
        if (accounts && !_.isEmpty(accounts)) {
            try {
                for (var accounts_2 = __values(accounts), accounts_2_1 = accounts_2.next(); !accounts_2_1.done; accounts_2_1 = accounts_2.next()) {
                    var account = accounts_2_1.value;
                    delete account.locationsBasics;
                }
            }
            catch (e_10_1) { e_10 = { error: e_10_1 }; }
            finally {
                try {
                    if (accounts_2_1 && !accounts_2_1.done && (_a = accounts_2.return)) _a.call(accounts_2);
                }
                finally { if (e_10) throw e_10.error; }
            }
        }
        delete report.updateAt;
        report.createdAt = this._dateService.nowTimestamp;
    };
    ReportListComponent.prototype.isAdmin = function () {
        return this._authService.isAdmin;
    };
    // toggleModePerformaceLegacy(): void {
    //   this.reportType = this._getReportType();
    //   this.router.navigateByUrl(`/${this.reportType}-report`).then();
    //   this.detectChanges();
    //   //this.ngOnInit();
    // }
    ReportListComponent.prototype.detectChanges = function () {
        if (this._cdRef !== null &&
            this._cdRef !== undefined &&
            !this._cdRef.destroyed) {
            this._cdRef.detectChanges();
        }
    };
    ReportListComponent.prototype._getReportType = function () {
        var _a;
        return this.viewModeChecked == 'new' ? (_a = this.reportType) === null || _a === void 0 ? void 0 : _a.slice("performance-".length) : "performance-" + this.reportType;
    };
    ReportListComponent.prototype.hasDateInFire = function () {
        return (this.viewModeChecked === 'legacy');
    };
    return ReportListComponent;
}());
export { ReportListComponent };
