import { Title } from '@angular/platform-browser';
import { VERSION, isDevMode } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { AppConfigService } from './app.config';
import { AuthService } from './services/auth.service';
import { WhiteLabelService } from './services/white-label.service';
import { environment } from '@environment';
import { GlobalErrorHandler } from './services/error-handle.service';
var DEFAULT_SWIPER_CONFIG = {
    direction: 'horizontal',
    slidesPerView: 'auto'
};
var DATE_FORMAT = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'MM/DD/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'MM/DD/YYYY',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};
// To avoid dependencies issues, we need to create a factory for the GlobalErrorHandler
export function errorHandlerFactory(authService) {
    return new GlobalErrorHandler(authService);
}
var ɵ0 = {}, ɵ1 = DEFAULT_SWIPER_CONFIG, ɵ2 = DATE_FORMAT, ɵ3 = { autoClose: true };
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export function initializeApp(appConfig) {
    console.log("Angular version: " + VERSION.full);
    console.log('Mode: ' + (isDevMode() ? 'DEVELOPMENT' : 'PRODUCTION'));
    return appConfig.fireConfig();
}
export function WhiteLabelDomainInitializerFn(appConfig, wl, titleService, html) {
    return function () {
        wl.getDynamicWhiteLabel().then(function (data) {
            if (environment.apiUrlOverwriteWithWL) {
                environment.apiUrl = data.apiUrl;
            }
            environment.branding = data.branding;
            environment.hideComparisonInGrader = data.hideComparisonInGrader;
            environment.hideSignUpButton = data.hideSignUpButton;
            html.getElementById('favicon').setAttribute('href', data.branding.favicon || '/assets/images/favicon-wl/favicon.ico');
        });
    };
}
export { ɵ0, ɵ1, ɵ2, ɵ3 };
