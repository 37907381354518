import { __awaiter, __generator, __values } from "tslib";
// dep
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, BehaviorSubject, of } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import * as _ from 'lodash';
// app
import { SnackbarService } from '../../services/snackbar.service';
import { ReportService } from '../../services/report.service';
import { LocationService } from '../../services/location.service';
import { ReviewsService } from '../../services/reviews.service';
import { ProtocolService } from '../../services/protocol.service';
import { LoadingService } from '../../services/loading.service';
var ProtocolReportComponent = /** @class */ (function () {
    function ProtocolReportComponent(protocolS, route, reviewsS, locationS, reportS, snack, loadingService) {
        this.protocolS = protocolS;
        this.route = route;
        this.reviewsS = reviewsS;
        this.locationS = locationS;
        this.reportS = reportS;
        this.snack = snack;
        this.loadingService = loadingService;
        this.isShared = false;
        this.lockDates = false;
        this.last = null;
        this.next = null;
        this.resume = new Subject();
        // responses: { answered: number, notAnswered: number };
        this.count_reviews_replies_protocols$ = of(null);
        this.count_reviews_remaining = 0;
        this.page = 1;
        this.size = 10;
        this.paginate = { size: this.size, page: this.page };
        this.previousPageable = { page: 1, size: 10 };
        this.pagination = {
            items: [],
            per_page: this.paginate.size,
            page: 1,
            hasNext: false,
            hasPrev: false,
            pages: 0,
            total: 0
        };
        this.displayedColumns = ['name', 'status', 'triggers'];
        this.dataSource = new MatTableDataSource([]);
        this.protocolsExist = false;
        this.filtered = false;
        // progress events
        this.isProgressResume = true;
        this.isProgressDistribution = true;
        // isProgressResponses = true;
        this.progress = new BehaviorSubject(true);
        this.NotFoundSearch$ = new BehaviorSubject(false);
        // TimeOut search
        this.timeout = null;
        this.selectionProtocol = new SelectionModel(true, []);
        this.searchArrayProtocols = [];
    }
    ProtocolReportComponent.prototype.ngOnDestroy = function () {
        this.reviewResume$.unsubscribe();
        this.ratingDistribution$.unsubscribe();
        // this.aggregationResponses$ .unsubscribe();
    };
    ProtocolReportComponent.prototype.ngOnInit = function () {
        this.locations = this.reportDataPicker.locations;
        this.dataPicker = this.reportDataPicker;
        this.gid = this.route.snapshot.params.gid;
        this.getReviewData(this.dataPicker, false);
        this.getData();
    };
    /**
     * this method get all data of charts when change date picker
     */
    ProtocolReportComponent.prototype.getReviewData = function (dataPicker, refresh) {
        var _this = this;
        this.isProgressResume = true;
        this.isProgressDistribution = true;
        // this.isProgressResponses = true;
        if (!this.locations)
            return;
        this.reviewResume$ = this.locationS.review_summary(this.gid, this.locations).take(2).subscribe(function (resume) {
            var _a;
            if (resume) {
                _this.isFailedResume = false;
                _this.isProgressResume = false;
                if (((_a = resume) === null || _a === void 0 ? void 0 : _a.length) > 1) {
                    _this.resumeManyLocations(resume);
                }
                else {
                    _this.reviewsStats = resume;
                    _this.differenceAverage = resume.difference.average;
                    _this.differenceTotal = resume.difference.total;
                }
                _this.resume.next(_this.reviewsStats);
                _this.loadingService.reportAdvanced(1, "Review Summary");
            }
            else {
                _this.isProgressResume = false;
                _this.isFailedResume = true;
            }
            refresh && _this.snack.openSuccess('Finished refreshing! ', 2000);
        });
        this.count_reviews_replies_protocols$ = this.protocolS.getProtocolDataCounters(this.locations).pipe(map(function (r) { return r.Count; }));
        this.ratingDistribution$ = this.reviewsS.ratingDistribution(this.locations).take(2).subscribe(function (result) {
            if (result.data) {
                _this.isProgressDistribution = false;
                _this.rating = result.data;
                _this.loadingService.reportAdvanced(1, "Review Ratings");
            }
        }, function () {
            // this.noDataDistribution = true;
            _this.isProgressDistribution = false;
        });
        // this.aggregationResponses$ = this.reviewsS.aggregationResponses(this.locations, null).take(2).subscribe(result => {
        //   this.responses = result.data;
        //   this.isProgressResponses = false;
        //   this.loadingService.reportAdvanced(1, "Review Responses")
        // });
    };
    ProtocolReportComponent.prototype.isAllSelectedProtocol = function () {
        var numSelected = this.selectionProtocol.selected.length;
        var numRows = this.dataSource.data.length;
        return numSelected === numRows;
    };
    ProtocolReportComponent.prototype.masterToggleProtocol = function () {
        var _this = this;
        this.isAllSelectedProtocol() ?
            this.selectionProtocol.clear() :
            this.dataSource.data.forEach(function (row) { return _this.selectionProtocol.select(row); });
    };
    ProtocolReportComponent.prototype.checkboxLabelProtocol = function (row) {
        if (!row) {
            return (this.isAllSelectedProtocol() ? 'select' : 'deselect') + " all";
        }
        return (this.selectionProtocol.isSelected(row) ? 'deselect' : 'select') + " row " + (row.protocolId + 1);
    };
    ProtocolReportComponent.prototype.progressValue = function (element) {
        return (element.status.replied / element.status.total) * 100;
    };
    // TODO: Refactor against dashboard-reviews.component.ts / resumeManyLocations
    ProtocolReportComponent.prototype.resumeManyLocations = function (resumes) {
        var e_1, _a;
        var _b;
        var stats = { averageRating: 0,
            totalReviewCount: 0,
            answered: 0,
            notAnswered: 0,
            googleResume: { totalReviewCount: 0 }
        };
        var difference = { average: 0,
            total: 0 };
        var length = resumes.length;
        try {
            for (var resumes_1 = __values(resumes), resumes_1_1 = resumes_1.next(); !resumes_1_1.done; resumes_1_1 = resumes_1.next()) {
                var r = resumes_1_1.value;
                if (!r || r.totalReviewCount === 0) {
                    if (length > 0)
                        length--;
                    continue;
                }
                difference.total += r.difference.total;
                difference.average += r.difference.average;
                stats.averageRating += r.averageRating;
                stats.totalReviewCount += r.totalReviewCount;
                stats.answered += r.answered;
                stats.notAnswered += r.notAnswered;
                stats.googleResume.totalReviewCount += ((_b = r.googleResume) === null || _b === void 0 ? void 0 : _b.totalReviewCount) || 0;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (resumes_1_1 && !resumes_1_1.done && (_a = resumes_1.return)) _a.call(resumes_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        stats.averageRating /= (length || 1);
        difference.average /= (length || 1);
        this.differenceTotal = difference.total;
        this.differenceAverage = difference.average;
        this.reviewsStats = stats;
        this.isProgressResume = false;
    };
    ProtocolReportComponent.prototype.getData = function () {
        var _this = this;
        this.protocolS.getProtocolReport(this.locations, this.paginate)
            .pipe(take(1))
            .subscribe(function (result) { return __awaiter(_this, void 0, void 0, function () {
            var pagination;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        pagination = result.paginate;
                        return [4 /*yield*/, this.locationS.basicLocations(pagination.items)];
                    case 1:
                        _a.sent();
                        this.protocols = pagination.items;
                        this.pagination = pagination;
                        this.previousPageable = { size: pagination.per_page, page: pagination.page };
                        this.progress.next(false);
                        this.dataSource = new MatTableDataSource(this.protocols);
                        this.protocolsExist = this.dataSource.data.length > 0;
                        this.count_reviews_remaining = result.remaining;
                        this.searchArrayProtocols = result.protocols;
                        this.loadingService.reportAdvanced(1, "Protocols");
                        return [2 /*return*/];
                }
            });
        }); });
    };
    ProtocolReportComponent.prototype.countLocation = function (element) {
        var _a, _b;
        var counter = 0;
        (_b = (_a = element) === null || _a === void 0 ? void 0 : _a.accounts) === null || _b === void 0 ? void 0 : _b.forEach(function (account) {
            var _a, _b;
            counter += (_b = (_a = account) === null || _a === void 0 ? void 0 : _a.locations) === null || _b === void 0 ? void 0 : _b.length;
        });
        return counter;
    };
    ProtocolReportComponent.prototype.countLocations = function (element) {
        return this.reportS.countLocation(element);
    };
    // apply filter from search
    ProtocolReportComponent.prototype.applyFilter = function ($event, key) {
        var _this = this;
        this.NotFoundSearch$.next(false);
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
            if (!$event || $event === '') {
                _this.progress.next(true);
                _this.initPaginate();
                _this.getData();
                _this.filtered = false;
                _this.searchText = '';
            }
            else {
                !_this.filtered && (_this.initPaginate());
                _this.progress.next(true);
                var text_1 = $event.toLowerCase().trim();
                _this.searchText = text_1;
                var search = _this.searchArrayProtocols.filter(function (s) { return s.name.trim().toLowerCase().indexOf(text_1) > -1; });
                _this.setData(search);
                _this.filtered = true;
            }
        }, 350);
    };
    ProtocolReportComponent.prototype.initPaginate = function () {
        this.paginate = { page: 1, size: 10 };
        this.previousPageable = null;
        this.dataSource = new MatTableDataSource([]);
    };
    ProtocolReportComponent.prototype.setData = function (results) {
        var data = _.chunk(results, this.paginate.size);
        var items = data[this.paginate.page - 1];
        var source = this.protocolS.getProtocolsPaginate(results.length, this.paginate, items);
        this.previousPageable = { size: source.per_page, page: source.page };
        this.pagination = source;
        this.dataSource = new MatTableDataSource(source.items);
        this.progress.next(false);
    };
    ProtocolReportComponent.prototype.handleReload = function ($event) {
        this.progress.next(true);
        this.paginate = $event;
        if (this.filtered) {
            this.applyFilter(this.searchText, 'Enter');
        }
        else {
            this.getData();
        }
    };
    return ProtocolReportComponent;
}());
export { ProtocolReportComponent };
