<div class="dialog__header">
    <h2 mat-dialog-title class="m--0 txt--xl"><strong>{{'Add service'|titlecase}}</strong></h2>
  </div>
  <div class="dialog__content" mat-dialog-content>
    <div class="row" *ngIf="category?.serviceTypes && category.serviceTypes.length > 0"> 
        <div class="col-12">
            <small>{{ category.primary ? 'Main Category': ''}}</small>
            <h3 class="my-1" style="color: #000; margin: 10px 0;">{{category.displayName}}</h3>
            <p>Add the services you offer and allow customers to find you</p>
            <mat-form-field class="w--100">
                <input type="text"
                       placeholder="Select one service"
                       matInput
                       [formControl]="serviceTypesOptions"
                       [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="continue($event.option.value, false)"> 
                  <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                    {{option}}
                  </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    </div>
    <mat-divider style="margin: 30px 0;" *ngIf="category?.serviceTypes && category.serviceTypes.length > 0"></mat-divider>
    <div class="row"> 
        <div class="col-12">
            <h3 style="color: #000; margin: 10px 0;">Customized services</h3>
            <p>Don't see a service you offer? Create yours.</p>
            <span class="alternate-theme d-flex align-items-center">
                <button mat-flat-button color="primary" class="btn btn-icon" (click)="continue(category, true)">
                    <i class="fas fa-plus"></i> Add custom service
                </button>
            </span>
        </div>
    </div>
  
  </div>
  <div mat-dialog-actions class="dialog__footer">
    <span class="alternate-theme d-inline-block mr--10">
        <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
    </span>
  </div>
  