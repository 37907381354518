import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges} from '@angular/core';
import {CdkStepper} from '@angular/cdk/stepper';
import { ModalService } from 'src/app/services/modal.service'; 
import { Directionality } from '@angular/cdk/bidi';
import { AlertType } from 'src/app/components/alert.component';

export const PROTOCOL_SCAN_ACTION: number = 1;
export const NORMAL_ACTION: number = 0;

@Component({
  selector: 'app-modal-stepper',
  templateUrl: './modal-stepper.component.html',
  styleUrls: ['./modal-stepper.component.scss'],
  providers: [
    { provide: CdkStepper, useExisting: ModalStepperComponent }
  ]
})
export class ModalStepperComponent extends CdkStepper implements OnInit, OnChanges {
  @Input() title = [];
  @Input() finalAction: string;
  @Input() step: number;
  @Input() scan_action = false;
  @Input() isEdit =  false;
  @Input() isScan =  false;
  @Input() isDraft = false;
  @Input() hasDraft = false;
  @Input() disabledStep = false;
  @Input() drawer = null;
  @Input() isSliderOpened = false;
  @Input() isSlider = false;
  @Input() reportType;
  @Input() stepTitle = [];
  @Input() nextButtonLabels = [];
  @Input() cancelText = {
    title: 'Heads up…',
    message : 'Your changes will not be saved if you exit the report creation wizard.'
  };
  @Input() closeDrawerAfterComplete = true
  @Input() closeLabel = 'Cancel';
  @Input() verifiedDataLabel = null;
  @Input() dataIsBeingVerified = false;
  @Input() cancelButton = 'Confirm'
  
  @Output() completed = new EventEmitter();
  @Output() backStep = new EventEmitter();
  @Output() step_activate = new EventEmitter();
  @Output() destroyData = new EventEmitter();
  @Output() doValidationMetrics = new EventEmitter();
  @Output() onStepChange = new EventEmitter();
  @Output() postDraftEvent = new EventEmitter();

  constructor(
    public dir: Directionality,
    public changeDetectorRef: ChangeDetectorRef,
    public elementRef: ElementRef<HTMLElement>,
    private _modalService: ModalService
    ) {
    super(dir, changeDetectorRef, elementRef);
   }   

  ngOnInit(): void {
    this.selectedIndex = this.step;
    this.title = typeof(this.title) === 'object' ? this.title : [this.title];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.isSliderOpened && this.isSlider) {
      this.selectedIndex = 0;
    }

    const step: SimpleChange = changes?.step;
    if(step){
      this.selectedIndex = step.currentValue;
    }

    this.title = typeof(this.title) === 'object' ? this.title : [this.title];
  }

  get percent(): number {
    return (this.selectedIndex + 1) * (100 / this.steps.length);
  }

  get index(): number {
    return this.selectedIndex + 1;
  }

  get_step(): void {
    this.doValidationMetrics.emit(this.reportType == 'performance-comparison' && this.selectedIndex == 2)
    this.step_activate.emit(this.selectedIndex);
    this.onStepChange.emit(this.selectedIndex);
  }

  stepperPrevious(): void {
    this.backStep.emit(this.selectedIndex);
  }

  complete(): void{
    if(this.closeDrawerAfterComplete) {
      this.drawer?.toggle();
    }
    this.completed.emit(NORMAL_ACTION);
  }

  create_scan(): void {
    this.completed.emit(PROTOCOL_SCAN_ACTION);
  }

  postDraft(): void {
    this.postDraftEvent.emit();
  }

  dialogClose(): void {
    this._modalService.openConfirmModal(
      this.cancelText?.title,
      this.cancelText?.message,
      ((response) => {
        if (response) {
          this.drawer?.toggle();
          this.selectedIndex = 0;
          this.destroyData.emit();
        }
      }),
      null,
      this.cancelButton
    );
  }
  
  buttonNextVisible(): boolean {
    if(this.isScan && !this.isEdit && this.selectedIndex === 2){
      return false;
    }

    return true;
  }
}