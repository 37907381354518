import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bulk-post',
  templateUrl: './bulk-post.component.html',
  styleUrls: ['./bulk-post.component.scss']
})
export class BulkPostComponent implements OnInit {
  public isNewPost = false;

  constructor(
    private _router: Router,
  ) { }

  ngOnInit() {
    this.isNewPost = this._router.url?.includes('posts-management');
  }

}
