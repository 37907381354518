import { EventEmitter, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { LocationEditService } from "../../services/location-edit.service";
import { GoogleService } from "../../services/google.service";
import { of, Subject } from "rxjs";
import { debounceTime, switchMap } from "rxjs/operators";
import { FormArray, FormControl, Validators } from '@angular/forms';
import { SnackbarService } from '../../services/snackbar.service';
function autocompleteObjectValidator() {
    return function (control) {
        if (typeof control.value === 'string') {
            return { 'invalidAutocompleteObject': { value: control.value } };
        }
        return null; /* valid option selected */
    };
}
var ModalCategoryComponent = /** @class */ (function () {
    function ModalCategoryComponent(dialogRef, data, locationEditS, googleS, snackS) {
        var _a;
        this.dialogRef = dialogRef;
        this.data = data;
        this.locationEditS = locationEditS;
        this.googleS = googleS;
        this.snackS = snackS;
        this.primaryCategory = '';
        this.hideAddCategory = false;
        this.bulk = false;
        this.validResult = new EventEmitter();
        this._search = new Subject();
        this.formPrimaryCategory = new FormControl(null, [autocompleteObjectValidator(), Validators.required]);
        this.additionalList = new FormArray([]);
        if (data) {
            this.regionCode = ((_a = data) === null || _a === void 0 ? void 0 : _a.regionCode) ? data.regionCode : this.regionCode;
            if (data.primaryCategory === null || data.primaryCategory === undefined) {
                this.primaryCategory = '';
                this.formPrimaryCategory.setValue('');
            }
            else {
                this.primaryCategory = data.primaryCategory;
                this.formPrimaryCategory.setValue(data.primaryCategory);
            }
            var additionalData = this.data.additionalCategory;
            if (additionalData !== null && additionalData !== undefined) {
                if (additionalData.length > 0) {
                    for (var keyItem in additionalData) {
                        this.additionalList.push(new FormControl(additionalData[keyItem], [autocompleteObjectValidator(), Validators.required]));
                    }
                }
            }
        }
    }
    ModalCategoryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.search$ = this._search.pipe(debounceTime(500), switchMap(function (value) {
            if (value) {
                var usedCategories = _this.additionalList.controls.map(function (el) { return el.value; });
                return _this.googleS.getCategories(value, usedCategories, _this.regionCode);
            }
            else {
                return of([]);
            }
        }));
    };
    ModalCategoryComponent.prototype.addCategory = function () {
        if (this.additionalList.length < 9) {
            if (this.additionalList.length == 8)
                this.hideAddCategory = true;
            this.additionalList.push(new FormControl({ categoryId: '', displayName: '' }, [autocompleteObjectValidator(), Validators.required]));
        }
        else
            this.snackS.openInfo('Cannot add more locations', 3000);
    };
    ModalCategoryComponent.prototype.searchCategories = function ($event) {
        this._search.next($event.target.value);
    };
    ModalCategoryComponent.prototype.removeAdditionalCategory = function (index) {
        if (index > -1) {
            if (this.hideAddCategory)
                this.hideAddCategory = false;
            this.additionalList.removeAt(index);
        }
    };
    ModalCategoryComponent.prototype.deleteEmptycategories = function () {
        var _this = this;
        this.additionalList.controls.forEach(function (el, index) {
            if (el.status == 'INVALID' && !el.value) {
                _this.removeAdditionalCategory(index);
            }
        });
    };
    ModalCategoryComponent.prototype.apply = function () {
        var _this = this;
        this.deleteEmptycategories();
        if (this.formPrimaryCategory.status != "VALID" || this.additionalList.status != "VALID") {
            return;
        }
        var location = this.locationEditS.locationEdit;
        if ('type' in this.primaryCategory)
            delete this.primaryCategory['type'];
        location.primaryCategory = this.formPrimaryCategory.value || this.primaryCategory;
        var additionalList = this.additionalList.value.map(function (ac) {
            if ('type' in ac)
                delete ac['type'];
            return ac;
        });
        location.additionalCategories = additionalList.filter(function (el) { return el.categoryId != ''; });
        this.locationEditS.setAndUpdate().toPromise().then(function () {
            _this.dialogRef.close();
        });
    };
    ModalCategoryComponent.prototype.displayFn = function (category) {
        return category ? category.displayName : this.primaryCategory.displayName;
    };
    ModalCategoryComponent.prototype.returnFn = function (category) {
        return category ? category.id : undefined;
    };
    ModalCategoryComponent.prototype.clearSearch = function () {
        this.primaryCategoriesDropdown = [];
        this.additionalCategoriesDropdown = [];
        this._search.next();
    };
    ModalCategoryComponent.prototype.selectedAdditional = function ($event, form) {
        var data = $event.option.value;
        if ('type' in data) {
            delete data.type;
        }
        form.setValue(data);
    };
    ModalCategoryComponent.prototype.validate = function (e) {
        this.validResult.emit(e !== '');
    };
    ModalCategoryComponent.prototype.getResult = function () {
        if (this.formPrimaryCategory.valid) {
            if (!this.additionalList.valid) {
                this.validResult.emit(false);
                return;
            }
            var additionalList = this.additionalList.controls.filter(function (el) { return el.value.categoryId && el.value.displayName; });
            this.primaryCategory = this.formPrimaryCategory.value;
            var additionalListValues_1 = [];
            additionalList.forEach(function (al) { additionalListValues_1.push(al.value); });
            this.validResult.emit(true);
            if ('type' in this.primaryCategory)
                delete this.primaryCategory['type'];
            return { primaryCategory: this.primaryCategory, additionalCategories: additionalListValues_1 };
        }
        else {
            this.validResult.emit(false);
        }
    };
    return ModalCategoryComponent;
}());
export { ModalCategoryComponent };
