import { Routes } from '@angular/router';

import { MainComponent } from './modules/main/main.component';
import { AccountsComponent } from './modules/accounts/accounts.component';
import { LocationsComponent } from './modules/locations/locations.component';
import { RollupReportComponent } from './modules/rollup-report/rollup-report.component';
import { ReviewReportsComponent } from './modules/review-reports/review-reports.component';
import { ComparisonReportsComponent } from './modules/comparison-reports/comparison-reports.component';
import { RevenueReportsComponent } from './modules/revenue-reports/revenue-reports.component';
import { LoginComponent } from './modules/login/login.component';
import { AuthGuardService as AuthGuard } from './services/auth-guard.service';
import { MetaGuardService } from './services/meta-guard.service';
import { RedirectGuardService as RedirectGuard } from './services/redirect-guard.service';
import { LocationFreeGuardService as LocationFreeGuard, LocationUltimateTrialGuardService as LocationUltimateTrialGuard } from './services/location-guard.service';
import { BlankLayoutComponent, DefaultLayoutComponent } from './layouts';
import { UpgradeComponent } from './modules/upgrade/upgrade.component';
import { ReviewReportInsightComponent } from './modules/review-report-insight/review-report-insight.component';
import { RegisterComponent } from './modules/register/register.component';
import { RevenueReportInsightComponent } from './modules/revenue-report-insight/revenue-report-insight.component';
import { ComparisonReportInsightComponent } from './modules/comparison-report-insight/comparison-report-insight.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { DashboardInsightsComponent } from './modules/dashboard-insights/dashboard-insights.component';
import { DashboardPerformanceInsightComponent } from './modules/dashboard-performance-insight/dashboard-performance-insight.component';
import { DashboardReviewsComponent } from './modules/dashboard-reviews/dashboard-reviews.component';
import { DashboardPostsComponent } from './modules/dashboard-posts/dashboard-posts.component';
import { DashboardLocationComponent } from './modules/dashboard-location/dashboard-location.component';
import { DashboardGradeComponent } from './modules/dashboard-grade/dashboard-grade.component';
import { DashboardWidgetComponent } from './modules/dashboard-widget/dashboard-widget.component';
import { DashboardReviewLinkComponent } from './modules/dashboard-review-link/dashboard-review-link.component';
import { DashboardSearchComponent } from './modules/dashboard-search/dashboard-search.component';
import { DashboardWorkshopComponent } from './modules/dashboard-workshop/dashboard-workshop.component';
import { ForgotPasswordComponent } from './modules/forgot-password/forgot-password.component';
import { ReportsComponent } from './modules/reports/reports.component';
import { ReviewAutoResponderComponent } from './modules/review-auto-responder/review-auto-responder.component';
import { BulkPostComponent } from './modules/bulk-post/bulk-post.component';
import { ExternalGradeComponent } from './modules/external-grade/external-grade.component';
import { TextReaderComponent } from './modules/text-reader/text-reader.component';
import { VerifyEmailComponent } from './modules/verify-email/verify-email.component';
import { LocationInfoListComponent } from './modules/location-info-list/location-info-list.component';

import { DashboardServicesComponent } from './modules/dashboard-services/dashboard-services.component';
import { DashboardPhotosComponent } from './modules/dashboard-photos/dashboard-photos.component';
import { DashboardMenuComponent } from './modules/dashboard-menu/dashboard-menu.component';
import { SearchKeywordsComponent } from './modules/search-keywords/search-keywords-report/search-keywords.component';
import { SearchKeywordsViewComponent } from './modules/search-keywords/search-keywords-view/search-keywords-view.component';
import { QuestionsAnswerComponent } from './modules/questions-answer/questions-answer.component';
import { QuestionsAnswersViewComponent } from './modules/questions-answer/questions-answers-view/questions-answers-view.component';
import { ProfilesComponent } from './modules/profiles/profiles.component';
import { BulkActionsGuardService } from './services/bulk-actions-guard.service';

export const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'widget/:gid/:accountId/:locationId/review',
    loadChildren: './widget/widget.module#WidgetModule',
  },
  {
    path: 'settings',
    component: DefaultLayoutComponent,
    loadChildren: './settings/settings.module#SettingsModule',
  },
  {
    path: 'verify',
    component: VerifyEmailComponent,
  },
  {
    path: 'text-reader/:text',
    component: TextReaderComponent,
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      {
        path: 'login',
        data: { title: 'Login' },
        component: LoginComponent
      },
      {
        path: 'forgot-password',
        data: { title: 'Forgot password' },
        component: ForgotPasswordComponent
      },
      {
        path: 'register',
        data: { title: 'Register', robots: 'noindex, nofollow' },
        canActivate: [MetaGuardService],
        component: RegisterComponent,
      },
    ]
  },
  {
    path: 'account/:accountId/location/:locationId',
    component: DashboardLayoutComponent,
    children: [
      {
        path: 'workshop',
        component: DashboardWorkshopComponent,
        data: { title: 'Workshop' },
        canActivate: [AuthGuard, LocationFreeGuard],
      },
      {
        path: 'keywords',
        component: SearchKeywordsComponent,
        data: { 
          title: 'Keywords',
          isReport: false
        },
        //loadChildren: () => SearchKeywordsModule
      },
      {
        path: 'insights',
        component: DashboardPerformanceInsightComponent,
        canActivate: [AuthGuard],
        data: { title: 'Insights' }
      },
      {
        path: 'legacy-insights',
        component: DashboardInsightsComponent,
        canActivate: [AuthGuard],
        data: { title: 'Insights' }
      },
      {
        path: 'reviews',
        component: DashboardReviewsComponent,
        canActivate: [AuthGuard],
        data: { title: 'Reviews' }
      },
      {
        path: 'qanda',
        component: QuestionsAnswerComponent,
        data: { title: 'Q & A' }
      },
      {
        path: 'posts',
        component: DashboardPostsComponent,
        canActivate: [AuthGuard, LocationFreeGuard],
        data: { title: 'Posts' }
      },
      {
        path: 'post-management',
        component: DashboardPostsComponent,
        canActivate: [AuthGuard, LocationFreeGuard],
        data: { title: 'Posts' }
      },
      {
        path: 'location-info',
        component: DashboardLocationComponent,
        canActivate: [AuthGuard, LocationFreeGuard],
        data: { title: 'Location Info' }
      },
      {
        path: 'services',
        component: DashboardServicesComponent,
        canActivate: [AuthGuard, LocationFreeGuard],
        data: { title: 'Services' }
      },
      {
        path: 'menu',
        component: DashboardMenuComponent,
        canActivate: [AuthGuard, LocationFreeGuard],
        data: { title: 'Menu' }
      },
      {
        path: 'photos',
        component: DashboardPhotosComponent,
        canActivate: [AuthGuard, LocationFreeGuard],
        data: { title: 'Photos' }
      },
      {
        path: 'grade',
        component: DashboardGradeComponent,
        canActivate: [AuthGuard],
        data: { title: 'Grade' }
      }, {
        path: 'widget',
        component: DashboardWidgetComponent,
        canActivate: [AuthGuard, LocationFreeGuard],
        data: { title: 'Review Widget' }
      }, 
      {
        path: 'review-link-generator',
        component: DashboardReviewLinkComponent,
        canActivate: [AuthGuard, LocationFreeGuard],
        data: { title: 'Review Link Generator' }
      },
      {
        path: 'search',
        component: DashboardSearchComponent,
        canActivate: [AuthGuard],
        data: { title: 'Search' }
      },
    ]
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: ''
    },
    children: [
      {
        path: 'home',
        component: MainComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'accounts',
        component: AccountsComponent,
        data: { title: 'Accounts' },
        canActivate: [AuthGuard]
      },
      {
        path: 'report/:gid/:id',
        component: ReportsComponent,
        data: { title: 'Report' },
        canActivate: [AuthGuard]
      },
      {
        path: 'report/:gid/:id/shared',
        component: ReportsComponent,
      },
      {
        path: 'report/:gid/:id/:reportType',
        component: ReportsComponent,
        data: { title: 'Report' },
        canActivate: [AuthGuard]
      },
      {
        path: 'reports/:id/keywords',
        component: SearchKeywordsComponent,
        data: {
          title: 'Keywords',
          isReport: true
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'accounts/:accountObjectId/locations',
        component: LocationsComponent,
        data: { title: 'Locations' },
        canActivate: [AuthGuard]
      },
      {
        path: 'profiles',
        component: ProfilesComponent,
        data: { title: 'Profiles' },
        canActivate: [AuthGuard]
      },
      {
        path: 'location/:locationId/workshop',
        component: DashboardWorkshopComponent,
        canActivate: [AuthGuard, RedirectGuard, LocationFreeGuard]
      },
      {
        path: 'local-posts',
        component: BulkPostComponent,
        data: { title: 'Bulk Post Management' },
        canActivate: [AuthGuard, BulkActionsGuardService]
      },
      {
        path: 'posts-management',
        component: BulkPostComponent,
        data: { title: 'Bulk Post Management' },
        canActivate: [AuthGuard, BulkActionsGuardService]
      },
      {
        path: 'local-bulk',
        component: LocationInfoListComponent,
        data: { title: 'Bulk Location Info' },
        canActivate: [AuthGuard, BulkActionsGuardService]
      },
      {
        path: 'qanda',
        component: QuestionsAnswersViewComponent,
        data: { title: 'Q & A' },
        canActivate: [AuthGuard, BulkActionsGuardService]
      },
      {
        path: 'review-assistant',
        component: ReviewAutoResponderComponent,
        data: { title: 'Review Assistant' },
        canActivate: [AuthGuard]
      },
      {
        path: 'rollup-report',
        component: RollupReportComponent,
        data: { title: 'Legacy Rollup Report' },
        canActivate: [AuthGuard]
      },
      {
        path: 'performance-rollup-report',
        component: RollupReportComponent,
        data: { title: 'Performance Rollup Report' },
        canActivate: [AuthGuard, BulkActionsGuardService]
      },
      {
        path: 'review-report',
        component: ReviewReportsComponent,
        data: { title: 'Review Report' },
        canActivate: [AuthGuard]
      },
      {
        path: 'comparison-report',
        component: ComparisonReportsComponent,
        data: { title: 'Comparison Report' },
        canActivate: [AuthGuard]
      },
      {
        path: 'performance-comparison-report',
        component: ComparisonReportsComponent,
        data: { title: 'Comparison Report' },
        canActivate: [AuthGuard]
      },
      {
        path: 'performance-revenue-report',
        component: RevenueReportsComponent,
        data: { title: 'Revenue Report' },
        canActivate: [AuthGuard]
      },
      {
        path: 'revenue-report',
        component: RevenueReportsComponent,
        data: { title: 'Revenue Report' },
        canActivate: [AuthGuard]
      },
      {
        path: 'keywords',
        component: SearchKeywordsViewComponent,
        data: {
          title: 'Keywords',
          isReport: true
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'upgrade',
        component: UpgradeComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'review-report-insight',
        component: ReviewReportInsightComponent
      },
      {
        path: 'revenue-report-insight',
        component: RevenueReportInsightComponent
      },
      
      {
        path: 'comparison-report-insight',
        component: ComparisonReportInsightComponent
      },
      {
        path: 'auto-responder',
        component: ReviewAutoResponderComponent
      },
    ]
  },
  {
    path: 'report/:gid/:id/:reportType/shared',
    component: ReportsComponent,
  },
  {
    path: 'report/automated/:gid/:automatedReport/:reportType/shared',
    component: ReportsComponent,
  },
  {
    path: 'reports/:hash/auth',
    component: ReportsComponent,
  },
  {
    path: 'grade',
    component: ExternalGradeComponent,
    data: { title: 'Grader' },
  },

  { path: '**', redirectTo: '/login' }
];
