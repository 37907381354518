<ng-container *ngIf="!type">
  <div *ngIf="showEmpty; else onlyFull">
    <span class="single-star" *ngIf="starts<1"></span>
    <span class="single-star single-star--full" *ngIf="starts>=1"></span>
    <span class="single-star" *ngIf="starts<2"></span>
    <span class="single-star single-star--full" *ngIf="starts>=2"></span>
    <span class="single-star" *ngIf="starts<3"></span>
    <span class="single-star single-star--full" *ngIf="starts>=3"></span>
    <span class="single-star" *ngIf="starts<4"></span>
    <span class="single-star single-star--full" *ngIf="starts>=4"></span>
    <span class="single-star" *ngIf="starts<5"></span>
    <span class="single-star single-star--full" *ngIf="starts==5"></span>
  </div>

  <ng-template #onlyFull>
    <p *ngIf="showLabel" class="m--0 w--50-px">{{getLabel()}}</p>
    <ng-container *ngFor="let star of numberOfStars">
      <span class="single-star single-star--full"></span>
    </ng-container>
  </ng-template>
</ng-container>


<ng-container *ngIf="type && type === 'average'">
  <div class="stars m--0">
    <div class="stars--bg stars--empty"></div>
    <div class="stars--bg stars--full" [style.width.%]="starts * 10 * 2"></div>
  </div>
</ng-container>


