// dep
import { AfterViewChecked, Component, OnInit, OnDestroy, ViewChild, Renderer2 } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatSidenav } from '@angular/material';

// app
import { WhiteLabelService } from '../../services/white-label.service';
import { DataTransferService } from '../../services/data-transfer.service';
import { SpinnerService } from '../../services/spinner.service';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent implements OnInit, OnDestroy, AfterViewChecked {
  isFullWidth: boolean;
  contentClass: any;
  opened: boolean;

  destroySubs$ = new Subject<boolean>()
  @ViewChild('sidenav', {static: false}) 
  sidenav: MatSidenav

  constructor(
    public spinnerService: SpinnerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private wl: WhiteLabelService,
    private renderer2: Renderer2,
    private dataTransfer: DataTransferService<string>
  ) {
    const appTitle = this.titleService.getTitle();
    router.events.pipe(takeUntil(this.destroySubs$))
    .filter((event) => event instanceof NavigationEnd)
    .map(() => {
      const child = this.activatedRoute.firstChild;
      if (child.snapshot.data['title']) {
        return child.snapshot.data['title'];
      }
      return appTitle;
    }).subscribe(async (title: string) => {
      this.dataTransfer.getData().pipe(takeUntil(this.destroySubs$)).subscribe( async (locationName) => {
        const company_name = (await this.wl.getDynamicWhiteLabel()).branding.company_name;
        const title_pass = `${title} | ${locationName} | ${company_name}`;
        this.titleService.setTitle(title_pass)
      });


      const url = this.router.url;
      this.isFullWidth = (url.includes('insights') || url.includes('reviews'));
      this.updateClass();
    });
    this.opened = true;
  }

  toggleSideNav() {
    this.sidenav.toggle();
    const elementId = document.getElementById('dashboard-filter');
    if (elementId) {
      if (!this.sidenav.opened) {
        this.renderer2.addClass(elementId, 'remove-padding')
      } else {
        this.renderer2.removeClass(elementId, 'remove-padding')
      }
    }
  }

  ngOnDestroy(): void {
    this.destroySubs$.next(true);
    this.destroySubs$.unsubscribe();
  }

  ngOnInit() {
    const url = this.router.url;
    this.isFullWidth = (url.includes('insights') || url.includes('reviews'));
    this.updateClass();
  }

  ngAfterViewChecked() {
    this.checkForTrialMessage();
  }

  private updateClass() {
    this.contentClass = {
      'content content--padding': !this.isFullWidth,
      'dashboard-content': this.isFullWidth
    };
  }

  checkForTrialMessage() {
    const trialMessageLength = document.querySelectorAll('.js-trial-message').length;
    if(trialMessageLength) {
      document.querySelector('body').classList.add('trial-message-wrapper');
    } else {
      document.querySelector('body').classList.remove('trial-message-wrapper');
    }
  }
}

