/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./footer.component";
import * as i4 from "@angular/router";
import * as i5 from "../../services/white-label.service";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
function View_FooterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "footer", [["class", "footer"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "custom-position": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 13, "ul", [["class", "footer__nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" \u00A9 ", " ", ""])), i1.ɵppd(8, 2), (_l()(), i1.ɵeld(9, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToTermsOfService() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Terms and Conditions"])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToPrivacyPolicy() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Privacy Policy"])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToPaymentPolicy() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Payment Policy"])), (_l()(), i1.ɵeld(18, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleContactUs() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Help"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "footer"; var currVal_1 = _ck(_v, 3, 0, _co.customPosition); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.env == null) ? null : ((_co.env.branding == null) ? null : _co.env.branding.company_name)); var currVal_3 = i1.ɵunv(_v, 7, 1, _ck(_v, 8, 0, i1.ɵnov(_v.parent, 0), _co.date, "yyyy")); _ck(_v, 7, 0, currVal_2, currVal_3); }); }
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.footerDefault; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i3.FooterComponent, [i4.Router, i5.WhiteLabelService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentNgFactory = i1.ɵccf("app-footer", i3.FooterComponent, View_FooterComponent_Host_0, { customPosition: "customPosition" }, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
