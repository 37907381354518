<table class="table table--sm table--last-row-border">
  <thead>
  <tr class="mat-row">
    <th class="mat-header-cell"><mat-checkbox></mat-checkbox></th>
    <th class="mat-header-cell txt--left"><strong>Location</strong></th>
    <th class="mat-header-cell txt--right"><strong>Actions</strong></th>
  </tr>
  </thead>
  <tbody>
  <tr class="mat-row" *ngFor="let i of [0,1,2]">

    <td class="mat-cell cdk-column-id">
      <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '25px', height: '25px', 'border-radius': '3px', 'margin': '0' }"></ngx-skeleton-loader>
    </td>

    <td class="mat-cell">
      <div class="d-flex align-items-center">
        <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '30px', height: '30px' }"></ngx-skeleton-loader>
        <div>
          <div>
             <ngx-skeleton-loader count="1" [theme]="{ width: '60px', height: '30px'}"></ngx-skeleton-loader>
          </div>
          <ngx-skeleton-loader count="1" [theme]="{ width: '200px', height: '20px'}"></ngx-skeleton-loader>
        </div>
      </div>
    </td>

    <td class="mat-cell mat-column-actions txt--right">
      <ngx-skeleton-loader count="4" appearance="circle" [theme]="{ width: '30px', height: '30px', 'margin-bottom': '0', 'border-radius': '5px'}"></ngx-skeleton-loader>
    </td>
  </tr>
  </tbody>
</table>
