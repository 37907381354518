import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { LocalPostsComponent } from '../local-posts/local-posts.component';
import { ModalService } from 'src/app/services/modal.service';
import { AlertType } from 'src/app/components/alert.component';
import { LocationService } from 'src/app/services/location.service';
import { AuthService } from 'src/app/services/auth.service';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-dashboard-posts',
  templateUrl: './dashboard-posts.component.html',
  styleUrls: ['./dashboard-posts.component.scss']
})
export class DashboardPostsComponent implements OnInit {
  @ViewChild('localpost', { static: false }) localpost: LocalPostsComponent;
  public accountId: string = '';
  public locationId: string;
  public isRefreshing = false;
  public isPurging = false;
  public isNewPost = false;
  public location = {};

  constructor(
    private _route: ActivatedRoute,
    private _snack: SnackbarService,
    private _modalService: ModalService,
    private _router: Router,
    private _locationS: LocationService,
    private _accountS: AccountService,
    private _authS: AuthService
  ) {}

  ngOnInit() {
    this.isNewPost = this._router.url?.includes('post-management');
    this.accountId = this._route.snapshot.parent.params.accountId;
    this.locationId = this._route.snapshot.parent.params.locationId;

    this.getLocation();
  }

  getLocation() {
    forkJoin([
      this._locationS.fetchLocation(this._authS.session.gid, this.accountId, this.locationId),
      this._accountS.get(this._authS.session.gid, this.accountId)
    ]).subscribe(
      res => {
        this.location = [{
          "accountId": this.accountId,
          "accountName": res[1]?.accountName,
          "gid": this._authS.session.gid,
          "locations": [{
            "accountId": this.accountId,
            "locationId": this.locationId,
            "locationName": res[0]?.locationName,
            "address": res[0]?.location?.address,
            "serviceArea": res[0]?.location?.serviceArea
          }]
        }];
      }
    );
  }

  async handleRefreshButton() {
    try {
      this.isRefreshing = true;
      await this.localpost.refresh();
      this._snack.openSuccess('Finished refreshing! ', 2000);
      this.isRefreshing = false;
    } catch (error) {
      this.isRefreshing = false;
      this._snack.openError('Refresh request fail, try again later', 2000);
    }
  }

  openModalConfirm() {
    const dialogRef = this._modalService.openConfirmModal('Heads Up', 'Purging will delete any posts that are not currently on GBP',
    res => {
      if (res) {
        this.handlePurgeButton();
      }
    }, AlertType.INFO);
  }

  async handlePurgeButton() {
    try {
      this.isPurging = true;
      await this.localpost.purge();
      this._snack.openSuccess('Finished purging! ', 2000);
      this.isPurging = false;
    } catch (error) {
      this.isPurging = false;
      this._snack.openError('Purge request fail, try again later', 2000);
    }
  }
}
