import { __awaiter, __generator } from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { GoogleAccountService } from '../services/google-account.service';
import { GoogleService } from '../services/google.service';
import { SnackbarService } from '../services/snackbar.service';
import { AuthService } from '../services/auth.service';
import { ReviewsService } from '../services/reviews.service';
import { takeUntil } from 'rxjs/operators';
var AutoReplyReviewErrorComponent = /** @class */ (function () {
    function AutoReplyReviewErrorComponent(data, dialogRef, googleAccountService, google, snack, auth, reviewService) {
        this.data = data;
        this.dialogRef = dialogRef;
        this.googleAccountService = googleAccountService;
        this.google = google;
        this.snack = snack;
        this.auth = auth;
        this.reviewService = reviewService;
        this.review = {};
        this.subsManager$ = new Subject();
        this.user = this.auth.session;
        this.setUp();
        this.review = data.data;
    }
    AutoReplyReviewErrorComponent.prototype.ngOnDestroy = function () {
        this.subsManager$.next(true);
        this.subsManager$.complete();
    };
    AutoReplyReviewErrorComponent.prototype.ngOnInit = function () {
    };
    AutoReplyReviewErrorComponent.prototype.setUp = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    AutoReplyReviewErrorComponent.prototype.getError = function (error) {
        if (error) {
            switch (error) {
                case 'INVALID_ARGUMENT':
                    return error.message + " " + error.details[0].errorDetails[0].message;
                default:
                    return error.message;
            }
        }
        return error.errorMessage;
    };
    AutoReplyReviewErrorComponent.prototype.openReauth = function (element) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.onAddNewAccount(element)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AutoReplyReviewErrorComponent.prototype.replyReview = function (element) {
        return __awaiter(this, void 0, void 0, function () {
            var r, accountId;
            var _this = this;
            return __generator(this, function (_a) {
                r = this.review;
                accountId = r.name.split('/')[1];
                this.reviewService.reply_with_errors(accountId, r.locationId, r.reviewId, r.comment, r.protocolId, r.replyId).toPromise()
                    .then(function (d) {
                    _this.dialogRef.close();
                });
                return [2 /*return*/];
            });
        });
    };
    AutoReplyReviewErrorComponent.prototype.onAddNewAccount = function (element) {
        return __awaiter(this, void 0, void 0, function () {
            var data, oauth_1, popupTick_1, e_1, message;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.googleAccountService.onLoadAll
                            .pipe(takeUntil(this.subsManager$))
                            .subscribe(function (e) {
                            if (e.success !== true) {
                                var msg = '';
                                switch (e.details.status) {
                                    case 901:
                                        msg = 'Oops! There was an error in the application ';
                                        break;
                                    case 401:
                                        msg = 'Google said you are not authorized! - Try again';
                                        break;
                                    default:
                                        msg = 'Some odd error happened. Try again.';
                                }
                                return _this.snack.openError(msg, 4000);
                            }
                            else {
                                var accountId = _this.data.name.split('/')[1];
                                _this.reviewService.reply_with_errors(accountId, _this.review.locationId, _this.review.reviewId, _this.review.comment, _this.review.protocolId, _this.review.replyId).toPromise()
                                    .then(function (d) {
                                    if (d == '') {
                                        _this.snack.openInfo("The reply can't send, please edit it and send again", 2000);
                                    }
                                    _this.snack.openInfo("The reply can't send, please edit it and send again", 2000);
                                    _this.dialogRef.close();
                                });
                            }
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        this.snack.openInfo("A tab to authenticate with Google will open. If you don't see it, check your pop-up blocker settings", 2000);
                        return [4 /*yield*/, this.google.authenticate(element.gid || this.user.gid, this.user.uid, element.accountId)];
                    case 2:
                        data = _a.sent();
                        oauth_1 = window.open(data, '_blank');
                        popupTick_1 = setInterval(function () {
                            if (oauth_1.closed) {
                                clearInterval(popupTick_1);
                                _this.googleAccountService.loadAll();
                            }
                        }, 1000);
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        message = 'There was an error with the GBP Authentication service';
                        return [2 /*return*/, this.snack.openError(message, 4000)];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return AutoReplyReviewErrorComponent;
}());
export { AutoReplyReviewErrorComponent };
