import { __awaiter, __generator } from "tslib";
import { MatDialog } from "@angular/material/dialog";
// app
import { AuthService } from '../services/auth.service';
import { GroupService } from '../services/group.service';
import { SpinnerService } from '../services/spinner.service';
import { LocationService } from '../services/location.service';
import { SubscriptionService } from '../services/subscription.service';
var FeaturedDatePickerComponent = /** @class */ (function () {
    function FeaturedDatePickerComponent(data, dialog, groupService, auth, spinnerService, locationService, subscriptionService) {
        var _this = this;
        var _a, _b;
        this.data = data;
        this.dialog = dialog;
        this.groupService = groupService;
        this.auth = auth;
        this.spinnerService = spinnerService;
        this.locationService = locationService;
        this.subscriptionService = subscriptionService;
        this.subscription$ = this.auth.subscription$;
        this.OPTS_DEFAULT = { config: { width: '470px',
                height: '420px' } };
        this.auth.subscription$.subscribe(function () {
            _this.spinnerService.loading$.next(false);
        });
        this.accountId = (_a = this.data) === null || _a === void 0 ? void 0 : _a.accountId;
        this.locationId = (_b = this.data) === null || _b === void 0 ? void 0 : _b.locationId;
        this.subscription$ = this.auth.subscription$;
        this.subscription$.subscribe(function (subscription) { return _this.subscription = subscription; });
    }
    FeaturedDatePickerComponent.prototype.dismissModal = function () {
        var _this = this;
        var today = new Date();
        var tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        this.spinnerService.loading$.next(true);
        this.groupService.updateByDocId(this.auth.session.gid, { 'dismissModalDatePicker': tomorrow })
            .then(function () {
            _this.groupService.getByDocId(_this.auth.session.gid).toPromise().then(function (group) {
                _this.auth.updateGroup(group);
                _this.auth.processSubscription();
            })
                .catch(function () {
                _this.spinnerService.loading$.next(false);
            });
        })
            .catch(function () {
            _this.spinnerService.loading$.next(false);
        });
    };
    FeaturedDatePickerComponent.prototype.openUpgrade = function () {
        return __awaiter(this, void 0, void 0, function () {
            var locRef, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        locRef = { locationId: this.locationId,
                            accountId: this.accountId };
                        _a = this;
                        return [4 /*yield*/, this.locationService.getRef(this.auth.session.gid, this.accountId, this.locationId).toPromise()];
                    case 1:
                        _a.location = _c.sent();
                        return [4 /*yield*/, this.subscriptionService.flowChangeLocationsPlan(this.subscription, [locRef])];
                    case 2:
                        if (!_c.sent()) return [3 /*break*/, 4];
                        // TODO: See comment above
                        _b = this;
                        return [4 /*yield*/, this.locationService.getRef(this.auth.session.gid, this.accountId, this.locationId).toPromise()];
                    case 3:
                        // TODO: See comment above
                        _b.location = _c.sent();
                        _c.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    FeaturedDatePickerComponent.prototype.isMember = function () {
        return this.auth.isMember;
    };
    return FeaturedDatePickerComponent;
}());
export { FeaturedDatePickerComponent };
