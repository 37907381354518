<div class="content__header d-flex justify-content-between align-items-center">
	<h2 class="m--0"><strong>Accounts</strong></h2>
	<button *ngIf="auth.isAdmin" (click)="onAddNewAccount()" mat-flat-button color="accent" class="btn btn--icon btn--md" id="addNewListingBtn">
		Add New Profiles
	</button>
</div>
<div class="content__body box">

	<!--if no accounts are linked-->
	<div [hidden]="(accountService.loading || (accounts | async)?.length != 0)">
		<app-no-data message="Connect your Google Business Profiles"></app-no-data>
	</div>

	<!--if there are accounts-->
	<div class="table--shadow" [hidden]="!((accounts | async)?.length)">
		<app-skeleton-accounts
				*ngIf="accountService.loading && (accounts | async)?.length === 0"></app-skeleton-accounts>
		<table mat-table [dataSource]="accounts | async" class="table">

			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef><strong>Name</strong></th>
				<td mat-cell *matCellDef="let element"
					(click)="goLocation(element)">
					<div class="cell__content d-flex justify-content-start align-items-center">
						<span class="circle circle--green" >{{ element.accountName | slice:0:1 }} </span>
						<span matTooltip=" {{'This account is connected with: ' + element?.googleAuthEmailAddress}}" matTooltipPosition="above"
						matTooltipClass="mat-tooltip--override" class="cursor--pointer">{{ element.accountName }}</span>
					</div>
				</td>
			</ng-container>

			<ng-container matColumnDef="locations">
				<th mat-header-cell *matHeaderCellDef><strong>Locations</strong></th>
				<td mat-cell *matCellDef="let element">
					<div class="cell__content">
						<button mat-flat-button color="accent"
								class="d-flex align-items-center btn btn--badge btn--badge--sm txt--uppercase txt--xs">
							{{ element.locationsCount || 0 }} locations
						</button>
					</div>
				</td>
			</ng-container>

			<ng-container matColumnDef="actions">
				<th mat-header-cell *matHeaderCellDef class="txt--right"><strong>Actions</strong></th>
				<td mat-cell *matCellDef="let element" class="txt--right">
					<div class="cell__content">
            <button *ngIf="!element?.receiveNotifications" mat-flat-button  [color]="'primary'" class="btn btn-icon mr--10"
                    (click)="enableNotifications(element)">
              <em class="fas fa-bell-slash"></em>
            </button>
            <button *ngIf="!element?.gauthStatus?.isValid" [matTooltip]="'The Google authorization for this location is invalid. Click here to re-authorize this account'"
            matTooltipClass="tooltip tooltip--white" mat-flat-button  [color]="'warn'" class="btn btn-icon mr--10"
                    (click)="openReauth(element).then()">
              <em class="fab fa-google"></em>
            </button>
						<button mat-flat-button
							(click)="refresh(element)" color="accent" class="btn btn-icon mr--10">
							<em [id]="'spin-'+element.accountId" class="fas fa-redo"></em>
						</button>
						<span class="alternate-theme">
                            <button (click)="onDeleteAccount(element)" mat-flat-button color="primary"
									class="btn btn-icon">
                               <i class="far fa-trash-alt"></i>
                            </button>
                        </span>
					</div>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
		</table>
		<app-paginator [pagination]="pagination" (reload)="handleReload($event)" [options]="[25,50,100,200,500]"></app-paginator>

	</div>

</div>
