import { __awaiter, __generator } from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';
import { GoogleService } from '../../../services/google.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { defaultMenu, convertCusine } from '../../../constants/google/menuList';
import { ModalFormItemComponent } from './modal-form-item/modal-form-item.component';
import { ModalFormSectionComponent } from './modal-form-section/modal-form-section.component';
import { ModalFormMenuComponent } from './modal-form-menu/modal-form-menu.component';
var MenuLocationComponent = /** @class */ (function () {
    function MenuLocationComponent(dialog, googleS, snackS) {
        this.dialog = dialog;
        this.googleS = googleS;
        this.snackS = snackS;
        this.foodMenuList = Object.assign([], defaultMenu);
        this.onlyShow = false;
        this.bulk = false;
        this.validResult = new EventEmitter(false);
        this.loading = false;
        this.showMoreDescriptionMessage = false;
        this.showMoreMessage = false;
    }
    MenuLocationComponent.prototype.ngOnInit = function () { };
    MenuLocationComponent.prototype.addSection = function (foodMenuIndex, sectionIndex) {
        var _this = this;
        if (foodMenuIndex === void 0) { foodMenuIndex = 0; }
        if (sectionIndex === void 0) { sectionIndex = null; }
        var _a;
        var data = {
            type: this.type,
            priceIndex: this.index,
            foodMenuIndex: foodMenuIndex
        };
        if (sectionIndex !== undefined) {
            data.section = (_a = this.foodMenuList[foodMenuIndex]) === null || _a === void 0 ? void 0 : _a.sections[sectionIndex];
            data.sectionIndex = sectionIndex;
        }
        var dialogRef = this.dialog.open(ModalFormSectionComponent, {
            width: '680px',
            panelClass: 'overflow--visible',
            data: data
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().take(1).subscribe(function (result) {
            if (result) {
                if (result.action === 'added') {
                    _this.foodMenuList[foodMenuIndex].sections.push(result.item);
                }
                else if (result.action === 'update') {
                    _this.foodMenuList[foodMenuIndex].sections[sectionIndex].labels = result.item.labels;
                }
                else if (result.action === 'remove') {
                    _this.foodMenuList[foodMenuIndex].sections.splice(sectionIndex, 1);
                }
            }
        });
    };
    MenuLocationComponent.prototype.setDataMenu = function (foodMenu) {
        this.foodMenuList = Object.assign([], foodMenu);
    };
    MenuLocationComponent.prototype.getResult = function () {
        if (this.foodMenuList.length === 0 || this.foodMenuList[0].sections.length === 0) {
            this.validResult.emit(false);
            return;
        }
        this.validResult.emit(true);
        return this.foodMenuList;
    };
    MenuLocationComponent.prototype.convertKeysCuisines = function (array) {
        return array.map(function (c) { return convertCusine(c); });
    };
    MenuLocationComponent.prototype.addItem = function (foodMenuIndex, sectionIndex, itemIndex) {
        var _this = this;
        if (foodMenuIndex === void 0) { foodMenuIndex = 0; }
        if (itemIndex === void 0) { itemIndex = null; }
        var _a;
        if (this.onlyShow) {
            return;
        }
        var data = {
            foodMenuIndex: foodMenuIndex,
            sectionIndex: sectionIndex,
            typePrice: 'fixed'
        };
        if (itemIndex !== null) {
            var menuItem = (_a = this.foodMenuList[foodMenuIndex]) === null || _a === void 0 ? void 0 : _a.sections[sectionIndex].items[itemIndex];
            data.menuItems = [menuItem];
            if (menuItem.attributes) {
                if (menuItem.attributes.price) {
                    if (Number(menuItem.attributes.price.units) === 0)
                        data.typePrice = 'free';
                }
                else {
                    data.typePrice = 'noPrice';
                }
            }
            else {
                data.typePrice = 'noPrice';
            }
        }
        var dialogRef = this.dialog.open(ModalFormItemComponent, {
            width: '680px',
            panelClass: 'overflow--visible',
            data: data
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                if (result.action === 'added') {
                    _this.foodMenuList[foodMenuIndex].sections[sectionIndex].items.push(result.item[0]);
                }
                else if (result.action === 'update') {
                    _this.foodMenuList[foodMenuIndex].sections[sectionIndex].items[itemIndex] = result.item[0];
                }
                else if (result.action === 'remove') {
                    _this.foodMenuList[foodMenuIndex].sections[sectionIndex].items.splice(itemIndex, 1);
                }
            }
        });
    };
    MenuLocationComponent.prototype.editMenu = function (foodMenuIndex) {
        var _this = this;
        if (foodMenuIndex === void 0) { foodMenuIndex = 0; }
        var foodMenu = this.foodMenuList[foodMenuIndex];
        var dialogRef = this.dialog.open(ModalFormMenuComponent, {
            width: '680px',
            panelClass: 'overflow--visible',
            data: {
                foodMenuIndex: foodMenuIndex,
                foodMenu: foodMenu
            }
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().take(1).subscribe(function (result) {
            if (result) {
                if (result.action === 'updated') {
                    _this.foodMenuList[foodMenuIndex].labels = result.item.labels;
                    _this.foodMenuList[foodMenuIndex].cuisines = result.item.cuisines;
                }
            }
        });
    };
    MenuLocationComponent.prototype.parseMoney = function (units, nanos) {
        if (units === undefined && nanos === undefined) {
            return '0';
        }
        else if (units !== undefined && nanos === undefined) {
            return units + ".0";
        }
        else if (units === undefined && nanos !== undefined) {
            return "0." + nanos;
        }
        else {
            return units + "." + nanos;
        }
    };
    Object.defineProperty(MenuLocationComponent.prototype, "MenuList", {
        get: function () {
            return this.foodMenuList;
        },
        enumerable: true,
        configurable: true
    });
    MenuLocationComponent.prototype.toggleMessage = function (i) {
        this.showMoreMessage = !this.showMoreMessage;
        this.showMoreMessageIndex = i;
        return;
    };
    MenuLocationComponent.prototype.apply = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        return [4 /*yield*/, this.googleS.updateMenuList(this.accountId, this.locationId, this.priceList)
                                .then(function (data) {
                                if (data.success)
                                    _this.snackS.openSuccess(data.message, 2000);
                                else
                                    _this.snackS.openError(data.message, 2000);
                                _this.loading = false;
                            })
                                .catch(function () { return _this.loading = false; })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return MenuLocationComponent;
}());
export { MenuLocationComponent };
