<div class="container--full container--padding-lg" id="charts-container">
  <app-reports-filter
    [reportName]="reportName"
    [locations]="reportDataPicker.locations"
    [aggregation]="report?.aggregation"
    [comparisonMetrics]="metrics"
    [selectedDate]="selectDate" 
    [selectedDateB]="selectDateB"
    (dataPicker)="handleDataPicker($event)"
    [isShared]="isShared"
    (export)="exportCSV($event)"
    [lockDates]="lockDates"
    [type]="reportType"
    (expand)="expandPanel($event)"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [subscribeToDatepicker]="isDatePickerFirstOpened"
    [showTooltipsOnDisabledDates]="showTooltipsOnDisabledDates"
    [hasDateLimitsInfo]="true">
  </app-reports-filter>

  <mat-progress-bar mode="indeterminate" *ngIf="isProgressCSV"></mat-progress-bar><!-- //NOSONAR -->

  <h2><strong>{{reportName|uppercase}}</strong></h2>

  <div class="box box--rounded box--shadow bg--white override-error mb--30">
    <div class="box__header">
      <h2 class="txt--capitalize">
        <div *ngIf="(locations && locations.length === 1)">
          <p class="m--0 txt--black txt--md"><strong>{{locations[0]?.locationName}}</strong></p>
          <gmb-address class="txt--lightgray m--0" 
            *ngIf="locations[0]?.location?.address || locations[0]?.location?.serviceArea"
            [address]="locations[0]?.location?.address" 
            [serviceAreas]="locations[0]?.location?.serviceArea?.places?.placeInfos">
          </gmb-address>
        </div>
        <strong *ngIf="(locations && locations.length > 1)">All Locations</strong>
        <strong *ngIf="!locations">Loading...</strong>
      </h2>
    </div>
    <div class="box__content box--padding-xs">
      <app-grade-comparison
        [viewModeChecked]="viewModeChecked"
        [locations]="reportDataPicker?.locations"
        [metrics]='metricsNames'
        [dataPicker]="dataPicker"
        [columns]="3"
        [type]="'report'"
        [reportType]="reportType"
        [refreshFilter]="filterRefresh.asObservable()"
        [fromReportsScreen]="true"
        [reportId]="reportId"
      *ngIf="dataPicker"></app-grade-comparison>
    </div>
  </div>


  <div class="box box--rounded box--shadow bg--white override-error" *ngIf="!locations || (locations && locations.length > 1)">
    <div class="box__header d-flex justify-content-between alternate-theme">
      <h2 class="txt--capitalize"><strong>Locations</strong></h2>
      <button mat-flat-button color="primary" class="btn btn--xs" (click)="showAll()" *ngIf="!panelExpand">Show All</button>
      <button mat-flat-button color="primary" class="btn btn--xs" (click)="closeAll()" *ngIf="panelExpand">Close All</button>
    </div>
    <div class="box__content">

      <!--skeleton loader for locations-->
      <mat-accordion displayMode="flat" class="no-shadow no-hover" *ngIf="!locations">
        <mat-expansion-panel disabled="true" class="box" *ngFor="let i of [0,1,2]" hideToggle>
          <mat-expansion-panel-header class="border-bottom" collapsedHeight="111px" expandedHeight="111px">
            <mat-panel-title class="flex-column">
              <ngx-skeleton-loader count="1"
                                   [theme]="{ width: '80px', height: '20px', display: 'block' }"></ngx-skeleton-loader>
              <ngx-skeleton-loader count="1"
                                   [theme]="{ width: '250px', height: '15px', display: 'block', 'margin-top': '5px' }"></ngx-skeleton-loader>
              <ngx-skeleton-loader count="3"
                                   [theme]="{ width: '50px', height: '25px','margin':'5px 5px 0 0' }"></ngx-skeleton-loader>
            </mat-panel-title>
            <mat-panel-description class="align-items-center justify-content-end">
              <ngx-skeleton-loader count="1"
                                   [theme]="{ width: '30px', height: '30px', display: 'block' }"></ngx-skeleton-loader>
            </mat-panel-description>
          </mat-expansion-panel-header>
        </mat-expansion-panel>
      </mat-accordion>

      <mat-accordion displayMode="flat" *ngIf="locations" class="no-shadow no-hover" [multi]="panelExpand">
        <mat-expansion-panel class="box" *ngFor="let l of locations; index as i" hideToggle [expanded]="panelExpand">
          <mat-expansion-panel-header class="border-bottom" collapsedHeight="111px" expandedHeight="111px">
            <mat-panel-title class="flex-column">
              <p class="m--0 txt--black txt--md"><strong>{{l?.locationName}}</strong></p>
              <gmb-address class="txt--lightgray m--0" [address]="l?.location?.address" [serviceAreas]="l?.location?.serviceArea?.places?.placeInfos"></gmb-address>
              <div class="d-flex mt--5" *ngIf="!isShared">
                <a [routerLink]="locationUrl(l,'insights')" target="_blank"
                   matTooltip="View Insights"
                   matTooltipClass="tooltip tooltip--blue"
                   matTooltipPosition="above"
                   data-html2canvas-ignore="true"
                   class="btn btn--flex btn--blue btn-icon--xl mr--5">
                  <img src="/assets/images/icons/graph.png" alt="Graph Icon">
                </a>
                <a [routerLink]="locationUrl(l,'reviews')" target="_blank"
                   matTooltip="See Reviews"
                   matTooltipClass="tooltip tooltip--orange"
                   matTooltipPosition="above"
                   data-html2canvas-ignore="true"
                   class="btn btn--flex btn--orange btn-icon--xl mr--5">
                  <img src="/assets/images/icons/star-empty.png" alt="Star Icon">
                </a>
                <a *ngIf="l.location.metadata" [href]="l.location.metadata.mapsUrl" target="_blank"
                   matTooltip="See Location on Map"
                   matTooltipClass="tooltip tooltip--green"
                   matTooltipPosition="above"
                   data-html2canvas-ignore="true"
                   class="btn btn--flex btn--green btn-icon--xl">
                  <img src="/assets/images/icons/pin-green.png" alt="Pin Icon">
                </a>
              </div>
            </mat-panel-title>
            <mat-panel-description class="align-items-center justify-content-end">
              <mat-icon><span class="mat-expansion-panel-icon" data-html2canvas-ignore="true"></span></mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <app-grade-comparison
              [viewModeChecked]="viewModeChecked"
              [locations]="[l]"
              [metrics]='metricsNames'
              [dataPicker]="dataPicker"
              [columns]="3"
              [type]="'report'"
              [expand]="true"
              [refreshFilter]="filterRefresh.asObservable()"
              [fromReportsScreen]="true"
              [reportId]="reportId"
              *ngIf="dataPicker">

            </app-grade-comparison>
          </ng-template>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
