import { __assign, __values } from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MatSlideToggleChange } from '@angular/material';
import { AlertType } from 'src/app/components/alert.component';
import { ModalService } from 'src/app/services/modal.service';
import { WEEK_DAYS } from '../../constants/google/week-days';
import { DatesService } from '../../services/dates.service';
import { LocationEditService } from '../../services/location-edit.service';
import { LocationService } from '../../services/location.service';
var ModalRegularHoursComponent = /** @class */ (function () {
    function ModalRegularHoursComponent(dialogRef, data, dateS, locationEditS, locationS, modalService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.dateS = dateS;
        this.locationEditS = locationEditS;
        this.locationS = locationS;
        this.modalService = modalService;
        this.title = 'Set Regular Hours';
        this.save = true;
        this.clickApply = false;
        this.hasSpecialHours = false;
        this.hasMoreHours = false;
        this.hasErrorSpecialHours = false;
        this.openPeriods = [];
        this.bulk = false;
        this.validResult = new EventEmitter();
        this.originalOrder = function (a, b) { return 0; };
        this.checkedList = [];
        if (data) {
            if (data.weekDays) {
                this.periods = JSON.parse(JSON.stringify(data.weekDays));
                if (this.periods.length === 0) {
                    this.periods = this.locationS.verifyOpen(this.periods);
                }
            }
            else {
                this.periods = this.locationS.verifyOpen(this.periods);
            }
        }
        else
            this.periods = this.locationS.verifyOpen(this.periods);
        this.formatPeriodArray();
        this.openPeriods = JSON.parse(JSON.stringify(this.periods)).filter(function (el) { return el.open; });
        this.regularHoursList = this.dateS.loadHoursDropdown();
    }
    ModalRegularHoursComponent.prototype.ngOnInit = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        this.title = ((_a = this.data) === null || _a === void 0 ? void 0 : _a.title) ? (_b = this.data) === null || _b === void 0 ? void 0 : _b.title : this.title;
        this.save = ((_c = this.data) === null || _c === void 0 ? void 0 : _c.save) != null ? (_d = this.data) === null || _d === void 0 ? void 0 : _d.save : this.save;
        this.hasSpecialHours = ((_e = this.data) === null || _e === void 0 ? void 0 : _e.hasSpecialHours) != null ? (_f = this.data) === null || _f === void 0 ? void 0 : _f.hasSpecialHours : false;
        this.hasMoreHours = ((_g = this.data) === null || _g === void 0 ? void 0 : _g.hasMoreHours) != null ? (_h = this.data) === null || _h === void 0 ? void 0 : _h.hasMoreHours : false;
    };
    ModalRegularHoursComponent.prototype.apply = function () {
        var _this = this;
        this.clickApply = true;
        this.hasErrorSpecialHours = false;
        if (this.isValidForm()) {
            var hasChanges = this.hasChanges(this.periods.filter(function (el) { return el.open; }));
            var periodsOpen = JSON.parse(JSON.stringify(this.periods));
            periodsOpen = periodsOpen.filter(function (period) { return period.open === true; });
            periodsOpen.map(function (period) {
                var _a, _b, _c;
                period.openTime = _this.getOpenTime(period);
                period.openTime = _this.dateS.hours12To24((_a = period) === null || _a === void 0 ? void 0 : _a.openTime);
                period.closeTime = _this.dateS.hours12To24((_b = period) === null || _b === void 0 ? void 0 : _b.closeTime);
                if ((_c = period) === null || _c === void 0 ? void 0 : _c.open)
                    delete period.open;
                return period;
            });
            if (this.save && hasChanges) {
                if (!this.hasSpecialHours && !this.hasMoreHours || periodsOpen.length > 0) {
                    this.locationEditS.locationEdit.regularHours = { periods: periodsOpen };
                    if (this.hasConflictingHours(this.periods)) {
                        this.modalService.openAlertModal('Please verify your input', 'The hours you\'ve entered overlap and conflict with one another. Please review your entry and try again.', AlertType.ERROR);
                        return;
                    }
                    if (this.hasOverlaps(this.periods)) {
                        this.modalService.openConfirmModal('Are you sure?', 'The hours you\'ve selected overlap from one day to the next. If this was intentional, click Confirm, otherwise click Cancel to review.', function (result) {
                            if (result) {
                                _this.locationEditS.setAndUpdate().toPromise().then(function () {
                                    _this.dialogRef.close(_this.periods);
                                });
                            }
                        });
                    }
                    else {
                        this.locationEditS.setAndUpdate().toPromise().then(function () {
                            _this.dialogRef.close(_this.periods);
                        });
                    }
                }
                else {
                    this.hasErrorSpecialHours = true;
                }
            }
            else {
                var data = hasChanges ? periodsOpen : null;
                this.dialogRef.close(data);
            }
        }
    };
    ModalRegularHoursComponent.prototype.hasConflictingHours = function (periods) {
        var _this = this;
        var dummyDate = '1/1/2020';
        var result = false;
        var previousPeriod;
        if (periods && periods.length > 1) {
            periods.forEach(function (period) {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j;
                if (period &&
                    previousPeriod && ((_a = period) === null || _a === void 0 ? void 0 : _a.closeTime) && ((_b = previousPeriod) === null || _b === void 0 ? void 0 : _b.openTime) && ((_c = previousPeriod) === null || _c === void 0 ? void 0 : _c.closeTime) &&
                    ((_d = period) === null || _d === void 0 ? void 0 : _d.openTime) !== ((_e = previousPeriod) === null || _e === void 0 ? void 0 : _e.closeTime) &&
                    new Date(dummyDate + ' ' + ((_f = previousPeriod) === null || _f === void 0 ? void 0 : _f.openTime)).getTime() > new Date(dummyDate + ' ' + ((_g = previousPeriod) === null || _g === void 0 ? void 0 : _g.closeTime)).getTime() &&
                    new Date(dummyDate + ' ' + ((_h = previousPeriod) === null || _h === void 0 ? void 0 : _h.closeTime)).getTime() > new Date(dummyDate + ' ' + ((_j = period) === null || _j === void 0 ? void 0 : _j.openTime)).getTime() &&
                    _this.areContiguousDays(period, previousPeriod)) {
                    result = true;
                }
                previousPeriod = period;
            });
        }
        return result;
    };
    ModalRegularHoursComponent.prototype.areContiguousDays = function (period, previousPeriod) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
        if (((_a = period) === null || _a === void 0 ? void 0 : _a.openDay) === WEEK_DAYS[0] && ((_b = previousPeriod) === null || _b === void 0 ? void 0 : _b.openDay) === WEEK_DAYS[6])
            return true;
        if (((_c = period) === null || _c === void 0 ? void 0 : _c.openDay) === WEEK_DAYS[1] && ((_d = previousPeriod) === null || _d === void 0 ? void 0 : _d.openDay) === WEEK_DAYS[0])
            return true;
        if (((_e = period) === null || _e === void 0 ? void 0 : _e.openDay) === WEEK_DAYS[2] && ((_f = previousPeriod) === null || _f === void 0 ? void 0 : _f.openDay) === WEEK_DAYS[1])
            return true;
        if (((_g = period) === null || _g === void 0 ? void 0 : _g.openDay) === WEEK_DAYS[3] && ((_h = previousPeriod) === null || _h === void 0 ? void 0 : _h.openDay) === WEEK_DAYS[2])
            return true;
        if (((_j = period) === null || _j === void 0 ? void 0 : _j.openDay) === WEEK_DAYS[4] && ((_k = previousPeriod) === null || _k === void 0 ? void 0 : _k.openDay) === WEEK_DAYS[3])
            return true;
        if (((_l = period) === null || _l === void 0 ? void 0 : _l.openDay) === WEEK_DAYS[5] && ((_m = previousPeriod) === null || _m === void 0 ? void 0 : _m.openDay) === WEEK_DAYS[4])
            return true;
        if (((_o = period) === null || _o === void 0 ? void 0 : _o.openDay) === WEEK_DAYS[6] && ((_p = previousPeriod) === null || _p === void 0 ? void 0 : _p.openDay) === WEEK_DAYS[5])
            return true;
        return false;
    };
    ModalRegularHoursComponent.prototype.hasOverlaps = function (periods) {
        var result = false;
        var previousPeriod;
        if (periods && periods.length > 1) {
            periods.forEach(function (period) {
                var _a, _b, _c, _d;
                if (period &&
                    previousPeriod && ((_a = period) === null || _a === void 0 ? void 0 : _a.closeTime) && ((_b = previousPeriod) === null || _b === void 0 ? void 0 : _b.openTime) &&
                    ((_c = previousPeriod) === null || _c === void 0 ? void 0 : _c.closeTime) === ((_d = period) === null || _d === void 0 ? void 0 : _d.openTime)) {
                    result = true;
                }
                previousPeriod = period;
            });
        }
        return result;
    };
    ModalRegularHoursComponent.prototype.hasChanges = function (periodsOpen) {
        return JSON.stringify(this.openPeriods) != JSON.stringify(periodsOpen);
    };
    ModalRegularHoursComponent.prototype.getOpenTime = function (hour) {
        return hour.openTime == '12:00 AM' && hour.closeTime == '12:00 AM' ? '24:00 AM' : hour.openTime;
    };
    ModalRegularHoursComponent.prototype.isValidForm = function () {
        var _this = this;
        var periodsOpen = JSON.parse(JSON.stringify(this.periods));
        var findError = periodsOpen.find(function (el) { return (el.openTime == '' || el.closeTime == '') && el.open; });
        if (!findError) {
            findError = periodsOpen.map(function (el) { return _this.areAcceptedHours(el); }).find(function (f) { return f == true; });
        }
        return findError ? false : true;
    };
    ModalRegularHoursComponent.prototype.areAcceptedHours = function (hours) {
        var _a, _b, _c, _d, _e, _f;
        var condition = false;
        if (hours.open) {
            condition = (hours.open &&
                (((_a = hours) === null || _a === void 0 ? void 0 : _a.openTime) == '' || ((_b = hours) === null || _b === void 0 ? void 0 : _b.closeTime) == '' ||
                    ((_c = hours) === null || _c === void 0 ? void 0 : _c.openTime) == '12:00 AM' && ((_d = hours) === null || _d === void 0 ? void 0 : _d.closeTime) == '12:00 AM' ||
                    ((_e = hours) === null || _e === void 0 ? void 0 : _e.openTime) != ((_f = hours) === null || _f === void 0 ? void 0 : _f.closeTime))
                ? false : true);
        }
        return condition;
    };
    ModalRegularHoursComponent.prototype.ngOnDestroy = function () {
        this.data = {};
    };
    ModalRegularHoursComponent.prototype.validate = function (e) {
        this.validResult.emit(e !== '');
    };
    ModalRegularHoursComponent.prototype.getResult = function () {
        var _this = this;
        var periodsOpen = JSON.parse(JSON.stringify(this.periods));
        this.clickApply = true;
        if (this.isValidForm()) {
            if (periodsOpen.length == 0) {
                this.validResult.emit(false);
                return;
            }
            this.validResult.emit(true);
            periodsOpen = periodsOpen.filter(function (period) { return period.open === true; });
            periodsOpen.map(function (period) {
                var _a, _b, _c;
                period.openTime = _this.getOpenTime(period);
                period.openTime = _this.dateS.hours12To24((_a = period) === null || _a === void 0 ? void 0 : _a.openTime);
                period.closeTime = _this.dateS.hours12To24((_b = period) === null || _b === void 0 ? void 0 : _b.closeTime);
                if ((_c = period) === null || _c === void 0 ? void 0 : _c.open)
                    delete period.open;
                return period;
            });
            return { regularHours: { periods: periodsOpen } };
        }
    };
    ModalRegularHoursComponent.prototype.addNewHour = function (period) {
        var newPeriod = __assign({}, period);
        period.closeTime = '';
        period.openTime = '';
        var periodIndex = this.periods.findIndex(function (p) { return p === period; });
        this.periods.splice(periodIndex, 0, newPeriod);
        this.formatPeriodArray();
    };
    ModalRegularHoursComponent.prototype.deleteHour = function (period) {
        var periodIndex = this.periods.findIndex(function (p) { return p === period; });
        this.periods.splice(periodIndex, 1);
        this.formatPeriodArray();
    };
    ModalRegularHoursComponent.prototype.formatPeriodArray = function () {
        this.formattedPeriods = this.locationS.sortPeriodsByDay(this.periods);
    };
    ModalRegularHoursComponent.prototype.changeOpenState = function (event, day) {
        var e_1, _a;
        try {
            for (var _b = __values(day.value), _c = _b.next(); !_c.done; _c = _b.next()) {
                var hours = _c.value;
                hours.open = event.checked;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    return ModalRegularHoursComponent;
}());
export { ModalRegularHoursComponent };
