import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { SubscriptionService } from '../../services/subscription.service';
import { SnackbarService } from '../../services/snackbar.service';
import { Messages } from '../../constants/messages';
// import {VerificationEmailService} from "../../services/verification-email.service";
import { WhiteLabelService } from "../../services/white-label.service";
import { SessionTraceService } from 'src/app/services/session-trace.service';
import { environment } from "@environment";
import { Router } from '@angular/router';



// Global set from Google Analytics Library
declare var gtag;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  trialDays : number
  loading = true
  registerProgress = false
  env;

  constructor(
    private _auth: AuthService,
    private _router: Router,
    private _apiSub: SubscriptionService,
    private _sessionTrace: SessionTraceService,
    public snack: SnackbarService,
    public wl: WhiteLabelService,
  ) {}

  async ngOnInit() : Promise<void> {
    this.env = environment
    this.trialDays = (await this.wl.getDynamicWhiteLabel()).trialDays
    this.loading = false
  }

  async handleRegisterWithGoogle(event : Event): Promise<void> {
    // TODO: All of this should be done atomically and server-side
    event.preventDefault();

    this.registerProgress = true;

    try {
      await this._auth.registerWithGoogle()
    } catch(err_msg) {
      this.registerProgress = false;
      this.snack.openError(err_msg, 4000);
      return
    }

    try {
      // Note createSubscription needs the user to be already logged-in
      // to send the parameters in headers
      await this._apiSub.createSubscription()
      
      await this._auth.initSession(); 

      this._sessionTrace.onRegistration();

      this.snack.openSuccess(Messages.register.SUCCESS, 3500);
      this._auth.redirectAfterLogin();
    } finally {
      this.registerProgress = false;
    }
  }

  goToLogin(): void {
    this._router.navigate(['/login']);
  }

  goToTermsOfService(): void {
    this.wl.goToTermsOfService()
  }

  goToPrivacyPolicy(): void {
    this.wl.goToPrivacyPolicy()
  }

  goToPaymentPolicy(): void {
    this.wl.goToPaymentPolicy()
  }
}
