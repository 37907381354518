import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {ModalService} from '../../../services/modal.service';
import {ModalDescriptionComponent} from '../../modal-description/modal-description.component';

@Component({
  selector: 'app-location-description',
  templateUrl: './location-description.component.html',
  styleUrls: ['./location-description.component.scss']
})
export class LocationDescriptionComponent implements OnInit {
  @Input() description;
  @Input() isEdit = true;
  @Output() onUpdate: EventEmitter<boolean> = new EventEmitter();

  constructor(private modalService: ModalService) {
  }

  ngOnInit() {
  }

  // edit website
  openDialog() {
    const dialogRef = this.modalService.openGenericModal(ModalDescriptionComponent, {description: this.description}, () => {});

    dialogRef.afterClosed().subscribe(res => {
      if (res != '') this.onUpdate.emit(true);

    });

    dialogRef.disableClose = true;
  }
}
