<app-trial-message></app-trial-message>
<app-impersonate-message></app-impersonate-message>
<app-header (sidenavToggle)="toggleSideNav()"></app-header>

<!--loader-->
<div class="spinner-wrapper spinner-wrapper--bg spinner-wrapper--fixed" *ngIf="spinnerService.loading$ | async">
    <mat-spinner></mat-spinner>
</div>
<mat-sidenav-container>
    <mat-sidenav #sidenav id="sidenav" role="navigation" mode="side" disableClose="false" opened="true" [fixedInViewport]="true" >
        <app-sidebar></app-sidebar>
    </mat-sidenav>
    <mat-sidenav-content>
        <main [ngClass]="contentClass">
            <router-outlet></router-outlet>
            <app-footer></app-footer>
        </main>
    </mat-sidenav-content>
</mat-sidenav-container>

