import { Component, Inject, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { Section } from '../../constants/service';
import { FoodMenu } from '../../constants/google/menuList';

export const SECTION_DATA: Section[] = [];

@Component({
  selector: 'app-modal-menu',
  templateUrl: './modal-menu.component.html',
  styleUrls: ['./modal-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalMenuComponent implements OnInit, OnDestroy {
  @Input() foodMenu: FoodMenu[] = [];
  @Input() onlyShow: boolean = false;
  @Input() accountId: string;
  @Input() locationId: string;
  error = {};
  error_details = [];

  constructor(
    public dialogRef: MatDialogRef<ModalMenuComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if(this.data) {
      this.onlyShow = this.data.onlyShow;
      this.foodMenu = this.data.foodMenu;
      this.accountId = this.data.accountId;
      this.locationId = this.data.locationId;
      this.error = this.data?.error;
      this.error_details = this.data?.error_details;
    }
  }

  ngOnInit() {
  }

  apply(): void {

  }

  ngOnDestroy(): void {
    
  }

}
  
