<div class="dialog__header">
    <h2 mat-dialog-title class="m--0 txt--xl"><strong>Services</strong></h2>
</div>
<div class="dialog__content" mat-dialog-content>
    <div class="dialog-row pb--0">
        <mat-form-field class="input-field">
            <input matInput placeholder="Service Area" [options]="optionsGoogle" [(ngModel)]="newServiceArea" ngx-google-places-autocomplete #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"/>        
            </mat-form-field>

        <div class="d-flex mb--25">
            <mat-chip-list class="chip--sm">
              <mat-chip color="primary" class="w--auto" *ngFor="let place of placeInfos; let i = index">
                {{place?.placeName}}
                <button mat-icon-button class="btn btn-icon" (click)="removePlace(place, i)">
                    <em class="far fa-times"></em>
                </button>
              </mat-chip>
            </mat-chip-list>
          </div>
    </div>
</div>
<div mat-dialog-actions class="dialog__footer">
    <span class="alternate-theme d-inline-block mr--10">
        <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
    </span>
    <button mat-flat-button (click)="apply()" tabIndex="-1" color="accent" class="btn btn--action">Apply</button>
</div>
  
