// TODO: Refactor against location-info-list.component.ts

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import AccountReport from '../../services/account-report';
import { BulkLocationInfo, COMPONENTS_CORE, COMPONENTS_MISC } from "../../constants/location-info";
import { AuthService } from '../../services/auth.service';
import { BulkLocationsEditService } from '../../services/bulk-locations-edit.service';
import { SnackbarService } from '../../services/snackbar.service';
import { LocationService } from '../../services/location.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { GoogleAttributes, GroupAttributes } from '../../constants/google/attributes';
import { GoogleService } from '../../services/google.service';
import * as _ from 'lodash';
import { ServiceList } from '../../constants/google/service-list';
import { MatSelectChange } from '@angular/material/select';
import { startWith, map } from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FoodMenu, defaultMenu } from '../../constants/google/menuList';
import { ModalService } from '../../services/modal.service';
import { AlertType } from '../../components/alert.component';

@Component({
  selector: 'app-modal-bulk-location-info',
  templateUrl: './modal-bulk-location-info.component.html',
  styleUrls:  ['./modal-bulk-location-info.component.scss']
})
export class ModalBulkLocationInfoComponent implements OnInit {
  public listCoreComponents = COMPONENTS_CORE;
  public listMiscComponents = COMPONENTS_MISC;
  public listActionLink = [];
  public step = 0;
  public stepTotal = 0;
  public accountsReport: AccountReport[] = [];
  public componentToEdit = new FormControl({}, [Validators.required]);
  public validResult = false;
  public showLoader: boolean;
  public regionCodes = null;
  public locationsInfo = [];
  public notSameCategory$   = new BehaviorSubject(false)
  public notAllowedMenu$    = new BehaviorSubject(false)
  public notAllowServices$  = new BehaviorSubject(true)
  public loadingAttributes$ = new BehaviorSubject(true)
  public notSameRegionCode$ = new BehaviorSubject(false)
  public ListTemplate: { source: string, serviceList: ServiceList[] }[] = [];
  public ListMenuTemplate: { source: string, menuList: FoodMenu[] }[] = [];
  public serviceListTemplate: ServiceList[] = [];
  public menuListTemplate: FoodMenu[] = [];
  public primaryCategories: any[] = [];
  public additionalCategories: any[] = [];
  public commonAdditionalCategories: any[] = [];
  public gid = this._auth.session.gid;
  public groupAttributes: GroupAttributes[] = [];
  public attributesUrl: GoogleAttributes[] = null;
  public actionLinks = [];
  @ViewChild('update', { static: false })
  public resultComponent: any;
  public serviceActive: boolean[] = [];
  public menuActive: boolean[] = [];
  public filteredOptions: Observable<any[]> = of([]);
  public locationsTemplate = new FormControl();
  public locationsSelection: any[] = [];
  public Replace = true;
  public regionCodesdViewDetails = false;
  public categoriesViewDetails = false;

  constructor(
    private _dialogRef: MatDialogRef<ModalBulkLocationInfoComponent>,
    private _auth: AuthService,
    private _bulkLocationService: BulkLocationsEditService,
    private _snackS: SnackbarService,
    private _googleServices: GoogleService,
    private _locationService: LocationService,
    private _modalService: ModalService
    ) { }

  ngOnInit() {
    this.filteredOptions = this.locationsTemplate.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );

  }

  private _filter(value: string): AccountReport[] {
    const filterValue = value.toLowerCase();
    if (filterValue !== '') {
      const locations = Object.assign([], this.locationsSelection);
      return locations.filter(option => option.address.addressLines[0].toLowerCase().indexOf(filterValue) === 0 || option.locationName.toLowerCase().indexOf(filterValue) === 0);
    } else {
      return this.locationsSelection;
    }


  }

  selectedLocations($event: AccountReport[]): void {
    this.accountsReport = $event;
  }

  completeSteeper() {
    this._modalService.openConfirmModal(
      'Bulk Update Confirmation',
      'Are you sure you want to update our local copy of the location? If they are locked, they will remain locked',
      (res) => {
        if (!res) 
          return
        const component = this.resultComponent.getResult();
        if (!this.validResult) {
          this._snackS.openError('You must add some information', 2000);
          return;
        }
        this.save(component);
      },
      AlertType.INFO
    );
  }

  loadCategories(): Array<Promise<any>> {
    const promises: Promise<any>[] = []
    this.locationsSelection = []
    this.accountsReport.forEach(a => {

      a.locations.forEach((l) => {
        const result = this._locationService.getRef(this._auth.session.gid, a.accountId, l.locationId);
        this.locationsSelection.push({ gid: this._auth.session.gid, accountId: a.accountId, locationId: l.locationId, ...l });
        promises.push(result.toPromise())
      });
    });
    return promises;
  }

  async verification($event): Promise<void> {
    if ($event === 0) {
      this.componentToEdit.setValue({});
    } else if ($event === 1) {
      this.componentToEdit.setValue({});
      this.componentToEdit.markAsUntouched()
      this.loadingAttributes$.next(true);
      this.primaryCategories = [];
      this.additionalCategories = [];
      this.serviceActive = [];
      this.menuActive = [];
      this.ListTemplate = [];
      this.ListMenuTemplate = [];
      this.serviceListTemplate = [];
      this.locationsInfo = [];
      this.actionLinks = [];
      this.listActionLink = [];

      await this.addActionLinks();
      const promises = this.loadCategories();

      await Promise.all(promises).then((results) => {
        let regionCodes = results.map(el => this._locationService.getRegionCode(el));
        regionCodes = [...new Set(regionCodes)];
        this.regionCodes = regionCodes?.join(', ');
        
        const value = regionCodes.every(el => el == regionCodes[0]);
        this.notSameRegionCode$.next(!value);
        
        results.forEach(el => this.locationsInfo.push({locationName: el.locationName, 
                                                       locationAddress: el.location.address, 
                                                       serviceArea: el?.location?.serviceArea?.places?.placeInfos}))
        
        results.forEach(async data => {
          if (data['serviceList']) 
            this.ListTemplate.push({ source: `${this._auth.session.gid}/${data['accountId']}/${data['locationId']}`, serviceList: data['serviceList'] })

          if (data['menuList']) 
            this.ListMenuTemplate.push({ source: `${this._auth.session.gid}/${data['accountId']}/${data['locationId']}`, menuList: data['menuList'] })

          const loc = data['location']
          if (loc) {
            this.primaryCategories.push(loc['primaryCategory'] || null)

            this.additionalCategories.push(loc['additionalCategories'] || null)

            const canService = loc['locationState']?.canModifyServiceList || false
            this.serviceActive.push(canService)

            const canMenu = loc['locationState']?.canHaveFoodMenus || false
            this.menuActive.push(canMenu)
          }
        })
      })
        .catch(() => {
          this.primaryCategories.push(null);
          this.additionalCategories.push(null);
        });

      this.primaryCategories[0]['primary'] = true;
      this.notAllowedMenu$.next(!this.menuActive.every(v => v === true));
      this.notAllowServices$.next(!this.serviceActive.every(v => v === true));

      if (this.primaryCategories.length > 1) {
        const primaryC: string = this.primaryCategories[0].categoryId;
        // additional
        const pivot = this.additionalCategories[0] || []

        const search = this.additionalCategories.slice(1).filter(Boolean);
        this.commonAdditionalCategories = pivot.reduce((ArrayResult, pivotCategory) => {
          if (search.every(s => {
            const finding = s.find(categorySearch => categorySearch.categoryId === pivotCategory.categoryId)
            if (finding) return true
            else return false
          })) {
            ArrayResult.push(pivotCategory)
          }
          return ArrayResult;
        }, []);
        if (primaryC) {
          const value = !this.primaryCategories.every(c => c?.categoryId === primaryC);
          this.notSameCategory$.next(value);
          if (!value) {
            await this.setInfo();
          }
        }
      } else if (this.primaryCategories.length == 1) {
        // additional
        const pivot = this.additionalCategories[0] || []

        this.commonAdditionalCategories = pivot;
        this.notSameCategory$.next(false);
        await this.setInfo();
      } else {
        this.notSameCategory$.next(true);
      }


      this.loadingAttributes$.next(false);
    } else if ($event === 2) {
      if (this.componentToEdit?.value?.key === 'service') {
        this.resultComponent.setDataService(this.serviceListTemplate, this.primaryCategories[0], this.commonAdditionalCategories)
      }
    }
  }

  async addActionLinks(): Promise<void> {
    const accountIds = this.accountsReport.map(el => el.accountId);
    const locationIds = []
    this.accountsReport.forEach(acc => acc.locations.map(el => locationIds.push(el.locationId)))

    try {
      const res = await this._bulkLocationService.getBulkPlaceActionLinks(this._auth.session.gid, accountIds, locationIds).toPromise();
      if (res?.data) {
          this.showLoader = false;
          this.actionLinks = res?.data[0]?.actions;
          this.actionLinks?.forEach(el => {
              this.listActionLink.push({
                key: 'placeActionLinks',
                value: el?.displayName,
                placeActionType: el?.placeActionType
              });
          });
      }
    } catch (err) {}
  }


  async setInfo() {
    const accountIds = this.accountsReport.map(el => el.accountId);
    const locationIds = []
    this.accountsReport.forEach(acc => acc.locations.map(el => locationIds.push(el.locationId)))

    const { groups } = await (await this._googleServices.groupsWithAttribute(accountIds, [this._auth.session.gid], locationIds));
    this.groupAttributes = groups;
    this.attributesUrl = groups.reduce((c, p) => {
      const items: GoogleAttributes[] = p.items.filter(i => {
        return _.startsWith(i.attributeId, 'url');
      });
      if (items.length > 0) 
        Object.assign(c, items)
      return c
    }, []);
  }



  changeReplace(Replace: MatCheckboxChange) {
    this.Replace = Replace.checked;
  }

  get completeStep1() {
    return this.accountsReport?.length > 0;
  }

  get completeStep2() {
    this.validResult = false;
    return this.componentToEdit.valid;
  }

  get completeStep3() {
    return this.validResult;
  }

  GetNameProperty(component): string {
    if (!!Object.keys(component).length) {
      const key = typeof(component) === 'string' ? component : component?.key;
      if (!key?.includes("Secondary-")) {
        return component.value
      } else {
        const name = component.split("-");
        name.splice(0, 1);
        const displayName = name.join(" ")
        return `Secondary Website URLs (${displayName})`
      }
    }
    return ''
  }

  async save(component): Promise<void> {
    this.showLoader = true;
    const key = this.componentToEdit?.value?.key || this.componentToEdit?.value
    const bulkEdit: BulkLocationInfo = {
      accounts: this.accountsReport,
      component: key,
      gid: this._auth.session.gid,
      status: 'PENDING',
      change: key !== 'placeActionLinks' ? component : this.getActionLinksData(component),
      replace: this.Replace
    };

    if (key === 'photos') 
      delete bulkEdit['change']

    try {
      const bulkSaved = await this._bulkLocationService.create(this._auth.session.gid, bulkEdit)
      this._dialogRef.close({ location_edit: bulkSaved, 
                             updates: component })
    } catch {
      this._snackS.openError("There was an error during the bulk edit. Please try again then contact support.", 2500)
    }
    this.showLoader = false

  }

  getActionLinksData(component): {} {
    const data = this.actionLinks.find(el => el.placeActionType === this.componentToEdit.value.placeActionType);
    data.placeActionLinks = component;

    return data;
  }

  getAttributes(): GoogleAttributes[] {
    return this._googleServices.attributesToGMB(this.groupAttributes)
  }

  getAttribute(): GoogleAttributes {
    const component = this.componentToEdit?.value?.key || this.componentToEdit?.value;
    const attributeId = !!Object.keys(component).length ? component?.split('Secondary-')?.[1] : null;
    return this.attributesUrl.find(a => a.attributeId === attributeId)
  }

  changeSourceService(source: MatSelectChange) : void {
    const search = this.ListTemplate.find(sl => sl.source === source.value);
    this.serviceListTemplate = search?.serviceList || []
    this.resultComponent.setDataService(this.serviceListTemplate, this.primaryCategories[0], this.commonAdditionalCategories)
  }

  changeAutoCompleteSourceService(source: string) : void {
    const search = this.ListTemplate.find(sl => sl.source === source);
    this.serviceListTemplate = search?.serviceList || []
    this.resultComponent.setDataService(this.serviceListTemplate, this.primaryCategories[0], this.commonAdditionalCategories)
  }

  changeAutoCompleteSourceMenu(source: string ) : void {
    const search = this.ListMenuTemplate.find(sl => sl.source === source);
    this.menuListTemplate = Object.assign([], search?.menuList || defaultMenu);
    this.resultComponent.setDataMenu(this.menuListTemplate)
  }

  formatAddress(address) : string {
    return address ? this._locationService.formatAddress(address) : ''
  }

}
