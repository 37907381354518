import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { UuidService } from '../../services/uuid.service';

@Component({
  selector: 'app-modal-products-item',
  templateUrl: './modal-products-item.component.html',
  styleUrls: ['./modal-products-item.component.scss']
})
export class ModalProductsItemComponent implements OnInit {

  productName: string;
  productNameInvalid: boolean;
  productDescription: string;
  productImage: string;

  priceRange: boolean;
  productPrice: string;
  productPriceMax: string;
  currencyCode: string;

  buttonAdded: boolean;
  buttonType: string;
  buttonText: string;

  linkType: any[];

  constructor(
    public dialogRef: MatDialogRef<ModalProductsItemComponent>,
    private uuid: UuidService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.priceRange = false;
    this.buttonAdded = false;
    this.productNameInvalid = false;
    this.linkType = ['Order Online', 'Buy', 'Learn More', 'Get offer'];
    this.currencyCode = data?.product?.price?.currencyCode ? data?.productItem?.price?.currencyCode : '';
  }

  ngOnInit() {
  }

  showLinkText(type: string): void {
    this.buttonAdded = (type !== 'hideButton');
    this.buttonType = type;
  }

  apply(): void {
    if ( this.productName ) {
      this.productNameInvalid = false;
      const productItem = {
        itemId: this.uuid.uuidRandom,
        labels: [
          { 
            displayName: this.productName,
            description: this.productDescription ? this.productDescription : ''
          }
        ],
        price: {
          currencyCode: "USD",
          units: this.productPrice ? this.productPrice : ""
        },
      };
      const data = { productItem };
      this.dialogRef.close(data);
    } else {
      this.productNameInvalid = true;
    }
  }
}
