<ng-container *ngIf="FrecuencySelect === 'WeeklyOn'; else MonthDaySelector">
  <div class="row mb--20">
     <div class="col-6 bg-danger">
      <mat-label class="d-block mb--10">Starts on:</mat-label>
      <div class="d-flex m-auto">
          <span class="frecuency-week-selector" (click)="selectDay('0')" [ngClass]="{'activate': daySelected === '0'}">SU</span>
          <span class="frecuency-week-selector" (click)="selectDay('1')" [ngClass]="{'activate': daySelected === '1'}">MO</span>
          <span class="frecuency-week-selector" (click)="selectDay('2')" [ngClass]="{'activate': daySelected === '2'}">TU</span>
          <span class="frecuency-week-selector" (click)="selectDay('3')" [ngClass]="{'activate': daySelected === '3'}">WE</span>
          <span class="frecuency-week-selector" (click)="selectDay('4')" [ngClass]="{'activate': daySelected === '4'}">TH</span>
          <span class="frecuency-week-selector" (click)="selectDay('5')" [ngClass]="{'activate': daySelected === '5'}">FR</span>
          <span class="frecuency-week-selector" (click)="selectDay('6')" [ngClass]="{'activate': daySelected === '6'}">SA</span>
      </div>
     </div>
     <div class="col-6 bg-info">
      <mat-label class="d-block mb--10">Ends on:</mat-label>
      <div class="d-flex m-auto">
          <span class="frecuency-week-selector" (click)="selectDurationDay('0')" [ngClass]="{'activate-duration': durationDaySelected === '0'}">SU</span>
          <span class="frecuency-week-selector" (click)="selectDurationDay('1')" [ngClass]="{'activate-duration': durationDaySelected === '1'}">MO</span>
          <span class="frecuency-week-selector" (click)="selectDurationDay('2')" [ngClass]="{'activate-duration': durationDaySelected === '2'}">TU</span>
          <span class="frecuency-week-selector" (click)="selectDurationDay('3')" [ngClass]="{'activate-duration': durationDaySelected === '3'}">WE</span>
          <span class="frecuency-week-selector" (click)="selectDurationDay('4')" [ngClass]="{'activate-duration': durationDaySelected === '4'}">TH</span>
          <span class="frecuency-week-selector" (click)="selectDurationDay('5')" [ngClass]="{'activate-duration': durationDaySelected === '5'}">FR</span>
          <span class="frecuency-week-selector" (click)="selectDurationDay('6')" [ngClass]="{'activate-duration': durationDaySelected === '6'}">SA</span>
      </div>
     </div>
     <div class="col-12 text-center">
      <div class="mt--10" *ngIf="durationDaySelected === daySelected" style="color: #999ea2;">
        <p>The duration of this post will be 1 day.</p>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #MonthDaySelector>
    <div class="dialog-row p-tb--0">
        <mat-label class="d-block mb--10">
          Repeats on (Day of the month):
          <span matTooltip="Pick which day of the month you want the post to begin on. Please note, you can only choose the 1st - 28th of the month as not all months have the same number of days. If choosing an event or offer post, the post will automatically expire on the day prior to the next event in the series at 11:59pm of the following calendar month. For example, a post on the 1st of June will publish at 12:01am on the 1st and set to expire on June 30th and ‘self-destruct’ at 11:59pm on the 30th. At 12:01am on the 1st of July, the next instance will be published following the same schedule."
          matTooltipPosition="above"
          matTooltipClass="mat-tooltip--override">
          <i class="fas fa-info-circle txt--lightgray txt--sm"></i>
        </span>
        </mat-label>
        <div class="row" style="margin: auto 0px;">
          <div class="col-1">
            <h6 style="padding: 10px 0px; margin: 0;">The</h6>
          </div>
          <div class="col-3">
            <mat-form-field
              floatLabel="never"
              class="input-group input-group--override input-group--override-label"
              style="text-align: center;"
            >
              <input
                [formControl]="daySelectedControl"
                matInput
                required
                types="number"
                min="1"
                max="31"
                class="input-group__field"
                (input)="SelectCalendar()"
              />
            </mat-form-field>
          </div>
          <div class="col-8">
            <h6 style="padding: 10px 0px; margin: 0;">day of the month</h6>
          </div>
          <div class="col-12">
            <div *ngIf="daySelectedControl.invalid">
              <p class="mb--10 mat-error">The day must be between 1st and 29th</p>
            </div>
          </div>
        </div>
      </div>
</ng-template>

