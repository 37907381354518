import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { LocationService } from '../../../services/location.service';
import { ModalMoreHoursComponent } from '../modal-more-hours/modal-more-hours.component';

@Component({
  selector: 'app-location-more-hours',
  templateUrl: './location-more-hours.component.html',
  styleUrls: ['./location-more-hours.component.scss']
})
export class LocationMoreHoursComponent implements OnInit {
  @Input() location;
  @Input() moreHours = [];
  @Input() moreHoursLabels = [];
  @Input() isEdit = false;
  @Input() isUnderReview = false;
  @Input() countRegularHours = 0;
  @Input() placeId;
  @Output() onUpdate: EventEmitter<boolean> = new EventEmitter();

  public moreHoursFormatted;
  public periods: any[];
  public periodsByDay: any;
  public weekDaysRegular: any;

  constructor(
    private dialog: MatDialog,
    private locationS: LocationService
  ) { }

  ngOnInit() {
    if(this.moreHoursLabels && this.moreHours?.length) {
      this.moreHours = this.moreHours.sort((a,b) => { return a.hoursTypeId < b.hoursTypeId ? -1 : 1});
      this.buildPeriods();
      this.getLabelsName();
    } else if (!this.moreHoursLabels) {
      this.getLabels();
    }
  }

  getLabels() {
    this.locationS.getRef(this.location?.gid, this.location?.accountId, this.location?.locationId).subscribe(res => {
      this.moreHoursLabels = res?.location.primaryCategory?.moreHoursTypes || [];
      res?.location.additionalCategories?.map(el => {
        el.moreHoursTypes.forEach(h => {
          const hasLabel = this.moreHoursLabels.find(l => l.hoursTypeId == h.hoursTypeId) ? true : false;
          if (!hasLabel) { this.moreHoursLabels.push(h); }
        });
      });
      this.buildPeriods();
      this.getLabelsName();
    });
  }

  buildPeriods() {
    this.moreHoursFormatted = JSON.parse(JSON.stringify(this.moreHours || []));
    this.moreHoursFormatted.forEach(el => {
      el.formattedPeriods = this.locationS.verifyOpen(el.periods);
      el.formattedPeriods = this.locationS.getContinuousHours(el.formattedPeriods);
      el.periodsByDay = this.locationS.sortPeriodsByDay(el.formattedPeriods);
    });
  }

  getLabelsName() {
    this.moreHoursFormatted.forEach(el => {
      const label = this.moreHoursLabels?.find(l => (l.hoursTypeId).toLowerCase() == (el.hoursTypeId).toLowerCase());
      el.displayName = label?.displayName;
    })
  }

  openMoreHoursDialog() {
    const dialogRef = this.dialog.open(ModalMoreHoursComponent, {
      width: '680px',
      data: {
        moreHours: this.moreHoursFormatted,
        labels: this.moreHoursLabels
      }
    });

    dialogRef.disableClose = true;

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onUpdate.emit(true);
      }
    });
  }

  originalOrder = (a, b) => 0;
}
