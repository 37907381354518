import { PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
var ZuluToDatePipe = /** @class */ (function () {
    function ZuluToDatePipe() {
    }
    ZuluToDatePipe.prototype.transform = function (value) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        var datePipe = new DatePipe('EN-US');
        return datePipe.transform(value, 'MM/dd/yyyy', '+0000');
    };
    return ZuluToDatePipe;
}());
export { ZuluToDatePipe };
