import { Component, OnInit } from '@angular/core';
import {REPORT_TYPE} from "src/app/constants/firestore/report";

@Component({
  selector: 'app-questions-answers-view',
  templateUrl: './questions-answers-view.component.html',
  styleUrls: ['./questions-answers-view.component.scss']
})
export class QuestionsAnswersViewComponent implements OnInit {
  reportType: REPORT_TYPE = 'qanda';

  constructor() { }

  ngOnInit() {
  }

}
