import { MatSnackBarRef } from '@angular/material';
var SnackBarComponent = /** @class */ (function () {
    function SnackBarComponent(snackBarRef, data) {
        var _a;
        this.snackBarRef = snackBarRef;
        this.data = data;
        this.panelClass = '';
        this.message = this.capitalizeFirstLetter((_a = this.data) === null || _a === void 0 ? void 0 : _a.message);
        this.type = this.data.type;
        this.panelClass = this.data.panelClass;
    }
    SnackBarComponent.prototype.capitalizeFirstLetter = function (sentence) {
        var _a, _b;
        sentence = (_a = sentence) === null || _a === void 0 ? void 0 : _a.toLowerCase();
        return (_b = sentence) === null || _b === void 0 ? void 0 : _b.replace(/(^\w|\.\s+\w)/gm, function (match) {
            var _a;
            return (_a = match) === null || _a === void 0 ? void 0 : _a.toUpperCase();
        });
    };
    return SnackBarComponent;
}());
export { SnackBarComponent };
