import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import AccountReport from 'src/app/services/account-report';
import { BulkLocationsEditService, ResumeEdit } from 'src/app/services/bulk-locations-edit.service';
import { ModalFetchComponent } from '../modal-fetch/modal-fetch.component';
import { ModalPhotosComponent } from '../modal-photos/modal-photos.component';
import { ModalServiceComponent } from '../modal-service/modal-service.component';
import { LocationService } from '../../services/location.service';
import { AuthService } from '../../services/auth.service';
import { Category, ServiceList } from '../../constants/google/service-list';
import { FoodMenu } from '../../constants/google/menuList';
import { ModalMenuComponent } from '../modal-menu/modal-menu.component';
import { SnackbarService } from 'src/app/services/snackbar.service';


@Component({
  selector: 'app-modal-view-bulk-locations-changes',
  templateUrl: './modal-view-bulk-locations-changes.component.html',
  styleUrls:  ['./modal-view-bulk-locations-changes.component.scss']
})
export class ModalViewBulkLocationsChangesComponent implements OnInit {
  accounts: AccountReport[] = []
  bulk_id: string;
  component = '';
  dataSource: ResumeEdit[] = [];
  page = 1;
  pages = 1;
  loadingNoti = false;
  loading = true;
  bulkStatus: string;
  showDeleted: boolean;

  constructor(public dialogRef: MatDialogRef<ModalViewBulkLocationsChangesComponent>,
              private dialog: MatDialog,
              private locationS: LocationService,
              private auth: AuthService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private bulkService: BulkLocationsEditService,
              private snackS: SnackbarService) {
    if (data) {
      this.accounts    = data.accounts;
      this.component   = data.component;
      this.bulk_id     = data._id;
      this.bulkStatus  = (data.status)?.toLowerCase();
      this.showDeleted = data?.deleted;
    }
  }

  ngOnInit() {
    this.getData(this.page);
  }

  getData(page: number) {
    if (this.component !== '') {
      this.bulkService.getResumeEdits(this.bulk_id, page, !!this.showDeleted).take(2).subscribe( data => {
        this.pages = data.pages;
        this.dataSource = [...this.dataSource,...data.response];
        this.loadingNoti = false;
        this.loading = false;
      })
    } else {
      this.loading = false;
    }

  }

  openFetchDialog(loc: ResumeEdit, notifyErrors= false) {
    this.dialog.open(ModalFetchComponent, {
      width: '1000px',
      data: {
        location: loc.log,
        difference: loc.log.difference,
        placeId: loc.location_id,
        history: true,
        notifyErrors,
        error: loc.log.error,
        error_details: loc.error_details
      }
    });
  }

  showMedia(accountId: string, locationId: string, location) {
    this.dialog.open(ModalPhotosComponent, {
      width: '1000px',
      data: {
        accountId,
        locationId,
        showOnlyMedia: true,
        error: location?.log?.error,
        error_details: location?.error_details
      }
    });
  }

  showServices(accountId: string, locationId: string, loc: any) {
    this.locationS.getRef(this.auth.session.gid, accountId, locationId).take(2).subscribe( result => {
      let serviceList: ServiceList[] = [];
      let primaryCategory: Category = null;
      let additionalCategories: Category[] = [];
      const location = result.locationEdit || result.location

      if ('serviceList' in result) {
        serviceList = result.serviceList;
      }

      if ('additionalCategories' in location) {
        additionalCategories = location['additionalCategories'];
      }

      primaryCategory = location['primaryCategory'];

      this.dialog.open(ModalServiceComponent, {
        width: '1000px',
        data: {
          type: 'Services',
          serviceList,
          primaryCategory,
          additionalCategories,
          accountId: accountId,
          locationId: locationId,
          showOnly: true,
          error: loc?.log?.error,
          error_details: loc?.error_details
        }
      });
    })
  }

  showMenu(accountId: string, locationId: string, loc) {
    this.locationS.getRef(this.auth.session.gid, accountId, locationId).take(2).subscribe( result => {
      let menuList: FoodMenu[] = [];
      // const location = result.locationEdit || result.location

      if ('menuList' in result) {
        menuList = result.menuList;
      }

      this.dialog.open(ModalMenuComponent, {
        width: '1000px',
        data: {
          foodMenu: menuList,
          accountId: accountId,
          locationId: locationId,
          onlyShow: true,
          error: loc?.log?.error,
          error_details: loc?.error_details
        }
      });
    })
  }

  handleReload(e) {
    e.preventDefault();
    if (!this.loadingNoti) {
      this.page += 1;
      this.loadingNoti = true;
      this.getData(this.page);
    }

  }

  retryFailedLocations() {
    this.loading = true;
    this.bulkService.retryFailedLocations(this.bulk_id).subscribe(
      res => {
        this.dialogRef.close(true);
        this.snackS.openSuccess('Failed locations were updated successfully', 2000);
      },
      err => {
        this.loading = false;
        this.dialogRef.close(false);
        this.snackS.openError('There was an error while trying to update the failed locations', 2000);
      }
    )
  }

  purgeFailedLocations() {
    this.loading = true;
    this.bulkService.purgeFailedLocations(this.bulk_id).subscribe(
      res => {
        this.snackS.openSuccess('Failed locations were deleted successfully', 2000);
        this.dialogRef.close(true);
      },
      err => {
        this.loading = false;
        this.dialogRef.close(false);
        this.snackS.openError('There was an error while deleting failed locations', 2000);
      }
    )
  }

}

