import {AlertType} from "../components/alert.component";

export class ModalConfirmData {
  data: object | any;
  title: string;
  content: string;
  confirmButtonLabel: string;
  closeButtonLabel: string;
  alertType: AlertType;
  modalPayment: boolean;
  description: string;
  typePaid: boolean;
  normalText?: boolean;

  constructor(data?) {
    if (data) {
      this.data               = data.data
      this.title              = data.title
      this.content            = data.content
      this.confirmButtonLabel = data.confirmButtonLabel
      this.closeButtonLabel   = data.closeButtonLabel
      this.alertType          = data.alertType || null
      this.modalPayment       = data.modalPayment || false
      this.description        = data.description || null
      this.normalText         = data.normalText ? data.alertType : false
      this.typePaid           = data.typePaid || false
    }
  }
}
