import * as i0 from "@angular/core";
var ChartService = /** @class */ (function () {
    function ChartService() {
    }
    ChartService.prototype.multiCharset = function (stats, dataPicker) {
        var _a, _b;
        var dataset = [];
        if (!dataPicker.multiChart)
            return [];
        if (!dataPicker) {
            (_a = stats) === null || _a === void 0 ? void 0 : _a.forEach(function (stats) {
                var _a;
                (_a = Object.keys(stats)) === null || _a === void 0 ? void 0 : _a.forEach(function (key) {
                    var stat = stats[key];
                    if (key !== 'title' && key !== 'totals') {
                        dataset.push(stat);
                    }
                });
            });
            return dataset;
        }
        (_b = dataPicker.multiChart) === null || _b === void 0 ? void 0 : _b.forEach(function (m) {
            var _a;
            (_a = stats) === null || _a === void 0 ? void 0 : _a.forEach(function (stats) {
                var _a;
                (_a = Object.keys(stats)) === null || _a === void 0 ? void 0 : _a.forEach(function (key) {
                    var _a, _b, _c;
                    var stat = stats[key];
                    var mId = m['key'] ? (m['key']).toUpperCase() : m.id;
                    var id = typeof (mId) == 'string' ? key.toUpperCase() : typeof ((_a = stat) === null || _a === void 0 ? void 0 : _a.id) == 'string' ? Number((_b = stat) === null || _b === void 0 ? void 0 : _b.id) : (_c = stat) === null || _c === void 0 ? void 0 : _c.id;
                    if (key !== 'title' && key !== 'totals' && mId === id) {
                        dataset.push(stat);
                    }
                });
            });
        });
        return dataset;
    };
    ChartService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChartService_Factory() { return new ChartService(); }, token: ChartService, providedIn: "root" });
    return ChartService;
}());
export { ChartService };
