import { FirestoreService } from './firestore.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { GROUPS, NOTIFICATIONS } from '../constants/firestore/collections';
import { firestore } from 'firebase/app';
import { TYPE_LOG_USER, TYPE_LOG_LOCATION, TYPE_LOG_GRADE, TYPE_LOG_SUBSCRIPTION } from "../constants/notifications";
// import {map} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import * as i0 from "@angular/core";
import * as i1 from "./firestore.service";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "@angular/common/http";
var NotificationService = /** @class */ (function () {
    function NotificationService(fs, afs, http) {
        this.fs = fs;
        this.afs = afs;
        this.http = http;
    }
    NotificationService.prototype.saveNotification = function (gid, domain, message, type, typeLog, meta) {
        return this.afs.collection(GROUPS).doc(gid).collection(NOTIFICATIONS)
            .add({ gid: gid, domain: domain, message: message, type: type, meta: meta, createAt: firestore.Timestamp.now(), status: 'unviewed', typeLog: typeLog });
    };
    NotificationService.prototype.getNotifications = function (gid) {
        return this.fs.colWithIds$(GROUPS + "/" + gid + "/" + NOTIFICATIONS, function (ref) { return ref
            .where('status', '==', 'unviewed').orderBy('createAt', 'desc').limit(100); });
    };
    NotificationService.prototype.paginateNotifications = function (gid, pageable, next, prev, where) {
        return this.fs.paginate(GROUPS + "/" + gid + "/" + NOTIFICATIONS, 'createAt', null, {
            size: pageable.size,
            page: pageable.page
        }, false, next, prev, null, null, 'desc');
    };
    NotificationService.prototype.deleteAll = function (gid) {
        return this.http.get(environment.apiUrl + "/v2/groups/gid/" + gid + "/delete/notification").toPromise();
    };
    NotificationService.prototype.deleteNotification = function (gid, docId) {
        this.afs.collection(GROUPS).doc(gid).collection(NOTIFICATIONS).doc(docId).delete().then();
    };
    // TODO: Unused, remove
    // countNotifications(gid) {
    //   return this.afs.collection(GROUPS).doc(gid).collection(NOTIFICATIONS,
    //     ref => ref.where('status', '==', 'unviewed')).snapshotChanges().pipe(map(documents => documents.length));
    // }
    // 
    // merge(gid, docId, data) {
    //   return this.afs.collection(GROUPS).doc(gid).collection(NOTIFICATIONS).doc(docId).set(data, {merge: true});
    // }
    NotificationService.prototype.getMetaTypeLog = function (typeLog, data) {
        switch (typeLog) {
            case TYPE_LOG_USER:
                return { email: data.email, displayName: data.displayName, role: data.role, uid: data.uid };
            case TYPE_LOG_LOCATION:
                return { accountId: data.accountId, locationName: data.locationName, locationId: data.locationId, address: data.address };
            case TYPE_LOG_GRADE:
                return { accountId: data.accountId, email: data.email, displayName: data.displayName, role: data.role, uid: data.uid };
            case TYPE_LOG_SUBSCRIPTION:
                return { accountId: data.accountId, locationId: data.locationId, locationName: data.locationName, subscriptionType: data.subscriptionType, planBefore: data.planBefore, planAfter: data.planAfter };
            default:
                break;
        }
    };
    NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.ɵɵinject(i1.FirestoreService), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.HttpClient)); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };
