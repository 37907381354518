import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-skeleton-location-info',
  templateUrl: './skeleton-location-info.component.html'
})
export class SkeletonLocationInfoComponent implements OnInit {

  @Input() type: string;

  constructor() { }

  ngOnInit() {
  }

}
