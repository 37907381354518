<div class="box box--padding-sm-tb d-flex justify-content-start border-bottom" *ngIf="observation">
    <div class="icon icon--rounded icon--gray" [ngSwitch]="observation?.key">
      <i class="far fa-images" *ngSwitchCase="'no_media_30'"></i>
      <i class="fas fa-file" *ngSwitchCase="'no_posts_30'"></i>
      <i class="fas fa-star" *ngSwitchCase="'no_reviews_30'"></i>
      <i class="fas fa-star" *ngSwitchCase="'unreplied_ratio_10'"></i>
      <i class="far fa-shopping-basket" *ngSwitchCase="'no_services'"></i>
      <i class="far fa-shopping-basket" *ngSwitchCase="'no_menu'"></i>
      <i class="far fa-shopping-basket" *ngSwitchCase="'no_menu_service'"></i>
    </div>

    <div>
      <h4 class="fw--medium" [ngSwitch]="observation?.key">
        <ng-container *ngSwitchCase="'no_media_30'">No new photos in the last 30 days</ng-container>
        <ng-container *ngSwitchCase="'no_posts_30'">No new posts in the last 30 days</ng-container>
        <ng-container *ngSwitchCase="'no_reviews_30'">No new reviews in the last 30 days</ng-container>
        <ng-container *ngSwitchCase="'unreplied_ratio_10'">Un-replied reviews ratio > 10%</ng-container>
        <ng-container *ngSwitchCase="'no_services'">No services items published</ng-container>
        <ng-container *ngSwitchCase="'no_menu'">No menu items published</ng-container>
        <ng-container *ngSwitchCase="'no_menu_service'">No menu and services items published</ng-container>
      </h4>
      <p class="txt--sm txt--lightgray m--0">
        <!--      May 2, 2019-->
        {{lastUpdate?.$date | date}}
      </p>
      <p class="mt--25 mb--0">
        {{observation?.message}}
      </p>
    </div>
</div>
