import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { QuestionsAnswerService } from 'src/app/services/questions-answer.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-slider-questions',
  templateUrl: './slider-questions.component.html',
  styleUrls: ['./slider-questions.component.scss']
})
export class SliderQuestionsComponent implements OnInit, OnChanges {
  @Input() question: any = {};
  @Input() slider: any = {};
  @Input() gid;
  @Input() accountId;
  @Input() locationId;
  @Input() displayName;
  @Input() isImpersonating = false;
  @Input() isReport = false;

  @Output() closedSlider = new EventEmitter();

  public updatingData = false;
  public newAnswersValue = null;

  constructor(
    public questionAnswerS: QuestionsAnswerService,
    public snackS: SnackbarService
  ) { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.newAnswersValue = this.getOwnerAnswer(this.question)?.text;
  }

  closeSlider() {
    this.slider.close();
  }

  sendNewAnswer(item, text = null) {
    let answer = !text ? this.newAnswersValue : text;
    answer = answer.trim();
    const accountId = this.accountId || item.accountId;
    const locationId = this.locationId || item.locationId;
    const hasError = document.getElementById('errorContainer').textContent ? true : false;
    
    if (!answer || hasError) { return; }
    
    this.updatingData = true;
    this.questionAnswerS.addAnswer(this.gid, accountId, locationId, item?.questionId, this.displayName, answer).subscribe(
      res => {
        this.closeSlider();
        this.closedSlider.emit();
        this.updatingData = false;
        this.snackS.openSuccess(`The answer was added successfully`, 4000);
      },
      err => {
        this.closeSlider();
        this.closedSlider.emit();
        this.updatingData = false;
        this.snackS.openError(`There was an error while adding the answer`, 4000);
      }
    )
  }

  deleteQuestions(question) {
    this.updatingData = true;
    const accountId = this.accountId || question.accountId;
    const locationId = this.locationId || question.locationId;
    const answer = this.getOwnerAnswer(question);

    this.questionAnswerS.deleteAnswer(this.gid, accountId, locationId, question?.questionId, answer?.name).subscribe(
      res => {
        this.closeSlider();
        this.closedSlider.emit();
        this.updatingData = false;
        this.snackS.openSuccess(`The request has been sent to google please check again in some minutes`, 4000);
      },
      err => {
        this.closeSlider();
        this.closedSlider.emit();
        this.updatingData = false;
        this.snackS.openError(`There was an error while deleting the answer`, 4000);
      }
    )
  }

  getOwnerAnswer(item) {
    return item?.answers && item?.hasOwner ? item?.answers?.find(el => (el.author.type).toLowerCase() == 'merchant') : {};
  }

}
