import { __awaiter, __generator } from "tslib";
import { EventEmitter } from '@angular/core';
import { AuthService } from './auth.service';
import { BehaviorSubject } from 'rxjs';
import { GoogleService } from './google.service';
import { ExternalGradeService } from './external-grade.service';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "./google.service";
import * as i3 from "./external-grade.service";
var GoogleAccountService = /** @class */ (function () {
    // private token : any; // TODO: Unused, remove
    function GoogleAccountService(auth, google, externalGradeService) {
        this.auth = auth;
        this.google = google;
        this.externalGradeService = externalGradeService;
        this.onLoadAll = new EventEmitter();
        this.dataStore = { accounts: [] };
        this._accounts = new BehaviorSubject([]);
    }
    Object.defineProperty(GoogleAccountService.prototype, "accounts", {
        get: function () {
            return this._accounts.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    GoogleAccountService.prototype.resetAccounts = function () {
        this._accounts.next([]);
    };
    GoogleAccountService.prototype.loadAll = function () {
        var _this = this;
        this.auth.getGmbToken().subscribe(function (token) {
            _this.loadAccounts(token);
        });
    };
    GoogleAccountService.prototype.loadAllGrade = function (gid, externalGradeId) {
        var _this = this;
        this.externalGradeService.getExternalGradeToken(gid, externalGradeId).subscribe(function (token) {
            if (token.token_expiry) {
                // this.token = token;  TODO: Unused, remove
                _this.loadAccounts(token);
            }
        });
    };
    // TODO: Not used, remove
    // getToken() {
    //   return this.token;
    // }
    GoogleAccountService.prototype.loadAccounts = function (token) {
        var _this = this;
        this.google.getAccounts(token).subscribe(function (data) {
            _this.dataStore = data;
            _this.dataStore['accounts'].sort(function (a, b) { return (a.accountName > b.accountName) ? 1 : -1; });
            var accountsArray = Object.assign({}, _this.dataStore).accounts;
            _this._accounts.next(accountsArray);
            _this.onLoadAll.emit({ success: true, token: token });
        }, function (err) {
            _this.onLoadAll.emit({ success: false, details: err });
        });
    };
    GoogleAccountService.prototype.google_oauth = function (user, externalId) {
        return __awaiter(this, void 0, void 0, function () {
            var urlOauth;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.google.authenticate(user.gid, user.uid, externalId)];
                    case 1:
                        urlOauth = _a.sent();
                        return [4 /*yield*/, this.openOauthWindow(urlOauth, user.gid, externalId)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    GoogleAccountService.prototype.openOauthWindow = function (url, gid, externalId) {
        return __awaiter(this, void 0, void 0, function () {
            var oauth, popupTick;
            var _this = this;
            return __generator(this, function (_a) {
                oauth = window.open(url, '_blank');
                popupTick = setInterval(function () {
                    if (oauth.closed) {
                        clearInterval(popupTick);
                        _this.loadAllGrade(gid, externalId);
                    }
                }, 500);
                return [2 /*return*/];
            });
        });
    };
    GoogleAccountService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GoogleAccountService_Factory() { return new GoogleAccountService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.GoogleService), i0.ɵɵinject(i3.ExternalGradeService)); }, token: GoogleAccountService, providedIn: "root" });
    return GoogleAccountService;
}());
export { GoogleAccountService };
