import {Component, OnInit} from '@angular/core';
import {ObservationsDTO} from '../../constants/firestore/observation';
import {ActivatedRoute} from '@angular/router';
import {LocationService} from '../../services/location.service';
import {ObservationService} from '../../services/observation.service';
import {SnackbarService} from '../../services/snackbar.service';
import {InsightsService} from '../../services/insights.service';
import {AuthService} from '../../services/auth.service';
import moment from 'moment';

@Component({
  selector: 'app-dashboard-workshop',
  templateUrl: './dashboard-workshop.component.html',
  styleUrls: ['./dashboard-workshop.component.scss']
})
export class DashboardWorkshopComponent implements OnInit {

  private readonly locationId;
  observations: ObservationsDTO;
  refreshing: boolean;

  config: any;
  index: number;

  user;
  topMetrics;
  isProgressObservation = true;
  isProgressTopMetrics = true;
  accountId: string;

  constructor(
    private route: ActivatedRoute,
    private locationService: LocationService,
    private observationService: ObservationService,
    private snack: SnackbarService,
    private insightsService: InsightsService,
    private auth: AuthService
  ) {
    this.user = this.auth.session;
    this.locationId = this.route.parent.snapshot.params.locationId;
    this.accountId = this.route.parent.snapshot.params.accountId;

    this.locationService.getObservations(this.user.gid, this.accountId, this.locationId).subscribe(observations => {
      this.observations = observations;
      this.isProgressObservation = false;
    }, error => {
      this.isProgressObservation = false;
    });
    this.insightsService.getTopMetrics(this.locationId).subscribe(topMetrics => {
      this.topMetrics = topMetrics;
      this.isProgressTopMetrics = false;
    }, error => {
      this.isProgressTopMetrics = false;
    });

    this.config = {
      loop: true,
      initialSlide: 0,
      slidesPerView: 4,
      navigation: true,
      nextButton: '.swiper-button-next',
      prevButton: '.swiper-button-prev',
      spaceBetween: 30, // Space between each Item,
      grabCursor: true
    };
    this.index = 0;
  }

  ngOnInit() {
  }

  handleRefresh() {
    this.refreshing = true;
    const endDate = `${moment()['year']()}-${moment()['month']() +1}-${moment()['date']()}`;
    const startDate = `${moment().subtract(1, 'year')['year']()}-${moment().subtract(1, 'year')['month']() +1}-${moment().subtract(1, 'year')['date']()}`;

    this.observationService.save(this.accountId, this.locationId).subscribe(
      (result) => {
        this.snack.openSuccess('No new observations or top metrics', 4000);
        this.refreshing = false;
      }, error => {
        this.refreshing = false;
        if (error.status !== 0) {
          error = error.error;
          this.snack.openError(error.message, 4000);
        }
      }
    );

    this.insightsService.saveLocationStats(this.auth.session.gid, this.accountId, this.locationId, startDate, endDate).subscribe(result => {
    }, error => {
      console.error(error);
      this.snack.openError(error.message, 4000);
    });
  }


}
