import { __awaiter, __generator } from "tslib";
import { OnDestroy, isDevMode } from '@angular/core';
import { NavigationEnd, Router /*, ActivatedRoute*/ } from '@angular/router';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { WhiteLabelService } from './services/white-label.service';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';
import { environment } from '@environment';
// import {Title} from "@angular/platform-browser";
import { filter, first } from "rxjs/operators";
import { Renderer2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModalService } from './services/modal.service';
import { HEADERS_NO_AUTH, MAIL_EXTERNAL_GRADE } from './constants/auth';
import { STORAGE_CUSTOM_SCRIPT } from "./constants/session";
import { AlertType } from './components/alert.component';
import { isRunningEmbedded } from './helpers/utils.helpers';
var AppComponent = /** @class */ (function () {
    function AppComponent(router, wl, renderer, httpClient, modalService, auth, userService, document) {
        var _this = this;
        this.router = router;
        this.wl = wl;
        this.renderer = renderer;
        this.httpClient = httpClient;
        this.modalService = modalService;
        this.auth = auth;
        this.userService = userService;
        this.document = document;
        this.title = 'map labs';
        this.subsManager$ = new Subject();
        this.wl.getDynamicWhiteLabel().then(function (data) {
            //this.titleService.setTitle(data.branding.company_name);
            console.debug('Branding loaded', data.branding);
            _this.router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; })).subscribe(function (event) {
                var isShared = _.endsWith(_this.router.url, 'shared');
                if (!isShared) {
                    if (data.branding.podium) {
                        _this.loadPodiumScript(data.branding.podium);
                    }
                    else if (data.branding.beacon) {
                        _this.loadBeaconScript(data.branding.beacon);
                    }
                }
                if (typeof gtag === 'function') { // Check if gtag is defined
                    gtag('config', _.get(environment, 'branding.analytics') || 'UA-138537135-2', {
                        'page_path': event.urlAfterRedirects
                    });
                }
                else {
                    console.error('gtag is not defined');
                }
            });
            if (!isRunningEmbedded()) {
                localStorage.setItem(STORAGE_CUSTOM_SCRIPT, data.branding.customScript);
            }
        });
        var savedVersion;
        var URL = '/assets/version.json';
        setInterval(function () {
            _this.httpClient.get(URL, HEADERS_NO_AUTH).subscribe(function (version) {
                var _a;
                var currentVersion = (_a = version) === null || _a === void 0 ? void 0 : _a.version;
                if (savedVersion && savedVersion !== currentVersion) {
                    _this.modalService.openAlertModal('New Version Available', 'A new version is available, please refresh the page to continue.', AlertType.WARNING, null, 'Reload Page', function () {
                        window.location.reload();
                    });
                }
                savedVersion = currentVersion;
            }, function (error) { });
        }, 1000 * 60 * 2);
        var interval = setInterval(function () {
            _this.auth.afAuth.authState.pipe(first()).toPromise().then(function (isSignedIn) {
                var isShared = _.endsWith(_this.router.url, 'shared');
                var isExternalUrlGrade = _.endsWith(_this.router.url, 'grade');
                if (isSignedIn && !isShared && !isExternalUrlGrade && _this.auth.session.email !== MAIL_EXTERNAL_GRADE) {
                    _this.userService.domainValidation(location.hostname, _this.auth.session.gid, _this.auth.session.uid, _this.auth.session.domainSurfing).subscribe(function (res) { return __awaiter(_this, void 0, void 0, function () {
                        var _this = this;
                        return __generator(this, function (_a) {
                            // The isDevMode flag should be present here to enable impersonation during development.
                            if (!res['allowLogin'] && !isDevMode()) {
                                console.debug("Domain validation FAILED: Domain [" + location.hostname + "], registration domain [" + res['domain']);
                                // Prevents interval from running again
                                clearInterval(interval);
                                // Show alert modal
                                this.modalService.openAlertModal('Heads up', "Sorry, we couldn't find your account. Please check your username and password or contact support.", AlertType.ERROR, null, 'OK', function () {
                                    // log user out
                                    _this.auth.signOut(true, true);
                                });
                            }
                            else if (isDevMode()) {
                                console.debug("Domain validation OK: DevMode [" + isDevMode() + "], Domain [" + location.hostname + "], registration domain [" + res['domain'] + "]");
                            }
                            return [2 /*return*/];
                        });
                    }); }, function (err) {
                        console.log(err);
                    });
                }
            });
        }, 1000 * 60 * 1);
    }
    AppComponent.prototype.loadPodiumScript = function (token) {
        var script = this.renderer.createElement('script');
        script['defer'] = true;
        script['type'] = 'text/javascript';
        script['src'] = "https://connect.podium.com/widget.js#API_TOKEN=" + token;
        script['id'] = "podium-widget";
        script['data-api-token'] = token;
        this.renderer.appendChild(this.document.body, script);
        return script;
    };
    AppComponent.prototype.loadBeaconScript = function (id) {
        var container = this.renderer.createElement('section');
        var script = this.renderer.createElement('script');
        script['type'] = 'text/javascript';
        script.innerText = function (e, t, n) { function a() { var e = t.getElementsByTagName("script")[0], n = t.createElement("script"); n.type = "text/javascript", n.async = !0, n.src = "https://beacon-v2.helpscout.net", e.parentNode.insertBefore(n, e); } if (e['Beacon'] = n = function (t, n, a) { e['Beacon'].readyQueue.push({ method: t, options: n, data: a }); }, n.readyQueue = [], "complete" === t.readyState)
            return a(); e['attachEvent'] ? e['attachEvent']("onload", a) : e.addEventListener("load", a, !1); }(window, document, window['Beacon'] || function () { });
        container.appendChild(script);
        var scriptId = this.renderer.createElement('script');
        scriptId['type'] = 'text/javascript';
        scriptId.innerText = window['Beacon']('init', id);
        container.appendChild(scriptId);
        this.renderer.appendChild(this.document.head, container);
        return container;
    };
    AppComponent.prototype.ngOnDestroy = function () {
        this.subsManager$.next(true);
        this.subsManager$.complete();
    };
    return AppComponent;
}());
export { AppComponent };
