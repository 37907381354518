import { __read, __spread } from "tslib";
import { AlertType } from './../../../components/posts-errors';
import { OnInit, OnDestroy, OnChanges, EventEmitter, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators, } from '@angular/forms';
import { MatDrawer } from '@angular/material';
import { Subject } from 'rxjs';
import { map, takeUntil, startWith } from 'rxjs/operators';
import moment from 'moment';
import { PostService } from 'src/app/services/post.service';
import { AuthService } from 'src/app/services/auth.service';
import { AccountService } from 'src/app/services/account.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ModalService } from './../../../services/modal.service';
import { StorageImageService } from './../../../services/storage-image.service';
import { DatesService } from 'src/app/services/dates.service';
import { FormInputUploadComponent } from '../../form-input-upload/form-input-upload.component';
var PostManegementSliderComponent = /** @class */ (function () {
    function PostManegementSliderComponent(_dateS, _postS, _authS, _accountS, _snackS, _storageImgService, _modalService) {
        this._dateS = _dateS;
        this._postS = _postS;
        this._authS = _authS;
        this._accountS = _accountS;
        this._snackS = _snackS;
        this._storageImgService = _storageImgService;
        this._modalService = _modalService;
        this.isSliderOpened = false;
        this.postToEdit = {};
        this.isBulk = true;
        this.location = {};
        this.closeSlider = new EventEmitter();
        this.isEdit = false;
        this.step = 0;
        this.accountsSelected = [];
        this.locationsSelected = [];
        this.isDraft = false;
        this.cancelText = {
            title: 'You have unsaved changes',
            message: 'If you close and leave this view, you will lose any unsaved changes.'
        };
        this.stepTitle = [
            'Select Locations',
            'Post Schedule and Settings',
            'Add Details',
            'Confirmation'
        ];
        this.nextButtonLabels = ['Post Schedule', 'Add Details', 'Preview Post', 'Publish'];
        this.hoursList = [];
        this.daysList = [];
        this.nameOfDaysOptions = [
            { value: [0], displayName: 'Sunday' },
            { value: [1], displayName: 'Monday' },
            { value: [2], displayName: 'Tuesday' },
            { value: [3], displayName: 'Wednesday' },
            { value: [4], displayName: 'Thursday' },
            { value: [5], displayName: 'Friday' },
            { value: [6], displayName: 'Saturday' }
        ];
        this.imageSelectedUrl = null;
        this.imageRequirements = {
            type: ['image/png', 'image/jpg', 'image/jpeg', 'image/webp'],
            min_width: 400,
            min_height: 300,
            min_size: 10240,
            max_size: 5000000
        };
        this.isLoading = false;
        this.actionsUrl = [];
        this.actionOptions = [
            { value: 'NONE', displayName: 'None' },
            { value: 'BOOK', displayName: 'Book' },
            { value: 'ORDER', displayName: 'Order online' },
            { value: 'SHOP', displayName: 'Buy' },
            { value: 'LEARN_MORE', displayName: 'Learn More' },
            { value: 'SIGN_UP', displayName: 'Sign Up' },
            { value: 'CALL', displayName: 'Call Now' }
        ];
        this.dataForLastStep = {};
        this.mediaUrlArray = [];
        this.selectedUrl = '';
        this.selectedFileIndex = 0;
        this.swiperConfig = {
            slidesPerView: 4,
            initialSlide: 0,
            spaceBetween: 30,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            simulateTouch: true
        };
        this._subsManager$ = new Subject();
        this.dataIsBeingVerified = false;
        this.isCorrectData = false;
        this.fieldsWithError = [];
        this.schedulePreviewData = [];
    }
    PostManegementSliderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initData();
        this._storageImgService.multipleMediaUrl$ // multiple media subscription
            .pipe(takeUntil(this._subsManager$))
            .subscribe(function (urlsArray) {
            if (urlsArray && urlsArray.length > 0) {
                _this.mediaUrlArray = __spread(urlsArray).filter(function (url) { return !url.error; });
            }
            else {
                _this.mediaUrlArray = [];
            }
            // this.isUploading = false;
        }, function (e) {
            console.error(e);
            _this._modalService.openErrorModal('Upload Error', "Error uploading the image, try again");
            // this.isUploading = false;
        });
    };
    PostManegementSliderComponent.prototype.initData = function () {
        var _this = this;
        var _a, _b, _c;
        this.hoursList = this._dateS.loadHoursDropdown(true);
        this.hoursListOptions = (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.get('eventTimeStart')) === null || _b === void 0 ? void 0 : _b.valueChanges.pipe(startWith(''), map(function (value) { return _this._filter(value); }));
        this.daysList = Array.from(Array(28), function (e, i) { return i + 1; });
        this.isDraft = this.isEdit && ((_c = this.postToEdit) === null || _c === void 0 ? void 0 : _c.status) === 'DRAFT';
        this.initForm();
    };
    PostManegementSliderComponent.prototype.initForm = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47, _48, _49, _50, _51, _52, _53, _54, _55, _56, _57, _58, _59, _60, _61, _62, _63, _64, _65, _66, _67, _68, _69, _70, _71, _72, _73, _74, _75, _76, _77, _78, _79, _80, _81, _82, _83, _84, _85, _86, _87, _88, _89, _90, _91, _92, _93, _94, _95, _96, _97, _98, _99, _100, _101, _102, _103, _104, _105, _106, _107, _108, _109, _110, _111, _112, _113, _114, _115, _116, _117, _118, _119, _120, _121, _122, _123, _124, _125;
        //first Step
        if (this.isEdit) {
            var accounts = this._accountS.buildToggleLocationAccounts((_a = this.postToEdit) === null || _a === void 0 ? void 0 : _a.locations);
            this.postToEdit.accounts = accounts;
            this.accountsSelected = accounts;
            if (((_d = (_c = (_b = this.postToEdit) === null || _b === void 0 ? void 0 : _b.post) === null || _c === void 0 ? void 0 : _c.media) === null || _d === void 0 ? void 0 : _d.length) > 0) {
                (_f = (_e = this.postToEdit) === null || _e === void 0 ? void 0 : _e.post) === null || _f === void 0 ? void 0 : _f.media.forEach(function (el) {
                    var _a, _b;
                    _this.mediaUrlArray.push({
                        url: (_a = el) === null || _a === void 0 ? void 0 : _a.sourceUrl,
                        category: (_b = el) === null || _b === void 0 ? void 0 : _b.mediaFormat
                    });
                });
                this._storageImgService.setMultipleMediaUrl(this.mediaUrlArray);
            }
        }
        if (!this.isBulk) {
            this.accountsSelected = this.location;
        }
        this.form = new FormGroup({
            //Second Step
            postType: new FormControl(((_h = (_g = this.postToEdit) === null || _g === void 0 ? void 0 : _g.post) === null || _h === void 0 ? void 0 : _h.topicType) || null, [Validators.required]),
            cadence: new FormControl(((_k = (_j = this.postToEdit) === null || _j === void 0 ? void 0 : _j.scheduleRules) === null || _k === void 0 ? void 0 : _k.type) || null, [Validators.required]),
            schedulePublish: new FormControl(((_m = (_l = this.postToEdit) === null || _l === void 0 ? void 0 : _l.scheduleRules) === null || _m === void 0 ? void 0 : _m.type) != "INSTANT" ? null :
                ((_r = (_q = (_p = (_o = this.postToEdit) === null || _o === void 0 ? void 0 : _o.scheduleRules) === null || _p === void 0 ? void 0 : _p.userInput) === null || _q === void 0 ? void 0 : _q.firstPublish) === null || _r === void 0 ? void 0 : _r.publish_now) ? 'publish_immediately' : 'custom'),
            postStartDate: new FormControl(((_t = (_s = this.postToEdit) === null || _s === void 0 ? void 0 : _s.scheduleRules) === null || _t === void 0 ? void 0 : _t.type) != "INSTANT" ? null :
                ((_x = (_w = (_v = (_u = this.postToEdit) === null || _u === void 0 ? void 0 : _u.scheduleRules) === null || _v === void 0 ? void 0 : _v.userInput) === null || _w === void 0 ? void 0 : _w.firstPublish) === null || _x === void 0 ? void 0 : _x.publish_now) ? null : this.getFormDate((_1 = (_0 = (_z = (_y = this.postToEdit) === null || _y === void 0 ? void 0 : _y.scheduleRules) === null || _z === void 0 ? void 0 : _z.userInput) === null || _0 === void 0 ? void 0 : _0.firstPublish) === null || _1 === void 0 ? void 0 : _1.date)),
            repeatsOnMonthly: new FormControl(((_3 = (_2 = this.postToEdit) === null || _2 === void 0 ? void 0 : _2.scheduleRules) === null || _3 === void 0 ? void 0 : _3.type) != 'MONTHLY' || !((_6 = (_5 = (_4 = this.postToEdit) === null || _4 === void 0 ? void 0 : _4.scheduleRules) === null || _5 === void 0 ? void 0 : _5.publishRule) === null || _6 === void 0 ? void 0 : _6.daysOfMonth) ? null :
                ((_10 = (_9 = (_8 = (_7 = this.postToEdit) === null || _7 === void 0 ? void 0 : _7.scheduleRules) === null || _8 === void 0 ? void 0 : _8.publishRule) === null || _9 === void 0 ? void 0 : _9.daysOfMonth) === null || _10 === void 0 ? void 0 : _10.includes(1)) || ((_11 = this.postToEdit.scheduleRules.publishRule.daysOfMonth) === null || _11 === void 0 ? void 0 : _11.includes(15)) || ((_12 = this.postToEdit.scheduleRules.publishRule.daysOfMonth) === null || _12 === void 0 ? void 0 : _12.includes(-1)) ? (_16 = (_15 = (_14 = (_13 = this.postToEdit) === null || _13 === void 0 ? void 0 : _13.scheduleRules) === null || _14 === void 0 ? void 0 : _14.publishRule) === null || _15 === void 0 ? void 0 : _15.daysOfMonth) === null || _16 === void 0 ? void 0 : _16[0] : 'custom'),
            repeatsOnDay: new FormControl(((_19 = (_18 = (_17 = this.postToEdit) === null || _17 === void 0 ? void 0 : _17.scheduleRules) === null || _18 === void 0 ? void 0 : _18.publishRule) === null || _19 === void 0 ? void 0 : _19.daysOfMonth) && !(((_20 = this.postToEdit.scheduleRules.publishRule.daysOfMonth) === null || _20 === void 0 ? void 0 : _20.includes(1)) || ((_21 = this.postToEdit.scheduleRules.publishRule.daysOfMonth) === null || _21 === void 0 ? void 0 : _21.includes(15))) ? (_22 = this.daysList) === null || _22 === void 0 ? void 0 : _22.find(function (el) { var _a, _b, _c, _d; return el == ((_d = (_c = (_b = (_a = _this.postToEdit) === null || _a === void 0 ? void 0 : _a.scheduleRules) === null || _b === void 0 ? void 0 : _b.publishRule) === null || _c === void 0 ? void 0 : _c.daysOfMonth) === null || _d === void 0 ? void 0 : _d[0]); }) :
                null),
            repeatsOnWeekly: new FormControl(((_24 = (_23 = this.postToEdit) === null || _23 === void 0 ? void 0 : _23.scheduleRules) === null || _24 === void 0 ? void 0 : _24.type) == 'WEEKLY' && ((_27 = (_26 = (_25 = this.postToEdit) === null || _25 === void 0 ? void 0 : _25.scheduleRules) === null || _26 === void 0 ? void 0 : _26.publishRule) === null || _27 === void 0 ? void 0 : _27.daysOfWeek) ? (_28 = this.nameOfDaysOptions) === null || _28 === void 0 ? void 0 : _28.find(function (el) { var _a; return el.value == ((_a = _this.postToEdit.scheduleRules.publishRule.daysOfWeek) === null || _a === void 0 ? void 0 : _a[0]); }).value : []),
            publishDay: new FormControl(((_30 = (_29 = this.postToEdit) === null || _29 === void 0 ? void 0 : _29.scheduleRules) === null || _30 === void 0 ? void 0 : _30.type) == 'DAILY' ? (_33 = (_32 = (_31 = this.postToEdit) === null || _31 === void 0 ? void 0 : _31.scheduleRules) === null || _32 === void 0 ? void 0 : _32.publishRule) === null || _33 === void 0 ? void 0 : _33.daysOfWeek : []),
            publishTime: new FormControl(this._dateS.hours24To12((_36 = (_35 = (_34 = this.postToEdit) === null || _34 === void 0 ? void 0 : _34.scheduleRules) === null || _35 === void 0 ? void 0 : _35.publishRule) === null || _36 === void 0 ? void 0 : _36.time) || null),
            // unpublishTime: new FormControl(this._dateS.hours24To12(this.postToEdit?.scheduleRules?.unpublishRule?.time) || null),
            deleteDate: new FormControl(((_38 = (_37 = this.postToEdit) === null || _37 === void 0 ? void 0 : _37.scheduleRules) === null || _38 === void 0 ? void 0 : _38.type) != "INSTANT" ? null :
                !((_43 = (_42 = (_41 = (_40 = (_39 = this.postToEdit) === null || _39 === void 0 ? void 0 : _39.scheduleRules) === null || _40 === void 0 ? void 0 : _40.userInput) === null || _41 === void 0 ? void 0 : _41.schedule) === null || _42 === void 0 ? void 0 : _42.end) === null || _43 === void 0 ? void 0 : _43.date) ? null : this.getFormDate(this.postToEdit.scheduleRules.userInput.schedule.end.date)),
            endsOn: new FormControl(!((_44 = Object.keys(this.postToEdit)) === null || _44 === void 0 ? void 0 : _44.length) ? null :
                ((_46 = (_45 = this.postToEdit) === null || _45 === void 0 ? void 0 : _45.scheduleRules) === null || _46 === void 0 ? void 0 : _46.interval) ? 'custom_occurrences' :
                    ((_48 = (_47 = this.postToEdit) === null || _47 === void 0 ? void 0 : _47.scheduleRules) === null || _48 === void 0 ? void 0 : _48.endDate) ? 'custom_date' :
                        ((_52 = (_51 = (_50 = (_49 = this.postToEdit) === null || _49 === void 0 ? void 0 : _49.scheduleRules) === null || _50 === void 0 ? void 0 : _50.userInput) === null || _51 === void 0 ? void 0 : _51.schedule) === null || _52 === void 0 ? void 0 : _52.type) != "INSTANT" ? 'never' : null),
            endsOnCustomDate: new FormControl(this.getFormDate((_57 = (_56 = (_55 = (_54 = (_53 = this.postToEdit) === null || _53 === void 0 ? void 0 : _53.scheduleRules) === null || _54 === void 0 ? void 0 : _54.userInput) === null || _55 === void 0 ? void 0 : _55.schedule) === null || _56 === void 0 ? void 0 : _56.end) === null || _57 === void 0 ? void 0 : _57.date) || null),
            endsOnAfterOcurrences: new FormControl(((_62 = (_61 = (_60 = (_59 = (_58 = this.postToEdit) === null || _58 === void 0 ? void 0 : _58.scheduleRules) === null || _59 === void 0 ? void 0 : _59.userInput) === null || _60 === void 0 ? void 0 : _60.schedule) === null || _61 === void 0 ? void 0 : _61.end) === null || _62 === void 0 ? void 0 : _62.limit_occurrences) || null),
            firstPublish: new FormControl(((_66 = (_65 = (_64 = (_63 = this.postToEdit) === null || _63 === void 0 ? void 0 : _63.scheduleRules) === null || _64 === void 0 ? void 0 : _64.userInput) === null || _65 === void 0 ? void 0 : _65.firstPublish) === null || _66 === void 0 ? void 0 : _66.publish_now) ? "post_immediately" :
                ((_70 = (_69 = (_68 = (_67 = this.postToEdit) === null || _67 === void 0 ? void 0 : _67.scheduleRules) === null || _68 === void 0 ? void 0 : _68.userInput) === null || _69 === void 0 ? void 0 : _69.firstPublish) === null || _70 === void 0 ? void 0 : _70.publish_next_occurrence) ? "next_occurrence" :
                    ((_74 = (_73 = (_72 = (_71 = this.postToEdit) === null || _71 === void 0 ? void 0 : _71.scheduleRules) === null || _72 === void 0 ? void 0 : _72.userInput) === null || _73 === void 0 ? void 0 : _73.firstPublish) === null || _74 === void 0 ? void 0 : _74.date) ? "custom" : null),
            firstPublishDate: new FormControl(this.getFormDate((_78 = (_77 = (_76 = (_75 = this.postToEdit) === null || _75 === void 0 ? void 0 : _75.scheduleRules) === null || _76 === void 0 ? void 0 : _76.userInput) === null || _77 === void 0 ? void 0 : _77.firstPublish) === null || _78 === void 0 ? void 0 : _78.date) || null),
            //Third step
            title: new FormControl((_80 = (_79 = this.postToEdit) === null || _79 === void 0 ? void 0 : _79.post) === null || _80 === void 0 ? void 0 : _80.title),
            summary: new FormControl(((_82 = (_81 = this.postToEdit) === null || _81 === void 0 ? void 0 : _81.post) === null || _82 === void 0 ? void 0 : _82.summary) || null),
            actionType: new FormControl(((_85 = (_84 = (_83 = this.postToEdit) === null || _83 === void 0 ? void 0 : _83.post) === null || _84 === void 0 ? void 0 : _84.callToAction) === null || _85 === void 0 ? void 0 : _85.actionType) || 'NONE'),
            actionUrl: new FormControl(this.getActionUrl()),
            instantEventDatesStart: new FormControl(this.getFormDate((_89 = (_88 = (_87 = (_86 = this.postToEdit) === null || _86 === void 0 ? void 0 : _86.post) === null || _87 === void 0 ? void 0 : _87.eventSchedule) === null || _88 === void 0 ? void 0 : _88.publishRule) === null || _89 === void 0 ? void 0 : _89.date) || null),
            instantEventDatesEnd: new FormControl(this.getFormDate((_93 = (_92 = (_91 = (_90 = this.postToEdit) === null || _90 === void 0 ? void 0 : _90.post) === null || _91 === void 0 ? void 0 : _91.eventSchedule) === null || _92 === void 0 ? void 0 : _92.deleteRule) === null || _93 === void 0 ? void 0 : _93.date) || null),
            eventTimeStart: new FormControl(this._dateS.hours24To12((_97 = (_96 = (_95 = (_94 = this.postToEdit) === null || _94 === void 0 ? void 0 : _94.post) === null || _95 === void 0 ? void 0 : _95.eventSchedule) === null || _96 === void 0 ? void 0 : _96.publishRule) === null || _97 === void 0 ? void 0 : _97.time) || null),
            eventTimeEnd: new FormControl(this._dateS.hours24To12((_101 = (_100 = (_99 = (_98 = this.postToEdit) === null || _98 === void 0 ? void 0 : _98.post) === null || _99 === void 0 ? void 0 : _99.eventSchedule) === null || _100 === void 0 ? void 0 : _100.deleteRule) === null || _101 === void 0 ? void 0 : _101.time) || null),
            weeklyEventsDates: new FormControl(!((_102 = this.postToEdit) === null || _102 === void 0 ? void 0 : _102.post) ? null :
                ((_106 = (_105 = (_104 = (_103 = this.postToEdit) === null || _103 === void 0 ? void 0 : _103.post) === null || _104 === void 0 ? void 0 : _104.eventSchedule) === null || _105 === void 0 ? void 0 : _105.publishRule) === null || _106 === void 0 ? void 0 : _106.matchScheduleDate) && ((_110 = (_109 = (_108 = (_107 = this.postToEdit) === null || _107 === void 0 ? void 0 : _107.post) === null || _108 === void 0 ? void 0 : _108.eventSchedule) === null || _109 === void 0 ? void 0 : _109.publishRule) === null || _110 === void 0 ? void 0 : _110.matchScheduleDate) ?
                    'corresponds_publish_date' : 'custom'),
            eventDatesStartWeekly: new FormControl(((_112 = (_111 = this.nameOfDaysOptions) === null || _111 === void 0 ? void 0 : _111.find(function (el) { var _a, _b, _c, _d, _e, _f; return (_a = el.value) === null || _a === void 0 ? void 0 : _a.includes((_f = (_e = (_d = (_c = (_b = _this.postToEdit) === null || _b === void 0 ? void 0 : _b.post) === null || _c === void 0 ? void 0 : _c.eventSchedule) === null || _d === void 0 ? void 0 : _d.publishRule) === null || _e === void 0 ? void 0 : _e.daysOfWeek) === null || _f === void 0 ? void 0 : _f[0]); })) === null || _112 === void 0 ? void 0 : _112.value) || null),
            eventDatesEndWeekly: new FormControl(((_114 = (_113 = this.nameOfDaysOptions) === null || _113 === void 0 ? void 0 : _113.find(function (el) { var _a, _b, _c, _d, _e, _f; return (_a = el.value) === null || _a === void 0 ? void 0 : _a.includes((_f = (_e = (_d = (_c = (_b = _this.postToEdit) === null || _b === void 0 ? void 0 : _b.post) === null || _c === void 0 ? void 0 : _c.eventSchedule) === null || _d === void 0 ? void 0 : _d.deleteRule) === null || _e === void 0 ? void 0 : _e.daysOfWeek) === null || _f === void 0 ? void 0 : _f[0]); })) === null || _114 === void 0 ? void 0 : _114.value) || null),
            eventDatesStartMonthly: new FormControl(((_115 = this.daysList) === null || _115 === void 0 ? void 0 : _115.find(function (el) { var _a, _b, _c, _d, _e; return el == ((_e = (_d = (_c = (_b = (_a = _this.postToEdit) === null || _a === void 0 ? void 0 : _a.post) === null || _b === void 0 ? void 0 : _b.eventSchedule) === null || _c === void 0 ? void 0 : _c.publishRule) === null || _d === void 0 ? void 0 : _d.daysOfMonth) === null || _e === void 0 ? void 0 : _e[0]); })) || null),
            eventDatesEndMonthly: new FormControl(((_116 = this.daysList) === null || _116 === void 0 ? void 0 : _116.find(function (el) { var _a, _b, _c, _d, _e; return el == ((_e = (_d = (_c = (_b = (_a = _this.postToEdit) === null || _a === void 0 ? void 0 : _a.post) === null || _b === void 0 ? void 0 : _b.eventSchedule) === null || _c === void 0 ? void 0 : _c.deleteRule) === null || _d === void 0 ? void 0 : _d.daysOfMonth) === null || _e === void 0 ? void 0 : _e[0]); })) || null),
            couponCode: new FormControl(((_119 = (_118 = (_117 = this.postToEdit) === null || _117 === void 0 ? void 0 : _117.post) === null || _118 === void 0 ? void 0 : _118.offer) === null || _119 === void 0 ? void 0 : _119.couponCode) || null),
            linkToRedeem: new FormControl(((_122 = (_121 = (_120 = this.postToEdit) === null || _120 === void 0 ? void 0 : _120.post) === null || _121 === void 0 ? void 0 : _121.offer) === null || _122 === void 0 ? void 0 : _122.redeemOnlineUrl) || null),
            termsAndCondition: new FormControl(((_125 = (_124 = (_123 = this.postToEdit) === null || _123 === void 0 ? void 0 : _123.post) === null || _124 === void 0 ? void 0 : _124.offer) === null || _125 === void 0 ? void 0 : _125.termsConditions) || null),
        });
        this.stepTitle[2] = this.isEdit ? "Add " + this.postTypeLabel + " Details" : "Add Details";
        this.nextButtonLabels[1] = this.isEdit ? "Add " + this.postTypeLabel + " Details" : "Add Details";
    };
    PostManegementSliderComponent.prototype.getActionUrl = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g;
        return (!((_c = (_b = (_a = this.postToEdit) === null || _a === void 0 ? void 0 : _a.post) === null || _b === void 0 ? void 0 : _b.callToAction) === null || _c === void 0 ? void 0 : _c.url) ? null :
            ((_e = (_d = this.actionsUrl) === null || _d === void 0 ? void 0 : _d.find(function (el) { var _a, _b, _c; return el.key == ((_c = (_b = (_a = _this.postToEdit) === null || _a === void 0 ? void 0 : _a.post) === null || _b === void 0 ? void 0 : _b.callToAction) === null || _c === void 0 ? void 0 : _c.url); })) === null || _e === void 0 ? void 0 : _e.value) ? (_g = (_f = this.actionsUrl) === null || _f === void 0 ? void 0 : _f.find(function (el) { var _a, _b, _c; return el.key == ((_c = (_b = (_a = _this.postToEdit) === null || _a === void 0 ? void 0 : _a.post) === null || _b === void 0 ? void 0 : _b.callToAction) === null || _c === void 0 ? void 0 : _c.url); })) === null || _g === void 0 ? void 0 : _g.value :
                this.postToEdit.post.callToAction.url);
    };
    PostManegementSliderComponent.prototype.getActionLinks = function () {
        var _this = this;
        var _a, _b, _c;
        this.actionsUrl = [];
        if (!((_a = this.actionType) === null || _a === void 0 ? void 0 : _a.value) || ((_b = this.actionType) === null || _b === void 0 ? void 0 : _b.value) === 'CALL' || ((_c = this.actionType) === null || _c === void 0 ? void 0 : _c.value) === 'NONE') {
            this.actionUrl.setValue(null);
            return;
        }
        this.isLoading = true;
        var accounts = this.accountsSelected.forEach(function (acc) {
            var _a;
            (_a = acc) === null || _a === void 0 ? void 0 : _a.locations.forEach(function (loc) { return loc.name = loc.locationName; });
        });
        this._postS.getActionLinks(this.accountsSelected).subscribe(function (res) {
            var _a, _b;
            if ((_a = res) === null || _a === void 0 ? void 0 : _a.data) {
                _this.actionsUrl = (_b = res) === null || _b === void 0 ? void 0 : _b.data;
                if (_this.isEdit) {
                    _this.actionUrl.setValue(_this.getActionUrl());
                }
                _this.isLoading = false;
            }
        }, function (err) {
            _this.isLoading = false;
        });
    };
    PostManegementSliderComponent.prototype.clearActionUrl = function () {
        this.actionUrl.setValue(null);
    };
    PostManegementSliderComponent.prototype.validateInput = function (event) {
        var allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ':', 'A', 'P', 'M', ':', 'BACKSPACE', ' ', 'TAB', 'ARROWLEFT', 'ARROWRIGHT', 'DELETE'];
        var key = event.key.toUpperCase();
        if (!allowedKeys.includes(key)) {
            event.preventDefault();
        }
    };
    PostManegementSliderComponent.prototype.addValidationsStepTwo = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
        var label = this.postTypeLabel ? "Add " + this.postTypeLabel + " Details" : "Add Details";
        this.nextButtonLabels[1] = label;
        this.stepTitle[2] = label;
        this.removeAllValidations();
        if ((_a = this.cadence) === null || _a === void 0 ? void 0 : _a.value) {
            if (((_b = this.cadence) === null || _b === void 0 ? void 0 : _b.value) == 'INSTANT') {
                (_c = this.schedulePublish) === null || _c === void 0 ? void 0 : _c.setValidators(Validators.required);
                this.form.controls.postStartDate.setValidators(this.isRequired((_d = this.schedulePublish) === null || _d === void 0 ? void 0 : _d.value, 'custom') ? [Validators.required] : null);
                this.form.controls.publishTime.setValidators(this.isRequired((_e = this.schedulePublish) === null || _e === void 0 ? void 0 : _e.value, 'custom') ? [Validators.required] : null);
            }
            else {
                //Daily | Monthly | Weekly
                this.form.controls.publishTime.setValidators(Validators.required);
                (_f = this.endsOn) === null || _f === void 0 ? void 0 : _f.setValidators(Validators.required);
                (_g = this.endsOnCustomDate) === null || _g === void 0 ? void 0 : _g.setValidators(this.isRequired((_h = this.endsOn) === null || _h === void 0 ? void 0 : _h.value, 'custom_date') ? [Validators.required] : null);
                (_j = this.endsOnAfterOcurrences) === null || _j === void 0 ? void 0 : _j.setValidators(this.isRequired((_k = this.endsOn) === null || _k === void 0 ? void 0 : _k.value, 'custom_occurrences') ? [Validators.required] : null);
                (_l = this.firstPublish) === null || _l === void 0 ? void 0 : _l.setValidators(Validators.required);
                (_m = this.firstPublishDate) === null || _m === void 0 ? void 0 : _m.setValidators(this.isRequired((_o = this.firstPublish) === null || _o === void 0 ? void 0 : _o.value, 'custom') ? [Validators.required] : null);
                switch ((_p = this.cadence) === null || _p === void 0 ? void 0 : _p.value) {
                    case 'DAILY':
                        (_q = this.publishDay) === null || _q === void 0 ? void 0 : _q.setValidators(Validators.required);
                        break;
                    case 'WEEKLY':
                        (_r = this.repeatsOnWeekly) === null || _r === void 0 ? void 0 : _r.setValidators(Validators.required);
                        break;
                    case 'MONTHLY':
                        (_s = this.repeatsOnMonthly) === null || _s === void 0 ? void 0 : _s.setValidators(Validators.required);
                        (_t = this.repeatsOnDay) === null || _t === void 0 ? void 0 : _t.setValidators(this.isRequired((_u = this.repeatsOnMonthly) === null || _u === void 0 ? void 0 : _u.value, 'custom') ? [Validators.required] : null);
                        break;
                }
            }
            this.updateValueAndValidity();
        }
    };
    PostManegementSliderComponent.prototype.addFormFieldsStepThree = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
        this.removeAllValidations();
        this.form.controls.summary.setValidators([Validators.required]);
        if (((_a = this.postType) === null || _a === void 0 ? void 0 : _a.value) !== 'STANDARD') {
            (_b = this.title) === null || _b === void 0 ? void 0 : _b.setValidators([Validators.required]);
            //this.eventTimeEnd?.setValidators([this._maxTimeValidator()]);
            if (((_c = this.cadence) === null || _c === void 0 ? void 0 : _c.value) == 'INSTANT') {
                (_d = this.instantEventDatesStart) === null || _d === void 0 ? void 0 : _d.setValidators([Validators.required]);
                (_e = this.instantEventDatesEnd) === null || _e === void 0 ? void 0 : _e.setValidators([Validators.required]);
                this.setTimeHours();
            }
            if (((_f = this.cadence) === null || _f === void 0 ? void 0 : _f.value) == 'WEEKLY') {
                (_g = this.eventDatesStartWeekly) === null || _g === void 0 ? void 0 : _g.setValidators([Validators.required]);
                (_h = this.eventDatesEndWeekly) === null || _h === void 0 ? void 0 : _h.setValidators([Validators.required]);
            }
            if (((_j = this.cadence) === null || _j === void 0 ? void 0 : _j.value) == 'MONTHLY') {
                (_k = this.weeklyEventsDates) === null || _k === void 0 ? void 0 : _k.setValidators([Validators.required]);
                (_l = this.eventDatesStartMonthly) === null || _l === void 0 ? void 0 : _l.setValidators(this.isRequired((_m = this.weeklyEventsDates) === null || _m === void 0 ? void 0 : _m.value, 'custom') ? [Validators.required] : null);
                (_o = this.eventDatesEndMonthly) === null || _o === void 0 ? void 0 : _o.setValidators(this.isRequired((_p = this.weeklyEventsDates) === null || _p === void 0 ? void 0 : _p.value, 'custom') ? [Validators.required] : null);
            }
            if (((_q = this.cadence) === null || _q === void 0 ? void 0 : _q.value) == 'DAILY') {
                this.setTimeHours();
            }
        }
        this.updateValueAndValidity();
    };
    PostManegementSliderComponent.prototype.setTimeHours = function () {
        this.eventTimeStart.setValue('12:00 AM');
        this.eventTimeEnd.setValue('11:59 PM');
    };
    PostManegementSliderComponent.prototype.removeAllValidations = function () {
        var _this = this;
        var keys = Object.keys(this.form.controls);
        keys.forEach(function (k) {
            if (k != 'postType' && k != 'cadence')
                _this.form.controls[k].clearValidators();
        });
        this.updateValueAndValidity();
    };
    PostManegementSliderComponent.prototype.updateValueAndValidity = function () {
        var _this = this;
        var keys = Object.keys(this.form.controls);
        keys.forEach(function (k) {
            _this.form.controls[k].updateValueAndValidity();
        });
    };
    PostManegementSliderComponent.prototype.isRequired = function (field, value) {
        return field === value;
    };
    PostManegementSliderComponent.prototype.selectDay = function (day) {
        var _a;
        if (this.isEdit && !this.isDraft) {
            return;
        }
        var values = (_a = this.publishDay) === null || _a === void 0 ? void 0 : _a.value;
        var repeteadElement = this.publishDay.value.findIndex(function (el) { return el == day; });
        if (repeteadElement > -1) {
            values.splice(repeteadElement, 1);
        }
        else {
            values.push(day);
        }
        values = values.sort(function (a, b) { return a - b; });
        this.publishDay.setValue(values);
    };
    PostManegementSliderComponent.prototype.parseTime = function (time) {
        var _a, _b, _c, _d, _e, _f;
        var parsedTime = this._dateS.hours12To24(time);
        var hour = (_c = (_b = ((_a = parsedTime) === null || _a === void 0 ? void 0 : _a.hours)) === null || _b === void 0 ? void 0 : _b.toString()) === null || _c === void 0 ? void 0 : _c.padStart(2, '0');
        var minutes = (_f = (_e = ((_d = parsedTime) === null || _d === void 0 ? void 0 : _d.minutes)) === null || _e === void 0 ? void 0 : _e.toString()) === null || _f === void 0 ? void 0 : _f.padStart(2, '0');
        return hour + ":" + minutes;
    };
    PostManegementSliderComponent.prototype.getFormDate = function (date) {
        return date ? date + "T00:00:00" : null;
    };
    PostManegementSliderComponent.prototype.getServiceDate = function (date, isYMD) {
        if (isYMD === void 0) { isYMD = true; }
        return (date && isYMD ?
            this._dateS.getStringDateYMD(moment(date)) :
            date && !isYMD ?
                this._dateS.getStringDateMDY(moment(date)) :
                null);
    };
    PostManegementSliderComponent.prototype.getUrlsMedia = function () {
        var urlsArray = [];
        this.mediaUrlArray.forEach(function (el) {
            var _a, _b, _c, _d, _e;
            var format = !((_b = (_a = el) === null || _a === void 0 ? void 0 : _a.category) === null || _b === void 0 ? void 0 : _b.includes('/')) ? (_c = el) === null || _c === void 0 ? void 0 : _c.category : ((_d = el) === null || _d === void 0 ? void 0 : _d.category.split('/')[0]) === 'image' ? 'PHOTO' : 'VIDEO';
            urlsArray.push({
                "mediaFormat": format,
                "sourceUrl": (_e = el) === null || _e === void 0 ? void 0 : _e.url
            });
        });
        return urlsArray.length ? urlsArray : null;
    };
    PostManegementSliderComponent.prototype.buildServiceData = function (isDraft) {
        var _this = this;
        if (isDraft === void 0) { isDraft = false; }
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
        this.accountsSelected.forEach(function (acc) {
            var _a;
            (_a = acc) === null || _a === void 0 ? void 0 : _a.locations.forEach(function (loc) { return loc.name = loc.locationName; });
        });
        return {
            "gid": this._authS.session.gid,
            "accounts": this.accountsSelected,
            "post": {
                "summary": this.summary.value,
                "callToAction": this.postType.value == 'OFFER' || (!((_a = this.actionType) === null || _a === void 0 ? void 0 : _a.value) || ((_b = this.actionType) === null || _b === void 0 ? void 0 : _b.value) === 'NONE') ? null :
                    {
                        "actionType": (_c = this.actionType) === null || _c === void 0 ? void 0 : _c.value,
                        "url": !((_d = this.actionUrl) === null || _d === void 0 ? void 0 : _d.value) ? null : ((_e = this.actionsUrl.find(function (el) { return el.value == _this.actionUrl.value; })) === null || _e === void 0 ? void 0 : _e.key) ? (_f = this.actionsUrl.find(function (el) { return el.value == _this.actionUrl.value; })) === null || _f === void 0 ? void 0 : _f.key : this.actionUrl.value,
                    },
                "title": this.postType.value !== 'STANDARD' ? this.title.value : null,
                "eventSchedule": this.postType.value == 'STANDARD' ? null : {
                    "publishRule": {
                        "daysOfWeek": this.cadence.value == 'WEEKLY' ? this.eventDatesStartWeekly.value : null,
                        "daysOfMonth": this.cadence.value == 'MONTHLY' && this.weeklyEventsDates.value == 'custom' ? [this.eventDatesStartMonthly.value] : null,
                        "time": ((_g = this.eventTimeStart) === null || _g === void 0 ? void 0 : _g.value) ? this.parseTime(this.eventTimeStart.value) : null,
                        "date": this.cadence.value == 'INSTANT' ? this.getServiceDate(this.instantEventDatesStart.value) : null,
                        "matchScheduleDate": (((this.postType.value == 'EVENT' || this.postType.value == 'OFFER') && this.cadence.value == 'DAILY') ||
                            ((_h = this.weeklyEventsDates) === null || _h === void 0 ? void 0 : _h.value) == "corresponds_publish_date" ? true : false)
                    },
                    "deleteRule": {
                        "daysOfWeek": this.cadence.value == 'WEEKLY' ? this.eventDatesEndWeekly.value : null,
                        "daysOfMonth": (this.cadence.value == 'MONTHLY' && this.weeklyEventsDates.value == 'custom' ? [this.eventDatesEndMonthly.value] : null),
                        "time": ((_j = this.eventTimeEnd) === null || _j === void 0 ? void 0 : _j.value) ? this.parseTime(this.eventTimeEnd.value) : null,
                        "date": this.cadence.value == 'INSTANT' ? this.getServiceDate(this.instantEventDatesEnd.value) : null,
                        "matchScheduleDate": (((this.postType.value == 'EVENT' || this.postType.value == 'OFFER') && this.cadence.value == 'DAILY') ||
                            ((_k = this.weeklyEventsDates) === null || _k === void 0 ? void 0 : _k.value) == "corresponds_publish_date" ? true : false)
                    }
                },
                "media": this.getUrlsMedia(),
                "topicType": (_l = this.postType) === null || _l === void 0 ? void 0 : _l.value,
                "offer": this.postType.value !== 'OFFER' || (!((_m = this.form.controls.couponCode) === null || _m === void 0 ? void 0 : _m.value) && !((_o = this.form.controls.linkToRedeem) === null || _o === void 0 ? void 0 : _o.value) && !((_p = this.form.controls.termsAndCondition) === null || _p === void 0 ? void 0 : _p.value)) ? null :
                    {
                        "couponCode": (_q = this.form.controls.couponCode) === null || _q === void 0 ? void 0 : _q.value,
                        "redeemOnlineUrl": (_r = this.form.controls.linkToRedeem) === null || _r === void 0 ? void 0 : _r.value,
                        "termsConditions": (_s = this.form.controls.termsAndCondition) === null || _s === void 0 ? void 0 : _s.value
                    }
            },
            "status": isDraft ? "DRAFT" : "ACTIVE",
            "is_bulk": this.isBulk,
            "is_recurring": this.cadence.value != 'INSTANT' ? true : false,
            "is_scheduled": this.cadence.value != 'INSTANT' || (this.cadence.value == 'INSTANT' && this.schedulePublish.value == 'custom') ? true : false,
            "first_publish": {
                "date": (this.cadence.value == 'INSTANT' && this.schedulePublish.value == 'custom' ? this.getServiceDate(this.postStartDate.value) :
                    this.firstPublish.value == 'custom' ? this.getServiceDate(this.firstPublishDate.value) : null),
                "publish_now": (((_t = this.cadence) === null || _t === void 0 ? void 0 : _t.value) == 'INSTANT' && this.schedulePublish.value == 'publish_immediately') || this.firstPublish.value == 'post_immediately' ? true : false,
                "publish_next_occurrence": this.firstPublish.value == 'next_occurrence' ? true : false,
            },
            "schedule": {
                "type": this.cadence.value,
                "end": this.endsOn.value != 'custom_date' && this.endsOn.value != 'custom_occurrences' && !this.deleteDate.value ? null : {
                    "date": (this.cadence.value == 'INSTANT' && this.deleteDate.value ? this.getServiceDate(this.deleteDate.value) :
                        this.endsOn.value == 'custom_date' ? this.getServiceDate(this.endsOnCustomDate.value) : null),
                    "limit_occurrences": this.endsOn.value == 'custom_occurrences' ? this.endsOnAfterOcurrences.value : null,
                },
                "publishRule": this.cadence.value == 'INSTANT' && this.schedulePublish.value != 'custom' ? null : {
                    "daysOfWeek": (this.cadence.value == 'DAILY' ? this.publishDay.value :
                        this.cadence.value == 'WEEKLY' ? this.repeatsOnWeekly.value : null),
                    "daysOfMonth": (this.cadence.value == 'MONTHLY' && this.repeatsOnMonthly.value != 'custom' ? [this.repeatsOnMonthly.value] :
                        this.cadence.value == 'MONTHLY' && this.repeatsOnMonthly.value == 'custom' ? [this.repeatsOnDay.value] : null),
                    "time": this.parseTime(this.publishTime.value)
                },
            }
        };
    };
    PostManegementSliderComponent.prototype.completeSteeper = function (isDraft) {
        if (isDraft === void 0) { isDraft = false; }
        this.isLoading = true;
        this.dataIsBeingVerified = true;
        this.isCorrectData = false;
        this.fieldsWithError = [];
        var data = this.buildServiceData(isDraft);
        if (this.isEdit) {
            this.editPost(data);
        }
        else {
            this.createPost(data);
        }
    };
    PostManegementSliderComponent.prototype.createPost = function (data) {
        var _this = this;
        this._postS.createPost(data).subscribe(function (res) {
            _this.isLoading = false;
            _this.dataIsBeingVerified = false;
            _this.drawer.toggle();
            _this.closeSlider.emit(true);
        }, function (err) {
            var _a, _b;
            _this.handleApiError((_b = (_a = err) === null || _a === void 0 ? void 0 : _a.error) === null || _b === void 0 ? void 0 : _b.errors);
        });
    };
    PostManegementSliderComponent.prototype.editPost = function (data) {
        var _this = this;
        var _a;
        var postId = (_a = this.postToEdit) === null || _a === void 0 ? void 0 : _a._id;
        this._postS.editPost(data, postId).subscribe(function (res) {
            _this.isLoading = false;
            _this.dataIsBeingVerified = false;
            _this.drawer.toggle();
            _this.closeSlider.emit(true);
        }, function (err) {
            var _a, _b;
            _this.handleApiError((_b = (_a = err) === null || _a === void 0 ? void 0 : _a.error) === null || _b === void 0 ? void 0 : _b.errors);
        });
    };
    PostManegementSliderComponent.prototype.handleApiError = function (errors) {
        var _this = this;
        errors.forEach(function (el) {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            if ((_a = el) === null || _a === void 0 ? void 0 : _a.parameter) {
                var error = (_c = (_b = el) === null || _b === void 0 ? void 0 : _b.displayable_message) === null || _c === void 0 ? void 0 : _c.split('. ')[1];
                var field = (_d = el.parameter) === null || _d === void 0 ? void 0 : _d.split('->');
                var lastPart = (_e = field) === null || _e === void 0 ? void 0 : _e[((_f = field) === null || _f === void 0 ? void 0 : _f.length) - 1];
                lastPart = lastPart.replaceAll('occurrence', 'occurence');
                lastPart = (_h = (_g = lastPart) === null || _g === void 0 ? void 0 : _g.replace(/([A-Z])/g, ' $1')) === null || _h === void 0 ? void 0 : _h.replace(/_/g, ' ');
                _this.fieldsWithError.push({ field: lastPart, error: error });
            }
        });
        this.isLoading = false;
        this.dataIsBeingVerified = false;
        this.isCorrectData = true;
    };
    PostManegementSliderComponent.prototype.selectedLocations = function ($event) {
        this.accountsSelected = $event;
    };
    PostManegementSliderComponent.prototype.handleUrl = function () {
    };
    PostManegementSliderComponent.prototype.addActionUrl = function (placeHolder) {
        var _a;
        this.actionUrl.setValue((_a = placeHolder) === null || _a === void 0 ? void 0 : _a.value);
    };
    PostManegementSliderComponent.prototype.handleRef = function ($event) {
        this.firestorRef = $event;
    };
    PostManegementSliderComponent.prototype.onStepChange = function (event) {
        this.step = event;
        if (this.step === 1) {
            this.buildLocationsNames();
        }
        if (this.step === 2) {
            this.getActionLinks();
            this.addFormFieldsStepThree();
        }
        if (this.step === 3) {
            this.buildDataForLastStep();
            //this.getSchedulePreview();
        }
    };
    PostManegementSliderComponent.prototype.backStep = function (event) {
        this.step = event;
        this.removeAllValidations();
    };
    PostManegementSliderComponent.prototype.getActionType = function (value) {
        var _a;
        return (_a = this.actionOptions.find(function (el) { var _a; return ((_a = el) === null || _a === void 0 ? void 0 : _a.value) === value; })) === null || _a === void 0 ? void 0 : _a.displayName;
    };
    PostManegementSliderComponent.prototype.buildLocationsNames = function () {
        var _this = this;
        this.locationsSelected = [];
        this.accountsSelected.forEach(function (acc) {
            acc.locations.map(function (loc) {
                _this.locationsSelected.push({
                    'accountName': loc.accountName,
                    'locationName': loc.locationName,
                    'address': loc.address,
                    'serviceArea': loc.serviceArea
                });
            });
        });
    };
    PostManegementSliderComponent.prototype.buildDataForLastStep = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47, _48, _49, _50, _51, _52, _53, _54, _55, _56, _57;
        this.dataForLastStep = {};
        switch ((_a = this.cadence) === null || _a === void 0 ? void 0 : _a.value) {
            case 'INSTANT':
                this.dataForLastStep = {
                    postType: this.postTypeLabel,
                    cadence: 'Does Not Repeat',
                    timeOfPublish: ((_b = this.schedulePublish) === null || _b === void 0 ? void 0 : _b.value) == 'custom' ? (_c = this.publishTime) === null || _c === void 0 ? void 0 : _c.value : null,
                    schedulePublish: ((_d = this.schedulePublish) === null || _d === void 0 ? void 0 : _d.value) !== 'custom' ? 'Now' : this.getServiceDate((_e = this.postStartDate) === null || _e === void 0 ? void 0 : _e.value, false),
                    scheduledDetele: this.getServiceDate((_f = this.deleteDate) === null || _f === void 0 ? void 0 : _f.value, false),
                    dailyDate: null,
                    startDate: ((_g = this.postType) === null || _g === void 0 ? void 0 : _g.value) === 'STANDARD' ? null : this.getServiceDate((_h = this.instantEventDatesStart) === null || _h === void 0 ? void 0 : _h.value, false),
                    endDate: ((_j = this.postType) === null || _j === void 0 ? void 0 : _j.value) === 'STANDARD' ? null : this.getServiceDate((_k = this.instantEventDatesEnd) === null || _k === void 0 ? void 0 : _k.value, false),
                    startTime: ((_l = this.postType) === null || _l === void 0 ? void 0 : _l.value) === 'STANDARD' ? null : (_m = this.eventTimeStart) === null || _m === void 0 ? void 0 : _m.value,
                    endTime: ((_o = this.postType) === null || _o === void 0 ? void 0 : _o.value) === 'STANDARD' ? null : (_p = this.eventTimeEnd) === null || _p === void 0 ? void 0 : _p.value,
                    couponCode: ((_q = this.postType) === null || _q === void 0 ? void 0 : _q.value) !== 'OFFER' ? null : (_r = this.form.controls.couponCode) === null || _r === void 0 ? void 0 : _r.value,
                    linkToRedeem: ((_s = this.postType) === null || _s === void 0 ? void 0 : _s.value) !== 'OFFER' ? null : (_t = this.form.controls.linkToRedeem) === null || _t === void 0 ? void 0 : _t.value,
                    termsAndCondition: ((_u = this.postType) === null || _u === void 0 ? void 0 : _u.value) !== 'OFFER' ? null : (_v = this.form.controls.termsAndCondition) === null || _v === void 0 ? void 0 : _v.value,
                    publishesEvery: null,
                    endsOn: null,
                    firstPublish: null,
                    // timeOfUnpublish: null,
                    repeatsOn: null
                };
                break;
            case 'DAILY':
                this.dataForLastStep = {
                    postType: this.postTypeLabel,
                    cadence: (_w = this.cadence) === null || _w === void 0 ? void 0 : _w.value,
                    timeOfPublish: ((_x = this.publishTime) === null || _x === void 0 ? void 0 : _x.value) ? (_y = this.publishTime) === null || _y === void 0 ? void 0 : _y.value : null,
                    publishesEvery: this._postS.getDaysUnified(this.publishDay.value),
                    endsOn: (this.endsOn.value === 'never' ? 'Never' :
                        this.endsOn.value === 'custom_date' ? this.getServiceDate((_z = this.endsOnCustomDate) === null || _z === void 0 ? void 0 : _z.value, false) :
                            "After " + ((_0 = this.endsOnAfterOcurrences) === null || _0 === void 0 ? void 0 : _0.value) + " Occurences"),
                    firstPublish: (((_1 = this.firstPublish) === null || _1 === void 0 ? void 0 : _1.value) === 'post_immediately' ? 'Post immediately' :
                        ((_2 = this.firstPublish) === null || _2 === void 0 ? void 0 : _2.value) === 'custom' ? this.getServiceDate((_3 = this.firstPublishDate) === null || _3 === void 0 ? void 0 : _3.value, false) :
                            'Wait for the next occurence'),
                    dailyDate: "Daily on " + this._postS.getDaysUnified(this.publishDay.value),
                    // timeOfUnpublish: null,
                    startDate: null,
                    endDate: null,
                    startTime: ((_4 = this.postType) === null || _4 === void 0 ? void 0 : _4.value) === 'STANDARD' ? null : (_5 = this.eventTimeStart) === null || _5 === void 0 ? void 0 : _5.value,
                    endTime: ((_6 = this.postType) === null || _6 === void 0 ? void 0 : _6.value) === 'STANDARD' ? null : (_7 = this.eventTimeEnd) === null || _7 === void 0 ? void 0 : _7.value,
                    couponCode: ((_8 = this.postType) === null || _8 === void 0 ? void 0 : _8.value) !== 'OFFER' ? null : (_9 = this.form.controls.couponCode) === null || _9 === void 0 ? void 0 : _9.value,
                    linkToRedeem: ((_10 = this.postType) === null || _10 === void 0 ? void 0 : _10.value) !== 'OFFER' ? null : (_11 = this.form.controls.linkToRedeem) === null || _11 === void 0 ? void 0 : _11.value,
                    termsAndCondition: ((_12 = this.postType) === null || _12 === void 0 ? void 0 : _12.value) !== 'OFFER' ? null : (_13 = this.form.controls.termsAndCondition) === null || _13 === void 0 ? void 0 : _13.value,
                    schedulePublish: null,
                    scheduledDetele: null,
                    repeatsOn: null
                };
                break;
            case 'WEEKLY':
                this.dataForLastStep = {
                    postType: this.postTypeLabel,
                    cadence: (_14 = this.cadence) === null || _14 === void 0 ? void 0 : _14.value,
                    timeOfPublish: (_15 = this.publishTime) === null || _15 === void 0 ? void 0 : _15.value,
                    // timeOfUnpublish: this.unpublishTime?.value,
                    publishesEvery: null,
                    repeatsOn: this.nameOfDaysOptions.find(function (el) { return el.value === _this.repeatsOnWeekly.value; }).displayName,
                    endsOn: (this.endsOn.value === 'never' ? 'Never' :
                        this.endsOn.value === 'custom_date' ? this.getServiceDate((_16 = this.endsOnCustomDate) === null || _16 === void 0 ? void 0 : _16.value, false) :
                            "After " + ((_17 = this.endsOnAfterOcurrences) === null || _17 === void 0 ? void 0 : _17.value) + " Occurences"),
                    firstPublish: (((_18 = this.firstPublish) === null || _18 === void 0 ? void 0 : _18.value) === 'post_immediately' ? 'Post immediately' :
                        ((_19 = this.firstPublish) === null || _19 === void 0 ? void 0 : _19.value) === 'custom' ? this.getServiceDate((_20 = this.firstPublishDate) === null || _20 === void 0 ? void 0 : _20.value, false) :
                            'Wait for the next occurence'),
                    dailyDate: null,
                    startDate: ((_21 = this.postType) === null || _21 === void 0 ? void 0 : _21.value) === 'STANDARD' ? null : this.nameOfDaysOptions.find(function (el) { return el.value === _this.eventDatesStartWeekly.value; }).displayName,
                    endDate: ((_22 = this.postType) === null || _22 === void 0 ? void 0 : _22.value) === 'STANDARD' ? null : this.nameOfDaysOptions.find(function (el) { return el.value === _this.eventDatesEndWeekly.value; }).displayName,
                    startTime: ((_23 = this.postType) === null || _23 === void 0 ? void 0 : _23.value) === 'STANDARD' ? null : (_24 = this.eventTimeStart) === null || _24 === void 0 ? void 0 : _24.value,
                    endTime: ((_25 = this.postType) === null || _25 === void 0 ? void 0 : _25.value) === 'STANDARD' ? null : (_26 = this.eventTimeEnd) === null || _26 === void 0 ? void 0 : _26.value,
                    couponCode: ((_27 = this.postType) === null || _27 === void 0 ? void 0 : _27.value) !== 'OFFER' ? null : (_28 = this.form.controls.couponCode) === null || _28 === void 0 ? void 0 : _28.value,
                    linkToRedeem: ((_29 = this.postType) === null || _29 === void 0 ? void 0 : _29.value) !== 'OFFER' ? null : (_30 = this.form.controls.linkToRedeem) === null || _30 === void 0 ? void 0 : _30.value,
                    termsAndCondition: ((_31 = this.postType) === null || _31 === void 0 ? void 0 : _31.value) !== 'OFFER' ? null : (_32 = this.form.controls.termsAndCondition) === null || _32 === void 0 ? void 0 : _32.value,
                    schedulePublish: null,
                    scheduledDetele: null,
                };
                break;
            case 'MONTHLY':
                var repeatsOn = (((_33 = this.repeatsOnMonthly) === null || _33 === void 0 ? void 0 : _33.value) === 'custom' ?
                    this._postS.getOrdinalNumbers((_34 = this.repeatsOnDay) === null || _34 === void 0 ? void 0 : _34.value) :
                    ((_35 = this.repeatsOnMonthly) === null || _35 === void 0 ? void 0 : _35.value) === -1 ?
                        'last day' :
                        this._postS.getOrdinalNumbers((_36 = this.repeatsOnMonthly) === null || _36 === void 0 ? void 0 : _36.value));
                this.dataForLastStep = {
                    postType: this.postTypeLabel,
                    cadence: (_37 = this.cadence) === null || _37 === void 0 ? void 0 : _37.value,
                    timeOfPublish: (_38 = this.publishTime) === null || _38 === void 0 ? void 0 : _38.value,
                    // timeOfUnpublish: this.unpublishTime?.value,
                    publishesEvery: null,
                    repeatsOn: "The " + repeatsOn + " of the month",
                    endsOn: (this.endsOn.value === 'never' ? 'Never' :
                        this.endsOn.value === 'custom_date' ? this.getServiceDate((_39 = this.endsOnCustomDate) === null || _39 === void 0 ? void 0 : _39.value, false) :
                            "After " + ((_40 = this.endsOnAfterOcurrences) === null || _40 === void 0 ? void 0 : _40.value) + " Occurences"),
                    firstPublish: (((_41 = this.firstPublish) === null || _41 === void 0 ? void 0 : _41.value) === 'post_immediately' ? 'Post immediately' :
                        ((_42 = this.firstPublish) === null || _42 === void 0 ? void 0 : _42.value) === 'custom' ? this.getServiceDate((_43 = this.firstPublishDate) === null || _43 === void 0 ? void 0 : _43.value, false) :
                            'Wait for the next occurence'),
                    dailyDate: "Monthly on the " + repeatsOn + " of the month",
                    startDate: ((_44 = this.postType) === null || _44 === void 0 ? void 0 : _44.value) === 'STANDARD' ? null : this._postS.getOrdinalNumbers((_45 = this.eventDatesStartMonthly) === null || _45 === void 0 ? void 0 : _45.value),
                    endDate: ((_46 = this.postType) === null || _46 === void 0 ? void 0 : _46.value) === 'STANDARD' ? null : this._postS.getOrdinalNumbers((_47 = this.eventDatesEndMonthly) === null || _47 === void 0 ? void 0 : _47.value),
                    startTime: ((_48 = this.postType) === null || _48 === void 0 ? void 0 : _48.value) === 'STANDARD' ? null : (_49 = this.eventTimeStart) === null || _49 === void 0 ? void 0 : _49.value,
                    endTime: ((_50 = this.postType) === null || _50 === void 0 ? void 0 : _50.value) === 'STANDARD' ? null : (_51 = this.eventTimeEnd) === null || _51 === void 0 ? void 0 : _51.value,
                    couponCode: ((_52 = this.postType) === null || _52 === void 0 ? void 0 : _52.value) !== 'OFFER' ? null : (_53 = this.form.controls.couponCode) === null || _53 === void 0 ? void 0 : _53.value,
                    linkToRedeem: ((_54 = this.postType) === null || _54 === void 0 ? void 0 : _54.value) !== 'OFFER' ? null : (_55 = this.form.controls.linkToRedeem) === null || _55 === void 0 ? void 0 : _55.value,
                    termsAndCondition: ((_56 = this.postType) === null || _56 === void 0 ? void 0 : _56.value) !== 'OFFER' ? null : (_57 = this.form.controls.termsAndCondition) === null || _57 === void 0 ? void 0 : _57.value,
                    schedulePublish: null,
                    scheduledDetele: null,
                };
                break;
        }
    };
    PostManegementSliderComponent.prototype.openDelete = function (item) {
        var _this = this;
        this._modalService.openAlertModal('Confirmation', "Are you sure you want to delete this image? This action cannot be undone.", AlertType.ERROR, null, 'Delete', function (response) {
            if (response) {
                _this._storageImgService.removeFileFromArray(_this.mediaUrlArray, item);
                _this._storageImgService.notifyFileDeleted();
            }
        }, 'red-alert');
    };
    PostManegementSliderComponent.prototype.getSchedulePreview = function () {
        var _this = this;
        this.isLoading = true;
        var data = this.buildServiceData();
        this.schedulePreviewData = [];
        this._postS.getSchedulePreview(data).subscribe(function (res) {
            var _a;
            _this.schedulePreviewData = (_a = res) === null || _a === void 0 ? void 0 : _a.data;
            _this.isLoading = false;
        }, function (err) {
            _this.isLoading = false;
        });
    };
    PostManegementSliderComponent.prototype.changePreview = function (file) {
        this.selectedUrl = file.url;
    };
    // custom functions for custom swiper
    PostManegementSliderComponent.prototype.customSwiperPrev = function () {
        if (this.selectedFileIndex === 0) {
            return;
        }
        this.selectedFileIndex--;
    };
    PostManegementSliderComponent.prototype.customSwiperNext = function () {
        if (this.mediaUrlArray.length - 1 === this.selectedFileIndex) {
            return;
        }
        this.selectedFileIndex++;
    };
    // custom swiper
    PostManegementSliderComponent.prototype.ngOnChanges = function (changes) {
        var _a, _b;
        if ((_a = changes) === null || _a === void 0 ? void 0 : _a.isSliderOpened) {
            this.isEdit = ((_b = changes) === null || _b === void 0 ? void 0 : _b.postToEdit) && Object.keys(changes.postToEdit.currentValue).length ? true : false;
            // const content = document.getElementsByClassName('content');
            // if (content.length > 0) {
            //   content[0]['className'] += ' pr--0';
            // }
            if (!this.isSliderOpened) {
                this.destroyData();
            }
            else {
                this.initData();
            }
        }
    };
    PostManegementSliderComponent.prototype.ngOnDestroy = function () {
        this.destroyData();
    };
    PostManegementSliderComponent.prototype.destroyData = function () {
        this.accountsSelected = [];
        this.locationsSelected = [];
        this.actionsUrl = [];
        this.dataForLastStep = {};
        this.step = 0;
        this.isEdit = false;
        this.isDraft = false;
        this.postToEdit = null;
        this._subsManager$.next();
        this._subsManager$.complete();
        this._storageImgService.reset();
        this.closeSlider.emit();
    };
    PostManegementSliderComponent.prototype.getMinDate = function (field) {
        var hasValidation = (this.postType.value === 'EVENT' && this.cadence.value === 'INSTANT' && this.schedulePublish.value === 'custom' && field === 'instantEventDatesStart' ?
            false :
            true);
        return (this.isEdit && !this.isDraft) || !hasValidation ? null : new Date();
    };
    PostManegementSliderComponent.prototype.getDisabledFieldTooltip = function () {
        var message = this.cadence.value === 'INSTANT' ?
            "You can\u2019t edit the schedule for this post. If you\u2019d like to change the schedule, you need to create a new post with the desired schedule change" :
            "You can\u2019t edit the schedule for a recurring post. If you\u2019d like to change the schedule, you need to create a new post with the desired schedule change";
        return (this.isEdit && !this.isDraft ?
            message :
            null);
    };
    Object.defineProperty(PostManegementSliderComponent.prototype, "formattedSummary", {
        get: function () {
            var _a, _b;
            var summary = (_b = (_a = this.summary) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.replaceAll(/\n/g, '<br>');
            return summary;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "infoAboutDates", {
        get: function () {
            var _a;
            var postType = (_a = this.postTypeLabel) === null || _a === void 0 ? void 0 : _a.replace(/_/g, ' ');
            postType = postType.charAt(0).toUpperCase() + postType.slice(1).toLowerCase();
            return "If the \"" + postType + " End Time\u201D is set, each post will be unpublished at that time instead, and republished at the specified Time of Publish.";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "postTypeLabel", {
        get: function () {
            var _a;
            return ((_a = this.postType) === null || _a === void 0 ? void 0 : _a.value) === 'STANDARD' ? 'Update' : this.postType.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "completeStep1", {
        get: function () {
            var _a, _b, _c;
            var isComplete = false;
            if (this.isBulk) {
                var totalLocations_1 = 0;
                (_a = this.accountsSelected) === null || _a === void 0 ? void 0 : _a.forEach(function (account) {
                    var _a, _b;
                    totalLocations_1 += (_b = (_a = account) === null || _a === void 0 ? void 0 : _a.locations) === null || _b === void 0 ? void 0 : _b.length;
                });
                isComplete = ((_b = this.accountsSelected) === null || _b === void 0 ? void 0 : _b.length) > 0 && totalLocations_1 >= 2;
            }
            else {
                isComplete = ((_c = this.accountsSelected) === null || _c === void 0 ? void 0 : _c.length) > 0;
            }
            return isComplete;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "completeStep2", {
        get: function () {
            var _a;
            return (_a = this.form) === null || _a === void 0 ? void 0 : _a.valid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "completeStep3", {
        get: function () {
            var _a;
            return (_a = this.form) === null || _a === void 0 ? void 0 : _a.valid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "postType", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.postType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "cadence", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.cadence;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "schedulePublish", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.schedulePublish;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "postStartDate", {
        get: function () {
            var _a;
            return (_a = this.form.controls) === null || _a === void 0 ? void 0 : _a.postStartDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "deleteDate", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.deleteDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "publishTime", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.publishTime;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "publishDay", {
        // get unpublishTime(): any {
        //   return this.form?.controls?.unpublishTime;
        // }
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.publishDay;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "unifiedPublishDayValues", {
        get: function () {
            return this._postS.getDaysUnified(this.publishDay.value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "endsOn", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.endsOn;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "endsOnCustomDate", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.endsOnCustomDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "endsOnAfterOcurrences", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.endsOnAfterOcurrences;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "repeatsOnMonthly", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.repeatsOnMonthly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "repeatsOnWeekly", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.repeatsOnWeekly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "repeatsOnDay", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.repeatsOnDay;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "firstPublish", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.firstPublish;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "firstPublishDate", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.firstPublishDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "title", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.title;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "summary", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.summary;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "eventDatesStartWeekly", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.eventDatesStartWeekly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "eventDatesEndWeekly", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.eventDatesEndWeekly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "eventDatesStartMonthly", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.eventDatesStartMonthly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "eventDatesEndMonthly", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.eventDatesEndMonthly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "instantEventDatesStart", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.instantEventDatesStart;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "instantEventDatesEnd", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.instantEventDatesEnd;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "weeklyEventsDates", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.weeklyEventsDates;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "eventTimeStart", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.eventTimeStart;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "actionType", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.actionType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "actionUrl", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.actionUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "eventTimeEnd", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.eventTimeEnd;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "disabledNextStep", {
        get: function () {
            return !(this.step === 0 ? this.completeStep1 :
                this.step === 1 ? this.completeStep2 :
                    this.step === 2 ? this.completeStep3 : true);
        },
        enumerable: true,
        configurable: true
    });
    // get endsOnTooltip(): string {
    //   return (
    //     this.postType?.value === 'STANDARD' ? 
    //     'Choose whether this recurring post will continue indefinitely or stop after a specific date or number of occurences.' : 
    //     'Choose whether this recurring post will continue indefinitely or stop after a specific date or number of occurences.If your event or offer also has an end date/time, the occurence will be automatically unpublished at the earlier of the two.' 
    //   )
    // }
    PostManegementSliderComponent.prototype._filter = function (value) {
        var filterValue = value.toLowerCase();
        var filtered = this.hoursList.filter(function (option) { return option.toLowerCase().includes(filterValue); });
        return filtered;
    };
    PostManegementSliderComponent.prototype._maxTimeValidator = function () {
        return function (control) {
            var _a;
            if (!((_a = control) === null || _a === void 0 ? void 0 : _a.value)) {
                return null;
            }
            else {
                var currentTime = moment();
                var inputTime = moment(control.value, 'hh:mm A', true);
                if (!inputTime.isValid()) {
                    return { invalidTime: true };
                }
                return inputTime.isBefore(currentTime) ? { maxTime: true } : null;
            }
        };
    };
    return PostManegementSliderComponent;
}());
export { PostManegementSliderComponent };
