import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'acceptFormatImage'
})
export class AcceptFormatImagePipe implements PipeTransform {

  transform(value: string[]): string[] {
    const copyObj = Object.assign(value, []);
    if (copyObj.length > 1) {
      const lastElement = copyObj[copyObj.length-1].split('/')[1].toUpperCase();
      let tmpResponse: string[] = copyObj.slice(0, copyObj.length - 1);
      tmpResponse = tmpResponse.reduce( (r, c) => {
        r.push(c.split('/')[1].toUpperCase())
        return r
      }, []);
      const response: string = tmpResponse.join(", ");
      return [`${response} Or ${lastElement}`];

    } else {
      return [copyObj[0].split('/')[1].toUpperCase()];
    }

  }

}
