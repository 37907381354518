import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { REPORT_TYPE } from "../../constants/firestore/report";


@Component({
  selector: 'app-review-reports',
  templateUrl: './review-reports.component.html',
  styleUrls: ['./review-reports.component.scss']
})
export class ReviewReportsComponent implements OnInit {
  reportType: REPORT_TYPE = 'performance-review-report';
  isBoxView: boolean;

  displayedColumns: string[];
  dataSource: any;

  checked = false;

  // custom pagination elements
  manualPage: number;
  errorMessage: boolean;
  // custom pagination with page numbers
  @Input() page: number;
  @Input() totalPages: number;
  // events for custom pagination
  @Output() goPrev = new EventEmitter<boolean>();
  @Output() goNext = new EventEmitter<boolean>();
  @Output() goPage = new EventEmitter<boolean>();


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.isBoxView = true;
    this.manualPage = 1;
    this.errorMessage = false;

    this.reportType = this.route?.snapshot?.routeConfig?.path?.replace('-report','') as REPORT_TYPE;
  }

}

