import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import * as firebase from 'firebase/app';
import { GoogleAccountService } from '../../services/google-account.service';
import { SnackbarService } from '../../services/snackbar.service';
import { GoogleService } from '../../services/google.service';
import { AuthService } from '../../services/auth.service';
import GoogleAccount from '../../constants/firestore/google-account';
import { AccountService } from '../../services/account.service';
import { LocationService } from '../../services/location.service';
import { AccountLocSelectorComponent } from '../account-loc-selector/account-loc-selector.component';
import { environment } from '@environment';
import { GradeLeadService } from 'src/app/services/grade-lead.service';
import { WhiteLabelService } from 'src/app/services/white-label.service';
import { Title } from '@angular/platform-browser';
import { Log, LogGrade, LogsService } from 'src/app/services/logs.service';
import { EXTERNAL_GRADE_ID, SESSION_EXTERNAL, STORAGE_GRADE_LEAD } from '../../constants/session';
import { ExternalGradeService } from 'src/app/services/external-grade.service';

@Component({
  selector: 'app-external-grade',
  templateUrl: './external-grade.component.html',
  styleUrls: ['./external-grade.component.scss']
})
export class ExternalGradeComponent implements OnInit, OnDestroy {

  dl: any;
  user;
  userExternal;
  accounts: GoogleAccount[] = [];
  public locations: any;
  grade: boolean;
  locationId: string;
  accountId: string;
  loading = false;
  addAccountDialog;
  showGradePopup = true;
  gradeLoad: any;
  env;
  companyName = '';
  externalGradeId: string;

  constructor(
    public dialog: MatDialog,
    private snack: SnackbarService,
    private googleS: GoogleService,
    private googleAccountS: GoogleAccountService,
    private accountService: AccountService,
    private locationService: LocationService,
    private gradeLeadService: GradeLeadService,
    private auth: AuthService,
    private wl: WhiteLabelService,
    private titleService: Title,
    private logService: LogsService,
    private externalGradeService: ExternalGradeService
  ) {
    this.wl.getDynamicWhiteLabel().then(data => {
      this.companyName = data.branding.company_name;
      const titlePass = `Grader | ${this.companyName}`;
      this.titleService.setTitle(titlePass);
    });

  }

  async ngOnInit() {
    const now = Date.now();
    const randomNum = Math.round(Math.random() * 1000);
    this.externalGradeId = `${now}${randomNum}`;
    localStorage.setItem(EXTERNAL_GRADE_ID, this.externalGradeId);
    const gradeLead = localStorage.getItem(STORAGE_GRADE_LEAD)
    if (gradeLead && this.auth.session) 
      this.loadGradeLead(JSON.parse(gradeLead));
    
  }

  async setExternalSession() {
    this.env = environment;
    this.loading = true;
    this.user = await this.auth.createExternalGradeUser();
    localStorage.setItem(SESSION_EXTERNAL, JSON.stringify(this.user));
    this.auth.gradeExternalUser = this.user;
    try {
      this.user.authToken = await this.auth.afAuth.auth.currentUser.getIdToken(true)
    } catch(e) {
      //
    }
    this.loading = false;
  }

  ngOnDestroy() {
    localStorage.removeItem(STORAGE_GRADE_LEAD);
  }

  async openAuth() {
    await this.setExternalSession();
    this.subscribeToGoogleResponse();
    this.snack.openInfo(`A tab to authenticate with Google will open. If you don't see it, check your pop-up blocker settings`, 5000);
    const gradeId = localStorage.getItem(EXTERNAL_GRADE_ID);
    return this.googleAccountS.google_oauth(this.user, gradeId);
  }

  async handleSelected($event: any) {
    try {
      const location = $event.location;
      const account  = $event.account;
      this.accountId  = location.name.split('/')[1];
      this.locationId = location.name.split('/')[3];

      this.locations = { locationId: this.locationId, accountId: this.accountId };
      const token = await this.externalGradeService.getExternalGradeToken(
        this.auth.externalSession.gid,
        this.externalGradeId
      ).toPromise();

      const exist = await this.accountService.get(this.auth.externalSession.gid, this.accountId).toPromise();

      if (!exist) {
        const accountSave = await this.accountService.save({
          ...account,
          gid: this.auth.externalSession.gid,
          accountId: this.accountId,
          googleAuth: token
        }, true);
        this.accountId = accountSave.accountId;
      }

      const saved = await this.locationService.save($event.location, this.accountId, this.auth.externalSession.gid);

      if (saved) {
        const user = await this.googleS.getUserProfile(token);
        const parsedUrl = new URL(window.location.href);
        const registrationDomain = parsedUrl.host;
        const gradeLead = {
          locationId: $event.location.name.split('/')[3],
          locationName: $event.location.locationName,
          accountId: $event.account.name.split('/')[1],
          accountName: $event.account.accountName,
          createdAt: firebase.firestore.Timestamp.now(),
          name: user.name,
          email: user.email,
          registrationDomain,
          address: $event.location.address,
          viewed: false
        };
        const data        = await this.wl.getDynamicWhiteLabel();
        const companyName = data.branding.company_name;
        const domain      = data.branding.domain;
        const titlePass   = `Grade | ${gradeLead.locationName} | ${companyName}`;
        this.titleService.setTitle(titlePass);

        const metaData: LogGrade = {
          accountId: this.accountId,
          locationId: this.locationId,
          locationName: gradeLead.locationName,
          email: user.email,
          displayName: user.name
        };

        const newLog: Log = {
          create_at: new Date(),
          domain: domain,
          gid: this.user.gid,
          message: `A new public grade was created for ${gradeLead.locationName}`,
          type_log: 'Grade',
          meta_data: metaData
        };
        await this.logService.saveLog(newLog);
        this.gradeLeadService.save(gradeLead);
        this.grade = true;
        this.loading = false;

      } else {
        this.loading = false;
      }
    } catch (e) {
      console.log(e);
      this.snack.openError('There was a problem accessing your account. Please try again later.', 2000);
      this.loading = false;
    }
  }

  async loadGradeLead(gradeLead) {
    this.loading = true;
    this.accountId = gradeLead.accountId;
    this.user = this.auth.session;
    this.userExternal = await this.auth.createExternalGradeUser();
    this.auth.gradeExternalUser = this.userExternal;
    this.locationId = gradeLead.locationId;
    this.locations = { locationId: this.locationId, accountId: this.accountId };
    this.grade = true;
    this.loading = false;

    const data = await this.wl.getDynamicWhiteLabel()
    const company_name = data.branding.company_name;
    const titlePass = `Grader | ${gradeLead.locationName} | ${company_name}`;
    this.titleService.setTitle(titlePass);
    
  }

  closePopup(event: any) {
    event.preventDefault();
    this.showGradePopup = false;
  }

  subscribeToGoogleResponse() {
    const sub = this.googleAccountS.accounts.subscribe(accounts => {
      this.accounts = accounts;
      if (!_.isNil(accounts) && !_.isEmpty(accounts) && !this.addAccountDialog) {
        this.addAccountDialog = this.dialog.open(AccountLocSelectorComponent, {
          width: '680px',
          data: { accounts: this.accounts, user: this.user },
          autoFocus: false
        });

        this.addAccountDialog.backdropClick().subscribe(() => {
          this.addAccountDialog = null;
          this.dialog.closeAll();
        });

        this.addAccountDialog.afterClosed()
          .subscribe(response => {
            sub.unsubscribe();
            this.googleAccountS.resetAccounts();
            this.addAccountDialog = null;
            this.dialog.closeAll();
            if (response) {
              this.loading = true;
              this.handleSelected(response);
            }
          });
      }
    });
  }

  redirectTo() {
    window.location.href = (this.env.branding?.signUp_url  || '/register')
  }
}
