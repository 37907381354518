import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-grade',
  templateUrl: './skeleton-grade.component.html'
})
export class SkeletonGradeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
