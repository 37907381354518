import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {GoogleService} from '../../services/google.service';
import {LocationEditService} from '../../services/location-edit.service';
import {GroupAttributes} from '../../constants/google/attributes';
import * as _ from 'lodash';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { REGEX_COMPLEX_URL } from '../../constants/regex';
@Component({
  selector: 'app-modal-attributes',
  templateUrl: './modal-attributes.component.html',
  styleUrls: ['./modal-attributes.component.scss']
})
export class ModalAttributesComponent implements OnInit {
  @Input() groups: GroupAttributes[] = [];
  @Input() originalGroups: GroupAttributes[];
  @Input() excludedAtributes: GroupAttributes[] = [];
  @Input() bulk: boolean = false;
  
  @Output() validResult: EventEmitter<boolean> = new EventEmitter(false);

  public addUrlIndex: any;
  public isUrlInvalid: boolean;
  public newUrl: string;
  public isFiltered: boolean;
  public isSocialMedia = false;
  public socialMediaAttributes = [];
  public socialMediaData: FormArray
  public allSocialMediaOptions = [];

  constructor(
    private _dialogRef: MatDialogRef<ModalAttributesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { groupAttributes: GroupAttributes[], excludedAtributes: GroupAttributes[], isSocialMedia: false, socialMedia: [] },
    private _googleS: GoogleService,
    private _locationEditS: LocationEditService,
  ) {
    if (data) {
      this.isSocialMedia = data?.isSocialMedia;
      this.socialMediaAttributes = data?.socialMedia;
      if (this.data.groupAttributes) {
        this.groups = this.originalGroups = JSON.parse(JSON.stringify(this.data.groupAttributes));
      }
    }
    
  }
  
  ngOnInit() {
    this.allSocialMediaOptions = this._locationEditS.allSocialMediaOptions;
    this.initForm();
  }

  initForm(): void {
    this.socialMediaData = new FormArray([]);
    
    this.socialMediaAttributes?.forEach( el => {
      this.addSocialMedia(el.attributeId, el.urlValues[0].url)
    });
  }

  addSocialMedia(socialMedia = null, url = null): void {
    this.socialMediaData.push(new FormGroup({
      socialMedia: new FormControl(socialMedia, [Validators.required]),
      url: new FormControl(url, [Validators.required, Validators.pattern(REGEX_COMPLEX_URL)])
    }));
  }

  removeSocialMedia(index): void {
    this.socialMediaData.removeAt(index);
  }

  getLabel(label): string {
    return label?.split('_')[1]
  }

  getAvailableOptions(control: FormGroup): any[] {
    const selectedValues = this.socialMediaData.controls
      .filter(c => c !== control)
      .map(c => c.get('socialMedia')?.value);
    return this.allSocialMediaOptions.filter(option => !selectedValues.includes(option.value));
  }

  getOption(control: FormGroup): any[] {
    const selectedValue = control.get('socialMedia')?.value;
    return this.allSocialMediaOptions.find(opt => opt.value === selectedValue);
  }

  /**
   * this method change true of false attributes with bool type
   */
  changeStatus(groupIndex, itemIndex): void {
    let originalGroupIndex = groupIndex;
    let originalItemIndex = itemIndex;
    if (this.isFiltered) {
      const { indexGroup, indexItem, indexMeta } = this.findIndexes(this.groups[groupIndex].groupName,
        this.groups[groupIndex].items[itemIndex].attributeId);
      originalGroupIndex = indexGroup;
      originalItemIndex = indexItem;
    }

    const value = this.groups[groupIndex].items[itemIndex].active;
    const type = this.groups[groupIndex].items[itemIndex].valueType;
    const values = this.groups[groupIndex].items[itemIndex].valueMetadata;
    let newValue = null;
    if (type === 'REPEATED_ENUM') {
      newValue = !_.isEmpty(_.reject(values.map(v => v.active), (o) =>  _.isNil(o)));
    } else {
      switch (value) {
        case true:
          newValue = false;
          break;
        case false:
          newValue = null;
          break;
        case null:
          newValue = true;
          break;
      }
    }
    this.groups[groupIndex].items[itemIndex].active = newValue;
    this.originalGroups[originalGroupIndex].items[originalItemIndex].active = newValue;
  }


  apply(): void {
    if(this.isSocialMedia && !this.socialMediaData.valid) { 
      this.socialMediaData.markAllAsTouched();
      return
    }

    const attributes = this._googleS.attributesToGMB(this.originalGroups);
    this._locationEditS.locationEdit.attributes = [...attributes, ... this.excludedAtributes];
    this.builSocialMediaData();
    this._locationEditS.setAndUpdate().toPromise().then(() => {
      this._dialogRef.close({attributes});
    });
  }
  
  builSocialMediaData(): void {
    const data = []
    this.socialMediaData?.value?.forEach(el => {
      const url = el?.url?.replace(/ /g, '%20')
      this._locationEditS.locationEdit.attributes.push({
        attributeId: el.socialMedia,
        name: `attributes/${el.socialMedia}`,
        urlValues: [
          {url: url}
        ],
        valueType: "URL"
      });
    })
  }

  getResult(): { attributes: GroupAttributes[]} {
    if(this.isSocialMedia && !this.socialMediaData.valid) { 
      this.socialMediaData.markAllAsTouched();
      return
    }
    const attributes = this._googleS.attributesToGMB(this.originalGroups);
    this.validResult.emit(true);
    return { attributes: [...attributes, ... this.excludedAtributes] }
  }


  handleShowAddUrl(itemIndex: number, url: { url: string }[] | undefined = undefined): void {
    if (url) {
      this.newUrl = url[0].url;
    }

    if (this.addUrlIndex === itemIndex) {
      this.addUrlIndex = null;
      return;
    }
    this.addUrlIndex = itemIndex;
  }

  addUrl(groupIndex: number, itemIndex: number): void {
    if (!this.newUrl) {
      this.isUrlInvalid = true;
      return;
    }
    this.groups[groupIndex].items[itemIndex].urlValues = [{url: this.newUrl}];
    this.originalGroups[groupIndex].items[itemIndex].urlValues = [{url: this.newUrl}];
    this.newUrl = null;
    this.addUrlIndex = null;
  }

  changeStatusEnum(groupIndex: number, itemIndex: number, metaIndex: number): void {
    let originalGroupIndex = groupIndex;
    let originalItemIndex = itemIndex;
    let originalMetaIndex = metaIndex;
    if (this.isFiltered) {
      const { indexGroup, indexItem, indexMeta } = this.findIndexes(this.groups[groupIndex].groupName,
        this.groups[groupIndex].items[itemIndex].attributeId, this.groups[groupIndex].items[itemIndex].valueMetadata[metaIndex].value);
      originalGroupIndex = indexGroup;
      originalItemIndex = indexItem;
      originalMetaIndex = indexMeta;
    }
    const value = this.groups[groupIndex].items[itemIndex].valueMetadata[metaIndex].active;


    let newValue = null;
    switch (value) {
      case true:
        newValue = false;
        break;
      case false:
        newValue = null;
        break;
      case undefined :
        newValue = true;
        break;
      case null :
        newValue = true;
        break;
    }


    this.groups[groupIndex].items[itemIndex].valueMetadata[metaIndex].active = newValue;
    this.originalGroups[originalGroupIndex].items[originalItemIndex].valueMetadata[originalMetaIndex].active = newValue;
  }

  // apply filter from search
  applyFilter($event: string): void {
    if (!$event || $event === '') {
      this.groups = this.originalGroups;
      this.isFiltered = false;
      return;
    }
    const result = [];
    this.originalGroups.forEach((group) => {
      const searchGroupName = group.groupName.toLowerCase().includes($event.toLowerCase());
      const searchItemsName = group.items.filter(item => item.displayName.toLowerCase().includes($event.toLowerCase()));
      searchGroupName && result.push({groupName: group.groupName, items: searchItemsName, active: group.active });
      !searchGroupName && !_.isEmpty(searchItemsName) && result.push({groupName: group.groupName, items: searchItemsName, active: group.active });
    });
    this.groups = result;
    this.isFiltered = true;
  }

  findIndexes(group, attr, meta?) {
    const indexGroup = _.findIndex(this.originalGroups, { 'groupName': group });
    const indexItem = _.findIndex(this.originalGroups[indexGroup].items, { 'attributeId': attr });
    const indexMeta = meta ? _.findIndex(this.originalGroups[indexGroup].items[indexItem].valueMetadata, { 'value': meta }) : null;

    return { indexGroup, indexItem, indexMeta };
  }

  hasError(index, field, error):boolean {
    const control = this.socialMediaData?.at(index);
    return control?.get(field)?.hasError(error) && control.touched;
  }

  isBoolean(group): boolean {
    const url = group?.items?.filter(item => item?.valueType === 'URL');

    return group?.items?.length === url?.length;
  }

  isNotLastVisibleGroup(groupIndex: number): boolean {
    const visibleGroups = this.groups.filter(group => 
      group?.items?.some(item => item?.valueType !== 'URL')
    );
    
    return visibleGroups.indexOf(this.groups[groupIndex]) !== visibleGroups.length - 1;
  }
}