
<ng-container *ngIf="type === 'icon'">
  <div class="box__item box__item--editable">
    <div class="d-flex align-items-center justify-content-between w--100">
      <div class="d-flex align-items-center">
        <div class="icon icon--rounded icon--gray mr--20">
          <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ 'margin-bottom': '0' }"> </ngx-skeleton-loader>
        </div>
        <ngx-skeleton-loader count="1" [theme]="{ height: '20px', width: '100px', 'margin-bottom': '0' }"> </ngx-skeleton-loader>
      </div>

      <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ 'margin': '0 20px 0 0' }"> </ngx-skeleton-loader>
    </div>
  </div>
</ng-container>

