import { ErrorHandler } from '@angular/core';
import { AuthService } from './auth.service';

export class GlobalErrorHandler extends ErrorHandler {
  constructor(private _auth: AuthService) {
    super();
  }

  async handleError(error : any) : Promise<void> {
    if (error?.name === 'FirebaseError') {
      console.error('Error FirebaseError from GlobalErrorHandler', error);
      await this._auth.signOut(true, true);
    }
    throw error;
  }
}