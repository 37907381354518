
<div class="box box--shadow" [ngStyle]="{'border-radius': '8px'}" *ngFor="let foodMenu of foodMenuList; let foodMenuIndex = index">
  <div class="box__header box__header--xs justify-content-between align-items-center d-flex" [ngStyle]="{'background-color': '#f1f3f4', 'border-radius': '8px 8px 0px 0px'}">
    <!-- <p class="txt--lg m--0"><strong>{{sectionLabel?.displayName}}</strong></p> -->
    <div style="width: 90%;">
      <p class="txt--lg m--0"><strong>{{foodMenu.labels[0].displayName}}</strong></p>
      
      <ng-container *ngIf="foodMenu.cuisines && foodMenu.cuisines.length > 0">
        <small>{{foodMenu.cuisines.length > 1 ? convertKeysCuisines(foodMenu.cuisines).join(', ') : convertKeysCuisines(foodMenu.cuisines)[0]}}</small>
      </ng-container>

      <ng-container *ngIf="foodMenu.labels[0]?.description">
        <p class="txt--left w--100" style="cursor: pointer;" *ngIf="foodMenu.labels[0]?.description && foodMenu.labels[0].description.length <= 60" >{{ foodMenu.labels[0].description}}</p>
        <p class="txt--left txt--black mb--10" style="text-align: justify; white-space: normal; overflow-wrap: break-word;" *ngIf="foodMenu.labels[0]?.description && foodMenu.labels[0].description.length > 60">
          {{(showMoreDescriptionMessage) ? foodMenu.labels[0]?.description :  foodMenu.labels[0].description | limitTo: '60'}}
          <a (click)="showMoreDescriptionMessage = !showMoreDescriptionMessage" class="btn txt--underline txt--lightblue"
              *ngIf="foodMenu.labels[0]?.description?.length > 60">{{ showMoreDescriptionMessage  ? 'read less' : 'read more'}}</a>
        </p>
      </ng-container>
    </div>
    <div style="float: right; cursor: pointer; padding: 10px;" *ngIf="!onlyShow"  (click)="editMenu(foodMenuIndex)">
      <i class="fas fa-ellipsis-v"></i>
    </div>
  </div>
  <div class="table--shadow mb--30">
    <div class="mat-row mat-row--padding mat-row--border justify-content-between align-items-center bg--white" style="padding: 10px 25px;">
      <div class="dialog-row" *ngFor="let section of foodMenu.sections; let sectionIndex = index">
        <div class="box box--shadow" [ngStyle]="{'border-radius': '8px'}">
          <div class="box__header box__header--xs justify-content-between align-items-center d-flex" [ngStyle]="{'background-color': '#f1f3f4', 'border-radius': '8px 8px 0px 0px'}">
            <!-- <p class="txt--lg m--0"><strong>{{sectionLabel?.displayName}}</strong></p> -->
            <p class="txt--lg m--0"><strong>{{section.labels[0].displayName}}</strong></p>
            <div style="float: right; cursor: pointer; padding: 10px;" *ngIf="!onlyShow"  (click)="addSection(foodMenuIndex, sectionIndex)">
              <i class="fas fa-ellipsis-v"></i>
            </div>
          </div>

          <div name="items" >
            <ul class="list-group list-group-flush">
              <li class="list-group-item border-bottom" style="margin: 5px 0;" *ngFor="let item of section.items; let itemIndex = index">
                  <div class="row box__content box__content--padding-xs w--100">
                    <div class="col-10">
                      <h3 class="txt--left" style="margin: 5px 0;cursor: pointer;" (click)="addItem(foodMenuIndex, sectionIndex, itemIndex)">{{item.labels[0].displayName}}</h3>
                      <h5 class="txt--left" style="margin: 5px 0;cursor: pointer;" (click)="addItem(foodMenuIndex, sectionIndex, itemIndex)">
                        <ng-container *ngIf="item.attributes === null || item.attributes === undefined; else HasAttributes">
                          Priceless
                        </ng-container>
                        <ng-template #HasAttributes>
                          <ng-container *ngIf="item.attributes.price === null || item.attributes.price === undefined; else Money">
                            Priceless
                          </ng-container>
                          <ng-template #Money>
                            <ng-container *ngIf="(item.attributes.price?.units === '0') && (item.attributes.price?.nanos === 0)">
                              Free
                            </ng-container>
                            <ng-container *ngIf="(item.attributes.price?.units !== '0') && (item.attributes.price?.nanos !== null && item.attributes.price?.nanos !== 0); else lackMoney">
                              {{ parseMoney(item.attributes.price?.units, item.attributes.price?.nanos) | currency }} {{item.attributes.price?.currencyCode}}
                            </ng-container>
                            <ng-template #lackMoney>
                              <ng-container *ngIf="item.attributes.price?.units !== '0'">
                                {{ parseMoney(item.attributes.price?.units, 0) | currency }} {{item.attributes.price?.currencyCode}}
                              </ng-container>
                              <ng-container *ngIf="item.attributes.price?.nanos  !== 0">
                                {{ parseMoney(0, item.attributes.price?.nanos) | currency }} {{item.attributes.price?.currencyCode}}
                              </ng-container>
                              <ng-container *ngIf="(item.attributes.price?.units === null || item.attributes.price?.units === undefined) && (item.attributes.price?.nanos ===null ||  item.attributes.price?.nanos === undefined )">
                                {{ parseMoney(0, 0) | currency }} {{item.attributes.price?.currencyCode}}
                              </ng-container>
                            </ng-template>
                          </ng-template>
                        </ng-template>
                        

                        
                      </h5>
                      <ng-container *ngIf="item.labels[0]?.description">
                        <p class="txt--left w--100" style="cursor: pointer;" *ngIf="item.labels[0].description.length <= 60" >{{ item.labels[0].description}}</p>
                        <p class="txt--left txt--black mb--10" style="text-align: justify;white-space: normal; overflow-wrap: break-word;" *ngIf="item.labels[0].description.length > 60">
                          {{(showMoreMessage && showMoreMessageIndex === section.labels[0].displayName+'.'+itemIndex) ? item.labels[0]?.description :  item.labels[0].description | limitTo: '60'}}
                          <a (click)="toggleMessage(section.labels[0].displayName+'.'+itemIndex)" class="btn txt--underline txt--lightblue"
                              *ngIf="item.labels[0]?.description?.length > 60">{{ showMoreMessage && showMoreMessageIndex === section.labels[0].displayName+'.'+itemIndex ? 'read less' : 'read more'}}</a>
                        </p>
                      </ng-container>
                    </div>
                    <div class="col-2 txt--right" style="margin: auto;cursor: pointer;" (click)="addItem(foodMenuIndex, sectionIndex, itemIndex)">
                      <i class="fas fa-chevron-right txt--lg"></i>
                    </div>
                  </div>
              </li>
            </ul>
            
          </div>

          <div class="box__content box__content--padding-xs" *ngIf="!onlyShow">
            <button mat-button tabIndex="-1" style="color: #1a73e8;" (click)="addItem(foodMenuIndex, sectionIndex)">
              <p  class="m--0" style="padding: 10px 5px; font-weight: 100;"><span style="font-size: 17px;">+</span> Add item</p>
            </button>
          </div>

        </div>
      </div>

      <div class="dialog-row" name="add_section" *ngIf="!onlyShow">
        <div class="box box--shadow selectable" [ngStyle]="{'border-radius': '8px'}" (click)="addSection(foodMenuIndex)" >
          <h3  class="my--5" style="padding: 10px 15px; font-weight: 300; color: #1a73e8;">Add new section</h3>
        </div>
      </div>

    </div>
  </div>
</div>
