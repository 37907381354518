import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ReviewsService } from '../../services/reviews.service';
import { Protocol } from '../../constants/firestore/protocol';

@Component({
  selector: 'app-resume-create-protocol',
  templateUrl: './resume-create-protocol.component.html',
  styleUrls: ['./resume-create-protocol.component.scss']
})
export class ResumeCreateProtocolComponent implements OnInit {
  @Input() protocol: Protocol = null;
  @Output() isLoading = new EventEmitter();

  public progress$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public delay = 0;
  public counter: BehaviorSubject<{Replied: number, Total: number, NotReplied: number}> = new BehaviorSubject(null);
  public data_copy: {Replied: number, Total: number, NotReplied: number} = {Replied: 0, Total: 0, NotReplied: 0};

  constructor(private reviewS: ReviewsService) { }

  ngOnInit(): void {
    //this.GetCounters();
  }

  GetCounters(): void {
    this.progress$.next(true)
    this.counter.next(null);
    this.delay = Number(this.protocol?.triggers?.delay);
    this.reviewS.protocolCheckCounterReview(this.protocol).then(
      data => {
        this.progress$.next(false)
        if (typeof(data) === "string") {
          data = {Replied: 0, Total: 0, NotReplied: 0}
        }
        if (data.NotReplied === undefined) data['NotReplied'] = 0
        if (data.Replied === undefined) data['Replied'] = 0
        if (data.Total === undefined) data['Total'] = 0
        this.data_copy = data;
        this.counter.next(data);
        this.isLoading.emit(false);
      },
      err => {
        this.progress$.next(false);
        this.isLoading.emit(false);
      })
  }

  orderStarts(stars: number[]) {
    return stars.sort((a, b) => b - a)
  }

  get Counters() {
    return this.data_copy;
  }

}
