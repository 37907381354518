/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./read-more.component";
var styles_ReadMoreComponent = [];
var RenderType_ReadMoreComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ReadMoreComponent, data: {} });
export { RenderType_ReadMoreComponent as RenderType_ReadMoreComponent };
function View_ReadMoreComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "link link--blue txt--normal ml--5"]], [[2, "link--white", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleView() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, ["", " ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isWhite; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.txtLink; var currVal_2 = (_co.isCollapsed ? "more" : "less"); _ck(_v, 1, 0, currVal_1, currVal_2); }); }
export function View_ReadMoreComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["style", "display: inline;"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showToggleButton; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentText; _ck(_v, 0, 0, currVal_0); }); }
export function View_ReadMoreComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "read-more", [], null, null, null, View_ReadMoreComponent_0, RenderType_ReadMoreComponent)), i0.ɵdid(1, 573440, null, 0, i2.ReadMoreComponent, [], null, null)], null, null); }
var ReadMoreComponentNgFactory = i0.ɵccf("read-more", i2.ReadMoreComponent, View_ReadMoreComponent_Host_0, { text: "text", maxLength: "maxLength", showToggleButton: "showToggleButton", isWhite: "isWhite", txtLink: "txtLink" }, {}, []);
export { ReadMoreComponentNgFactory as ReadMoreComponentNgFactory };
