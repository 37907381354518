import { Component, OnInit } from '@angular/core';
import { IS_IMPERSONATING } from 'src/app/constants/session';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-impersonate-message',
  templateUrl: './impersonate-message.component.html',
  styleUrls: ['./impersonate-message.component.scss']
})
export class ImpersonateMessageComponent implements OnInit {
  isImpersonating = false;
  user;

  constructor(public auth: AuthService) {}
  ngOnInit(): void {
    this.user = this.auth.session;
    this.isImpersonating = !!localStorage.getItem(IS_IMPERSONATING);
  }

  handleLogOut(): void {
    // Stop Impersonating
    this.auth.signOut();
  }

}
