import { __assign, __read, __spread, __values } from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { AuthService } from 'src/app/services/auth.service';
import { GoogleService } from '../../services/google.service';
import { LocationEditService } from '../../services/location-edit.service';
import { SnackbarService } from '../../services/snackbar.service';
import { ORDER_LOCATION_INFO } from '../../constants/google/location-stats';
import { DatesService } from '../../services/dates.service';
import * as jsdiff from 'diff';
var ModalFetchComponent = /** @class */ (function () {
    function ModalFetchComponent(data, _dialogRef, _googleS, _locationS, _snack, _dateS, _authS) {
        var _this = this;
        var _a;
        this.data = data;
        this._dialogRef = _dialogRef;
        this._googleS = _googleS;
        this._locationS = _locationS;
        this._snack = _snack;
        this._dateS = _dateS;
        this._authS = _authS;
        this.history = false;
        this.notifyErrors = false;
        this.pendingMask = [];
        this.diffMask = [];
        this.title = 'Fetch';
        this.isDiffMask = false;
        this.location = (_a = this.data) === null || _a === void 0 ? void 0 : _a.location;
        this.difference = this.data.difference;
        this.pendingMask = this.getPendingMask();
        this.diffMask = this.data.diffMask;
        this.isDiffMask = this.data.isDiffMask;
        this.title = this.data.title ? this.data.title : 'Fetch';
        this.notifyErrors = this.data.notifyErrors;
        this.error = this.data.error;
        this.error_details = this.data.error_details;
        this.history = this.data.history;
        this.moreHoursLabels = this.data.moreHoursLabels;
        _.isObject(this.difference) && _.isEmpty(this.difference) && (this.difference = []);
        if (this.difference) {
            this.obtainDifference();
            var hasPrimarycategory = _.find(this.difference, { 'key': 'primaryCategory' });
            var hasAdditionalcategories = _.find(this.difference, { 'key': 'additionalCategories' });
            if (_.isEmpty(hasPrimarycategory) && !_.isEmpty(hasAdditionalcategories) || !_.isEmpty(hasPrimarycategory) && _.isEmpty(hasAdditionalcategories)) {
                this.showBothCategories = true;
                this.omitedCategory = _.isEmpty(hasPrimarycategory) ? 'primaryCategory' : 'additionalCategories';
                this.categoriesData = this._locationS.locationEdit;
            }
            var hasAttributes_1 = _.find(this.difference, { 'key': 'attributes' });
            if (!_.isEmpty(hasAttributes_1)) {
                hasAttributes_1.googleLocation = _.uniqBy(hasAttributes_1.googleLocation, 'attributeId');
                hasAttributes_1.location = _.uniqBy(hasAttributes_1.location, 'attributeId');
                var diffInGoogle = _.differenceBy(hasAttributes_1.googleLocation, hasAttributes_1.location, 'attributeId');
                if (!_.isEmpty(diffInGoogle)) {
                    diffInGoogle.forEach(function (gl) {
                        var attribute = _.clone(gl);
                        delete attribute.urlValues;
                        delete attribute.repeatedEnumValue;
                        attribute.values && (attribute.values = []);
                        hasAttributes_1.location.push(attribute);
                    });
                }
                var diffInLocation = _.differenceBy(hasAttributes_1.location, hasAttributes_1.googleLocation, 'attributeId');
                if (!_.isEmpty(diffInLocation)) {
                    diffInLocation.forEach(function (l) {
                        var attribute = _.clone(l);
                        delete attribute.urlValues;
                        delete attribute.repeatedEnumValue;
                        attribute.values && (attribute.values = []);
                        hasAttributes_1.googleLocation.push(attribute);
                    });
                }
                hasAttributes_1.location = _.orderBy(hasAttributes_1.location, ['attributeId'], ['asc']);
                hasAttributes_1.googleLocation = _.orderBy(hasAttributes_1.googleLocation, ['attributeId'], ['asc']);
                var googleAttr = !_.isEmpty(hasAttributes_1.googleLocation) && hasAttributes_1.googleLocation || [];
                var locationAttr = !_.isEmpty(hasAttributes_1.location) && hasAttributes_1.location || [];
                var attributes = __spread(googleAttr, locationAttr);
                this.hasAttributesUrl = !_.isEmpty(_.find(attributes, function (o) { return _.startsWith(o.attributeId, 'url'); }));
                this.hasAttributes = !_.isEmpty(_.find(attributes, function (o) { return !_.startsWith(o.attributeId, 'url'); }));
                this.hasSocialMedia = !_.isEmpty(_.find(attributes, function (o) { return _this._locationS.isSocialMedia(o); }));
            }
            this.difference.forEach(function (diff) {
                var _a, _b;
                diff.key = (_b = (_a = diff) === null || _a === void 0 ? void 0 : _a.key) === null || _b === void 0 ? void 0 : _b.toLowerCase();
                return diff;
            });
            this.difference = this.orderingFetch(this.difference);
        }
        this.locationId = this.data.placeId;
    }
    ModalFetchComponent.prototype.ngOnInit = function () { };
    ModalFetchComponent.prototype.isEmptyObject = function (obj) {
        for (var property in obj) {
            if (obj.hasOwnProperty(property)) {
                return false;
            }
        }
        return true;
    };
    ModalFetchComponent.prototype.keys = function (object) {
        return Object.keys(object);
    };
    ModalFetchComponent.prototype.getPendingMask = function () {
        var _a, _b;
        var index = (_b = (_a = this.data) === null || _a === void 0 ? void 0 : _a.pendingMask) === null || _b === void 0 ? void 0 : _b.findIndex(function (el) { return el == 'metadata'; });
        if (index > 0) {
            this.data.pendingMask.splice(index, 1);
        }
        return this.data.pendingMask || [];
    };
    ModalFetchComponent.prototype.camelCase = function (string) {
        return string.split(' ')
            .map(function (word) { return word.charAt(0).toUpperCase() + word.slice(1); });
    };
    ModalFetchComponent.prototype.isString = function (value) {
        return typeof (value) === 'string' ? true : false;
    };
    ModalFetchComponent.prototype.apply = function (select) {
        var _this = this;
        this.select = select;
        this.isProgress = true;
        this._googleS.fetch(this.select, this._locationS.accountId, this._locationS.locationId).then(function (result) {
            var _a, _b, _c, _d, _e;
            if (((_b = (_a = result) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.success) == 200) {
                _this.select = null;
                _this.isProgress = false;
                _this.difference = [];
                _this._dialogRef.close(!_this.history);
                _this._snack.openSuccess((_c = result) === null || _c === void 0 ? void 0 : _c.message, 2000);
            }
            else {
                _this.select = null;
                _this.isProgress = false;
                _this.difference = [];
                _this._dialogRef.close(!_this.history);
                _this._snack.openError((_e = (_d = result) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.message, 4000);
            }
        }, function (err) {
            var _a, _b, _c;
            _this.select = null;
            _this.isProgress = false;
            _this.difference = [];
            _this._dialogRef.close(!_this.history);
            _this._snack.openError((_c = (_b = (_a = err) === null || _a === void 0 ? void 0 : _a.error) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message, 4000);
        });
    };
    ModalFetchComponent.prototype.setValueByPath = function (obj, path, value) {
        var _a;
        if (!obj || !path || path.length === 0) {
            return;
        }
        var current = obj;
        for (var i = 0; i < path.length - 1; i++) {
            if (!current[path[i]]) {
                current[path[i]] = {};
            }
            current = current[path[i]];
        }
        current[path[path.length - 1]] = (_a = value) === null || _a === void 0 ? void 0 : _a.value;
    };
    ModalFetchComponent.prototype.acceptChangesFromGoogle = function () {
        var _this = this;
        var _a, _b;
        this.isProgress = true;
        this.select = 2;
        var fieldsToModify = (_a = this.data) === null || _a === void 0 ? void 0 : _a.diffMask;
        var locationEdit = this._locationS.locationEdit;
        (_b = fieldsToModify) === null || _b === void 0 ? void 0 : _b.forEach(function (el) {
            var keys = el.key.includes('.') ? el.key.split('.') : [el.key];
            var lastKey = keys[keys.length - 1];
            var value = _this.setValueByPath(locationEdit, keys, el);
        });
        this.saveData();
    };
    ModalFetchComponent.prototype.saveData = function () {
        var _this = this;
        var msg = 'There was an error while saving the data. Please try again or contact support';
        this._locationS.setAndUpdate().subscribe(function (res) {
            var gid = _this._authS.session.gid;
            var differences = _this.difference;
            differences.forEach(function (el) {
                delete el.diffLoc;
                delete el.diffGoogle;
            });
            _this._googleS.diffMaskPush(gid, _this._locationS.accountId, _this._locationS.locationId, differences).subscribe(function (result) {
                var _a;
                _this.select = null;
                _this.isProgress = false;
                _this.difference = [];
                _this._dialogRef.close(!_this.history);
                _this._snack.openSuccess((_a = result) === null || _a === void 0 ? void 0 : _a.message, 2000);
            }, function (error) {
                _this.handleError(msg);
            });
        }, function (err) {
            _this.handleError(msg);
        });
    };
    ModalFetchComponent.prototype.getValue = function (value) {
        var _a, _b, _c, _d;
        var data = (_a = value) === null || _a === void 0 ? void 0 : _a.value;
        return typeof (data) !== 'object' ? data : ((_b = data) === null || _b === void 0 ? void 0 : _b.displayName) ? data.displayName : (_d = (_c = data) === null || _c === void 0 ? void 0 : _c.map(function (el) { var _a; return (_a = el) === null || _a === void 0 ? void 0 : _a.displayName; })) === null || _d === void 0 ? void 0 : _d.join(' | ');
    };
    ModalFetchComponent.prototype.handleError = function (msg) {
        this.select = null;
        this.isProgress = false;
        this.difference = [];
        this._dialogRef.close(!this.history);
        this._snack.openError(msg, 4000);
    };
    ModalFetchComponent.prototype.openInfoDate = function (openDating) {
        return this._dateS.dateJsonToDate(openDating);
    };
    ModalFetchComponent.prototype.orderingFetch = function (diferences) {
        var e_1, _a;
        var result = [];
        var keys = diferences.map(function (d) { return d.key; });
        try {
            for (var ORDER_LOCATION_INFO_1 = __values(ORDER_LOCATION_INFO), ORDER_LOCATION_INFO_1_1 = ORDER_LOCATION_INFO_1.next(); !ORDER_LOCATION_INFO_1_1.done; ORDER_LOCATION_INFO_1_1 = ORDER_LOCATION_INFO_1.next()) {
                var key = ORDER_LOCATION_INFO_1_1.value;
                if (_.includes(keys, key)) {
                    result.push(_.find(diferences, { 'key': key }));
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (ORDER_LOCATION_INFO_1_1 && !ORDER_LOCATION_INFO_1_1.done && (_a = ORDER_LOCATION_INFO_1.return)) _a.call(ORDER_LOCATION_INFO_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return result;
    };
    ModalFetchComponent.prototype.obtainDifference = function () {
        var _this = this;
        var _a;
        var htmlDiffs, keys, google, location;
        (_a = this.difference) === null || _a === void 0 ? void 0 : _a.forEach(function (el) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
            var key = (el.key).toLowerCase();
            if (key === 'locationname' || key === 'websiteurl' || key === 'profile' ||
                key === 'storecode' || key === 'adwordslocationextensions' || key === 'primaryphone' || key === 'primarycategory') {
                switch (key) {
                    case 'adwordslocationextensions':
                        google = (_a = el.googleLocation) === null || _a === void 0 ? void 0 : _a.adPhone;
                        location = (_b = el.location) === null || _b === void 0 ? void 0 : _b.adPhone;
                        break;
                    case 'profile':
                        google = (_c = el.googleLocation) === null || _c === void 0 ? void 0 : _c.description;
                        location = (_d = el.location) === null || _d === void 0 ? void 0 : _d.description;
                        break;
                    case 'primarycategory':
                        google = (_e = el.googleLocation) === null || _e === void 0 ? void 0 : _e.displayName;
                        location = (_f = el.location) === null || _f === void 0 ? void 0 : _f.displayName;
                        break;
                    default:
                        google = el.googleLocation;
                        location = el.location;
                        break;
                }
                htmlDiffs = _this.buildHtmlByDifference((google || ''), (location || ''));
                el.diffLoc = htmlDiffs.diffLoc;
                el.diffGoogle = htmlDiffs.diffGoogle;
            }
            if (key === "labels" || key === "additionalphones") {
                _this.getArrayDifference(el);
            }
            if (key === 'additionalcategories') {
                el.diffLoc = _this.buildHtmlByArrays((el.location || []), (el.googleLocation || []), 'displayName', 'border--success');
                el.diffGoogle = _this.buildHtmlByArrays((el.googleLocation || []), (el.location || []), 'displayName', 'border--error');
            }
            if (key === 'address') {
                el.googleLocation = el.googleLocation || {};
                el.location = el.location || {};
                keys = (_g = Object.keys(el.googleLocation)) === null || _g === void 0 ? void 0 : _g.filter(function (k) { return k != 'diffGoogle'; });
                el.location.diffLoc = __assign({}, el.location);
                el.googleLocation.diffGoogle = __assign({}, el.googleLocation);
                keys.forEach(function (k) {
                    var _a, _b, _c, _d;
                    if (k !== 'addressLines') {
                        htmlDiffs = _this.buildHtmlByDifference((el.googleLocation[k] || ''), (el.location[k] || ''));
                        el.location.diffLoc[k] = htmlDiffs.diffLoc;
                        el.googleLocation.diffGoogle[k] = htmlDiffs.diffGoogle;
                    }
                    else {
                        el.location.diffLoc.addressLines = __spread(((_a = el.location) === null || _a === void 0 ? void 0 : _a.addressLines) || []);
                        el.googleLocation.diffGoogle.addressLines = __spread((_b = el.googleLocation) === null || _b === void 0 ? void 0 : _b.addressLines);
                        (_c = el.googleLocation[k]) === null || _c === void 0 ? void 0 : _c.forEach(function (g, i) {
                            var _a;
                            htmlDiffs = _this.buildHtmlByDifference(g, (((_a = el.location[k]) === null || _a === void 0 ? void 0 : _a[i]) || ''));
                            el.googleLocation.diffGoogle[k][i] = htmlDiffs.diffGoogle;
                        });
                        (_d = el.location[k]) === null || _d === void 0 ? void 0 : _d.forEach(function (l, i) {
                            var _a;
                            htmlDiffs = _this.buildHtmlByDifference((((_a = el.googleLocation[k]) === null || _a === void 0 ? void 0 : _a[i]) || ''), l);
                            el.location.diffLoc[k][i] = htmlDiffs.diffLoc;
                        });
                    }
                });
            }
            if (key === 'attributes') {
                el.diffLoc = JSON.parse(JSON.stringify(el.location));
                el.diffGoogle = JSON.parse(JSON.stringify(el.googleLocation));
                (_h = el.googleLocation) === null || _h === void 0 ? void 0 : _h.forEach(function (googleAttr, index) {
                    var _a, _b;
                    if (googleAttr.valueType == 'URL') {
                        el.diffGoogle[index].urlValues = el.diffGoogle[index].urlValues ? el.diffGoogle[index].urlValues : [];
                        var locAttr = el.location.find(function (l) { return googleAttr.attributeId == l.attributeId; });
                        _this.obtainUrlValues(locAttr);
                        el.diffGoogle[index].urlValues = _this.buildHtmlByArrays((_a = googleAttr) === null || _a === void 0 ? void 0 : _a.urlValues, (_b = locAttr) === null || _b === void 0 ? void 0 : _b.urlValues, 'url', 'border--error');
                    }
                });
                (_j = el.location) === null || _j === void 0 ? void 0 : _j.forEach(function (locAttr, index) {
                    var _a, _b, _c;
                    if (((_a = locAttr) === null || _a === void 0 ? void 0 : _a.valueType) == 'URL') {
                        el.diffLoc[index].urlValues = el.diffLoc[index].urlValues ? el.diffLoc[index].urlValues : [];
                        var googleAttr = el.googleLocation.find(function (g) { return g.attributeId == locAttr.attributeId; });
                        _this.obtainUrlValues(locAttr);
                        el.diffLoc[index].urlValues = _this.buildHtmlByArrays((_b = locAttr) === null || _b === void 0 ? void 0 : _b.urlValues, (_c = googleAttr) === null || _c === void 0 ? void 0 : _c.urlValues, 'url', 'border--success');
                    }
                });
            }
            if (key === 'placeactionlinks') {
                (_l = (_k = el) === null || _k === void 0 ? void 0 : _k.location) === null || _l === void 0 ? void 0 : _l.forEach(function (item, index) {
                    var _a, _b, _c;
                    el.diffLoc = [];
                    var diffLocation = __assign({}, item);
                    diffLocation.placeActionLinks = _this.buildHtmlByArrays((((_a = item) === null || _a === void 0 ? void 0 : _a.placeActionLinks) || []), (((_c = (_b = el) === null || _b === void 0 ? void 0 : _b.googleLocation[index]) === null || _c === void 0 ? void 0 : _c.placeActionLinks) || []), 'uri', 'border--success');
                    el.diffLoc.push(diffLocation);
                });
                (_o = (_m = el) === null || _m === void 0 ? void 0 : _m.googleLocation) === null || _o === void 0 ? void 0 : _o.forEach(function (item, index) {
                    var _a, _b, _c;
                    el.diffGoogle = [];
                    var diffGoogle = __assign({}, item);
                    diffGoogle.placeActionLinks = _this.buildHtmlByArrays((((_a = item) === null || _a === void 0 ? void 0 : _a.placeActionLinks) || []), (((_c = (_b = el) === null || _b === void 0 ? void 0 : _b.location[index]) === null || _c === void 0 ? void 0 : _c.placeActionLinks) || []), 'uri', 'border--error');
                    el.diffGoogle.push(diffGoogle);
                });
            }
        });
    };
    ModalFetchComponent.prototype.obtainUrlValues = function (data) {
        var _a;
        if (!((_a = data) === null || _a === void 0 ? void 0 : _a.uriValues)) {
            return;
        }
        data.urlValues = data.uriValues.map(function (i) { return { url: i.uri }; });
        delete data.uriValues;
    };
    ModalFetchComponent.prototype.buildHtmlByArrays = function (firstArray, secondArray, fieldModified, className) {
        var _a;
        (_a = firstArray) === null || _a === void 0 ? void 0 : _a.forEach(function (c) {
            var _a;
            var item = (_a = secondArray) === null || _a === void 0 ? void 0 : _a.findIndex(function (el) { return el[fieldModified] == c[fieldModified]; });
            c[fieldModified] = item == -1 || item == null ?
                "<span class=" + className + ">" + c[fieldModified] + "</span>" :
                c[fieldModified];
        });
        return firstArray;
    };
    ModalFetchComponent.prototype.getArrayDifference = function (el) {
        var _this = this;
        var _a, _b;
        var htmlDiffs;
        el.diffGoogle = [];
        el.diffLoc = [];
        (_a = el.googleLocation) === null || _a === void 0 ? void 0 : _a.forEach(function (g, i) {
            var _a, _b;
            htmlDiffs = _this.buildHtmlByDifference(g, (((_b = (_a = el) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b[i]) || ''));
            el.diffGoogle.push(htmlDiffs.diffGoogle);
        });
        (_b = el.location) === null || _b === void 0 ? void 0 : _b.forEach(function (l, i) {
            var _a;
            htmlDiffs = _this.buildHtmlByDifference((((_a = el.googleLocation) === null || _a === void 0 ? void 0 : _a[i]) || ''), l);
            el.diffLoc.push(htmlDiffs.diffLoc);
        });
    };
    ModalFetchComponent.prototype.buildHtmlByDifference = function (google, location) {
        var diff = jsdiff.diffWordsWithSpace(google, location);
        var locHtml = '';
        var googleHtml = '';
        diff.forEach(function (d) {
            if (d.added) {
                locHtml += "<span class=border--success>" + d.value + "</span>";
            }
            else if (d.removed) {
                googleHtml += "<span class=border--error>" + d.value + "</span>";
            }
            else if (!d.removed) {
                locHtml += "" + d.value;
                googleHtml += "" + d.value;
            }
        });
        return {
            diffLoc: locHtml,
            diffGoogle: googleHtml
        };
    };
    ModalFetchComponent.prototype.formatStatus = function (status) {
        if (status != 'OPEN') {
            status = status.split('_');
            status = status.join(' ');
        }
        return status;
    };
    ModalFetchComponent.prototype.hasOpeningData = function (data) {
        return data['openingDate'] && Object.keys(data.openingDate).length > 0 ? true : false;
    };
    return ModalFetchComponent;
}());
export { ModalFetchComponent };
