import { EventEmitter, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { LocationEditService } from "../../services/location-edit.service";
import { FormControl, FormGroup } from '@angular/forms';
var ModalGoogleAdsComponent = /** @class */ (function () {
    function ModalGoogleAdsComponent(dialogRef, data, locationEditS) {
        var _a;
        this.dialogRef = dialogRef;
        this.data = data;
        this.locationEditS = locationEditS;
        this.phoneForm = new FormGroup({
            phone: new FormControl('')
        });
        this.bulk = false;
        this.validResult = new EventEmitter(false);
        if (data) {
            var number = data.googleAds;
            if (number) {
                number = ((_a = data.googleAds) === null || _a === void 0 ? void 0 : _a.includes('+')) ? data.googleAds : "+1 " + data.googleAds;
            }
            this.phoneForm.controls.phone.setValue(number);
        }
    }
    ModalGoogleAdsComponent.prototype.ngOnInit = function () {
    };
    ModalGoogleAdsComponent.prototype.getResult = function () {
        var _a;
        if (!this.phoneForm.controls.phone.invalid) {
            this.validResult.emit(false);
            return;
        }
        this.validResult.emit(true);
        return { adWordsLocationExtensions: { adPhone: (_a = this.phone.value) === null || _a === void 0 ? void 0 : _a.internationalNumber } };
    };
    ModalGoogleAdsComponent.prototype.apply = function () {
        var _this = this;
        var _a;
        if (!this.phoneForm.controls.phone.invalid) {
            this.locationEditS.locationEdit.adWordsLocationExtensions = { adPhone: (_a = this.phone.value) === null || _a === void 0 ? void 0 : _a.internationalNumber };
            this.locationEditS.setAndUpdate().toPromise().then(function () { _this.dialogRef.close(); });
        }
    };
    Object.defineProperty(ModalGoogleAdsComponent.prototype, "phone", {
        get: function () {
            return this.phoneForm.controls.phone;
        },
        enumerable: true,
        configurable: true
    });
    return ModalGoogleAdsComponent;
}());
export { ModalGoogleAdsComponent };
