<mat-progress-bar *ngIf="exportInProgress" mode="query"></mat-progress-bar>
<div class="box box--rounded box--shadow bg--white box--padding-sm mb--30" *ngIf="location && !progress;else empty">
	<div class="row align-items-center">
		<div class="col-12 col-md-6" [ngClass]="{'mt--15 pt--20': dataPicker}">
			<h2 class="txt--xl txt--lightblue m--0"><strong>{{location.locationName}}</strong></h2>
			<gmb-address [isGray]="true" [address]="location?.location.address" [serviceAreas]="location?.location?.serviceArea?.places?.placeInfos"></gmb-address>
			<button class="btn btn--reset txt--sm txt--uppercase mb--10" data-html2canvas-ignore="true" (click)="openExplain()"><strong>How is this
				calculated?</strong></button>
			<!--share button-->


			<div class="d-flex mb--30" data-html2canvas-ignore>
				<button *ngIf="!isShared && !isExternal"
						mat-flat-button
						class="btn btn--between btn--icon btn--lightblue btn--md txt--uppercase mb--10 mr--10 pr--30"
						(click)="handleShared()">

					<i class="fas fa-share-alt"></i>
					<span class="btn__txt--xxs">Share this report</span>
				</button>
				<span class="alternate-theme">
          <button
				  (click)="handlePdf()"
				  mat-flat-button
				  color="primary"
				  class="btn btn--between btn--icon btn--md txt--uppercase btn-txt--red pr--30">
            <i class="fas fa-file-pdf"></i>
            <span class="btn__txt--xxs">Download as pdf</span>
          </button>
        </span>
			</div>
		</div>
		<div class="col-12 col-md-6 txt--center">
			<circle-progress
					[percent]="(grade <= 20) ? 20 : (grade <= 40 ? 40 : (grade <= 60 ? 60 : (grade <= 80) ? 80 : 100))"
					[outerStrokeGradient]="true"
					[outerStrokeColor]="(grade <= 20) ? '#ff2222' : (grade <= 40 ? '#f27e2b' : (grade <= 60 ? '#f2a32b' : (grade <= 80) ? '#1f8cf3' : '#39b54a'))"
					[outerStrokeGradientStopColor]="(grade <= 20) ? '#ff2222' : (grade <= 40 ? '#f27e2b' : (grade <= 60 ? '#f2a32b' : (grade <= 80) ? '#1f8cf3' : '#39b54a'))"
					outerStrokeWidth="25"
					innerStrokeWidth="25"
					space="-25"
					[showInnerStroke]="true"
					[animation]="true"
					[showUnits]="false"
					innerStrokeColor='#e7e8ea'
					animationDuration="400"
					title="Your Overall Grade"
					[subtitle]="(grade <= 20) ? 'F' : (grade <= 40 ? 'D' : (grade <= 60 ? 'C' : (grade <= 80) ? 'B' : 'A'))"
					titleFontSize="15"
					subtitleFontSize="40"
					titleFontWeight="700"
					subtitleFontWeight="700"
					subtitleColor="#25A1F7"
			></circle-progress>
		</div>
	</div>
</div>
<ng-template #empty>
	<app-no-data></app-no-data>
</ng-template>
