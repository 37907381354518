import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';
import { map, share } from 'rxjs/operators';
// app
import { WhiteLabelService } from './white-label.service';
import { DOMAINS } from '../constants/firestore/collections';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "./white-label.service";
// TODO: Unify with WhiteLabelService
var DomainService = /** @class */ (function () {
    function DomainService(afs, wl) {
        this.afs = afs;
        this.wl = wl;
        this.domain$ = new BehaviorSubject(null);
        this.ref = this.afs.collection(DOMAINS).ref;
        var domain = this.wl.slugDomain;
        this.searchDomain$ = this.searchIndex(domain).pipe(share(), map(function (res) { return res; }));
    }
    DomainService.prototype.searchIndex = function (xdomain) {
        return this.afs.collection(DOMAINS).doc(xdomain).valueChanges();
    };
    DomainService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DomainService_Factory() { return new DomainService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.WhiteLabelService)); }, token: DomainService, providedIn: "root" });
    return DomainService;
}());
export { DomainService };
