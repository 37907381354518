<p *ngIf="!isEdit" class="mb--15">Location State</p>
<div class="box__item box__item--sm box__item--editable" >
    <div>
        <p class="m--0 txt--lightgray" *ngFor="let locState of keys()">
            {{ convertKeyToText(locState) }}
            <ng-container *ngIf="state[locState]; else ban">
                <i class="far fa-check mr--15"></i>
            </ng-container>
            <ng-template #ban>
                <i class="far fa-ban mr--15"></i>
            </ng-template>
        </p>
  
    </div>
  </div>
  