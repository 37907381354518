import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'hours12'
})
export class HoursAmPmPipe implements PipeTransform {
  transform(value: string, ...args: any[]): any {

    if (!value) {
      return;
    }

    value = value.toUpperCase().replace('AM', '');
    value = value.toUpperCase().replace('PM', '');
    const strHours = value.split(':')[0] == '0' ? "00" : value.split(':')[0] ;
    const strMinutes = value.split(':')[1] == '0' ? "00" : value.split(':')[1];
    let hours: number = parseInt(strHours, 0);
    const meridian = (hours >= 0 && hours <= 11 || hours == 24) ? 'AM' : 'PM';
    hours = (hours == 0 ? 0 : hours > 12 ? hours - 12 : hours);
    const displayHours = (hours == 0 ? '12' : hours < 10 ? '0' + hours : hours);

    return `${displayHours}:${strMinutes.trim()} ${meridian.toUpperCase()}`;
  }
}
