import {HttpParams} from '@angular/common/http';

export class ApiResponse {
  message: string;
  data: any;
}

export class ApiResponse2<T> {
  message: string;
  data: T;
}

export class Pagination {
  items: any[];
  page: number;
  pages: number;
  per_page?: number;
  pageSize?: number;
  total: number;
  hasNext: boolean;
  hasPrev: boolean;
  ref?: any;
  totalItems?: any[];
}

export class Pagination2<T> {
  items: T[];
  page: number;
  pages: number;
  per_page: number;
  total: number;
  hasNext: boolean;
  hasPrev: boolean;
  ref?: any;
  totalItems?: any[];
}



export const PAGEABLE_DEFAULT = new HttpParams().set('size', '10').set('page', '1');

export const PAGINATION_OPTION = [10, 25, 50, 100, 200, 500];
