<app-dashboard-filter [progress]="false" [accountId]="accountId"  typeDashboard="workshop"></app-dashboard-filter>

<!--Observations-->
<div class="box box--rounded box--shadow bg--white mb--30" style="margin-top: 50px;" >
  <div class="box__header d-flex justify-content-between align-items center">
    <div class="d-flex align-items-center">
      <div class="icon icon--lg icon--rounded icon--blue">
        <img src="/assets/images/icons/lightbulb.png" alt="Observation Title icon">
      </div>
        <h2 class="m--0"><strong>Observation</strong></h2>
    </div>
    <div class="d-flex align-items-center flex-nowrap">
      <p class="txt--sm txt--lightgray m--0">Click to refresh observations</p>
      <button mat-flat-button color="accent" class="btn btn-icon ml--30" (click)="handleRefresh()"
              [disabled]="refreshing"><i class="fas fa-redo"  [ngClass]="{'active-spin': refreshing}"></i>
      </button>
    </div>
  </div>

  <div class="box__content box--padding-lr">

    <app-skeleton-workshop type="OBSERVATION" *ngIf="isProgressObservation"></app-skeleton-workshop>

    <ng-container *ngIf="!isProgressObservation">
      <ng-container *ngIf="observations?.observations?.length>0; else noDataObservation">
        <app-workshop-observation [observation]="o" [lastUpdate]="observations.lastUpdateAt"
                                  *ngFor="let o of observations.observations"></app-workshop-observation>
      </ng-container>

      <ng-template #noDataObservation>
        <div class="p--tb-20">
          <h4 class="txt--capitalize m--0">No observations data</h4>
        </div>
      </ng-template>
    </ng-container>
  </div>
</div>

<div class="content__header">
  <h2 class="m--0"><strong>All Time Best</strong></h2>
</div>

<app-skeleton-workshop type="METRICS" *ngIf="isProgressTopMetrics"></app-skeleton-workshop>

<ng-container *ngIf="!isProgressTopMetrics">
  <!--Carousel-->
  <ng-container *ngIf="topMetrics; else noTopMetric ">

    <swiper [config]="config" [(index)]="index" class="carousel carousel--up">
      <app-top-metric-carrousel-item [metrics]="metrics" *ngFor="let metrics of topMetrics | keyvalue"></app-top-metric-carrousel-item>
    </swiper>
  </ng-container>

  <ng-template #noTopMetric>
    <div class="box box--rounded box--shadow bg--white">
      <div class="box__header d-flex align-items-center justify-content-center">
        <h2 class="txt--capitalize"><strong>No metrics data</strong></h2>
      </div>
    </div>
  </ng-template>
</ng-container>
