<div class="dialog__header" *ngIf="!bulk">
    <h2 mat-dialog-title class="m--0 txt--xl"><strong>Labels</strong></h2>
</div>
<div class="dialog__content" mat-dialog-content>
    <div class="dialog-row d-flex justify-content-between align-items-center p--0" *ngFor="let item of labelList; let i = index;">
        <mat-form-field class="input-field">
            <input matInput placeholder="Label" (change)="handleChange($event, i)" [value]="item">
        </mat-form-field>
        <button mat-button class="btn btn-icon" (click)="removeLabel(i)"><i class="fas fa-times"></i></button>
    </div>
    <div class="pb--0 row" *ngIf="labelList.length < 10">
        <button mat-button class="btn btn--md d-flex align-items-center" (click)="addNewLabel()" tabIndex="-1">
            <img src="/assets/images/add-btn.png" class="mr--10 img--xxs">Add new label</button>
    </div>
</div>
<div mat-dialog-actions class="dialog__footer" *ngIf="!bulk">
    <span class="alternate-theme d-inline-block mr--10">
        <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
    </span>
    <button mat-flat-button (click)="apply()"  tabIndex="-1" color="accent" class="btn btn--action">Apply</button>
</div>
