import { __awaiter, __generator } from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { LocationService } from '../../services/location.service';
import { ModalShareComponent } from '../modal-share/modal-share.component';
import { ModalService } from '../../services/modal.service';
import { ActivatedRoute } from '@angular/router';
import { GradeExplainComponent } from '../grade-explain/grade-explain.component';
import { AuthService } from '../../services/auth.service';
import { PdfService } from '../../services/pdf.service';
import { WhiteLabelService } from 'src/app/services/white-label.service';
import { Title } from '@angular/platform-browser';
import { LoadingService } from '../../services/loading.service';
import { GradeLoadedService } from 'src/app/services/grade-loaded.service';
import { ExternalGradeService } from 'src/app/services/external-grade.service';
import { GradeLeadService } from 'src/app/services/grade-lead.service';
var GradeHeaderLocationComponent = /** @class */ (function () {
    function GradeHeaderLocationComponent(route, locationS, modalS, auth, wl, titleService, pdfS, loadingService, gradeLoaded, gradeLeadService, externalGradeService) {
        this.route = route;
        this.locationS = locationS;
        this.modalS = modalS;
        this.auth = auth;
        this.wl = wl;
        this.titleService = titleService;
        this.pdfS = pdfS;
        this.loadingService = loadingService;
        this.gradeLoaded = gradeLoaded;
        this.gradeLeadService = gradeLeadService;
        this.externalGradeService = externalGradeService;
        this.finish = new EventEmitter();
        this.isExternal = false;
        this.progress = true;
        this.exportInProgress = false;
    }
    GradeHeaderLocationComponent.prototype.ngOnInit = function () {
        var _this = this;
        var _a, _b, _c, _d;
        // TODO: the two below should be dynamic (the colors too if they depend on the percentage)
        this.circlePercent = 40;
        this.circleGrade = 'A';
        this.circleColorFirst = '#ff4949';
        this.circleColorSecond = '#E91616';
        if (!this.gid) {
            this.id = (_b = (_a = this.route.snapshot) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.id;
            if (this.isExternal)
                this.gid = this.auth.externalSession.gid;
            else
                this.gid = ((_d = (_c = this.route.snapshot) === null || _c === void 0 ? void 0 : _c.params) === null || _d === void 0 ? void 0 : _d.gid) || this.auth.session.gid;
            if (this.id) {
                this.isShared = true;
            }
        }
        this.locationS.getRef(this.gid, this.accountId, this.locationId).take(2).subscribe(function (loc) {
            _this.progress = false;
            _this.location = loc;
            _this.finish.emit(true);
            if (_this.isExternal) {
                _this.wl.getDynamicWhiteLabel().then(function (data) {
                    var _a;
                    _this.branding = (_a = data) === null || _a === void 0 ? void 0 : _a.branding;
                    var company_name = data.branding.company_name;
                    var title_pass = "Grade | " + loc.locationName + " | " + company_name;
                    _this.titleService.setTitle(title_pass);
                });
            }
            _this.loadingService.reportAdvanced(1, "Locations");
        }, function () {
            _this.finish.emit(false);
            _this.progress = false;
        });
        if (this.isExternal) {
            this.gradeLoaded.loaded$.subscribe(function (result) {
                if (result) {
                    setTimeout(function () {
                        _this.exportPdf(false);
                    }, 2000); // Time to finish animations before export
                }
            });
        }
        this.gradeLeadService.firebaseGradeId$.subscribe(function (id) {
            _this.firebaseGradeLeadId = id;
        });
    };
    GradeHeaderLocationComponent.prototype.handleShared = function () {
        var dialogRef = this.modalS.openGenericModal(ModalShareComponent, {
            createNew: true,
            dataPicker: this.dataPicker,
            reportType: 'grade',
        }, function () {
        });
        dialogRef.disableClose = true;
    };
    GradeHeaderLocationComponent.prototype.openExplain = function () {
        var dialogRef = this.modalS.openGenericModal(GradeExplainComponent, {}, function () {
        }, 900);
        dialogRef.disableClose = true;
    };
    GradeHeaderLocationComponent.prototype.handlePdf = function () {
        this.exportPdf();
    };
    GradeHeaderLocationComponent.prototype.exportPdf = function (saveToDisk) {
        if (saveToDisk === void 0) { saveToDisk = true; }
        return __awaiter(this, void 0, void 0, function () {
            var filename, pdfBase64, grade_lead_id;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        filename = "Grader - " + this.location.locationName;
                        return [4 /*yield*/, this.pdfS.base64AndSave('charts-container', filename, '', saveToDisk)];
                    case 1:
                        pdfBase64 = _a.sent();
                        this.exportInProgress = true;
                        if (saveToDisk) {
                            this.exportInProgress = false;
                            return [2 /*return*/];
                        }
                        if (this.isExternal) {
                            grade_lead_id = this.firebaseGradeLeadId;
                            this.externalGradeService.uploadExternalGradePDF(this.gid, this.locationId, this.accountId, pdfBase64, grade_lead_id, this.branding).subscribe(function () { return __awaiter(_this, void 0, void 0, function () {
                                var host, port, hostnameWithPort, domain;
                                var _this = this;
                                return __generator(this, function (_a) {
                                    host = window.location.hostname;
                                    port = window.location.port;
                                    hostnameWithPort = host + ":" + port;
                                    domain = port !== '80' ? hostnameWithPort : host;
                                    this.gradeLeadService.getGradeLeadById(this.firebaseGradeLeadId, domain)
                                        .then(function (gradeLead) {
                                        _this.gradeLeadService.setHasPdf(gradeLead);
                                        _this.exportInProgress = false;
                                    }).catch(function () {
                                        _this.exportInProgress = false;
                                    });
                                    return [2 /*return*/];
                                });
                            }); });
                        }
                        if (!pdfBase64) {
                            return [2 /*return*/];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return GradeHeaderLocationComponent;
}());
export { GradeHeaderLocationComponent };
