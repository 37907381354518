import { Injectable } from '@angular/core';

import * as firebase from 'firebase/app';
import * as moment from 'moment';
import { REGEX_NUMBERS } from '../constants/regex';
import { Moment, unitOfTime } from 'moment';


export interface JsonDate {
  day: number;
  month: number;
  year: number;
}


export interface JsonTime {
  hours: number;
  minutes: number;
  seconds: number;
  nanos: number;
}


@Injectable({
  providedIn: 'root'
})
export class DatesService {

  constructor() {
  }


  toTimestamp(date: Date) {
    return firebase.firestore.Timestamp.fromDate(moment(date).utc(false).toDate());
  }


  toTimestampFromReport(date: Date) {
    return firebase.firestore.Timestamp.fromMillis(moment(date).utc(false).unix());
  }

  get nowTimestamp() {
    return firebase.firestore.Timestamp.now();
  }

  stringToDate(dateStr: string) {
    return new Date(dateStr);
  }

  stringToTimestamp(dateString: string) {
    return this.toTimestamp(this.stringToDate(dateString));
  }

  timestampToISOString(timestamp: firebase.firestore.Timestamp) {
    return timestamp.toDate().toISOString();
  }

  dayToNumber(day: string) {
    const WEEKDAYS = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
    return WEEKDAYS.indexOf(day);
  }

  loadHoursDropdown(every15min = false): any[] {
    const regularHoursList = [];
    let hours;
    let meridian;

    for (let i = 0; i < 2; i++) {
      if (i === 0) {
        meridian = 'AM';
      } else {
        meridian = 'PM';
      }
      for (let j = 0; j < 12; j++) {
        if (j === 0) {
          hours = '12';
        } else if (j > 0 && j < 10) {
          hours = '0' + j;
        } else {
          hours = j.toString();
        }

        if(every15min) {
          regularHoursList.push(`${hours}:00 ${meridian}`);
          regularHoursList.push(`${hours}:15 ${meridian}`);
          regularHoursList.push(`${hours}:30 ${meridian}`);
          regularHoursList.push(`${hours}:45 ${meridian}`);
        } else {
          regularHoursList.push(`${hours}:00 ${meridian}`);
          regularHoursList.push(`${hours}:30 ${meridian}`);
        }
      }
    }

    return regularHoursList;
  }

  getTheNearestTime(currentTime, timeList) {
    // Get the current time in minutes since midnight
    const currentMinutes = this.timeToMinutes(currentTime);
  
    // Convert list hours to minutes since midnight and sort them
    const minutesList = timeList.map(this.timeToMinutes).sort((a, b) => a - b);
  
    // Find the next time
    for (let minutes of minutesList) {
      if (minutes > currentMinutes) {
        return timeList[minutesList.indexOf(minutes)];
      }
    }
  
    // If there is no next time on the same day, return the first of the next day
    return timeList[0];
  }

  // Function to convert time in 'hh:mmAM/PM' format to minutes since midnight
  timeToMinutes(time) {
    const [timePart, modifier] = time.split(/(AM|PM)/);
    let [hours, minutes] = timePart.split(':').map(Number);

    if (modifier === 'PM' && hours !== 12) {
      hours += 12;
    }
    if (modifier === 'AM' && hours === 12) {
      hours = 0;
    }

    return hours * 60 + minutes;
  }

  dateJsonToDate(dateJson: JsonDate) {
    if (dateJson.day) {
      return dateJson !== undefined ? new Date(dateJson.year, dateJson.month - 1, dateJson.day !== 0 ? dateJson.day : 1) : undefined;
    } else {
      return dateJson !== undefined ? new Date(dateJson.year, dateJson.month - 1,  1) : undefined;
    }
  }

  dateToJsonDate(date: Date) {
    return { day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear() };
  }

  timeToJsonTime(hour: string): JsonTime {
    const split = hour.split(':');
    return { hours: parseInt(split[0], 0), minutes: parseInt(split[1], 0), seconds: 0, nanos: 0 };
  }

  jsonTimeToTime(time: JsonTime): string {
    const hour = time.hours < 10 ? '0' + time.hours : time.hours || '00';
    const minutes = time.minutes < 10 ? '0' + time.minutes : time.minutes || '00';
    return `${hour}:${minutes}`;
  }

  lastDayOfMonth(month, year) {
    return new Date(year, month + 1, 0).getDate();
  }


  lastDayOfMonthDate(date: Date) {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  }


  dateLessMonth(date: Date, months: number) {
    const dateMoment = moment(date);
    return dateMoment.subtract(months, 'months').toDate();
  }

  momentSubtractYearReport(m: Moment, years): string {
    return m.subtract(years, 'years').utc().toISOString();
  }

  momentSubtractMonthReport(m: Moment, months): string {
    return m.subtract(months, 'months').utc().toISOString();
  }

  momentSubtractMonthOfReport(m: Moment, months, start: boolean = false): string {
    if (start) return m.subtract(months, 'months').startOf('month').utc().toISOString();
    return m.subtract(months, 'months').endOf('month').utc().toISOString();
  }

  momentSubtractDaysReport(m: Moment, days): string {
    return m.subtract(days, 'days').utc().toISOString();
  }


  momentSubtractReport(date: Moment, diff: number, type: unitOfTime.DurationConstructor): string {
    return date.subtract(diff, type).utc().toISOString();
  }

  getStringDate(date) {
    const month = (date['month']() + 1)?.toString()?.padStart(2, '0');
    const day = (date['date']())?.toString()?.padStart(2, '0');
    return`${month}/${day}/${date['year']()}`
  }

  getStringDateYMD(date) {
    const month = (date['month']() + 1)?.toString()?.padStart(2, '0');
    const day = (date['date']())?.toString()?.padStart(2, '0');
    return`${date['year']()}-${month}-${day}`
  }

  getStringDateMDY(date) {
    const month = (date['month']() + 1)?.toString()?.padStart(2, '0');
    const day = (date['date']())?.toString()?.padStart(2, '0');
    return`${month}/${day}/${date['year']()}`
  }


  startAndEndLessMont(startDate: Date, endDate: Date, month: number) {
    const startB = this.dateLessMonth(startDate, month);
    const endB = this.dateLessMonth(endDate, month);

    return { start: startB.toISOString(), end: endB.toISOString() };
  }


  hours12To24(value) {
    const minutes = parseInt(value?.split(':')[1]?.split(' ')[0]);
    const meridian = value?.split(':')[1]?.split(' ')[1];
    let hour = parseInt(value?.split(':')[0]);
    hour = (
      meridian == 'AM' ? hour == 12 ? 0 : hour :
      hour == 12 ? hour : hour + 12
    );

    return {
      hours: hour,
      minutes: minutes
    }
  }

  getStringHours(hours) {
    return `${hours.hours}:${hours.minutes}`;
  }

  hours24To12(value) {
    if (value === undefined || value === '' || !value) {
      return;
    }

    const splitSemicolon = value.split(':');
    const strHours = splitSemicolon[0];
    const strMinutes = splitSemicolon[1];
    let hours = parseInt(strHours, 0);
    let meridian;

    meridian = (hours >= 12) ? 'pm' : 'am';
    if (hours > 12 && hours !== 0) {
      hours = hours - 12;
    }
    if (hours === 0) {
      hours = 12;
    }

    return `${hours < 10 ? '0' + hours : hours}:${strMinutes} ${meridian.toUpperCase()}`;
  }

  handleTime($event: any) {
    let value: string = $event.target.value;
    const data: string = $event.data;


    if (!data) {
      return;
    }

    if (value.length === 2) {
      $event.target.value += ':';
    }
    if (value.length === 3 && value[3] !== ':') {
      value = $event.target.value = value.substring(0, 2) + ':' + data;
    }

    const matchNumbers = data.match(REGEX_NUMBERS);

    if (!matchNumbers) {
      value = $event.target.value = value.replace(data, '');
    }

    if (value.length > 5) {
      value = $event.target.value = value.substring(0, 5);
    }

    const hours = parseInt(value.substring(0, 2), 0);
    if (hours > 12) {
      value = $event.target.value = '12:' + value.substring(3, value.length);
    }
    const minutes = parseInt(value.substring(3, value.length), 0);
    if (minutes > 59) {
      $event.target.value = value.substr(0, 2) + ':59';
    }
  }

  changeMeridian(meridian) {
    if (meridian === 'AM') {
      meridian = 'PM';
    } else if (meridian === 'PM') {
      meridian = 'AM';
    }
    return meridian;
  }

}
