import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { EXTERNAL_GRADES, GROUPS } from '../constants/firestore/collections';
import { environment } from '@environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExternalGradeService {

  constructor(
    private afs: AngularFirestore,
    private http: HttpClient,
  ) { }

  getExternalGradeToken(gid, externalGradeId) {
    return this.afs.collection(GROUPS).doc(gid).collection(EXTERNAL_GRADES).doc(externalGradeId).get().pipe(map(value => {
      return value.data()?.googleAuth;
    }));
  }

  uploadExternalGradePDF(gid: string, loc_id: string, acc_id: string, pdf: string, grade_lead_id: string, branding: any): Observable<any> {
    const data = {
      gid,
      loc_id,
      acc_id,
      pdf,
      grade_lead_id,
      branding
    }

    return this.http.post(`${environment.apiUrl}/v2/pdf/save`, data)
  }
}
