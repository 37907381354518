import {Component, OnInit} from '@angular/core';
import {GoogleService} from "../../services/google.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-dashboard-review-link',
  templateUrl: './dashboard-review-link.component.html',
  styleUrls: ['./dashboard-review-link.component.scss']
})
export class DashboardReviewLinkComponent implements OnInit {
  private placeId: string;
  url: string;

  constructor(private googleS: GoogleService, private route: ActivatedRoute) {

    this.placeId = this.route.parent.snapshot.params.placeId;

    this.googleS.dynamicUrl(this.placeId).subscribe(url => {
      this.url = url.shortLink;
    });
  }

  ngOnInit() {
  }

}
