import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
var RevenueReportsComponent = /** @class */ (function () {
    function RevenueReportsComponent(router, route) {
        this.router = router;
        this.route = route;
        this.reportType = 'performance-revenue';
    }
    RevenueReportsComponent.prototype.ngOnInit = function () {
        var _a, _b, _c, _d;
        this.reportType = (_d = (_c = (_b = (_a = this.route) === null || _a === void 0 ? void 0 : _a.snapshot) === null || _b === void 0 ? void 0 : _b.routeConfig) === null || _c === void 0 ? void 0 : _c.path) === null || _d === void 0 ? void 0 : _d.replace('-report', '');
    };
    return RevenueReportsComponent;
}());
export { RevenueReportsComponent };
