import { __awaiter, __generator } from "tslib";
import { HttpClient } from '@angular/common/http';
// app
import { environment as ENV } from '@environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var URL_CARD = function (gid, cardId) {
    return ENV.billingApiUrl + "/card/by-gid/" + gid + (cardId ? '/' + cardId : '');
};
var ɵ0 = URL_CARD;
var PaymentsService = /** @class */ (function () {
    function PaymentsService(http) {
        this.http = http;
    }
    PaymentsService.prototype.addNewCard = function (gid, token) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.post(URL_CARD(gid), { source: token }).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    PaymentsService.prototype.deleteCard = function (gid, cardId) {
        return this.http.delete(URL_CARD(gid, cardId)).toPromise();
    };
    PaymentsService.prototype.getCards = function (gid) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get(URL_CARD(gid)).toPromise()];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    PaymentsService.prototype.hasPaymentMethods = function (gid) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.getCards(gid)];
                    case 1: return [2 /*return*/, !!(_b.sent()).length];
                    case 2:
                        _a = _b.sent();
                        return [2 /*return*/, false];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    // TODO: Change to "fetchPlan" and return a promise, not Observable (is single-shot)
    PaymentsService.prototype.getPlan = function (planId) {
        return this.http.get(ENV.billingApiUrl + "/product/" + planId.toLowerCase());
    };
    PaymentsService.prototype.setDefaultSource = function (gid, cardId) {
        return this.http.post(URL_CARD(gid, cardId) + '/default', {}).toPromise();
    };
    PaymentsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PaymentsService_Factory() { return new PaymentsService(i0.ɵɵinject(i1.HttpClient)); }, token: PaymentsService, providedIn: "root" });
    return PaymentsService;
}());
export { PaymentsService };
export { ɵ0 };
