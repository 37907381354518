import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-options-filter',
  templateUrl: './options-filter.component.html',
  styleUrls: ['./options-filter.component.scss']
})
export class OptionsFilterComponent implements OnInit, OnChanges {
  public filterControl = new FormControl('');
  public filteredOptionsObs: Observable<any>;
  public filteredOptions = [];
  public options = [];
  
  @Input() allOptions = [];
  @Input() labelFiltered: string = "All";
  @Input() isMultiple = true;
  @Input() hasClear = true;
  @Input() hasApply = true;

  @Output() filterChanged: EventEmitter<any> = new EventEmitter();
  
  constructor() { }

  ngOnInit() {
    this.options = [...this.allOptions];
    this.filteredOptionsObs = this.filterControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  getLocationsFiltered(event, account) {
    this.filteredOptions= [];
    this.labelFiltered = null;
    this.options.forEach(acc => {
      acc.checked = acc.accountId == account.account ? event.checked : acc.checked;
      if (acc.checked) {
        this.filteredOptions.push(acc);
        this.labelFiltered = this.labelFiltered ? `${this.labelFiltered} - ${acc.accountName}`: acc.accountName; 
      }
    });
  }

  filterAccountChanged(menu, clear, field = null) {
    if (clear) {
      this.options.forEach(option => option.checked = false);
      this.labelFiltered = 'All';
      this.filteredOptions = [];
    } else {
      this.filteredOptions= [];
      this.labelFiltered = null;
      this.filteredOptions.push(field);
      this.labelFiltered = field.accountName;
    }


    this.filterControl.setValue(null);
    menu.close.next(false);

    this.filterChanged.emit(this.filteredOptions)
  }

  _filter(value: string) {
    this.options = [];
    const filterValue = value?.toLowerCase();
    if(value != ''){
      this.allOptions?.forEach(acc => {
        const name = acc.accountName.toLowerCase();
        if (name.includes(filterValue)) {
          this.options.push(acc)
        }
      });
    } else {
      this.options = [...this.allOptions];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.allOptions) {
      this.options = [...this.allOptions];
    }
  }

}
