/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-show-media.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/tooltip";
import * as i3 from "@angular/cdk/overlay";
import * as i4 from "@angular/cdk/scrolling";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "@angular/platform-browser";
import * as i9 from "@angular/common";
import * as i10 from "@angular/material/dialog";
import * as i11 from "../../../../node_modules/ngx-swiper-wrapper/dist/ngx-swiper-wrapper.ngfactory";
import * as i12 from "ngx-swiper-wrapper";
import * as i13 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i14 from "@angular/material/button";
import * as i15 from "@angular/platform-browser/animations";
import * as i16 from "./modal-show-media.component";
import * as i17 from "../../services/modal.service";
import * as i18 from "../../services/google.service";
import * as i19 from "../../services/snackbar.service";
var styles_ModalShowMediaComponent = [i0.styles];
var RenderType_ModalShowMediaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalShowMediaComponent, data: {} });
export { RenderType_ModalShowMediaComponent as RenderType_ModalShowMediaComponent };
function View_ModalShowMediaComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["rel", "noopener noreferrer"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.sourceUrl; _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.googleUrl); var currVal_2 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.googleUrl); _ck(_v, 2, 0, currVal_1, currVal_2); }); }
function View_ModalShowMediaComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.googleUrl); var currVal_1 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.googleUrl); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ModalShowMediaComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "txt--sm"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.description); _ck(_v, 1, 0, currVal_0); }); }
function View_ModalShowMediaComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "full-image-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 16777216, null, null, 2, "button", [["class", "btn btn-delete btn-delete--gallery "], ["matTooltip", "Delete"], ["matTooltipClass", "tooltip tooltip--red"], ["matTooltipPosition", "above"]], null, [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.openDelete(_v.context.$implicit) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 212992, null, 0, i2.MatTooltip, [i3.Overlay, i1.ElementRef, i4.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i5.Platform, i6.AriaDescriber, i6.FocusMonitor, i2.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i7.Directionality], [2, i2.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i8.HAMMER_LOADER]], { position: [0, "position"], message: [1, "message"], tooltipClass: [2, "tooltipClass"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fas fa-trash-alt"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalShowMediaComponent_2)), i1.ɵdid(6, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["photoImage", 2]], null, 0, null, View_ModalShowMediaComponent_3)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalShowMediaComponent_4)), i1.ɵdid(9, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = "above"; var currVal_1 = "Delete"; var currVal_2 = "tooltip tooltip--red"; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = (_v.context.$implicit.mediaFormat === "VIDEO"); var currVal_4 = i1.ɵnov(_v, 7); _ck(_v, 6, 0, currVal_3, currVal_4); var currVal_5 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.description); _ck(_v, 9, 0, currVal_5); }, null); }
function View_ModalShowMediaComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "thumb-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.googleUrl); var currVal_1 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.googleUrl); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_ModalShowMediaComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { gallery: 0 }), i1.ɵqud(671088640, 2, { galleryThumbs: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 14, "div", [["class", "dialog__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "dialog__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "h2", [["class", "m--0 txt--xl"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Photos"])), (_l()(), i1.ɵeld(7, 0, null, null, 9, "div", [["class", "dialog__content dialog__content--gallery mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i10.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 3, "swiper", [["class", "carousel carousel--media"]], null, [[null, "indexChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("indexChange" === en)) {
        var pd_0 = ((_co.index = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_SwiperComponent_0, i11.RenderType_SwiperComponent)), i1.ɵdid(10, 4374528, [[1, 4], ["gallery", 4]], 0, i12.SwiperComponent, [i1.NgZone, i1.ChangeDetectorRef, i1.PLATFORM_ID, [2, i12.SWIPER_CONFIG]], { index: [0, "index"], config: [1, "config"] }, { indexChange: "indexChange" }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ModalShowMediaComponent_1)), i1.ɵdid(12, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 3, "swiper", [["class", "carousel carousel--thumbs"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeGalleryIndex($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_SwiperComponent_0, i11.RenderType_SwiperComponent)), i1.ɵdid(14, 4374528, [[2, 4], ["galleryThumbs", 4]], 0, i12.SwiperComponent, [i1.NgZone, i1.ChangeDetectorRef, i1.PLATFORM_ID, [2, i12.SWIPER_CONFIG]], { config: [0, "config"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ModalShowMediaComponent_5)), i1.ɵdid(16, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 6, "div", [["class", "dialog__footer footer-show-media mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(18, 16384, null, 0, i10.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(19, 0, null, null, 4, "span", [["class", "alternate-theme d-inline-block mr--10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 3, "button", [["class", "btn btn-cancel"], ["color", "primary"], ["mat-button", ""], ["mat-dialog-close", ""], ["tabIndex", "-1"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 22).dialogRef.close(i1.ɵnov(_v, 22).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_MatButton_0, i13.RenderType_MatButton)), i1.ɵdid(21, 180224, null, 0, i14.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i15.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(22, 606208, null, 0, i10.MatDialogClose, [[2, i10.MatDialogRef], i1.ElementRef, i10.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.index; var currVal_1 = _co.galleryConfig; _ck(_v, 10, 0, currVal_0, currVal_1); var currVal_2 = _co.mediaFiles; _ck(_v, 12, 0, currVal_2); var currVal_3 = _co.galleryThumbsConfig; _ck(_v, 14, 0, currVal_3); var currVal_4 = _co.mediaFiles; _ck(_v, 16, 0, currVal_4); var currVal_9 = "primary"; _ck(_v, 21, 0, currVal_9); var currVal_10 = ""; _ck(_v, 22, 0, currVal_10); }, function (_ck, _v) { var currVal_5 = (i1.ɵnov(_v, 21).disabled || null); var currVal_6 = (i1.ɵnov(_v, 21)._animationMode === "NoopAnimations"); var currVal_7 = (i1.ɵnov(_v, 22).ariaLabel || null); var currVal_8 = i1.ɵnov(_v, 22).type; _ck(_v, 20, 0, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_ModalShowMediaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-show-media", [], null, null, null, View_ModalShowMediaComponent_0, RenderType_ModalShowMediaComponent)), i1.ɵdid(1, 4308992, null, 0, i16.ModalShowMediaComponent, [i10.MAT_DIALOG_DATA, i10.MatDialogRef, i17.ModalService, i18.GoogleService, i19.SnackbarService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalShowMediaComponentNgFactory = i1.ɵccf("app-modal-show-media", i16.ModalShowMediaComponent, View_ModalShowMediaComponent_Host_0, {}, {}, []);
export { ModalShowMediaComponentNgFactory as ModalShowMediaComponentNgFactory };
