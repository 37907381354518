// dep
import { HttpHeaders } from '@angular/common/http';


// Tell the HTTP interceptor to don't add the app Authorization header
// This will not be sent on the wire
// TODO: On newer angular sections this can be done using requests metadata
export const HEADERS_NO_AUTH = {headers: new HttpHeaders({'X-Token-Type' : 'NO_AUTH'})}

export const MAIL_EXTERNAL_GRADE="external-grade@maplabs.com"
export const MAIL_ANONYMOUS="anonymous@mail.com"
