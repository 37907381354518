<div class="row box--height">

  <div class="col-md-7 col-lg-9 mb--30">
    <div class="box box--rounded box--shadow bg--white">
      <div class="box__header d-flex align-items-center justify-content-start">
        <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '20px', height: '20px', 'margin-right': '20px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" [theme]="{ width: '300px', height: '20px' }"></ngx-skeleton-loader>
      </div>
      <div class="box__content box--padding-xxs">
        <div class="row">
          <div class="col-md-5">
            <div class="d-flex justify-content-center flex-column align-items-center">
              <ngx-skeleton-loader count="1" [theme]="{ width: '370px', height: '30px' }"></ngx-skeleton-loader>
              <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '150px', height: '150px', 'margin-top': '10px'}"></ngx-skeleton-loader>
              <ngx-skeleton-loader count="1" [theme]="{ width: '50px', height: '50px', 'margin-top': '10px'}"></ngx-skeleton-loader>
              <ngx-skeleton-loader count="1" [theme]="{ width: '40px', height: '20px', 'margin-top': '10px'}"></ngx-skeleton-loader>
            </div>
          </div>
          <div class="col-md-7">
            <div class="d-flex flex-column mb--15" *ngFor="let i of [0,1]">
              <ngx-skeleton-loader count="1" [theme]="{ width: '200px', height: '20px'}"></ngx-skeleton-loader>
              <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '20px', 'margin-top': '5px' }"></ngx-skeleton-loader>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-5 col-lg-3 mb--30">
    <div class="box box--rounded box--shadow bg--white">
      <div class="box__header d-flex align-items-center justify-content-start">
        <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '20px', height: '20px', 'margin-right': '20px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" [theme]="{ width: '200px', height: '20px' }"></ngx-skeleton-loader>
      </div>
      <div class="box__content box--padding-xxs">
        <div class="row">
          <div class="col-md-6" *ngFor="let i of [0,1]">
            <div class="d-flex flex-column justify-content-center align-items-center mb--15">
              <ngx-skeleton-loader count="1" [theme]="{ width: '20px', height: '180px'}"></ngx-skeleton-loader>
              <ngx-skeleton-loader count="1" [theme]="{ width: '60px', height: '15px', 'margin-top': '5px' }"></ngx-skeleton-loader>
              <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px', 'margin-top': '5px' }"></ngx-skeleton-loader>
            </div>
          </div>
          <div class="col-md-12">
            <div class="d-flex flex-column justify-content-center align-items-center mb--15">
              <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '60px'}"></ngx-skeleton-loader>
              <ngx-skeleton-loader count="1" [theme]="{ width: '50px', height: '15px', 'margin-top': '5px' }"></ngx-skeleton-loader>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 mb--30">
    <div class="box box--shadow box--rounded bg--white">
      <div class="box__header">
        <ngx-skeleton-loader count="1" [theme]="{ width: '200px', height: '20px' }"></ngx-skeleton-loader>
      </div>
      <div class="box__content box--padding-lg">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-8">
            <div class="row">

              <div class="col-md-4" *ngFor="let i of [0,1,2]">
                <div class="box">
                  <div class="box__header box__header--sm box--shadow d-flex justify-content-center align-items-center">
                    <ngx-skeleton-loader count="1" [theme]="{ width: '200px', height: '20px' }"></ngx-skeleton-loader>
                    <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '20px', height: '20px', 'margin-right': '10px' }"></ngx-skeleton-loader>
                  </div>
                  <div class="box__content box--padding-t txt--center">
                    <ngx-skeleton-loader count="1" [theme]="{ width: '200px', height: '20px' }"></ngx-skeleton-loader>
                    <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '50px', 'margin-top': '5px' }"></ngx-skeleton-loader>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-4 txt--center d-flex flex-column justify-content-center align-items-center">
            <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '120px', height: '120px' }"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" [theme]="{ width: '200px', height: '60px', 'margin-top': '20px' }"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px', 'margin-top': '10px' }"></ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-4 mb--30" *ngFor="let i of [0,1,2]">
    <div class="box box--shadow box--rounded bg--white">
      <div class="box__header">
        <ngx-skeleton-loader count="1" [theme]="{ width: '200px', height: '20px' }"></ngx-skeleton-loader>
      </div>
      <div class="box__content box--padding-xxs d-flex justify-content-between">
        <div>
          <ngx-skeleton-loader count="1" [theme]="{ width: '130px', height: '65px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" [theme]="{ width: '200px', height: '20px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '50px' }"></ngx-skeleton-loader>
        </div>
        <ngx-skeleton-loader appearance="circle" count="1" [theme]="{ width: '150px', height: '150px' }"></ngx-skeleton-loader>
      </div>
    </div>
  </div>

  <!--potential store visits-->
  <div class="col-12 mb--30">
    <div class="box box--shadow box--rounded bg--white">
      <div class="box__header">
        <ngx-skeleton-loader count="1" [theme]="{ width: '200px', height: '20px' }"></ngx-skeleton-loader>
      </div>
      <div class="box__content box--padding-xxs">
        <ngx-skeleton-loader count="1" [theme]="{ width: '60%', height: '40px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '300px', 'margin-top': '15px' }"></ngx-skeleton-loader>
      </div>
    </div>
  </div>
</div>
