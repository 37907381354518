import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-frecuency-selector',
  templateUrl: './frecuency-selector.component.html',
  styleUrls: ['./frecuency-selector.component.scss']
})
export class FrecuencySelectorComponent implements OnInit, OnChanges {
  @Input() FrecuencySelect: 'WeeklyOn' | 'MonthlyOn' = 'WeeklyOn';
  @Input() frecuencyEdit: string = null;
  @Input() durationEdit: string = null;
  @Output() FrecuencyOutput: EventEmitter<{day:string, duration:number}> = new EventEmitter();
  daySelected: string = null;
  durationDaySelected: string = null;
  daySelectedControl: FormControl = new FormControl(1, [Validators.min(1), Validators.max(29), Validators.required])

  constructor() { }

  ngOnInit(): void {
    this.loadFrecuencyData();
  }

  loadFrecuencyData(){
    if (this.frecuencyEdit) {
      if (this.FrecuencySelect === 'WeeklyOn') {
        this.daySelected = this.frecuencyEdit;
        this.durationDaySelected = (!this.durationEdit) ? this.frecuencyEdit : this.calculateDayByDuration().toString();
        this.durationDaySelected = Number(this.durationDaySelected) > 6 ? "1" : this.durationDaySelected;
        this.selectDurationDay(this.durationDaySelected)
      } else {
        this.daySelectedControl.setValue(this.frecuencyEdit)
      }
    }
  }

  ngOnChanges(): void {
    this.loadFrecuencyData();
  }

  selectDay(day: string): void {
    this.daySelected = day;
    const duration = this.calculateDurationByDays();
    this.FrecuencyOutput.emit({day, duration: duration });
  }

  selectDurationDay(day: string) {
    if (this.daySelected) {
      this.durationDaySelected = day;
      const duration = this.calculateDurationByDays();
      this.FrecuencyOutput.emit({day: this.daySelected , duration});
      return;
    }
  }

  calculateDayByDuration(): number {
    const frecuency = parseInt(this.frecuencyEdit);
    const duration = parseInt(this.durationEdit);
    const durationDay = duration + frecuency;
    if (durationDay <= 7) return durationDay - 1;
    if (durationDay > 7) return durationDay - 7 - 1;

    return -1;
  }

  calculateDurationByDays() : number{
    const start = parseInt(this.daySelected);
    const end = parseInt(this.durationDaySelected);

    if(end > start) return (end - start) + 1;
    if(end < start) return (7 - start + end) + 1;
    return 1;
  }

  SelectCalendar(): void {
    this.FrecuencyOutput.emit({day: this.daySelectedControl.value, duration: 30});
  }

}
