import { __awaiter, __generator } from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { GoogleService } from '../../services/google.service';
import { SnackbarService } from '../../services/snackbar.service';
import { LocationService } from '../../services/location.service';
import { AuthService } from '../../services/auth.service';
import { LoadingService } from '../../services/loading.service';
import { WhiteLabelService } from 'src/app/services/white-label.service';
import { environment } from 'src/environments/environment';
import { GradeLoadedService } from 'src/app/services/grade-loaded.service';
var GradeMetricsComponent = /** @class */ (function () {
    function GradeMetricsComponent(auth, googleS, locationS, loadingService, snackService, wl, gradeLoaded) {
        this.auth = auth;
        this.googleS = googleS;
        this.locationS = locationS;
        this.loadingService = loadingService;
        this.snackService = snackService;
        this.wl = wl;
        this.gradeLoaded = gradeLoaded;
        this.metrics = false;
        this.progress = true;
        this.hasError = false;
        this.isExternal = false;
        this.finish = new EventEmitter();
        this.grade = new EventEmitter();
    }
    GradeMetricsComponent.prototype.ngOnInit = function () {
        this.getMetrics();
    };
    GradeMetricsComponent.prototype.getMetrics = function () {
        var _this = this;
        this.progress = true;
        this.hasError = false;
        this.googleS.metrics(this.accountId, this.locationId, this.dataPicker).take(2).subscribe(function (result) { return __awaiter(_this, void 0, void 0, function () {
            var data, metrics;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.wl.getDynamicWhiteLabel()];
                    case 1:
                        data = _a.sent();
                        environment.hideComparisonInGrader = data.hideComparisonInGrader;
                        metrics = _.omitBy(result, _.isNil);
                        this.metrics = this.calculateGrade(metrics);
                        this.progress = false;
                        this.finish.emit(true);
                        this.loadingService.reportAdvanced(1, "Metrics");
                        this.gradeLoaded.loaded$.next(true);
                        return [2 /*return*/];
                }
            });
        }); }, function (error) {
            _this.hasError = true;
            _this.progress = false;
            _this.finish.emit(true);
            _this.snackService.openError(error.error.message, 2000);
        });
    };
    GradeMetricsComponent.prototype.calculateGrade = function (metrics) {
        var totals = Object.keys(metrics).reduce(function (total, prop) {
            return total + metrics[prop].total;
        }, 0);
        var maxs = Object.keys(metrics).reduce(function (max, prop) {
            var value = metrics[prop].max || 0;
            return max + value;
        }, 0);
        this.grade.emit({ maxs: maxs, totals: totals });
        return metrics;
    };
    return GradeMetricsComponent;
}());
export { GradeMetricsComponent };
