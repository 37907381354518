import { __read, __values } from "tslib";
import * as firebase from 'firebase/app';
import * as moment from 'moment';
import { REGEX_NUMBERS } from '../constants/regex';
import * as i0 from "@angular/core";
var DatesService = /** @class */ (function () {
    function DatesService() {
    }
    DatesService.prototype.toTimestamp = function (date) {
        return firebase.firestore.Timestamp.fromDate(moment(date).utc(false).toDate());
    };
    DatesService.prototype.toTimestampFromReport = function (date) {
        return firebase.firestore.Timestamp.fromMillis(moment(date).utc(false).unix());
    };
    Object.defineProperty(DatesService.prototype, "nowTimestamp", {
        get: function () {
            return firebase.firestore.Timestamp.now();
        },
        enumerable: true,
        configurable: true
    });
    DatesService.prototype.stringToDate = function (dateStr) {
        return new Date(dateStr);
    };
    DatesService.prototype.stringToTimestamp = function (dateString) {
        return this.toTimestamp(this.stringToDate(dateString));
    };
    DatesService.prototype.timestampToISOString = function (timestamp) {
        return timestamp.toDate().toISOString();
    };
    DatesService.prototype.dayToNumber = function (day) {
        var WEEKDAYS = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
        return WEEKDAYS.indexOf(day);
    };
    DatesService.prototype.loadHoursDropdown = function (every15min) {
        if (every15min === void 0) { every15min = false; }
        var regularHoursList = [];
        var hours;
        var meridian;
        for (var i = 0; i < 2; i++) {
            if (i === 0) {
                meridian = 'AM';
            }
            else {
                meridian = 'PM';
            }
            for (var j = 0; j < 12; j++) {
                if (j === 0) {
                    hours = '12';
                }
                else if (j > 0 && j < 10) {
                    hours = '0' + j;
                }
                else {
                    hours = j.toString();
                }
                if (every15min) {
                    regularHoursList.push(hours + ":00 " + meridian);
                    regularHoursList.push(hours + ":15 " + meridian);
                    regularHoursList.push(hours + ":30 " + meridian);
                    regularHoursList.push(hours + ":45 " + meridian);
                }
                else {
                    regularHoursList.push(hours + ":00 " + meridian);
                    regularHoursList.push(hours + ":30 " + meridian);
                }
            }
        }
        return regularHoursList;
    };
    DatesService.prototype.getTheNearestTime = function (currentTime, timeList) {
        var e_1, _a;
        // Get the current time in minutes since midnight
        var currentMinutes = this.timeToMinutes(currentTime);
        // Convert list hours to minutes since midnight and sort them
        var minutesList = timeList.map(this.timeToMinutes).sort(function (a, b) { return a - b; });
        try {
            // Find the next time
            for (var minutesList_1 = __values(minutesList), minutesList_1_1 = minutesList_1.next(); !minutesList_1_1.done; minutesList_1_1 = minutesList_1.next()) {
                var minutes = minutesList_1_1.value;
                if (minutes > currentMinutes) {
                    return timeList[minutesList.indexOf(minutes)];
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (minutesList_1_1 && !minutesList_1_1.done && (_a = minutesList_1.return)) _a.call(minutesList_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        // If there is no next time on the same day, return the first of the next day
        return timeList[0];
    };
    // Function to convert time in 'hh:mmAM/PM' format to minutes since midnight
    DatesService.prototype.timeToMinutes = function (time) {
        var _a = __read(time.split(/(AM|PM)/), 2), timePart = _a[0], modifier = _a[1];
        var _b = __read(timePart.split(':').map(Number), 2), hours = _b[0], minutes = _b[1];
        if (modifier === 'PM' && hours !== 12) {
            hours += 12;
        }
        if (modifier === 'AM' && hours === 12) {
            hours = 0;
        }
        return hours * 60 + minutes;
    };
    DatesService.prototype.dateJsonToDate = function (dateJson) {
        if (dateJson.day) {
            return dateJson !== undefined ? new Date(dateJson.year, dateJson.month - 1, dateJson.day !== 0 ? dateJson.day : 1) : undefined;
        }
        else {
            return dateJson !== undefined ? new Date(dateJson.year, dateJson.month - 1, 1) : undefined;
        }
    };
    DatesService.prototype.dateToJsonDate = function (date) {
        return { day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear() };
    };
    DatesService.prototype.timeToJsonTime = function (hour) {
        var split = hour.split(':');
        return { hours: parseInt(split[0], 0), minutes: parseInt(split[1], 0), seconds: 0, nanos: 0 };
    };
    DatesService.prototype.jsonTimeToTime = function (time) {
        var hour = time.hours < 10 ? '0' + time.hours : time.hours || '00';
        var minutes = time.minutes < 10 ? '0' + time.minutes : time.minutes || '00';
        return hour + ":" + minutes;
    };
    DatesService.prototype.lastDayOfMonth = function (month, year) {
        return new Date(year, month + 1, 0).getDate();
    };
    DatesService.prototype.lastDayOfMonthDate = function (date) {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    };
    DatesService.prototype.dateLessMonth = function (date, months) {
        var dateMoment = moment(date);
        return dateMoment.subtract(months, 'months').toDate();
    };
    DatesService.prototype.momentSubtractYearReport = function (m, years) {
        return m.subtract(years, 'years').utc().toISOString();
    };
    DatesService.prototype.momentSubtractMonthReport = function (m, months) {
        return m.subtract(months, 'months').utc().toISOString();
    };
    DatesService.prototype.momentSubtractMonthOfReport = function (m, months, start) {
        if (start === void 0) { start = false; }
        if (start)
            return m.subtract(months, 'months').startOf('month').utc().toISOString();
        return m.subtract(months, 'months').endOf('month').utc().toISOString();
    };
    DatesService.prototype.momentSubtractDaysReport = function (m, days) {
        return m.subtract(days, 'days').utc().toISOString();
    };
    DatesService.prototype.momentSubtractReport = function (date, diff, type) {
        return date.subtract(diff, type).utc().toISOString();
    };
    DatesService.prototype.getStringDate = function (date) {
        var _a, _b, _c, _d;
        var month = (_b = (_a = (date['month']() + 1)) === null || _a === void 0 ? void 0 : _a.toString()) === null || _b === void 0 ? void 0 : _b.padStart(2, '0');
        var day = (_d = (_c = (date['date']())) === null || _c === void 0 ? void 0 : _c.toString()) === null || _d === void 0 ? void 0 : _d.padStart(2, '0');
        return month + "/" + day + "/" + date['year']();
    };
    DatesService.prototype.getStringDateYMD = function (date) {
        var _a, _b, _c, _d;
        var month = (_b = (_a = (date['month']() + 1)) === null || _a === void 0 ? void 0 : _a.toString()) === null || _b === void 0 ? void 0 : _b.padStart(2, '0');
        var day = (_d = (_c = (date['date']())) === null || _c === void 0 ? void 0 : _c.toString()) === null || _d === void 0 ? void 0 : _d.padStart(2, '0');
        return date['year']() + "-" + month + "-" + day;
    };
    DatesService.prototype.getStringDateMDY = function (date) {
        var _a, _b, _c, _d;
        var month = (_b = (_a = (date['month']() + 1)) === null || _a === void 0 ? void 0 : _a.toString()) === null || _b === void 0 ? void 0 : _b.padStart(2, '0');
        var day = (_d = (_c = (date['date']())) === null || _c === void 0 ? void 0 : _c.toString()) === null || _d === void 0 ? void 0 : _d.padStart(2, '0');
        return month + "/" + day + "/" + date['year']();
    };
    DatesService.prototype.startAndEndLessMont = function (startDate, endDate, month) {
        var startB = this.dateLessMonth(startDate, month);
        var endB = this.dateLessMonth(endDate, month);
        return { start: startB.toISOString(), end: endB.toISOString() };
    };
    DatesService.prototype.hours12To24 = function (value) {
        var _a, _b, _c, _d, _e;
        var minutes = parseInt((_b = (_a = value) === null || _a === void 0 ? void 0 : _a.split(':')[1]) === null || _b === void 0 ? void 0 : _b.split(' ')[0]);
        var meridian = (_d = (_c = value) === null || _c === void 0 ? void 0 : _c.split(':')[1]) === null || _d === void 0 ? void 0 : _d.split(' ')[1];
        var hour = parseInt((_e = value) === null || _e === void 0 ? void 0 : _e.split(':')[0]);
        hour = (meridian == 'AM' ? hour == 12 ? 0 : hour :
            hour == 12 ? hour : hour + 12);
        return {
            hours: hour,
            minutes: minutes
        };
    };
    DatesService.prototype.getStringHours = function (hours) {
        return hours.hours + ":" + hours.minutes;
    };
    DatesService.prototype.hours24To12 = function (value) {
        if (value === undefined || value === '' || !value) {
            return;
        }
        var splitSemicolon = value.split(':');
        var strHours = splitSemicolon[0];
        var strMinutes = splitSemicolon[1];
        var hours = parseInt(strHours, 0);
        var meridian;
        meridian = (hours >= 12) ? 'pm' : 'am';
        if (hours > 12 && hours !== 0) {
            hours = hours - 12;
        }
        if (hours === 0) {
            hours = 12;
        }
        return (hours < 10 ? '0' + hours : hours) + ":" + strMinutes + " " + meridian.toUpperCase();
    };
    DatesService.prototype.handleTime = function ($event) {
        var value = $event.target.value;
        var data = $event.data;
        if (!data) {
            return;
        }
        if (value.length === 2) {
            $event.target.value += ':';
        }
        if (value.length === 3 && value[3] !== ':') {
            value = $event.target.value = value.substring(0, 2) + ':' + data;
        }
        var matchNumbers = data.match(REGEX_NUMBERS);
        if (!matchNumbers) {
            value = $event.target.value = value.replace(data, '');
        }
        if (value.length > 5) {
            value = $event.target.value = value.substring(0, 5);
        }
        var hours = parseInt(value.substring(0, 2), 0);
        if (hours > 12) {
            value = $event.target.value = '12:' + value.substring(3, value.length);
        }
        var minutes = parseInt(value.substring(3, value.length), 0);
        if (minutes > 59) {
            $event.target.value = value.substr(0, 2) + ':59';
        }
    };
    DatesService.prototype.changeMeridian = function (meridian) {
        if (meridian === 'AM') {
            meridian = 'PM';
        }
        else if (meridian === 'PM') {
            meridian = 'AM';
        }
        return meridian;
    };
    DatesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DatesService_Factory() { return new DatesService(); }, token: DatesService, providedIn: "root" });
    return DatesService;
}());
export { DatesService };
