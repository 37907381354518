import { AuthService } from "./auth.service";
import { ModalTrialEnded } from '../modules/modal-trial-ended/modal-trial-ended.component';
import { ModalService } from './modal.service';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "./modal.service";
var BLOCK_REASON_MESSAGES = {
    'TRIAL_EXPIRED': { title: 'Your Free Subscription Has Ended',
        messageV2: ('Schedule a call with us to upgrade your account and make the best local search ' +
            'investment for your business; 100 TIMES MORE users than other local search channels!'),
        messageV3: ('Reach out to our sales team to upgrade your account and make the best local search ' +
            'investment for your business; 100 TIMES MORE users than other local search channels!') },
    'UNPAID_TOO_LONG': { title: 'Your Subscription is Unpaid',
        messageV2: 'Schedule a call with us to assist with your payments to continue using Map Labs.',
        messageV3: 'Reach out to our sales team to assist with your payments and continue using Map Labs.' },
    'MANUAL_BLOCK': { title: 'Your Subscription has been Blocked ',
        messageV2: 'Schedule a call with us to unblock your subscription and continue using Map Labs.',
        messageV3: 'Reach out to our sales team to unblock your subscription and continue using Map Labs.' }
};
// Only used on transition, TODO: Remove after MAP-2504 backend is deployed
var BLOCK_REASON_MESSAGES_DEFAULT = 'MANUAL_BLOCK';
var AuthGuardService = /** @class */ (function () {
    function AuthGuardService(_auth, _modalService) {
        var _this = this;
        this._auth = _auth;
        this._modalService = _modalService;
        this._modalTrialEndedOpened = false;
        this.subscription$ = this._auth.subscription$;
        this.subscription$.subscribe(function (subscription) {
            var _a, _b;
            _this.subscription = subscription;
            if (((_a = subscription) === null || _a === void 0 ? void 0 : _a.status) === 'BLOCKED') {
                var message = void 0;
                var buttons = void 0;
                switch ((_b = _this.subscription) === null || _b === void 0 ? void 0 : _b.pricingVersion) {
                    case 1:
                    case 2: {
                        message = _this._blockReason().messageV2,
                            buttons = {
                                'accept_icon': 'far fa-calendar',
                                'accept_text': 'Schedule Call',
                                'accept_cta': 'https://calendly.com/maplabs/discovery-call',
                                'cancel_text': 'Logout',
                            };
                        _this._openModal(buttons, message);
                        break;
                    }
                    case 3: {
                        message = _this._blockReason().messageV3,
                            buttons = {
                                'accept_icon': 'fas fa-paper-plane',
                                'accept_text': 'Contact Sales',
                                'accept_cta': 'mailto:sales@maplabs.com',
                                'cancel_text': 'Logout',
                            };
                        _this._openModal(buttons, message);
                        break;
                    }
                }
            }
        });
    }
    AuthGuardService.prototype._blockReason = function () {
        var _a;
        return BLOCK_REASON_MESSAGES[((_a = this.subscription) === null || _a === void 0 ? void 0 : _a.statusReason) || BLOCK_REASON_MESSAGES_DEFAULT];
    };
    AuthGuardService.prototype._openModal = function (buttons, message) {
        if (!this._modalTrialEndedOpened) {
            this._modalService.openModal(ModalTrialEnded, {
                title: this._blockReason().title,
                message: message,
                buttons: buttons
            }, {
                config: {
                    width: '750px',
                    disableClose: true
                }
            });
            this._modalTrialEndedOpened = true;
        }
    };
    AuthGuardService.prototype.canActivate = function () {
        var _a, _b;
        if (((_a = this.subscription) === null || _a === void 0 ? void 0 : _a.status) === "BLOCKED") {
            var message = void 0;
            var buttons = void 0;
            switch ((_b = this.subscription) === null || _b === void 0 ? void 0 : _b.pricingVersion) {
                case 1:
                case 2: {
                    message = this._blockReason().messageV2,
                        buttons = {
                            'accept_icon': 'far fa-calendar',
                            'accept_text': 'Schedule Call',
                            'accept_cta': 'https://calendly.com/maplabs/discovery-call',
                            'cancel_text': 'Logout',
                        };
                    this._openModal(buttons, message);
                    break;
                }
                case 3: {
                    message = this._blockReason().messageV3,
                        buttons = {
                            'accept_icon': 'fas fa-paper-plane',
                            'accept_text': 'Contact Sales',
                            'accept_cta': 'mailto:sales@maplabs.com',
                            'cancel_text': 'Logout',
                        };
                    this._openModal(buttons, message);
                    break;
                }
            }
        }
        if ((!this._auth.authenticated && !this._auth.session))
            return false;
        return true;
    };
    AuthGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuardService_Factory() { return new AuthGuardService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.ModalService)); }, token: AuthGuardService, providedIn: "root" });
    return AuthGuardService;
}());
export { AuthGuardService };
