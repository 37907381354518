/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../modules/main/main.component.ngfactory";
import * as i2 from "../../modules/main/main.component";
import * as i3 from "@angular/router";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../../services/white-label.service";
import * as i6 from "../../services/data-transfer.service";
import * as i7 from "./blank-layout.component";
var styles_BlankLayoutComponent = [];
var RenderType_BlankLayoutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BlankLayoutComponent, data: {} });
export { RenderType_BlankLayoutComponent as RenderType_BlankLayoutComponent };
export function View_BlankLayoutComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-main", [], null, null, null, i1.View_MainComponent_0, i1.RenderType_MainComponent)), i0.ɵdid(1, 245760, null, 0, i2.MainComponent, [i3.Router, i3.ActivatedRoute, i4.Title, i5.WhiteLabelService, i6.DataTransferService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_BlankLayoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-layout", [], null, null, null, View_BlankLayoutComponent_0, RenderType_BlankLayoutComponent)), i0.ɵdid(1, 114688, null, 0, i7.BlankLayoutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BlankLayoutComponentNgFactory = i0.ɵccf("app-layout", i7.BlankLayoutComponent, View_BlankLayoutComponent_Host_0, {}, {}, []);
export { BlankLayoutComponentNgFactory as BlankLayoutComponentNgFactory };
