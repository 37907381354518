import {Injectable} from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  QueryFn
} from '@angular/fire/firestore';

import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/switchMap';
import {firestore} from 'firebase/app';

import { combineLatest, defer, of } from 'rxjs';
import { flatMap, map, switchMap, tap, reduce, filter } from 'rxjs/operators';

import {Pagination} from '../constants/api-response';
import { Pageable } from '../constants/pageable';
import { Query } from '@angular/fire/firestore';
import { LOCATIONS /*, LOGGER*/ } from '../constants/firestore/collections';
import { OrderByDirection } from '@google-cloud/firestore';

interface DocWithId {
  id: string;
}


// Documentat
// https://angularfirebase.com/lessons/firestore-advanced-usage-angularfire/

type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;
type DocPredicate<T> = string | AngularFirestoreDocument<T>;

@Injectable()
export class FirestoreService {

  previousPage: Pageable = null;

  constructor(private db: AngularFirestore) {
  }

/// **************
/// Write Data
/// **************
/// Firebase Server Timestamp
  get timestamp() {
    return firestore.FieldValue.serverTimestamp();
  }

  /// with Ids

  /// **************
  /// Get a Reference
  /// **************
  public col<T>(ref: CollectionPredicate<T>, queryFn?): AngularFirestoreCollection<T> {
    return typeof ref === 'string' ? this.db.collection<T>(ref, queryFn) : ref;
  }

  public doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    return typeof ref === 'string' ? this.db.doc<T>(ref) : ref;
  }

  /// **************
  /// Get Data
  /// **************
  public doc$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc(ref).snapshotChanges().map(doc => {
      return doc.payload.data() as T;
    });
  }

  public col$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<T[]> {
    return this.col(ref, queryFn).snapshotChanges().map(docs => {
      return docs.map(a => a.payload.doc.data()) as T[];
    });
  }

  /// with Ids
  public colWithIds$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<any[]> {
    return this.col(ref, queryFn).snapshotChanges().map(actions => {
      return actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return {id, ...data};
      });
    });
  }


  private paginateValues<T>(ref: string, order, queryFn?: QueryFn, pageable?: Pageable,
                           next?, prev?, filter ?, where ?: {field: string, operator: "==" | "<" | ">" | "<=" | ">=" | "array-contains", value: any}, 
                           type: OrderByDirection='asc'): Observable<Pagination> {
    let snapshot: Observable<any> = null;
    let countSnapshot: Observable<any> = null;
    this.previousPage = pageable;
    if (where) {
      countSnapshot = this.db.collection<T>(ref, ref => ref.where(where.field, where.operator, where.value)).snapshotChanges().pipe(map(r => filter ? r.filter(filter).length : r.length));
    } else {
      countSnapshot = this.db.collection<T>(ref).snapshotChanges().pipe(map(r => filter ? r.filter(filter).length : r.length));
    }
    if (next) {
      if (where) {
        snapshot = this.db.collection<T>(ref, ref => ref.orderBy(order, type).where(where.field, where.operator, where.value).startAfter(next[order]).limit(pageable.size)).valueChanges();  
      } else {
        snapshot = this.db.collection<T>(ref, ref => ref.orderBy(order, type).startAfter(next[order]).limit(pageable.size)).valueChanges();
      }
      return this.formatPaginationValues(snapshot, countSnapshot, pageable);
    }

    if (prev) {
      if (where) {
        snapshot = this.db.collection<T>(ref, ref => ref.where(where.field, where.operator, where.value).orderBy(order, type).endBefore(prev[order]).limit(pageable.size)).valueChanges();  
      } else {
        snapshot = this.db.collection<T>(ref, ref => ref.orderBy(order, type).endBefore(prev[order]).limitToLast(pageable.size)).valueChanges();
      }
      return this.formatPaginationValues(snapshot, countSnapshot, pageable);
    }

    if (filter) {
      snapshot = this.db.collection<T>(ref, ref => ref.orderBy(order, type)).snapshotChanges().pipe(map(r => r.filter(filter)));
      return this.formatPaginationSnapshots(snapshot, countSnapshot, pageable);
    } else if (queryFn) {
      snapshot = this.db.collection<T>(ref, queryFn).valueChanges();
    } else if (where) {
      snapshot = this.db.collection<T>(ref, ref => ref.orderBy(order, type).where(where.field, where.operator, where.value).limit(pageable.size)).valueChanges();
    } else {
      snapshot = this.db.collection<T>(ref, ref => ref.orderBy(order, type).limit(pageable.size)).valueChanges();
    }
    return this.formatPaginationValues(snapshot, countSnapshot, pageable);
  }


  // TODO: Unused, remove
  // public paginateLocations<T>(ref: string, order: string,
  //                             queryFn?: QueryFn, pageable?: Pageable, values?: boolean, next?,
  //                             prev?, filter?): Observable<Pagination> {
  //   return this.paginateValuesLocations(ref, order, queryFn, pageable, next, prev, filter);
  // }

  public paginateValuesLocations<T>(ref: string, order, queryFn?: QueryFn,
                                    pageable?: Pageable, next?, prev?, filter?
  ): Observable<Pagination> {

    this.previousPage = pageable;
    let snapshot: Observable<any> = null;
    let countSnapshot: Observable<any> = null;
    countSnapshot = this.db.collection<T>(ref).snapshotChanges().pipe(map(r => filter ? r.filter(filter).length : r.length));
    if (next) {
      snapshot = this.db.collection<T>(ref,
        ref => ref.orderBy('locationName').orderBy('locationId')
          .startAfter(next.locationName, next.locationId)
          .limit(pageable.size))
        .valueChanges();
      if (filter) {
        snapshot = snapshot.pipe(map(r => r.filter(filter)));
      }
      return this.formatPaginationValues(snapshot, countSnapshot, pageable);
    }

    if (prev) {
      snapshot = this.db.collection<T>(ref, ref => ref
        .orderBy('locationName').orderBy('locationId').endBefore(prev.locationName, prev.locationId)
        .limitToLast(pageable.size)).valueChanges();
      if (filter) {
        snapshot = snapshot.pipe(map(r => r.filter(filter)));
      }
      return this.formatPaginationValues(snapshot, countSnapshot, pageable);
    }

    if (filter) {
      snapshot = this.db.collection<T>(ref, ref => ref.orderBy('locationName').orderBy('locationId')).valueChanges();
      snapshot = snapshot.pipe(map(r => r.filter(filter)));
    } else {
      snapshot = this.db.collection<T>(ref, ref => ref.orderBy('locationName').orderBy('locationId').limit(pageable.size)).valueChanges();
    }

    return this.formatPaginationValues(snapshot, countSnapshot, pageable);
  }

  paginateValuesReports<T>( ref: string, order, direction, gid, report, 
    pageable ?: Pageable, next ?, prev ?, keywords ?): Observable<Pagination> {
    this.previousPage = pageable;
    let snapshot: Observable<any> = null;
    let countSnapshot: Observable<any> = null;
    if (keywords) {
      countSnapshot = this.db.collection<T>(ref, refFn => refFn
        .where('gid', '==', gid)
        .where('reportType', '==', report)
        .where(firestore.FieldPath.documentId(), 'in', keywords)
        .where('sharedOnly', '==', false)).snapshotChanges().pipe(map(r => r.length));
    } else {
      countSnapshot = this.db.collection<T>(ref, refFn => refFn
        .where('gid', '==', gid)
        .where('reportType', '==', report)
        .where('sharedOnly', '==', false)).snapshotChanges().pipe(map(r => r.length));
    }

    if (next) {
      if (keywords) {
        const secOrder = order === 'reportName' ? 'createdAt' : order;
        const secDirection = order === 'reportName' ? 'desc' : direction;
        const repDirection = order === 'reportName' ? direction : 'asc';
        snapshot = this.db.collection<T>(ref, ref => ref.orderBy('reportName', repDirection)
          .orderBy(secOrder, secDirection)
          .where('gid', '==', gid)
          .where(firestore.FieldPath.documentId(), 'in', keywords)
          .where('sharedOnly', '==', false)
          .startAfter(next[order], next.reportName)
          .limit(pageable.size))
          .snapshotChanges();
      } else {
        const secOrder = order !== 'reportName' ? 'reportName' : 'createdAt';
        const secDirection = order !== 'reportName' ? 'asc' : 'desc';
        snapshot = this.db.collection<T>(ref, ref => ref.orderBy(order, direction)
          .orderBy(secOrder, secDirection)
          .where('gid', '==', gid)
          .where('reportType', '==', report)
          .where('sharedOnly', '==', false)
          .startAfter(next[order], next.reportName)
          .limit(pageable.size))
          .snapshotChanges();
      }

      return this.formatPaginationSnapshots(snapshot, countSnapshot, pageable);
    }

    if (prev) {
      if (keywords) {
        const secOrder = order === 'reportName' ? 'createdAt' : order;
        const secDirection = order === 'reportName' ? 'desc' : direction;
        const repDirection = order === 'reportName' ? direction : 'asc';
        snapshot = this.db.collection<T>(ref, ref => ref.orderBy('reportName', repDirection)
          .orderBy(secOrder, secDirection)
          .where('gid', '==', gid)
          .where('reportType', '==', report)
          .where(firestore.FieldPath.documentId(), 'in', keywords)
          .where('sharedOnly', '==', false)
          .endBefore(prev[order], prev.reportName)
          .limit(pageable.size))
          .snapshotChanges();
      } else {
        const secOrder = order !== 'reportName' ? 'reportName' : 'createdAt';
        const secDirection = order !== 'reportName' ? 'asc' : 'desc';
        snapshot = this.db.collection<T>(ref, ref => ref.orderBy(order, direction)
          .orderBy(secOrder, secDirection)
          .where('gid', '==', gid)
          .where('reportType', '==', report)
          .where('sharedOnly', '==', false)
          .endBefore(prev[order], prev.reportName).limitToLast(pageable.size)).snapshotChanges();
      }

      return this.formatPaginationSnapshots(snapshot, countSnapshot, pageable);
    }

    if (keywords) {
      const secOrder = order === 'reportName' ? 'createdAt' : order;
      const secDirection = order === 'reportName' ? 'desc' : direction;
      const repDirection = order === 'reportName' ? direction : 'asc';
      snapshot = this.db.collection<T>(ref, ref => ref.orderBy('reportName', repDirection)
        .orderBy(secOrder, secDirection)
        .where('gid', '==', gid)
        .where('reportType', '==', report)
        .where(firestore.FieldPath.documentId(), 'in', keywords)
        .where('sharedOnly', '==', false)
        .limit(pageable.size)).snapshotChanges();
    } else {
      const secOrder = order !== 'reportName' ? 'reportName' : 'createdAt';
      const secDirection = order !== 'reportName' ? 'asc' : 'desc';
      snapshot = this.db.collection<T>(ref, ref => ref.orderBy(order, direction)
        .orderBy(secOrder, secDirection)
        .where('gid', '==', gid)
        .where('reportType', '==', report)
        .where('sharedOnly', '==', false)
        .limit(pageable.size)).snapshotChanges();
    }

    return this.formatPaginationSnapshots(snapshot, countSnapshot, pageable);
  }

// TODO: Unused, remove
//   paginateloggers<T>(ref: string, filter, order, direction, pageable?: Pageable, next?, prev?, values?): Observable<Pagination> {
//     this.previousPage = pageable;
//     let snapshot: Observable<any> = null;
//     let countSnapshot: Observable<any> = null;
//     var query : Query = null;
// 
//     var collection = this.db.collection<T>( ref ).doc(filter.domain).collection(LOGGER, refFn => {
//       query = refFn;
// 
//       /// Order BY 
//       if( order && direction ) {
//         query = query.orderBy(order, direction)
//       }
//       
//       // WHERE 
//       if( filter ) {
//         if ( filter.domain != null ) {
//           query = query.where('domain', '==', filter.domain)
//         }
//       }
// 
//       // Pagination 
//       if ( next ) {
//         query = query.startAfter( next[order] )
//         query = query.limit(pageable.size)
//       }
//       if ( prev ) {
//         query = query.endBefore( prev[order] )
//         query = query.limitToLast(pageable.size)
//       }
//       // Return query build 
//       return query
//     })
//     
//     // snapshotchanges or valueschange 
//     if ( values ) {
//       countSnapshot = collection.valueChanges().pipe(map(r => r.length));
//       snapshot = collection.valueChanges();
//       return this.formatPaginationValues(snapshot, countSnapshot, pageable);
//     }
//     else {
//       countSnapshot = collection.snapshotChanges().pipe(map(r => r.length));
//       snapshot = collection.snapshotChanges();
//       return this.formatPaginationSnapshots(snapshot, countSnapshot, pageable);
//     }
//   }

  paginateGrades<T>(ref: string, filter, order, direction, pageable?: Pageable, next?, prev?, values?): Observable<Pagination> {
    let snapshot: Observable<any> = null;
    let countSnapshot: Observable<any> = null;
    var query : Query = null;

    var collection = this.db.collection<T>( ref ).doc(filter.domain).collection(LOCATIONS, refFn => {
      query = refFn;

      /** Order BY */
      if( order && direction ) {
        query = query.orderBy(order, direction)
      }
      
      /** WHERE */
      if( filter ) {
        if ( filter.domain != null ) {
          query = query.where('registrationDomain', '==', filter.domain)
        }
        if ( filter.viewed != null ) {
          query = query.where('viewed', '==', filter.viewed)
        }
      }

      /** Pagination */
      if ( next ) {
        query = query.startAfter( next[order] )
        query = query.limit(pageable.size)
      }
      if ( prev ) {
        query = query.endBefore( prev[order] )
        query = query.limitToLast(pageable.size)
      }
      /** Return query build */
      return query
    })
    
    /** snapshotchanges or valueschange */
    if ( values ) {
      countSnapshot = collection.valueChanges().pipe(map(r => r.length));
      snapshot = collection.valueChanges();
      return this.formatPaginationValues(snapshot, countSnapshot, pageable);
    }
    else {
      countSnapshot = collection.snapshotChanges().pipe(map(r => r.length));
      snapshot = collection.snapshotChanges();
      return this.formatPaginationSnapshots(snapshot, countSnapshot, pageable);
    }
  }

  paginateUsers<T>(ref: string, domain, order, direction, pageable?: Pageable, next?, prev?): Observable<Pagination> {
    let snapshot: Observable<any> = null;
    let countSnapshot: Observable<any> = null;
    countSnapshot = this.db.collectionGroup<T>(ref, refFn => refFn.orderBy(order, direction)
      .where('registrationDomain', 'in', [domain, domain + ':'])).snapshotChanges().pipe(map(r => r.length));

    if (next) {
      snapshot = this.db.collectionGroup<T>(ref, refFn => refFn.orderBy(order, direction)
        .where('registrationDomain', 'in', [domain, domain + ':'])
        .startAfter(next[order])
        .limit(pageable.size))
        .snapshotChanges();

      return this.formatPaginationSnapshots(snapshot, countSnapshot, pageable);
    }

    if (prev) {
      snapshot = this.db.collectionGroup<T>(ref, refFn => refFn.orderBy(order, direction)
        .where('registrationDomain', 'in', [domain, domain + ':'])
        .endBefore(prev[order]).limitToLast(pageable.size)).snapshotChanges();

      return this.formatPaginationSnapshots(snapshot, countSnapshot, pageable);
    }

    snapshot = this.db.collectionGroup<T>(ref, refFn => refFn.orderBy(order, direction)
      .where('registrationDomain', 'in', [domain, domain + ':'])
      .limit(pageable.size)).snapshotChanges();

    return this.formatPaginationSnapshots(snapshot, countSnapshot, pageable);
  }

  paginateSnapshot<T>(ref: string, order: string, queryFn ?: QueryFn,
                      pageable ?: Pageable, next ?, prev ?, filter ?, where ?: {field: string, operator: "==" | "<" | ">" | "<=" | ">=" | "array-contains", value: any},
                      type: OrderByDirection='asc'): Observable<Pagination> {
    let snapshot: Observable<any> = null;
    let countSnapshot: Observable<any> = null;
    if (where) {
      countSnapshot = this.db.collection<T>(ref, ref => ref.where(where.field, where.operator, where.value)).snapshotChanges().pipe(map(r => filter ? r.filter(filter).length : r.length));
    } else {
      countSnapshot = this.db.collection<T>(ref).snapshotChanges().pipe(map(r => filter ? r.filter(filter).length : r.length));
    }

    if (next) {
      if (where) {
        snapshot = this.db.collection<T>(ref, ref => ref.orderBy(order, type).where(where.field, where.operator, where.value).startAfter(next[order]).limit(pageable.size)).snapshotChanges();  
      } else {
        snapshot = this.db.collection<T>(ref, ref => ref.orderBy(order, type).startAfter(next[order]).limit(pageable.size)).snapshotChanges();
      }
      return this.formatPaginationSnapshots(snapshot, countSnapshot, pageable);
    }

    if (prev) {
      if (where) {
        snapshot = this.db.collection<T>(ref, ref => ref.where(where.field, where.operator, where.value).orderBy(order, type).endBefore(prev[order]).limit(pageable.size)).snapshotChanges();  
      } else {
        snapshot = this.db.collection<T>(ref, ref => ref.orderBy(order, type).endBefore(prev[order]).limitToLast(pageable.size)).snapshotChanges();
      }
      return this.formatPaginationSnapshots(snapshot, countSnapshot, pageable);
    }
    if (filter) {
      snapshot = this.db.collection<T>(ref, ref =>ref.orderBy(order, type)).snapshotChanges().pipe(map(r => r.filter(filter)));
    } else if (queryFn) {
      snapshot = this.db.collection<T>(ref, queryFn).snapshotChanges();
    } else if (where) {
      snapshot = this.db.collection<T>(ref, ref => ref.orderBy(order, type).where(where.field, where.operator, where.value).limit(pageable.size)).snapshotChanges();
    } else {
      snapshot = this.db.collection<T>(ref, ref => ref.orderBy(order, type).limit(pageable.size)).snapshotChanges();
    }
    return this.formatPaginationSnapshots(snapshot, countSnapshot, pageable);
  }

  paginate<T>(ref: string, order: string, queryFn ?: QueryFn, pageable ?: Pageable,
              values ?: boolean, next ?, prev ?, filter?, where ?, type: OrderByDirection='asc'
  ):
    Observable<Pagination> {
      this.previousPage = pageable;
      if (values) {
        return this.paginateValues(ref, order, queryFn, pageable, next, prev, filter, where, type);
      } else {
        return this.paginateSnapshot(ref, order, queryFn, pageable, next, prev, filter, where, type);
      }
  }


  formatPaginationValues(snapshot: Observable<any>, ob: Observable<any>, pageable: Pageable) {
    return combineLatest(ob, snapshot).pipe(
      filter( d => pageable.page == this.previousPage.page),
      switchMap( (result) => {
        return of(this.formatPagination(result[0], pageable, result[1]))
      })
    );
  }

  formatPaginationSnapshots(snapshot:Observable<any>, 
                            ob : Observable<any>, 
                            pageable: any) {
    return combineLatest(ob, snapshot).pipe(
      filter( d => pageable.page == this.previousPage.page),
      map( (result) => {
        const tmp = result[1].map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return {id, ...data};
        });
        return {data: tmp, count: result[0]}
      }),
      map((data) => this.formatPagination(data.count, pageable, data.data))
    );
  }

  formatPagination(count, pageable, actions) {
    const pages = Math.ceil(count / pageable.size);
    let hasPrev = true;
    let hasNext = true;
    if (pages === pageable.page && pages > 1) {
      hasNext = false;
      hasPrev = true;
    } else if (pages === pageable.page && pages === 1) {
      hasNext = false;
      hasPrev = false;
    } else if (pageable.page === 1 && pages !== 0) {
      hasPrev = false;
      hasNext = true;
    } else if (pageable.page > 1 && pageable.page < pages) {
      hasPrev = true;
      hasNext = true;
    } else {
      hasPrev = false;
      hasNext = false;
    }


    const pagination: Pagination = {
      items: actions,
      total: count,
      per_page: pageable.size,
      page: pageable.page,
      pages,
      hasPrev,
      hasNext,
    };

    return pagination;
  }


  paginateValueChanges<T>(ref, queryFn, order, next, prev, pageable) {
    let snapshot: Observable<any> = null;
    let countSnapshot: Observable<any> = null;
    countSnapshot = this.db.collection<T>(ref).snapshotChanges().pipe(map(r => r.length));

    if (next) {
      snapshot = this.db.collection<T>(ref, ref => ref.orderBy(order).startAfter(next[order]).limit(pageable.size)).valueChanges();
      return this.formatPagination(snapshot, countSnapshot, pageable);
    }

    if (prev) {
      snapshot = this.db.collection<T>(ref, ref => ref.orderBy(order).endBefore(prev[order]).limitToLast(pageable.size)).valueChanges();
      return this.formatPagination(snapshot, countSnapshot, pageable);
    }

    return this.formatPagination(snapshot, countSnapshot, pageable);
  }


  sliceItemsPagination(pageable
                         :
                         Pageable, items
                         :
                         any[]
  ) {
    if (pageable.page === 1) {
      return items.slice(0, (pageable.page * pageable.size));
    }
    return items.slice(((pageable.page - 1) * pageable.size), (pageable.page * pageable.size) - 1);
  }

  docWithIds$<T>(ref
                   :
                   DocPredicate<T>
  ):
    Observable<any> {
    return this.doc(ref).snapshotChanges().map(a => {
      const data = a.payload.data();
      const id = a.payload.id;
      return {id, ...data};
    });
  }

  set<T>(ref
           :
           DocPredicate<T>, data
           :
           any
  ) {
    const timestamp = this.timestamp;
    return this.doc(ref).set({
      ...data,
      updatedAt: timestamp,
      createdAt: timestamp
    }, {merge: true});
  }

  update<T>(ref
              :
              DocPredicate<T>, data
              :
              any
  ) {
    return this.doc(ref).update({
      ...data,
      updatedAt: this.timestamp
    });
  }

  delete<T>(ref: DocPredicate<T>) {
    return this.doc(ref).delete();
  }

  add<T>(ref
           :
           CollectionPredicate<T>, data
  ) {
    const timestamp = this.timestamp;
    return this.col(ref).add({
      ...data,
      updatedAt: timestamp,
      createdAt: timestamp
    });
  }

  geopoint(lat
             :
             number, lng
             :
             number
  ) {
    return new firestore.GeoPoint(lat, lng);
  }

/// If doc exists update, otherwise set
  upsert<T>(ref
              :
              DocPredicate<T>, data
              :
              any
  ) {
    const doc = this.doc(ref).snapshotChanges().take(1).toPromise();
    return doc.then(snap => {
      return snap.payload.exists ? this.update(ref, data) : this.set(ref, data);
    });
  }

/// **************
/// Inspect Data
/// **************
  inspectDoc(ref
               :
               DocPredicate<any>
  ):
    void {
    const tick = new Date().getTime();
    this.doc(ref).snapshotChanges()
      .take(1)
      .do(d => {
        const tock = new Date().getTime() - tick;
      })
      .subscribe();
  }

  inspectCol(ref
               :
               CollectionPredicate<any>
  ):
    void {
    const tick = new Date().getTime();
    this.col(ref).snapshotChanges()
      .take(1)
      .do(c => {
        const tock = new Date().getTime() - tick;
      })
      .subscribe();
  }

/// **************
/// Create and read doc references
/// **************
/// create a reference between two documents
  connect(host
            :
            DocPredicate<any>, key
            :
            string, doc
            :
            DocPredicate<any>
  ) {
    return this.doc(host).update({[key]: this.doc(doc).ref});
  }

/// returns a documents references mapped to AngularFirestoreDocument
  docWithRefs$<T>(ref
                    :
                    DocPredicate<T>
  ) {
    return this.doc$(ref).map(doc => {
      for (const k of Object.keys(doc)) {
        if (doc[k] instanceof firestore.DocumentReference) {
          doc[k] = this.doc(doc[k].path);
        }
      }
      return doc;
    });
  }

/// **************
/// Atomic batch example
/// **************
/// Just an example, you will need to customize this method.
  atomic() {
    // const batch = firebase.firestore().batch();
    // /// add your operations here
    // const itemDoc = firebase.firestore().doc('items/myCoolItem');
    // const userDoc = firebase.firestore().doc('users/userId');
    // const currentTime = this.timestamp;
    // batch.update(itemDoc, {timestamp: currentTime});
    // batch.update(userDoc, {timestamp: currentTime});
    // /// commit operations
    // return batch.commit();
  }


// 1:1 Joins
  docJoin = (
    afs: AngularFirestore,
    paths: { [key: string]: string }
  ) => {
    return source =>
      defer(() => {
        let parent;
        const keys = Object.keys(paths);

        return source.pipe(
          switchMap(data => {
            // Save the parent data state
            parent = data;

            // Map each path to an Observable
            const docs$ = keys.map(k => {
              const fullPath = `${paths[k]}/${parent[k]}`;
              return afs.doc(fullPath).valueChanges();
            });

            // return combineLatest, it waits for all reads to finish
            return combineLatest(docs$);
          }),
          map(arr => {
            // We now have all the associated douments
            // Reduce them to a single object based on the parent's keys
            const joins = keys.reduce((acc, cur, idx) => {
              return {...acc, [cur]: arr[idx]};
            }, {});

            // Return the parent doc with the joined objects
            return {...parent, ...joins};
          })
        );
      });
  }

// 1:n Join
  leftJoin = (
    afs: AngularFirestore,
    field,
    collection,
    limit = 100
  ) => {
    return source =>
      defer(() => {
        // Operator state
        let collectionData;

        // Track total num of joined doc reads
        let totalJoins = 0;

        return source.pipe(
          switchMap(data => {
            // Clear mapping on each emitted val ;
            // Save the parent data state
            collectionData = data as any[];

            const reads$ = [];
            for (const doc of collectionData) {
              // Push doc read to Array
              if (doc[field]) {
                // Perform query on join key, with optional limit
                const q = ref => ref.where(field, '==', doc[field]).limit(limit);

                reads$.push(afs.collection(collection, q).valueChanges());
              } else {
                reads$.push(of([]));
              }
            }

            return combineLatest(reads$);
          }),
          map(joins => {
            return collectionData.map((v, i) => {
              totalJoins += joins[i].length;
              return {...v, [collection]: joins[i] || null};
            });
          }),
          tap(final => {
            totalJoins = 0;
          })
        );
      });
  }

// ---------

  convertSnapshots<T>(snaps) {
    return snaps.map(snap => {
      return {
        id: snap.payload.doc.id,
        ...snap.payload.doc.data()
      };
    }) as T[];
  }

  getDocumentsWithSubcollection<T extends DocWithId>(afs
                                                       :
                                                       AngularFirestore, collection
                                                       :
                                                       string, subCollection
                                                       :
                                                       string
  ) {
    return afs
      .collection(collection)
      .snapshotChanges()
      .pipe(
        map(this.convertSnapshots),
        map((documents: T[]) =>
          documents.map(document => {
            return afs
              .collection(`${collection}/${document.id}/${subCollection}`)
              .snapshotChanges()
              .pipe(
                map(this.convertSnapshots),
                map(subdocuments =>
                  Object.assign(document, {[subCollection]: subdocuments})
                )
              );
          })
        ),
        flatMap(combined => combineLatest(combined))
      );
  }
}



