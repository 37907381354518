<!--filter-->
<app-reports-filter></app-reports-filter>

<!--rollup insight content-->
<div class="container--full container--padding-lg">
  <div class="row box--height">

    <!--All Locations-->
    <div class="col-12 mb--30">
      <div class="box box--rounded box--shadow bg--white">
        <div class="box__header">
          <h2 class="txt--capitalize"><strong>All locations</strong></h2>
        </div>
        <div class="box__content box--padding-xs">
          <div class="row">
            <div class="col-12 col-lg-7">
              <div class="row">
                <ng-container *ngFor="let key of multiKeys; let i = index;">
                  <div class="col-6 col-md-4 mb--30">
                    <button mat-button class="p--0 w--100" (click)="setSelectedItem(i)">
                      <div class="box box--shadow box--border-sm box--padding-xxxs box--flex box--{{ key.status }}" [ngClass]="{'box--selected': selectedItem === i}">
                        <h6 class="txt--capitalize mb--10"><strong>{{ key.name }}</strong></h6>
                        <div class="d-flex justify-content-center align-items-center">
                          <mat-chip-list>
                            <mat-chip disabled>1</mat-chip>
                            <svg-icon src="/assets/images/icons/graph-rise.svg"></svg-icon>
                            <mat-chip disabled>32%</mat-chip>
                          </mat-chip-list>
                        </div>
                      </div>
                    </button>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="col-12 col-lg-5">
              <img src="/assets/images/placeholder/comparison-graph.png" alt="" class="img--fluid">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 mb--30">
      <div class="box box--rounded box--shadow bg--white">
        <div class="box__header">
          <h2 class="txt--capitalize"><strong>locations</strong></h2>
        </div>
        <div class="box__content">
          <mat-accordion displayMode="flat">
            <mat-expansion-panel class="box" *ngFor="let item of multipleLocation; let i = index;" hideToggle>
              <mat-expansion-panel-header class="border-bottom" collapsedHeight="111px" expandedHeight="111px">
                <mat-panel-title class="flex-column">
                  <p class="m--0 txt--black txt--md"><strong>{{ item.location.name }}</strong></p>
                  <p class="txt--lightgray m--0">{{ item.location?.address ? item.location?.address : '' }}</p>

                  <div class="d-flex mt--5">
                    <a href="" matTooltip="View Insights"
                       matTooltipClass="tooltip tooltip--blue"
                       matTooltipPosition="above"
                       class="btn btn--flex btn--blue btn-icon--xl mr--5">
                      <img src="/assets/images/icons/graph.png" alt="Graph Icon">
                    </a>
                    <a href=""
                       matTooltip="See Reviews"
                       matTooltipClass="tooltip tooltip--orange"
                       matTooltipPosition="above"
                       class="btn btn--flex btn--orange btn-icon--xl mr--5">
                      <img src="/assets/images/icons/star-empty.png" alt="Star Icon">
                    </a>
                    <a href=""
                       matTooltip="See Location on Map"
                       matTooltipClass="tooltip tooltip--green"
                       matTooltipPosition="above"
                       class="btn btn--flex btn--green btn-icon--xl">
                      <img src="/assets/images/icons/pin-green.png" alt="Pin Icon">
                    </a>
                  </div>
                </mat-panel-title>
                <mat-panel-description class="align-items-center justify-content-end">
                  <mat-icon><span class="mat-expansion-panel-icon"></span></mat-icon>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <div class="row border-box">
                  <div class="col-12 col-lg-7">
                    <div class="row">
                      <ng-container *ngFor="let key of multiKeys; let j = index;">
                        <div class="col-6 col-md-4 mb--30">
                          <button mat-button class="p--0 w--100" (click)="setSelectedItemNested((i * 15) + j)">
                            <div class="box box--shadow box--border-sm box--padding-xxxs box--flex box--{{ key.status }}" [ngClass]="{'box--selected': selectedNestedItem === ((i * 15) + j)}">
                              <h6 class="txt--capitalize mb--10"><strong>{{ key.name }}</strong></h6>
                              <div class="d-flex justify-content-center align-items-center">
                                <mat-chip-list>
                                  <mat-chip disabled>1</mat-chip>
                                  <svg-icon src="/assets/images/icons/graph-rise.svg"></svg-icon>
                                  <mat-chip disabled>32%</mat-chip>
                                </mat-chip-list>
                              </div>
                            </div>
                          </button>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div class="col-12 col-lg-5">
                    <img src="/assets/images/placeholder/comparison-graph.png" alt="" class="img--fluid">
                  </div>
                </div>
              </ng-template>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>

    <!--multi locations table-->
    <div class="col-12 mb--30">
      <app-table-multilocations></app-table-multilocations>
    </div>
  </div>
</div>
