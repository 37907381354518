import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var GlobalSearchService = /** @class */ (function () {
    function GlobalSearchService(http) {
        this.http = http;
    }
    GlobalSearchService.prototype.search = function (query, gid) {
        var data = { query: query };
        return this.http.post(environment.apiUrl + "/v2/search/global-search/" + gid, data);
    };
    GlobalSearchService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GlobalSearchService_Factory() { return new GlobalSearchService(i0.ɵɵinject(i1.HttpClient)); }, token: GlobalSearchService, providedIn: "root" });
    return GlobalSearchService;
}());
export { GlobalSearchService };
