import {Component, Input, OnInit} from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {
  BORDERS_CHART_DOUGHNUT,
  BORDERS_CHART_DOUGHNUT_REVENUE,
  COLORS_CHART_DOUGHNUT, COLORS_CHART_DOUGHNUT_REVENUE
} from '../../../constants/chart-color';
import {tooltipLabelCallback} from '../../../constants/tooltip';

@Component({
  selector: 'app-chart-donut',
  templateUrl: './chart-donut.component.html',
  styleUrls: ['./chart-donut.component.scss']
})
export class ChartDonutsComponent implements OnInit {
  @Input() dataset: any[];
  @Input() colorChange: string = '';
  private initData = [];
  private initLabels = [];
  data;
  labels = [];
  hidden: number[] = [];

  // Charts Line Options
  public barChartType: ChartType = 'doughnut';
  public barChartLegend = false;
  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 50,
    tooltips: {
      enabled: true,
      callbacks: {
        label: tooltipLabelCallback
      }
    },

  };

  dataSets: ChartDataSets[];


  constructor() {
  }

  ngOnInit() {
    this.dataset.forEach(d => {
      this.initData.push(d?.total?.numeric);
      this.initLabels.push(d?.label);
    });

    this.data = this.initData;
    this.labels = this.initLabels;
    if(this.colorChange !== 'switch-colors') {
      this.dataSets = [{data: this.data, backgroundColor: COLORS_CHART_DOUGHNUT, borderColor: BORDERS_CHART_DOUGHNUT}];
    } else {
      this.dataSets = [{data: this.data, backgroundColor: COLORS_CHART_DOUGHNUT_REVENUE, borderColor: BORDERS_CHART_DOUGHNUT_REVENUE}];
    }

  }

  hideData(i: number, hide: boolean) {
    if (hide) {
      this.hidden.push(i);
    } else {
      this.hidden = this.hidden.filter(value => value !== i);
    }

    this.data = [];
    this.initData.forEach((d, dataIndex) => {
      let isHidden = false;
      this.hidden.forEach((h, hiddenIndex) => {
        if (dataIndex === hiddenIndex) {
          isHidden = true;
        }
      });
      if (!isHidden) {
        this.data.push(d);
      }
    });
  }

  isDataHidden(i: number) {
    return this.hidden.includes(i);
  }
}
