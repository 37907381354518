import { __assign } from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange, MatDialogRef } from '@angular/material';
import { LocationEditService } from 'src/app/services/location-edit.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { REGEX_COMPLEX_URL } from '../../constants/regex';
var ModalActionLinksComponent = /** @class */ (function () {
    function ModalActionLinksComponent(dialogRef, _locationEditS, _snack, data) {
        this.dialogRef = dialogRef;
        this._locationEditS = _locationEditS;
        this._snack = _snack;
        this.data = data;
        this.title = 'Links';
        this.actionLinks = {
            displayName: null,
            placeActionLinks: [],
            placeActionType: null
        };
        this.placeActionLinks = [];
        this.actionLinksAttributes = [];
        this.isEdit = true;
        this.bulk = false;
        this.bulkActionLinks = [];
        this.validResult = new EventEmitter(false);
    }
    ModalActionLinksComponent.prototype.ngOnInit = function () {
        var _a, _b, _c;
        this.title = (_a = this.data) === null || _a === void 0 ? void 0 : _a.title;
        this.actionLinks = (_b = this.data) === null || _b === void 0 ? void 0 : _b.actionLinks;
        this.placeActionLinks = (_c = this.actionLinks) === null || _c === void 0 ? void 0 : _c.placeActionLinks;
        this.initForm();
    };
    ModalActionLinksComponent.prototype.initForm = function () {
        var _this = this;
        var _a;
        this.actionLinksData = new FormArray([]);
        (_a = this.placeActionLinks) === null || _a === void 0 ? void 0 : _a.forEach(function (el) {
            _this.addActionLinks(el);
        });
    };
    ModalActionLinksComponent.prototype.addActionLinks = function (element) {
        var _a, _b, _c;
        this.actionLinksData.push(new FormGroup({
            url: new FormControl((_a = element) === null || _a === void 0 ? void 0 : _a.uri, [Validators.required, Validators.pattern(REGEX_COMPLEX_URL)]),
            isPreferred: new FormControl(((_b = element) === null || _b === void 0 ? void 0 : _b.isPreferred) || false),
            name: new FormControl((_c = element) === null || _c === void 0 ? void 0 : _c.name)
        }));
    };
    ModalActionLinksComponent.prototype.removeActionLinks = function (index) {
        this.actionLinksData.removeAt(index);
    };
    ModalActionLinksComponent.prototype.handleCheckPreferred = function (event, index) {
        this.actionLinksData.controls[index].get('isPreferred').setValue(event.checked);
        if (event.checked) {
            this.actionLinksData.controls.forEach(function (control, i) {
                if (i !== index) {
                    control.get('isPreferred').setValue(false);
                }
            });
        }
    };
    ModalActionLinksComponent.prototype.hasError = function (index, field, error) {
        var _a, _b, _c;
        var control = (_a = this.actionLinksData) === null || _a === void 0 ? void 0 : _a.at(index);
        return ((_c = (_b = control) === null || _b === void 0 ? void 0 : _b.get(field)) === null || _c === void 0 ? void 0 : _c.hasError(error)) && control.touched;
    };
    ModalActionLinksComponent.prototype.apply = function () {
        var _this = this;
        if (!this.actionLinksData.valid) {
            this.actionLinksData.markAllAsTouched();
            return;
        }
        var index = this._locationEditS.locationEdit.placeActionLinks.findIndex(function (el) { return el.placeActionType === _this.actionLinks.placeActionType; });
        this._locationEditS.locationEdit.placeActionLinks[index].placeActionLinks = this.builData();
        this._locationEditS.setAndUpdate().toPromise()
            .then(function () {
            _this.dialogRef.close(true);
        })
            .catch(function () {
            _this._snack.openError('There was an error while saving the data. Please try again or contact support');
            _this.dialogRef.close(false);
        });
    };
    ModalActionLinksComponent.prototype.builData = function () {
        var _this = this;
        var data = [];
        var newElement;
        this.actionLinksData.controls.forEach(function (control) {
            var _a, _b, _c;
            newElement = {};
            var index = (_b = (_a = _this.actionLinks) === null || _a === void 0 ? void 0 : _a.placeActionLinks) === null || _b === void 0 ? void 0 : _b.findIndex(function (el) { var _a; return el.name === ((_a = control.get('name')) === null || _a === void 0 ? void 0 : _a.value); });
            var url = (_c = (control.get('url').value)) === null || _c === void 0 ? void 0 : _c.replace(/ /g, '%20');
            if (index > -1) {
                newElement = __assign(__assign({}, _this.actionLinks.placeActionLinks[index]), { uri: url, isPreferred: control.get('isPreferred').value });
            }
            else {
                newElement = {
                    uri: url,
                    isPreferred: control.get('isPreferred').value,
                    name: null
                };
            }
            data.push(newElement);
        });
        return data;
    };
    ModalActionLinksComponent.prototype.getResult = function () {
        if (!this.actionLinksData.valid) {
            this.actionLinksData.markAllAsTouched();
            return;
        }
        this.validResult.emit(true);
        return this.builData();
    };
    return ModalActionLinksComponent;
}());
export { ModalActionLinksComponent };
