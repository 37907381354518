import { AngularFirestore } from "@angular/fire/firestore";
import { BehaviorSubject } from "rxjs";
import { GRADE_LEADS, LOCATIONS } from "../constants/firestore/collections";
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
var GradeLeadService = /** @class */ (function () {
    function GradeLeadService(afs) {
        this.afs = afs;
        // public loading = false;
        this._gradeLeads = null;
        this.firebaseGradeId$ = new BehaviorSubject("");
        this._gradeLeads = new BehaviorSubject([]);
    }
    // TODO: Unused, remove
    // getAllDomains() {   
    //   return  this.afs.collectionGroup<any>(GRADE_LEADS).valueChanges()
    // }
    // 
    // getAllGradeLeads(domain: string) {
    //   return  this.afs.collection(GRADE_LEADS).doc(domain).collection(LOCATIONS).valueChanges()
    // }
    // 
    // getGradeLeads(pageable?: Pageable, filter?: { domain: string, viewed: boolean}, next?, prev?, order = 'createdAt', direction = 'desc', values?): Observable<any> {
    //   return this.fs.paginateGrades(GRADE_LEADS, filter, order, direction, pageable, next, prev, values);
    // }
    //  
    // set(gradeLeads: GradeLead[]) {
    //   this._gradeLeads.next(gradeLeads);
    // }
    //
    // getGradeLeadPaginate(count, pageable, actions) {
    //   return this.fs.formatPagination(count, pageable, actions);    
    // }
    /**
     * this method save in firestore entity Grade Lead
     * @param GradeLead
     */
    GradeLeadService.prototype.save = function (gradeLead) {
        var _this = this;
        this.afs.collection(GRADE_LEADS).doc(gradeLead.registrationDomain).get().toPromise().then(function (domainCollection) {
            if (!domainCollection.exists) {
                _this.afs.collection(GRADE_LEADS).doc(gradeLead.registrationDomain).set({ domain: gradeLead.registrationDomain });
            }
            _this.afs.collection(GRADE_LEADS)
                .doc(gradeLead.registrationDomain)
                .collection(LOCATIONS)
                .add(gradeLead)
                .then(function (docRef) {
                _this.firebaseGradeId$.next(docRef.id);
            })
                .catch(function () { });
        });
    };
    // TODO: Unused, remove
    // update(gradeLead: GradeLead) {
    //   return this.afs.collection(GRADE_LEADS).doc(gradeLead.registrationDomain).collection(LOCATIONS).doc(gradeLead.id).set(gradeLead)
    // }
    GradeLeadService.prototype.getGradeLeadById = function (id, domain) {
        return this.afs.collection(GRADE_LEADS)
            .doc(domain)
            .collection(LOCATIONS)
            .doc(id)
            .get()
            .toPromise()
            .then(function (doc) {
            var _a, _b, _c;
            if (doc.exists) {
                var data = doc.data();
                var gradeLead = {
                    id: doc.id,
                    name: data.name,
                    locationName: data.locationName,
                    locationId: data.locationId,
                    accountId: data.accountId,
                    accountName: data.accountName,
                    registrationDomain: (_a = data) === null || _a === void 0 ? void 0 : _a.registrationDomain,
                    address: (_b = data) === null || _b === void 0 ? void 0 : _b.address,
                    email: (_c = data) === null || _c === void 0 ? void 0 : _c.email,
                    viewed: data.viewed,
                    createdAt: data.createdAt,
                };
                return gradeLead;
            }
        });
    };
    GradeLeadService.prototype.setHasPdf = function (gradeLead) {
        this.afs.collection(GRADE_LEADS)
            .doc(gradeLead.registrationDomain)
            .collection(LOCATIONS)
            .doc(gradeLead.id)
            .update({ hasPdf: true });
    };
    GradeLeadService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GradeLeadService_Factory() { return new GradeLeadService(i0.ɵɵinject(i1.AngularFirestore)); }, token: GradeLeadService, providedIn: "root" });
    return GradeLeadService;
}());
export { GradeLeadService };
