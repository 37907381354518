/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "../../../modules/toggle-locations-in-account/toggle-locations-in-account.component.ngfactory";
import * as i3 from "../../../modules/toggle-locations-in-account/toggle-locations-in-account.component";
import * as i4 from "../../../services/location.service";
import * as i5 from "../../../services/protocol.service";
import * as i6 from "../../../services/auth.service";
import * as i7 from "../../../services/group.service";
import * as i8 from "@angular/common/http";
import * as i9 from "@angular/router";
import * as i10 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i11 from "@angular/material/button";
import * as i12 from "@angular/cdk/a11y";
import * as i13 from "@angular/platform-browser/animations";
import * as i14 from "./user-setting-dialog.component";
import * as i15 from "../../../services/user.service";
var styles_UserSettingDialogComponent = [];
var RenderType_UserSettingDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UserSettingDialogComponent, data: {} });
export { RenderType_UserSettingDialogComponent as RenderType_UserSettingDialogComponent };
export function View_UserSettingDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "dialog__header txt--center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "h2", [["class", "m--0 txt--xl mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(2, 81920, null, 0, i1.MatDialogTitle, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], null, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Edit User Settings"])), (_l()(), i0.ɵeld(5, 0, null, null, 3, "div", [["class", "dialog__content mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i0.ɵdid(6, 16384, null, 0, i1.MatDialogContent, [], null, null), (_l()(), i0.ɵeld(7, 0, null, null, 1, "app-toggle-locations-in-account", [["class", "locations-no-padding"]], null, [[null, "selected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selected" === en)) {
        var pd_0 = (_co.selectedLocations($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ToggleLocationsInAccountComponent_0, i2.RenderType_ToggleLocationsInAccountComponent)), i0.ɵdid(8, 770048, null, 0, i3.ToggleLocationsInAccountComponent, [i4.LocationService, i5.ProtocolService, i6.AuthService, i7.GroupService, i8.HttpClient, i9.Router], { reportEdit: [0, "reportEdit"], selectByUser: [1, "selectByUser"] }, { selected: "selected" }), (_l()(), i0.ɵeld(9, 0, null, null, 9, "div", [["class", "dialog__footer mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i0.ɵdid(10, 16384, null, 0, i1.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(11, 0, null, null, 4, "span", [["class", "alternate-theme d-inline-block mr--10"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 3, "button", [["class", "btn btn-cancel"], ["color", "primary"], ["mat-button", ""], ["mat-dialog-close", ""], ["tabIndex", "-1"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 14).dialogRef.close(i0.ɵnov(_v, 14).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_MatButton_0, i10.RenderType_MatButton)), i0.ɵdid(13, 180224, null, 0, i11.MatButton, [i0.ElementRef, i12.FocusMonitor, [2, i13.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i0.ɵdid(14, 606208, null, 0, i1.MatDialogClose, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i0.ɵted(-1, 0, ["Cancel"])), (_l()(), i0.ɵeld(16, 0, null, null, 2, "button", [["class", "btn btn--action"], ["color", "accent"], ["mat-flat-button", ""], ["tabIndex", "-1"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_MatButton_0, i10.RenderType_MatButton)), i0.ɵdid(17, 180224, null, 0, i11.MatButton, [i0.ElementRef, i12.FocusMonitor, [2, i13.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i0.ɵted(-1, 0, ["Save"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_1 = _co.accountsSelected; var currVal_2 = true; _ck(_v, 8, 0, currVal_1, currVal_2); var currVal_7 = "primary"; _ck(_v, 13, 0, currVal_7); var currVal_8 = ""; _ck(_v, 14, 0, currVal_8); var currVal_11 = "accent"; _ck(_v, 17, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_3 = (i0.ɵnov(_v, 13).disabled || null); var currVal_4 = (i0.ɵnov(_v, 13)._animationMode === "NoopAnimations"); var currVal_5 = (i0.ɵnov(_v, 14).ariaLabel || null); var currVal_6 = i0.ɵnov(_v, 14).type; _ck(_v, 12, 0, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = (i0.ɵnov(_v, 17).disabled || null); var currVal_10 = (i0.ɵnov(_v, 17)._animationMode === "NoopAnimations"); _ck(_v, 16, 0, currVal_9, currVal_10); }); }
export function View_UserSettingDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-user-dialog", [], null, null, null, View_UserSettingDialogComponent_0, RenderType_UserSettingDialogComponent)), i0.ɵdid(1, 245760, null, 0, i14.UserSettingDialogComponent, [i1.MAT_DIALOG_DATA, i1.MatDialogRef, i15.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserSettingDialogComponentNgFactory = i0.ɵccf("app-user-dialog", i14.UserSettingDialogComponent, View_UserSettingDialogComponent_Host_0, {}, {}, []);
export { UserSettingDialogComponentNgFactory as UserSettingDialogComponentNgFactory };
