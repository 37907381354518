// dep
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import 'rxjs-compat/add/operator/filter';
import { takeUntil, take } from 'rxjs/operators';
import { Subject } from 'rxjs';

// app
import { WhiteLabelService } from '../../services/white-label.service';
import { DataTransferService } from '../../services/data-transfer.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {

  isFullWidth: boolean;
  isHeader: boolean;
  biggerPadding: boolean;
  destroySubs$ = new Subject<boolean>()
  isAuth: boolean;
  contentClass: any;

  constructor( private router: Router, private activatedRoute: ActivatedRoute, private titleService: Title,
              private wl: WhiteLabelService, private dataTransfer:  DataTransferService<string>) {
    const appTitle = this.titleService.getTitle();
    router.events.pipe(takeUntil(this.destroySubs$))
      .filter((event) => event instanceof NavigationEnd)
      .map(() => {
        const child = this.activatedRoute.firstChild;
        if (child.snapshot.data['title']) {
          return child.snapshot.data['title'];
        }
        return appTitle;
      }).subscribe( async (title: string) => {

        const company_name = (await this.wl.getDynamicWhiteLabel()).branding.company_name

        if (title == "Report") {
          this.dataTransfer.getData().pipe(take(3)).subscribe( reportName => {
            this.titleService.setTitle(`${reportName} | ${company_name}`)
          })
        } else {
          this.titleService.setTitle(`${title} | ${company_name}`)
        }
        const url = this.router.url
        
        this.isFullWidth   = url.includes('report/')
        this.isAuth        = (url.includes('login') || url.includes('register') || url.includes('forgot-password'))
        this.isHeader      = url.includes('report/')
        this.biggerPadding = url.includes('billing')

        this.setContentClass();
      });
  }

  ngOnDestroy(): void {
    this.destroySubs$.next(true);
    this.destroySubs$.unsubscribe();
  }

  ngOnInit() {}

  setContentClass(): void {
    this.contentClass = {
      '' : this.isAuth,
      'content--full' : this.isFullWidth,
      'content' : !this.isFullWidth && !this.isAuth,
      'content-header': this.isHeader,
      'content--padding': this.biggerPadding
    };
  }
}
