// TODO: Remove, backend queues shouldn't be referenced from frontend

export enum Queue {
  // Not used:
  //
  // INSIGHTS_NORMAL = 'insightsnormal',
  // INSIGHTS_EXPRESS = 'insightsexpress',
  // REVIEWS_NORMAL = 'reviewsnormal',
  // REVIEWS_EXPRESS = 'reviewsexpress',
  // COMBINED_NORMAL = 'combinednormal',
  COMBINED_EXPRESS = 'combinedexpress1'
}
