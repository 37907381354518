import { MainComponent } from './modules/main/main.component';
import { AccountsComponent } from './modules/accounts/accounts.component';
import { LocationsComponent } from './modules/locations/locations.component';
import { RollupReportComponent } from './modules/rollup-report/rollup-report.component';
import { ReviewReportsComponent } from './modules/review-reports/review-reports.component';
import { ComparisonReportsComponent } from './modules/comparison-reports/comparison-reports.component';
import { RevenueReportsComponent } from './modules/revenue-reports/revenue-reports.component';
import { LoginComponent } from './modules/login/login.component';
import { AuthGuardService as AuthGuard } from './services/auth-guard.service';
import { MetaGuardService } from './services/meta-guard.service';
import { RedirectGuardService as RedirectGuard } from './services/redirect-guard.service';
import { LocationFreeGuardService as LocationFreeGuard } from './services/location-guard.service';
import { BlankLayoutComponent, DefaultLayoutComponent } from './layouts';
import { UpgradeComponent } from './modules/upgrade/upgrade.component';
import { ReviewReportInsightComponent } from './modules/review-report-insight/review-report-insight.component';
import { RegisterComponent } from './modules/register/register.component';
import { RevenueReportInsightComponent } from './modules/revenue-report-insight/revenue-report-insight.component';
import { ComparisonReportInsightComponent } from './modules/comparison-report-insight/comparison-report-insight.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { DashboardInsightsComponent } from './modules/dashboard-insights/dashboard-insights.component';
import { DashboardPerformanceInsightComponent } from './modules/dashboard-performance-insight/dashboard-performance-insight.component';
import { DashboardReviewsComponent } from './modules/dashboard-reviews/dashboard-reviews.component';
import { DashboardPostsComponent } from './modules/dashboard-posts/dashboard-posts.component';
import { DashboardLocationComponent } from './modules/dashboard-location/dashboard-location.component';
import { DashboardGradeComponent } from './modules/dashboard-grade/dashboard-grade.component';
import { DashboardWidgetComponent } from './modules/dashboard-widget/dashboard-widget.component';
import { DashboardReviewLinkComponent } from './modules/dashboard-review-link/dashboard-review-link.component';
import { DashboardSearchComponent } from './modules/dashboard-search/dashboard-search.component';
import { DashboardWorkshopComponent } from './modules/dashboard-workshop/dashboard-workshop.component';
import { ForgotPasswordComponent } from './modules/forgot-password/forgot-password.component';
import { ReportsComponent } from './modules/reports/reports.component';
import { ReviewAutoResponderComponent } from './modules/review-auto-responder/review-auto-responder.component';
import { BulkPostComponent } from './modules/bulk-post/bulk-post.component';
import { ExternalGradeComponent } from './modules/external-grade/external-grade.component';
import { TextReaderComponent } from './modules/text-reader/text-reader.component';
import { VerifyEmailComponent } from './modules/verify-email/verify-email.component';
import { LocationInfoListComponent } from './modules/location-info-list/location-info-list.component';
import { DashboardServicesComponent } from './modules/dashboard-services/dashboard-services.component';
import { DashboardPhotosComponent } from './modules/dashboard-photos/dashboard-photos.component';
import { DashboardMenuComponent } from './modules/dashboard-menu/dashboard-menu.component';
import { SearchKeywordsComponent } from './modules/search-keywords/search-keywords-report/search-keywords.component';
import { SearchKeywordsViewComponent } from './modules/search-keywords/search-keywords-view/search-keywords-view.component';
import { QuestionsAnswerComponent } from './modules/questions-answer/questions-answer.component';
import { QuestionsAnswersViewComponent } from './modules/questions-answer/questions-answers-view/questions-answers-view.component';
import { ProfilesComponent } from './modules/profiles/profiles.component';
import { BulkActionsGuardService } from './services/bulk-actions-guard.service';
var ɵ0 = { title: 'Login' }, ɵ1 = { title: 'Forgot password' }, ɵ2 = { title: 'Register', robots: 'noindex, nofollow' }, ɵ3 = { title: 'Workshop' }, ɵ4 = {
    title: 'Keywords',
    isReport: false
}, ɵ5 = { title: 'Insights' }, ɵ6 = { title: 'Insights' }, ɵ7 = { title: 'Reviews' }, ɵ8 = { title: 'Q & A' }, ɵ9 = { title: 'Posts' }, ɵ10 = { title: 'Posts' }, ɵ11 = { title: 'Location Info' }, ɵ12 = { title: 'Services' }, ɵ13 = { title: 'Menu' }, ɵ14 = { title: 'Photos' }, ɵ15 = { title: 'Grade' }, ɵ16 = { title: 'Review Widget' }, ɵ17 = { title: 'Review Link Generator' }, ɵ18 = { title: 'Search' }, ɵ19 = {
    title: ''
}, ɵ20 = { title: 'Accounts' }, ɵ21 = { title: 'Report' }, ɵ22 = { title: 'Report' }, ɵ23 = {
    title: 'Keywords',
    isReport: true
}, ɵ24 = { title: 'Locations' }, ɵ25 = { title: 'Profiles' }, ɵ26 = { title: 'Bulk Post Management' }, ɵ27 = { title: 'Bulk Post Management' }, ɵ28 = { title: 'Bulk Location Info' }, ɵ29 = { title: 'Q & A' }, ɵ30 = { title: 'Review Assistant' }, ɵ31 = { title: 'Legacy Rollup Report' }, ɵ32 = { title: 'Performance Rollup Report' }, ɵ33 = { title: 'Review Report' }, ɵ34 = { title: 'Comparison Report' }, ɵ35 = { title: 'Comparison Report' }, ɵ36 = { title: 'Revenue Report' }, ɵ37 = { title: 'Revenue Report' }, ɵ38 = {
    title: 'Keywords',
    isReport: true
}, ɵ39 = { title: 'Grader' };
export var appRoutes = [
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
    },
    {
        path: 'widget/:gid/:accountId/:locationId/review',
        loadChildren: './widget/widget.module#WidgetModule',
    },
    {
        path: 'settings',
        component: DefaultLayoutComponent,
        loadChildren: './settings/settings.module#SettingsModule',
    },
    {
        path: 'verify',
        component: VerifyEmailComponent,
    },
    {
        path: 'text-reader/:text',
        component: TextReaderComponent,
    },
    {
        path: '',
        component: BlankLayoutComponent,
        children: [
            {
                path: 'login',
                data: ɵ0,
                component: LoginComponent
            },
            {
                path: 'forgot-password',
                data: ɵ1,
                component: ForgotPasswordComponent
            },
            {
                path: 'register',
                data: ɵ2,
                canActivate: [MetaGuardService],
                component: RegisterComponent,
            },
        ]
    },
    {
        path: 'account/:accountId/location/:locationId',
        component: DashboardLayoutComponent,
        children: [
            {
                path: 'workshop',
                component: DashboardWorkshopComponent,
                data: ɵ3,
                canActivate: [AuthGuard, LocationFreeGuard],
            },
            {
                path: 'keywords',
                component: SearchKeywordsComponent,
                data: ɵ4,
            },
            {
                path: 'insights',
                component: DashboardPerformanceInsightComponent,
                canActivate: [AuthGuard],
                data: ɵ5
            },
            {
                path: 'legacy-insights',
                component: DashboardInsightsComponent,
                canActivate: [AuthGuard],
                data: ɵ6
            },
            {
                path: 'reviews',
                component: DashboardReviewsComponent,
                canActivate: [AuthGuard],
                data: ɵ7
            },
            {
                path: 'qanda',
                component: QuestionsAnswerComponent,
                data: ɵ8
            },
            {
                path: 'posts',
                component: DashboardPostsComponent,
                canActivate: [AuthGuard, LocationFreeGuard],
                data: ɵ9
            },
            {
                path: 'post-management',
                component: DashboardPostsComponent,
                canActivate: [AuthGuard, LocationFreeGuard],
                data: ɵ10
            },
            {
                path: 'location-info',
                component: DashboardLocationComponent,
                canActivate: [AuthGuard, LocationFreeGuard],
                data: ɵ11
            },
            {
                path: 'services',
                component: DashboardServicesComponent,
                canActivate: [AuthGuard, LocationFreeGuard],
                data: ɵ12
            },
            {
                path: 'menu',
                component: DashboardMenuComponent,
                canActivate: [AuthGuard, LocationFreeGuard],
                data: ɵ13
            },
            {
                path: 'photos',
                component: DashboardPhotosComponent,
                canActivate: [AuthGuard, LocationFreeGuard],
                data: ɵ14
            },
            {
                path: 'grade',
                component: DashboardGradeComponent,
                canActivate: [AuthGuard],
                data: ɵ15
            }, {
                path: 'widget',
                component: DashboardWidgetComponent,
                canActivate: [AuthGuard, LocationFreeGuard],
                data: ɵ16
            },
            {
                path: 'review-link-generator',
                component: DashboardReviewLinkComponent,
                canActivate: [AuthGuard, LocationFreeGuard],
                data: ɵ17
            },
            {
                path: 'search',
                component: DashboardSearchComponent,
                canActivate: [AuthGuard],
                data: ɵ18
            },
        ]
    },
    {
        path: '',
        component: DefaultLayoutComponent,
        data: ɵ19,
        children: [
            {
                path: 'home',
                component: MainComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'accounts',
                component: AccountsComponent,
                data: ɵ20,
                canActivate: [AuthGuard]
            },
            {
                path: 'report/:gid/:id',
                component: ReportsComponent,
                data: ɵ21,
                canActivate: [AuthGuard]
            },
            {
                path: 'report/:gid/:id/shared',
                component: ReportsComponent,
            },
            {
                path: 'report/:gid/:id/:reportType',
                component: ReportsComponent,
                data: ɵ22,
                canActivate: [AuthGuard]
            },
            {
                path: 'reports/:id/keywords',
                component: SearchKeywordsComponent,
                data: ɵ23,
                canActivate: [AuthGuard]
            },
            {
                path: 'accounts/:accountObjectId/locations',
                component: LocationsComponent,
                data: ɵ24,
                canActivate: [AuthGuard]
            },
            {
                path: 'profiles',
                component: ProfilesComponent,
                data: ɵ25,
                canActivate: [AuthGuard]
            },
            {
                path: 'location/:locationId/workshop',
                component: DashboardWorkshopComponent,
                canActivate: [AuthGuard, RedirectGuard, LocationFreeGuard]
            },
            {
                path: 'local-posts',
                component: BulkPostComponent,
                data: ɵ26,
                canActivate: [AuthGuard, BulkActionsGuardService]
            },
            {
                path: 'posts-management',
                component: BulkPostComponent,
                data: ɵ27,
                canActivate: [AuthGuard, BulkActionsGuardService]
            },
            {
                path: 'local-bulk',
                component: LocationInfoListComponent,
                data: ɵ28,
                canActivate: [AuthGuard, BulkActionsGuardService]
            },
            {
                path: 'qanda',
                component: QuestionsAnswersViewComponent,
                data: ɵ29,
                canActivate: [AuthGuard, BulkActionsGuardService]
            },
            {
                path: 'review-assistant',
                component: ReviewAutoResponderComponent,
                data: ɵ30,
                canActivate: [AuthGuard]
            },
            {
                path: 'rollup-report',
                component: RollupReportComponent,
                data: ɵ31,
                canActivate: [AuthGuard]
            },
            {
                path: 'performance-rollup-report',
                component: RollupReportComponent,
                data: ɵ32,
                canActivate: [AuthGuard, BulkActionsGuardService]
            },
            {
                path: 'review-report',
                component: ReviewReportsComponent,
                data: ɵ33,
                canActivate: [AuthGuard]
            },
            {
                path: 'comparison-report',
                component: ComparisonReportsComponent,
                data: ɵ34,
                canActivate: [AuthGuard]
            },
            {
                path: 'performance-comparison-report',
                component: ComparisonReportsComponent,
                data: ɵ35,
                canActivate: [AuthGuard]
            },
            {
                path: 'performance-revenue-report',
                component: RevenueReportsComponent,
                data: ɵ36,
                canActivate: [AuthGuard]
            },
            {
                path: 'revenue-report',
                component: RevenueReportsComponent,
                data: ɵ37,
                canActivate: [AuthGuard]
            },
            {
                path: 'keywords',
                component: SearchKeywordsViewComponent,
                data: ɵ38,
                canActivate: [AuthGuard]
            },
            {
                path: 'upgrade',
                component: UpgradeComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'review-report-insight',
                component: ReviewReportInsightComponent
            },
            {
                path: 'revenue-report-insight',
                component: RevenueReportInsightComponent
            },
            {
                path: 'comparison-report-insight',
                component: ComparisonReportInsightComponent
            },
            {
                path: 'auto-responder',
                component: ReviewAutoResponderComponent
            },
        ]
    },
    {
        path: 'report/:gid/:id/:reportType/shared',
        component: ReportsComponent,
    },
    {
        path: 'report/automated/:gid/:automatedReport/:reportType/shared',
        component: ReportsComponent,
    },
    {
        path: 'reports/:hash/auth',
        component: ReportsComponent,
    },
    {
        path: 'grade',
        component: ExternalGradeComponent,
        data: ɵ39,
    },
    { path: '**', redirectTo: '/login' }
];
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27, ɵ28, ɵ29, ɵ30, ɵ31, ɵ32, ɵ33, ɵ34, ɵ35, ɵ36, ɵ37, ɵ38, ɵ39 };
