import { EventEmitter, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormBuilder, Validators } from '@angular/forms';
import { LocationEditService } from '../../services/location-edit.service';
var ModalPhoneComponent = /** @class */ (function () {
    function ModalPhoneComponent(dialogRef, data, locationEditS, fb) {
        var _this = this;
        var _a, _b;
        this.dialogRef = dialogRef;
        this.data = data;
        this.locationEditS = locationEditS;
        this.fb = fb;
        this.bulk = false;
        this.validResult = new EventEmitter(false);
        this.form = this.fb.group({
            primary: ['', Validators.required],
            additional: this.fb.array([])
        });
        if (data) {
            var primary = data.primaryPhone;
            if (primary) {
                primary = ((_b = (_a = data) === null || _a === void 0 ? void 0 : _a.primaryPhone) === null || _b === void 0 ? void 0 : _b.includes('+')) ? data.primaryPhone : "+1 " + data.primaryPhone;
            }
            this.primary.setValue(primary);
            if (data.additionalPhones === null || data.additionalPhones === undefined) {
                this.additional.setValue([]);
            }
            else {
                data.additionalPhones.forEach(function (item) {
                    var number = item.includes('+') ? item : "+1 " + item;
                    _this.addPhone(number);
                });
            }
        }
    }
    ModalPhoneComponent.prototype.ngOnInit = function () { };
    // add phone
    ModalPhoneComponent.prototype.addPhone = function (phone) {
        this.additional.push(this.fb.group({
            phone: [null],
        }));
        if (phone) {
            var pos = this.additional.controls.length - 1;
            this.additional.controls[pos].get('phone').setValue(phone);
        }
    };
    // remove phone and reindex array
    ModalPhoneComponent.prototype.removePhone = function (index) {
        this.additional.removeAt(index);
    };
    // apply changes
    ModalPhoneComponent.prototype.apply = function () {
        var _this = this;
        var _a;
        if (!this.form.valid) {
            return;
        }
        var primary = (_a = this.primary.value) === null || _a === void 0 ? void 0 : _a.internationalNumber;
        var additional = this.additional.value.map(function (item) { var _a; return (_a = item.phone) === null || _a === void 0 ? void 0 : _a.internationalNumber; });
        this.locationEditS.locationEdit.primaryPhone = primary;
        this.locationEditS.locationEdit.additionalPhones = additional.filter(function (el) { return el != null; });
        this.locationEditS.setAndUpdate().toPromise().then(function () { _this.dialogRef.close(data); });
        var data = { primary: primary, additional: additional };
    };
    ModalPhoneComponent.prototype.getResult = function () {
        var _a;
        if (!this.form.valid) {
            this.validResult.emit(false);
            return;
        }
        this.validResult.emit(true);
        var primary = (_a = this.primary.value) === null || _a === void 0 ? void 0 : _a.internationalNumber;
        var additional = this.additional.value.map(function (item) { var _a; return (_a = item.phone) === null || _a === void 0 ? void 0 : _a.internationalNumber; });
        return { primaryPhone: primary, additionalPhones: additional };
    };
    Object.defineProperty(ModalPhoneComponent.prototype, "primary", {
        get: function () {
            return this.form.get('primary');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalPhoneComponent.prototype, "additional", {
        get: function () {
            return this.form.get('additional');
        },
        enumerable: true,
        configurable: true
    });
    return ModalPhoneComponent;
}());
export { ModalPhoneComponent };
