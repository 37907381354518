import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent {
  message: string;
  type: any;
  panelClass: any = '';

  constructor(
    public snackBarRef: MatSnackBarRef<SnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {
    this.message = this.capitalizeFirstLetter(this.data?.message);
    this.type = this.data.type;
    this.panelClass = this.data.panelClass;
  }

  capitalizeFirstLetter(sentence: string): string {
    sentence = sentence?.toLowerCase();
    return sentence?.replace(/(^\w|\.\s+\w)/gm, (match: string) => {
      return match?.toUpperCase();
    });
  }

}
