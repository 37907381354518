import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Recurring } from '../../../v3.models/posts.models';
import { MatSelectChange } from '@angular/material/select';
import { SnackbarService } from '../../../services/snackbar.service';
import { getNextReccurringDate } from '../../../helpers/functions.helpers';
import * as _moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponent } from '../../../components/confirm.component';
import { ModalConfirmData } from '../../../classes/modal-confirm-data';
import { Subject, Observable, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-recurring',
  templateUrl: './recurring.component.html',
  styleUrls: ['./recurring.component.scss']
})
export class RecurringComponent implements OnInit, OnDestroy {
  @Input() postDateAvaible = false;
  @Input() postTypeName = '';
  @Input() recurring: Recurring = null;
  @Output() recurringChange = new EventEmitter<Recurring>();
  @Input() publishRecurringNow = false;
  @Output() publishRecurringNowChange = new EventEmitter<boolean>();
  @Output() isRecurringValid = new EventEmitter<boolean>();
  formRecurring: FormGroup;
  eventTimeActivate = false;
  deactivateDuration = false;
  previewDate= new Date()
  subscriptions$: Subject<boolean> = new Subject()

  constructor(private snack: SnackbarService, private dialog: MatDialog) {
    this.formRecurring = new FormGroup({
      time: new FormControl('00:01', [Validators.required]),
      frecuency: new FormControl('WeeklyOn', [Validators.required]),
      isAllMonth: new FormControl(false),
      repeatOn: new FormControl('1', [Validators.required, Validators.pattern('^[0-9]*$')]),
      duration: new FormControl(null, [Validators.min(0), Validators.max(7)]),
      eventTimeStart: new FormControl(null),
      eventTimeEnd: new FormControl(null),
    })
  }
  ngOnDestroy(): void {
    this.subscriptions$.next(true);
    this.subscriptions$.unsubscribe()
  }

  ngOnInit(): void {
    this.formRecurring.statusChanges.subscribe((status) => {
      if (status === 'INVALID') {
        this.isRecurringValid.emit(false);
        return;
      }
      this.isRecurringValid.emit(true);
    });
    this.formRecurring.valueChanges.subscribe(() => {
      const date = new Date()
      const nextDate: Date = getNextReccurringDate(date, this.repeatOn.value, this.frecuency.value, this.time.value);
      const recurring: Recurring = {
        time: this.time.value,
        frecuency: this.frecuency.value,
        repeatOn: this.repeatOn.value,
        nextDate,
        duration: this.duration.value,
        eventTimeStart: this.eventStart.value,
        eventTimeEnd: this.eventEnd.value,
        isAllMonth: this.isAllMonth.value
      };
      this.recurringChange.emit(recurring)
    })
    if (this.postDateAvaible) {
      this.duration.setValidators([Validators.required, Validators.min(0), Validators.max(7)])
    }
    if (this.recurring) {
      if (this.recurring.frecuency === 'MonthlyOn') {
        if (this.postDateAvaible) this.duration.setValidators([Validators.required, Validators.min(0), Validators.max(31)])
        if (this.recurring.isAllMonth) this.duration.setValidators([])
      }
      this.deactivateDuration = this.recurring.isAllMonth ? this.recurring.isAllMonth : false;
      if (this.deactivateDuration) {
        this.formRecurring.controls.duration.disable();
      }
      this.eventTimeActivate =  this.recurring.eventTimeEnd ? true : false;
      if (this.recurring.duration !== null && this.recurring.duration > 0) {
        this.eventEnd.setValidators([]);
        this.eventStart.setValidators([]);
      } else if (this.recurring.duration !== null && this.recurring.duration === 0) {
        this.eventTimeActivate = true;
        this.eventStart.setValidators([Validators.required])
        this.eventEnd.setValidators([this.valitadeGreaterTime(), Validators.required])
      }
      this.formRecurring.setValue({
        time: this.recurring.time,
        frecuency: this.recurring.frecuency,
        repeatOn: this.recurring.repeatOn,
        duration: this.recurring.duration !== null ? this.recurring.duration : null,
        isAllMonth: this.deactivateDuration,
        eventTimeStart: this.recurring.eventTimeStart ? this.recurring.eventTimeStart : null,
        eventTimeEnd: this.recurring.eventTimeEnd ? this.recurring.eventTimeEnd : null
      })
    }



    this.isAllMonth.valueChanges.pipe(takeUntil(this.subscriptions$)).subscribe( data => {
      this.deactivateDuration = data;
      if (data) {
        this.formRecurring.controls.duration.disable();
        this.duration.setValue(null)
        this.duration.setValidators([])
        this.eventEnd.setValidators([])
        this.eventStart.setValidators([])
      } else {
        this.formRecurring.controls.duration.enable();
        this.duration.setValidators([Validators.required, Validators.min(1), Validators.max(30)])
      }
      this.duration.updateValueAndValidity()
      this.eventEnd.updateValueAndValidity()
      this.eventStart.updateValueAndValidity()
    })

    this.duration.valueChanges.pipe(takeUntil(this.subscriptions$)).subscribe( data => {
      this.eventStart.updateValueAndValidity()
      this.eventEnd.updateValueAndValidity()
    }) 
    this.repeatOn.valueChanges.pipe(takeUntil(this.subscriptions$)).subscribe( data => {
      this.eventStart.updateValueAndValidity()
      this.eventEnd.updateValueAndValidity()
    }) 


  }

  valitadeGreaterTime(): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {
      const dateClean = new Date();
      const time = this.time.value.split(':')
      if (!control.value) return {minorDate:true}
      if (this.publishRecurringNow) {
        dateClean.setTime(dateClean.getTime() + 30*60000)
      } else {
        dateClean.setHours(time[0], time[1], 0, 0)
      }
      this.previewDate = dateClean;
      const value = control.value.split(":");

      const endDate = new Date();
      endDate.setHours(value[0],value[1],0,0)

      if (dateClean.getTime() >= endDate.getTime()) {
        return {minorDate:true}
      }

      return null
    }
  }

  valitadeGreaterThanTodayTime(): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {
      if(!control.value) return null
      const [controlHours, controlMinutes] = control.value.split(':')

      const now = _moment()
      const nextDateStart = new Date(this.recurring.nextDate.getFullYear(), this.recurring.nextDate.getMonth(), this.recurring.nextDate.getDate(), 0, 0, 0, 0);

      const nextDateEnd = this.frecuency.value === 'WeeklyOn'
        ? new Date(nextDateStart.getFullYear(), nextDateStart.getMonth(), nextDateStart.getDate() + (this.duration.value-1), controlHours, controlMinutes)
        : new Date(nextDateStart.getFullYear(), nextDateStart.getMonth()+1 , nextDateStart.getDate()-1, controlHours, controlMinutes);

      const nextDate = _moment(nextDateEnd)

      const isBefore = nextDate.isBefore(now)
      if (isBefore) return {minorDate:true}

      return null
    }
  }

  frecuencyControl(event: MatSelectChange): void {
    if (event.value === 'MonthlyOn') {
      this.duration.setValidators([Validators.required, Validators.min(1), Validators.max(30)])
      this.repeatOn.setValidators([Validators.required, Validators.min(1), Validators.max(29)])
      this.repeatOn.setValue("1")
    } else {
      this.duration.setValidators([Validators.required, Validators.min(0), Validators.max(7)])
      this.repeatOn.setValidators([Validators.required, Validators.pattern("^[0-9]*$")])
      this.repeatOn.setValue("1")
    }

    this.frecuency.setValue(event.value)
  }

  get frecuency(): AbstractControl {
    return this.formRecurring.get('frecuency')
  }

  get repeatOn(): AbstractControl {
    return this.formRecurring.get('repeatOn')
  }

  get time(): AbstractControl {
    return this.formRecurring.get('time')
  }

  get isAllMonth(): AbstractControl {
    return this.formRecurring.get('isAllMonth')
  }

  get duration(): AbstractControl {
    return this.formRecurring.get('duration')
  }

  get eventEnd(): AbstractControl {
    return this.formRecurring.get('eventTimeEnd')
  }

  get eventStart(): AbstractControl {
    return this.formRecurring.get('eventTimeStart')
  }
  
  callBackRecurring(result: boolean, nextDate: Date): {data: Recurring, publishNow: boolean, duplicate: boolean} {
    if (!result) {
      this.publishRecurringNow = false
      return
    }

    const recurring: Recurring = {
      frecuency: this.frecuency.value,
      repeatOn: this.repeatOn.value,
      time: this.time.value,
      nextDate: nextDate,
      duration: this.duration.value,
      eventTimeStart: this.eventStart.value,
      eventTimeEnd: this.eventEnd.value,
      isAllMonth: this.isAllMonth.value
    };
    return {data: recurring, publishNow: this.publishRecurringNow, duplicate: result}
  }

  apply(): Observable<{data: Recurring, publishNow: boolean, duplicate: boolean}> {
    if (this.formRecurring.invalid) {
      this.snack.openInfo('The form has errors', 3500)
      return of(null)
    }

    if (this.frecuency.value === 'MonthlyOn' && this.repeatOn.value === '0') {
      this.snack.openInfo('The day of the month cannot be 0', 3500)
      return of(null)
    }

    const date = new Date()
    const nextDate: Date = getNextReccurringDate(date, this.repeatOn.value, this.frecuency.value, this.time.value)
    const utcNow = _moment.utc(date).toDate()
    const nextDateString =  `${nextDate.getUTCFullYear()}/${nextDate.getUTCMonth()}/${nextDate.getUTCDate()}`
    const nowDateString =  `${utcNow.getUTCFullYear()}/${utcNow.getUTCMonth()}/${utcNow.getUTCDate()}`
    
    if (this.publishRecurringNow && nextDateString === nowDateString) {
      const dialogRef = this.dialog.open(ConfirmComponent, {
        width: '680px',
        data: new ModalConfirmData({
          title: 'Heads Up',
          content: "The combination you have chosen will potentially create a duplicate post. Are you sure?",
          confirmButtonLabel: 'Yes',
          closeButtonLabel: 'No',
          alertType: null,
          modalPayment: false,
          description:  null,
          typePaid: false
        })
      });
      return dialogRef.afterClosed().map(result =>  this.callBackRecurring(result,nextDate));
    } else {
      const recurring: Recurring = {
        frecuency: this.frecuency.value,
        repeatOn: this.repeatOn.value,
        time: this.time.value,
        nextDate: nextDate,
        duration: this.duration.value,
        eventTimeEnd: this.eventEnd.value,
        isAllMonth: this.isAllMonth.value
      };
      return of({data: recurring, publishNow: this.publishRecurringNow, duplicate: false})
    }



  }

  changePublishNow(checked: boolean): void {
    this.publishRecurringNow = checked;
    this.publishRecurringNowChange.emit(checked);
    /* if (this.publishRecurringNow && this.postDateAvaible) {
      this.eventTimeActivate = true;
      this.eventStart.setValidators([Validators.required])
      this.eventStart.updateValueAndValidity()
      this.eventEnd.setValidators([this.valitadeGreaterTime(), Validators.required])
      this.eventEnd.updateValueAndValidity()
    } */
  }

  deactivateTime(checked: boolean): void {
    this.eventTimeActivate = checked;

    if (checked) {
      this.eventStart.setValue('00:01');
      this.eventEnd.setValue('23:59');
      this.eventStart.setValidators([Validators.required]);
      this.eventEnd.setValidators([this.valitadeGreaterThanTodayTime(), Validators.required]);
      return;
    }

    this.eventStart.setValidators(null);
    this.eventEnd.setValidators(null);
    this.eventStart.setValue(null);
    this.eventEnd.setValue(null);
  }

  handleFrecuency(event:{day:string, duration:number}){
    this.repeatOn.setValue(event.day);
    this.duration.setValue(event.duration);
  }
}
