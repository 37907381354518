import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import * as _moment from 'moment';
import { RecurringComponent } from './recurring/recurring.component';
var ModalSchedulePostComponent = /** @class */ (function () {
    function ModalSchedulePostComponent(fb, dialogRef, config) {
        this.fb = fb;
        this.dialogRef = dialogRef;
        this.config = config;
        // RECURRING
        this.recurringPost = false;
        this.recurringTime = '12:00';
        this.frecuency = "WeeklyOn";
        this.repeatOn = "0";
        this.durationEvent = 0;
        this.recurringModel = null;
        this.isDeletePost = false;
        this.title = '';
        this.publishRecurringNow = false;
        // LIST POST TYPE
        this.listPostType = {
            0: "Standard",
            1: "Event",
            2: "Offer",
            3: "Alert",
        };
        this.postDateAvaible = false;
        this.postTypeName = null;
    }
    ModalSchedulePostComponent.prototype.ngOnInit = function () {
        this.schedule = this.config.data.schedule ? this.config.data.schedule : { 'date': new Date().toISOString() };
        if (this.config.data.postType) {
            this.postTypeName = this.listPostType[this.config.data.postType];
            this.postDateAvaible = this.postTypeName === 'Event' || this.postTypeName === 'Offer' ? true : false;
        }
        if (this.config.data.recurring) {
            this.recurringPost = true;
            this.recurringTime = this.config.data.recurring.time;
            this.frecuency = this.config.data.recurring.frecuency;
            this.repeatOn = this.config.data.recurring.repeatOn;
            this.recurringModel = this.config.data.recurring;
            this.publishRecurringNow = this.config.data.publishNow;
        }
        this.isDeletePost = this.config.data.isDeletePost ? this.config.data.isDeletePost : false;
        this.title = this.config.data.title ? this.config.data.title : 'Schedule';
        this.submitted = false;
        this.initForm();
    };
    ModalSchedulePostComponent.prototype.initForm = function () {
        var _this = this;
        var _a, _b;
        var parseZone = _moment(this.schedule.date).parseZone();
        var time = _moment(parseZone['_d'].getTime()).format('HH:mm');
        this.form = this.fb.group({
            date: [((_a = this.schedule) === null || _a === void 0 ? void 0 : _a.date) ? parseZone.toDate() : new Date(), Validators.required],
            time: [((_b = this.schedule) === null || _b === void 0 ? void 0 : _b.date) ? time : '12:00', Validators.required],
        });
        this.form.valueChanges.subscribe(function (values) {
            _this.checkChanges();
        });
    };
    ModalSchedulePostComponent.prototype.checkChanges = function () {
        var date = new Date(this.date.value);
        if (this.time.value) {
            var split = this.time.value.split(':');
            date.setHours(split[0], split[1]);
        }
        this.lessToNow = date < new Date(Date.now());
    };
    Object.defineProperty(ModalSchedulePostComponent.prototype, "date", {
        get: function () {
            return this.form.get('date');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalSchedulePostComponent.prototype, "time", {
        get: function () {
            return this.form.get('time');
        },
        enumerable: true,
        configurable: true
    });
    ModalSchedulePostComponent.prototype.frecuencyControl = function (event) {
        event.value === 'MonthlyOn' ? this.repeatOn = "1" : this.repeatOn = "0";
        this.frecuency = event.value;
    };
    ModalSchedulePostComponent.prototype.recurringTimeControl = function (event) {
        this.recurringTime = event.target.value;
    };
    ModalSchedulePostComponent.prototype.recurringDurationControl = function (event) {
        this.recurringTime = event.target.value;
    };
    ModalSchedulePostComponent.prototype.apply = function () {
        this.outSchedule();
    };
    ModalSchedulePostComponent.prototype.changeRecurring = function ($event) {
        this.recurringPost = $event.checked;
    };
    ModalSchedulePostComponent.prototype.callBackRecurring = function (result, nextDate) {
        if (!result) {
            this.publishRecurringNow = false;
            return;
        }
        var recurring = {
            frecuency: this.frecuency,
            repeatOn: this.repeatOn,
            time: this.recurringTime,
            nextDate: nextDate
        };
        this.dialogRef.close({ recurring: true, data: recurring, publishNow: this.publishRecurringNow });
    };
    ModalSchedulePostComponent.prototype.outSchedule = function () {
        var _this = this;
        if (!this.recurringPost) {
            this.checkChanges();
            if (this.form.invalid || this.lessToNow) {
                return;
            }
            var schedule = this.form.value;
            var date = new Date(this.form.value.date);
            var splitTime = this.time.value.split(':');
            var hours = splitTime[0];
            var minutes = splitTime[1];
            date.setHours(hours, minutes);
            var utcDate = _moment.utc(date).toDate();
            schedule.date = utcDate;
            this.dialogRef.close({ recurring: false, data: schedule });
        }
        else {
            this.formRecurring.apply().subscribe(function (data) {
                var response = data;
                if (response)
                    _this.dialogRef.close({ recurring: true, data: response.data, publishNow: response.publishNow, duplicate: response.duplicate });
            });
        }
    };
    ModalSchedulePostComponent.prototype.cancelDelete = function () {
        this.dialogRef.close({ cancel: true });
    };
    return ModalSchedulePostComponent;
}());
export { ModalSchedulePostComponent };
