import { OnInit } from '@angular/core';
var TopMetricCarrouselItemComponent = /** @class */ (function () {
    function TopMetricCarrouselItemComponent() {
    }
    TopMetricCarrouselItemComponent.prototype.ngOnInit = function () {
        var _a, _b;
        this.name = (_a = this.metrics) === null || _a === void 0 ? void 0 : _a.key;
        this.arrayMetric = (_b = this.metrics) === null || _b === void 0 ? void 0 : _b.value;
    };
    return TopMetricCarrouselItemComponent;
}());
export { TopMetricCarrouselItemComponent };
