<ng-container>

  <table class="table table--bordered">
    <thead>
    <tr class="mat-row">
      <th class="mat-header-cell txt--left"><strong>Location</strong></th>
      <th class="mat-header-cell txt--center"><strong>Star Rating</strong></th>
      <th class="mat-header-cell txt--center"><strong>Total Reviews</strong></th>
      <th class="mat-header-cell txt--center"><strong>Responded</strong></th>
      <th class="mat-header-cell txt--center"><strong>Unresponded</strong></th>
      <th class="mat-header-cell txt--center"><strong>Review Link</strong></th>
    </tr>
    </thead>
    <tbody>
    <tr class="mat-row no-hover border--full" *ngFor="let i of [0,1,2]">

      <td class="mat-cell cdk-column-location" style="width: 20%;">
        <div class="cell__content flex-column align-items-start">
          <ngx-skeleton-loader count="1" [theme]="{ width: '80px', height: '20px', 'border-radius': '4px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" [theme]="{ width: '140px', height: '20px', 'border-radius': '4px', 'display':'block', 'margin-top': '5px' }"></ngx-skeleton-loader>

          <ngx-skeleton-loader style="display: flex;" count="3" [theme]="{ display: 'block', width: '30px', height: '30px', 'border-radius': '4px', 'margin-top': '10px', 'margin-right': '5px', 'margin-bottom': '0' }"></ngx-skeleton-loader>
        </div>
      </td>
      <td class="mat-cell cdk-column-rating txt--center" style="width: 20%;">
        <div class="d-flex flex-column align-items-center">
          <ngx-skeleton-loader count="1" [theme]="{ width: '20px', height: '20px', 'border-radius': '4px', 'display':'block' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px', 'border-radius': '4px', 'display':'block', 'margin-top': '10px' }"></ngx-skeleton-loader>
        </div>
      </td>

      <td class="mat-cell cdk-column-date--resp txt--center" style="width: 15%;">
        <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px'}"></ngx-skeleton-loader>
      </td>

      <td class="mat-cell cdk-column-date--resp txt--center" style="width: 15%;">
        <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px'}"></ngx-skeleton-loader>
      </td>

      <td class="mat-cell cdk-column-date--resp txt--center" style="width: 15%;">
        <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px'}"></ngx-skeleton-loader>
      </td>

      <td class="mat-cell cdk-column-actions column--sm txt--center" style="width: 15%;">
        <div class="cell__content justify-content-center">
          <ngx-skeleton-loader count="1" [theme]="{ width: '140px', height: '30px' }"></ngx-skeleton-loader>
        </div>
      </td>
    </tr>
    </tbody>
  </table>

</ng-container>

