
<app-modal-stepper 
  [step]="step" 
  [title]="titleModal" 
  (completed)="completed($event)" 
  (step_activate)="current_step($event)" 
  [finalAction]="'Save'" 
  [scan_action]="scan_action" 
  linear
  [isEdit]="isEdit"
  [isScan]="isScan"
  (onStepChange)="onStepChange($event)"
  [disabledStep]="disabledNextStep"
>

  <cdk-step [completed]="complete1">
    <app-form-create-protocol (isValid)="createIsValid($event)" [protocolEdit]="protocolEdit"></app-form-create-protocol>
  </cdk-step>

  <cdk-step [completed]="complete2">

    <app-toggle-locations-in-account 
      class="locations-no-padding" [class.width-section]="isEdit" 
      (selected)="handleSelect($event)" 
      [reportEdit]="locationsSelected"></app-toggle-locations-in-account>
  </cdk-step>

  <cdk-step [completed]="complete3">
    <div *ngIf="progress | async" class="d-flex flex-row justify-content-center  mt--20 mb--20 h--150 width-section">
      <gmb-spinner></gmb-spinner>
    </div>

    <app-resume-create-protocol #resume
      *ngIf="!(progress | async)"
      class="width-section mt--20"
      [protocol]="parcial_protocol"
      (isLoading)="loadingStep3($event)"
    ></app-resume-create-protocol>
  </cdk-step>
  
  <cdk-step [completed]="complete4">
    <div *ngIf="progress | async" class="d-flex flex-row justify-content-center mt--20 mb--20 h--150 width-section">
      <gmb-spinner></gmb-spinner>
    </div>

    <div class="dialog__content dialog__content--height" *ngIf="!(progress | async)" mat-dialog-content>
      <div class="row">
        <div class="col-12">
          <mat-label class="d-block mb--10">Placeholders</mat-label>
          <!--<mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
            <input matInput placeholder="%keyword_1%, %keyword_2%, etc" [value]="placeholders" disabled class="input-group__field"/>
          </mat-form-field>-->
          <div class="d-block mb--20">
            <button mat-flat-button color="primary" (click)="AddPlaceHolder(placeholder)" class="btn btn--action mr--10" *ngFor="let placeholder of placeholders_array">{{placeholder}}</button>
          </div>
        </div>

        <div class="col-12">
          <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
            <textarea matInput required placeholder="Enter your description here" rows="5" [formControl]="defaultResponse" (focusout)="index_focus_out = 0" class="input-group__field"></textarea>
            <mat-error *ngIf="defaultResponse?.errors">
              <p *ngIf="defaultResponse.errors['required']">At least one response is required</p>
              <p *ngIf="defaultResponse.errors['minlength']">Min 10 characters</p>
            </mat-error>
          </mat-form-field>

          <ng-container *ngIf="responsesAdditional?.length">
            <div *ngFor="let form of responsesAdditional?.controls; index as i" class="d-flex align-items-start">
              <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
                <textarea matInput placeholder="Enter your description here" rows="5" [formControl]="form" (focusout)="index_focus_out = i+1" class="input-group__field"></textarea>
              </mat-form-field>
              <span class="alternate-theme">
              <button mat-flat-button color="primary" (click)="removeResponse(i)" class="btn btn-icon btn--rounded ml--10">-</button>
            </span>
            </div>
          </ng-container>
        </div>
        <div class="col-12 mb--20">
          <app-button color="primary" [alternative]="true" (click)="addResponse()">+ Add new Response</app-button>
        </div>
      </div>
    </div>
  </cdk-step>

</app-modal-stepper>
