<!--Regular Hours-->
<div class="box__item box__item--editable" [class.align-items-start]="periods">
  <div class="d-flex w--100" [ngClass]="periods ? 'align-items-start' : 'align-items-center'">
    <div class="icon icon--rounded icon--gray mr--20"><i class="far fa-clock"></i></div>
    <div class="w--100" [class.txt--medium-gray]="isUnderReview">
      <h4 class="w--100"><strong>Regular Hours</strong></h4>
      <p class="w--100" *ngIf="regularHours?.periods?.length == 0">Add Regular Hours</p>
      <div *ngIf="periods" class="w--100 row" [class.mb--5]="isUnderReview">
        <ng-container *ngFor="let day of periodsByDay | keyvalue: originalOrder">
          <ng-container *ngFor="let hours of day?.value; let i = index;">
            <div class="col-4">
              <p *ngIf="i === 0" class="txt--uppercase"><strong>{{ day.key }}</strong></p>
            </div>
            <div class="col-8">
              <p class="txt--uppercase" *ngIf="hours.open else close">
                <span><strong>{{ hours?.openTime}} - {{ hours?.closeTime}}</strong></span>
                <ng-template #allDay>
                  <strong>{{ hours?.startTime }}</strong>
                </ng-template>
              </p>
              <ng-template #close>
                <p>Closed</p>
              </ng-template>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <span class="under-review" *ngIf="isUnderReview" role="alert">Under review</span>
    </div>
  </div>
  <button mat-button (click)="openRegularHoursDialog()" class="btn btn-icon btn-icon--gray" *ngIf="isEdit"><em class="fas fa-pencil"></em></button>
</div>
