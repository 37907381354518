import { Component, OnInit, ViewEncapsulation, OnDestroy, Input } from '@angular/core';
import { ReviewsService } from '../../services/reviews.service';
import { MatTableDataSource } from '@angular/material/table';
import { ProtocolService } from '../../services/protocol.service';
import { SnackbarService } from '../../services/snackbar.service';
import { Pageable } from '../../constants/pageable';
import { ModalService } from '../../services/modal.service';
import { ModalReplyReviewComponent } from '../reply-edit/reply-edit.component';
import { Pagination } from '../../constants/api-response';
import { map, take } from 'rxjs/operators';
import { Subject, of, Observable } from 'rxjs';
import { AutoReplyReviewErrorComponent } from '../../components/auto-reply-review-error.component';
import { FormControl } from '@angular/forms';
import { LoadingService } from '../../services/loading.service';
import { LocationProtocolLog } from 'src/app/constants/protocol-log';

@Component({
  selector: 'app-protocol-log',
  templateUrl: './protocol-log.component.html',
  styleUrls: ['./protocol-log.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProtocolLogComponent implements OnInit, OnDestroy {
  @Input() locations: { accountId: string; locationId: string }[] = [];
  @Input() shared: boolean = false;
  paginate: Pageable = {page: 1, size: 10};
  previousPageable: { size: any; page: any };
  pagination: Pagination;
  columns: string[] = ['date', 'name', 'message', 'status', 'action-btn'];
  showMoreMessageIndex: number;
  showMoreReplyIndex: number;
  progress = true;
  selectedStatus: boolean = null;
  selectedView: boolean = null;
  searchText = '';
  errorMessage = false;
  showMoreReply = false;
  showMoreMessage = false;
  filter = { status: null, view: null, location: [], accounts: []};
  dataSource = new MatTableDataSource<any>([]);
  dataSourceNew = new MatTableDataSource<any>([]);
  view: any;
  predicate: (data: any, filter: string) => boolean;
  reload: boolean;
  resultLocationName: Observable<LocationProtocolLog[]> = of([]);
  copyResultLocationName: Observable<LocationProtocolLog[]> = of([]);
  selectedSearch = new FormControl();
  locationName: string = '';
  destoySubs$: Subject<boolean> = new Subject();
  isClear = true;

  constructor(
    private reviewS: ReviewsService,
    private protocolS: ProtocolService,
    private snackS: SnackbarService,
    private modalService: ModalService,
    private loadingService: LoadingService
  ) {  }

  get isFiltered(): boolean {
    return this.filter?.accounts?.length > 0 && this.filter?.location?.length > 0 && this.filter?.status && this.filter?.view;
  }

  ngOnInit() {
    this.progress = true;
    if (this.shared) {
      this.columns  = ['date', 'name', 'message', 'status'];
    }
    if (this.locations?.length > 0) {
      this.locations.forEach( l => {
        this.filter.location.push(l.locationId);
        this.filter.accounts.push(l.accountId);
      });
      this.getData(this.filter);
    } else {
      this.getData(this.filter);
      this.resultLocationName = this.copyResultLocationName = this.protocolS.getLocationNamesProtocolLog();
    }
    this.predicate = this.dataSourceNew.filterPredicate = (data: any, filter: string) => {
      return data.viewed.toString() === filter;
    };

  }

  getData(filter: { status: boolean, view: boolean, location: string[], accounts: string[]} = null) {
    this.progress = true;
    this.protocolS.getProtocolLog(this.paginate, filter).pipe(take(1)).subscribe(result => {

      this.previousPageable = {size: result.per_page, page: result.page};
      this.pagination = result;
      this.dataSource.data = result.items as any[];
      this.progress = false;
      this.isClear = false;
      this.loadingService.reportAdvanced(1, "Logs")
    });
  }

  data = (pageable: Pageable = undefined) => {
    return this.protocolS.getProtocolLog(pageable);
  };


  changeViewed(element: any) {
    if (!element.reviewId) {
      return;
    }
    if (!element?.viewed) {
      element.viewed = false;
    }
    this.reviewS.changeViewed(element.reviewId, !element.viewed).pipe(take(1)).subscribe(result => {
      element.viewed = result.viewed;
      this.getData(this.filter)
      this.handleView(this.view);
    }, error => {
      this.snackS.openError('error to change viewed', 4000);
    });
  }

  handleView($event: any) {
    this.view = $event;
    switch ($event) {
      case 'read':
        this.dataSourceNew.filter = 'true';
        break;
      case 'unread':
        this.dataSourceNew.filter = 'false';
        break;
      default:
        this.dataSourceNew.filter = undefined;
        break;
    }
  }

  handleChangeSearch(event: string) {
    this.resultLocationName = this.copyResultLocationName;
    if (event !== '' && event !== null && event[event?.length-1] !== ' ') {
      const textSearch = event.trim().toLowerCase();
      this.resultLocationName = this.resultLocationName.pipe(map(al => al.filter( l => l.locationName.trim().toLowerCase().indexOf(textSearch) > -1 ||
            l.locationAddress.locality.trim().toLowerCase().indexOf(textSearch) > -1 ||
            l.locationAddress.administrativeArea.trim().toLowerCase().indexOf(textSearch) > -1 ||
            l.locationAddress.postalCode.trim().toLowerCase().indexOf(textSearch) > -1 ||
            l.locationAddress.regionCode.trim().toLowerCase().indexOf(textSearch) > -1 ||
            l.locationAddress.addressLines.some( add => add.trim().toLowerCase().indexOf(textSearch) > -1))
      ))
    }
  }

  selected() {
    const value = this.selectedSearch.value;
    this.selectedSearch.setValue(value.locationName);
    this.locationName = value.locationName;
    this.filter.location.push(value.locationId);
    this.filter.accounts.push(value.accountId);
    this.paginate = {page: 1, size: 10};
    this.getData(this.filter)
  }

  toggleMessage(i): void {
    this.showMoreMessage = !this.showMoreMessage;
    this.showMoreMessageIndex = i;
  }

  toggleReply(i): void {
    this.showMoreReply = !this.showMoreReply;
    this.showMoreReplyIndex = i;
  }

  edit(element: any) {
    // todo use real messages
    this.modalService.openGenericModal(ModalReplyReviewComponent, element, result => {
      this.changeViewed(element)
    });
  }

  delete(element: any) : void {
    this.modalService.openConfirmModal('Delete Reply', '', () => {
      const accountId = element.name.split('/')[1];
      const locationId = element.name.split('/')[3];
      this.reviewS.replyDelete(accountId, locationId, element.reviewId)
        .pipe(take(1))
        .subscribe(() => {
          this.getData(this.filter);
        });
    });
  }

  handleReload($event: Pageable) {
    this.paginate = $event;
    this.getData(this.filter);
  }

  openError(element) {
    if (element?.error && !this.shared) {
      const dialog = this.modalService.openGenericModal(AutoReplyReviewErrorComponent, {...element}, () => {});
    }
  }

  ngOnDestroy() {
    this.destoySubs$.next(true);
    this.destoySubs$.complete();
  }

  locationUrl(log, destiny) {
    const url  = log.name.split('/');
    const accountId = url[1];
    const locationId = url[3];
    return `/account/${accountId}/location/${locationId}/${destiny}`;
  }

  applyFilterStatus($event) {
    this.filter.status = $event.value;
    this.getData(this.filter);
  }

  clearFilters() {
    this.isClear = true;
    this.selectedStatus = null;
    this.filter.status = null;
    if (this.locations?.length == 0) {
      this.filter.location = [];
      this.filter.accounts = [];
      this.resultLocationName = this.copyResultLocationName;
    }
    this.locationName = '';
    this.getData(this.filter);
  }

  applyFilterView($event) {
    this.filter.view = $event.value;
    this.getData(this.filter);
  }

   /**
   * this method handle change text search keyword comment or reviewer
   * @param searchText
   */
  handleSearchText(searchText: string) {
    this.searchText = searchText;
    //this.getData();
  }
}
