import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {BehaviorSubject} from 'rxjs';
import {GoogleService} from './google.service';
import User from '../constants/firestore/user';
import GoogleLocation from '../constants/firestore/google-location';
import { EXTERNAL_GRADE_ID } from '../constants/session';
import { ExternalGradeService } from './external-grade.service';

@Injectable({
  providedIn: 'root'
})

// This refers to Google GMB Locations.
export class GoogleLocationService {
  private dataStore: {
    locations: GoogleLocation[]
  };
  private _locations: BehaviorSubject<GoogleLocation[]>;


  constructor(
    private auth: AuthService,
    private google: GoogleService,
    private externalGradeService: ExternalGradeService,
  ) {

    this.dataStore = {locations: []};
    this._locations = <BehaviorSubject<GoogleLocation[]>> new BehaviorSubject([]);
  }

  get locations() {
    return this._locations.asObservable();
  }


  loadAll(user: User, accountId: string) {
    const gmbToken = this.auth.getGmbToken().subscribe(token => {
      this.google.getLocations(token, accountId).subscribe(data => {
        const locations = 'locations';
        this.dataStore = data;
        this.dataStore[locations].sort((a, b) => (a.locationName > b.locationName) ? 1 : -1);
        this._locations.next(Object.assign({}, this.dataStore).locations);
        gmbToken.unsubscribe();
      });
    });
  }



  loadAllGrade(user: User, accountId: string) {
    const externalGradeId = localStorage.getItem(EXTERNAL_GRADE_ID);
    const sub = this.externalGradeService.getExternalGradeToken(user.gid, externalGradeId).subscribe(token => {
      this.google.getLocations(token, accountId).subscribe(data => {
        sub.unsubscribe();
        const locations = 'locations';
        this.dataStore = data;
        this.dataStore[locations].sort((a, b) => (a.locationName > b.locationName) ? 1 : -1);
        this._locations.next(Object.assign({}, this.dataStore).locations);
      });
    });
  }

  reset(): void {
    this._locations = new BehaviorSubject<GoogleLocation[]>([]);
  }
}
