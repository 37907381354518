// dep
import { Injectable } from '@angular/core'
import { CanActivate } from '@angular/router'
import { Observable } from 'rxjs'

// app
import { ISubscription } from '../constants/subscription';
import {AuthService} from "./auth.service";
import { ModalTrialEnded } from '../modules/modal-trial-ended/modal-trial-ended.component'
import { ModalService } from './modal.service'

const BLOCK_REASON_MESSAGES : {[reason in ISubscription['statusReason']] : 
 { title : string, messageV2 : string, messageV3: string}} = {
  'TRIAL_EXPIRED'   : { title     : 'Your Free Subscription Has Ended',
                        messageV2 : ('Schedule a call with us to upgrade your account and make the best local search '+
                                     'investment for your business; 100 TIMES MORE users than other local search channels!'),
                        messageV3 : ('Reach out to our sales team to upgrade your account and make the best local search '+
                                     'investment for your business; 100 TIMES MORE users than other local search channels!')},

  'UNPAID_TOO_LONG' : { title     : 'Your Subscription is Unpaid',
                        messageV2 : 'Schedule a call with us to assist with your payments to continue using Map Labs.',
                        messageV3 : 'Reach out to our sales team to assist with your payments and continue using Map Labs.' },
                          
  'MANUAL_BLOCK'    : { title     : 'Your Subscription has been Blocked ',
                        messageV2 : 'Schedule a call with us to unblock your subscription and continue using Map Labs.',
                        messageV3 : 'Reach out to our sales team to unblock your subscription and continue using Map Labs.'}
} as const;

// Only used on transition, TODO: Remove after MAP-2504 backend is deployed
const BLOCK_REASON_MESSAGES_DEFAULT = 'MANUAL_BLOCK';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  subscription$: Observable<ISubscription>;
  subscription: ISubscription;

  private _modalTrialEndedOpened = false;
  
  constructor(
    private _auth: AuthService, 
    private _modalService: ModalService,
  ) {
    this.subscription$ = this._auth.subscription$
    this.subscription$.subscribe(
      subscription => {
        this.subscription = subscription
        if (subscription?.status === 'BLOCKED') {
          let message
          let buttons
          switch (this.subscription?.pricingVersion) {
            case 1:
            case 2: {
              message = this._blockReason().messageV2,
              buttons = {
                'accept_icon': 'far fa-calendar',
                'accept_text': 'Schedule Call',
                'accept_cta': 'https://calendly.com/maplabs/discovery-call',
                'cancel_text': 'Logout',
              }
              this._openModal(buttons, message)
              break
            }
            case 3: {
              message = this._blockReason().messageV3,
              buttons = {
                'accept_icon': 'fas fa-paper-plane',
                'accept_text': 'Contact Sales',
                'accept_cta': 'mailto:sales@maplabs.com',
                'cancel_text': 'Logout',
              }
              this._openModal(buttons, message)
              break
            }
          }
        }
      }
    )
  }

  private _blockReason(): typeof BLOCK_REASON_MESSAGES['MANUAL_BLOCK'] {
    return BLOCK_REASON_MESSAGES[this.subscription?.statusReason || BLOCK_REASON_MESSAGES_DEFAULT];
  }

  private _openModal(buttons: any, message: string): void {
    if (!this._modalTrialEndedOpened) {
      this._modalService.openModal(ModalTrialEnded,
        {
          title: this._blockReason().title,
          message: message,
          buttons: buttons
        }, 
        { 
          config: { 
            width: '750px',
            disableClose: true 
          }
        }
      )
      this._modalTrialEndedOpened = true
    }
  }

  canActivate(): boolean {
    if (this.subscription?.status === "BLOCKED") {
      let message
      let buttons
      switch (this.subscription?.pricingVersion) {
        case 1:
        case 2: {
          message = this._blockReason().messageV2,
          buttons = {
            'accept_icon': 'far fa-calendar',
            'accept_text': 'Schedule Call',
            'accept_cta': 'https://calendly.com/maplabs/discovery-call',
            'cancel_text': 'Logout',
          }
          this._openModal(buttons, message)
          break
        }
        case 3: {
          message = this._blockReason().messageV3,
          buttons = {
            'accept_icon': 'fas fa-paper-plane',
            'accept_text': 'Contact Sales',
            'accept_cta': 'mailto:sales@maplabs.com',
            'cancel_text': 'Logout',
          }
          this._openModal(buttons, message)
          break
        }
      }
     }
    if ((!this._auth.authenticated && !this._auth.session)) return false
    return true
  }
}
