import { __assign } from "tslib";
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environment';
import { map, catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var LogsService = /** @class */ (function () {
    function LogsService(http) {
        this.http = http;
    }
    /**
     * this method save a new log
     * @param log is the object to save new log
     */
    LogsService.prototype.saveLog = function (log) {
        return this.http.post(environment.apiUrl + "/v2/logs/save", log)
            .pipe(map(function (value) { return value.data; }), catchError(function (e) {
            return null;
        })).toPromise();
    };
    /**
    * this method get log
    * @param log is the object to save new log
    */
    LogsService.prototype.getLogs = function (pageable, filter) {
        var params = new HttpParams()
            .set('page', pageable.page.toString())
            .set('size', pageable.size.toString());
        return this.http.post(environment.apiUrl + "/v2/logs", __assign({}, filter), { params: params })
            .pipe(map(function (value) { return value.data; }));
    };
    LogsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LogsService_Factory() { return new LogsService(i0.ɵɵinject(i1.HttpClient)); }, token: LogsService, providedIn: "root" });
    return LogsService;
}());
export { LogsService };
