import { Component, Input, OnChanges, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ModalSpecialHoursComponent } from "../../modal-special-hours/modal-special-hours.component";
import { MatDialog } from "@angular/material/dialog";
import { DatesService } from "src/app/services/dates.service";
import { LocationService } from "../../../services/location.service";
import * as moment from 'moment';

@Component({
  selector: 'app-location-special-hours',
  templateUrl: './location-special-hours.component.html',
  styleUrls: ['./location-special-hours.component.scss']
})
export class LocationSpecialHoursComponent implements OnInit, OnChanges {
  @Input() specialHours: any;
  @Input() isEdit = true;
  @Input() isUnderReview = false;
  @Input() countRegularHours = 0;
  @Output() onUpdate: EventEmitter<boolean> = new EventEmitter();


  specialHourPeriods: {};

  constructor(
    private dialog: MatDialog,
    private datesS: DatesService,
    private locationS: LocationService
  ) {
  }

  ngOnInit() {
    if (this.specialHours) {
      let specialHourPeriods = JSON.parse(JSON.stringify(this.specialHours.specialHourPeriods));
      
      specialHourPeriods.forEach(el => {
        if (!el.isClosed) {
          el.openTime = this.datesS.getStringHours(el.openTime);
          el.closeTime = this.datesS.getStringHours(el.closeTime);
        }
      })
      this.specialHourPeriods = this.locationS.joinByDay(specialHourPeriods);
      this.specialHourPeriods = this.sortPeriods();
    } else {
      this.specialHourPeriods = {};
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.specialHours.firstChange) {
      this.ngOnInit();
    }
  }

  sortPeriods() {
    const keys = Object.keys(this.specialHourPeriods);
    const periods = {};

    keys.sort((a,b) => {
      const aSplit = a.split('/');
      const dateA = `${aSplit[1]}/${aSplit[0]}/${aSplit[2]}`;
      
      const bSplit = b.split('/');
      const dateB = `${bSplit[1]}/${bSplit[0]}/${bSplit[2]}`;

      return new Date(dateB) < new Date(dateA) ? 1 : -1;
    })

    keys.forEach(p => periods[p] = this.specialHourPeriods[p] )

    return periods;
  }

  openSpecialHoursDialog() {
    const dialogRef = this.dialog.open(ModalSpecialHoursComponent, {
      width: '810px',
      data: {
        specialDays: this.specialHourPeriods,
        previousDay: this.specialHours?.specialHourPeriods || []
      }
    });
    dialogRef.disableClose = true;

    dialogRef.afterClosed().subscribe(res => {
      if (res != '') this.onUpdate.emit(true);
    });
  }

  orderPeriods = (a, b) => {
    const dateA = `${a.key.split('/')[1]}/${a.key.split('/')[0]}/${a.key.split('/')[2]}`;
    const dateB = `${b.key.split('/')[1]}/${b.key.split('/')[0]}/${b.key.split('/')[2]}`;

    return new Date(dateB) > new Date(dateA) ? -1 : 1;
  }

  formatDate(date) {
    const dateSplit = date.split('/');
    date = `${dateSplit[1]}/${dateSplit[0]}/${dateSplit[2]}`;
    return moment(date).format('MM/DD/YYYY');
  }

  get hasSpecialHours(): boolean {
    return !!Object.keys(this.specialHourPeriods)?.length
  }
}
