import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { AngularFireStorage } from '@angular/fire/storage';
import { SnackbarService } from './snackbar.service';
import { InsightsService } from './insights.service';

@Injectable({
  providedIn: 'root'
})
export class SearchKeywordsService {

  public sortByMap = {
    'impressions': 'numberOfImpressions',
    'prevPeriodComparison': 'comparison_percentage',
    'prevYearComparison': 'comparison_percentage',
    'prevMonthComparison': 'comparison_percentage',
    'default': 'atlas_search_score'
  };

  constructor(
    private http: HttpClient,
    private afsStorage: AngularFireStorage,
    private insightS: InsightsService,
    private snackS: SnackbarService,
  ) {}

  
  getSearchKeywords(gid = null, reportId = null, locationId = null, accountId = null, period, startDate, endDate, sort, paginate): Observable<any> {
    period = period ? period?.replace('Comparison', '') : null;

    const data = {
      "gid": gid,
      "reportId": reportId,
      "accountId": accountId,
      "locationId": locationId,
      "startDate": startDate,
      "endDate": endDate,
      "sortOrder": sort.sortOrder,
      "sortBy": sort.sortBy,
      "page": paginate.page,
      "size": paginate.size,
      "period": period,
    }
    
    return this.http.post(`${environment.apiUrl}/v2/locations/search-keywords`, data);
  }

  getSearchKeywordsAtlas(keyword, paginate, startDate, endDate, period, location_ids, sort): Observable<any> {
    const sortBy = {
      'impressions': 'numberOfImpressions',
      'prevPeriodComparison': 'comparison_percentage',
      'prevYearComparison': 'comparison_percentage',
      'prevMonthComparison': 'comparison_percentage',
      'default': 'atlas_search_score'
    };
    
    period = period === null ? 'prevYear' : period;
    period = period?.replace('Comparison', '');

    const data = {
      "size": paginate.size,
      "page": paginate.page,
      startDate,
      endDate,
      location_ids,
      keyword,
      period,
      sort_order: sort.sortDirection || 'desc',
      sort_by: sortBy[sort.sortBy] || sortBy['default'],
    }
    
    return this.http.post(`${environment.apiUrl}/v2/search/keyword-search`, data);
  }

  getViews(locationId = null, gid = null, accountId = null, startDate, endDate, viewBy = null): Observable<any> {
    const data = {
      "accountId": accountId,
      "locationId": locationId,
      "gid": gid,
      "startDate": startDate, 
      "endDate": endDate,
    }

    if (viewBy) {
      data['viewBy'] = viewBy.toLowerCase()
    }

    return this.http.post(`${environment.apiUrl}/v2/locations/views`, data);
  }

  getSearchImpressions(locationId = null, gid = null, accountId = null, startDate, endDate): Observable<any> {
    const data = {
      "accountId": accountId,
      "locationId": locationId,
      "gid": gid,
      "startDate": startDate, 
      "endDate": endDate,
    }
    return this.http.post(`${environment.apiUrl}/v2/locations/search-impressions`, data);
  }

  getTopicsData(startDate, endDate, paginate, gid = null, reportId = null, locationId = null, accountId = null): Observable<any> {
    const data = {
      "accountId": accountId,
      "locationId": locationId,
      "reportId": reportId,
      "gid": gid,
      "startDate": startDate, 
      "endDate": endDate,
      "size": paginate?.size,
      "page": paginate?.page,
    }
    return this.http.post(`${environment.apiUrl}/v2/locations/keyword-trends`, data);
  }

  async handleExportCsvTopic(gid, reportId, accountId, locationId, startDate, endDate, topic)  {
    await this.downloadTopicCsv(gid, reportId, accountId, locationId, startDate, endDate, topic).subscribe(
      res => {
        const filename = res;

        this.afsStorage.ref(filename).getDownloadURL().toPromise().then(filename => {
          this.insightS.downloadFromGS(filename);
          this.snackS.openSuccess("The file exported successfully", 2000);
        });
      },
      err => this.snackS.openError("There was an error in the export", 2000)
    )
  }

  async handleExportCsvKeywordsSearch (startDate, endDate, locationIds, keyword, period, sort)  {
    period = period ? period?.replace('Comparison', '') : null;
    
    await this.createFileAndGetName(startDate, endDate, locationIds, keyword, period, sort).subscribe(
      res => {
        const fileName = res["fileName"];

        this.afsStorage.ref(fileName).getDownloadURL().toPromise().then(fileName => {
          this.insightS.downloadFromGS(fileName);
          this.snackS.openSuccess("The file exported successfully", 2000);
        });
      },
      err => this.snackS.openError("There was an error in the export", 2000)
    )
  }

  downloadTopicCsv(gid, reportId, accountId, locationId, startDate, endDate, topic): Observable<any> {
    const data = {
      "gid": gid,
      "accountId": accountId,
      "locationId": locationId,
      "reportId": reportId,
      "startDate": startDate, 
      "endDate": endDate,
      "type": 'keyword-topic',
      "topic": topic,
    };

    return this.http.post(`${environment.apiUrl}/v3/export/keyword-topic`, data)
  }

  createFileAndGetName(startDate, endDate, locationIds, keyword, comparisonType, sort): Observable<Object>{
    const data = {
      startDate: startDate, 
      endDate: endDate,
      locationIds: locationIds,
      keyword: keyword,
      period: comparisonType,
      sortOrder: sort.sortDirection || 'desc',
      sortBy: this.sortByMap[sort.sortBy] || this.sortByMap['default']
    }

    return this.http.post(`${environment.apiUrl}/v3/export/keyword-search`, data)
  }

}
