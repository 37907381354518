import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-revenue',
  templateUrl: './skeleton-revenue.component.html'
})
export class SkeletonRevenueComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
