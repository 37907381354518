import { __awaiter, __generator } from "tslib";
// dep
import { OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import 'rxjs-compat/add/operator/filter';
import { takeUntil, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
// app
import { WhiteLabelService } from '../../services/white-label.service';
import { DataTransferService } from '../../services/data-transfer.service';
var MainComponent = /** @class */ (function () {
    function MainComponent(router, activatedRoute, titleService, wl, dataTransfer) {
        var _this = this;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.titleService = titleService;
        this.wl = wl;
        this.dataTransfer = dataTransfer;
        this.destroySubs$ = new Subject();
        var appTitle = this.titleService.getTitle();
        router.events.pipe(takeUntil(this.destroySubs$))
            .filter(function (event) { return event instanceof NavigationEnd; })
            .map(function () {
            var child = _this.activatedRoute.firstChild;
            if (child.snapshot.data['title']) {
                return child.snapshot.data['title'];
            }
            return appTitle;
        }).subscribe(function (title) { return __awaiter(_this, void 0, void 0, function () {
            var company_name, url;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.wl.getDynamicWhiteLabel()];
                    case 1:
                        company_name = (_a.sent()).branding.company_name;
                        if (title == "Report") {
                            this.dataTransfer.getData().pipe(take(3)).subscribe(function (reportName) {
                                _this.titleService.setTitle(reportName + " | " + company_name);
                            });
                        }
                        else {
                            this.titleService.setTitle(title + " | " + company_name);
                        }
                        url = this.router.url;
                        this.isFullWidth = url.includes('report/');
                        this.isAuth = (url.includes('login') || url.includes('register') || url.includes('forgot-password'));
                        this.isHeader = url.includes('report/');
                        this.biggerPadding = url.includes('billing');
                        this.setContentClass();
                        return [2 /*return*/];
                }
            });
        }); });
    }
    MainComponent.prototype.ngOnDestroy = function () {
        this.destroySubs$.next(true);
        this.destroySubs$.unsubscribe();
    };
    MainComponent.prototype.ngOnInit = function () { };
    MainComponent.prototype.setContentClass = function () {
        this.contentClass = {
            '': this.isAuth,
            'content--full': this.isFullWidth,
            'content': !this.isFullWidth && !this.isAuth,
            'content-header': this.isHeader,
            'content--padding': this.biggerPadding
        };
    };
    return MainComponent;
}());
export { MainComponent };
