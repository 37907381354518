import { Meta } from '@angular/platform-browser';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
var MetaGuardService = /** @class */ (function () {
    function MetaGuardService(_meta) {
        this._meta = _meta;
    }
    MetaGuardService.prototype.canActivate = function (next, state) {
        var robots = next.data['robots'] || 'index, follow';
        this._meta.updateTag({ name: 'robots', content: robots });
        return true;
    };
    MetaGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MetaGuardService_Factory() { return new MetaGuardService(i0.ɵɵinject(i1.Meta)); }, token: MetaGuardService, providedIn: "root" });
    return MetaGuardService;
}());
export { MetaGuardService };
