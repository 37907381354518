
	  <div class="dialog__header txt--center dialog__header--warn"
		   [ngClass]="{'dialog__header--warn': data.alertType == 1, 'dialog__header--info': data.alertType == 0, 'dialog__header--error': data.alertType == 2}">
		  <h2 mat-dialog-title class="m--0 txt--xl"><strong>{{ data.title | titlecase}}</strong></h2>
	  </div>

	  <div class="dialog__content" mat-dialog-content>
		  <div class="dialog-row">
			  <div class="txt--lg txt--center m--0" [innerHTML]="data.content"></div>
		  </div>
	  </div>

	  <div *ngIf="!data.disableCancelButton" mat-dialog-actions class="dialog__footer">
      <div>
        <span class="alternate-theme d-inline-block mr--10" [ngClass]="{'d--none': data?.hideCancel}">
          <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
        </span>
        
        <span *ngIf="!data.hideContactSupport" class="alternate-theme d-inline-block mr--10" [ngClass]="{'d--none': data?.hideContactSupport}">
          <button *ngIf="!data.hideContactSupport" mat-button tabIndex="-1" color="primary" class="btn btn-cancel" (click)="goToContactSupport()">Contact Support</button>
        </span>
      </div>

      <button tabIndex="-1" mat-flat-button color="{{data?.colorCloseBtn}}" [mat-dialog-close]="data?.title" class="btn btn--action padding-buttons">{{ data?.closeButtonLabel }}</button>
	  </div>
  