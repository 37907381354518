import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-skeleton-reviews',
  templateUrl: './skeleton-reviews.component.html'
})
export class SkeletonReviewsComponent implements OnInit {

  @Input() type: string;
  @Input() isSingle: boolean;
  @Input() flagHarderLoad: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
