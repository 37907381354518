import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {LocationEditService} from "../../services/location-edit.service";

import { FormControl, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-modal-google-ads',
  templateUrl: './modal-google-ads.component.html',
  styleUrls: ['./modal-google-ads.component.scss']
})
export class ModalGoogleAdsComponent implements OnInit {
  phoneForm = new FormGroup({
		phone: new FormControl('')
	});
  
  @Input() bulk: boolean = false;
  @Output() validResult: EventEmitter<boolean> = new EventEmitter(false);

  constructor(
    public dialogRef: MatDialogRef<ModalGoogleAdsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private locationEditS:LocationEditService

  ) {
    if (data) {
      let number = data.googleAds;
      if (number)  {
        number = data.googleAds?.includes('+') ? data.googleAds : `+1 ${data.googleAds}`;
      }
      this.phoneForm.controls.phone.setValue(number)
    }
  }

  ngOnInit() {
  }

  getResult(): { [k: string]: any } {
    if(!this.phoneForm.controls.phone.invalid) {
      this.validResult.emit(false);
      return
    }
    this.validResult.emit(true);
    return {adWordsLocationExtensions: {adPhone: this.phone.value?.internationalNumber} }
  }

  apply(): void {
    if(!this.phoneForm.controls.phone.invalid) {
      this.locationEditS.locationEdit.adWordsLocationExtensions = {adPhone: this.phone.value?.internationalNumber};
      this.locationEditS.setAndUpdate().toPromise().then(()=>{this.dialogRef.close();});
    }
  }

  get phone() {
    return this.phoneForm.controls.phone;
  }
}
