import { EventEmitter, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { LocationEditService } from '../../services/location-edit.service';
import { FormBuilder, Validators } from '@angular/forms';
import { AttributeOpenInfoStatusType } from 'src/app/constants/google/attributes';
import { AlertComponent } from '../../components/alert.component';
var ModalOpenDateComponent = /** @class */ (function () {
    function ModalOpenDateComponent(dialogRef, data, locationEdit, fb, dialog) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.locationEdit = locationEdit;
        this.fb = fb;
        this.dialog = dialog;
        this.listOpen = AttributeOpenInfoStatusType;
        this.statusBusiness = 'OPEN';
        this.bulk = false;
        this.validResult = new EventEmitter(false);
        this.months = [
            { value: 1, name: 'January', days: 31 },
            { value: 2, name: 'February', days: 28 },
            { value: 3, name: 'March', days: 31 },
            { value: 4, name: 'April', days: 30 },
            { value: 5, name: 'May', days: 31 },
            { value: 6, name: 'June', days: 30 },
            { value: 7, name: 'July', days: 31 },
            { value: 8, name: 'August', days: 31 },
            { value: 9, name: 'September', days: 30 },
            { value: 10, name: 'October', days: 31 },
            { value: 11, name: 'November', days: 30 },
            { value: 12, name: 'December', days: 31 }
        ];
        this.days = Array.from(Array(31), function (e, i) { return i + 1; });
    }
    ModalOpenDateComponent.prototype.ngOnInit = function () {
        var _a;
        this.statusBusiness = (_a = this.data) === null || _a === void 0 ? void 0 : _a.status;
        this.initForm();
    };
    ModalOpenDateComponent.prototype.apply = function () {
        var _this = this;
        this.form.markAllAsTouched();
        if (this.form.valid) {
            if (this.statusBusiness === 'CLOSED_PERMANENTLY') {
                var dialog = this.dialog.open(AlertComponent, {
                    width: '680px',
                    data: {
                        title: 'Closed Permanently',
                        alertType: 0,
                        content: 'Are you sure you want to close this business permanently?',
                        closeButtonLabel: 'Confirm'
                    }
                });
                dialog.afterClosed().subscribe(function (data) {
                    if (data) {
                        _this.locationEdit.locationEdit.openInfo.status = _this.statusBusiness;
                        _this.locationEdit.locationEdit.openInfo.openingDate = _this.getData();
                        if (_this.statusBusiness == 'OPEN' && !_this.year.value && !_this.month.value) {
                            delete _this.locationEdit.locationEdit.openInfo.openingDate;
                        }
                        _this.locationEdit.setAndUpdate().toPromise().then(function () {
                            _this.dialogRef.close();
                        });
                    }
                });
            }
            else {
                this.locationEdit.locationEdit.openInfo.status = this.statusBusiness;
                this.locationEdit.locationEdit.openInfo.openingDate = this.getData();
                if (this.statusBusiness == 'OPEN' && !this.year.value && !this.month.value) {
                    delete this.locationEdit.locationEdit.openInfo.openingDate;
                }
                this.locationEdit.setAndUpdate().toPromise().then(function () {
                    _this.dialogRef.close();
                });
            }
        }
    };
    ModalOpenDateComponent.prototype.changeMonth = function () {
        var _this = this;
        var _a;
        var days = ((_a = this.months.find(function (el) { return el.value == _this.month.value; })) === null || _a === void 0 ? void 0 : _a.days) || 31;
        this.days = Array.from(Array(days), function (e, i) { return i + 1; });
    };
    ModalOpenDateComponent.prototype.getData = function () {
        var data = {};
        if (this.year.value && this.month.value) {
            data['year'] = Number(this.year.value),
                data['month'] = Number(this.month.value);
            if (Number(this.day.value) > 0) {
                data['day'] = Number(this.day.value);
            }
        }
        return data;
    };
    ModalOpenDateComponent.prototype.initForm = function () {
        var _a;
        this.form = this.fb.group({
            year: ['', [Validators.pattern('[0-9]{4}')]],
            month: ['', [Validators.max(12)]],
            day: ['', [Validators.pattern('[1-3]?[0-9]|31')]],
        });
        if ((_a = this.data) === null || _a === void 0 ? void 0 : _a.openingDate) {
            this.setForm();
        }
    };
    ModalOpenDateComponent.prototype.setForm = function () {
        this.form = this.fb.group({
            year: [(this.data.openingDate.year || ''), [Validators.pattern('[0-9]{4}')]],
            month: [(this.data.openingDate.month || ''), [Validators.max(12)]],
            day: [(this.data.openingDate.day || ''), [Validators.pattern('[1-3]?[0-9]|31')]],
        });
    };
    ModalOpenDateComponent.prototype.isValidYear = function (year) {
        if (year.value === '0000') {
            this.year.setErrors({ incorrectError: true });
        }
    };
    ModalOpenDateComponent.prototype.isRequired = function (field, compareField) {
        var required = false;
        var compareValue = compareField == 'year' ? this.year.value : this.months.value;
        required = field.value || compareValue || this.day.value && this.day.value != "0" ? true : false;
        return required;
    };
    ModalOpenDateComponent.prototype.statusChanged = function () {
        this.initForm();
    };
    ModalOpenDateComponent.prototype.getResult = function () {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            this.validResult.emit(true);
            return {
                status: this.statusBusiness,
                openingDate: this.getData()
            };
        }
    };
    Object.defineProperty(ModalOpenDateComponent.prototype, "year", {
        get: function () {
            return this.form.get('year');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalOpenDateComponent.prototype, "month", {
        get: function () {
            return this.form.get('month');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalOpenDateComponent.prototype, "day", {
        get: function () {
            return this.form.get('day');
        },
        enumerable: true,
        configurable: true
    });
    return ModalOpenDateComponent;
}());
export { ModalOpenDateComponent };
