import { __awaiter, __generator } from "tslib";
import { ACCOUNTS, GROUPS, LOCATIONS, POST_MANAGEMENT_GROUP, POST_MANAGEMENT_LOCATION } from '../constants/firestore/collections';
import { FirestoreService } from './firestore.service';
import * as firebase from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import * as i0 from "@angular/core";
import * as i1 from "./firestore.service";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "@angular/common/http";
var PostManagementService = /** @class */ (function () {
    function PostManagementService(fs, afs, http) {
        this.fs = fs;
        this.afs = afs;
        this.http = http;
    }
    PostManagementService.prototype.save = function (gid, accountId, locationId, post) {
        return __awaiter(this, void 0, void 0, function () {
            var post_id;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        post.createdAt = firebase.firestore.Timestamp.now();
                        post_id = this.afs.createId();
                        delete post.lastUpdate;
                        if (!post.placeId) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fs.col(GROUPS).doc(gid).collection(ACCOUNTS).doc(accountId).collection(LOCATIONS).doc(locationId)
                                .collection(POST_MANAGEMENT_LOCATION).add(post)];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2: return [4 /*yield*/, this.fs.col(GROUPS).doc(gid).collection(POST_MANAGEMENT_GROUP).doc(post_id).set(post)];
                    case 3:
                        _a.sent();
                        return [2 /*return*/, post_id];
                }
            });
        });
    };
    PostManagementService.prototype.getPostManagement = function (gid, docId) {
        return this.afs.collection(GROUPS).doc(gid).collection(POST_MANAGEMENT_GROUP).doc(docId);
    };
    PostManagementService.prototype.sendScheduled = function (gid, post_id) {
        return this.http.get(environment.apiUrl + "/v2/posts/check/gid/" + gid + "/post/" + post_id)
            .pipe(map(function (response) { return response.data || null; }));
    };
    PostManagementService.prototype.update = function (gid, accountId, locationId, post, id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        post.lastUpdate = firebase.firestore.Timestamp.now();
                        if (!post.placeId) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fs.col(GROUPS).doc(post.gid).collection(ACCOUNTS).doc(accountId).collection(LOCATIONS).doc(locationId).collection(POST_MANAGEMENT_LOCATION).doc(id)
                                .set(post, { merge: true })];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2: return [4 /*yield*/, this.fs.col(GROUPS).doc(post.gid).collection(POST_MANAGEMENT_GROUP).doc(id).set(post, { merge: true })];
                    case 3: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    PostManagementService.prototype.getByGidPending = function (pageable, gid, next, last) {
        return this.fs.paginate(GROUPS + "/" + gid + "/" + POST_MANAGEMENT_GROUP, 'createdAt', null, {
            size: pageable.size,
            page: pageable.page
        }, false, next, last, null, null, 'desc');
    };
    PostManagementService.prototype.delete = function (gid, accountId, locationId, docId) {
        if (locationId) {
            return this.fs.col(GROUPS).doc(gid).collection(ACCOUNTS).doc(accountId).collection(LOCATIONS).doc(locationId)
                .collection(POST_MANAGEMENT_LOCATION).doc(docId).delete();
        }
        else {
            return this.fs.col(GROUPS).doc(gid).collection(POST_MANAGEMENT_GROUP).doc(docId).delete();
        }
    };
    PostManagementService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PostManagementService_Factory() { return new PostManagementService(i0.ɵɵinject(i1.FirestoreService), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.HttpClient)); }, token: PostManagementService, providedIn: "root" });
    return PostManagementService;
}());
export { PostManagementService };
