import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import * as _ from 'lodash';

import { AuthService } from 'src/app/services/auth.service';
import { GoogleService } from '../../services/google.service';
import { LocationEditService } from '../../services/location-edit.service';
import { SnackbarService } from '../../services/snackbar.service';
import { ORDER_LOCATION_INFO } from '../../constants/google/location-stats';
import { DatesService, JsonDate } from '../../services/dates.service';

import * as jsdiff from 'diff';

@Component({
  selector: 'app-modal-fetch',
  templateUrl: './modal-fetch.component.html',
  styleUrls: ['./modal-fetch.component.scss']
})
export class ModalFetchComponent implements OnInit {
  public history = false;
  public difference: any[];
  public select: number;
  public isProgress: boolean;
  public locationId: string;
  public hasAttributesUrl: boolean;
  public hasAttributes: boolean;
  public hasSocialMedia: boolean;
  public showBothCategories: boolean;
  public categoriesData: any[] | any;
  public omitedCategory: string;
  public notifyErrors: boolean = false;
  public error: any;
  public error_details: any [];
  public moreHoursLabels: any [];
  public location;
  public pendingMask = [];
  public diffMask = [];
  public title = 'Fetch';
  public isDiffMask = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<ModalFetchComponent>,
    private _googleS: GoogleService,
    private _locationS: LocationEditService,
    private _snack: SnackbarService,
    private _dateS: DatesService,
    private _authS: AuthService
  ) {
    this.location = this.data?.location;
    this.difference = this.data.difference;
    this.pendingMask = this.getPendingMask();
    this.diffMask = this.data.diffMask;
    this.isDiffMask = this.data.isDiffMask;
    this.title = this.data.title ? this.data.title : 'Fetch';
    this.notifyErrors = this.data.notifyErrors;
    this.error = this.data.error;
    this.error_details = this.data.error_details;
    this.history = this.data.history;
    this.moreHoursLabels = this.data.moreHoursLabels;
    _.isObject(this.difference) && _.isEmpty(this.difference) && (this.difference = []);

    if (this.difference) {
      this.obtainDifference();
      const hasPrimarycategory = _.find(this.difference, { 'key': 'primaryCategory' });
      const hasAdditionalcategories = _.find(this.difference, { 'key': 'additionalCategories' });
      if (_.isEmpty(hasPrimarycategory) && !_.isEmpty(hasAdditionalcategories) || !_.isEmpty(hasPrimarycategory) && _.isEmpty(hasAdditionalcategories)) {
        this.showBothCategories = true;
        this.omitedCategory = _.isEmpty(hasPrimarycategory) ? 'primaryCategory' : 'additionalCategories';
        this.categoriesData = this._locationS.locationEdit;
      }
      let hasAttributes = _.find(this.difference, { 'key': 'attributes' });
      if (!_.isEmpty(hasAttributes)) {
        hasAttributes.googleLocation = _.uniqBy(hasAttributes.googleLocation, 'attributeId');
        hasAttributes.location = _.uniqBy(hasAttributes.location, 'attributeId');
        const diffInGoogle = _.differenceBy(hasAttributes.googleLocation, hasAttributes.location, 'attributeId');
        if (!_.isEmpty(diffInGoogle)) {
          diffInGoogle.forEach((gl) => {
            const attribute = _.clone(gl);
            delete attribute.urlValues;
            delete attribute.repeatedEnumValue;
            attribute.values && (attribute.values = []);
            hasAttributes.location.push(attribute);
          })
        }
        const diffInLocation = _.differenceBy(hasAttributes.location, hasAttributes.googleLocation, 'attributeId');
        if (!_.isEmpty(diffInLocation)) {
          diffInLocation.forEach((l) => {
            const attribute = _.clone(l);
            delete attribute.urlValues;
            delete attribute.repeatedEnumValue;
            attribute.values && (attribute.values = []);
            hasAttributes.googleLocation.push(attribute);
          })
        }
        hasAttributes.location = _.orderBy(hasAttributes.location, ['attributeId'], ['asc']);
        hasAttributes.googleLocation = _.orderBy(hasAttributes.googleLocation, ['attributeId'], ['asc']);
        const googleAttr = !_.isEmpty(hasAttributes.googleLocation) && hasAttributes.googleLocation || [];
        const locationAttr = !_.isEmpty(hasAttributes.location) && hasAttributes.location || [];
        let attributes = [...googleAttr, ...locationAttr];
        this.hasAttributesUrl = !_.isEmpty(_.find(attributes, (o) => { return _.startsWith(o.attributeId, 'url'); }));
        this.hasAttributes = !_.isEmpty(_.find(attributes, (o) => { return !_.startsWith(o.attributeId, 'url'); }));
        this.hasSocialMedia = !_.isEmpty(_.find(attributes, (o) => {return this._locationS.isSocialMedia(o);}));
      }
      this.difference.forEach(diff => {
        diff.key = diff?.key?.toLowerCase();
        return diff;
      });
      this.difference = this.orderingFetch(this.difference);
    }
    this.locationId = this.data.placeId;
  }

  ngOnInit() {}

  isEmptyObject(obj): boolean {
    for (var property in obj) {
        if (obj.hasOwnProperty(property)) {
            return false;
        }
    }

    return true;
}

  keys(object): {} {
    return Object.keys(object);
  }

  getPendingMask(): [] {
    const index = this.data?.pendingMask?.findIndex(el => el == 'metadata');
    if (index > 0) {
      this.data.pendingMask.splice(index, 1);
    }

    return this.data.pendingMask || [];
  }

  camelCase(string): string {
    return string.split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
  }

  isString(value): boolean {
    return typeof(value) === 'string' ? true : false;
  }

  apply(select: number): void {
    this.select = select;
    this.isProgress = true;
    this._googleS.fetch(this.select, this._locationS.accountId, this._locationS.locationId).then(
      result => {
        if (result?.data?.success == 200) {
          this.select = null;
          this.isProgress = false;
          this.difference = [];
          this._dialogRef.close(!this.history);
          this._snack.openSuccess(result?.message, 2000); 
        } else {
          this.select = null;
          this.isProgress = false;
          this.difference = [];
          this._dialogRef.close(!this.history);
          this._snack.openError(result?.data?.message, 4000);
        }
      }, err => {
        this.select = null;
        this.isProgress = false;
        this.difference = [];
        this._dialogRef.close(!this.history);
        this._snack.openError(err?.error?.data?.message, 4000);
      }
    );
  }

  setValueByPath(obj: any, path: string[], value: any): void {
    if (!obj || !path || path.length === 0) {
      return;
    }

    let current = obj;
    for (let i = 0; i < path.length - 1; i++) {
      if (!current[path[i]]) {
        current[path[i]] = {};
      }
      current = current[path[i]];
    }
    current[path[path.length - 1]] = value?.value;
  }

  acceptChangesFromGoogle(): void {
    this.isProgress = true;
    this.select = 2;
    const fieldsToModify = this.data?.diffMask
    const locationEdit = this._locationS.locationEdit;

    fieldsToModify?.forEach(el => {
      const keys = el.key.includes('.') ? el.key.split('.') : [el.key];
      const lastKey = keys[keys.length - 1];

      const value = this.setValueByPath(locationEdit, keys, el);
    })

    this.saveData();
  }

  saveData() {
    const msg = 'There was an error while saving the data. Please try again or contact support';

    this._locationS.setAndUpdate().subscribe(
      res => {
        const gid = this._authS.session.gid;
        const differences = this.difference;
        
        differences.forEach(el => {
          delete el.diffLoc;
          delete el.diffGoogle;
        });

        this._googleS.diffMaskPush(gid, this._locationS.accountId, this._locationS.locationId, differences).subscribe(
          result => {
            this.select = null;
            this.isProgress = false;
            this.difference = [];
            this._dialogRef.close(!this.history);
            this._snack.openSuccess(result?.message, 2000); 
          },
          error => {
            this.handleError(msg);
          }
        )
      },
      err => {
        this.handleError(msg);
      }
    )
  }

  getValue(value): string {
    const data = value?.value
    return typeof(data) !== 'object' ? data : data?.displayName ? data.displayName : data?.map(el => el?.displayName)?.join(' | ');
  }

  handleError(msg) {
    this.select = null;
    this.isProgress = false;
    this.difference = [];
    this._dialogRef.close(!this.history);
    this._snack.openError(msg, 4000);
  }

  openInfoDate(openDating: JsonDate) {
    return this._dateS.dateJsonToDate(openDating)
  }

  orderingFetch(diferences) {
    const result = [];
    const keys = diferences.map(d => d.key);
    for (const key of ORDER_LOCATION_INFO) {
      if (_.includes(keys, key)) {
        result.push(_.find(diferences, { 'key': key}));
      }
    }

    return result;
  }

  obtainDifference(): void {
    let htmlDiffs, keys, google, location;
    this.difference?.forEach(el => {
      const key = (el.key).toLowerCase()
      if (key === 'locationname' || key === 'websiteurl' || key === 'profile' ||
        key === 'storecode' || key === 'adwordslocationextensions' || key === 'primaryphone' || key === 'primarycategory') {
        switch(key) {
          case 'adwordslocationextensions':
            google = el.googleLocation?.adPhone;
            location = el.location?.adPhone;
            break;
          case 'profile':
            google = el.googleLocation?.description;
            location = el.location?.description;
            break
          case 'primarycategory':
            google = el.googleLocation?.displayName;
            location = el.location?.displayName;
            break
          default:
            google = el.googleLocation;
            location = el.location;
            break;
        }

        htmlDiffs = this.buildHtmlByDifference((google || ''), (location || ''));
        el.diffLoc = htmlDiffs.diffLoc;
        el.diffGoogle = htmlDiffs.diffGoogle;
      }

      if (key === "labels" || key === "additionalphones") {
        this.getArrayDifference(el);
      }

      if (key === 'additionalcategories') {
        el.diffLoc = this.buildHtmlByArrays((el.location || []), (el.googleLocation || []), 'displayName', 'border--success');
        el.diffGoogle = this.buildHtmlByArrays((el.googleLocation || []), (el.location || []), 'displayName', 'border--error');
      }

      if (key === 'address') {
        el.googleLocation = el.googleLocation || {};
        el.location = el.location || {};
        keys = Object.keys(el.googleLocation)?.filter(k => k != 'diffGoogle');
        el.location.diffLoc = {...el.location};
        el.googleLocation.diffGoogle = {...el.googleLocation};

        keys.forEach(k => {
          if (k !== 'addressLines') {
            htmlDiffs = this.buildHtmlByDifference((el.googleLocation[k] || ''), (el.location[k] || ''));
            el.location.diffLoc[k] = htmlDiffs.diffLoc;
            el.googleLocation.diffGoogle[k] = htmlDiffs.diffGoogle;
          } else {
            el.location.diffLoc.addressLines = [...el.location?.addressLines || []];
            el.googleLocation.diffGoogle.addressLines = [...el.googleLocation?.addressLines];

            el.googleLocation[k]?.forEach((g, i) => {
              htmlDiffs = this.buildHtmlByDifference(g, (el.location[k]?.[i] || ''));
              el.googleLocation.diffGoogle[k][i] = htmlDiffs.diffGoogle;
            });

            el.location[k]?.forEach((l, i) => {
              htmlDiffs = this.buildHtmlByDifference((el.googleLocation[k]?.[i] || ''), l);
              el.location.diffLoc[k][i] = htmlDiffs.diffLoc;
            });
          }
        })
      }

      if (key === 'attributes') {
          el.diffLoc = JSON.parse(JSON.stringify(el.location));
          el.diffGoogle = JSON.parse(JSON.stringify(el.googleLocation));

          el.googleLocation?.forEach((googleAttr, index) =>{
            if(googleAttr.valueType == 'URL') {
              el.diffGoogle[index].urlValues = el.diffGoogle[index].urlValues ? el.diffGoogle[index].urlValues : [];
              const locAttr = el.location.find(l => googleAttr.attributeId == l.attributeId);
              this.obtainUrlValues(locAttr);
              el.diffGoogle[index].urlValues = this.buildHtmlByArrays(googleAttr?.urlValues, locAttr?.urlValues, 'url', 'border--error');
            }
          })

          el.location?.forEach((locAttr, index) =>{
            if(locAttr?.valueType == 'URL') {
              el.diffLoc[index].urlValues = el.diffLoc[index].urlValues ? el.diffLoc[index].urlValues : [];
              const googleAttr = el.googleLocation.find(g => g.attributeId == locAttr.attributeId);
              this.obtainUrlValues(locAttr);
              el.diffLoc[index].urlValues = this.buildHtmlByArrays(locAttr?.urlValues, googleAttr?.urlValues, 'url', 'border--success');
            }
          })
      }

      if(key === 'placeactionlinks') {
        el?.location?.forEach((item, index) => {
          el.diffLoc = [];
          let diffLocation = {...item};
          diffLocation.placeActionLinks = this.buildHtmlByArrays((item?.placeActionLinks || []), (el?.googleLocation[index]?.placeActionLinks || []), 'uri', 'border--success');
          el.diffLoc.push(diffLocation);
        });

        el?.googleLocation?.forEach((item, index) => {
          el.diffGoogle = [];
          let diffGoogle = {...item};
          diffGoogle.placeActionLinks = this.buildHtmlByArrays((item?.placeActionLinks || []), (el?.location[index]?.placeActionLinks || []), 'uri', 'border--error');
          el.diffGoogle.push(diffGoogle);
        });
      }
    })
  }

  obtainUrlValues(data) {
    if (!data?.uriValues) { return; }
    data.urlValues = data.uriValues.map(i => {return {url: i.uri}});
    delete data.uriValues;
  }

  buildHtmlByArrays(firstArray, secondArray, fieldModified, className) {
    firstArray?.forEach(c => {
      const item = secondArray?.findIndex(el => el[fieldModified] == c[fieldModified]);
      c[fieldModified] = item == -1 || item == null ?
      `<span class=${className}>${c[fieldModified]}</span>` :
      c[fieldModified]
    });

    return firstArray;
  }

  getArrayDifference(el): void {
    let htmlDiffs;
    el.diffGoogle = [];
    el.diffLoc = []

    el.googleLocation?.forEach((g, i) => {
      htmlDiffs = this.buildHtmlByDifference(g, (el?.location?.[i] || ''));
      el.diffGoogle.push(htmlDiffs.diffGoogle);
    });

    el.location?.forEach((l, i) => {
      htmlDiffs = this.buildHtmlByDifference((el.googleLocation?.[i] || ''), l);
      el.diffLoc.push(htmlDiffs.diffLoc);
    });
  }

  buildHtmlByDifference(google, location): {} {
    const diff = jsdiff.diffWordsWithSpace(google, location);

    let locHtml = '';
    let googleHtml = '';

    diff.forEach(d => {
      if (d.added) {
        locHtml += `<span class=border--success>${d.value}</span>`;
      }  else if (d.removed) {
        googleHtml += `<span class=border--error>${d.value}</span>`;
      } else if (!d.removed){
        locHtml += `${d.value}`;
        googleHtml += `${d.value}`;
      }
    });

    return {
      diffLoc: locHtml,
      diffGoogle: googleHtml
    }
  }

  formatStatus(status): string {
    if (status != 'OPEN') {
      status = status.split('_');
      status = status.join(' ');
    }

    return status;
  }


  hasOpeningData(data): boolean {
    return data['openingDate'] && Object.keys(data.openingDate).length > 0 ? true : false;
  }
}
