<div class="dialog__header">
    <h2 mat-dialog-title class="m--0 txt--xl"><strong>{{!isEdit ? 'Add ' : 'Edit '}} Section</strong></h2>
  </div>
  
  <form [formGroup]="form">
    <div class="dialog__content" mat-dialog-content>
  
      <div class="dialog-row pb--0" [formGroupName]="'labels'" style="margin: 15px 0;">
        <mat-form-field class="input-field">
          <input matInput placeholder="Section Name" formControlName="displayName">
          <mat-hint align="start">i.e: Lunch, Drinks, Snacks, Dinner </mat-hint>
          <mat-hint align="end">{{form.get('labels.displayName').value.length}} / 140</mat-hint>
          <mat-error *ngIf="form.get('labels.displayName').hasError('required')">The name is required</mat-error>
        </mat-form-field>
      </div>

      <div class="dialog-row pb--0" *ngIf="!isEdit" >
        <h4 style="font-weight: 500;">Add new item to menu</h4>
        <ng-container formArrayName="items" *ngFor="let item of ItemsArray.controls;let i = index">
            <div [formGroupName]="i" class="form-group">
                <div class="row">
                    <div class="col-12">
                        <mat-form-field class="input-field">
                            <input matInput placeholder="Item Name" formControlName="displayName" name="itemName">
                            <mat-error *ngIf="getItemArray(i).get('displayName').hasError('required')"><p>Name is required</p></mat-error>
                            <mat-hint align="end">{{getItemArray(i).get('displayName').value.length}} / 140</mat-hint>
                        </mat-form-field>
                    </div>
                </div>

                
                  <ng-container formGroupName="attributes">
                    <div class="row">
                        <div class="col-12">
                          <mat-form-field class="input-field">
                            <mat-select placeholder="Type price" formControlName="type" name="TypePrice">
                              <mat-option value="noPrice">No price</mat-option>
                              <mat-option value="free">Free</mat-option>
                              <mat-option value="fixed">Fixed</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                    </div>
                    <div class="row" *ngIf="getItemArray(i).get('attributes.type').value !== 'free' && getItemArray(i).get('attributes.type').value !== 'noPrice'">
                        <div class="col-12 col-md-6">
                          <mat-form-field class="input-field">
                            <mat-select formControlName="currencyCode" placeholder="Currency" >
                              <mat-option value="USD">USD</mat-option>
                              <mat-option value="EUR">EUR</mat-option>
                              <mat-option value="GBP">GBP</mat-option>
                              <mat-option value="AUD">AUD</mat-option>
                              <mat-option value="CAD">CAD</mat-option>
                              <mat-option value="COP">COP</mat-option>
                            </mat-select>
                            <mat-error *ngIf="getItemArray(i).get('attributes.currencyCode').errors">
                              <p *ngIf="getItemArray(i).get('attributes.currencyCode').hasError('required')">Currency is required.</p>
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-12 col-md-6">
                          <mat-form-field class="input-field">
                            <input (blur)="onChangePrice(i)" matInput [placeholder]="'Item Price (' + getItemArray(i).get('attributes.currencyCode').value + ')'" formControlName="units" step="0.01"  name="itemPrice">
                            <mat-error *ngIf="getItemArray(i).get('attributes.units').errors">
                              <p *ngIf="getItemArray(i).get('attributes.units').hasError('required')">Units is required.</p>
                              <p *ngIf="getItemArray(i).get('attributes.units').hasError('pattern')">The input must be a number.</p>
                              <p *ngIf="getItemArray(i).get('attributes.units').hasError('min')">The minimum price is 0.</p>
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                  </ng-container>
                  
                  
            
                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="input-field">
                        <textarea matInput placeholder="Description" formControlName="description" name="itemDescription" rows="3"></textarea>
                        <mat-hint align="end">{{getItemArray(i).get('description').value.length}} / 1,000</mat-hint>
                      </mat-form-field>
                    </div>
                  </div>
            </div>
        </ng-container>
            
      </div>
  
    </div>
    <div mat-dialog-actions class="dialog__footer">
      <span class="alternate-theme d-inline-block mr--10">
          <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
      </span>
      <div>
        <button mat-flat-button (click)="remove()" tabIndex="-1" color="primary" class="btn btn--action mr--10" *ngIf="isEdit">Remove</button>
        <button mat-flat-button (click)="apply()" tabIndex="-1" color="accent" class="btn btn--action">Apply</button>
      </div>
    </div>
  </form>
  
  