import { PipeTransform } from '@angular/core';
var AttributtesFetchPipe = /** @class */ (function () {
    function AttributtesFetchPipe() {
    }
    AttributtesFetchPipe.prototype.transform = function (value) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        value = value.replace('is_', '');
        value = value.replace('has_', '');
        return value.replace(/_/g, ' ');
    };
    return AttributtesFetchPipe;
}());
export { AttributtesFetchPipe };
