
<div class="spinner-wrapper spinner-wrapper--fixed" *ngIf="isSpinner">
    <mat-spinner></mat-spinner>
  </div>
  <div class="content__header content__header--up d-flex justify-content-between align-items-center">

    <h1><strong>Bulk Listing Updates</strong></h1>
    <button mat-flat-button color="accent" class="btn btn--icon btn--md" (click)="create()">
      <i class="far fa-plus"></i> Create Bulk Update
    </button>
  </div>

  <div class="content__body">
    <div class="table--shadow mb--30">
      <div class="mat-row mat-row--padding mat-row--border  justify-content-between align-items-center bg--white">
        <app-skeleton-location-info-list *ngIf="progressBulkLocation"></app-skeleton-location-info-list>
        <ng-container *ngIf="!progressBulkLocation">
          <ng-container *ngIf="pendingBulkLocationExist; else bulkEmpty">
            <table mat-table matSort [dataSource]="dataSource"  class="table table--lg ">
              <ng-container matColumnDef="createDate">
                <th mat-header-cell *matHeaderCellDef><strong>Creation Date/Time</strong></th>
                <td mat-cell *matCellDef="let element" class="cursor--pointer" style="width: 20%;">
                  <div class="cell__content">
                    <p>{{ element.createdDate | date: 'MM/dd/yyyy - hh:mm:ss a'}}</p>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="property">
                <th mat-header-cell *matHeaderCellDef class="txt--center"><strong>Update</strong></th>
                <td mat-cell *matCellDef="let element" style="width: 20%;">
                  <div class="cell__content flex-column align-items-center justify-content-center">
                    <h6 class="m--0"><strong>{{ GetNameProperty(element) }}</strong></h6>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="locations">
                <th mat-header-cell *matHeaderCellDef class="txt--center"><strong>Locations</strong></th>
                <td mat-cell *matCellDef="let element" style="width: 20%;">
                  <div class="cell__content flex-column align-items-center justify-content-center">
                    <button mat-flat-button color="accent" [matMenuTriggerFor]="locationMenu"
                            class="d-flex align-items-center btn btn--badge btn--badge--sm txt--uppercase txt--xs">
                      {{ countLocation(element) }} locations <i class="fas fa-angle-down ml--10 mr--0"></i>
                    </button>
                    <mat-menu #locationMenu="matMenu" class="mat-menu--lg">
                      <ng-container *ngFor="let account of element.accounts">
                        <button class="d-flex align-items-center" mat-menu-item *ngFor="let location of account.locations">
                          <span class="mr--5">{{location?.locationName}} -</span>
                          <gmb-address  
                            *ngIf="location?.address || location?.serviceArea"
                            [address]="location?.address"
                            [serviceAreas]="location?.serviceArea?.places?.placeInfos">
                          </gmb-address>
                        </button>
                      </ng-container>
                    </mat-menu>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="changes">
                <th mat-header-cell *matHeaderCellDef class="txt--center"><strong>Changes</strong></th>
                <td mat-cell *matCellDef="let element" style="width: 20%;">
                  <div class="cell__content flex-column align-items-center justify-content-center">
                    <button mat-flat-button color="accent" class="btn--badge btn--badge--sm" (click)="openViewChanges(element)" [disabled]="element.status === 'PENDING' " >
                      <i class="far fa-file-alt"></i>
                      <span>View</span>
                    </button>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="progress">
                <th mat-header-cell *matHeaderCellDef class="txt--center"><strong>Progress</strong></th>
                <td mat-cell *matCellDef="let element" style="width: 20%;">
                  <div class="cell__content flex-column align-items-center justify-content-center">
                    <div class="cell__content">
                      <ng-container>
                        <div class="progress-label" style="padding: 0px 10px;">
                          <mat-progress-bar
                            color="primary"
                            [mode]="element?.progress ? 'determinate' : 'indeterminate'"
                            [value]="element?.progress?.value">
                          </mat-progress-bar>
                          <ng-container>
                            <p class="pl--40 pr--40 progress-label__txt {{ (element.progress.data.failed_locations + element.progress.data.updated_locations) / element.progress.data.total_locations < 0.6 ? 'txt--black' : 'txt--white' }}">
                              <span>{{ (element.progress.data.failed_locations + element.progress.data.updated_locations) }} / {{ element.progress.data.total_locations }}</span>
                            </p>
                          </ng-container>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef class="txt--center"><strong>Status</strong></th>
                <td mat-cell *matCellDef="let element" style="width: 20%;">
                  <div class="cell__content flex-column align-items-center justify-content-center">
                    <ng-container *ngIf="selectColor(element.status); let color">
                        <ng-container [ngSwitch]="element.status">
                          <ng-container *ngSwitchCase="'PARTIAL'">
                            <span class="alternate-theme d-inline-block">
                              <button mat-flat-button [color]="color" class="btn--badge btn--badge--sm">
                                <span>{{element.status}}</span>
                              </button>
                            </span>
                          </ng-container>
                          <ng-container *ngSwitchDefault>
                            <button mat-flat-button [color]="color" class="btn--badge btn--badge--sm">
                              <span>{{element.status}}</span>
                            </button>
                            <em class="fas fa-info-circle txt--lightgray txt--sm changes-log-icon" *ngIf='element.edited' (click)="openViewChanges(element, true)"></em>
                          </ng-container>
                        </ng-container>
                    </ng-container>

                  </div>
                </td>
              </ng-container>


              <tr mat-header-row *matHeaderRowDef="displayedColumns" class="with-padding"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns" class="with-padding"></tr>

            </table>


          </ng-container>
          <ng-template #bulkEmpty>
            <div class="box bg--white">
              <div class="box--margin d-flex align-items-center justify-content-center">
                <h2 class="txt--capitalize"><strong>No Bulk Listing Updates </strong></h2>
              </div>
            </div>
          </ng-template>
          <app-paginator [pagination]="pagination" *ngIf="pagination && pendingBulkLocationExist" (reload)="handleReload($event)"></app-paginator>

        </ng-container>
      </div>

    </div>

  </div>

