<app-dashboard-filter *ngIf="!isExternal" [progress]="false" [accountId]="accountId"  typeDashboard="grade" [isShared]='isShared'></app-dashboard-filter>
<div [ngClass]="{'with-dashboard': !isExternal}">
  <app-skeleton-grade *ngIf="validateIfprogress"></app-skeleton-grade>
  <ng-container>
    <div id="charts-container" [class.show]="show" [class.hide]="!show" >
      <app-grade-header-location
        [dataPicker]="dataPicker"
        [gid]="gid"
        [accountId]="accountId"
        [locationId]="locationId"
        [grade]="overallGrade"
        [isExternal]="isExternal"
        *ngIf="!isReport" (finish)="handleHeaderSuccess($event)">
      </app-grade-header-location>
      <app-grade-metrics
        [dataPicker]="dataPicker"
        [gid]="gid"
        [accountId]="accountId"
        [locationId]="locationId"
        [isReport]="isReport"
        [isExternal]="isExternal"
        (finish)="handleMetricsSuccess($event)"
        (grade)="qualification($event)">
      </app-grade-metrics>
      <app-grade-comparison
        [locations]="locations"
        [dataPicker]="dataPicker"
        [chartBy]="'Day'"
        [showScoreOnCard]="true"
        [type]="'grade'"
        [isExternal]="isExternal"
        [information]="'Checking off all the boxes above is great, but ultimately all that really matters is if your listing is reaching more customers than it was in the past. We use this section to score your listings performance “last month” compared to the same month in the “previous year”. If you just updated your listings information, content, images, etc recently, it will take some time to show impact in your insights performance. Fortunately, you can refresh your grade each month to see how your listing is improving over time.'"
        (finish)="handleComparisonSuccess($event)">
      </app-grade-comparison>
    </div>
    <app-skeleton-comparison columns="2" *ngIf="validateIfprogress"></app-skeleton-comparison>
    <div *ngIf="noData">
      <app-no-data message="No grade data"></app-no-data>
    </div>
  </ng-container>

</div>
