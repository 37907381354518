
      <div class="dialog__content dialog--featured" mat-dialog-content>
        <img src="/assets/images/modal-star.png" alt="Image of a star" class="dialog__icon" width="128" height="128">
        <button mat-dialog-close="" class="btn btn-dialog-close btn--absolute"><i class="txt--lg fal fa-times"></i></button>
        <div class="dialog-row txt--center">
          <h2 class="heading--xl mb--10 mt--25 txt--black"><strong>Report Contains Essential Or Basic Locations</strong></h2>
          <p class="heading-h2 mb--10">To access this report, you must upgrade all locations to the <strong>Ultimate</strong> plan or remove them from the report.</p>
        </div>
        <div mat-dialog-actions class="dialog__footer d-flex justify-content-between">
          <button mat-flat-button color="accent"  class="btn btn--md txt--md" [mat-dialog-close]="'upgrade'">Upgrade All</button>
          <button mat-flat-button color="primary" class="btn btn--md txt--md" [mat-dialog-close]="'edit'">Edit Report</button>
        </div>
      </div>
  