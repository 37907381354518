// dep
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment as ENV } from '@environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var BulkLocationsEditService = /** @class */ (function () {
    function BulkLocationsEditService(http) {
        this.http = http;
    }
    // TODO: Firestore not used, remove
    // getRef(gid) {
    //   return this.afs.collection(GROUPS).doc(gid).collection(BULK_LOCATIONS_EDITS);
    // }
    BulkLocationsEditService.prototype.getByGid = function (pageable, gid, next, last) {
        return this.http.get(ENV.apiUrl + "/v2/bulk_updates/" + gid + "/all?page=" + pageable.page + "&pageSize=" + pageable.size);
        /*     return this.fs.paginate<BulkLocationInfo>(`${GROUPS}/${gid}/${BULK_LOCATIONS_EDITS}`, 'createdAt', null
              , {
                size: pageable.size,
                page: pageable.page
              }, true, next, last, null, null , 'desc'); */
    };
    BulkLocationsEditService.prototype.getBulkProgress = function (id) {
        return this.http.get(ENV.apiUrl + "/v2/google/bulk_edit/" + id + "/status");
    };
    BulkLocationsEditService.prototype.create = function (gid, bulkEdit) {
        return this.http.post(ENV.apiUrl + "/v2/bulk_updates/" + gid + "/add", bulkEdit).toPromise();
    };
    BulkLocationsEditService.prototype.getResumeEdits = function (bulk_edit, page, deleted) {
        if (deleted === void 0) { deleted = false; }
        return this.http.post(ENV.apiUrl + "/v2/google/resume_edits/edit/" + bulk_edit, { page: page, deleted: deleted }).pipe(map(function (value) {
            return value.data || [];
        }));
    };
    BulkLocationsEditService.prototype.processBulkEdit = function (o) {
        return this.http.post(ENV.apiUrl + "/v2/google/bulk_edit", o).toPromise();
    };
    BulkLocationsEditService.prototype.editBulk = function (gid, bulkId, updates) {
        return this.http.post(ENV.apiUrl + "/v2/bulk_updates/" + gid + "/" + bulkId + "/edit", { updates: updates }).pipe(map(function (_value) { return true; })).toPromise();
    };
    BulkLocationsEditService.prototype.retryFailedLocations = function (bulk_id) {
        return this.http.get(ENV.apiUrl + "/v2/google/bulk_edit/" + bulk_id + "/retry");
    };
    BulkLocationsEditService.prototype.purgeFailedLocations = function (bulk_id) {
        return this.http.get(ENV.apiUrl + "/v2/google/bulk_edit/" + bulk_id + "/purge");
    };
    BulkLocationsEditService.prototype.getchangeslogs = function (bulk_id) {
        return this.http.get(ENV.apiUrl + "/v2/google/bulk_edit/" + bulk_id + "/changelog");
    };
    BulkLocationsEditService.prototype.getBulkPlaceActionLinks = function (gids, accountIds, locationIds) {
        var data = {
            gids: [gids],
            accountIds: accountIds,
            locationIds: locationIds
        };
        return this.http.post(ENV.apiUrl + "/v2/google/bulkPlaceActionLinks", data);
    };
    BulkLocationsEditService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BulkLocationsEditService_Factory() { return new BulkLocationsEditService(i0.ɵɵinject(i1.HttpClient)); }, token: BulkLocationsEditService, providedIn: "root" });
    return BulkLocationsEditService;
}());
export { BulkLocationsEditService };
