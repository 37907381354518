import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { environment } from "@environment";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BLUE_COLOR, CORAL_COLOR } from '../../constants/chart-color';
import moment from 'moment';
import * as _ from 'lodash';
import { InsightsService } from '../../services/insights.service';
import { ORDER_KEYS } from '../../constants/google/location-stats';
import { LoadingService } from '../../services/loading.service';
var GradeComparisonComponent = /** @class */ (function () {
    function GradeComparisonComponent(insightS, loadingService) {
        this.insightS = insightS;
        this.loadingService = loadingService;
        this.finish = new EventEmitter();
        this.showScoreOnCard = false;
        this.isExternal = false;
        this.fromReportsScreen = false;
        this.reportType = '';
        this.viewModeChecked = null;
        this.comparison = null;
        this.tooltipDataSelected = { rangeA: [], rangeB: [] };
        this.progress = true;
        this.comparisonPartialData = false;
        this.comparisonEmptyData = false;
        this.comparisonPartialDataMessage = '';
        this.comparisonEmptyDataMessage = '';
        this.rangesDiffer = false;
        this.primaryDataColor = BLUE_COLOR;
        this.secondaryDataColor = CORAL_COLOR;
        this.unsubscribe$ = new Subject();
        this.environment = environment;
    }
    GradeComparisonComponent.prototype.ngOnInit = function () {
        var _this = this;
        var _a;
        if (this.type === 'grade') {
            this.getGradeData();
        }
        if (this.expand) {
            this.getData();
        }
        (_a = this.refreshFilter) === null || _a === void 0 ? void 0 : _a.pipe(takeUntil(this.unsubscribe$)).subscribe(function (result) {
            _this.dataPicker = result;
            _this.getData();
        });
        if (this.externalComparison) {
            this.externalComparisonSet(this.externalComparison);
            return;
        }
    };
    GradeComparisonComponent.prototype.getData = function () {
        var _this = this;
        this.progress = true;
        this.chartBy && (this.dataPicker.aggregation = this.chartBy);
        this.insightS.comparison(this.locations, this.dataPicker, this.metrics, this.type, this.isExternal, this.reportId, this.viewModeChecked).take(2).subscribe(function (result) {
            var _a, _b, _c, _d, _e;
            _this.progress = false;
            if (!result) {
                _this.comparison = undefined;
                _this.finish.emit(false);
                return;
            }
            if (Array.isArray(result) && ((_a = result) === null || _a === void 0 ? void 0 : _a.length) === 0) {
                _this.comparison = undefined;
                _this.finish.emit(false);
                return;
            }
            _this.comparison = result;
            if (_this.comparison.stats) {
                _this.comparison.stats = _this.orderingStats(_this.comparison.stats);
                if (_this.type === 'report') {
                    var start = (_b = (_this.dataPicker.range.start)) === null || _b === void 0 ? void 0 : _b.split('T')[0];
                    var end = (_c = (_this.dataPicker.range.end)) === null || _c === void 0 ? void 0 : _c.split('T')[0];
                    var startB = (_d = (_this.dataPicker.rangeB.start)) === null || _d === void 0 ? void 0 : _d.split('T')[0];
                    var endB = (_e = (_this.dataPicker.rangeB.end)) === null || _e === void 0 ? void 0 : _e.split('T')[0];
                    _this.startDate = _this.buildDate(start, end);
                    _this.endDate = _this.buildDate(startB, endB);
                    // check in runtime for different ranges, in case the user modifies the date range in the picker:
                    var dateStart = moment(start);
                    var dateEnd = moment(end);
                    var dateStartB = moment(startB);
                    var dateEndB = moment(endB);
                    var daysDifferenceStartEnd = dateEnd.diff(dateStart, 'days');
                    var daysDifferenceStartBEndB = dateEndB.diff(dateStartB, 'days');
                    _this.rangesDiffer = daysDifferenceStartEnd !== daysDifferenceStartBEndB;
                }
                _this.tooltipDataSelected.rangeA = Object.assign(_this.comparison.labels.map(function (l) { return "Range A " + l + ":"; }), []);
                _this.tooltipDataSelected.rangeB = Object.assign(_this.comparison.labels.map(function (l) { return "Range B " + l + ":"; }), []);
                _this.stats = _this.comparison.stats;
                _this.loadingService.reportAdvanced(1, "Comparison Insigths");
                _this.setDataComparison(result);
            }
            else {
                _this.finish.emit(false);
            }
        }, function (error) {
            _this.finish.emit(false);
            _this.progress = false;
        });
    };
    GradeComparisonComponent.prototype.buildDate = function (start, end) {
        var _a, _b, _c, _d, _e, _f;
        var startDate = (((_a = start) === null || _a === void 0 ? void 0 : _a.split('-')) || []);
        var endDate = (((_b = end) === null || _b === void 0 ? void 0 : _b.split('-')) || []);
        return ((_c = startDate[1]) === null || _c === void 0 ? void 0 : _c.padStart(2, '0')) + "/" + ((_d = startDate[2]) === null || _d === void 0 ? void 0 : _d.padStart(2, '0')) + "/" + startDate[0] + " - " + ((_e = endDate[1]) === null || _e === void 0 ? void 0 : _e.padStart(2, '0')) + "/" + ((_f = endDate[2]) === null || _f === void 0 ? void 0 : _f.padStart(2, '0')) + "/" + endDate[0];
    };
    GradeComparisonComponent.prototype.getGradeData = function () {
        var _this = this;
        this.progress = true;
        this.chartBy && (this.dataPicker.aggregation = this.chartBy);
        this.insightS.getGradeComparison(this.locations[0].locationId, this.dataPicker).take(2).subscribe(function (result) {
            _this.progress = false;
            if (!result) {
                _this.comparison = undefined;
                _this.finish.emit(false);
                return;
            }
            // TODO: Duplicate code, intention was to do the second check like in the getData method?
            // if (!result) {
            //   this.comparison = undefined;
            //   this.finish.emit(false);
            //   return;
            // }
            _this.comparison = result.data;
            if (_this.comparison.stats) {
                _this.startDate = moment(_this.dataPicker.range.start).format('MM/DD/YYYY') + " - " + moment(_this.dataPicker.range.end).format('MM/DD/YYYY');
                _this.endDate = moment(_this.comparison.startDateB).format('MM/DD/YYYY') + " - " + moment(_this.comparison.endDateB).format('MM/DD/YYYY');
                _this.tooltipDataSelected.rangeA = Object.assign(_this.comparison.labels.map(function (l) { return "Range A " + l + ":"; }), []);
                _this.tooltipDataSelected.rangeB = Object.assign(_this.comparison.labels.map(function (l) { return "Range B " + l + ":"; }), []);
                _this.comparison.stats = _this.orderingStats(_this.comparison.stats);
                _this.stats = _this.comparison.stats;
                _this.loadingService.reportAdvanced(1, "Comparison Insigths");
                _this.setDataComparison(result);
            }
            else {
                _this.finish.emit(false);
            }
        }, function (error) {
            _this.finish.emit(false);
            _this.progress = false;
        });
    };
    GradeComparisonComponent.prototype.isShowMetric = function (metric) {
        if (!this.metrics) {
            return true;
        }
        return this.metrics.includes(metric);
    };
    GradeComparisonComponent.prototype.setSelectedItem = function (i, key) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        this.comparisonPartialData = false; // reset the flag
        this.comparisonEmptyData = false;
        this.selectedItem = i;
        this.dataSelected = this.stats[key];
        // used to build the empty data message
        var hasNoDataInRangeA = ((_a = this.dataSelected) === null || _a === void 0 ? void 0 : _a.totalA) === 0;
        var hasNoDataInRangeB = ((_b = this.dataSelected) === null || _b === void 0 ? void 0 : _b.totalB) === 0;
        var hasNoDataInSomeRange = ((_c = this.dataSelected) === null || _c === void 0 ? void 0 : _c.totalA) === 0 || ((_d = this.dataSelected) === null || _d === void 0 ? void 0 : _d.totalB) === 0;
        var hasNoDataInBothRange = hasNoDataInRangeA && hasNoDataInRangeB;
        // used to build the partial data message
        var hasGapsInRangeA = (_f = (_e = this.dataSelected) === null || _e === void 0 ? void 0 : _e.rangeA) === null || _f === void 0 ? void 0 : _f.includes(0);
        var hasGapsInRangeB = (_h = (_g = this.dataSelected) === null || _g === void 0 ? void 0 : _g.rangeB) === null || _h === void 0 ? void 0 : _h.includes(0);
        var hasGapsInData = ((_k = (_j = this.dataSelected) === null || _j === void 0 ? void 0 : _j.rangeA) === null || _k === void 0 ? void 0 : _k.includes(0)) || ((_m = (_l = this.dataSelected) === null || _l === void 0 ? void 0 : _l.rangeB) === null || _m === void 0 ? void 0 : _m.includes(0));
        var hasGapsInBothRange = hasGapsInRangeA && hasGapsInRangeB;
        if (hasNoDataInSomeRange) {
            this.comparisonEmptyData = true;
            // empty data message factory
            this.comparisonEmptyDataMessage = "\n      There is no data in\n      " + (hasNoDataInRangeA ? ' Range A' : '') + "\n      " + (hasNoDataInBothRange ? ' and' : '') + "\n      " + (hasNoDataInRangeB ? ' Range B' : '') + "\n      ";
        }
        else if (hasGapsInData || this.rangesDiffer) {
            this.comparisonPartialData = true;
            // partial data message factory
            this.comparisonPartialDataMessage = "\n        " + (this.rangesDiffer ? 'The amount of days in range A and range B differ. ' : '') + "\n        " + (hasGapsInData ? 'There are gaps in the data for' : '') + "\n        " + (hasGapsInRangeA ? ' Range A' : '') + "\n        " + (hasGapsInBothRange ? ' and' : '') + "\n        " + (hasGapsInRangeB ? ' Range B' : '') + "\n        " + (hasGapsInData ? '. Because some of the values represent zero, the comparison may not be useful.' : '') + "\n        ";
        }
    };
    GradeComparisonComponent.prototype.externalComparisonSet = function (external) {
        if (!external) {
            return;
        }
        this.setDataComparison(external);
    };
    GradeComparisonComponent.prototype.setDataComparison = function (result) {
        var _a, _b, _c;
        this.progress = false;
        if (this.comparison) {
            this.finish.emit(result);
            this.labels = (_a = this.comparison) === null || _a === void 0 ? void 0 : _a.labels;
            this.labels_b = (_b = this.comparison) === null || _b === void 0 ? void 0 : _b.labels_b;
            this.gradeKeys = Object.keys((_c = this.comparison) === null || _c === void 0 ? void 0 : _c.stats);
            this.setSelectedItem(0, this.gradeKeys[0]);
        }
    };
    GradeComparisonComponent.prototype.tooltipContent = function (item) {
        return item.points + "/" + item.max;
    };
    GradeComparisonComponent.prototype.orderingStats = function (stats) {
        var result = {};
        var keys = _.keys(stats);
        for (var key in ORDER_KEYS) {
            if (ORDER_KEYS.hasOwnProperty(key) && _.includes(keys, key)) {
                result[key] = _.get(stats, key);
            }
        }
        return result;
    };
    GradeComparisonComponent.prototype.getGraphRiseImage = function (selectedItem, index, difference) {
        if (selectedItem !== index && difference > 0) {
            return "/assets/images/icons/graph-rise-green.svg";
        }
        if (selectedItem !== index && difference < 0) {
            return "/assets/images/icons/graph-rise-red.svg";
        }
        return "/assets/images/icons/graph-rise.svg";
    };
    GradeComparisonComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    };
    return GradeComparisonComponent;
}());
export { GradeComparisonComponent };
