import {Component, OnInit,} from '@angular/core';
import {MULTIKEY_DATA} from "../../constants/google/location-stats";

export interface MultiKeyArray {
  name: string;
  status: string;
}


@Component({
  selector: 'app-comparison-report-insight',
  templateUrl: './comparison-report-insight.component.html',
  styleUrls: ['./comparison-report-insight.component.scss']
})
export class ComparisonReportInsightComponent implements OnInit {
  multipleLocation: any;
  selectedItem: number;
  selectedNestedItem: number;
  multiKeys: any;

  constructor() {
    this.multiKeys = MULTIKEY_DATA;
  }

  ngOnInit() {
  }

  setSelectedItem(selected: number) {
    this.selectedItem = selected;
  }

  setSelectedItemNested(selected: number) {
    this.selectedNestedItem = selected;
  }
}

