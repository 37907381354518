import { __assign, __awaiter, __generator } from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, of, merge } from 'rxjs';
import * as _ from 'lodash';
import { LocationService } from '../../services/location.service';
import { ModalService } from '../../services/modal.service';
import { GoogleService } from '../../services/google.service';
import { ReviewLinkGeneratorComponent } from '../../components/review-link-generator.component';
import { DomainService } from '../../services/domain.service';
import { AuthService } from '../../services/auth.service';
import * as constants_locations from '../../constants/firestore/account-location';
import { takeUntil, switchMap } from 'rxjs/operators';
import { DataTransferService } from '../../services/data-transfer.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { DELETE_DATA, NOTIFICATION_GENERAL, TYPE_LOG_LOCATION } from 'src/app/constants/notifications';
import { NotificationService } from 'src/app/services/notification.service';
import { WhiteLabelService } from "../../services/white-label.service";
import { Messages, string_message } from 'src/app/constants/messages';
import { GROUP_SUBSCRIPTION_TYPE, LOCATION_SUBSCRIPTION_TYPE } from '../../constants/firestore/account-location';
import { SubscriptionService } from '../../services/subscription.service';
var SidebarComponent = /** @class */ (function () {
    function SidebarComponent(domainService, auth, _route, _locationS, _modalService, _googleService, _dataTransfer, _spinnerService, _notificationService, _wl, _router, _apiSubscription) {
        var _this = this;
        this.domainService = domainService;
        this.auth = auth;
        this._route = _route;
        this._locationS = _locationS;
        this._modalService = _modalService;
        this._googleService = _googleService;
        this._dataTransfer = _dataTransfer;
        this._spinnerService = _spinnerService;
        this._notificationService = _notificationService;
        this._wl = _wl;
        this._router = _router;
        this._apiSubscription = _apiSubscription;
        this.location = of({});
        this.destroySubs$ = new Subject();
        this.isRevealPath = false;
        this.companyName = '';
        this.c_auth_unauthorized = constants_locations.VERIFICATION_UNAUTHORIZED;
        this.c_auth_not_found = constants_locations.VERIFICATION_NOT_FOUND;
        this.auth.subscription$.subscribe(function (subscription) { return _this.subscription = subscription; });
        this.user = this.auth.session;
    }
    SidebarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._wl.branding.then(function (res) { return _this.companyName = res['company_name']; });
        this.loadLocation();
        /*this.navigationSubscription = this.router.events.subscribe((e: any) => {
          // If it is a NavigationEnd event re-initalise the component
          if (e instanceof NavigationEnd) {
            this.loadLocation();
          }
        });
    
        this.locationTitle = this.location.subscribe(location => {
          const title = location ? `${location.locationName} @ ${location.location.address.addressLines[0]}`: '';
          this.wl.setTitle(title);
        });*/
    };
    SidebarComponent.prototype.getStatus = function (status) {
        if (!status) {
            return;
        }
        return status.replace(/\_/g, " ");
    };
    SidebarComponent.prototype.loadLocation = function () {
        var _this = this;
        var _a, _b;
        this.locationId = (_a = this._route.snapshot.params) === null || _a === void 0 ? void 0 : _a.locationId;
        this.accountId = (_b = this._route.snapshot.params) === null || _b === void 0 ? void 0 : _b.accountId;
        this.location = this._locationS.getRef(this.auth.session.gid, this.accountId, this.locationId);
        this.location.pipe(takeUntil(this.destroySubs$)).subscribe(function (location) {
            _this.currentLocation = _this.formData(location);
            _this._dataTransfer.setData(location.locationName);
            _this.placeId = location ? location.location.locationKey.placeId : null;
        });
    };
    SidebarComponent.prototype.formData = function (location) {
        if (location && this.subscription) {
            var status_1 = '';
            if (location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE && this.subscription.status === GROUP_SUBSCRIPTION_TYPE.TRIAL) {
                status_1 = 'ULTIMATE TRIAL';
            }
            else if (location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE && this.subscription.status !== GROUP_SUBSCRIPTION_TYPE.TRIAL) {
                status_1 = 'ESSENTIAL';
            }
            if (location.subscriptionType !== LOCATION_SUBSCRIPTION_TYPE.FREE) {
                status_1 = location.subscriptionType;
            }
            location['subscriptionStatus'] = status_1;
        }
        return location;
    };
    SidebarComponent.prototype.ngOnDestroy = function () {
        if (this.navigationSubscription) {
            this.navigationSubscription.unsubscribe();
        }
        this.destroySubs$.next(true);
        this.destroySubs$.unsubscribe();
    };
    SidebarComponent.prototype.handleReviewLinkGenerator = function () {
        var _this = this;
        if (!this.placeId) {
            return;
        }
        this._googleService.dynamicUrl(this.placeId).subscribe(function (url) {
            var dialogRef = _this._modalService.openGenericModal(ReviewLinkGeneratorComponent, url, function (data) { return null; });
            dialogRef.disableClose = true;
        });
    };
    SidebarComponent.prototype.openDeleteDialog = function () {
        var _this = this;
        var _a;
        this._modalService.openConfirmModal("Are you sure you want to disconnect " + ((_a = this.currentLocation) === null || _a === void 0 ? void 0 : _a.locationName) + " from " + this.companyName + "?", "Note: this only disconnects the profile from " + this.companyName + "; it does not delete your business profile from Google Maps. You can always re-add your business profile to " + this.companyName + ".", function (res) {
            var _a, _b;
            if (!res) {
                return;
            }
            _this.auth.processSubscription();
            //Notification informative delete location
            if ((_a = _this.currentLocation) === null || _a === void 0 ? void 0 : _a.address)
                _this.currentLocation.formatAddress = _this._locationS.formatAddress((_b = _this.currentLocation) === null || _b === void 0 ? void 0 : _b.address);
            else
                _this.currentLocation.formatAddress = '';
            var delete$ = of(_this._locationS.deleteReferencesToLocation(_this.user.gid, _this.locationId));
            delete$.pipe(switchMap(function () { return _this._locationS.deleteLocation(_this.user.gid, _this.currentLocation.locationId, _this.accountId); })).subscribe(function () {
                _this._spinnerService.loading$.next(false);
            }, function (err) {
                _this._spinnerService.loading$.next(false);
                console.error('Error deleting locations', err);
            }, function () {
                var _a, _b, _c;
                _this.currentLocation = __assign({ accountId: _this.accountId, address: (_a = _this.currentLocation) === null || _a === void 0 ? void 0 : _a.formatAddress }, _this.currentLocation);
                var meta = _this._notificationService.getMetaTypeLog(TYPE_LOG_LOCATION, _this.currentLocation);
                var notify$ = merge(_this._notificationService.saveNotification(_this.auth.session.gid, _this._wl.baseDomain, string_message(Messages.notifications.LOCATION_TOGGLE, [(_b = _this.currentLocation) === null || _b === void 0 ? void 0 : _b.locationName, (_c = _this.currentLocation) === null || _c === void 0 ? void 0 : _c.address, DELETE_DATA]), NOTIFICATION_GENERAL, TYPE_LOG_LOCATION, meta));
                notify$.subscribe();
                _this._router.navigate(['/accounts']).then();
            });
        }, 2);
    };
    SidebarComponent.prototype.changePlan = function () {
        return __awaiter(this, void 0, void 0, function () {
            var locationsChange;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.auth.isMember) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._modalService.openWarningModal('Contact your administrator', 'Your user doesn’t have permissions to do this. Contact your account administrator to change your role.')];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                    case 2:
                        locationsChange = [];
                        if (!_.isArray(this.currentLocation)) {
                            locationsChange = [{ locationId: this.currentLocation, accountId: this.accountId }];
                        }
                        else {
                            this.currentLocation.forEach(function (location) {
                                locationsChange.push({ locationId: location, accountId: _this.accountId });
                            });
                        }
                        return [4 /*yield*/, this._apiSubscription.flowChangeLocationsPlan(this.subscription, locationsChange)];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return SidebarComponent;
}());
export { SidebarComponent };
