// dep
import { Injectable } from '@angular/core';
import { MatPaginator } from '@angular/material';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';

// app
import { AuthService } from './auth.service';
import SavedAccount from '../constants/firestore/saved-account';
import User from '../constants/firestore/user';
import AccountObject from '../constants/firestore/account-object';
import { Pageable } from '../constants/pageable';
import { Pagination } from '../constants/api-response';
import { environment as ENV } from '@environment';
import { GoogleService } from './google.service';
import { Queue } from '../constants/firestore/enqueue';
// import GoogleUserProfile from '../constants/firestore/google-user-profile';


interface response {
  message: string,
  data: { status: string; 
          message: string; 
          response: any }
}

@Injectable({
  providedIn: 'root'
})


// This refers to Saved GMB Accounts in Firestore.
export class AccountService {
  private _dataStore: {
    accounts: SavedAccount[]
  };
  private _accounts: BehaviorSubject<SavedAccount[]>;
  public loading = false;
  paginator: MatPaginator;
  query;
  size = 10;
  page = 1;
  pagination: Pagination = {
    items: [],
    per_page: this.size,
    page: this.page,
    hasNext: false,
    hasPrev: false,
    pages: 0,
    total: 0
  };
  previousPageable: Pageable;

  constructor(
    private _auth: AuthService,
    private _googleS: GoogleService,
    private _http: HttpClient
  ) {
    this._dataStore = { accounts: [] };
    this._accounts = new BehaviorSubject<SavedAccount[]>([]);
  }

  get accounts(): Observable<SavedAccount[]> {
    return this._accounts.asObservable();
  }

  async getAccountPaginate(gid : string, pageable: Pageable, accountIds?: string[]) : Promise<{items : any[]}> {
    const params = new HttpParams()
      .set('page',       pageable.page.toString())
      .set('pageSize',   pageable.size.toString())
      .set('accountIds', accountIds.join(','))

    return await this._http.get<{items : any[]}>(`${ENV.apiUrl}/v2/accounts/${gid}/all`, { params }).toPromise()
  }

  async loadAll(user: User, pageable: Pageable): Promise<any> {
    this.loading = true;
    const accountIds = user.accounts?.map(it => it['accountId']) || [];
    if (user.role == 'member' && accountIds.length == 0) {
        accountIds.push('-');
    }
    const result = await this.getAccountPaginate(user.gid, pageable, accountIds)
    this.previousPageable = { size: result['perPage'], 
                              page: result['currentPage'] };
    this.pagination = {
      items:    result['items'],
      per_page: result['perPage'],
      page:     result['currentPage'],
      hasNext:  result['currentPage'] < result['totalPages'],
      hasPrev:  result['currentPage'] > 1,
      pages:    result['totalPages'],
      total:    result['totalItems']
    };
    this._dataStore.accounts = result['items'];
    this._accounts.next(Object.assign({}, this._dataStore).accounts);
    this.loading = false;
    return result;
  }

  delete(gid : string, accountId : string) : Promise<any> {
    return this._http.delete(`${ENV.apiUrl}/v2/accounts/${gid}/${accountId}`).toPromise();
  }

  async saveAll(accounts) {
    const r : AccountObject[] = []
    for (const acc of accounts) {
        r.push(await this.save(acc))
    }
    return r
  }

  updateGauth(gid : string, accountId : string): Promise<any> {
    const gauth = {
      googleAuth: this._auth.session.googleAuth,
      gauthStatus: {
        isValid: true,
        message: null,
        updatedAt: new Date()  // FIXME: Local browser time, not Server UTC
      }
    };

    return this.getAndUpdate(gid, accountId, gauth).toPromise();
  }


  async save(accountObject, gradeExternal?: boolean): Promise<AccountObject> {
    const googleAuth = gradeExternal ? accountObject.googleAuth : this._auth.session.googleAuth

    const userProfile = await this._googleS.getUserProfile(googleAuth)

    const account: SavedAccount = {
      gid:         gradeExternal ? this._auth.externalSession.gid : this._auth.session.gid,
      accountId:   accountObject.name.split('/')[1],
      accountName: accountObject.accountName,
      account:     accountObject,
      googleAuthEmailAddress: userProfile.email,
      gauthStatus: { isValid: true },
      googleAuth
    };

    if (gradeExternal) {
      account.gradeExternal = gradeExternal;
    }

    return await this._http.post<AccountObject>(`${ENV.apiUrl}/v2/accounts/add`, account).toPromise();
  }

  get(gid : string, accountId : string): Observable<any> {
    return this._http.get(`${ENV.apiUrl}/v2/accounts/${gid}/${accountId}`);
  }

  getAndUpdate(gid: string, accountId: string, data): Observable<any> {
    return this._http.post(`${ENV.apiUrl}/v2/accounts/${gid}/${accountId}/save`, data);
  }

  reset(): void {
    this._accounts = new BehaviorSubject<SavedAccount[]>([]);
  }

  refreshAccountLocations(element): Observable<response> {
    let params = new HttpParams();
    if (ENV.queuesEnabled) {
      params = params.append('enqueue', Queue.COMBINED_EXPRESS);
    }

    if (element.last_refresh) {
      const currentDate = new Date();
      const lastUpdate  = new Date(element.last_refresh.toDate());

      if (currentDate.getDate() >= lastUpdate.getDate()) {
        let diffInMilliSeconds = Math.abs(currentDate.getDate() - lastUpdate.getDate()) / 1000;
        const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
        diffInMilliSeconds -= hours * 3600;
        if (diffInMilliSeconds <= 24) {
          return of({
            message: 'We cannot do this action',
            data: {
              message: 'We cannot do this action',
              response: null,
              status: 'FAIL'
            }
          })
        }
      }
    }
    
    return this._http.post<response>(`${ENV.apiUrl}/v2/accounts/refresh/gid/${element.gid}/account/${element.accountId}`, {}, { params });
  }


  // TODO: Not used, remove?
  // refreshCount(enqueue: string): Observable<response> {
  //  let params = new HttpParams();
  //  if (enqueue && ENV.queuesEnabled) {
  //    params = params.append('enqueue', enqueue);
  //  }
  //
  //  return this.http.get<response>(`${ENV.apiUrl}/v2/accounts/refresh/count`, { params });
  // }

  enableNotifications(accountId: string): Observable<response> {
    const headers = new HttpHeaders({'accountId' : accountId});

    return this._http.post<response>(`${ENV.apiUrl}/v2/google/updateNotificationSetting`, {}, {headers});
  }

  buildToggleLocationAccounts(locations): {} {
    const data = []
    locations.forEach(el =>{
      const indexAcc = data.findIndex(loc => loc.accountId == el?.accountId);
      if (indexAcc > -1) {
        data[indexAcc].locations.push(this.buildToggleLocationLocations(el))
      } else {
        const acc = {
          accountId: el?.accountId,
          gid: this._auth.session.gid,
          locations: []
        }
        acc.locations.push(this.buildToggleLocationLocations(el))
        data.push(acc)
      }
    });
    return data;
  }

  buildToggleLocationLocations(account): {} {
    return {
      accountId: account?.accountId,
      locationId: account?.location.locationId,
      locationName: account?.location.name,
      address: account?.location.address,
      serviceArea: account?.location.serviceArea,
    }
  }

}
