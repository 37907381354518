import { PipeTransform } from '@angular/core';
var ShortNumberPipe = /** @class */ (function () {
    function ShortNumberPipe() {
    }
    ShortNumberPipe.prototype.transform = function (value) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        value = parseInt(value, 0);
        if (isNaN(value)) {
            return null;
        } // will only work value is a number
        if (value === null) {
            return null;
        }
        if (value === 0) {
            return null;
        }
        var abs = Math.abs(value);
        var rounder = Math.pow(10, 1);
        var isNegative = value < 0; // will also work for Negetive numbers
        var key = '';
        var powers = [
            { key: 'Q', value: Math.pow(10, 15) },
            { key: 'T', value: Math.pow(10, 12) },
            { key: 'B', value: Math.pow(10, 9) },
            { key: 'M', value: Math.pow(10, 6) },
            { key: 'K', value: 1000 }
        ];
        for (var i = 0; i < powers.length; i++) {
            var reduced = abs / powers[i].value;
            reduced = Math.round(reduced * rounder) / rounder;
            if (reduced >= 1) {
                abs = reduced;
                key = powers[i].key;
                break;
            }
        }
        return (isNegative ? '-' : '') + abs + key;
    };
    return ShortNumberPipe;
}());
export { ShortNumberPipe };
