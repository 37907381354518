import { EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../../services/user.service';
import { GroupService } from '../../../services/group.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { HttpClient } from '@angular/common/http';
var DeleteUserDialogComponent = /** @class */ (function () {
    function DeleteUserDialogComponent(data, router, dialogRef, userService, groupService, snack, http) {
        this.data = data;
        this.router = router;
        this.dialogRef = dialogRef;
        this.userService = userService;
        this.groupService = groupService;
        this.snack = snack;
        this.http = http;
        this.onDelete = new EventEmitter();
        this.user = data.user;
    }
    DeleteUserDialogComponent.prototype.ngOnInit = function () {
    };
    DeleteUserDialogComponent.prototype.delete = function () {
        var _this = this;
        this.userService.deleteUser(this.user).subscribe(function (res) {
            _this.dialogRef.close(true);
        }, function (error) {
            var _a, _b;
            if ((_a = error.error) === null || _a === void 0 ? void 0 : _a.message) {
                _this.snack.openError("Error: " + ((_b = error.error) === null || _b === void 0 ? void 0 : _b.message), 4000);
            }
            else {
                _this.snack.openError("Unexpected Error: Please contact support", 4000);
            }
            _this.dialogRef.close(false);
        });
    };
    return DeleteUserDialogComponent;
}());
export { DeleteUserDialogComponent };
