<div class="box box--rounded box--shadow bg--white">
    <div class="box__header d-flex">
      <div>
        <h2 class="txt--capitalize d-flex align-items-center" >
          <strong>Review replies by protocol</strong>
        </h2>
        <h2 class="txt--capitalize d-flex align-items-center">
          Total:
          <ngx-skeleton-loader count="1" [theme]="{ width: '25px',height: '25px','margin-bottom': '0', 'transform':'translateY(5px)'}"
                                 *ngIf="isProgressNumber"> </ngx-skeleton-loader>
          <span class="txt--green" *ngIf="!isProgressNumber">{{total | number: '1.0-2'}}</span>
        </h2>
      </div>
      <div style="margin-left: auto;" class=" d-flex">
        <!--view by dropdown-->
        <div class="filter__item">
          <span class="d-inline-block txt--normal ml--10 mr--15 mb--0">View By:</span>
          <button mat-button [matMenuTriggerFor]="viewByMenu" class="filter__item__btn" ngDefaultControl
                  [(ngModel)]="dataPicker.aggregation">
            {{ dataPicker.aggregation }} <i class="fal fa-angle-down" data-html2canvas-ignore></i>
          </button>

          <mat-menu #viewByMenu="matMenu" [overlapTrigger]="false" xPosition="before">
            <button mat-button color="accent" (click)="changeText('Month')">Month</button>
            <button mat-button color="accent" (click)="changeText('Week')">Week</button>
            <button mat-button color="accent" (click)="changeText('Day')">Day</button>
          </mat-menu>
        </div>

        <!--calendar dropdown-->
        <div class="filter__item d-flex align-items-baseline" *ngIf="dataPicker.range" >
          <app-date-picker [dataPicker]="dataPicker" (dateSelected)="datesUpdated($event)" type="review"
                          [select]="selectedDate"></app-date-picker>
        </div>
      </div>
    </div>
    <div class="box__content box--padding-xxs">

     <app-skeleton-reviews type="NUMBER-NEW-REVIEWS" *ngIf="isProgressNumber"></app-skeleton-reviews>

    <app-chart-line
      type="COLORS-FULL"
      *ngIf="!isProgressNumber"
      [showLegend]="false"
      [labels]="labelsBarRating"
      [dataset]="datasetBarNumber"
      [dataPicker]="dataPicker"
      chartType="bar">

    </app-chart-line>

    </div>
  </div>
