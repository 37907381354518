import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {REPORT_TYPE} from '../../constants/firestore/report';

@Component({
  selector: 'app-rollup-report',
  templateUrl: './rollup-report.component.html',
  styleUrls: ['./rollup-report.component.scss']
})
export class RollupReportComponent implements OnInit {
  reportType: REPORT_TYPE = 'performance-rollup-report';


  constructor(
    public router: Router,
    private route: ActivatedRoute,
    ) {}

  ngOnInit() {
    this.reportType = this.route?.snapshot?.routeConfig?.path?.replace('-report','') as REPORT_TYPE;
  }

}
