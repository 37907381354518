import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataTransferService<T> {

  private dataSource: BehaviorSubject<T> = new BehaviorSubject(null);
  currentMessage$ = this.dataSource.asObservable();

  constructor() { }

  /**
   * funcion que setea información en mi behavior subject
   * @param d tipo de informacion de conjuto dinamico dependiendo de la clase que se necesite
   */
  setData(d: T) {
    this.dataSource.next(d);
  }

  getData(): Observable<T> {
      return this.currentMessage$;
  }

}
