/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/ngx-skeleton-loader/ngx-skeleton-loader.ngfactory";
import * as i2 from "ngx-skeleton-loader";
import * as i3 from "@angular/common";
import * as i4 from "./skeleton-questions-answer.component";
var styles_SkeletonQuestionsAnswerComponent = [];
var RenderType_SkeletonQuestionsAnswerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SkeletonQuestionsAnswerComponent, data: {} });
export { RenderType_SkeletonQuestionsAnswerComponent as RenderType_SkeletonQuestionsAnswerComponent };
function View_SkeletonQuestionsAnswerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "ngx-skeleton-loader", [["count", "1"]], null, null, null, i1.View_NgxSkeletonLoaderComponent_0, i1.RenderType_NgxSkeletonLoaderComponent)), i0.ɵdid(2, 114688, null, 0, i2.NgxSkeletonLoaderComponent, [], { count: [0, "count"], theme: [1, "theme"] }, null), i0.ɵpod(3, { width: 0, height: 1 })], function (_ck, _v) { var currVal_0 = "1"; var currVal_1 = _ck(_v, 3, 0, "380px", "225px"); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_SkeletonQuestionsAnswerComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "mb--25"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "ngx-skeleton-loader", [["count", "1"]], null, null, null, i1.View_NgxSkeletonLoaderComponent_0, i1.RenderType_NgxSkeletonLoaderComponent)), i0.ɵdid(2, 114688, null, 0, i2.NgxSkeletonLoaderComponent, [], { count: [0, "count"], theme: [1, "theme"] }, null), i0.ɵpod(3, { width: 0, height: 1 })], function (_ck, _v) { var currVal_0 = "1"; var currVal_1 = _ck(_v, 3, 0, "430px", "510px"); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_SkeletonQuestionsAnswerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "box--height mt--60"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "col-12 p--0 mb--30"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "box box--padding-xxs box--shadow box--rounded bg--white row justify-content-between"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_SkeletonQuestionsAnswerComponent_1)), i0.ɵdid(4, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpad(5, 4), (_l()(), i0.ɵeld(6, 0, null, null, 3, "div", [["class", "col-12 p--0 row justify-content-between"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_SkeletonQuestionsAnswerComponent_2)), i0.ɵdid(8, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpad(9, 6), (_l()(), i0.ɵeld(10, 0, null, null, 3, "div", [["class", "txt--align--center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 2, "ngx-skeleton-loader", [["count", "1"]], null, null, null, i1.View_NgxSkeletonLoaderComponent_0, i1.RenderType_NgxSkeletonLoaderComponent)), i0.ɵdid(12, 114688, null, 0, i2.NgxSkeletonLoaderComponent, [], { count: [0, "count"], theme: [1, "theme"] }, null), i0.ɵpod(13, { width: 0, height: 1 })], function (_ck, _v) { var currVal_0 = _ck(_v, 5, 0, 0, 1, 2, 3); _ck(_v, 4, 0, currVal_0); var currVal_1 = _ck(_v, 9, 0, 0, 1, 2, 3, 4, 5); _ck(_v, 8, 0, currVal_1); var currVal_2 = "1"; var currVal_3 = _ck(_v, 13, 0, "180px", "40px"); _ck(_v, 12, 0, currVal_2, currVal_3); }, null); }
export function View_SkeletonQuestionsAnswerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-skeleton-questions-answer", [], null, null, null, View_SkeletonQuestionsAnswerComponent_0, RenderType_SkeletonQuestionsAnswerComponent)), i0.ɵdid(1, 114688, null, 0, i4.SkeletonQuestionsAnswerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SkeletonQuestionsAnswerComponentNgFactory = i0.ɵccf("app-skeleton-questions-answer", i4.SkeletonQuestionsAnswerComponent, View_SkeletonQuestionsAnswerComponent_Host_0, {}, {}, []);
export { SkeletonQuestionsAnswerComponentNgFactory as SkeletonQuestionsAnswerComponentNgFactory };
