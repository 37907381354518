// base color code definitions

export const WHITE_COLOR = {
  opacity: 'rgba(255, 255, 255, 0.1)',
  full: 'rgb(255, 255, 255)'
};

export const BLUE_PASTEL_COLOR = {
  opacity: 'rgba(82, 120, 165, 0.1)',
  full: 'rgb(82, 120, 165)',
};

export const ORANGE_PASTEL_COLOR = {
  opacity: 'rgba(240, 146, 61, 0.1)',
  full: 'rgb(240, 146, 61)',
};

// tableau palette color code definitions
// export const BLUE_COLOR = {
//   full : '#4E79A7',
//   opacity : '#A0CBE84d',
// };
// export const ORANGE_COLOR = {
//   full : '#F28E2B',
//   opacity : '#FFBE7D4d',
// };
// export const GREEN_COLOR = {
//   full : '#59A14F',
//   opacity : '#8CD17D4d',
// };
// export const YELLOW_COLOR = {
//   full : '#B6992D',
//   opacity : '#F1CE634d',
// };
// export const TURQUOISE_COLOR = {
//   full : '#499894',
//   opacity : '#86BCB64d',
// };
// export const RED_COLOR = {
//   full : '#E15759',
//   opacity : '#FF9D9A4d',
// };
// export const GRAY_COLOR = {
//   full : '#79706E',
//   opacity : '#BAB0AC4d',
// };
// export const PINK_COLOR = {
//   full : '#D37295',
//   opacity : '#FABFD24d',
// };
// export const VIOLET_COLOR = {
//   full : '#B07AA1',
//   opacity : '#D4A6C84d',
// };
// export const BROWN_COLOR = {
//   full : '#9D7660',
//   opacity : '#D7B5A64d',
// };

// primary colors definition

export const BLUE_COLOR = {
  full: '#36A2EB',
  opacity: '#36A2EB00'
};
export const CORAL_COLOR = {
  full: '#FF6384',
  opacity: '#FF638400'
};
export const TEAL_COLOR = {
  full: '#4BC0C0',
  opacity: '#4BC0C000'
};
export const ORANGE_COLOR = {
  full: '#FF9F40',
  opacity: '#FF9F4000'
};
export const PURPLE_COLOR = {
  full: '#9966FF',
  opacity: '#9966FF00'
};
export const YELLOW_COLOR = {
  full: '#FFCD56',
  opacity: '#FFCD5600'
};
export const BROWN_COLOR = {
  full: '#9B7661',
  opacity: '#9B766100'
};
export const PINK_COLOR = {
  full: '#FEA0A9',
  opacity: '#FEA0A900'
};
export const TAUPE_COLOR = {
  full: '#BAB1AD',
  opacity: '#BAB1AD00'
};

// secondary colors definition (not in use yet)
export const BLUE_COLOR_SECONDARY = {
  full: 'A5D5F6',
  opacity: 'A5D5F64d'
};
export const CORAL_COLOR_SECONDARY = {
  full: 'FFB9C8',
  opacity: 'FFB9C84d'
};
export const TEAL_COLOR_SECONDARY = {
  full: 'AEE3E3',
  opacity: 'AEE3E34d'
};
export const ORANGE_COLOR_SECONDARY = {
  full: 'FFD4A9',
  opacity: 'FFD4A94d'
};
export const PURPLE_COLOR_SECONDARY = {
  full: 'D1BAFF',
  opacity: 'D1BAFF4d'
};
export const YELLOW_COLOR_SECONDARY = {
  full: 'FFE8B3',
  opacity: 'FFE8B34d'
};
export const BROWN_COLOR_SECONDARY = {
  full: 'D2C1B8',
  opacity: 'D2C1B84d'
};
export const PINK_COLOR_SECONDARY = {
  full: 'FED4D8',
  opacity: 'FED4D84d'
};
export const TAUPE_COLOR_SECONDARY = {
  full: 'E0DCDA',
  opacity: 'E0DCDA4d'
};


// COLORS_CHART color definitions

export const CHART_BLUE_COLOR = {
  backgroundColor: BLUE_COLOR.opacity,
  borderColor: BLUE_COLOR.full
};

export const CHART_CORAL_COLOR = {
  backgroundColor: CORAL_COLOR.opacity,
  borderColor: CORAL_COLOR.full
};

export const CHART_TEAL_COLOR = {
  backgroundColor: TEAL_COLOR.opacity,
  borderColor: TEAL_COLOR.full
};

export const CHART_ORANGE_COLOR = {
  backgroundColor: ORANGE_COLOR.opacity,
  borderColor: ORANGE_COLOR.full
};

export const CHART_PURPLE_COLOR = {
  backgroundColor: PURPLE_COLOR.opacity,
  borderColor: PURPLE_COLOR.full
};

export const CHART_YELLOW_COLOR = {
  backgroundColor: YELLOW_COLOR.opacity,
  borderColor: YELLOW_COLOR.full
};

export const CHART_BROWN_COLOR = {
  backgroundColor: BROWN_COLOR.opacity,
  borderColor: BROWN_COLOR.full
};

export const CHART_PINK_COLOR = {
  backgroundColor: PINK_COLOR.opacity,
  borderColor: PINK_COLOR.full
};

export const CHART_TAUPE_COLOR = {
  backgroundColor: TAUPE_COLOR.opacity,
  borderColor: TAUPE_COLOR.full
};

export const CHART_BLUE_PASTEL_COLOR = {
  backgroundColor: BLUE_PASTEL_COLOR.opacity,
  borderColor: BLUE_PASTEL_COLOR.full,
};

export const CHART_ORANGE_PASTEL_COLOR = {
  backgroundColor: ORANGE_PASTEL_COLOR.opacity,
  borderColor: ORANGE_PASTEL_COLOR.full,
};

// COLORS_CHART array definition

export const COLORS_CHART = [
  CHART_BLUE_COLOR,
  CHART_CORAL_COLOR,
  CHART_TEAL_COLOR,
  CHART_ORANGE_COLOR,
  CHART_PURPLE_COLOR,
  CHART_YELLOW_COLOR,
  CHART_BROWN_COLOR,
  CHART_PINK_COLOR,
  CHART_TAUPE_COLOR
];

// COLORS_CHART_FULL color definitions

export const CHART_BLUE_COLOR_SAME = {
  backgroundColor: BLUE_COLOR.full,
  borderColor: BLUE_COLOR.full
};

export const CHART_CORAL_COLOR_SAME = {
  backgroundColor: CORAL_COLOR.full,
  borderColor: CORAL_COLOR.full
};

export const CHART_TEAL_COLOR_SAME = {
  backgroundColor: TEAL_COLOR.full,
  borderColor: TEAL_COLOR.full
};

export const CHART_ORANGE_COLOR_SAME = {
  backgroundColor: ORANGE_COLOR.full,
  borderColor: ORANGE_COLOR.full
};

export const CHART_PURPLE_COLOR_SAME = {
  backgroundColor: PURPLE_COLOR.full,
  borderColor: PURPLE_COLOR.full
};

export const CHART_YELLOW_COLOR_SAME = {
  backgroundColor: YELLOW_COLOR.full,
  borderColor: YELLOW_COLOR.full
};

export const CHART_BROWN_COLOR_SAME = {
  backgroundColor: BROWN_COLOR.full,
  borderColor: BROWN_COLOR.full
};

export const CHART_PINK_COLOR_SAME = {
  backgroundColor: PINK_COLOR.full,
  borderColor: PINK_COLOR.full
};

export const CHART_TAUPE_COLOR_SAME = {
  backgroundColor: TAUPE_COLOR.full,
  borderColor: TAUPE_COLOR.full
};

export const CHART_BLUE_PASTEL_COLOR_SAME = {
  backgroundColor: BLUE_PASTEL_COLOR.full,
  borderColor: BLUE_PASTEL_COLOR.full,
};

export const CHART_ORANGE_PASTEL_COLOR_SAME = {
  backgroundColor: ORANGE_PASTEL_COLOR.full,
  borderColor: ORANGE_PASTEL_COLOR.full,
};

// COLORS_CHART_FULL array definition

export const COLORS_CHART_FULL = [
  CHART_BLUE_COLOR_SAME,
  CHART_CORAL_COLOR_SAME,
  CHART_TEAL_COLOR_SAME,
  CHART_ORANGE_COLOR_SAME,
  CHART_PURPLE_COLOR_SAME,
  CHART_YELLOW_COLOR_SAME,
  CHART_BROWN_COLOR_SAME,
  CHART_PINK_COLOR_SAME,
  CHART_TAUPE_COLOR_SAME
];

// other definitions

export const COLORS_CHART_DOUGHNUT = [
  BLUE_COLOR.full,
  CORAL_COLOR.full,
  TEAL_COLOR.full,
  ORANGE_COLOR.full,
  PURPLE_COLOR.full,
  YELLOW_COLOR.full,
  BROWN_COLOR.full,
  PINK_COLOR.full,
  TAUPE_COLOR.full
];

export const BORDERS_CHART_DOUGHNUT = [
  BLUE_COLOR.full,
  CORAL_COLOR.full,
  TEAL_COLOR.full,
  ORANGE_COLOR.full,
  PURPLE_COLOR.full,
  YELLOW_COLOR.full,
  BROWN_COLOR.full,
  PINK_COLOR.full,
  TAUPE_COLOR.full
];

export const COLORS_CHART_DOUGHNUT_REVENUE = [
  YELLOW_COLOR.full, 
  PURPLE_COLOR.full
];

export const BORDERS_CHART_DOUGHNUT_REVENUE = [
  YELLOW_COLOR.full, 
  PURPLE_COLOR.full
];

export const COLORS_CHART_PROGRESS_REVENUE = [
  YELLOW_COLOR.opacity, 
  PURPLE_COLOR.opacity
];

export const FULL_PASTEL_COLORS_CHARTS = [
  CHART_BLUE_PASTEL_COLOR,
  CHART_ORANGE_PASTEL_COLOR,
];


