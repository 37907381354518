import AccountReport from "../../services/account-report";
import {firestore} from "firebase/app";
export interface Protocol {
  _id?:string,
  id?: string,
  name: string,
  withComment: boolean,
  withOutComment: boolean,
  triggers: { delay: string, keywords: string[], excludeKeywords: string[] }
  stars: number[],
  response: string[]
  accountId: string,
  gid: string,
  uid,
  accounts: AccountReport[];
  status: { replied: number, total: number },
  enabled: boolean,
  retroactive: boolean,
  createdAt: firestore.Timestamp,
  scan: boolean;
  protocolId: string
}

export const SAVE_COMPLETE_SCAN = 'SAVE SCAN';
export const SAVE_COMPLETE_PROTOCOL = 'SAVE PROTOCOL';
export const SAVE_COMPLETE_EDIT_SCAN = 'EDIT SCAN';
export const SAVE_COMPLETE_EDIT_PROTOCOL = 'EDIT PROTOCOL';


export interface ProtocolTable {
  id: number;
  name: {
    name: string;
    locationCount: number;
  };
  status: {
    replied: number;
    total: number;
  };
  triggers: {
    delay: string;
    keywords: string;
  };
  enable: boolean;
  retroactive: string;
  actions: string;
}
