import { Injectable } from '@angular/core';
import * as uuid from 'uuid';
@Injectable({
  providedIn: 'root'
})
export class UuidService {

  constructor() { }

  get uuidRandom(){
    return uuid.v4();
  }
}
