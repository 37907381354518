import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {ModalProductsCollectionComponent} from '../modal-products-collection/modal-products-collection.component';
import {ModalProductsCollectionEditComponent} from '../modal-products-collection-edit/modal-products-collection-edit.component';
import {ModalProductsItemComponent} from '../modal-products-item/modal-products-item.component';
import {ModalProductsItemEditComponent} from '../modal-products-item-edit/modal-products-item-edit.component';
import {Product} from '../../constants/firestore/product';
import {UuidService} from '../../services/uuid.service';
import {PriceList} from '../../constants/google/price-list-section';
import { LocationEditService } from 'src/app/services/location-edit.service';

export const PRODUCT_DATA: Product[] = [];

@Component({
  selector: 'app-modal-products',
  templateUrl: './modal-products.component.html',
  styleUrls: ['./modal-products.component.scss']
})
export class ModalProductsComponent implements OnInit {

  priceLists: any;
  productsIndex: any;
  touchEdit: boolean = false;
  @Output() updateProduct: EventEmitter<any> = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<ModalProductsComponent>,
    public dialog: MatDialog,
    private uuid: UuidService,
    private locationEditS: LocationEditService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.priceLists = this.filterProducts(this.data.priceList);
    
  }


  ngOnInit() {

  }

  filterProducts(priceLists: PriceList[]) {

    let result = [];
    if (priceLists) {
      result = priceLists.filter(
        (p, index) => {

          if (p.labels.find( la => la.displayName === 'Products')) {
            this.productsIndex = index;
          }

          return p.labels.find( la => la.displayName === 'Products');
        }
      )[0]?.sections;
    } else {
      result = [
        {
          priceListId: this.uuid.uuidRandom,
          labels: [{displayName: 'Products'}],
          sections: []
        }
      ];
    }

    return result;

  }

  addSectionProduct() {
    const dialogRef = this.dialog.open(ModalProductsCollectionComponent, {
      width: '680px',
      panelClass: 'overflow--visible',
      data: {
        products: this.priceLists,
        productsIndex: this.productsIndex
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.touchEdit = true;
        this.priceLists.push({
          sectionId: result.id,
          labels: [{
            displayName: result.productCollectionName,
            description: result.productCollectionDesc,
          }],
          items: []
        });
      }
    });
  }

  editProduct(i) {
    const dialogRef = this.dialog.open(ModalProductsCollectionEditComponent, {
      width: '680px',
      panelClass: 'overflow--visible',
      data: {
        productName: this.priceLists[i].labels[0].displayName,
        productDescription: this.priceLists[i].labels[0].description
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.touchEdit = true;
        this.priceLists[i].labels[0] = {displayName: result.productName, description:  result.productDescription};
      }
    });
  }

  addProductItem(i) {
    const dialogRef = this.dialog.open(ModalProductsItemComponent, {
      width: '680px',
      panelClass: 'overflow--visible',
      data: {
        product: this.priceLists[i]
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.touchEdit = true;
        this.priceLists[i].items.push(result.productItem);
        
      }
    });
  }

  editProductItem(i, j) {
    const dialogRef = this.dialog.open(ModalProductsItemEditComponent, {
      width: '680px',
      panelClass: 'overflow--visible',
      data: {
        productIndex: i,
        itemIndex: j,
        productItem: this.priceLists[i].items[j]
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.touchEdit = true;
        if (!result.removeItem) {
          this.priceLists[i].items[j] = result.productItem;
        } else {
          this.priceLists[i].items.splice(j, 1);
        }
      }
    });
  }

  apply(): void {
    const editPriceList = this.data.priceList;
    editPriceList[this.productsIndex].sections = this.priceLists;
    const edit = this.locationEditS.locationEdit;
    edit.priceLists = editPriceList;
    this.locationEditS.setAndUpdate().toPromise().then();
    this.dialogRef.close();
  }

}
