<app-dashboard-filter [progress]="false" [accountId]="accountId" typeDashboard="widget"></app-dashboard-filter>

<div class="row" style="margin-top: 65px;">
  <!--Reviews-->
  <div class="col-12 col-md-6 box--height">
    <div class="box box--rounded box--shadow bg--white">
      <div class="box__header">
        <h2 class="txt--capitalize txt--black m--0">
          <strong>Reviews</strong>
        </h2>
      </div>
      <form *ngIf='form'>
        <div class="box__content box--padding-xxs">
          <!--checkbox rating buttons-->
          <div class="row gutters-sm align-items-center mb--20">
            <div class="col-12 col-lg-9">
              <div
                class="checkbox-group d-flex justify-content-start align-items-center"
              >
                <ng-container
                  *ngFor="let number of [5, 4, 3, 2, 1]; index as i"
                >
                  <mat-checkbox
                    class="d-flex align-items-center checkbox--star checkbox--star-sm"
                    [checked]="selectedStarts.includes(number)"
                    (change)="handleStarts($event, number)"
                  >
                    {{ number }}
                    <img
                      src="/assets/images/star-full.png"
                      alt="Star full"
                      width="22"
                      height="20"
                    />
                  </mat-checkbox>
                </ng-container>
              </div>
            </div>
            <div class="col-12 col-lg-3">
              <button
                mat-flat-button
                color="accent"
                (click)="submit()"
                class="btn btn--action btn--full"
              >
                Apply
              </button>
            </div>
          </div>

          <!--sort by-->
          <mat-form-field class="input-group input-group--select">
            <mat-label class="txt--uppercase">Sort By</mat-label>
            <mat-select [formControl]="order" class="input-group__select" >
              <mat-option value="createTime">Newest</mat-option>
              <mat-option value="starRatingNumber">Highest Rating</mat-option>
            </mat-select>
          </mat-form-field>
          <!--sort by-->
          <mat-form-field class="input-group input-group--select">
            <mat-label class="txt--uppercase">Show Responses</mat-label>
            <mat-select [formControl]="responses" class="input-group__select">
              <mat-option [value]="false">No</mat-option>
              <mat-option [value]="true">Yes</mat-option>
            </mat-select>
          </mat-form-field>

          <!--background color-->
          <h3 class="mb--10 txt--black"><strong>Background Color</strong></h3>
          <app-color-date-picker
            [color]="this.backgroundColor"
            (selected)="selectedBackground($event)"
            (cleared)="clearedBackground()"
            [masterReset]="masterReset"
          ></app-color-date-picker>

          <!--button color-->
          <h3 class="mb--10 txt--black"><strong>Button Color</strong></h3>
          <app-color-date-picker
            [color]="this.buttonColor"
            (selected)="selectedButton($event)"
            (cleared)="clearedButtonColor()"
            [masterReset]="masterReset"
          ></app-color-date-picker>

          <!--button text color-->
          <h3 class="mb--10 txt--black"><strong>Button Text Color</strong></h3>
          <app-color-date-picker
            [color]="this.buttonTextColor"
            (selected)="selectedButtonText($event)"
            (cleared)="clearedButtonText()"
            [masterReset]="masterReset"
          ></app-color-date-picker>

          <!--text color-->
          <h3 class="mb--10 txt--black"><strong>Text Color</strong></h3>
          <app-color-date-picker
            [color]="this.textColor"
            (selected)="selectedText($event)"
            (cleared)="clearedText()"
            [masterReset]="masterReset"
          ></app-color-date-picker>

          <!--width and measurement-->
          <h3 class="mb--10 txt--black"><strong>Width</strong></h3>
          <div class="row gutters-sm mb--60">
            <div class="col-12 col-lg-6">
              <div class="d-flex">
                <div class="input-group flex-grow-1">
                  <input
                    type="text"
                    [formControl]="width"
                    class="input-group__field input-group__field--sm"
                  />
                </div>

                <mat-form-field
                  class="input-group input-group--select input-group--select-sm"
                >
                  <mat-select
                    class="input-group__select"
                    [formControl]="measure"
                  >
                    <mat-option value="px">px</mat-option>
                    <mat-option value="%">%</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="alternate-theme input--width">
                <button
                  color="primary"
                  mat-flat-button
                  class="btn btn--full btn--action"
                  (click)="resetOptions()"
                >
                  Reset to Default
                </button>
              </div>
            </div>
          </div>

          <h3 class="txt--black mb--10"><strong>Widget Code</strong></h3>

          <div class="input-group">
            <textarea
              class="input-group__field widget"
              readonly
              #iframeContent
              >{{ frameSource }}</textarea
            >
          </div>
          <div class="d-flex justify-content-end">
              <button
                  color="primary"
                  mat-flat-button
                  class="btn btn--md mr--20"
                  (click)="copyIframe(iframeContent)"
              >Copy Widget Code
              </button>
              <button
                  mat-flat-button
                  color="accent"
                  (click)="save()"
                  class="btn btn--action btn--md"
              >Save Widget
              </button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <!--Reviews info-->
  <div class="col-12 col-md-6 box--height" *ngIf='form'>
    <app-widget
      [backColor]="backgroundColor"
      [btnColor]="buttonColor"
      [btnTextColor]="buttonTextColor"
      [textColorAll]="textColor"
      [orderI]="order"
      [startsI]="joinStars"
      [responsesI]="responses"
      [events]="eventsSubject?.asObservable()"
    ></app-widget>
  </div>
</div>
