import {Component, Input, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {environment} from "@environment";
import {WhiteLabelService} from '../../services/white-label.service';

import * as _ from 'lodash';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() customPosition = false;
  date: Date = new Date();
  footerDefault: boolean;
  env;

  constructor(
    private router: Router,
    public wl: WhiteLabelService) {
    router.events
      .filter((event) => event instanceof NavigationEnd)
      .subscribe((event: NavigationEnd) => {
        const url = this.router.url;
        this.footerDefault = !((url.includes('login') || url.includes('register') || url.includes('forgot-password')));
      });
  }

  ngOnInit() {
    this.env = environment;
    const url = this.router.url;
    this.footerDefault = !((url.includes('login') || url.includes('register') || url.includes('forgot-password')));
  }

  goToTermsOfService(): void {
    this.wl.goToTermsOfService()
  }

  goToPrivacyPolicy(): void {
    this.wl.goToPrivacyPolicy()
  }

  goToPaymentPolicy(): void {
    this.wl.goToPaymentPolicy()
  }

  handleContactUs() {
    this.wl.goToContactSupport()
  }

}
