import {Component, EventEmitter, Input, OnInit, Output, AfterViewInit, Renderer2, ElementRef} from '@angular/core';
import {DataPicker} from '../../constants/data-picker';
import { Moment } from 'moment';
import { DATE_PICKER_RANGES } from '../../constants/datePicker';
import * as moment from 'moment-timezone';
import {EventMessageService} from "../../services/event-message.service";
import { ReportService } from 'src/app/services/report.service';
import { DatesService } from 'src/app/services/dates.service';

export interface SelectedDatePicker {
  start: Moment;
  end: Moment;
}

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit, AfterViewInit {
  id: string = '';
  @Output() dateSelected = new EventEmitter();

  @Input() reportType: string;
  @Input() dataPicker: DataPicker;
  @Input() rangeB: boolean;
  @Input() disabled: boolean;
  @Input() isForm: boolean;
  @Input() select: SelectedDatePicker;
  @Input() dropsChange: string;
  @Input() minRange: boolean;
  @Input() maxDate;
  @Input() minDateRange;
  @Input() subscribeToDatepicker = false
  @Input() showTooltipsOnDisabledDates = false;
  @Input() isReadOnly = false;
  @Input() hasDateLimitsInfo = false;

  ranges = DATE_PICKER_RANGES;
  opens = 'left';
  drops = 'down';
  alwaysShowCalendars = true;
  keepCalendarOpeningWithRange = true;
  locale: any = {
    applyLabel: 'Apply',
    format: 'MM/DD/YYYY',
    cancelLabel: 'Cancel',
    customRangeLabel: 'Custom Range',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: 0, // first day is sunday
  };
  isClosed = true;
  events: string[] = [];
  private tooltipsApplied = false;
  public isOpened = false;

  constructor(
    public em: EventMessageService,
    private reportS: ReportService,
    private renderer: Renderer2,
    private el: ElementRef,
    private dateS: DatesService
    ) {}

  ngOnInit() {
    if (this.rangeB) this.id = 'B'
    this.drops = (this.dropsChange) ? this.dropsChange : 'down';
    if (this.minRange) {
      const months = this.reportType == 'performance-rollup' || this.reportType == 'review' ? 13 : 12;
      const minDate = moment().subtract(moment.duration({'months': months}));
      this.minDateRange = this.minDateRange && this.minDateRange.isAfter(minDate) ? this.minDateRange : minDate;
    }

    if (this.disabled) {
      this.dateSelected.emit(this.dataPicker);
    }

    this.setRanges();
  }

  applyTooltips() {
    if (!this.tooltipsApplied) {
      const elementsWithTooltip = document.querySelectorAll('.off.disabled');

      if (elementsWithTooltip?.length && this.showTooltipsOnDisabledDates) {
        const date = this.maxDate?.set({'hours': 23, 'minute': 59, 'second': 59});
        elementsWithTooltip.forEach(element => {
          element.setAttribute(
            'title',
            `Google Business Profile performance data is latent for up to 7 days from today's date. Please choose a date before ${date.format('MM/DD/YYYY')} to see the most recent performance data for your business profile.`
            );
        });

        this.tooltipsApplied = true;
      }
    }
  }

  addDateLimitsInfo() {
    const elemento = document.getElementsByClassName('limits-info-txt')[0];
    const buttonsInput = this.el.nativeElement.querySelector('.buttons_input');
    if (elemento) {
      this.renderer.removeChild(elemento.parentNode, elemento);
    }

    if(this.minDateRange && this.maxDate && buttonsInput) {
      let minDate = this.dateS.getStringDate(this.minDateRange) || '';
      let maxDate = this.dateS.getStringDate(this.maxDate) || '';
      const txt = this.renderer.createText(`Please select a date from ${minDate} to ${maxDate}`);
      const span = this.renderer.createElement('span');

      this.renderer.addClass(span, 'limits-info-txt');
      this.renderer.appendChild(span, txt);
      this.renderer.insertBefore(buttonsInput, span, buttonsInput.children[1]);
    }
  }

  ngAfterViewInit() {
    this.applyTooltips();
  }
  

  rangeClicked(range) {
    if (this.rangeB) {
      this.dataPicker.rangeB = {start: range.startDate, end: range.endDate};
      return;
    }

    this.dataPicker.range = {start: range.startDate, end: range.endDate};

  }

  handleClick($event) {
    this.isClosed = false
    if (this.hasDateLimitsInfo) {
      this.addDateLimitsInfo();
    }

    if (this.minRange) {
      this.em.emitMessageEvent("DATEPICKER SUBSCRIPTION", true);
    }

    if (!this.minRange && this.subscribeToDatepicker) {
      this.em.emitMessageEvent("DATEPICKER FIRST TIME SUBSCRIPTION", true);
    }

    if (!this.isOpened) {
      this.isOpened = true;
      this.setRanges();

      if (!this.tooltipsApplied) {
        setTimeout(() => {
          this.applyTooltips();
        }, 500);
      }
    }
  }

  setRanges() {
    if (this.reportType == 'performance-rollup' || this.reportType == 'performance-comparison') {
      this.ranges = {
        'Last 7 Days': [this.reportS.getDates('Last7', 'performance-rollup').startDatetime, this.reportS.getDates('Last7', 'performance-rollup').endDatetime],
        'Last 30 Days': [this.reportS.getDates('Last30', 'performance-rollup').startDatetime, this.reportS.getDates('Last30', 'performance-rollup').endDatetime],
        'Last 90 Days': [this.reportS.getDates('Last90', 'performance-rollup').startDatetime, this.reportS.getDates('Last90', 'performance-rollup').endDatetime],
        'This Month': [this.reportS.getDates('ThisMonth', 'performance-rollup').startDatetime, this.reportS.getDates('ThisMonth', 'performance-rollup').endDatetime],
        'Last Month': [this.reportS.getDates('LastMonth', 'performance-rollup').startDatetime, this.reportS.getDates('LastMonth', 'performance-rollup').endDatetime],
        'Last Year': [this.reportS.getDates('LastYear', 'performance-rollup').startDatetime, this.reportS.getDates('LastYear', 'performance-rollup').endDatetime],
        YTD: [this.reportS.getDates('YTD', 'performance-rollup').startDatetime, this.reportS.getDates('YTD', 'performance-rollup').endDatetime]
      }
    };
  }

  datesUpdated($event) {
    if ($event.startDate) {
      if (this.rangeB) {
        this.dataPicker.rangeB = {
          start: $event.startDate.utc(true).toISOString(),
          end: $event.endDate.utc(true).toISOString()
        };
      } else {
        this.dataPicker.range = {
          start: $event.startDate.utc(true).toISOString(),
          end: $event.endDate.utc(true).toISOString()
        };
      }
    }
    this.dateSelected.emit(this.dataPicker);
  }

  datesChange($event) {
    if ($event.start) {
      if (this.rangeB) {
        this.dataPicker.rangeB = {
          start: $event.start.utc(true).toISOString(),
          end: $event.end.utc(true).toISOString()
        };
      } else {
        this.dataPicker.range = {
          start: $event.start.utc(true).toISOString(),
          end: $event.end.utc(true).toISOString()
        };
      }
    }

    this.dateSelected.emit(this.dataPicker);
  }

  changeDateWrite(data: string, $event) {
    if($event.keyCode === 13){
      const arraydate = data.split('-');
      if (arraydate.length > 1) {
        const dateStart = moment(arraydate[0].trim());
        const dateEnd = moment(arraydate[1].trim());
        if (!dateStart && !dateEnd) return;
        const start = !this.minDateRange ? dateStart : dateStart.isAfter(this.minDateRange) ? dateStart : this.minDateRange;
        const end = !this.maxDate ? dateEnd : dateEnd.isBefore(this.maxDate) ? dateEnd : this.maxDate;

        this.select = {
          start: start || dateStart,
          end: end || dateEnd
        }
        this.datesUpdated({
          startDate: start || dateStart,
          endDate: end || dateEnd
        })
      }
    }
  }

  getDates(d1, d2) {
    d1 = moment(d1).utc().format(this.locale.format);
    d2 = moment(d2).utc().format(this.locale.format);

    return `${d1} - ${d2}`;
  }

}
