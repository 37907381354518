import { Component, Input, OnDestroy, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import { Subscription, Subject } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';

import { ReviewsService } from '../../services/reviews.service';
import { ChartDataSets, ChartPoint } from 'chart.js';
import { DataPicker } from '../../constants/data-picker';
import { takeUntil } from 'rxjs/operators';
import { LoadingService } from '../../services/loading.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-reviews-number',
  templateUrl: './reviews-number.component.html',
  styleUrls: ['./reviews-number.component.scss']
})
export class ReviewsNumberComponent implements OnInit, OnChanges , OnDestroy {
  @Input() locations;
  @Input() dataPicker: DataPicker;
  @Input() refreshFilter;
  @Input() reviewsData;
  @Input() tooltip = null;

  public aggregationNumber$: Subscription;
  public isProgressNumber = true;
  public datasetBarNumber: ChartDataSets[];
  public labelsBarRating: any;
  public total: any;

  public destroySubs$: Subject<boolean> = new Subject();

  constructor(
    private _reviewsS: ReviewsService,
    private _loadingService: LoadingService,
    private _router: Router,
    private _auth: AuthService
  ) {
  }

  ngOnInit() {
    this.refreshFilter?.pipe(
      takeUntil(this.destroySubs$)
    ).subscribe(result => {
      this.isProgressNumber = true;
      this.dataPicker = result;
      this.getData();
    });
    this.getData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.reviewsData) {
      this.getData();
    }
  }

  getData() {
    const isReport = this._router.url.includes('report');
    
    if (this.reviewsData) {
      this.buildData(this.reviewsData);
    } else {
      if (isReport) {
        this.aggregationNumber$ = this._reviewsS.aggregationNumber(this.locations, this.dataPicker)
      .pipe(
        takeUntil(this.destroySubs$)
      )
      .subscribe(result => {
        this.buildData(result);
      });

      } else {
        this.aggregationNumber$ = this._reviewsS.getRatingsAndReviewsData(this.locations[0].accountId, this._auth.session.gid, this.locations[0].locationId, this.dataPicker)
        .pipe(takeUntil(this.destroySubs$)).subscribe(result => {
          this.buildData({
            data: result.reviews,
            labels: result.dates,
            total: result.total_reviews
          });
        });
      }
    }
  }

  buildData(result) {
    // Remove extra days
    if (this.dataPicker.aggregation === 'Day') {
      const start = moment(this.dataPicker.range.start);
      const end = moment(this.dataPicker.range.end);
      const days = end.diff(start, 'days') + 1;
      const dropValues = result.labels?.length - days;
      result['data'] = _.dropRight(result.data, dropValues);
      result['labels'] = _.dropRight(result.labels, dropValues);
    }

    this.datasetBarNumber = [{data: result.data, label: 'Number'}];
    this.labelsBarRating = result.labels;

    if (result?.total) {
      this.total = result.total;
    } else {
      this.total = this.getTotal(this.datasetBarNumber[0].data);
    }
    this.isProgressNumber = false;
    this._loadingService.reportAdvanced(1, 'Number Reviews')
  }

  /**
   * this method get total sum of a array
   */
  getTotal(array: number[] | ChartPoint[]) {
    let total = 0;
    array?.forEach(x => {
      total += x;
    });
    return total;
  }


  ngOnDestroy(): void {
    this.destroySubs$.next(true);
    this.destroySubs$?.unsubscribe();
  }

}
