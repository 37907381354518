import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-grade-explain-article',
  templateUrl: './grade-explain-article.component.html',
  styleUrls: ['./grade-explain-article.component.scss']
})
export class GradeExplainArticleComponent implements OnInit {
  @Input() title: any;
  @Input() add: number = 1;
  @Input() sub: number = 0;
  @Input() customLabel: string = "Yes";

  constructor() { }

  ngOnInit() {
  }

}
