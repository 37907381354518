// dep
import { Observable, Subject } from "rxjs"

/**
 * Returns if the current page is running inside an iframe
 * Used for https://www.theflightschoolinc.com/reviews (see MAP-2017, MAP-2030)
 */
export function isRunningEmbedded() : boolean {
    return (window.location !== window.parent.location)
}


export type PromisedObservable<T> = Observable<T> & {getValue : () => Promise<T>,
                                                     destroy  : () => void }

/**
 * Given an RXJS Subject, returns an Observer augmented with two new methods:
 *   - getValue() - returns a the latest value emmited by the Subject wrapped in a dummy
 *                  Promise or returns a Promise that resolves when the Subject emits
 *                  a value (rejected when the Subject complete()'s)
 *   - destroy()  - cancels the subscription and rejects the Promise returned by getValue(),
 *                  is necesary to call this method to dispose the Subject subscription and
 *                  avoid memory leaks.
 * 
 * Differences with Observable.toPromise()
 *   - Observable.toPromise() requires the Observable to be complete()'ed to
 *     resolve.
 *   - This resolves to multiple values, Observable.toPromise() resolves to
 *     a single value
 *   - If the Observable is .completed() without emmiting a single value,
 *     then Observable.toPromise() resolves to 'undefined' (the signature is
 *     wrong on RXJS 6, but solved on RXJS 7). This excepts. 
 * 
 * TODO: Some usages can be replaced by firstValueFrom when upgrading RXJS from 6 to 7
 * (which requires TS 4.2, but we cannot upgrade to that TS version given our
 *  current Angular 8.3.4 version)
 */
export function asPromisedObservable<T>(upstreamSubject : Subject<T>, opts : {injectVal? : T} = {}) : PromisedObservable<T> {
     
       // Downstream
       // private _subject = new Subject<T>()

       let _promise : Promise<T> | null = null
       let _promiseResolve : (t : T) => void
       let _promiseReject  : (reason? : any) => void

       const _createPromise = () => {
            _promise = new Promise<T>((resolve, reject) => {
                _promiseResolve = resolve
                _promiseReject  = reject
            })
            // Avoid the "Uncaught (in promise)" error log
            _promise.catch(()=>{ /** empty on purpose **/ })
        }

       let _hasLastValue = false
       let _lastValue : T 

       const _onNewValue = (v : T) => {
            _lastValue = v
            _hasLastValue = true
            const _promiseResolvePrev = _promiseResolve
            _createPromise()
            _promiseResolvePrev(v)
            // _subject.next(v)
        }

        const destroy = (err : any = "PromisedObservable destroyed") : void => {
            if(!_promise)
                return
    
            _promise = null
            _upstreamSubscription.unsubscribe()
            // __subject.complete()
            _promiseReject(err)
        }

        // init
        _createPromise()
        const _upstreamSubscription = upstreamSubject.subscribe({
                next     : _onNewValue,
                error    : destroy,
                complete : () => destroy("PromisedObservable completed")
            })
            
        const getValue = () : Promise<T> => {
            if(_hasLastValue)
                return Promise.resolve(_lastValue)
                
            if(!_promise)
                throw Error('PromisedObservable already completed/destroyed')
    
            return _promise
        }
        
        const o = upstreamSubject.asObservable()
        if('destroy' in o || 'getValue' in o)
            // maybe added in newer RXJS versions
            throw Error('Patching Observable will override existing attrs')
        o['destroy']  = destroy
        o['getValue'] = getValue


        if('injectVal' in opts)
            upstreamSubject.next(opts.injectVal)

        return o as any        
    }

/**
 * A promise that exposes his resolve() and reject() functions so it can
 * be resolved/rejected by injecting values from the outside.
 */
export type OpenPromise<T> = Promise<T> & {resolve : (v : T | PromiseLike<T>) => void,
                                           reject  : (reason? : any) => void } 

export function makeOpenPromise<T>() : OpenPromise<T> {
    let p_resolve : OpenPromise<T>['resolve']
    let p_reject  : OpenPromise<T>['reject']

    const p = new Promise<T>((resolve, reject) => {
        p_resolve = resolve
        p_reject  = reject
    }) as OpenPromise<T>

    p.resolve = p_resolve
    p.reject  = p_reject
    return p
}
