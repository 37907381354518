<div class="dialog__header">
  <h2 mat-dialog-title class="m--0 txt--xl"><strong>{{!isEdit ? 'Add ' : 'Edit '}} Section</strong></h2>
</div>

<form [formGroup]="form">
  <div class="dialog__content" mat-dialog-content>

    <div class="dialog-row pb--0">
      <mat-form-field class="input-field">
        <input matInput placeholder="Section Name" [formControl]="name">
        <mat-error *ngIf="name.hasError('required')">The name is required</mat-error>
      </mat-form-field>
    </div>

  </div>
  <div mat-dialog-actions class="dialog__footer">
    <span class="alternate-theme d-inline-block mr--10">
        <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
    </span>
    <div>
      <button mat-flat-button (click)="remove()" tabIndex="-1" color="primary" class="btn btn--action mr--10" *ngIf="isEdit">Remove</button>
      <button mat-flat-button (click)="apply()" tabIndex="-1" color="accent" class="btn btn--action">Apply</button>
    </div>
  </div>
</form>

