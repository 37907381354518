import { HttpClient } from '@angular/common/http';
import { environment as ENV } from '@environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ProfilesService = /** @class */ (function () {
    function ProfilesService(http) {
        this.http = http;
    }
    ProfilesService.prototype.createProfile = function (request) {
        return this.http.post(ENV.coreApiUrl + "/profiles", request);
    };
    ProfilesService.prototype.getProfiles = function (request) {
        return this.http.post(ENV.coreApiUrl + "/profiles/list", request);
    };
    ProfilesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProfilesService_Factory() { return new ProfilesService(i0.ɵɵinject(i1.HttpClient)); }, token: ProfilesService, providedIn: "root" });
    return ProfilesService;
}());
export { ProfilesService };
