<div class="dialog__header">
  <h2 mat-dialog-title class="m--0 txt--xl"><strong>More hours</strong></h2>
  <p class="m--0 txt--white">You can add hours for specific services or specials to your business on Google</p>
</div>

<div class="dialog__content" mat-dialog-content>
  <div class="d-flex mb--25">
    <mat-chip-list class="chip--sm">
      <mat-chip color="primary" class="w--auto" *ngFor="let label of labels" (click)="selectLabel(label)">
        <span class="m--4 txt--md txt--lightblue">+</span> {{label?.displayName}}
      </mat-chip>
    </mat-chip-list>
  </div>

  <div *ngIf="moreHours.length && !loading">
    <h3 class="mb--5">Your business hours</h3>
    <ng-container *ngFor="let item of moreHours">
      <div class="col-4 period-name">
        <p class="txt--uppercase"><strong>{{ item.displayName }}</strong></p>
      </div>

      <div class="col-6 period-info">
        <ng-container *ngFor="let day of item.periodsByDay | keyvalue: originalOrder">
          <ng-container *ngFor="let hours of day.value; let i = index;">
            <ng-container *ngIf="hours?.open">
              <div class="col-4">
                <p *ngIf="i === 0" class="txt--uppercase"><strong>{{ day.key }}</strong></p>
              </div>
              <div class="col-8">
                <p class="txt--uppercase">
                  <span><strong>{{ hours?.openTime}} - {{ hours?.closeTime}}</strong></span>
                  <ng-template #allDay>
                    <strong>{{ hours?.startTime }}</strong>
                  </ng-template>
                </p>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>

      <button mat-button (click)="openHoursDialog(item)" class="col-2 btn btn-icon btn-icon--gray edit-hours"><em
        class="fas fa-pencil"></em>
      </button>
    </ng-container>
  </div>
</div>

<div mat-dialog-actions class="dialog__footer" *ngIf="!bulk">
  <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
  <button mat-flat-button (click)="apply()"  tabIndex="-1" color="accent" class="btn btn--action">Apply</button>
</div>
