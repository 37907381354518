<div class="dialog__header">
    <h2 mat-dialog-title class="m--0 txt--xl"><strong>{{!isEdit ? 'Add ' : 'Edit '}} Menu</strong></h2>
</div>

<form [formGroup]="form">
    <div class="dialog__content" mat-dialog-content>

        <div class="dialog-row pb--0" [formGroupName]="'labels'" style="margin: 15px 0;">
            <mat-form-field class="input-field">
                <input matInput placeholder="Menu Name" formControlName="displayName">
                <mat-hint align="end">{{form.get('labels.displayName').value.length}} / 140</mat-hint>
                <mat-error *ngIf="form.get('labels.displayName').hasError('required')">The name is required</mat-error>
            </mat-form-field>

            <mat-form-field class="input-field">
                <textarea matInput placeholder="Description" formControlName="description" name="itemDescription" rows="3"></textarea>
                <mat-hint align="end">{{form.get('labels.description').value.length}} / 1,000</mat-hint>
            </mat-form-field>
        </div>

        <div class="dialog-row pb--0" style="margin: 15px 0;">
            <mat-form-field class="input-field">
                <mat-label>Cusines</mat-label>
                <mat-select formControlName="cuisines" multiple>
                  <mat-option *ngFor="let Cusine of Cusines" [value]="Cusine.key">{{Cusine.description}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div mat-dialog-actions class="dialog__footer">
        <span class="alternate-theme d-inline-block mr--10">
            <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
        </span>
        <div>
          <button mat-flat-button (click)="apply()" tabIndex="-1" color="accent" class="btn btn--action">Apply</button>
        </div>
    </div>
</form>
