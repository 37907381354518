import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'attributtesFetch'
})
export class AttributtesFetchPipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    value = value.replace('is_', '');
    value = value.replace('has_', '');
    return value.replace(/_/g, ' ');
  }

}
