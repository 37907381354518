/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./paginator.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/form-field";
import * as i3 from "../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i4 from "@angular/material/progress-spinner";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/common";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "@angular/forms";
import * as i9 from "../../../../../node_modules/@angular/material/paginator/typings/index.ngfactory";
import * as i10 from "@angular/material/paginator";
import * as i11 from "./paginator.component";
var styles_PaginatorComponent = [i0.styles];
var RenderType_PaginatorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaginatorComponent, data: {} });
export { RenderType_PaginatorComponent as RenderType_PaginatorComponent };
function View_PaginatorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "mat-error", [["class", "mat-error--absolute mat-error"], ["role", "alert"]], [[1, "id", 0]], null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatError, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Enter valid number"]))], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); }); }
function View_PaginatorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "mat-error", [["class", "mat-error--absolute mat-error"], ["role", "alert"], ["style", "position: absolute; width: max-content;"]], [[1, "id", 0]], null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatError, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["The last available page is ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.pagination.pages; _ck(_v, 3, 0, currVal_1); }); }
function View_PaginatorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i3.View_MatSpinner_0, i3.RenderType_MatSpinner)), i1.ɵdid(1, 114688, null, 0, i4.MatSpinner, [i1.ElementRef, i5.Platform, [2, i6.DOCUMENT], [2, i7.ANIMATION_MODULE_TYPE], i4.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null)], function (_ck, _v) { var currVal_3 = 20; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_PaginatorComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { paginator: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 22, "div", [["class", "mat-row mat-row--padding-md bg--white d-flex justify-content-start align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "pagination"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "pagination__item"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPrev() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fas fa-angle-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "pagination__item"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNext() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "fas fa-angle-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 12, "div", [["class", "input-field input-field--pagination"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Go to:"])), (_l()(), i1.ɵeld(10, 0, null, null, 5, "input", [["matInput", ""], ["min", "1"], ["type", "text"]], [[8, "max", 0], [8, "readOnly", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "keydown"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 11)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 11)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.pagination.page = $event) !== false);
        ad = (pd_4 && ad);
    } if (("input" === en)) {
        var pd_5 = (_co.validate($event.target.value) !== false);
        ad = (pd_5 && ad);
    } if (("blur" === en)) {
        var pd_6 = (_co.updateManualPage($event) !== false);
        ad = (pd_6 && ad);
    } if (("keydown" === en)) {
        var pd_7 = (_co.updateManualPage($event) !== false);
        ad = (pd_7 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 16384, null, 0, i8.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i8.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i8.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i8.DefaultValueAccessor]), i1.ɵdid(13, 671744, null, 0, i8.NgModel, [[8, null], [8, null], [8, null], [6, i8.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i8.NgControl, null, [i8.NgModel]), i1.ɵdid(15, 16384, null, 0, i8.NgControlStatus, [[4, i8.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaginatorComponent_1)), i1.ɵdid(17, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaginatorComponent_2)), i1.ɵdid(19, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(20, 0, null, null, 1, "mat-paginator", [["class", "mat-paginator"]], null, [[null, "page"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("page" === en)) {
        var pd_0 = (_co.handlePage($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_MatPaginator_0, i9.RenderType_MatPaginator)), i1.ɵdid(21, 245760, [[1, 4]], 0, i10.MatPaginator, [i10.MatPaginatorIntl, i1.ChangeDetectorRef], { pageIndex: [0, "pageIndex"], length: [1, "length"], pageSize: [2, "pageSize"], pageSizeOptions: [3, "pageSizeOptions"] }, { page: "page" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaginatorComponent_3)), i1.ɵdid(23, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_11 = _co.pagination.page; _ck(_v, 13, 0, currVal_11); var currVal_12 = _co.errorMessage; _ck(_v, 17, 0, currVal_12); var currVal_13 = _co.errorPageMessage; _ck(_v, 19, 0, currVal_13); var currVal_14 = (_co.currentPage - 1); var currVal_15 = _co.pagination.total; var currVal_16 = _co.pagination.per_page; var currVal_17 = _co.PAGINATION_OPTION; _ck(_v, 21, 0, currVal_14, currVal_15, currVal_16, currVal_17); var currVal_18 = _co.loadingTotal; _ck(_v, 23, 0, currVal_18); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.pagination.hasPrev || _co.loadingTotal); _ck(_v, 3, 0, currVal_0); var currVal_1 = (!_co.pagination.hasNext || _co.loadingTotal); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.pagination.pages; var currVal_3 = _co.loadingTotal; var currVal_4 = i1.ɵnov(_v, 15).ngClassUntouched; var currVal_5 = i1.ɵnov(_v, 15).ngClassTouched; var currVal_6 = i1.ɵnov(_v, 15).ngClassPristine; var currVal_7 = i1.ɵnov(_v, 15).ngClassDirty; var currVal_8 = i1.ɵnov(_v, 15).ngClassValid; var currVal_9 = i1.ɵnov(_v, 15).ngClassInvalid; var currVal_10 = i1.ɵnov(_v, 15).ngClassPending; _ck(_v, 10, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }); }
export function View_PaginatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-paginator", [], null, null, null, View_PaginatorComponent_0, RenderType_PaginatorComponent)), i1.ɵdid(1, 114688, null, 0, i11.PaginatorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaginatorComponentNgFactory = i1.ɵccf("app-paginator", i11.PaginatorComponent, View_PaginatorComponent_Host_0, { pagination: "pagination", options: "options", loadingTotal: "loadingTotal" }, { reload: "reload" }, []);
export { PaginatorComponentNgFactory as PaginatorComponentNgFactory };
