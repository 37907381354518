import { OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '../../../services/user.service';
import { SnackbarService } from '../../../services/snackbar.service';
var UserDialogComponent = /** @class */ (function () {
    function UserDialogComponent(data, dialogRef, usersService, snack, formBuilder) {
        this.data = data;
        this.dialogRef = dialogRef;
        this.usersService = usersService;
        this.snack = snack;
        this.formBuilder = formBuilder;
        this.subsManager$ = new Subject();
        this.user = this.data.user;
        this.isNew = this.user === null;
    }
    Object.defineProperty(UserDialogComponent.prototype, "form", {
        get: function () {
            return this.userForm;
        },
        enumerable: true,
        configurable: true
    });
    UserDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.buildForm();
        this.usersService.users
            .pipe(takeUntil(this.subsManager$))
            .subscribe(function (response) {
            _this.users = response;
        });
    };
    UserDialogComponent.prototype.ngOnDestroy = function () {
        this.subsManager$.next(true);
        this.subsManager$.complete();
    };
    UserDialogComponent.prototype.buildForm = function () {
        var readOnlyFields = this.user !== null;
        this.userForm = this.formBuilder.group({
            name: ['', [Validators.required, Validators.minLength(5)]],
            email: [{ value: '', disabled: readOnlyFields }, [Validators.required, Validators.email]],
            active: [true],
            role: ['']
        });
        if (null !== this.user) {
            this.userForm.patchValue({
                name: this.user.displayName,
                email: this.user.email,
                active: this.user.isActive,
                role: this.user.role
            });
        }
    };
    UserDialogComponent.prototype.save = function () {
        var userData = this.userForm.getRawValue();
        if (this.isNew) {
            var existUser = this.users.filter(function (user) { return user.displayName === userData.name || user.email === userData.email; });
            if (existUser.length) {
                this.snack.openError('The user already exists.', 5000);
                return;
            }
        }
        this.dialogRef.close({ displayName: userData.name, email: userData.email, isActive: userData.active, role: userData.role });
    };
    return UserDialogComponent;
}());
export { UserDialogComponent };
