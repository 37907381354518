import { userInfo } from 'os';

// TYPE NOTIFICATIONS
export const NOTIFICATION_ERROR_LOCATION = 'Error auth';
export const NOTIFICATION_WARNING= 'warning';
export const NOTIFICATION_ERROR_FETCH_DATA = 'Error sync data';
export const NOTIFICATION_GENERAL = 'Informative';
export const NOTIFICATION_PROTOCOLS = "protocols";

export const TYPE_LOG_USER = 'USER';
export const TYPE_LOG_LOCATION = 'LOCATION';
export const TYPE_LOG_GRADE = 'GRADE';
export const TYPE_LOG_SUBSCRIPTION = 'SUBSCRIPTION';

export const CREATE_DATA = 'created'
export const DELETE_DATA = 'deleted'