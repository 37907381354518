import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, OnChanges, ViewChild } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as _ from 'lodash';
import SavedAccount from '../../constants/firestore/saved-account';
import SavedLocation from '../../constants/firestore/saved-location';
import AccountReport from '../../services/account-report';
import { LocationService } from '../../services/location.service';
import { AuthService } from '../../services/auth.service';
import { GROUP_SUBSCRIPTION_TYPE, LOCATION_SUBSCRIPTION_TYPE } from '../../constants/firestore/account-location';
import { GroupService } from '../../services/group.service';
import { ISubscription } from '../../constants/subscription';
import { ProtocolService } from '../../services/protocol.service';
import { MatAccordion } from '@angular/material';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environment';
import { Router } from '@angular/router';

const FlexSearch = require('flexsearch');

@Component({
  selector: 'app-toggle-locations-in-account',
  templateUrl: './toggle-locations-in-account.component.html',
  styleUrls: ['./toggle-locations-in-account.component.scss']
})
export class ToggleLocationsInAccountComponent implements OnInit, OnDestroy, OnChanges {
  private _accountsCopy: SavedAccount[];
  private _destroySubs$: Subject<boolean> = new Subject();
  private _isEdit = false;

  public loadingSearch$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public accountsFilter: any;
  public onTrial: boolean;
  public toogleIcon = true;
  public noAccounts = false;
  public flexSearch = new FlexSearch({
    encode: "advanced",
    tokenize: "reverse",
    suggest: true,
    cache: true,
    doc: {
      id: 'id',
      field: [
        'accountName',
        'locationName',
        'locName',
        'address',
        'serviceArea',
        'reportName',
        'labels'
      ]
    }
  });
  public accounts: any = [];
  public search: string;
  public isFilter = false;
  public listEventSelect: AccountReport[] = [];
  public hasInfoNotification = false;

  // TimeOut search
  public timeout: any = null;
  public subscription$: Observable<ISubscription>;
  public subscription: ISubscription;

  constructor(
    private _locationS: LocationService,
    private _protocolService: ProtocolService,
    private _authService: AuthService,
    private _groupService: GroupService,
    private _http: HttpClient,
    private _router: Router,
  ) {
    this.subscription$ = this._authService.subscription$;
    this.subscription$.subscribe(subscription => this.subscription = subscription);
  }

  @Input() reportEdit: AccountReport[];
  @Input() selectByUser = false;
  @Input() checkUpgrade = false;
  @Input() isPost = false;
  @Input() safeNumberLocations = true;
  @Input() filterProtocolLocations = false;
  @Input() isSliderOpened = false;
  @Input() isSlider = false;
  @Input() txtInfoNotification = null;
  @Input() hasGoToLocation = false;
  
  @Output() selected: EventEmitter<AccountReport[]> = new EventEmitter<AccountReport[]>();

  @ViewChild(MatAccordion, {static: false}) accordion: MatAccordion;

  private disableAccounts(accounts: any[]) {
    return accounts.map(account => {
      account.checked = false;
      return account;
    });
  }


  async ngOnInit() {
    this.search = null;
    this.onTrial = this.subscription?.status === GROUP_SUBSCRIPTION_TYPE.TRIAL;
    if (this.reportEdit && this.reportEdit.length > 0) {
      this.listEventSelect = JSON.parse(JSON.stringify(this.reportEdit));
    }

    this.setInfoNotification();
    this.getAccounts();
  }

  ngOnChanges(){
    if (this.isSlider) {
      if (!this.isSliderOpened) {
        this.removeAllChecked();
        this.ngOnDestroy();
        this.accordion?.closeAll();
      } else {
        this.ngOnInit();
      }
    }
  }

  removeAllChecked() {
    this.listEventSelect?.forEach(el => {
      const account = this.accounts.find(a => a.accountId === el.accountId);
      if (account?.selectAll) {
        this.changeAccountSelected(account);
      } else {
        account?.locations?.forEach(l => {
          if (l?.checked) {
            this.changeLocationsSelected(l, account);
          }
        })
      }
    })
  }

  getAccounts() {
    if (this.filterProtocolLocations) {
      this._protocolService.getAccountsProtocols()
        .subscribe((accounts) => {
          if (accounts.length > 0) {
            this.accounts = this._accountsCopy = this.disableAccounts(Object.assign(accounts, []));
          } else {
            this.noAccounts = true
          }
        });
    } else {
      this._groupService.get_accounts(this._authService.session.gid).subscribe((accounts) => {
        if (accounts) {
          this.accounts = this._accountsCopy = this.disableAccounts(Object.assign(accounts, []));
        }
      });
    }
  }


  getAccountsLocations(query: string){
    let url
    if (this.filterProtocolLocations === true){
      url = `${environment.apiUrl}/v2/search/gid/${this._authService.session.gid}/account-locations/protocols`
    } else {
      url = `${environment.apiUrl}/v2/search/gid/${this._authService.session.gid}/account-locations`
    }

    return this._http.post(url, {'query': query})
  }


  changeAccountSelected(a: SavedAccount) {
    if (a.checked && a.selectAll) {
      a.checked = false;
      a.selectAll = false;
      a.locations.forEach(l => {
        l.checked = false;
        this.setChange(l, a);
        this.removeToList(l, a);
      });
    } else if (!a.selectAll) {
      a.selectAll = true;
      a.checked = true;
      a.locations.forEach(l => {
        l.checked = !l.deny;
        this.setChange(l, a);
        if (!l.deny) {
          this.AddToList(l, a);
        }
      });
    }

    this.setInfoNotification();
    this.selected.emit(this.listEventSelect);
  }

  removeToList(l: SavedLocation, a: SavedAccount) {
    const Search_Account = this.listEventSelect.find(r => r.accountId === a.accountId);
    if (Search_Account) {
      const search_locations = Search_Account.locations.find((locationId: any) => locationId.locationId === l.locationId);
      if (search_locations) {
        const locationsFilter = Search_Account.locations.filter((locationId: any) => locationId.locationId !== l.locationId);
        if (locationsFilter.length === 0) {
          this.listEventSelect = this.listEventSelect.filter(acc => acc.accountId !== a.accountId);
        } else {
          this.listEventSelect = this.listEventSelect.map(acc => {
            if (acc.accountId === a.accountId) {
              acc.locations = locationsFilter
            }
            return acc;
          });
        }
      }
    }
  }

  AddToList(l: SavedLocation, a: SavedAccount) {
    if (this.listEventSelect.length === 0) {
      const element = {
        accountId: a.accountId,
        locationId: l.locationId,
        accountName: a.accountName,
        locationName: l.locationName
      }
      if (l.location) {
        if (l.location.labels) element['labels'] = l.location.labels
        if (l.location.address) element['address'] = l.location.address
        if (l.location.serviceArea) element['serviceArea'] = l.location.serviceArea
      } else if (l?.labels) {
        element['labels'] = l.labels;
        if (l?.address) element['address'] = l.address
      } else if (l?.address) {
        element['address'] = l.address;
      } else if (l?.serviceArea) {
        element['serviceArea'] = l.serviceArea;
      }

      this.listEventSelect.push({
        accountId: a.accountId,
        gid: a.gid,
        locations: [element]
      });
    } else {
      const Search_Account = this.listEventSelect.find(r => r.accountId === a.accountId);
      if (Search_Account) {
        const search_locations = Search_Account.locations.find((locationId: any) => locationId.locationId === l.locationId);
        if (search_locations) {
          const locationsFilter = Search_Account.locations.filter((locationId: any) => locationId.locationId !== l.locationId);
          if (locationsFilter.length === 0 && !l.checked) {
            if (a?.selectAll) a.selectAll = false;
            this.listEventSelect = this.listEventSelect.filter(acc => acc.accountId !== a.accountId);
          } else {
            this.listEventSelect = this.listEventSelect.map(acc => {
              if (acc.accountId === a.accountId && !l.checked) {
                acc.locations = locationsFilter
              }
              return acc;
            });
          }
        } else {
          this.listEventSelect = this.listEventSelect.map(acc => {
            if (acc.accountId === a.accountId) {
              const element = {
                accountId: a.accountId,
                locationId: l.locationId,
                accountName: a.accountName,
                locationName: l.locationName
              }
              if (l.location) {
                if (l.location.labels) element['labels'] = l.location.labels
                if (l.location.address) element['address'] = l.location.address
                if (l.location.serviceArea) element['serviceArea'] = l.location.serviceArea
              } else if (l?.labels) {
                element['labels'] = l.labels;
                if (l?.address) element['address'] = l.address
              } else if (l?.address) {
                element['address'] = l.address;
              } else if (l?.serviceArea) {
                element['serviceArea'] = l.serviceArea;
              }
              acc.locations.push(element)
            }
            return acc;
          });
        }
      } else {
        const element = {
          accountId: a.accountId,
          locationId: l.locationId,
          accountName: a.accountName,
          locationName: l.locationName
        }
        if (l.location) {
          if (l.location.labels) element['labels'] = l.location.labels
          if (l.location.address) element['address'] = l.location.address
          if (l.location.serviceArea) element['serviceArea'] = l.location.serviceArea
        } else if (l?.labels) {
          element['labels'] = l.labels;
          if (l?.address) element['address'] = l.address;
        } else if (l?.serviceArea) {
          element['serviceArea'] = l.serviceArea;
        }
        this.listEventSelect.push({
          accountId: a.accountId,
          gid: a.gid,
          locations: [element]
        });
      }
    }

    const locationChecked = a?.locations?.filter(l => l.checked);
    const possibleLocations = a?.locations?.filter(l => !l.deny);
    a.selectAll = locationChecked.length === possibleLocations.length;

    if (this.listEventSelect.length > 0) {
      this.removeBasics();
    }

  }

  removeBasics() {
    this.listEventSelect.forEach(location => {
      if (Object.prototype.hasOwnProperty.call(location, 'locationsBasics')) {
        delete location['locationsBasics']
      }
    })
  }

  changeLocationsSelected(l: SavedLocation, a: SavedAccount) {
    l.checked = !l.checked;
    const locationActive = a.locations.filter(l => l.checked);
    a.checked = locationActive.length !== 0;
    this.setChange(l, a);
    this.AddToList(l, a);
    this.setInfoNotification();
    this.selected.emit(this.listEventSelect);
  }

  setChange(l: SavedLocation, a: SavedAccount) {
    const account = _.find(this._accountsCopy, { 'accountId': a.accountId });
    const location = _.find(account.locations, { 'locationId': l.locationId });
    location.checked = l.checked;

    // if toggle uncheck and it depends on reporEdit, delete it.
    if (l.checked === false && this.search){
      const accountReportEdit = _.find(this.reportEdit, { 'accountId': a.accountId })
      if (accountReportEdit){
        const locationIndex = _.findIndex(accountReportEdit.locations, { 'locationId': l.locationId });
        if (locationIndex !== -1) {
          accountReportEdit.locations.splice(locationIndex, 1);
        }
      }
    }
  }

  isActiveAccount(account: any, report: any[], isFromFilterSearch = false) {
    Promise.all(report.map((activeAccount) => {
      if (activeAccount.gid + activeAccount.accountId === account.gid + account.accountId) {
        account.selectAll = false;
        account.checked = true;
        account.locations?.forEach(location => {
          const locationId = location.locationId;
          activeAccount.locations.forEach(l => {
            if (locationId === l.locationId) {
              location.checked = true;
            }
          });
          if ((!this.onTrial && location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE) || (location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.BASIC && !this.selectByUser)) {
            location.deny = true;
          }
        });
      }
    }));
    if (!isFromFilterSearch){
      this._accountsCopy = this.accounts
    }
  }

  filterLocation($event: string, key: any) {
    if ($event[$event.length - 1] === ' ') {
      return
    }
    clearTimeout(this.timeout);
    this.loadingSearch$.next(true);
    this.timeout = setTimeout(async () => {
      if (!$event || $event === '') {
        this.isFilter = false;
        this.accounts = await this.checkSelected(this._accountsCopy, this.accounts);
        if (this.reportEdit && this.reportEdit.length > 0) {
          this.accounts.forEach(account => {
            this.isActiveAccount(account, this.reportEdit);
          });
        }
        this.loadingSearch$.next(false);
      } else {
        const text = $event.toLowerCase();
        this.getAccountsLocations(text).pipe(
          switchMap(result => this.checkSelected(result["accounts"],this._accountsCopy)
          )).subscribe(accounts => {
            this.accounts = accounts
            if (this.reportEdit && this.reportEdit.length > 0) {
              this.accounts.forEach(account => {
                this.isActiveAccount(account, this.reportEdit, true);
              })
            }
            this.isFilter = true;
            this.loadingSearch$.next(false);
          })       
      }
    }, 350);
  }


  async checkSelected(source, otherSource) {
    for (const acc of source) {
      const selectAcc = _.find(otherSource, { 'accountId': acc.accountId });
      if (selectAcc && selectAcc.locations) {
        selectAcc.locations.forEach(l => {
          const currentloc = _.find(acc.locations, { 'locationId': l.locationId });
          if (currentloc) {

            currentloc['checked'] = l.checked || false;
            currentloc['deny'] = l.deny || l.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE || false;
            currentloc['subscriptionType'] = l.subscriptionType;
            currentloc['serviceArea'] = l?.location?.serviceArea || l?.serviceArea;
          }
        });
      } else if (selectAcc && !selectAcc.locations && acc.locations) {
        await this._locationS.getAccountLocation(this._authService.session.gid, selectAcc);
        selectAcc.locations.forEach(l => {
          const currentloc = _.find(acc.locations, { 'locationId': l.locationId });
          if (currentloc) {
            currentloc['checked'] = l.checked || false;
            currentloc['deny'] = l.deny || l.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE || false;
            currentloc['subscriptionType'] = l.subscriptionType;
            currentloc['serviceArea'] = l?.location?.serviceArea || l?.serviceArea;
          }
        });
      }
    }

    return source;
  }

  setInfoNotification(): void {
    if(!this.txtInfoNotification) { 
      return;
    }

    let totalLocations = 0;
    this.listEventSelect.forEach(account => {
      totalLocations += account.locations.length;
    });

    this.hasInfoNotification = totalLocations < 2;
  }

  ngOnDestroy(): void {
    this.accounts = this.disableAccounts(this.accounts);
    this._destroySubs$.next(true);
    this._destroySubs$.complete();
  }

  openAccount(account) {
    const user = this._authService.session;
    if (this.accounts.length === 1 && this._isEdit) {
      return;
    }
    if (!_.has(account, 'locations')) {
      if (this.filterProtocolLocations) {
        this._protocolService.getLocationsProtocols(account.accountId)
          .toPromise().then((locations) => {
            account.locations = locations;
            if (this.reportEdit && this.reportEdit.length > 0) {
              this.accounts.forEach(account => {
                this.isActiveAccount(account, this.reportEdit);
              });
              this.setInfoNotification();
              this.selected.emit(this.listEventSelect);
              this._isEdit = true;
            }
            account['loadAllLocations'] = true;
          });
      } else {
        this._groupService.get_locations(this._authService.session.gid, account.accountId)
          .toPromise().then((locations) => {
            if (this.isMember()) {
              const memberLocations = [];
              user.accounts.forEach(account => {
                account.locations.forEach(location => {
                  memberLocations.push(location.locationId)
                });
              });
              const memberLocation = [];
              locations.forEach(location => {
                if (memberLocations.includes(location['locationId'])) {
                  memberLocation.push(location);
                }
              });
              account.locations = memberLocation;
            }
            else {
              account.locations = locations;
            }
            if (this.reportEdit && this.reportEdit.length > 0) {
              this.accounts.forEach(account => {
                this.isActiveAccount(account, this.reportEdit);
              });
              this.setInfoNotification();
              this.selected.emit(this.listEventSelect);
              this._isEdit = true;
            }
            account['loadAllLocations'] = true;
          })
      }
    } else if (!_.get(account, 'loadAllLocations') && !this.isFilter) {
      const prevAccounts = _.cloneDeep(this._accountsCopy);
      account.locations = null;
      if (this.filterProtocolLocations) {
        this._protocolService.getLocationsProtocols(account.accountId)
          .toPromise().then((locations) => {
            account.locations = locations;
            if (this.reportEdit && this.reportEdit.length > 0) {
              this.accounts.forEach(account => {
                this.isActiveAccount(account, this.reportEdit);
              });
              this.setInfoNotification();
              this.selected.emit(this.listEventSelect);
              this._isEdit = true;
            }
            account['loadAllLocations'] = true;
            account = _.head(this.checkSelected([account], prevAccounts));
          });
      } else {
        if (_.has(account, 'locations')) account.locations = null;
          this._groupService.get_locations(this._authService.session.gid, account.accountId)
          .toPromise().then((locations) => {
            account.locations = locations;
            if (this.reportEdit && this.reportEdit.length > 0) {
              this.accounts.forEach(account => {
                this.isActiveAccount(account, this.reportEdit);
              });
              this.setInfoNotification();
              this.selected.emit(this.listEventSelect);
              this._isEdit = true;
            }
            account['loadAllLocations'] = true;
            account = _.head(this.checkSelected([account], prevAccounts));
          });
      }
    }
    if (this.checkUpgrade) {
      account.locations = account.locations.filter(location => location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE || location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.BASIC);
    }
  }

  changeIconToggle(e) {
    const icon = document.getElementById(`Icon${e}`);
    if (icon.classList.contains('fa-plus')) {
      icon.classList.remove('fa-plus');
      icon.classList.add('fa-minus');
    } else {
      icon.classList.remove('fa-minus');
      icon.classList.add('fa-plus');
    }
  }

  isMember() {
    return this._authService.session.role?.toLowerCase() === 'member'
  }

  goToLocation(account, location): void {
    this._router.navigate(['/account', account?.accountId, 'location', location?.locationId, 'post-management'])
  }

}
