<div class="dialog__header">
    <h2 mat-dialog-title class="m--0 txt--xl"><strong>Menu</strong></h2>
</div>

<div *ngIf="error != {} && error_details?.length > 0">
    <app-error-message [error]="error" [error_details]="error_details"></app-error-message>
  </div>
  
<div class="dialog__content" mat-dialog-content>
    <app-menu-location [foodMenuList]="foodMenu" [onlyShow]="onlyShow"></app-menu-location>
</div>
<div mat-dialog-actions class="dialog__footer">
  <span class="alternate-theme d-inline-block mr--10">
      <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">{{onlyShow ? 'Close' : 'Cancel'}}</button>
  </span>
  <button mat-flat-button (click)="apply()" tabIndex="-1" *ngIf="!onlyShow" color="accent" class="btn btn--action">Apply</button>
  <button mat-flat-button mat-dialog-close [routerLink]="['/account', accountId , 'location', locationId, 'menu']" tabIndex="-1" *ngIf="onlyShow" color="accent" class="btn btn--action">Go to location</button>
</div>
  