import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-search-keywords',
  templateUrl: './skeleton-search-keywords.component.html'
})
export class SkeletonSearchKeywordsComponent implements OnInit {

  constructor() {
    // This is intentional
  }

  ngOnInit() {
    // This is intentional
  }

}
