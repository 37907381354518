import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'topicType'
})
export class TopicTypePipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    switch (value.toUpperCase()) {
      case 'STANDARD':
        return 'Standard post';
      case 'ALERT':
        return 'COVID-19';
      default:
        return value  ;
    }
  }

}
