interface UrlAttributeValue {
  "url": string
}

export interface Attributes {
  "attributeId": string,
  "valueType": AttributeValueType,
  "values"?: number | string | boolean [],
  "repeatedEnumValue"?: RepeatedEnumAttributeValue,
  "urlValues"?: UrlAttributeValue[],
  "uriValues"?: UrlAttributeValue[]
  displayName: string
}


export interface RepeatedEnumAttributeValue {
  "setValues": [
    string
  ],
  "unsetValues": [
    string
  ]
}

export enum AttributeValueType {
  ATTRIBUTE_VALUE_TYPE_UNSPECIFIED = 'ATTRIBUTE_VALUE_TYPE_UNSPECIFIED',
  BOOL = 'BOOL',
  ENUM = 'ENUM',
  URL = 'URL',
  REPEATED_ENUM = 'REPEATED_ENUM'
}

export const AttributeOpenInfoStatusType: {name: string, key: string}[] = [
  {name: 'Open', key: 'OPEN'},
  {name: 'Closed Temporarily', key: 'CLOSED_TEMPORARILY'},
  {name: 'Closed Permanently', key: 'CLOSED_PERMANENTLY'},
];


export interface GoogleAttributes {
  attributeId: string,
  displayName: string
  groupDisplayName: string
  isDeprecated: boolean
  valueMetadata: any
  active: boolean
  valueType: AttributeValueType,
  urlValues?: UrlAttributeValue[],
  uriValues?: [],
  repeatedEnumValue?: RepeatedEnumAttributeValue[],
  setValues?:[]
}


export interface GroupAttributes {
  groupName: string,
  items: GoogleAttributes[],
  active?: boolean
}

