 import {EventMessage} from '../constants/event-message';
import {EventNames} from '../constants/event-names';
import {EventEmitter} from "@angular/core";

export class EventMessageService {

  dispatcher: EventEmitter<any> = new EventEmitter();
  onAddAccount: EventEmitter<any> = new EventEmitter();
  eventNames: EventNames = new EventNames();

  constructor() {
  }

  emitMessageEvent(name: string, data: any) {
    let message = new EventMessage(name, data);
    this.dispatcher.emit(message);
  }

  emitOnAddAccount() {
    this.onAddAccount.emit();
  }

  getEmitter() {
    return this.dispatcher;
  }

  getNames() {
    return this.eventNames;
  }
}
