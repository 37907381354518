// dep
import {Component, Inject, OnInit} from "@angular/core";
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material";
import * as _ from "lodash";

// app
import {LOCATION_SUBSCRIPTION_TYPE as PLAN, GROUP_SUBSCRIPTION_TYPE} from '../../constants/firestore/account-location';
import { LocationRef, normalizeLocationRef } from "../../constants/firestore/location-object";
import {AuthService} from "../../services/auth.service";
import {PaymentsService} from '../../services/payments.service';
import {LocationService} from '../../services/location.service';
import {WhiteLabelService} from '../../services/white-label.service';
import { SpinnerService } from "../../services/spinner.service";


type Selection = { nextPlan : PLAN, 
                   action   : 'downgrade' | 'upgrade' | 'change_plan'}

type Product = { interval: any
                 amount: number
                 isFree: boolean }

type Option = PLAN.ESSENTIAL | PLAN.BASIC | PLAN.ULTIMATE

/**
 Shows a dialog with the possible plans for the chosen location(s) asking the user to select one.

 - For a single location only shows the all the plans excluding the one the location currently has.
 - For multiple locations always shows all the plans, no matter which ones the locations currently have.

 - Always returns a SINGLE destination plan for all locations
*/
@Component({
  selector: 'change-plan-location',
  templateUrl: './change-plan-location.component.html',
  styleUrls:  ['./change-plan-location.component.scss']
})
export class ChangePlanLocationComponent implements OnInit {

  readonly OPTS_DEFAULT = { config : { width: '860px',  //760px 
                                       panelClass: 'dialog--ultimate-wrapper',
                                       height: '100%' },
                            disableClose : true }

  RESULT_TYPE : Selection | null

  public activated = false;
  public pricing_url: string;
  
  public PLAN = PLAN;
  public titleCurrentPlan = ''
  public subscriptionTrialDays : number | null = null  
  public hasContactUsButton = true;

  public basicPlan    : Product
  public ultimatePlan : Product

  private isMultipleLocations = false
  private currentPlan : PLAN | null = null


  constructor(
    public dialogRef: MatDialogRef<ChangePlanLocationComponent>,
    @Inject(MAT_DIALOG_DATA) 
    public data: LocationRef[],

    private _spinnerService: SpinnerService,
    private _locationService: LocationService,
    private _authService: AuthService,
    private _paymentsService: PaymentsService,
    private _whiteLabelService: WhiteLabelService,
  ) {

    this._spinnerService.loading$.next(true);

    this._whiteLabelService.branding.then( (brand : any) => {
      this.pricing_url = brand?.pricing_url
    })

    this.isMultipleLocations = (this.locations.length > 1)

    this._initFromSubscription()

    this._spinnerService.loading$.next(false);
  }

  get locations() : LocationRef[] {
    return this.data
  }

  ngOnInit() {
    this._getPlanInfo();
  }

  handleSelectOption(option : Option) : void {
    const action = this.optionAction(option)

    this.dialogRef.close({ nextPlan: option, 
                           action })
    this.dialogRef.disableClose = true
  }

  private async _initFromSubscription() : Promise<void> {
    let singleLocationPlan : PLAN | null = null
    if(this.locations.length == 1) {
      // Single Location
      const loc = this.locations[0]
      if (loc && loc.locationId && loc.accountId) {
        const locationId = normalizeLocationRef(loc).locationId
        singleLocationPlan = (await this._locationService.fetchLocation(this._authService.session.gid, loc.accountId, locationId)).subscriptionType
      }
    }

    this._authService.subscription$.subscribe(sub => {
      const subInTrial = (sub.status === GROUP_SUBSCRIPTION_TYPE.TRIAL)

      this.subscriptionTrialDays = (subInTrial ? sub.daysInTrial : null)
 
      if (this.isMultipleLocations) {
        //-- Multiple Locations --
        this.currentPlan      = null
        this.titleCurrentPlan = `MULTIPLE (${this.locations.length})`

      } else if(singleLocationPlan) {
        //-- Single Location --
        this.currentPlan      = ((subInTrial && singleLocationPlan === PLAN.FREE) ? PLAN.ULTIMATE_TRIAL : 
                                 (singleLocationPlan === PLAN.FREE ? PLAN.ESSENTIAL : 
                                  singleLocationPlan))
        this.titleCurrentPlan = this.currentPlan

      } else {
        //-- No Location --
        // Current assumed as Ultimate
        // TODO: This case is ever reached?
        this.currentPlan      = (subInTrial ? PLAN.ULTIMATE_TRIAL : PLAN.ULTIMATE)
        this.titleCurrentPlan = this.currentPlan
      }
    })
  }

  private async _getPlanInfo() : Promise<void> {
    const [p_ultimate, p_basic, wl] = await Promise.all([
      this._paymentsService.getPlan(PLAN.ULTIMATE).toPromise(),
      this._paymentsService.getPlan(PLAN.BASIC).toPromise(),
      this._whiteLabelService.getDynamicWhiteLabel()
    ] as const)

    const parsePlan = (plan : typeof p_basic) : Product => {
      const price    = plan['data']['price']
      const interval = plan['data']['interval']
      return {
        interval: interval,
        amount: price,
        isFree: (price == 0)
      }
    }

    // 'Essential' is not loaded as is always assumed essential.price == 0
    this.ultimatePlan = parsePlan(p_ultimate)
    this.basicPlan    = parsePlan(p_basic)

    this.hasContactUsButton = !_.get(wl, 'hideContactUsButton', false);
  }

  handleContactUs() {
    this._whiteLabelService.goToContactSupport()
  }

  seeFullComparison() {
    window.open(this.pricing_url, "_blank");
  }

  optionAction(option : Option) : Selection['action'] | null {
    const currentPlan = (this.isMultipleLocations ? 'MULTIPLE' : this.currentPlan)
    if(!currentPlan)
      // undefined if subscription is not loaded yet
      return null

    // 'change_plan' means both change the plan of multiple locations and
    // also move the Subscription out of the Trial phase. 
    // TODO: If multiple locations were selected and, for example, all are
    // 'ESSENTIAL', then maybe show the "ULTIMATE" option as 'upgrade' and not
    // 'change_plan'
    const C = 'change_plan'
    const U = 'upgrade'
    const D = 'downgrade'
    // don't show option:
    const _ = null
    
    const column = { [PLAN.ESSENTIAL] : 0,
                     [PLAN.BASIC]     : 1,
                     [PLAN.ULTIMATE]  : 2 }[option]

                                   // E  B  U
    return { 'MULTIPLE'            : [C, C, C], 
             [PLAN.ULTIMATE_TRIAL] : [_, C, C], 
             [PLAN.ESSENTIAL]      : [_, U, U],
             [PLAN.BASIC]          : [D, _, U],
             [PLAN.ULTIMATE]       : [D, D, _] }[currentPlan][column]    
  }

  optionButtonLabel(option : Option) : string {
    return {'downgrade'   : 'Downgrade',
            'upgrade'     : 'Upgrade',
            'change_plan' : 'Change' }[this.optionAction(option)]
  }

  optionsClass() : string {
     return this.isMultipleLocations ? 'col-4' : 'col-6'
  }

}
