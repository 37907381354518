// dep
import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {MatDialog} from "@angular/material/dialog";
import { Observable /*, Subject*/ } from 'rxjs';

// app
import {AuthService} from '../services/auth.service';
import {GroupService} from '../services/group.service';
import { SpinnerService } from '../services/spinner.service';
import { ISubscription } from '../constants/subscription';
import { LocationService } from '../services/location.service';
import { SubscriptionService } from '../services/subscription.service';


@Component({
  selector: 'app-featured-datepicker',
  template: `
	  <div class="dialog__content dialog--featured" mat-dialog-content>
		  <img src="/assets/images/modal-star.png" alt="Image of a star" class="dialog__icon" width="128" height="128">
		  <button mat-dialog-close="" class="btn btn-dialog-close btn--absolute"><i class="txt--lg fal fa-times"></i>
		  </button>
		  <div *ngIf="!isMember()">
			<div class="dialog-row txt--center">
				<h2 class="heading--xl mb--10 mt--10 txt--black"><strong>Just as a heads up...</strong></h2>
				<p class="heading-h2 mb--10">You will only be able to access the last 12 months of data. If you'd like to
					see more, please upgrade.</p>
			</div>
			<div class="d-flex justify-content-center mb--10">
				<button mat-flat-button color="accent" class="btn btn--md txt--md" mat-dialog-close (click)="openUpgrade()">Upgrade Now
				</button>
			</div>
			<div class="d-flex justify-content-center">
				<a class="txt--lightblue cursor--pointer mt--5" (click)="dismissModal()" mat-dialog-close>Dismiss until tomorrow</a>
			</div>
		  </div>
		  <div *ngIf="isMember()">
			<div class="dialog-row txt--center">
					<h2 class="heading--xl mb--10 mt--10 txt--black"><strong>Just as a heads up...</strong></h2>
					<p class="heading-h2 mb--10">You will only be able to access the last 12 months of data. If you'd like to
						see more, please contact your account administrator.</p>
				</div>
		  </div>
	  </div>
  `,
})
export class FeaturedDatePickerComponent {
	subscription$: Observable<ISubscription> = this.auth.subscription$;
    subscription: ISubscription;
	location: any;
	locationId: string;
	accountId: string;

	readonly OPTS_DEFAULT = { config : { width: '470px',
                                         height: '420px' }}
 
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialog: MatDialog,
    private groupService: GroupService,
    private auth: AuthService,
    private spinnerService: SpinnerService,
	private locationService: LocationService,
	private subscriptionService: SubscriptionService,
  ) {
	this.auth.subscription$.subscribe(() => {
		this.spinnerService.loading$.next(false);
	});
	this.accountId = this.data?.accountId;
	this.locationId = this.data?.locationId;
	this.subscription$ = this.auth.subscription$;
    this.subscription$.subscribe(subscription => this.subscription = subscription);
  }

  dismissModal() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

	this.spinnerService.loading$.next(true);
    this.groupService.updateByDocId(this.auth.session.gid, {'dismissModalDatePicker': tomorrow})
		.then(()=>{
			this.groupService.getByDocId(this.auth.session.gid).toPromise().then(group => {
				this.auth.updateGroup(group);
				this.auth.processSubscription();
			})
			.catch(()=>{
				this.spinnerService.loading$.next(false);
			})
		})
		.catch(()=>{
			this.spinnerService.loading$.next(false);
		})
  }

  async openUpgrade() : Promise<void> {

	// TODO: Is this.location really needed? It's accessed from outside the component? If not
	// remove
	const locRef = { locationId: this.locationId, 
                      accountId: this.accountId }

	this.location = await this.locationService.getRef(this.auth.session.gid, this.accountId, this.locationId).toPromise()
	
	if(await this.subscriptionService.flowChangeLocationsPlan(this.subscription, [locRef]))
		// TODO: See comment above
		this.location = await this.locationService.getRef(this.auth.session.gid, this.accountId, this.locationId).toPromise()

  }

  isMember() : boolean {
    return this.auth.isMember 
  }

}
