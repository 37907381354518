<div class="dialog__header" *ngIf="!bulk">
    <h2 mat-dialog-title class="m--0 txt--xl"><strong>{{title | titlecase}}</strong></h2>
</div>

<div class="dialog__content">
    <div class="row mt--30 m--0 mb--10" *ngIf="actionLinksData?.length">
            <p class="fw--medium col-8 mb--0 p--0">Link</p>
            <p class="fw--medium col-4 mb--0 p--0 pl--30">
                Preferred
                <app-info-tooltip [tooltip]="'Preferred links are labeled and displayed at the top of your booking links as “Business preferred”. You can only have 1 preferred link.'"></app-info-tooltip>
            </p>
    </div>

    <mat-divider></mat-divider>

    <div class="dialog-row row justify-content-between align-items-center p--0 m--0 mt--10" *ngFor="let control of actionLinksData?.controls; let i = index;" [formGroup]="control">
        <section class="col-8 p--0">
            <mat-form-field class="input-group input-group--override input-group--override-label p--0">
            <input matInput class="input-group__field" formControlName="url" placeholder="Url"/>
            </mat-form-field>
            <mat-error *ngIf="hasError(i, 'url', 'pattern')">Looks like your URL has an incorrect format. Please check your formatting, no spaces or special characters are allowed.</mat-error>
            <mat-error *ngIf="hasError(i, 'url', 'required')">The URL is required</mat-error>
        </section>

        <section class="col-2 p--0 d-flex j--content--center">
            <mat-checkbox class="vertical--align--super" formControlName="isPreferred" (change)="handleCheckPreferred($event, i)"></mat-checkbox>
        </section>

        <button mat-button class="btn btn-icon col-1 p--0 j--content--center" (click)="removeActionLinks(i)"><i class="fas fa-times"></i></button>

        <mat-divider></mat-divider>
    </div>

    <div class="pb--0 row mt--10">
        <button mat-button class="btn btn--md d-flex align-items-center" (click)="addActionLinks()">
        <img src="/assets/images/add-btn.png" class="mr--10 img--xxs">Add {{title | titlecase}}</button>
    </div>
</div>


<div mat-dialog-actions class="dialog__footer" *ngIf="isEdit && !bulk">
    <span class="alternate-theme d-inline-block mr--10">
        <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
    </span>
    <button mat-flat-button class="btn btn--action" tabIndex="-1" color="accent" (click)="apply()">Apply</button>
</div>