// dep
import {Injectable} from '@angular/core'
import * as _ from 'lodash'
import {EMPTY, Observable, timer} from 'rxjs';
import 'rxjs/add/operator/retry'
import {debounce, expand, map, reduce} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';

// app
import { HEADERS_NO_AUTH } from '../constants/auth'
import {WhiteLabelService} from './white-label.service';
import GoogleAccountResponse from '../constants/firestore/google-account-response';
import GoogleLocationResponse from '../constants/firestore/google-location-response';
import {ApiResponse} from '../constants/api-response';
import {GoogleAttributes, GroupAttributes} from '../constants/google/attributes';
import {Pageable} from '../constants/pageable';
import {environment as ENV} from '@environment';
import {DataPicker} from '../constants/data-picker';
import GoogleUserProfile from '../constants/firestore/google-user-profile';
import { ServiceList } from '../constants/google/service-list';

const DYNAMIC_URL = 'https://search.google.com/local/writereview?placeid=';

@Injectable({
  providedIn: 'root'
})

export class GoogleService {

  domain: string;
  googleApiUrl                  = ENV.googleApi
  googleApiUrLocations          = ENV.googleApiLocations
  googleApiUrverifications      = ENV.googleApiVerifications
  googleAccountManagementApiUrl = ENV.googleAccountManagementApi

  constructor(
    private _wl: WhiteLabelService,
    private _http: HttpClient) {

    this.domain = this._wl.apiUrlForGoogle;
  }



  async authenticate(gid, uid, accountId) {
    const apiUrl = ENV.apiUrl
    const domain = apiUrl.endsWith('/api') ? apiUrl.substring(0, apiUrl.length - 4) : apiUrl;

    try {
      return await this._http.get<string>(`${apiUrl}/google/auth_url?uid=${uid}&gid=${gid}&accountId=${accountId}&domain=${domain}`, 
                                         HEADERS_NO_AUTH
                                        ).toPromise()
    } catch(err) {
        throw new Error(err);
    }
  }


  //--------------------------------------------------------------------------
  // Requests to Google
  //--------------------------------------------------------------------------

// TODO: Unused, remove. 
//   async updateProductList(accountId, locationId, priceList) : Promise<void> {
//     this.auth.getGmbToken().subscribe(token => {
//       this._http.patch(`${this.googleApiUrl}/accounts/${accountId}/locations/${locationId}?updateMask=priceLists`, 
//                       {"priceLists": priceList}, this.headersGoogle(token)).toPromise();
//     });
// 
//   }

  getUserProfile(googleAuth): Promise<GoogleUserProfile> {
    return this._http.get<GoogleUserProfile>(`https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${googleAuth.access_token}`,
                                            HEADERS_NO_AUTH).toPromise()
  }

  private headersGoogle(token) {
    return {headers: HEADERS_NO_AUTH.headers.set('Authorization', `Bearer ${token?.access_token || ''}`)}
  }

  getAccounts(token) {
    let url = this.googleAccountManagementApiUrl + '/accounts';

    return this._http.get<GoogleAccountResponse>(url, this.headersGoogle(token))
      .pipe(
        expand(res => {
          if (res.nextPageToken) {
            url += `${res.nextPageToken ? `&pageToken=${res.nextPageToken}` : ''}`;
            return this._http.get<GoogleAccountResponse>(this.googleAccountManagementApiUrl + `/accounts?pageToken=${res.nextPageToken}`, this.headersGoogle(token));
          } else {
            return EMPTY;
          }
        }),
        reduce((acc: GoogleAccountResponse[], x: GoogleAccountResponse) => {
          return acc.concat(x.accounts);
        }, []),
        map((res: any) => {


          return {accounts: res};
        }),
      );
  }

  getLocations(token, accountId, nextPageToken = null) {

    const readMask="storeCode,regularHours,name,languageCode,title,phoneNumbers,categories,storefrontAddress,websiteUri,regularHours,specialHours,serviceArea,labels,adWordsLocationExtensions,latlng,openInfo,metadata,profile,relationshipData,moreHours,metadata";
    let url = `${this.googleApiUrLocations}/${accountId}/locations?readMask=${readMask}&pageSize=100${nextPageToken ? `&pageToken=${nextPageToken}` : ''}`;

    return this._http.get<GoogleLocationResponse>(url, this.headersGoogle(token))
      .pipe(
        expand(res => {
          if (res.nextPageToken) {
            url += `${res.nextPageToken ? `&pageToken=${res.nextPageToken}` : ''}`;
            return this._http.get<GoogleLocationResponse>(url, this.headersGoogle(token));
          } else {
            return EMPTY;
          }
        }),
        reduce((acc: GoogleAccountResponse[], x: GoogleLocationResponse) => acc.concat(x.locations), []),
        map((res: any) => {
          res.forEach(async el => {
            if(el){
              el.locationName = el.title;
              el.name = accountId + '/' + el.name;
              el.address = el.storefrontAddress;
            }
          });
          return {locations: res};
        }),
      );
  }

  //--------------------------------------------------------------------------

  async updateMenuList(accountId, locationId, priceList): Promise<{success: boolean, message: string}> {
    return await this._http.post<any>(`${ENV.apiUrl}/v2/google/account/${accountId}/location/${locationId}/menu_list`, {"priceList": priceList})
    .pipe(
      map( value => value ? value.data : {})
    ).toPromise();
  }

  saveMedia(accountId: string, locationId: string, data) {
    const url = `${ENV.apiUrl}/v2/google/${accountId}/${locationId}/media`;
    return this._http.post(url, data);
  }

  saveBulkMedia(accountId: string, locationId: string, data) {
    const url = `${ENV.apiUrl}/v2/google/${accountId}/${locationId}/bulk_media`;
    return this._http.post(url, data);
  }

  deleteMedia(accountId: string, locationId: string, name) {
    const params = new HttpParams().set('name', name);
    const url = `${ENV.apiUrl}/v2/google/${accountId}/${locationId}/media`;
    return this._http.delete<ApiResponse>(url, {params}).pipe(map(value => value.data || []));
  }

  getMedia(accountId, locationId) {
    const url = `${ENV.apiUrl}/v2/google/${accountId}/${locationId}/media`;
    return this._http.get<ApiResponse>(url).pipe(map(value => value.data || []));
  }


  getProfilePhoto(accountId: string, locationId) {
    const url = `${ENV.apiUrl}/v2/google/${accountId}/${locationId}/media/profile`;
    return this._http.get<ApiResponse>(url).pipe(map(value => value.data || []));
  }

  getCategories(displayName, usedCategories, region_code) {
    const params = new HttpParams().set('q', displayName).set('region_code', region_code);
    return this._http.get<ApiResponse>(`${ENV.apiUrl}/v2/categories/search`, {
      params
    }).pipe(
      map(value => {
        if (value?.data) {
          const data = [...value.data];
          usedCategories.forEach(cat => {
            const categoryId = cat?.categoryId?.includes('categories') ? cat?.categoryId?.split('/')[1] : cat?.categoryId;
            const index = data.findIndex(d => d.categoryId == categoryId);
            if (index > -1) {
              data.splice(index, 1);
            }
          })
          return data;
        }
        return null
      }),
      debounce(() => timer(1000))
    );
  }

  // ATTRIBUTES SERVICES, FOOD MENU, PRODUCTS
  saveMenu(accountId: string, locationId : string) {
    return this._http.get<ApiResponse>(`${ENV.apiUrl}/v2/google/account/${accountId}/location/${locationId}/menu`
    ).pipe(map(value => value.data || []));
  }


  saveServices(accountId: string, locationId : string) {
    return this._http.get<ApiResponse>(`${ENV.apiUrl}/v2/google/account/${accountId}/location/${locationId}/service_list` 
    ).pipe(map(value => value.data || []));
  }

  updateServices(accountId: string, locationId, serviceList: ServiceList[]) {
    return this._http.post<ApiResponse>(`${ENV.apiUrl}/v2/google/account/${accountId}/location/${locationId}/service_list`, { serviceList }
    ).pipe(map(value => value.data || []));
  }

  updateBulkServices(accountId: string, locationId: string, data: any) {
    return this._http.post<ApiResponse>(`${ENV.apiUrl}/v2/google/account/${accountId}/location/${locationId}/service_list`, data
    ).pipe(map(value => value.data || []));
  }

  updateFoodMenu(accountId: string, locationId: string,  data: any) {
    return this._http.post<ApiResponse>(`${ENV.apiUrl}/v2/google/account/${accountId}/location/${locationId}/menu_list`, data
    ).pipe(map(value => value.data || []));
  }

  getAttributes(gids, accountIds, locationIds) {
    // const params = new HttpParams().set('categoryId', categoryId);
    const data = {
      gids: gids,
      accountIds: accountIds,
      locationIds: locationIds
    }  

    return this._http.post<ApiResponse>(`${ENV.apiUrl}/v2/google/attributes`, data).pipe(map(value => value.data ? value?.data[0]?.attributes : []));
  }

  // TODO: Unused, remove
  //
  // getGoogleData(accountId, locationId) {
  //   return this._http.get<ApiResponse>(`${ENV.apiUrl}/v2/google/${accountId}/${locationId}/getGoogleData`)
  //     .pipe(map(value => value.data || null));
  // }

  fetchDifference(accountId, locationId) {
    return this._http.get<ApiResponse>(`${ENV.apiUrl}/v2/google/${accountId}/${locationId}/fetchDifferences`)
      .pipe(map(value => value.data || null));
  }

  diffMaskPush(gid, accountId, locationId, differences = null):Observable <any> {
    const data = {
      gid: gid,
      differences: differences
    }
    return this._http.post(`${ENV.apiUrl}/v2/google/${accountId}/${locationId}/accept_google_updates`, data);
  }

  fetch(select, accountId, locationId) {
    const params = new HttpParams().set('select', select);

    return this._http.get<ApiResponse>(`${ENV.apiUrl}/v2/google/${accountId}/${locationId}/fetch`, {
      params,
    }).pipe(map(value => value || null)).toPromise();
  }

  listLockHistory(accountId: string, placeId: string, pageable: Pageable) {
    const params = new HttpParams()
                        .set('size', pageable.size.toString())
                        .set('accountId', accountId)
                        .set('page', pageable.page.toString());
    return this._http.get<ApiResponse>(`${ENV.apiUrl}/v2/google/${placeId}/lockHistory`, {
      params,
    }).pipe(map(value => value ? value.data : null));
  }


  saveLockHistory(accountId, locationId, action: string, status: string) {
    return this._http.post<ApiResponse>(`${ENV.apiUrl}/v2/google/${accountId}/${locationId}/lockHistory`, {
      status,
      action
    }).pipe(map(value => value ? value.data : null));
  }

  push(accountId, locationId) {
    return this._http.post<ApiResponse>(`${ENV.apiUrl}/v2/google/${accountId}/${locationId}/push`, {})
      .pipe(map(value => value || null));
  }


  metrics(accountId, locationId, dataPicker?: DataPicker) {
    const loc = dataPicker?.locations[0]
    if (loc && loc.accountId && loc.locationId){
        accountId  = loc.accountId;
        locationId = loc.locationId;
    }

    return this._http.get<ApiResponse>(`${ENV.apiUrl}/v2/google/${accountId}/${locationId}/grade`,
      ).retry(3)
       .pipe(map(value => value ? value.data : []));
  }

  dynamicUrl(locationId: string): Observable<any> {
    const url = DYNAMIC_URL + locationId;
    return this._http.post(ENV.fbDynamicLinkApi, {
      longDynamicLink: `${ENV.fbDynamicLinkDomain}/?link=${url}`,
      suffix: {
        option: 'SHORT'
      }
    }, HEADERS_NO_AUTH)
  }

  attributesToGMB(groups: GroupAttributes[]) {
    let itemsActive: GoogleAttributes[] = [];
    groups.forEach(group => {
      const active = group.items.filter(item => {
        return item.active === true || item.active === false || item.attributeId.startsWith('url');
      }).filter((item) => {
        if (item.valueType === 'URL' && item.urlValues === undefined) {
            return false;
        }
        return true;
      });
      itemsActive = itemsActive.concat(active);
    });

    const attributes = this.toLocationAttribute(itemsActive);

    return attributes;
  }

  private toLocationAttribute(items: GoogleAttributes[]) {
    const result = [];
    items.forEach(item => {
      result.push(this.toLocationAttributes(item));
    });
    return result;
  }

  private toLocationAttributes(item: GoogleAttributes) {
    let result = {};
    switch (item.valueType) {
      case 'BOOL':
        result = { valueType: item.valueType, attributeId: item.attributeId, values: [item.active] };
        break;
      case 'ENUM':
        result = { valueType: item.valueType, attributeId: item.attributeId, values: [item.valueMetadata] };
        break;
      case 'URL':
        result = { valueType: item.valueType, attributeId: item.attributeId, urlValues: item.urlValues };
        break;
      case 'REPEATED_ENUM':
        const valuesSet   = _.reject(item.valueMetadata.map(v => v.active && v.value), (o) =>  !o);
        const valuesUnSet = _.reject(item.valueMetadata.map(v => v.active === false && v.value), (o) =>  !o);
        const enumValues  = {};
        !_.isEmpty(valuesSet) && (enumValues['setValues'] = valuesSet);
        !_.isEmpty(valuesUnSet) && (enumValues['unsetValues'] = valuesUnSet);
        result = { valueType: item.valueType, attributeId: item.attributeId, repeatedEnumValue: enumValues };
        break;
      default:
        break;
    }

    return result;
  }


  /**
   * organize the items in google attributes object
   * @return  this.groups organized
   */
  private toAttributeGroups(googleAttributes: GoogleAttributes[]): GroupAttributes[] {
    const attributes = _.cloneDeep(googleAttributes);
    const groups: GroupAttributes[] = [];

    if (!googleAttributes) {
      return [];
    }

    attributes.forEach(value => {

      const groupName = value.groupDisplayName;
      let exist = false;
      let index = null;
      groups.forEach((group, i) => {
        if (groupName === group.groupName) {
          exist = true;
          index = i;
        }
      });
      value.active = null;

      if (exist) {
        groups[index].items.push(value);
      } else {
        groups.push({
          groupName,
          items: [value],
        });
      }
    });

    groups.forEach((group) => {
      group.items = _.orderBy(group.items, ['valueType'], ['asc']);
    });

    return groups;
  }


  /*
    FIXME: smell bad
    attribute onlyActive never used
  */
  activeSavedAttributes(groups: GroupAttributes[], locationAttributes, onlyActive = false) {

    if (!locationAttributes) {
      return;
    }

    if (locationAttributes.length === 0) {
      return groups;
    }

    groups.forEach(group => {
      group.items.forEach(item => {
        if (item.valueType === 'URL') group.active = true;
        locationAttributes.forEach(locAttribute => {
          if (item.attributeId === locAttribute.attributeId) {
            if (item.valueType === 'URL') {
              item.urlValues = locAttribute.urlValues;
              group.active = true;
            }
            if (item.valueType === 'BOOL') {
              item.active = locAttribute.values[0];
              group.active = true;
            }
            if (item.valueType === 'REPEATED_ENUM' || item.valueType === 'ENUM') {
              item?.valueMetadata?.map(metadata => {
                metadata.active = null;
                if (locAttribute?.repeatedEnumValue?.setValues?.findIndex(value => value === metadata.value) > -1) {
                  group.active = true;
                  item.active = true;
                  metadata.active = true;
                } else if (locAttribute?.repeatedEnumValue?.unsetValues?.findIndex(value => value === metadata.value) > -1) {
                  group.active = true;
                  item.active = true;
                  metadata.active = false;
                }
              });
            }

          }
        });
      });
    });
    return groups;
  }

  private getExcludeAtributes(googleAttributes: GoogleAttributes[], locationAttributes: GoogleAttributes[] = []) {
    const result = [];
    locationAttributes.forEach(locAttribute => {
      const findAttribute = _.find(googleAttributes, { 'attributeId': locAttribute.attributeId });
      if (!findAttribute || _.isEqual(locAttribute.valueType, 'URL')) {
        result.push(locAttribute);
      }
    });

    return result;
  }

  async groupsWithAttributeSaved(gid, accountId, locationId, primaryCategory, locationAttributes, googleAttributes: GoogleAttributes[] = []) {
    if (googleAttributes?.length === 0) {
      googleAttributes = await this.getAttributes([gid], [accountId], [locationId]).toPromise();
    }
    const excludedAtributes = this.getExcludeAtributes(googleAttributes, locationAttributes);
    const groups = this.toAttributeGroups(googleAttributes);
    this.activeSavedAttributes(groups, locationAttributes);
    return {groups, googleAttributes, excludedAtributes};
  }

  async groupsWithAttribute(accountId, gid, locationIds) {

    const googleAttributes = await this.getAttributes(gid, accountId, locationIds).toPromise();
    const excludedAtributes = [];
    const groups = this.toAttributeGroups(googleAttributes);
    return {groups, googleAttributes, excludedAtributes};
  }


}
