import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-skeleton-review-report',
  templateUrl: './skeleton-review-report.component.html'
})
export class SkeletonReviewReportComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
