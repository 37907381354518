import * as uuid from 'uuid';
import * as i0 from "@angular/core";
var UuidService = /** @class */ (function () {
    function UuidService() {
    }
    Object.defineProperty(UuidService.prototype, "uuidRandom", {
        get: function () {
            return uuid.v4();
        },
        enumerable: true,
        configurable: true
    });
    UuidService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UuidService_Factory() { return new UuidService(); }, token: UuidService, providedIn: "root" });
    return UuidService;
}());
export { UuidService };
