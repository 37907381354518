import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environment';
import { Queue } from '../constants/firestore/enqueue';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ObservationService = /** @class */ (function () {
    function ObservationService(http) {
        this.http = http;
        this.subjectAddAccount = new Subject();
    }
    ObservationService.prototype.save = function (accountId, locationId) {
        var params = new HttpParams();
        if (environment.queuesEnabled) {
            params = params.append('enqueue', Queue.COMBINED_EXPRESS);
        }
        return this.http.post(environment.apiUrl + "/v2/observations/" + accountId + "/" + locationId, {}, { params: params });
    };
    ObservationService.prototype.get = function (accountId, locationId) {
        return this.http.get(environment.apiUrl + "/v2/observations/" + accountId + "/" + locationId).pipe(map(function (value) { return value.data; }));
    };
    ObservationService.prototype.sendAddAccount = function () {
        this.subjectAddAccount.next();
    };
    ObservationService.prototype.getAddAccount = function () {
        return this.subjectAddAccount.asObservable();
    };
    ObservationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ObservationService_Factory() { return new ObservationService(i0.ɵɵinject(i1.HttpClient)); }, token: ObservationService, providedIn: "root" });
    return ObservationService;
}());
export { ObservationService };
