/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./location-more-hours.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/material/tooltip";
import * as i8 from "@angular/cdk/overlay";
import * as i9 from "@angular/cdk/scrolling";
import * as i10 from "@angular/cdk/platform";
import * as i11 from "@angular/cdk/bidi";
import * as i12 from "@angular/platform-browser";
import * as i13 from "./location-more-hours.component";
import * as i14 from "@angular/material/dialog";
import * as i15 from "../../../services/location.service";
var styles_LocationMoreHoursComponent = [i0.styles];
var RenderType_LocationMoreHoursComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LocationMoreHoursComponent, data: {} });
export { RenderType_LocationMoreHoursComponent as RenderType_LocationMoreHoursComponent };
function View_LocationMoreHoursComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "d-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h4", [["class", "w--100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["More hours"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [["class", "w--100 wrap"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Add hours"]))], null, null); }
function View_LocationMoreHoursComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "txt--uppercase"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.parent.context.$implicit.key; _ck(_v, 1, 0, currVal_0); }); }
function View_LocationMoreHoursComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = ((_v.parent.parent.context.$implicit == null) ? null : _v.parent.parent.context.$implicit.startTime); _ck(_v, 0, 0, currVal_0); }); }
function View_LocationMoreHoursComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-4"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LocationMoreHoursComponent_7)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "col-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "p", [["class", "txt--uppercase"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", " - ", ""])), (_l()(), i1.ɵand(0, [["allDay", 2]], null, 0, null, View_LocationMoreHoursComponent_8))], function (_ck, _v) { var currVal_0 = (_v.parent.context.index === 0); _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.openTime); var currVal_2 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.closeTime); _ck(_v, 7, 0, currVal_1, currVal_2); }); }
function View_LocationMoreHoursComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LocationMoreHoursComponent_6)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.open); _ck(_v, 2, 0, currVal_0); }, null); }
function View_LocationMoreHoursComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LocationMoreHoursComponent_5)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.value; _ck(_v, 2, 0, currVal_0); }, null); }
function View_LocationMoreHoursComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "mt--10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "p", [["class", "txt--uppercase mb--5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "w--100 row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_LocationMoreHoursComponent_4)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i2.KeyValuePipe, [i1.KeyValueDiffers])], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(_v.context.$implicit.periodsByDay, _co.originalOrder)); _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.displayName; _ck(_v, 4, 0, currVal_0); }); }
function View_LocationMoreHoursComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "d-block w--100"]], [[2, "txt--medium-gray", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h4", [["class", "w--100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["More hours"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LocationMoreHoursComponent_3)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.moreHoursFormatted; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isUnderReview; _ck(_v, 0, 0, currVal_0); }); }
function View_LocationMoreHoursComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "btn btn-icon btn-icon--gray"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openMoreHoursDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵeld(2, 16777216, null, 0, 1, "em", [["class", "fas fa-pencil"], ["matTooltip", "You must add regular hours before you can add more hours. "], ["matTooltipClass", "tooltip tooltip--black"], ["matTooltipPosition", "below"]], null, [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 212992, null, 0, i7.MatTooltip, [i8.Overlay, i1.ElementRef, i9.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i10.Platform, i5.AriaDescriber, i5.FocusMonitor, i7.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i11.Directionality], [2, i7.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i12.HAMMER_LOADER]], { position: [0, "position"], disabled: [1, "disabled"], message: [2, "message"], tooltipClass: [3, "tooltipClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.countRegularHours == 0); _ck(_v, 1, 0, currVal_2); var currVal_3 = "below"; var currVal_4 = (_co.countRegularHours > 0); var currVal_5 = "You must add regular hours before you can add more hours. "; var currVal_6 = "tooltip tooltip--black"; _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_LocationMoreHoursComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "txt-pendingMask"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "under-review"], ["role", "alert"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Under review"]))], null, null); }
export function View_LocationMoreHoursComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "box__item box__item--editable"]], [[2, "pb--0", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "d-flex w--100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "icon icon--rounded icon--gray mr--20 d-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", ""], ["class", "mw--xxs"], ["src", "../../../assets/images/icons/more-clock.svg"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LocationMoreHoursComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LocationMoreHoursComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LocationMoreHoursComponent_9)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LocationMoreHoursComponent_10)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !((_co.moreHoursFormatted == null) ? null : _co.moreHoursFormatted.length); _ck(_v, 5, 0, currVal_1); var currVal_2 = ((_co.moreHoursFormatted == null) ? null : _co.moreHoursFormatted.length); _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.isEdit; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.isUnderReview; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isUnderReview; _ck(_v, 0, 0, currVal_0); }); }
export function View_LocationMoreHoursComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-location-more-hours", [], null, null, null, View_LocationMoreHoursComponent_0, RenderType_LocationMoreHoursComponent)), i1.ɵdid(1, 114688, null, 0, i13.LocationMoreHoursComponent, [i14.MatDialog, i15.LocationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LocationMoreHoursComponentNgFactory = i1.ɵccf("app-location-more-hours", i13.LocationMoreHoursComponent, View_LocationMoreHoursComponent_Host_0, { location: "location", moreHours: "moreHours", moreHoursLabels: "moreHoursLabels", isEdit: "isEdit", isUnderReview: "isUnderReview", countRegularHours: "countRegularHours", placeId: "placeId" }, { onUpdate: "onUpdate" }, []);
export { LocationMoreHoursComponentNgFactory as LocationMoreHoursComponentNgFactory };
