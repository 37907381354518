// dep
import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http'
import {AngularFirestore} from "@angular/fire/firestore";
import * as _ from 'lodash';

// app
import {environment} from "@environment";
import {DOMAINS} from "../constants/firestore/collections";
import { HEADERS_NO_AUTH } from '../constants/auth'
import { Domain } from '../constants/firestore/domain'


@Injectable({
  providedIn: 'root'
})

/**
 * this service manage the white Label properties
 * TODO: Misleading name, this manages all the domains, unify with domain.service.ts
 */
export class WhiteLabelService {
  constructor(
    private afs: AngularFirestore,
    private _http : HttpClient,
    ) {}  

  private async _fetchDomainData(xdomain : string) : Promise<Domain> {
    return (await this.afs.collection(DOMAINS).doc(xdomain).get().toPromise()).data() as Domain
  }

  // TODO: Promise returning getter, bad
  get branding() : Promise<Domain['branding']> {
    return (async () => (await this._fetchDomainData(this.slugDomain)).branding)()
  }

  // Todo: This is temporary until we implement fetch from DB
  // main-api URL
  get apiUrl(): string {
    return environment.apiUrl;
  }

  async getDynamicWhiteLabel() : Promise<firebase.firestore.DocumentData> {
    // TODO: Should be coalesced to the coreApi endpoint when ditching Firestore
    const domainP = this._http.get<any>(`${environment.coreApiUrl}/domains/${this.domainName}`, HEADERS_NO_AUTH).toPromise()
    const domainFs = (await this.afs.collection(DOMAINS).doc(`${this.slugDomain}`).get().toPromise()).data()
    let domain = { trialDays : 30 }
    try {
      domain = (await domainP).data
    } catch(e) {
      // Can fail because not all domains are yet in Mongo, pass
      // Remove this try/catch after they are migrated from FS
    }

    return {...domainFs,
            ...domain }
  }

  get domainName() : string {
    return this.baseDomain.split(":")[0]
  }

  get baseDomain(): string {
    return (_.startsWith(window.location.hostname, 'localhost') ? 'localhost' : window.location.hostname)
  }

  /**
   * Current domain name in 'xdomain' format (e.g. "app_maplabs_com")
   */
  get slugDomain(): string {
    return this.domainName.replace(/\./g, '_');
  }

  get apiUrlForGoogle() : string {
    const url = environment.apiUrl
    return url.endsWith('/api') ? url.substring(0, url.length - 4) : url
  }

  get domainUser() : string {
    return `${window.location.hostname}`;
  }

  get domain() : string {
    return `${window.location.hostname}:${window.location.port}`;
  }

 /**
   * Returns the public key used to create add-credit-card tokens configured
   * for the current domain for the Development environment (Stripe test mode)
   */
  async getStripeTestMaplabsPublicKey() : Promise<string> {
    return (await this._fetchDomainData('localhost')).stripe_keys.public_key
  }

  /**
   * Returns the public key used to create add-credit-card tokens configured
   * for the current domain.
   */
  async getStripePublicKey() : Promise<string> {
    // Always uses the maplabs PK, no matter the WL domain, because billing-api 
    // runs with the maplabs Stripe key, not the one with the WL Stripe (Connect)
    // Accounts.
    return (await this._fetchDomainData('app_maplabs_com')).stripe_keys.public_key
  }

  async getStripeConnectAccountId() : Promise<string | undefined> {
    return (await this.getDynamicWhiteLabel())?.stripeConnect?.stripeAccountId
  }


  get supportMailURL() : string {
    const b = environment?.branding as any
    return b?.contact_us_link || `mailto:${b?.contact_us}`
  }

  private async _openNewTab(link_name : string, route : string) : Promise<void> {
    const {content} = await this.getDynamicWhiteLabel()
    const url = _.get(content, link_name);
    window.open(url || `/text-reader/${route}`, '_blank')
  }
  
  async goToTermsOfService(): Promise<void> {
    return this._openNewTab('terms_service_link', 'terms-and-conditions')
  }

  async goToPrivacyPolicy(): Promise<void> {
    return this._openNewTab('privacy_policy_link', 'privacy-policy')
  }

  async goToPaymentPolicy(): Promise<void> {
    return this._openNewTab('payment_policy_link', 'payment-policy')
  }

  async goToContactSupport(): Promise<void> {
    window.open(this.supportMailURL)
  }


}
