
<div class="box box--rounded box--shadow bg--white mb--30" *ngIf="dataset">
  <div class="box__header d-flex align-items-center justify-content-start">
    <h2 class="txt--capitalize"><strong>Custom Chart</strong></h2>
    <div class="d-flex align-items-right p--lr-10 btn-pull--right">
      <button mat-button color="primary" class="btn btn-cancel" (click)="clearChecks()">Close</button>
    </div> 
  </div>
  <div class="box__content box--padding-xxs">
    <div *ngIf="dataset" >
      <!--checkbox-->
      <div class="p--lr-30 d-flex justify-content-start align-items-center flex-wrap mb--10">
        <div class="d-flex align-items-center mb--10 mr--10" *ngFor="let item of dataset; let i = index">
          <div (click)="multiChartLine.hideDataset(i, !multiChartLine.isDatasetHidden(i))" class="mat-checkbox--square mr--10" [ngStyle]="{'border': '2px solid ' + item.borderColor}">
            <div *ngIf="!multiChartLine.isDatasetHidden(i)" class="mat-checkbox--square--check" [ngStyle]="{'border': 'solid ' + item.borderColor, 'border-width': '0 2px 2px 0'}">
            </div>
          </div>
          <p class="txt--sm mr--5 mb--0" >{{item.label}}</p>
        </div>
      </div>
    </div>
    <div class="w--100" style="min-height: 300px">
      <canvas #multiChartLine="base-chart" baseChart
              [datasets]="dataset"
              [labels]="labels"
              [legend]="barChartLegend"
              [options]="barChartOptions"
              [colors]="colors"
              [chartType]="barChartType">
      </canvas>
    </div>
  </div>
</div>
