<ng-container *ngIf="FrecuencySelect === 'WeeklyOn'; else MonthDaySelector">
    <div class="dialog-row p-tb--0">
        <mat-label class="d-block mb--10">Repeat on</mat-label>
        <div class="d-flex m-auto">
            <span class="frecuency-week-selector" (click)="selectDay('0')" [ngClass]="{'activate': daySelected === '0'}" >S</span>
            <span class="frecuency-week-selector" (click)="selectDay('1')" [ngClass]="{'activate': daySelected === '1'}">M</span>
            <span class="frecuency-week-selector" (click)="selectDay('2')" [ngClass]="{'activate': daySelected === '2'}">T</span>
            <span class="frecuency-week-selector" (click)="selectDay('3')" [ngClass]="{'activate': daySelected === '3'}">W</span>
            <span class="frecuency-week-selector" (click)="selectDay('4')" [ngClass]="{'activate': daySelected === '4'}">T</span>
            <span class="frecuency-week-selector" (click)="selectDay('5')" [ngClass]="{'activate': daySelected === '5'}">F</span>
            <span class="frecuency-week-selector" (click)="selectDay('6')" [ngClass]="{'activate': daySelected === '6'}">S</span>
        </div>
        <mat-error *ngIf="!daySelected">
          <p>The day must be between Monday and Sunday</p>
        </mat-error>
    </div>
</ng-container>
<ng-template #MonthDaySelector>
    <div class="dialog-row p-tb--0">
        <mat-label class="d-block mb--10">Repeats on:</mat-label>
        <div class="row" style="margin: auto 0px;">
          <div class="col-1">
            <h6 style="padding: 10px 0px; margin: 0;">The</h6>
          </div>
          <div class="col-3">
            <mat-form-field
              floatLabel="never"
              class="input-group input-group--override input-group--override-label"
              style="text-align: center;"
            >
              <input
                [formControl]="daySelectedControl"
                matInput
                required
                type="number"
                min="1"
                max="28"
                class="input-group__field fw--normal"
                (input)="SelectCalendar()"
              />
            </mat-form-field>
          </div>
          <div class="col-8">
            <h6 style="padding: 10px 0px; margin: 0;">day of the month</h6>
          </div>
          <div class="col-12">
            <div *ngIf="daySelectedControl.invalid">
              <p class="mb--10 mat-error">The day must be between 1st and 28th</p>
            </div>
          </div>
        </div>
      </div>
</ng-template>

