<div class="spinner-wrapper" *ngIf="isProgress">
  <gmb-spinner></gmb-spinner>
</div>

<div *ngIf="(error != {} && error_details?.length > 0) && !isProgress">
  <app-error-message [error]="error" [error_details]="error_details"></app-error-message>
</div>

<mat-tab-group #tabGroup (selectedTabChange)="tabChange($event, inputUpload)" [selectedIndex]="0"  *ngIf="!isProgress">
  <ng-container *ngFor="let category of categories" >
    <mat-tab [label]="category.name">
      <div *ngIf="!showOnlyMedia">
        <div class="d-flex justify-content-between pt--20 mb--20">
          <div>
            <div class="input-field--image input-field--image--lg">
              <div class="spinner-wrapper spinner-wrapper--bg spinner-wrapper--fixed" *ngIf="isUploading">
                <mat-spinner></mat-spinner>
              </div>
              <app-form-input-upload #inputUpload
                [urlInput]="previewImage"
                [placeId]="locationId"
                [isMultiple]="true"
                (url)="handleUrl($event)"
                [category]="category.name === 'VIDEO' ? 'VIDEO' : 'PHOTO'"
                [requirements]="imageRequirements"
                actionText="media"
                (changedImg)="clearImagen()">
              </app-form-input-upload>
                <div>
                  <button *ngIf="imageSelectedUrl" mat-flat-button color="warn" class="btn btn-icon btn-icon--remove" matTooltip="Remove" matTooltipPosition="below" matTooltipClass="tooltip tooltip--white" (click)="removeImagen()" style="right: 10px;">
                    <i class="far fa-trash-alt"></i>
                  </button>
                  <button *ngIf="imageSelectedUrl" mat-flat-button class="btn btn-icon btn-icon--plus" matTooltip="Add" matTooltipPosition="below" matTooltipClass="tooltip tooltip--white" (click)="addNewImage()">
                    <i class="far fa-plus"></i>
                  </button>
                </div>
            </div>
            <div class="mt--5">
              <div class="txt--lightgray txt--sm ml--5 d-flex align-items-start">
                <i class="fas fa-info-circle mr--5"></i>
                <p class="mb--10 txt--sm">
                  <!--TODO: instead of tooltip, the requirements can be printed here-->
                  <span class="d-block">Minimum Size: {{imageRequirements.min_width}} x {{imageRequirements.min_height}}</span>
                  <span class="d-block">Maximum Size: {{imageRequirements.max_width}} x {{imageRequirements.max_height}}</span>
                  <span class="d-block">Formats: {{ (imageRequirements.type | acceptFormatImage)[0] }}</span>
                  <span class="d-block">Aspect ratio: {{selectedCategory?.value === 'COVER' ? '16:9' :'4:3'}}</span>
                  <span class="d-block">File Size min/max: 10KB/5MB</span>
                </p>
              </div>
            </div>
          </div>


          <div class="dialog-row w--100">
            <p class="txt--md">Note: Adding {{ (category.name === 'VIDEO') ? 'videos' : 'photos' }} here are
              published immediately to Google.</p>

            <div class="d-flex flex-column align-items-end">
              <mat-form-field class="input-field w--100">
                <input matInput placeholder="Description" name="description" [(ngModel)]="description" (ngModelChange)="changeDescription()">
              </mat-form-field>
              <button mat-flat-button *ngIf="!bulk"  (click)="publish(category.value)" tabIndex="-1" color="accent" [disabled]="isProgressPublish || imageSelectedUrl === null"
                      class="btn btn--action"><strong>Publish <i *ngIf="isProgressPublish" class="fas fa-spinner fa-pulse"></i></strong></button>
            </div>

            <mat-progress-bar mode="determinate" [value]="percentUpload" *ngIf="percentUpload && percentUpload !=100"></mat-progress-bar>
          </div>
        </div>

        <ng-container *ngIf="imageUrl.length >= 1">
          <swiper [config]="swiperConfig" (click)="changePreview($event.target.src)" class="carousel carousel--thumbs preview-photos">
            <div class="thumb-wrapper" *ngFor="let url of imageUrl">
              <img [src]="url.preview" [ngClass]="url.fileName ? '' : 'new-image'" [alt]="url.preview">
            </div>
          </swiper>
        </ng-container>

      </div>

      <mat-divider *ngIf="!showOnlyMedia"></mat-divider>
      <div class="dialog-row" *ngIf="!bulk || showOnlyMedia">
        <h3 class="mb--20"><strong>Media</strong></h3>

        <div class="d-flex justify-content-start flex-wrap">
          <ng-container *ngIf="category?.name === 'VIDEO'; else Photo">
            <div class="thumbnails-wrapper mr--10 mb--10" *ngFor="let file of VideoList; index as i">
              <div class="thumbnail" (click)="showMedia(VideoList, file,category?.value, i)">
                <img [src]="file?.googleUrl" [alt]="file?.googleUrl">
              </div>
            </div>
          </ng-container>
          <ng-template #Photo>
            <div class="thumbnails-wrapper mr--10 mb--10" *ngFor="let file of listFiles[category?.value]; index as i">
              <div class="thumbnail" (click)="showMedia(listFiles[category?.value], file,category?.value, i )">
                <ng-container *ngIf="category.name=='PROFILE' || file.mediaFormat=='VIDEO'; else googleUrl">
                  <img [src]="file?.thumbnailUrl" [alt]="file?.thumbnailUrl">
                </ng-container>
                <ng-template #googleUrl>
                  <img [src]="file?.googleUrl" [alt]="file?.googleUrl">
                </ng-template>
              </div>
            </div>
          </ng-template>

        </div>
      </div>

    </mat-tab>
  </ng-container>
</mat-tab-group>
