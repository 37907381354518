import { Injectable } from '@angular/core';
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable'
import { WhiteLabelService } from './white-label.service';
import { HttpClient } from '@angular/common/http';
import { AngularFireStorage } from '@angular/fire/storage';

interface jsAuto {
  columns: { dataKey: number, header: any}[],
  data: [{colSpan: number, content: string, rowSpan: number,styles: any }][]
}

@Injectable({
  providedIn: 'root'
})
export class PdfService {

  constructor(private wl: WhiteLabelService, private http: HttpClient, private storage: AngularFireStorage) {
  }

  blobToBase64 = async (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return await new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  async base64AndSave(id, filename, urlWeb?, saveToDisk: boolean = true) {
    const logoHeight = 115;
    window['html2canvas'] = html2canvas;
    const content = document.getElementById(id);
    if (!content) {
      return;
    }

    const logo = await this.wl.getDynamicWhiteLabel().then( b => b.branding?.pdfLogo ? b.branding?.pdfLogo : '').catch( err => '');
    this.i2Canvas(id + " i");
    const image = await html2canvas(content, {
      allowTaint: false,
      scale: 2,
      removeContainer: true,
      useCORS: true,
      logging: false,
    });

    const relation = image.width / image.height;
    let orientation = 'p';
    if (relation > 1) {
      orientation = 'l';
    }

    let doc;
    if (logo !== '' && logo !== undefined && logo) {
      doc = new jsPDF(orientation, 'px', [image.width, image.height + logoHeight]);
    }
    else{
      doc = new jsPDF(orientation, 'px', [image.width, image.height]);
    }

    doc.internal.pageSize.height = image.height;
    doc.internal.pageSize.width = image.width;

    const height = doc.internal.pageSize.height;
    const width = doc.internal.pageSize.width;

    if (logo !== '' && logo !== undefined && logo) {
      const downoloadBlob = await fetch(logo).then( d => d.blob())
      const converToBase64 = await this.blobToBase64(downoloadBlob)
      const typeImage = downoloadBlob.type.split('/')[1].toUpperCase();
      doc.addImage(converToBase64, typeImage, 20, 10, 300, logoHeight);
      doc.addImage(image, 'JPEG', 5, 135, width, height - logoHeight, 'a', 'FAST');
    } else {
      doc.addImage(image, 'JPEG', 5, 20, width, height, 'a', 'FAST');
    }


    doc.setFontSize(40);
    doc.setTextColor(229, 229, 229);

    for (let h = 200; h < height; h = h + 900) {
      for (let w = 200; w < width; w = w + 700) {
        doc.text(w, h, urlWeb, null, 45);
      }
    }

    if(saveToDisk){
      doc.save(`${filename}.pdf`);
    }

    return doc.output('datauristring');
  }

  i2Canvas(idContainer): void {

    const icontainers = Array.prototype.slice.call(document.querySelectorAll('#' + idContainer), 0) || [],
      trimquotes_rg = /^["']*|["']+$/g;

    icontainers.forEach(icontainer => {

      const canvas = document.createElement("canvas"),
        iconparent = icontainer.parentNode,
        compsty = window.getComputedStyle(icontainer, ':before'),
        fontColor = window.getComputedStyle(icontainer, null).color,
        textSize = compsty.fontSize,
        charAwe = compsty.content.replace(trimquotes_rg, "");

      canvas.setAttribute("style", "height: 1em; width: .7em;");
      canvas.setAttribute("class", icontainer.className);

      iconparent.replaceChild(canvas, icontainer);

      const ctx = canvas.getContext("2d");
      this.prepareContext(ctx, fontColor, textSize);

      const charW = Math.ceil(ctx.measureText(charAwe).width);

      canvas.style.width = charW + 'px';
      canvas.width = charW;
      canvas.height = parseInt(textSize);
      if (icontainer.className.includes('icon-respose')) {
        const style = window.innerWidth < 1550 ? 'height: 20px; width: 20px; margin-right: 10px;' : 'height: 25px; width: 25px; margin-right: 15px;';
        canvas.setAttribute('style', style);
      }
      this.prepareContext(ctx, fontColor, textSize);
      ctx.scale(1, 1);
      ctx.fillText(charAwe, canvas.width / 2, canvas.height / 2);
    });
  }

  prepareContext(context, fontColor, textSize) {
    context.font = textSize + " 'Font Awesome 5 Pro'";
    context.textAlign = "center";
    context.textBaseline = "middle";
    context.fillStyle = fontColor;
  }

  async exportTableToPDF(idTable: string, filename: string, title: string, exclude =false, formatName: string = null, columnStyles: {} = {}): Promise<void> {
    const doc = new jsPDF('p', 'pt', [window.innerWidth, window.innerHeight]);

    doc.text(40, 35, title);

    if (exclude) {
      const idElement = idTable.substring(1);
      const res = doc.autoTableHtmlToJson(document.getElementById(idElement));
      let indexFormat = -1;
      const headers = res.data[0].reduce((response: number[], data, i) => {
        if (formatName && formatName.toLowerCase().trim().includes(data.content.toLowerCase().trim())) indexFormat = i
        if (data._element.classList.contains('exclude'))  response.push(i)
        return response
      }, [])
      const columns = res.columns.filter( (c: string, i ) => !headers.some( postion => postion === i));
      doc.autoTable({
        columns: columns,
        cellWidth: { name: { columnWidth: 'auto' }, country: { columnWidth: 'auto' } },
        body: res.data.map( d => {
          d[0].content = d[0].content.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          if (indexFormat >= 0) {
            const chararacter = d[indexFormat].content.match(/[A-Za-z]\d/)[0].split('');
            const message = d[indexFormat].content.split(/[A-Za-z]\d/);
            const result = `${message[0]}${chararacter[0]} ${chararacter[1]}${message[1]}`;
            d[indexFormat].content = result;
          }
          return d;
        }),
        columnStyles: columnStyles
      });

    } else {
      doc.autoTable({
        html: idTable,
        body: [
          [{ content: 'Text', colSpan: 2, rowSpan: 2, styles: { halign: 'center' } }],
        ]
      });
    }

    doc.save(`${filename}.pdf`);
  }
}
