/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-input-upload-multiple.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./form-input-upload-multiple.component";
import * as i8 from "../../services/storage-image.service";
import * as i9 from "../../services/modal.service";
import * as i10 from "../../services/snackbar.service";
var styles_FormInputUploadMultipleComponent = [i0.styles];
var RenderType_FormInputUploadMultipleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormInputUploadMultipleComponent, data: {} });
export { RenderType_FormInputUploadMultipleComponent as RenderType_FormInputUploadMultipleComponent };
function View_FormInputUploadMultipleComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "spinner-wrapper spinner-wrapper--bg spinner-wrapper--sticky"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(2, 114688, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 2).diameter; var currVal_2 = i1.ɵnov(_v, 2).diameter; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_FormInputUploadMultipleComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "d-block txt--sm txt--lightgray"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageTxt; _ck(_v, 1, 0, currVal_0); }); }
export function View_FormInputUploadMultipleComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { fileInput: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "col-2.5 input-field input-field--image bg--gray"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormInputUploadMultipleComponent_1)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 4, "label", [["class", ""]], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "preview-text txt--center p--6 border--dashed border--darkgray"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["alt", "Upload photos here."], ["class", "img--xs mb--10"], ["src", "/assets/images/add_media.svg"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormInputUploadMultipleComponent_2)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, [[1, 0], ["fileInput", 1]], null, 0, "input", [["hidden", ""], ["name", "fileMedia"], ["type", "file"]], [[8, "id", 0], [8, "multiple", 0], [8, "accept", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.filesChanged($event.target) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isUploading; _ck(_v, 3, 0, currVal_0); var currVal_2 = (_co.actionText.toLowerCase() == "post"); _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, "fileInput", _co.imageId, ""); _ck(_v, 4, 0, currVal_1); var currVal_3 = i1.ɵinlineInterpolate(1, "fileInput", _co.imageId, ""); var currVal_4 = _co.multiple; var currVal_5 = _co.requirements.accepts; _ck(_v, 9, 0, currVal_3, currVal_4, currVal_5); }); }
export function View_FormInputUploadMultipleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-form-input-upload-multiple", [], null, null, null, View_FormInputUploadMultipleComponent_0, RenderType_FormInputUploadMultipleComponent)), i1.ɵdid(1, 245760, null, 0, i7.FormInputUploadMultipleComponent, [i8.StorageImageService, i9.ModalService, i10.SnackbarService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormInputUploadMultipleComponentNgFactory = i1.ɵccf("app-form-input-upload-multiple", i7.FormInputUploadMultipleComponent, View_FormInputUploadMultipleComponent_Host_0, { urlInput: "urlInput", requirements: "requirements", multiple: "multiple", maxNumberPhotos: "maxNumberPhotos", imageId: "imageId", actionText: "actionText" }, { ref: "ref" }, []);
export { FormInputUploadMultipleComponentNgFactory as FormInputUploadMultipleComponentNgFactory };
