import { Component, Input, OnInit } from '@angular/core';
import { LocationState } from 'src/app/constants/location-info';

@Component({
  selector: 'app-location-state',
  templateUrl: './location-state.component.html',
  styleUrls: ['./location-state.component.scss']
})
export class LocationStateComponent implements OnInit {
  @Input() state: LocationState;
  @Input() isEdit: boolean;

  constructor() { }

  ngOnInit() {
  }

  keys() : Array<string> {
    return Object.keys(this.state);
  }

  convertKeyToText(key: string) {
    let text = key.match(/[A-Z]*[^A-Z]+/g) 
    let firstData = text[0];
    text[0] = firstData.charAt(0).toUpperCase() + firstData.slice(1)
    return  text.join(" ")
  }

}
