/**
 * Session Trace service
 * 
 * Currently uses userguiding.com to track user actions and events.
 * 
 * See:
 *   - https://help.userguiding.com/en/articles/2869066-what-are-the-containers 
 *   - https://help.userguiding.com/en/articles/2824058-enabling-user-identification
 *   - https://help.userguiding.com/en/articles/3420081-userguiding-javascript-api
 *   - https://help.userguiding.com/en/articles/5562847-sending-user-attributes-and-tracking-user-actions
 *   - https://user.userguiding.com/docs
 * 
 * Note:
 *   - "UserGuiding history is independent of the browser's local storage, so clearing 
 *      cookies or application data won't affect it".
 *   - "User Identification ensures that users see guides only once, even when accessing 
 *      them from different browsers or devices".
 */

// dep
import { Injectable } from '@angular/core';
// import { HttpClient, HttpHeaders } from '@angular/common/http';

// app
// import { HEADERS_NO_AUTH } from '../constants/auth';

declare var gtag : any

const ZAPIER_URL = 'https://hooks.zapier.com/hooks/catch/13075898/23djv7q/'
  
@Injectable({
  providedIn: 'root'
})
export class SessionTraceService {

  private _isGtagEnabled = false
  private _isUserGuidingEnabled = false 
  private _uid : string | null = null
  private _info : Object = null

  constructor(
    // private _http: HttpClient
  ) {
    // pass
  }

  /**
   * Set if the service is enabled, if not, all calls will be ignored and no
   * communication with UserGuiding will be made.
   * @param enabled 
   */
  setEnableUserGuiding(enabled : boolean) : void {
    this._isUserGuidingEnabled = enabled
  }

  /**
   * Set if the gtag service is enabled, if not, all calls will be ignored and no
   * communication with gtag will be made.
   * @param enabled 
   */
  setEnableGtag(enabled : boolean) : void {
    this._isGtagEnabled = enabled
  }

  private analyticsQ() : any[] {

    const w = window as any
    w.jitsuQ = w.jitsuQ || []
    return w.jitsuQ
  }

  private async _userGuiding() : Promise<any> {
    // UserGuiding already initialized from index.html on an async fashion
    // using the code provided at https://panel.userguiding.com/settings/containers 
    try {
      const w = window as any
      await w.userGuidingIsLoaded
      return w.userGuiding
    } catch(err) {
      console.error(err)
    }
  }

  private async _track(event : string, info? : object ) : Promise<void> {
    try {
      // Gtag event
      if(this._isGtagEnabled) {
        try {
          gtag('event', event)
        } catch(err) {
          console.error(err)
        }
      }

      // SEE https://docs.jitsu.com/sending-data/html#jitsu-processing-queue
      this.analyticsQ().push(function(jitsu) {
        jitsu.track(event, info)
      })
        

      // track via ug
      if(this._isUserGuidingEnabled)
        // TODO: UserGuiding doesn't have an specific way to signal user logout?
        return (await this._userGuiding()).track(event, info)
    } catch(err) {
      console.error(err)
    }
  }

  private async _identify( uid : string, info : object) : Promise<void> {
    try {
      // SEE https://docs.jitsu.com/sending-data/html#jitsu-processing-queue
      this.analyticsQ().push((jitsu : any) => jitsu.identify(uid, info))

      if(this._isUserGuidingEnabled)
        return (await this._userGuiding()).identify(uid, info)
    } catch(err) {
      console.error(err)
    }
  }

  /**
   * Must be called when the user logs in. 
   */
  async onLogin(uid : string, info : { domain            : string,
                                       gid               : string,
                                       name              : string, 
                                       email             : string,
                                       created_at        : string,
                                       isTrial           : boolean,
                                       essentialListings : number
                                       basicListings     : number,
                                       ultimateListings  : number
                                       totalListings     : number }) : Promise<void> {
    if(this._uid)
        // avoid double log-in miscalls
        return

    this._uid = uid
    this._info = info
    this._identify(uid, info)
  }

  /**
   * Must be called when the user logs out.
   */
  async onLogout() : Promise<void> {
    const uid = this._uid 
    this._uid = null
    this._info = null
    this._track("session logout", { userId : uid })
  }

  /**
   * Must be called when a new Location is added.
   */ 
  async onLocationAdded(accountId : string, locationId : string, totalLocations : number) : Promise<void> {
    this._track("location added", { userId : this._uid,
                                    accountId, 
                                    locationId,
                                    totalLocations })
  }

  async onFirstLocationAdded() : Promise<void> {
    /**
     * See MAP-2437: Jitsu>Hubspot wont work so we have to rely on Zapier hook
     * The most prudent way is to fire an event directly to to existing Zapier hook
     */
    try {
      const body = { data: {
          email: this._info['email'],
          gid: this._info['gid'],
          uid: this._uid,
          add_first_listing: "true"
        }
      }

      // When using Angular HTTPClient, a default Content-Type 'application/json' is
      // added. This rejected by Zapier CORS, see:
      // - https://help.zapier.com/hc/en-us/articles/8496291737485-Troubleshoot-webhooks-in-Zapier#h_01HCZE2Q4N14HEFS0CWTXZ833C
      // So we need to use plain fetch(), as the Angular HTTPClient doesn't seems to allow
      // not sending the Content-Type header.
      //
      // await this._http.post<any>(ZAPIER_URL, body, HEADERS_NO_AUTH).toPromise()
      await fetch(ZAPIER_URL, { method: 'POST', 
                                body:   JSON.stringify(body), 
                                mode:   'no-cors'})

      console.info("Event succeeded: Added first listing")
    } catch(err) {
      console.error('Error sending info to Zapier', err)
    }

    this._track('addFirstListing')
  }

  async onRegistration() : Promise<void> {
    this._track('completeSignup')
  }
}