<ng-container *ngIf="type === 'OBSERVATION'">

  <div class="box box--padding-sm-tb d-flex justify-content-start border-bottom"  *ngFor="let i of [0,1,2]">
    <div class="icon icon--rounded icon--gray">
      <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '35px', height: '35px'}"></ngx-skeleton-loader>
    </div>
    <div class="flex-grow-1">
      <ngx-skeleton-loader count="1" [theme]="{ width: '300px', height: '25px'}"></ngx-skeleton-loader>
      <ngx-skeleton-loader count="1"[theme]="{ width: '100%', height: '40px', 'margin-top': '25px'}"></ngx-skeleton-loader>
    </div>
  </div>

</ng-container>

<ng-container *ngIf="type === 'METRICS'">

  <div class="row">
    <div class="col-md-3" *ngFor="let i of [0,1,2,3]">
      <div class="carousel__item" style="height: 350px">
        <div class="box box--rounded box--shadow box--padding-xs bg--white txt--center">

          <div class="icon--center mb--25">
            <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '80px', height: '80px'}"></ngx-skeleton-loader>
          </div>

          <div class="mb--15">
            <ngx-skeleton-loader count="1" [theme]="{ width: '150px', height: '20px'}"></ngx-skeleton-loader>
          </div>

          <ngx-skeleton-loader count="5" [theme]="{ width: '100px', height: '15px', display: 'block', 'margin': '5px auto'}"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>

</ng-container>
