import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: './blank-layout.component.html'
})
export class BlankLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.checkForTrialMessage();
  }

  checkForTrialMessage() {
    let trialMessageLength = document.querySelectorAll('.js-trial-message').length;
    if(trialMessageLength) {
      document.querySelector('body').classList.remove('trial-message-wrapper');
    }
  }
}
