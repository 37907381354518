/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i2 from "@angular/material/progress-spinner";
import * as i3 from "@angular/cdk/platform";
import * as i4 from "@angular/common";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "../../modules/trial-message/trial-message.component.ngfactory";
import * as i7 from "../../modules/trial-message/trial-message.component";
import * as i8 from "@angular/router";
import * as i9 from "../../services/subscription.service";
import * as i10 from "../../services/auth.service";
import * as i11 from "../../services/location.service";
import * as i12 from "../../modules/impersonate-message/impersonate-message.component.ngfactory";
import * as i13 from "../../modules/impersonate-message/impersonate-message.component";
import * as i14 from "../../modules/header/header.component.ngfactory";
import * as i15 from "../../modules/header/header.component";
import * as i16 from "../../services/domain.service";
import * as i17 from "@angular/material/dialog";
import * as i18 from "../../services/group.service";
import * as i19 from "../../services/notification.service";
import * as i20 from "../../services/global-search.service";
import * as i21 from "../../services/account.service";
import * as i22 from "../../services/modal.service";
import * as i23 from "../../modules/main/main.component.ngfactory";
import * as i24 from "../../modules/main/main.component";
import * as i25 from "@angular/platform-browser";
import * as i26 from "../../services/white-label.service";
import * as i27 from "../../services/data-transfer.service";
import * as i28 from "./default-layout.component";
import * as i29 from "../../services/spinner.service";
var styles_DefaultLayoutComponent = [];
var RenderType_DefaultLayoutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DefaultLayoutComponent, data: {} });
export { RenderType_DefaultLayoutComponent as RenderType_DefaultLayoutComponent };
function View_DefaultLayoutComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "spinner-wrapper spinner-wrapper--bg spinner-wrapper--fixed"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i1.View_MatSpinner_0, i1.RenderType_MatSpinner)), i0.ɵdid(2, 114688, null, 0, i2.MatSpinner, [i0.ElementRef, i3.Platform, [2, i4.DOCUMENT], [2, i5.ANIMATION_MODULE_TYPE], i2.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2)._noopAnimations; var currVal_1 = i0.ɵnov(_v, 2).diameter; var currVal_2 = i0.ɵnov(_v, 2).diameter; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_DefaultLayoutComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-trial-message", [], null, null, null, i6.View_TrialMessageComponent_0, i6.RenderType_TrialMessageComponent)), i0.ɵdid(1, 114688, null, 0, i7.TrialMessageComponent, [i8.ActivatedRoute, i9.SubscriptionService, i10.AuthService, i11.LocationService], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-impersonate-message", [], null, null, null, i12.View_ImpersonateMessageComponent_0, i12.RenderType_ImpersonateMessageComponent)), i0.ɵdid(3, 114688, null, 0, i13.ImpersonateMessageComponent, [i10.AuthService], null, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_DefaultLayoutComponent_1)), i0.ɵdid(5, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(7, 0, null, null, 1, "app-header", [], null, null, null, i14.View_HeaderComponent_0, i14.RenderType_HeaderComponent)), i0.ɵdid(8, 245760, null, 0, i15.HeaderComponent, [i10.AuthService, i16.DomainService, i17.MatDialog, i8.Router, i18.GroupService, i19.NotificationService, i11.LocationService, i20.GlobalSearchService, i21.AccountService, i22.ModalService], { noToggle: [0, "noToggle"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 1, "app-main", [], null, null, null, i23.View_MainComponent_0, i23.RenderType_MainComponent)), i0.ɵdid(10, 245760, null, 0, i24.MainComponent, [i8.Router, i8.ActivatedRoute, i25.Title, i26.WhiteLabelService, i27.DataTransferService], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); _ck(_v, 3, 0); var currVal_0 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform(_co.spinnerService.loading$)); _ck(_v, 5, 0, currVal_0); var currVal_1 = true; _ck(_v, 8, 0, currVal_1); _ck(_v, 10, 0); }, null); }
export function View_DefaultLayoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-layout", [], null, null, null, View_DefaultLayoutComponent_0, RenderType_DefaultLayoutComponent)), i0.ɵdid(1, 8503296, null, 0, i28.DefaultLayoutComponent, [i10.AuthService, i29.SpinnerService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DefaultLayoutComponentNgFactory = i0.ɵccf("app-layout", i28.DefaultLayoutComponent, View_DefaultLayoutComponent_Host_0, {}, {}, []);
export { DefaultLayoutComponentNgFactory as DefaultLayoutComponentNgFactory };
