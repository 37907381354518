import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormArray, AbstractControl } from '@angular/forms';

import { GoogleAttributes, GroupAttributes } from '../../constants/google/attributes';
import { LocationEditService } from '../../services/location-edit.service';
import { REGEX_COMPLEX_URL } from '../../constants/regex';

@Component({
  selector: 'app-modal-attribute-url',
  templateUrl: './modal-attribute-url.component.html',
  styleUrls: ['./modal-attribute-url.component.scss']
})
export class ModalAttributeUrlComponent implements OnInit {
  loading: boolean;
  public form: FormGroup;

  @Input() attributes: GroupAttributes[];
  @Input() attribute: GoogleAttributes;
  @Input() title: string;
  @Input() bulk = false;
  @Input() attributesUrl = null;
  @Output() validResult: EventEmitter<boolean> = new EventEmitter(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) public config: any,
    private locationEditS: LocationEditService,
    private dialog: MatDialogRef<ModalAttributeUrlComponent>
  ) {
  }

  ngOnInit(): void {
    if (this.config) {
      this.attribute = this.config?.data?.attribute;
      this.initForm();
      this.setForm();
    } else {
      this.initForm();
    }

  }

  initForm() {
    this.form = new FormGroup({
      urls: new FormArray([])
    });
  }

  setForm() {
    this.attribute?.urlValues?.forEach(el => {
      this.addNewUrl(el.url);
    });
  }

  addNewUrl(url = '') {
    this.UrlsArray.push(new FormGroup({
      url: new FormControl(url, [Validators.pattern(REGEX_COMPLEX_URL)])
    }))
  }

  removeUrl(index) {
    this.UrlsArray.removeAt(index);
  }

  apply() {
    this.buildUrl();
    if (this.form.valid) {
      const data = this.buildData();
      const index = this.locationEditS.locationEdit.attributes.findIndex(attribute => attribute.attributeId == this.attribute.attributeId);

      if (index != -1) {
        if (Object.keys(this.locationEditS.locationEdit.attributes[index]).includes('uriValues')) {
          this.locationEditS.locationEdit.attributes[index].urlValues = this.locationEditS.locationEdit.attributes[index].uriValues;
          this.locationEditS.locationEdit.attributes[index].urlValues.forEach(el => {
            el.url = el.uri;
            delete el.uri
          })
        }
        this.locationEditS.locationEdit.attributes[index].urlValues = data;
      } else {
        this.locationEditS.locationEdit.attributes.push({
          attributeId: this.attribute.attributeId,
          valueType: this.attribute.valueType,
          urlValues: data
        });
      }
      this.loading = true;
      this.locationEditS.setAndUpdate().toPromise().then(() => {
        this.loading = false;
        this.dialog.close();
      });
    }
  }

  buildUrl() {
    this.UrlsArray.controls.forEach(c => {
      const url = c.value?.url;
      const hasHttp = url.substr(0, 7) === 'http://' ? true : false;
      const hasHttps = url.substr(0, 8) === 'https://' ? true : false;

      if (!hasHttp && !hasHttps) {
        c['controls']['url'].setValue(`http://${url}`)
      }
    })
  }

  buildData() {
    const data = [];
    this.UrlsArray.controls.forEach(el => {
      if (el.value?.url != '') { 
        el.value.url = el.value.url?.replace(/ /g, '%20');
        data.push(el.value) 
      }
    });

    return data;
  }

  getResult(): { attributes: GoogleAttributes[]} {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.validResult.emit(false);
      return;
    }
    const data = this.buildData();

    if(this.attribute) {
      if ('active' in this.attribute) delete this.attribute['active']
      if ('displayName' in this.attribute) delete this.attribute['displayName']
      if ('groupDisplayName' in this.attribute) delete this.attribute['groupDisplayName']
      if ('isRepeatable' in this.attribute) delete this.attribute['isRepeatable']
      if ('isDeprecated' in this.attribute) delete this.attribute['isDeprecated']
    }
      

    this.validResult.emit(true);
    return {attributes: [{...this.attribute, urlValues: data}] }
  }

  get UrlsArray(): FormArray {
    return (this.form.get('urls') as FormArray);
  }

  getUrlArray(index): AbstractControl {
    return (this.form.get('urls') as FormArray).at(index);
  }

  addBtnShown() {
    return (
      this.UrlsArray.controls.length == 0 ||
      (this.bulk && this.attributesUrl[0]?.attributeId == 'url_appointment' ||
      this.attribute?.['attributeId'] == 'url_appointment' ||
      this.attribute?.['attributeId'] == 'url_order_ahead'  ||
      this.attribute?.['attributeId'] == 'url_reservations') &&
      this.UrlsArray.controls.length < 10
    )
  }

}

