export const Messages = {
  notifications: {
    // NEW_ACCOUNT_USER: "A new account has been created for admin user {0}",
    USER_ADDED: "User {0} was created with role {1}",
    LOCATION_TOGGLE: "{0} {1} was {2}",
    // LOCATION_CHANGE_PLAN: "{0} {1}, plan was changed to {2}",
  },
  register: {
    SUCCESS: 'Registration complete... redirecting to your dashboard.',
    USER_ALREADY_EXIST: 'You need to register first',
    // RECREATED: 'Your account has been re-created! Welcome back!',
    EMAIL_VERIFIED: 'You must verify the registration email'
  },
  reports: {
    SHARE_SUCCESS: 'Report shared successfully!'
  },

  upload: {
    BAD_DIMENSION: 'Image width/height must be less than 2120px / 1192px.',
    BAD_DIMENSION_DEFINED: 'Image width/height must be less than 250px / 100px.',
    BAD_DIMENSION_MINIMUM: 'Image width/height must be greater than 250px / 250px.',
    BAD_SIZE: 'Image size must be at least 10240 bytes.',
    BAD_TYPE: 'Image image must be of type png.',
    BAD_TYPES: 'Image image must be of type.',
    BAD_RATIO: 'Image ratio must be 4:3. Please edit and retry or upload a new image.',
    SEND_SUCCESS: 'Successfully sent media to GBP!',
    BAD_DIMENSION_DEFINED_CUSTOM: 'Image width/height must be less than {0}px / {1}px.',
    BAD_DIMENSION_MINIMUM_CUSTOM: 'Image width/height must be greater than {0}px / {1}px.',
    BAD_SIZE_CUSTOM: 'Image size must be at least {0} bytes.',
    BAD_MAX_SIZE_CUSTOM: 'Image size must be less than {0} bytes.',
  },
  post: {
    PARTIAL_PUBLISH: 'Posts are being published. This can take several minutes. Please refresh after a few minutes',
    PARTIAL_DELETE: 'Posts are being deleted. This can take several minutes. Please refresh after a few minutes',
    PARTIAL_UPDATE: 'Posts are being updated. This can take several minutes. Please refresh after a few minutes',
  }

};


export const string_message = (template: string, data: string[]) => template.replace(/{\w+}/g, placeholder => data[Number(placeholder.substring(1, placeholder.length - 1))] || placeholder);
