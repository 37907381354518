  
export class CreateProfileRequest {
    profiles: ProfileModel[];
}

class GauthStatus {
    isValid: boolean;
}
  
class GoogleAuth {
    refresh_token: string;
    token_expiry: string;
    access_token: string;
}
  
class Account {
    name: string;
    accountName: string;
    type: string;
    role: string;
    verificationState: string;
    vettedState: string;
    accountNumber: string;
    permissionLevel: string;
}
  
export class ProfileModel {
    account: AccountContainer;
    locations: LocationModel[];
}         

export class AccountContainer {
    gid: string;
    accountId: string;
    accountName: string;
    account: Account;
    googleAuthEmailAddress: string;
    gauthStatus: GauthStatus;
    googleAuth: GoogleAuth;
}

export class LocationModel {
    locationName: string;
    lockedOn: string;
    locationId: string;
    subscriptionType: string;
    accountId: string;
    gid: string;
}

