import {Injectable} from '@angular/core';
import {FirestoreService} from './firestore.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {GROUPS, NOTIFICATIONS} from '../constants/firestore/collections';
import {firestore} from 'firebase/app';
import {TYPE_LOG_USER, TYPE_LOG_LOCATION, TYPE_LOG_GRADE, TYPE_LOG_SUBSCRIPTION} from "../constants/notifications";
// import {map} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { Pageable } from '../constants/pageable';

type NOTIFICATION_TYPE = 'success' | 'error' | 'info' | 'Informative';
type TYPE_LOG = 'USER' | 'LOCATION' | 'GRADE' | 'SUBSCRIPTION'

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private fs: FirestoreService,
    private afs: AngularFirestore,
    private http: HttpClient
  ) { }

  saveNotification(gid, domain, message: string, type: NOTIFICATION_TYPE, typeLog: TYPE_LOG, meta?) {
    return this.afs.collection(GROUPS).doc(gid).collection(NOTIFICATIONS)
      .add({gid, domain, message, type, meta, createAt: firestore.Timestamp.now(), status: 'unviewed', typeLog});
  }

  getNotifications(gid) {
    return this.fs.colWithIds$(`${GROUPS}/${gid}/${NOTIFICATIONS}`, ref => ref
      .where('status', '==', 'unviewed').orderBy('createAt', 'desc').limit(100));

  }

  paginateNotifications(gid, pageable: Pageable, next?, prev?, where?:  {field: string, operator: "==" | "<" | ">" | "<=" | ">=" | "array-contains" | "!=", value: any}) {
    return this.fs.paginate<any[]>(`${GROUPS}/${gid}/${NOTIFICATIONS}`, 'createAt', null
    , {
      size: pageable.size,
      page: pageable.page
    }, false, next, prev, null, null, 'desc');

  }

  deleteAll(gid) {
    return this.http.get(`${environment.apiUrl}/v2/groups/gid/${gid}/delete/notification`).toPromise();
  }

  deleteNotification(gid, docId) {
    this.afs.collection(GROUPS).doc(gid).collection(NOTIFICATIONS).doc(docId).delete().then();
  }

  // TODO: Unused, remove
  // countNotifications(gid) {
  //   return this.afs.collection(GROUPS).doc(gid).collection(NOTIFICATIONS,
  //     ref => ref.where('status', '==', 'unviewed')).snapshotChanges().pipe(map(documents => documents.length));
  // }
  // 
  // merge(gid, docId, data) {
  //   return this.afs.collection(GROUPS).doc(gid).collection(NOTIFICATIONS).doc(docId).set(data, {merge: true});
  // }

  getMetaTypeLog(typeLog: string, data: any) {
    switch (typeLog) {
      case TYPE_LOG_USER:
        return { email: data.email, displayName: data.displayName, role: data.role, uid: data.uid}
      case TYPE_LOG_LOCATION:
        return { accountId: data.accountId, locationName: data.locationName, locationId: data.locationId, address: data.address}
      case TYPE_LOG_GRADE:
        return { accountId: data.accountId, email: data.email, displayName: data.displayName, role: data.role, uid: data.uid}
      case TYPE_LOG_SUBSCRIPTION:
        return { accountId: data.accountId, locationId: data.locationId, locationName: data.locationName, subscriptionType: data.subscriptionType, planBefore: data.planBefore, planAfter: data.planAfter}
      default:
        break;
    }
  }

}
