import { OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import moment from 'moment';
import { map, startWith } from 'rxjs/operators';
import { DatesService } from 'src/app/services/dates.service';
import { PostService } from 'src/app/services/post.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
var PostManagementDeleteDateModalComponent = /** @class */ (function () {
    function PostManagementDeleteDateModalComponent(dialogRef, data, _dateS, _postS, _snackS) {
        var _a, _b, _c;
        this.dialogRef = dialogRef;
        this.data = data;
        this._dateS = _dateS;
        this._postS = _postS;
        this._snackS = _snackS;
        this.isProgress = false;
        this.postIds = [];
        this.deleteButtonLabel = 'Delete Now';
        this.deleteWholeSeries = false;
        this.whenToDelete = 'deleteNow';
        this.minDate = new Date();
        this.hoursList = [];
        if (this.data) {
            this.postIds = (_a = this.data) === null || _a === void 0 ? void 0 : _a.postIds;
            this.isMultiplePost = (_b = this.data) === null || _b === void 0 ? void 0 : _b.isMultiplePost;
            this.irRecurring = (_c = this.data) === null || _c === void 0 ? void 0 : _c.irRecurring;
        }
    }
    PostManagementDeleteDateModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.hoursList = this._dateS.loadHoursDropdown(true);
        this.hoursListOptions = new FormControl('').valueChanges.pipe(startWith(''), map(function (value) { return _this._filter(value); }));
    };
    PostManagementDeleteDateModalComponent.prototype.whenDeletedChanged = function () {
        if (this.whenToDelete === 'deleteNow') {
            this.deleteDate = null;
            this.deleteTime = null;
            this.deleteButtonLabel = 'Delete Now';
        }
        else {
            this.deleteButtonLabel = 'Schedule delete';
        }
    };
    PostManagementDeleteDateModalComponent.prototype.deletePosts = function () {
        var _this = this;
        var _a;
        this.isProgress = true;
        var date = this.whenToDelete === 'deleteNow' ? null : moment(this.deleteDate).format('YYYY-MM-DD');
        var time = this.whenToDelete === 'deleteNow' ? null : (_a = this.deleteTime.split(' ')) === null || _a === void 0 ? void 0 : _a[0];
        this._postS.deletePost(this.postIds, date, time, this.deleteWholeSeries).subscribe(function (res) {
            _this.dialogRef.close(true);
        }, function (err) {
            _this._snackS.openError('There was an error while deleting the post', 2000);
            _this.dialogRef.close(false);
        });
    };
    PostManagementDeleteDateModalComponent.prototype.validateInput = function (event) {
        var allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ':', 'A', 'P', 'M', ':', 'BACKSPACE', ' ', 'TAB', 'ARROWLEFT', 'ARROWRIGHT', 'DELETE'];
        var key = event.key.toUpperCase();
        if (!allowedKeys.includes(key)) {
            event.preventDefault();
        }
    };
    Object.defineProperty(PostManagementDeleteDateModalComponent.prototype, "scheduleDateTime", {
        // validateTime(value: string) {
        //   this.timeFormatError = !moment(value, 'hh:mm A', true).isValid();
        // }
        get: function () {
            var formattedDate = null;
            if (this.deleteDate && this.deleteTime) {
                var moment_1 = require('moment-timezone');
                var offset = moment_1(this.parsedDate).format('Z');
                formattedDate = moment_1(this.parsedDate).format('MM/DD/YYYY') + " " + this.deleteTime + " GMT" + offset;
            }
            return formattedDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManagementDeleteDateModalComponent.prototype, "parsedDate", {
        get: function () {
            var date = moment(this.deleteDate).format('YYYY-MM-DD');
            var deleteTime = this.deleteTime ? this.deleteTime : moment().format('hh:mm A');
            var time = moment(deleteTime, "hh:mm A").format("HH:mm:ss");
            return date + "T" + time + "Z";
        },
        enumerable: true,
        configurable: true
    });
    PostManagementDeleteDateModalComponent.prototype._filter = function (value) {
        var filterValue = value.toLowerCase();
        return this.hoursList.filter(function (option) { return option.toLowerCase().includes(filterValue); });
    };
    return PostManagementDeleteDateModalComponent;
}());
export { PostManagementDeleteDateModalComponent };
