<!--number of new reviews-->

<div class="box box--rounded box--shadow bg--white">
  <div class="p--20">
    <h2 class="txt--capitalize d-flex align-items-center txt--md d--block mb--15"><strong>number of new reviews</strong></h2>
    <ngx-skeleton-loader *ngIf="isProgressNumber" count="1" [theme]="{ width: '25px',height: '25px','margin-bottom': '0', 'transform':'translateY(5px)'}"></ngx-skeleton-loader>
    <p class="d--inl-block m--0 txt--price fw--regular" *ngIf="!isProgressNumber">{{total | number: '1.0-2'}}</p>
    <span *ngIf="tooltip"
      [matTooltip]="tooltip"
      matTooltipPosition="above"
      matTooltipClass="mat-tooltip--override"
      class="mat-tooltip tooltip tooltip--info">
      <em class="fas fa-info"></em>
    </span>
  </div>
  
  <div class="box__content box--padding-xxs">
    <app-skeleton-reviews type="NUMBER-NEW-REVIEWS" *ngIf="isProgressNumber"></app-skeleton-reviews>
    <app-chart-line
      type="COLORS-FULL"
      *ngIf="!isProgressNumber"
      [showLegend]="false"
      [labels]="labelsBarRating"
      [dataset]="datasetBarNumber"
      [dataPicker]="dataPicker"
      chartType="bar">

    </app-chart-line>
  </div>
</div>
