<app-skeleton-location-info *ngIf="progress"></app-skeleton-location-info>

<app-dashboard-filter [progress]="progress" [accountId]="accountId" [isProgressFetch]="fetchProgress"
  [isProgressPush]="isProgressPush" typeDashboard="locInfo" [location]="location$ | async"
  (ClickEvent)="dispatchAction($event)"></app-dashboard-filter>

<ng-container *ngIf="!progress">
  <div class="row box--height mt--25" *ngIf="noData">
    <div class="col-12">
      <div class="box box--rounded box--shadow bg--white">
        <div class="box__header d-flex align-items-center justify-content-center">
          <h2 class="txt--capitalize">
            <strong>No Location info data</strong>
          </h2>
        </div>
      </div>
    </div>
  </div>


  <div class="container--full" *ngIf="location" style="margin-top: 65px">
    <div class="table--shadow mb--30">
      <div class="mat-row mat-row--padding mat-row--border  bg--white">

        <div class="content__body">
          <div class="table--shadow mb--30">
            <div class="mat-row mat-row--padding mat-row--border justify-content-between align-items-center bg--white">
              <div class="row">

                <div class="alert alert-warning w--100" role="alert" *ngIf="pendingMask?.length > 0 && locked == null">
                  <p>
                    <em class="fas fa-info-circle"></em>
                    This location has updated information from Google users or other sources. Consider locking this profile via {{companyName}} to prevent these changes from publishing to your listing on Maps and Search on Google.
                  </p>
                </div>

                <div class="alert alert-notification w--100 d-none" role="alert" *ngIf="!!diffMask?.length" [appUserFeature]="'reviewUpdatesFromGoogle'">
                  <p>
                    <em class="fas fa-exclamation-triangle"></em>
                    This location has pending updates from Google that needs to be reviewed.
                    <a class="link link--blue txt--normal" (click)="showDiffMaskChanges()">Review Updates</a>
                  </p>
                </div>

                <div class="col-12 col-md-6 col--padding border-right">
                  <div class="box">
                    <div class="box__section">
                      <app-location-name [locationName]="locationName" [isUnderReview]="hasPendingMask('location name')" [placeId]="locationId"
                        (onUpdate)="updateLocation($event)"></app-location-name>
                      <app-location-primary-categ [regionCode]="regionCode" [placeId]="locationId" [additionalCategories]="additionalCategories" [isUnderReview]="hasPendingMask('categories')"
                        (onUpdate)="updateLocation($event)" [primaryCategory]="primaryCategory">
                      </app-location-primary-categ>
                    </div>
                    <div class="box__section">
                      <app-location-address *ngIf="address" [address]="address" (onUpdate)="updateLocation($event)" [isUnderReview]="hasPendingMask('address')">
                      </app-location-address>
                      <app-location-service-area *ngIf="serviceArea" [serviceArea]="serviceArea" [regionCode]="regionCode" (onUpdate)="updateLocation($event)" [isUnderReview]="hasPendingMask('serviceArea')"></app-location-service-area>
                      <app-location-regular-hours [regularHours]="regularHours" [placeId]="locationId" [isUnderReview]="hasPendingMask('regular hours')" [countMoreHours]="countMoreHours" [countSpecialHours]="countSpecialHours"
                        (onUpdate)="updateLocation($event)"></app-location-regular-hours>
                      <app-location-more-hours [moreHours]="moreHours" [moreHoursLabels]="moreHoursLabels" [placeId]="locationId" [isEdit]="true" [countRegularHours]="countRegularHours" (onUpdate)="updateLocation($event)" [isUnderReview]="hasPendingMask('more hours')"></app-location-more-hours>
                      <app-location-special-hours [specialHours]="specialHours" (onUpdate)="updateLocation($event)" [countRegularHours]="countRegularHours" [isUnderReview]="hasPendingMask('special hours')">
                      </app-location-special-hours>
                      <app-location-phone [additionalPhones]="additionalPhones" [primaryPhone]="primaryPhone"  (onUpdate)="updateLocation($event)" [isUnderReview]="hasPendingMask('phone numbers')">
                      </app-location-phone>
                      <app-location-url [url]="url"  (onUpdate)="updateLocation($event)" [isUnderReview]="hasPendingMask('website')"></app-location-url>
                      <app-location-url-attributes [attributes]="urlAttributes" *ngIf="urlAttributes" (onUpdate)="updateLocation($event)">
                      </app-location-url-attributes>
                      <app-location-attributes [attributes]="attributes" [socialMedia]="socialMediaAttributes" (urls)="handleUrls($event)" (onUpdate)="updateLocation($event)">
                      </app-location-attributes>
                      <app-location-description *ngIf="!locationType?.canOperateLodgingData"
                        [description]="location?.profile?.description"  (onUpdate)="updateLocation($event)"></app-location-description>

                      <div class="box__item box__item--editable" [class.align-items-start]="openingDate">
                        <div class="d-flex w--100 align-items-center">
                          <div class="icon icon--rounded icon--gray mr--20">
                            <em class="far fa-calendar-alt"></em>
                          </div>
                          <div class="w--100" [class.txt--medium-gray]="hasPendingMask('opening date & status')">
                            <p class="w--100 m--0"><strong>Opening Date & Status</strong></p>
                            <p *ngIf="!location.openInfo.openingDate && location.openInfo.status == 'OPEN'">Add Opening Date & Status</p>
                            <p class="m--0" *ngIf="!location.openInfo.openingDate && location.openInfo.status != 'OPEN'">
                              <strong>
                                {{formatStatus(location.openInfo.status) | titlecase}} 
                              </strong>
                            </p>
                            <div class="w--100" *ngIf="location.openInfo.openingDate as openObject">
                              <p class="m--0" *ngIf="openingDate">
                                <strong *ngIf="location.openInfo.status != 'OPEN'">
                                  {{formatStatus(location.openInfo.status) | titlecase}} 
                                </strong>
                                <strong *ngIf="hasOpeningData() && location.openInfo.status == 'OPEN'">
                                  {{formatStatus(location.openInfo.status) | titlecase}} 
                                  <span *ngIf="openObject.day">on {{ openingDate | date : 'MMMM d, yyyy'}}</span>
                                  <span *ngIf="!openObject.day">on {{ openingDate | date: "MMMM yyyy" }}</span>
                                </strong>
                              </p>
                            </div>
                            <span class="under-review mt--5" *ngIf="hasPendingMask('opening date & status')" role="alert">Under review</span>
                          </div>
                        </div>
                        <button mat-button (click)="openDateDialog()" class="btn btn-icon btn-icon--gray">
                          <em class="fas fa-pencil"></em>
                        </button>
                      </div>

                      <app-location-action-links *ngIf="!!actionLinks?.length" [actionLinks]="actionLinks" (onUpdate)="updateLocation($event)"></app-location-action-links>


                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 col--padding">
                  <div class="box">
                    <div class="box__section">
                      <!--Your business is live on Google-->
                      <div class="box__item border-bottom">
                        <h2 class="mb--20">
                          <strong>Your business is live on Google</strong>
                        </h2>
                        <div class="d-flex flex-column align-items-start" *ngIf="location">
                          <a [href]="location?.search_url"
                            target="_blank" rel="noopener">
                            <button mat-button class="btn btn--md">
                              <img src="/assets/images/icons/google.png" alt="Google Logo" class="img--xxs mr--10" />
                              View on search
                            </button>
                          </a>
                          <a [href]="location.metadata.mapsUrl" target="_blank" rel="noopener">
                            <button mat-button class="btn btn--md">
                              <img src="/assets/images/icons/gm.png" alt="Google Maps Logo" class="img--xxs mr--10" />
                              View on maps
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="box__section">
                      <div class="border-bottom pt--20">
                        <h2><strong>Advanced information</strong></h2>

                        <!--store code-->
                        <div class="box__item box__item--editable">
                          <div class="row w--100">
                            <div class="col-12">
                              <p class="w--100 m--0"><strong>Store code</strong></p>
                            </div>
                            <div class="col-12 col-md-6" [class.txt--medium-gray]="hasPendingMask('store code')">
                              <p>{{ storeCode ? storeCode : "Add Store Code" }}</p>
                            </div>
                            <span class="under-review mt--5 ml--15 " *ngIf="hasPendingMask('store code')" role="alert">Under review</span>
                          </div>
                          <button mat-button (click)="openStoreCodeDialog()" class="btn btn-icon btn-icon--gray">
                            <em class="fas fa-pencil"></em>
                          </button>
                        </div>

                        <!--labels-->
                        <div class="box__item box__item--editable">
                          <div class="row w--100 align-items-start">
                            <div class="col-12">
                              <p class="w--100 m--0"><strong>Labels</strong></p>
                            </div>
                            <div class="col-12">
                              <ng-container *ngIf="location?.labels?.length; else labelsText">
                                <p [class.txt--medium-gray]="hasPendingMask('labels')" *ngFor="let item of location.labels" class="m--0 txt--lightgray">
                                  - {{ item }}
                                </p>
                              </ng-container>
                              <ng-template #labelsText>
                                <p>Add labels</p>
                              </ng-template>
                            </div>
                            <span class="under-review mt--5 ml--15" *ngIf="hasPendingMask('labels')" role="alert">Under review</span>
                          </div>
                          <button mat-button (click)="openLabelsDialog()" class="btn btn-icon btn-icon--gray">
                            <em class="fas fa-pencil"></em>
                          </button>
                        </div>

                        <!--Google Ads-->
                        <div class="box__item box__item--editable">
                          <div class="row w--100">
                            <div class="col-12">
                              <p class="w--100 m--0"><strong>Google Ads location extensions phone</strong></p>
                            </div>
                            <div class="col-12" [class.txt--medium-gray]="hasPendingMask('google ads location extensions phone')">
                              <p>{{ adWordsPhone ? adWordsPhone : "Add Phone Number" }}</p>
                              <span class="under-review mt--5" *ngIf="hasPendingMask('google ads location extensions phone')" role="alert">Under review</span>
                            </div>
                          </div>
                          <button mat-button (click)="openGoogleAdsDialog()" class="btn btn-icon btn-icon--gray">
                            <em class="fas fa-pencil"></em>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="dataSource">
      <div class="col-12 mb--30" *ngIf="dataSource.data.length > 0; else noHistory">
        <div class="box box--rounded box--shadow bg--white">
          <div class="box__header">
            <h2 class="txt--capitalize"><strong>lock history</strong></h2>
          </div>
          <div class="box__content">
            <mat-progress-bar mode="indeterminate" *ngIf="!dataSource"></mat-progress-bar>

            <table mat-table [dataSource]="dataSource" matSort class="table table--bordered">
              <!-- Date -->
              <ng-container matColumnDef="date">
                <th width="25%" mat-header-cell *matHeaderCellDef mat-sort-header>
                  <strong>Date</strong>
                </th>
                <td mat-cell *matCellDef="let row">
                  <div class="cell__content flex-column align-items-start justify-content-center">
                    <p class="m--0 txt--md">
                      <strong>{{ row.date | date: "MMMM d, yyyy h:mm a zzzz" }}</strong>
                    </p>
                  </div>
                </td>
              </ng-container>

              <!-- Action -->
              <ng-container matColumnDef="action">
                <th width="25%" mat-header-cell *matHeaderCellDef mat-sort-header class="txt--center">
                  Action
                </th>
                <td mat-cell *matCellDef="let row">
                  <div class="cell__content d-flex flex-column justify-content-center align-items-center">
                    <button mat-flat-button color="{{row.action == 'pull' ? 'warn' : 'accent'}}"
                      class="btn btn--badge btn--badge--sm txt--uppercase txt--xs">
                      <em class="far" [ngClass]="{
                          'fa-chevron-double-down': row.action == 'pull',
                          'fa-chevron-double-up': row.action == 'push'
                        }"></em>
                      {{ row.action == 'pull' ? 'fetch' : row.action }}
                    </button>
                  </div>
                </td>
              </ng-container>
              <!-- Status -->
              <ng-container matColumnDef="status">
                <th width="25%" mat-header-cell *matHeaderCellDef mat-sort-header class="txt--center">
                  Status
                </th>
                <td mat-cell *matCellDef="let row">
                  <div class="cell__content d-flex flex-column justify-content-center align-items-center">
                    <button mat-stroked-button color="warn" class="btn btn--badge btn--badge--sm txt--uppercase txt--xs" *ngIf="row.status == 'failed'">
                      <em class="fa fa fa-times-circle mr--5"></em>  
                      {{ row.status }}
                    </button>
                    <button mat-stroked-button color="accent" class="btn btn--badge btn--badge--sm txt--uppercase txt--xs" *ngIf="row.status == 'success'">
                      <em class="fa fa-check-circle mr--5"></em>
                      {{ row.status }}
                    </button>
                    <button mat-stroked-button class="btn btn--badge btn--badge--sm txt--uppercase txt--xs txt--orange" *ngIf="row.status == 'partial'">
                      <em class="fa fa fa-minus-circle mr--5"></em>  
                      {{ row.status }}
                      
                      <span matTooltip="{{getPartialError(row)}}"
                          matTooltipPosition="above"
                          matTooltipClass="mat-tooltip--override"
                          class="mat-tooltip tooltip tooltip--info w--15-px h--15">
                          <em class="fas fa-info"></em>
                      </span>
                    </button>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="changes">
                <th width="25%" mat-header-cell *matHeaderCellDef mat-sort-header class="txt--center">
                  Changes
                </th>
                <td mat-cell *matCellDef="let row">
                  <div class="cell__content d-flex flex-column justify-content-center align-items-center">
                    <button mat-flat-button color="accent" class="change-btn btn btn--xs fw--regular mr--10"
                      (click)="showChanges(row)" *ngIf="(row.action != 'unlock' && row.action != 'locked')">
                      <em class="fas fa-history"></em>
                      View Changes
                    </button>
                    <button mat-flat-button class="change-btn--grey btn btn--xs fw--regular mr--10 gray-backgound " *ngIf="(row.action == 'unlock' || row.action == 'locked')">
                      No Changes
                    </button>
                  </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns" class="no-hover"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns" class="no-hover border--full"></tr>
            </table>
          </div>
        </div>
        <mat-progress-bar mode="indeterminate" *ngIf="lockProgress"></mat-progress-bar>
        <app-paginator [pagination]="pagination" *ngIf="pagination" (reload)="reloadPaginator($event)"></app-paginator>
      </div>
    </ng-container>

    <ng-template #noHistory>
      <div class="row m--0 box--height w--100">
        <div class="col-12">
          <div class="box box--rounded box--shadow bg--white">
            <div class="box__header d-flex align-items-center justify-content-center">
              <h2 class="txt--capitalize"><strong>No History</strong></h2>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</ng-container>
