<!--filter-->
<app-reports-filter></app-reports-filter>

<!--revenue insight content-->
<div class="container--full container--padding-lg pt--20">

    <div class="d-flex justify-content-start align-items-center mb--20">
        <img src="/assets/images/revenue-title.png" alt="Revenue Title Icon" class="img--xs mr--15">
        <h2 class="m--0"><strong>Potential Revenue Calculator</strong></h2>
    </div>

    <div class="row box--height">

        <!--Searches-->
        <div class="col-md-7 col-lg-9 mb--30">
            <div class="box box--shadow box--rounded bg--white">
                <div class="box__header">
                    <h2 class="txt--capitalize"><strong>searches</strong></h2>
                </div>
                <div class="box__content box--padding-lg d-flex align-items-center justify-content-center">
                    <img src="/assets/images/placeholder/revenue-search.png" alt="Search Pie" class="img--fluid">
                </div>
            </div>
        </div>

        <!--views-->
        <div class="col-md-5 col-lg-3 mb--30">
            <div class="box box--shadow box--rounded bg--white">
                <div class="box__header">
                    <h2 class="txt--capitalize"><strong>views</strong></h2>
                </div>
                <div class="box__content box--padding-xs">
                    <img src="/assets/images/placeholder/revenue-views.png" alt="Revenue Views" class="img--fluid">
                </div>
            </div>
        </div>

        <!--actions-->
        <div class="col-12 mb--30">
            <div class="box box--shadow box--rounded bg--white">
                <div class="box__header">
                    <h2 class="txt--capitalize"><strong>actions</strong></h2>
                </div>
                <div class="box__content box--padding-lg">
                    <div class="row align-items-center">
                        <div class="col-md-6 col-lg-8">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="box">
                                        <div class="box__header box__header--sm box--shadow alternate-theme d-flex justify-content-center">
                                            <h6 class="txt--capitalize"><strong>direction requests</strong></h6>
                                            <span class="d-inline-block box__badge" matBadge="11" matBadgePosition="above after" matBadgeOverlap="false" matBadgeColor="primary"></span>
                                        </div>
                                        <div class="box__content box--padding-t txt--center">
                                            <p class="txt--md fw--medium txt--lightgray">Assumed conversion rate %</p>
                                            <input type="number" name="direction_requests" value="15" min="0" matInput>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="box">
                                        <div class="box__header box__header--sm box--shadow alternate-theme d-flex justify-content-center">
                                            <h6 class="txt--capitalize"><strong>mobile phone calls</strong></h6>
                                            <span class="d-inline-block box__badge" matBadge="0" matBadgePosition="above after" matBadgeOverlap="false" matBadgeColor="primary"></span>
                                        </div>
                                        <div class="box__content box--padding-t txt--center">
                                            <p class="txt--md fw--medium txt--lightgray">Assumed conversion rate %</p>
                                            <input type="number" name="mobile_phone_calls" value="23" min="0" matInput>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="box">
                                        <div class="box__header box__header--sm box--shadow alternate-theme d-flex justify-content-center">
                                            <h6 class="txt--capitalize"><strong>website</strong></h6>
                                            <span class="d-inline-block box__badge" matBadge="16" matBadgePosition="above after" matBadgeOverlap="false" matBadgeColor="primary"></span>
                                        </div>
                                        <div class="box__content box--padding-t txt--center">
                                            <p class="txt--md fw--medium txt--lightgray">Assumed conversion rate %</p>
                                            <input type="number" name="website" value="04" min="0" matInput>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 txt--center">
                            <img src="/assets/images/revenue-actions.png" alt="Revenue Action Image" class="img--md img--fluid mb--20">
                            <p class="txt--xxxl-sm txt--lightblue fw--medium m--0">35 actions</p>
                            <p class="txt--md txt--lightgray fw--medium">(CTR = 0.13%)</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--potential store visits-->
        <div class="col-12 col-md-4 mb--30">
            <div class="box box--shadow box--rounded bg--white">
                <div class="box__header">
                    <h2 class="txt--capitalize"><strong>potential store visits</strong></h2>
                </div>
                <div class="box__content box--padding-xxs d-flex justify-content-between">
                    <div>
                        <p class="txt--xxxl txt--lightblue fw--medium m--0">1.1</p>
                        <p class="txt--md txt--lightgray fw--medium mb--10">Purchase Rate (%)</p>
                        <input type="number" min="0" value="15" name="potential_store_visits">
                    </div>
                    <img src="/assets/images/potential-store-visits.png" alt="Potential Store Visits Image" class="img--height-md img--fluid">
                </div>
            </div>
        </div>

        <!--potential sales-->
        <div class="col-12 col-md-4 mb--30">
            <div class="box box--shadow box--rounded bg--white">
                <div class="box__header">
                    <h2 class="txt--capitalize"><strong>potential sales</strong></h2>
                </div>
                <div class="box__content box--padding-xxs d-flex justify-content-between">
                    <div>
                        <p class="txt--xxxl txt--lightblue fw--medium m--0">0.022</p>
                        <p class="txt--md txt--lightgray fw--medium mb--10">Average Customer Spend</p>
                        <input type="number" min="0" value="15" name="potential_sales">
                    </div>
                    <img src="/assets/images/potential-sales.png" alt="Potential Sales" class="img--height-md img--fluid">
                </div>
            </div>
        </div>

        <!--potential revenue-->
        <div class="col-12 col-md-4 mb--30">
            <div class="box box--shadow box--rounded bg--white">
                <div class="box__header">
                    <h2 class="txt--capitalize"><strong>potential revenue</strong></h2>
                </div>
                <div class="box__content box--padding-xxs d-flex justify-content-between">
                    <p class="txt--xxxl txt--lightblue fw--medium m--0">$0.04</p>
                    <img src="/assets/images/potential-revenue.png" alt="Potential Revenue" class="img--height-md img--fluid">
                </div>
            </div>
        </div>

        <!--chart-->
        <div class="col-12 mb--30">
            <div class="box box--shadow box--rounded bg--white">
                <div class="box__header">
                    <h2 class="txt--capitalize"><strong>chart</strong></h2>
                </div>
                <div class="box__content box--padding-xxs">
                    <img src="/assets/images/placeholder/revenue-chart.png" alt="Revenue Chart" class="img--fluid">
                </div>
            </div>
        </div>

        <!--multi locations table-->
        <div class="col-12 mb--30">
            <app-table-multilocations></app-table-multilocations>
        </div>
    </div>
</div>
