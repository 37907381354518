<div class="row" *ngIf="metrics && !progress">
  <app-grade-card class="col-12 col-md-6 box--height mb--30"
    [information]="'Listing status indicates if you’ve completed the most basic actions on your GBP listing. Verified listings are more trusted by Google than listings that are not. Additionally, listings with no pending updates or duplicate listings are also more likely to appear higher and more prominently in search results'"
    [gradeSection]="metrics.listingStatus">
  </app-grade-card>
  <app-grade-card class="col-12 col-md-6 box--height mb--30"
    [information]="'Basic information like your hours of operation, phone number, website, etc are critical pieces of information that customers are looking for when they are searching for your products and services. Having all your basic business information listed accurately and completely will help to improve the overall health of your GBP listing and also have an impact on user experience.'"
    [gradeSection]="metrics.basicInfo">
  </app-grade-card>
  <app-grade-card class="col-12 col-md-6 box--height mb--30"
    [gradeSection]="metrics.reputation"
    [information]="'Reviews are one of the most impactful components of your business listing performance on GBP. Having higher quality and quantity of reviews compared with your competition will increase your “prominence” in local search rankings. In addition to a strong average rating and high quantity of reviews, research suggests that businesses who actively respond to all of their reviews also are seen as more relevant by Google. Businesses who achieve a high average rating, high quantity and actively respond to reviews will build more trust with prospective customers and Google over the long run'">
  </app-grade-card>
  <app-grade-card class="col-12 col-md-6 box--height mb--30"
    [gradeSection]="metrics.media"
    [information]="'Photos are often one of the most overlooked aspects of your business listings performance on GBP. Not only are customers using images to gain insight into what your products and services look like but Google uses advanced machine learning and vision products to identify “objects” within your images to evaluate your businesses offerings in their entirety. Having a large quantity of high quality, full resolution images on your GBP listing can have a tremendous impact on public perception as well as listing relevancy. Research also suggests that Google “trusts” images from the business owner compared with “customer” photos so adding images as the business owner can help you to control the conversation.'">
  </app-grade-card>
  <app-grade-card class="col-12 col-md-6 box--height mb--30"
    [gradeSection]="metrics.post"
    [information]="'Adding new content to your business listing monthly can have an impact on your business listings visibility and click through rate (CTR), which may positively impact your rankings. Additionally, features like Posts are great tools to keep your customers up to date with ongoing promotions, events, etc and may lead to increased engagement and sales. For restaurants and other food based businesses, having your ‘menu’ uploaded is also an important tool for educating users and Google of your current offerings. Service businesses may also upload ‘services’ that are offered to similar effect. '">
  </app-grade-card>
</div>

<div *ngIf="!metrics" class="box box--rounded box--shadow bg--white mb--30" style="height: auto;">
  <div class="box__header align-items-center justify-content-center row">
    <div class="col-auto">
      <h2 *ngIf="hasError" class="txt--capitalize">
        <strong>Error getting metrics grade</strong>
      </h2>
      <div *ngIf="progress" class="text-center">
        <span class="tag__content"> Loading... </span>
      </div>
      <div *ngIf="hasError && !progress" class="text-center">
        <button mat-flat-button color="accent" class="btn btn--grade" (click)="getMetrics()">Retry</button>
      </div>
    </div>
  </div>
</div>

<ng-template *ngIf="!metrics && !hasError">
  <app-no-data message="No metrics data"></app-no-data>
</ng-template>
