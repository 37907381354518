<div class="dialog__header" >
  <h2 mat-dialog-title class="m--0 txt--xl" ><strong>{{title|titlecase}}</strong></h2>
</div>
<div class="dialog__content" mat-dialog-content>
  <ng-content></ng-content>
</div>
<div mat-dialog-actions class="dialog__footer">
    <span class="alternate-theme d-inline-block mr--10">
        <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
    </span>

  <button mat-flat-button (click)="action()" tabIndex="-1" [color]="color" class="btn btn--action">
    <span *ngIf="actionText">{{actionText|titlecase}}</span>
    <span *ngIf="!actionText">Apply</span>
  </button>
</div>

