import { __awaiter, __generator, __read, __values } from "tslib";
import * as i0 from "@angular/core";
var AuthProxyService = /** @class */ (function () {
    function AuthProxyService() {
        this.callQueue = [];
    }
    AuthProxyService.prototype.initialize = function (authService_signOut, authService_headers, authService_forceAuthRefresh) {
        return __awaiter(this, void 0, void 0, function () {
            var queue, queue_1, queue_1_1, _a, resume, ended, e_1_1;
            var e_1, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (this.initialized)
                            return [2 /*return*/];
                        this.authService_signOut = authService_signOut;
                        this.authService_headers = authService_headers;
                        this.authService_forceAuthRefresh = authService_forceAuthRefresh;
                        queue = this.callQueue;
                        this.callQueue = null;
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 6, 7, 8]);
                        queue_1 = __values(queue), queue_1_1 = queue_1.next();
                        _c.label = 2;
                    case 2:
                        if (!!queue_1_1.done) return [3 /*break*/, 5];
                        _a = __read(queue_1_1.value, 2), resume = _a[0], ended = _a[1];
                        // console.debug('de-queued 1')
                        resume();
                        // console.debug('de-queued 2', ended)
                        return [4 /*yield*/, ended
                            // console.debug('de-queued 3')
                        ];
                    case 3:
                        // console.debug('de-queued 2', ended)
                        _c.sent();
                        _c.label = 4;
                    case 4:
                        queue_1_1 = queue_1.next();
                        return [3 /*break*/, 2];
                    case 5: return [3 /*break*/, 8];
                    case 6:
                        e_1_1 = _c.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 8];
                    case 7:
                        try {
                            if (queue_1_1 && !queue_1_1.done && (_b = queue_1.return)) _b.call(queue_1);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(AuthProxyService.prototype, "initialized", {
        get: function () {
            return this.callQueue === null;
        },
        enumerable: true,
        configurable: true
    });
    AuthProxyService.prototype.forceAuthRefresh = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.runOrQueue(function () { return _this.authService_forceAuthRefresh; })];
                    case 1: return [4 /*yield*/, (_a.sent())];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    AuthProxyService.prototype.authHeaders = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.runOrQueue(function () { return _this.authService_headers; })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    AuthProxyService.prototype.signOut = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.runOrQueue(function () { return _this.authService_signOut; })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    AuthProxyService.prototype.runOrQueue = function (f) {
        return __awaiter(this, void 0, void 0, function () {
            var p_ini_resolve_1, p_end_resolve_1, p_ini, p_end, r;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.initialized) return [3 /*break*/, 1];
                        return [2 /*return*/, f()()];
                    case 1:
                        p_ini = new Promise(function (resolve, _reject) { p_ini_resolve_1 = resolve; });
                        p_end = new Promise(function (resolve, _reject) { p_end_resolve_1 = resolve; });
                        this.callQueue.push([p_ini_resolve_1, p_end]);
                        return [4 /*yield*/, p_ini];
                    case 2:
                        _a.sent();
                        r = f()();
                        p_end_resolve_1();
                        return [2 /*return*/, r];
                }
            });
        });
    };
    AuthProxyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthProxyService_Factory() { return new AuthProxyService(); }, token: AuthProxyService, providedIn: "root" });
    return AuthProxyService;
}());
export { AuthProxyService };
