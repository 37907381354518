/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i2 from "@angular/material/progress-spinner";
import * as i3 from "@angular/cdk/platform";
import * as i4 from "@angular/common";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "./spinner.component";
var styles_SpinnerComponent = [];
var RenderType_SpinnerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SpinnerComponent, data: {} });
export { RenderType_SpinnerComponent as RenderType_SpinnerComponent };
export function View_SpinnerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mat-progress-spinner", [["class", "mat-progress-spinner"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"], [1, "aria-valuemin", 0], [1, "aria-valuemax", 0], [1, "aria-valuenow", 0], [1, "mode", 0]], null, null, i1.View_MatProgressSpinner_0, i1.RenderType_MatProgressSpinner)), i0.ɵdid(1, 114688, null, 0, i2.MatProgressSpinner, [i0.ElementRef, i3.Platform, [2, i4.DOCUMENT], [2, i5.ANIMATION_MODULE_TYPE], i2.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { mode: [0, "mode"], value: [1, "value"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "spinner-message"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.mode; var currVal_8 = _co.value; _ck(_v, 1, 0, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i0.ɵnov(_v, 1).diameter; var currVal_2 = i0.ɵnov(_v, 1).diameter; var currVal_3 = ((i0.ɵnov(_v, 1).mode === "determinate") ? 0 : null); var currVal_4 = ((i0.ɵnov(_v, 1).mode === "determinate") ? 100 : null); var currVal_5 = ((i0.ɵnov(_v, 1).mode === "determinate") ? i0.ɵnov(_v, 1).value : null); var currVal_6 = i0.ɵnov(_v, 1).mode; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = _co.message; _ck(_v, 3, 0, currVal_9); }); }
export function View_SpinnerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "gmb-spinner", [], null, null, null, View_SpinnerComponent_0, RenderType_SpinnerComponent)), i0.ɵdid(1, 114688, null, 0, i6.SpinnerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SpinnerComponentNgFactory = i0.ɵccf("gmb-spinner", i6.SpinnerComponent, View_SpinnerComponent_Host_0, { message: "message", mode: "mode", value: "value" }, {}, []);
export { SpinnerComponentNgFactory as SpinnerComponentNgFactory };
