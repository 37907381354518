<div class="dialog__header dialog__header--warn txt--center">
    <h2 mat-dialog-title class="m--0 txt--xl"><strong>Are you sure you want to remove {{name}}?</strong></h2>
</div>
<div class="dialog__content" mat-dialog-content>

    <div class="dialog-row" *ngIf="status == 'init'">
        <p class="txt--lg txt--center m--0"><strong>{{message}}</strong></p>
    </div>

    <div class="dialog-row txt--center" *ngIf="status == 'pending'">
        <p class="txt--lg txt--center m--0 mb--2"><strong>Wait while we're working in that</strong></p>
        <mat-spinner style="margin:0 auto;"></mat-spinner>
    </div>
    <div class="dialog-row txt--center" *ngIf="status == 'completed'">
        <p class="txt--lg txt--center m--0"><strong>{{message}} <i class="far fa-check-circle"></i></strong></p>
    </div>
</div>
<div mat-dialog-actions class="dialog__footer">
    <span class="alternate-theme d-inline-block mr--10">
        <button tabIndex="-1" mat-button color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
    </span>
    <button tabIndex="-1" mat-flat-button color="warn" (click)="delete()" [disabled]="status == 'pending' || status == 'completed'" class="btn btn--action">Delete</button>
</div>
