import {environment as defaultEnvironment} from './environment.default';

export const environment = {
  ...defaultEnvironment,

  env: 'prod',
  production: true,
  
  // core-api 
  // coreApiUrl: 'https://core-secondary.maplabs.com',
  coreApiUrl: 'https://core-api-seconday-156411061417.us-east1.run.app',

  // post-api
  apiUrlV3: 'https://api-ccx5mqu6oa-ue.a.run.app/api/v3',

  // billing-api
  billingApiUrl: 'https://billing-api-ccx5mqu6oa-ue.a.run.app/api',

  // main-api (App Engine):
  apiUrl: 'https://main-api.maplabs.com/api',

  // main-api (Cloud Run):
  // apiUrl: 'https://main-api-ccx5mqu6oa-ue.a.run.app/api', 

  queuesEnabled: true,
  saveLocationInChain: true,
  userGuidingEnabled: true,
  ga4Enabled: true
};
