<form [formGroup]="formRecurring" style="width: 100%;">
  <div class="col-12 p--0">
    <mat-label class="d-block mb--10">
      Posting Frequency (Required)
      <span matTooltip="All weekly recurring posts will be published at 12:01am on the chosen “starts on” day of the week and will be removed at 11:59pm on the chosen “ends on” day of the week. For monthly recurring posts, the post will begin on the chosen start date and automatically expire the day before the next post in the series is scheduled. For example, an event or offer post on the 1st of the month will “Start” on the 1st and “end” on the last day of that month. All posts regardless of frequency will publish at 12:01am on the start date and delete at 11:59pm on the end date."
        matTooltipPosition="above"
        matTooltipClass="mat-tooltip--override">
          <i class="fas fa-info-circle txt--lightgray txt--sm"></i>
      </span>
    </mat-label>
    <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
      <mat-select (selectionChange)="frecuencyControl($event)" [value]="frecuency.value" class="input-group__field">
        <mat-option value="WeeklyOn">Weekly on (Day of Week)</mat-option>
        <mat-option value="MonthlyOn">Monthly on (Day of Month)</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-error *ngIf="frecuency.invalid">
      <p>Frequency is required</p>
    </mat-error>
  </div>

  <div class="col-12">
    <app-frecuency-selector [FrecuencySelect]="frecuency.value" [frecuencyEdit]="repeatOn.value" [durationEdit]="duration.value" (FrecuencyOutput)="handleFrecuency($event)" ></app-frecuency-selector>
  </div>

  <!-- <div class="col-12 p--0" *ngIf="postDateAvaible && this.frecuency.value === 'MonthlyOn'" style="padding-top: 15px; padding-bottom: 25px;">
    <mat-slide-toggle class="align-self-end" labelPosition="before" formControlName="isAllMonth" color="primary">The post duration is all month?</mat-slide-toggle>
  </div> -->

  <div class="col-12 p--0" *ngIf="postDateAvaible">
    <mat-label class="d-flex align-items-center mb--10">
      <h5 class="fw--medium txt--black mr--10">
        Add Event Start/End Time (Optional)
        <span matTooltip="Select a start/end time that aligns with your Event or Offer. Adding an event or offer start/end time does not change the posting frequency or publication time of the posts. All posts regardless of frequency will publish at 12:01am on the start date and delete at 11:59pm on the end date. "
        matTooltipPosition="above"
        matTooltipClass="mat-tooltip--override">
          <i class="fas fa-info-circle txt--lightgray txt--sm"></i>
        </span>
      </h5>
      <!--switch-->
      <mat-slide-toggle [checked]="eventTimeActivate" (change)="deactivateTime($event.checked)" [disabled]="duration.value === 0" color="primary"></mat-slide-toggle>
    </mat-label>
    
    <div class="row">
      <div class="col-md-6">
        <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label" *ngIf="eventTimeActivate">
          <input class="input-group__field" matInput type="time" formControlName="eventTimeStart">
          <mat-error *ngIf="eventStart.invalid">
            <p *ngIf="eventStart.errors.required">Time is required</p>
            <p *ngIf="eventStart.errors.minorDate">Event time end must be after the current time ({{previewDate | date:'hh:mm a'}})</p>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label" *ngIf="eventTimeActivate">
          <input class="input-group__field" matInput type="time" formControlName="eventTimeEnd">
          <mat-error *ngIf="eventEnd.invalid">
            <p *ngIf="eventEnd.errors.required">Time is required</p>
            <p *ngIf="eventEnd.errors.minorDate">Event time end must be after the current time ({{previewDate | date:'hh:mm a'}})</p>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="col-12 p--0 mb--10">
    <mat-label class="d-flex align-items-center mb--10">
      <h5 class="fw--medium txt--black mr--10">
        Publish the first post in this series now? (Optional)
        <span matTooltip="Selecting this option will immediately post the next instance of the post in the series now. For example, if you have an event or offer that posts on the 1st of the month, it will begin appearing on your business listing as an event or offer that begins in the future. This is useful if you want to begin building interest in your event or offer in advance of it being available to customers."
        matTooltipPosition="above"
        matTooltipClass="mat-tooltip--override">
          <i class="fas fa-info-circle txt--lightgray txt--sm"></i>
        </span>
      </h5>
      <!--switch-->
      <mat-slide-toggle [checked]="publishRecurringNow" (change)="changePublishNow($event.checked)" color="primary"></mat-slide-toggle>
    </mat-label>
  </div>
</form>
