import {AfterViewChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnChanges, OnInit, Output, EventEmitter, ViewChild, ElementRef, Renderer2} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import {ReportService} from '../../services/report.service';
import {AuthService} from '../../services/auth.service';
import SavedAccount from '../../constants/firestore/saved-account';
import Report, {
  COMPARISON_LIST,
  COMPARISON_LIST_DEFAULT,
  DATAPICKER_REPORT_DEFAULT,
  FormReport,
  REPORT_TYPE
} from '../../constants/firestore/report';
import AccountReport from '../../services/account-report';
import {DatesService} from '../../services/dates.service';
import {DataPicker} from '../../constants/data-picker';
import {MatSelectChange} from '@angular/material/select';
import * as moment from 'moment';
import {Router} from '@angular/router';
import {VAR_MONTH_PICKER_YEAR_AGO} from '../../constants/datePicker';
import { WhiteLabelService } from '../../services/white-label.service';
import { DateRangePickerComponent } from '@syncfusion/ej2-angular-calendars';
import { alwaysShowCalendar } from 'src/app/helpers/syncfusion.helpers';
import { QuestionsAnswerService } from 'src/app/services/questions-answer.service';
import { ModalService } from 'src/app/services/modal.service';
import { periodDaysQanda } from 'src/app/constants/periodDaysQanda';
import { LocationService } from 'src/app/services/location.service';

@Component({
  selector: 'app-slide-create-report',
  templateUrl: './slide-create-report.component.html',
  styleUrls: ['./slide-create-report.component.scss']
})
export class SlideCreateReportComponent implements OnInit, OnDestroy, AfterViewChecked, OnChanges {

  @Input() viewModeChecked = 'legacy';
  @Input() reportData: any = {};
  @Input() drawer: any = {};
  @Input() isSliderOpened: boolean = false;

  @Output() closeSliderReport = new EventEmitter();

  @ViewChild('dateRangePickerMonthly',{ static: false }) 

  public dateRange: DateRangePickerComponent;
  public montPicker = JSON.parse(JSON.stringify(VAR_MONTH_PICKER_YEAR_AGO));
  public form: FormGroup;
  public formReport: FormReport;
  public step = 0;
  public stepTotal = 0;
  public location: any;
  public accounts: SavedAccount[];
  public isEdit: boolean;
  public reportEdit: Report;
  public rangeBDisabled = true;
  public dataPicker: DataPicker = DATAPICKER_REPORT_DEFAULT;
  public selectDate: any;
  public selectDateB: any;
  public rangeAPeriod = -1;
  public rangeBPeriod = -1;
  public compareList = COMPARISON_LIST;
  public defaultSelected = COMPARISON_LIST_DEFAULT;
  public accountsReport: AccountReport[] = [];
  public metricsChecked: { name: string; checked: boolean }[] = [];
  public difference: number;
  public showLoader: boolean;
  public DynamicRangeControl: FormControl = new FormControl(null);
  public FrecuencyControl: FormControl = new FormControl('WeeklyOn', [Validators.required]);
  public RecipentsControl: FormControl = new FormControl('', [Validators.email]);
  public RecipentsEmails: string[] = [];
  public repeatOn = '0';
  public dynamicRangeOptions = [
    {value: 'LastMonth', displayName: 'Last Month'},
    {value: 'Last30', displayName: 'Last 30 Days'},
    {value: 'Last90', displayName: 'Last 90 Days'},
    {value: 'YTD', displayName: 'Year to Date'}
  ];
  public maxDateDatePicker;
  public minDate = null;
  public maxDate = null;
  public reportType;
  public metricVerification = false;
  public showTooltipsOnDisabledDates = false;

  constructor(
    private reportS: ReportService,
    private wl: WhiteLabelService,
    private auth: AuthService,
    private fb: FormBuilder,
    private dateS: DatesService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private questionsS: QuestionsAnswerService,
    private modalService: ModalService,
    private locationS: LocationService,
    private el: ElementRef,
    private renderer2: Renderer2,
  ) {}

  ngOnInit(){
    this.initSteps();
  }

  ngOnChanges() {
    this.reportType = this.reportData.reportType;
    this.reportEdit = null;
    const content = document.getElementsByClassName('content')
    if (content.length > 0) {
      this.renderer2.addClass(content[0], 'pr--0')
    }
    this.initSteps();
    if (!this.isEdit) {
      if(this.reportType === 'keyword'){
        this.handleDateSelected({startDate: this.montPicker?.lastYearStart, endDate: this.montPicker?.lastYearEnd});
      }else{
        this.handleDateSelected(this.dataPicker);
      }
    } else {
      if(this.reportType === 'keyword'){
        this.handleDateSelected(this.dataPicker);
      }
    }

    if(!this.isSliderOpened) {
      this.ngOnDestroy();
    }
  }
  
  initSteps() {
    const content = document.getElementsByClassName('content');
    const reportType = this.reportType?.includes('comparison') ? 'comparison' : this.reportType;
    this.showTooltipsOnDisabledDates = this.reportType == 'performance-rollup' || this.reportType == 'performance-comparison';

    switch(reportType) {
      case 'keyword':
        this.dynamicRangeOptions = [
          {value: 'OneMonth', displayName: 'Last Month'},
          {value: 'Last3Month', displayName: 'Last 3 Months'},
          {value: 'OneYear', displayName: 'Year to Date'}
        ]
        break;
      case 'comparison':
        this.dynamicRangeOptions = [
          {value: 'LastMonth-PrevMonth', displayName: 'Last month vs Previous Month'},
          {value: 'LastMonth-YearAgoMonth', displayName: 'Last Month vs Same Month Last Year'},
          {value: 'Last90-PrevLast90', displayName: 'Last 90 days vs Previous 90 days'}
        ];
        break;
      case 'qanda':
        this.dynamicRangeOptions = periodDaysQanda;
        break;
    }

    this.maxDateDatePicker = this.viewModeChecked == 'legacy' ? moment('12-31-2022', 'MM-DD-YYYY') : null;

    if (this.reportData?.edit) {
      this.reportType = this.reportData?.reportType as REPORT_TYPE;
      this.isEdit = !!this.reportData?.edit;
      this.stepTotal = this.reportType === 'comparison' || this.reportType ==='performance-comparison' ? 3 : 2;
    }

    if (this.reportData?.stepLocations) {
      this.step++;
    }

    if (this.reportType === 'comparison' || this.reportType ==='performance-comparison') {
      this.dataPicker.rangeB = {start: new Date().toISOString(), end: new Date().toISOString()};
    } 

    if (this.isEdit) {
      if (this.reportData?.data) {
        this.reportEdit = this.reportData?.data.edit;
      } else {
        this.reportEdit = this.reportData?.edit;
      }

      this.reportEdit = this.reportS.convertReportDate(this.reportEdit);
      this.selectDate = this.reportS.reportSelectDatesFromReport(this.reportEdit);

      if(this.reportType === 'keyword' && this.dateRange){
        this.dateRange.startDate = this.reportData?.edit?.startDatetime ? new Date(this.reportData?.edit?.fromDate) :  this.montPicker.lastYearStart;
        this.dateRange.endDate = this.reportData?.edit?.endDatetime ? new Date(this.reportData?.edit?.toDate) :  this.montPicker.lastYearEnd;
      }
    } else {
      this.buildSelectDate();
    }

    this.initForm(this.reportEdit);
  }

  buildSelectDate() {
    const dates = this.reportType == 'review' && this.minDate && this.maxDate 
    ? {start: this.minDate, end: this.maxDate} 
    : this.locationS.buildDatepickerDate(this.reportType, this.maxDate);
    
    if(!this.isEdit) {
      this.selectDate = {
        start: dates?.start.isBefore(this.minDate) ? moment(this.minDate) : dates?.start,
        end: dates?.end.isAfter(this.maxDate) ? moment(this.maxDate) : dates?.end
      }
    }
    
    this.dataPicker.range = {
      start: this.selectDate?.start?.format('YYYY-MM-DD'),
      end: this.selectDate?.end?.format('YYYY-MM-DD')
    }

    if (this.reportType == 'keyword' && this.minDate && this.maxDate) {
      const start = moment(VAR_MONTH_PICKER_YEAR_AGO.lastYearStart);
      const end = moment(VAR_MONTH_PICKER_YEAR_AGO.lastYearEnd);
      this.montPicker.lastYearStart = start.isBefore(this.minDate) ? this.minDate : VAR_MONTH_PICKER_YEAR_AGO.lastYearStart;
      this.montPicker.lastYearEnd = end.isAfter(this.maxDate) ? this.maxDate : VAR_MONTH_PICKER_YEAR_AGO.lastYearEnd;
    } else {
      this.montPicker.lastYearStart =  VAR_MONTH_PICKER_YEAR_AGO.lastYearStart;
      this.montPicker.lastYearEnd = VAR_MONTH_PICKER_YEAR_AGO.lastYearEnd;
    }
  }

  handleDateSelected($event: any): void {
    const startDate = ($event?.range?.start)?.includes('T') ? `${($event?.range?.start).split('T')[0]}T23:59:59.000Z` : $event?.range?.start;
    
    if (this.reportType === 'comparison' || this.reportType ==='performance-comparison') {
      $event.rangeB.start = ($event?.rangeB?.start)?.includes('T') ? `${($event?.rangeB?.start).split('T')[0]}T23:59:59.000Z` : $event?.rangeB?.start;
    }

    let dateStart = moment(startDate).utc();
    let dateEnd = moment($event?.range?.end).utc();

    this.rangeAPeriod = dateEnd.diff(dateStart, 'days');
    if($event?.startDate && $event?.endDate){
      dateStart = $event?.startDate;
      dateEnd = $event?.endDate
    }

    if($event?.startDate && $event?.endDate && this.reportType === 'keyword' && this.dateRange){
      this.dateRange.startDate = $event.startDate;
      this.dateRange.endDate = $event.endDate;
    } else if (!$event?.range?.start && !$event?.range?.end && this.reportType === 'keyword' && this.dateRange)
    {
      this.dateRange.startDate = this.reportData?.edit?.startDatetime ? new Date(this.reportData?.edit?.startDatetime) :  this.montPicker?.lastYearStart;
      this.dateRange.endDate = this.reportData?.edit?.endDatetime ? new Date(this.reportData?.edit?.endDatetime) :  this.montPicker?.lastYearEnd;
    }
    
    this.startDateTime.setValue(dateStart);
    this.endDateTime.setValue(dateEnd);

    // if (this.reportType === 'comparison' || this.reportType ==='performance-comparison') {
    //   const selectChange = new MatSelectChange(null, this.period.value);
      
    //   this.periodChange(selectChange);
    // }

    if (this.form.controls.period?.value == 'month'){
      if (this.isOptionEnabled('month')) { 
       this.form.controls.period.setValue('period');
       this.periodChange({value: 'period'})
     }
    }

    if(this.step > 0){
      switch(this.form.controls.period?.value) {
        case 'month':
          if (this.isOptionEnabled('month')) {
            this.form.controls.period.setValue('period');
            this.periodChange({value: 'period'})
          }
          break;
        case 'year':
          if (this.isOptionEnabled('year')) {
            this.form.controls.period.setValue('period');
            this.periodChange({value: 'period'})
          }
          break;
        case 'period':
          if (this.isOptionEnabled('period')) {
            this.form.controls.period.setValue('custom');
            this.periodChange({value: 'custom'})
          } else {
            this.periodChange({value: 'period'})
          }
          break;
      }
    } else {
      this.periodChange({value: this.period?.value})
    }
  }

  handleDateBSelected($event): void {
    if (this.reportType === 'comparison' || this.reportType ==='performance-comparison') {
      this.startDateTimeB.setValue($event.rangeB.start);
      this.endDateTimeB.setValue($event.rangeB.end);

      const dateStart = moment($event?.rangeB.start).utc();
      const dateEnd = moment($event?.rangeB.end).utc();

      this.rangeBPeriod = dateEnd.diff(dateStart, 'days') || -1;
    }
  }

  daterangepickerOpen(): void{
    alwaysShowCalendar();
  }

  changeLockDates($event: any): void {
    this.lockDates.setValue($event.checked);
  }

  changeDynamicDates($event: any): void {
    this.dynamic.setValue($event.checked);
    if($event.checked) {
      this.DynamicRangeControl.setValidators(Validators.required);
      this.form.updateValueAndValidity();
      this.period?.setValue('period');
    } else {
      this.DynamicRangeControl.setValidators([]);
      this.DynamicRangeControl.setErrors(null);
      this.form.updateValueAndValidity();
    }

    if (this.reportType == 'performance-comparison') {
      if(!$event.checked) {
        this.form.controls?.startDateB.setValidators(Validators.required);
        this.form.controls?.endDateB.setValidators(Validators.required);
        this.form.updateValueAndValidity();
      } else {
        this.form.controls?.startDateB.setValidators([]);
        this.form.controls?.endDateB.setValidators([]);
        this.form.controls?.startDateB.setErrors(null);
        this.form.controls?.endDateB.setErrors(null);
        this.form.updateValueAndValidity();
      }
    }
  }

  next(): void {
    this.step++;
  }

  backStep(event) {
    this.step = event;
  }

  periodChange($event): void {
    if (this.reportType == 'keyword') { return;}
    const data = this.reportS.periodChange($event.value, this.startDateTime.value, this.endDateTime.value);
    if ($event.value == 'period' || $event.value == 'month') {
      this.difference = data.difference
    }
    if(this.reportType?.includes('comparison')) {
      this.dataPicker.rangeB.start = data?.startDateB;
      this.dataPicker.rangeB.end = data?.endDateB;
      this.startDateTimeB?.setValue(data?.startDateB);
      this.endDateTimeB?.setValue(data?.endDateB);
      this.rangeBDisabled = data?.rangeBDisabled;
    }
    this.period?.setValue($event?.value);
    this.selectDateB = null;
  }

  onStepChange(event): void {
    this.step = event;
    const hasLocation = (
      this.accountsReport.length == 0 ? false : 
      this.accountsReport.find(a => a.locations.length > 0) ? true : false
    );
    
    if(!hasLocation) {
      this.drawer.opened = true;
      this.modalService.openWarningModal('Heads up', 'This report has no locations selected. Please choose at least 1 location before trying to save the report.');
    }
    // if(!this.form.valid && event > 0) {
    //   this.form.markAllAsTouched()
    // }
  }

  selectedLocations($event: AccountReport[]): void {
    this.accountsReport = $event;
  }

  save(): void {
    if (this.accountsReport.length == 0) {
      return;
    } else {
      this.showLoader = true;
      this.formReport = this.form.value;

      let accounts = this.deleteLabels(this.accountsReport);
      accounts = this.locationS.deleteServiceArea(accounts)
  
      const startDatetimeMoment = moment(this.startDateTime?.value);
      const startDatetime = this.reportS.getFormattedMomentDate(startDatetimeMoment);
      const endDatetimeMoment = moment(this.endDateTime.value);
      const endDatetime = this.reportS.getFormattedMomentDate(endDatetimeMoment);
  
      if (this.isEdit) {
        this.reportEdit.accounts = accounts;
        this.reportEdit.startDatetime = startDatetime;
        this.reportEdit.endDatetime = endDatetime;
        this.reportEdit.endDatetime = endDatetime;
        this.reportEdit.reportName = this.formReport?.reportName;
        this.reportEdit.lockDates = this.formReport?.lockDates;
        this.reportEdit.dynamic = this.formReport?.dynamic;
        this.reportEdit['updatedAt'] = moment().toISOString();
  
        if (this.scheduleReport.value) {
          this.reportEdit = this.configAutomatedReport(this.reportEdit);
          if (!this.reportEdit) return;
  
          this.reportEdit.scheduleReport = this.formReport?.scheduleReport;
          if (this.reportType === 'review' || this.reportType === 'rollup') {
            this.reportEdit.dynamicRange = this.DynamicRangeControl.value;
            const dateDynamic = this.reportS.converDynamicDateComparison(this.reportEdit?.dynamicRange, this.reportType);
            this.reportEdit.startDatetime = dateDynamic?.startDatetime;
            this.reportEdit.endDatetime = dateDynamic?.endDatetime;
          }
        }
  
        if (this.reportEdit.dynamic){
          this.reportEdit.dynamicRange = this.DynamicRangeControl.value;
          const dateDynamic = this.reportType == 'qanda' ? this.questionsS.buildPeriodDate(this.DynamicRangeControl.value) : this.reportS.converDynamicDateComparison(this.reportEdit.dynamicRange, this.reportType);
          this.reportEdit.startDatetime = dateDynamic?.startDatetime;
          this.reportEdit.endDatetime = dateDynamic?.endDatetime;
        }
  
        if (this.reportType === 'comparison' || this.reportType === 'performance-comparison') {
          const date = this.startDateTimeB?.value || moment().format('YYYY-MM-DD');
          this.startDateTimeB?.setValue(this.reportS.getFormattedStringDate(date));
          
          if (this.scheduleReport.value || this.dynamic.value) {
            const dateDynamic = this.reportS.converDynamicDateComparison(this.reportEdit.dynamicRange, this.reportType);
            this.reportEdit.startDatetime = dateDynamic?.startDatetime;
            this.reportEdit.endDatetime = dateDynamic?.endDatetime;
            this.reportEdit.startDatetimeB = dateDynamic?.startDatetimeB;
            this.reportEdit.endDatetimeB = dateDynamic?.endDatetimeB;
          } else {
            const startDateBtimeMoment = moment(this.startDateTimeB.value);
            const startDateBtime = `${startDateBtimeMoment['year']()}-${startDateBtimeMoment['month']() +1}-${startDateBtimeMoment['date']()}`;
            const endDateBtimeMoment = this.setHours(moment(this.endDateTimeB.value));
            const endDateBtime = `${endDateBtimeMoment['year']()}-${endDateBtimeMoment['month']() +1}-${endDateBtimeMoment['date']()}`;
  
            const startDatetimeB = startDateBtime;
            const endDatetimeB = endDateBtime;
            this.reportEdit.startDatetimeB = startDatetimeB;
            this.reportEdit.endDatetimeB = endDatetimeB;
          }
          this.reportEdit.metrics = this.metricsChecked;
          this.reportEdit.period = this.period.value;
        }
  
        delete this.reportEdit['needToUpgrade'];
        delete this.reportEdit['invalidLocations'];
        delete this.reportEdit['duplicatedLocations'];
        if (this.viewModeChecked != 'new') {
          this.reportS.update(false, this.reportEdit.gid, this.reportEdit.id, this.reportEdit).then(() => {
            this.showLoader = false;
            this.closeSliderReport.emit();
          });
        } else {
          if (this.reportEdit.reportType !== 'comparison' && this.reportEdit.reportType !== 'performance-comparison') {
            this.reportEdit.metrics = [];
            this.reportEdit.metricsName = [];
          }

          this.reportS.updateReportsInMongo(this.reportEdit['_id'], this.reportEdit).subscribe(
            res => {
              if(res?.error) {
                this.showLoader = false;
                this.closeSliderReport.emit();
              } else {
                const id = res.reportId || res._id;
                this.showLoader = false;
                this.closeSliderReport.emit();
                this.router.navigateByUrl(`/report/${this.auth.session.gid}/${id}/${this.reportType}`).then();
              }
            },
            err => {
              this.showLoader = false;
              this.closeSliderReport.emit();
            }
          );
        }
      } else {
        this.reportS.getHash().subscribe(hashToken => {
          let report: Report = null;
          report = {
            reportName: this.formReport.reportName,
            lockDates: this.formReport.lockDates,
            dynamic: this.formReport.dynamic,
            accounts: accounts,
            reportType: this.reportType,
            startDatetime,
            endDatetime,
            createdAt: this.dateS.nowTimestamp,
            token: hashToken.token,
            hash: hashToken.hash,
            uid: this.auth.session.uid,
            gid: this.auth.session.gid,
            shared: false,
            sharedOnly: false,
            scheduleReport: this.formReport.scheduleReport,
          };
  
          if (this.scheduleReport.value) {
            report = this.configAutomatedReport(report);
            if (!report) return;
          }
  
          if (this.dynamic.value) {
            report.dynamicRange = this.DynamicRangeControl.value;
            const dateDynamic = this.reportType == 'qanda' ? this.questionsS.buildPeriodDate(this.DynamicRangeControl.value) : this.reportS.converDynamicDateComparison(this.DynamicRangeControl.value, this.reportType);
            report.startDatetime = dateDynamic.startDatetime;
            report.endDatetime = dateDynamic.endDatetime;
          }
  
          if (this.reportType === 'comparison' || this.reportType === 'performance-comparison') {
            this.startDateTimeB.setValue(this.reportS.getFormattedStringDate(this.startDateTimeB.value));
  
            if (this.scheduleReport.value || this.dynamic.value) {
              const dateDynamic = this.reportS.converDynamicDateComparison(report.dynamicRange, this.reportType);
              report.startDatetime = dateDynamic.startDatetime;
              report.endDatetime = dateDynamic.endDatetime;
              report.startDatetimeB = dateDynamic.startDatetimeB;
              report.endDatetimeB = dateDynamic.endDatetimeB;
            } else {
              const startDateBtimeMoment = moment(this.startDateTimeB.value);
              const startDateBtime =  this.reportS.getFormattedMomentDate(startDateBtimeMoment);
              const endDateBtimeMoment = this.setHours(moment(this.endDateTimeB.value));
              const endDateBtime = this.reportS.getFormattedMomentDate(endDateBtimeMoment);
  
              report.startDatetimeB = startDateBtime;
              report.endDatetimeB = endDateBtime;
              report.period = this.period.value;
            }
            report.metrics = this.metricsChecked;
          }
  
          if (this.viewModeChecked != 'new') {
            this.reportS.save(report.gid, report).then(reportSaved => {
              this.showLoader = false;
              this.closeSliderReport.emit();
              this.router.navigateByUrl(`/report/${this.auth.session.gid}/${reportSaved.id}`).then();
            });
          } else {
            this.reportS.saveReportInMongo(report).subscribe(
              res => {
                if (res?.error){
                  this.showLoader = false;
                  this.closeSliderReport.emit();
                } else {
                  const id = res.reportId || res._id;
                  this.closeSliderReport.emit();
                  this.showLoader = false;
                  this.router.navigateByUrl(`/report/${this.auth.session.gid}/${id}/${this.reportType}`).then();
                }
              },
              err => {
                this.showLoader = false;
                this.closeSliderReport.emit();
              }
            );
          }
        });
      }
    }

  }

  deleteLabels(accounts) {
    accounts.forEach(acc => {
      acc.locations.forEach(l => {
        if (l?.labels) {
          delete l.labels;
        }
      })
    })

    return accounts;
  }


  setHours(date) {
    return date.set({'hours': 23, 'minute': 59, 'second': 59});
  }

  configAutomatedReport(report: Report): Report {
    if (!this.FrecuencyControl.valid && this.repeatOn === '' || !this.DynamicRangeControl.valid || this.RecipentsEmails.length === 0) {
      return
    }

    report.frecuencySelector = this.FrecuencyControl.value;
    report.repeatOn = (this.repeatOn).toString();
    report.dynamicRange = this.DynamicRangeControl.value;
    report.recipentsEmails = this.RecipentsEmails;
    report.shared = true;
    report.domain = this.wl.slugDomain;
    const date = moment().utc().toDate(), year = date.getFullYear(), month = date.getMonth(), day = date.getDate(), dayOfWeek = date.getDay();
    if (report.frecuencySelector === 'WeeklyOn') {
      if (dayOfWeek >= Number(report.repeatOn)) {
        const daysToDate =  7 - (dayOfWeek - Number(report.repeatOn));
        report.scheduleDate = moment(new Date(year, month, day + daysToDate )).utc().toISOString();
      } else {
        const daysToDate = Number(report.repeatOn) - dayOfWeek;
        report.scheduleDate = moment(new Date(year, month, day + daysToDate )).utc().toISOString();
      }
    } else {
      if (day >= Number(report.repeatOn)) {
        report.scheduleDate = moment(new Date(year, month + 1, Number(report.repeatOn) )).utc().toISOString();
      } else {
        report.scheduleDate = moment(new Date(year, month, Number(report.repeatOn) )).utc().toISOString();
      }
    }
    return report
  }

  initForm(report: Report): void {
    let periodValue = report && report.period ? this.compareList.find(el => el.value == report.period) : this.compareList[1];
    
    if (report) {
      this.accountsReport = report.accounts;
      this.form = this.fb.group({
        reportName: [report.reportName, Validators.required],
        scheduleReport: [report.scheduleReport ? report.scheduleReport : false],
        startDate: [report.startDatetime || '', Validators.required],
        endDate: [report.endDatetime || '', Validators.required],
        lockDates: [report.lockDates],
        dynamic: [report.dynamic],
        period: [periodValue.value]
      });
      if (report.scheduleReport) {
        this.RecipentsEmails = report.recipentsEmails;
        this.FrecuencyControl.setValue(report.frecuencySelector)
        this.DynamicRangeControl.setValue(report.dynamicRange)
        this.repeatOn = report.repeatOn;
      } else if(report.dynamic) {
        this.DynamicRangeControl.setValue(report.dynamicRange)
      }

      if (report.startDatetime && report.endDatetime) {
        this.dataPicker = {
          range: {start: report.startDatetime, end: report.endDatetime},
        };
      }

      if (this.reportType === 'comparison' || this.reportType === 'performance-comparison') {
        this.dataPicker.rangeB = {start: report.startDatetimeB, end: report.endDatetimeB};
      }
    } else {
      this.form = this.fb.group({
        reportName: ['', Validators.required],
        scheduleReport: [false],
        startDate: ['', Validators.required],
        endDate: ['', Validators.required],
        lockDates: [false, Validators.required],
        dynamic: [false, Validators.required],
      });
      
      if (this.reportType == 'qanda') {
        this.DynamicRangeControl.setValue('allTime');
        this.dynamic.setValue(true);
      }
    }

    if (this.reportType == 'qanda') {
      this.startDateTime.setValidators([]);
      this.startDateTime.updateValueAndValidity();
      this.endDateTime.setValidators([]);
      this.endDateTime.updateValueAndValidity();
    }


    if (this.reportType === 'comparison' || this.reportType === 'performance-comparison') {
      if (report?.metrics) {
        this.metricsChecked = report?.metrics;
      }

      if (report?.metricsName) {
        this.metricsChecked = report?.metricsName;
      }

      if (report) {
        if (periodValue.value == 'custom') {
          const dates = {
            startDatetime: report?.startDatetimeB,
            endDatetime: report?.endDatetimeB
          }
          this.selectDateB = this.reportS.reportSelectDatesFromReport(dates);
          this.rangeBDisabled = false;
        }
        this.period.setValue(periodValue.value);
        this.form.addControl('startDateB', new FormControl(report.startDatetimeB, !report?.dynamic ? Validators.required : null));
        this.form.addControl('endDateB', new FormControl(report.endDatetimeB, !report?.dynamic ? Validators.required : null));
      } else {
        this.form.addControl('period', new FormControl('period', Validators.required));
        this.form.addControl('startDateB', new FormControl('', Validators.required));
        this.form.addControl('endDateB', new FormControl('', Validators.required));
      }
    }
    if (this.dynamic.value) {
      this.DynamicRangeControl.setValidators(Validators.required);
      this.form.updateValueAndValidity();
    }
  }

  addNewRecipent(): void {
    if (this.RecipentsControl.valid) {
      this.RecipentsEmails.push(this.RecipentsControl.value);
      this.RecipentsControl.setValue('');
    }
  }

  deleteRecipent(email: string): void {
    const emailRemoved = this.RecipentsEmails.findIndex(el => el == email)
    this.RecipentsEmails.splice(emailRemoved, 1);
  }

  get reportName(): AbstractControl {
    return this.form.get('reportName');
  }

  get scheduleReport(): AbstractControl {
    return this.form.get('scheduleReport');
  }

  get startDateTime(): AbstractControl {
    return this.form.get('startDate');
  }

  get endDateTime(): AbstractControl {
    return this.form.get('endDate');
  }

  get startDateTimeB(): AbstractControl {
    return this.form.get('startDateB');
  }

  get period(): AbstractControl {
    return this.form.get('period');
  }

  get endDateTimeB(): AbstractControl {
    return this.form.get('endDateB');
  }

  get lockDates(): AbstractControl {
    return this.form.get('lockDates');
  }

  get dynamic(): AbstractControl {
    return this.form.get('dynamic');
  }

  selectedMetrics($event: { name: string, checked: boolean }[]): void {
    this.metricsChecked = $event;
  }

  completeSteeper(): void {
    if (this.reportType !== 'comparison' && this.reportType !== 'performance-comparison') {
      if (this.completeStep1 && this.completeStep2) {
        this.save();
      } else {
        this.closeSliderReport.emit();
      }
    } else {
      if (this.completeStep1 && 
          this.completeStep2 && 
          !this.completeStep3 &&
          this.metricsChecked?.length === 0
        ){
          this.drawer.opened = true;
          this.modalService.openWarningModal('Heads up', 'This report has no metrics selected. Please choose at least 1 metric before trying to save the report.');
          this.step = 2;
          return;
      }

      if (this.completeStep1 && this.completeStep2 && this.completeStep3) {
        this.save();
      }
    }
  }

  getReportTitle(reportType: string) : string {
    if(!reportType){
      return "";
    }
    switch (reportType) {
      case 'qanda':
        reportType = 'Questions And Answers';
        break;
      case 'performance-rollup':
        reportType = 'Performance';
        break;
      default:
        reportType = reportType;
        break;
    }
    reportType = reportType == 'qanda' ? 'Questions And Answers' : reportType
    return this.toTitleCase(reportType?.replace('-', ' ')) + ' Report' ;
  }

  toTitleCase(phrase: string) : string{
    return phrase
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  get completeStep1(): boolean {
    return this.accountsReport?.length > 0;
  }

  get completeStep2(): boolean {
    if (this.scheduleReport?.value) {
      if (this.FrecuencyControl?.valid && this.repeatOn !== '' && this.DynamicRangeControl?.valid && this.RecipentsEmails?.length > 0) {
        return this.FrecuencyControl?.value == 'MonthlyOn' && (this.repeatOn == '0' || parseInt(this.repeatOn) > 28) ? false : true;
      }

    } else if (this.form.valid && this.DynamicRangeControl?.valid) {
      // new conditional to check that range A and custom range B periods are equal in length
      if (this.period?.value === 'custom' && this.reportType === 'performance-comparison') {
        
        return this.rangeAPeriod === this.rangeBPeriod;

      } else {

        return true;
      }
    }
  }

  get completeStep3(): boolean {
    return this.metricsChecked?.length > 0;
  }

  get disabledNextStep() {
    let datepickerIsClosed = true;
    let datepickerIsClosedComparison = true;

    if(this.step == 1) {
      let datePickerElement = this.el.nativeElement.querySelector('ngx-daterangepicker-material.ng-star-inserted');
      datepickerIsClosed = !datePickerElement ? true : datePickerElement?.lastChild?.classList?.contains('hidden');
      

      if (this.reportType == 'performance-comparison' && this.period?.value == 'custom') {
        const datePickerElementComparison = document?.getElementsByClassName('datepicker-rangeB')[0];
        datepickerIsClosedComparison = !datePickerElementComparison ? true : datePickerElementComparison?.childNodes[1]?.childNodes[1]?.lastChild?.['classList'].contains('hidden');
      }
    }


    return !(this.step == 0 ? this.completeStep1 : this.step == 1 ? this.completeStep2 && datepickerIsClosed && datepickerIsClosedComparison : this.completeStep3)
  }

  doValidationMetrics(event) {
    this.metricVerification = event;
  }

  ngOnDestroy(): void {
    this.isEdit = false;
    this.metricsChecked = [];
    this.reportEdit = null;
    this.RecipentsEmails = [];
    this.accountsReport = [];
    this.step = 0;
    this.reportData = {};
    this.dataPicker = DATAPICKER_REPORT_DEFAULT;
    this.DynamicRangeControl.setValue(null);
    this.reportName.setValue(null);
    this.metricVerification = false;
    this.closeSliderReport.emit();
    this.selectDate = null;
    this.DynamicRangeControl.setErrors(null)
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  isDynamicOptionEnabled(dyanmic) {
    let result = false
    // deprecated by Evan's request

    // const dates = this.reportS.converDynamicDateComparison(dyanmic, this.reportType);
    // dates.startDatetime = this.locationS.formatDates(dates.startDatetime);
    // dates.endDatetime = this.locationS.formatDates(dates.endDatetime);

    // if (this.reportType == 'performance-rollup') {
    //   result = !(moment(dates.startDatetime).isSameOrAfter(this.minDate) && moment(dates.endDatetime).isSameOrBefore(this.maxDate));
    // } else if (this.reportType == 'performance-comparison') {
      
    //   dates.startDatetime = this.locationS.formatDates(dates.startDatetime);
    //   dates.endDatetimeB = this.locationS.formatDates(dates.endDatetimeB);
    //   result = !(
    //     moment(dates.startDatetime).isSameOrAfter(this.minDate) && moment(dates.endDatetime).isSameOrBefore(this.maxDate) && 
    //     moment(dates.startDatetimeB).isSameOrAfter(this.minDate) && moment(dates.endDatetimeB).isSameOrBefore(this.maxDate)
    //   );

    //   return result;
    // }

    return result;
  }

  isOptionEnabled(compare) {
    let result = false;
    let endMontheMoment, endMonthStr, startMonthMoment, startMonthStr, isRangeBAnAccepted;

    const reportStartMoment = moment(this.startDateTime.value);
    const reportStartStr = this.reportS.getFormattedMomentDate(reportStartMoment);

    const reportEndMoment = moment(this.endDateTime.value);
    const reportEndStr = this.reportS.getFormattedMomentDate(reportEndMoment);

    switch (compare) { 
      case 'month':
        startMonthMoment =  reportStartMoment.startOf('month');
        startMonthStr = this.reportS.getFormattedMomentDate(startMonthMoment);
       
        endMontheMoment =  reportStartMoment.endOf('month')
        endMonthStr = this.reportS.getFormattedMomentDate(endMontheMoment);

        isRangeBAnAccepted = this.isRangeBAnAccepted('month');
        result = !(reportStartStr === startMonthStr && reportEndStr === endMonthStr && isRangeBAnAccepted);
        break;
      case 'year':
        isRangeBAnAccepted = this.isRangeBAnAccepted('year');
        result = !(reportStartMoment.diff(reportEndMoment, 'years') == 0 && isRangeBAnAccepted);
        break;
      case 'period':
        isRangeBAnAccepted = this.isRangeBAnAccepted('period');
        result = !isRangeBAnAccepted;
        break;
    }

    return result;
  }

  isRangeBAnAccepted(period) {
    const datesB = this.reportS.periodChange(period, this.startDateTime.value, this.endDateTime.value)
    return moment(datesB.startDateB).isAfter(this.minDate) && moment(datesB.endDateB).isBefore(this.maxDate);
  }

  async validateDates(event) {
    if (event.selectedIndex == 1 && (this.reportType == 'keyword' || this.reportType == 'performance-rollup' || this.reportType == 'performance-comparison'|| this.reportType == 'review')) {
      this.showLoader = true
      const accountIds = this.accountsReport.map(el => el.accountId);
      const locationIds = [];
      this.accountsReport.forEach(a => a.locations.map(l => locationIds.push(l.locationId)));
      const dateValidations = await this.locationS.getDateValidations(this.reportType, accountIds, [this.auth.session.gid], locationIds).toPromise();
      const dates = this.locationS.dateValidation(dateValidations);
      this.minDate = this.reportType == 'keyword' && dates.minDate ? dates.minDate.startOf('month').toDate() : dates.minDate;
      this.maxDate = this.reportType == 'keyword' && dates.maxDate ? dates.maxDate.endOf('month').toDate() : dates.maxDate;
      this.buildSelectDate();
      this.showLoader = false;
    }
  }
}
