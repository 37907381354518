import { PipeTransform } from '@angular/core';
var HoursAmPmPipe = /** @class */ (function () {
    function HoursAmPmPipe() {
    }
    HoursAmPmPipe.prototype.transform = function (value) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        if (!value) {
            return;
        }
        value = value.toUpperCase().replace('AM', '');
        value = value.toUpperCase().replace('PM', '');
        var strHours = value.split(':')[0] == '0' ? "00" : value.split(':')[0];
        var strMinutes = value.split(':')[1] == '0' ? "00" : value.split(':')[1];
        var hours = parseInt(strHours, 0);
        var meridian = (hours >= 0 && hours <= 11 || hours == 24) ? 'AM' : 'PM';
        hours = (hours == 0 ? 0 : hours > 12 ? hours - 12 : hours);
        var displayHours = (hours == 0 ? '12' : hours < 10 ? '0' + hours : hours);
        return displayHours + ":" + strMinutes.trim() + " " + meridian.toUpperCase();
    };
    return HoursAmPmPipe;
}());
export { HoursAmPmPipe };
