import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, Subject, of, merge } from 'rxjs';

import * as _ from 'lodash';
import { LocationService } from '../../services/location.service';
import { ModalService } from '../../services/modal.service';
import { GoogleService } from '../../services/google.service';
import { ReviewLinkGeneratorComponent } from '../../components/review-link-generator.component';
import { DomainService } from '../../services/domain.service';
import { AuthService } from '../../services/auth.service';
import * as constants_locations from '../../constants/firestore/account-location';
import { takeUntil, switchMap } from 'rxjs/operators';
import { DataTransferService } from '../../services/data-transfer.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { DELETE_DATA, NOTIFICATION_GENERAL, TYPE_LOG_LOCATION } from 'src/app/constants/notifications';
import { NotificationService } from 'src/app/services/notification.service';
import { WhiteLabelService } from "../../services/white-label.service";
import { Messages, string_message } from 'src/app/constants/messages';
import { GROUP_SUBSCRIPTION_TYPE, LOCATION_SUBSCRIPTION_TYPE } from '../../constants/firestore/account-location';
import { SubscriptionService } from '../../services/subscription.service';
import { LocationRef } from 'src/app/constants/firestore/location-object';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  navigationSubscription;
  locationId: string;
  accountId: string;
  location: Observable<any> = of({});
  private destroySubs$: Subject<boolean> = new Subject();
  private placeId: any;
  public locationTitle;
  public isRevealPath = false;
  public currentLocation;
  public user;
  public subscription;
  public companyName = '';

  c_auth_unauthorized = constants_locations.VERIFICATION_UNAUTHORIZED;
  c_auth_not_found = constants_locations.VERIFICATION_NOT_FOUND;


  constructor(
    public domainService: DomainService,
    public auth: AuthService,
    private _route: ActivatedRoute,
    private _locationS: LocationService,
    private _modalService: ModalService,
    private _googleService: GoogleService,
    private _dataTransfer: DataTransferService<string>,
    private _spinnerService: SpinnerService,
    private _notificationService: NotificationService,
    private _wl: WhiteLabelService,
    private _router: Router,
    private _apiSubscription: SubscriptionService,
  ) {
    this.auth.subscription$.subscribe(
      subscription => this.subscription = subscription
    );
    this.user = this.auth.session;
  }

  ngOnInit(): void {
    this._wl.branding.then(res => this.companyName = res['company_name']);
    this.loadLocation();

    /*this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.loadLocation();
      }
    });

    this.locationTitle = this.location.subscribe(location => {
      const title = location ? `${location.locationName} @ ${location.location.address.addressLines[0]}`: '';
      this.wl.setTitle(title);
    });*/
  }

  getStatus(status) {
    if(!status) { return; }
    return status.replace(/\_/g, " ")
  }

  loadLocation() {
    this.locationId = this._route.snapshot.params?.locationId;
    this.accountId  = this._route.snapshot.params?.accountId;
    this.location = this._locationS.getRef(this.auth.session.gid, this.accountId, this.locationId);
    this.location.pipe(takeUntil(this.destroySubs$)).subscribe(location => {
      this.currentLocation = this.formData(location);
      this._dataTransfer.setData(location.locationName);
      this.placeId = location ? location.location.locationKey.placeId : null;
    });
  }

  formData(location) {
    if (location && this.subscription) {
      let status = '';
      if (location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE && this.subscription.status === GROUP_SUBSCRIPTION_TYPE.TRIAL) {
        status = 'ULTIMATE TRIAL';
      } else if (location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE && this.subscription.status !== GROUP_SUBSCRIPTION_TYPE.TRIAL) {
        status = 'ESSENTIAL';
      }
      if (location.subscriptionType !== LOCATION_SUBSCRIPTION_TYPE.FREE) {
        status = location.subscriptionType;
      }
      location['subscriptionStatus'] = status;
    }

    return location
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }

    this.destroySubs$.next(true);
    this.destroySubs$.unsubscribe();
  }


  handleReviewLinkGenerator() {
    if (!this.placeId) {
      return;
    }

    this._googleService.dynamicUrl(this.placeId).subscribe(url => {
      const dialogRef = this._modalService.openGenericModal(ReviewLinkGeneratorComponent, url, data => null);
      dialogRef.disableClose = true;
    });
  }

  openDeleteDialog(): void {
    this._modalService.openConfirmModal(`Are you sure you want to disconnect ${this.currentLocation?.locationName} from ${this.companyName}?`, 
    `Note: this only disconnects the profile from ${this.companyName}; it does not delete your business profile from Google Maps. You can always re-add your business profile to ${this.companyName}.`, (res) => {
      if (!res) {
        return;
      }
      this.auth.processSubscription();
      //Notification informative delete location
      if (this.currentLocation?.address)
      this.currentLocation.formatAddress = this._locationS.formatAddress(this.currentLocation?.address)
      else
      this.currentLocation.formatAddress = ''

      const delete$ = of(this._locationS.deleteReferencesToLocation(this.user.gid, this.locationId));
      delete$.pipe(
        switchMap( () => this._locationS.deleteLocation(this.user.gid, this.currentLocation.locationId, this.accountId))
      ).subscribe(() => {
        this._spinnerService.loading$.next(false);
      }, err => {
        this._spinnerService.loading$.next(false);
        console.error('Error deleting locations', err)
      },() => {
        this.currentLocation = { accountId: this.accountId, address: this.currentLocation?.formatAddress, ...this.currentLocation}
        const meta = this._notificationService.getMetaTypeLog( TYPE_LOG_LOCATION, this.currentLocation);
        const notify$ = merge(this._notificationService.saveNotification(this.auth.session.gid, this._wl.baseDomain, string_message(Messages.notifications.LOCATION_TOGGLE, [this.currentLocation?.locationName, this.currentLocation?.address, DELETE_DATA]), NOTIFICATION_GENERAL, TYPE_LOG_LOCATION, meta))
        notify$.subscribe();
        this._router.navigate(['/accounts']).then();
      });
    }, 2);
  }

  async changePlan() : Promise<void> {
    if (this.auth.isMember) {
      await this._modalService.openWarningModal('Contact your administrator',
                                               'Your user doesn’t have permissions to do this. Contact your account administrator to change your role.')
      return;
    }

    let locationsChange : LocationRef[] = []
    if (!_.isArray(this.currentLocation)) {
      locationsChange = [{ locationId: this.currentLocation, accountId: this.accountId }];
    }
    else {
      this.currentLocation.forEach(location => {
        locationsChange.push({ locationId: location, accountId: this.accountId })
      });
    }

    await this._apiSubscription.flowChangeLocationsPlan(this.subscription, locationsChange)


  }
}
