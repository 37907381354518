import { AuthService } from './auth.service';
import { BehaviorSubject } from 'rxjs';
import { GoogleService } from './google.service';
import { EXTERNAL_GRADE_ID } from '../constants/session';
import { ExternalGradeService } from './external-grade.service';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "./google.service";
import * as i3 from "./external-grade.service";
var GoogleLocationService = /** @class */ (function () {
    function GoogleLocationService(auth, google, externalGradeService) {
        this.auth = auth;
        this.google = google;
        this.externalGradeService = externalGradeService;
        this.dataStore = { locations: [] };
        this._locations = new BehaviorSubject([]);
    }
    Object.defineProperty(GoogleLocationService.prototype, "locations", {
        get: function () {
            return this._locations.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    GoogleLocationService.prototype.loadAll = function (user, accountId) {
        var _this = this;
        var gmbToken = this.auth.getGmbToken().subscribe(function (token) {
            _this.google.getLocations(token, accountId).subscribe(function (data) {
                var locations = 'locations';
                _this.dataStore = data;
                _this.dataStore[locations].sort(function (a, b) { return (a.locationName > b.locationName) ? 1 : -1; });
                _this._locations.next(Object.assign({}, _this.dataStore).locations);
                gmbToken.unsubscribe();
            });
        });
    };
    GoogleLocationService.prototype.loadAllGrade = function (user, accountId) {
        var _this = this;
        var externalGradeId = localStorage.getItem(EXTERNAL_GRADE_ID);
        var sub = this.externalGradeService.getExternalGradeToken(user.gid, externalGradeId).subscribe(function (token) {
            _this.google.getLocations(token, accountId).subscribe(function (data) {
                sub.unsubscribe();
                var locations = 'locations';
                _this.dataStore = data;
                _this.dataStore[locations].sort(function (a, b) { return (a.locationName > b.locationName) ? 1 : -1; });
                _this._locations.next(Object.assign({}, _this.dataStore).locations);
            });
        });
    };
    GoogleLocationService.prototype.reset = function () {
        this._locations = new BehaviorSubject([]);
    };
    GoogleLocationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GoogleLocationService_Factory() { return new GoogleLocationService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.GoogleService), i0.ɵɵinject(i3.ExternalGradeService)); }, token: GoogleLocationService, providedIn: "root" });
    return GoogleLocationService;
}());
export { GoogleLocationService };
