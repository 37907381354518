// app
import User from "./firestore/user";

export interface Session extends User {
  [x: string]: any;
}

// localStorage keys
export const IS_IMPERSONATING = 'impersonating';
export const SESSION = 'session';
export const SESSION_EXTERNAL = 'session_external';
export const EXTERNAL_GRADE_ID = 'external_grade_id';

export const STORAGE_CUSTOM_SCRIPT = 'custom-script'
export const STORAGE_GRADE_LEAD = 'gradeLead'

export const STORAGE_ALL_KEYS = [IS_IMPERSONATING, SESSION, SESSION_EXTERNAL, 
                                 EXTERNAL_GRADE_ID, STORAGE_CUSTOM_SCRIPT, 
                                 STORAGE_GRADE_LEAD] as const

