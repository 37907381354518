import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-modal-edit',
  templateUrl: './modal-edit.component.html',
  styleUrls: ['./modal-edit.component.scss']
})
export class ModalEditComponent implements OnInit {

  constructor(
      public dialogRef: MatDialogRef<ModalEditComponent>
  ) {
  }
  ngOnInit() {
  }

  save() {
  }
}
