import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appEmailPhoneValidation]'
})
export class EmailPhoneValidationDirective {
  @Input() appEmailPhoneValidation: string;

  constructor(private el: ElementRef) { }

  @HostListener('input') onInput(){
    let text = this.el.nativeElement.value;
    text = text.replaceAll(/[\(\)"\s-]/g, "");
    const errorContainer = document.getElementById(this.appEmailPhoneValidation);
    const phoneRegex = /\b(\+?\d{1,3}(\s?\(\d{1,}\))?)?\s?\d{3,}[-.\s]?\d{3,}[-.\s]?\d{4}\b/g;
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/;

    if (emailRegex.test(text) || phoneRegex.test(text)) {
      const text = 'Please refrain from entering phone numbers or email addresses. Remove any existing contact information.'
      errorContainer.textContent = text;
    } else {
      errorContainer.textContent = null;
    }
  }

}
