// dep
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

// app
import { HEADERS_NO_AUTH } from '../constants/auth'
import { environment } from "@environment";


@Injectable({
  providedIn: 'root'
})
export class EmailerService {

  constructor(private http: HttpClient) {
  }

  // FIXME: Security issue, no arbitrary mails should be sent from frontend / 
  // is bad to have a backend endpoint open for that.
  send(email: string, subject: string, message: string) {
    const body = {
      'to': email,
      'subject': subject,
      'message': message
    };

    return this.http.post(`${environment.apiUrl}/v2/emailer/send`, body)
  }

  sendResetPasswordMail(data) {
    return this.http.post(`${environment.apiUrl}/v2/auth/reset`, data, HEADERS_NO_AUTH).toPromise()
  }

  // TODO: Unused, remove?
  // subscriberMailerLite(email: string, name: string, company: string, domain: string) {
  //   const body = { email, name, company, domain };
  //   return this.http.post(`${environment.apiUrl}/v2/emailer/ml/subscriber`, body)
  // }
  // 
  // modifyMailerLite(status: string, upgraded: number, locations: number, email: string, updateAll = false) {
  //   const data = { 'status': status , 'upgraded': upgraded, 'locations': locations, 'email': email, 'update_all': updateAll };
  //   return this.http.post(`${environment.apiUrl}/v2/emailer/ml/modify`, data)
  // }
  
}
