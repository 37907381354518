import { OnChanges, OnInit, EventEmitter, SimpleChanges } from '@angular/core';
import { ModalSpecialHoursComponent } from "../../modal-special-hours/modal-special-hours.component";
import { MatDialog } from "@angular/material/dialog";
import { DatesService } from "src/app/services/dates.service";
import { LocationService } from "../../../services/location.service";
import * as moment from 'moment';
var LocationSpecialHoursComponent = /** @class */ (function () {
    function LocationSpecialHoursComponent(dialog, datesS, locationS) {
        this.dialog = dialog;
        this.datesS = datesS;
        this.locationS = locationS;
        this.isEdit = true;
        this.isUnderReview = false;
        this.countRegularHours = 0;
        this.onUpdate = new EventEmitter();
        this.orderPeriods = function (a, b) {
            var dateA = a.key.split('/')[1] + "/" + a.key.split('/')[0] + "/" + a.key.split('/')[2];
            var dateB = b.key.split('/')[1] + "/" + b.key.split('/')[0] + "/" + b.key.split('/')[2];
            return new Date(dateB) > new Date(dateA) ? -1 : 1;
        };
    }
    LocationSpecialHoursComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.specialHours) {
            var specialHourPeriods = JSON.parse(JSON.stringify(this.specialHours.specialHourPeriods));
            specialHourPeriods.forEach(function (el) {
                if (!el.isClosed) {
                    el.openTime = _this.datesS.getStringHours(el.openTime);
                    el.closeTime = _this.datesS.getStringHours(el.closeTime);
                }
            });
            this.specialHourPeriods = this.locationS.joinByDay(specialHourPeriods);
            this.specialHourPeriods = this.sortPeriods();
        }
        else {
            this.specialHourPeriods = {};
        }
    };
    LocationSpecialHoursComponent.prototype.ngOnChanges = function (changes) {
        if (!changes.specialHours.firstChange) {
            this.ngOnInit();
        }
    };
    LocationSpecialHoursComponent.prototype.sortPeriods = function () {
        var _this = this;
        var keys = Object.keys(this.specialHourPeriods);
        var periods = {};
        keys.sort(function (a, b) {
            var aSplit = a.split('/');
            var dateA = aSplit[1] + "/" + aSplit[0] + "/" + aSplit[2];
            var bSplit = b.split('/');
            var dateB = bSplit[1] + "/" + bSplit[0] + "/" + bSplit[2];
            return new Date(dateB) < new Date(dateA) ? 1 : -1;
        });
        keys.forEach(function (p) { return periods[p] = _this.specialHourPeriods[p]; });
        return periods;
    };
    LocationSpecialHoursComponent.prototype.openSpecialHoursDialog = function () {
        var _this = this;
        var _a;
        var dialogRef = this.dialog.open(ModalSpecialHoursComponent, {
            width: '810px',
            data: {
                specialDays: this.specialHourPeriods,
                previousDay: ((_a = this.specialHours) === null || _a === void 0 ? void 0 : _a.specialHourPeriods) || []
            }
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe(function (res) {
            if (res != '')
                _this.onUpdate.emit(true);
        });
    };
    LocationSpecialHoursComponent.prototype.formatDate = function (date) {
        var dateSplit = date.split('/');
        date = dateSplit[1] + "/" + dateSplit[0] + "/" + dateSplit[2];
        return moment(date).format('MM/DD/YYYY');
    };
    Object.defineProperty(LocationSpecialHoursComponent.prototype, "hasSpecialHours", {
        get: function () {
            var _a;
            return !!((_a = Object.keys(this.specialHourPeriods)) === null || _a === void 0 ? void 0 : _a.length);
        },
        enumerable: true,
        configurable: true
    });
    return LocationSpecialHoursComponent;
}());
export { LocationSpecialHoursComponent };
