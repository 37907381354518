import { EventEmitter, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ModalProductsCollectionComponent } from '../modal-products-collection/modal-products-collection.component';
import { ModalProductsCollectionEditComponent } from '../modal-products-collection-edit/modal-products-collection-edit.component';
import { ModalProductsItemComponent } from '../modal-products-item/modal-products-item.component';
import { ModalProductsItemEditComponent } from '../modal-products-item-edit/modal-products-item-edit.component';
import { UuidService } from '../../services/uuid.service';
import { LocationEditService } from 'src/app/services/location-edit.service';
export var PRODUCT_DATA = [];
var ModalProductsComponent = /** @class */ (function () {
    function ModalProductsComponent(dialogRef, dialog, uuid, locationEditS, data) {
        this.dialogRef = dialogRef;
        this.dialog = dialog;
        this.uuid = uuid;
        this.locationEditS = locationEditS;
        this.data = data;
        this.touchEdit = false;
        this.updateProduct = new EventEmitter();
        this.priceLists = this.filterProducts(this.data.priceList);
    }
    ModalProductsComponent.prototype.ngOnInit = function () {
    };
    ModalProductsComponent.prototype.filterProducts = function (priceLists) {
        var _this = this;
        var _a;
        var result = [];
        if (priceLists) {
            result = (_a = priceLists.filter(function (p, index) {
                if (p.labels.find(function (la) { return la.displayName === 'Products'; })) {
                    _this.productsIndex = index;
                }
                return p.labels.find(function (la) { return la.displayName === 'Products'; });
            })[0]) === null || _a === void 0 ? void 0 : _a.sections;
        }
        else {
            result = [
                {
                    priceListId: this.uuid.uuidRandom,
                    labels: [{ displayName: 'Products' }],
                    sections: []
                }
            ];
        }
        return result;
    };
    ModalProductsComponent.prototype.addSectionProduct = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ModalProductsCollectionComponent, {
            width: '680px',
            panelClass: 'overflow--visible',
            data: {
                products: this.priceLists,
                productsIndex: this.productsIndex
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.touchEdit = true;
                _this.priceLists.push({
                    sectionId: result.id,
                    labels: [{
                            displayName: result.productCollectionName,
                            description: result.productCollectionDesc,
                        }],
                    items: []
                });
            }
        });
    };
    ModalProductsComponent.prototype.editProduct = function (i) {
        var _this = this;
        var dialogRef = this.dialog.open(ModalProductsCollectionEditComponent, {
            width: '680px',
            panelClass: 'overflow--visible',
            data: {
                productName: this.priceLists[i].labels[0].displayName,
                productDescription: this.priceLists[i].labels[0].description
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.touchEdit = true;
                _this.priceLists[i].labels[0] = { displayName: result.productName, description: result.productDescription };
            }
        });
    };
    ModalProductsComponent.prototype.addProductItem = function (i) {
        var _this = this;
        var dialogRef = this.dialog.open(ModalProductsItemComponent, {
            width: '680px',
            panelClass: 'overflow--visible',
            data: {
                product: this.priceLists[i]
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.touchEdit = true;
                _this.priceLists[i].items.push(result.productItem);
            }
        });
    };
    ModalProductsComponent.prototype.editProductItem = function (i, j) {
        var _this = this;
        var dialogRef = this.dialog.open(ModalProductsItemEditComponent, {
            width: '680px',
            panelClass: 'overflow--visible',
            data: {
                productIndex: i,
                itemIndex: j,
                productItem: this.priceLists[i].items[j]
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.touchEdit = true;
                if (!result.removeItem) {
                    _this.priceLists[i].items[j] = result.productItem;
                }
                else {
                    _this.priceLists[i].items.splice(j, 1);
                }
            }
        });
    };
    ModalProductsComponent.prototype.apply = function () {
        var editPriceList = this.data.priceList;
        editPriceList[this.productsIndex].sections = this.priceLists;
        var edit = this.locationEditS.locationEdit;
        edit.priceLists = editPriceList;
        this.locationEditS.setAndUpdate().toPromise().then();
        this.dialogRef.close();
    };
    return ModalProductsComponent;
}());
export { ModalProductsComponent };
