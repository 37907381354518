export interface PriceList {
  labels: label[]
  priceListId: string
  sections: Section[]
}

export interface Section {
  labels: label[]
  items?: PriceListItems[]
  sectionId: string
  sectionType: SectionType
}


export interface PriceListItems {
  "itemId": string,
  "labels": label[],
  "price": Money
}


interface Money {
  "currencyCode": string,
  "units": string,
  "nanos": number
}

interface label {
  "displayName": string,
  "description"?: string,
  "languageCode"?: string
}


export enum SectionType{
  SECTION_TYPE_UNSPECIFIED='SECTION_TYPE_UNSPECIFIED',
  FOOD = 'FOOD',
  SERVICES = 'SERVICES'
}
export const SECTION_TYPES =  ['SECTION_TYPE_UNSPECIFIED', 'FOOD', 'SERVICES'];


