import { OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { LocationService } from "../services/location.service";
var AddressComponent = /** @class */ (function () {
    function AddressComponent(locationService) {
        this.locationService = locationService;
        this.hasInfoModal = false;
        this.serviceAreas = [];
    }
    AddressComponent.prototype.ngOnChanges = function (changes) {
        this.formatServiceAreas();
        this.formatAddress();
    };
    AddressComponent.prototype.ngOnInit = function () {
        this.formatServiceAreas();
        // @Todo: Add logic for missing address 0, and support other formats.
        this.formatAddress();
    };
    AddressComponent.prototype.hasAddress = function () {
        return this.address && Object.keys(this.address).length;
    };
    AddressComponent.prototype.formatAddress = function () {
        if (this.address)
            this.formattedAddress = this.locationService.formatAddress(this.address);
        else
            this.formattedAddress = '';
    };
    AddressComponent.prototype.formatServiceAreas = function () {
        var _a;
        this.formattedServiceAreas = (_a = this.serviceAreas) === null || _a === void 0 ? void 0 : _a.map(function (el) { return el.placeName; }).join(' | ');
    };
    return AddressComponent;
}());
export { AddressComponent };
var AddressOuterComponent = /** @class */ (function () {
    function AddressOuterComponent(locationService) {
        this.locationService = locationService;
    }
    AddressOuterComponent.prototype.ngOnChanges = function (changes) {
        this.formatAddress();
    };
    AddressOuterComponent.prototype.ngOnInit = function () {
        // @Todo: Add logic for missing address 0, and support other formats.
        this.formatAddress();
    };
    AddressOuterComponent.prototype.formatAddress = function () {
        if (this.address)
            this.formattedAddress = this.locationService.formatAddress(this.address);
        else
            this.formattedAddress = '';
    };
    return AddressOuterComponent;
}());
export { AddressOuterComponent };
