import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {Router} from '@angular/router';
import {MatDialogRef} from '@angular/material/dialog';
import {UserService} from '../../../services/user.service';
import {GroupService} from '../../../services/group.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment as ENV} from '@environment';
import Group from 'src/app/constants/firestore/group';

@Component({
  selector: 'app-delete-user-dialog',
  templateUrl: './delete-user-dialog.component.html',
  styleUrls: ['./delete-user-dialog.component.scss']
})
export class DeleteUserDialogComponent implements OnInit {

  public user;
  onDelete = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public router: Router,
    public dialogRef: MatDialogRef<DeleteUserDialogComponent>,
    private userService: UserService,
    private groupService: GroupService,
    private snack: SnackbarService,
    private http: HttpClient
  ) {
    this.user = data.user;
  }

  ngOnInit() {
  }

  delete(): void{
    this.userService.deleteUser(this.user).subscribe(
      res => {
        this.dialogRef.close(true);
      },
      error => {
        if (error.error?.message){
          this.snack.openError(`Error: ${error.error?.message}`, 4000);
        } else{
          this.snack.openError(`Unexpected Error: Please contact support`, 4000)
        }
        this.dialogRef.close(false);
      }
    )
  }

}