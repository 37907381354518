// dep
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
var ComparisonReportsComponent = /** @class */ (function () {
    function ComparisonReportsComponent(_route) {
        this._route = _route;
        this.reportType = 'performance-comparison';
    }
    ComparisonReportsComponent.prototype.ngOnInit = function () {
        var _a, _b, _c;
        this.reportType = (_c = (_b = (_a = this._route.snapshot) === null || _a === void 0 ? void 0 : _a.routeConfig) === null || _b === void 0 ? void 0 : _b.path) === null || _c === void 0 ? void 0 : _c.replace('-report', '');
    };
    return ComparisonReportsComponent;
}());
export { ComparisonReportsComponent };
