// app
import AccountReport from "../services/account-report"

export const COMPONENTS_CORE = [
    {key: 'locationName', value: 'Business Name'}, 
    {key: 'primaryCategory', value: 'Categories (primary and secondary)'},
    {key: 'regularHours', value: 'Regular Hours'}, 
    {key: 'moreHours', value: 'More Hours'}, 
    {key: 'specialHours', value: 'Special Hours'},
    {key: 'primaryPhone', value: 'Primary Phone and Secondary Phone'}, 
    {key: 'websiteUrl', value: 'Primary Website URL ("Website")'},
    {key: 'service', value: 'Services'}, 
    {key: 'menu', value: 'Menu'},
    {key: 'attributes', value: 'Attributes'}, 
    {key: 'profile', value: 'Business Descriptions'}, 
    {key: 'openInfo', value: 'Opening Date & Status'} ] as const


export const COMPONENTS_MISC = [{key: 'labels', value:'Labels'}, 
                                {key: 'adWordsLocationExtensions', value: 'Google Ads Location Extensions' }, 
                                {key: 'photos', value:'Images/Photos'}] as const


export interface BulkLocationInfo {
    _id?: string;
    accounts: AccountReport[];
    gid: string;
    component: string;
    status: string;
    change: any;
    replace: boolean;
    createdAt?: string;
    createDate?: Date;
}

export interface LocationState {
    isGoogleUpdated?: boolean,
    isDuplicate?: boolean,
    isSuspended?: boolean,
    canUpdate?: boolean,
    canDelete?: boolean,
    isVerified?: boolean,
    needsReverification?: boolean,
    isPendingReview?: boolean,
    isDisabled?: boolean,
    isPublished?: boolean,
    isDisconnected?: boolean,
    isLocalPostApiDisabled?: boolean,
    canModifyServiceList?: boolean,
    canHaveFoodMenus?: boolean,
    hasPendingEdits?: boolean,
    hasPendingVerification?: boolean,
    canOperateHealthData?: boolean,
    canOperateLodgingData?: boolean
}

