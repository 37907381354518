import { __assign, __read, __spread } from "tslib";
import { OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { LocationService } from '../../../services/location.service';
import { GoogleService } from '../../../services/google.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { ModalAddServiceComponent } from '../../modal-add-service/modal-add-service.component';
import { ModalServiceItemComponent } from '../../modal-service-item/modal-service-item.component';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
var ServicesLocationComponent = /** @class */ (function () {
    function ServicesLocationComponent(dialog, locationS, googleS, snackS, activateRoute, auth) {
        this.dialog = dialog;
        this.locationS = locationS;
        this.googleS = googleS;
        this.snackS = snackS;
        this.activateRoute = activateRoute;
        this.auth = auth;
        this.dataSource = [];
        this.aditionalCategories = [];
        this.combineCategories = [];
        this.accountId = '';
        this.locationId = '';
        this.loading = false;
        this.showMoreMessage = false;
        this.showOnly = false;
        this.updateService = false;
    }
    ServicesLocationComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.activateRoute.snapshot.parent.params.locationId) {
            this.locationId = this.activateRoute.snapshot.parent.params.locationId;
            this.accountId = this.activateRoute.snapshot.parent.params.accountId;
            //TODO Add 'type' in getRef service. 
            this.locationS.getRef(this.auth.session.gid, this.accountId, this.locationId).take(1).subscribe(function (result) {
                if ('serviceList' in result)
                    _this.serviceList = result.serviceList;
                if (!result['location']['priceLists'])
                    result.location.priceLists = [];
                var location = result.location;
                _this.primaryCategory = location.primaryCategory;
                _this.aditionalCategories = location.additionalCategories;
                _this.checkData();
            });
        }
        else {
            this.checkData();
        }
    };
    Object.defineProperty(ServicesLocationComponent.prototype, "ServiceList", {
        get: function () {
            return this.serviceList;
        },
        enumerable: true,
        configurable: true
    });
    ServicesLocationComponent.prototype.checkData = function () {
        this.primaryCategory = __assign(__assign({}, this.primaryCategory), { primary: true });
        if (this.aditionalCategories)
            this.combineCategories = __spread([this.primaryCategory], this.aditionalCategories);
        else
            this.combineCategories = [this.primaryCategory];
        this.serviceList = this.serviceList.map(function (s) {
            if ('price' in s) {
                if (s.price.nanos && s.price.nanos != 0) {
                    s.price.nanos = s.price.nanos * Math.pow(10, -7);
                }
            }
            return s;
        });
        this.dataSource = this.locationS.organizeServiceList(this.serviceList, this.combineCategories);
    };
    ServicesLocationComponent.prototype.toggleMessage = function (i) {
        this.showMoreMessage = !this.showMoreMessage;
        this.showMoreMessageIndex = i;
        return;
    };
    ServicesLocationComponent.prototype.addItem = function (position) {
        var _this = this;
        var dialogRef = this.dialog.open(ModalAddServiceComponent, {
            width: '480px',
            panelClass: 'overflow--visible',
            data: {
                category: this.combineCategories[position]
            }
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.serviceList.push(result.item);
                var data = _this.dataSource.find(function (x) { return x.categoryId === result.category; });
                if (data) {
                    data.services.push(result.structuredItem);
                }
            }
        });
    };
    ServicesLocationComponent.prototype.refreshServices = function (serviceList) {
        this.serviceList = serviceList;
        this.checkData();
        this.snackS.openSuccess('Services updated', 3000);
    };
    ServicesLocationComponent.prototype.editItem = function (service, position, sectionIndex, serviceMaskIndex) {
        var _this = this;
        if (!this.showOnly) {
            //TODO: When the getRef service returns 'type', remove this logyc 
            var type = 'fixed';
            if (service.price) {
                if (Number(service.price.units) === 0 || (service.price.units == null && service.price.nanos == null)) {
                    type = 'free';
                }
            }
            else {
                type = 'noPrice';
            }
            //
            var dialogRef = this.dialog.open(ModalServiceItemComponent, {
                width: '480px',
                panelClass: 'overflow--visible',
                data: {
                    structuredService: service,
                    item: this.serviceList[position],
                    typeServiceItem: type,
                    position: position
                }
            });
            dialogRef.afterClosed().subscribe(function (result) {
                if (result) {
                    if (result.action === 'removed') {
                        _this.serviceList.splice(position, 1);
                        _this.dataSource = _this.locationS.organizeServiceList(_this.serviceList, _this.combineCategories);
                    }
                    else if (result.action === 'updated') {
                        _this.serviceList[position] = result.item;
                        _this.dataSource[sectionIndex].services[serviceMaskIndex] = result.structuredItem;
                    }
                }
            });
        }
    };
    ServicesLocationComponent.prototype.parseMoney = function (units, nanos) {
        return (units || 0) + "." + (nanos || 0);
    };
    ServicesLocationComponent.prototype.setServiceList = function (serviceList) {
        this.serviceList = serviceList;
        this.dataSource = this.locationS.organizeServiceList(this.serviceList, this.combineCategories);
    };
    ServicesLocationComponent.prototype.apply = function () {
        var _this = this;
        this.loading = true;
        this.googleS.updateServices(this.accountId, this.locationId, this.serviceList).toPromise().then(function (data) {
            if (data) {
                _this.loading = false;
                _this.snackS.openSuccess('The services was uploaded successfully', 2000);
                _this.serviceList = data;
                _this.dataSource = _this.locationS.organizeServiceList(_this.serviceList, _this.combineCategories);
            }
        }, function (err) {
            _this.loading = false;
            _this.snackS.openSuccess("An error has occurred " + err, 2000);
        });
    };
    return ServicesLocationComponent;
}());
export { ServicesLocationComponent };
