// dep
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { DatesService } from '../../services/dates.service';
import { ModalService } from '../../services/modal.service';
import { environment } from '../../../environments/environment';
import { LoadComponent } from '../../components/load.component';
var DashboardGradeComponent = /** @class */ (function () {
    function DashboardGradeComponent(route, dateS, modalService) {
        this.route = route;
        this.dateS = dateS;
        this.modalService = modalService;
        this.userExternal = null;
        this.isShared = false;
        this.isReport = false;
        this.show = false;
        this.isExternal = false;
        this.accountId = this.route.parent.snapshot.params.accountId;
        this.locationId = this.route.parent.snapshot.params.locationId;
        this.locations = [{ accountId: this.accountId,
                locationId: this.locationId }];
    }
    DashboardGradeComponent.prototype.ngOnInit = function () {
        this.modalService.openModal(LoadComponent, { title: 'The Grader is loading',
            content: 'Please wait a moment while we scan your business listing' }, { autoCloseMS: 12000 });
        if (this.userExternal) {
            this.gid = this.userExternal.gid;
        }
        else if (this.user) {
            this.gid = this.user.gid;
        }
        if (this.externalAccountId && this.externalLocationId) {
            this.accountId = this.externalAccountId;
            this.locationId = this.externalLocationId;
            this.isExternal = true;
        }
        if (this.dataPickerReport) {
            this.dataPicker = this.dataPickerReport;
            if (this.gradeReport) {
                this.isReport = false;
                this.accountId = this.dataPickerReport.locations[0].accountId;
                this.locationId = this.dataPickerReport.locations[0].locationId;
                this.locations = this.dataPickerReport.locations;
            }
            else {
                this.isReport = true;
            }
            this.locationsEmpty = true;
            if (!this.locations) {
                this.locations = this.dataPickerReport.locations;
            }
        }
        else if (this.locations) {
            this.getDataPicker();
        }
        else {
            this.getDataPicker();
        }
    };
    DashboardGradeComponent.prototype.getDataPicker = function () {
        var lastMonth = this.dateS.dateLessMonth(new Date(Date.now()), 1);
        var start = new Date(lastMonth);
        start.setDate(1);
        var end = new Date(lastMonth);
        end.setDate(this.dateS.lastDayOfMonthDate(end));
        this.dataPicker = {
            range: {
                start: moment(start).format('YYYY-MM-DD'),
                end: moment(end).format('YYYY-MM-DD')
            },
            locations: this.locations,
            aggregation: 'week'
        };
    };
    DashboardGradeComponent.prototype.handleHeaderSuccess = function ($event) {
        this.headerSuccess = $event;
        this.show = this.validateIfShow;
    };
    DashboardGradeComponent.prototype.handleMetricsSuccess = function ($event) {
        this.metricsSuccess = $event;
        this.show = this.validateIfShow;
    };
    DashboardGradeComponent.prototype.handleComparisonSuccess = function ($event) {
        this.comparisonSuccess = $event;
        this.show = this.validateIfShow;
    };
    Object.defineProperty(DashboardGradeComponent.prototype, "validateIfShow", {
        get: function () {
            if (this.metricsSuccess === false && this.comparisonSuccess === false && !this.isReport) {
                this.noData = true;
                return false;
            }
            else if (this.headerSuccess && this.metricsSuccess && this.comparisonSuccess && !this.isReport) {
                this.noData = false;
                return true;
            }
            else if (this.metricsSuccess && this.comparisonSuccess && this.isReport) {
                this.noData = false;
                return true;
            }
            else if (this.metricsSuccess && this.comparisonSuccess && !this.isReport) {
                this.noData = false;
                return true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardGradeComponent.prototype, "validateIfprogress", {
        get: function () {
            if (!this.isReport) {
                return !(this.headerSuccess !== undefined && this.metricsSuccess !== undefined && this.comparisonSuccess !== undefined);
            }
            else {
                return !(this.metricsSuccess !== undefined && this.comparisonSuccess !== undefined);
            }
        },
        enumerable: true,
        configurable: true
    });
    DashboardGradeComponent.prototype.qualification = function (event) {
        var total = (environment.hideComparisonInGrader) ? event.totals : (event.totals + this.comparisonSuccess.data.points);
        var max = (environment.hideComparisonInGrader) ? event.maxs : (event.maxs + this.comparisonSuccess.data.max);
        var totalPercent = ((total / max) * 100).toFixed(0);
        this.overallGrade = parseFloat(totalPercent);
    };
    return DashboardGradeComponent;
}());
export { DashboardGradeComponent };
