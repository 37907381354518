import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ModalService } from '../services/modal.service';
import { AuthService } from '../services/auth.service';
import { GROUP_SUBSCRIPTION_TYPE } from '../constants/firestore/account-location';
import { SubscriptionService } from '../services/subscription.service';
import { ISubscription } from '../constants/subscription';
import { FeaturedComponent } from '../components/featured.component';
import { LocationRef } from '../constants/firestore/location-object';
import { ModalGetAddOn } from '../modules/modal-get-addon/modal-get-addon.component';


@Directive({
  selector: '[gmbGGActive]'
})
export class GroupGatedPaidDirective implements OnInit {
  subscription$: Observable<ISubscription>;
  subscription: ISubscription;
  @Input() gmbGGActive = () => { };

  constructor(
    private _modalService: ModalService,
    private _auth: AuthService,
    private _subscriptionService: SubscriptionService,
    private _route: ActivatedRoute,
    private _element: ElementRef,
  ) {
    this.subscription$ = this._auth.subscription$;
    this.subscription$.subscribe(subscription => this.subscription = subscription);
  }

  async ngOnInit() {
    if (this.subscription.status === GROUP_SUBSCRIPTION_TYPE.PAID) {
      this._element.nativeElement.removeAllListeners('click');
      this._element.nativeElement.addEventListener('click', this.handler.bind(this));
    }
  }

  // TODO: Search #TODO-refactor-featured-model to find code to refactor against this 
  // code block. 
  async handler() : Promise<void> {
    console.log('GroupGatedPaidDirective handler');
    if(await this._modalService.openModal(FeaturedComponent, null) && 
       await this._subscriptionService.flowChangeLocationsPlan(this.subscription, [this._route.snapshot.parent.params as LocationRef]))
         console.log("upgrade successful - group-gated.directive")
  }
  
}

@Directive({
  selector: '[gmbSubscriptionInTrial]'
})
export class GroupGatedTrialDirective implements OnInit {
  subscription$: Observable<ISubscription>;
  subscription: ISubscription;
  @Input() gmbSubscriptionInTrial = () => { };

  constructor(
    private _modalService: ModalService,
    private _auth: AuthService,
    private _subscriptionService: SubscriptionService,
    private _route: ActivatedRoute,
    private _element: ElementRef,
  ) {
    this.subscription$ = this._auth.subscription$;
    this.subscription$.subscribe(subscription => this.subscription = subscription);
  }

  async ngOnInit() {
    if (this.subscription?.status) {
      if ((this.subscription?.pricingVersion < 3) &&
           this.subscription.status === GROUP_SUBSCRIPTION_TYPE.TRIAL) {
        this._element.nativeElement.removeAllListeners('click');
        this._element.nativeElement.addEventListener('click', this.handler.bind(this));
      }
    }
  }

  // TODO: Search #TODO-refactor-featured-model to find code to refactor against this 
  // code block. 
  async handler() : Promise<void> {
    console.log('GroupGatedTrialDirective handler');
    if(await this._modalService.openModal(FeaturedComponent, null) &&
       await this._subscriptionService.flowChangeLocationsPlan(this.subscription, [this._route.snapshot.parent.params as LocationRef]))
         console.log("upgrade successful - group-gated.directive")
  }

}


@Directive({
  selector: '[gmbBulkUpdateAddOn]'
})
export class GroupGatedBulkUpdateAddOnDirective implements OnInit {
  subscription$: Observable<ISubscription>;
  subscription: ISubscription;
  @Input() gmbBulkUpdateAddOn = () => { };

  private _bulk_actions_enabled: boolean;
  constructor(
    private _modalService: ModalService,
    private _auth: AuthService,
    private _element: ElementRef,
  ) {
    this.subscription$ = this._auth.subscription$;
    this.subscription$.subscribe(subscription => this.subscription = subscription);
  }

  async ngOnInit() : Promise<void> {
    this._bulk_actions_enabled = (
      (this.subscription?.pricingVersion < 3) || (
        this.subscription?.pricingVersion >= 3 &&
        this.subscription?.packages?.hasOwnProperty('pkg_bulk_actions')
      ) 
    )

    if (!this._bulk_actions_enabled) {
      this._element.nativeElement.removeAllListeners('click');
      this._element.nativeElement.addEventListener('click', this.handler.bind(this));
    }
  }

  async handler() : Promise<void> {
    await this._modalService.openModal(ModalGetAddOn,
      {
        title: 'Add-On: Bulk Actions and Roll-Up Reports',
        message: '',
        buttons: {
          'cancel': 'Maybe Later',
          'accept': 'Get Add-On'
        }
      }, 
      { 
        config: { 
          width: '830px' 
        }
      }
    )
  }
}