<div class="dialog__header" *ngIf="!bulk">
    <h2 mat-dialog-title class="txt--xl"><strong>Google Ads Phone</strong></h2>
</div>
<div class="dialog__content overflow--visible" mat-dialog-content>
    <div class="dialog-row">
        <div class="dropdown--fixed" [formGroup]="phoneForm">
            <label class="input-group__label w--100">Enter Google Ads Phone</label>
            <div class="w--100">
                <ngx-intl-tel-input
                    [enablePlaceholder]="true"
                    [enableAutoCountrySelect]="true"
                    [phoneValidation]="true"
                    [maxlength]="20"
                    name="phone_number"
                    formControlName="phone">
                </ngx-intl-tel-input>
                <p *ngIf="phone?.invalid" class="txt--red pt--10 m--0">Invalid phone number</p>
            </div>
        </div>
    </div>

</div>
<div mat-dialog-actions class="dialog__footer" *ngIf="!bulk">
    <span class="alternate-theme d-inline-block mr--10">
        <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
    </span>
    <button mat-flat-button (click)="apply()"  tabIndex="-1" color="accent" class="btn btn--action">Apply</button>
</div>
