import { __awaiter, __generator } from "tslib";
import { Router } from '@angular/router';
// app
import { AuthService } from './auth.service';
import { LocationService } from './location.service';
import { GROUP_SUBSCRIPTION_TYPE, LOCATION_SUBSCRIPTION_TYPE } from '../constants/firestore/account-location';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./auth.service";
import * as i3 from "./location.service";
var RedirectGuardService = /** @class */ (function () {
    function RedirectGuardService(router, auth, locationService) {
        var _this = this;
        this.router = router;
        this.auth = auth;
        this.locationService = locationService;
        this.subscription$ = this.auth.subscription$;
        this.subscription$.subscribe(function (subscription) { return _this.subscription = subscription; });
    }
    RedirectGuardService.prototype.canActivate = function (route) {
        return __awaiter(this, void 0, void 0, function () {
            var locationId, accountId, location;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.user = this.auth.session;
                        locationId = route.parent.params.locationId;
                        accountId = route.parent.params.accountId;
                        return [4 /*yield*/, this.locationService.fetchLocation(this.user.gid, accountId, locationId)];
                    case 1:
                        location = _a.sent();
                        if (!(this.subscription.status !== GROUP_SUBSCRIPTION_TYPE.TRIAL && location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.router.navigate(['/account', accountId, 'location', locationId, 'insights'])];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, false];
                    case 3: return [2 /*return*/, true];
                }
            });
        });
    };
    RedirectGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RedirectGuardService_Factory() { return new RedirectGuardService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AuthService), i0.ɵɵinject(i3.LocationService)); }, token: RedirectGuardService, providedIn: "root" });
    return RedirectGuardService;
}());
export { RedirectGuardService };
