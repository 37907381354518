import { Component, Input, OnInit } from '@angular/core';
import { PriceList } from 'src/app/constants/google/price-list-section';

@Component({
  selector: 'app-location-products',
  templateUrl: './location-products.component.html',
  styleUrls: ['./location-products.component.scss']
})
export class LocationProductsComponent implements OnInit {

  @Input() priceList: PriceList[];

  constructor() { }

  ngOnInit() {}

}
