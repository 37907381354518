import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { environment } from "@environment";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BLUE_COLOR, CORAL_COLOR } from '../../constants/chart-color';

import moment from 'moment';
import * as _ from 'lodash';

import { InsightsService } from '../../services/insights.service';
import { IComparisonResponse, IStats } from '../../constants/comparison'
import { DataPicker } from '../../constants/data-picker';
import { ORDER_KEYS } from '../../constants/google/location-stats';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-grade-comparison',
  templateUrl: './grade-comparison.component.html',
  styleUrls:  ['./grade-comparison.component.scss']
})
export class GradeComparisonComponent implements OnInit, OnDestroy {

  @Output() finish = new EventEmitter();
  @Input() locations: any;
  @Input() metrics: any[];
  @Input() dataPicker: DataPicker;
  @Input() columns: number;
  @Input() type: any;
  @Input() externalComparison;
  @Input() refreshFilter;
  @Input() showScoreOnCard = false;
  @Input() chartBy: string;
  @Input() information: string;
  @Input() reportId: string;
  @Input() expand: boolean;
  @Input() isExternal = false;
  @Input() fromReportsScreen = false;
  @Input() reportType = '';
  @Input() viewModeChecked = null;


  public comparison: IComparisonResponse = null;
  public labels: any;
  public labels_b: any;
  public gradeKeys: string[];
  public stats: IStats;
  public selectedItem: number;
  public dataSelected: any;
  public tooltipDataSelected: any = { rangeA: [], rangeB: [] };
  public progress = true;
  public startDate: string;
  public endDate: string;
  public environment;
  public comparisonPartialData = false;
  public comparisonEmptyData = false;
  public comparisonPartialDataMessage = '';
  public comparisonEmptyDataMessage = '';
  public rangesDiffer = false;
  public primaryDataColor = BLUE_COLOR;
  public secondaryDataColor = CORAL_COLOR;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private insightS: InsightsService,
    private loadingService: LoadingService
  ) {
    this.environment = environment;
  }

  public ngOnInit(): void {
    if (this.type === 'grade') {
      this.getGradeData()
    } 
    if (this.expand) {
      this.getData();
    }

    this.refreshFilter?.pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
      this.dataPicker = result;
      this.getData();
    });

    if (this.externalComparison) {
      this.externalComparisonSet(this.externalComparison);
      return;
    }
  }

  public getData(): void {
    this.progress = true;
    this.chartBy && (this.dataPicker.aggregation = this.chartBy);
    this.insightS.comparison(this.locations, this.dataPicker, this.metrics, this.type, this.isExternal, this.reportId, this.viewModeChecked).take(2).subscribe(result => {
      this.progress = false;
      if (!result) {
        this.comparison = undefined;
        this.finish.emit(false);
        return;
      }

      if (Array.isArray(result) && result?.length === 0) {
        this.comparison = undefined;
        this.finish.emit(false);
        return;
      }
      this.comparison = result as IComparisonResponse;

      if (this.comparison.stats) {
        this.comparison.stats = this.orderingStats(this.comparison.stats);

        if (this.type === 'report') {
          const start = (this.dataPicker.range.start)?.split('T')[0];
          const end = (this.dataPicker.range.end)?.split('T')[0];
          const startB = (this.dataPicker.rangeB.start)?.split('T')[0];
          const endB = (this.dataPicker.rangeB.end)?.split('T')[0];

          this.startDate = this.buildDate(start, end);
          this.endDate = this.buildDate(startB, endB);


          // check in runtime for different ranges, in case the user modifies the date range in the picker:
          const dateStart = moment(start);
          const dateEnd = moment(end);
          const dateStartB = moment(startB);
          const dateEndB = moment(endB);

          const daysDifferenceStartEnd = dateEnd.diff(dateStart, 'days');
          const daysDifferenceStartBEndB = dateEndB.diff(dateStartB, 'days');
          this.rangesDiffer = daysDifferenceStartEnd !== daysDifferenceStartBEndB;
        }

        this.tooltipDataSelected.rangeA = Object.assign(this.comparison.labels.map(l => `Range A ${l}:`), []);
        this.tooltipDataSelected.rangeB = Object.assign(this.comparison.labels.map(l => `Range B ${l}:`), []);
        this.stats = this.comparison.stats;
        this.loadingService.reportAdvanced(1, "Comparison Insigths")
        this.setDataComparison(result);
      } else {
        this.finish.emit(false);
      }
    }, error => {
      this.finish.emit(false);
      this.progress = false;
    });
  }

  public buildDate(start: string, end: string): string {
    const startDate = (start?.split('-') || []);
    const endDate = (end?.split('-')  || []);

    return `${startDate[1]?.padStart(2, '0')}/${startDate[2]?.padStart(2, '0')}/${startDate[0]} - ${endDate[1]?.padStart(2, '0')}/${endDate[2]?.padStart(2, '0')}/${endDate[0]}`
  }

  public getGradeData(): void {
    this.progress = true;
    this.chartBy && (this.dataPicker.aggregation = this.chartBy);
    this.insightS.getGradeComparison(this.locations[0].locationId, this.dataPicker).take(2).subscribe(result => {
      this.progress = false;
      if (!result) {
        this.comparison = undefined;
        this.finish.emit(false);
        return;
      }

      // TODO: Duplicate code, intention was to do the second check like in the getData method?
      // if (!result) {
      //   this.comparison = undefined;
      //   this.finish.emit(false);
      //   return;
      // }

      this.comparison = result.data as IComparisonResponse;
      
      if (this.comparison.stats) {
        this.startDate = `${moment(this.dataPicker.range.start).format('MM/DD/YYYY')} - ${moment(this.dataPicker.range.end).format('MM/DD/YYYY')}`;
        this.endDate = `${ moment(this.comparison.startDateB).format('MM/DD/YYYY') } - ${ moment(this.comparison.endDateB).format('MM/DD/YYYY') }`;
        
        this.tooltipDataSelected.rangeA = Object.assign(this.comparison.labels.map(l => `Range A ${l}:`), []);
        this.tooltipDataSelected.rangeB = Object.assign(this.comparison.labels.map(l => `Range B ${l}:`), []);
        
        this.comparison.stats = this.orderingStats(this.comparison.stats);
        this.stats = this.comparison.stats;
        
        this.loadingService.reportAdvanced(1, "Comparison Insigths")
        this.setDataComparison(result);
      } else {
        this.finish.emit(false);
      }
    }, error => {
      this.finish.emit(false);
      this.progress = false;
    });
  }


  public isShowMetric(metric): boolean {
    if (!this.metrics) {
      return true;
    }
    return this.metrics.includes(metric);

  }


  public setSelectedItem(i: number, key): void {

    this.comparisonPartialData = false; // reset the flag
    this.comparisonEmptyData = false;

    this.selectedItem = i;
    this.dataSelected = this.stats[key];

    // used to build the empty data message
    const hasNoDataInRangeA = this.dataSelected?.totalA === 0;
    const hasNoDataInRangeB = this.dataSelected?.totalB === 0;
    const hasNoDataInSomeRange = this.dataSelected?.totalA === 0 || this.dataSelected?.totalB === 0;
    const hasNoDataInBothRange = hasNoDataInRangeA && hasNoDataInRangeB;
    
    // used to build the partial data message
    const hasGapsInRangeA = this.dataSelected?.rangeA?.includes(0);
    const hasGapsInRangeB = this.dataSelected?.rangeB?.includes(0);
    const hasGapsInData = this.dataSelected?.rangeA?.includes(0) || this.dataSelected?.rangeB?.includes(0);
    const hasGapsInBothRange = hasGapsInRangeA && hasGapsInRangeB;
    
    if (hasNoDataInSomeRange) {
      this.comparisonEmptyData = true;

      // empty data message factory
      this.comparisonEmptyDataMessage = `
      There is no data in
      ${hasNoDataInRangeA ? ' Range A' : ''}
      ${hasNoDataInBothRange ? ' and' : ''}
      ${hasNoDataInRangeB ? ' Range B' : ''}
      `;

    } else if (hasGapsInData || this.rangesDiffer) {
      this.comparisonPartialData = true;
      
      // partial data message factory
      this.comparisonPartialDataMessage = `
        ${this.rangesDiffer ? 'The amount of days in range A and range B differ. ' : ''}
        ${hasGapsInData ? 'There are gaps in the data for' : ''}
        ${hasGapsInRangeA ? ' Range A' : ''}
        ${hasGapsInBothRange ? ' and' : ''}
        ${hasGapsInRangeB ? ' Range B' : ''}
        ${hasGapsInData ? '. Because some of the values represent zero, the comparison may not be useful.' : ''}
        `;
    }
  }


  public externalComparisonSet(external): void {
    if (!external) {
      return;
    }
    this.setDataComparison(external);
  }


  public setDataComparison(result): void {
    this.progress = false;
    if (this.comparison) {
      this.finish.emit(result);
      this.labels = (this.comparison as IComparisonResponse)?.labels;
      this.labels_b = (this.comparison as IComparisonResponse)?.labels_b;
      this.gradeKeys = Object.keys((this.comparison as IComparisonResponse)?.stats);
      this.setSelectedItem(0, this.gradeKeys[0]);
    }
  }

  public tooltipContent(item: any): string {
    return `${item.points}/${item.max}`;
  }

  public orderingStats(stats): any {
    const result = {};
    const keys = _.keys(stats);
    for (const key in ORDER_KEYS) {
      if (ORDER_KEYS.hasOwnProperty(key) && _.includes(keys, key)) {
        result[key] = _.get(stats, key);
      }
    }

    return result;
  }

  public getGraphRiseImage(selectedItem :number, index: number, difference: number) :string {
    if(selectedItem !== index && difference > 0) {
      return "/assets/images/icons/graph-rise-green.svg";
    }

    if(selectedItem !== index && difference < 0) {
      return "/assets/images/icons/graph-rise-red.svg";
    }

    return "/assets/images/icons/graph-rise.svg";
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
