export class EventNames {

  public EVENT_DATEPICKER_SUBSCRIPTION: string = "DATE PICKER SUBSCRIPTION";


  constructor() {
  }

}

// note to self can't use const in class
