import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GoogleService} from '../../services/google.service';
import {ReviewsService} from '../../services/reviews.service';
import {ModalService} from '../../services/modal.service';

@Component({
  selector: 'app-reply-edit',
  templateUrl: './reply-edit.component.html',
  styleUrls: ['./reply-edit.component.scss']
})
export class ModalReplyReviewComponent implements OnInit {

  comment = new FormControl('', Validators.required);
  readonly data: any;
  isEdit: boolean;
  activeDelete: boolean;
  title: string;
  reviewer: any;
  readOnly: boolean;
  actionText: any;
  profilePhoto: any;
  progress: boolean;
  confirmDelete = false;
  color: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) private config,
    private googleS: GoogleService,
    private reviewS: ReviewsService,
    private dialog: MatDialogRef<ModalReplyReviewComponent>,
    private modalS: ModalService,
  ) {

    this.data = config.data;

    if (this.data.activeDelete) {
      this.activeDelete = true;
    }
    this.googleS.getProfilePhoto(this.data.accountId, this.data.locationId).subscribe(result => {
      this.profilePhoto = result;
    });


    this.reviewer = this.data.reviewer;
    if (this.data?.reviewReply?.comment) {
      this.comment.setValue(this.data?.reviewReply.comment);
    } else {
      this.comment.setValue(this.data?.reviewReply);
    }

    if (this.data.reviewReply) {
      this.isEdit = true;
      this.title = 'edit reply';
      this.actionText = 'update';
      return;
    }

    this.title = 'reply review';
    this.actionText = 'reply';
  }

  ngOnInit(): void {}

  apply = () => {
    if (this.comment.invalid) {
      return;
    }

    this.progress = true;
    this.reviewS.reply( this.data.accountId, this.data.locationId, this.data.reviewId, this.comment.value).subscribe(
      result => {
        this.progress = false;
        if (result) {
          delete this.data.error;
          this.dialog.close(this.comment.value);
          return;
        }
        this.dialog.close(false);
      },
      err => {
        this.dialog.close(false);
      });
  }


  handleConfirmDelete() {
    this.modalS.openConfirmModal('Delete Reply', '', result => {
      if (result) {
        this.progress = true;
        this.reviewS.replyDelete(this.data.accountId, this.data.locationId, this.data.reviewId).subscribe(
          res => {
            this.progress = false;
            this.dialog.close(true);
          },
          err => {
            this.progress = false;
            this.dialog.close(false);
          }
        );
      }
    });
  }


}
