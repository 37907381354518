// GMB Location Object (See Google specs)

import AccountLocation from './account-location';
import SavedLocation from './saved-location';

export default interface LocationObject {
  [x: string]: any;

  placeId?: string;
  locationKey: {
    [x: string]: any;
  };
  locationName: string;
  accountLocation?: AccountLocation;
}


export interface LocationRef {
  accountId: string
  locationId: string // | { locationId : string}
}


// TODO: Normalize data on callers site to don't need this function
export function normalizeLocationRef(l: LocationRef | { accountId: string, 
                                                        locationId: SavedLocation }): LocationRef {
  return {
    accountId: l.accountId,
    locationId: (l.locationId as SavedLocation).locationId || (l.locationId as string)
  }

}

