import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatIconModule,
  MatFormFieldModule,
  MatMenuModule,
  MatSidenavModule,
  MatTableModule,
  MatToolbarModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule
} from '@angular/material';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ColorPickerModule } from 'ngx-color-picker';
import { QuillModule } from 'ngx-quill';

import { StartsComponent } from '../modules/starts/starts.component';
import { AddressComponent, AddressOuterComponent } from '../components/address.component';
import { DateAgoPipe } from '../pipes/date-ago.pipe';
import { WidgetComponent } from '../widget/components/widget.component';
import { ColorDatePickerComponent } from '../modules/color-date-picker/color-date-picker.component';
import { ReadMoreComponent } from '../components/read-more.component';
import { FormInputUploadComponent } from '../modules/form-input-upload/form-input-upload.component';
import { FormInputUploadMultipleComponent } from '../modules/form-input-upload-multiple/form-input-upload-multiple.component';
import { TrialMessageComponent } from '../modules/trial-message/trial-message.component';
import { PaginatorComponent } from '../modules/charts/paginator/paginator.component';
import { HoursAmPmPipe } from '../pipes/hours-am-pm.pipe';
import { FireTimestampPipe } from '../pipes/date-fire-timestamp.pipe';
import { SwiperModule, SWIPER_CONFIG, SwiperConfigInterface } from 'ngx-swiper-wrapper';
 
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

@NgModule({
  imports: [CommonModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatMenuModule,
    MatSidenavModule,
    MatTableModule,
    ColorPickerModule,
    FormsModule,
    MatProgressBarModule,
    MatToolbarModule,
    NgxSkeletonLoaderModule,
    QuillModule.forRoot(),
    MatPaginatorModule,
    MatProgressSpinnerModule,
    SwiperModule
  ],
  exports: [
    ColorDatePickerComponent,
    CommonModule,
    FormsModule,
    QuillModule,
    AddressComponent,
    AddressOuterComponent,
    FormInputUploadComponent,
    FormInputUploadMultipleComponent,
    StartsComponent,
    WidgetComponent,
    ReadMoreComponent,
    DateAgoPipe,
    TrialMessageComponent,
    PaginatorComponent,
    FireTimestampPipe,
    HoursAmPmPipe,
  ],
  declarations: [
    ColorDatePickerComponent,
    WidgetComponent,
    StartsComponent,
    DateAgoPipe,
    AddressComponent,
    AddressOuterComponent,
    FormInputUploadComponent,
    FormInputUploadMultipleComponent,
    ReadMoreComponent,
    TrialMessageComponent,
    PaginatorComponent,
    FireTimestampPipe,
    HoursAmPmPipe,
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ]
})
export class SharedModule {
}
