/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./grade-explain-section.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/divider/typings/index.ngfactory";
import * as i3 from "@angular/material/divider";
import * as i4 from "./grade-explain-section.component";
var styles_GradeExplainSectionComponent = [i0.styles];
var RenderType_GradeExplainSectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GradeExplainSectionComponent, data: {} });
export { RenderType_GradeExplainSectionComponent as RenderType_GradeExplainSectionComponent };
export function View_GradeExplainSectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "header", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h3", [["class", "mb--15 mt--15"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "mat-divider", [["class", "mat-divider"], ["role", "separator"]], [[1, "aria-orientation", 0], [2, "mat-divider-vertical", null], [2, "mat-divider-horizontal", null], [2, "mat-divider-inset", null]], null, null, i2.View_MatDivider_0, i2.RenderType_MatDivider)), i1.ɵdid(6, 49152, null, 0, i3.MatDivider, [], null, null), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 4, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 6).vertical ? "vertical" : "horizontal"); var currVal_2 = i1.ɵnov(_v, 6).vertical; var currVal_3 = !i1.ɵnov(_v, 6).vertical; var currVal_4 = i1.ɵnov(_v, 6).inset; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
export function View_GradeExplainSectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-grade-explain-section", [], null, null, null, View_GradeExplainSectionComponent_0, RenderType_GradeExplainSectionComponent)), i1.ɵdid(1, 114688, null, 0, i4.GradeExplainSectionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GradeExplainSectionComponentNgFactory = i1.ɵccf("app-grade-explain-section", i4.GradeExplainSectionComponent, View_GradeExplainSectionComponent_Host_0, { title: "title" }, {}, ["*"]);
export { GradeExplainSectionComponentNgFactory as GradeExplainSectionComponentNgFactory };
