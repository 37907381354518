import { PipeTransform } from '@angular/core';
var TopicTypePipe = /** @class */ (function () {
    function TopicTypePipe() {
    }
    TopicTypePipe.prototype.transform = function (value) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        switch (value.toUpperCase()) {
            case 'STANDARD':
                return 'Standard post';
            case 'ALERT':
                return 'COVID-19';
            default:
                return value;
        }
    };
    return TopicTypePipe;
}());
export { TopicTypePipe };
