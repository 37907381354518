<div class="dialog__header" *ngIf="!bulk">
  <h2 mat-dialog-title class="m--0 txt--xl"><strong>Opening Date</strong></h2>
</div>
<div class="dialog__content" mat-dialog-content>
  <div class="dialog-row row pb--0">
    <div class="col-12">
      <mat-form-field class="input-field w--100">
        <mat-label>Open for business?</mat-label>
        <mat-select [(ngModel)]="statusBusiness" (ngModelChange)="statusChanged()">
          <mat-option [value]="item.key" *ngFor="let item of listOpen">{{item?.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="dialog-row row pb--0" *ngIf="statusBusiness == 'OPEN'">
    <div class="col-4">
      <mat-form-field class="input-field w--100" hideRequiredMarker="always">
        <input matInput maxlength="4" placeholder="Year" [formControl]="year" name="openingDateYear" [required]="isRequired(month, 'year')">
        <mat-error *ngIf="year?.errors && year?.errors?.required"><p>Year is required</p></mat-error>
        <mat-error *ngIf="(year?.errors && !year?.errors?.required) || isValidYear(year)"><p>Enter a 4-digit year using the digits 0-9</p></mat-error>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field class="input-field w--100" hideRequiredMarker="always">
        <mat-label>Select Month</mat-label>
        <mat-select class="w--100" [formControl]="month" placeholder="Select a month" (selectionChange)="changeMonth()" [required]="isRequired(year, 'month')">
          <mat-option>Month</mat-option>
          <mat-divider></mat-divider>
          <mat-option *ngFor="let month of months" [value]="month?.value?month.value: months[0]">
            {{ month?.name }}
          </mat-option>
        </mat-select>
        <mat-error><p>Month is required</p></mat-error>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field class="input-field w--100">
        <mat-label>Select Day</mat-label>
        <mat-select class="w--100" [formControl]="day">
          <mat-option value="0">Day</mat-option>
          <mat-divider></mat-divider>
          <mat-option *ngFor="let day of days;" [value]="day">
            {{ day }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
<div mat-dialog-actions class="dialog__footer" *ngIf="!bulk">
    <span class="alternate-theme d-inline-block mr--10">
        <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
    </span>
  <button mat-flat-button (click)="apply()" tabIndex="-1" color="accent" class="btn btn--action">Apply</button>
</div>
