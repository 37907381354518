import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {LocationService} from "../services/location.service";

@Component({
  selector: 'gmb-address',
  template: `
	  <p *ngIf='hasAddress()' [ngClass]="isGray ? ' txt--gray mb--30' : 'm--0'">{{formattedAddress}}</p>
    <p *ngIf='!hasAddress() && !hasInfoModal' class="m--0 w--300-px txt-overflow-ellipsis" [ngClass]="isGray ? ' txt--gray mb--30' : 'm--0'">{{formattedServiceAreas}}</p>
    <read-more *ngIf="!hasAddress() && hasInfoModal" [txtLink]='"View"' [isWhite]="true" [text]="formattedServiceAreas" [maxLength]="50" [showToggleButton]="true"></read-more>
  `,
  styles: [`
    .service-area-label {
      width: 25%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    `]
})
export class AddressComponent implements OnInit, OnChanges {

  @Input() address;
  @Input() isGray;
  @Input() hasInfoModal: boolean = false;
  @Input() serviceAreas = [];
  formattedServiceAreas: string;
  formattedAddress: string;

  constructor(private locationService:LocationService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.formatServiceAreas();
    this.formatAddress();
  }


  ngOnInit() {
    this.formatServiceAreas();
    // @Todo: Add logic for missing address 0, and support other formats.
    this.formatAddress();
  }

  hasAddress() {
    return this.address && Object.keys(this.address).length
  }


  formatAddress() {
    if (this.address) this.formattedAddress = this.locationService.formatAddress(this.address)
    else this.formattedAddress = ''
  }

  formatServiceAreas() {
    this.formattedServiceAreas = this.serviceAreas?.map(el => el.placeName).join(' | ');
  }
}

@Component({
  selector: 'gmb-address-outer',
  template: `
	  <span [ngClass]="isGray ? ' txt--gray mb--30' : 'm--0'" [outerHTML]="formattedAddress"></span>
  `,
})
export class AddressOuterComponent implements OnInit, OnChanges {

  @Input() address;
  @Input() isGray;
  formattedAddress: string;

  constructor(private locationService:LocationService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.formatAddress();
  }


  ngOnInit() {
    // @Todo: Add logic for missing address 0, and support other formats.
    this.formatAddress();
  }


  formatAddress() {
    if (this.address) this.formattedAddress = this.locationService.formatAddress(this.address)
    else this.formattedAddress = ''
  }
}
