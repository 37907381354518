import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subject } from 'rxjs';
import * as _ from 'lodash';

import { DomainService } from '../../services/domain.service';
import { WhiteLabelService } from '../../services/white-label.service';

@Component({
  selector: 'app-text-reader',
  templateUrl: './text-reader.component.html',
  styleUrls: ['./text-reader.component.scss']
})
export class TextReaderComponent implements OnInit, OnDestroy {
  editor: string;
  title: string;
  subsManager$: Subject<boolean> = new Subject();

  constructor(
    public domainService: DomainService,
    private route: ActivatedRoute,
    private wl: WhiteLabelService
  ) {}

  ngOnInit() {
    this.wl.getDynamicWhiteLabel().then((data: any) => {
      const { text } = this.route.snapshot.params;
      const { content } = data;
      let value = '';
      switch (text) {
        case 'terms-and-conditions':
          value = _.get(content, 'terms_service_text') || '';
          this.title = 'Terms of Service';
          break;
        case 'privacy-policy':
          value = _.get(content, 'privacy_policy_text') || '';
          this.title = 'Privacy Policy';
          break;
        case 'payment-policy':
            value = _.get(content, 'payment_policy_text') || '';
            this.title = 'Payment Policy';
            break;
        default:
          break;
      }
      this.editor = value;
    });
  }

  ngOnDestroy(): void {
    this.subsManager$.next(true);
    this.subsManager$.complete();
  }

}
