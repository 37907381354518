import { Component, OnInit } from '@angular/core';
import { REPORT_TYPE } from 'src/app/constants/firestore/report';

@Component({
  selector: 'app-search-keywords-view',
  templateUrl: './search-keywords-view.component.html',
  styleUrls: ['./search-keywords-view.component.scss']
})
export class SearchKeywordsViewComponent implements OnInit {
  reportType: REPORT_TYPE = 'keyword';

  constructor() {
    // This is intentional
  }

  ngOnInit() {
    // This is intentional
  }

}
