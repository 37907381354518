<div class="spinner-wrapper spinner-wrapper--fixed" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>

<div class="body__slider">
    <div class="bg--dark--white slider-drawer p--20 pt--15 pb--15">
        <h2 mat-dialog-title class="txt--lg mb--10 mt--10 txt--black"><strong>Status of Locations</strong></h2>
        <p class="fw--700 txt--14 {">{{postTitle}}</p>
        <div class="dialog__close row mt--5">
            <button mat-button class="btn txt--underline pl--0 txt--black" color="primary" (click)="destroyData()">Close</button>
        </div>
    </div>

    <div class="m--15 hp--100 overflow--auto">
        <ng-container *ngFor="let element of statusData">
            <div class="d-inline-block col-6 vertical--align--middle pt--10">
                <p class="m--0 txt--black txt--md">
                    <strong>{{ element?.location?.name }}</strong><br>
                </p>
                <p class="txt--lightgray">
                    <gmb-address [address]='element?.location?.address' [serviceAreas]="element?.location?.serviceArea?.places?.placeInfos"></gmb-address>
                </p>
            </div>

            <div class="d-inline-block br--8 section-status col-2 m--0" [ngClass]="getStatusClass(element?.status)">
                <p class="m--0 d-inline-block">{{statusLabel(element?.status) | titlecase}}</p>
                <button mat-icon-button class="btn btn-icon status-button" (click)="infoAboutStatus(element)"
                *ngIf="element?.status === 'CONTACT_SUPPORT' || element?.status === 'REJECTED' || element?.status === 'FAILED' || (element?.hasFailedTask && (element?.bulkStatus === 'ACTIVE' || element?.bulkStatus === 'SUCCESS'))">
                    <em class="fas fa-question-circle"></em>
                </button>
            </div>
            
            <div class="d-inline-block col-4 txt--align--end">
                <span class="alternate-theme">
                    <button mat-flat-button color="warn" class="btn btn-icon buttons-actions mr--10" (click)="goToLocation(element)">
                        <em class="fal fa-external-link"></em>
                    </button>
                </span>

                <button *ngIf="element?.status === 'FAILED'" mat-flat-button color="primary" class="btn btn-icon buttons-actions mr--10" (click)="retryPost(element)">
                    <em class="fas fa-redo"></em>
                </button>

                <button *ngIf="element?.status !== 'PENDING' && element?.status !== 'DELETED'" mat-flat-button color="warn" class="btn btn-icon buttons-actions mr--10" (click)="deleteLocationsModal(element, false)">
                    <em class="far fa-trash-alt"></em>
                </button>
            </div>
        </ng-container>
    </div>

    <mat-divider></mat-divider>

    <div class="dialog__footer">
        <div class="row pull--right mt--20 mb--20 pr--30">
            <button mat-flat-button color="primary" class="btn btn--icon pt--10 pb--10 mr--20" [disabled]="existFailed()" (click)="retryAll(statusData)">
                Retry All Failed <em class="fas fa-redo ml--10" data-html2canvas-ignore></em>
            </button>
            <button mat-flat-button color="warn" class="btn btn--icon pt--10 pb--10  mr--20" [disabled]="existFailed()" (click)="deleteLocationsModal(null, true)">
                Delete all failed <em class="far fa-trash-alt ml--10" data-html2canvas-ignore></em>
            </button>
        </div>
    </div>
</div>
