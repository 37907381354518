import {EventEmitter, Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {BehaviorSubject} from 'rxjs';
import {GoogleService} from './google.service';
import User from '../constants/firestore/user';
import GoogleAccount from '../constants/firestore/google-account';
import AccountObject from '../constants/firestore/account-object';
import { ExternalGradeService } from './external-grade.service';

@Injectable({
  providedIn: 'root'
})

// This refers to Google GMB Accounts.
export class GoogleAccountService {
  private dataStore: {
    accounts: AccountObject[]
  };
  private _accounts: BehaviorSubject<GoogleAccount[]>;
  onLoadAll = new EventEmitter();
  // private token : any; // TODO: Unused, remove


  constructor(
    private auth: AuthService,
    private google: GoogleService,
    private externalGradeService: ExternalGradeService,
  ) {

    this.dataStore = {accounts: []};
    this._accounts = new BehaviorSubject([] as GoogleAccount[]);
  }

  get accounts() {
    return this._accounts.asObservable();
  }

  resetAccounts() {
    this._accounts.next([]);
  }


  loadAll() { // Bad smell
    this.auth.getGmbToken().subscribe(token => {
      this.loadAccounts(token);
    });
  }

  loadAllGrade(gid, externalGradeId) {
    this.externalGradeService.getExternalGradeToken(gid, externalGradeId).subscribe(token => {
      if (token.token_expiry) {
        // this.token = token;  TODO: Unused, remove
        this.loadAccounts(token);
      }
    });
  }

  // TODO: Not used, remove
  // getToken() {
  //   return this.token;
  // }


  loadAccounts(token) {
    this.google.getAccounts(token).subscribe((data) => {
      this.dataStore = data;

      this.dataStore['accounts'].sort((a, b) => (a.accountName > b.accountName) ? 1 : -1);
      const accountsArray = Object.assign({}, this.dataStore).accounts;

      this._accounts.next(accountsArray);
      this.onLoadAll.emit({success: true, token});
    }, err => {
      this.onLoadAll.emit({success: false, details: err});
    });
  }


  async google_oauth(user: User, externalId?) {
      const urlOauth = await this.google.authenticate(user.gid, user.uid, externalId);
      await this.openOauthWindow(urlOauth, user.gid, externalId);
  }


  async openOauthWindow(url, gid, externalId) {
    const oauth = window.open(url, '_blank');
    // This popup ends up being a redirection so we cannot detect the real close event.
    // So we use an interval trick to overcome this.
    const popupTick = setInterval(() => {
      if (oauth.closed) {
        clearInterval(popupTick);
        this.loadAllGrade(gid, externalId);
      }
    }, 500);
  }


}


