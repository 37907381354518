<div class="box box--rounded box--shadow bg--white" [ngStyle]="{'color': textColor, 'background-color': backgroundColor}">
  <div class="box__header">
    <!--skeleton for name-->
    <div class="d-flex justify-content-between align-items-center" *ngIf="!location">
      <div>
        <ngx-skeleton-loader count="1" [theme]="{ width: '150px', height: '20px', 'display': 'block' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" [theme]="{ width: '250px', height: '20px', 'margin-top': '5px' }"></ngx-skeleton-loader>
      </div>
      <ngx-skeleton-loader count="1" [theme]="{ width: '180px', height: '45px' }"></ngx-skeleton-loader>
    </div>
    <!--name and button-->
    <ng-container *ngIf="location">
      <div [ngStyle]="{'color': textColor}" class="d-flex justify-content-between align-items-center {{ reviews ? 'mb--20' : '' }}">
        <div>
          <h2 [ngStyle]="{'color': textColor}" class="txt--black txt--capitalize m--0"><strong> {{location?.locationName}} </strong></h2>
          <gmb-address style="display: inline-block"
            *ngIf="location?.location?.address || location?.location?.serviceArea"
            [address]="location?.location?.address" 
            [serviceAreas]="location?.location?.serviceArea?.places?.placeInfos">
          </gmb-address>
        </div>

        <a [href]="location.location.metadata.newReviewUrl" target="_blank" rel="noopener">
          <button mat-flat-button color="accent" [ngStyle]="{'color': buttonTextColor, 'background-color': buttonColor}" class="btn btn--md">Write a Review</button>
        </a>
      </div>

      <!--skeleton for ratings-->
      <div class="d-flex align-items-center justify-content-start mt--20" *ngIf="!reviews">
        <ngx-skeleton-loader count="1" [theme]="{ width: '90px', height: '65px' }"></ngx-skeleton-loader>
        <div class="d-flex flex-column">
          <ngx-skeleton-loader count="1" [theme]="{ width: '125px', height: '25px', 'display': 'block', 'margin-left': '5px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" [theme]="{ width: '70px', height: '20px', 'display': 'block', 'margin-left': '5px', 'margin-top': '5px' }"></ngx-skeleton-loader>
        </div>
      </div>
      <!--rating-->
      <div [ngStyle]="{'color': textColor}" class="d-flex align-items-center justify-content-start" *ngIf="reviews">
        <p [ngStyle]="{'color': textColor}" class="txt--xxxl txt--lightblue fw--medium m--0">{{average|number:'1.0-1'}}</p>

        <div class="d-flex flex-column">
          <app-starts type="average" [starts]="average"></app-starts>
          <p [ngStyle]="{'color': textColor}" class="txt--lightgray txt--md ml--5 mb--0">{{total}} reviews</p>
        </div>
      </div>
    </ng-container>
  </div>

  <!--reviews-->
  <div class="box__content box--padding-xxs" [ngStyle]="{'color': textColor}">
    <!--skeleton loader for reviews-->
    <ng-container *ngIf="!reviews || reviewsProgress">

      <div class="reviewer" *ngFor="let i of [0,1,2,3,4]">
        <div class="d-flex align-items-start">
          <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '65px', height: '65px' }"></ngx-skeleton-loader>

          <div class="w--100 d-flex flex-column">
            <div class="d-flex justify-content-between">
              <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px', 'margin-left': '5px' }"></ngx-skeleton-loader>
              <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '15px' }"></ngx-skeleton-loader>
            </div>
            <ngx-skeleton-loader count="1" [theme]="{ width: '70px', height: '15px', 'margin-left': '5px' }"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '60px', 'margin-left': '5px' }"></ngx-skeleton-loader>
          </div>
        </div>
        <div class="reviewer__reply">
          <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '40px' }"></ngx-skeleton-loader>
        </div>

      </div>
    </ng-container>
    <!--reviews-->
    <ng-container *ngIf="reviews && !reviewsProgress">
      <ng-container *ngFor="let r of reviews">
        <div class="reviewer" *ngIf="r?.reviewer">

          <div class="d-flex {{ r?.comment ? 'align-items-start' : 'align-items-center' }}">
            <img width="65" height="65" [src]="r?.reviewer?.profilePhotoUrl ? r?.reviewer?.profilePhotoUrl : '/assets/images/unknown-avatar.png'" alt="Reviewer image" class="reviewer__img">

            <div class="w--100 d-flex flex-column">
              <div class="d-flex justify-content-between">
                <h5 [ngStyle]="{'color': textColor}" class="txt--black ml--5"><strong>{{r?.reviewer?.displayName}}</strong></h5>
                <p [ngStyle]="{'color': textColor}" class="txt--gray m--0">{{r?.createTime|dateAgo}}</p>
              </div>
              <app-starts [starts]="r?.starRatingNumber" class="stars--xs"></app-starts>
              <p [ngStyle]="{'color': textColor}" class="reviewer__comment m--0" *ngIf="r?.comment ">
                <read-more [text]="r?.comment" [maxLength]="120" [showToggleButton]="true"></read-more>
              </p>
            </div>
          </div>
          <ng-container *ngIf="r?.reviewReply && responses">
            <div class="reviewer__reply">
              <p [ngStyle]="{'color': textColor}" class="reviewer__comment">{{r?.reviewReply?.comment}}</p>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
