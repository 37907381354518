import { __assign, __values } from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/switchMap';
import { firestore } from 'firebase/app';
import { combineLatest, defer, of } from 'rxjs';
import { flatMap, map, switchMap, tap, filter } from 'rxjs/operators';
import { LOCATIONS /*, LOGGER*/ } from '../constants/firestore/collections';
var FirestoreService = /** @class */ (function () {
    function FirestoreService(db) {
        this.db = db;
        this.previousPage = null;
        // 1:1 Joins
        this.docJoin = function (afs, paths) {
            return function (source) {
                return defer(function () {
                    var parent;
                    var keys = Object.keys(paths);
                    return source.pipe(switchMap(function (data) {
                        // Save the parent data state
                        parent = data;
                        // Map each path to an Observable
                        var docs$ = keys.map(function (k) {
                            var fullPath = paths[k] + "/" + parent[k];
                            return afs.doc(fullPath).valueChanges();
                        });
                        // return combineLatest, it waits for all reads to finish
                        return combineLatest(docs$);
                    }), map(function (arr) {
                        // We now have all the associated douments
                        // Reduce them to a single object based on the parent's keys
                        var joins = keys.reduce(function (acc, cur, idx) {
                            var _a;
                            return __assign(__assign({}, acc), (_a = {}, _a[cur] = arr[idx], _a));
                        }, {});
                        // Return the parent doc with the joined objects
                        return __assign(__assign({}, parent), joins);
                    }));
                });
            };
        };
        // 1:n Join
        this.leftJoin = function (afs, field, collection, limit) {
            if (limit === void 0) { limit = 100; }
            return function (source) {
                return defer(function () {
                    // Operator state
                    var collectionData;
                    // Track total num of joined doc reads
                    var totalJoins = 0;
                    return source.pipe(switchMap(function (data) {
                        var e_1, _a;
                        // Clear mapping on each emitted val ;
                        // Save the parent data state
                        collectionData = data;
                        var reads$ = [];
                        var _loop_1 = function (doc) {
                            // Push doc read to Array
                            if (doc[field]) {
                                // Perform query on join key, with optional limit
                                var q = function (ref) { return ref.where(field, '==', doc[field]).limit(limit); };
                                reads$.push(afs.collection(collection, q).valueChanges());
                            }
                            else {
                                reads$.push(of([]));
                            }
                        };
                        try {
                            for (var collectionData_1 = __values(collectionData), collectionData_1_1 = collectionData_1.next(); !collectionData_1_1.done; collectionData_1_1 = collectionData_1.next()) {
                                var doc = collectionData_1_1.value;
                                _loop_1(doc);
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (collectionData_1_1 && !collectionData_1_1.done && (_a = collectionData_1.return)) _a.call(collectionData_1);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        return combineLatest(reads$);
                    }), map(function (joins) {
                        return collectionData.map(function (v, i) {
                            var _a;
                            totalJoins += joins[i].length;
                            return __assign(__assign({}, v), (_a = {}, _a[collection] = joins[i] || null, _a));
                        });
                    }), tap(function (final) {
                        totalJoins = 0;
                    }));
                });
            };
        };
    }
    Object.defineProperty(FirestoreService.prototype, "timestamp", {
        /// **************
        /// Write Data
        /// **************
        /// Firebase Server Timestamp
        get: function () {
            return firestore.FieldValue.serverTimestamp();
        },
        enumerable: true,
        configurable: true
    });
    /// with Ids
    /// **************
    /// Get a Reference
    /// **************
    FirestoreService.prototype.col = function (ref, queryFn) {
        return typeof ref === 'string' ? this.db.collection(ref, queryFn) : ref;
    };
    FirestoreService.prototype.doc = function (ref) {
        return typeof ref === 'string' ? this.db.doc(ref) : ref;
    };
    /// **************
    /// Get Data
    /// **************
    FirestoreService.prototype.doc$ = function (ref) {
        return this.doc(ref).snapshotChanges().map(function (doc) {
            return doc.payload.data();
        });
    };
    FirestoreService.prototype.col$ = function (ref, queryFn) {
        return this.col(ref, queryFn).snapshotChanges().map(function (docs) {
            return docs.map(function (a) { return a.payload.doc.data(); });
        });
    };
    /// with Ids
    FirestoreService.prototype.colWithIds$ = function (ref, queryFn) {
        return this.col(ref, queryFn).snapshotChanges().map(function (actions) {
            return actions.map(function (a) {
                var data = a.payload.doc.data();
                var id = a.payload.doc.id;
                return __assign({ id: id }, data);
            });
        });
    };
    FirestoreService.prototype.paginateValues = function (ref, order, queryFn, pageable, next, prev, filter, where, type) {
        if (type === void 0) { type = 'asc'; }
        var snapshot = null;
        var countSnapshot = null;
        this.previousPage = pageable;
        if (where) {
            countSnapshot = this.db.collection(ref, function (ref) { return ref.where(where.field, where.operator, where.value); }).snapshotChanges().pipe(map(function (r) { return filter ? r.filter(filter).length : r.length; }));
        }
        else {
            countSnapshot = this.db.collection(ref).snapshotChanges().pipe(map(function (r) { return filter ? r.filter(filter).length : r.length; }));
        }
        if (next) {
            if (where) {
                snapshot = this.db.collection(ref, function (ref) { return ref.orderBy(order, type).where(where.field, where.operator, where.value).startAfter(next[order]).limit(pageable.size); }).valueChanges();
            }
            else {
                snapshot = this.db.collection(ref, function (ref) { return ref.orderBy(order, type).startAfter(next[order]).limit(pageable.size); }).valueChanges();
            }
            return this.formatPaginationValues(snapshot, countSnapshot, pageable);
        }
        if (prev) {
            if (where) {
                snapshot = this.db.collection(ref, function (ref) { return ref.where(where.field, where.operator, where.value).orderBy(order, type).endBefore(prev[order]).limit(pageable.size); }).valueChanges();
            }
            else {
                snapshot = this.db.collection(ref, function (ref) { return ref.orderBy(order, type).endBefore(prev[order]).limitToLast(pageable.size); }).valueChanges();
            }
            return this.formatPaginationValues(snapshot, countSnapshot, pageable);
        }
        if (filter) {
            snapshot = this.db.collection(ref, function (ref) { return ref.orderBy(order, type); }).snapshotChanges().pipe(map(function (r) { return r.filter(filter); }));
            return this.formatPaginationSnapshots(snapshot, countSnapshot, pageable);
        }
        else if (queryFn) {
            snapshot = this.db.collection(ref, queryFn).valueChanges();
        }
        else if (where) {
            snapshot = this.db.collection(ref, function (ref) { return ref.orderBy(order, type).where(where.field, where.operator, where.value).limit(pageable.size); }).valueChanges();
        }
        else {
            snapshot = this.db.collection(ref, function (ref) { return ref.orderBy(order, type).limit(pageable.size); }).valueChanges();
        }
        return this.formatPaginationValues(snapshot, countSnapshot, pageable);
    };
    // TODO: Unused, remove
    // public paginateLocations<T>(ref: string, order: string,
    //                             queryFn?: QueryFn, pageable?: Pageable, values?: boolean, next?,
    //                             prev?, filter?): Observable<Pagination> {
    //   return this.paginateValuesLocations(ref, order, queryFn, pageable, next, prev, filter);
    // }
    FirestoreService.prototype.paginateValuesLocations = function (ref, order, queryFn, pageable, next, prev, filter) {
        this.previousPage = pageable;
        var snapshot = null;
        var countSnapshot = null;
        countSnapshot = this.db.collection(ref).snapshotChanges().pipe(map(function (r) { return filter ? r.filter(filter).length : r.length; }));
        if (next) {
            snapshot = this.db.collection(ref, function (ref) { return ref.orderBy('locationName').orderBy('locationId')
                .startAfter(next.locationName, next.locationId)
                .limit(pageable.size); })
                .valueChanges();
            if (filter) {
                snapshot = snapshot.pipe(map(function (r) { return r.filter(filter); }));
            }
            return this.formatPaginationValues(snapshot, countSnapshot, pageable);
        }
        if (prev) {
            snapshot = this.db.collection(ref, function (ref) { return ref
                .orderBy('locationName').orderBy('locationId').endBefore(prev.locationName, prev.locationId)
                .limitToLast(pageable.size); }).valueChanges();
            if (filter) {
                snapshot = snapshot.pipe(map(function (r) { return r.filter(filter); }));
            }
            return this.formatPaginationValues(snapshot, countSnapshot, pageable);
        }
        if (filter) {
            snapshot = this.db.collection(ref, function (ref) { return ref.orderBy('locationName').orderBy('locationId'); }).valueChanges();
            snapshot = snapshot.pipe(map(function (r) { return r.filter(filter); }));
        }
        else {
            snapshot = this.db.collection(ref, function (ref) { return ref.orderBy('locationName').orderBy('locationId').limit(pageable.size); }).valueChanges();
        }
        return this.formatPaginationValues(snapshot, countSnapshot, pageable);
    };
    FirestoreService.prototype.paginateValuesReports = function (ref, order, direction, gid, report, pageable, next, prev, keywords) {
        this.previousPage = pageable;
        var snapshot = null;
        var countSnapshot = null;
        if (keywords) {
            countSnapshot = this.db.collection(ref, function (refFn) { return refFn
                .where('gid', '==', gid)
                .where('reportType', '==', report)
                .where(firestore.FieldPath.documentId(), 'in', keywords)
                .where('sharedOnly', '==', false); }).snapshotChanges().pipe(map(function (r) { return r.length; }));
        }
        else {
            countSnapshot = this.db.collection(ref, function (refFn) { return refFn
                .where('gid', '==', gid)
                .where('reportType', '==', report)
                .where('sharedOnly', '==', false); }).snapshotChanges().pipe(map(function (r) { return r.length; }));
        }
        if (next) {
            if (keywords) {
                var secOrder_1 = order === 'reportName' ? 'createdAt' : order;
                var secDirection_1 = order === 'reportName' ? 'desc' : direction;
                var repDirection_1 = order === 'reportName' ? direction : 'asc';
                snapshot = this.db.collection(ref, function (ref) { return ref.orderBy('reportName', repDirection_1)
                    .orderBy(secOrder_1, secDirection_1)
                    .where('gid', '==', gid)
                    .where(firestore.FieldPath.documentId(), 'in', keywords)
                    .where('sharedOnly', '==', false)
                    .startAfter(next[order], next.reportName)
                    .limit(pageable.size); })
                    .snapshotChanges();
            }
            else {
                var secOrder_2 = order !== 'reportName' ? 'reportName' : 'createdAt';
                var secDirection_2 = order !== 'reportName' ? 'asc' : 'desc';
                snapshot = this.db.collection(ref, function (ref) { return ref.orderBy(order, direction)
                    .orderBy(secOrder_2, secDirection_2)
                    .where('gid', '==', gid)
                    .where('reportType', '==', report)
                    .where('sharedOnly', '==', false)
                    .startAfter(next[order], next.reportName)
                    .limit(pageable.size); })
                    .snapshotChanges();
            }
            return this.formatPaginationSnapshots(snapshot, countSnapshot, pageable);
        }
        if (prev) {
            if (keywords) {
                var secOrder_3 = order === 'reportName' ? 'createdAt' : order;
                var secDirection_3 = order === 'reportName' ? 'desc' : direction;
                var repDirection_2 = order === 'reportName' ? direction : 'asc';
                snapshot = this.db.collection(ref, function (ref) { return ref.orderBy('reportName', repDirection_2)
                    .orderBy(secOrder_3, secDirection_3)
                    .where('gid', '==', gid)
                    .where('reportType', '==', report)
                    .where(firestore.FieldPath.documentId(), 'in', keywords)
                    .where('sharedOnly', '==', false)
                    .endBefore(prev[order], prev.reportName)
                    .limit(pageable.size); })
                    .snapshotChanges();
            }
            else {
                var secOrder_4 = order !== 'reportName' ? 'reportName' : 'createdAt';
                var secDirection_4 = order !== 'reportName' ? 'asc' : 'desc';
                snapshot = this.db.collection(ref, function (ref) { return ref.orderBy(order, direction)
                    .orderBy(secOrder_4, secDirection_4)
                    .where('gid', '==', gid)
                    .where('reportType', '==', report)
                    .where('sharedOnly', '==', false)
                    .endBefore(prev[order], prev.reportName).limitToLast(pageable.size); }).snapshotChanges();
            }
            return this.formatPaginationSnapshots(snapshot, countSnapshot, pageable);
        }
        if (keywords) {
            var secOrder_5 = order === 'reportName' ? 'createdAt' : order;
            var secDirection_5 = order === 'reportName' ? 'desc' : direction;
            var repDirection_3 = order === 'reportName' ? direction : 'asc';
            snapshot = this.db.collection(ref, function (ref) { return ref.orderBy('reportName', repDirection_3)
                .orderBy(secOrder_5, secDirection_5)
                .where('gid', '==', gid)
                .where('reportType', '==', report)
                .where(firestore.FieldPath.documentId(), 'in', keywords)
                .where('sharedOnly', '==', false)
                .limit(pageable.size); }).snapshotChanges();
        }
        else {
            var secOrder_6 = order !== 'reportName' ? 'reportName' : 'createdAt';
            var secDirection_6 = order !== 'reportName' ? 'asc' : 'desc';
            snapshot = this.db.collection(ref, function (ref) { return ref.orderBy(order, direction)
                .orderBy(secOrder_6, secDirection_6)
                .where('gid', '==', gid)
                .where('reportType', '==', report)
                .where('sharedOnly', '==', false)
                .limit(pageable.size); }).snapshotChanges();
        }
        return this.formatPaginationSnapshots(snapshot, countSnapshot, pageable);
    };
    // TODO: Unused, remove
    //   paginateloggers<T>(ref: string, filter, order, direction, pageable?: Pageable, next?, prev?, values?): Observable<Pagination> {
    //     this.previousPage = pageable;
    //     let snapshot: Observable<any> = null;
    //     let countSnapshot: Observable<any> = null;
    //     var query : Query = null;
    // 
    //     var collection = this.db.collection<T>( ref ).doc(filter.domain).collection(LOGGER, refFn => {
    //       query = refFn;
    // 
    //       /// Order BY 
    //       if( order && direction ) {
    //         query = query.orderBy(order, direction)
    //       }
    //       
    //       // WHERE 
    //       if( filter ) {
    //         if ( filter.domain != null ) {
    //           query = query.where('domain', '==', filter.domain)
    //         }
    //       }
    // 
    //       // Pagination 
    //       if ( next ) {
    //         query = query.startAfter( next[order] )
    //         query = query.limit(pageable.size)
    //       }
    //       if ( prev ) {
    //         query = query.endBefore( prev[order] )
    //         query = query.limitToLast(pageable.size)
    //       }
    //       // Return query build 
    //       return query
    //     })
    //     
    //     // snapshotchanges or valueschange 
    //     if ( values ) {
    //       countSnapshot = collection.valueChanges().pipe(map(r => r.length));
    //       snapshot = collection.valueChanges();
    //       return this.formatPaginationValues(snapshot, countSnapshot, pageable);
    //     }
    //     else {
    //       countSnapshot = collection.snapshotChanges().pipe(map(r => r.length));
    //       snapshot = collection.snapshotChanges();
    //       return this.formatPaginationSnapshots(snapshot, countSnapshot, pageable);
    //     }
    //   }
    FirestoreService.prototype.paginateGrades = function (ref, filter, order, direction, pageable, next, prev, values) {
        var snapshot = null;
        var countSnapshot = null;
        var query = null;
        var collection = this.db.collection(ref).doc(filter.domain).collection(LOCATIONS, function (refFn) {
            query = refFn;
            /** Order BY */
            if (order && direction) {
                query = query.orderBy(order, direction);
            }
            /** WHERE */
            if (filter) {
                if (filter.domain != null) {
                    query = query.where('registrationDomain', '==', filter.domain);
                }
                if (filter.viewed != null) {
                    query = query.where('viewed', '==', filter.viewed);
                }
            }
            /** Pagination */
            if (next) {
                query = query.startAfter(next[order]);
                query = query.limit(pageable.size);
            }
            if (prev) {
                query = query.endBefore(prev[order]);
                query = query.limitToLast(pageable.size);
            }
            /** Return query build */
            return query;
        });
        /** snapshotchanges or valueschange */
        if (values) {
            countSnapshot = collection.valueChanges().pipe(map(function (r) { return r.length; }));
            snapshot = collection.valueChanges();
            return this.formatPaginationValues(snapshot, countSnapshot, pageable);
        }
        else {
            countSnapshot = collection.snapshotChanges().pipe(map(function (r) { return r.length; }));
            snapshot = collection.snapshotChanges();
            return this.formatPaginationSnapshots(snapshot, countSnapshot, pageable);
        }
    };
    FirestoreService.prototype.paginateUsers = function (ref, domain, order, direction, pageable, next, prev) {
        var snapshot = null;
        var countSnapshot = null;
        countSnapshot = this.db.collectionGroup(ref, function (refFn) { return refFn.orderBy(order, direction)
            .where('registrationDomain', 'in', [domain, domain + ':']); }).snapshotChanges().pipe(map(function (r) { return r.length; }));
        if (next) {
            snapshot = this.db.collectionGroup(ref, function (refFn) { return refFn.orderBy(order, direction)
                .where('registrationDomain', 'in', [domain, domain + ':'])
                .startAfter(next[order])
                .limit(pageable.size); })
                .snapshotChanges();
            return this.formatPaginationSnapshots(snapshot, countSnapshot, pageable);
        }
        if (prev) {
            snapshot = this.db.collectionGroup(ref, function (refFn) { return refFn.orderBy(order, direction)
                .where('registrationDomain', 'in', [domain, domain + ':'])
                .endBefore(prev[order]).limitToLast(pageable.size); }).snapshotChanges();
            return this.formatPaginationSnapshots(snapshot, countSnapshot, pageable);
        }
        snapshot = this.db.collectionGroup(ref, function (refFn) { return refFn.orderBy(order, direction)
            .where('registrationDomain', 'in', [domain, domain + ':'])
            .limit(pageable.size); }).snapshotChanges();
        return this.formatPaginationSnapshots(snapshot, countSnapshot, pageable);
    };
    FirestoreService.prototype.paginateSnapshot = function (ref, order, queryFn, pageable, next, prev, filter, where, type) {
        if (type === void 0) { type = 'asc'; }
        var snapshot = null;
        var countSnapshot = null;
        if (where) {
            countSnapshot = this.db.collection(ref, function (ref) { return ref.where(where.field, where.operator, where.value); }).snapshotChanges().pipe(map(function (r) { return filter ? r.filter(filter).length : r.length; }));
        }
        else {
            countSnapshot = this.db.collection(ref).snapshotChanges().pipe(map(function (r) { return filter ? r.filter(filter).length : r.length; }));
        }
        if (next) {
            if (where) {
                snapshot = this.db.collection(ref, function (ref) { return ref.orderBy(order, type).where(where.field, where.operator, where.value).startAfter(next[order]).limit(pageable.size); }).snapshotChanges();
            }
            else {
                snapshot = this.db.collection(ref, function (ref) { return ref.orderBy(order, type).startAfter(next[order]).limit(pageable.size); }).snapshotChanges();
            }
            return this.formatPaginationSnapshots(snapshot, countSnapshot, pageable);
        }
        if (prev) {
            if (where) {
                snapshot = this.db.collection(ref, function (ref) { return ref.where(where.field, where.operator, where.value).orderBy(order, type).endBefore(prev[order]).limit(pageable.size); }).snapshotChanges();
            }
            else {
                snapshot = this.db.collection(ref, function (ref) { return ref.orderBy(order, type).endBefore(prev[order]).limitToLast(pageable.size); }).snapshotChanges();
            }
            return this.formatPaginationSnapshots(snapshot, countSnapshot, pageable);
        }
        if (filter) {
            snapshot = this.db.collection(ref, function (ref) { return ref.orderBy(order, type); }).snapshotChanges().pipe(map(function (r) { return r.filter(filter); }));
        }
        else if (queryFn) {
            snapshot = this.db.collection(ref, queryFn).snapshotChanges();
        }
        else if (where) {
            snapshot = this.db.collection(ref, function (ref) { return ref.orderBy(order, type).where(where.field, where.operator, where.value).limit(pageable.size); }).snapshotChanges();
        }
        else {
            snapshot = this.db.collection(ref, function (ref) { return ref.orderBy(order, type).limit(pageable.size); }).snapshotChanges();
        }
        return this.formatPaginationSnapshots(snapshot, countSnapshot, pageable);
    };
    FirestoreService.prototype.paginate = function (ref, order, queryFn, pageable, values, next, prev, filter, where, type) {
        if (type === void 0) { type = 'asc'; }
        this.previousPage = pageable;
        if (values) {
            return this.paginateValues(ref, order, queryFn, pageable, next, prev, filter, where, type);
        }
        else {
            return this.paginateSnapshot(ref, order, queryFn, pageable, next, prev, filter, where, type);
        }
    };
    FirestoreService.prototype.formatPaginationValues = function (snapshot, ob, pageable) {
        var _this = this;
        return combineLatest(ob, snapshot).pipe(filter(function (d) { return pageable.page == _this.previousPage.page; }), switchMap(function (result) {
            return of(_this.formatPagination(result[0], pageable, result[1]));
        }));
    };
    FirestoreService.prototype.formatPaginationSnapshots = function (snapshot, ob, pageable) {
        var _this = this;
        return combineLatest(ob, snapshot).pipe(filter(function (d) { return pageable.page == _this.previousPage.page; }), map(function (result) {
            var tmp = result[1].map(function (a) {
                var data = a.payload.doc.data();
                var id = a.payload.doc.id;
                return __assign({ id: id }, data);
            });
            return { data: tmp, count: result[0] };
        }), map(function (data) { return _this.formatPagination(data.count, pageable, data.data); }));
    };
    FirestoreService.prototype.formatPagination = function (count, pageable, actions) {
        var pages = Math.ceil(count / pageable.size);
        var hasPrev = true;
        var hasNext = true;
        if (pages === pageable.page && pages > 1) {
            hasNext = false;
            hasPrev = true;
        }
        else if (pages === pageable.page && pages === 1) {
            hasNext = false;
            hasPrev = false;
        }
        else if (pageable.page === 1 && pages !== 0) {
            hasPrev = false;
            hasNext = true;
        }
        else if (pageable.page > 1 && pageable.page < pages) {
            hasPrev = true;
            hasNext = true;
        }
        else {
            hasPrev = false;
            hasNext = false;
        }
        var pagination = {
            items: actions,
            total: count,
            per_page: pageable.size,
            page: pageable.page,
            pages: pages,
            hasPrev: hasPrev,
            hasNext: hasNext,
        };
        return pagination;
    };
    FirestoreService.prototype.paginateValueChanges = function (ref, queryFn, order, next, prev, pageable) {
        var snapshot = null;
        var countSnapshot = null;
        countSnapshot = this.db.collection(ref).snapshotChanges().pipe(map(function (r) { return r.length; }));
        if (next) {
            snapshot = this.db.collection(ref, function (ref) { return ref.orderBy(order).startAfter(next[order]).limit(pageable.size); }).valueChanges();
            return this.formatPagination(snapshot, countSnapshot, pageable);
        }
        if (prev) {
            snapshot = this.db.collection(ref, function (ref) { return ref.orderBy(order).endBefore(prev[order]).limitToLast(pageable.size); }).valueChanges();
            return this.formatPagination(snapshot, countSnapshot, pageable);
        }
        return this.formatPagination(snapshot, countSnapshot, pageable);
    };
    FirestoreService.prototype.sliceItemsPagination = function (pageable, items) {
        if (pageable.page === 1) {
            return items.slice(0, (pageable.page * pageable.size));
        }
        return items.slice(((pageable.page - 1) * pageable.size), (pageable.page * pageable.size) - 1);
    };
    FirestoreService.prototype.docWithIds$ = function (ref) {
        return this.doc(ref).snapshotChanges().map(function (a) {
            var data = a.payload.data();
            var id = a.payload.id;
            return __assign({ id: id }, data);
        });
    };
    FirestoreService.prototype.set = function (ref, data) {
        var timestamp = this.timestamp;
        return this.doc(ref).set(__assign(__assign({}, data), { updatedAt: timestamp, createdAt: timestamp }), { merge: true });
    };
    FirestoreService.prototype.update = function (ref, data) {
        return this.doc(ref).update(__assign(__assign({}, data), { updatedAt: this.timestamp }));
    };
    FirestoreService.prototype.delete = function (ref) {
        return this.doc(ref).delete();
    };
    FirestoreService.prototype.add = function (ref, data) {
        var timestamp = this.timestamp;
        return this.col(ref).add(__assign(__assign({}, data), { updatedAt: timestamp, createdAt: timestamp }));
    };
    FirestoreService.prototype.geopoint = function (lat, lng) {
        return new firestore.GeoPoint(lat, lng);
    };
    /// If doc exists update, otherwise set
    FirestoreService.prototype.upsert = function (ref, data) {
        var _this = this;
        var doc = this.doc(ref).snapshotChanges().take(1).toPromise();
        return doc.then(function (snap) {
            return snap.payload.exists ? _this.update(ref, data) : _this.set(ref, data);
        });
    };
    /// **************
    /// Inspect Data
    /// **************
    FirestoreService.prototype.inspectDoc = function (ref) {
        var tick = new Date().getTime();
        this.doc(ref).snapshotChanges()
            .take(1)
            .do(function (d) {
            var tock = new Date().getTime() - tick;
        })
            .subscribe();
    };
    FirestoreService.prototype.inspectCol = function (ref) {
        var tick = new Date().getTime();
        this.col(ref).snapshotChanges()
            .take(1)
            .do(function (c) {
            var tock = new Date().getTime() - tick;
        })
            .subscribe();
    };
    /// **************
    /// Create and read doc references
    /// **************
    /// create a reference between two documents
    FirestoreService.prototype.connect = function (host, key, doc) {
        var _a;
        return this.doc(host).update((_a = {}, _a[key] = this.doc(doc).ref, _a));
    };
    /// returns a documents references mapped to AngularFirestoreDocument
    FirestoreService.prototype.docWithRefs$ = function (ref) {
        var _this = this;
        return this.doc$(ref).map(function (doc) {
            var e_2, _a;
            try {
                for (var _b = __values(Object.keys(doc)), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var k = _c.value;
                    if (doc[k] instanceof firestore.DocumentReference) {
                        doc[k] = _this.doc(doc[k].path);
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_2) throw e_2.error; }
            }
            return doc;
        });
    };
    /// **************
    /// Atomic batch example
    /// **************
    /// Just an example, you will need to customize this method.
    FirestoreService.prototype.atomic = function () {
        // const batch = firebase.firestore().batch();
        // /// add your operations here
        // const itemDoc = firebase.firestore().doc('items/myCoolItem');
        // const userDoc = firebase.firestore().doc('users/userId');
        // const currentTime = this.timestamp;
        // batch.update(itemDoc, {timestamp: currentTime});
        // batch.update(userDoc, {timestamp: currentTime});
        // /// commit operations
        // return batch.commit();
    };
    // ---------
    FirestoreService.prototype.convertSnapshots = function (snaps) {
        return snaps.map(function (snap) {
            return __assign({ id: snap.payload.doc.id }, snap.payload.doc.data());
        });
    };
    FirestoreService.prototype.getDocumentsWithSubcollection = function (afs, collection, subCollection) {
        var _this = this;
        return afs
            .collection(collection)
            .snapshotChanges()
            .pipe(map(this.convertSnapshots), map(function (documents) {
            return documents.map(function (document) {
                return afs
                    .collection(collection + "/" + document.id + "/" + subCollection)
                    .snapshotChanges()
                    .pipe(map(_this.convertSnapshots), map(function (subdocuments) {
                    var _a;
                    return Object.assign(document, (_a = {}, _a[subCollection] = subdocuments, _a));
                }));
            });
        }), flatMap(function (combined) { return combineLatest(combined); }));
    };
    return FirestoreService;
}());
export { FirestoreService };
