<div class="box box--rounded {{ type !== 'report' ? 'box--shadow' : '' }} bg--white mb--30">
  <div class="box__header" *ngIf="type !== 'report'">
    <ngx-skeleton-loader count="1" [theme]="{ width: '250px', height: '30px' }"></ngx-skeleton-loader>
  </div>
  <div class="box__content {{ type !== 'report' ? 'box--padding-xs' : '' }}">
    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="row {{ columns === 3 ? 'chips-row--lg' : '' }}">
          <ng-container *ngFor="let i of [0,1,2,3,4,5,6,7,8,9,10,11];">
            <div class="col-6 {{ columns == 3 ? 'col-md-4' : 'col-md-6' }} mb--30">
              <div class="p--0 w--100">
                <div class="box box--shadow box--border-sm box--padding-xxxs txt--center">

                  <div class="d-flex justify-content-between chip-title">
                    <ngx-skeleton-loader count="1"  [theme]="{ width: '100px', height: '20px' }"></ngx-skeleton-loader>
                    <ngx-skeleton-loader count="1"  [theme]="{ width: '30px', height: '20px' }"></ngx-skeleton-loader>
                  </div>

                  <div class="d-flex justify-content-center align-items-center">
                    <ngx-skeleton-loader count="1" appearance="circle"  [theme]="{ width: '30px', height: '30px' }"></ngx-skeleton-loader>
                    <ngx-skeleton-loader count="1"  [theme]="{ width: '30px', height: '20px' }"></ngx-skeleton-loader>
                    <ngx-skeleton-loader count="1" appearance="circle"  [theme]="{ width: '30px', height: '30px' }"></ngx-skeleton-loader>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="col-12 col-lg-6">
        <div class="d-flex justify-content-center align-items-center flex-column">
          <ngx-skeleton-loader count="1" [theme]="{ width: '150px', height: '25px' }"></ngx-skeleton-loader>

          <div class="d-flex justify-content-center align-items-center">
            <ngx-skeleton-loader count="1" appearance="circle"  [theme]="{ width: '40px', height: '40px' }"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1"  [theme]="{ width: '40px', height: '30px' }"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" appearance="circle"  [theme]="{ width: '40px', height: '40px' }"></ngx-skeleton-loader>
          </div>

            <div class="mb--10">
              <div class="row align-items-end txt--center mb--20">
                <div class="col-5">
                  <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '15px' }"></ngx-skeleton-loader>
                  <ngx-skeleton-loader count="1" [theme]="{ width: '50px', height: '20px' }"></ngx-skeleton-loader>
                </div>
                <div class="col-2">
                  <ngx-skeleton-loader count="1" [theme]="{ width: '30px', height: '10px' }"></ngx-skeleton-loader>
                </div>

                <div class="col-5">
                  <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '15px' }"></ngx-skeleton-loader>
                  <ngx-skeleton-loader count="1" [theme]="{ width: '50px', height: '20px' }"></ngx-skeleton-loader>
                </div>
              </div>
            </div>

            <ngx-skeleton-loader count="1"  [theme]="{ width: '100px', height: '20px' }"></ngx-skeleton-loader>

            <div class="w--100 mt--10">
              <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '300px' }"></ngx-skeleton-loader>
            </div>

          </div>
      </div>
    </div>
  </div>
</div>
