// dep
import { Injectable } from '@angular/core';
// import { AngularFirestore, } from '@angular/fire/firestore';
import { HttpClient, HttpParams } from '@angular/common/http';

import { FirestoreService } from './firestore.service';
import { Observable } from 'rxjs';
import { isEmpty, map } from 'rxjs/operators';
import * as _ from 'lodash';

// app
// import { GROUPS, PROTOCOLS } from '../constants/firestore/collections';
import { Protocol } from '../constants/firestore/protocol';
import { ReviewsService } from './reviews.service';
// import { AuthService } from './auth.service';
import { environment } from '@environment';
import { ApiResponse, PAGEABLE_DEFAULT, Pagination } from '../constants/api-response';
import { Pageable } from '../constants/pageable';
// import { AccountService } from './account.service';
import { DataPicker } from '../constants/data-picker';
import { LocationProtocolLog } from '../constants/protocol-log';


@Injectable({
  providedIn: 'root'
})
export class ProtocolService {

  constructor(
    private fs: FirestoreService,
    // private afs: AngularFirestore,
    private reviewS: ReviewsService,
    private http: HttpClient,
    // private auth: AuthService,
    // private accountS: AccountService
  ) {
  }

  async verifyProtocol(protocol: Protocol, protocol_id: string = null): Promise<{result: boolean, duplicatedProtocol: Protocol | null}> {
    const exist = await this.http.get<ApiResponse>(
                          `${environment.apiUrl}/v2/protocol/${protocol.gid}/verify_protocol?stars=${protocol.stars}&withComment=${protocol.withComment}&withOutComment=${protocol.withOutComment}`,
                        ).toPromise();
    let duplicatedProtocol = null;

    if (exist?.data?.length == 0) {
      return {result: true, duplicatedProtocol: null}; // it doesn't exist any protocols that match with these condition
    } else {
      let evaluatedProtocols = [];
      let duplicatedProtocol = null;
      const response = [];

      if (protocol_id !== null) { // if we are editing an existing protocol
        evaluatedProtocols = exist.data.filter((el) => el.protocolId !== protocol_id);
      } else {
        evaluatedProtocols = exist.data
      }

      evaluatedProtocols.map((snap_protocol) => {
        for (const account of snap_protocol.accounts) {
          if (protocol.accounts.some(p => p.accountId == account.accountId)) {
            for (const location of account.locations) {
              if (protocol.accounts.some(p => p.locations.some( lp => lp.locationId == location.locationId))) {
                if(protocol.withComment === true) {
                  if (protocol.triggers.keywords.length === 0 && snap_protocol.triggers.keywords.length === 0) {
                    // both protocols have zero keywords
                    duplicatedProtocol = snap_protocol;
                    response.push(false);
                  } else if (protocol.triggers.keywords.some(k =>  snap_protocol.triggers.keywords.some(sk => k === sk))) { 
                    // if both protocols have at least one same keyword
                    duplicatedProtocol = snap_protocol;
                    response.push(false);
                  } 
                } else {
                  duplicatedProtocol = snap_protocol;
                  response.push(false);
                }
              }
            }
          }
        }
        response.push(true);
      });
      // we return the final evaluation, where we check for duplicated protocols

      return {result: response.every(r => r == true), duplicatedProtocol};
    }
  }

  async save(protocol: Protocol, scan = false) {
    protocol.scan = scan;
    const body = {protocol: protocol}
    const response = await this.http.post<ApiResponse>(`${environment.apiUrl}/v2/protocol/${protocol.gid}/add`,
      body,

    ).toPromise();
    const data_protocol = response.data
    return await this.reviewS.protocolValidateReviews(data_protocol.gid, data_protocol.protocolId);
  }

  async update(protocol: Partial<Protocol>, id: string) {
    const body = {protocol: protocol}
    const data_protocol = await this.http.post<ApiResponse>(`${environment.apiUrl}/v2/protocol/update/${id}`,
      body,
    ).toPromise();

    return data_protocol;
  }

  delete_reviews_to_reply(gid: string, protocol_id: string) {
    return this.http.delete<ApiResponse>(`${environment.apiUrl}/v2/reviews/gid/${gid}/protocol/${protocol_id}/reply`).toPromise()
  }

  async check_protocol(gid: string, protocol_id: string) {
    return await this.reviewS.protocolValidateReviews(gid, protocol_id);
  }

  get_all_flex(gid, value: boolean) {
    return this.http.get<ApiResponse>(`${environment.apiUrl}/v2/protocol/${gid}/all_flex?scan=${value}`).toPromise();
  }

  // YAM: MIGRATE!!
  getProtocolsPaginate(count, pageable, actions) {
    return this.fs.formatPagination(count, pageable, actions);
  }

  paginate(gid, pageable: Pageable, next?, prev?, values = false, where?:  {field: string, operator: "==" | "<" | ">" | "<=" | ">=" | "array-contains" | "!=", value: any}) {
    return this.http.get<Pagination>(
        `${environment.apiUrl}/v2/protocol/${gid}/all?page=${pageable.page}&pageSize=${pageable.size}&prev=${prev}&next=${next}&value=${values}&where=${where}`
      );
  }

  delete(docId: any): Promise<any> {
    return this.http.delete<ApiResponse>(`${environment.apiUrl}/v2/protocol/${docId}`).toPromise()
  }

  getLocationNamesProtocolLog(): Observable<LocationProtocolLog[]> {

    return this.http.get<ApiResponse>(`${environment.apiUrl}/v2/reviews/protocol_log/name`,).pipe(
      map(response => response?.data ? [...response.data.map(l => ({...l.location}))] as LocationProtocolLog[] :
                                       []
    ));
  }

  getAccountsProtocols() {
    return this.http.get<ApiResponse>(`${environment.apiUrl}/v2/reviews/search/accounts`)
           .pipe(map(response => response?.data || []));
  }

  getLocationsProtocols(accountId: string) {

    return this.http.get<ApiResponse>(`${environment.apiUrl}/v2/reviews/account/${accountId}/search/locations`)
           .pipe(map(response => response?.data || []));
  }


  getProtocolLog(pageable?: Pageable, filter?: { status: boolean, view: boolean, location: string[], accounts: string[]}): Observable<Pagination> {
    let params = PAGEABLE_DEFAULT;
    if (pageable) {
      params = new HttpParams()
        .set('page', pageable.page.toString())
        .set('size', pageable.size.toString());
    }

    return this.http.post<ApiResponse>(`${environment.apiUrl}/v2/reviews/protocol_log`, {...filter} , {
      params
    }).pipe(map(response => response?.data || []));
  }


  getProtocolReport(locations: any[], pageable?: Pageable): Observable<{paginate: Pagination, remaining: number, protocols: Protocol[]}> {
    let params = PAGEABLE_DEFAULT;
    if (pageable) {
      params = new HttpParams()
        .set('page', pageable.page.toString())
        .set('size', pageable.size.toString());
    }

    const body = {locations: []}

    locations.forEach(l => {
      body.locations.push(`${l.accountId}/${l.locationId}`)
    });

    return this.http.post<{paginate: Pagination, remaining: number, protocols: Protocol[]}>(`${environment.apiUrl}/v2/reviews/protocols-report`, body ,{
      params
    }).pipe(map(response => response || null));
  }

  getProtocolDataCounters(locations: any[]): Observable<{Count: number, _id: string}> {

    const body = {locations: []}

    locations.forEach(l => {
      body.locations.push(`${l.accountId}/${l.locationId}`)
    });

    return this.http.post<ApiResponse>(`${environment.apiUrl}/v2/reviews/data-protocols`, body)
           .pipe(map(response => response?.data || []));
  }

  getProtocolDataChartCounters(locations: any[], dataPicker: DataPicker) {
    const params = new HttpParams()
      .set('startDateTime', dataPicker.range.start)
      .set('endDateTime',   dataPicker.range.end)
      .set('aggregate',     dataPicker.aggregation.toLowerCase());
    const body = {locations: []}

    locations.forEach(l => {
      body.locations.push(`${l.accountId}/${l.locationId}`)
    });

    return this.http.post<any>(`${environment.apiUrl}/v2/reviews/protoco-report-data`, body ,{
      params
    }).pipe(map(response => response.data));
  }


  // TODO: Unused, remove?
  // updateProtocolLog(protocol) {
  //   // TODO: Bug? auth headers used as body
  //   return this.http.put<ApiResponse>(`${environment.apiUrl}/v2/reviews/protocolLog`)
  //          .pipe(map(response => response?.data || []));
  // }

}
