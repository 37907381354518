<div class="row gutters-sm">
  <!--picker-->
  <div class="col-6">
    <div class="input-group">
      <input class="input-group__field input-group__field--sm" placeholder="Select Color" [(colorPicker)]="color" (colorPickerChange)="handleSelected($event)"/>
      <span class="input-color-picker" [style.background]="color"></span>
    </div>
  </div>
  <!--value-->
  <div class="col-3">
    <div class="input-group">
      <input class="input-group__field input-group__field--sm" value="{{color}}"  [(colorPicker)]="color"  (colorPickerChange)="handleSelected($event)" readonly/>
    </div>
  </div>
  <!--reset button-->
  <div class="col-3 alternate-theme">
    <button color="primary" mat-flat-button (click)="handleCleared()" class="btn btn--action btn--full">Clear</button>
  </div>
</div>
