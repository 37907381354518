/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i2 from "@angular/material/button";
import * as i3 from "@angular/cdk/a11y";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "@angular/common";
import * as i6 from "./trial-message.component";
import * as i7 from "@angular/router";
import * as i8 from "../../services/subscription.service";
import * as i9 from "../../services/auth.service";
import * as i10 from "../../services/location.service";
var styles_TrialMessageComponent = [];
var RenderType_TrialMessageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TrialMessageComponent, data: {} });
export { RenderType_TrialMessageComponent as RenderType_TrialMessageComponent };
function View_TrialMessageComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn--white btn--xs ml--10"], ["id", "upgradeTrialLocBtn"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openUpgrade() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatButton_0, i1.RenderType_MatButton)), i0.ɵdid(1, 180224, null, 0, i2.MatButton, [i0.ElementRef, i3.FocusMonitor, [2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵted(-1, 0, [" Upgrade Now "]))], null, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 1).disabled || null); var currVal_1 = (i0.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_TrialMessageComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "trial-message js-trial-message"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "p", [["class", "m--0"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" Only ", " days remaining in trial! "])), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_TrialMessageComponent_2)), i0.ɵdid(5, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var tmp_1_0 = null; var currVal_1 = ((((tmp_1_0 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform(_co.subscription$))) == null) ? null : tmp_1_0.pricingVersion) < 3); _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var tmp_0_0 = null; var currVal_0 = (((tmp_0_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.subscription$))) == null) ? null : tmp_0_0.daysInTrial); _ck(_v, 2, 0, currVal_0); }); }
function View_TrialMessageComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "trial-message js-trial-message"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "p", [["class", "m--0"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" This is an Essential Location! "])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "button", [["class", "btn btn--white btn--xs ml--10"], ["id", "upgradeFreeLocBtn"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openUpgrade() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatButton_0, i1.RenderType_MatButton)), i0.ɵdid(4, 180224, null, 0, i2.MatButton, [i0.ElementRef, i3.FocusMonitor, [2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵted(-1, 0, ["Upgrade Now"]))], null, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 4).disabled || null); var currVal_1 = (i0.ɵnov(_v, 4)._animationMode === "NoopAnimations"); _ck(_v, 3, 0, currVal_0, currVal_1); }); }
export function View_TrialMessageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_TrialMessageComponent_1)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 5, null, View_TrialMessageComponent_3)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var tmp_0_0 = null; var currVal_0 = (!_co.isMember() && ((((tmp_0_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.subscription$))) == null) ? null : tmp_0_0.status) == _co.GROUP_SUBSCRIPTION_TYPE.TRIAL)); _ck(_v, 1, 0, currVal_0); var tmp_1_0 = null; var currVal_1 = ((((!_co.isMember() && ((((tmp_1_0 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform(_co.subscription$))) == null) ? null : tmp_1_0.pricingVersion) < 3)) && ((((tmp_1_0 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 6).transform(_co.subscription$))) == null) ? null : tmp_1_0.status) !== _co.GROUP_SUBSCRIPTION_TYPE.TRIAL)) && (((((tmp_1_0 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 7).transform(_co.location$))) == null) ? null : tmp_1_0.subscriptionType) == _co.LOCATION_SUBSCRIPTION_TYPE.FREE) || ((((tmp_1_0 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 8).transform(_co.location$))) == null) ? null : tmp_1_0.subscriptionType) == _co.LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL))) && _co.isOnLocation); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_TrialMessageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-trial-message", [], null, null, null, View_TrialMessageComponent_0, RenderType_TrialMessageComponent)), i0.ɵdid(1, 114688, null, 0, i6.TrialMessageComponent, [i7.ActivatedRoute, i8.SubscriptionService, i9.AuthService, i10.LocationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TrialMessageComponentNgFactory = i0.ɵccf("app-trial-message", i6.TrialMessageComponent, View_TrialMessageComponent_Host_0, {}, {}, []);
export { TrialMessageComponentNgFactory as TrialMessageComponentNgFactory };
