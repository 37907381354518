import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ObservationService } from '../../services/observation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatDialog, MatDrawer, MatTableDataSource } from '@angular/material';
import { Pageable } from '../../constants/pageable';
import { Pagination } from '../../constants/api-response';
import { forkJoin, from, merge, Observable, of, Subject, throwError } from 'rxjs';
import { FormControl } from '@angular/forms';
import { catchError, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { AccountService } from '../../services/account.service';
import { GROUP_SUBSCRIPTION_TYPE, LOCATION_SUBSCRIPTION_TYPE } from '../../constants/firestore/account-location';
import { ISubscription } from '../../constants/subscription';
import { ModalService } from '../../services/modal.service';
import { LocationService } from '../../services/location.service';
import { SpinnerService } from '../../services/spinner.service';
import { GroupService } from '../../services/group.service';
import { ProfilesService } from '../../services/profiles/profiles.service';
import { AccountContainer, CreateProfileRequest, LocationModel, ProfileModel } from '../../lib/api-requests/create-profile-request';
import { GetProfilesRequest } from '../../lib/api-requests/get-profiles-request';
import { Profile } from '../../lib/api-responses/get-profiles-response';
import { GoogleAccountService } from '../../services/google-account.service';
import { GoogleService } from '../../services/google.service';
import { SnackbarService } from '../../services/snackbar.service';
import { GoogleLocationService } from '../../services/google-location.service';
import { DELETE_DATA, NOTIFICATION_GENERAL, TYPE_LOG_LOCATION } from '../../constants/notifications';
import { Messages, string_message } from '../../constants/messages';
import { NotificationService } from '../../services/notification.service';
import { WhiteLabelService } from '../../services/white-label.service';

@Component({
  selector: 'app-profiles',
  templateUrl: './profiles.component.html',
  styleUrls: ['./profiles.component.scss']
})
export class ProfilesComponent implements OnInit {
  
  @ViewChild('drawer', { static: true }) public drawer: MatDrawer;
  
  public DEFAULT_PAGE_SIZE = 10;
  public loadingTable = true;
  public allChecked = false;
  public displayedColumns = [];
  public locations = new MatTableDataSource([]);
  public paginate: Pageable = {size: this.DEFAULT_PAGE_SIZE, page: 1};
  public pagination: Pagination = {
    items: [],
    per_page: this.paginate.size,
    page: this.paginate.page,
    hasNext: false,
    hasPrev: false,
    pages: 0,
    total: 0
  };
  public sort = {
    sortBy: 'locationName',
    sortOrder: 'desc'
  };
  public filteredOptions: Observable<any>;
  public filterAccountsControl = new FormControl('');
  public addProfileFilterSelected: Observable<any>;
  public addProfileFilterAccountsControl = new FormControl('');
  public accountsOptions = [];
  public accounts;
  public labelAccountsFiltered = 'All';
  public filteredAccounts = [];
  public allLocationCheck = false;
  public subscription$: Observable<ISubscription>;
  public subscription: ISubscription;
  public selectedLocations = [];
  public dialog: MatDialog;
  public user: any;
  public drawerOpened: boolean = false;
  public dl;
  public da;
  public subsManager$: Subject<boolean> = new Subject();
  public googleAccounts: any[] = []
  public profilesOptions: any[] = []
  public accountProfilesOptions: any[] = []
  public addingProfile: boolean = false;
  public profileStatus = {
    'verified': 'verified',
    'pending_edits': 'pending edits',
    'not_verified': 'not verified',
    'verified_locked': 'verified & locked',
  };

  constructor(
    public router: Router,
    public authService: AuthService,
    private obsService: ObservationService,
    private accountService: AccountService,
    private modalService: ModalService,
    private profilesService: ProfilesService,
    private locationService: LocationService,
    private spinnerService: SpinnerService,
    private notificationService: NotificationService,
    private wl: WhiteLabelService,
    private groupService: GroupService,
    private googleAccountService: GoogleAccountService,
    private snackbarService: SnackbarService,
    private googleService: GoogleService,
    private googleLocationService: GoogleLocationService,
    private route: ActivatedRoute,

  ) {}
    
  ngOnInit(): void {
    this.user = this.authService.session;

    this.subscription$ = this.authService.subscription$;
    this.subscription$.subscribe(subscription => this.subscription = subscription);

    this.formatAdminColumns();

    this.loadAccounts();

    this.filteredOptions = this.filterAccountsControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );

    this.addProfileFilterSelected = this.addProfileFilterAccountsControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  formatAdminColumns(): void{
    if (this.authService.isAdmin) {
      this.displayedColumns = ['check', 'company','storeCode', 'status', 'actions', 'subscription'];
    } else {
      this.displayedColumns = ['check', 'company','storeCode', 'status', 'subscription'];
    }
  }

  async loadAccounts(): Promise<void> {
    this.accounts = (await this.accountService.getAccountPaginate(this.authService?.session?.gid, {page: 1, size: 1000}, [])).items
    for(const acc of this.accounts)
      acc.checked = false
    this.accountsOptions = [...this.accounts]
    this.loadProfilesTable(true)
  }

  loadProfilesTable(resetPaginator: boolean = false): void {
    this.loadingTable = true;

    if(resetPaginator){
      this.pagination = {
        items: [],
        per_page: this.DEFAULT_PAGE_SIZE,
        page: 1,
        hasNext: false,
        hasPrev: false,
        pages: 0,
        total: 0
      }
    }

    const accountsForSearch = this.filteredAccounts.length > 0 ? this.filteredAccounts : this.accounts

    const profileRequest: GetProfilesRequest =  {
      gids: [...new Set<string>(accountsForSearch.map(item => item.gid))],
      accountIds: [...new Set<string>(accountsForSearch.map(item => item.accountId))],
      locationIds: [],
      page: this.pagination.page,
      pageSize: this.pagination.per_page,
      sortKey: "locationName",
      reverse: false
    };

    if(accountsForSearch.length > 0){
      this.profilesService.getProfiles(profileRequest)
      .subscribe(
        (result) => {
          this.locations = new MatTableDataSource<Profile>(result.data.items);
          this.pagination.items = result.data.items;
          this.pagination.total = result.data.total;
          this.pagination.hasNext = result.data.hasNext;
          this.pagination.hasPrev = result.data.hasPrev;
          this.pagination.page = result.data.page;
          this.pagination.per_page = result.data.pageSize;
          this.pagination.pages = result.data.totalPages;

          this.loadingTable = false;
        }
      );
    }
    
  }

  onAddAccount(): void {
    this.router.navigate(['profiles']).then(() => this.obsService.sendAddAccount());
  }

  actionSelected(event) {
    console.log(event);
  }
  
  goLocation(location: any): void {
    this.router.navigate(['/account', location.accountId, 'location', location.locationId, 'insights'])
  }
  
  toggleCheckAll(event) {
    if(event.checked){
      this.selectAll();
    } else {
      this.clearAll();
    }
  }
  
  selectLocation(element: any, event): void {
    if(event.checked){
      this.selectedLocations.push(element);
    } else {
      this.selectedLocations = this.selectedLocations.filter((item) => item.locationId !== element.locationId);
    }
  }
  
  selectAll(): void {
    this.locations.data.forEach(element => {
      element.isChecked = true;
      this.selectedLocations.push(element);
    });
  }

  clearAll(): void {
    this.locations.data.forEach(element => {
      element.isChecked = false;
    });
    this.selectedLocations = [];
  }
  
  refresh(profile: any): void {
    this.locationService.refreshAll(profile.accountId, profile.locationId, this.user.gid)
      .finally(() => {
      }
    );
  }
  
  lockSelectedLocations() : void {
    let anyIsAlreadyLocked = false;
    const observables = this.selectedLocations.map(profile =>
      this.locationService.isLocked(profile.locationId)
    );
    
    forkJoin(observables).subscribe(results => {
      for (let i = 0; i < results.length; i++) {
        const res = results[i];
        const profile = this.selectedLocations[i];
        if (res && res.locked) {
          anyIsAlreadyLocked = true;
        }
      }

      if(anyIsAlreadyLocked){
        const dialogRef = this.modalService.openInfoModal(
          "Heads Up",
          "There is identical location in our network is already locked. Try a different selection or contact support."
        );
      } else {
        this.modalService.openConfirmModal(
          'Are you sure you want to lock these locations?',
          'Locking a location will periodically update GBP with the locked data. Any changes you make directly in GBP will be overwritten.',
          res => {
            if (!res) {
              return;
            }
            
            const observables = this.selectedLocations.map(profile =>
              this.locationService.update(
                this.authService.session.gid,
                profile.accountId,
                profile.locationId,
                { lockedOn: new Date() }
              ).pipe(
                switchMap(loc => {
                  return this.googleService.saveLockHistory(
                    profile.accountId,
                    profile.locationId,
                    'locked',
                    'success'
                  );
                })
              )
            );
            
            forkJoin(observables).subscribe(
              () => {
                this.loadProfilesTable();
                this.snackbarService.openSuccess('Locations successfully locked!', 2000);
              },
              err => {
                this.loadProfilesTable();
                this.snackbarService.openError(
                  'There was a problem locking these locations. Please try again later or contact support.',
                  2000
                );
                console.error('Error locking a location', err);
              }
            );
          }, 2);
        }
      });
  }

  lockLocation(profile: any) : void {
    this.locationService.isLocked(profile.locationId)
      .subscribe(res => {
        if(res && res.locked) {
          const dialogRef = this.modalService.openInfoModal(
            "Heads Up",
            "An identical location in our network is already locked. Please contact support.")
        } 

        if(res && !res.locked) {
          // this.progress = true;
        this.modalService.openConfirmModal(
          'Are you sure you want to lock this location?',
          'Locking a location will periodically update GBP with the locked data. Any changes you make directly in GBP will be overwritten.',
          res => {
            if (!res) {
              return;
            }
            this.locationService.update(
                this.authService.session.gid, 
                profile.accountId, 
                profile.locationId, 
                { lockedOn: new Date() }
            ).toPromise().then(loc => {
              this.googleService.saveLockHistory(
                profile.accountId, 
                profile.locationId, 
                'locked', 
                'success'
              ).subscribe(result => {
                  this.loadProfilesTable();
                  this.snackbarService.openSuccess('Location successfully locked!', 2000);
                });
            }, err => {
              this.snackbarService.openError('There was a problem locking this location. Please try again later or contact support.', 2000);
              console.error('Error locking a location', err);
            });
          }, 2);
        }
      })
  }

  unlockLocation(profile: any) {
    this.locationService.update(
      this.authService.session.gid,
      profile.accountId,
      profile.locationId,
      { lockedOn: null })
      .toPromise()
      .then(loc => {
        this.googleService.saveLockHistory(
          profile.accountId,
          profile.locationId,
          'unlock',
          'success')
          .subscribe(result => {
            this.loadProfilesTable();
            this.snackbarService.openSuccess('Location successfully unlocked!', 2000);
          });
    }, err => {
      this.snackbarService.openError('There was a problem unlocking this location. Please try again later or contact support.', 2000);
      console.error('Error unlocking a location', err);
    });
  }

  deleteBulk(): void {
    if (this.selectedLocations.length === 0) {
      return;
    }
  
    this.spinnerService.loading$.next(true);
  
    const deleteObservables = this.selectedLocations.map((location) => {
      return this.deleteLocation(location);
    });
  
    forkJoin(deleteObservables).subscribe(
      () => {
        const completedNotifications$ = this.selectedLocations.map((location) => {
          const meta = this.notificationService.getMetaTypeLog(TYPE_LOG_LOCATION, location);
          const notification = this.notificationService.saveNotification(
            this.authService.session.gid,
            this.wl.baseDomain,
            string_message(Messages.notifications.LOCATION_TOGGLE, [location.locationName, location.address, DELETE_DATA]),
            NOTIFICATION_GENERAL,
            TYPE_LOG_LOCATION,
            meta
          );
          return notification;
        });
  
        forkJoin(completedNotifications$).subscribe(() => {
          this.spinnerService.loading$.next(false);
          this.loadAccounts();
        });
      },
      (err) => {
        this.spinnerService.loading$.next(false);
        console.error('Error deleting locations', err);
      }
    );
  }
  
  deleteLocation(location: any): Observable<any> {
    if (location.address) {
      location.formatAddress = this.locationService.formatAddress(location.address);
    } else {
      location.formatAddress = '';
    }
  
    const deleteReferences$ = of(this.locationService.deleteReferencesToLocation(this.user.gid, location.locationId));
    const deleteLocation$ = from(this.locationService.deleteLocation(this.user.gid, location.locationId, location.accountId));
  
    return deleteReferences$.pipe(
      switchMap(() => deleteLocation$),
      catchError((error) => {
        console.error('Error deleting location', error);
        return throwError(error);
      })
    );
  }

  deleteLocationModal(location: any) : void{
    if (location) {
      this.modalService.openConfirmModal(
        `Are you sure you want to delete ${location.locationName} ?`,
        'This action cannot be reverted.', 
        (res) => {
          if (!res) {
          return;
        }
          this.deleteLocation(location).subscribe(()=>{
            this.spinnerService.loading$.next(false);
            this.loadAccounts();
          });
        }
        , 2);
    } 
  }

  onMatDrawerOpenedChange(event: boolean): void{
    this.drawerOpened = event;
  }

  toggleDrawer(){
    this.drawer.toggle();
    this.drawerOpened = this.drawer.opened;
  }

  addPhotos() {
    this.router.navigate(['local-bulk'])
  }

  goToLocation(location: any): void {
    this.router.navigate(['/account', location.accountId, 'location', location.locationId, 'insights'])
  }

  goToLocationNewTab(location: any): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/account', location.accountId, 'location', location.locationId, 'insights'])
    );

    window.open(url, '_blank');
  }
  
  editLocation(element) {

  }
  
  handleReload($event: Pageable): void {
    this.paginate =  $event;
    this.loadProfilesTable();
  }
  
  filterAccountChanged(menu, clear) {
    if (clear || this.filteredAccounts.length === 0) {
      this.accounts.forEach(acc => acc.checked = false);
      this.labelAccountsFiltered = 'All';
      this.filteredAccounts = []
    }

    this.filterAccountsControl.setValue(null);
    menu.close.next(false);

    const resetPaginator = true;
    this.loadProfilesTable(resetPaginator);
  }

  _filter(value: string) {
    this.accountsOptions = [];
    const filterValue = value?.toLowerCase();
    if(value != ''){
      this.accounts?.forEach(acc => {
        const name = acc.accountName.toLowerCase();
        if (name.includes(filterValue)) {
          this.accountsOptions.push(acc)
        }
      });
    } else {
      this.accountsOptions = [...this.accounts];
    }
  }

  getLocationsFiltered(event, account) {
    this.filteredAccounts= [];
    this.labelAccountsFiltered = null;
    this.accounts.forEach(acc => {
      acc.checked = acc.accountId == account.account ? event.checked : acc.checked;
      if (acc.checked) {
        this.filteredAccounts.push(acc);
        this.labelAccountsFiltered = this.labelAccountsFiltered ? `${this.labelAccountsFiltered} - ${acc.accountName}`: acc.accountName; 
      }
    });
  }

  checkAllAccounts(event){
    this.googleAccounts.forEach(element => {
      element.checked = event.checked;
    });
  }

  checkProfiles(event, locations){
    locations.forEach(element => {
      element.checked = event.checked;
    });
  }

  hasAnySelectedAccount(){
    let result = false;
    this.googleAccounts.forEach(element => {
      if(element.checked){
        result = true;
      }
    });

    return result;
  }

  hasAnySelectedProfile() : boolean{
    let result = false;
    this.googleAccounts.forEach(element => {
      element?.profiles?.forEach(profile => {
        if(profile.checked){
          result = true;
        }
      });
    });
    return result;
  }

  openAccountExpansionPanel(account){
    this.groupService.get_locations(
      this.authService.session.gid, 
      account.accountId
    )
      .subscribe(response => {
        if(response){

          this.googleAccounts?.forEach(option => {
            if(!option.locations){
              option.locations = []
            }
            if(option.locations){
              response.forEach( (r: any) => {
                if(option.accountId === r.accountId ){
                  option.locations.push(r);
                }
              });
            }
          });
        }
    })
  }

  async addNewProfiles(accountId = null) {
    this.da = null;
    this.dl = null;

    this.googleAccountService.onLoadAll
      .pipe(takeUntil(this.subsManager$))
      .subscribe((e: any) => {
        if (e.success !== true) {
          let msg = '';
          switch (e.details.status) {
            case 901:
              msg = 'Oops! There was an error in the application ';
              break;
            case 401:
              msg = 'Google said you are not authorized! - Try again';
              break;
            default:
              msg = 'Some odd error happened. Try again.';
          }
          return this.snackbarService.openError(msg, 4000);
        }

        // Everything looks good...
        // Prevent multiple dialogs from being opened
        !this.da && this.toggleDrawer();
        this.googleAccountService.accounts.subscribe(accounts => {
          accounts.forEach((element: any) => {
            element.checked = false;
          });
          this.googleAccounts = accounts;
        })
      });


    try {
      this.snackbarService.openInfo(`A tab to authenticate with Google will open. If you don't see it, check your pop-up blocker settings`, 2000);
      const data = await this.googleService.authenticate(this.user.gid, this.user.uid, null);
      const oauth = window.open(data, '_blank');
      // This popup ends up being a redirection so we cannot detect the real close event.
      // So we use an interval trick to overcome this.
      const popupTick = setInterval(() => {
        if (oauth?.closed) {
          clearInterval(popupTick);
          this.googleAccountService.loadAll();
        }
      }, 1000);
    } catch (e) {

      // This popup ends up being a redirection so we cannot detect the real close event.
      // So we use an interval trick to overcome this, with an event
      const message = 'There was an error with the GBP Authentication service';
      console.error(message, e);
      return this.snackbarService.openError(message, 4000);
    }
  }

  loadStep2Profiles(): void {
    this.profilesOptions = [];

    this.googleAccounts.forEach(account => {
      if(account.checked){
        this.googleLocationService.loadAll(this.user, account.name);
      }
    });

    this.googleLocationService.locations.subscribe((locations: any) =>{
      let accountName = '';

      if (locations && locations.length > 0 && locations[0] && locations[0].name ) {
        const [firstPart, secondPart] = locations[0].name.split('/');

        if (firstPart && secondPart) {
          accountName = `${firstPart}/${secondPart}`;
        }
      }

      const account = this.googleAccounts.find(_=>_.name === accountName);
      if(account){
        account.profiles = locations;
      } 
    })
  }

  checkedGoogleAccounts() {
    if(this.googleAccounts && this.googleAccounts.length){
      return this.googleAccounts.filter(_=>_.checked)
    }

    return []
  }

  addProfiles(): void{
    this.addingProfile = true;

    const request: CreateProfileRequest = {
      profiles: []
    }

    this.googleAccounts.forEach(account => {

      if(account.checked){
        const accountId = account.name.split('/')[1];
        const profileModel = new ProfileModel();
        const accountContainer = new AccountContainer();
        accountContainer.gid = this.authService.session.gid;
        accountContainer.accountId = accountId;
        accountContainer.accountName = account.accountName;
        accountContainer.account = 
          {
            name: account.name,
            accountName: account.accountName,
            type: account.type,
            role: account.role,
            verificationState: account.verificationState,
            vettedState: account.vettedState,
            accountNumber: account.accountNumber,
            permissionLevel: account.permissionLevel,
          }
        accountContainer.googleAuthEmailAddress = this.authService.session.email;
        accountContainer.gauthStatus = { isValid: true };
        accountContainer.googleAuth = this.authService.session.googleAuth;
        profileModel.account = accountContainer;

        profileModel.locations = [];
        account?.profiles?.forEach(profile => {
          if(profile.checked){
            const locationContainer = new LocationModel();
            locationContainer.accountId = accountId;
            locationContainer.gid = this.authService.session.gid;
            locationContainer.locationId = profile.name.split('/')[3];
            locationContainer.locationName = profile.locationName;
            locationContainer.lockedOn = null;
            locationContainer.subscriptionType = LOCATION_SUBSCRIPTION_TYPE.FREE;
            profileModel.locations.push(locationContainer);
          }
        });

        request.profiles.push(profileModel);
      }
    });

    this.profilesService.createProfile(request)
      .subscribe(response => {
        this.addingProfile = false;
        this.toggleDrawer();
        this.loadAccounts();
    });
  }
}
