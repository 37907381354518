// dep
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, Subscription as subs } from 'rxjs';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';

// app
import { InsightsService } from '../../services/insights.service';
import { DataPicker } from '../../constants/data-picker';
import { ChartService } from '../../services/chart.service';
import { DatesService } from '../../services/dates.service';
import { ReportService } from '../../services/report.service';
import { SelectedDatePicker } from '../date-picker/date-picker.component';
import { REPORT_TYPE } from '../../constants/firestore/report';
import { SnackbarService } from '../../services/snackbar.service';
import { EventMessageService } from "../../services/event-message.service";
import { ModalService } from "../../services/modal.service";
import { SubscriptionService } from "../../services/subscription.service";
import { AuthService } from '../../services/auth.service';
import { ISubscription } from '../../constants/subscription';
import { LocationService } from '../../services/location.service';
import { LoadingService } from '../../services/loading.service';
import { FeaturedDatePickerComponent } from "../../components/featured-datepicker.component";

import {CHART_BLUE_COLOR, CHART_CORAL_COLOR} from '../../constants/chart-color';

@Component({
  selector: 'app-dashboard-insights',
  templateUrl: './dashboard-insights.component.html',
  styleUrls: ['./dashboard-insights.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class DashboardInsightsComponent implements OnInit, OnDestroy {

  @Input('dataPicker') reportDataPicker: DataPicker;
  @Input() reportName: string;
  @Input() shared = false;
  @Input() lockDates = false;
  @Input() checks: boolean;
  @Output() changeDataPicker = new EventEmitter();

  locationId: string[];
  stats: any[] = [];
  labels: string[];
  dataPicker: DataPicker;
  isProgress = true;
  multiChartDataSet: any[] = [];
  isNoData: boolean;
  isReport: boolean;
  selectDate: SelectedDatePicker;
  typeReport: REPORT_TYPE = null;
  colorsViewChart = [
    CHART_BLUE_COLOR,
    CHART_CORAL_COLOR,
  ]

  private insights$: subs;
  popularStats: any;
  private first = true;
  public readonly accountId: string;
  locations: { accountId: string; locationId: string[] }[];
  locationUpgraded: { accountId: string; locationId: any }[];
  isProgressCSV = false
  subscription$: Observable<ISubscription>;
  subscription: ISubscription;
  modalSubscription = new Subject<boolean>();
  public viewModeOptions = [];
  public viewModeChecked = 'legacy';
  public localRoute;

  constructor(
    private insightS: InsightsService,
    private route: ActivatedRoute,
    private router: Router,
    private chartS: ChartService,
    private dateS: DatesService,
    private auth: AuthService,
    private reportS: ReportService,
    private snackS: SnackbarService,
    private em: EventMessageService,
    private modalService: ModalService,
    private subscriptionService: SubscriptionService,
    private locationService: LocationService,
    private loadingService: LoadingService,
  ) {
    this.accountId  = this.route.parent.snapshot.params.accountId
    this.locationId = this.route.parent.snapshot.params.locationId
    this.locations = [{ accountId: this.accountId, locationId: this.locationId }];
    this.subscription$ = this.auth.subscription$;
    this.subscription$.subscribe(subscription => this.subscription = subscription);
    this.localRoute = route;
  }

  ngOnInit() {

    if (this.reportDataPicker) {
      this.typeReport = 'rollup';
      this.isReport = true;
      this.locationId = this.reportDataPicker.locations;
      this.reportDataPicker.aggregation = this.reportS.correctDateAggregate(this.reportDataPicker);
      this.dataPicker = this.reportDataPicker;
      this.selectDate = this.reportS.selectDatesFromDataPicker(this.dataPicker);
      this.locations = this.dataPicker.locations;
      return;
    } else {
      this.typeReport = 'rollup';
      this.selectDate = {
        start: moment('12-31-2022', 'MM-DD-YYYY').subtract(3, 'month'), // month - 3 for future reference
        end:   moment('12-31-2022', 'MM-DD-YYYY')
      };
    }
    this.em.getEmitter().pipe(takeUntil(this.modalSubscription)).subscribe(res => {
      if (res.name != "DATEPICKER SUBSCRIPTION")
        return;

      const dateDismiss = this.subscription.dismissModalDatePicker?.toDate() || new Date()

      if (new Date() >= dateDismiss) {
        this.modalService.openModal(FeaturedDatePickerComponent,
          { 
            accountId: this.accountId,
            locationId: this.locationId
          },
          { onClose : res => {
            if (!res)
              return;
            console.log('DashboardInsightsComponent upgrade suscription');
            this.subscriptionService.flowChangeLocationsPlan(this.subscription, [this.locationUpgraded[0]])
        }});
      }

    });

    // TODO: Here locationId is always a single element array? Note `${[1]}` == "1"
    this.locationService.getRef(this.auth.session.gid, this.accountId, `${this.locationId}`).toPromise().then(ref => this.locationUpgraded = [{ accountId: this.accountId, locationId: ref }]);
  }

  getInsightData(dataPicker: DataPicker, refresh?: boolean): void {
    this.resetData();
    this.insights$ = this.insightS.getInsights(this.locations, dataPicker).take(2).subscribe(
      res => {
        this.popularStats = res.popularTime;
        this.isProgress = false;
        if (res?.labels?.length > 0) {
          this.labels = res.labels;
          this.stats = this.orderingStats(res.stats);
          this.multiChartDataSet = this.handleMultiChart(this.dataPicker);
        } else {
          this.isNoData = true;
        }
        this.loadingService.reportAdvanced(1, 'Insights')
        refresh && this.snackS.openSuccess('Finished refreshing! ', 2000);
      }, () => {
        this.isNoData = true;
        this.isProgress = false;
      }
    );
  }

  async exportCSV() {
    this.isProgressCSV = true;
    await this.reportS.handleExportCsvFire(this.locations, this.dataPicker.range.start, this.dataPicker.range.end, this.dataPicker.aggregation.toLowerCase(), this.reportName);
    this.isProgressCSV = false;
  }

  handleExport($event) {
    if ($event === 'csv') {
      this.exportCSV();
    }
  }

  handleDataPicker($event: DataPicker) {
    // Init get data
    if (this.isReport && this.first) {
      this.first = false;
      this.getInsightData(this.dataPicker);
      return;
    }
    if (!this.dataPicker) {
      this.dataPicker = { ...$event };
      this.getInsightData(this.dataPicker);
      return;
    }

    // when multiChart Change
    this.dataPicker.multiChart = $event.multiChart;
    this.multiChartDataSet = this.handleMultiChart(this.dataPicker);

    // ranges or aggregation changes
    if (this.dataPicker.range !== $event.range || this.dataPicker.aggregation !== $event.aggregation) {

      if (this.dataPicker.aggregation === 'month') {
        const endMonth = this.dateS.stringToDate(this.dataPicker.range.end).getMonth();
        const startMonth = this.dateS.stringToDate(this.dataPicker.range.start).getMonth();
        if (startMonth === endMonth) {
          this.dataPicker.aggregation = 'day';
        }
      }

      this.dataPicker = { ...$event };
      this.getInsightData(this.dataPicker);

      // Calendar change event here
    }

    this.dataPicker.locations = this.locations;
    this.changeDataPicker.emit(this.dataPicker);
  }

  private handleMultiChart(dataPicker: DataPicker) {
    return this.chartS.multiCharset(this.stats, dataPicker);
  }

  private resetData() {
    this.labels = [];
    this.stats = [];
    this.isProgress = true;
  }

  clearChecked(event) {
    this.multiChartDataSet = [];
    this.checks = event;
  }

  statusChecks(event) {
    this.checks = event;
  }

  ngOnDestroy(): void {
    this.insights$?.unsubscribe();
    this.modalSubscription.next(true);
    this.modalSubscription.unsubscribe();
  }

  handleRefresh(): void {
    this.getInsightData(this.dataPicker, true);
  }

  orderingStats(stats) {
    const orderedData = [];
    const order = [
      "Actions on your Google Business Profile",
      "Search impressions",
      "total views on search",
      "Google Posts Activity",
      "Photo views",
      "Photo quantity"
    ];
    order.forEach(el => {
      const item = stats.find(s => s?.title == el);
      if (item) {
        orderedData.push(item);
      }
    });
    return orderedData;
  }

  hasNoDate() {
    const createdData = this.locationUpgraded ? moment(this.locationUpgraded[0]?.locationId?.createdAt?.$date) : null;
    const hasMoreThan24hs =  createdData ? this.insightS.hasMoreThan24hs(createdData) : true;

    return hasMoreThan24hs ? 'No insights data' : 'The performance insights for this business profile are still being imported, please refresh and check again in 10 minutes';
  }

  // changedViewMode(value) {
  //   this.router.navigateByUrl(`/account/${this.accountId}/location/${this.locationId}/insights`);
  // }
}



