<div class="row box--height" *ngFor="let i of [0,1,2,3,4]">
  <div class="col-12 mb--30">
    <div class="box box--rounded box--shadow bg--white">
      <div class="box__header d-flex align-items-center justify-content-start">
        <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '20px', height: '20px', 'margin-right': '20px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" [theme]="{ width: '300px', height: '20px' }"></ngx-skeleton-loader>
      </div>
      <div class="box__content box--padding-xxs">
        <div class="row">
          <div class="col-12 mb--20">
            <ngx-skeleton-loader count="1" [theme]="{ width: '300px', height: '20px' }"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '300px', 'margin-top': '10px' }"></ngx-skeleton-loader>
          </div>
          <div class="col-md-5">
            <div class="d-flex justify-content-center flex-column align-items-center">
              <ngx-skeleton-loader count="1" [theme]="{ width: '370px', height: '30px' }"></ngx-skeleton-loader>
              <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '150px', height: '150px', 'margin-top': '10px'}"></ngx-skeleton-loader>
              <ngx-skeleton-loader count="1" [theme]="{ width: '50px', height: '50px', 'margin-top': '10px'}"></ngx-skeleton-loader>
              <ngx-skeleton-loader count="1" [theme]="{ width: '40px', height: '20px', 'margin-top': '10px'}"></ngx-skeleton-loader>
            </div>
          </div>
          <div class="col-md-7">
            <div class="d-flex flex-column mb--15" *ngFor="let i of [0,1,2]">
              <ngx-skeleton-loader count="1" [theme]="{ width: '200px', height: '20px'}"></ngx-skeleton-loader>
              <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '20px', 'margin-top': '5px' }"></ngx-skeleton-loader>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
