import { OnInit } from '@angular/core';
var StartsComponent = /** @class */ (function () {
    function StartsComponent() {
        this.showLabel = false;
    }
    StartsComponent.prototype.ngOnInit = function () {
        this.showEmpty = this.showEmpty !== undefined ? this.showEmpty : true;
        if (this.starts) {
            this.numberOfStars = Array.apply(null, Array(Math.floor(this.starts)).map(function (x, i) { return i + 1; }));
        }
    };
    StartsComponent.prototype.getLabel = function () {
        var _a, _b;
        return ((_a = this.numberOfStars) === null || _a === void 0 ? void 0 : _a.length) + "-" + (((_b = this.numberOfStars) === null || _b === void 0 ? void 0 : _b.length) == 1 ? 'Star' : 'Stars');
    };
    return StartsComponent;
}());
export { StartsComponent };
