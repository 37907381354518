// dep
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';

// app
import { SubscriptionService } from '../../services/subscription.service';
import { GROUP_SUBSCRIPTION_TYPE, LOCATION_SUBSCRIPTION_TYPE } from '../../constants/firestore/account-location';
import User from '../../constants/firestore/user';
import { ISubscription } from '../../constants/subscription';
import SavedLocation from 'src/app/constants/firestore/saved-location';
import { LocationService } from '../../services/location.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-trial-message',
  templateUrl: './trial-message.component.html'
})
export class TrialMessageComponent implements OnInit {

  user: User;
  location: SavedLocation
  locationId: string;
  accountId: string;
  location$ : Observable<SavedLocation>
  /** Upgrade a single location OR upgrade the subscription (finish Trial phase) */
  isOnLocation: boolean;
  // isOnMasterAdmin = false; // TODO: Unused, remove.
  public LOCATION_SUBSCRIPTION_TYPE = LOCATION_SUBSCRIPTION_TYPE;
  public GROUP_SUBSCRIPTION_TYPE = GROUP_SUBSCRIPTION_TYPE;

  subscription$ = this._authService.subscription$;
  subscription: ISubscription;

  constructor(
    private _route: ActivatedRoute,
    private _subscriptionService: SubscriptionService,
    private _authService: AuthService,
    private _locationService: LocationService,
  ) {

  }

  ngOnInit() {
    this.subscription$ = this._authService.subscription$;
    this.subscription$.subscribe(subscription => {
      this.subscription = subscription;
      this._getLocation()
    });
  }

  private _getLocation() {
    this._route.params.subscribe(({ accountId, locationId }) => {
      this.locationId = locationId;
      this.accountId = accountId;
      if (locationId && accountId) {
        this.location$ = this._locationService.getRef(this._authService.session.gid, accountId, locationId).pipe(share(), map(res => {
          return res;
        }))

        this.isOnLocation = true
      }
    });
  }

  isMember() : boolean {
    return this._authService.isMember
  }

  async openUpgrade() : Promise<void> {
    console.log('TrialMessageComponent upgrade suscription');

    if (this.isOnLocation) {
      this.location = await this._locationService.getRef(this._authService.session.gid, this.accountId, this.locationId).toPromise()
      if(await this._subscriptionService.flowChangeLocationsPlan(this.subscription, [{ locationId: this.location.locationId , 
                                                                                       accountId: this.accountId }]))
        this.ngOnInit()
    } else if(await this._subscriptionService.stopTrial(this.subscription)) {
      await this._authService.processSubscription()
      // Locations are changed from FREE to ESSENTIAL
      this._locationService.someLocationChanged.next(null)
      this.ngOnInit()
    }
    
  }
}
