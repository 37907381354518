import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-protocol-log',
  templateUrl: './skeleton-protocol-log.component.html'
})
export class SkeletonProtocolLogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
