/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./read-more.component.ngfactory";
import * as i3 from "./read-more.component";
import * as i4 from "./address.component";
import * as i5 from "../services/location.service";
var styles_AddressComponent = [".service-area-label[_ngcontent-%COMP%] {\n      width: 25%;\n      white-space: nowrap;\n      overflow: hidden;\n      text-overflow: ellipsis;\n    }"];
var RenderType_AddressComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_AddressComponent, data: {} });
export { RenderType_AddressComponent as RenderType_AddressComponent };
function View_AddressComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "p", [], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵted(3, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isGray ? " txt--gray mb--30" : "m--0"); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.formattedAddress; _ck(_v, 3, 0, currVal_1); }); }
function View_AddressComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "p", [["class", "m--0 w--300-px txt-overflow-ellipsis"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵted(3, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "m--0 w--300-px txt-overflow-ellipsis"; var currVal_1 = (_co.isGray ? " txt--gray mb--30" : "m--0"); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.formattedServiceAreas; _ck(_v, 3, 0, currVal_2); }); }
function View_AddressComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "read-more", [], null, null, null, i2.View_ReadMoreComponent_0, i2.RenderType_ReadMoreComponent)), i0.ɵdid(1, 573440, null, 0, i3.ReadMoreComponent, [], { text: [0, "text"], maxLength: [1, "maxLength"], showToggleButton: [2, "showToggleButton"], isWhite: [3, "isWhite"], txtLink: [4, "txtLink"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formattedServiceAreas; var currVal_1 = 50; var currVal_2 = true; var currVal_3 = true; var currVal_4 = "View"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_AddressComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AddressComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AddressComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AddressComponent_3)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasAddress(); _ck(_v, 1, 0, currVal_0); var currVal_1 = (!_co.hasAddress() && !_co.hasInfoModal); _ck(_v, 3, 0, currVal_1); var currVal_2 = (!_co.hasAddress() && _co.hasInfoModal); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_AddressComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "gmb-address", [], null, null, null, View_AddressComponent_0, RenderType_AddressComponent)), i0.ɵdid(1, 638976, null, 0, i4.AddressComponent, [i5.LocationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddressComponentNgFactory = i0.ɵccf("gmb-address", i4.AddressComponent, View_AddressComponent_Host_0, { address: "address", isGray: "isGray", hasInfoModal: "hasInfoModal", serviceAreas: "serviceAreas" }, {}, []);
export { AddressComponentNgFactory as AddressComponentNgFactory };
var styles_AddressOuterComponent = [];
var RenderType_AddressOuterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AddressOuterComponent, data: {} });
export { RenderType_AddressOuterComponent as RenderType_AddressOuterComponent };
export function View_AddressOuterComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [], [[8, "outerHTML", 1]], null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.isGray ? " txt--gray mb--30" : "m--0"); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formattedAddress; _ck(_v, 0, 0, currVal_0); }); }
export function View_AddressOuterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "gmb-address-outer", [], null, null, null, View_AddressOuterComponent_0, RenderType_AddressOuterComponent)), i0.ɵdid(1, 638976, null, 0, i4.AddressOuterComponent, [i5.LocationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddressOuterComponentNgFactory = i0.ɵccf("gmb-address-outer", i4.AddressOuterComponent, View_AddressOuterComponent_Host_0, { address: "address", isGray: "isGray" }, {}, []);
export { AddressOuterComponentNgFactory as AddressOuterComponentNgFactory };
