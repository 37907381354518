import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-skeleton-location-info-box',
  templateUrl: './skeleton-location-info-box.component.html'
})
export class SkeletonLocationInfoBoxComponent implements OnInit {

  @Input() type: string;

  constructor() { }

  ngOnInit() {
  }

}
