import { Component, OnInit, Input } from '@angular/core';
import { DataPicker } from '../../constants/data-picker';
import { Subject, Subscription } from 'rxjs';
import { ChartDataSets, ChartPoint } from 'chart.js';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ProtocolService } from '../../services/protocol.service';
import { SelectedDatePicker } from '../date-picker/date-picker.component';
import { DEFAULT_CHART_DATE_SELECT } from '../../constants/datePicker';
import { LoadingService } from '../../services/loading.service';
import { ReportService } from 'src/app/services/report.service';

@Component({
  selector: 'app-reviews-number-protocols',
  templateUrl: './reviews-number-protocols.component.html',
  styleUrls: ['./reviews-number-protocols.component.scss']
})
export class ReviewsNumberProtocolsComponent implements OnInit {

  @Input() locations;
  @Input() dataPicker: DataPicker;
  @Input() refreshFilter;
  selectedDate: SelectedDatePicker = DEFAULT_CHART_DATE_SELECT;
  aggregationNumber$: Subscription;
  isProgressNumber = true;
  datasetBarNumber: ChartDataSets[];
  labelsBarRating: any;
  total: any;

  destroySubs$: Subject<boolean> = new Subject();

  constructor(
    private protocolS: ProtocolService,
    private loadingService: LoadingService,
    private reportS: ReportService
  ) { }

  ngOnInit() {
    const range = {
      start: moment().subtract(3, 'month').day(1).utc(false).format('YYYY-MM-DD 00:00:00'),
      end: moment().utc(false).format('YYYY-MM-DD 23:59:59')
    };
    this.dataPicker.range = {start: range.start, end: range.end};
    this.dataPicker.aggregation = 'Month';
    this.getData()
  }

  getData() {
    this.aggregationNumber$ = this.protocolS.getProtocolDataChartCounters(this.locations, this.dataPicker)
    .pipe(
      takeUntil(this.destroySubs$)
    )
    .subscribe(result => {
      // Remove extra days
      if (this.dataPicker.aggregation === 'Day') {
        const start = moment(this.dataPicker.range.start);
        const end = moment(this.dataPicker.range.end);
        const days = end.diff(start, 'days') + 1;
        const dropValues = result.labels.length - days;
        result['data'] = _.dropRight(result.data, dropValues);
        result['labels'] = _.dropRight(result.labels, dropValues);
      }
      this.isProgressNumber = false;
      this.datasetBarNumber = [{data: result.data, label: 'Number'}];
      this.labelsBarRating = result.labels;
      this.total = this.getTotal(this.datasetBarNumber[0].data);
      this.loadingService.reportAdvanced(1, "Protocol Charts")
    });
  }

  datesUpdated($event) {
    
    this.dataPicker.aggregation = this.reportS.correctDateAggregate($event);
    this.getData();
  }

  changeText(aggregation: string) {
    this.dataPicker.aggregation = aggregation;
    this.getData();
  }

  /**
   * this method get total sum of a array
   */
  getTotal(array: number[] | ChartPoint[]) {
    let total = 0;
    array.forEach(x => {
      total += x;
    });
    return total;
  }

}
