import { __assign } from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { ReviewsService } from '../../services/reviews.service';
import { MatTableDataSource } from '@angular/material/table';
import { ProtocolService } from '../../services/protocol.service';
import { SnackbarService } from '../../services/snackbar.service';
import { ModalService } from '../../services/modal.service';
import { ModalReplyReviewComponent } from '../reply-edit/reply-edit.component';
import { map, take } from 'rxjs/operators';
import { Subject, of } from 'rxjs';
import { AutoReplyReviewErrorComponent } from '../../components/auto-reply-review-error.component';
import { FormControl } from '@angular/forms';
import { LoadingService } from '../../services/loading.service';
var ProtocolLogComponent = /** @class */ (function () {
    function ProtocolLogComponent(reviewS, protocolS, snackS, modalService, loadingService) {
        var _this = this;
        this.reviewS = reviewS;
        this.protocolS = protocolS;
        this.snackS = snackS;
        this.modalService = modalService;
        this.loadingService = loadingService;
        this.locations = [];
        this.shared = false;
        this.paginate = { page: 1, size: 10 };
        this.columns = ['date', 'name', 'message', 'status', 'action-btn'];
        this.progress = true;
        this.selectedStatus = null;
        this.selectedView = null;
        this.searchText = '';
        this.errorMessage = false;
        this.showMoreReply = false;
        this.showMoreMessage = false;
        this.filter = { status: null, view: null, location: [], accounts: [] };
        this.dataSource = new MatTableDataSource([]);
        this.dataSourceNew = new MatTableDataSource([]);
        this.resultLocationName = of([]);
        this.copyResultLocationName = of([]);
        this.selectedSearch = new FormControl();
        this.locationName = '';
        this.destoySubs$ = new Subject();
        this.isClear = true;
        this.data = function (pageable) {
            if (pageable === void 0) { pageable = undefined; }
            return _this.protocolS.getProtocolLog(pageable);
        };
    }
    Object.defineProperty(ProtocolLogComponent.prototype, "isFiltered", {
        get: function () {
            var _a, _b, _c, _d, _e, _f;
            return ((_b = (_a = this.filter) === null || _a === void 0 ? void 0 : _a.accounts) === null || _b === void 0 ? void 0 : _b.length) > 0 && ((_d = (_c = this.filter) === null || _c === void 0 ? void 0 : _c.location) === null || _d === void 0 ? void 0 : _d.length) > 0 && ((_e = this.filter) === null || _e === void 0 ? void 0 : _e.status) && ((_f = this.filter) === null || _f === void 0 ? void 0 : _f.view);
        },
        enumerable: true,
        configurable: true
    });
    ProtocolLogComponent.prototype.ngOnInit = function () {
        var _this = this;
        var _a;
        this.progress = true;
        if (this.shared) {
            this.columns = ['date', 'name', 'message', 'status'];
        }
        if (((_a = this.locations) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            this.locations.forEach(function (l) {
                _this.filter.location.push(l.locationId);
                _this.filter.accounts.push(l.accountId);
            });
            this.getData(this.filter);
        }
        else {
            this.getData(this.filter);
            this.resultLocationName = this.copyResultLocationName = this.protocolS.getLocationNamesProtocolLog();
        }
        this.predicate = this.dataSourceNew.filterPredicate = function (data, filter) {
            return data.viewed.toString() === filter;
        };
    };
    ProtocolLogComponent.prototype.getData = function (filter) {
        var _this = this;
        if (filter === void 0) { filter = null; }
        this.progress = true;
        this.protocolS.getProtocolLog(this.paginate, filter).pipe(take(1)).subscribe(function (result) {
            _this.previousPageable = { size: result.per_page, page: result.page };
            _this.pagination = result;
            _this.dataSource.data = result.items;
            _this.progress = false;
            _this.isClear = false;
            _this.loadingService.reportAdvanced(1, "Logs");
        });
    };
    ProtocolLogComponent.prototype.changeViewed = function (element) {
        var _this = this;
        var _a;
        if (!element.reviewId) {
            return;
        }
        if (!((_a = element) === null || _a === void 0 ? void 0 : _a.viewed)) {
            element.viewed = false;
        }
        this.reviewS.changeViewed(element.reviewId, !element.viewed).pipe(take(1)).subscribe(function (result) {
            element.viewed = result.viewed;
            _this.getData(_this.filter);
            _this.handleView(_this.view);
        }, function (error) {
            _this.snackS.openError('error to change viewed', 4000);
        });
    };
    ProtocolLogComponent.prototype.handleView = function ($event) {
        this.view = $event;
        switch ($event) {
            case 'read':
                this.dataSourceNew.filter = 'true';
                break;
            case 'unread':
                this.dataSourceNew.filter = 'false';
                break;
            default:
                this.dataSourceNew.filter = undefined;
                break;
        }
    };
    ProtocolLogComponent.prototype.handleChangeSearch = function (event) {
        var _a;
        this.resultLocationName = this.copyResultLocationName;
        if (event !== '' && event !== null && event[((_a = event) === null || _a === void 0 ? void 0 : _a.length) - 1] !== ' ') {
            var textSearch_1 = event.trim().toLowerCase();
            this.resultLocationName = this.resultLocationName.pipe(map(function (al) { return al.filter(function (l) { return l.locationName.trim().toLowerCase().indexOf(textSearch_1) > -1 ||
                l.locationAddress.locality.trim().toLowerCase().indexOf(textSearch_1) > -1 ||
                l.locationAddress.administrativeArea.trim().toLowerCase().indexOf(textSearch_1) > -1 ||
                l.locationAddress.postalCode.trim().toLowerCase().indexOf(textSearch_1) > -1 ||
                l.locationAddress.regionCode.trim().toLowerCase().indexOf(textSearch_1) > -1 ||
                l.locationAddress.addressLines.some(function (add) { return add.trim().toLowerCase().indexOf(textSearch_1) > -1; }); }); }));
        }
    };
    ProtocolLogComponent.prototype.selected = function () {
        var value = this.selectedSearch.value;
        this.selectedSearch.setValue(value.locationName);
        this.locationName = value.locationName;
        this.filter.location.push(value.locationId);
        this.filter.accounts.push(value.accountId);
        this.paginate = { page: 1, size: 10 };
        this.getData(this.filter);
    };
    ProtocolLogComponent.prototype.toggleMessage = function (i) {
        this.showMoreMessage = !this.showMoreMessage;
        this.showMoreMessageIndex = i;
    };
    ProtocolLogComponent.prototype.toggleReply = function (i) {
        this.showMoreReply = !this.showMoreReply;
        this.showMoreReplyIndex = i;
    };
    ProtocolLogComponent.prototype.edit = function (element) {
        var _this = this;
        // todo use real messages
        this.modalService.openGenericModal(ModalReplyReviewComponent, element, function (result) {
            _this.changeViewed(element);
        });
    };
    ProtocolLogComponent.prototype.delete = function (element) {
        var _this = this;
        this.modalService.openConfirmModal('Delete Reply', '', function () {
            var accountId = element.name.split('/')[1];
            var locationId = element.name.split('/')[3];
            _this.reviewS.replyDelete(accountId, locationId, element.reviewId)
                .pipe(take(1))
                .subscribe(function () {
                _this.getData(_this.filter);
            });
        });
    };
    ProtocolLogComponent.prototype.handleReload = function ($event) {
        this.paginate = $event;
        this.getData(this.filter);
    };
    ProtocolLogComponent.prototype.openError = function (element) {
        var _a;
        if (((_a = element) === null || _a === void 0 ? void 0 : _a.error) && !this.shared) {
            var dialog = this.modalService.openGenericModal(AutoReplyReviewErrorComponent, __assign({}, element), function () { });
        }
    };
    ProtocolLogComponent.prototype.ngOnDestroy = function () {
        this.destoySubs$.next(true);
        this.destoySubs$.complete();
    };
    ProtocolLogComponent.prototype.locationUrl = function (log, destiny) {
        var url = log.name.split('/');
        var accountId = url[1];
        var locationId = url[3];
        return "/account/" + accountId + "/location/" + locationId + "/" + destiny;
    };
    ProtocolLogComponent.prototype.applyFilterStatus = function ($event) {
        this.filter.status = $event.value;
        this.getData(this.filter);
    };
    ProtocolLogComponent.prototype.clearFilters = function () {
        var _a;
        this.isClear = true;
        this.selectedStatus = null;
        this.filter.status = null;
        if (((_a = this.locations) === null || _a === void 0 ? void 0 : _a.length) == 0) {
            this.filter.location = [];
            this.filter.accounts = [];
            this.resultLocationName = this.copyResultLocationName;
        }
        this.locationName = '';
        this.getData(this.filter);
    };
    ProtocolLogComponent.prototype.applyFilterView = function ($event) {
        this.filter.view = $event.value;
        this.getData(this.filter);
    };
    /**
    * this method handle change text search keyword comment or reviewer
    * @param searchText
    */
    ProtocolLogComponent.prototype.handleSearchText = function (searchText) {
        this.searchText = searchText;
        //this.getData();
    };
    return ProtocolLogComponent;
}());
export { ProtocolLogComponent };
