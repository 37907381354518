

  <div class="dialog__header dialog__header--sm dialog__header--white dialog__header--border">
    <h4 mat-dialog-title class="txt--center m--0"><strong>{{title}}</strong></h4>
  </div>
  <div class="dialog__content" mat-dialog-content>

    <div class="row dialog-row pb--0">
      <ng-container>
        <form [formGroup]="form" (ngSubmit)="outSchedule()" style="width: 100%;">
          <div class="col-12">
            <mat-label class="d-block mb--10">Date</mat-label>
            <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
              <input (click)="myDatepicker.open()" class="input-group__field input--calendar input-icon--right" matInput [matDatepicker]="myDatepicker" placeholder="Date" [formControl]="date">
              <mat-datepicker #myDatepicker></mat-datepicker>
              <mat-error *ngIf="date.errors">
                <p>Date is required</p>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-label class="d-block mb--10">Time</mat-label>
            <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
          <input class="input-group__field input--clock input-icon--right" matInput type="time" [formControl]="time">
          <div class="txt--lightgray txt--sm ml--5 mt--5 d-flex align-items-start">
          <p class="mb--5 txt--xs">
            <span class="d-block">Scheduling time is according to your own timezone.</span>
            </p>
              </div>

              <mat-error *ngIf="time.errors">
                <p *ngIf="time.hasError('required')">Time is required</p>
              </mat-error>

            </mat-form-field>
          </div>
          <mat-error  class="col-12" *ngIf="lessToNow">
            <p>Schedule date/time must be in the future.</p>
          </mat-error>
        </form>
      </ng-container>
      



    </div>
  </div>
  <div mat-dialog-actions class="dialog__footer">
    <span class="alternate-theme d-inline-block mr--10">
      <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
    </span>
    <button type="button" (click)="cancelDelete()" style="margin-left: auto;" mat-flat-button tabIndex="-1" color="warn" class="btn btn--action" *ngIf="isDeletePost && schedule" >Cancel Delete</button>
    <button (click)="outSchedule()" *ngIf="!recurringPost" mat-flat-button tabIndex="-1" color="accent" class="btn btn--action" >Save</button>
    <button (click)="outSchedule()" *ngIf="recurringPost" mat-flat-button tabIndex="-1" color="accent" class="btn btn--action" >Save Recurring</button>
  </div>

