<div class="dialog__header modal-share-header">
  <h2 mat-dialog-title class="m--0 txt--xl"><strong>Share Link</strong></h2>
  <button mat-icon-button mat-dialog-close class="txt--lg"><i class="fal fa-times-circle"></i></button>
</div>
<div class="dialog__content" mat-dialog-content>
  <mat-progress-bar mode="indeterminate" *ngIf="progress"></mat-progress-bar>
  <ng-container *ngIf="!successSend">
    <mat-checkbox
            class="d-flex align-items-center checkbox--star checkbox--star-md"
            value="1"
            [(ngModel)]="isToShare"
    >Share via email?</mat-checkbox>
    <mat-form-field *ngIf="isToShare" floatLabel="never" class="input-group input-group--override input-group--override-label">
      <mat-label>Email</mat-label>
      <input class="input-group__field" matInput [formControl]="email">
      <mat-error *ngIf="email.errors">
        <span *ngIf="email.hasError('required')">This value is required</span>
        <span *ngIf="email.hasError('email')">Bad email format</span>
      </mat-error>
    </mat-form-field>
  </ng-container>
  <div style="padding-bottom: 1.25em" *ngIf="!url">
    <ngx-skeleton-loader count="1"  [theme]="{ width: '100%', height: '48.6px'}"></ngx-skeleton-loader>
  </div>
  <ng-container *ngIf="url">
    <div class="d-flex">
      <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
        <mat-label>Shared url</mat-label>
        <input matInput class="input-group__field no-borders-right" [value]="url" readonly="true">
      </mat-form-field>
      <button mat-flat-button type="button" color="primary" class="btn btn-icon btn-icon--share"
              (click)="copyInputMessage($event)">
        <i class="fal fa-copy mr--5"></i>
      </button>
    </div>
    <ng-container *ngIf="successSend || (isCopy && timeoutMessage)">
      <div class="notice">
        <p *ngIf="successSend">Email was successfully sent to {{ email.value }}.</p>
        <p *ngIf="isCopy && timeoutMessage">URL successfully copied to the clipboard!</p>
      </div>
    </ng-container>

  </ng-container>
</div>

<div mat-dialog-actions class="dialog__footer">
  <span class="alternate-theme d-inline-block mr--10">
      <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel" *ngIf="isToShare">Close</button>
  </span>
  <button mat-flat-button tabIndex="-1" color="accent" class="btn btn--action" (click)="send()" *ngIf="!successSend && isToShare">Send</button>
  <button mat-flat-button tabIndex="-1" mat-dialog-close color="accent" class="btn btn--action" *ngIf="!isToShare">Close</button>
</div>
