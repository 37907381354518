// dep
import { Directive, HostListener, Input } from '@angular/core';

// app
import { ModalService } from '../services/modal.service';
import { GoogleLocationInfoComponent } from '../components/google-location-info.component';

@Directive({
  selector: '[gmbGLI]'
})
export class GoogleLocationDirective {
  @Input() 
  gmbGLI = () => {};

  @Input('gid') 
  gid : string;

  @Input('accountId') 
  accountId : string;

  @Input('locationId') 
  locationId : string;

  constructor(
    private _modalService: ModalService,
  ) {}

  @HostListener('dblclick', ['$event'])
  onDblClick(_$event: Event): void {
    this._modalService.openModal(GoogleLocationInfoComponent,
      {
        title: 'Google Location Information',
        content: `{gid: '${this.gid}', accountId: '${this.accountId}', locationId: '${this.locationId}'}`,
        closeButtonLabel: 'Ok',
        normalText: false
      },
      { disableClose: true,
        onClose : (r) => 1 })
  }

}
