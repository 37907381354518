import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ReportService } from '../../services/report.service';
import { WhiteLabelService } from '../../services/white-label.service';
import { AuthService } from '../../services/auth.service';
import { EmailerService } from '../../services/emailer.service';
import { environment } from '@environment';
import * as _ from 'lodash';
var ModalShareComponent = /** @class */ (function () {
    function ModalShareComponent(modalConfirmData, fb, reportS, wl, auth, emailerS) {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
        this.modalConfirmData = modalConfirmData;
        this.fb = fb;
        this.reportS = reportS;
        this.wl = wl;
        this.auth = auth;
        this.emailerS = emailerS;
        this.isToShare = false;
        this.comparisonMetrics = [];
        this.reportName = '';
        this.showComparison = null;
        this.questionsType = null;
        this.periodDaysValue = null;
        this.compareToValue = null;
        this.createNew = true;
        this.data = this.modalConfirmData.data;
        this.report = this.modalConfirmData.data.report;
        this.dataPicker = this.data.dataPicker;
        this.reportType = this.data.reportType;
        this.createNew = (_a = this.data) === null || _a === void 0 ? void 0 : _a.createNew;
        this.comparisonMetrics = ((_b = this.data) === null || _b === void 0 ? void 0 : _b.comparisonMetrics) || [];
        this.reportName = (_c = this.data) === null || _c === void 0 ? void 0 : _c.reportName;
        this.showComparison = (_d = this.data) === null || _d === void 0 ? void 0 : _d.showComparison;
        this.questionsType = (_e = this.data) === null || _e === void 0 ? void 0 : _e.questionsType;
        this.periodDaysValue = (_f = this.data) === null || _f === void 0 ? void 0 : _f.periodDaysValue;
        this.compareToValue = (_g = this.data) === null || _g === void 0 ? void 0 : _g.compareToValue;
        var paramId = (_l = (_k = (_j = (_h = this.data) === null || _h === void 0 ? void 0 : _h.route) === null || _j === void 0 ? void 0 : _j.snapshot) === null || _k === void 0 ? void 0 : _k.params) === null || _l === void 0 ? void 0 : _l.id;
        var paramGid = (_q = (_p = (_o = (_m = this.data) === null || _m === void 0 ? void 0 : _m.route) === null || _o === void 0 ? void 0 : _o.snapshot) === null || _p === void 0 ? void 0 : _p.params) === null || _q === void 0 ? void 0 : _q.gid;
        var id = paramId ? paramId : (((_r = this.report) === null || _r === void 0 ? void 0 : _r.id) || ((_s = this.report) === null || _s === void 0 ? void 0 : _s._id));
        var gid = paramGid ? paramGid : (_t = this.report) === null || _t === void 0 ? void 0 : _t.gid;
        var domain = _.startsWith(this.wl.domain, 'localhost') ? this.wl.domain : this.wl.domain.replace(/\:/g, '');
        if (this.createNew) {
            this.reportS.share(this.dataPicker, this.reportType, this.comparisonMetrics, this.reportName, this.showComparison, this.questionsType, this.periodDaysValue, this.compareToValue).subscribe(function (report) {
                if (_this.reportType != 'performance-insights' && !_this.reportType.includes('-location') && !_this.reportType.includes('-report')) {
                    report.get().then(function (result) {
                        var reportId = result.id;
                        var reportGid = gid || result.data().gid;
                        _this.url = "https://" + domain + "/report/" + reportGid + "/" + reportId + "/" + _this.reportType + "/shared";
                    });
                }
                else {
                    var reportId = report.reportId;
                    var reportGid = gid || _this.auth.session.gid;
                    _this.url = "https://" + domain + "/report/" + reportGid + "/" + reportId + "/" + _this.reportType + "/shared";
                }
            });
        }
        else {
            this.url = "https://" + domain + "/report/" + gid + "/" + id + "/" + this.reportType + "/shared";
            this.reportS.setMerge(gid, id, { shared: true }).then();
            return;
        }
    }
    ModalShareComponent.prototype.ngOnInit = function () {
        this.initForm();
        this.timeoutMessage = true;
    };
    ModalShareComponent.prototype.initForm = function () {
        this.form = this.fb.group({
            email: ['', [Validators.email, Validators.required]]
        });
    };
    Object.defineProperty(ModalShareComponent.prototype, "email", {
        get: function () {
            return this.form.get('email');
        },
        enumerable: true,
        configurable: true
    });
    ModalShareComponent.prototype.copyInputMessage = function ($event) {
        var _this = this;
        $event.preventDefault();
        var selBox = document.createElement('input');
        selBox.value = this.url;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.isCopy = true;
        setTimeout(function () {
            _this.timeoutMessage = false;
        }, 3000);
    };
    ModalShareComponent.prototype.apply = function () {
    };
    ModalShareComponent.prototype.send = function () {
        var _this = this;
        var env = environment;
        if (this.form.invalid) {
            return;
        }
        this.progress = true;
        var message = "\n    <h2>" + env.branding.company_name + "</h2>\n    <h3>A report has been shared with you!</h3>\n    <hr>\n    <p>Someone at " + env.branding.company_name + " shared a report with you. Click <a href=\"" + this.url + "\" target=\"_BLANK\">here</a> \n    to view it or copy and paste the link below.</p>\n    <p>" + this.url + "</p>\n    <hr>\n    <p><center><a href=\"" + env.branding.main_website + "\">" + env.branding.company_name + "</a></center></p>\n    ";
        this.emailerS.send(this.email.value, 'A report has been shared', message).subscribe(function () {
            _this.successSend = true;
            _this.progress = false;
        }, function (error) {
            _this.progress = false;
            _this.failedSend = true;
            console.error(error);
        });
    };
    return ModalShareComponent;
}());
export { ModalShareComponent };
