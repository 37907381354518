/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./verify-email.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i8 from "@angular/router";
import * as i9 from "@angular/material/button";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "@angular/forms";
import * as i12 from "./verify-email.component";
import * as i13 from "../../services/verification-email.service";
import * as i14 from "../../services/white-label.service";
var styles_VerifyEmailComponent = [i0.styles];
var RenderType_VerifyEmailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VerifyEmailComponent, data: {} });
export { RenderType_VerifyEmailComponent as RenderType_VerifyEmailComponent };
function View_VerifyEmailComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "spinner-wrapper spinner-wrapper--bg spinner-wrapper--fixed"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(2, 114688, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 2).diameter; var currVal_2 = i1.ɵnov(_v, 2).diameter; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_VerifyEmailComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "txt--center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your email has been verified!"]))], null, null); }
function View_VerifyEmailComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "txt--center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your email has been verified already or could not be found."]))], null, null); }
function View_VerifyEmailComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["class", "btn btn--xxl btn--full mb--20"], ["color", "primary"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(1, 16384, null, 0, i8.RouterLink, [i8.Router, i8.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 1), i1.ɵdid(3, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Login Now"]))], function (_ck, _v) { var currVal_2 = _ck(_v, 2, 0, "/login"); _ck(_v, 1, 0, currVal_2); var currVal_3 = "primary"; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 3).disabled || null); var currVal_1 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_VerifyEmailComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "btn btn--xxl btn--full mb--20"], ["color", "primary"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleContactUs() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Contact Support"]))], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_VerifyEmailComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 39, "div", [["class", "content--full p--tb-20 bg--darkblue d-flex justify-content-center align-items-center flex-column"]], null, null, null, null, null)), i1.ɵprd(512, null, i5.ɵNgStyleImpl, i5.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i5.NgStyle, [i5.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "background-color": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VerifyEmailComponent_1)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "logo mb--20 mt--20"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "a", [["mat-button", ""]], [[1, "tabindex", 0], [1, "disabled", 0], [1, "aria-disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8)._haltDisabledEvents($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatAnchor_0, i7.RenderType_MatAnchor)), i1.ɵdid(8, 180224, null, 0, i9.MatAnchor, [i10.FocusMonitor, i1.ElementRef, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(9, 0, null, 0, 0, "img", [["alt", "Logo"], ["height", "59"], ["width", "170"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 16, "div", [["class", "box box--md box--padding-md box--rounded bg--white mb--20"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 15, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 13).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 16384, null, 0, i11.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(13, 4210688, null, 0, i11.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i11.ControlContainer, null, [i11.NgForm]), i1.ɵdid(15, 16384, null, 0, i11.NgControlStatusGroup, [[4, i11.ControlContainer]], null, null), (_l()(), i1.ɵeld(16, 0, null, null, 2, "h1", [["class", "txt--xl mb--10 txt--center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Email Verification"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VerifyEmailComponent_2)), i1.ɵdid(20, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VerifyEmailComponent_3)), i1.ɵdid(22, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VerifyEmailComponent_4)), i1.ɵdid(24, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VerifyEmailComponent_5)), i1.ɵdid(26, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(27, 0, null, null, 12, "div", [["class", "footer footer--noborder flex-column box box--md txt--center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 9, "ul", [["class", "footer__nav d-flex justify-content-center mb--20"]], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 1, "a", [["class", "txt--white"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToTermsOfService() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Terms and Conditions"])), (_l()(), i1.ɵeld(32, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(33, 0, null, null, 1, "a", [["class", "txt--white"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToPrivacyPolicy() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Privacy Policy"])), (_l()(), i1.ɵeld(35, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(36, 0, null, null, 1, "a", [["class", "txt--white"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToPaymentPolicy() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Payment Policy"])), (_l()(), i1.ɵeld(38, 0, null, null, 1, "p", [["class", "txt--normal txt--gray m--0"]], null, null, null, null, null)), (_l()(), i1.ɵted(39, null, ["\u00A9 ", " All Rights Reserved."]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (((_co.env == null) ? null : ((_co.env.branding == null) ? null : _co.env.branding.primaryColor)) || "")); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.loginProgress; _ck(_v, 5, 0, currVal_1); var currVal_14 = _co.verified; _ck(_v, 20, 0, currVal_14); var currVal_15 = !_co.verified; _ck(_v, 22, 0, currVal_15); var currVal_16 = _co.verified; _ck(_v, 24, 0, currVal_16); var currVal_17 = !_co.verified; _ck(_v, 26, 0, currVal_17); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (i1.ɵnov(_v, 8).disabled ? (0 - 1) : (i1.ɵnov(_v, 8).tabIndex || 0)); var currVal_3 = (i1.ɵnov(_v, 8).disabled || null); var currVal_4 = i1.ɵnov(_v, 8).disabled.toString(); var currVal_5 = (i1.ɵnov(_v, 8)._animationMode === "NoopAnimations"); _ck(_v, 7, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = i1.ɵinlineInterpolate(1, "", (((_co.env == null) ? null : ((_co.env.branding == null) ? null : _co.env.branding.mainLogo)) || "/assets/images/logo/blankmap.png"), ""); _ck(_v, 9, 0, currVal_6); var currVal_7 = i1.ɵnov(_v, 15).ngClassUntouched; var currVal_8 = i1.ɵnov(_v, 15).ngClassTouched; var currVal_9 = i1.ɵnov(_v, 15).ngClassPristine; var currVal_10 = i1.ɵnov(_v, 15).ngClassDirty; var currVal_11 = i1.ɵnov(_v, 15).ngClassValid; var currVal_12 = i1.ɵnov(_v, 15).ngClassInvalid; var currVal_13 = i1.ɵnov(_v, 15).ngClassPending; _ck(_v, 11, 0, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13); var currVal_18 = ((_co.env == null) ? null : ((_co.env.branding == null) ? null : _co.env.branding.company_name)); _ck(_v, 39, 0, currVal_18); }); }
export function View_VerifyEmailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-verify-email", [], null, null, null, View_VerifyEmailComponent_0, RenderType_VerifyEmailComponent)), i1.ɵdid(1, 114688, null, 0, i12.VerifyEmailComponent, [i8.ActivatedRoute, i13.VerificationEmailService, i14.WhiteLabelService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VerifyEmailComponentNgFactory = i1.ɵccf("app-verify-email", i12.VerifyEmailComponent, View_VerifyEmailComponent_Host_0, {}, {}, []);
export { VerifyEmailComponentNgFactory as VerifyEmailComponentNgFactory };
