import { __awaiter, __generator } from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as firebase from 'firebase/app';
import { WhiteLabelService } from 'src/app/services/white-label.service';
import { environment } from '@environment';
import { VerificationEmailService } from 'src/app/services/verification-email.service';
//import { UserService } from 'src/app/services/user.service';
// import User from 'src/app/constants/firestore/user';
var VerifyEmailComponent = /** @class */ (function () {
    function VerifyEmailComponent(route, verificationService, wl) {
        this.route = route;
        this.verificationService = verificationService;
        this.wl = wl;
    }
    VerifyEmailComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var hash;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loginProgress = true;
                        this.env = environment;
                        hash = this.route.snapshot.queryParams.hash;
                        return [4 /*yield*/, this.verificationService.getVerificationToHash(hash).toPromise().then(function (ver) {
                                ver.forEach(function (v) {
                                    if (!v) {
                                        _this.verified = false;
                                        return;
                                    }
                                    var data = v.data();
                                    if (data.emailVerified == null) {
                                        _this.verificationService.update(v.id, { 'emailVerified': firebase.firestore.Timestamp.now() });
                                    }
                                    _this.verified = true;
                                });
                            })];
                    case 1:
                        _a.sent();
                        this.loginProgress = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    VerifyEmailComponent.prototype.goToTermsOfService = function () {
        this.wl.goToTermsOfService();
    };
    VerifyEmailComponent.prototype.goToPrivacyPolicy = function () {
        this.wl.goToPrivacyPolicy();
    };
    VerifyEmailComponent.prototype.goToPaymentPolicy = function () {
        this.wl.goToPaymentPolicy();
    };
    VerifyEmailComponent.prototype.handleContactUs = function () {
        this.wl.goToContactSupport();
    };
    return VerifyEmailComponent;
}());
export { VerifyEmailComponent };
