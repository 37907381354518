<div class="dialog__header dialog__header--warn txt--center">
  <h2 mat-dialog-title class="m--0 txt--xl">
    <strong>Are you sure you want to disconnect {{account?.account?.accountName}} from {{ companyName }}?</strong>
  </h2>
</div>
<div class="dialog__content" mat-dialog-content>
  <div class="dialog-row">
    <p class="txt--lg txt--center m--0"><strong>Note: this only disconnects the account from {{ companyName }}; it does not delete it from Google Maps. You can always re-add your account to {{ companyName }}.</strong></p>
  </div>

</div>
<div mat-dialog-actions class="dialog__footer">
    <span class="alternate-theme d-inline-block mr--10">
        <button tabIndex="-1" mat-button color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
    </span>
  <button tabIndex="-1" mat-flat-button color="warn" (click)="handleDeleteAccount()" class="btn btn--action">Delete</button>
</div>
