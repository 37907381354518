// dep
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';

// app
import { AuthService } from './auth.service';
import { LocationService } from './location.service';
import { GROUP_SUBSCRIPTION_TYPE, LOCATION_SUBSCRIPTION_TYPE } from '../constants/firestore/account-location';
import User from '../constants/firestore/user';
import { ISubscription } from '../constants/subscription';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuardService implements CanActivate {
  user: User;
  subscription$: Observable<ISubscription>;
  subscription: ISubscription;

  constructor(
    private router: Router,
    private auth: AuthService,
    private locationService: LocationService,
  ) {
    this.subscription$ = this.auth.subscription$;
    this.subscription$.subscribe(subscription => this.subscription = subscription);
  }

  async canActivate(route: ActivatedRouteSnapshot) : Promise<boolean> {
    this.user = this.auth.session;
    const locationId = route.parent.params.locationId;
    const accountId  = route.parent.params.accountId;

    const location = await this.locationService.fetchLocation(this.user.gid, accountId, locationId)

    if (this.subscription.status !== GROUP_SUBSCRIPTION_TYPE.TRIAL && location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE) {
      await this.router.navigate(['/account', accountId , 'location', locationId, 'insights']);
      return false;
    }
    return true;
  }
}
