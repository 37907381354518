import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { BehaviorSubject, interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-load-modal',
  templateUrl: './load-modal.component.html',
  styleUrls: ['./load-modal.component.scss']
})
export class LoadModalComponent implements OnInit {

  steps: number = 0;
  complete: number = 0;
  completed: string = null;
  progress: number = 0
  loadingValue$: BehaviorSubject<number> = new BehaviorSubject(0)

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { steps: number, complete: number, completed: string},
    public dialogRef: MatDialogRef<LoadModalComponent>
  ) {
    this.steps = data.steps;
    this.complete = data.complete;
    this.completed = data.completed;
    let destoyCount$: Subject<boolean> = new Subject()
    interval(500).pipe(takeUntil(destoyCount$)).subscribe( () => {
      if (this.progress >= 60) {
        destoyCount$.next(true);
        destoyCount$.unsubscribe()
      } else {
        this.completed = `Stage 1 - Fetching Location Insights`;
        this.progress += Math.ceil(Math.random() * (30 - 10) + 10);
        this.loadingValue$.next(this.progress)
      }
    })
   }

  ngOnInit() {
  }

  updatedData(complete: number, completed: string) {
    this.complete += complete;
    //this.completed = `Stage ${this.complete} - Fetching ${completed}`;
    let progressCompleted = ((this.complete / this.steps) * 100) + 60
    if (progressCompleted >= 60 ) {
      this.progress = progressCompleted;
      if (this.complete === this.steps){
        this.completed = 'Stage 3 - Complete!';
        setTimeout(() => {
          this.dialogRef.close()
        }, 1000)
      }

      if (progressCompleted >= 100  || (this.complete - this.steps) < 3) this.loadingValue$.next(90)
      this.loadingValue$.next(progressCompleted)
      this.completed = `Stage 2 - Fetching Review Data`;
    }
  }

}
