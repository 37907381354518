
	  <div class="dialog__header txt--center dialog__header--warn"
		   [ngClass]="{'dialog__header--warn': data.alertType == 1, 'dialog__header--info': data.alertType == 0, 'dialog__header--error': data.alertType == 2}">
		  <h2 mat-dialog-title class="m--0 txt--xl"><strong>Review Link</strong></h2>
	  </div>

	  <div class="dialog__content" mat-dialog-content>

		  <div class="dialog-row pb--0">

			  <ng-container>
				  <div class="d-flex">
					  <mat-form-field floatLabel="never"
									  class="input-group input-group--override input-group--override-label">
						  <mat-label>Review link url</mat-label>
						  <input matInput class="input-group__field no-borders-right" [value]="url" readonly="true">
					  </mat-form-field>
					  <button mat-flat-button type="button" color="primary" class="btn btn-icon btn-icon--share"
							  (click)="copy($event)" *ngIf="!isCopy">
						  <i class="fal fa-copy mr--5"></i>
					  </button>
				  </div>
			  </ng-container>
			  <ng-container>
				  <qrcode #parent [qrdata]="url" [width]="256" [errorCorrectionLevel]="'M'" elementType="img"></qrcode>
				  <button color="primary" mat-flat-button class="btn btn--lg"
						  (click)="saveAsImage(parent)">
					  Download QR Code
				  </button>


			  </ng-container>

		  </div>
	  </div>
	  <div mat-dialog-actions class="dialog__footer">
		  <button tabIndex="-1" mat-button class="btn btn-cancel" [mat-dialog-close]="true">Cancel</button>
	  </div>
  