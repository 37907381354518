import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Observable, of, BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import { ReportService } from '../../services/report.service';
import { DataPicker } from '../../constants/data-picker';
import { AuthService } from '../../services/auth.service';
import SavedLocation from '../../constants/firestore/saved-location';
import Report from '../../constants/firestore/report';
import { takeUntil, take } from 'rxjs/operators';
import { DataTransferService } from '../../services/data-transfer.service';
import { WhiteLabelService } from 'src/app/services/white-label.service';
import { Title } from '@angular/platform-browser';
import { LocationService } from '../../services/location.service';
import { GROUP_SUBSCRIPTION_TYPE, LOCATION_SUBSCRIPTION_TYPE } from 'src/app/constants/firestore/account-location';
import { ValidateLocations } from 'src/app/shared/Auxiliary/validate-locations';
import AccountReport from '../../services/account-report';
import { LoadingService } from '../../services/loading.service';
import { MatDialog } from '@angular/material';
import { DialogDeleteLocationsComponent } from 'src/app/components/dialog-delete-locations.component';
import { ModalService } from 'src/app/services/modal.service';
import { AlertComponent, AlertType } from 'src/app/components/alert.component';
import { SpinnerService } from 'src/app/services/spinner.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { MAIL_ANONYMOUS } from 'src/app/constants/auth';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnDestroy {
  public id: string;
  dataPicker: DataPicker;
  stats: any[];
  labels: string[];
  reportType: string;
  locations: SavedLocation[] = [];
  shared: any;
  lockDates = false;
  sharedNotAllowed = false;
  report: Report;
  reportId: string;
  gid: any;
  refresh = new Subject();
  showMultilocations = true;
  contentClass: any;
  viewModeChecked = 'legacy';
  isProcessed = false;
  public minDate = null;
  public maxDate = null
  publicRoute;
  loadDataObservable$: Observable<{ total: number, completed: string }> = of({ total: 0, completed: null })

  subscriptionManager$ = new Subject<boolean>();

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private reportS: ReportService,
    private auth: AuthService,
    private wl: WhiteLabelService,
    private titleService: Title,
    private dataTrasnfer: DataTransferService<string>,
    private locationService: LocationService,
    private validateLocation: ValidateLocations,
    private loadingService: LoadingService,
    private dialog: MatDialog,
    private modalService: ModalService,
    private spinnerService: SpinnerService,
    private snackS: SnackbarService
  ) {
    this.spinnerService.loading$.next(true);
    this.reportType = this.route?.snapshot.params?.reportType;
    this.reportType = (
      this.reportType?.includes('-location') ? 
      this.reportType?.replace('-location', '') : 
      this.reportType?.includes('-report') ?
      this.reportType?.replace('-report', '') :  
      this.reportType
    );
    this.viewModeChecked = this.reportType?.includes('performance') || this.reportType?.includes('review') || this.reportType?.includes('keyword') || this.reportType?.includes('qanda') ? 'new' : 'legacy';

    if (this.route?.snapshot?.params?.hash) {
      this.loadRouteWithHash(this.route?.snapshot?.params?.hash).then();
    } else {
      const automatedReport = this.route?.snapshot?.params?.automatedReport;
      if (automatedReport && this.viewModeChecked == 'legacy') {
        this.loadAutomatedData(automatedReport);
      } else {
        this.loadData(automatedReport);
      }
    }

    this.publicRoute = route;
  }

  async loadRouteWithHash(hash) {
    this.reportS.getByHash(hash)?.pipe(
      takeUntil(this.subscriptionManager$)
    ).subscribe(report => {
      this.id = report.id;
      this.gid = report.gid;
      this.auth.signInAnonymously(this.gid).then(res => {
        this.router.navigate([`/report/${this.gid}/${this.id}/shared`]).then();
      });
    }, error => {
      console.error('error on:', error);
      this.spinnerService.loading$.next(false);
    });
  }

  async loadAutomatedData(automated: string) {
    let authenticated = null;
    this.gid = this.route?.snapshot?.params?.gid;
    this.id = automated;
    this.reportId = this.id;
    this.shared = true;
    this.lockDates = this.report?.lockDates || false;
    if (!this.auth.session || !this.auth.session.email || this.auth.session.email === MAIL_ANONYMOUS) {
      authenticated = await this.auth.signInAnonymously(this.gid);
    } else {
      this.showNotSupportedModal();
      return;
    }

    this.getAutomatedReport(this.gid, automated, authenticated);

  }

  async AlloweReport(report: Report) {
    if (!_.isEmpty(report.accounts)) {
      await this.locationService.basicLocations([{ accounts: report.accounts }]).then()

      this.auth.getSubscription(report.gid)
      .then(subscription => {
        if (subscription.status == GROUP_SUBSCRIPTION_TYPE.TRIAL) {
          return;
        } 
        
        if (report.sharedOnly) {
          const subscriptionType = report.accounts[0].locationsBasics[0].subscriptionType;
          if (subscriptionType == 'ESSENTIAL' || subscriptionType == 'FREE') {
            this.router.navigate(['/login']);
          }
          return;
        }

        for (const account of report.accounts) {
          const hasFreeLocations      = !_.isEmpty(_.filter(account.locationsBasics, { subscriptionType: LOCATION_SUBSCRIPTION_TYPE.FREE }));
          const hasEssentialLocations = !_.isEmpty(_.filter(account.locationsBasics, { subscriptionType: LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL }));
          const hasBasicLocations     = !_.isEmpty(_.filter(account.locationsBasics, { subscriptionType: LOCATION_SUBSCRIPTION_TYPE.BASIC }));
  
          this.auth.getSubscription(account.gid).then(subscription => {
            if (subscription.status !== GROUP_SUBSCRIPTION_TYPE.TRIAL &&
              (hasFreeLocations || hasEssentialLocations || hasBasicLocations)) {
              this.router.navigate(['/login'])
            }
          })
  
          const hasInvalidAccounts  = !_.isEmpty(_.filter(account.locations, { accountId: 'accounts' }));
          const hasInvalidLocations = !_.isEmpty(_.filter(account.locations, { locationId: 'locations' }));
          if (hasInvalidAccounts || hasInvalidLocations) {
            this.router.navigate(['/login'])
          }
  
          const hasDuplicated = _.size(_.uniqBy(account.locations, 'locationId')) < _.size(account.locations);
          if (hasDuplicated) {
            this.router.navigate(['/login'])
          }
        }
      })
      .catch(err => {
        this.modalService.openConfirmModal(
          'Loading error',
          'There was an error while loading the data. Please try again or contact support (error code 1)',
        (() => {
          this.auth.signOut(true, true)
        }),
        AlertType.ERROR,
        'Retry');
      });
    }
  }

  async loadData(automatedReport = null) {
    let authenticated = null;
    this.id = automatedReport || this.route?.snapshot?.params?.id
    this.gid = this.route?.snapshot?.params?.gid;
    this.reportId = this.id;
    this.reportType = this.reportType == 'performance-insights' ? 'performance-rollup' : this.reportType;

    if (this.route?.snapshot?.url?.findIndex(segment => segment.toString() === 'shared') !== -1 || this.auth.session.uid === undefined) {
      this.shared = true;
      this.lockDates = this.report?.lockDates || false;
      if (!this.auth?.session?.email || this.auth?.session?.email === MAIL_ANONYMOUS) {
        authenticated = await this.auth.signInAnonymously(this.gid);
      } else {
        this.showNotSupportedModal();
        return;
      }
    }

    if(this.viewModeChecked == 'new'){
      this.getReportMongo(this.gid, this.id, authenticated, automatedReport); 
    } else {
      this.getReport(this.gid, this.id, authenticated);
    }
  }

  showNotSupportedModal() {
    this.modalService.openConfirmModal(
      'Alert',
      'Opening a shared report while logged-in is not supported. Please use an incognito session or log-out.',
      () => { },
      AlertType.WARNING
    );
  }

  private getAutomatedReport(gid, id, anonymous = null) {
    this.reportS.getAutomated(gid, id)?.pipe(
      take(1)
    ).subscribe(async (report) => {
      this.buildAutomatedReport(report, anonymous);
    }, error => {
      this.spinnerService.loading$.next(false);
      console.log(error);
    });
  }

  getReport(gid, id, anonymous = null) {
    this.reportS.get(gid, id)?.pipe(
      take(1)
    ).subscribe((report) => {
      this.report = report;
      this.verfiyLocations();
      this.buildReport(gid, id, anonymous, report);
    }, error => {
      console.log(error);
      this.snackS.openError('There was an error while loading the data. Please try again or contact support', 4000);
      this.spinnerService.loading$.next(false);
    });
  }

  verfiyLocations() {
    const hasLocations = this.report?.accounts.find(el => el?.locations?.length > 0);
    if (!hasLocations) {
      const reportType = this.reportType == 'keyword' ? 'keywords' : (this.reportType == 'qanda' ? 'qanda' : `${this.reportType}-report`);
      this.router.navigateByUrl(`${reportType}`).then();
    }
  }

  async buildAutomatedReport(report, anonymous) {
    this.report = report;
      const accounts = report.accounts ? report.accounts: [
        {
          gid: this.gid,
          accountId: report?.locations[0]?.accountId,
          locations: report.locations
        }
      ];
      this.report.accounts = await this.validateLocation.validateArrayLocations(accounts);
      this.spinnerService.loading$.next(false);
      const waitingPopup = this.loadDataModal(
        this.report.accounts,
        report.reportType
      );
      if (anonymous) await this.AlloweReport(report);
      if (!report) return;
      let ReportTypeName = report.reportType.replace(/\b\w/g, l => l.toUpperCase());
      ReportTypeName = ReportTypeName.toLowerCase().includes('qanda') ? 'Q & A' : ReportTypeName;
      if (this.shared) {
        this.wl.getDynamicWhiteLabel().then(data => {
          const company_name = data?.branding?.company_name;
          const title_pass = `${ReportTypeName} Report | ${company_name}`;
          this.titleService.setTitle(title_pass)
        });
      } else {
        this.dataTrasnfer.setData(`${ReportTypeName} Report | ${report.reportName}`);
      }
      this.processReport(anonymous, report, true);
  }

  getReportMongo(gid, id, anonymous = null, automatedReport = null) : void { 
    this.reportS.getReportById(id, automatedReport)?.pipe(
      take(1)
    ).subscribe(async (report) => {
      if(report?.error) {
        console.log(report?.error);
        this.snackS.openError('There was an error while loading the data. Please try again or contact support', 4000);
        this.spinnerService.loading$.next(false);
      } else {
        const id = report?.id || report?._id;
        //added it until the new version of back that handles the existence of locations and accounts is integrated
        report.accounts = report?.accounts.filter(r => r.locations.length);
        report.reportType = report?.reportType == 'performance-insights' ? 'performance-rollup' : report.reportType;
        report?.multiChart.forEach(m => m.checked = true)
        this.report = report;
        const accountIds = report.accounts.map(a => a.accountId);
        const locationIds = []; 
        report.accounts.forEach(a => a.locations.forEach(l => locationIds.push(l.locationId)));
        const dateValidations = await this.locationService.getDateValidations(this.reportType, accountIds, [gid], locationIds).toPromise();
        const dates = this.locationService.dateValidation(dateValidations);
        this.minDate = dates.minDate;
        this.maxDate = dates.maxDate;
        this.verfiyLocations();
        this.lockDates = this.report && this.report.lockDates ? this.report.lockDates : false;
        this.reportId = automatedReport ? report.parentReport : id;
        this.buildReport(gid, id, anonymous, report);
      }
    }, error => {
      console.log(error);
      this.snackS.openError('There was an error while loading the data. Please try again or contact support', 4000);
      this.spinnerService.loading$.next(false);
    });
  }

  async buildReport(gid, id, anonymous, report) : Promise<void> {
    const accounts = report?.accounts || [
      {
        gid,
        accountId: report.locations[0].accountId,
        locations: report.locations
      }
    ];

    this.report.accounts = accounts;
    this.spinnerService.loading$.next(false);
    const waitingPopup = this.loadDataModal(this.report.accounts, report.reportType);

    if (await this.existAllLocations(report, id)) {
      this.report.accounts = await this.validateLocation.validateArrayLocations(accounts);
      if (anonymous) 
        await this.AlloweReport(report);

      if (!report)
        return;

      let ReportTypeName = report.reportType.replace(/\b\w/g, l => l.toUpperCase());
      ReportTypeName = ReportTypeName.toLowerCase().includes('qanda') ? 'Q & A' : ReportTypeName;
      if (this.shared) {
        this.wl.getDynamicWhiteLabel().then(data => {
          const company_name = data.branding.company_name;
          const title_pass = `${ReportTypeName} Report | ${company_name}`;
          this.titleService.setTitle(title_pass)
        });
      } else {
        this.dataTrasnfer.setData(`${ReportTypeName} Report | ${report.reportName}`);
      }
      this.processReport(anonymous, report);
    } else {
      waitingPopup?.close();
    }
  }

  private async existAllLocations(report, reportId) : Promise<boolean> {
    const locationsIds = [];

    for (const account of report.accounts) {
      if (account.locations && account.locations.length > 0) {
        account.locations.forEach(l => {
          locationsIds.push({
            'gid': account.gid,
            'accountId': account.accountId,
            'locationId': l.locationId
          });
        });
      } else {
        return false;
      }
    }

    const AllLocations = await this.locationService.fetchLocationsExistence(locationsIds)
    
    if (AllLocations.some(r => !r.exist)) {

      const DeletedLocations = AllLocations.filter(l => !l.exist)
      const nouns = DeletedLocations.length > 1 ? ['were', 'locations have'] : ['was', 'location has'];
      const textDescription = `This report used to contain ${AllLocations.length} locations but ${DeletedLocations.length} ${nouns[0]} removed from the system. The missing ${nouns[1]} now been removed from this report.`;
      this.locationService.deleteReportLocation(DeletedLocations, DeletedLocations[0]['gid'], reportId)

      const dialogRef = this.dialog.open(DialogDeleteLocationsComponent, {
        width: '400px',
        data: {
          title: 'Heads Up',
          description: textDescription,
          DeletedLocations,
        }
      });

      dialogRef.disableClose = true;

      dialogRef.afterClosed().subscribe(() => {
        dialogRef.close();
        this.deleteLocations(DeletedLocations);
      });

      return false;
    }

    return true;
  }

  deleteLocations(deletedLocations) {
    this.spinnerService.loading$.next(true);

    deletedLocations.forEach(el => {
      const indexAccount = this.report?.accounts?.findIndex(a => a.accountId == el.accountId);
      const indexLoc = this.report.accounts[indexAccount].locations?.findIndex(l => l.locationId == el.locationId);
      this.report.accounts[indexAccount].locations?.splice(indexLoc, 1);
    });

    this.report.accounts = this.locationService.deleteServiceArea(this.report.accounts);
    
    if (this.viewModeChecked != 'new') {
      if(this.report?.id) {
        this.reportS.update(false, this.report?.gid, this.report?.id, this.report).then(
          res => {
            location.reload();
          },
        err => {
          this.handleDeleteError();
        });
      } else {
        location.reload();
      }
    } else {
      if (this.reportType == 'performance-comparison') {
        this.report.metrics = this.report.metricsName;
      } 

      this.reportS.updateReportsInMongo(this.report['_id'], this.report).subscribe(
        res => {
          if(res?.['error']) {
            this.handleDeleteError();
          }else {
            location.reload();
          }
        },
        err => {
          this.handleDeleteError();
        }
      );
    }
  }

  handleDeleteError() {
    this.spinnerService.loading$.next(false);
    const dialogRef = this.dialog.open(AlertComponent, {
      width: '680px',
      data: {
        title: 'Heads up',
        content: "There was an error while removing the locations.",
        closeButtonLabel: 'Close',
        alertType: AlertType.ERROR
      }
    });

    dialogRef.disableClose = true;

    dialogRef.afterClosed().subscribe(() => {
      dialogRef.close();
      location.reload();
    });
  }

  loadDataModal(accounts: AccountReport[], type: string) {
    accounts = accounts.filter(Boolean)
    if (accounts.reduce((r, ac) => r += ac.locations.length, 0) <= 9) return

    let steps = 0;
    let reportType = type.includes('-report') ? type.replace('-report', '') : type;
    reportType = reportType.includes('performance') ? reportType?.slice("performance-".length) : reportType

    switch (reportType) {
      case 'rollup':
        steps = 5;
        break;
      case 'qanda':
        steps = 1;
        break;
      case 'revenue':
        steps = 1;
        break;
      case 'review':
        steps = 3;
        break;
      case 'comparison':
        steps = 2;
        break;
      case 'grade':
        steps = 3;
        break;
      case 'Review Assistant':
        steps = 6;
        break;
      case 'keyword':
        steps = 1;
        break;
    }

    return this.loadingService.open(steps);
  }

  processReport(anonymous, report: Report, automatedReport = false) {
    this.spinnerService.loading$.next(false);
    this.reportType = this.reportType?.includes('-location') ? this.reportType?.replace('-location', '') : this.reportType;
    this.dataPicker = this.reportS.reportToDataPicker(report, this.id, automatedReport, this.viewModeChecked);
    this.dataPicker.aggregation = report?.aggregation || this.dataPicker?.aggregation
    this.dataPicker.multiChart  = report?.multiChart  || this.dataPicker?.multiChart
    this.showMultilocations = _.has(report, 'dynamic');
    this.updateClass();
    this.isProcessed = true;
  }

  ngOnDestroy(): void {
    this.reportType = null;
    this.subscriptionManager$.next(true);
    this.subscriptionManager$.unsubscribe();
    this.spinnerService.loading$.next(false);
  }

  handleDatapicker($event: any) {

    if ($event) {
      this.dataPicker = $event;
      this.refresh.next(true);
    }
  }

  private updateClass(): void {
    if (this.shared) {

      if(
        this.reportType.includes('rollup') ||
        this.reportType.includes('review') ||
        this.reportType.includes('comparison') ||
        this.reportType.includes('revenue') ||
        this.reportType.includes('keyword')
      ) {
        return;
      }

      this.contentClass = {
        'content content--padding': (this.reportType === 'grade'),
        'dashboard-content dashboard-content--sm': !(this.reportType === 'grade')
      };
    }

  }

  get loadingSpinner(): BehaviorSubject<boolean> {
    return this.spinnerService.loading$;
  }
}
