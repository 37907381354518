<div *ngIf="!disabled; else ReadOnly">
  <input [id]="'calendar'.concat(id)" type="text" class="input--calendar" [ngClass]="{'input-field' : !isForm,'input-group__field': isForm} " style="width: 100%;"
    #datePicker
    (click)="handleClick($event)"
    ngxDaterangepickerMd
    [ranges]="ranges"
    startKey="start"
    endKey="end"
    name="ranges"
    [(ngModel)]="select"
    [locale]="locale"
    [opens]="opens"
    [drops]="drops"
    [maxDate]="maxDate"
    [minDate]="minDateRange"
    [closeOnAutoApply]="false"
    [showCustomRangeLabel]="true"
    [alwaysShowCalendars]="true"
    [showRangeLabelOnInput]="true"
    [showCancel]="true"
    [readOnly]="isReadOnly"
    (rangeClicked)="rangeClicked($event)"
    (datesUpdated)="datesUpdated($event)"
    (keyup)="changeDateWrite(datePicker.value,$event)"
  >
  <label [for]="'calendar'.concat(id)" class="input-label--angle"></label>
</div>
<ng-template #ReadOnly>
    <input
      style="width: 100%;"
      [id]="'calendar'.concat(id)"
      matInput
      type="text"
      class="input--calendar input-field"
      [value]="getDates(dataPicker.range.start, dataPicker.range.end)"
      [disabled]="true"
    />
    <label [for]="'calendar'.concat(id)" class="input-label--angle"></label>
</ng-template>

