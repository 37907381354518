import { __awaiter, __generator, __values } from "tslib";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, of } from 'rxjs';
// app
import { AuthService } from './auth.service';
import { environment as ENV } from '@environment';
import { GoogleService } from './google.service';
import { Queue } from '../constants/firestore/enqueue';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "./google.service";
import * as i3 from "@angular/common/http";
var AccountService = /** @class */ (function () {
    function AccountService(_auth, _googleS, _http) {
        this._auth = _auth;
        this._googleS = _googleS;
        this._http = _http;
        this.loading = false;
        this.size = 10;
        this.page = 1;
        this.pagination = {
            items: [],
            per_page: this.size,
            page: this.page,
            hasNext: false,
            hasPrev: false,
            pages: 0,
            total: 0
        };
        this._dataStore = { accounts: [] };
        this._accounts = new BehaviorSubject([]);
    }
    Object.defineProperty(AccountService.prototype, "accounts", {
        get: function () {
            return this._accounts.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    AccountService.prototype.getAccountPaginate = function (gid, pageable, accountIds) {
        return __awaiter(this, void 0, void 0, function () {
            var params;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        params = new HttpParams()
                            .set('page', pageable.page.toString())
                            .set('pageSize', pageable.size.toString())
                            .set('accountIds', accountIds.join(','));
                        return [4 /*yield*/, this._http.get(ENV.apiUrl + "/v2/accounts/" + gid + "/all", { params: params }).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    AccountService.prototype.loadAll = function (user, pageable) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var accountIds, result;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.loading = true;
                        accountIds = ((_a = user.accounts) === null || _a === void 0 ? void 0 : _a.map(function (it) { return it['accountId']; })) || [];
                        if (user.role == 'member' && accountIds.length == 0) {
                            accountIds.push('-');
                        }
                        return [4 /*yield*/, this.getAccountPaginate(user.gid, pageable, accountIds)];
                    case 1:
                        result = _b.sent();
                        this.previousPageable = { size: result['perPage'],
                            page: result['currentPage'] };
                        this.pagination = {
                            items: result['items'],
                            per_page: result['perPage'],
                            page: result['currentPage'],
                            hasNext: result['currentPage'] < result['totalPages'],
                            hasPrev: result['currentPage'] > 1,
                            pages: result['totalPages'],
                            total: result['totalItems']
                        };
                        this._dataStore.accounts = result['items'];
                        this._accounts.next(Object.assign({}, this._dataStore).accounts);
                        this.loading = false;
                        return [2 /*return*/, result];
                }
            });
        });
    };
    AccountService.prototype.delete = function (gid, accountId) {
        return this._http.delete(ENV.apiUrl + "/v2/accounts/" + gid + "/" + accountId).toPromise();
    };
    AccountService.prototype.saveAll = function (accounts) {
        return __awaiter(this, void 0, void 0, function () {
            var r, accounts_1, accounts_1_1, acc, _a, _b, e_1_1;
            var e_1, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        r = [];
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 6, 7, 8]);
                        accounts_1 = __values(accounts), accounts_1_1 = accounts_1.next();
                        _d.label = 2;
                    case 2:
                        if (!!accounts_1_1.done) return [3 /*break*/, 5];
                        acc = accounts_1_1.value;
                        _b = (_a = r).push;
                        return [4 /*yield*/, this.save(acc)];
                    case 3:
                        _b.apply(_a, [_d.sent()]);
                        _d.label = 4;
                    case 4:
                        accounts_1_1 = accounts_1.next();
                        return [3 /*break*/, 2];
                    case 5: return [3 /*break*/, 8];
                    case 6:
                        e_1_1 = _d.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 8];
                    case 7:
                        try {
                            if (accounts_1_1 && !accounts_1_1.done && (_c = accounts_1.return)) _c.call(accounts_1);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 8: return [2 /*return*/, r];
                }
            });
        });
    };
    AccountService.prototype.updateGauth = function (gid, accountId) {
        var gauth = {
            googleAuth: this._auth.session.googleAuth,
            gauthStatus: {
                isValid: true,
                message: null,
                updatedAt: new Date() // FIXME: Local browser time, not Server UTC
            }
        };
        return this.getAndUpdate(gid, accountId, gauth).toPromise();
    };
    AccountService.prototype.save = function (accountObject, gradeExternal) {
        return __awaiter(this, void 0, void 0, function () {
            var googleAuth, userProfile, account;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        googleAuth = gradeExternal ? accountObject.googleAuth : this._auth.session.googleAuth;
                        return [4 /*yield*/, this._googleS.getUserProfile(googleAuth)];
                    case 1:
                        userProfile = _a.sent();
                        account = {
                            gid: gradeExternal ? this._auth.externalSession.gid : this._auth.session.gid,
                            accountId: accountObject.name.split('/')[1],
                            accountName: accountObject.accountName,
                            account: accountObject,
                            googleAuthEmailAddress: userProfile.email,
                            gauthStatus: { isValid: true },
                            googleAuth: googleAuth
                        };
                        if (gradeExternal) {
                            account.gradeExternal = gradeExternal;
                        }
                        return [4 /*yield*/, this._http.post(ENV.apiUrl + "/v2/accounts/add", account).toPromise()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    AccountService.prototype.get = function (gid, accountId) {
        return this._http.get(ENV.apiUrl + "/v2/accounts/" + gid + "/" + accountId);
    };
    AccountService.prototype.getAndUpdate = function (gid, accountId, data) {
        return this._http.post(ENV.apiUrl + "/v2/accounts/" + gid + "/" + accountId + "/save", data);
    };
    AccountService.prototype.reset = function () {
        this._accounts = new BehaviorSubject([]);
    };
    AccountService.prototype.refreshAccountLocations = function (element) {
        var params = new HttpParams();
        if (ENV.queuesEnabled) {
            params = params.append('enqueue', Queue.COMBINED_EXPRESS);
        }
        if (element.last_refresh) {
            var currentDate = new Date();
            var lastUpdate = new Date(element.last_refresh.toDate());
            if (currentDate.getDate() >= lastUpdate.getDate()) {
                var diffInMilliSeconds = Math.abs(currentDate.getDate() - lastUpdate.getDate()) / 1000;
                var hours = Math.floor(diffInMilliSeconds / 3600) % 24;
                diffInMilliSeconds -= hours * 3600;
                if (diffInMilliSeconds <= 24) {
                    return of({
                        message: 'We cannot do this action',
                        data: {
                            message: 'We cannot do this action',
                            response: null,
                            status: 'FAIL'
                        }
                    });
                }
            }
        }
        return this._http.post(ENV.apiUrl + "/v2/accounts/refresh/gid/" + element.gid + "/account/" + element.accountId, {}, { params: params });
    };
    // TODO: Not used, remove?
    // refreshCount(enqueue: string): Observable<response> {
    //  let params = new HttpParams();
    //  if (enqueue && ENV.queuesEnabled) {
    //    params = params.append('enqueue', enqueue);
    //  }
    //
    //  return this.http.get<response>(`${ENV.apiUrl}/v2/accounts/refresh/count`, { params });
    // }
    AccountService.prototype.enableNotifications = function (accountId) {
        var headers = new HttpHeaders({ 'accountId': accountId });
        return this._http.post(ENV.apiUrl + "/v2/google/updateNotificationSetting", {}, { headers: headers });
    };
    AccountService.prototype.buildToggleLocationAccounts = function (locations) {
        var _this = this;
        var data = [];
        locations.forEach(function (el) {
            var _a;
            var indexAcc = data.findIndex(function (loc) { var _a; return loc.accountId == ((_a = el) === null || _a === void 0 ? void 0 : _a.accountId); });
            if (indexAcc > -1) {
                data[indexAcc].locations.push(_this.buildToggleLocationLocations(el));
            }
            else {
                var acc = {
                    accountId: (_a = el) === null || _a === void 0 ? void 0 : _a.accountId,
                    gid: _this._auth.session.gid,
                    locations: []
                };
                acc.locations.push(_this.buildToggleLocationLocations(el));
                data.push(acc);
            }
        });
        return data;
    };
    AccountService.prototype.buildToggleLocationLocations = function (account) {
        var _a, _b, _c, _d, _e;
        return {
            accountId: (_a = account) === null || _a === void 0 ? void 0 : _a.accountId,
            locationId: (_b = account) === null || _b === void 0 ? void 0 : _b.location.locationId,
            locationName: (_c = account) === null || _c === void 0 ? void 0 : _c.location.name,
            address: (_d = account) === null || _d === void 0 ? void 0 : _d.location.address,
            serviceArea: (_e = account) === null || _e === void 0 ? void 0 : _e.location.serviceArea,
        };
    };
    AccountService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountService_Factory() { return new AccountService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.GoogleService), i0.ɵɵinject(i3.HttpClient)); }, token: AccountService, providedIn: "root" });
    return AccountService;
}());
export { AccountService };
