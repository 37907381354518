<ng-container *ngIf="serviceList">
    <div class="content__body">
        <div class="table--shadow mb--30">
            <div class="mat-row mat-row--padding mat-row--border justify-content-between align-items-center bg--white">
                <div class="dialog-row" *ngFor="let category of dataSource; let sectionIndex  = index">
                    <div class="box box--shadow" [ngStyle]="{'border-radius': '8px'}">
                      <ng-container *ngIf="category">
                        <div class="box__header box__header--xs justify-content-between align-items-center" [ngStyle]="{'background-color': '#f1f3f4', 'border-radius': '8px 8px 0px 0px'}">
                          <!-- <p class="txt--lg m--0"><strong>{{sectionLabel?.displayName}}</strong></p> -->
                          <p class="txt--lg m--0"><strong>{{category.displayName}}</strong></p>
                          <small >{{category.principal ? 'Main Category' : '' }}</small>
                        </div>

                        <div >

                            <ul class="list-group list-group-flush">
                              <li class="list-group-item border-bottom" style="margin: 5px 0;" *ngFor="let service of category.services; let serviceMaskIndex  = index">
                                  <div class="row box__content box__content--padding-xs w--100">
                                    <div class="col-10">
                                      <h3 class="txt--left" style="margin: 5px 0;cursor: pointer;" (click)="editItem(service, service.positionEdit, sectionIndex, serviceMaskIndex); $event.stopPropagation">{{service.displayName}}</h3>
                                      <h5 class="txt--left" style="margin: 5px 0;cursor: pointer;"  (click)="editItem(service, service.positionEdit, sectionIndex, serviceMaskIndex); $event.stopPropagation">
                                        <ng-container *ngIf="service.price === null || service.price === undefined; else Money">
                                          <!-- nothing -->
                                        </ng-container>
                                        <ng-template #Money>
                                          <ng-container *ngIf="(service.price?.units == null && service.price?.nanos == null) || ((service.price?.units === '0') && (service.price?.nanos === 0))">
                                            Free
                                          </ng-container>
                                          <ng-container *ngIf="service.price?.units != null || service.price?.nanos != null">
                                            <ng-container *ngIf="(service.price?.units !== '0') && (service.price?.nanos !== null && service.price?.nanos !== 0); else lackMoney">
                                              {{ parseMoney(service.price?.units, service.price?.nanos) | currency }}
                                            </ng-container>
                                          </ng-container>
                                          <ng-template #lackMoney>
                                            <ng-container *ngIf="service.price?.units !== '0'">
                                              {{ parseMoney(service.price?.units, 0) | currency }}
                                            </ng-container>
                                            <ng-container *ngIf="service.price?.nanos  !== 0">
                                              {{ parseMoney(0, service.price?.nanos) | currency }}
                                            </ng-container>
                                            <ng-container *ngIf="(service.price?.units === null || service.price?.units === undefined) && (service.price?.nanos ===null ||  service.price?.nanos === undefined )">
                                              {{ parseMoney(0, 0) | currency }}
                                            </ng-container>
                                          </ng-template>
                                        </ng-template>
                                      </h5>
                                      <ng-container *ngIf="service?.description">
                                        <p class="txt--left w--100" style="cursor: pointer;" *ngIf="service.description.length <= 60" (click)="editItem(service, service.positionEdit, sectionIndex, serviceMaskIndex); $event.stopPropagation">{{ service.description}}</p>
                                        <p class="txt--left txt--black mb--10" style="white-space: normal; overflow-wrap: break-word;" *ngIf="service.description.length > 60">
                                          {{(showMoreMessage && showMoreMessageIndex === serviceMaskIndex) ? service?.description :  service.description | limitTo: '60'}}
                                          <a (click)="toggleMessage(serviceMaskIndex)" class="btn txt--underline txt--lightblue"
                                             *ngIf="service?.description?.length > 60">{{ showMoreMessage && showMoreMessageIndex === serviceMaskIndex ? 'read less' : 'read more'}}</a>
                                        </p>
                                      </ng-container>
                                    </div>
                                    <div class="col-2 txt--right" style="margin: auto;cursor: pointer;" (click)="editItem(service, service.positionEdit, sectionIndex, serviceMaskIndex)">
                                      <i class="fas fa-chevron-right txt--lg"></i>
                                    </div>
                                  </div>
                              </li>
                            </ul>
                        </div>

                        <div class="box__content box__content--padding-xs">
                          <button mat-button tabIndex="-1" style="color: #1a73e8;"  (click)="addItem(sectionIndex)">
                            <p  class="m--0" style="padding: 10px 5px; font-weight: 100;"><span style="font-size: 17px;">+</span> Add Item</p>
                          </button>
                        </div>
                      </ng-container>
                    </div>
                </div>


            </div>
        </div>
      </div>

  </ng-container>
