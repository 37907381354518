 <section class="mat-row--padding">
  <ngx-skeleton-loader count="1" [theme]="{ width: '190px', height: '55px'}"></ngx-skeleton-loader>
</section>

<table class="table table--sm table--last-row-border bg--white">
  <thead>
  <tr class="mat-row with-padding">
    <th class="mat-header-cell txt--left"><mat-checkbox></mat-checkbox></th>
    <th class="mat-header-cell txt--left"><strong>Name</strong></th>
    <th class="mat-header-cell txt--left"><strong>Replied/Unreplied</strong></th>
    <th class="mat-header-cell txt--left"><strong>Triggers</strong></th>
    <th class="mat-header-cell txt--left"><strong>Enable</strong></th>
    <th class="mat-header-cell txt--left"><strong>Retroactive</strong></th>
    <th class="mat-header-cell txt--right"><strong>Actions</strong></th>
  </tr>
  </thead>
  <tbody>
  <tr class="mat-row with-padding" *ngFor="let i of [0,1,2]">

    <td class="mat-cell cdk-column-id">
      <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '25px', height: '25px', 'border-radius': '3px', 'margin': '0' }"></ngx-skeleton-loader>
    </td>

    <td class="mat-cell" style="width: 210px">
        <ngx-skeleton-loader count="2" [theme]="{ width: '100px', height: '20px', 'border-radius': '4px', 'display':'block', 'margin-bottom': '5px' }"></ngx-skeleton-loader>
    </td>

    <td class="mat-cell">
      <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '200px', height: '20px', 'border-radius': '10px'}"></ngx-skeleton-loader>
    </td>

    <td class="mat-cell pb--5">
      <ngx-skeleton-loader count="4" [theme]="{ width: '100px', height: '20px', 'display': 'block', 'margin': '5px 0' }"></ngx-skeleton-loader>
    </td>

    <td class="mat-cell cdk-column-date">
      <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '40px', height: '20px', 'border-radius': '10px' }"></ngx-skeleton-loader>
    </td>
    <td class="mat-cell" style="width: 420px">
      <ngx-skeleton-loader count="1" [theme]="{ width: '190px', height: '30px'}"></ngx-skeleton-loader>
    </td>

    <td class="mat-cell txt--right">
      <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '30px', height: '30px', 'margin': '0 10px 0 0', 'border-radius': '5px' }"></ngx-skeleton-loader>
      <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '30px', height: '30px', 'margin': '0', 'border-radius': '5px' }"></ngx-skeleton-loader>
    </td>
  </tr>
  </tbody>
</table>
