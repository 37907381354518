import { __assign, __awaiter, __generator } from "tslib";
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from "@angular/fire/firestore";
import * as _ from 'lodash';
// app
import { environment } from "@environment";
import { DOMAINS } from "../constants/firestore/collections";
import { HEADERS_NO_AUTH } from '../constants/auth';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "@angular/common/http";
var WhiteLabelService = /** @class */ (function () {
    function WhiteLabelService(afs, _http) {
        this.afs = afs;
        this._http = _http;
    }
    WhiteLabelService.prototype._fetchDomainData = function (xdomain) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.afs.collection(DOMAINS).doc(xdomain).get().toPromise()];
                    case 1: return [2 /*return*/, (_a.sent()).data()];
                }
            });
        });
    };
    Object.defineProperty(WhiteLabelService.prototype, "branding", {
        // TODO: Promise returning getter, bad
        get: function () {
            var _this = this;
            return (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._fetchDomainData(this.slugDomain)];
                    case 1: return [2 /*return*/, (_a.sent()).branding];
                }
            }); }); })();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WhiteLabelService.prototype, "apiUrl", {
        // Todo: This is temporary until we implement fetch from DB
        // main-api URL
        get: function () {
            return environment.apiUrl;
        },
        enumerable: true,
        configurable: true
    });
    WhiteLabelService.prototype.getDynamicWhiteLabel = function () {
        return __awaiter(this, void 0, void 0, function () {
            var domainP, domainFs, domain, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        domainP = this._http.get(environment.coreApiUrl + "/domains/" + this.domainName, HEADERS_NO_AUTH).toPromise();
                        return [4 /*yield*/, this.afs.collection(DOMAINS).doc("" + this.slugDomain).get().toPromise()];
                    case 1:
                        domainFs = (_a.sent()).data();
                        domain = { trialDays: 30 };
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, domainP];
                    case 3:
                        domain = (_a.sent()).data;
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _a.sent();
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/, __assign(__assign({}, domainFs), domain)];
                }
            });
        });
    };
    Object.defineProperty(WhiteLabelService.prototype, "domainName", {
        get: function () {
            return this.baseDomain.split(":")[0];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WhiteLabelService.prototype, "baseDomain", {
        get: function () {
            return (_.startsWith(window.location.hostname, 'localhost') ? 'localhost' : window.location.hostname);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WhiteLabelService.prototype, "slugDomain", {
        /**
         * Current domain name in 'xdomain' format (e.g. "app_maplabs_com")
         */
        get: function () {
            return this.domainName.replace(/\./g, '_');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WhiteLabelService.prototype, "apiUrlForGoogle", {
        get: function () {
            var url = environment.apiUrl;
            return url.endsWith('/api') ? url.substring(0, url.length - 4) : url;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WhiteLabelService.prototype, "domainUser", {
        get: function () {
            return "" + window.location.hostname;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WhiteLabelService.prototype, "domain", {
        get: function () {
            return window.location.hostname + ":" + window.location.port;
        },
        enumerable: true,
        configurable: true
    });
    /**
      * Returns the public key used to create add-credit-card tokens configured
      * for the current domain for the Development environment (Stripe test mode)
      */
    WhiteLabelService.prototype.getStripeTestMaplabsPublicKey = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._fetchDomainData('localhost')];
                    case 1: return [2 /*return*/, (_a.sent()).stripe_keys.public_key];
                }
            });
        });
    };
    /**
     * Returns the public key used to create add-credit-card tokens configured
     * for the current domain.
     */
    WhiteLabelService.prototype.getStripePublicKey = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._fetchDomainData('app_maplabs_com')];
                    case 1: 
                    // Always uses the maplabs PK, no matter the WL domain, because billing-api 
                    // runs with the maplabs Stripe key, not the one with the WL Stripe (Connect)
                    // Accounts.
                    return [2 /*return*/, (_a.sent()).stripe_keys.public_key];
                }
            });
        });
    };
    WhiteLabelService.prototype.getStripeConnectAccountId = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.getDynamicWhiteLabel()];
                    case 1: return [2 /*return*/, (_b = (_a = (_c.sent())) === null || _a === void 0 ? void 0 : _a.stripeConnect) === null || _b === void 0 ? void 0 : _b.stripeAccountId];
                }
            });
        });
    };
    Object.defineProperty(WhiteLabelService.prototype, "supportMailURL", {
        get: function () {
            var _a, _b, _c;
            var b = (_a = environment) === null || _a === void 0 ? void 0 : _a.branding;
            return ((_b = b) === null || _b === void 0 ? void 0 : _b.contact_us_link) || "mailto:" + ((_c = b) === null || _c === void 0 ? void 0 : _c.contact_us);
        },
        enumerable: true,
        configurable: true
    });
    WhiteLabelService.prototype._openNewTab = function (link_name, route) {
        return __awaiter(this, void 0, void 0, function () {
            var content, url;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getDynamicWhiteLabel()];
                    case 1:
                        content = (_a.sent()).content;
                        url = _.get(content, link_name);
                        window.open(url || "/text-reader/" + route, '_blank');
                        return [2 /*return*/];
                }
            });
        });
    };
    WhiteLabelService.prototype.goToTermsOfService = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this._openNewTab('terms_service_link', 'terms-and-conditions')];
            });
        });
    };
    WhiteLabelService.prototype.goToPrivacyPolicy = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this._openNewTab('privacy_policy_link', 'privacy-policy')];
            });
        });
    };
    WhiteLabelService.prototype.goToPaymentPolicy = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this._openNewTab('payment_policy_link', 'payment-policy')];
            });
        });
    };
    WhiteLabelService.prototype.goToContactSupport = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                window.open(this.supportMailURL);
                return [2 /*return*/];
            });
        });
    };
    WhiteLabelService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WhiteLabelService_Factory() { return new WhiteLabelService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.HttpClient)); }, token: WhiteLabelService, providedIn: "root" });
    return WhiteLabelService;
}());
export { WhiteLabelService };
