<div class="tag tag--{{ gradeGoogleItem.active=== true ? 'success'  : 'error'}}"
     *ngIf="gradeGoogleItem"
   >
  <span class="tag__icon"><i class="fas fa-{{ (gradeGoogleItem.active === true) ? 'check' : 'times' }}"></i></span>
  <span class="tag__content">
    {{ gradeGoogleItem.name }}
    <mat-chip-list class="ml--10">
      <mat-chip selected disabled color="{{ gradeGoogleItem.active=== true ? 'accent'  : 'warn'}}">{{gradeGoogleItem.points ? gradeGoogleItem.points : 0}}</mat-chip>
    </mat-chip-list>
  </span>
</div>
