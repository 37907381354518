import { EventEmitter, OnInit } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
var ReviewReportsComponent = /** @class */ (function () {
    function ReviewReportsComponent(router, route) {
        this.router = router;
        this.route = route;
        this.reportType = 'performance-review-report';
        this.checked = false;
        // events for custom pagination
        this.goPrev = new EventEmitter();
        this.goNext = new EventEmitter();
        this.goPage = new EventEmitter();
    }
    ReviewReportsComponent.prototype.ngOnInit = function () {
        var _a, _b, _c, _d;
        this.isBoxView = true;
        this.manualPage = 1;
        this.errorMessage = false;
        this.reportType = (_d = (_c = (_b = (_a = this.route) === null || _a === void 0 ? void 0 : _a.snapshot) === null || _b === void 0 ? void 0 : _b.routeConfig) === null || _c === void 0 ? void 0 : _c.path) === null || _d === void 0 ? void 0 : _d.replace('-report', '');
    };
    return ReviewReportsComponent;
}());
export { ReviewReportsComponent };
