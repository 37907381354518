import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hourAmPmSchedule'
})
export class HourAmPmSchedulePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {

    value = value.toUpperCase().replace('AM', '');
    value = value.toUpperCase().replace('PM', '');
    const strHours = value.split(':')[0];
    const strMinutes = value.split(':')[1];
    let hours: number = parseInt(strHours);
    let meridian;
    if (hours == 0) {
      hours = 12;
      meridian = 'am';
    } else if (hours > 12) {
      meridian = 'pm';
      hours = hours - 12;
    } else if (hours == 12) {
      meridian = 'pm'
    }else {
      meridian = 'am'
    }
    return `${hours < 10 ? '0' + hours : hours}:${strMinutes} ${meridian.toUpperCase()}`;
  }
}
