import { __awaiter, __generator } from "tslib";
import { OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { DomainService } from '../../services/domain.service';
import { environment } from '@environment';
import { Router } from '@angular/router';
import { SnackbarService } from '../../services/snackbar.service';
import { WhiteLabelService } from '../../services/white-label.service';
import { EmailerService } from 'src/app/services/emailer.service';
var ForgotPasswordComponent = /** @class */ (function () {
    function ForgotPasswordComponent(domainService, authService, emailerService, router, snack, wl) {
        this.domainService = domainService;
        this.authService = authService;
        this.emailerService = emailerService;
        this.router = router;
        this.snack = snack;
        this.wl = wl;
        this.passwordProgress = false;
        this.forgotForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
        });
    }
    ForgotPasswordComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.authService.afAuth.auth.signOut()];
                    case 1:
                        _a.sent();
                        this.env = environment;
                        return [2 /*return*/];
                }
            });
        });
    };
    ForgotPasswordComponent.prototype.forgotPassword = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        this.passwordProgress = true;
                        return [4 /*yield*/, this.emailerService.sendResetPasswordMail({ email: this.email.value })];
                    case 1:
                        _a.sent();
                        this.router.navigateByUrl("/login");
                        this.snack.openSuccess("A reset password email has been sent to " + this.email.value, 4000);
                        return [3 /*break*/, 4];
                    case 2:
                        e_1 = _a.sent();
                        this.snack.openError("The email " + this.email.value + " is incorrect or not registered.", 4000);
                        return [3 /*break*/, 4];
                    case 3:
                        this.passwordProgress = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(ForgotPasswordComponent.prototype, "email", {
        get: function () {
            return this.forgotForm.get('email');
        },
        enumerable: true,
        configurable: true
    });
    ForgotPasswordComponent.prototype.goToLogin = function () {
        this.router.navigate(['/login']);
    };
    ForgotPasswordComponent.prototype.goToTermsOfService = function () {
        this.wl.goToTermsOfService();
    };
    ForgotPasswordComponent.prototype.goToPrivacyPolicy = function () {
        this.wl.goToPrivacyPolicy();
    };
    ForgotPasswordComponent.prototype.goToPaymentPolicy = function () {
        this.wl.goToPaymentPolicy();
    };
    return ForgotPasswordComponent;
}());
export { ForgotPasswordComponent };
