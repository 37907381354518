<div class="dialog__header">
    <h2 mat-dialog-title class="m--0 txt--xl"><strong>Store Code</strong></h2>
</div>
<div class="dialog__content" mat-dialog-content>
    <div class="dialog-row pb--0">
        <mat-form-field class="input-field">
            <input matInput placeholder="Store Code" [(ngModel)]="data.storeCode" [value]="data.storeCode" maxlength="64" name="storeCode">
            <mat-hint align="end">{{data.storeCode?.length ? data.storeCode?.length : 0}} / 64</mat-hint>
        </mat-form-field>
        <p *ngIf="hasAllCharacters()" class="txt--red">You exceeded the number of characters</p>
    </div>

</div>
<div mat-dialog-actions class="dialog__footer">
    <span class="alternate-theme d-inline-block mr--10">
        <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
    </span>
    <button mat-flat-button (click)="apply()"  tabIndex="-1" color="accent" class="btn btn--action">Apply</button>
</div>
