import {Component, Input, OnInit} from '@angular/core';
import {DecimalPipe} from '@angular/common';

import {Colors} from 'ng2-charts';

import {ChartDataSets, ChartOptions} from 'chart.js';
import {COLORS_CHART, COLORS_CHART_FULL, } from '../../../constants/chart-color';
import { tooltipCustomWeek, tooltipCustom, tooltipCustomGrade, tooltipHorizontalBar } from '../../../constants/tooltip';
import {DataPicker} from '../../../constants/data-picker';

@Component({
  selector: 'app-chart-line',
  templateUrl: './chart-line.component.html',
  styleUrls: ['./chart-line.component.scss']
})
export class ChartLineComponent implements OnInit {
  @Input() chartType: string;
  @Input() isHorizontal: boolean = false;
  @Input() dataset: ChartDataSets[];
  @Input() labels: string[];
  @Input() showLegend: boolean;
  @Input() disabledLengend: boolean = false;
  @Input() yDecimals: number;
  @Input() max: number = null;
  @Input() commaNumbers: boolean;
  @Input() centerLegend = true;
  @Input() type: string;
  @Input() grade: boolean;
  @Input() dataPicker: DataPicker;
  @Input() subLabels: string[] = [];
  @Input() title: string;
  @Input() noDecimals: boolean;
  @Input() colors: Colors[] = [];

  constructor() {
    this.showLegend = true;
    this.chartType = 'line';
  }

  // Charts Line Options
  barChartLegend = false;
  barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        ticks: {
          min: 1, // Change this
          max: 10,
          stepSize: 1,
          fontColor : '#FFF',
          fontSize : 20,
        }
      }],
    },
    tooltips: {
      // Disable the on-canvas tooltip
      enabled: false,
      intersect: true,
      mode: 'index',
      position: 'nearest',
    }
  };
  numberWithCommas(x: number) {
    const pipe = new DecimalPipe('EN-US');
    return pipe.transform(x, '1.0-0');
  }
  ngOnInit() {
      // tslint:disable-next-line: max-line-length
    this.barChartOptions.tooltips.custom = this.grade ? tooltipCustomGrade : (this.dataPicker && this.dataPicker.aggregation === 'Week') ? tooltipCustomWeek : this.chartType == 'horizontalBar' ? tooltipHorizontalBar : tooltipCustom;
    this.colors = (this.colors.length ? this.colors : (this.type !== 'COLORS-FULL') ? COLORS_CHART : COLORS_CHART_FULL);
    if (this.yDecimals > 0 || this.commaNumbers) {
      this.barChartOptions.scales = {
        yAxes: [{
          ticks: {
            min: 0,
            beginAtZero: false,
            callback: (value, index, values) => {
              let v = value;
              if (value !== 0) {
                const i = Math.pow(100, this.yDecimals);
                if (this.yDecimals > 0) {
                  v = Math.round(value * i) / i;
                }
                if (this.commaNumbers) {
                  v = this.numberWithCommas(v);
                }
                if (this.noDecimals) {
                  if (value % 1 !== 0) return;
                }
                return v;
              }
            }
          }
        }]
      };
      if (this.max) {
        this.barChartOptions.scales.yAxes[0].ticks.max = this.max;
      }
    } else {
      this.barChartOptions.scales = {
        yAxes: [{
          id: 'left',
          type: 'linear',
          position: 'left',
          ticks: {
            callback: (value: any) => {
              if (value !== 0) {
                if (value % 1 === 0) {
                  return value;
                }
              }
            }
          }
        }]
      };
    }


    if (this.subLabels && this.subLabels.length > 0) {
      this.barChartOptions.tooltips.callbacks = {
        label: (tooltipItem, data) => {
          let resumeDataPicker = '';
          if (this.dataPicker.aggregation === 'Week') { resumeDataPicker = 'Week Of '; }

          if (tooltipItem.datasetIndex === 0) {
              if (this.dataPicker.aggregation === 'Day') {
                resumeDataPicker = this.calculateMessage(tooltipItem.label, 'en-us');
              }
              // tslint:disable-next-line: max-line-length
              return `${data.datasets[tooltipItem.datasetIndex].label} (${resumeDataPicker}${tooltipItem.label}): ${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]}`;
            } else {
              if (this.dataPicker.aggregation === 'Day') {
                resumeDataPicker = this.calculateMessage(this.subLabels[tooltipItem.index], 'en-us');
              }
              // tslint:disable-next-line: max-line-length
              return `${data.datasets[tooltipItem.datasetIndex].label} (${resumeDataPicker}${this.subLabels[tooltipItem.index]}): ${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]}`;
            }
        }
      };
    }

    if (this.chartType == 'horizontalBar') {
      this.barChartOptions.scales.xAxes = this.barChartOptions.scales.yAxes;
      delete this.barChartOptions.scales.yAxes;
      this.colors = this.type == 'COLORS-FULL' ? this.colors : COLORS_CHART_FULL;
    }
  }

  calculateMessage(dateStr: string, locale: string): string {
    const date = new Date(dateStr);
    return `${date.toLocaleDateString(locale, { weekday: 'long' })} `;
  }

  isCheckboxDisabled(actionChartLine, index) {
    const activeDatasets = actionChartLine.datasets.filter((_, i) => !actionChartLine.isDatasetHidden(i));
    return activeDatasets.length === 1 && !actionChartLine.isDatasetHidden(index);
  }

}