<div class="dialog__header">
    <h2 mat-dialog-title class="m--0 txt--xl"><strong>Edit Product Collection</strong></h2>
</div>
<div class="dialog__content" mat-dialog-content>
    <div class="dialog-row pb--0">
        <mat-form-field class="input-field">
            <input matInput placeholder="Product Collection Name" [(ngModel)]="productName" name="productName">
            <mat-error *ngIf="productCollectionNameInvalid"><p>Collection Name is required</p></mat-error>
        </mat-form-field>
    </div>
    <div class="dialog-row p--0">
        <mat-form-field class="input-field">
            <input matInput placeholder="Product Collection Description" [(ngModel)]="productDescription" name="productDescription">
        </mat-form-field>
    </div>

</div>
<div mat-dialog-actions class="dialog__footer">
    <span class="alternate-theme d-inline-block mr--10">
        <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
    </span>
    <button mat-flat-button (click)="apply()"  tabIndex="-1" color="accent" class="btn btn--action">Apply</button>
</div>
