// TODO: Refactor against admin-frontend/http-auth.interceptor.ts
import { __awaiter, __generator, __values } from "tslib";
import { from } from "rxjs";
import { single } from "rxjs/operators";
// app
import { AuthProxyService } from "../services/auth.proxy.service";
var HttpAuthInterceptor = /** @class */ (function () {
    function HttpAuthInterceptor(authProxyService) {
        this.authProxyService = authProxyService;
        this.currentRefresh = null;
    }
    HttpAuthInterceptor.prototype.intercept = function (request, next) {
        //return defer(() => this.doRequest(request, next))
        return from(this.doRequest(request, next)).pipe(single());
    };
    HttpAuthInterceptor.prototype.doRequest = function (request, next) {
        return __awaiter(this, void 0, void 0, function () {
            var manageAuth, refreshDone, headersOrig, headers, authHeaders, _a, _b, k, error_1, refreshError_1;
            var e_1, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        manageAuth = true;
                        if (request.headers.has('X-Token-Type')) {
                            manageAuth = request.headers.get('X-Token-Type') !== 'NO_AUTH';
                            // X-Token-Type is only for client-side bookeeping, don't send it to the wire
                            request = request.clone({ headers: request.headers.delete('X-Token-Type') });
                        }
                        refreshDone = false;
                        headersOrig = request.headers;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 5, , 14]);
                        if (!manageAuth) return [3 /*break*/, 3];
                        headers = headersOrig;
                        return [4 /*yield*/, this.authProxyService.authHeaders()
                            // console.debug('authHeaders', authHeaders)
                        ];
                    case 2:
                        authHeaders = _d.sent();
                        try {
                            // console.debug('authHeaders', authHeaders)
                            for (_a = (e_1 = void 0, __values(authHeaders.headers.keys())), _b = _a.next(); !_b.done; _b = _a.next()) {
                                k = _b.value;
                                if (!headers.has(k) || k === 'authorization')
                                    headers = headers.set(k, authHeaders.headers.get(k));
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (_b && !_b.done && (_c = _a.return)) _c.call(_a);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        // console.debug('requestHeaders', headers)
                        request = request.clone({ headers: headers });
                        _d.label = 3;
                    case 3: return [4 /*yield*/, next.handle(request).toPromise()];
                    case 4: 
                    // console.debug('request', request)
                    // NOTE: request is sent using withCredentials=false, so Authorization
                    // headers and cookies won't be sent to non-origin domains
                    return [2 /*return*/, _d.sent()];
                    case 5:
                        error_1 = _d.sent();
                        if (!(error_1.error instanceof ErrorEvent)) return [3 /*break*/, 6];
                        // client-side error
                        // TODO: recheck if this can happen here
                        throw {
                            status: 901,
                            message: "Error: " + error_1.error.message
                        };
                    case 6:
                        if (!(error_1.status === 401 && manageAuth)) return [3 /*break*/, 12];
                        _d.label = 7;
                    case 7:
                        _d.trys.push([7, 9, , 11]);
                        if (refreshDone)
                            throw 'Refresh already tried';
                        // Coalesce multiple concurrent refresh tries to max single one
                        if (!this.currentRefresh)
                            this.currentRefresh = this.authProxyService.forceAuthRefresh();
                        return [4 /*yield*/, this.currentRefresh];
                    case 8:
                        _d.sent();
                        this.currentRefresh = null;
                        return [3 /*break*/, 11];
                    case 9:
                        refreshError_1 = _d.sent();
                        console.log('Error refreshing token', refreshError_1);
                        this.currentRefresh = null;
                        // Can't refresh the token, logout
                        return [4 /*yield*/, this.authProxyService.signOut()];
                    case 10:
                        // Can't refresh the token, logout
                        _d.sent();
                        throw {
                            status: error_1.status,
                            message: "Error Code: " + error_1.status + "\nMessage: " + error_1.message,
                            error: error_1.error
                        };
                    case 11:
                        // The request will be updated with the refreshed accsss token and retried
                        // one time again.
                        refreshDone = true;
                        return [3 /*break*/, 13];
                    case 12: throw {
                        status: error_1.status,
                        message: "Error Code: " + error_1.status + "\nMessage: " + error_1.message,
                        error: error_1.error
                    };
                    case 13: return [3 /*break*/, 14];
                    case 14: return [3 /*break*/, 1];
                    case 15: return [2 /*return*/];
                }
            });
        });
    };
    return HttpAuthInterceptor;
}());
export { HttpAuthInterceptor };
// export const AuthInterceptorProvider = {
//     provide: HTTP_INTERCEPTORS,
//     useClass: HttpAuthInterceptor,
//     multi: true
// };
