import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { AuthService } from '../../services/auth.service';
import { DomainService } from '../../services/domain.service';
import { environment } from '@environment';
import { Router } from '@angular/router';
import { SnackbarService } from '../../services/snackbar.service';
import { WhiteLabelService } from '../../services/white-label.service';

import * as _ from 'lodash';
import { EmailerService } from 'src/app/services/emailer.service';

@Component({
  selector: 'app-login',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotForm: FormGroup;
  passwordProgress = false;
  hide: boolean;
  env;

  constructor(
    public domainService: DomainService,
    private authService: AuthService,
    private emailerService: EmailerService,
    private router: Router,
    private snack: SnackbarService,
    public wl: WhiteLabelService
  ) {
    this.forgotForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  async ngOnInit() {
    await this.authService.afAuth.auth.signOut();
    this.env = environment;
  }

  async forgotPassword() : Promise<void> {
    try {
      this.passwordProgress = true;
      await this.emailerService.sendResetPasswordMail({ email: this.email.value })
      this.router.navigateByUrl(`/login`);
      this.snack.openSuccess(`A reset password email has been sent to ${this.email.value}`, 4000);
    } catch(e) {
      this.snack.openError(`The email ${this.email.value} is incorrect or not registered.`, 4000);
    } finally {
      this.passwordProgress = false;
    }
  }


  get email() {
    return this.forgotForm.get('email');
  }

  goToLogin(): void {
    this.router.navigate(['/login'])
  }

  goToTermsOfService(): void {
    this.wl.goToTermsOfService()
  }

  goToPrivacyPolicy(): void {
    this.wl.goToPrivacyPolicy()
  }

  goToPaymentPolicy(): void {
    this.wl.goToPaymentPolicy()
  }


}
