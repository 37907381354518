import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ISubscription } from '../../constants/subscription';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-modal-get-addon',
  templateUrl: './modal-get-addon.component.html',
  styleUrls: ['./modal-get-addon.component.scss']
})
export class ModalGetAddOn {
    subscription$: Observable<ISubscription>;
    subscription: ISubscription;
    public price: number;
    constructor(
        public dialogRef: MatDialogRef<ModalGetAddOn>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _auth: AuthService,
        private _router: Router,
        
    ){
        this.subscription$ = this._auth.subscription$;
        this.subscription$.subscribe((subscription) => {
            this.subscription = subscription
            this.price = this.subscription?.pricing?.pkg_bulk_actions?.tiers[2]?.price
        });

     }

    public getAddOn() : void {
        this.dialogRef.close()
        // commented until '/plan' allows users to buy add-ons directly
        // this._router.navigate(['/settings/billing'])

        const email = 'sales@maplabs.com';
        const subject = encodeURIComponent('Add-On Request');
        const body = encodeURIComponent('Hello, I would like to purchase the Bulk Actions Add-on. Thank you.');
        const mailtoLink = `mailto:${email}?subject=${subject}&body=${body}`;
        window.location.href = mailtoLink;
    }
}