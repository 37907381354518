import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-grade-card',
  templateUrl: './grade-card.component.html',
  styleUrls: ['./grade-card.component.scss']
})
export class GradeCardComponent implements OnInit {
  @Input() gradeSection: { name: string, total: number, max: number, items: any[] };
  @Input() order: string;
  @Input() information:string;

  constructor() {
  }

  ngOnInit() {
  }


  countPercent(total, max) {
    return (((total || 0) / (max || 1)) * 100).toFixed(2);
  }
}
