<div class="dialog__header" *ngIf="!bulk">
  <h2 mat-dialog-title class="m--0 txt--xl"><strong>{{type|titlecase}}</strong></h2>
</div>
<div class="dialog__content" mat-dialog-content [style.height]="bulk ? '300px' : '100%'">

  <div *ngIf="error != {} && error_details?.length > 0">
    <app-error-message [error]="error" [error_details]="error_details"></app-error-message>
  </div>

  <ng-container *ngIf="serviceList">
    <div class="dialog-row" *ngFor="let category of dataSource; let sectionIndex  = index">
      <div class="box box--shadow" [ngStyle]="{'border-radius': '8px'}">
        <ng-container *ngIf="category">
          <div class="box__header box__header--xs justify-content-between align-items-center" [ngStyle]="{'background-color': '#f1f3f4', 'border-radius': '8px 8px 0px 0px'}">
            <!-- <p class="txt--lg m--0"><strong>{{sectionLabel?.displayName}}</strong></p> --> 
            <p class="txt--lg m--0"><strong>{{category?.displayName}}</strong></p>
            <small >{{category?.principal ? 'Main Category' : '' }}</small>
          </div>

          <div >
            
              <ul class="list-group list-group-flush">
                <li class="list-group-item border-bottom" style="margin: 5px 0;" *ngFor="let service of category?.services; let serviceMaskIndex  = index">
                    <div class="row box__content box__content--padding-xs w--100">
                      <div class="col-10">
                        <h3 class="txt--left" style="margin: 5px 0;cursor: pointer;" (click)="editItem(service, service?.positionEdit, sectionIndex, serviceMaskIndex); $event.stopPropagation">{{service?.displayName}}</h3>
                        <h5 class="txt--left" style="margin: 5px 0;cursor: pointer;" (click)="editItem(service, service?.positionEdit, sectionIndex, serviceMaskIndex); $event.stopPropagation">{{service?.price?.type === 'noPrice' ? '' : service?.price?.type === 'free' ? 'Free' : (service?.price?.units | currency)}}</h5>
                        <ng-container *ngIf="service?.description">
                          <p class="txt--left w--100" style="cursor: pointer;" *ngIf="service?.description.length <= 60" (click)="editItem(service, service?.positionEdit, sectionIndex, serviceMaskIndex); $event.stopPropagation">{{ service?.description}}</p>
                          <p class="txt--left txt--black mb--10" style="white-space: normal; overflow-wrap: break-word;" *ngIf="service?.description.length > 60">
                            {{(showMoreMessage && showMoreMessageIndex === serviceMaskIndex) ? service?.description :  service?.description | limitTo: '60'}}
                            <a (click)="toggleMessage(serviceMaskIndex)" class="btn txt--underline txt--lightblue"
                               *ngIf="service?.description?.length > 60">{{ showMoreMessage && showMoreMessageIndex === serviceMaskIndex ? 'read less' : 'read more'}}</a>
                          </p>
                        </ng-container>
                      </div>
                      <div class="col-2 txt--right" style="margin: auto;cursor: pointer;" (click)="editItem(service, service?.positionEdit, sectionIndex, serviceMaskIndex)">
                        <i class="fas fa-chevron-right txt--lg"></i>
                      </div>
                    </div>
                </li>
              </ul>
          </div>

          <div class="box__content box__content--padding-xs">
            <button mat-button tabIndex="-1" style="color: #1a73e8;" [disabled]="showOnly"  (click)="addItem(sectionIndex)">
              <p  class="m--0" style="padding: 10px 5px; font-weight: 100;"><span style="font-size: 17px;">+</span> Add Item</p>
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <!--<div class="dialog-row">
    <div class="box box--shadow">
      <button mat-button class="p--0 w--100" tabIndex="-1" (click)="addSection()">
        <div class="box__header box__header--xs"><strong>Add Section</strong></div>
      </button>
    </div>
  </div>-->

</div>
<div mat-dialog-actions class="dialog__footer" *ngIf="!bulk">
    <span class="alternate-theme d-inline-block mr--10">
        <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
    </span>
  <button mat-flat-button (click)="apply()" tabIndex="-1" color="accent" class="btn btn--action" *ngIf="!showOnly" [disabled]="loading">Apply <i *ngIf="loading" class="fas fa-spinner fa-pulse"></i></button>
</div>
