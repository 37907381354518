<footer class="footer" 
  [ngClass]="{'custom-position': customPosition}" 
  *ngIf="footerDefault"
>
  <ul class="footer__nav">
    <li> <span> &copy; {{ env?.branding?.company_name }}  {{date | date:'yyyy'}}</span> </li>
    <li><a (click)="goToTermsOfService()">Terms and Conditions</a></li>
    <li><a (click)="goToPrivacyPolicy()">Privacy Policy</a></li>
    <li><a (click)="goToPaymentPolicy()">Payment Policy</a></li>
  </ul>
  <a (click)="handleContactUs()">Help</a>
</footer>
