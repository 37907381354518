<div class="dialog__header txt--center">
    <h2 mat-dialog-title class="m--0 txt--xl"><strong>Configuration</strong></h2>
</div>
<div class="dialog__content" mat-dialog-content>
    <div class="dialog-row pb--0">
        <mat-form-field class="input-field input-field--uppercase">
            <input matInput placeholder="Iframe Keyword report">
        </mat-form-field>
    </div>

</div>
<div mat-dialog-actions class="dialog__footer">
    <span class="alternate-theme d-inline-block mr--10">
        <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
    </span>
    <button mat-flat-button tabIndex="-1" color="accent" (click)="save()" class="btn btn--action">Save</button>
</div>
