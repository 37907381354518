<div class="dialog__header">
  <h2 mat-dialog-title class="m--0 txt--xl"><strong>Products</strong></h2>
</div>
<div class="dialog__content" mat-dialog-content>

  <ng-container *ngIf="priceLists?.length">
    <div class="dialog-row" *ngFor="let product of priceLists; let i = index">
      <div class="box box--shadow">
        <button
          mat-button
          class="box__header box__header--flex w--100"
          tabIndex="-1"
          (click)="editProduct(i)"
        >
          <div class="w--100">
            <strong>{{ (product?.labels)[0]?.displayName }}</strong>
            <span
              class="d-block txt--sm txt--lightgray"
              *ngIf="product?.description"
              >{{ product?.description }}</span
            >
          </div>
          <i class="far fa-ellipsis-v txt--lg"></i>
        </button>

        <ng-container *ngIf="product.items">
          <button
            mat-button
            *ngFor="let item of product.items; let j = index"
            tabIndex="-1"
            (click)="editProductItem(i, j)"
            class="box__header w--100"
          >
            <div class="row align-items-center">
              <div class="col-7 txt--left">
                <span class="d-block">{{
                  (item?.labels)[0]?.displayName
                }}</span>
                <span
                  class="d-block txt--sm txt--lightgray"
                  *ngIf="(item?.labels)[0]?.description"
                  >{{ (item?.labels)[0]?.description | ellipsis : 50 }}</span
                >
              </div>
              <div class="col-3">
                <ng-container *ngIf="item?.price?.units"> 
                  <ng-container *ngIf="item?.price?.currencyCode; else notCurrencyCode">
                    <span>{{ item?.price?.units | currency:item.price?.currencyCode  }}</span> - {{ item.price?.currencyCode }}
                  </ng-container>
                  <ng-template #notCurrencyCode>
                    <span>$ {{ item?.price?.units  }}</span>
                  </ng-template>
                </ng-container>
              </div>
              <div class="col-2 txt--right">
                <i class="far fa-ellipsis-v txt--lg"></i>
              </div>
            </div>
          </button>
        </ng-container>

        <button
        class="text-center"
          [class.border-top]="product.items"
          mat-button
          class="box__header box__header--flex w--100"
          tabIndex="-1"
          (click)="addProductItem(i)"
        >
          <strong>Add Product Item</strong>
        </button>
      </div>
    </div>
  </ng-container>

  <div class="dialog-row">
    <div class="box box--shadow">
      <button
        mat-button
        class="p--0 w--100"
        tabIndex="-1"
        (click)="addSectionProduct()"
      >
        <div class="box__header box__header--xs"><strong>Add Section</strong></div>
      </button>
    </div>
  </div>
</div>
<div mat-dialog-actions class="dialog__footer">
  <span class="alternate-theme d-inline-block mr--10">

    <button
      mat-button
      tabIndex="-1"
      color="primary"
      mat-dialog-close
      class="btn btn-cancel"
    >Cancel</button>
  </span>
  <button
    mat-flat-button
    (click)="apply()"
    [disabled]="!touchEdit"
    tabIndex="-1"
    color="accent"
    class="btn btn--action">Apply</button>
</div>
