<div *ngFor="let link of actionLinks">
    <div class="box__item box__item--editable" [class.align-items-start]="actionLinks" [class.pb--0]="isUnderReview">
        <div class="d-flex w--100" [ngClass]="!!actionLinks.length ? 'align-items-start' : 'align-items-center'">
            <div class="icon icon--rounded icon--gray mr--20 d-block">
                <i class="fas fa-link"></i>
            </div>
  
            <div class="d-block w--100" [class.txt--medium-gray]="isUnderReview">
                <h4 class="w--100"><strong>{{link?.displayName | titlecase}}</strong></h4>
                <p class="w--100 wrap" *ngIf="!link?.placeActionLinks?.length">Add {{link?.displayName | titlecase}}</p>
                
                <ng-container *ngIf="link?.placeActionLinks?.length">
                    <div class="w--100 links-container" [class.mb--12]="!url?.isPreferred" *ngFor="let url of link?.placeActionLinks">
                        <p class="w--80 display-in-bl m--0 wrap" [class.w--75]="!isEdit"><span [outerHTML]="url?.uri"></span></p>
                        <p *ngIf="url?.isPreferred" class="selected-label ml--15 display-in-bl pull--right">Preferred</p>
                    </div>
                </ng-container>   
            </div>
        </div>

        <button mat-button (click)="openActionLinksDialog(link)" class="btn btn-icon" *ngIf="isEdit">
            <i class="fas fa-pencil m--0"></i>
        </button>
    </div>

    

    <div *ngIf="isUnderReview" class="txt-pendingMask">
        <span class="under-review" role="alert">Under review</span>
    </div>
</div>
  