import { __assign, __awaiter, __generator, __read, __spread } from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { HttpClient } from '@angular/common/http';
import { map, switchMap } from 'rxjs/operators';
import moment from 'moment';
import firebase from 'firebase/app';
// appp
import { AuthService } from './auth.service';
import { FirestoreService } from './firestore.service';
import { ZuluToDatePipe } from '../pipes/zulu-to-date.pipe';
import { FIRESTORE_AUTOMATED_REPORTS, GROUPS, REPORTS } from '../constants/firestore/collections';
import { environment } from "@environment";
import { InsightsService } from './insights.service';
import { SnackbarService } from './snackbar.service';
import { DatesService } from './dates.service';
import { LocationService } from './location.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "./auth.service";
import * as i4 from "./firestore.service";
import * as i5 from "./insights.service";
import * as i6 from "@angular/fire/storage";
import * as i7 from "./snackbar.service";
import * as i8 from "./dates.service";
import * as i9 from "./location.service";
var ReportService = /** @class */ (function () {
    function ReportService(http, afs, auth, firestoreS, insightS, afsStorage, snackS, dateS, locationS) {
        this.http = http;
        this.afs = afs;
        this.auth = auth;
        this.firestoreS = firestoreS;
        this.insightS = insightS;
        this.afsStorage = afsStorage;
        this.snackS = snackS;
        this.dateS = dateS;
        this.locationS = locationS;
    }
    // api operations
    ReportService.prototype.getRef = function (gid) {
        return this.afs.collection(GROUPS).doc(gid).collection(REPORTS);
    };
    ReportService.prototype.getAutomatedRef = function (gid) {
        return this.afs.collection(GROUPS).doc(gid).collection(FIRESTORE_AUTOMATED_REPORTS);
    };
    /**
     * this method get hash and token for public report
     * @return result.hash and result.token
     */
    ReportService.prototype.getHash = function () {
        if (!this.auth.session) {
            return;
        }
        return this.http.get(environment.apiUrl + "/users/hash");
    };
    // CRUD firestore operations
    /**
     * this method get doc by docId
     */
    ReportService.prototype.get = function (gid, docId) {
        return this.afs.collection(GROUPS + "/" + gid + "/" + REPORTS).doc(docId).valueChanges();
    };
    ReportService.prototype.getAutomated = function (gid, docId) {
        return this.afs.collection(GROUPS).doc(gid).collection(FIRESTORE_AUTOMATED_REPORTS).doc(docId).valueChanges();
    };
    /**
     * this method get doc by docId
     */
    ReportService.prototype.getById = function (gid, docId) {
        return this.afs.collection(GROUPS).doc(gid).collection(REPORTS).doc(docId);
    };
    /**
     * search by uid and gid
     */
    ReportService.prototype.getByGIdAndType = function (gid, type, pageable, next, prev, searchKeywords, order, direction) {
        if (pageable === void 0) { pageable = { size: 10, page: 1 }; }
        if (order === void 0) { order = 'createdAt'; }
        if (direction === void 0) { direction = 'desc'; }
        return this.firestoreS.paginateValuesReports(GROUPS + "/" + gid + "/" + REPORTS, order, direction, gid, type, pageable, next, prev, searchKeywords);
    };
    ReportService.prototype.getByGIdAndTypeCount = function (gid, reportType) {
        var collectionRef = this.afs.collection(GROUPS + "/" + gid + "/" + REPORTS, function (query) { return query.where('gid', '==', gid)
            .where('reportType', '==', reportType); });
        return collectionRef.get();
    };
    ReportService.prototype.getLegacy = function (gid, type) {
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.afs.collection(GROUPS + "/" + gid + "/" + REPORTS, function (ref) {
                            return ref.where('gid', '==', gid)
                                .where('reportType', '==', type);
                        }).get().toPromise()];
                    case 1:
                        res = _a.sent();
                        // console.log(res.forEach(doc => console.log(doc.data())))
                        return [2 /*return*/, res.docs.length];
                }
            });
        });
    };
    ReportService.prototype.getReportsFromMongo = function (gid, reportType, paginate, sortBy, sortOrder, reportName) {
        if (reportName === void 0) { reportName = null; }
        var data = {
            gid: gid,
            reportType: reportType,
            size: paginate.size,
            page: paginate.page,
            sortBy: sortBy,
            sortOrder: sortOrder,
            reportName: reportName
        };
        return this.http.post(environment.apiUrl + "/v3/reports/list", data);
    };
    ReportService.prototype.saveReportInMongo = function (report) {
        return this.http.post(environment.apiUrl + "/v3/reports/create", report);
    };
    ReportService.prototype.updateReportsInMongo = function (reportId, report) {
        var data = { updatedData: report };
        report.accounts = this.locationS.deleteServiceArea(report.accounts);
        return this.http.post(environment.apiUrl + "/v3/reports/" + reportId + "/edit", data);
    };
    ReportService.prototype.deleteFromMongo = function (reportId) {
        return this.http.delete(environment.apiUrl + "/v3/reports/" + reportId + "/delete");
    };
    ReportService.prototype.getSearchKeywordsBrands = function (accountId, locationId, gid, reportId, startDate, endDate, period) {
        if (accountId === void 0) { accountId = null; }
        if (locationId === void 0) { locationId = null; }
        if (reportId === void 0) { reportId = null; }
        if (startDate === void 0) { startDate = null; }
        if (endDate === void 0) { endDate = null; }
        var _a;
        period = period ? (_a = period) === null || _a === void 0 ? void 0 : _a.replace('Comparison', '') : null;
        var data = {
            "accountId": accountId,
            "locationId": locationId,
            "reportId": reportId,
            "gid": gid,
            "startDate": startDate,
            "endDate": endDate,
            "period": period
        };
        return this.http.post(environment.apiUrl + "/v2/locations/keyword-brands", data);
    };
    ReportService.prototype.getPerformanceRollupReports = function (reportId, viewBy, startDate, endDate) {
        if (viewBy === void 0) { viewBy = 'month'; }
        if (startDate === void 0) { startDate = null; }
        if (endDate === void 0) { endDate = null; }
        var data = {
            reportId: reportId,
            viewBy: viewBy.toLocaleLowerCase(),
            startDate: startDate,
            endDate: endDate,
        };
        return this.http.post(environment.apiUrl + "/v3/reports/insights", data);
    };
    ReportService.prototype.validateDateFormat = function (date) {
        var _a;
        var dateParts = ((_a = date) === null || _a === void 0 ? void 0 : _a.split('-')) || [];
        var year = dateParts[0].padStart(4, '0');
        var month = dateParts[1].padStart(2, '0');
        var day = dateParts[2].padStart(2, '0');
        return year + "-" + month + "-" + day + " 00:00:00";
    };
    ReportService.prototype.resumeExportCsv = function (gid, reportType, sort, dataPicker, filename) {
        var _a, _b, _c, _d, _e, _f;
        var startDatetimeMoment = moment(dataPicker.range.start);
        var endDatetimeMoment = moment(dataPicker.range.end);
        var startDatetime = !startDatetimeMoment.isValid() ? null : startDatetimeMoment['_i'].includes('T') ? (startDatetimeMoment['_i'].split('T')[0]).replaceAll('/', '-') : (startDatetimeMoment['_i'].split(' ')[0]).replaceAll('/', '-');
        var endDatetime = !endDatetimeMoment.isValid() ? null : endDatetimeMoment['_i'].includes('T') ? (endDatetimeMoment['_i'].split('T')[0]).replaceAll('/', '-') : (endDatetimeMoment['_i'].split(' ')[0]).replaceAll('/', '-');
        if (!!startDatetime) {
            startDatetime = this.validateDateFormat(startDatetime);
        }
        if (!!endDatetime) {
            endDatetime = this.validateDateFormat(endDatetime);
        }
        var accounts = (_b = (_a = dataPicker) === null || _a === void 0 ? void 0 : _a.locations) === null || _b === void 0 ? void 0 : _b.map(function (l) { var _a; return (_a = l) === null || _a === void 0 ? void 0 : _a.accountId; });
        accounts = __spread(new Set(accounts));
        var locations = (_d = (_c = dataPicker) === null || _c === void 0 ? void 0 : _c.locations) === null || _d === void 0 ? void 0 : _d.map(function (l) { var _a; return (_a = l) === null || _a === void 0 ? void 0 : _a.locationId; });
        var data = {
            "gids": [gid],
            "accountIds": accounts,
            "locationIds": locations,
            "filter": {
                "startDate": startDatetime,
                "endDate": endDatetime
            },
            "type": reportType,
            "filename": filename
        };
        if (sort) {
            data['sortDesc'] = ((_e = sort) === null || _e === void 0 ? void 0 : _e.direction) == 'asc' ? false : true;
            data['sortKey'] = (_f = sort) === null || _f === void 0 ? void 0 : _f.sortBy;
        }
        return this.http.post(environment.coreApiUrl + "/export/multilocation", data);
    };
    ReportService.prototype.getTableData = function (gid, reportType, pageable, sort, dataPicker) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
        var startDatetime, endDatetime, endDatetimeMoment, startDatetimeMoment = null;
        if (dataPicker.range.start) {
            startDatetimeMoment = moment(dataPicker.range.start);
            startDatetime = !startDatetimeMoment.isValid() ? null : ((_a = startDatetimeMoment['_i']) === null || _a === void 0 ? void 0 : _a.includes('T')) ? (_c = ((_b = startDatetimeMoment['_i']) === null || _b === void 0 ? void 0 : _b.split('T')[0])) === null || _c === void 0 ? void 0 : _c.replaceAll('/', '-') : (_e = ((_d = startDatetimeMoment['_i']) === null || _d === void 0 ? void 0 : _d.split(' ')[0])) === null || _e === void 0 ? void 0 : _e.replaceAll('/', '-');
            startDatetime = startDatetime + " 00:00:00";
        }
        if (dataPicker.range.end) {
            endDatetimeMoment = moment(dataPicker.range.end);
            endDatetime = !endDatetimeMoment.isValid() ? null : ((_f = endDatetimeMoment['_i']) === null || _f === void 0 ? void 0 : _f.includes('T')) ? (_h = ((_g = endDatetimeMoment['_i']) === null || _g === void 0 ? void 0 : _g.split('T')[0])) === null || _h === void 0 ? void 0 : _h.replaceAll('/', '-') : (_k = ((_j = endDatetimeMoment['_i']) === null || _j === void 0 ? void 0 : _j.split(' ')[0])) === null || _k === void 0 ? void 0 : _k.replaceAll('/', '-');
            endDatetime = endDatetime + " 00:00:00";
        }
        var accounts = (_m = (_l = dataPicker) === null || _l === void 0 ? void 0 : _l.locations) === null || _m === void 0 ? void 0 : _m.map(function (l) { var _a; return (_a = l) === null || _a === void 0 ? void 0 : _a.accountId; });
        accounts = __spread(new Set(accounts));
        var locations = (_p = (_o = dataPicker) === null || _o === void 0 ? void 0 : _o.locations) === null || _p === void 0 ? void 0 : _p.map(function (l) { var _a; return (_a = l) === null || _a === void 0 ? void 0 : _a.locationId; });
        var data = {
            "gids": [gid],
            "accountIds": accounts,
            "locationIds": locations,
            "filter": {
                "startDate": startDatetime,
                "endDate": endDatetime
            },
            "type": reportType,
            "page": pageable.page,
            "pageSize": pageable.size,
        };
        if (sort) {
            data['sortDesc'] = ((_q = sort) === null || _q === void 0 ? void 0 : _q.direction) == 'asc' ? false : true;
            data['sortKey'] = (_r = sort) === null || _r === void 0 ? void 0 : _r.sortBy;
        }
        return this.http.post(environment.coreApiUrl + "/reports/multilocation", data);
    };
    ReportService.prototype.getReportsByType = function (gid, reportId, reporType, startDate, endDate, viewBy) {
        if (startDate === void 0) { startDate = null; }
        if (endDate === void 0) { endDate = null; }
        if (viewBy === void 0) { viewBy = null; }
        var data = {
            gid: gid,
            reportId: reportId,
            startDate: startDate,
            endDate: endDate,
        };
        if (viewBy) {
            data['viewBy'] = viewBy.toLowerCase();
        }
        return this.http.post(environment.apiUrl + "/v3/reports/" + reporType, data);
    };
    ReportService.prototype.getReportDetails = function (reportId) {
        var data = {
            reportId: reportId,
        };
        return this.http.post(environment.apiUrl + "/v3/reports/accounts", data);
    };
    ReportService.prototype.getReportById = function (reportId, isAutomatedReport) {
        if (isAutomatedReport === void 0) { isAutomatedReport = false; }
        var type = isAutomatedReport ? 'automated' : 'default';
        return this.http.get(environment.apiUrl + "/v3/reports/" + reportId + "?type=" + type);
    };
    ReportService.prototype.handleExportCsvFire = function (locations, startDate, endDate, viewBy, reportName) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: 
                    // let hasExport;
                    return [4 /*yield*/, this.insightS.getExportFile(locations, startDate, endDate, viewBy, 'csv', reportName).toPromise().then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                            var filename;
                            var _this = this;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        filename = response.data;
                                        return [4 /*yield*/, this.afsStorage.ref(filename).getDownloadURL().toPromise().then(function (filename) {
                                                _this.insightS.downloadFromGS(filename);
                                                _this.snackS.openSuccess("The file exported successfully", 2000);
                                            }, function (err) { return _this.snackS.openError("There was an error in the export", 2000); })];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); })];
                    case 1:
                        // let hasExport;
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ReportService.prototype.handleExportCsvMongo = function (reportId, gid, accountId, locationId, startDate, endDate, viewBy, type, period) {
        if (type === void 0) { type = null; }
        if (period === void 0) { period = null; }
        return __awaiter(this, void 0, void 0, function () {
            var startDatetime, endDatetime;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        startDatetime = this.locationS.formatDates(startDate);
                        endDatetime = this.locationS.formatDates(endDate);
                        return [4 /*yield*/, this.downloadReportCsv(reportId, gid, accountId, locationId, startDatetime, endDatetime, viewBy, type, period).subscribe(function (res) {
                                var filename = res;
                                _this.afsStorage.ref(filename).getDownloadURL().toPromise().then(function (filename) {
                                    _this.insightS.downloadFromGS(filename);
                                    _this.snackS.openSuccess("The file exported successfully", 2000);
                                });
                            }, function (err) { return _this.snackS.openError("There was an error in the export", 2000); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ReportService.prototype.downloadReportCsv = function (reportId, gid, accountId, locationId, startDate, endDate, viewBy, type, period) {
        if (reportId === void 0) { reportId = null; }
        if (gid === void 0) { gid = null; }
        if (accountId === void 0) { accountId = null; }
        if (locationId === void 0) { locationId = null; }
        if (startDate === void 0) { startDate = null; }
        if (endDate === void 0) { endDate = null; }
        if (viewBy === void 0) { viewBy = null; }
        if (type === void 0) { type = null; }
        if (period === void 0) { period = null; }
        var _a;
        var data = {
            reportId: reportId,
            gid: gid,
            accountId: accountId,
            locationId: locationId,
            startDate: startDate,
            endDate: endDate,
            viewBy: (_a = viewBy) === null || _a === void 0 ? void 0 : _a.toLowerCase(),
            type: type,
            period: period
        };
        return this.http.post(environment.apiUrl + "/v3/reports/export", data);
    };
    ReportService.prototype.downloadReportCsvReview = function (gid, reportId, startDate, endDate, type) {
        var data = {
            gid: gid,
            reportId: reportId,
            startDate: startDate,
            endDate: endDate,
            type: type
        };
        return this.http.post(environment.apiUrl + "/v3/reports/export", data);
    };
    ReportService.prototype.save = function (gid, report) {
        return this.getRef(gid).add(report);
    };
    ReportService.prototype.delete = function (gid, docId) {
        return this.getRef(gid)
            .doc(docId)
            .delete();
    };
    ReportService.prototype.update = function (automatedReport, gid, docId, report) {
        if (automatedReport === void 0) { automatedReport = false; }
        if (automatedReport) {
            return this.getAutomatedRef(gid)
                .doc(docId)
                .update(__assign(__assign({}, report), { updateAt: firebase.firestore.Timestamp.now() }));
        }
        return this.getRef(gid)
            .doc(docId)
            .update(__assign(__assign({}, report), { updateAt: firebase.firestore.Timestamp.now() }));
    };
    ReportService.prototype.setMerge = function (gid, docId, report) {
        return this.getRef(gid)
            .doc(docId)
            .set(report, { merge: true });
    };
    ReportService.prototype.share = function (dataPicker, type, metrics, reportName, showComparison, questionsType, periodDaysValue, compareToValue) {
        var _this = this;
        return this.getHash()
            .pipe(map(function (result) {
            var _a, _b, _c, _d;
            var report = {
                reportName: reportName || '',
                reportType: type,
                createdAt: firebase.firestore.Timestamp.now(),
                locations: dataPicker.locations,
                gid: _this.auth.session.gid,
                hash: result.hash,
                token: result.token,
                aggregation: dataPicker.aggregation,
                lockDates: false,
                uid: _this.auth.session.uid,
                shared: true,
                sharedOnly: true
            };
            if (dataPicker.multiChart) {
                report.multiChart = dataPicker.multiChart;
            }
            if (dataPicker.range) {
                report.startDatetime = (_b = (_a = dataPicker) === null || _a === void 0 ? void 0 : _a.range) === null || _b === void 0 ? void 0 : _b.start;
                report.endDatetime = (_d = (_c = dataPicker) === null || _c === void 0 ? void 0 : _c.range) === null || _d === void 0 ? void 0 : _d.end;
            }
            if (dataPicker.rangeB) {
                if (dataPicker.rangeB.start !== undefined && dataPicker.rangeB.end !== undefined) {
                    report.startDatetimeB = dataPicker.rangeB.start;
                    report.endDatetimeB = dataPicker.rangeB.end;
                }
            }
            if (metrics.length) {
                report.metrics = metrics;
            }
            if (!report.accounts) {
                report.accounts = _this.buildAccounts(dataPicker.locations);
            }
            if (type.includes('keyword') && showComparison) {
                report.showComparison = showComparison;
            }
            if (type.includes('keyword') && compareToValue) {
                report.compareToValue = compareToValue;
            }
            if (type.includes('qanda') && questionsType) {
                report.questionsType = questionsType;
            }
            if (type.includes('qanda') && periodDaysValue) {
                report.dynamicRange = periodDaysValue;
            }
            return report;
        }), switchMap(function (report) { var _a, _b; return type != 'performance-insights' && !((_a = type) === null || _a === void 0 ? void 0 : _a.includes('-location')) && !((_b = type) === null || _b === void 0 ? void 0 : _b.includes('-report')) ? _this.save(report.gid, report) : _this.saveReportInMongo(report); }));
    };
    ReportService.prototype.buildAccounts = function (locations) {
        var _this = this;
        var data = [];
        locations.forEach(function (el) {
            var _a;
            var indexAcc = data.findIndex(function (loc) { var _a; return loc.accountId == ((_a = el) === null || _a === void 0 ? void 0 : _a.accountId); });
            if (indexAcc > -1) {
                data[indexAcc].locations.push(el);
            }
            else {
                data.push({
                    accountId: (_a = el) === null || _a === void 0 ? void 0 : _a.accountId,
                    gid: _this.auth.session.gid,
                    locations: [el]
                });
            }
        });
        return data;
    };
    ReportService.prototype.getByHash = function (hash) {
        return this.afs.collectionGroup(REPORTS, function (ref) { return ref.where('hash', '==', hash)
            .limit(1); }).get()
            .pipe(map(function (report) { return report.docs.length > 0 ? __assign({ id: report.docs[0].id }, report.docs[0].data()) : {}; }));
        // return this.firestoreS.colWithIds$<Report>(GROUPS + '/' + REPORTS, ref => ref.where('hash', '==', hash).limit(1))
    };
    ReportService.prototype.reportSelectDatesFromReport = function (report) {
        var selectStart = report.startDatetime;
        var selectEnd = report.endDatetime;
        return { start: moment(selectStart), end: moment(selectEnd) };
    };
    ReportService.prototype.converDynamicDateComparison = function (selector, reportType) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var dates = this.getDates(selector, reportType);
        var startDatetime = (_b = (_a = dates) === null || _a === void 0 ? void 0 : _a.startDatetime) === null || _b === void 0 ? void 0 : _b.toISOString();
        var endDatetime = (_d = (_c = dates) === null || _c === void 0 ? void 0 : _c.endDatetime) === null || _d === void 0 ? void 0 : _d.toISOString();
        var startDatetimeB = (_f = (_e = dates) === null || _e === void 0 ? void 0 : _e.startDatetimeB) === null || _f === void 0 ? void 0 : _f.toISOString();
        var endDatetimeB = (_h = (_g = dates) === null || _g === void 0 ? void 0 : _g.endDatetimeB) === null || _h === void 0 ? void 0 : _h.toISOString();
        return { startDatetime: startDatetime, endDatetime: endDatetime, startDatetimeB: startDatetimeB, endDatetimeB: endDatetimeB };
    };
    ReportService.prototype.updateDynamicDates = function (report, idReport, automatedReport, viewMode) {
        if (viewMode === void 0) { viewMode = 'new'; }
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (!((_a = report) === null || _a === void 0 ? void 0 : _a.dynamic) || !((_b = report) === null || _b === void 0 ? void 0 : _b.dynamicRange))
            return;
        var dynamic = (_c = report) === null || _c === void 0 ? void 0 : _c.dynamicRange;
        var dates = this.getDates(dynamic, report.reportType);
        report.startDatetime = moment((_d = dates) === null || _d === void 0 ? void 0 : _d.startDatetime).utc().toISOString();
        report.endDatetime = moment((_e = dates) === null || _e === void 0 ? void 0 : _e.endDatetime).utc().toISOString();
        if ((_f = report.reportType) === null || _f === void 0 ? void 0 : _f.includes('comparison')) {
            report.startDatetimeB = (_g = dates) === null || _g === void 0 ? void 0 : _g.startDatetimeB;
            report.endDatetimeB = (_h = dates) === null || _h === void 0 ? void 0 : _h.endDatetimeB;
        }
        this.updateReport(viewMode, idReport, report, automatedReport);
    };
    ReportService.prototype.getDates = function (selector, reportType) {
        var startDatetime = null;
        var endDatetime = null;
        var startDatetimeB = null;
        var endDatetimeB = null;
        var date = moment().utc().toDate();
        var year = date.getFullYear();
        var month = date.getMonth();
        var day = date.getDate();
        switch (selector) {
            case 'LastMonth':
                startDatetime = moment(new Date(year, month - 1, 1).setHours(0, 0, 0));
                endDatetime = moment(new Date(year, month, 0).setHours(0, 0, 0));
                return { startDatetime: startDatetime, endDatetime: endDatetime, startDatetimeB: null, endDatetimeB: null };
            case 'ThisMonth':
                startDatetime = moment(new Date(year, month, 1).setHours(0, 0, 0));
                endDatetime = moment(new Date(year, month, day).setHours(0, 0, 0));
                return { startDatetime: startDatetime, endDatetime: endDatetime, startDatetimeB: null, endDatetimeB: null };
            case 'Last7':
                startDatetime = moment(new Date(year, month, day - 7).setHours(0, 0, 0));
                startDatetime = this.removeSevenDays(startDatetime, reportType);
                endDatetime = moment(new Date(year, month, day).setHours(0, 0, 0));
                endDatetime = this.removeSevenDays(endDatetime, reportType);
                return { startDatetime: startDatetime, endDatetime: endDatetime, startDatetimeB: null, endDatetimeB: null };
            case 'Last30':
                startDatetime = moment(new Date(year, month, day - 30).setHours(0, 0, 0));
                startDatetime = this.removeSevenDays(startDatetime, reportType);
                endDatetime = moment(new Date(year, month, day).setHours(0, 0, 0));
                endDatetime = this.removeSevenDays(endDatetime, reportType);
                return { startDatetime: startDatetime, endDatetime: endDatetime, startDatetimeB: null, endDatetimeB: null };
            case 'Last90':
                startDatetime = moment(new Date(year, month, day - 90).setHours(0, 0, 0));
                startDatetime = this.removeSevenDays(startDatetime, reportType);
                endDatetime = moment(new Date(year, month, day).setHours(0, 0, 0));
                endDatetime = this.removeSevenDays(endDatetime, reportType);
                return { startDatetime: startDatetime, endDatetime: endDatetime, startDatetimeB: null, endDatetimeB: null };
            case 'LastYear':
                startDatetime = moment(new Date(year - 1, 0, 1));
                endDatetime = moment(new Date(year - 1, 11, 31));
                return { startDatetime: startDatetime, endDatetime: endDatetime, startDatetimeB: null, endDatetimeB: null };
            case 'YTD':
                startDatetime = moment(new Date(year, 0, 1).setHours(0, 0, 0));
                endDatetime = moment(new Date(year, month, day).setHours(0, 0, 0));
                endDatetime = this.removeSevenDays(endDatetime, reportType);
                return { startDatetime: startDatetime, endDatetime: endDatetime, startDatetimeB: null, endDatetimeB: null };
            case 'OneMonth':
                startDatetime = moment().subtract(1, 'month').startOf('month');
                endDatetime = moment(new Date().setDate(0));
                return { startDatetime: startDatetime, endDatetime: endDatetime, startDatetimeB: null, endDatetimeB: null };
            case 'Last3Month':
                startDatetime = moment().subtract(3, 'month').startOf('month');
                endDatetime = moment(new Date().setDate(0));
                return { startDatetime: startDatetime, endDatetime: endDatetime, startDatetimeB: null, endDatetimeB: null };
            case 'OneYear':
                startDatetime = moment(new Date(year, 0, 1));
                endDatetime = moment(new Date().setDate(0));
                return { startDatetime: startDatetime, endDatetime: endDatetime, startDatetimeB: null, endDatetimeB: null };
            case 'LastMonth-PrevMonth':
                startDatetime = moment(new Date(year, month - 1, 1).setHours(0, 0, 0));
                endDatetime = moment(new Date(year, month, 0).setHours(0, 0, 0));
                startDatetimeB = moment(new Date(year, month - 2, 1).setHours(0, 0, 0));
                endDatetimeB = moment(new Date(year, month - 1, 0).setHours(0, 0, 0));
                return { startDatetime: startDatetime, endDatetime: endDatetime, startDatetimeB: startDatetimeB, endDatetimeB: endDatetimeB };
            case 'LastMonth-YearAgoMonth':
                startDatetime = moment(new Date(year, month - 1, 1).setHours(0, 0, 0));
                endDatetime = moment(new Date(year, month, 0).setHours(0, 0, 0));
                startDatetimeB = moment(new Date(year - 1, month - 1, 1).setHours(0, 0, 0));
                endDatetimeB = moment(new Date(year - 1, month, 0).setHours(0, 0, 0));
                return { startDatetime: startDatetime, endDatetime: endDatetime, startDatetimeB: startDatetimeB, endDatetimeB: endDatetimeB };
            case 'Last90-PrevLast90':
                startDatetime = moment(new Date(year, month, day - 90).setHours(0, 0, 0));
                startDatetime = this.removeSevenDays(startDatetime, reportType);
                endDatetime = moment(new Date().setHours(0, 0, 0));
                endDatetime = this.removeSevenDays(endDatetime, reportType);
                startDatetimeB = moment(new Date(year, month, day - 180).setHours(0, 0, 0));
                startDatetimeB = this.removeSevenDays(startDatetimeB, reportType);
                endDatetimeB = moment(new Date(year, month, day - 90).setHours(0, 0, 0));
                endDatetimeB = this.removeSevenDays(endDatetimeB, reportType);
                return { startDatetime: startDatetime, endDatetime: endDatetime, startDatetimeB: startDatetimeB, endDatetimeB: endDatetimeB };
        }
    };
    ReportService.prototype.removeSevenDays = function (date, reportType) {
        var _a, _b;
        if (((_a = reportType) === null || _a === void 0 ? void 0 : _a.includes('rollup')) || ((_b = reportType) === null || _b === void 0 ? void 0 : _b.includes('comparison'))) {
            date = moment(date).subtract(7, 'days');
        }
        return date;
    };
    ReportService.prototype.addSevenDays = function (date, reportType) {
        var _a, _b;
        if (((_a = reportType) === null || _a === void 0 ? void 0 : _a.includes('rollup')) || ((_b = reportType) === null || _b === void 0 ? void 0 : _b.includes('comparison'))) {
            date = moment(date).add(7, 'days');
        }
        return date;
    };
    ReportService.prototype.updateReport = function (viewMode, idReport, report, automatedReport) {
        if (viewMode == 'new') {
            this.updateReportsInMongo(idReport, __assign(__assign({}, report), { startDatetime: report.startDatetime, endDatetime: report.endDatetime }));
        }
        else {
            this.update(automatedReport, report.gid, idReport, __assign(__assign({}, report), { startDatetime: report.startDatetime, endDatetime: report.endDatetime }));
        }
    };
    ReportService.prototype.reportToDataPicker = function (report, idReport, automatedReport, viewMode, refresh) {
        if (automatedReport === void 0) { automatedReport = false; }
        if (viewMode === void 0) { viewMode = 'new'; }
        if (refresh === void 0) { refresh = false; }
        if (!refresh) {
            this.updateDynamicDates(report, idReport, automatedReport, viewMode);
        }
        var dataPicker = {
            range: {
                start: report.startDatetime,
                end: report.endDatetime
            },
            rangeB: null,
            aggregation: 'Month'
        };
        if (report.reportType.includes('comparison') || report.reportType === 'grade') {
            var startMoment = moment(report.startDatetimeB);
            var endMoment = moment(report.endDatetimeB);
            dataPicker.rangeB = {
                start: startMoment['year']() + "-" + (startMoment['month']() + 1) + "-" + startMoment['date'](),
                end: endMoment['year']() + "-" + (endMoment['month']() + 1) + "-" + endMoment['date']()
            };
        }
        var locations = [];
        if (report.accounts) {
            report.accounts.forEach(function (account) {
                locations = locations.concat(account.locations);
            });
        }
        else if (report.locations) {
            locations = report.locations;
        }
        dataPicker.locations = locations;
        return dataPicker;
    };
    ReportService.prototype.selectDatesFromDataPicker = function (dataPicker, rangeB) {
        if (rangeB === void 0) { rangeB = false; }
        var r = rangeB ? dataPicker.rangeB : dataPicker.range;
        return {
            start: moment(r.start).utc(false).utcOffset(0),
            end: moment(r.end).utc(false).utcOffset(0)
        };
    };
    ReportService.prototype.convertReportDate = function (report) {
        if (!report)
            return;
        report.startDatetime = this.convertDate(report.startDatetime);
        report.endDatetime = this.convertDate(report.endDatetime);
        if (report.reportType === 'comparison') {
            report.startDatetimeB = this.convertDate(report.startDatetimeB);
            report.endDatetimeB = this.convertDate(report.endDatetimeB);
        }
        return report;
    };
    ReportService.prototype.convertDate = function (date) {
        var zulu = new ZuluToDatePipe();
        return zulu.transform(date);
    };
    ReportService.prototype.countLocation = function (element) {
        var _a, _b;
        var counter = 0;
        (_b = (_a = element) === null || _a === void 0 ? void 0 : _a.accounts) === null || _b === void 0 ? void 0 : _b.forEach(function (account) {
            var _a, _b;
            counter += (_b = (_a = account) === null || _a === void 0 ? void 0 : _a.locations) === null || _b === void 0 ? void 0 : _b.length;
        });
        return counter;
    };
    // TODO: Unused, remoe?
    // getReports(gid, reports) {
    //   const reports$ = [];
    //   reports.forEach(r => {
    //     reports$.push(this.afs.collection<any>(`${GROUPS}/${gid}/${REPORTS}`,
    //       ref => ref.orderBy('reportName')).doc(r.id).snapshotChanges().pipe(
    //         map(report => {
    //           const data = report.payload.data();
    //           if (!data) {
    //             return;
    //           }
    //           // tslint:disable-next-line: no-string-literal
    //           data['id'] = report.payload.id;
    //           return data;
    //         }),
    //     ));
    //   });
    //   return combineLatest(reports$);
    // }
    ReportService.prototype.correctDateAggregate = function (reportDataPicker) {
        var start = moment(reportDataPicker.range.start).utc(true);
        var end = moment(reportDataPicker.range.end).utc(true);
        var diff = end.diff(start, 'days') + 1;
        var value = 'Month';
        if (diff >= 90) {
            value = 'Month';
        }
        else if (diff >= 32) {
            value = 'Week';
        }
        else if (diff <= 31) {
            value = 'Day';
        }
        else {
            value = 'Month';
        }
        return value;
    };
    ReportService.prototype.periodChange = function (period, startDateTime, endDateTime) {
        var startDate = moment(startDateTime).utc();
        var endDate = moment(endDateTime).utc();
        var data = {
            difference: null,
            startDateB: null,
            endDateB: null,
            rangeBDisabled: true
        };
        switch (period) {
            case 'period':
                data.difference = endDate.diff(startDate, 'days');
                data.endDateB = this.getFormattedMomentDate(startDate.subtract(1, 'days'));
                data.startDateB = this.getFormattedMomentDate(moment(data.endDateB).subtract(data.difference, 'days'));
                break;
            case 'year':
                data.startDateB = this.dateS.momentSubtractYearReport(startDate, 1);
                data.endDateB = this.dateS.momentSubtractYearReport(endDate, 1);
                break;
            case 'month':
                data.difference = endDate.diff(startDate, 'days');
                data.startDateB = this.getFormattedMomentDate(startDate.subtract(1, 'month').startOf('month'));
                data.endDateB = this.getFormattedMomentDate(moment(data.startDateB).endOf('month'));
                break;
            default:
                data.rangeBDisabled = false;
                break;
        }
        return data;
    };
    ReportService.prototype.getFormattedMomentDate = function (date) {
        return date['year']() + "-" + (date['month']() + 1) + "-" + date['date']();
    };
    ReportService.prototype.getFormattedStringDate = function (date) {
        var _a;
        return ((_a = date) === null || _a === void 0 ? void 0 : _a.includes('T')) ? "" + date.split('T')[0] : date;
    };
    ReportService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReportService_Factory() { return new ReportService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.AuthService), i0.ɵɵinject(i4.FirestoreService), i0.ɵɵinject(i5.InsightsService), i0.ɵɵinject(i6.AngularFireStorage), i0.ɵɵinject(i7.SnackbarService), i0.ɵɵinject(i8.DatesService), i0.ɵɵinject(i9.LocationService)); }, token: ReportService, providedIn: "root" });
    return ReportService;
}());
export { ReportService };
