<article class="mb--15 mt--15">
  <p class="fw--medium mb--15">
      {{title}}
  </p>
  <ul >
    <li class="tag-wrapper m--0 pt--0">
      <span class="tag tag--success">
      <span class="tag__icon"><i class="fas fa-check"></i></span>
      <span class="tag__content p--10 fw--regular">{{add}} Points</span>
      </span>
    </li>
    <li class="tag-wrapper m--0 pt--0" *ngIf="customLabel == 'Yes'">
      <span class="tag tag--error">
      <span class="tag__icon"><i class="fas fa-times"></i></span>
      <span class="tag__content p--10 p--5 fw--regular">{{sub}} Points</span>
      </span>
    </li>
  </ul>

</article>
