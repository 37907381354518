// dep
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient /*, HttpParams*/ } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// app
import Group from '../constants/firestore/group';
import { GROUPS, SEARCH_INDEX } from '../constants/firestore/collections';
// import User from '../constants/firestore/user';
import { environment as ENV } from '@environment';
import { ApiResponse } from '../constants/api-response';

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  private groupRef = this.afs.collection(GROUPS);

  constructor(
    private afs: AngularFirestore,
    private http: HttpClient,
  ) {
    this.groupRef = this.afs.collection(GROUPS);
  }

  updateByDocId(gid : string, data: any) {
    return this.http.put(`${ENV.apiUrl}/v2/groups/${gid}`, data).toPromise();
  }

  updateByDocIdToFirestore(docId : string, data: object) {
    return this.groupRef.doc(docId).update(data);
  }

  // TODO: Unused, remove
  // searchIndex(gid): Observable<Group> {
  //   return this.afs.collection<Group>(GROUPS).doc<Group>(gid).valueChanges();
  // }

  getByDocId(gid: string):Observable<Group> {
    return this.http.get<Group>(`${ENV.apiUrl}/v2/groups/${gid}`);
  }

  // TODO: Unused, remove
  // getByDocIdFromFirestore(docId: string) {
  //   return this.afs.collection(GROUPS).doc<Group>(docId);
  // }

// TODO: Unused, remove?
//   getLocationCount(docId: string) {
//     return this.searchIndex(docId).pipe(map(res => {
//       const freeLoc = res['totalLocationsCount'];
//       const ultiLoc = res['ultimateLocationsCount'];
//       const locations = [
//         { number: (freeLoc == null) ? 0 : freeLoc, name: 'Essential locations' },
//         { number: (ultiLoc == null) ? 0 : ultiLoc, name: 'Ultimate locations' }
//       ];
//       return locations;
//     }));
//   }
// 
//   async getUltimate(user: User) {
//     return await this.getByDocId(user.gid).toPromise().then((group: Group) => {
//       return (group.ultimateLocationsCount == null) ? 0 : group.ultimateLocationsCount;
//     });
//   }

  getIndexes(docId: string) {
    return this.afs.collection(`${GROUPS}/${docId}/${SEARCH_INDEX}`).valueChanges();
  }

  getReportIndexes(docId: string, type: string) {
    return this.afs.collection(`${GROUPS}/${docId}/${SEARCH_INDEX}`,
      rf => rf.where('type', '==', 'report').where('reportType', '==', type)
    ).valueChanges();
  }

  // TODO: Unused, remove
  // getLocationIndexes(docId: string) {
  //   return this.afs.collection(`${GROUPS}/${docId}/${SEARCH_INDEX}`,
  //     rf => rf.where('type', '==', 'location')
  //   ).valueChanges();
  // }

// TODO: Unused, remove?
// 
//   getGroupByDomain(domain: string) {
//     return this.afs.collection(GROUPS,
//       rf => rf.where('domain', '==', domain)
//     ).get();
//   }
// 
//   refreshIndexes(enqueue: string): Observable<any> {
//     let params = new HttpParams();
//     if (enqueue && ENV.queuesEnabled) {
//       params = params.append('enqueue', enqueue);
//     }
// 
//     return this.http.get<any>(`${ENV.apiUrl}/v2/groups/refresh/indexes`, { params });
//   }
// 
//   async groupsByUser(list) {
//     await Promise.all(list.map(
//       async (user) => {
//         if (user.gid) {
//           const group = this.getByDocId(user.gid);
//           const asyncData = await Promise.all([group]);
//           if (asyncData[0]) {
//             await new Promise(resolve =>
//               asyncData[0]
//                 .subscribe(g => {
//                   user.locationsCount = _.get(g, 'totalLocationsCount', 0);
//                   resolve(g);
//                 }));
//           }
//         } else {
//           return;
//         }
//       }
//     ));
//   }

  get_accounts(gid: string): Observable<ApiResponse[]> {
    return this.http.get<ApiResponse>(`${ENV.apiUrl}/v2/groups/gid/${gid}/get_accounts`).pipe(
      map(response => response['data'])
    );
  }

  get_locations(gid: string, accountId: string): Observable<ApiResponse[]> {
    return this.http.get<ApiResponse>(`${ENV.apiUrl}/v2/groups/gid/${gid}/account/${accountId}/get_locations`).pipe(
      map(response => response['data']),
    );
  }

  // TODO: Unused, remove
  // getReports(filter?: { domain: string }) {
  //   let domain = ''
  //   if (filter.domain !== null && filter.domain !== '') {
  //     domain = `?domain=${filter.domain}`
  //   }
  //   return this.http.get<ApiResponse>(`${ENV.apiUrl}/v2/groups/report/domain${domain}`).pipe(map(value => value.data));
  // }

}


