import {JsonDate, JsonTime} from "../services/dates.service";
import AccountReport from "../services/account-report";
import {firestore} from "firebase/app";
import { Recurring } from '../v3.models/posts.models';

export class Post {
  _id?: string;
  name?: string;
  languageCode: string;
  summary: string;
  state: string;
  callToAction?: {
    "actionType": ActionType,
    "url": string
  };
  event?: {
    title: string, schedule: {
      startDate: JsonDate
      startTime: JsonTime | unknown
      endDate: JsonDate
      endTime: JsonTime | unknown
    }
  };

  media: any[];
  topicType: string;
  offer: {
    couponCode: string,
    redeemOnlineUrl: string,
    termsConditions: string
  };
  created_at? = Date;
  updated_at? = Date;
  scheduled_delete_at?: Date;
  updateTime?: string;
  createTime?: string;
  searchUrl?: string;
  accounts?: AccountReport[];
  gid?: string;
  locationId?: string;
  accountId?: string;
  alertType?: string;
  posted_ids?: string[];
  totalViews?: number = null;
  totalAction?: number = null;
  status?: string = null;
  scheduled?: SchedulePost;
  recurring?: Recurring;
}

export class PostManagement {
  post: Post;
  accounts?: AccountReport[];
  placeId?: string;
  accountId?: string;
  gid: string;
  status: string;
  lastError?: ScheduleError;
  lastUpdate?: firestore.Timestamp;
  createdAt?: firestore.Timestamp;
  schedule?: SchedulePost;
  id?:string;
  _id?:string;
  alertType?: any;
}


export class SchedulePost {
  date: Date;
  time: string;
}

export enum ActionType {
  BOOK = 'BOOK',
  ORDER = 'ORDER',
  SHOP = 'SHOP',
  LEARN_MORE = 'LEARN_MORE',
  SIGN_UP = 'SIGN_UP',
  CALL = 'CALL'
}

export class ScheduleError {
  errorMessage: string;
  detailMessage?: string;
}

// TODO: Refactor against form-post.component.ts 
export const placeHolders = [
  {key: '%websiteUrl%', value: 'Website URL'},
  {key: '%url_menu%', value: 'Menu URL'},
  {key: '%url_order_ahead%', value: 'Order Ahead URL'},
  {key: '%url_facility_telemedicine_page%', value: 'Virtual Care URL'},
  {key: '%url_appointment%', value: 'Appointment URL'},
  {key: '%url_reservations%', value: 'Reservations URL'},
] as const;

export const validatePlaceholder = (list: string[]): string[] => {
  return list.filter(l => placeHolders.some(ph => ph.key === l))
}

// ------ STATES

export const POST_STATUS_DRAFT = 'DRAFT'
export const POST_STATUS_PENDING = 'PENDING'
export const POST_STATUS_PENDING_UPDATE = 'PENDING UPDATE'
export const POST_STATUS_PENDING_DELETE = 'PENDING DELETE'
export const POST_STATUS_SCHEDULED = 'SCHEDULE'
export const POST_STATUS_RECURRING = 'RECURRING'
export const POST_STATUS_PUBLISH = 'PUBLISH'
export const POST_STATUS_ERROR = 'ERROR'
export const POST_STATUS_SEND = 'SEND'
export const BULK_POST_STATUS_PARTIAL_PUBLISH = 'PARTIAL PUBLISH'

