import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import moment from 'moment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var QuestionsAnswerService = /** @class */ (function () {
    function QuestionsAnswerService(http) {
        this.http = http;
    }
    QuestionsAnswerService.prototype.getReportData = function (reportId, paginate, startDate, endDate, accounts, questionType, isAllTime) {
        if (isAllTime === void 0) { isAllTime = true; }
        var data = {
            reportId: reportId,
            page: paginate.page,
            size: paginate.size,
            startDate: startDate,
            endDate: endDate,
            reportAccounts: accounts,
            questionType: questionType,
            isAllTime: isAllTime
        };
        return this.http.post(environment.apiUrl + "/v3/reports/qanda", data);
    };
    QuestionsAnswerService.prototype.buildPeriodDate = function (period) {
        var today = moment();
        var dates = {
            startDatetime: null,
            endDatetime: this.getDate(today)
        };
        switch (period) {
            case 'Last30':
                dates.startDatetime = this.getDate(today.subtract('day', 30));
                break;
            case 'ThisMonth':
                dates.startDatetime = this.getDate(today.startOf('month'));
                dates.endDatetime = this.getDate(today.endOf('month'));
                break;
            case 'LastMonth':
                var month = today.subtract('month', 1);
                dates.startDatetime = this.getDate(month.startOf('month'));
                dates.endDatetime = this.getDate(month.endOf('month'));
                break;
            default:
                dates.startDatetime = null;
                dates.endDatetime = null;
                break;
        }
        return dates;
    };
    QuestionsAnswerService.prototype.getDate = function (date) {
        return date['year']() + "-" + (date['month']() + 1) + "-" + date['date']();
    };
    QuestionsAnswerService.prototype.getTotals = function (gid, accountId, locationId, questionType, startDate, endDate) {
        if (gid === void 0) { gid = null; }
        if (accountId === void 0) { accountId = null; }
        if (locationId === void 0) { locationId = null; }
        if (questionType === void 0) { questionType = null; }
        if (startDate === void 0) { startDate = null; }
        if (endDate === void 0) { endDate = null; }
        var data = {
            questionType: questionType,
            startDate: startDate,
            endDate: endDate
        };
        return this.http.post(environment.apiUrl + "/v3/qanda/" + gid + "/" + accountId + "/" + locationId + "/get_questions_metrics", data);
    };
    QuestionsAnswerService.prototype.getQuestions = function (gid, accountId, locationId, paginate, questionType, startDate, endDate) {
        if (gid === void 0) { gid = null; }
        if (accountId === void 0) { accountId = null; }
        if (locationId === void 0) { locationId = null; }
        if (questionType === void 0) { questionType = null; }
        if (startDate === void 0) { startDate = null; }
        if (endDate === void 0) { endDate = null; }
        var data = {
            page: paginate.page,
            size: paginate.size,
            questionType: questionType,
            startDate: startDate,
            endDate: endDate
        };
        return this.http.post(environment.apiUrl + "/v3/qanda/" + gid + "/" + accountId + "/" + locationId + "/get_questions", data);
    };
    QuestionsAnswerService.prototype.getQuestionsAtlasSearch = function (accounts, search_text, search_type, initial_date, final_date, limit) {
        var data = {
            accounts: accounts,
            search_text: search_text,
            search_type: search_type,
            initial_date: initial_date,
            final_date: final_date,
            limit: limit
        };
        return this.http.post(environment.apiUrl + "/v3/qanda/get_filtered_qnas", data);
    };
    QuestionsAnswerService.prototype.addAnswer = function (gid, accountId, locationId, questionId, displayName, answer) {
        if (gid === void 0) { gid = null; }
        if (accountId === void 0) { accountId = null; }
        if (locationId === void 0) { locationId = null; }
        var data = {
            data: {
                questionId: questionId,
                answer: answer,
                displayName: displayName
            }
        };
        return this.http.post(environment.apiUrl + "/v3/qanda/" + gid + "/" + accountId + "/" + locationId + "/add_answer", data);
    };
    QuestionsAnswerService.prototype.deleteAnswer = function (gid, accountId, locationId, questionId, name) {
        if (gid === void 0) { gid = null; }
        if (accountId === void 0) { accountId = null; }
        if (locationId === void 0) { locationId = null; }
        var data = {
            data: {
                questionId: questionId,
                name: name
            }
        };
        return this.http.post(environment.apiUrl + "/v3/qanda/" + gid + "/" + accountId + "/" + locationId + "/delete_answer", data);
    };
    QuestionsAnswerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function QuestionsAnswerService_Factory() { return new QuestionsAnswerService(i0.ɵɵinject(i1.HttpClient)); }, token: QuestionsAnswerService, providedIn: "root" });
    return QuestionsAnswerService;
}());
export { QuestionsAnswerService };
