import { CurrencyPipe } from '@angular/common';
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
var ModalProductsItemEditComponent = /** @class */ (function () {
    function ModalProductsItemEditComponent(currencyPipe, dialogRef, data) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35;
        this.currencyPipe = currencyPipe;
        this.dialogRef = dialogRef;
        this.data = data;
        this.productPriceToShow = "";
        this.removeItem = false;
        this.linkType = ['Order Online', 'Buy', 'Learn More', 'Get offer'];
        this.productNameInvalid = false;
        this.itemId = (_b = (_a = data) === null || _a === void 0 ? void 0 : _a.productItem) === null || _b === void 0 ? void 0 : _b.itemId;
        this.productName = ((_e = (_d = (_c = data) === null || _c === void 0 ? void 0 : _c.productItem) === null || _d === void 0 ? void 0 : _d.labels[0]) === null || _e === void 0 ? void 0 : _e.displayName) ? (_h = (_g = (_f = data) === null || _f === void 0 ? void 0 : _f.productItem) === null || _g === void 0 ? void 0 : _g.labels[0]) === null || _h === void 0 ? void 0 : _h.displayName : '';
        this.productImage = ((_k = (_j = data) === null || _j === void 0 ? void 0 : _j.productItem) === null || _k === void 0 ? void 0 : _k.image) ? (_m = (_l = data) === null || _l === void 0 ? void 0 : _l.productItem) === null || _m === void 0 ? void 0 : _m.image : '';
        this.productDescription = ((_q = (_p = (_o = data) === null || _o === void 0 ? void 0 : _o.productItem) === null || _p === void 0 ? void 0 : _p.labels[0]) === null || _q === void 0 ? void 0 : _q.description) ? (_t = (_s = (_r = data) === null || _r === void 0 ? void 0 : _r.productItem) === null || _s === void 0 ? void 0 : _s.labels[0]) === null || _t === void 0 ? void 0 : _t.description : '';
        this.priceRange = ((_v = (_u = data) === null || _u === void 0 ? void 0 : _u.productItem) === null || _v === void 0 ? void 0 : _v.priceMax);
        this.productPrice = ((_y = (_x = (_w = data) === null || _w === void 0 ? void 0 : _w.productItem) === null || _x === void 0 ? void 0 : _x.price) === null || _y === void 0 ? void 0 : _y.units) ? (_1 = (_0 = (_z = data) === null || _z === void 0 ? void 0 : _z.productItem) === null || _0 === void 0 ? void 0 : _0.price) === null || _1 === void 0 ? void 0 : _1.units : null;
        this.productPriceMax = ((_3 = (_2 = data) === null || _2 === void 0 ? void 0 : _2.productItem) === null || _3 === void 0 ? void 0 : _3.priceMax) ? (_5 = (_4 = data) === null || _4 === void 0 ? void 0 : _4.productItem) === null || _5 === void 0 ? void 0 : _5.priceMax : '';
        this.currencyCode = ((_8 = (_7 = (_6 = data) === null || _6 === void 0 ? void 0 : _6.productItem) === null || _7 === void 0 ? void 0 : _7.price) === null || _8 === void 0 ? void 0 : _8.currencyCode) ? (_11 = (_10 = (_9 = data) === null || _9 === void 0 ? void 0 : _9.productItem) === null || _10 === void 0 ? void 0 : _10.price) === null || _11 === void 0 ? void 0 : _11.currencyCode : null;
        this.selectedButton = ((_14 = (_13 = (_12 = data) === null || _12 === void 0 ? void 0 : _12.productItem) === null || _13 === void 0 ? void 0 : _13.link) === null || _14 === void 0 ? void 0 : _14.linkType) ? (_17 = (_16 = (_15 = data) === null || _15 === void 0 ? void 0 : _15.productItem) === null || _16 === void 0 ? void 0 : _16.link) === null || _17 === void 0 ? void 0 : _17.linkType : '';
        this.buttonAdded = (((_20 = (_19 = (_18 = data) === null || _18 === void 0 ? void 0 : _18.productItem) === null || _19 === void 0 ? void 0 : _19.link) === null || _20 === void 0 ? void 0 : _20.linkType) && ((_23 = (_22 = (_21 = data) === null || _21 === void 0 ? void 0 : _21.productItem) === null || _22 === void 0 ? void 0 : _22.link) === null || _23 === void 0 ? void 0 : _23.linkText));
        this.buttonType = ((_26 = (_25 = (_24 = data) === null || _24 === void 0 ? void 0 : _24.productItem) === null || _25 === void 0 ? void 0 : _25.link) === null || _26 === void 0 ? void 0 : _26.linkType) ? ((_29 = (_28 = (_27 = data) === null || _27 === void 0 ? void 0 : _27.productItem) === null || _28 === void 0 ? void 0 : _28.link) === null || _29 === void 0 ? void 0 : _29.linkType) : '';
        this.buttonText = ((_32 = (_31 = (_30 = data) === null || _30 === void 0 ? void 0 : _30.productItem) === null || _31 === void 0 ? void 0 : _31.link) === null || _32 === void 0 ? void 0 : _32.linkText) ? ((_35 = (_34 = (_33 = data) === null || _33 === void 0 ? void 0 : _33.productItem) === null || _34 === void 0 ? void 0 : _34.link) === null || _35 === void 0 ? void 0 : _35.linkText) : '';
        if (this.productPrice && this.currencyCode) {
            this.productPriceToShow = this.currencyPipe.transform(this.productPrice, this.currencyCode);
        }
    }
    ModalProductsItemEditComponent.prototype.ngOnInit = function () {
    };
    ModalProductsItemEditComponent.prototype.fileChanged = function (val) {
        this.productImage = val;
    };
    ModalProductsItemEditComponent.prototype.showLinkText = function (type) {
        this.buttonAdded = (type !== 'hideButton');
        this.buttonType = type;
    };
    ModalProductsItemEditComponent.prototype.changeCurrency = function (event) {
        if (this.currencyCode) {
            var price = Number(event.split("$")[1]);
            if (!isNaN(price)) {
                this.productPrice = price;
                this.productPriceToShow = this.currencyPipe.transform(price, this.currencyCode);
            }
            else {
                this.productPrice = 0;
                this.productPriceToShow = this.currencyPipe.transform(0, this.currencyCode);
            }
        }
        else {
            if (event !== '') {
                this.productPrice = Number(event);
                this.productPriceToShow = this.currencyPipe.transform(this.productPrice);
            }
            else {
                this.productPrice = 0;
                this.productPriceToShow = this.currencyPipe.transform(0);
            }
        }
    };
    ModalProductsItemEditComponent.prototype.apply = function () {
        this.removeItem = false;
        if (this.productName) {
            this.productNameInvalid = false;
            var productItem = {
                itemId: this.itemId,
                labels: [
                    {
                        displayName: this.productName,
                        description: this.productDescription ? this.productDescription : ''
                    }
                ],
                price: {
                    currencyCode: this.currencyCode,
                    units: this.productPrice ? this.productPrice : ""
                }
            };
            var data = { productItem: productItem, removeItem: this.removeItem };
            this.dialogRef.close(data);
        }
        else {
            this.productNameInvalid = true;
        }
    };
    ModalProductsItemEditComponent.prototype.remove = function () {
        this.removeItem = true;
        var data = { removeItem: this.removeItem };
        this.dialogRef.close(data);
    };
    return ModalProductsItemEditComponent;
}());
export { ModalProductsItemEditComponent };
