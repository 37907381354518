<div class="dialog__header">
    <h2 mat-dialog-title class="m--0 txt--xl"><strong>Add Item</strong></h2>
</div>
<div class="dialog__content" mat-dialog-content>
    <div class="row">
        <div class="col-3">
            <div class="dialog-row">
                <div class="input-field input-field--image">
                    <label for="image" class="txt--sm txt--lightblue">Add product image</label>
                    <input id="image" type="file" [(ngModel)]="productImage" name="productImage" hidden>
                </div>
            </div>
        </div>
        <div class="col-9">
            <div class="dialog-row p--0">
                <mat-form-field class="input-field">
                    <input matInput placeholder="Product name (optional)" [(ngModel)]="productName" name="productName">
                    <mat-error *ngIf="productNameInvalid"><p>Product Item name is required</p></mat-error>
                </mat-form-field>
            </div>
            <div class="dialog-row row p--0">
                <div class="col-12" [class.col-md-6]="priceRange">
                    <mat-form-field class="input-field">
                        <ng-container *ngIf="currencyCode !== ''; else notCurrencyCode">
                            <input matInput placeholder="Product Price ({{currencyCode}})" [ngModel]="productPrice | currency:currencyCode"  (ngModelChange)="productPrice=$event" name="itemPrice" type="text">
                        </ng-container>
                        <ng-template #notCurrencyCode>
                            <input matInput placeholder="Product Price" [(ngModel)]="productPrice" name="itemPrice">
                        </ng-template>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6" *ngIf="priceRange">
                    <mat-form-field class="input-field">
                        <ng-container *ngIf="currencyCode !== ''; else notCurrencyCodeMax">
                            <input matInput placeholder="Product Price ({{currencyCode}})" [ngModel]="productPriceMax | currency:currencyCode"  (ngModelChange)="productPriceMax=$event" name="itemPrice" type="text">
                        </ng-container>
                        <ng-template #notCurrencyCodeMax>
                            <input matInput placeholder="Product Price" [(ngModel)]="productPriceMax" name="itemPrice">
                        </ng-template>
                    </mat-form-field>
                </div>
            </div>

            <div class="d-flex justify-content-between align-items-center">
                <p class="txt--lightgray txt--md m--0">Range</p>
                <mat-slide-toggle [(ngModel)]="priceRange"></mat-slide-toggle>
            </div>

            <div class="dialog-row pb--0">
                <mat-form-field class="input-field">
                    <input matInput placeholder="Product description" [(ngModel)]="productDescription" name="productDescription">
                </mat-form-field>
            </div>


            <div class="dialog-row p--0">
                <mat-form-field class="input-field">
                    <mat-label>Add Button</mat-label>
                    <mat-select>
                        <mat-option value="hideButton" (click)="showLinkText('hideButton')">Add button</mat-option>
                        <mat-divider></mat-divider>
                        <mat-option *ngFor="let type of linkType" [value]="type" (click)="showLinkText(type)">
                            {{type}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="dialog-row p--0" *ngIf="buttonAdded">
                <mat-form-field class="input-field">
                    <input matInput placeholder="Add Button Text" [(ngModel)]="buttonText" name="buttonText">
                </mat-form-field>
            </div>
        </div>
    </div>

</div>
<div mat-dialog-actions class="dialog__footer">
    <span class="alternate-theme d-inline-block mr--10">
        <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
    </span>
    <button mat-flat-button (click)="apply()"  tabIndex="-1" color="accent" class="btn btn--action">Apply</button>
</div>
