import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LocationService} from '../../services/location.service';
import {ModalShareComponent} from '../modal-share/modal-share.component';
import {DataPicker} from '../../constants/data-picker';
import {ModalService} from '../../services/modal.service';
import {ActivatedRoute} from '@angular/router';
import {GradeExplainComponent} from '../grade-explain/grade-explain.component';
import {AuthService} from '../../services/auth.service';
import {PdfService} from '../../services/pdf.service';
import { WhiteLabelService } from 'src/app/services/white-label.service';
import { Title } from '@angular/platform-browser';
import { LoadingService } from '../../services/loading.service';
import SavedLocation from 'src/app/constants/firestore/saved-location';
import { GradeLoadedService } from 'src/app/services/grade-loaded.service';
import { ExternalGradeService } from 'src/app/services/external-grade.service';
import { GradeLeadService } from 'src/app/services/grade-lead.service';

@Component({
  selector: 'app-grade-header-location',
  templateUrl: './grade-header-location.component.html',
  styleUrls: ['./grade-header-location.component.scss']
})
export class GradeHeaderLocationComponent implements OnInit {
  @Output() finish = new EventEmitter();
  @Input() accountId: any;
  @Input() locationId: any;
  @Input() dataPicker: DataPicker;
  @Input() gid: string;
  @Input() grade: number;
  @Input() isExternal = false;

  location;
  isShared: boolean;
  progress = true;
  id: any;
  circleColorFirst: string;
  circleColorSecond: string;
  circlePercent: number;
  circleGrade: string;
  firebaseGradeLeadId: string;
  exportInProgress: boolean = false;
  branding: any;

  constructor(
    private route: ActivatedRoute,
    private locationS: LocationService,
    private modalS: ModalService,
    private auth: AuthService,
    private wl: WhiteLabelService,
    private titleService: Title,
    private pdfS: PdfService,
    private loadingService: LoadingService,
    private gradeLoaded: GradeLoadedService,
    private gradeLeadService: GradeLeadService,
    private externalGradeService: ExternalGradeService
  ) {
  }

  ngOnInit() {
    // TODO: the two below should be dynamic (the colors too if they depend on the percentage)
    this.circlePercent = 40;
    this.circleGrade = 'A';
    this.circleColorFirst = '#ff4949';
    this.circleColorSecond = '#E91616';

    if (!this.gid) {
      this.id = this.route.snapshot?.params?.id;
      if (this.isExternal) 
          this.gid =  this.auth.externalSession.gid;
      else 
          this.gid = this.route.snapshot?.params?.gid || this.auth.session.gid;

      if (this.id) {
        this.isShared = true;
      }
    }

    this.locationS.getRef(this.gid, this.accountId, this.locationId).take(2).subscribe( loc => {
      this.progress = false;
      this.location = loc;
      this.finish.emit(true);
      if (this.isExternal) {
        this.wl.getDynamicWhiteLabel().then(data => {
          this.branding = data?.branding;
          const company_name = data.branding.company_name;
          const title_pass = `Grade | ${loc.locationName} | ${company_name}`;
          this.titleService.setTitle(title_pass)
        });
      }
      this.loadingService.reportAdvanced(1, "Locations")
    }, () => {
      this.finish.emit(false);
      this.progress = false;
    });

    if(this.isExternal){
      this.gradeLoaded.loaded$.subscribe(result => {
        if(result){
          setTimeout(()=>{
            this.exportPdf(false);
          },2000); // Time to finish animations before export
        }
      })
    }

    this.gradeLeadService.firebaseGradeId$.subscribe(id =>{
      this.firebaseGradeLeadId = id;
    })
  }

  handleShared() {
    const dialogRef = this.modalS.openGenericModal(ModalShareComponent, {
      createNew: true,
      dataPicker: this.dataPicker,
      reportType: 'grade',
    }, () => {
    });
    dialogRef.disableClose = true;
  }

  openExplain() {
    const dialogRef = this.modalS.openGenericModal(GradeExplainComponent, {}, () => {
    }, 900);

    dialogRef.disableClose = true;
  }

  handlePdf() {
    this.exportPdf()
  }

  async exportPdf(saveToDisk = true) {
    const filename = `Grader - ${this.location.locationName}`;
    const pdfBase64 = await this.pdfS.base64AndSave('charts-container', filename, '', saveToDisk); 

    this.exportInProgress = true;
    if(saveToDisk){
      this.exportInProgress = false;
      return;
    }

    if(this.isExternal){
      const grade_lead_id = this.firebaseGradeLeadId;
      this.externalGradeService.uploadExternalGradePDF(
        this.gid, 
        this.locationId, 
        this.accountId, 
        pdfBase64, 
        grade_lead_id,
        this.branding
      ).subscribe(async ()=>{
        const host = window.location.hostname;
        const port = window.location.port;
        const hostnameWithPort = host + ":" + port;
        const domain = port !== '80' ? hostnameWithPort : host
        this.gradeLeadService.getGradeLeadById(this.firebaseGradeLeadId, domain)
          .then(gradeLead =>{
            this.gradeLeadService.setHasPdf(gradeLead);
            this.exportInProgress = false;
        }).catch(()=>{
          this.exportInProgress = false;
        });
      });
    }

    if (!pdfBase64) {
      return;
    }
  }
}
