/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./review-report-insight.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./review-report-insight.component";
import * as i3 from "@angular/router";
import * as i4 from "@angular/material/dialog";
var styles_ReviewReportInsightComponent = [i0.styles];
var RenderType_ReviewReportInsightComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReviewReportInsightComponent, data: {} });
export { RenderType_ReviewReportInsightComponent as RenderType_ReviewReportInsightComponent };
export function View_ReviewReportInsightComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { paginator: 0 }), i1.ɵqud(402653184, 2, { sort: 0 })], null, null); }
export function View_ReviewReportInsightComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-review-report-insight", [], null, null, null, View_ReviewReportInsightComponent_0, RenderType_ReviewReportInsightComponent)), i1.ɵdid(1, 4308992, null, 0, i2.ReviewReportInsightComponent, [i3.Router, i4.MatDialog, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReviewReportInsightComponentNgFactory = i1.ɵccf("app-review-report-insight", i2.ReviewReportInsightComponent, View_ReviewReportInsightComponent_Host_0, { page: "page", totalPages: "totalPages" }, { goPrev: "goPrev", goNext: "goNext", goPage: "goPage" }, []);
export { ReviewReportInsightComponentNgFactory as ReviewReportInsightComponentNgFactory };
