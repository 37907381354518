// dep
import { Injectable } from '@angular/core';
import { AngularFirestore, CollectionReference } from '@angular/fire/firestore';
import {BehaviorSubject} from 'rxjs';
import { map, share } from 'rxjs/operators';

// app
import { WhiteLabelService } from './white-label.service';
import { DOMAINS } from '../constants/firestore/collections'
import { Domain } from '../constants/firestore/domain';


// TODO: Unify with WhiteLabelService
@Injectable({
  providedIn: 'root'
})
export class DomainService {
  ref: CollectionReference;
  searchDomain$;
  domain$ = new BehaviorSubject<Domain>(null);

  constructor(
    private afs: AngularFirestore,
    private wl: WhiteLabelService,
  ) {
    this.ref = this.afs.collection(DOMAINS).ref;
    const domain = this.wl.slugDomain;
    this.searchDomain$ = this.searchIndex(domain).pipe(share(), map(res => res));
  }


  searchIndex(xdomain: string) {
    return this.afs.collection(DOMAINS).doc(xdomain).valueChanges();
  }
}
