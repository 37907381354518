<div class="dialog__header dialog__header--error">

  <h2 mat-dialog-title class="m--0 txt--xl txt--center"><strong>Are you sure you want to remove this photo?</strong></h2>
</div>
<div class="dialog__content" mat-dialog-content>
  <div class="dialog-row">
    <p class="txt--lg txt--center"><strong>This action cannot be undone!</strong></p>
    <div class=" d-flex justify-content-center">
      <img [src]="data?.media?.googleUrl" alt="" style="width: 500px">
    </div>
  </div>
</div>
<div mat-dialog-actions class="dialog__footer">
    <span class="alternate-theme d-inline-block mr--10">
        <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
    </span>
  <button mat-flat-button (click)="dialog.close(true)" tabIndex="-1" color="warn" class="btn btn--action">Delete</button>
</div>
