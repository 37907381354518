import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var DataTransferService = /** @class */ (function () {
    function DataTransferService() {
        this.dataSource = new BehaviorSubject(null);
        this.currentMessage$ = this.dataSource.asObservable();
    }
    /**
     * funcion que setea información en mi behavior subject
     * @param d tipo de informacion de conjuto dinamico dependiendo de la clase que se necesite
     */
    DataTransferService.prototype.setData = function (d) {
        this.dataSource.next(d);
    };
    DataTransferService.prototype.getData = function () {
        return this.currentMessage$;
    };
    DataTransferService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DataTransferService_Factory() { return new DataTransferService(); }, token: DataTransferService, providedIn: "root" });
    return DataTransferService;
}());
export { DataTransferService };
