import { __awaiter, __generator } from "tslib";
import { OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { SubscriptionService } from '../../services/subscription.service';
import { SnackbarService } from '../../services/snackbar.service';
import { Messages } from '../../constants/messages';
// import {VerificationEmailService} from "../../services/verification-email.service";
import { WhiteLabelService } from "../../services/white-label.service";
import { SessionTraceService } from 'src/app/services/session-trace.service';
import { environment } from "@environment";
import { Router } from '@angular/router';
var RegisterComponent = /** @class */ (function () {
    function RegisterComponent(_auth, _router, _apiSub, _sessionTrace, snack, wl) {
        this._auth = _auth;
        this._router = _router;
        this._apiSub = _apiSub;
        this._sessionTrace = _sessionTrace;
        this.snack = snack;
        this.wl = wl;
        this.loading = true;
        this.registerProgress = false;
    }
    RegisterComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.env = environment;
                        _a = this;
                        return [4 /*yield*/, this.wl.getDynamicWhiteLabel()];
                    case 1:
                        _a.trialDays = (_b.sent()).trialDays;
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    RegisterComponent.prototype.handleRegisterWithGoogle = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var err_msg_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // TODO: All of this should be done atomically and server-side
                        event.preventDefault();
                        this.registerProgress = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this._auth.registerWithGoogle()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_msg_1 = _a.sent();
                        this.registerProgress = false;
                        this.snack.openError(err_msg_1, 4000);
                        return [2 /*return*/];
                    case 4:
                        _a.trys.push([4, , 7, 8]);
                        // Note createSubscription needs the user to be already logged-in
                        // to send the parameters in headers
                        return [4 /*yield*/, this._apiSub.createSubscription()];
                    case 5:
                        // Note createSubscription needs the user to be already logged-in
                        // to send the parameters in headers
                        _a.sent();
                        return [4 /*yield*/, this._auth.initSession()];
                    case 6:
                        _a.sent();
                        this._sessionTrace.onRegistration();
                        this.snack.openSuccess(Messages.register.SUCCESS, 3500);
                        this._auth.redirectAfterLogin();
                        return [3 /*break*/, 8];
                    case 7:
                        this.registerProgress = false;
                        return [7 /*endfinally*/];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    RegisterComponent.prototype.goToLogin = function () {
        this._router.navigate(['/login']);
    };
    RegisterComponent.prototype.goToTermsOfService = function () {
        this.wl.goToTermsOfService();
    };
    RegisterComponent.prototype.goToPrivacyPolicy = function () {
        this.wl.goToPrivacyPolicy();
    };
    RegisterComponent.prototype.goToPaymentPolicy = function () {
        this.wl.goToPaymentPolicy();
    };
    return RegisterComponent;
}());
export { RegisterComponent };
