import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { AuthService } from './auth.service';
import { environment as ENV } from '@environment';
import { Queue } from '../constants/firestore/enqueue';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "@angular/common/http";
var InsightsService = /** @class */ (function () {
    function InsightsService(auth, http) {
        this.auth = auth;
        this.http = http;
    }
    /**
     * this method return metrics top to mongo db
     * @param accountId
     * @param placeId is id to google locations in firebase and mongo
     */
    InsightsService.prototype.getTopMetrics = function (locationId) {
        return this.http.get(ENV.apiUrl + "/v2/locations/" + locationId + "/topMetrics").pipe(map(function (value) { return value.data; }));
    };
    /**
     * @param locations
     * @param dataPicker
     */
    InsightsService.prototype.getInsights = function (locations, dataPicker, version) {
        if (version === void 0) { version = 'legacy'; }
        var data = { locations: [] };
        var params = new HttpParams()
            .set('endDateTime', dataPicker.range.end)
            .set('startDateTime', dataPicker.range.start)
            .set('aggregate', dataPicker.aggregation.toLowerCase())
            .set('version', version);
        locations.forEach(function (l) {
            data.locations.push(l.accountId + "/" + l.locationId);
        });
        return this.http.post(ENV.apiUrl + "/v2/insights/", data, {
            params: params,
        }).pipe(map(function (value) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            var d = (_a = value) === null || _a === void 0 ? void 0 : _a.data;
            return {
                labels: (_b = d) === null || _b === void 0 ? void 0 : _b.labels,
                stats: [(_c = d) === null || _c === void 0 ? void 0 : _c.actionStats, (_d = d) === null || _d === void 0 ? void 0 : _d.viewStats, (_e = d) === null || _e === void 0 ? void 0 : _e.searchStats, (_f = d) === null || _f === void 0 ? void 0 : _f.localPostStats, (_g = d) === null || _g === void 0 ? void 0 : _g.photoViews, (_h = d) === null || _h === void 0 ? void 0 : _h.photoCount],
                popularTime: (_j = d) === null || _j === void 0 ? void 0 : _j.popularTime
            };
        }));
    };
    InsightsService.prototype.getPerformanceInsights = function (accountId, gid, locationId, dataPicker) {
        var _a, _b;
        var body = {
            startDate: moment(dataPicker.range.start, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            endDate: moment(dataPicker.range.end).format('YYYY-MM-DD'),
            viewBy: (_b = (_a = dataPicker) === null || _a === void 0 ? void 0 : _a.aggregation) === null || _b === void 0 ? void 0 : _b.toLowerCase(),
            accountId: accountId,
            gid: gid,
            locationId: locationId
        };
        return this.http.post(ENV.apiUrl + "/v2/locations/search-insights", body).pipe(map(function (value) {
            var _a, _b, _c, _d;
            return {
                labels: (_a = value) === null || _a === void 0 ? void 0 : _a.labels,
                stats: [(_b = value) === null || _b === void 0 ? void 0 : _b.primaryActions, (_c = value) === null || _c === void 0 ? void 0 : _c.secondaryActions, (_d = value) === null || _d === void 0 ? void 0 : _d.searchImpressions],
            };
        }));
    };
    InsightsService.prototype.saveLocationStats = function (gid, accountId, locationId, startDate, endDate) {
        var body = {
            startDate: startDate,
            endDate: endDate
        };
        return this.http.post(ENV.apiUrl + "/v2/locations/retrieve-impressions/gid/" + gid + "/account/" + accountId + "/location/" + locationId, body);
    };
    InsightsService.prototype.comparison = function (locations, dataPicker, metrics, type, isExternal, reportId, viewModeChecked) {
        if (type === void 0) { type = 'report'; }
        if (isExternal === void 0) { isExternal = false; }
        if (reportId === void 0) { reportId = null; }
        if (viewModeChecked === void 0) { viewModeChecked = null; }
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        var data = { locations: [] };
        var params = new HttpParams()
            .set('startDateA', dataPicker.range.start)
            .set('endDateA', dataPicker.range.end)
            .set('aggregate', dataPicker.aggregation)
            .set('type', type);
        if ((_a = dataPicker) === null || _a === void 0 ? void 0 : _a.rangeB) {
            if (dataPicker.rangeB.start !== undefined && dataPicker.rangeB.end !== undefined) {
                params = params.append('startDateB', dataPicker.rangeB.start);
                params = params.append('endDateB', dataPicker.rangeB.end);
            }
        }
        if (dataPicker.locations && !locations) {
            locations = dataPicker.locations;
        }
        if (locations) {
            (_b = locations) === null || _b === void 0 ? void 0 : _b.forEach(function (l) {
                data.locations.push(l.accountId + "/" + l.locationId);
            });
        }
        if (metrics) {
            (_c = metrics) === null || _c === void 0 ? void 0 : _c.forEach(function (l) {
                params = params.append('metrics', l);
            });
        }
        var headers = { gid: !isExternal ? this.auth.session.gid : this.auth.externalSession.gid };
        if (viewModeChecked == 'legacy' || !reportId) {
            return this.http.post(ENV.apiUrl + "/v2/locationStats/comparison", data, { params: params, headers: headers })
                .pipe(map(function (value) { return value.data ? value.data : []; }));
        }
        else {
            data.locations = undefined;
            data.locationId = (locations && locations.length === 1 ? (_d = locations[0]) === null || _d === void 0 ? void 0 : _d.locationId : undefined);
            data.reportId = reportId;
            data.startDateA = (_e = dataPicker.range.start) === null || _e === void 0 ? void 0 : _e.substring(0, 10);
            data.endDateA = (_f = dataPicker.range.end) === null || _f === void 0 ? void 0 : _f.substring(0, 10);
            data.viewBy = (_g = dataPicker.aggregation) === null || _g === void 0 ? void 0 : _g.toLowerCase();
            data.startDateB = (_j = (_h = dataPicker.rangeB) === null || _h === void 0 ? void 0 : _h.start) === null || _j === void 0 ? void 0 : _j.substring(0, 10);
            data.endDateB = (_l = (_k = dataPicker.rangeB) === null || _k === void 0 ? void 0 : _k.end) === null || _l === void 0 ? void 0 : _l.substring(0, 10);
            return this.http.post(ENV.apiUrl + "/v3/reports/comparison", data, { headers: headers })
                .pipe(map(function (value) { return value.data ? value.data : []; }));
        }
    };
    InsightsService.prototype.getGradeComparison = function (locationId, dataPicker) {
        var _a, _b, _c, _d;
        // const data = { locations: [] };
        var body = {
            'startDateA': dataPicker.range.start,
            'endDateA': dataPicker.range.end,
            'viewBy': dataPicker.aggregation.toLocaleLowerCase(),
            'startDateB': ((_b = (_a = dataPicker) === null || _a === void 0 ? void 0 : _a.rangeB) === null || _b === void 0 ? void 0 : _b.start) || null,
            'endDateB': ((_d = (_c = dataPicker) === null || _c === void 0 ? void 0 : _c.rangeB) === null || _d === void 0 ? void 0 : _d.end) || null,
        };
        return this.http.post(ENV.apiUrl + "/v3/reports/comparison/" + locationId + "/grader", body);
    };
    InsightsService.prototype.gradeMetricsReports = function (locations) {
        var _a;
        var now = new Date(Date.now());
        var startDateA = new Date(Date.now());
        // const endDateA = new Date(Date.now());
        var lastMonth = now.getMonth() - 1;
        var lastYear = startDateA.getFullYear() - 1;
        // range A
        startDateA.setDate(1);
        startDateA.setMonth(lastMonth);
        var endDateA = new Date(startDateA.getFullYear(), startDateA.getMonth() + 1, 0);
        // range B
        var startDateB = new Date(startDateA);
        startDateB.setFullYear(lastYear);
        var endDateB = new Date(startDateB.getFullYear(), startDateB.getMonth() + 1, 0);
        var params = new HttpParams()
            .set('startDateA', startDateA.toISOString())
            .set('endDateA', endDateA.toISOString())
            .set('startDateB', startDateB.toISOString())
            .set('endDateB', endDateB.toISOString())
            .set('aggregate', 'week');
        if (locations) {
            (_a = locations) === null || _a === void 0 ? void 0 : _a.forEach(function (l) {
                params = params.append('locations', l);
            });
        }
        return this.http.get(ENV.apiUrl + "/v2/locationStats/comparison/report", { params: params }).pipe(map(function (value) { return value.data; }));
    };
    InsightsService.prototype.resume = function (locations, pageable, dataPicker, sort) {
        var _a;
        dataPicker.range.start = dataPicker.range.start.includes('T') ? dataPicker.range.start : dataPicker.range.start + "T00:00:00.00Z";
        dataPicker.range.end = dataPicker.range.end.includes('T') ? dataPicker.range.end : dataPicker.range.end + "T00:00:00.00Z";
        var data = { locations: [] };
        var params = new HttpParams()
            .set('endDateTime', dataPicker.range.end)
            .set('startDateTime', dataPicker.range.start);
        if (locations) {
            (_a = locations) === null || _a === void 0 ? void 0 : _a.forEach(function (l) {
                data.locations.push(l.accountId + "/" + l.locationId);
            });
        }
        if (sort) {
            params = params.append('sortBy', sort.sortBy.toString());
            params = params.append('direction', sort.direction.toString());
        }
        params = params.append('page', pageable.page.toString());
        params = params.append('size', pageable.size.toString());
        return this.http.post(ENV.apiUrl + "/v2/locationStats/resume", data, {
            params: params,
        }).pipe(map(function (value) { return value.data; }));
    };
    /**
     * Export insights and generate file in api server
     */
    InsightsService.prototype.getExportFile = function (locations, startDate, endDate, aggregate, format, reportName) {
        var _a;
        // FIXME: Harcoded uid/gid ?
        var data = { locations: [] };
        var params = new HttpParams()
            .set('endDateTime', endDate)
            .set('startDateTime', startDate)
            .set('aggregate', aggregate.toLowerCase())
            .set('uid', 'D8QGdcMtDUcvi76IIsEfsDg1OZy2')
            .set('gid', 'uwxfwK6rKveXqdVXaJEF').set('format', format);
        if (reportName) {
            params = params.append('reportName', reportName);
        }
        (_a = locations) === null || _a === void 0 ? void 0 : _a.forEach(function (l) {
            data.locations.push(l.accountId + "/" + l.locationId);
        });
        return this.http.post(ENV.apiUrl + "/v2/insights/export", data, {
            params: params,
        });
    };
    InsightsService.prototype.downloadFromGS = function (filename) {
        window.open("" + filename, '_blank');
    };
    InsightsService.prototype.saveInsights = function (accountId, placeId) {
        var params = new HttpParams();
        if (ENV.queuesEnabled) {
            params = params.append('enqueue', Queue.COMBINED_EXPRESS);
        }
        return this.http.post(ENV.apiUrl + "/v2/insights/" + accountId + "/" + placeId, {}, { params: params });
    };
    InsightsService.prototype.hasMoreThan24hs = function (date) {
        var createdData = moment(date);
        var today = moment(new Date());
        return (today.diff(createdData, 'day') >= 1);
    };
    InsightsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InsightsService_Factory() { return new InsightsService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.HttpClient)); }, token: InsightsService, providedIn: "root" });
    return InsightsService;
}());
export { InsightsService };
