import { __awaiter, __generator } from "tslib";
import { OnInit, EventEmitter, ChangeDetectorRef, ViewRef } from '@angular/core';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { AuthService } from '../../../services/auth.service';
import { ModalService } from '../../../services/modal.service';
import { PostManagementService } from '../../../services/post-management.service';
import { PostService } from '../../../services/post.service';
import { ModalLocalPostsComponent } from '../../modal-local-posts/modal-local-posts.component';
import { ModalSchedulePostComponent } from '../../modal-schedule-post/modal-schedule-post.component';
import { convertMetaToAccountsReports } from '../../../helpers/functions.helpers';
import * as moment from 'moment';
import { PostsErrorsComponent } from '../../../components/posts-errors';
import { BULK_POST_STATUS_PARTIAL_PUBLISH, POST_STATUS_PENDING, POST_STATUS_PENDING_DELETE, POST_STATUS_PENDING_UPDATE, POST_STATUS_SEND, POST_STATUS_RECURRING, POST_STATUS_SCHEDULED, POST_STATUS_DRAFT } from '../../../constants/post';
import { Messages } from '../../../constants/messages';
import { interval, BehaviorSubject } from 'rxjs';
var PostListComponent = /** @class */ (function () {
    function PostListComponent(postManagementS, dialog, auth, modalS, cdRef, postS) {
        var _a, _b;
        this.postManagementS = postManagementS;
        this.dialog = dialog;
        this.auth = auth;
        this.modalS = modalS;
        this.cdRef = cdRef;
        this.postS = postS;
        this.displayedColumns = ['img', 'info', 'date', 'dateUpdate', 'views', 'actions_post', 'actions'];
        this.ColumnsSize = [5, 40, 10, 10, 5, 5, 10];
        this.post_type = 'Posted';
        this.focus = new BehaviorSubject(0);
        this.isSpinner = new EventEmitter();
        this.selected = new EventEmitter();
        // constanst
        this.partialPublishMessage = Messages.post.PARTIAL_PUBLISH;
        this.partialDeleteMessage = Messages.post.PARTIAL_DELETE;
        this.partialUpdateMessage = Messages.post.PARTIAL_UPDATE;
        this.partialStatus = BULK_POST_STATUS_PARTIAL_PUBLISH;
        this.pendingStatus = POST_STATUS_PENDING;
        this.pendingDeleteStatus = POST_STATUS_PENDING_DELETE;
        this.pendingUpdateStatus = POST_STATUS_PENDING_UPDATE;
        this.sendStatus = POST_STATUS_SEND;
        this.statusMessages = (_a = {},
            _a[BULK_POST_STATUS_PARTIAL_PUBLISH] = Messages.post.PARTIAL_PUBLISH,
            _a[POST_STATUS_PENDING] = Messages.post.PARTIAL_PUBLISH,
            _a[POST_STATUS_PENDING_DELETE] = Messages.post.PARTIAL_DELETE,
            _a[POST_STATUS_PENDING_UPDATE] = Messages.post.PARTIAL_UPDATE,
            _a);
        this.redirectTo = (_b = {},
            _b[BULK_POST_STATUS_PARTIAL_PUBLISH] = 'Pending',
            _b[POST_STATUS_PENDING] = 'Pending',
            _b[POST_STATUS_SCHEDULED] = 'Pending',
            _b[POST_STATUS_RECURRING] = 'Pending',
            _b[POST_STATUS_DRAFT] = 'Drafts',
            _b);
        this.progressPost = true;
        this.postsExist = false;
        this.dataSource = new MatTableDataSource([]);
        this.loadingLocationsRef = false;
        this.locationRef = [];
        // Pagination
        this.paginate = { page: 1, size: 10 };
        this.sort = {
            sortBy: 'createdAt',
            direction: 'desc',
        };
        // --- UPDATE COUNTERS
        this.bulkPostList = [];
        this.loading$ = this.postS.loading.asObservable();
    }
    PostListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.progressPost = true;
        if (this.dashboardType === 'LOCAL_POST') {
            this.bulkPost = false;
            this.postData();
        }
        else if (this.dashboardType === 'BULK_POST') {
            this.bulkPost = true;
            if (this.post_type === 'Posted') {
                this.bulkData();
            }
            if (this.post_type === 'Pending') {
                this.ColumnsSize = [5, 35, 15, 15, 5, 5];
                this.focus.subscribe(function (tabSelected) {
                    if (tabSelected === 1) {
                        _this.subscriptionUpdateCounter$ = interval(15000).subscribe(function () {
                            var arrayPromise = [];
                            _this.bulkPostList.forEach(function (bl) {
                                arrayPromise.push(_this.postS.getBulkCounters(bl.id));
                            });
                            Promise.all(arrayPromise).then(function (values) {
                                values = values.filter(Boolean);
                                if (values.length === 0)
                                    return;
                                var copyData = _this.dataSource.data;
                                copyData.forEach(function (data) {
                                    var updateData = values.find(function (value) { return value.id === data.id; });
                                    if (updateData)
                                        data.posted = updateData.posted;
                                });
                                _this.dataSource.data = copyData;
                            });
                        });
                    }
                    else {
                        if (_this.subscriptionUpdateCounter$)
                            _this.subscriptionUpdateCounter$.unsubscribe();
                    }
                });
            }
            if (this.post_type === 'Drafts') {
                this.ColumnsSize = [5, 35, 15, 20, 5];
            }
        }
    };
    PostListComponent.prototype.progressValue = function (element) {
        if (element.status === POST_STATUS_PENDING_DELETE) {
            return ((element.planned - element.posted) / element.planned) * 100;
        }
        else {
            return (element.posted / element.planned) * 100;
        }
    };
    PostListComponent.prototype.postData = function () {
        var _this = this;
        this.postS.getLocalPost(this.accountId, this.locationId, this.post_type.toUpperCase(), this.paginate, this.sort.sortBy, this.sort.direction).then(function (result) {
            var _a, _b;
            if (!result)
                return;
            _this.postS.isLoading(true);
            _this.previousPageable = { size: result.per_page, page: result.page };
            _this.pagination = result;
            _this.dataSource = new MatTableDataSource(result.items);
            _this.postsExist = ((_b = (_a = _this.dataSource) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.length) > 0;
            _this.progressPost = false;
        });
    };
    PostListComponent.prototype.bulkData = function () {
        var _this = this;
        this.postS.getBulkPost(this.post_type.toUpperCase(), this.paginate).then(function (result) {
            var _a, _b;
            _this.postS.isLoading(true);
            _this.previousPageable = { size: result.per_page, page: result.page };
            _this.pagination = result;
            result.items.forEach(function (el) {
                var _a;
                el['missingLocations'] = el.planned - ((_a = el.locationsRef) === null || _a === void 0 ? void 0 : _a.length);
            });
            _this.dataSource = new MatTableDataSource(result.items);
            _this.postsExist = ((_b = (_a = _this.dataSource) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.length) > 0;
            _this.progressPost = false;
            if (_this.post_type === 'Pending') {
                _this.bulkPostList = result.items.filter(function (bl) { return (bl.status === BULK_POST_STATUS_PARTIAL_PUBLISH || bl.status === POST_STATUS_PENDING || bl.status === POST_STATUS_PENDING_DELETE || bl.status === POST_STATUS_PENDING_UPDATE) && !bl.hasError; });
            }
        });
    };
    PostListComponent.prototype.sortByActions = function ($event) {
        this.sort.sortBy = $event.active;
        this.sort.direction = $event.direction;
        this.ngOnInit();
    };
    PostListComponent.prototype.isDisplayField = function (status) {
        return (status === POST_STATUS_PENDING_UPDATE || status === POST_STATUS_PENDING || status === POST_STATUS_PENDING_DELETE || status === BULK_POST_STATUS_PARTIAL_PUBLISH);
    };
    PostListComponent.prototype.isPendingStatus = function (status) {
        return (status === POST_STATUS_PENDING_UPDATE || status === POST_STATUS_PENDING || status === POST_STATUS_PENDING_DELETE || status === BULK_POST_STATUS_PARTIAL_PUBLISH);
    };
    PostListComponent.prototype.getMessage = function (status) {
        return this.statusMessages[status];
    };
    PostListComponent.prototype.getDate = function (element) {
        return moment(element.scheduled.date).parseZone().toDate();
    };
    PostListComponent.prototype.getStatus = function (status) {
        return status === POST_STATUS_PENDING_UPDATE || status === POST_STATUS_PENDING || status === POST_STATUS_PENDING_DELETE ? 'IN PROGRESS' : status.toUpperCase();
    };
    PostListComponent.prototype.loadLocaLocations = function (bulk_id) {
        var _this = this;
        this.loadingLocationsRef = true;
        this.postS.getBulkLocationPost(bulk_id).then(function (data) {
            _this.locationRef = data;
            _this.loadingLocationsRef = false;
        });
    };
    PostListComponent.prototype.edit = function (element) {
        var _this = this;
        var type = element.status || null;
        var schedule = element.scheduled || null;
        var recurring = element.recurring || null;
        var dialog = this.dialog.open(ModalLocalPostsComponent, {
            width: '700px',
            data: {
                post: element,
                posts: this.bulkPost,
                accounts: this.bulkPost ? convertMetaToAccountsReports(element.locationsRef) : null,
                type: type,
                schedule: schedule,
                recurring: recurring,
                locationId: this.locationId,
                accountId: this.accountId,
                post_type: this.post_type
            }
        });
        dialog.afterClosed().subscribe(function (saved) {
            if (saved) {
                _this.ngOnInit();
            }
        });
    };
    PostListComponent.prototype.adjustScheduleTime = function (date) {
        return moment.utc(date).local().toDate();
    };
    PostListComponent.prototype.getTime = function (rawTime) {
        var time = rawTime.split(':');
        var hours = Number(time[0]);
        var minutes = Number(time[1]);
        var timeValue;
        if (hours > 0 && hours <= 12) {
            timeValue = "" + hours;
        }
        else if (hours > 12) {
            timeValue = "" + (hours - 12);
        }
        else if (hours == 0) {
            timeValue = "12";
        }
        timeValue += (minutes < 10) ? ":0" + minutes : ":" + minutes;
        timeValue += (hours >= 12) ? " PM" : " AM";
        return timeValue;
    };
    PostListComponent.prototype.savePostManagement = function (postManagement) {
        var _this = this;
        if (this.locationId) {
            postManagement.placeId = this.locationId;
            postManagement.accountId = this.accountId;
        }
        this.postManagementS.save(this.auth.session.gid, this.accountId, this.locationId, postManagement).then(function () {
            _this.isSpinner.emit(false);
            _this.selected.emit(1);
        });
    };
    PostListComponent.prototype.copy = function (postId) {
        return __awaiter(this, void 0, void 0, function () {
            var type;
            return __generator(this, function (_a) {
                if (!postId || postId === "")
                    return [2 /*return*/];
                type = this.bulkPost ? 'BULK' : 'LOCAL';
                this.postS.copy(postId, type, 'Drafts').then();
                return [2 /*return*/];
            });
        });
    };
    PostListComponent.prototype.delete = function (postId, status) {
        var _this = this;
        this.modalS.openConfirmModal('Are you sure you want to delete this post?', 'This action cannot be undone!', function (result) {
            if (result) {
                _this.isSpinner.emit(true);
                if (postId) {
                    var type = _this.bulkPost ? 'BULK' : 'LOCAL';
                    var redirectTo = _this.redirectTo[status];
                    _this.isSpinner.emit(false);
                    _this.postS.deleteV3(postId, type, redirectTo).then(function () {
                        _this.refresh();
                    });
                }
            }
        }, 2);
    };
    PostListComponent.prototype.disableAction = function (status) {
        return (status === POST_STATUS_PENDING || status === POST_STATUS_PENDING_DELETE || status === POST_STATUS_PENDING_UPDATE);
    };
    PostListComponent.prototype.disableDeleteAction = function (element) {
        var date = this.adjustScheduleTime(element.createdAt);
        var hoursDiff = Math.floor((Math.abs(+new Date(date) - +new Date()) / 1000) / 3600) % 24;
        if ((element.status === POST_STATUS_PENDING ||
            element.status === POST_STATUS_PENDING_DELETE ||
            element.status === POST_STATUS_PENDING_UPDATE) && hoursDiff === 0) {
            return true;
        }
        return false;
    };
    PostListComponent.prototype.getSizeColumn = function (name) {
        var index = this.displayedColumns.indexOf(name);
        if (index >= 0) {
            return this.ColumnsSize[index] + "%";
        }
    };
    PostListComponent.prototype.handleReload = function ($event) {
        this.paginate = $event;
        this.progressPost = true;
        if (this.dashboardType === 'LOCAL_POST') {
            this.bulkPost = false;
            this.postData();
        }
        else if (this.dashboardType === 'BULK_POST') {
            this.bulkPost = true;
            this.bulkData();
        }
    };
    PostListComponent.prototype.refresh = function () {
        this.paginate = { page: 1, size: 10 };
        this.progressPost = true;
        if (this.dashboardType === 'LOCAL_POST') {
            this.bulkPost = false;
            this.postData();
        }
        else if (this.dashboardType === 'BULK_POST') {
            this.bulkPost = true;
            this.bulkData();
        }
    };
    PostListComponent.prototype.openSchedule = function (element) {
        var _this = this;
        var _a, _b;
        var schedule = null;
        if ((_a = element) === null || _a === void 0 ? void 0 : _a.toScheduleDelete) {
            schedule = (_b = element) === null || _b === void 0 ? void 0 : _b.scheduled;
        }
        this.modalS.openGenericModal(ModalSchedulePostComponent, {
            schedule: schedule,
            isDeletePost: true,
            title: 'Scheduled Delete'
        }, function (result) {
            if (result) {
                _this.isSpinner.emit(true);
                var cancelSchedule = Object.prototype.hasOwnProperty.call(result, 'cancel');
                var type = _this.bulkPost ? 'BULK' : 'LOCAL';
                var data = result.data ? result.data : null;
                _this.postS.scheduledDeleteV3(element.id, data, cancelSchedule, type, 'Posted').then(function () {
                    _this.progressPost = false;
                    _this.isSpinner.emit(false);
                }).catch(function () {
                    _this.progressPost = false;
                    _this.isSpinner.emit(false);
                });
            }
        }, 470);
    };
    PostListComponent.prototype.ngAfterViewChecked = function () {
        this.detectChanges();
    };
    PostListComponent.prototype.detectChanges = function () {
        if (this.cdRef !== null &&
            this.cdRef !== undefined &&
            !this.cdRef.destroyed) {
            this.cdRef.detectChanges();
        }
    };
    PostListComponent.prototype.openError = function (element) {
        var _this = this;
        var dialog = this.modalS.openGenericModal(PostsErrorsComponent, {
            bulk: this.bulkPost,
            location: element,
            bulkId: element.id
        }, function () { return null; }, 1000);
        dialog.afterClosed().subscribe(function (saved) {
            if (saved) {
                _this.refresh();
            }
        });
    };
    PostListComponent.prototype.getCountTotalViews = function (info) {
        return info.reduce(function (response, data) { return response += data.totalViews; }, 0);
    };
    PostListComponent.prototype.getCountTotalActions = function (info) {
        return info.reduce(function (response, data) { return response += data.totalAction; }, 0);
    };
    return PostListComponent;
}());
export { PostListComponent };
