<!--Regular Hours-->
<div class="box__item box__item--editable">
    <div class="d-flex w--100" [ngClass]="'align-items-start'">
        <div class="icon icon--rounded icon--gray mr--20"><i class="far fa-shopping-basket"></i></div>
            <div class="w--100">
                <div *ngIf="priceList" class="w--100 row">
                    <ng-container *ngFor="let price of priceList">
                        <div class="col-12"><h5 class="txt--uppercase"><strong>{{ price?.labels[0].displayName }}</strong></h5></div>
                        <div class="col-10 mx-auto">
                            <div class="container-fluid" *ngFor="let section of price.sections">
                                <div class="row">
                                    <div class="col-12">
                                        <strong>- {{ (section?.labels)[0]?.displayName }}</strong>
                                        <div class="container-fluid " *ngFor="let item of section.items">
                                            <div class="row" style="margin-top: 10px;margin-bottom: 10px;">
                                                <div class="col-8 mx-auto">
                                                    <p>
                                                        {{(item?.labels)[0]?.displayName}}
                                                        <span
                                                        class="d-block txt--sm txt--lightgray"
                                                        *ngIf="(item?.labels)[0]?.description">
                                                            {{ (item?.labels)[0]?.description }}
                                                        </span>
                                                    </p>
                                                </div>
                                                <div class="col-4 mx-auto">
                                                    <ng-container *ngIf="(item?.price)?.currencyCode; else noCurrencyCode">
                                                        <p>{{(item?.price)?.units  | currency:(item?.price)?.currencyCode}}</p>
                                                    </ng-container>
                                                    <ng-template #noCurrencyCode>
                                                        <p>{{(item?.price)?.units  | currency}}</p>
                                                    </ng-template>
                                                    
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
  </div>
  