import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var GradeLoadedService = /** @class */ (function () {
    function GradeLoadedService() {
        this.loaded$ = new BehaviorSubject(false);
    }
    GradeLoadedService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GradeLoadedService_Factory() { return new GradeLoadedService(); }, token: GradeLoadedService, providedIn: "root" });
    return GradeLoadedService;
}());
export { GradeLoadedService };
