
        <div class="dialog__header dialog__header--info" >
		    <h2 mat-dialog-title class="m--0 txt--xl"><strong>{{ data.title }}</strong></h2>
	    </div>
        <div class="dialog__content dialog__content--height" mat-dialog-content>
            <div class="dialog-row">
                <p>{{data.description}}</p>
                <ul>
                    <li *ngFor="let location of data.DeletedLocations">
                        <h5 class="m--0" ><b>{{ location.locationName }}</b></h5>
                        <gmb-address [address]="location.address" ></gmb-address>
                    </li>
                </ul>
            </div>
        </div>
    
        <div mat-dialog-actions class="dialog__footer">
            <div class="alternate-theme d-inline-block mr--10">
                <button tabIndex="-1" mat-button color="primary" [mat-dialog-close]="false" class="btn btn-cancel"></button>
            </div>
            <button tabIndex="-1" mat-flat-button [color]="'accent'" class="btn btn--action" (click)="applyDelete()">Continue</button>
        </div>
    