<!-- TRIAL subscriptions -->
<div class="trial-message js-trial-message" 
     *ngIf="!isMember() && (subscription$ | async)?.status == GROUP_SUBSCRIPTION_TYPE.TRIAL">

	<p class="m--0">
		Only {{(subscription$ | async)?.daysInTrial}} days remaining in trial!
		<button mat-button 
			id="upgradeTrialLocBtn"
			class="btn btn--white btn--xs ml--10"
			(click)="openUpgrade()"
			*ngIf="(subscription$ | async)?.pricingVersion < 3">
			Upgrade Now
		</button>
	</p>
</div>

<!-- Essential Location of non-TRIAL subscriptions -->
<div class="trial-message js-trial-message"
	*ngIf="!isMember() &&
	(subscription$ | async)?.pricingVersion < 3 && 
	(subscription$ | async)?.status !== GROUP_SUBSCRIPTION_TYPE.TRIAL && 
	((location$ | async)?.subscriptionType == LOCATION_SUBSCRIPTION_TYPE.FREE || 
	 (location$ | async)?.subscriptionType == LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL) &&
	isOnLocation"
>
	<p class="m--0">
		This is an Essential Location!
		<button mat-button id="upgradeFreeLocBtn" class="btn btn--white btn--xs ml--10" (click)="openUpgrade()">Upgrade Now</button>
	</p>
</div>

<!-- TODO: Unused, remove
<div class="trial-message js-trial-message" *ngIf="isOnMasterAdmin">
	<p class="m--4">Master Admin</p>
</div>
-->
