import { __assign, __awaiter, __generator, __values } from "tslib";
// TODO: Refactor against admin-frontend/auth.service.ts
import { ReplaySubject, BehaviorSubject, combineLatest } from 'rxjs';
import { NgZone, isDevMode, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import 'rxjs/add/observable/from';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { map, share } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Md5 } from 'ts-md5/dist/md5';
// app
import { GroupService } from './group.service';
import { UserService } from './user.service';
import { WhiteLabelService } from './white-label.service';
import { SnackbarService } from './snackbar.service';
import { Messages, string_message } from '../constants/messages';
import { IS_IMPERSONATING, SESSION, SESSION_EXTERNAL, STORAGE_ALL_KEYS } from '../constants/session';
import { GROUP_SUBSCRIPTION_TYPE } from "../constants/firestore/account-location";
import { NOTIFICATION_GENERAL, TYPE_LOG_USER } from "../constants/notifications";
import { DomainService } from './domain.service';
import { NotificationService } from './notification.service';
import { VerificationEmailService } from '../services/verification-email.service';
import { AlertComponent, AlertType } from '../components/alert.component';
import { SpinnerService } from './spinner.service';
import { PaymentsService } from "./payments.service";
import { ModalService } from "./modal.service";
import { environment as ENV } from '@environment';
import { AuthProxyService } from './auth.proxy.service';
import { HEADERS_NO_AUTH, MAIL_EXTERNAL_GRADE, MAIL_ANONYMOUS } from '../constants/auth';
import { asPromisedObservable, isRunningEmbedded } from '../helpers/utils.helpers';
import { SessionTraceService } from './session-trace.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/auth";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common/http";
import * as i4 from "./group.service";
import * as i5 from "./white-label.service";
import * as i6 from "./user.service";
import * as i7 from "./snackbar.service";
import * as i8 from "./domain.service";
import * as i9 from "./notification.service";
import * as i10 from "./verification-email.service";
import * as i11 from "./auth.proxy.service";
import * as i12 from "./spinner.service";
import * as i13 from "./payments.service";
import * as i14 from "./modal.service";
import * as i15 from "./session-trace.service";
var AuthService = /** @class */ (function () {
    function AuthService(
    // dep
    afAuth, _router, _http, _ngZone, 
    // app
    _groupService, _wl, _userService, _snack, _domainService, _notificationService, _verificationService, _authProxyService, _spinnerService, _paymentsService, _modalService, _sessionTraceService) {
        var _this = this;
        this.afAuth = afAuth;
        this._router = _router;
        this._http = _http;
        this._ngZone = _ngZone;
        this._groupService = _groupService;
        this._wl = _wl;
        this._userService = _userService;
        this._snack = _snack;
        this._domainService = _domainService;
        this._notificationService = _notificationService;
        this._verificationService = _verificationService;
        this._authProxyService = _authProxyService;
        this._spinnerService = _spinnerService;
        this._paymentsService = _paymentsService;
        this._modalService = _modalService;
        this._sessionTraceService = _sessionTraceService;
        this.user$ = new BehaviorSubject({});
        this.userFeatures = new Promise(function (resolve, reject) {
            _this._featureResolve = resolve;
        });
        this._disableSessionCallbacks = false;
        this._accessToken = null;
        this._groupIn$ = new ReplaySubject(1);
        this.groupOut$ = asPromisedObservable(this._groupIn$);
        this._subscriptionIn$ = new ReplaySubject(1);
        this.subscription$ = asPromisedObservable(this._subscriptionIn$); // TODO: Rename to subscriptionOut$
        if (!isRunningEmbedded()) {
            this.initSession().then(); // TODO: Intention was to await?
        }
        if (!this._domainService.domain$.value) {
            this.searchDomain$ = this._domainService.searchDomain$;
            this.searchDomain$.subscribe(function (domain) {
                _this._domainService.domain$.next(domain);
            });
        }
        //// Subscribe to Firestore Auth state changes
        // onAuthStateChanged():
        // Prior to 4.0.0, this triggered the observer when users were signed in, signed out, 
        // or when the user's ID token changed in situations such as token expiry or password change. 
        // After 4.0.0, the observer is only triggered on sign-in or sign-out.
        this.afAuth.auth.onAuthStateChanged(
        // this.afAuth.authState.pipe(map(
        function (user) {
            // It was observed that at this point sometimes this._router.url and this._route.snapshot.url 
            // are equal to '/'. Maybe a services initialization race condition? Weird, because
            // here router should be fully initialized. Changed to window.location.
            // '/' ? Services initialization race condition? Changed to window.location.pathname
            var _a, _b, _c, _d;
            // const segments = this._route.snapshot.url.map(segment => segment.path);
            // const url = '/' + segments.join('/');
            // const {url} = this._router // this._route.snapshot
            var url = window.location.pathname;
            if (((_a = user) === null || _a === void 0 ? void 0 : _a.email) === MAIL_ANONYMOUS && !(url.startsWith('/widget') ||
                url.startsWith('/report') ||
                url.startsWith('/reports'))) {
                console.error('Unauthorized anonymous login to ' + url);
                _this.signOut(true, true);
                return;
            }
            if (_this._disableSessionCallbacks)
                return;
            if (!user)
                return;
            if (!_this.session)
                _this.setSession(user);
            else if ((_b = _this.session) === null || _b === void 0 ? void 0 : _b.gid) {
                _this._checkUser((_c = _this.session) === null || _c === void 0 ? void 0 : _c.gid, (_d = _this.session) === null || _d === void 0 ? void 0 : _d.uid);
            }
            // this.forceAuthRefresh()
            // user.getIdToken(true).then(token => {
            //   BAD: This changed epheremal session getter object with no real effect
            //   this.session?.authToken = token;
            // });
        });
        // onIdTokenChanged():
        // Adds an observer for changes to the signed-in user's ID token, which includes sign-in, 
        // sign-out, and token refresh events. This method has the same behavior as 
        // firebase.auth.Auth.onAuthStateChanged had prior to 4.0.0.
        // Also check:
        // https://github.com/angular/angularfire/issues/2694#issuecomment-734052171
        this.afAuth.auth.onIdTokenChanged(function (user) { return __awaiter(_this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (this._disableSessionCallbacks)
                            return [2 /*return*/];
                        // Update the accessToken for events not triggered by forceAuthRefresh
                        // (e.g., when firebase-sdk refreshes it automatically in one of his non
                        // angular intercepted queries)
                        //
                        // console.log('idTokenChanged changed handler', user)
                        _a = this._setAccessToken;
                        if (!user) return [3 /*break*/, 2];
                        return [4 /*yield*/, user.getIdToken()];
                    case 1:
                        _b = _c.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        _b = null;
                        _c.label = 3;
                    case 3:
                        // Update the accessToken for events not triggered by forceAuthRefresh
                        // (e.g., when firebase-sdk refreshes it automatically in one of his non
                        // angular intercepted queries)
                        //
                        // console.log('idTokenChanged changed handler', user)
                        _a.apply(this, [_b, 'onIdTokenChanged']);
                        return [2 /*return*/];
                }
            });
        }); });
        // Firebase can start with a token already loaded (from cookies
        // or localStorage?)
        // console.log('token at start=', this.session?.authToken  )
        window.addEventListener('storage', function (ev) { return _this._onStorageEventFromOtherTab(ev); });
    }
    AuthService.prototype.ngOnDestroy = function () {
        this.subscription$.destroy();
        this.groupOut$.destroy();
    };
    Object.defineProperty(AuthService.prototype, "session", {
        /**
         * Returns the session as saved in LocalStorage
         */
        get: function () {
            var session = localStorage.getItem(SESSION);
            if (!session)
                return null;
            var parseSession = JSON.parse(session); // TODO: Validate
            if (parseSession.email === MAIL_EXTERNAL_GRADE) {
                this.signOut();
                return null;
            }
            return parseSession;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "authenticated", {
        get: function () {
            return !!this.afAuth.auth.currentUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "isAdmin", {
        get: function () {
            var _a, _b;
            var role = (_b = (_a = this.session) === null || _a === void 0 ? void 0 : _a.role) === null || _b === void 0 ? void 0 : _b.toLowerCase();
            return role === 'admin' || role === 'master_admin';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "isMember", {
        get: function () {
            var _a, _b;
            return ((_b = (_a = this.session) === null || _a === void 0 ? void 0 : _a.role) === null || _b === void 0 ? void 0 : _b.toLowerCase()) === 'member';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "externalSession", {
        get: function () {
            var session_ext = localStorage.getItem(SESSION_EXTERNAL);
            if (!session_ext)
                return null;
            var parseSession = JSON.parse(session_ext); // TODO: validate
            return parseSession;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "headers", {
        get: function () {
            var _a, _b, _c;
            var h;
            if (this.anonymous)
                h = { 'gid': ((_a = this.session) === null || _a === void 0 ? void 0 : _a.gid) || '',
                };
            else if (this.gradeExternal)
                h = { 'uid': this.gradeExternalUser.uid,
                    'gid': this.gradeExternalUser.gid };
            else
                h = { 'uid': ((_b = this.session) === null || _b === void 0 ? void 0 : _b.uid) || '',
                    'gid': ((_c = this.session) === null || _c === void 0 ? void 0 : _c.gid) || '',
                    'Authorization': 'Bearer ' + this._accessToken,
                    'domain': this._wl.apiUrl || ''
                };
            return { headers: new HttpHeaders(h) };
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.initSession = function (forceGid) {
        if (forceGid === void 0) { forceGid = null; }
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var featuresP, group, f, sub, s, createdAt;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (forceGid) {
                            localStorage.setItem(SESSION, JSON.stringify(__assign(__assign({}, this.session), { gid: forceGid })));
                        }
                        if (!(this.session && ((_a = Object.keys(this.session)) === null || _a === void 0 ? void 0 : _a.length) == 1)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.processSubscription()];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2:
                        // Non-anonymous
                        if (!this.session)
                            return [2 /*return*/];
                        featuresP = this._userService.getUserFeature(this.session.uid).toPromise();
                        this.setSession(this.session); // JWT: Not present before merge
                        this.datadogInit();
                        return [4 /*yield*/, this._groupService.getByDocId(this.session.gid).toPromise()];
                    case 3:
                        group = _b.sent();
                        this.updateGroup(group);
                        return [4 /*yield*/, featuresP];
                    case 4:
                        f = _b.sent();
                        this._featureResolve(f);
                        // Reloading subcription to keep data fresh
                        if (this._processSubscriptionTimer) {
                            clearInterval(this._processSubscriptionTimer);
                            this._processSubscriptionTimer = null;
                        }
                        this._processSubscriptionTimer = setInterval(function () { _this.processSubscription(); }, 120000);
                        return [4 /*yield*/, this.processSubscription()]; // TODO: Redundant processSubscription?
                    case 5:
                        sub = _b.sent() // TODO: Redundant processSubscription?
                        ;
                        s = this.session;
                        try {
                            createdAt = new firebase.firestore.Timestamp(s.createdAt.seconds, s.createdAt.nanoseconds).toDate().toISOString();
                        }
                        catch (_c) {
                            //Anonymous case
                            createdAt = "2024-01-01T00:00:00.000Z";
                        }
                        this._sessionTraceService.setEnableGtag(ENV.ga4Enabled);
                        // TODO: Replace 'bento' feature with a generic 'userGuides' after MAP-2240 deployment
                        this._sessionTraceService.setEnableUserGuiding(ENV.userGuidingEnabled && (f.userFeatures.bento || f.generalFeatures.bento));
                        this._sessionTraceService.onLogin(s.uid, {
                            domain: this._wl.baseDomain || '',
                            gid: this.session.gid,
                            email: s.email,
                            name: s.displayName || s.company || s.email,
                            created_at: createdAt,
                            isTrial: sub.status === GROUP_SUBSCRIPTION_TYPE.TRIAL,
                            essentialListings: group.freeLocationsCount || 0,
                            basicListings: group.basicLocationsCount || 0,
                            ultimateListings: group.ultimateLocationsCount || 0,
                            totalListings: group.totalLocationsCount || 0,
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.forceAuthRefresh = function () {
        return __awaiter(this, void 0, void 0, function () {
            var user, token;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        user = firebase.auth().currentUser;
                        if (!user)
                            throw Error("No user");
                        return [4 /*yield*/, user.getIdToken(/*forceRefresh =*/ true)
                            // onIdTokenChanged will be called here synchronously
                        ];
                    case 1:
                        token = _a.sent();
                        // onIdTokenChanged will be called here synchronously
                        this._setAccessToken(token, 'forceAuthRefresh');
                        return [2 /*return*/, token];
                }
            });
        });
    };
    AuthService.prototype.updateGroup = function (group) {
        this._groupIn$.next(group);
    };
    AuthService.prototype.processSubscription = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var gid, sub, trialEndNumericDate, group, err_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        gid = (_a = this.session) === null || _a === void 0 ? void 0 : _a.gid;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 4, , 7]);
                        if (!gid)
                            throw ('No session/gid');
                        return [4 /*yield*/, this.getSubscription(gid)];
                    case 2:
                        sub = _b.sent();
                        trialEndNumericDate = (sub.trialEnd['$date'] || sub.trialEnd);
                        sub.daysInTrial = Math.round(Math.abs((new Date(trialEndNumericDate).getTime() - Date.now()) / (86400 * 1000)));
                        return [4 /*yield*/, this.groupOut$.getValue()];
                    case 3:
                        group = _b.sent();
                        if (group.dismissModalDatePicker)
                            sub.dismissModalDatePicker = group.dismissModalDatePicker;
                        this._subscriptionIn$.next(sub);
                        return [2 /*return*/, sub];
                    case 4:
                        err_1 = _b.sent();
                        console.error("Error loading subscription gid=" + gid + " err=" + err_1);
                        this._spinnerService.loading$.next(false);
                        return [4 /*yield*/, this._modalService.openErrorModal('Loading error', ('Error while loading the subscription. ' +
                                'Please try again or contact support (error code 5)'))];
                    case 5:
                        _b.sent();
                        return [4 /*yield*/, this.signOut(true, true)];
                    case 6:
                        _b.sent();
                        throw (''); // not really throwed as the page is reloaded on signOut
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    AuthService.getSession = function () {
        return JSON.parse(localStorage.getItem(SESSION));
    };
    AuthService.prototype.setSession = function (user) {
        if (user)
            localStorage.setItem(SESSION, JSON.stringify(user));
    };
    AuthService.prototype.googleLogin = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var provider, authState;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        provider = new firebase.auth.GoogleAuthProvider();
                        provider.setCustomParameters({
                            prompt: 'select_account'
                        });
                        return [4 /*yield*/, this.afAuth.auth.signInWithPopup(provider)];
                    case 1:
                        authState = _c.sent();
                        if (!((_b = (_a = authState) === null || _a === void 0 ? void 0 : _a.additionalUserInfo) === null || _b === void 0 ? void 0 : _b.isNewUser)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.afAuth.auth.currentUser.delete()];
                    case 2:
                        _c.sent();
                        _c.label = 3;
                    case 3: return [4 /*yield*/, this._afterLogin(authState)];
                    case 4:
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.signInWithImpersonateToken = function (impersonate_token) {
        return __awaiter(this, void 0, void 0, function () {
            var authState, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afAuth.auth.signInWithCustomToken(impersonate_token)];
                    case 1:
                        authState = _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.debug("error impersonating", e_1);
                        return [2 /*return*/, (e_1.code + ' ' + e_1.message)];
                    case 3:
                        localStorage.setItem(IS_IMPERSONATING, 'true');
                        return [4 /*yield*/, this._afterLogin(authState)];
                    case 4:
                        _a.sent();
                        return [2 /*return*/, null];
                }
            });
        });
    };
    AuthService.prototype.signInWithEmailAndPassword = function (email, password) {
        return __awaiter(this, void 0, void 0, function () {
            var authState;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.afAuth.auth.signInWithEmailAndPassword(email, password)];
                    case 1:
                        authState = _a.sent();
                        return [4 /*yield*/, this._afterLogin(authState)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.signOut = function (redirectToLogin, reloadPage) {
        if (redirectToLogin === void 0) { redirectToLogin = true; }
        if (reloadPage === void 0) { reloadPage = false; }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._ngZone.run(function () { return __awaiter(_this, void 0, void 0, function () {
                            var _a, _b, f, e_2, e_3_1;
                            var e_3, _c;
                            var _this = this;
                            return __generator(this, function (_d) {
                                switch (_d.label) {
                                    case 0:
                                        console.debug("signOut(): redirectToLogin=" + redirectToLogin + " reloadPage=" + reloadPage);
                                        if (!(redirectToLogin && reloadPage) && !this.afAuth.auth.currentUser) {
                                            console.debug('signout(): already logged out');
                                            return [2 /*return*/];
                                        }
                                        if (this._processSubscriptionTimer) {
                                            clearInterval(this._processSubscriptionTimer);
                                            this._processSubscriptionTimer = null;
                                        }
                                        _d.label = 1;
                                    case 1:
                                        _d.trys.push([1, 8, 9, 10]);
                                        _a = __values([(function () { return _this._sessionTraceService.onLogout(); }),
                                            (function () { return _this.storageRemoveAll(); }),
                                            (function () { return _this.afAuth.auth.signOut(); })]), _b = _a.next();
                                        _d.label = 2;
                                    case 2:
                                        if (!!_b.done) return [3 /*break*/, 7];
                                        f = _b.value;
                                        _d.label = 3;
                                    case 3:
                                        _d.trys.push([3, 5, , 6]);
                                        return [4 /*yield*/, f()];
                                    case 4:
                                        _d.sent();
                                        return [3 /*break*/, 6];
                                    case 5:
                                        e_2 = _d.sent();
                                        console.error("signOut(): error", e_2);
                                        return [3 /*break*/, 6];
                                    case 6:
                                        _b = _a.next();
                                        return [3 /*break*/, 2];
                                    case 7: return [3 /*break*/, 10];
                                    case 8:
                                        e_3_1 = _d.sent();
                                        e_3 = { error: e_3_1 };
                                        return [3 /*break*/, 10];
                                    case 9:
                                        try {
                                            if (_b && !_b.done && (_c = _a.return)) _c.call(_a);
                                        }
                                        finally { if (e_3) throw e_3.error; }
                                        return [7 /*endfinally*/];
                                    case 10:
                                        if (!redirectToLogin) return [3 /*break*/, 12];
                                        console.debug('signout(): reload');
                                        return [4 /*yield*/, this._router.navigate(['/login'], reloadPage ? { replaceUrl: true } : undefined)];
                                    case 11:
                                        _d.sent();
                                        if (reloadPage) {
                                            // Ensure refresh on logout to mitigate memory leaks and oversubscribed observers
                                            // If we don't that, some observers still run and try to make http requests
                                            // without valid authorization headers
                                            window.location.reload();
                                        }
                                        _d.label = 12;
                                    case 12: return [2 /*return*/];
                                }
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    // Maybe this needs its own GMB service?
    // But it means the token we already saved..?
    AuthService.prototype.getGmbToken = function () {
        var _this = this;
        var _a, _b, _c, _d;
        var getByGidDocId_1 = this._userService.getByGidDocId((_a = this.session) === null || _a === void 0 ? void 0 : _a.gid, (_b = this.session) === null || _b === void 0 ? void 0 : _b.uid).valueChanges();
        var getByGidDocId_2 = this._userService.getByGidDocId((_c = this.session) === null || _c === void 0 ? void 0 : _c.gid, "backup_" + ((_d = this.session) === null || _d === void 0 ? void 0 : _d.uid)).valueChanges();
        return combineLatest(getByGidDocId_1, getByGidDocId_2).pipe(map(function (value) {
            var _a, _b;
            var user1 = value[0];
            var user2 = value[1];
            var googleAuth = null;
            if (user1 !== null && user1 !== undefined) {
                _this.afAuth.auth.currentUser.getIdToken().then(function (token) {
                    user1.authToken = token;
                    _this.setSession(user1);
                });
                googleAuth = (_a = user1) === null || _a === void 0 ? void 0 : _a.googleAuth;
            }
            else {
                // TODO: Maybe user2 is also null?
                _this.afAuth.auth.currentUser.getIdToken().then(function (token) {
                    user2.authToken = token;
                    _this.setSession(user2);
                });
                googleAuth = (_b = user2) === null || _b === void 0 ? void 0 : _b.googleAuth;
            }
            return googleAuth;
        }));
    };
    // TODO: Move to subscription.service.ts (without creating a circular dep)
    AuthService.prototype.getSubscription = function (gid) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._http.get(ENV.billingApiUrl + "/subscription/" + gid + "/without-locations").toPromise()];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    /**
     * this method with email and password create user account in firebase auth
     */
    AuthService.prototype.createUserWithEmailAndPassword = function (email, password) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.afAuth.auth.createUserWithEmailAndPassword(email, password)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Create group$ and user with gid and get of firebase token id and set in localstorage session user
     * @param newUser  Response firestore  User
     * @param displayName  name for User.ts
     * @param company  company for User.ts
     */
    AuthService.prototype.createUserAndGroup = function (newUser, displayName, company, needsVerification) {
        if (displayName === void 0) { displayName = null; }
        if (company === void 0) { company = ''; }
        if (needsVerification === void 0) { needsVerification = false; }
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var authUser, name, user, group, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        authUser = newUser.user;
                        name = authUser.displayName || displayName;
                        user = this._userService.transformUser(authUser.uid, authUser.email, name, company, authUser.photoURL);
                        return [4 /*yield*/, this._initNewGroup({ users: [user.uid],
                                admin: user.uid,
                                domain: this._wl.domainUser,
                                company: company })];
                    case 1:
                        group = _e.sent();
                        user.gid = group['gid'];
                        user.role = 'admin';
                        user.createdAt = new Date(); // FIXME: Browser local time but must be server UTC time. 
                        user.displayName = name;
                        if (needsVerification) {
                            user.emailVerified = null;
                            user.emailVerificationHash = (new Md5()).appendStr("" + authUser.uid + user.gid + user.displayName).end();
                        }
                        return [4 /*yield*/, this._userService.save(user)];
                    case 2:
                        _e.sent();
                        try {
                            this._notificationService.saveNotification(user.gid, this._wl.baseDomain, string_message(Messages.notifications.USER_ADDED, [user.displayName, user.role]), NOTIFICATION_GENERAL, TYPE_LOG_USER, this._notificationService.getMetaTypeLog(TYPE_LOG_USER, user));
                        }
                        catch (e) {
                            console.error("error sending notification", e);
                        }
                        ///// TODO: Refactor against session callbacks
                        this.setSession(user);
                        if (!!needsVerification) return [3 /*break*/, 4];
                        if ((_a = this.session) === null || _a === void 0 ? void 0 : _a.gid) {
                            this._checkUser((_b = this.session) === null || _b === void 0 ? void 0 : _b.gid, (_c = this.session) === null || _c === void 0 ? void 0 : _c.uid);
                        }
                        _d = this._setAccessToken;
                        return [4 /*yield*/, this.afAuth.auth.currentUser.getIdToken(true)];
                    case 3:
                        _d.apply(this, [_e.sent(), "createUserAndGroup"]);
                        _e.label = 4;
                    case 4: 
                    ///// 
                    return [2 /*return*/, this.session];
                }
            });
        });
    };
    /**
     * Creates an account on Firebase Auth using a Google account popup
     * Here the UID is created
     */
    AuthService.prototype.registerWithGoogle = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var newUser, displayName, e_4;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        try {
                            // Just in case
                            this.storageRemoveAll();
                        }
                        catch (_c) {
                            // pass
                        }
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, , 9, 10]);
                        // Set this flag to disable session initialization triggered by callbacks called by
                        // signInWithPopup before the user setup is completely finished
                        this._disableSessionCallbacks = true;
                        return [4 /*yield*/, this.afAuth.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider())];
                    case 2:
                        newUser = _b.sent();
                        if (!newUser.additionalUserInfo.isNewUser)
                            throw 'This account is already registered. Try logging in.';
                        _b.label = 3;
                    case 3:
                        _b.trys.push([3, 6, , 7]);
                        displayName = (_a = newUser.additionalUserInfo.profile) === null || _a === void 0 ? void 0 : _a.name;
                        if (!displayName) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.afAuth.auth.currentUser.updateProfile({ displayName: displayName })];
                    case 4:
                        _b.sent();
                        _b.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        e_4 = _b.sent();
                        console.error("Error updating new user profile:", e_4);
                        return [3 /*break*/, 7];
                    case 7:
                        this.gradeExternal = false;
                        return [4 /*yield*/, this.createUserAndGroup(newUser)];
                    case 8:
                        _b.sent();
                        return [3 /*break*/, 10];
                    case 9:
                        this._disableSessionCallbacks = false;
                        return [7 /*endfinally*/];
                    case 10: return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.signInAnonymously = function (gid) {
        if (gid === void 0) { gid = null; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.anonymous = true;
                        this.gradeExternal = false;
                        return [4 /*yield*/, this.afAuth.auth.signInWithEmailAndPassword(MAIL_ANONYMOUS, 'password')];
                    case 1:
                        _a.sent();
                        if (!!isRunningEmbedded()) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.initSession(gid)
                            // localStorage.setItem(SESSION, JSON.stringify({ gid }));
                        ];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.redirectAfterLogin = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var r, accounts, err_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.isMember) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._router.navigate(['/accounts'])];
                    case 1:
                        _b.sent();
                        return [3 /*break*/, 7];
                    case 2:
                        _b.trys.push([2, 6, , 7]);
                        return [4 /*yield*/, this._http.get(ENV.apiUrl + "/v2/accounts/" + ((_a = this.session) === null || _a === void 0 ? void 0 : _a.gid) + "/all?page=1&pageSize=25&accountIds=").toPromise()];
                    case 3:
                        r = _b.sent();
                        accounts = r["items"];
                        return [4 /*yield*/, this._router.navigate([accounts.length > 0 ? "accounts/" + accounts[0].accountId + "/locations" : '/accounts'])];
                    case 4:
                        _b.sent();
                        return [4 /*yield*/, this._alertIfNotPaymentMethod()];
                    case 5:
                        _b.sent();
                        return [3 /*break*/, 7];
                    case 6:
                        err_2 = _b.sent();
                        console.error(err_2);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.createExternalGradeUser = function () {
        return __awaiter(this, void 0, void 0, function () {
            var user_1, e_5, user;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.gradeExternal = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 6]);
                        return [4 /*yield*/, this.afAuth.auth.signInWithEmailAndPassword(MAIL_EXTERNAL_GRADE, 'external')];
                    case 2:
                        user_1 = _a.sent();
                        return [2 /*return*/, this._userService.getUserByUid(user_1.user.uid).pipe(map(function (result) { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    if (result.docs.length == 0)
                                        return [2 /*return*/, this.createUserAndGroup(user_1, 'external-grade', 'maplabs.com')];
                                    else
                                        return [2 /*return*/, result.docs[0].data()];
                                    return [2 /*return*/];
                                });
                            }); })).toPromise().then()]; // TODO: "then" intention is to await? bad
                    case 3:
                        e_5 = _a.sent();
                        if (!(e_5.code === 'auth/user-not-found')) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.createUserWithEmailAndPassword(MAIL_EXTERNAL_GRADE, 'external')];
                    case 4:
                        user = _a.sent();
                        return [2 /*return*/, this.createUserAndGroup(user, 'external-grade', 'maplabs.com')];
                    case 5: return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.storageRemoveAll = function () {
        var e_6, _a;
        console.debug("localStorage: Removing all");
        try {
            for (var STORAGE_ALL_KEYS_1 = __values(STORAGE_ALL_KEYS), STORAGE_ALL_KEYS_1_1 = STORAGE_ALL_KEYS_1.next(); !STORAGE_ALL_KEYS_1_1.done; STORAGE_ALL_KEYS_1_1 = STORAGE_ALL_KEYS_1.next()) {
                var k = STORAGE_ALL_KEYS_1_1.value;
                try {
                    localStorage.removeItem(k);
                }
                catch (_b) {
                    //pass
                }
            }
        }
        catch (e_6_1) { e_6 = { error: e_6_1 }; }
        finally {
            try {
                if (STORAGE_ALL_KEYS_1_1 && !STORAGE_ALL_KEYS_1_1.done && (_a = STORAGE_ALL_KEYS_1.return)) _a.call(STORAGE_ALL_KEYS_1);
            }
            finally { if (e_6) throw e_6.error; }
        }
    };
    AuthService.prototype.datadogInit = function () {
        var _a, _b;
        var uid = (_a = this.session) === null || _a === void 0 ? void 0 : _a.uid;
        var email = (_b = this.session) === null || _b === void 0 ? void 0 : _b.email;
        if (ENV.env == 'prod') {
            // Now loading via asycn so need to ensure we 'wait' via onReady
            var DD_RUM_1 = window.DD_RUM;
            DD_RUM_1.onReady(function () {
                DD_RUM_1.setUser({
                    'id': uid,
                    'email': email
                });
            });
        }
    };
    AuthService.prototype._afterLogin = function (user) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, this.afAuth.auth.currentUser.getIdToken().then(function (token) {
                        return _this._userService.getUserByUid(user.user.uid).toPromise()
                            .then(function (data) { return [data.docs[0].data()]; })
                            .then(function (value) { return __awaiter(_this, void 0, void 0, function () {
                            var isEmailVerified;
                            var _this = this;
                            var _a, _b, _c, _d, _e, _f;
                            return __generator(this, function (_g) {
                                switch (_g.label) {
                                    case 0:
                                        if (!!value[0]) return [3 /*break*/, 1];
                                        this.signOut();
                                        this._snack.openError(Messages.register.USER_ALREADY_EXIST, 4000);
                                        return [3 /*break*/, 3];
                                    case 1: return [4 /*yield*/, this._userService.getEmailIsVerified(value[0])];
                                    case 2:
                                        isEmailVerified = _g.sent();
                                        this.setSession(value[0]);
                                        if (!this.session)
                                            return [2 /*return*/];
                                        // TODO: Superflous user re-fetch?
                                        this._userService.getUserByUid(user.user.uid).pipe(map(function (result) {
                                            return result.docs.length > 0 ? result.docs[0].data() : null;
                                        })).toPromise().then(function (u) {
                                            if (u) {
                                                _this._userService.updateUser(u.gid, u.uid, { lastLogin: firebase.firestore.Timestamp.now() });
                                            }
                                        });
                                        if (isEmailVerified) {
                                            value[0].authToken = token;
                                            this.setSession(value[0]);
                                            if ((_a = this.session) === null || _a === void 0 ? void 0 : _a.gid) {
                                                this._checkUser((_b = this.session) === null || _b === void 0 ? void 0 : _b.gid, (_c = this.session) === null || _c === void 0 ? void 0 : _c.uid);
                                            }
                                            this._userService.domainValidation(location.hostname, (_d = this.session) === null || _d === void 0 ? void 0 : _d.gid, (_e = this.session) === null || _e === void 0 ? void 0 : _e.uid, (_f = this.session) === null || _f === void 0 ? void 0 : _f.domainSurfing).subscribe(function (res) {
                                                // The isDevMode flag should be present here to enable impersonation during development.
                                                if (!res['allowLogin'] && !isDevMode()) {
                                                    console.debug("Domain validation FAILED");
                                                    _this._ngZone.run(function () { return __awaiter(_this, void 0, void 0, function () {
                                                        return __generator(this, function (_a) {
                                                            switch (_a.label) {
                                                                case 0: return [4 /*yield*/, this._modalService.openErrorModal('Heads up', "Sorry, we couldn't find your account. " +
                                                                        "Please check your username and password or contact support.")];
                                                                case 1:
                                                                    _a.sent();
                                                                    return [4 /*yield*/, this.signOut(true, true)];
                                                                case 2:
                                                                    _a.sent();
                                                                    return [2 /*return*/];
                                                            }
                                                        });
                                                    }); });
                                                }
                                                else {
                                                    _this.initSession();
                                                    _this.redirectAfterLogin(); // TODO: then() intention is to await?
                                                }
                                            }, function (err) { return console.log(err); });
                                            return [2 /*return*/, value[0]];
                                        }
                                        else {
                                            this._verificationService.getVerification(value[0].uid, value[0].gid).toPromise().then(function (view) {
                                                view.forEach(function (v) {
                                                    if (!v) {
                                                        return;
                                                    }
                                                    var data = v.data();
                                                    if (data.emailVerified == null) {
                                                        _this.signOut();
                                                        _this._snack.openError(Messages.register.EMAIL_VERIFIED, 4000);
                                                    }
                                                    else {
                                                        _this._userService.getByGidDocId(data.key2, data.key1).get().map(function (doc) { return doc.data(); }).take(1).subscribe(function (user) {
                                                            var _a, _b, _c, _d;
                                                            if (user) {
                                                                // TODO: Should be done server-side!
                                                                user.emailVerified = firebase.firestore.Timestamp.now();
                                                                _this._userService.altUpdate(user);
                                                            }
                                                            user.authToken = token;
                                                            _this.setSession(user);
                                                            if ((_a = _this.session) === null || _a === void 0 ? void 0 : _a.gid) {
                                                                _this._checkUser((_b = _this.session) === null || _b === void 0 ? void 0 : _b.gid, (_c = _this.session) === null || _c === void 0 ? void 0 : _c.uid);
                                                            }
                                                            _this._groupService.getByDocId((_d = _this.session) === null || _d === void 0 ? void 0 : _d.gid).toPromise().then(function (group) {
                                                                _this.updateGroup(group);
                                                            });
                                                            _this.initSession();
                                                            _this.redirectAfterLogin(); // TODO: "then()" intention is to await? bad
                                                            return user;
                                                        });
                                                    }
                                                });
                                            });
                                        }
                                        _g.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); });
                    })];
            });
        });
    };
    AuthService.prototype._checkUser = function (gid, uid) {
        var _this = this;
        // TODO: this setups a subscription on userIndex, multiple calls to checkUser will
        // register multiple (redundant) subscriptions. Probably an observer leak here.
        this.userIndex$ = this._userService.get(gid, uid).pipe(share(), map(function (indexUser) {
            _this.user$.next(indexUser);
            var user = __assign(__assign({}, _this.session), indexUser);
            _this.setSession(user);
            _this._userService.updateSession(user);
            return indexUser;
        }));
        this.userIndex$.subscribe();
    };
    AuthService.prototype._initNewGroup = function (group) {
        return this._http.post(ENV.apiUrl + "/v2/auth/signup", group, HEADERS_NO_AUTH).toPromise();
    };
    AuthService.prototype._setAccessToken = function (accessToken, debugStr) {
        var _this = this;
        this._accessToken = accessToken;
        console.debug(debugStr + ": accessToken changed", accessToken);
        if (accessToken && !this._authProxyService.initialized)
            this._authProxyService.initialize(function () { return _this.signOut(true, true); }, function () { return _this.headers; }, function () { return _this.forceAuthRefresh(); });
    };
    /**
     * Alert the user if he has a paid Subscription and no Credit Card configured
     */
    AuthService.prototype._alertIfNotPaymentMethod = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var gid, sub, group, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        gid = (_a = this.session) === null || _a === void 0 ? void 0 : _a.gid;
                        return [4 /*yield*/, this.getSubscription(gid)];
                    case 1:
                        sub = _c.sent();
                        if (!(sub.status !== GROUP_SUBSCRIPTION_TYPE.TRIAL &&
                            sub.collectionByBillingOverride[sub.billingOverride.toString()].requiresPaymentMethod)) return [3 /*break*/, 5];
                        return [4 /*yield*/, this._groupService.getByDocId(gid).toPromise()];
                    case 2:
                        group = _c.sent();
                        _b = (group.basicLocationsCount || group.ultimateLocationsCount);
                        if (!_b) return [3 /*break*/, 4];
                        return [4 /*yield*/, this._paymentsService.hasPaymentMethods(gid)];
                    case 3:
                        _b = !(_c.sent());
                        _c.label = 4;
                    case 4:
                        if (_b) {
                            this._modalService.openModal(AlertComponent, {
                                title: 'Heads up',
                                content: "You don't have a Credit Card set up. Please add one.",
                                closeButtonLabel: 'Close',
                                alertType: AlertType.ERROR,
                                disableCancelButton: true
                            });
                        }
                        _c.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype._onStorageEventFromOtherTab = function (event) {
        // console.debug('storage', event)
        if (event.key === SESSION && (!event.newValue || !JSON.parse(event.newValue))) {
            console.debug('Detected signOut from another tab');
            this.signOut(true, true);
        }
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.AngularFireAuth), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.HttpClient), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i4.GroupService), i0.ɵɵinject(i5.WhiteLabelService), i0.ɵɵinject(i6.UserService), i0.ɵɵinject(i7.SnackbarService), i0.ɵɵinject(i8.DomainService), i0.ɵɵinject(i9.NotificationService), i0.ɵɵinject(i10.VerificationEmailService), i0.ɵɵinject(i11.AuthProxyService), i0.ɵɵinject(i12.SpinnerService), i0.ɵɵinject(i13.PaymentsService), i0.ɵɵinject(i14.ModalService), i0.ɵɵinject(i15.SessionTraceService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
