import { PipeTransform } from '@angular/core';
var HourAmPmSchedulePipe = /** @class */ (function () {
    function HourAmPmSchedulePipe() {
    }
    HourAmPmSchedulePipe.prototype.transform = function (value) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        value = value.toUpperCase().replace('AM', '');
        value = value.toUpperCase().replace('PM', '');
        var strHours = value.split(':')[0];
        var strMinutes = value.split(':')[1];
        var hours = parseInt(strHours);
        var meridian;
        if (hours == 0) {
            hours = 12;
            meridian = 'am';
        }
        else if (hours > 12) {
            meridian = 'pm';
            hours = hours - 12;
        }
        else if (hours == 12) {
            meridian = 'pm';
        }
        else {
            meridian = 'am';
        }
        return (hours < 10 ? '0' + hours : hours) + ":" + strMinutes + " " + meridian.toUpperCase();
    };
    return HourAmPmSchedulePipe;
}());
export { HourAmPmSchedulePipe };
