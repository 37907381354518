import { DateRangePicker } from "@syncfusion/ej2-angular-calendars";
import { createElement, isUndefined } from "@syncfusion/ej2-base";

export const alwaysShowCalendar = () => {
    (DateRangePicker as any).prototype.createPopup = function () {
        for (let i: number = 0; i < this.presetsItem.length; i++) {
            if ((i !== (this.presetsItem.length - 1)) && this.presetsItem[i].id === 'custom_range') {
                this.presetsItem.splice(i, 1);
            }
        }
        this.activeIndex = this.presetsItem.length - 1;
        this.isCustomRange = true;
        for (let i: number = 0; i <= this.presetsItem.length - 2; i++) {
            let startDate: Date = this.presetsItem[i].start as Date;
            let endDate: Date = this.presetsItem[i].end as Date;
            if (this.startValue && this.endValue) {
                if ((+startDate.setMilliseconds(0) === +this.startValue.setMilliseconds(0)) &&
                    (+endDate.setMilliseconds(0) === +this.endValue.setMilliseconds(0))) {
                    this.activeIndex = i;
                    this.isCustomRange = true;
                }
            }
        }
        this.popupWrapper = createElement('div', { id: this.element.id + '_popup', className: 'e-daterangepicker' + ' ' + 'e-popup' });
        let isPreset: boolean = (!this.isCustomRange || this.isMobile);
        if (!isUndefined(this.presets[0].start && this.presets[0].end && this.presets[0].label) && isPreset) {
            this.isCustomWindow = false;
            this.createPresets();
            this.listRippleEffect();
            this.renderPopup();
        } else {
            this.isCustomWindow = true;
            this.renderControl();
        }
    }
}