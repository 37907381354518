import { OnInit, EventEmitter } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { LocationService } from '../../../services/location.service';
import { ModalMoreHoursComponent } from '../modal-more-hours/modal-more-hours.component';
var LocationMoreHoursComponent = /** @class */ (function () {
    function LocationMoreHoursComponent(dialog, locationS) {
        this.dialog = dialog;
        this.locationS = locationS;
        this.moreHours = [];
        this.moreHoursLabels = [];
        this.isEdit = false;
        this.isUnderReview = false;
        this.countRegularHours = 0;
        this.onUpdate = new EventEmitter();
        this.originalOrder = function (a, b) { return 0; };
    }
    LocationMoreHoursComponent.prototype.ngOnInit = function () {
        var _a;
        if (this.moreHoursLabels && ((_a = this.moreHours) === null || _a === void 0 ? void 0 : _a.length)) {
            this.moreHours = this.moreHours.sort(function (a, b) { return a.hoursTypeId < b.hoursTypeId ? -1 : 1; });
            this.buildPeriods();
            this.getLabelsName();
        }
        else if (!this.moreHoursLabels) {
            this.getLabels();
        }
    };
    LocationMoreHoursComponent.prototype.getLabels = function () {
        var _this = this;
        var _a, _b, _c;
        this.locationS.getRef((_a = this.location) === null || _a === void 0 ? void 0 : _a.gid, (_b = this.location) === null || _b === void 0 ? void 0 : _b.accountId, (_c = this.location) === null || _c === void 0 ? void 0 : _c.locationId).subscribe(function (res) {
            var _a, _b, _c, _d;
            _this.moreHoursLabels = ((_b = (_a = res) === null || _a === void 0 ? void 0 : _a.location.primaryCategory) === null || _b === void 0 ? void 0 : _b.moreHoursTypes) || [];
            (_d = (_c = res) === null || _c === void 0 ? void 0 : _c.location.additionalCategories) === null || _d === void 0 ? void 0 : _d.map(function (el) {
                el.moreHoursTypes.forEach(function (h) {
                    var hasLabel = _this.moreHoursLabels.find(function (l) { return l.hoursTypeId == h.hoursTypeId; }) ? true : false;
                    if (!hasLabel) {
                        _this.moreHoursLabels.push(h);
                    }
                });
            });
            _this.buildPeriods();
            _this.getLabelsName();
        });
    };
    LocationMoreHoursComponent.prototype.buildPeriods = function () {
        var _this = this;
        this.moreHoursFormatted = JSON.parse(JSON.stringify(this.moreHours || []));
        this.moreHoursFormatted.forEach(function (el) {
            el.formattedPeriods = _this.locationS.verifyOpen(el.periods);
            el.formattedPeriods = _this.locationS.getContinuousHours(el.formattedPeriods);
            el.periodsByDay = _this.locationS.sortPeriodsByDay(el.formattedPeriods);
        });
    };
    LocationMoreHoursComponent.prototype.getLabelsName = function () {
        var _this = this;
        this.moreHoursFormatted.forEach(function (el) {
            var _a, _b;
            var label = (_a = _this.moreHoursLabels) === null || _a === void 0 ? void 0 : _a.find(function (l) { return (l.hoursTypeId).toLowerCase() == (el.hoursTypeId).toLowerCase(); });
            el.displayName = (_b = label) === null || _b === void 0 ? void 0 : _b.displayName;
        });
    };
    LocationMoreHoursComponent.prototype.openMoreHoursDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ModalMoreHoursComponent, {
            width: '680px',
            data: {
                moreHours: this.moreHoursFormatted,
                labels: this.moreHoursLabels
            }
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.onUpdate.emit(true);
            }
        });
    };
    return LocationMoreHoursComponent;
}());
export { LocationMoreHoursComponent };
