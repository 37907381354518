<div class="dialog__header" *ngIf="!bulk">
  <h2 mat-dialog-title class="m--0 txt--xl"><strong>{{title | titlecase}}</strong></h2>
</div>
<div class="dialog__content" mat-dialog-content>
  <div class="row">
    <div class="alert alert-warning w--100" role="alert" *ngIf="hasErrorSpecialHours">
      <p>
        <em class="fas fa-exclamation-triangle"></em>
        Since your business profile already has special hours and/or more hours set, you cannot remove your regular hours. If you want to remove your regular hours, please first delete your special hours and/or your more hours. Alternatively, mark your listing opening status as "temporarily closed" if your business will not be open any day of the week.
      </p>
    </div>
    <div class="col-lg-3 offset-lg-3">
      <p class="txt--sm txt--lightgray txt--uppercase mb--10">Opens at:</p>
    </div>
    <div class="col-lg-4">
      <p class="txt--sm txt--lightgray txt--uppercase mb--10">Closes at:</p>
    </div>
  </div>
  <div class="row mb--10" *ngFor="let day of formattedPeriods | keyvalue: originalOrder; let i = index;">
    <div class="col-12 col-lg-12">
      <ng-container *ngFor="let hours of day.value; let i = index;">
        <div class="row align-items-center mb--10" *ngIf="(!hours.open && i === 0) || hours.open">
          <div class="col-6 col-lg-2">
            <p *ngIf="i === 0" class="m--0"><strong>{{ day.key }}</strong></p>
          </div>

          <div class="col-6 col-lg-1">
            <mat-slide-toggle
                *ngIf="i === 0"
                color="primary"
                [checked]="hours.open"
                (change)="changeOpenState($event, day)">
            </mat-slide-toggle>
          </div>

          <ng-container *ngIf="hours.open">
            <!--startTime-->
            <div class="col-5 col-lg-3">
              <mat-form-field class="input-group m--0 input-group--select no-padding">
                <mat-select [(value)]="hours.openTime" class="input-group__select">
                  <mat-divider></mat-divider>
                  <mat-option [value]="h" *ngFor="let h of regularHoursList">{{h}}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-error *ngIf="!hours?.openTime && clickApply"><p>Open time is required.</p></mat-error>
            </div>

            <!--endTime-->
            <div class="col-5 col-lg-3">
              <mat-form-field class="input-group m--0 input-group--select no-padding">
                <mat-select [(value)]="hours.closeTime" class="input-group__select">
                  <mat-divider></mat-divider>
                  <mat-option [value]="h" *ngFor="let h of regularHoursList">{{h}}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-error *ngIf="!hours?.closeTime && clickApply"><p>Close time is required.</p></mat-error>
            </div>

            <div class="col-2 col-lg-1 p--0">
              <button *ngIf="day.value.length > 1" mat-flat-button color="warn" class="btn btn-icon mr--5" (click)="deleteHour(hours)">
                    <i class="far fa-times"></i>
              </button>
            </div>

            <div class="col-2 col-lg-2  p--0" *ngIf="(i+1) === day.value.length">
              <button mat-button color="primary" *ngIf="hours.open" (click)="addNewHour(hours)">Add hours</button>
            </div>

            <mat-error class="ml--15" *ngIf="areAcceptedHours(hours) && clickApply">
              <p>Please enter a valid time range. Start and end time can't be the same unless they're both 12:00 am (to designate that you're open 24 hours).</p>
            </mat-error>

          </ng-container>
          <div class="col-12 col-lg-6 text-center m--10" *ngIf="!hours.open">
            <div *ngIf="i === 0">
              Closed
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div mat-dialog-actions class="dialog__footer" *ngIf="!bulk">
  <span class="mb--10 txt--lightgray">
    <small>
      * Note: Adding overlapping hours will result in a hour sets being merged. 8am-2pm and 12pm-6pm will result in 8am-6pm
    </small>
  </span>
  <span class="alternate-theme d-inline-block mr--10">
      <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
  </span>
  <button mat-flat-button (click)="apply()"  tabIndex="-1" color="accent" class="btn btn--action">Apply</button>
</div>
