import { EventEmitter, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { REGEX_COMPLEX_URL } from 'src/app/constants/regex';
import { LocationEditService } from "../../services/location-edit.service";
var ModalUrlComponent = /** @class */ (function () {
    function ModalUrlComponent(dialogRef, locationEditS, data) {
        this.dialogRef = dialogRef;
        this.locationEditS = locationEditS;
        this.data = data;
        this.bulk = false;
        this.validResult = new EventEmitter(false);
        if (data)
            this.url = data.companyUrl;
        else
            this.url = '';
    }
    ModalUrlComponent.prototype.ngOnInit = function () {
    };
    ModalUrlComponent.prototype.isValidField = function () {
        return REGEX_COMPLEX_URL.test(this.url);
    };
    ModalUrlComponent.prototype.apply = function () {
        var _this = this;
        var _a;
        if (this.url && !this.isValidField())
            return;
        this.url = (_a = this.url) === null || _a === void 0 ? void 0 : _a.replace(/ /g, '%20');
        this.locationEditS.locationEdit.websiteUrl = this.url;
        this.locationEditS.setAndUpdate().toPromise().then(function () {
            _this.dialogRef.close();
        });
    };
    ModalUrlComponent.prototype.getResult = function () {
        var _a;
        if (this.url && !this.isValidField())
            return;
        if (this.url === '') {
            this.validResult.emit(false);
            return;
        }
        this.url = (_a = this.url) === null || _a === void 0 ? void 0 : _a.replace(/ /g, '%20');
        this.validResult.emit(true);
        return { websiteUrl: this.url };
    };
    return ModalUrlComponent;
}());
export { ModalUrlComponent };
