import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl} from '@angular/forms';
import { /*ActivatedRoute,*/ Router} from '@angular/router';
import * as _ from 'lodash';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { GroupService } from '../../services/group.service';
import { SearchIndexElastic } from '../../constants/firestore/group';
import { DomainService } from '../../services/domain.service';
import { NotificationService } from '../../services/notification.service';
// import {SnackbarService} from '../../services/snackbar.service';
import { LocationService } from '../../services/location.service';
import { ModalService } from '../../services/modal.service';
import { environment } from '@environment';
import { NOTIFICATION_ERROR_FETCH_DATA, NOTIFICATION_ERROR_LOCATION, NOTIFICATION_WARNING, NOTIFICATION_GENERAL, NOTIFICATION_PROTOCOLS } from '../../constants/notifications';
// import { GoogleService } from '../../services/google.service';
import { MatDialog } from '@angular/material';
import { ModalFetchComponent } from '../modal-fetch/modal-fetch.component';
import { Pageable } from '../../constants/pageable';
import { Pagination } from '../../constants/api-response';
import { GlobalSearchService } from '../../services/global-search.service';
import { AccountService } from '../../services/account.service';
import { takeUntil } from 'rxjs/operators';
// import { ModalGetAddOn } from '../modal-get-addon/modal-get-addon.component';
import { ISubscription } from '../../constants/subscription';


const FlexSearch = require('flexsearch');

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  fetchProgress = false;
  @Input() isReport: boolean;
  @Input() noToggle: boolean;

  // NOTIFICATIONS TYPES
  fetchLocationInfoData = NOTIFICATION_ERROR_FETCH_DATA;
  authFail = NOTIFICATION_ERROR_LOCATION;
  oldAuthFail = NOTIFICATION_WARNING;
  informative = NOTIFICATION_GENERAL;
  type_protocol = NOTIFICATION_PROTOCOLS;
  page = 1;
  size = 5;
  loadingNotifications: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private paginate: Pageable = {size: this.size, page: this.page};
  private previousPageable: Pageable={page: 1, size: 5};
  loadingNoti = false;
  pagination: Pagination = {
    items: [],
    per_page: this.size,
    page: this.page,
    hasNext: false,
    hasPrev: false,
    pages: 0,
    total: 0
  };

  @Output() public sidenavToggle = new EventEmitter();
  flexSearch = new FlexSearch({
    encode: "advanced",
    tokenize: "reverse",
    suggest: true,
    cache: true,
    doc: {
      id: 'id',
      field: [
        'accountName',
        'locationName',
        'locName',
        'address',
        'reportName',
        'labels'
      ]
    }
  });
  results: SearchIndexElastic[];
  selectedSearch = new FormControl();
  isUser = false;
  countNotification = 0;
  notifications: any[] = [];
  isShared: boolean;
  private searchSubject = new Subject<string>();
  env;
  user;
  public isLoading = true;

  public showChromeWarning = false;
  public subscription: ISubscription;
  public bulkActionsEnabled = false;
  private _destroy$ = new Subject<boolean>()
  
  branding: any;

  constructor(
    // TODO: commented out services are not used, remove if not needed
    // private googleS: GoogleService,
    // private snack: SnackbarService,
    // private route: ActivatedRoute,

    // Public attributes for services
    public auth: AuthService,
    public domainService: DomainService,
    public dialog: MatDialog,
    // Pivate attributes for services
    private _router: Router,
    private _groupS: GroupService,
    private _notificationS: NotificationService,
    private _locationS: LocationService,
    private _globalSearchService: GlobalSearchService,
    private _accountS: AccountService,
    private _modalService: ModalService,

  ) {
    this.user = this.auth.session;
    this.env = environment;
    this.isShared = _.endsWith(this._router.url, 'shared');
    this._untilDestroy(this.auth.subscription$).subscribe(sub => {
      this.subscription = sub
      this.bulkActionsEnabled = (
        (this.subscription?.pricingVersion < 3) || (
          this.subscription?.pricingVersion >= 3 &&
          this.subscription?.packages?.hasOwnProperty('pkg_bulk_actions')
        ) 
      )
      console.log('Subscription in header:', this.subscription)
      console.log('Bulk actions: ', this.bulkActionsEnabled? "enabled" : "disabled")
    });
    
    if (!this.auth.session?.gid) {
      return;
    }

    this._groupS.getIndexes(this.auth.session.gid).subscribe((result: Array<any>) => {
      if (!result || result.length === 0) {
        return;
      }


      for (const index of result) {
        index.id = JSON.stringify(index);
        _.has(index, 'address') && (index.address = this._locationS.formatAddress(index.address));
        if (this.auth.session.role && !this.auth.isAdmin) {
          if (index.type !== 'location' && index.type !== 'account') {
            continue;
          }
          if (index.type === 'account') {
            const accounts = this.auth.session.accounts;
            const findAccount = accounts.find(it => it.accountId === index.source);
            if (!findAccount) {
              continue;
            }
          } else {
            const dataLoc = index.source.split('/');
            const accountId = dataLoc[0];
            const locationId = dataLoc[1];
            const accounts = this.auth.session.accounts;
            const findAccount = accounts.find(it => it.accountId === accountId);
            if (!findAccount) {
              continue;
            }
            const findLoc = findAccount.locations.find(it => it.locationId === locationId);
            if (!findLoc) {
              continue;
            }
          }
        }
        this.addDoc(index);
      }

      this.isUser = true;
    });

  }

  private _untilDestroy<T>(o : Observable<T>) : Observable<T> {
    return o.pipe(takeUntil(this._destroy$))
  }

  capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  ngOnDestroy(): void {
    this._destroy$.next(true)
    this._destroy$.unsubscribe()
  }

  ngOnInit(): void {

    if (!this.auth.session?.gid) {
      return;
    }
    this.searchSubject.pipe(
      debounceTime(500)
    ).subscribe(result => {
      this.search(result);
    });
    if (!this.auth.session) {
      return;
    }
    /*this.notificationS.countNotifications(this.auth.session.gid).subscribe(count => {
      this.countNotification = count;
    });*/


    //this.getNotifications(this.paginate);

    if (this.isReport) {
      return;
    }

  }

  getNotifications(paginate: Pageable): void {
    let last = null;
    let next = null;
    if (this.previousPageable) {
      if (this.previousPageable.page < paginate.page) {
        if (this.notifications) {
          last = this.notifications[(paginate.size * (paginate.page - 1)) - 1];
        }
      } else if (this.previousPageable.page > paginate.page) {
        if (this.notifications) {
          next = this.notifications[0];
        }
      }
    }
    this._notificationS.paginateNotifications(this.auth.session.gid, paginate, last, next).subscribe( (result) => {
      this.loadingNoti = false;
      this.notifications.push(...result.items);
      this.countNotification = result.total;
      this.pagination = result;
      this.previousPageable = {size: result.per_page, page: result.page}
      this.loadingNotifications.next(false);
    });

  }

  getReportTypeString(input: string): string{
    const data  = {
      'keyword': 'Keyword',
      'qanda': 'Questions and Answers',
      'review': 'Review',
      'rollup': 'Performand Rollup',
      'revenue': 'Revenue',
      'performance-revenue': 'Performance Revenue',
      'performance-rollup': 'Performance Rollup',
      'performance-comparison': 'Performance Comparison',
      'comparison': 'Comparison',
      'grade': 'Grade',
    };
    
    return data[input] ? data[input] : input;
  }

  clearAllNotifications(): void {
    this._notificationS.deleteAll(this.auth.session.gid);
  }


  deleteAll(): void {
    this._notificationS.deleteAll(this.auth.session.gid);
  }

  deleteNotification(n): void {
    this._notificationS.deleteNotification(this.auth.session.gid, n.id);
  }

  openFetchDialog(result, accountId, locationId, notifyErrors=false): void {
    const dialogRef = this.dialog.open(ModalFetchComponent, {
      width: '1000px',
      data: {
        difference: result.difference,
        placeId: locationId,
        history: null,
        notifyErrors
      }
    });

    dialogRef.disableClose = true;

    dialogRef.afterClosed().subscribe(refresh => {
      if (refresh) {
        this._router.navigate(['/account', accountId, 'location', locationId, 'location-info']).then();
      }

    });
  }

  handleReload(e): void {
    e.preventDefault();
    if (!this.loadingNoti) {
      const $event: Pageable = {
        page: (this.paginate.page + 1),
        size: 5
      }
      this.loadingNoti = true;
      this.paginate = $event;
      //this.getNotifications($event);
    }

  }

  handleLogout() : void {
    this.auth.signOut(true, true)
  }

  // TODO: referenced from a commented-out section on header.component.html, obsolete? if yes remove.
  // viewed(n): void {
  //   switch (n.type) {
  //     case 'protocols':
  //       this.router.navigate(['/review-assistant']).then();
  //       break;
  //     case 'warning':
  //       this.router.navigate([`/accounts/${n.accountId}/locations`]).then();
  //       break;
  //     case NOTIFICATION_ERROR_LOCATION:
  //       this.router.navigate([`/accounts/${n.accountId}/locations`]).then();
  //       break;
  //     case NOTIFICATION_ERROR_FETCH_DATA:
  //       this.fetchProgress = true;
  //       this.googleS.fetchDifference(n.accountId, n.location.locationId).take(1).subscribe(result => {
  //         this.fetchProgress = false;
  //         this.openFetchDialog(result, n.accountId, n.location.locationId, true);
  //       }, error => {
  //         this.fetchProgress = false;
  //         this.snack.openError('failed to fetch', 4000);
  //       });
  //       break;
  //     case NOTIFICATION_GENERAL:
  //       break;
  //     default:
  //       this.router.navigate(['/account', n.accountId, 'location', n.location.locationId, 'workshop']).then();
  //       break;
  //   }
  //   // this.notificationS.merge(this.auth.session.gid, n.id, {status: 'viewed'}).then(() => {
  //   //
  //   // });
  // }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  };


  addDoc(doc) {
    this.flexSearch.add(doc);
  }


  handleChangeSearch($event: any) {
    this.searchSubject.next($event);
  }

  search($event: any) {
    const text = $event.target.value.toLowerCase();
    if(!text){
      this.results = [];
      return;
    }
    this._globalSearchService.search(text, this.auth.session.gid).subscribe(result =>{
      this.results = result;
    });
  }

  display(value): string {
    if (!value) {
      return null;
    }

    switch (value.type) {
      case 'account':
        return `${value.accountName}`;
      case 'location':
        return `${value.locationName} `;
      case 'report':
        return `${value.type}: ${value.reportName}`;
    }
  }

  private validateVerifiedStatus(location: any) : boolean {
    if (location?.locationState?.isVerified == false) {
      this._modalService.openErrorModal(
        "Verification required in Google Business Profile",
        `<div class='txt--left'>
          This location requires verification, which prevents access to your dashboard.
          <div>
            <br>Step 1: Login to your Google Business Profile and verify your location.
            <br>Step 2: Once verified, return to Map Labs and click the refresh button.
          </div>
        </div>`
      );
      return false
    }
    return true
  }

  selected(): void {
    const value = this.selectedSearch.value;

    if (value?.type == "location" && !this.validateVerifiedStatus(value)){
      return
    }

    let url = '';
    switch (value.type) {
      case 'account':
        url = `/accounts/${value.accountId}/locations`;
        break;
      case 'location':
        url = `/account/${value.accountId}/location/${value.locationId}/insights`;
        break;
      case 'report':
        url = `/report/${value.gid}/${value.reportId}/${value.reportType}`;
        break;
    }
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.navigate([url])
  }

  goToAdmin() : void {
    window.open('https://admin.maplabs.com/', '_blank', 'noopener');
  }

  async goToProfiles() : Promise<void> {
    if (this._router.url.includes('share')) {
      this._router.navigate(['/']);
    } else {
      const accounts = (await this._accountS.getAccountPaginate(this.user.gid, {page: 1, size: 5}, [])).items
      this._router.navigate([accounts.length > 0 ? 
                            `accounts/${accounts[0]?.accountId}/locations` : 
                            'accounts'
                          ])
    }
  }

}
