import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { REGEX_COMPLEX_URL } from 'src/app/constants/regex';
import {LocationEditService} from "../../services/location-edit.service";

@Component({
  selector: 'app-modal-url',
  templateUrl: './modal-url.component.html',
  styleUrls: ['./modal-url.component.scss']
})
export class ModalUrlComponent implements OnInit {
  public url: string;

  @Input() bulk: boolean = false;
  @Output() validResult: EventEmitter<boolean> = new EventEmitter(false);

  constructor(public dialogRef: MatDialogRef<ModalUrlComponent>,
              private locationEditS:LocationEditService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) this.url = data.companyUrl;
    else this.url = ''
  }

  ngOnInit() {
  }

  isValidField(): boolean {
    return REGEX_COMPLEX_URL.test(this.url);
  }

  apply(): void {
    if (this.url && !this.isValidField()) return;
    
    this.url = this.url?.replace(/ /g, '%20');
    this.locationEditS.locationEdit.websiteUrl = this.url;
    this.locationEditS.setAndUpdate().toPromise().then( ()=>{
      this.dialogRef.close();
    });
  }

  getResult(): { [k: string]: string } {
    if (this.url && !this.isValidField()) return;
    if (this.url === '') {
      this.validResult.emit(false);
      return
    }
    this.url = this.url?.replace(/ /g, '%20');
    this.validResult.emit(true);
    return {websiteUrl: this.url }
  }

}
