import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
var ModalFormItemComponent = /** @class */ (function () {
    function ModalFormItemComponent(dialogRef, data, decimalPipe) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.decimalPipe = decimalPipe;
        this.modelResponse = [];
        this.isEdit = false;
        this.typeFormPrice = 'fixed';
    }
    ModalFormItemComponent.prototype.ngOnInit = function () {
        this.isEdit = this.data.edit;
        this.initForm();
        if (this.data.menuItems) {
            this.isEdit = true;
            this.modelResponse = this.data.menuItems;
            var items = this.modelResponse[0];
            this.typeFormPrice = this.data.typePrice;
            var editAttributes = void 0;
            if (items.attributes && items.attributes.price) {
                var roundNanos = items.attributes.price.nanos ? items.attributes.price.nanos.toString().substr(0, 2) : '00';
                var units = items.attributes.price.units || '0';
                editAttributes = {
                    currencyCode: items.attributes.price.currencyCode,
                    units: units + "." + ((roundNanos === '0') ? '00' : roundNanos),
                    type: this.typeFormPrice
                };
            }
            else {
                editAttributes = {
                    currencyCode: 'USD',
                    units: '',
                    type: this.typeFormPrice
                };
            }
            this.getItemArray(0).setValue({
                displayName: items.labels[0].displayName || '',
                description: items.labels[0].description || '',
                languageCode: 'en',
                attributes: editAttributes
            });
        }
    };
    ModalFormItemComponent.prototype.remove = function () {
        this.dialogRef.close({ action: 'remove', item: this.modelResponse });
    };
    ModalFormItemComponent.prototype.apply = function () {
        var FormResult = this.form.value;
        var items = FormResult.items[0];
        var units = '0';
        var nanos = 0;
        if (items.attributes.type !== 'free' && items.attributes.type !== 'noPrice') {
            var amount = items.attributes.units.toString().split('.');
            units = amount[0];
            nanos = Number(amount[1]);
        }
        this.modelResponse = [{
                labels: [
                    {
                        displayName: items.displayName,
                        description: items.description,
                        languageCode: items.languageCode
                    }
                ],
                attributes: {
                    price: {
                        currencyCode: items.attributes.currencyCode,
                        units: units,
                        nanos: nanos
                    },
                    servesNumPeople: 1,
                    mediaKeys: [],
                }
            }];
        if (items.attributes.type === 'noPrice') {
            delete this.modelResponse[0].attributes.price;
        }
        if (this.isEdit) {
            this.dialogRef.close({ action: 'update', item: this.modelResponse });
        }
        else {
            this.dialogRef.close({ action: 'added', item: this.modelResponse });
        }
    };
    Object.defineProperty(ModalFormItemComponent.prototype, "ItemsArray", {
        get: function () {
            return this.form.get('items');
        },
        enumerable: true,
        configurable: true
    });
    ModalFormItemComponent.prototype.getItemArray = function (index) {
        return this.form.get('items').at(index);
    };
    ModalFormItemComponent.prototype.initForm = function () {
        this.form = new FormGroup({
            items: new FormArray([new FormGroup({
                    displayName: new FormControl('', [Validators.required, Validators.maxLength(140)]),
                    description: new FormControl('', [Validators.maxLength(1000)]),
                    languageCode: new FormControl('en'),
                    attributes: new FormGroup({
                        currencyCode: new FormControl('USD', [Validators.required]),
                        units: new FormControl('0', [Validators.required, Validators.pattern('^[+-]?[0-9]{1,5}(?:,?[0-9]{3})*(?:\.[0-9]{2})?'), Validators.min(0)]),
                        type: new FormControl(this.typeFormPrice, Validators.required),
                    })
                })])
        });
    };
    ModalFormItemComponent.prototype.onChangePrice = function (index) {
        var formInput = this.getItemArray(index).get('attributes.units');
        var currency = this.decimalPipe.transform(formInput.value, '1.2-2');
        formInput.setValue(currency.replace(/,/g, ''));
    };
    return ModalFormItemComponent;
}());
export { ModalFormItemComponent };
