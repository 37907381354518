// dep
import { Component, EventEmitter, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";

// app
import GoogleAccount from "../../constants/firestore/google-account";
import { Messages, string_message } from "../../constants/messages";
import { CREATE_DATA, NOTIFICATION_GENERAL, TYPE_LOG_LOCATION } from "../../constants/notifications";
import { AccountService } from "../../services/account.service";
import { AuthService } from "../../services/auth.service";
import { SessionTraceService as SessionTraceService } from "../../services/session-trace.service";
import { GoogleLocationService } from "../../services/google-location.service";
import { LocationService } from "../../services/location.service";
import { NotificationService } from "../../services/notification.service";
import { SpinnerService } from "../../services/spinner.service";
import { WhiteLabelService } from "../../services/white-label.service";

@Component({
    selector: 'app-dialog-locations',
    templateUrl: 'dialog-locations.component.html',
  })
  export class DialogLocationsComponent implements OnDestroy, OnInit {
    user;
    allChecked: boolean;
    googleLocations: any[];
    googleAccounts: Observable<GoogleAccount[]>;
    selectedAccountId: string;
    onLocationsSaved = new EventEmitter();
    subsManager$ = new Subject<boolean>();
    search: string;
    resultSearchLocation;
    alreadyExistLocation: any[];
    location;
    accountId: string;
    noDataFound$ = new BehaviorSubject(false);
    progressBar$ = new BehaviorSubject(false);
    selectedLocation: any[] = []
    mode = ""
  
    constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      public dialogRef: MatDialogRef<DialogLocationsComponent>,
      private _googleLocationService: GoogleLocationService,
      private _accountService: AccountService,
      private _auth: AuthService,
      private _spinnerService: SpinnerService,
      private _wl: WhiteLabelService,
      private _locationService: LocationService,
      private _notificationService: NotificationService,
      private _route: ActivatedRoute,
      private _sessionTracingService: SessionTraceService,

    ) {
      this.user = this._auth.session;
      this.selectedAccountId = this.data.selectedAccountId;
      this.accountId = this.selectedAccountId?.split('/')[1];
      this.googleAccounts = this.data.googleAccounts;
  
      this._googleLocationService.reset();
      this._googleLocationService.loadAll(this.user, this.selectedAccountId);
    }
  
    ngOnInit() {
      this._route.queryParams
        .subscribe(params => {
          this.mode = params?.mode;
        }
      );

      this.progressBar$.next(true);
      this._googleLocationService.locations.pipe(takeUntil(this.subsManager$)).subscribe(
        result => {
          if (result && result.length > 0 && result[0]) {
            const sub$: Subject<boolean> = new Subject();
            const resultRequest = result.map(({address, locationName, name, serviceArea}) => ({address, locationName, name, serviceArea}));
            this._locationService.byAccountGetLocations(this.user.gid, this.accountId, resultRequest).pipe(takeUntil(sub$)).subscribe(sl => {
              const filterL = result.filter((g) => g !== undefined && !sl.some((gl: any) => g.name.split('/')[3] === gl.id));
              this.alreadyExistLocation = result.filter((g) => g !== undefined && sl.some((gl: any) => g.name.split('/')[3] === gl.id));
              this.googleLocations = filterL;
              this.verificateLocations(this.googleLocations);
              if (this.googleLocations.length == 0 && this.alreadyExistLocation.length == 0 || (sl && sl?.length === 0)) {
                this.noDataFound$.next(true);
              } else {
                this.noDataFound$.next(false);
              }
              this.googleLocations = sl;
              this.resultSearchLocation = sl;
              sub$.next(true);
              sub$.unsubscribe();
              this.progressBar$.next(false);
            },
            () => {
                this.noDataFound$.next(true);
                this.progressBar$.next(false);
            });
          } 
          
          if(result && result.length > 0 && !result[0])
          {
                this.noDataFound$.next(true);
                this.progressBar$.next(false);
          }
        },
      );
    }
  
    locationCheckboxDisabled(location: any) : boolean {
      if(this.mode === "debug"){
        return false;
      }
  
      if(location?.exists){
        return true;
      }
  
      return false;
    }
  
    allCheckedCheckboxDisabled(googleLocations) : boolean {
      let disabledReturn = true;
      if(this.mode === "debug"){
        disabledReturn = false;
      }
  
      googleLocations.forEach(element => {
        if(element?.exists !== true){
          disabledReturn = false;
        }
      });
  
      return disabledReturn;
    }
  
    verificateLocations(locations: any[]) {
      locations.forEach(location => {
        if (location.locationState) {
          if (location.locationState.isDisconnected) {
            location.locationState.description = 'Unavailable';
            location.locationState.tooltip = 'Location Disconnected';
            location.locationState.fill = 'review-response--error';
            location.locationState.txt = 'txt--orange';
          } else if (location.locationState.hasPendingVerification) {
            location.locationState.description = 'Unavailable';
            location.locationState.tooltip = 'Location Pending verification';
            location.locationState.fill = 'review-response--error';
            location.locationState.txt = 'txt--orange';
          } else if (location.locationState.isDisabled) {
            location.locationState.description = 'Unavailable';
            location.locationState.tooltip = 'Location Disabled';
            location.locationState.fill = 'review-response--info';
            location.locationState.txt = 'txt--blue';
          } else if (location.locationState.needsReverification) {
            location.locationState.description = 'Unavailable';
            location.locationState.tooltip = 'Location Needs Reverification';
            location.locationState.fill = 'review-response--error';
            location.locationState.txt = 'txt--orange';
          } else if (location.locationState.isSuspended) {
            location.locationState.description = 'Unavailable';
            location.locationState.tooltip = 'Location Suspended';
            location.locationState.fill = 'review-response--info';
            location.locationState.txt = 'txt--blue';
          } else if (location.locationState.isDuplicate) {
            location.locationState.description = 'Unavailable';
            location.locationState.tooltip = 'Location Duplicate';
            location.locationState.fill = 'review-response--error';
            location.locationState.txt = 'txt--orange';
          } else if (!location.locationState.isVerified) {
            location.locationState.description = 'Unavailable';
            location.locationState.tooltip = 'Location Unverified';
            location.locationState.fill = 'review-response--error';
            location.locationState.txt = 'txt--orange';
          }
        }
      });
    }
  
  
    filterLocation() {
      this.googleLocations = this.resultSearchLocation.filter(location =>
        location.locationName.toLowerCase().includes(this.search.toLowerCase()) ||
        this._locationService.formatAddress(location.address || location.location.address).toLowerCase().includes(this.search.toLowerCase())
      );
    }
  
    ngOnDestroy(): void {
      this._accountService.reset();
      this.subsManager$.next(true);
      this.subsManager$.complete();
    }
  
    async addLocation() {
      // Need a cleaner way of removing accessory data so we can save a clean object and add address format
      this.selectedLocation.forEach(location => {
        delete location.isChecked;
        if (location.address) location.formatAddress = this._locationService.formatAddress(location.address)
        else location.formatAddress = ''
        location.gid = this._auth.session.gid
      });
  
      try {
        this._spinnerService.loading$.next(true)
        // Getting first location added date before saving locations
        const firstLocationAddedOn = (await this._auth.subscription$.getValue()).firstLocationAddedOn;
        const googleAcc = (await this.googleAccounts.pipe(take(1)).toPromise()).find(acc => acc.name === this.selectedAccountId)
        const acc = (await this._accountService.saveAll([googleAcc]))[0]
        await this._locationService.saveAll(this.selectedLocation, acc) // TODO: What happend if saveAll fails without throwing an error?
        if(!firstLocationAddedOn)
          this._sessionTracingService.onFirstLocationAdded();
        this.onLocationsSaved.emit(true);
        for (const sl of this.selectedLocation) {
          // Notification informative create location
          const l = { accountId: acc, locationId: sl.name.split('/')[3], ...sl }
          const meta = this._notificationService.getMetaTypeLog(TYPE_LOG_LOCATION, l);
          this._notificationService.saveNotification(l.gid, this._wl.baseDomain, string_message(Messages.notifications.LOCATION_TOGGLE, [l.locationName, l.formatAddress, CREATE_DATA]), 
                                                    NOTIFICATION_GENERAL, TYPE_LOG_LOCATION, meta)
        }

        const locationId = this.selectedLocation[0].name.split('/')[3]
        this._sessionTracingService.onLocationAdded(acc.accountId, locationId, this.selectedLocation.length)
      } catch(err) {
        console.error('Error saving accounts', err);
      } finally {
        this._spinnerService.loading$.next(false);
        this.dialogRef.close(true);
      }
    }
  
    // check/uncheck all checkbox
    toggleCheckAll() {
      if (!this.allChecked) this.selectedLocation = []
      this.googleLocations.forEach(location => {
        if(!location?.exists || this.mode === 'debug'){
          location.isChecked = this.allChecked;
          if (this.allChecked) {
            this.selectedLocation.push(location)
          }
        }
      });
    }
  
    // if every item is checked, check the Select All checkbox
  
    isAllChecked(location: any) {
      if (location.isChecked) {
        this.selectedLocation.push(location)
      } else {
        this.selectedLocation = this.selectedLocation.filter(l => location.name !== l.name)
      }
      this.googleLocations.every((item: any) => {
        return item.isChecked === true;
      });
    }
  
    get someCheck(): boolean {
      if (this.selectedLocation.length > 0) {
        return this.selectedLocation.some(item => item.isChecked === true);
      } else {
        return false;
      }
    }
  }
  