import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
var ModalGetAddOn = /** @class */ (function () {
    function ModalGetAddOn(dialogRef, data, _auth, _router) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.data = data;
        this._auth = _auth;
        this._router = _router;
        this.subscription$ = this._auth.subscription$;
        this.subscription$.subscribe(function (subscription) {
            var _a, _b, _c, _d;
            _this.subscription = subscription;
            _this.price = (_d = (_c = (_b = (_a = _this.subscription) === null || _a === void 0 ? void 0 : _a.pricing) === null || _b === void 0 ? void 0 : _b.pkg_bulk_actions) === null || _c === void 0 ? void 0 : _c.tiers[2]) === null || _d === void 0 ? void 0 : _d.price;
        });
    }
    ModalGetAddOn.prototype.getAddOn = function () {
        this.dialogRef.close();
        // commented until '/plan' allows users to buy add-ons directly
        // this._router.navigate(['/settings/billing'])
        var email = 'sales@maplabs.com';
        var subject = encodeURIComponent('Add-On Request');
        var body = encodeURIComponent('Hello, I would like to purchase the Bulk Actions Add-on. Thank you.');
        var mailtoLink = "mailto:" + email + "?subject=" + subject + "&body=" + body;
        window.location.href = mailtoLink;
    };
    return ModalGetAddOn;
}());
export { ModalGetAddOn };
