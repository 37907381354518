import { __awaiter, __generator } from "tslib";
import { EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
// Delete Dialog
var DialogDeleteLocationsComponent = /** @class */ (function () {
    function DialogDeleteLocationsComponent(data, dialogRef) {
        this.data = data;
        this.dialogRef = dialogRef;
        this.onAccept = new EventEmitter(false);
    }
    DialogDeleteLocationsComponent.prototype.applyDelete = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.onAccept.emit(true);
                this.dialogRef.close();
                return [2 /*return*/];
            });
        });
    };
    return DialogDeleteLocationsComponent;
}());
export { DialogDeleteLocationsComponent };
