<div>
  <div class="dialog__header txt--center">
    <div class="dialog__close">
      <button (click)="close()" mat-icon-button mat-dialog-close tabIndex="-1" class="btn btn-icon btn-icon--txt"><i
        class="fal fa-times"></i></button>
    </div>
    <h2 mat-dialog-title class="m--0 txt--xl"><strong>Account</strong></h2>
    <p class="txt--lg m--0">Select an account to add!</p>
  </div>
  <div class="dialog__content" mat-dialog-content>
    <mat-progress-bar mode="indeterminate" *ngIf="googleAccounts.length == 0"></mat-progress-bar>
    <div class="dialog__search border-bottom">
      <div class="input-field input-field--search">
        <div class="table__icon table__icon--lg">
          <i class="fas fa-search"></i>
        </div>
        <input placeholder="Type to search" [(ngModel)]="search" (ngModelChange)="filterAccount()">
      </div>
    </div>
    <div class="dialog-row border-bottom" *ngFor="let googleAccount of resultSearchAccount ;">
      <mat-radio-group [(ngModel)]="selectedAccount">
        <mat-radio-button [value]="googleAccount.name">
          <div class="checkbox__content">
            <h3 class="d-block"><strong>{{ googleAccount.accountName }} </strong></h3>
          </div>
        </mat-radio-button>
      </mat-radio-group>

    </div>
  </div>
  <div mat-dialog-actions class="dialog__footer">
    <span class="alternate-theme d-inline-block mr--10">
        <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
    </span>
    <button mat-flat-button tabIndex="-1" color="accent" (click)="addAccount()" [disabled]="selectedAccount==null" class="btn btn--lg btn--txt--lg">Add Account</button>
  </div>
</div>
