<div class="dialog__header">
  <h2 mat-dialog-title class="m--0 txt--xl"><strong>Address</strong></h2>
  <p class="m--0 txt--white">Providing an accurate business address is important so your business shows up in the
    right place on Google.</p>
</div>
<div class="dialog__content" mat-dialog-content>
  <div class="dialog-row pb--0">
    <mat-form-field class="input-field">
      <input matInput placeholder="Country/Region" [value]="address?.regionCode" [disabled]="true">
    </mat-form-field>
  </div>

  <div class="dialog-row   p--0">
    <ng-container *ngFor="let a of address?.addressLines; let i = index">
      <div class=" d-flex justify-content-between align-items-center">
        <mat-form-field class="input-field">
          <input *ngIf="i == 0" matInput placeholder="Street Address" [options]="optionsGoogle" (change)="handleChange($event, i)" [value]="a" ngx-google-places-autocomplete #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event, i)"/>
          <input *ngIf="i != 0" matInput placeholder="Street Address line {{i+1}}" (change)="handleChange($event, i)" [value]="a">
        </mat-form-field>
        <button mat-button class="btn btn-icon" (click)="removeAddress(i)" *ngIf="i>0"><i class="fas fa-times"></i>
        </button>
      </div>
    </ng-container>
  </div>

  <div class="pb--0 row" *ngIf="address?.addressLines?.length < 5">
    <button [disabled]="address?.addressLines?.length > 1 && address?.addressLines[address?.addressLines?.length - 1] === ''" mat-button class="btn btn--md d-flex align-items-center" (click)="addStreet()">
      <img src="/assets/images/add-btn.png" class="mr--10 img--xxs" alt="Add Adress Line">Add Address Line
    </button>
  </div>

  <div class="dialog-row pb--0">
    <mat-form-field class="input-field">
      <input matInput placeholder="City" [(ngModel)]="address.locality" readonly>
    </mat-form-field>
  </div>

  <div class="dialog-row p--0">
    <div class="row">
      <div class="col-12 col-md-6">
        <mat-form-field class="input-field">
          <input matInput placeholder="State" [(ngModel)]="address.administrativeArea" readonly>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-6">
        <mat-form-field class="input-field">
          <input matInput placeholder="Zip Code" [(ngModel)]="address.postalCode" readonly>
        </mat-form-field>
      </div>
    </div>
  </div>

</div>
<div mat-dialog-actions class="dialog__footer">
    <span class="alternate-theme d-inline-block mr--10">
        <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
    </span>
  <button mat-flat-button (click)="apply()" tabIndex="-1" color="accent" class="btn btn--action">Apply</button>
</div>
