
	  <div class="dialog__header txt--center dialog__header--warn">
		  <h2 mat-dialog-title class="m--0 txt--xl"><strong>{{ data.title }}</strong></h2>
	  </div>

	  <div class="dialog__content" mat-dialog-content>
		  <div class="dialog-row">
        
        <p class="txt--lg txt--center m--0" *ngIf="data.normalText"><strong>{{ data.content }}</strong></p>
        <pre class="txt--lg m--0" *ngIf="!data.normalText">{{data.content}}</pre>
		  </div>
	  </div>

	  <div mat-dialog-actions class="dialog__footer">
      <span class="alternate-theme d-inline-block mr--10">
        <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
      </span>
        <button tabIndex="-1" mat-flat-button color="primary" [mat-dialog-close]="data.title" class="btn btn--action">{{ data.closeButtonLabel }}</button>
	  </div>
  