<div class="filter__item filter-search p--0 w--200-px">
    <button mat-button class="filter__item__btn p--0 account-filter-button" [matMenuTriggerFor]="menuFilter" #metricsTrigger="matMenuTrigger">
        <p class="w--200-px pr--10 ml--10 mr--10">{{labelFiltered}}</p> 
        <em class="fal fa-angle-down select-icon" data-html2canvas-ignore="true"></em>
      </button>

      <mat-menu #menuFilter="matMenu" [overlapTrigger]="false" xPosition="before" class="filter__item__content p--0">
        <div (click)="$event.stopPropagation()">
            <ng-container>
                <form>
                    <mat-form-field class="col-12 p--0" appearance="fill">
                        <mat-label>Search</mat-label>
                        <input type="text" aria-label="Search" matInput [formControl]="filterControl">
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let option of filteredOptionsObs | async" [value]="option">
                                <ng-container>
                                    <div class="filter-search-options">
                                        <ng-container *ngIf="isMultiple">
                                            <mat-option class="m--0" *ngFor="let option of options" [value]="option">
                                                <mat-checkbox name="checked" [(ngModel)]="option.checked" (change)="getLocationsFiltered($event, option)">
                                                    <p class="m--0" *ngIf="option.accountName">{{option.accountName | titlecase}}</p>
                                                </mat-checkbox>
                                            </mat-option>
                                        </ng-container>
                                        <ng-container *ngIf="isMultiple">
                                            <section *ngFor="let option of options">
                                                <button class="cursor--pointer" mat-button color="accent" (click)="filterAccountChanged(menuFilter, false, option)">{{option.accountName | titlecase}}</button>
                                            </section>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </form>
            </ng-container>

            <div class="filter__content filter-search-content">
                <ng-container>
                    <div class="filter-search-options">
                        <ng-container *ngIf="isMultiple">
                            <mat-option class="m--0" *ngFor="let option of options" [value]="option">
                                <mat-checkbox name="checked" [(ngModel)]="option.checked" (change)="getLocationsFiltered($event, option)">
                                <p class="m--0" *ngIf="option.accountName">
                                    {{option.accountName | titlecase}}
                                </p>
                                </mat-checkbox>
                            </mat-option>
                        </ng-container>

                        <ng-container *ngIf="!isMultiple">
                            <section *ngFor="let option of options">
                                <button class="cursor--pointer" mat-button color="accent" (click)="filterAccountChanged(menuFilter, false, option)">{{option.accountName | titlecase}}</button>
                            </section>
                        </ng-container>
                    </div>
                </ng-container>
            </div>

            <mat-divider *ngIf="hasApply || hasClear"></mat-divider>

            <div *ngIf="hasApply || hasClear" class="filter-search-apply w--100 bg--white">
                <button mat-flat-button class="btn txt--md p--10 m--10 bg--gray" *ngIf="hasClear" (click)="filterAccountChanged(menuFilter, true)">Clear</button>
                <button mat-flat-button color="accent" class="btn txt--md p--10 m--10" *ngIf="isMultiple && hasApply" (click)="filterAccountChanged(menuFilter, false)">Apply</button>
            </div>
        </div>
    </mat-menu>
</div>

          