import { EventEmitter, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ReviewsService } from '../../services/reviews.service';
var ResumeCreateProtocolComponent = /** @class */ (function () {
    function ResumeCreateProtocolComponent(reviewS) {
        this.reviewS = reviewS;
        this.protocol = null;
        this.isLoading = new EventEmitter();
        this.progress$ = new BehaviorSubject(true);
        this.delay = 0;
        this.counter = new BehaviorSubject(null);
        this.data_copy = { Replied: 0, Total: 0, NotReplied: 0 };
    }
    ResumeCreateProtocolComponent.prototype.ngOnInit = function () {
        //this.GetCounters();
    };
    ResumeCreateProtocolComponent.prototype.GetCounters = function () {
        var _this = this;
        var _a, _b;
        this.progress$.next(true);
        this.counter.next(null);
        this.delay = Number((_b = (_a = this.protocol) === null || _a === void 0 ? void 0 : _a.triggers) === null || _b === void 0 ? void 0 : _b.delay);
        this.reviewS.protocolCheckCounterReview(this.protocol).then(function (data) {
            _this.progress$.next(false);
            if (typeof (data) === "string") {
                data = { Replied: 0, Total: 0, NotReplied: 0 };
            }
            if (data.NotReplied === undefined)
                data['NotReplied'] = 0;
            if (data.Replied === undefined)
                data['Replied'] = 0;
            if (data.Total === undefined)
                data['Total'] = 0;
            _this.data_copy = data;
            _this.counter.next(data);
            _this.isLoading.emit(false);
        }, function (err) {
            _this.progress$.next(false);
            _this.isLoading.emit(false);
        });
    };
    ResumeCreateProtocolComponent.prototype.orderStarts = function (stars) {
        return stars.sort(function (a, b) { return b - a; });
    };
    Object.defineProperty(ResumeCreateProtocolComponent.prototype, "Counters", {
        get: function () {
            return this.data_copy;
        },
        enumerable: true,
        configurable: true
    });
    return ResumeCreateProtocolComponent;
}());
export { ResumeCreateProtocolComponent };
