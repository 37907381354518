<div class="content--full p--tb-20 bg--radial-gradient-blue d-flex justify-content-center align-items-center flex-column">
	<div class="login--cont">
		<div class="spinner-wrapper spinner-wrapper--bg spinner-wrapper--fixed" *ngIf="loginProgress">
			<mat-spinner></mat-spinner>
		</div>
		<div cols="2" class="login--card-container pb--0">
			<div class="login--card-container-col col-first">
				<div class="logo">
					<div>
						<img 
						class="login-logo mb--20" 
						alt="Logo" 
						[src]="env?.branding?.loginLogo || env?.branding?.mainLogo || '/assets/images/logo/blankmap.png'"
						width="170" 
						height="59"/>
					</div>
					<div>
						<span class="txt--24 mb--10">Sign in to access your account</span>
					</div>
				</div>
			</div>
			<div class="login--card-container-col">
				<div class="col-cont col-login-form">
					<button mat-stroked-button class="btn--rounded w--100" (click)="signInWithGoogle($event)" [disabled]="blockedDomain">
						<div class="signup-button p--tb-14 justify-content-center">
							<img class="google-icon" src="/assets/images/icons/google-color.png">
							<span class="signup-text txt--md">Log in with Google</span>
						</div>
					</button>
					<div class="separator mt--30 mb--30">
						<div class="separator-line"></div>
						<div class="separator-text txt--14">OR</div>
						<div class="separator-line"></div>
					</div>
					<div class="login--signin">
						<form [formGroup]="loginForm" (submit)="signInWithEmailAndPassword($event)">

							<mat-form-field hideRequiredMarker class="input-group input-group--override input-group--override-new">
								<input matInput placeholder="Email" name="email" type="email" formControlName="email"
								class="input-group__field" required>
								<mat-error *ngIf="loginForm?.controls?.email?.invalid && loginForm?.controls?.email?.touched"><p>Please enter
									valid email</p></mat-error>
							</mat-form-field>

							<mat-form-field hideRequiredMarker class="input-group input-group--override input-group--override-new">
								<input matInput placeholder="Password" [type]="hide ? 'password' : 'text'" name="password"
								type="password" formControlName="password" class="input-group__field" required>
								<mat-error *ngIf="loginForm?.controls?.password?.invalid && loginForm?.controls?.password?.touched"><p>Please
									enter valid password</p></mat-error>
							</mat-form-field>

							<div class="remember-forgot-container d-flex align-items-center justify-content-between">
								<mat-checkbox class="txt--normal mr--5">Remember me</mat-checkbox>
								<a href="" class="txt--normal" [routerLink]="['/forgot-password']">Forgot password?</a>
							</div>

							<button mat-raised-button color="primary" class="btn border--radius-25 btn--full p--tb-10 p--lr-14 mb--20"
							[disabled]="loginForm?.invalid || blockedDomain"><strong>Log In</strong></button>
						</form>
						<div *ngIf="!env?.hideSignUpButton" class="mt--60 mb--20">
							<span class="d-inline-block">Don't have an account? </span>
							<a class="login--link ml--5"  (click)="goToRegister()"><u>Sign Up for free</u></a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer footer--noborder flex-column box box--md txt--center">
			<ul class="footer__nav d-flex justify-content-center mt--20 mb--40">
				<li><a class="txt--white" (click)="goToTermsOfService()">Terms and Conditions</a></li>
				<li><a class="txt--white" (click)="goToPrivacyPolicy()">Privacy Policy</a></li>
				<li><a class="txt--white" (click)="goToPaymentPolicy()">Payment Policy</a></li>
			</ul>
			<p class="txt--normal txt--white m--0">&copy; {{ env?.branding?.company_name }} All Rights Reserved.</p>
		</div>
	</div>
</div>


