<app-dashboard-filter [progress]="false" [accountId]="accountId" [locationId]="locationId"  typeDashboard="photos"  ></app-dashboard-filter>
<div class="container--full"  style="margin-top: 65px">
    <div class="content__body">
        <div class="table--shadow mb--30">
            <div class="mat-row mat-row--padding mat-row--border justify-content-between align-items-center bg--white" style="padding: 20px 20px;">
                <app-photos-location [accountId]="accountId" [locationId]="locationId"  ></app-photos-location>
            </div>
        </div>
    </div>    
</div>
