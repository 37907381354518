<div>
	<div class="dialog__header txt--center">
        <div class="dialog__back">
          <button mat-icon-button mat-dialog-close="back" tabIndex="-1" class="btn btn-icon btn-icon--txt"><i class="far fa-arrow-left"></i></button>
        </div>
		<div class="dialog__close">
			<button mat-icon-button mat-dialog-close tabIndex="-1" class="btn btn-icon btn-icon--txt"><i class="fal fa-times"></i></button>
		</div>
		<h2 mat-dialog-title class="m--0 txt--xl"><strong>Locations</strong></h2>
		<p class="txt--lg m--0">Select locations to add!</p>
	</div>
	<div class="dialog__content" mat-dialog-content>
		<div class="dialog__search border-bottom">
			<div class="input-field input-field--search">
				<div class="table__icon table__icon--lg">
					<i class="fas fa-search"></i>
				</div>
				<input placeholder="Type to search" [(ngModel)]="search" (ngModelChange)="filterLocation()">
			</div>
		</div>
		<div class="dialog-row border-bottom" *ngIf="googleLocations?.length > 0">
			<mat-checkbox class="m--0" 
				[(ngModel)]="allChecked" 
				(change)="toggleCheckAll()"
				[class.mat-checkbox-disabled]="allCheckedCheckboxDisabled(googleLocations)"
				[disabled]="allCheckedCheckboxDisabled(googleLocations)"
			>
				<p class="txt--lightgray m--0">Select All</p>
			</mat-checkbox>
		</div>
		<mat-progress-bar mode="indeterminate" 
			*ngIf="(progressBar$ | async)"
		></mat-progress-bar>
		<ng-container *ngIf="(noDataFound$ | async); else locationsTemplate">
			<div class="dialog-row border-bottom" style="text-align: center">
				<h3>No locations to add</h3>
			</div>
		</ng-container>
		<ng-template #locationsTemplate>
			<div class="dialog-row border-bottom" *ngFor="let location of googleLocations ">
				<mat-checkbox value="{{ location.id }}" 
					[(ngModel)]="location.isChecked" 
					(change)="isAllChecked(location)"
					[class.mat-checkbox-disabled]="locationCheckboxDisabled(location)"
					[disabled]="locationCheckboxDisabled(location)"
				>
					<div class="checkbox__content">
						<div class="d-flex">
							<label>
								<b>{{ location?.locationName }}</b>
								<span *ngIf="location?.exists"
									matTooltip="This profile is already managed in the platform by a different account. Please contact support if you believe this is an error."
									matTooltipPosition="above"
									matTooltipClass="mat-tooltip--override"
									class="mat-tooltip tooltip tooltip--info"
									style="vertical-align: middle; margin-bottom: 4px;"
								>
									<i class="fas fa-info"></i>
								</span>
							</label>
							<div class="ml--15 review-response review-response--xs {{location?.locationState?.fill}}">
								<p class="m--0 {{location?.locationState?.txt}} txt--uppercase" [matTooltip]="location?.locationState?.tooltip" matTooltipPosition="right"><strong>{{ location?.locationState?.description }}</strong></p>
							</div>
						</div>
						<p class="txt--lightgray m--0">
							<gmb-address 
								[address]="location?.address || location?.location?.address" 
								[serviceAreas]="location?.serviceArea?.places?.placeInfos || location?.location?.serviceArea?.places?.placeInfos">
							</gmb-address>
						</p>
					</div>
				</mat-checkbox>
			</div>
		</ng-template>
	</div>
	<div mat-dialog-actions class="dialog__footer">
    	<span class="alternate-theme d-inline-block mr--10">
        	<button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
    	</span>
		<button mat-flat-button tabIndex="-1" color="accent" [disabled]="!someCheck" (click)="addLocation()" class="btn btn--lg btn--txt--lg">Add Location</button>
	</div>
</div>
