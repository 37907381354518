import { EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ModalServiceAreaComponent } from '../../modal-service-area/modal-service-area.component';
var LocationServiceAreaComponent = /** @class */ (function () {
    function LocationServiceAreaComponent(dialog) {
        this.dialog = dialog;
        this.serviceAreaPlaces = [];
        this.serviceArea = {};
        this.isUnderReview = false;
        this.isEdit = true;
        this.regionCode = 'US';
        this.onUpdate = new EventEmitter();
    }
    LocationServiceAreaComponent.prototype.ngOnInit = function () {
        var _a, _b;
        this.serviceAreaPlaces = ((_b = (_a = this.serviceArea) === null || _a === void 0 ? void 0 : _a.places) === null || _b === void 0 ? void 0 : _b.placeInfos) || [];
    };
    LocationServiceAreaComponent.prototype.openAddressDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ModalServiceAreaComponent, {
            width: '680px',
            data: {
                'serviceArea': this.serviceArea,
                'regionCode': this.regionCode
            }
        });
        dialogRef.afterClosed().subscribe(function (res) {
            if (res != '')
                _this.onUpdate.emit(true);
        });
    };
    return LocationServiceAreaComponent;
}());
export { LocationServiceAreaComponent };
