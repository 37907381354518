<mat-accordion class="reports-accordion" displayMode="flat">
  <mat-expansion-panel (opened)="reportListAccordionChange()" (closed)="reportListAccordionChange()" hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h2><strong>Reviews By Location</strong></h2>
      </mat-panel-title>
      <mat-panel-description class="align-items-center justify-content-end">
        <div style="margin-right: 40px;" class="d-flex justify-content-between align-items-center alternate-theme" *ngIf="isReportListAccordionOpen">
          <button mat-flat-button color="primary" class="btn btn--xs" (click)="exportPDF($event)" style="margin-right: 5px;" gmbSubscriptionInTrial>Export PDF</button>
          <button mat-flat-button color="primary" class="btn btn--xs" (click)="exportCSV($event)" style="margin-right: 5px;" gmbSubscriptionInTrial>
            Export CSV <em *ngIf="isProgressCSV" class="fas fa-spinner fa-pulse"></em>
          </button>
          <button mat-flat-button color="primary" class="btn btn--xs" (click)="exportCSVReviewLinks($event)" style="margin-right: 5px;" gmbSubscriptionInTrial>
            Export Review Links <em *ngIf="isDowloadReviewLinks" class="fas fa-spinner fa-pulse"></em></button>
        </div>
        <mat-icon><span class="mat-expansion-panel-icon"></span></mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div>
        <div class="box__content">
          <app-skeleton-review-report *ngIf="isLoading"></app-skeleton-review-report>
          <table id="table-location-container" mat-table [dataSource]="source"  class="table table--bordered" *ngIf="!isLoading && source" matSort [matSortActive]="sort && sort.sortBy" [matSortDirection]="sort && sort.direction" (matSortChange)="sortBy($event)">

            <!-- Location -->
            <ng-container matColumnDef="location">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="locationName"> Location</th>
              <td mat-cell *matCellDef="let row" [style.width]="'20%'">
                <div class="cell__content flex-column align-items-start">
                  <p class="m--0 mr--10 txt--black txt--md"><strong>{{ row?.locationName }}</strong></p>
                  <p class="txt--lightgray">
                    <gmb-address 
                      *ngIf="row?.address || row?.serviceArea"
                      [address]='row?.address'
                      [serviceAreas]="row?.serviceArea?.places?.placeInfos">
                    </gmb-address>
                  </p>

                  <div class="mt--5" *ngIf="!isShared">
                    <a [routerLink]="locationUrl(row.accountId,row.locationId,'insights')" target="_blank"
                      matTooltip="View Insights" matTooltipClass="tooltip tooltip--blue" matTooltipPosition="above"
                      class="d-inline-block btn btn--blue btn-icon mr--5 border-box"><img src="/assets/images/icons/graph.png"
                                                                                alt="Graph Icon"></a>
                    <a [routerLink]="locationUrl(row.accountId,row.locationId,'reviews')" target="_blank"
                      matTooltip="See Reviews" matTooltipClass="tooltip tooltip--orange" matTooltipPosition="above"
                      class="d-inline-block btn btn--orange btn-icon mr--5 border-box"><img
                      src="/assets/images/icons/star-empty.png" alt="Star Icon"></a>
                    <a [href]="row.mapsUrl" target="_blank" matTooltip="See Location on Map"
                      matTooltipClass="tooltip tooltip--green" matTooltipPosition="above"
                      class="d-inline-block btn btn--green btn-icon border-box"><img src="/assets/images/icons/pin-green.png"
                                                                          alt="Pin Icon"></a>
                  </div>
                </div>
              </td>

            </ng-container>

            <!-- Star Rating -->
            <ng-container matColumnDef="rating">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="starRatingAvg" class="txt--center"> Average Star Rating</th>
              <td mat-cell *matCellDef="let row" [style.width]="'20%'">
                <div class="cell__content d-flex flex-column justify-content-center  align-items-center">
                  <p class="txt--lg fw--medium txt--black mb--10">{{ (row.starRatingAvg | number:'1.0-2') || 'N/A'}}</p>
                  <app-starts [starts]="row.starRatingAvg" class="stars--xs"></app-starts>
                </div>
              </td>
            </ng-container>

            <!-- Total Reviews -->
            <ng-container matColumnDef="total">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="reviewCount" class="txt--center"> Number of New Reviews</th>
              <td mat-cell *matCellDef="let row" [style.width]="'15%'">
                <div class="cell__content justify-content-center">
                  <p class="m--0 fw--medium">{{row?.reviewCount || 0}}</p>
                </div>
              </td>
            </ng-container>

            <!-- Responded -->
            <ng-container matColumnDef="responded">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="totalAnswered" class="txt--center"> Responded</th>
              <td mat-cell *matCellDef="let row" [style.width]="'15%'">
                <div class="cell__content justify-content-center">
                  <p class="m--0 fw--medium">{{row?.totalAnswered || 0}}</p>
                </div>
              </td>
            </ng-container>

            <!-- Unresponded -->
            <ng-container matColumnDef="unresponded">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="totalNotAnswered" class="txt--center"> Unresponded</th>
              <td mat-cell *matCellDef="let row" [style.width]="'15%'">
                <div class="cell__content justify-content-center">
                  <p class="m--0 fw--medium">{{row?.totalNotAnswered || 0}}</p>
                </div>
              </td>
            </ng-container>

            <!-- Review -->
            <ng-container matColumnDef="link">
              <th width="35%" mat-header-cell *matHeaderCellDef class="txt--center exclude">Review Link</th>
              <td mat-cell *matCellDef="let row" [style.width]="'15%'" class="exclude">
                <div class="cell__content justify-content-center">
                  <button mat-raised-button class="filter__item__btn filter__item__btn--md stars--xxs txt--blue" (click)="showReviewLink(row?.placeId)">
                    <app-starts [starts]="1" [showEmpty]="false" class="stars--xs"></app-starts>
                    Review Link
                  </button>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="no-hover"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="no-hover border--full"></tr>
          </table>

          <app-no-data [message]="'No matches found'" *ngIf="!isLoading && source?.length === 0"></app-no-data>

          <!--PAGINATION-->
          <div class="mat-row mat-row--border-top mat-row--padding-md d-flex justify-content-start align-items-center"
            *ngIf="pagination">
            <div class="pagination" data-html2canvas-ignore>
              <button (click)="onPrev()" class="pagination__item" [disabled]="!pagination.hasPrev"><i
                class="fas fa-angle-left"></i></button>
              <button (click)="onNext()" class="pagination__item" [disabled]="!pagination.hasNext"><i
                class="fas fa-angle-right"></i></button>
            </div>
            <div class="input-field input-field--pagination" data-html2canvas-ignore>
              <label>Go to:</label>
              <input type="text" matInput [(ngModel)]="this.pagination.page" (ngModelChange)="onChangePage($event)"/>
              <mat-error *ngIf="errorMessage" class="mat-error--absolute">
                <p>Enter valid number</p>
              </mat-error>
            </div>
            <mat-paginator data-html2canvas-ignore (page)="onChangePage($event)" [length]="pagination.total"
                      [pageSize]="pagination.per_page"
                      [pageSizeOptions]="sizeOption" [pageIndex]="pagination.page-1"
                      *ngIf="pagination"></mat-paginator>
          </div>
        </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>