import { Component, Input, OnInit } from '@angular/core';
import { MyBusinessErrors } from '../../constants/google-api-errors';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent implements OnInit {
  myBusinessErrors: any;
  @Input() error: any = {};
  @Input() error_details: any = [];

  constructor() { }

  ngOnInit() {
    this.myBusinessErrors = MyBusinessErrors;
  }

}
