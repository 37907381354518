import { __awaiter, __generator, __values } from "tslib";
import { LocationService } from '../../services/location.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/location.service";
var ValidateLocations = /** @class */ (function () {
    function ValidateLocations(locationService) {
        this.locationService = locationService;
    }
    ValidateLocations.prototype.validateArrayLocations = function (accounts) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var locationsIds, _loop_1, accounts_1, accounts_1_1, account, allLocations;
            var e_1, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        locationsIds = [];
                        _loop_1 = function (account) {
                            if ((_a = account.locations) === null || _a === void 0 ? void 0 : _a.length) {
                                account.locations.forEach(function (l) {
                                    locationsIds.push({
                                        'gid': account.gid,
                                        'accountId': account.accountId,
                                        'locationId': l.locationId
                                    });
                                });
                            }
                        };
                        try {
                            for (accounts_1 = __values(accounts), accounts_1_1 = accounts_1.next(); !accounts_1_1.done; accounts_1_1 = accounts_1.next()) {
                                account = accounts_1_1.value;
                                _loop_1(account);
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (accounts_1_1 && !accounts_1_1.done && (_b = accounts_1.return)) _b.call(accounts_1);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        return [4 /*yield*/, this.locationService.fetchLocationsExistence(locationsIds)];
                    case 1:
                        allLocations = _c.sent();
                        return [2 /*return*/, accounts.map(function (ac) {
                                ac.locations = ac.locations.filter(function (l) { return allLocations.some(function (lf) { return lf.gid === ac.gid && lf.accountId === ac.accountId && lf.locationId === l.locationId && lf.exist; }); });
                                if (ac.locations.length > 0)
                                    return ac;
                            })];
                }
            });
        });
    };
    ValidateLocations.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ValidateLocations_Factory() { return new ValidateLocations(i0.ɵɵinject(i1.LocationService)); }, token: ValidateLocations, providedIn: "root" });
    return ValidateLocations;
}());
export { ValidateLocations };
