import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Router} from '@angular/router';
import {MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material';


export interface SelectedLocations {
  id: number;
  company: object;
  rating: number;
  reviewer: object;
  date: Date;
  state: boolean;
  actions: string;
}

export interface ReviewKeywords {
  id: number;
  keyword: string;
  weight: number;
  score: number;
}
export const REVIEW_KEYWORDS_DATA: ReviewKeywords[] = [
  { id: 1, keyword: 'Lorem Ipsum', weight: 100, score: 15.00 },
  { id: 2, keyword: 'Lorem Ipsum', weight: 100, score: 15.00 },
  { id: 3, keyword: 'Lorem Ipsum', weight: 100, score: 15.00 },
  { id: 4, keyword: 'Lorem Ipsum', weight: 100, score: 15.00 },
  { id: 5, keyword: 'Lorem Ipsum', weight: 100, score: 15.00 },
  { id: 6, keyword: 'Lorem Ipsum', weight: 100, score: 15.00 },
  { id: 7, keyword: 'Lorem Ipsum', weight: 100, score: 15.00 },
  { id: 8, keyword: 'Lorem Ipsum', weight: 100, score: 15.00 },
  { id: 9, keyword: 'Lorem Ipsum', weight: 100, score: 15.00 },
  { id: 10, keyword: 'Lorem Ipsum', weight: 100, score: 15.00 },
];

@Component({
  selector: 'app-review-report-insight',
  templateUrl: './review-report-insight.component.html',
  styleUrls: ['./review-report-insight.component.scss']
})
export class ReviewReportInsightComponent implements OnInit, AfterViewInit {
  // print table or not
  keywordsLength: number;
  // data for table
  selectedLocationsData: any;
  displayedColumns: string[];
  dataSource: any;

  // data for table
  reviewKeywordsColumns: string[];
  reviewKeywordsData: any;

  // custom pagination elements
  manualPage: number;
  errorMessage: boolean;

  // custom pagination with page numbers
  @Input() page: number;
  @Input() totalPages: number;
  // events for custom pagination
  @Output() goPrev = new EventEmitter<boolean>();
  @Output() goNext = new EventEmitter<boolean>();
  @Output() goPage = new EventEmitter<boolean>();

  @ViewChild(MatPaginator, { read: MatPaginator, static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { read: MatSort, static: true }) sort: MatSort;

  constructor(private router: Router, public dialog: MatDialog, private detectStrategy: ChangeDetectorRef) {
      this.keywordsLength = 0;

    this.reviewKeywordsColumns = ['id', 'keyword', 'weight', 'score'];
    this.reviewKeywordsData = new MatTableDataSource<ReviewKeywords>(REVIEW_KEYWORDS_DATA);
  }

  ngOnInit() {
    this._updateTable();
  }
  ngAfterViewInit() {
    this.manualPage = 1;
    this.errorMessage = false;
    this.detectStrategy.detectChanges();
  }

  _updateTable() {
    // data for table
    this.displayedColumns = ['company', 'rating', 'reviews', 'responded', 'unresponded', 'actions'];
    this.dataSource = new MatTableDataSource<SelectedLocations>(this.selectedLocationsData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  // change page manually
  updateManualPage(index: number): void {
    index = +index;
    const totalPages = Math.ceil(this.paginator.length / this.paginator.pageSize);
    this.manualPage = index;
    if (index <= totalPages && index !== 0) {
      this.paginator.pageIndex = index - 1;
      this.errorMessage = false;
    } else {
      this.paginator.pageIndex = totalPages - 1;
      this.errorMessage = true;
    }
    this.paginator.page.next({
      pageIndex: this.paginator.pageIndex - 1,
      pageSize: this.paginator.pageSize - 1,
      length: this.paginator.length - 1
    });
  }
  getPages(): number[] {
    const pageLength = Math.ceil(this.paginator.length / this.paginator.pageSize);
    const currentPage = this.paginator.pageIndex || 1;
    const totalPages = this.paginator.length;
    const pages: number[] = [];
    pages.push(currentPage);

    for (let i = 0; i < totalPages - 1; i++) {
      if (pages.length < totalPages) {
        if (Math.min.apply(null, pages) > 1) {
          pages.push(Math.min.apply(null, pages) - 1);
        }
        if (Math.max.apply(null, pages) < pageLength) {
          pages.push(Math.max.apply(null, pages) + 1);
        }
      }
    }
    pages.sort((a, b) => a - b);
    return pages;
  }
  lastPage(): boolean {
    return this.paginator.pageSize * (this.paginator.pageIndex + 1) >= this.paginator.length;
  }
  onPage(index: number): void {
    this.paginator.pageIndex = index;
    this.updateManualPage(this.paginator.pageIndex);
  }
  onPrev(): void {
    this.updateManualPage(this.paginator.pageIndex);
  }
  onNext(): void {
    this.paginator.pageIndex += 2;
    this.updateManualPage(this.paginator.pageIndex);
  }
}

//deprecated

// custom labels for pagination
// export class CustomPaginatorSelectedLocations extends MatPaginatorIntl {
//   constructor() {
//     super();
//     this.itemsPerPageLabel = 'Items per page';
//   }

//   // change range label
//   getRangeLabel = (page: number, pageSize: number, length: number) => {
//     if (length === 0 || pageSize === 0) {
//       return `0 / ${length}`;
//     }
//     length = Math.max(length, 0);
//     const startIndex = page * pageSize;
//     const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
//     return `${startIndex + 1} - ${endIndex} of ${length} items`;
//   }
// }
