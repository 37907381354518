import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
var ModalFormSectionComponent = /** @class */ (function () {
    function ModalFormSectionComponent(dialogRef, decimalPipe, data) {
        this.dialogRef = dialogRef;
        this.decimalPipe = decimalPipe;
        this.data = data;
        this.modelResponse = null;
        this.typeFormPrice = 'fixed';
    }
    ModalFormSectionComponent.prototype.ngOnInit = function () {
        this.isEdit = this.data.edit;
        this.initForm();
        if (this.data.section) {
            this.isEdit = true;
            this.sectionIndex = this.data.sectionIndex;
            this.modelResponse = this.data.section;
            this.form.get('labels').setValue({
                displayName: this.modelResponse.labels[0].displayName ? this.modelResponse.labels[0].displayName : '',
                description: '',
                languageCode: 'en'
            });
        }
    };
    ModalFormSectionComponent.prototype.initForm = function () {
        this.form = new FormGroup({
            labels: new FormGroup({
                displayName: new FormControl('', [Validators.required, Validators.maxLength(140)]),
                description: new FormControl(''),
                languageCode: new FormControl('en'),
            }),
            items: new FormArray([new FormGroup({
                    displayName: new FormControl('', [Validators.required, Validators.maxLength(140)]),
                    description: new FormControl('', [Validators.maxLength(1000)]),
                    languageCode: new FormControl('en'),
                    attributes: new FormGroup({
                        currencyCode: new FormControl('USD', [Validators.required]),
                        units: new FormControl(0, [Validators.required, Validators.pattern('^[+-]?[0-9]{1,5}(?:,?[0-9]{3})*(?:\.[0-9]{2})?'), Validators.min(0)]),
                        type: new FormControl(this.typeFormPrice, Validators.required),
                    })
                })])
        });
    };
    ModalFormSectionComponent.prototype.apply = function () {
        var FormResult = this.form.value;
        var items = FormResult.items[0];
        if (this.isEdit) {
            this.modelResponse.labels = [{
                    displayName: FormResult.labels.displayName,
                    description: FormResult.labels.description,
                    languageCode: FormResult.labels.languageCode
                }];
            this.dialogRef.close({ action: 'update', item: this.modelResponse });
        }
        else {
            var units = '0';
            var nanos = 0;
            if (items.attributes.type !== 'free' && items.attributes.type !== 'noPrice') {
                var amount = items.attributes.units.split('.');
                units = amount[0];
                nanos = Number(amount[1]);
            }
            this.modelResponse = {
                labels: [
                    {
                        displayName: FormResult.labels.displayName,
                        description: FormResult.labels.description,
                        languageCode: FormResult.labels.languageCode
                    }
                ],
                items: [
                    {
                        attributes: {
                            price: {
                                currencyCode: items.attributes.currencyCode,
                                units: units,
                                nanos: nanos
                            },
                            servesNumPeople: 1,
                            mediaKeys: []
                        },
                        labels: [
                            {
                                displayName: items.displayName,
                                description: items.description,
                                languageCode: items.languageCode
                            }
                        ]
                    }
                ]
            };
            if (items.attributes.type === 'noPrice') {
                delete this.modelResponse.items[0].attributes.price;
            }
            this.dialogRef.close({ action: 'added', item: this.modelResponse });
        }
    };
    ModalFormSectionComponent.prototype.onChangePrice = function (index) {
        var formInput = this.getItemArray(index).get('attributes.units');
        var currency = this.decimalPipe.transform(formInput.value, '1.2-2');
        formInput.setValue(currency.replace(/,/g, ''));
    };
    ModalFormSectionComponent.prototype.remove = function () {
        this.dialogRef.close({ action: 'remove', item: this.modelResponse });
    };
    Object.defineProperty(ModalFormSectionComponent.prototype, "displayName", {
        get: function () {
            return this.form.get('labels.displayName');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalFormSectionComponent.prototype, "ItemsArray", {
        get: function () {
            return this.form.get('items');
        },
        enumerable: true,
        configurable: true
    });
    ModalFormSectionComponent.prototype.getItemArray = function (index) {
        return this.form.get('items').at(index);
    };
    ModalFormSectionComponent.prototype.addNewItem = function () {
        this.ItemsArray.push(new FormGroup({
            displayName: new FormControl('', [Validators.required, Validators.maxLength(140)]),
            description: new FormControl('', [Validators.maxLength(1000)]),
            languageCode: new FormControl('en'),
            attributes: new FormGroup({
                currencyCode: new FormControl('USD', [Validators.required]),
                units: new FormControl(0, [Validators.required, Validators.pattern('^[+-]?[0-9]{1,5}(?:,?[0-9]{3})*(?:\.[0-9]{2})?'), Validators.min(0)]),
                type: new FormControl(this.typeFormPrice, Validators.required),
            })
        }));
    };
    ModalFormSectionComponent.prototype.deleteItem = function (index) {
        this.ItemsArray.removeAt(index);
    };
    return ModalFormSectionComponent;
}());
export { ModalFormSectionComponent };
