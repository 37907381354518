/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./no-data.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./no-data.component";
var styles_NoDataComponent = [i0.styles];
var RenderType_NoDataComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NoDataComponent, data: {} });
export { RenderType_NoDataComponent as RenderType_NoDataComponent };
function View_NoDataComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No data"]))], null, null); }
function View_NoDataComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.message)); _ck(_v, 1, 0, currVal_0); }); }
function View_NoDataComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "txt--sm fw--normal display--block mt--20"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.description)); _ck(_v, 1, 0, currVal_0); }); }
export function View_NoDataComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.TitleCasePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["style", "height: auto;"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "h2", [["class", "txt--capitalize txt--center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NoDataComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NoDataComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NoDataComponent_3)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.message; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.message; _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.description; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "box box--rounded ", ((_co.type !== "report") ? "box--shadow" : ""), " bg--white mb--30"); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", ((_co.type !== "report") ? "box__header" : ""), " d-flex align-items-center justify-content-center"); _ck(_v, 2, 0, currVal_1); }); }
export function View_NoDataComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-no-data", [], null, null, null, View_NoDataComponent_0, RenderType_NoDataComponent)), i1.ɵdid(1, 114688, null, 0, i3.NoDataComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NoDataComponentNgFactory = i1.ɵccf("app-no-data", i3.NoDataComponent, View_NoDataComponent_Host_0, { message: "message", description: "description", type: "type" }, {}, []);
export { NoDataComponentNgFactory as NoDataComponentNgFactory };
