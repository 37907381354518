<div class="dialog__header" *ngIf="!bulk">
  <h2 mat-dialog-title class="m--0 txt--xl"><strong>Categories</strong></h2>
</div>
<div class="dialog__content" mat-dialog-content>
  <div class="dialog-row pb--0">
    <mat-form-field class="input-field">
      <input matInput placeholder="Primary Category" (change)="validate($event.target.value)" [formControl]="formPrimaryCategory" (blur)="clearSearch()"
             (keyup)="searchCategories($event)" [matAutocomplete]="primaryAuto">
      <mat-autocomplete [displayWith]="displayFn.bind(this)" #primaryAuto="matAutocomplete">
        <mat-option *ngFor="let item of search$|async" [value]="item">
          {{item.displayName}}
        </mat-option>
      </mat-autocomplete>

      <mat-error *ngIf="formPrimaryCategory.errors?.invalidAutocompleteObject">
        The category name not recognized. Click one of the autocomplete options.
      </mat-error>
    </mat-form-field>
  </div>

  <ng-container *ngIf="additionalList">
    <div class="dialog-row d-flex justify-content-between align-items-center p--0"
         *ngFor="let additional of additionalList.controls; let i = index;">
      <mat-form-field class="input-field">
        <input matInput placeholder="Additional Categories" (blur)="clearSearch()"  (keyup)="searchCategories($event)" [matAutocomplete]="additionalAuto" [formControl]="additional"   />
        <mat-autocomplete [displayWith]="displayFn"  #additionalAuto="matAutocomplete"
                          (optionSelected)="selectedAdditional($event, additional)">
          <mat-option *ngFor="let item of search$|async" [value]="item">
            {{item.displayName}}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="additional.errors?.invalidAutocompleteObject">
          The category name not recognized. Click one of the autocomplete options.
        </mat-error>
      </mat-form-field>
      <button mat-button class="btn btn-icon" (click)="removeAdditionalCategory(i)"><i class="fas fa-times"></i>
      </button>
    </div>
  </ng-container>

  <div class="pb--0 row">
    <button mat-button class="btn btn--md d-flex align-items-center" *ngIf="!hideAddCategory && additionalList.length < 9" (click)="addCategory()">
      <img src="/assets/images/add-btn.png" class="mr--10 img--xxs">Add Another Category
    </button>
  </div>

  <div class="dialog-row pb--0" *ngIf="newCategory">
    <mat-form-field class="input-field">
      <input matInput placeholder="Additional Categories" [(ngModel)]="newCategoryName">
    </mat-form-field>
  </div>


</div>
<div mat-dialog-actions class="dialog__footer" *ngIf="!bulk">
    <span class="alternate-theme d-inline-block mr--10">
        <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
    </span>
  <button mat-flat-button (click)="apply()" tabIndex="-1" color="accent" class="btn btn--action">Apply</button>
</div>
