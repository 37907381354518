/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i2 from "@angular/material/button";
import * as i3 from "@angular/cdk/a11y";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "@angular/material/dialog";
import * as i6 from "@angular/common";
import * as i7 from "./featured.component";
import * as i8 from "../services/auth.service";
var styles_FeaturedComponent = [];
var RenderType_FeaturedComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FeaturedComponent, data: {} });
export { RenderType_FeaturedComponent as RenderType_FeaturedComponent };
function View_FeaturedComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "dialog-row txt--center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "h2", [["class", "heading--xl mb--10 txt--black"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["This is a Premium Feature"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "p", [["class", "heading-h2 mb--10"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["To access this feature you need to upgrade your subscription."])), (_l()(), i0.ɵeld(7, 0, null, null, 4, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 3, "button", [["class", "btn btn--md txt--md"], ["color", "accent"], ["mat-dialog-close", "true"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 10).dialogRef.close(i0.ɵnov(_v, 10).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatButton_0, i1.RenderType_MatButton)), i0.ɵdid(9, 180224, null, 0, i2.MatButton, [i0.ElementRef, i3.FocusMonitor, [2, i4.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i0.ɵdid(10, 606208, null, 0, i5.MatDialogClose, [[2, i5.MatDialogRef], i0.ElementRef, i5.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i0.ɵted(-1, 0, ["Upgrade Now"]))], function (_ck, _v) { var currVal_4 = "accent"; _ck(_v, 9, 0, currVal_4); var currVal_5 = "true"; _ck(_v, 10, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 9).disabled || null); var currVal_1 = (i0.ɵnov(_v, 9)._animationMode === "NoopAnimations"); var currVal_2 = (i0.ɵnov(_v, 10).ariaLabel || null); var currVal_3 = i0.ɵnov(_v, 10).type; _ck(_v, 8, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_FeaturedComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "dialog-row txt--center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "h2", [["class", "heading--xl mb--10 txt--black"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["This is a Premium Feature"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "p", [["class", "heading-h2 mb--10"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["To access this feature you need to contact your administrator."]))], null, null); }
export function View_FeaturedComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "dialog__content dialog--featured mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i5.MatDialogContent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["alt", "Image of a star"], ["class", "dialog__icon"], ["height", "128"], ["src", "/assets/images/modal-star.png"], ["width", "128"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "button", [["class", "btn btn-dialog-close btn--absolute"], ["mat-dialog-close", ""]], [[1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).dialogRef.close(i0.ɵnov(_v, 4).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 606208, null, 0, i5.MatDialogClose, [[2, i5.MatDialogRef], i0.ElementRef, i5.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 0, "i", [["class", "txt--lg fal fa-times"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FeaturedComponent_1)), i0.ɵdid(7, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FeaturedComponent_2)), i0.ɵdid(9, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = ""; _ck(_v, 4, 0, currVal_2); var currVal_3 = !_co.isMember(); _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.isMember(); _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 4).ariaLabel || null); var currVal_1 = i0.ɵnov(_v, 4).type; _ck(_v, 3, 0, currVal_0, currVal_1); }); }
export function View_FeaturedComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-featured", [], null, null, null, View_FeaturedComponent_0, RenderType_FeaturedComponent)), i0.ɵdid(1, 49152, null, 0, i7.FeaturedComponent, [i5.MAT_DIALOG_DATA, i5.MatDialog, i8.AuthService], null, null)], null, null); }
var FeaturedComponentNgFactory = i0.ɵccf("app-featured", i7.FeaturedComponent, View_FeaturedComponent_Host_0, {}, {}, []);
export { FeaturedComponentNgFactory as FeaturedComponentNgFactory };
