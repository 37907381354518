<div class="box__content box--padding-xxs" *ngIf="dataset">
  <div>
    <div class="w--100 mb--20" style="min-height: 300px" >
      <!-- adding ID with labels as the value for Cypress Tests -->
      <canvas
        baseChart
        [attr.id-ct]="labels"
        #actionChartLine="base-chart"
        [datasets]="dataset"
        [labels]="labels"
        [legend]="barChartLegend"
        [options]="barChartOptions"
        [colors]="colors"
        [chartType]="chartType">
      </canvas>
    </div>

    <!--checkbox-->
    <div *ngIf="showLegend">
      <div class="p--lr-30 d-flex justify-content-start align-items-center flex-wrap mb--10" *ngIf="!disabledLengend" [class.justify-content-center]="centerLegend">
        <div class="d-flex align-items-center mb--10 mr--10" *ngFor="let item of dataset; let i = index">
          <mat-checkbox class="mr--10 txt--14 white-checkbox" [checked]="true" [disabled]="isCheckboxDisabled(actionChartLine, i)" (change)="actionChartLine.hideDataset(i, !actionChartLine.isDatasetHidden(i))">
            {{item?.label}}
          </mat-checkbox>
        </div>
      </div>

      <div class="p--lr-30 d-flex justify-content-start align-items-center flex-wrap mb--10" *ngIf="disabledLengend" [class.justify-content-center]="centerLegend">
        <div class="d-flex align-items-center mb--10 mr--10" *ngFor="let item of dataset; let i = index">
          <div class="mat-checkbox--square mr--10" [ngStyle]="{'background': item.borderColor}"></div>
          <p class="txt--14 mr--5 mb--0" >{{item?.label}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
