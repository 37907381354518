
    <div class="dialog__header txt--center dialog__header--info">
      <h2 mat-dialog-title class="m--0 txt--xl"><strong>Posting Error Details</strong></h2>
    </div>

    <div class="dialog__content" mat-dialog-content>
      <mat-progress-bar mode="indeterminate" *ngIf="loadingPostErrors"></mat-progress-bar>
      <ng-container *ngIf="!loadingPostErrors">
      <mat-card *ngFor="let post of locationsPost;let i = index"  class="mb--20">
        <div class="row">
          <div class="col-3">
            <h3><b>{{ post?.meta?.locationName }}</b></h3>

            <gmb-address *ngIf="post?.meta?.address" [address]="post?.meta?.address"></gmb-address>
          </div>
          <div class="col-2">
            <button mat-flat-button [color]=" post.hasError !== null ? 'warn' : 'accent' "
                    class="btn--badge btn--badge--sm">
              <i class="fas fa-exclamation-triangle"></i>
              <span>Failed</span>
            </button>
          </div>
          <div class="col-6">
            <h3><b>Details:</b></h3>
            <ng-container *ngIf="!post.error?.description; else normalStructure">
              <read-more [text]="post.error?.message" [maxLength]="300"></read-more>
            </ng-container>
            <ng-template #normalStructure>
              <div class="m--10" *ngIf="post.error?.description?.details?.length > 0; else noDetail">
                <div *ngFor="let error of post.error?.description?.details">
                  <div *ngFor="let errorChild of error.errorDetails">
                    - {{errorChild.message}}
                  </div>
                </div>
              </div>
              <ng-template #noDetail>
                {{post.error?.message||'Unknown error'}}
              </ng-template>
            </ng-template>
          </div>
          <div class="col-1">
            <button mat-flat-button color="primary" class="btn btn-icon btn-icon--lg" (click)="retry(post)">
                <i class="fas fa-redo" [ngClass]="{'fa-spin': false}"></i>
            </button>
          </div>
        </div>
      </mat-card>
    </ng-container>
    </div>
    <div mat-dialog-actions class="dialog__footer">
      <button mat-button
              tabIndex="-1"
              color="primary"
              mat-dialog-close
              class="btn btn-cancel">
              Cancel
      </button>
      <button *ngIf="!this.loadingPostErrors && this.consumeData?.bulk"
          tabIndex="-1"
          mat-flat-button
          color="primary"
          class="btn btn--action"
          (click)="retryAll()">
          Retry all
      </button>
    </div>
  