import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Pageable} from '../../../constants/pageable';
import {Pagination, PAGINATION_OPTION} from '../../../constants/api-response';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @Input() pagination: Pagination;
  @Input() options: number[];
  @Input() loadingTotal: boolean = false;
  page: number = 1;
  currentPage: number = 1;
  @Output() reload = new EventEmitter<Pageable>();
  errorMessage: any;
  errorPageMessage: boolean = false;
  manualPage: number;
  PAGINATION_OPTION = PAGINATION_OPTION;

  constructor() {}

  ngOnInit() {
    this.currentPage = this.pagination.page;
    if (this.options){
      this.PAGINATION_OPTION = this.options
    }
  }

  onPrev() {
    if (this.pagination.hasPrev) {
      this.pagination.page--;
      this.reloadEmit();
    }
  }

  onNext() {
    if (this.pagination.hasNext) {
      this.pagination.page++;
      this.reloadEmit();
    }
  }

  updateManualPage($event: any) {
    if ($event instanceof KeyboardEvent) {
      if ($event.key === 'Enter') {
        if (this.pagination.page <= this.pagination.pages && this.pagination.page >= 1 && this.pagination.page !== this.currentPage) this.reloadEmit();
        else return;
      }
    }
    if ($event instanceof FocusEvent) {
      if (this.pagination.page <= this.pagination.pages && this.pagination.page >= 1 && this.pagination.page !== this.currentPage) this.reloadEmit();
      else return;
    }
  }

  validate(page) {
    if (page === "" || isNaN(page)) {
      this.errorMessage = true;
      this.errorPageMessage = false;
    } else {
      this.errorMessage = false;
      page = parseInt(page)
      if (page > this.pagination.pages || page < 1) {
        this.errorPageMessage = true;
      } else {
        this.errorPageMessage = false;
        this.pagination.page = page
      }
    }
  }


  handlePage($event) {
    this.pagination.per_page = $event.pageSize;
    this.pagination.page = $event.pageIndex + 1;
    this.page = $event.pageIndex + 1;
    this.reloadEmit();
  }

  reloadEmit() {
    

    if (!isNaN(this.pagination.page)) {
      if (this.pagination.page <= this.pagination.pages) {
        this.currentPage = this.pagination.page;
      } else {
        if(this.pagination.pages !== undefined){
          this.pagination.page = this.pagination.pages;
        }
      }
    } else {
      this.pagination.page = 1;
    }
    this.reload.emit({page: this.pagination.page, size: this.pagination.per_page});
  }

  reset() {
    this.paginator.firstPage();
  }
}
