<!--<div class="mat-row mat-row--padding mat-row--border d-flex justify-content-between align-items-center bg--white">
  <div class="input-field input-field--search">
    <div class="table__icon table__icon--lg">
      <i class="fas fa-search"></i>
    </div>
    <input placeholder="Search Report Name">
  </div>

  <div class="table__buttons alternate-theme">
    <button mat-flat-button color="primary" class="btn btn-icon mr--10"><i class="fas fa-th"></i></button>
    <button mat-flat-button color="primary" class="btn btn-icon"><i class="fas fa-list"></i></button>
  </div>
</div>-->
<table class="table table--sm table--last-row-border bg--white">
  <thead>
  <tr class="mat-row">
    <th class="mat-header-cell txt--left"><strong>Name</strong></th>
    <th class="mat-header-cell txt--left"><strong>Created On</strong></th>
    <th class="mat-header-cell txt--left" *ngIf="type !=='comparison'"><strong>Last Modified</strong></th>
    <th class="mat-header-cell txt--left" *ngIf="type ==='comparison'"><strong>Modified On</strong></th>
    <th class="mat-header-cell txt--left" *ngIf="type ==='comparison'"><strong>Locations</strong></th>
    <th class="mat-header-cell txt--left"><strong>Date</strong></th>
    <th class="mat-header-cell txt--right"><strong>Actions</strong></th>
  </tr>
  </thead>
  <tbody>
  <tr class="mat-row" *ngFor="let i of [0,1,2]">

    <td class="mat-cell">
      <ngx-skeleton-loader count="1" [theme]="{ width: '228px', height: '20px' }"></ngx-skeleton-loader>
    </td>

    <td class="mat-cell" style="width: 14%">
      <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px' }"></ngx-skeleton-loader>
    </td>

    <td class="mat-cell" style="width: 10%">
      <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px' }"></ngx-skeleton-loader>
    </td>

    <td class="mat-cell" *ngIf="type ==='comparison'" style="width: 10%">
      <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px' }"></ngx-skeleton-loader>
    </td>

    <td class="mat-cell cdk-column-date {{ type==='comparison' ? 'cdk-column-date-lg' : '' }}">
      <div class="cell__content d-flex flex-column align-items-start" *ngIf="type!='comparison'">
        <ngx-skeleton-loader count="2" [theme]="{ width: '100px', height: '20px', 'display': 'block', 'margin-bottom': '5px' }"></ngx-skeleton-loader>
      </div>
      <div class="table--box__row"*ngIf="type=='comparison'">
        <div class="table--box__row__item" style="padding-bottom: 10px">
          <span class="d-block txt--black txt--uppercase"><strong>Range A</strong></span>
          <ngx-skeleton-loader count="2" [theme]="{ width: '110px', height: '20px', 'display': 'block', 'margin': '0 25px 5px' }"></ngx-skeleton-loader>
        </div>
        <div class="table--box__row__item" style="padding-bottom: 10px">
          <span class="d-block txt--black txt--uppercase"><strong>Range B</strong></span>
          <ngx-skeleton-loader count="2" [theme]="{ width: '110px', height: '20px', 'display': 'block', 'margin': '0 25px 5px' }"></ngx-skeleton-loader>
        </div>
      </div>

    </td>

    <td class="mat-cell mat-column-actions txt--right">
      <ngx-skeleton-loader count="3" appearance="circle" [theme]="{ width: '30px', height: '30px', 'margin': '0 10px 0 0', 'border-radius': '5px' }"></ngx-skeleton-loader>
      <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '30px', height: '30px', 'margin': '0', 'border-radius': '5px' }"></ngx-skeleton-loader>
    </td>
  </tr>
  </tbody>
</table>
