import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ModalPhoneComponent } from "../../modal-phone/modal-phone.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'app-location-phone',
  templateUrl: './location-phone.component.html',
  styleUrls: ['./location-phone.component.scss']
})
export class LocationPhoneComponent implements OnInit {
  @Input() additionalPhones: any = [];
  @Input() primaryPhone: any;
  @Input() isEdit = true;
  @Input() isUnderReview = false;
  @Input() hasPrimaryPhone = true;
  @Output() onUpdate: EventEmitter<boolean> = new EventEmitter();

  constructor(private dialog: MatDialog) { }

  ngOnInit() {
  }

  // edit phone
  openPhoneDialog() {
    const dialogRef = this.dialog.open(ModalPhoneComponent, {
      width: '680px',
      data: {
        primaryPhone: this.primaryPhone,
        additionalPhones: this.additionalPhones
      }
    });

    dialogRef.disableClose = true;

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.primaryPhone = result.primary;
        this.additionalPhones = result.additional;
        this.onUpdate.emit(true);
      }
    });
  }

}
