/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./location-state.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./location-state.component";
var styles_LocationStateComponent = [i0.styles];
var RenderType_LocationStateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LocationStateComponent, data: {} });
export { RenderType_LocationStateComponent as RenderType_LocationStateComponent };
function View_LocationStateComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "mb--15"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Location State"]))], null, null); }
function View_LocationStateComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "far fa-check mr--15"]], null, null, null, null, null))], null, null); }
function View_LocationStateComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "far fa-ban mr--15"]], null, null, null, null, null))], null, null); }
function View_LocationStateComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "p", [["class", "m--0 txt--lightgray"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LocationStateComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["ban", 2]], null, 0, null, View_LocationStateComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.state[_v.context.$implicit]; var currVal_2 = i1.ɵnov(_v, 4); _ck(_v, 3, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.convertKeyToText(_v.context.$implicit); _ck(_v, 1, 0, currVal_0); }); }
export function View_LocationStateComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LocationStateComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "box__item box__item--sm box__item--editable"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LocationStateComponent_2)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isEdit; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.keys(); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_LocationStateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-location-state", [], null, null, null, View_LocationStateComponent_0, RenderType_LocationStateComponent)), i1.ɵdid(1, 114688, null, 0, i3.LocationStateComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LocationStateComponentNgFactory = i1.ɵccf("app-location-state", i3.LocationStateComponent, View_LocationStateComponent_Host_0, { state: "state", isEdit: "isEdit" }, {}, []);
export { LocationStateComponentNgFactory as LocationStateComponentNgFactory };
