<div class="dialog__header" *ngIf="!bulk">
  <h2 mat-dialog-title class="m--0 txt--xl"><strong>Phone</strong></h2>
</div>

<div class="dialog__content" mat-dialog-content>
  <form [formGroup]="form">
    <div class="dialog-row w--100">
      <label class="input-group__label">Primary Phone</label>
        <ngx-intl-tel-input
            [enablePlaceholder]="true"
            [enableAutoCountrySelect]="true"
            [phoneValidation]="true"
            [maxlength]="20"
            name="phone_number"
            formControlName="primary">
        </ngx-intl-tel-input>
      <p *ngIf="primary?.invalid" class="txt--red pt--10 m--0">Invalid phone number</p>
    </div>

    <div formArrayName="additional">
      <label class="input-group__label m--0 fw--normal">Additional Phones</label>
      <ng-container *ngFor="let item of additional.controls; let phoneIndex = index;" [formGroupName]="phoneIndex">
        <div class="dialog-row d-flex align-items-center p--0 pt--10 mb--10 w--100">
          <section class="w--100">
            <ngx-intl-tel-input
              [enablePlaceholder]="true"
              [enableAutoCountrySelect]="true"
              [phoneValidation]="true"
              [maxlength]="20"
              name="phone_number"
              formControlName="phone">
            </ngx-intl-tel-input>
          </section>
          <button mat-button class="btn btn-icon" (click)="removePhone(phoneIndex)"><em class="fas fa-times"></em></button>
        </div>
        <p *ngIf="item?.invalid" class="txt--red pt--10 m--0 w--100">Invalid phone number</p>
      </ng-container>
    </div>

    <div class="pb--0 row" *ngIf="additional.controls.length < 2">
      <button mat-button class="btn btn--md d-flex align-items-center" (click)="addPhone()">
        <img src="/assets/images/add-btn.png" class="mr--10 img--xxs">Add additional phone</button>
    </div>
  </form>
</div>

<div mat-dialog-actions class="dialog__footer" *ngIf="!bulk">
    <span class="alternate-theme d-inline-block mr--10">
        <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
    </span>
    <button mat-flat-button (click)="apply()"  tabIndex="-1" color="accent" class="btn btn--action">Apply</button>
</div>