// TODO: Refactor against admin-frontend/http-domain.interceptor.ts

// dep
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http"
import { Observable } from "rxjs"
import { Injectable } from "@angular/core"

// app
import { WhiteLabelService } from '../services/white-label.service'
import { environment as ENV } from "@environment"

@Injectable()
export class HttpDomainInterceptor implements HttpInterceptor {

  constructor(private wl: WhiteLabelService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.headers.has('X-domain') &&
        (req.url.includes(ENV.apiUrl) ||
         req.url.includes(ENV.apiUrlV3) ||
         req.url.includes(ENV.billingApiUrl) ||
         req.url.includes(ENV.coreApiUrl))) {
      // Only add headers to requests targeted to Maplabs Services and not to other 
      // ones (e.g. requests directly targeted to Google).
      const domain = this.wl.slugDomain
      req = req.clone({ headers: req.headers.set('X-domain', domain) });
    }

    return next.handle(req)
  }
}
