import { MatDialogRef } from '@angular/material';
import { AuthService } from '../../services/auth.service';
var ModalTrialEnded = /** @class */ (function () {
    function ModalTrialEnded(dialogRef, data, _auth) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.data = data;
        this._auth = _auth;
        this._data = data;
        this.subscription$ = this._auth.subscription$;
        this.subscription$.subscribe(function (subscription) {
            _this.subscription = subscription;
            _this.price = _this.getPrice();
        });
        this.group$ = this._auth.groupOut$;
        this.group$.subscribe(function (group) {
            _this.group = group;
            _this.price = _this.getPrice();
        });
    }
    ModalTrialEnded.prototype.getPrice = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        var price = 0;
        if (!this.group || !this.subscription)
            return price;
        this.locations_qty = this.group.totalLocationsCount;
        for (var i = 0; i < ((_b = (_a = this.subscription.pricing) === null || _a === void 0 ? void 0 : _a.pkg_bulk_actions) === null || _b === void 0 ? void 0 : _b.tiers.length); i++) {
            if (this.locations_qty >= ((_e = (_d = (_c = this.subscription.pricing) === null || _c === void 0 ? void 0 : _c.pkg_bulk_actions) === null || _d === void 0 ? void 0 : _d.tiers[i]) === null || _e === void 0 ? void 0 : _e.locationsQtyMin) &&
                this.locations_qty <= ((_h = (_g = (_f = this.subscription.pricing) === null || _f === void 0 ? void 0 : _f.pkg_bulk_actions) === null || _g === void 0 ? void 0 : _g.tiers[i]) === null || _h === void 0 ? void 0 : _h.locationsQtyMax)) {
                price = (_l = (_k = (_j = this.subscription.pricing) === null || _j === void 0 ? void 0 : _j.pkg_bulk_actions) === null || _k === void 0 ? void 0 : _k.tiers[i]) === null || _l === void 0 ? void 0 : _l.price;
                return price;
            }
        }
    };
    ModalTrialEnded.prototype.accept_cta = function () {
        window.open(this.data.buttons['accept_cta'], "_blank");
    };
    ModalTrialEnded.prototype.close = function () {
        this.dialogRef.close();
        this._auth.signOut();
    };
    return ModalTrialEnded;
}());
export { ModalTrialEnded };
