 import {Component, Input, OnChanges} from '@angular/core';

@Component({
  selector: 'read-more',
  template: `
	  <div style="display: inline;" [innerHTML]="currentText"></div>
    <a class="link link--blue txt--normal ml--5" [class.link--white]='isWhite'
       *ngIf="showToggleButton"
			   (click)="toggleView()">{{txtLink}} {{isCollapsed ? 'more' : 'less'}}</a>
        `
})

export class ReadMoreComponent implements OnChanges {

  @Input('text') text: string;
  @Input('maxLength') maxLength: number = 100;
  @Input('showToggleButton') showToggleButton: boolean;
  @Input() isWhite = false;
  @Input() txtLink = 'Read';

  currentText: string;

  public isCollapsed: boolean = true;

  constructor(
    //private elementRef: ElementRef
  ) {

  }

  toggleView() {
    this.isCollapsed = !this.isCollapsed;
    this.determineView();
  }

  determineView() {
    if (this.text.length <= this.maxLength || typeof this.text !== "string") {
      this.currentText = this.text;
      this.showToggleButton = false;
      return;
    }

    if (this.text.length <= this.maxLength) {
      this.currentText = this.text;
      this.isCollapsed = false;
      this.showToggleButton = false;
      return;
    }

    if (this.isCollapsed == true) {
      this.currentText = this.text.substring(0, this.maxLength) + "...";
    } else if (this.isCollapsed == false) {
      this.currentText = this.text;
    }

  }

  ngOnChanges() {
    if (!this.validateSource(this.text)) {
      // console.e rror('Source must be a string.');
    } else  {
      this.determineView();
    }
  }

  validateSource(s) {
    if (typeof s !== 'string') {
      this.showToggleButton = false;
      return false;
    } else {
      return true;
    }
  }
}
