import { __awaiter, __generator } from "tslib";
// app
import { AuthService } from './auth.service';
import { LocationService } from './location.service';
import { ModalService } from './modal.service';
import { SubscriptionService } from './subscription.service';
import { GROUP_SUBSCRIPTION_TYPE, LOCATION_SUBSCRIPTION_TYPE } from '../constants/firestore/account-location';
import { FeaturedComponent } from '../components/featured.component';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "./location.service";
import * as i3 from "./modal.service";
import * as i4 from "./subscription.service";
var LocationFreeGuardService = /** @class */ (function () {
    function LocationFreeGuardService(auth, locationService, modalService, subscriptionService) {
        var _this = this;
        this.auth = auth;
        this.locationService = locationService;
        this.modalService = modalService;
        this.subscriptionService = subscriptionService;
        this.subscription$ = this.auth.subscription$;
        this.subscription$.subscribe(function (subscription) { return _this.subscription = subscription; });
    }
    // TODO: Search #TODO-refactor-featured-model to find code to refactor against this 
    // code block. 
    LocationFreeGuardService.prototype.canActivate = function (route) {
        return __awaiter(this, void 0, void 0, function () {
            var locationId, accountId, _a, subscriptionType;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.user = this.auth.session;
                        locationId = route.parent.params.locationId;
                        accountId = route.parent.params.accountId;
                        _a = this;
                        return [4 /*yield*/, this.locationService.fetchLocation(this.user.gid, accountId, locationId)];
                    case 1:
                        _a.location = _b.sent();
                        subscriptionType = this.location.subscriptionType;
                        if (this.subscription &&
                            this.subscription.pricingVersion < 3 &&
                            this.subscription.status !== GROUP_SUBSCRIPTION_TYPE.TRIAL &&
                            (subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE || subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL)) {
                            this.modalService.openModal(FeaturedComponent, null, { onClose: function (res) { return __awaiter(_this, void 0, void 0, function () {
                                    var location;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                if (!res) {
                                                    return [2 /*return*/];
                                                }
                                                location = { locationId: this.location.locationId,
                                                    accountId: route.parent.params.accountId };
                                                console.log('LocationFreeGuardService upgrade suscription');
                                                return [4 /*yield*/, this.subscriptionService.flowChangeLocationsPlan(this.subscription, [location])];
                                            case 1:
                                                if (_a.sent())
                                                    console.log("upgrade successful - location-guard");
                                                return [2 /*return*/];
                                        }
                                    });
                                }); } });
                            // TODO: returns false without awaiting the previous code, evaluate if that asynchronity is really needed
                            return [2 /*return*/, false];
                        }
                        return [2 /*return*/, true];
                }
            });
        });
    };
    LocationFreeGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocationFreeGuardService_Factory() { return new LocationFreeGuardService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.LocationService), i0.ɵɵinject(i3.ModalService), i0.ɵɵinject(i4.SubscriptionService)); }, token: LocationFreeGuardService, providedIn: "root" });
    return LocationFreeGuardService;
}());
export { LocationFreeGuardService };
var LocationUltimateTrialGuardService = /** @class */ (function () {
    function LocationUltimateTrialGuardService(auth, locationService, modalService, subscriptionService) {
        var _this = this;
        this.auth = auth;
        this.locationService = locationService;
        this.modalService = modalService;
        this.subscriptionService = subscriptionService;
        this.subscription$ = this.auth.subscription$;
        this.subscription$.subscribe(function (subscription) { return _this.subscription = subscription; });
    }
    // TODO: Search #TODO-refactor-featured-model to find code to refactor against this 
    // code block. 
    LocationUltimateTrialGuardService.prototype.canActivate = function (route) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var locationId, accountId, _c, subscriptionType;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        this.user = this.auth.session;
                        locationId = route.parent.params.locationId;
                        accountId = route.parent.params.accountId;
                        _c = this;
                        return [4 /*yield*/, this.locationService.fetchLocation(this.user.gid, accountId, locationId)];
                    case 1:
                        _c.location = _d.sent();
                        subscriptionType = this.location.subscriptionType;
                        if (((_a = this.subscription) === null || _a === void 0 ? void 0 : _a.pricingVersion) < 3 &&
                            ((_b = this.subscription) === null || _b === void 0 ? void 0 : _b.status) === GROUP_SUBSCRIPTION_TYPE.TRIAL &&
                            (subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE ||
                                subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL)) {
                            this.modalService.openModal(FeaturedComponent, null, { onClose: function (res) { return __awaiter(_this, void 0, void 0, function () {
                                    var location;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                if (!res) {
                                                    return [2 /*return*/];
                                                }
                                                console.log('LocationUltimateTrialGuardService upgrade suscription');
                                                location = { locationId: this.location,
                                                    accountId: route.parent.params.accountId };
                                                return [4 /*yield*/, this.subscriptionService.flowChangeLocationsPlan(this.subscription, [location])];
                                            case 1:
                                                if (_a.sent())
                                                    console.log("upgrade successful - location-guard");
                                                return [2 /*return*/];
                                        }
                                    });
                                }); } });
                            // TODO: returns false without awaiting the previous code, evaluate if that asynchronity is really needed
                            return [2 /*return*/, false];
                        }
                        return [2 /*return*/, true];
                }
            });
        });
    };
    LocationUltimateTrialGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocationUltimateTrialGuardService_Factory() { return new LocationUltimateTrialGuardService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.LocationService), i0.ɵɵinject(i3.ModalService), i0.ɵɵinject(i4.SubscriptionService)); }, token: LocationUltimateTrialGuardService, providedIn: "root" });
    return LocationUltimateTrialGuardService;
}());
export { LocationUltimateTrialGuardService };
