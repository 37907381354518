/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "./featured-report.component";
var styles_FeaturedReportComponent = [];
var RenderType_FeaturedReportComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FeaturedReportComponent, data: {} });
export { RenderType_FeaturedReportComponent as RenderType_FeaturedReportComponent };
export function View_FeaturedReportComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 24, "div", [["class", "dialog__content dialog--featured mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.MatDialogContent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["alt", "Image of a star"], ["class", "dialog__icon"], ["height", "128"], ["src", "/assets/images/modal-star.png"], ["width", "128"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "button", [["class", "btn btn-dialog-close btn--absolute"], ["mat-dialog-close", ""]], [[1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).dialogRef.close(i0.ɵnov(_v, 4).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 606208, null, 0, i1.MatDialogClose, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 0, "i", [["class", "txt--lg fal fa-times"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 8, "div", [["class", "dialog-row txt--center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "h2", [["class", "heading--xl mb--10 mt--25 txt--black"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Report Contains Essential Or Basic Locations"])), (_l()(), i0.ɵeld(10, 0, null, null, 4, "p", [["class", "heading-h2 mb--10"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["To access this report, you must upgrade all locations to the "])), (_l()(), i0.ɵeld(12, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Ultimate"])), (_l()(), i0.ɵted(-1, null, [" plan or remove them from the report."])), (_l()(), i0.ɵeld(15, 0, null, null, 9, "div", [["class", "dialog__footer d-flex justify-content-between mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i0.ɵdid(16, 16384, null, 0, i1.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(17, 0, null, null, 3, "button", [["class", "btn btn--md txt--md"], ["color", "accent"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 19).dialogRef.close(i0.ɵnov(_v, 19).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i0.ɵdid(18, 180224, null, 0, i3.MatButton, [i0.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i0.ɵdid(19, 606208, null, 0, i1.MatDialogClose, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i0.ɵted(-1, 0, ["Upgrade All"])), (_l()(), i0.ɵeld(21, 0, null, null, 3, "button", [["class", "btn btn--md txt--md"], ["color", "primary"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 23).dialogRef.close(i0.ɵnov(_v, 23).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i0.ɵdid(22, 180224, null, 0, i3.MatButton, [i0.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i0.ɵdid(23, 606208, null, 0, i1.MatDialogClose, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i0.ɵted(-1, 0, ["Edit Report"]))], function (_ck, _v) { var currVal_2 = ""; _ck(_v, 4, 0, currVal_2); var currVal_7 = "accent"; _ck(_v, 18, 0, currVal_7); var currVal_8 = "upgrade"; _ck(_v, 19, 0, currVal_8); var currVal_13 = "primary"; _ck(_v, 22, 0, currVal_13); var currVal_14 = "edit"; _ck(_v, 23, 0, currVal_14); }, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 4).ariaLabel || null); var currVal_1 = i0.ɵnov(_v, 4).type; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = (i0.ɵnov(_v, 18).disabled || null); var currVal_4 = (i0.ɵnov(_v, 18)._animationMode === "NoopAnimations"); var currVal_5 = (i0.ɵnov(_v, 19).ariaLabel || null); var currVal_6 = i0.ɵnov(_v, 19).type; _ck(_v, 17, 0, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = (i0.ɵnov(_v, 22).disabled || null); var currVal_10 = (i0.ɵnov(_v, 22)._animationMode === "NoopAnimations"); var currVal_11 = (i0.ɵnov(_v, 23).ariaLabel || null); var currVal_12 = i0.ɵnov(_v, 23).type; _ck(_v, 21, 0, currVal_9, currVal_10, currVal_11, currVal_12); }); }
export function View_FeaturedReportComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-featured-report", [], null, null, null, View_FeaturedReportComponent_0, RenderType_FeaturedReportComponent)), i0.ɵdid(1, 49152, null, 0, i6.FeaturedReportComponent, [i1.MAT_DIALOG_DATA, i1.MatDialog], null, null)], null, null); }
var FeaturedReportComponentNgFactory = i0.ɵccf("app-featured-report", i6.FeaturedReportComponent, View_FeaturedReportComponent_Host_0, {}, {}, []);
export { FeaturedReportComponentNgFactory as FeaturedReportComponentNgFactory };
