import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ModalConfirmData} from "../classes/modal-confirm-data";
import {SnackbarService} from "../services/snackbar.service";

@Component({
  selector: 'review-link-generator',
  template: `
	  <div class="dialog__header txt--center dialog__header--warn"
		   [ngClass]="{'dialog__header--warn': data.alertType == 1, 'dialog__header--info': data.alertType == 0, 'dialog__header--error': data.alertType == 2}">
		  <h2 mat-dialog-title class="m--0 txt--xl"><strong>Review Link</strong></h2>
	  </div>

	  <div class="dialog__content" mat-dialog-content>

		  <div class="dialog-row pb--0">

			  <ng-container>
				  <div class="d-flex">
					  <mat-form-field floatLabel="never"
									  class="input-group input-group--override input-group--override-label">
						  <mat-label>Review link url</mat-label>
						  <input matInput class="input-group__field no-borders-right" [value]="url" readonly="true">
					  </mat-form-field>
					  <button mat-flat-button type="button" color="primary" class="btn btn-icon btn-icon--share"
							  (click)="copy($event)" *ngIf="!isCopy">
						  <i class="fal fa-copy mr--5"></i>
					  </button>
				  </div>
			  </ng-container>
			  <ng-container>
				  <qrcode #parent [qrdata]="url" [width]="256" [errorCorrectionLevel]="'M'" elementType="img"></qrcode>
				  <button color="primary" mat-flat-button class="btn btn--lg"
						  (click)="saveAsImage(parent)">
					  Download QR Code
				  </button>


			  </ng-container>

		  </div>
	  </div>
	  <div mat-dialog-actions class="dialog__footer">
		  <button tabIndex="-1" mat-button class="btn btn-cancel" [mat-dialog-close]="true">Cancel</button>
	  </div>
  `,
})
export class ReviewLinkGeneratorComponent implements OnInit {

  public isCopy;
  public url: string;
  public elementType;
  public correctionLevel;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ModalConfirmData,
    public dialogRef: MatDialogRef<any>,
    private snack: SnackbarService,
  ) {
  }

  copy($event) {
    $event.preventDefault();
    const selBox = document.createElement('input');
    selBox.value = this.url;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.isCopy = true;
    this.snack.openSuccess('The link was copied to your clipboard.', 5000);
    this.cancel();
  }

  ngOnInit() {
    this.url = this.data.data.shortLink;
  }


  cancel() {
    this.dialogRef.close();
  }

  saveAsImage(parent) {
    // fetches base 64 date from image
    const parentElement = parent.qrcElement.nativeElement.querySelector("img").src;

    // converts base 64 encoded image to blobData
    let blobData = this.convertBase64ToBlob(parentElement);

    // saves as image
    if (window.navigator && window.navigator.msSaveOrOpenBlob) { //IE
      window.navigator.msSaveOrOpenBlob(blobData, 'Qrcode');
    } else { // chrome
      const blob = new Blob([blobData], {type: "image/png"});
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'Qrcode';
      link.click();
    }

  }

  private convertBase64ToBlob(Base64Image: any) {
    // SPLIT INTO TWO PARTS
    const parts = Base64Image.split(';base64,');
    // HOLD THE CONTENT TYPE
    const imageType = parts[0].split(':')[1];
    // DECODE BASE64 STRING
    const decodedData = window.atob(parts[1]);
    // CREATE UNIT8ARRAY OF SIZE SAME AS ROW DATA LENGTH
    const uInt8Array = new Uint8Array(decodedData.length);
    // INSERT ALL CHARACTER CODE INTO UINT8ARRAY
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    // RETURN BLOB IMAGE AFTER CONVERSION
    return new Blob([uInt8Array], {type: imageType});
  }
}
