import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-skeleton-posts',
  templateUrl: './skeleton-posts.component.html'
})
export class SkeletonPostsComponent implements OnInit {
  @Input() type: string;
  @Input() isNewPost = false;
  @Input() dashboardType = 'BULK_POST';

  constructor() { }

  ngOnInit() {
  }

}
