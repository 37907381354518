import { __awaiter, __generator } from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { MatDrawer } from '@angular/material';
import { Router } from '@angular/router';
import { AlertType } from 'src/app/components/alert.component';
import { ModalService } from 'src/app/services/modal.service';
import { PostService } from 'src/app/services/post.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import moment from 'moment';
var PostManagementStatusComponent = /** @class */ (function () {
    function PostManagementStatusComponent(_modalS, _postS, _snackS, _router) {
        this._modalS = _modalS;
        this._postS = _postS;
        this._snackS = _snackS;
        this._router = _router;
        this.isSliderOpen = false;
        this.statusData = [];
        this.postTitle = null;
        this.postId = null;
        this.closeStatusSlider = new EventEmitter();
        this.isLoading = false;
    }
    PostManagementStatusComponent.prototype.ngOnInit = function () {
    };
    PostManagementStatusComponent.prototype.getStatusClass = function (status) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        var statusClass = 'status--gray';
        if (((_a = status) === null || _a === void 0 ? void 0 : _a.toUpperCase()) === 'DRAFT') {
            statusClass = 'status--gray';
        }
        else if (((_b = status) === null || _b === void 0 ? void 0 : _b.toUpperCase()) === 'PENDING' || ((_c = status) === null || _c === void 0 ? void 0 : _c.toUpperCase()) === 'SCHEDULED') {
            statusClass = 'status--blue';
        }
        else if (((_d = status) === null || _d === void 0 ? void 0 : _d.toUpperCase()) === 'PUBLISHED' || ((_e = status) === null || _e === void 0 ? void 0 : _e.toUpperCase()) === 'FINISHED' || ((_f = status) === null || _f === void 0 ? void 0 : _f.toUpperCase()) === 'SUCCESS') {
            statusClass = 'status--green';
        }
        else if (((_g = status) === null || _g === void 0 ? void 0 : _g.toUpperCase()) === 'FLAGGED_FOR_DELETE' || ((_h = status) === null || _h === void 0 ? void 0 : _h.toUpperCase()) === 'CONTACT_SUPPORT') {
            statusClass = 'status--yellow';
        }
        else if (((_j = status) === null || _j === void 0 ? void 0 : _j.toUpperCase()) === 'FAILED' || ((_k = status) === null || _k === void 0 ? void 0 : _k.toUpperCase()) === 'REJECTED') {
            statusClass = 'status--red';
        }
        return statusClass;
    };
    PostManagementStatusComponent.prototype.statusLabel = function (status) {
        return this._postS.getStatusLabel(status);
    };
    PostManagementStatusComponent.prototype.getStatus = function (status) {
        return status.replaceAll('_', ' ');
    };
    PostManagementStatusComponent.prototype.retryAll = function (statusData) {
        var _this = this;
        this.isLoading = true;
        return this._postS.retryAllPosts(statusData, function () {
            _this.getData();
        });
    };
    PostManagementStatusComponent.prototype.retryPost = function (element) {
        var _this = this;
        this.isLoading = true;
        return this._postS.retryPost(element, function () {
            _this.getData();
        });
    };
    PostManagementStatusComponent.prototype.getInfoRejected = function (element) {
        var _this = this;
        this.isLoading = true;
        return new Promise(function (resolve, reject) {
            var _a;
            _this._postS.getInfoRejected(element.postId, (_a = element) === null || _a === void 0 ? void 0 : _a.postInstanceId).subscribe(function (res) {
                var msg = _this._postS.getModalText(res);
                _this.isLoading = false;
                resolve(msg);
            }, function (err) {
                _this.isLoading = false;
                reject(err);
            });
        });
    };
    PostManagementStatusComponent.prototype.infoAboutStatus = function (element) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            var title, msg, _e, _f;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        title = (((_a = element) === null || _a === void 0 ? void 0 : _a.status) === 'CONTACT_SUPPORT' ? 'Contact Us' :
                            ((_b = element) === null || _b === void 0 ? void 0 : _b.status) === 'REJECTED' ? 'Rejected' : 'Failed');
                        if (!(((_c = element) === null || _c === void 0 ? void 0 : _c.status) === 'CONTACT_SUPPORT')) return [3 /*break*/, 1];
                        _e = "<p class=\"txt--md txt--left\">This post had failed to publish 3 times. To resolve this issue, please contact support.</p>";
                        return [3 /*break*/, 5];
                    case 1:
                        if (!(((_d = element) === null || _d === void 0 ? void 0 : _d.status) === 'REJECTED')) return [3 /*break*/, 2];
                        _f = " <p class=\"txt--md txt--left\">This location has cannot be validated in Map Labs. Review the location in your </br> Google Business Profile for any potential issues, then try again.</p> \n        <p class=\"txt--md txt--left mt--20\">If the problem persist, please contact Support.</p>";
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.getInfoRejected(element)];
                    case 3:
                        _f = _g.sent();
                        _g.label = 4;
                    case 4:
                        _e = _f;
                        _g.label = 5;
                    case 5:
                        msg = (_e);
                        if (msg) {
                            this._modalS.openInfoModal(title, msg, 'Ok', true);
                        }
                        else {
                            this.isLoading = false;
                            this._snackS.openError('There was an error while loading the data. Please try again or contact support');
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    PostManagementStatusComponent.prototype.goToLocation = function (element) {
        var _a, _b, _c;
        this._router.navigate(['/account', (_a = element) === null || _a === void 0 ? void 0 : _a.accountId, 'location', (_c = (_b = element) === null || _b === void 0 ? void 0 : _b.location) === null || _c === void 0 ? void 0 : _c.locationId, 'post-management']);
    };
    PostManagementStatusComponent.prototype.deleteLocationsModal = function (element, allLocations) {
        var _this = this;
        var _a;
        var message = (allLocations ? "Are you sure you want to delete posts for all failed locations? This bulk action cannot be undone." :
            ((_a = element) === null || _a === void 0 ? void 0 : _a.isRecurring) ?
                "Are you sure you want to delete this profile from this post series? This action cannot be undone." :
                "Are you sure you want to delete this profile? This action cannot be undone.");
        this._modalS.openAlertModal("Attention", message, AlertType.ERROR, null, 'Delete Post', (function (response) {
            if (response) {
                _this.deleteLocationsFromPost(element, allLocations);
            }
        }), 'accent');
    };
    PostManagementStatusComponent.prototype.deleteLocationsFromPost = function (element, allLocations) {
        var _this = this;
        var _a, _b, _c;
        this.isLoading = true;
        var locationsId = !allLocations ? [(_a = element) === null || _a === void 0 ? void 0 : _a.postLocationId] : (_c = (_b = this.statusData) === null || _b === void 0 ? void 0 : _b.filter(function (el) { var _a; return ((_a = el) === null || _a === void 0 ? void 0 : _a.status) === "FAILED"; })) === null || _c === void 0 ? void 0 : _c.map(function (el) { var _a; return (_a = el) === null || _a === void 0 ? void 0 : _a.postLocationId; });
        var date = moment().format('YYYY-MM-DD');
        var time = moment().format('HH:MM');
        this._postS.deleteLocationsFromPost(this.postId, locationsId, date, time).subscribe(function (res) {
            _this.getData();
        }, function (err) {
            _this.destroyData();
            _this.isLoading = false;
        });
    };
    PostManagementStatusComponent.prototype.getData = function () {
        var _this = this;
        this._postS.getStatusPost(this.postId).subscribe(function (res) {
            var _a, _b, _c;
            if (((_b = (_a = res) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                _this.statusData = (_c = res) === null || _c === void 0 ? void 0 : _c.data;
                _this.isLoading = false;
            }
            else {
                _this.destroyData(true);
                _this.isLoading = false;
            }
        }, function (err) {
            _this.isLoading = false;
            _this.destroyData();
        });
    };
    PostManagementStatusComponent.prototype.destroyData = function (needsRefresh) {
        if (needsRefresh === void 0) { needsRefresh = false; }
        this.statusData = null;
        this.postId = null;
        this.isSliderOpen = false;
        this.closeStatusSlider.emit(needsRefresh);
    };
    PostManagementStatusComponent.prototype.existFailed = function () {
        return this.statusData.find(function (el) { return el.status === "FAILED"; }) ? false : true;
    };
    return PostManagementStatusComponent;
}());
export { PostManagementStatusComponent };
