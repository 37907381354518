import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { REPORT_TYPE } from "../../constants/firestore/report";

@Component({
  selector: 'app-revenue-reports',
  templateUrl: './revenue-reports.component.html',
  styleUrls: ['./revenue-reports.component.scss']
})
export class RevenueReportsComponent implements OnInit {

  reportType: REPORT_TYPE = 'performance-revenue';

  constructor(
    public router: Router,
    public route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.reportType = this.route?.snapshot?.routeConfig?.path?.replace('-report','') as REPORT_TYPE;
  }
}
