import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-starts',
  templateUrl: './starts.component.html',
  styleUrls: ['./starts.component.scss']
})
export class StartsComponent implements OnInit {

  @Input() starts: number;
  @Input() showEmpty: boolean;
  @Input() type: string;
  @Input() showLabel: boolean = false;
  numberOfStars: any;

  constructor() { }

  ngOnInit() {
    this.showEmpty = this.showEmpty !== undefined ? this.showEmpty : true;

    if (this.starts) {
      this.numberOfStars = Array.apply(null, Array(Math.floor(this.starts)).map((x, i) => i + 1));
    }
  }

  getLabel() {
    return `${this.numberOfStars?.length}-${this.numberOfStars?.length == 1 ? 'Star' : 'Stars'}`;
  }

}
