import { Injectable } from '@angular/core';
import AccountReport from '../../services/account-report';
import { LocationService } from '../../services/location.service';

@Injectable({
    providedIn: 'root'
})
export class ValidateLocations {
    constructor(private locationService: LocationService) {}

    async validateArrayLocations(accounts: AccountReport[]): Promise<AccountReport[]> { 
        const locationsIds = [];
        for (const account of accounts) {
            if (account.locations?.length) {
              account.locations.forEach(l => {
                locationsIds.push( {
                  'gid': account.gid,
                  'accountId':  account.accountId,
                  'locationId': l.locationId
                });
              });
            }
        }

        const allLocations = await this.locationService.fetchLocationsExistence(locationsIds)

        return accounts.map( ac => {
            ac.locations = ac.locations.filter( l => allLocations.some(lf => lf.gid === ac.gid && lf.accountId === ac.accountId && lf.locationId === l.locationId && lf.exist));
            if (ac.locations.length > 0) 
              return ac
        })
          
    }
}
