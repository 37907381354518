// dep
import { Component, EventEmitter, OnInit, Output, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';

// app
import { AuthService } from '../../services/auth.service';
import { LocationService } from '../../services/location.service';
import { MULTIKEY_METRIC, MULTIKEY_METRIC_V2, MULTIKEY_METRIC_V2_NEW } from "../../constants/google/location-stats";
import { LocationRef } from 'src/app/constants/firestore/location-object'

@Component({
  selector: 'app-check-metrics',
  templateUrl: './check-metrics.component.html',
  styleUrls: ['./check-metrics.component.scss']
})
export class CheckMetricsComponent implements OnInit, OnDestroy, OnDestroy, OnChanges {
  metrics: any = MULTIKEY_METRIC;
  updatingData = true;
  public hasSecondary = false;
  
  @Input('metrics') external;
  @Input('reportType') reportType;
  @Input() viewMode = 'legacy';
  @Input() accountsSelected = [];
  @Input() metricVerification = false;
  @Input() isEdit: boolean = false;
  @Input() isSliderOpened: boolean = false;
  
  @Output() changedMetricsValidation = new EventEmitter<boolean>();
  @Output() selected = new EventEmitter<{ name: string, checked: boolean }[]>();
  
  checkedAll = false;

  constructor(
    private locationS: LocationService,
    private authS: AuthService
  ) { }

  ngOnInit() {
    MULTIKEY_METRIC_V2_NEW.map(el => el.checked = false);
    this.initMetrics()
    this.buildMetrics();
    this.checkMetrics(this.external);
  }

  checkMetrics(external: any) : void{
    if (external) {
      external.forEach(element => {
        this.metrics.forEach(m => {
          if (element.name === m.name) {
            m.checked = true;
          }
        })
      });
    }
  }

  initMetrics() {
    if(this.reportType === 'performance-comparison'){
      if (this.viewMode == 'new') {
        this.metrics = [];
        this.buildMetrics();
      } else {
        this.metrics = MULTIKEY_METRIC_V2;
      }
    }
  }

  async buildMetrics() : Promise<void> {
    this.updatingData = true;
    const metrics = [...MULTIKEY_METRIC_V2_NEW];
    const menuActive = [];

    const locationsRefs : LocationRef[] = []
    for(const acc of this.accountsSelected)
        for(const l of acc.locations)
          locationsRefs.push({accountId: acc.accountId, locationId: l.locationId})

    try {
      for(const l of await this.locationS.fetchMultipleLocations(this.authS.session.gid, locationsRefs, ['location', 'locationState'] as ['location', 'locationState'])) {
        const value = l.locationState || l.location['locationState'];
        const canMenu = value?.canHaveFoodMenus || false
        menuActive.push(canMenu)
      }
    } catch {
      this.updatingData = false;
    }
   
    if (menuActive.filter(el => el == false).length > 0) {
      metrics.splice(9, 2);
    }
    this.metrics =  metrics;
    this.hasSecondary = !!this.metrics.find(el => el.isSecondary)
    this.updatingData = false;
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const metricValues = changes?.external;
    const changedLocations = this.metricVerification || Object.keys(changes).includes('accountsSelected');
    
    if (changedLocations) {
      this.metricVerification = false;
      await this.buildMetrics();
      this.selectedEmit(this.metrics.filter(m => m.checked));
      this.changedMetricsValidation.emit(this.metricVerification);
    }

    if(metricValues){
      this.external = metricValues.currentValue;
      if(metricValues.previousValue?.length === 0){
        this.uncheckAll();
      }
      this.checkMetrics(this.external);
    }

    if(!this.isSliderOpened) {
      this.changeAllChecked(false);
      this.checkedAll = false;
    }
  }

  uncheckAll(): void {
    this.metrics.forEach(m => { 
      m.checked = false;
    });
  }

  selectedEmit(value : any[], isAll = false) {
    this.selected.emit(value.filter(m => m.checked));
    if (value.length && !isAll) {
      this.checkedAll = value.find(m => !m.checked) ? false : true;
    }
  }

  changeChecked() {
    this.selectedEmit(this.metrics);
  }

  externalChecked() {
    this.selectedEmit(this.external);
  }


  changeAllChecked(checked) {
    this.metrics.forEach(m => m.checked = checked);
    this.selectedEmit(this.metrics, true);
  }

  ngOnDestroy(): void {
    this.metrics = [];
    this.metricVerification = false;
  }

}
