<app-reports-filter
  [reportName]="reportName"
  [locations]="locations"
  (dataPicker)="handleDataPicker($event)"
  (export)="exportCSV($event)"
  (clearValuesEmit)="clearValues()"
  (saveValuesEmit)="saveValues()"
  [selectedDate]="selectDate"
  type="revenue"
  [isShared]="shared"
  [lockDates]="lockDates"
  [hasClearValues]="true"
  [hasSaveValues]="true"
></app-reports-filter>

<div class="spinner-wrapper spinner-wrapper--bg spinner-wrapper--fixed" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>

<mat-progress-bar mode="indeterminate" *ngIf="isProgressCSV"></mat-progress-bar><!-- //NOSONAR -->

<div id="charts-container" class="container--full container--padding-lg pt--20 pb--0">

  <div class="d-flex justify-content-start align-items-center mb--20">
    <img src="/assets/images/revenue-title.png" alt="Revenue Title Icon" class="img--xs mr--15">
    <h2 class="m--0"><strong>Potential Revenue Calculator for {{reportName|uppercase}}</strong></h2>
  </div>

  <app-skeleton-revenue *ngIf="progress"></app-skeleton-revenue>

  <div class="row box--height" *ngIf="!progress">
    <!--actions-->
    <div class="col-12 mb--30">
      <div class="box box--shadow box--rounded bg--white">
        <div class="box__header">
          <h2 class="txt--capitalize"><strong>actions</strong></h2>
        </div>
        <div class="box__content box--padding-lg">
          <div class="row align-items-center">
            <div class="col-md-6 col-lg-8">
              <div class="row">

                <div class="col-md-4">
                  <div class="box">
                    <div
                      class="box__header box__header--sm box--shadow alternate-theme d-flex justify-content-center align-items-center">
                      <h6 class="txt--capitalize mr--10"><strong>direction requests</strong></h6>
                      <mat-chip-list class="chip--sm">
                        <mat-chip disabled color="primary">{{ direction | number: '1.0-2' }}</mat-chip>
                      </mat-chip-list>
                    </div>
                    <div class="box__content box--padding-t txt--center">
                      <p class="txt--md fw--medium txt--lightgray">Assumed conversion rate %</p>
                      <div class="input-number-wrapper input-number-wrapper--center">
                        <div class="input-number--arrows">
                          <span class="arrow arrow-up" (click)="triggerNumberUp(directionControl)"><i
                            class="fas fa-angle-up"></i></span>
                          <span class="arrow arrow-down" (click)="triggerNumberDown(directionControl)"><i
                            class="fas fa-angle-down"></i></span>
                        </div>
                        <input type="number" [formControl]="directionControl"  min="0"  max="100">
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="box">
                    <div
                      class="box__header box__header--sm box--shadow alternate-theme d-flex justify-content-center align-items-center">
                      <h6 class="txt--capitalize mr--10"><strong>mobile phone calls</strong></h6>
                      <mat-chip-list class="chip--sm">
                        <mat-chip disabled color="primary">{{ mobile | number: '1.0-2' }}</mat-chip>
                      </mat-chip-list>
                    </div>
                    <div class="box__content box--padding-t txt--center">
                      <p class="txt--md fw--medium txt--lightgray">Assumed conversion rate %</p>
                      <div class="input-number-wrapper input-number-wrapper--center">
                        <div class="input-number--arrows">
                          <span class="arrow arrow-up" (click)="triggerNumberUp(mobileControl)"><i
                            class="fas fa-angle-up"></i></span>
                          <span class="arrow arrow-down" (click)="triggerNumberDown(mobileControl)"><i
                            class="fas fa-angle-down"></i></span>
                        </div>
                        <input type="number" [formControl]="mobileControl" min="0" max="100"  >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="box">
                    <div
                      class="box__header box__header--sm box--shadow alternate-theme d-flex justify-content-center align-items-center">
                      <h6 class="txt--capitalize mr--10"><strong>website</strong></h6>
                      <mat-chip-list class="chip--sm">
                        <mat-chip disabled color="primary">{{ website | number: '1.0-2' }}</mat-chip>
                      </mat-chip-list>
                    </div>
                    <div class="box__content box--padding-t txt--center">
                      <p class="txt--md fw--medium txt--lightgray">Assumed conversion rate %</p>
                      <div class="input-number-wrapper input-number-wrapper--center">
                        <div class="input-number--arrows">
                          <span class="arrow arrow-up" (click)="triggerNumberUp(websiteControl)"><i
                            class="fas fa-angle-up"></i></span>
                          <span class="arrow arrow-down" (click)="triggerNumberDown(websiteControl)"><i
                            class="fas fa-angle-down"></i></span>
                        </div>
                        <input type="number" [formControl]="websiteControl" min="0"  max="100" >
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-md-6 col-lg-4 txt--center">
              <img src="/assets/images/revenue-actions.png" alt="Revenue Action Image"
                   class="img--md img--fluid mb--20">
              <p class="txt--xxxl-sm txt--lightblue fw--medium m--0">{{ totalAction | number: "1.0-2" }} actions</p>
              <p class="txt--md txt--lightgray fw--medium">(CTR = {{ ctr | number: "1.0-2" }}%)</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--potential store visits-->
    <div class="col-12 col-md-4 mb--30">
      <div class="box box--shadow box--rounded bg--white">
        <div class="box__header">
          <h2 class="txt--capitalize"><strong>potential store visits</strong></h2>
        </div>
        <div class="box__content box--padding-xxs d-flex justify-content-between">
          <div>
            <p class="txt--xxxl txt--lightblue fw--medium m--0">{{ potentialStore | shortNumber: "1.0-0" }}</p>
            <p class="txt--md txt--lightgray fw--medium mb--10">Purchase Rate (%)</p>
            <div class="input-number-wrapper">
              <div class="input-number--arrows">
                <span class="arrow arrow-up" (click)="triggerNumberUp(potentialStoreControl)"><i
                  class="fas fa-angle-up"></i></span>
                <span class="arrow arrow-down" (click)="triggerNumberDown(potentialStoreControl)"><i
                  class="fas fa-angle-down"></i></span>
              </div>
              <input type="number" [formControl]="potentialStoreControl" min="0"  max="100" >
            </div>
          </div>
          <img src="/assets/images/potential-store-visits.png" alt="Potential Store Visits Image"
               class="img--height-md img--fluid">
        </div>
      </div>
    </div>

    <!--potential sales-->
    <div class="col-12 col-md-4 mb--30">
      <div class="box box--shadow box--rounded bg--white">
        <div class="box__header">
          <h2 class="txt--capitalize"><strong>potential sales</strong></h2>
        </div>
        <div class="box__content box--padding-xxs d-flex justify-content-between">
          <div>
            <p class="txt--xxxl txt--lightblue fw--medium m--0">{{ potentialSales | shortNumber: "1.0-0" }}</p>
            <p class="txt--md txt--lightgray fw--medium mb--10">Average Customer Spend</p>
            <div class="input-number-wrapper">
              <div class="input-number--arrows">
                <span class="arrow arrow-up" (click)="triggerNumberUp(potentialSalesControl)"><i
                  class="fas fa-angle-up"></i></span>
                <span class="arrow arrow-down" (click)="triggerNumberDown(potentialSalesControl)"><i
                  class="fas fa-angle-down"></i></span>
              </div>
              <input type="number" [formControl]="potentialSalesControl" min="0">
            </div>
          </div>
          <img src="/assets/images/potential-sales.png" alt="Potential Sales" class="img--height-md img--fluid">
        </div>
      </div>
    </div>

    <!--potential revenue-->
    <div class="col-12 col-md-4 mb--30">
      <div class="box box--shadow box--rounded bg--white">
        <div class="box__header">
          <h2 class="txt--capitalize"><strong>potential revenue</strong></h2>
        </div>
        <div class="box__content box--padding-xxs d-flex justify-content-between">
          <div style="display: flex;">
            <p class="txt--xxxl txt--lightblue fw--medium m--0">$</p>
            <p class="txt--xxxl txt--green fw--medium m--0">{{ potentialRevenue | shortNumber: "1.0-0" }}</p>
          </div>
          <div>
            <img src="/assets/images/potential-revenue.png" alt="Potential Revenue" class="img--height-md img--fluid">
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<!--multi locations table-->
<app-table-multilocations 
  [reportType]="reportType"
  [reportName]="reportName"
  [reportId]="reportId"
  [dataPicker]="dataPicker"
  [locations]="locations"
  [disableIcons]="shared">
</app-table-multilocations>
