<!--PAGINATION-->
<div class="mat-row mat-row--padding-md bg--white d-flex justify-content-start align-items-center">
  <div class="pagination">
    <button (click)="onPrev()" class="pagination__item" [disabled]="!pagination.hasPrev || loadingTotal"><i
      class="fas fa-angle-left"></i></button>
<!--    <button *ngFor="let pageNum of getPages()"-->
<!--            class="pagination__item"-->
<!--            (click)="onPage(pageNum)"-->
<!--            [ngClass]="{ 'active': pageNum == manualPage }">-->
<!--      {{ pageNum }}-->
<!--    </button>-->
  <button (click)="onNext()" class="pagination__item" [disabled]="!pagination.hasNext || loadingTotal">
    <i class="fas fa-angle-right"></i></button>
  </div>
  <div class="input-field input-field--pagination">
    <label>Go to:</label>
    <input type="text" matInput [(ngModel)]="pagination.page" min="1" [max]="pagination.pages" (input)="validate($event.target.value)" (blur)="updateManualPage($event)" (keydown)="updateManualPage($event)"
      [readonly]="loadingTotal"
    >
    <mat-error *ngIf="errorMessage" class="mat-error--absolute">
      <p>Enter valid number</p>
    </mat-error>
    <mat-error *ngIf="errorPageMessage" class="mat-error--absolute" style="position: absolute; width: max-content;">
      <p>The last available page is {{pagination.pages}}</p>
    </mat-error>
  </div>
  <mat-paginator (page)="handlePage($event)" [length]="pagination.total"  [pageSize]="pagination.per_page" [pageIndex]="currentPage-1"
    [pageSizeOptions]="PAGINATION_OPTION"
  ></mat-paginator>
  <mat-spinner 
    *ngIf="loadingTotal"
    [diameter]="20"
  ></mat-spinner>
</div>
