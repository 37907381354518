import {Component, Input, OnInit} from "@angular/core";

@Component({
  selector: 'gmb-spinner',
  template: `
    <mat-progress-spinner
      [mode]="mode"
      [value]="value">
    </mat-progress-spinner>
    <div class="spinner-message">
      {{ message }}
    </div>
  `
})
export class SpinnerComponent implements OnInit {
  @Input() message = '';
  @Input() mode = 'indeterminate';
  @Input() value = null;


  constructor() {
  }

  ngOnInit() {
  }
}
