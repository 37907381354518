/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "./load.component";
var styles_LoadComponent = [];
var RenderType_LoadComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoadComponent, data: {} });
export { RenderType_LoadComponent as RenderType_LoadComponent };
export function View_LoadComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "dialog__header txt--center dialog__header--warn"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "h2", [["class", "m--0 txt--xl mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(2, 81920, null, 0, i1.MatDialogTitle, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], null, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 3, "div", [["class", "dialog__content mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i0.ɵdid(6, 16384, null, 0, i1.MatDialogContent, [], null, null), (_l()(), i0.ɵeld(7, 0, null, null, 1, "div", [["class", "dialog-row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 0, "div", [["class", "txt--lg txt--center m--0"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 2, "div", [["class", "dialog__footer mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i0.ɵdid(10, 16384, null, 0, i1.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(11, 0, null, null, 0, "span", [["class", "alternate-theme d-inline-block mr--10"]], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.data.title; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.data.content; _ck(_v, 8, 0, currVal_2); }); }
export function View_LoadComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-alert", [], null, null, null, View_LoadComponent_0, RenderType_LoadComponent)), i0.ɵdid(1, 49152, null, 0, i2.LoadComponent, [i1.MAT_DIALOG_DATA], null, null)], null, null); }
var LoadComponentNgFactory = i0.ɵccf("app-alert", i2.LoadComponent, View_LoadComponent_Host_0, {}, {}, []);
export { LoadComponentNgFactory as LoadComponentNgFactory };
