import { __awaiter, __generator } from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GROUP_SUBSCRIPTION_TYPE, LOCATION_SUBSCRIPTION_TYPE } from '../../constants/firestore/account-location';
import { AuthService } from '../../services/auth.service';
import { LocationService } from '../../services/location.service';
import { IS_IMPERSONATING } from '../../constants/session';
import { ModalService } from '../../services/modal.service';
var DashboardFilterComponent = /** @class */ (function () {
    function DashboardFilterComponent(router, route, locationService, authService, modalService) {
        var _this = this;
        this.router = router;
        this.route = route;
        this.locationService = locationService;
        this.authService = authService;
        this.modalService = modalService;
        this.isImpersonating = false;
        this.accountId = null;
        this.progress = false;
        this.ClickEvent = new EventEmitter();
        //---Location Info type
        this.location = null;
        this.locationId = null;
        this.isProgressPush = false;
        this.isProgressFetch = false;
        this.topBanner = false;
        this.GROUP_SUBSCRIPTION_TYPE = GROUP_SUBSCRIPTION_TYPE;
        // --- Services
        this.updateService = false;
        this.UpdateServices = new EventEmitter();
        this.UpdateMenuList = new EventEmitter();
        this.RefreshButtonPressed = new EventEmitter();
        this.PurgeButtonPressed = new EventEmitter();
        this.isRefreshing = false;
        this.isPurging = false;
        this.isProgressSave = false;
        this.isShared = false;
        this.isImpersonating = !!localStorage.getItem(IS_IMPERSONATING);
        this.subscription$ = this.authService.subscription$;
        this.subscription$.subscribe(function (subscription) { return _this.subscription = subscription; });
    }
    DashboardFilterComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.route.snapshot.parent.params.locationId) return [3 /*break*/, 2];
                        if (this.locationId === null) {
                            this.locationId = this.route.snapshot.parent.params.locationId;
                        }
                        return [4 /*yield*/, this.locationService.fetchLocation(this.authService.session.gid, this.accountId, this.locationId).then(function (loc) {
                                var _a;
                                if (((_a = _this.subscription) === null || _a === void 0 ? void 0 : _a.status) === GROUP_SUBSCRIPTION_TYPE.TRIAL || (loc.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE ||
                                    loc.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL)) {
                                    _this.topBanner = true;
                                }
                            })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this.topBanner = false;
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DashboardFilterComponent.prototype.updateServices = function () {
        var _this = this;
        this.updateService = true;
        this.locationService.saveServices(this.accountId, this.locationId).toPromise().then(function (services) {
            _this.getServices();
        });
    };
    DashboardFilterComponent.prototype.getServices = function () {
        var _this = this;
        this.locationService.getRef(this.authService.session.gid, this.accountId, this.locationId).toPromise().then(function (services) {
            var _a;
            _this.UpdateServices.emit((_a = services.serviceList, (_a !== null && _a !== void 0 ? _a : [])));
            _this.updateService = false;
        });
    };
    DashboardFilterComponent.prototype.updateMenu = function () {
        var _this = this;
        this.updateService = true;
        this.locationService.saveMenu(this.accountId, this.locationId).toPromise().then(function (menu) {
            _this.getMenu();
        });
    };
    DashboardFilterComponent.prototype.getMenu = function () {
        var _this = this;
        this.locationService.getRef(this.authService.session.gid, this.accountId, this.locationId).toPromise().then(function (services) {
            _this.UpdateMenuList.emit(services.menuList);
            _this.updateService = false;
        });
    };
    DashboardFilterComponent.prototype.launcAction = function (Action) {
        var _this = this;
        if (Action == 'lock') {
            this.locationService.isLocked(this.locationId).subscribe(function (res) {
                if (res && res.locked) {
                    var dialogRef = _this.modalService.openInfoModal("Heads Up", "An identical location in our network is already locked. Please contact support.");
                }
                else {
                    _this.ClickEvent.emit(Action);
                }
            });
        }
        else {
            this.ClickEvent.emit(Action);
        }
    };
    DashboardFilterComponent.prototype.GoBack = function () {
        this.router.navigate(['/accounts', this.accountId, 'locations']);
    };
    DashboardFilterComponent.prototype.refreshButtonPressed = function () {
        this.RefreshButtonPressed.emit();
    };
    DashboardFilterComponent.prototype.purgeButtonPressed = function () {
        this.PurgeButtonPressed.emit();
    };
    DashboardFilterComponent.prototype.handleShared = function () { };
    DashboardFilterComponent.prototype.handleRefresh = function () { };
    return DashboardFilterComponent;
}());
export { DashboardFilterComponent };
