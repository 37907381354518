import { __assign, __awaiter, __generator, __values } from "tslib";
// dep
import { EventEmitter, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { Messages, string_message } from "../../constants/messages";
import { CREATE_DATA, NOTIFICATION_GENERAL, TYPE_LOG_LOCATION } from "../../constants/notifications";
import { AccountService } from "../../services/account.service";
import { AuthService } from "../../services/auth.service";
import { SessionTraceService as SessionTraceService } from "../../services/session-trace.service";
import { GoogleLocationService } from "../../services/google-location.service";
import { LocationService } from "../../services/location.service";
import { NotificationService } from "../../services/notification.service";
import { SpinnerService } from "../../services/spinner.service";
import { WhiteLabelService } from "../../services/white-label.service";
var DialogLocationsComponent = /** @class */ (function () {
    function DialogLocationsComponent(data, dialogRef, _googleLocationService, _accountService, _auth, _spinnerService, _wl, _locationService, _notificationService, _route, _sessionTracingService) {
        var _a;
        this.data = data;
        this.dialogRef = dialogRef;
        this._googleLocationService = _googleLocationService;
        this._accountService = _accountService;
        this._auth = _auth;
        this._spinnerService = _spinnerService;
        this._wl = _wl;
        this._locationService = _locationService;
        this._notificationService = _notificationService;
        this._route = _route;
        this._sessionTracingService = _sessionTracingService;
        this.onLocationsSaved = new EventEmitter();
        this.subsManager$ = new Subject();
        this.noDataFound$ = new BehaviorSubject(false);
        this.progressBar$ = new BehaviorSubject(false);
        this.selectedLocation = [];
        this.mode = "";
        this.user = this._auth.session;
        this.selectedAccountId = this.data.selectedAccountId;
        this.accountId = (_a = this.selectedAccountId) === null || _a === void 0 ? void 0 : _a.split('/')[1];
        this.googleAccounts = this.data.googleAccounts;
        this._googleLocationService.reset();
        this._googleLocationService.loadAll(this.user, this.selectedAccountId);
    }
    DialogLocationsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._route.queryParams
            .subscribe(function (params) {
            var _a;
            _this.mode = (_a = params) === null || _a === void 0 ? void 0 : _a.mode;
        });
        this.progressBar$.next(true);
        this._googleLocationService.locations.pipe(takeUntil(this.subsManager$)).subscribe(function (result) {
            if (result && result.length > 0 && result[0]) {
                var sub$_1 = new Subject();
                var resultRequest = result.map(function (_a) {
                    var address = _a.address, locationName = _a.locationName, name = _a.name, serviceArea = _a.serviceArea;
                    return ({ address: address, locationName: locationName, name: name, serviceArea: serviceArea });
                });
                _this._locationService.byAccountGetLocations(_this.user.gid, _this.accountId, resultRequest).pipe(takeUntil(sub$_1)).subscribe(function (sl) {
                    var _a;
                    var filterL = result.filter(function (g) { return g !== undefined && !sl.some(function (gl) { return g.name.split('/')[3] === gl.id; }); });
                    _this.alreadyExistLocation = result.filter(function (g) { return g !== undefined && sl.some(function (gl) { return g.name.split('/')[3] === gl.id; }); });
                    _this.googleLocations = filterL;
                    _this.verificateLocations(_this.googleLocations);
                    if (_this.googleLocations.length == 0 && _this.alreadyExistLocation.length == 0 || (sl && ((_a = sl) === null || _a === void 0 ? void 0 : _a.length) === 0)) {
                        _this.noDataFound$.next(true);
                    }
                    else {
                        _this.noDataFound$.next(false);
                    }
                    _this.googleLocations = sl;
                    _this.resultSearchLocation = sl;
                    sub$_1.next(true);
                    sub$_1.unsubscribe();
                    _this.progressBar$.next(false);
                }, function () {
                    _this.noDataFound$.next(true);
                    _this.progressBar$.next(false);
                });
            }
            if (result && result.length > 0 && !result[0]) {
                _this.noDataFound$.next(true);
                _this.progressBar$.next(false);
            }
        });
    };
    DialogLocationsComponent.prototype.locationCheckboxDisabled = function (location) {
        var _a;
        if (this.mode === "debug") {
            return false;
        }
        if ((_a = location) === null || _a === void 0 ? void 0 : _a.exists) {
            return true;
        }
        return false;
    };
    DialogLocationsComponent.prototype.allCheckedCheckboxDisabled = function (googleLocations) {
        var disabledReturn = true;
        if (this.mode === "debug") {
            disabledReturn = false;
        }
        googleLocations.forEach(function (element) {
            var _a;
            if (((_a = element) === null || _a === void 0 ? void 0 : _a.exists) !== true) {
                disabledReturn = false;
            }
        });
        return disabledReturn;
    };
    DialogLocationsComponent.prototype.verificateLocations = function (locations) {
        locations.forEach(function (location) {
            if (location.locationState) {
                if (location.locationState.isDisconnected) {
                    location.locationState.description = 'Unavailable';
                    location.locationState.tooltip = 'Location Disconnected';
                    location.locationState.fill = 'review-response--error';
                    location.locationState.txt = 'txt--orange';
                }
                else if (location.locationState.hasPendingVerification) {
                    location.locationState.description = 'Unavailable';
                    location.locationState.tooltip = 'Location Pending verification';
                    location.locationState.fill = 'review-response--error';
                    location.locationState.txt = 'txt--orange';
                }
                else if (location.locationState.isDisabled) {
                    location.locationState.description = 'Unavailable';
                    location.locationState.tooltip = 'Location Disabled';
                    location.locationState.fill = 'review-response--info';
                    location.locationState.txt = 'txt--blue';
                }
                else if (location.locationState.needsReverification) {
                    location.locationState.description = 'Unavailable';
                    location.locationState.tooltip = 'Location Needs Reverification';
                    location.locationState.fill = 'review-response--error';
                    location.locationState.txt = 'txt--orange';
                }
                else if (location.locationState.isSuspended) {
                    location.locationState.description = 'Unavailable';
                    location.locationState.tooltip = 'Location Suspended';
                    location.locationState.fill = 'review-response--info';
                    location.locationState.txt = 'txt--blue';
                }
                else if (location.locationState.isDuplicate) {
                    location.locationState.description = 'Unavailable';
                    location.locationState.tooltip = 'Location Duplicate';
                    location.locationState.fill = 'review-response--error';
                    location.locationState.txt = 'txt--orange';
                }
                else if (!location.locationState.isVerified) {
                    location.locationState.description = 'Unavailable';
                    location.locationState.tooltip = 'Location Unverified';
                    location.locationState.fill = 'review-response--error';
                    location.locationState.txt = 'txt--orange';
                }
            }
        });
    };
    DialogLocationsComponent.prototype.filterLocation = function () {
        var _this = this;
        this.googleLocations = this.resultSearchLocation.filter(function (location) {
            return location.locationName.toLowerCase().includes(_this.search.toLowerCase()) ||
                _this._locationService.formatAddress(location.address || location.location.address).toLowerCase().includes(_this.search.toLowerCase());
        });
    };
    DialogLocationsComponent.prototype.ngOnDestroy = function () {
        this._accountService.reset();
        this.subsManager$.next(true);
        this.subsManager$.complete();
    };
    DialogLocationsComponent.prototype.addLocation = function () {
        return __awaiter(this, void 0, void 0, function () {
            var firstLocationAddedOn, googleAcc, acc, _a, _b, sl, l, meta, locationId, err_1;
            var e_1, _c;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        // Need a cleaner way of removing accessory data so we can save a clean object and add address format
                        this.selectedLocation.forEach(function (location) {
                            delete location.isChecked;
                            if (location.address)
                                location.formatAddress = _this._locationService.formatAddress(location.address);
                            else
                                location.formatAddress = '';
                            location.gid = _this._auth.session.gid;
                        });
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 6, 7, 8]);
                        this._spinnerService.loading$.next(true);
                        return [4 /*yield*/, this._auth.subscription$.getValue()];
                    case 2:
                        firstLocationAddedOn = (_d.sent()).firstLocationAddedOn;
                        return [4 /*yield*/, this.googleAccounts.pipe(take(1)).toPromise()];
                    case 3:
                        googleAcc = (_d.sent()).find(function (acc) { return acc.name === _this.selectedAccountId; });
                        return [4 /*yield*/, this._accountService.saveAll([googleAcc])];
                    case 4:
                        acc = (_d.sent())[0];
                        return [4 /*yield*/, this._locationService.saveAll(this.selectedLocation, acc)]; // TODO: What happend if saveAll fails without throwing an error?
                    case 5:
                        _d.sent(); // TODO: What happend if saveAll fails without throwing an error?
                        if (!firstLocationAddedOn)
                            this._sessionTracingService.onFirstLocationAdded();
                        this.onLocationsSaved.emit(true);
                        try {
                            for (_a = __values(this.selectedLocation), _b = _a.next(); !_b.done; _b = _a.next()) {
                                sl = _b.value;
                                l = __assign({ accountId: acc, locationId: sl.name.split('/')[3] }, sl);
                                meta = this._notificationService.getMetaTypeLog(TYPE_LOG_LOCATION, l);
                                this._notificationService.saveNotification(l.gid, this._wl.baseDomain, string_message(Messages.notifications.LOCATION_TOGGLE, [l.locationName, l.formatAddress, CREATE_DATA]), NOTIFICATION_GENERAL, TYPE_LOG_LOCATION, meta);
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (_b && !_b.done && (_c = _a.return)) _c.call(_a);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        locationId = this.selectedLocation[0].name.split('/')[3];
                        this._sessionTracingService.onLocationAdded(acc.accountId, locationId, this.selectedLocation.length);
                        return [3 /*break*/, 8];
                    case 6:
                        err_1 = _d.sent();
                        console.error('Error saving accounts', err_1);
                        return [3 /*break*/, 8];
                    case 7:
                        this._spinnerService.loading$.next(false);
                        this.dialogRef.close(true);
                        return [7 /*endfinally*/];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    // check/uncheck all checkbox
    DialogLocationsComponent.prototype.toggleCheckAll = function () {
        var _this = this;
        if (!this.allChecked)
            this.selectedLocation = [];
        this.googleLocations.forEach(function (location) {
            var _a;
            if (!((_a = location) === null || _a === void 0 ? void 0 : _a.exists) || _this.mode === 'debug') {
                location.isChecked = _this.allChecked;
                if (_this.allChecked) {
                    _this.selectedLocation.push(location);
                }
            }
        });
    };
    // if every item is checked, check the Select All checkbox
    DialogLocationsComponent.prototype.isAllChecked = function (location) {
        if (location.isChecked) {
            this.selectedLocation.push(location);
        }
        else {
            this.selectedLocation = this.selectedLocation.filter(function (l) { return location.name !== l.name; });
        }
        this.googleLocations.every(function (item) {
            return item.isChecked === true;
        });
    };
    Object.defineProperty(DialogLocationsComponent.prototype, "someCheck", {
        get: function () {
            if (this.selectedLocation.length > 0) {
                return this.selectedLocation.some(function (item) { return item.isChecked === true; });
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    return DialogLocationsComponent;
}());
export { DialogLocationsComponent };
