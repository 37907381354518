<div class="dialog__header grade-explain-header">
	<h2 class="m--0 txt--xl txt--center"><strong>How Is This Calculated?</strong></h2>
  <button mat-icon-button mat-dialog-close class="txt--lg"><i class="fal fa-times-circle"></i></button>
</div>

<div class="dialog__content" mat-dialog-content>

	<app-grade-explain-section title="Listing Status">
		<div class="row">
			<div class="col-4">
				<app-grade-explain-article title="Verified"></app-grade-explain-article>
			</div>
			<div class="col-4">
				<app-grade-explain-article title="Updates Pending"></app-grade-explain-article>
			</div>
			<div class="col-4">
				<app-grade-explain-article title="No Duplicate Listing"></app-grade-explain-article>
			</div>
		</div>
	</app-grade-explain-section>
	<app-grade-explain-section title="Basic Business Information">
		<div class="row">
			<div class="col-4">
				<app-grade-explain-article title="Phone Number"></app-grade-explain-article>
			</div>

			<div class="col-4">
				<app-grade-explain-article title="Website URL"></app-grade-explain-article>
			</div>

			<div class="col-4">
				<app-grade-explain-article title="Menu URL *"></app-grade-explain-article>
			</div>
			<div class="col-4">
				<app-grade-explain-article title="Order Ahead URL *"></app-grade-explain-article>
			</div>
			<div class="col-4">
				<app-grade-explain-article title="Reservations URL *"></app-grade-explain-article>
			</div>
			<div class="col-4">
				<app-grade-explain-article title="Appointment URL *"></app-grade-explain-article>
			</div>
			<div class="col-4">
				<app-grade-explain-article title="Phone Number"></app-grade-explain-article>
			</div>
			<div class="col-4">
				<app-grade-explain-article title="Hours of Operations"></app-grade-explain-article>
			</div>
			<div class="col-4">
				<app-grade-explain-article title="Business Description"></app-grade-explain-article>
			</div>
			<div class="col-4">
				<app-grade-explain-article title="Opening Date"></app-grade-explain-article>
			</div>
			<div class="col-4">
				<app-grade-explain-article title="Additional Categories"></app-grade-explain-article>
			</div>
			<div class="col-4">
				<app-grade-explain-article title="Attributes Selected"></app-grade-explain-article>
			</div>
		</div>
	</app-grade-explain-section>
	<app-grade-explain-section title="Reputation & Reviews">
		<div class="row">
			<div class="col-4">
				<article class="mb--15 mt--15">
					<p class="fw--medium mb--15">
						Average Rating
					</p>
					<ul>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">5 Points if > 4.5</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">4 Points if 4.0 - 4.4</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">3 Points if 3.5 - 3.9</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">2 Points if 3.0 - 3.4</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">1 Points if 2.5 - 2.9</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--error">
				  <span class="tag__icon"><i class="fas fa-times"></i></span>
				  <span class="tag__content p--10 p--5 fw--regular">0 Points if under 2.8</span>
				  </span>
						</li>
					</ul>


				</article>
			</div>
			<div class="col-4">
				<article class="mb--15 mt--15">
					<p class="fw--medium mb--15">
						Total Number of Reviews
					</p>
					<ul>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">5 Points if > 100</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">4 Points if > 75</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">3 Points if > 50</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">2 Points if > 25</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">1 Points if > 10</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--error">
				  <span class="tag__icon"><i class="fas fa-times"></i></span>
				  <span class="tag__content p--10 p--5 fw--regular">0 Points if < 10</span>
				  </span>
						</li>
					</ul>


				</article>
			</div>
			<div class="col-4">
				<article class="mb--15 mt--15">
					<p class="fw--medium mb--15">
						New Reviews Frequency
					</p>
					<ul>
						<li class="tag-wrapper m--0 pt--0">
						  <span class="tag tag--success">
							  <span class="tag__icon"><i class="fas fa-check"></i></span>
							  <span class="tag__content p--10 fw--regular">5 Points if > 4 / 30 Days</span>
						  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">4 Points if < 4 / 30 Days</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">3 Points if 3 / 21 Days</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">2 Points if 2 / 14 Days</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
						  <span class="tag tag--success">
						  <span class="tag__icon"><i class="fas fa-check"></i></span>
						  <span class="tag__content p--10 fw--regular">1 Points if 1 / 7 Days</span>
						  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
							<span class="tag tag--error">
							<span class="tag__icon"><i class="fas fa-times"></i></span>
							<span class="tag__content p--10 p--5 fw--regular">0 Points if < 7 Days</span>
							</span>
								  </li>
					</ul>


				</article>
			</div>
			<div class="col-4">
				<article class="mb--15 mt--15">
					<p class="fw--medium mb--15">
						Review Response Ratio
					</p>
					<ul>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">5 Points if 100%</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">4 Points if 75%</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">3 Points if 50%</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">2 Points if 25%</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">1 Points if  10%</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--error">
				  <span class="tag__icon"><i class="fas fa-times"></i></span>
				  <span class="tag__content p--10 p--5 fw--regular">0 Points if < 0%</span>
				  </span>
						</li>
					</ul>


				</article>
			</div>
		</div>
	</app-grade-explain-section>
	<app-grade-explain-section title="Photos">
		<div class="row">
			<div class="col-4">
				<app-grade-explain-article title="Interior Photos"></app-grade-explain-article>
			</div>
			<div class="col-4">
				<app-grade-explain-article title="Exterior Photos"></app-grade-explain-article>
			</div>
			<div class="col-4">
				<app-grade-explain-article title="Cover Photos"></app-grade-explain-article>
			</div>
			<div class="col-4">
				<app-grade-explain-article title="Profile Photo"></app-grade-explain-article>
			</div>
			<div class="col-4">
				<app-grade-explain-article title="Videos"></app-grade-explain-article>
			</div>
			<div class="col-4">
				<app-grade-explain-article title="Team Photos"></app-grade-explain-article>
			</div>

			<div class="col-4">
				<app-grade-explain-article title="New Image By Owner Last 30 Days"></app-grade-explain-article>
			</div>

			<div class="col-4">
				<app-grade-explain-article title="Food & Drink Photos *"></app-grade-explain-article>
			</div>

			<div class="col-4">
				<app-grade-explain-article title="Menu Photos *"></app-grade-explain-article>
			</div>

			<div class="col-4">
				<app-grade-explain-article title="Common Area Photos *"></app-grade-explain-article>
			</div>

			<div class="col-4">
				<app-grade-explain-article title="Room Photos *"></app-grade-explain-article>
			</div>

			<div class="col-4">
			</div>

			<div class="col-5">
				<article class="mb--15 mt--15">
					<p class="fw--medium mb--15">
						Total Quantity
					</p>

					<ul>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">5 Points if > than 100 images</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">4 Points if > than 75 images</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">3 Points if > than 50 images</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">2 Points if > than 25 images</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">1 Points if > than 10 images</span>
				  </span>
						</li>

						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--error">
				  <span class="tag__icon"><i class="fas fa-times"></i></span>
				  <span class="tag__content p--10 fw--regular">0 Points if < than 10 images</span>
				  </span>
						</li>

					</ul>


				</article>
			</div>
		</div>
	</app-grade-explain-section>
	<app-grade-explain-section title="Content">
		<div class="row">
			<div class="col-4">
				<app-grade-explain-article title="Posted In The Last 30 Days" [add]="3"></app-grade-explain-article>
			</div>

			<div class="col-4">
				<app-grade-explain-article title="Posted In The Last 60 Days" [add]="2"></app-grade-explain-article>
			</div>

			<div class="col-4">
				<app-grade-explain-article title="Posted In The Last 90 Days" [add]="1"></app-grade-explain-article>
			</div>

			<div class="col-4">
				<app-grade-explain-article title="Post Included Images or Videos" [add]="1"></app-grade-explain-article>
			</div>

			<div class="col-4">
				<app-grade-explain-article title="Post Included Call To Action" [add]="1"></app-grade-explain-article>
			</div>


			<div class="col-4">
				<app-grade-explain-article title="Posted Using Offer Type" [add]="1"></app-grade-explain-article>
			</div>


			<div class="col-4">
				<app-grade-explain-article title="Posted Using Event Type" [add]="1"></app-grade-explain-article>
			</div>


			<div class="col-4">
				<app-grade-explain-article title="Added Services / Menu" [add]="1"></app-grade-explain-article>
			</div>

		</div>
	</app-grade-explain-section>
	<app-grade-explain-section title="Performance">
		<div class="row">
			<div class="col-4">
				<article class="mb--15 mt--15">
					<p class="fw--medium mb--15">
						Direction Requests Increase YoY
					</p>
					<ul>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">5 Points if > 50%</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">4 Points if > 25%</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">3 Points if > 10%</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">2 Points if > 5%</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">1 Points if < 5%</span>
				  </span>
						</li>

						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--error">
				  <span class="tag__icon"><i class="fas fa-times"></i></span>
				  <span class="tag__content p--10 fw--regular">0 Points if < 0%</span>
				  </span>
						</li>

					</ul>


				</article>
			</div>
			<div class="col-4">
				<article class="mb--15 mt--15">
					<p class="fw--medium mb--15">
						Website Visits Increase YoY
					</p>

					<ul>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">5 Points if > 50%</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">4 Points if > 25%</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">3 Points if > 10%</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">2 Points if > 5%</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">1 Points if < 5%</span>
				  </span>
						</li>

						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--error">
				  <span class="tag__icon"><i class="fas fa-times"></i></span>
				  <span class="tag__content p--10 fw--regular">0 Points if < 0%</span>
				  </span>
						</li>

					</ul>


				</article>
			</div>
			<div class="col-4">
				<article class="mb--15 mt--15">
					<p class="fw--medium mb--15">
						Phone Calls Increase YoY
					</p>

					<ul>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">5 Points if > 50%</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">4 Points if > 25%</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">3 Points if > 10%</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">2 Points if > 5%</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">1 Points if < 5%</span>
				  </span>
						</li>

						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--error">
				  <span class="tag__icon"><i class="fas fa-times"></i></span>
				  <span class="tag__content p--10 fw--regular">0 Points if < 0%</span>
				  </span>
						</li>

					</ul>


				</article>
			</div>
			<div class="col-4">
				<article class="mb--15 mt--15">
					<p class="fw--medium mb--15">
						Map Views Increase YoY
					</p>

					<ul>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">5 Points if > 50%</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">4 Points if > 25%</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">3 Points if > 10%</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">2 Points if > 5%</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">1 Points if < 5%</span>
				  </span>
						</li>

						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--error">
				  <span class="tag__icon"><i class="fas fa-times"></i></span>
				  <span class="tag__content p--10 fw--regular">0 Points if < 0%</span>
				  </span>
						</li>

					</ul>


				</article>
			</div>
			<div class="col-4">
				<article class="mb--15 mt--15">
					<p class="fw--medium mb--15">
						Search Views Increase YoY
					</p>

					<ul>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">5 Points if > 50%</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">4 Points if > 25%</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">3 Points if > 10%</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">2 Points if > 5%</span>
				  </span>
						</li>
						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--success">
				  <span class="tag__icon"><i class="fas fa-check"></i></span>
				  <span class="tag__content p--10 fw--regular">1 Points if < 5%</span>
				  </span>
						</li>

						<li class="tag-wrapper m--0 pt--0">
				  <span class="tag tag--error">
				  <span class="tag__icon"><i class="fas fa-times"></i></span>
				  <span class="tag__content p--10 fw--regular">0 Points if < 0%</span>
				  </span>
						</li>

					</ul>


				</article>
			</div>


		</div>
		<div class="row">
			<div class="col">
				<span>(*) Only applies to businesses that are eligible for these fields, which is determined by the “primary category” of the business.</span>
			</div>
		</div>
	</app-grade-explain-section>

</div>
