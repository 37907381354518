<div class="dialog__header txt--center dialog__header--info">
    <h2 mat-dialog-title class="m--0 txt--xl"><strong>Hang Tight!</strong></h2>
  </div>
<div class="dialog__content dialog__content--height" mat-dialog-content>
    <div class="dialog-row">
        <p>This report has a lot of data to load, this may take a few moments</p>
        <mat-progress-bar mode="determinate" [value]="(loadingValue$ | async) > 0 ? (loadingValue$ |  async) : 0"></mat-progress-bar>
        <small *ngIf="completed">{{ completed }}</small>
    </div>
</div>

<div mat-dialog-actions class="dialog__footer">
    <div class="alternate-theme d-inline-block mr--10">
        <button tabIndex="-1" mat-button color="primary" [mat-dialog-close]="false" class="btn btn-cancel">Close</button>
    </div>
</div>