import {firestore} from 'firebase/app';
import {MultiChart} from '../data-picker';
import AccountReport from '../../services/account-report';
import * as moment from 'moment';

export default interface Report {
  createdAt: firestore.Timestamp;
  gid: string;
  id?: string;
  _id?: string;
  uid: string;
  hash: string;
  token: string;
  reportName: string;
  reportType: REPORT_TYPE;
  accounts?: AccountReport[];
  startDatetime?: string;
  endDatetime?: string;
  aggregation?: string;
  lockDates?: boolean;
  dynamic?: boolean;
  multiChart?: MultiChart[];
  shared: boolean;
  sharedOnly: boolean;
  locations?: any[];
  revenueInfo?: any;
  showComparison?: boolean;
  questionsType?: string;
  compareToValue?: {};
  metadata?: any

  // comparison
  metrics?: { name: string, checked: boolean }[];
  metricsName?: { name: string, checked: boolean }[];
  startDatetimeB?: string;
  endDatetimeB?: string;
  period?: string;

  // Scheduled Automated report
  scheduleReport?: boolean;
  frecuencySelector?: string;
  repeatOn?: string;
  dynamicRange?: string;
  recipentsEmails?: string[];
  scheduleDate?: string;
  domain?: string;
}


export type REPORT_TYPE = 'rollup' | 'review' | 'review-location' | 'rollup-report' | 'performance-rollup' | 'performance-rollup-report' | 'review-report' | 'performance-review-report' | 'comparison' | 'performance-comparison' | 'revenue' | 'performance-revenue' | 'grade' | 'Review Assistant' | 'keyword' | 'performance-insights' | 'keyword-location' | 'qanda' | 'qanda-location';
export const COMPARISON_LIST = [{value: 'custom', text: 'Custom'}, 
                                {value: 'period', text: 'Previous Period'},
                                {value: 'month', text: 'Previous Month'},
                                {value: 'year', text: 'Previous Year'}];

export const COMPARISON_LIST_DEFAULT = [{value: 'period', text: 'Previous Period'}];

export interface FormReport {
  lockDates: any;
  reportName: string;
  endDate: Date;
  startDate: Date;
  dynamic: boolean;
  scheduleReport?: boolean;
}


export const DATAPICKER_REPORT_DEFAULT = {
  range: {
    start: moment().subtract(3, 'months').toISOString(),
    end: moment().toISOString()
  },
  rangeB: {
    start: null,
    end: null
  }
};
