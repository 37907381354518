// dep
import * as moment from 'moment'

// app
import { MetaPost } from '../v3.models/posts.models';
import AccountReport from '../services/account-report';

export const convertMetaToAccountsReports = (meta: {meta: MetaPost}[]): AccountReport[] => {
  return meta.reduce( (response: AccountReport[], metaInfo: {meta: MetaPost}) => {
    let data: AccountReport;
    const gid = metaInfo.meta.gid;
    const accountId = metaInfo.meta.accountId;
    const locationId = metaInfo.meta.locationId;

    if (response.some(ac => ac.accountId === accountId && ac.gid === gid)) {
      data = response.find(ac => ac.accountId === accountId && ac.gid === gid);
      data.locations.push({
        accountId,
        locationName: metaInfo.meta.locationName || "",
        locationId,
        address: metaInfo.meta.address || null,
      })
    } else {
      data = {
        gid,
        accountId,
        locations: [{
          accountId,
          locationName: metaInfo.meta.locationName || "",
          locationId,
          address: metaInfo.meta.address || null,
        }]
      }
      response.push(data)
    }

    return response;

  }, [])
}


export const getNextReccurringDate = (date: Date, repeatOn: string, frecuencySelector: string, time: string): Date => {
  let result: Date;

  const currentDate: Date = date? moment.utc(date).toDate(): moment().utc().toDate();

  const year: number = currentDate.getFullYear();
  const month: number = currentDate.getMonth();
  const day: number = currentDate.getDate();
  const dayOfWeek: number = currentDate.getDay();

  if (frecuencySelector === 'WeeklyOn') {
    if (dayOfWeek >= Number(repeatOn)){
      const daysToDate =  7 - (dayOfWeek - Number(repeatOn));
      result = moment(new Date(year, month, day + daysToDate )).utc().toDate();
    } else {
      const daysToDate = Number(repeatOn) - dayOfWeek;
      result = moment(new Date(year, month, day + daysToDate )).utc().toDate();
    }
  } else {
    if (day >= Number(repeatOn)) {
      result = moment(new Date(year, month + 1, Number(repeatOn) )).utc().toDate();
    } else {
      result = moment(new Date(year, month, Number(repeatOn) )).utc().toDate();
    }
  }
  const splitTime = time.split(':')
  const hours = splitTime[0]
  const minutes = splitTime[1]
  result.setHours(Number(hours), Number(minutes));
  return result;
}
