import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  public loading$ = new BehaviorSubject(false)

  constructor() {
    //
  }
}
