<div class="dialog__header" *ngIf="!bulk">
    <h2 mat-dialog-title class="m--0 txt--xl"><strong>Website</strong></h2>
</div>
<div class="dialog__content" mat-dialog-content>
<!--    <div class="dialog-row pb&#45;&#45;0">-->
<!--        <mat-form-field class="input-field">-->
<!--            <input matInput placeholder="Website" [(ngModel)]="websiteData.website" [value]="websiteData.website" name="website">-->
<!--        </mat-form-field>-->
<!--    </div>-->
    <div class="dialog-row p--0">
        <mat-form-field class="input-field">
            <input matInput placeholder="Website URL" [(ngModel)]="url" [value]="url" name="url">
        </mat-form-field>
        <p *ngIf="url && !isValidField()" class="txt--red">Looks like your URL has an incorrect format. Please check your formatting, no spaces or special characters are allowed.</p>
    </div>
<!--    <div class="dialog-row p&#45;&#45;0">-->
<!--        <mat-form-field class="input-field">-->
<!--            <input matInput placeholder="Order ahead url" [(ngModel)]="websiteData.orderAhead" [value]="websiteData.orderAhead" name="orderAhead">-->
<!--        </mat-form-field>-->
<!--    </div>-->
<!--    <div class="dialog-row p&#45;&#45;0">-->
<!--        <mat-form-field class="input-field">-->
<!--            <input matInput placeholder="Reservations url" [(ngModel)]="websiteData.reservationsUrl" [value]="websiteData.reservationsUrl" name="reservationsUrl">-->
<!--        </mat-form-field>-->
<!--    </div>-->

</div>
<div mat-dialog-actions class="dialog__footer" *ngIf="!bulk">
    <span class="alternate-theme d-inline-block mr--10">
        <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
    </span>
    <button mat-flat-button (click)="apply()" tabIndex="-1" color="accent" class="btn btn--action">Apply</button>
</div>
