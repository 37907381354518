
<div class="box--height mt--60">
  <!-- TOTALS CARDS -->
  <div class="col-12 p--0 mb--30">
    <div class="box box--padding-xxs box--shadow box--rounded bg--white row justify-content-between">
      <div *ngFor="let i of [0, 1, 2, 3]">
        <ngx-skeleton-loader count="1" [theme]="{ width: '380px', height: '225px' }"></ngx-skeleton-loader>
      </div>
    </div>
  </div>

  <!-- QUESTIONS & ANSWERS -->
  <div class="col-12 p--0 row justify-content-between">
    <div *ngFor="let i of [0, 1, 2, 3, 4, 5]" class="mb--25">
      <ngx-skeleton-loader count="1" [theme]="{ width: '430px', height: '510px' }"></ngx-skeleton-loader>
    </div>
  </div>

  <!-- BTN LOAD MORE -->
  <div class="txt--align--center">
    <ngx-skeleton-loader count="1" [theme]="{ width: '180px', height: '40px' }"></ngx-skeleton-loader>
  </div>

</div>