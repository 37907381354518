<div *ngIf="(progress$ | async)" class="d-flex flex-row justify-content-center mt--20 mb--20 h--150">
    <gmb-spinner *ngIf="progress$ | async"></gmb-spinner>
</div>
<section *ngIf="!(progress$ | async)">
    <h2>Triggers Summary</h2>

    <div class="row mb--5" *ngIf="protocol" [style.height]="'110px'">
        <div class="col-6">
            <div class="cell__content flex-column align-items-start justify-content-center">
                <div *ngFor="let star of orderStarts(protocol.stars)">
                    <app-starts [starts]="star" class="stars--xs" [showEmpty]="false"></app-starts>
                </div>

            </div>
        </div>
        <div class="col-6">
            <p class="txt--darkgray m--0" *ngIf="protocol.withComment">With comments</p>
            <p class="txt--darkgray m--0" *ngIf="protocol.withOutComment">Without comments</p>
            <p class="txt--darkgray m--0" *ngIf="delay > 0">Delay: {{ delay }}</p>
            <p class="txt--darkgray m--0" *ngIf="protocol.triggers.keywords && protocol.triggers.keywords.length > 0">
            <span style="font-weight: bold;">Keywords:</span> {{ protocol.triggers.keywords.join(', ') }}</p>
            <p class="txt--darkgray m--0" *ngIf="protocol.triggers.excludeKeywords && protocol.triggers.excludeKeywords.length > 0">
            <span style="font-weight: bold;">Exclude Keywords:</span> {{ protocol.triggers.excludeKeywords.join(', ') }}</p>
        </div>

    </div>

    <div class="row mb--5" *ngIf="protocol">
        <div class="col-12" style="text-align: center;">
            <app-skeleton-reviews type="TOTAL-REVIEWS" *ngIf="(counter | async) === null"></app-skeleton-reviews>
            <h2 class="txt--capitalize" *ngIf="(counter | async) !== null"><strong>Pending Replies: </strong>{{ (counter | async).NotReplied === null || (counter | async).NotReplied === 0  ? 0 : (counter | async).NotReplied | shortNumber: "1.0-0"}}</h2>
        </div>
    </div>

    <div class="row" *ngIf="protocol">

        <!--Total # of Reviews Replied-->
        <div class="col-12 col-sm-6 col-lg-6 mb--15">
            <div class="box box--padding-xxs box--shadow box--rounded bg--white">
                <h2 class="txt--capitalize"><strong>Reviews Replied</strong></h2>
                <app-skeleton-reviews type="TOTAL-REVIEWS" *ngIf="(counter | async) === null"></app-skeleton-reviews>

                <div class="d-flex justify-content-between flex-column" *ngIf="(counter | async) !== null">

                    <div class="d-flex align-items-center justify-content-start mb--10">
                        <p class="txt--xxxl txt--lightblue fw--medium m--0">{{ (counter | async).Replied  === null ? 0 : (counter | async).Replied > 100000 ?  ((counter | async).Replied | shortNumber: "1.0-0") : (counter | async).Replied  | number: '1.0-0'}}</p>
                    </div>
                </div>
            </div>
        </div>


        <!--Total # of Reviews-->
        <div class="col-12 col-sm-6 col-lg-6 mb--15">
            <div class="box box--padding-xxs box--shadow box--rounded bg--white">
                <h2 class="txt--capitalize"><strong>Reviews Total</strong></h2>

                <app-skeleton-reviews type="TOTAL-REVIEWS" *ngIf="(counter | async) === null"></app-skeleton-reviews>

                <div class="d-flex justify-content-between flex-column" *ngIf="(counter | async) !== null">

                    <div class="d-flex align-items-center justify-content-start mb--10">
                        <p class="txt--xxxl txt--lightblue fw--medium m--0">{{ (counter | async).Total  === null ? 0 : (counter | async).Total > 100000 ?  ((counter | async).Total | shortNumber: "1.0-0") : (counter | async).Total | number: '1.0-0'}}</p>
                    </div>
                </div>
            </div>
        </div>


    </div>
</section>
