import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import User from '../../../constants/firestore/user';
import {UserService} from '../../../services/user.service';
import {SnackbarService} from '../../../services/snackbar.service';

@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss']
})
export class UserDialogComponent implements OnDestroy, OnInit {

  user: User;
  userForm: FormGroup;
  isNew: boolean;
  users: User[];
  subsManager$: Subject<boolean> = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserDialogComponent>,
    public usersService: UserService,
    private snack: SnackbarService,
    private formBuilder: FormBuilder,
  ) {
    this.user = this.data.user;
    this.isNew = this.user === null;
  }

  get form() {
    return this.userForm;
  }

  ngOnInit() {
    this.buildForm();
    this.usersService.users
      .pipe(takeUntil(this.subsManager$))
      .subscribe(response => {
        this.users = response;
      });
  }

  ngOnDestroy(): void {
    this.subsManager$.next(true);
    this.subsManager$.complete();
  }

  buildForm(): void {
    const readOnlyFields = this.user !== null;
    this.userForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(5)]],
      email: [{value: '', disabled: readOnlyFields}, [Validators.required, Validators.email]],
      active: [true],
      role: ['']
    });
    if (null !== this.user) {
      this.userForm.patchValue({
        name: this.user.displayName,
        email: this.user.email,
        active: this.user.isActive,
        role: this.user.role
      });
    }
  }

  save(): void {
    const userData = this.userForm.getRawValue();
    if (this.isNew) {
      const existUser = this.users.filter(user => user.displayName === userData.name || user.email === userData.email);
      if (existUser.length) {
        this.snack.openError('The user already exists.', 5000);
        return;
      } 
    } 
    this.dialogRef.close({displayName: userData.name, email: userData.email, isActive: userData.active, role: userData.role});
  }

}
