import { __awaiter, __generator } from "tslib";
// dep
import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
// app
import { AuthService } from '../../services/auth.service';
import { GoogleService } from '../../services/google.service';
import { LocationService } from '../../services/location.service';
import { ReviewsService } from '../../services/reviews.service';
var WidgetComponent = /** @class */ (function () {
    function WidgetComponent(googleS, reviewS, auth, route, router, authS, locationS, cdf) {
        this.googleS = googleS;
        this.reviewS = reviewS;
        this.auth = auth;
        this.route = route;
        this.router = router;
        this.authS = authS;
        this.locationS = locationS;
        this.cdf = cdf;
        this.location = null;
        this.reviewsProgress = true;
        this._order = 'createTime';
        this.locationId = this.route.parent.snapshot.params.locationId;
        this.accountId = this.route.parent.snapshot.params.accountId;
        this.gid = this.route.parent.snapshot.params.gid;
        this.uid = this.route.parent.snapshot.queryParams.uid;
        if (!this.gid) {
            this.gid = this.auth.session.gid;
        }
        if (!this.uid) {
            this.uid = this.auth.session.uid;
        }
        this.locations = [{ accountId: this.accountId, locationId: this.locationId }];
        var order = this.route.parent.snapshot.queryParams.order;
        if (order) {
            this._order = order;
        }
        var starts = this.route.parent.snapshot.queryParams.filter;
        if (starts) {
            this._starts = starts;
            var nStarts_1 = [];
            var selectedStarts = this._starts.split(',');
            selectedStarts.forEach(function (item) {
                nStarts_1.push(parseInt(item, 10));
            });
            this.selectedStarts = nStarts_1;
        }
        var backColor = this.route.parent.snapshot.queryParams.back_color;
        if (backColor) {
            this.backgroundColor = '#' + backColor;
        }
        var btnColor = this.route.parent.snapshot.queryParams.btn_color;
        if (btnColor) {
            this.buttonColor = '#' + btnColor;
        }
        var btnTextColor = this.route.parent.snapshot.queryParams.btn_txt_color;
        if (btnTextColor) {
            this.buttonTextColor = '#' + btnTextColor;
        }
        var textColorAll = this.route.parent.snapshot.queryParams.text_color;
        if (textColorAll) {
            this.textColor = '#' + textColorAll;
        }
    }
    Object.defineProperty(WidgetComponent.prototype, "starts", {
        get: function () {
            return this.startsI;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WidgetComponent.prototype, "order", {
        get: function () {
            var _a;
            return (_a = this.orderI) === null || _a === void 0 ? void 0 : _a.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WidgetComponent.prototype, "responses", {
        get: function () {
            var _a;
            return (_a = this.responsesI) === null || _a === void 0 ? void 0 : _a.value;
        },
        enumerable: true,
        configurable: true
    });
    WidgetComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var url;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.cdf.detectChanges();
                        url = this.router.url;
                        if (!!url.includes('location')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.authS.signInAnonymously(this.gid)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this.eventsSubscription = this.events.subscribe(function () {
                            _this.filter();
                        });
                        _a.label = 3;
                    case 3:
                        this.reviewResume$ = this.locationS.review_summary(this.gid, this.locations).subscribe(function (resume) {
                            var _a, _b;
                            _this.average = (_a = resume) === null || _a === void 0 ? void 0 : _a.averageRating;
                            _this.total = (_b = resume) === null || _b === void 0 ? void 0 : _b.totalReviewCount;
                        });
                        this.filter();
                        this.locationS.getRef(this.gid, this.accountId, this.locationId).subscribe(function (location) {
                            _this.location = location;
                            _this.reviewsProgress = false;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    WidgetComponent.prototype.filter = function () {
        var _this = this;
        this.reviewsProgress = true;
        var sort = {
            'direction': null,
            'sortBy': this.order
        };
        this.filter$ = this.reviewS.filter(this.locations, 1, null, this.selectedStarts, null, null, null, null, sort, this.gid).subscribe(function (reviews) {
            var _a;
            _this.reviewsProgress = false;
            _this.reviews = (_a = reviews) === null || _a === void 0 ? void 0 : _a.items;
        }, function (err) {
            _this.reviewsProgress = false;
        });
    };
    WidgetComponent.prototype.ngOnChanges = function (changes) {
        if (changes.backColor) {
            this.backgroundColor = changes.backColor.currentValue;
        }
        if (changes.btnColor) {
            this.buttonColor = changes.btnColor.currentValue;
        }
        if (changes.btnTextColor) {
            this.buttonTextColor = changes.btnTextColor.currentValue;
        }
        if (changes.textColorAll) {
            this.textColor = changes.textColorAll.currentValue;
        }
        if (changes.orderI) {
            this._order = changes.orderI.currentValue;
        }
        if (changes.responsesI) {
            this._responses = changes.responsesI.currentValue;
        }
        if (changes.startsI) {
            this._starts = changes.startsI.currentValue;
            if (this._starts) {
                var nStarts_2 = [];
                var selectedStarts = this._starts.split(',');
                selectedStarts.forEach(function (item) {
                    nStarts_2.push(parseInt(item, 10));
                });
                this.selectedStarts = nStarts_2;
            }
        }
    };
    WidgetComponent.prototype.ngOnDestroy = function () {
        this.filter$.unsubscribe();
    };
    return WidgetComponent;
}());
export { WidgetComponent };
