// dep
import {Component, OnInit, isDevMode} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {first} from 'rxjs/operators';
import * as _ from 'lodash';

// app
import {AuthService} from 'src/app/services/auth.service';
import {DomainService} from 'src/app/services/domain.service';
import {SnackbarService} from 'src/app/services/snackbar.service';
import {WhiteLabelService} from "src/app/services/white-label.service";
import { SessionTraceService } from 'src/app/services/session-trace.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { UserService } from 'src/app/services/user.service';
import { ModalService } from 'src/app/services/modal.service';
import { AlertType } from 'src/app/components/alert.component';
import {environment} from '@environment';
import { MAIL_EXTERNAL_GRADE } from 'src/app/constants/auth';
import { Messages } from 'src/app/constants/messages';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public loginProgress = false;
  public hide: boolean;
  public env;
  public blockedDomain = false;

  constructor(
    public domainService: DomainService,
    public wl: WhiteLabelService,
    private _auth: AuthService,
    private _router: Router,
    private _snack: SnackbarService,
    private _userService: UserService,
    private _modalService: ModalService,
    private _apiSub: SubscriptionService,
    private _sessionTrace: SessionTraceService,
    private _route: ActivatedRoute
  ) {
    this.wl.getDynamicWhiteLabel().then( data => {
      this.blockedDomain = data.blockedDomain;
      if (this.blockedDomain) 
        this._modalService.openAlertModal(
          'Domain Blocked',
          `Contact your account administrator`,
          AlertType.INFO
        );
    });
    
    this._auth.afAuth.authState.pipe(first()).toPromise().then(isSignedIn => {
      if (isSignedIn && this._auth.session?.role && this._auth.session.email !== MAIL_EXTERNAL_GRADE) {
        this._userService.domainValidation(location.hostname, this._auth.session.gid, this._auth.session.uid, this._auth.session.domainSurfing).subscribe(
          async (res) => {
            if (res['allowLogin']) {
              this._userService.updateForLogin(this._auth.session.gid, this._auth.session.uid).take(1).subscribe();
              this._auth.redirectAfterLogin(); // TODO: then() intention is to await?
              this._snack.openSuccess('You are being automatically logged in using your existing session!', 3000);
            } else {
              console.debug("Domain validation FAILED")
              await this._modalService.openWarningModal('Heads up', "Sorry, we couldn't find your account. Please check your email and password or contact support.")
              await this._auth.signOut(true, true);
            }
          },
          err => {
            console.log(err);
          }
        );
      } else {
        this._auth.signOut();
      }

      if (!isSignedIn) {
        this._route.queryParams.subscribe(async (params) => {
          // Receive an "?impersonate_token=XXX" query param on the route and login with it

          // console.log(params);
          const impersonate_token = params.impersonate_token;
          if (!impersonate_token) 
             return
          
          console.debug("Impersonate login with token="+impersonate_token)
          await this._auth.signOut(false);
          try {
            this.loginProgress = true;
            const err_msg = await this._auth.signInWithImpersonateToken(impersonate_token);
            if(err_msg) {
              console.error("Impersonate ERROR: ", err_msg)
              await this._modalService.openWarningModal('Error', "Error impersonating: "+err_msg)
              await this._auth.signOut(true, true)
            } else {
              console.debug("Impersonate OK")
              await this._auth.initSession(); // TODO: redundant? already done in signInWithImpersonateToken?
            }
          } finally {
            this.loginProgress = false
          }
        });
      }

    });

    this.loginForm = new FormGroup({
      email:    new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)])
    });

  }

  async ngOnInit() {
    this.env = environment;
    this.hide = true;
  }

  signInWithEmailAndPassword(event : Event) {
    event.preventDefault();
    const {email, password} = this.loginForm.value;
    this.loginProgress = true;
    this._auth.signInWithEmailAndPassword(email, password).then(res => {
      this.loginProgress = false;
    }).catch(err => {
      this.loginProgress = false;
      // TODO: Comparison against error string, code smell
      if (err.message === 'registration domain is not the current domain') {
        this._modalService.openConfirmModal(
          'You can’t login here…',
          'You appear to have a valid account but you are logging-in to the wrong domain. Please visit the correct site and try again.',
          _ => null,
          null,
          'Ok'
        );
        return;
      }
      if (err.code === "auth/user-not-found") {
        this._snack.openError('The email you entered is incorrect or not registered!', 3000);
        return;
      }
      this._snack.openError('The combination of email/password you entered is incorrect!', 3000);
    });
  }

  async signInWithGoogle(event : Event): Promise<void> {
    event.preventDefault();
    try {
      this.loginProgress = true;
      await this._auth.googleLogin()
    } catch(err) {
      this.loginProgress = false;
      // TODO: Comparison against error string, code smell
      if (err.message === 'registration domain is not the current domain') {
        this._modalService.openConfirmModal(
          'You can’t login here…',
          'You appear to have a valid account but you are logging-in to the wrong domain. Please visit the correct site and try again.',
          _ => null,
          null,
          'Ok'
        )
      } else 
        this._snack.openError('This account could not be found or the popup was closed. You need to register first', 3000);
    } finally {
      this.loginProgress = false;
    }
  }

  goToRegister(): void {
    this._router.navigate(['register']);
  }

  goToTermsOfService(): void {
    this.wl.goToTermsOfService()
  }

  goToPrivacyPolicy(): void {
    this.wl.goToPrivacyPolicy()
  }

  goToPaymentPolicy(): void {
    this.wl.goToPaymentPolicy()
  }

}
