<!--<gmb-spinner class="spinner" *ngIf="progress"></gmb-spinner>-->
<div class="content__header d-flex justify-content-between align-items-center">
  <h1 [style.margin]="!show_action_button_review ? '10px 0px 15px 0px': null"><strong>Review Assistant</strong></h1>
  <button mat-flat-button color="accent" class="btn btn--icon btn--md" (click)="newProtocol(false)" *ngIf="show_action_button_review">
    <i class="far fa-plus"></i> {{action_button_review}}
  </button>
</div>
<div class="content__body">

  <!--PROTOCOL TABLE-->
  <div class="table--shadow mb--30" >
    <div class="mat-row mat-row--padding mat-row--border d-flex justify-content-between align-items-center bg--white">

      <mat-tab-group (selectedTabChange)="tabChange($event)" [selectedIndex]="selectedLogs" class="w--100">
        <mat-tab *ngFor="let tab of tabs; let index = index" [label]="tab">
          
          <ng-container *ngIf="tab === 'Protocols'">
            <app-protocol-list #protocolsList (selectedLogsEmit)="selectedLogs = $event"></app-protocol-list>
          </ng-container>

          <ng-container *ngIf="tab === 'Activity'">
            <app-protocol-log [locations]="[]" *ngIf="selectedLogs"></app-protocol-log>
          </ng-container>

          <!-- <ng-container *ngIf="tab === 'Reports'">
            <app-report-list reportType="Review Assistant"></app-report-list>  
          </ng-container> -->

          <ng-container *ngIf="tab === 'Scan'">
            <app-protocol-scan #protocolScan (selectedLogs)="selectedLogs = $event"></app-protocol-scan>
          </ng-container>

        </mat-tab>
      </mat-tab-group>

    </div>
  </div>

</div>

