import {DatePipe, DecimalPipe} from '@angular/common';
import * as _ from 'lodash';
import * as moment from 'moment';

export function tooltipHorizontalBar(tooltipModel: any) {
  // Tooltip Element
  let tooltipEl = document.getElementById('chartjs-tooltip');

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.innerHTML = '<table></table>';
    document.body.appendChild(tooltipEl);
  }

  // Remove if no tooltip
  if (tooltipModel.opacity === 0) {
    tooltipEl.remove();
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove('above', 'below', 'no-transform');
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add('no-transform');
  }

  function getBody(bodyItem) {
    return bodyItem.lines.map(line => {
      const split = line.split(':');
      const num = parseFloat(split[1]);
      return `${split[0]}: ${numberWithCommas(num)}`;
    });
  }

  // Set Text
  if (tooltipModel.body) {
    // const titleLines = tooltipModel.title || [];
    let bodyLines = tooltipModel.body.map(getBody);

    let innerHtml = '<tbody>';

    // titleLines.forEach((title) => {
    //   const isTime = _.includes(title, ':') && (_.includes(title, 'am') || _.includes(title, 'pm'));
    //   const isDay = _.isEqual(_.size(_.split(title, '/')), 3);
    //   title = isDay && moment(title, 'MM/DD/YYYY').format('dddd MM/DD/YYYY') || title;
    //   innerHtml += `<tr><th colspan="2" class="txt--left"><h2 style="color:black"> <strong class="${isTime ? 'txt--lowercase' : 'txt--capitalize'}">` + title + ' </strong></h2></th></tr>';
    // });
    // innerHtml += '</thead><tbody>';

    bodyLines.forEach((body, i) => {
      const colors = tooltipModel.labelColors[i];
      let style = 'background-color:' + colors.borderColor;
      style += '; border: 2px solid ' + colors.borderColor;
      style += '; border-width: 2px; padding-right: 15px;';
      const span = '<span style="' + style + '"></span>';
      innerHtml += '<tr><td width="5px">' + span + '</td><td>' + '<h5 class="ml--10 mr--15 txt--capitalize" >' + body + '</h5>' + '</td></tr>';
    });
    innerHtml += '</tbody>';

    const tableRoot = tooltipEl.querySelector('table');
    tableRoot.innerHTML = innerHtml;
  }

  // `this` will be the overall tooltip
  const position = this._chart.canvas.getBoundingClientRect();

  // Display, position, and set styles for font
  let left = position.left + window.pageXOffset + tooltipModel.caretX;
  let top = position.top + window.pageYOffset + tooltipModel.caretY;
  const w = window.innerWidth;
  const h = window.innerHeight;
  const offsetWidth = 221;
  const offsetHeight = 140;
  if (left + offsetWidth > w) {
    left = left - offsetWidth;
  }

  if (top + offsetHeight > h) {
    top = top - offsetHeight;
  }
  tooltipEl.style.width = '' + offsetWidth;
  tooltipEl.style.height = '' + offsetHeight;
  tooltipEl.style.opacity = '1';
  tooltipEl.style.background = 'white';
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.zIndex = '100';
  tooltipEl.style.left = left + 'px';
  tooltipEl.style.top = top + 'px';
  tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
  tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
  tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
  tooltipEl.style.border = 'solid 0.03rem lightgray';
  // tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
  tooltipEl.style.padding = '15px';
  tooltipEl.style.pointerEvents = 'none';
}

export function tooltipCustom(tooltipModel: any) {
  // Tooltip Element
  let tooltipEl = document.getElementById('chartjs-tooltip');

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.innerHTML = '<table></table>';
    document.body.appendChild(tooltipEl);
  }

  // Remove if no tooltip
  if (tooltipModel.opacity === 0) {
    tooltipEl.remove();
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove('above', 'below', 'no-transform');
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add('no-transform');
  }

  function getBody(bodyItem) {
    return bodyItem.lines.map(line => {
      const split = line.split(':');
      const num = parseFloat(split[1]);
      return `${split[0]}: ${numberWithCommas(num)}`;
    });
  }

  // Set Text
  if (tooltipModel.body) {
    const titleLines = tooltipModel.title || [];
    let bodyLines = tooltipModel.body.map(getBody);

    let innerHtml = '<thead>';

    titleLines.forEach((title) => {
      const isTime = _.includes(title, ':') && (_.includes(title, 'am') || _.includes(title, 'pm'));
      const isDay = _.isEqual(_.size(_.split(title, '/')), 3);
      title = isDay && moment(title, 'MM/DD/YYYY').format('dddd MM/DD/YYYY') || title;
      innerHtml += `<tr><th colspan="2" class="txt--left"><h2 style="color:black"> <strong class="${isTime ? 'txt--lowercase' : 'txt--capitalize'}">` + title + ' </strong></h2></th></tr>';
    });
    innerHtml += '</thead><tbody>';

    bodyLines.forEach((body, i) => {
      const colors = tooltipModel.labelColors[i];
      let style = 'background-color:' + colors.borderColor;
      style += '; border: 2px solid ' + colors.borderColor;
      style += '; border-width: 2px; padding-right: 15px;';
      const span = '<span style="' + style + '"></span>';
      innerHtml += '<tr><td width="5px">' + span + '</td><td>' + '<h5 class="ml--10 mr--15 txt--capitalize" >' + body + '</h5>' + '</td></tr>';
    });
    innerHtml += '</tbody>';

    const tableRoot = tooltipEl.querySelector('table');
    tableRoot.innerHTML = innerHtml;
  }

  // `this` will be the overall tooltip
  const position = this._chart.canvas.getBoundingClientRect();

  // Display, position, and set styles for font
  let left = position.left + window.pageXOffset + tooltipModel.caretX;
  let top = position.top + window.pageYOffset + tooltipModel.caretY;
  const w = window.innerWidth;
  const h = window.innerHeight;
  const offsetWidth = 221;
  const offsetHeight = 140;
  if (left + offsetWidth > w) {
    left = left - offsetWidth;
  }

  if (top + offsetHeight > h) {
    top = top - offsetHeight;
  }
  tooltipEl.style.width = '' + offsetWidth;
  tooltipEl.style.height = '' + offsetHeight;
  tooltipEl.style.opacity = '1';
  tooltipEl.style.background = 'white';
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.zIndex = '100';
  tooltipEl.style.left = left + 'px';
  tooltipEl.style.top = top + 'px';
  tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
  tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
  tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
  tooltipEl.style.border = 'solid 0.03rem lightgray';
  // tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
  tooltipEl.style.padding = '15px';
  tooltipEl.style.pointerEvents = 'none';
}

export function tooltipCustomWeek(tooltipModel: any) {
  // Tooltip Element
  let tooltipEl = document.getElementById('chartjs-tooltip');

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.innerHTML = '<table></table>';
    document.body.appendChild(tooltipEl);
  }

  // Remove if no tooltip
  if (tooltipModel.opacity === 0) {
    tooltipEl.remove();
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove('above', 'below', 'no-transform');
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add('no-transform');
  }

  function getBody(bodyItem) {
    return bodyItem.lines.map(line => {
      const split = line.split(':');
      const num = parseFloat(split[1]);
      return `${split[0]}: ${numberWithCommas(num)}`;
    });
  }

  // Set Text
  if (tooltipModel.body) {
    const titleLines = tooltipModel.title || [];
    const bodyLines = tooltipModel.body.map(getBody);

    let innerHtml = '<thead>';
    titleLines.forEach((title) => {
      const isTime = _.includes(title, ':') && (_.includes(title, 'am') || _.includes(title, 'pm'));
      const isWeek = _.isEqual(_.size(_.split(title, '/')), 3);
      innerHtml += `<tr><th colspan="2" class="txt--left"><h2 style="color:black"> <strong class="${isTime ? 'txt--lowercase' : 'txt--capitalize'}">` + `${isWeek ? 'Week of ' : ''}` + title + ' </strong></h2></th></tr>';
    });
    innerHtml += '</thead><tbody>';

    bodyLines.forEach((body, i) => {
      const colors = tooltipModel.labelColors[i];
      let style = 'background-color:' + colors.borderColor;
      style += '; border: 2px solid ' + colors.borderColor;
      style += '; border-width: 2px; padding-right: 15px;';
      const span = '<span style="' + style + '"></span>';
      innerHtml += '<tr><td width="5px">' + span + '</td><td>' + '<h5 class="ml--10 mr--15 txt--capitalize" >' + body + '</h5>' + '</td></tr>';
    });
    innerHtml += '</tbody>';

    const tableRoot = tooltipEl.querySelector('table');
    tableRoot.innerHTML = innerHtml;
  }

  // `this` will be the overall tooltip
  const position = this._chart.canvas.getBoundingClientRect();

  // Display, position, and set styles for font
  let left = position.left + window.pageXOffset + tooltipModel.caretX;
  let top = position.top + window.pageYOffset + tooltipModel.caretY;
  const w = window.innerWidth;
  const h = window.innerHeight;
  const offsetWidth = 221;
  const offsetHeight = 140;
  if (left + offsetWidth > w) {
    left = left - offsetWidth;
  }

  if (top + offsetHeight > h) {
    top = top - offsetHeight;
  }
  tooltipEl.style.width = '' + offsetWidth;
  tooltipEl.style.height = '' + offsetHeight;
  tooltipEl.style.opacity = '1';
  tooltipEl.style.background = 'white';
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.zIndex = '100';
  tooltipEl.style.left = left + 'px';
  tooltipEl.style.top = top + 'px';
  tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
  tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
  tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
  tooltipEl.style.border = 'solid 0.03rem lightgray';
  // tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
  tooltipEl.style.padding = '15px';
  tooltipEl.style.pointerEvents = 'none';
}


export function tooltipCustomGrade(tooltipModel: any) {
  // Tooltip Element
  let tooltipEl = document.getElementById('chartjs-tooltip');

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.innerHTML = '<table></table>';
    document.body.appendChild(tooltipEl);
  }

  // Remove if no tooltip
  if (tooltipModel.opacity === 0) {
    tooltipEl.remove();
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove('above', 'below', 'no-transform');
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add('no-transform');
  }

  function getBody(bodyItem) {
    return bodyItem.lines.map(line => {
      const split = line.split(':');
      const num = parseFloat(split[1]);
      return `${split[0]}: ${numberWithCommas(num)}`;
    });
  }

  // Set Text
  if (tooltipModel.body) {
    const titleLines = tooltipModel.title || [];
    const bodyLines = tooltipModel.body.map(getBody);

    // const start = moment(titleLines[0]);
    // titleLines[0] = dateFormat(start.toDate());
    // titleLines[1] = dateFormat(moment(start).subtract(1, 'years').toDate()) ;

    let innerHtml = '<thead>';
    innerHtml += '</thead><tbody>';

    bodyLines.forEach((body, i) => {
      const colors = tooltipModel.labelColors[i];
      let style = 'background-color:' + colors.borderColor;
      style += '; border: 2px solid ' + colors.borderColor;
      style += '; border-width: 2px; padding-right: 15px;';
      const span = '<span style="' + style + '"></span>';
      innerHtml += '<tr><td width="5px">' + span + '</td><td>' + '<h5 class="ml--10 mr--15 txt--capitalize" >'
        + body + '</h5>' + '</td></tr>';
    });
    innerHtml += '</tbody>';

    const tableRoot = tooltipEl.querySelector('table');
    tableRoot.innerHTML = innerHtml;
  }

  const position = this._chart.canvas.getBoundingClientRect();

  // Display, position, and set styles for font
  let left = position.left + window.pageXOffset + tooltipModel.caretX;
  let top = position.top + window.pageYOffset + tooltipModel.caretY;
  const w = window.innerWidth;
  const h = window.innerHeight;
  const offsetWidth = 221;
  const offsetHeight = 140;
  if (left + offsetWidth > w) {
    left = left - offsetWidth;
  }

  if (top + offsetHeight > h) {
    top = top - offsetHeight;
  }
  tooltipEl.style.width = '' + offsetWidth;
  tooltipEl.style.height = '' + offsetHeight;
  tooltipEl.style.opacity = '1';
  tooltipEl.style.background = 'white';
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.zIndex = '100';
  tooltipEl.style.left = left + 'px';
  tooltipEl.style.top = top + 'px';
  tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
  tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
  tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
  tooltipEl.style.border = 'solid 0.03rem lightgray';
  // tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
  tooltipEl.style.padding = '15px';
  tooltipEl.style.pointerEvents = 'none';
}

const numberWithCommas = (x) => {
  const decimalPipe = new DecimalPipe('EN-US');
  return decimalPipe.transform(x, '1.0-1');
};


const dateFormat = (x) => {
  const datePipe = new DatePipe('EN-US');
  return datePipe.transform(x, 'MMM dd yyyy');
};


export const tooltipLabelCallback = (tooltipItem: Chart.ChartTooltipItem, data: any) => {
  const l = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

  return numberWithCommas(l);
};
