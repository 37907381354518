import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'zuluToDate'
})
export class ZuluToDatePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    const datePipe = new DatePipe('EN-US');
    return datePipe.transform(value, 'MM/dd/yyyy', '+0000');
  }

}
