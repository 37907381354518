import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  fireConfig() {
    return {
      apiKey: 'AIzaSyAna8iSk-6bFXPsKwcD6Enj4fvgQIioW18',
      authDomain: (['localhost', 'maplabs.dev', 'app.nat.com'].includes(window.location.hostname) ?
                   'app.maplabs.com' :
                   window.location.hostname),
      databaseURL: 'https://gmb-review-dashboard.firebaseio.com',
      projectId: 'gmb-review-dashboard',
      storageBucket: 'gmb-review-dashboard.appspot.com',
      messagingSenderId: '156411061417'
    };
  }
}
