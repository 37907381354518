/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chart-donut.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ng2-charts";
import * as i3 from "@angular/common";
import * as i4 from "./chart-donut.component";
var styles_ChartDonutsComponent = [i0.styles];
var RenderType_ChartDonutsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChartDonutsComponent, data: {} });
export { RenderType_ChartDonutsComponent as RenderType_ChartDonutsComponent };
function View_ChartDonutsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "canvas", [["baseChart", ""]], null, null, null, null, null)), i1.ɵdid(2, 999424, null, 0, i2.BaseChartDirective, [i1.ElementRef, i2.ThemeService], { datasets: [0, "datasets"], labels: [1, "labels"], options: [2, "options"], chartType: [3, "chartType"], legend: [4, "legend"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataSets; var currVal_1 = _co.labels; var currVal_2 = _co.barChartOptions; var currVal_3 = _co.barChartType; var currVal_4 = false; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_ChartDonutsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChartDonutsComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.dataSets && _co.data); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ChartDonutsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChartDonutsComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataset; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ChartDonutsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chart-donut", [], null, null, null, View_ChartDonutsComponent_0, RenderType_ChartDonutsComponent)), i1.ɵdid(1, 114688, null, 0, i4.ChartDonutsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChartDonutsComponentNgFactory = i1.ɵccf("app-chart-donut", i4.ChartDonutsComponent, View_ChartDonutsComponent_Host_0, { dataset: "dataset", colorChange: "colorChange" }, {}, []);
export { ChartDonutsComponentNgFactory as ChartDonutsComponentNgFactory };
