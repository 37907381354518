import { __awaiter, __generator, __values } from "tslib";
import { OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, of } from 'rxjs';
import * as _ from 'lodash';
import { ReportService } from '../../services/report.service';
import { AuthService } from '../../services/auth.service';
import { takeUntil, take } from 'rxjs/operators';
import { DataTransferService } from '../../services/data-transfer.service';
import { WhiteLabelService } from 'src/app/services/white-label.service';
import { Title } from '@angular/platform-browser';
import { LocationService } from '../../services/location.service';
import { GROUP_SUBSCRIPTION_TYPE, LOCATION_SUBSCRIPTION_TYPE } from 'src/app/constants/firestore/account-location';
import { ValidateLocations } from 'src/app/shared/Auxiliary/validate-locations';
import { LoadingService } from '../../services/loading.service';
import { MatDialog } from '@angular/material';
import { DialogDeleteLocationsComponent } from 'src/app/components/dialog-delete-locations.component';
import { ModalService } from 'src/app/services/modal.service';
import { AlertComponent, AlertType } from 'src/app/components/alert.component';
import { SpinnerService } from 'src/app/services/spinner.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { MAIL_ANONYMOUS } from 'src/app/constants/auth';
var ReportsComponent = /** @class */ (function () {
    function ReportsComponent(router, route, reportS, auth, wl, titleService, dataTrasnfer, locationService, validateLocation, loadingService, dialog, modalService, spinnerService, snackS) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
        this.router = router;
        this.route = route;
        this.reportS = reportS;
        this.auth = auth;
        this.wl = wl;
        this.titleService = titleService;
        this.dataTrasnfer = dataTrasnfer;
        this.locationService = locationService;
        this.validateLocation = validateLocation;
        this.loadingService = loadingService;
        this.dialog = dialog;
        this.modalService = modalService;
        this.spinnerService = spinnerService;
        this.snackS = snackS;
        this.locations = [];
        this.lockDates = false;
        this.sharedNotAllowed = false;
        this.refresh = new Subject();
        this.showMultilocations = true;
        this.viewModeChecked = 'legacy';
        this.isProcessed = false;
        this.minDate = null;
        this.maxDate = null;
        this.loadDataObservable$ = of({ total: 0, completed: null });
        this.subscriptionManager$ = new Subject();
        this.spinnerService.loading$.next(true);
        this.reportType = (_b = (_a = this.route) === null || _a === void 0 ? void 0 : _a.snapshot.params) === null || _b === void 0 ? void 0 : _b.reportType;
        this.reportType = (((_c = this.reportType) === null || _c === void 0 ? void 0 : _c.includes('-location')) ? (_d = this.reportType) === null || _d === void 0 ? void 0 : _d.replace('-location', '') :
            ((_e = this.reportType) === null || _e === void 0 ? void 0 : _e.includes('-report')) ? (_f = this.reportType) === null || _f === void 0 ? void 0 : _f.replace('-report', '') :
                this.reportType);
        this.viewModeChecked = ((_g = this.reportType) === null || _g === void 0 ? void 0 : _g.includes('performance')) || ((_h = this.reportType) === null || _h === void 0 ? void 0 : _h.includes('review')) || ((_j = this.reportType) === null || _j === void 0 ? void 0 : _j.includes('keyword')) || ((_k = this.reportType) === null || _k === void 0 ? void 0 : _k.includes('qanda')) ? 'new' : 'legacy';
        if ((_o = (_m = (_l = this.route) === null || _l === void 0 ? void 0 : _l.snapshot) === null || _m === void 0 ? void 0 : _m.params) === null || _o === void 0 ? void 0 : _o.hash) {
            this.loadRouteWithHash((_r = (_q = (_p = this.route) === null || _p === void 0 ? void 0 : _p.snapshot) === null || _q === void 0 ? void 0 : _q.params) === null || _r === void 0 ? void 0 : _r.hash).then();
        }
        else {
            var automatedReport = (_u = (_t = (_s = this.route) === null || _s === void 0 ? void 0 : _s.snapshot) === null || _t === void 0 ? void 0 : _t.params) === null || _u === void 0 ? void 0 : _u.automatedReport;
            if (automatedReport && this.viewModeChecked == 'legacy') {
                this.loadAutomatedData(automatedReport);
            }
            else {
                this.loadData(automatedReport);
            }
        }
        this.publicRoute = route;
    }
    ReportsComponent.prototype.loadRouteWithHash = function (hash) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_b) {
                (_a = this.reportS.getByHash(hash)) === null || _a === void 0 ? void 0 : _a.pipe(takeUntil(this.subscriptionManager$)).subscribe(function (report) {
                    _this.id = report.id;
                    _this.gid = report.gid;
                    _this.auth.signInAnonymously(_this.gid).then(function (res) {
                        _this.router.navigate(["/report/" + _this.gid + "/" + _this.id + "/shared"]).then();
                    });
                }, function (error) {
                    console.error('error on:', error);
                    _this.spinnerService.loading$.next(false);
                });
                return [2 /*return*/];
            });
        });
    };
    ReportsComponent.prototype.loadAutomatedData = function (automated) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            var authenticated;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        authenticated = null;
                        this.gid = (_c = (_b = (_a = this.route) === null || _a === void 0 ? void 0 : _a.snapshot) === null || _b === void 0 ? void 0 : _b.params) === null || _c === void 0 ? void 0 : _c.gid;
                        this.id = automated;
                        this.reportId = this.id;
                        this.shared = true;
                        this.lockDates = ((_d = this.report) === null || _d === void 0 ? void 0 : _d.lockDates) || false;
                        if (!(!this.auth.session || !this.auth.session.email || this.auth.session.email === MAIL_ANONYMOUS)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.auth.signInAnonymously(this.gid)];
                    case 1:
                        authenticated = _e.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this.showNotSupportedModal();
                        return [2 /*return*/];
                    case 3:
                        this.getAutomatedReport(this.gid, automated, authenticated);
                        return [2 /*return*/];
                }
            });
        });
    };
    ReportsComponent.prototype.AlloweReport = function (report) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!_.isEmpty(report.accounts)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.locationService.basicLocations([{ accounts: report.accounts }]).then()];
                    case 1:
                        _a.sent();
                        this.auth.getSubscription(report.gid)
                            .then(function (subscription) {
                            var e_1, _a;
                            if (subscription.status == GROUP_SUBSCRIPTION_TYPE.TRIAL) {
                                return;
                            }
                            if (report.sharedOnly) {
                                var subscriptionType = report.accounts[0].locationsBasics[0].subscriptionType;
                                if (subscriptionType == 'ESSENTIAL' || subscriptionType == 'FREE') {
                                    _this.router.navigate(['/login']);
                                }
                                return;
                            }
                            var _loop_1 = function (account) {
                                var hasFreeLocations = !_.isEmpty(_.filter(account.locationsBasics, { subscriptionType: LOCATION_SUBSCRIPTION_TYPE.FREE }));
                                var hasEssentialLocations = !_.isEmpty(_.filter(account.locationsBasics, { subscriptionType: LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL }));
                                var hasBasicLocations = !_.isEmpty(_.filter(account.locationsBasics, { subscriptionType: LOCATION_SUBSCRIPTION_TYPE.BASIC }));
                                _this.auth.getSubscription(account.gid).then(function (subscription) {
                                    if (subscription.status !== GROUP_SUBSCRIPTION_TYPE.TRIAL &&
                                        (hasFreeLocations || hasEssentialLocations || hasBasicLocations)) {
                                        _this.router.navigate(['/login']);
                                    }
                                });
                                var hasInvalidAccounts = !_.isEmpty(_.filter(account.locations, { accountId: 'accounts' }));
                                var hasInvalidLocations = !_.isEmpty(_.filter(account.locations, { locationId: 'locations' }));
                                if (hasInvalidAccounts || hasInvalidLocations) {
                                    _this.router.navigate(['/login']);
                                }
                                var hasDuplicated = _.size(_.uniqBy(account.locations, 'locationId')) < _.size(account.locations);
                                if (hasDuplicated) {
                                    _this.router.navigate(['/login']);
                                }
                            };
                            try {
                                for (var _b = __values(report.accounts), _c = _b.next(); !_c.done; _c = _b.next()) {
                                    var account = _c.value;
                                    _loop_1(account);
                                }
                            }
                            catch (e_1_1) { e_1 = { error: e_1_1 }; }
                            finally {
                                try {
                                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                                }
                                finally { if (e_1) throw e_1.error; }
                            }
                        })
                            .catch(function (err) {
                            _this.modalService.openConfirmModal('Loading error', 'There was an error while loading the data. Please try again or contact support (error code 1)', (function () {
                                _this.auth.signOut(true, true);
                            }), AlertType.ERROR, 'Retry');
                        });
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ReportsComponent.prototype.loadData = function (automatedReport) {
        if (automatedReport === void 0) { automatedReport = null; }
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
        return __awaiter(this, void 0, void 0, function () {
            var authenticated;
            return __generator(this, function (_q) {
                switch (_q.label) {
                    case 0:
                        authenticated = null;
                        this.id = automatedReport || ((_c = (_b = (_a = this.route) === null || _a === void 0 ? void 0 : _a.snapshot) === null || _b === void 0 ? void 0 : _b.params) === null || _c === void 0 ? void 0 : _c.id);
                        this.gid = (_f = (_e = (_d = this.route) === null || _d === void 0 ? void 0 : _d.snapshot) === null || _e === void 0 ? void 0 : _e.params) === null || _f === void 0 ? void 0 : _f.gid;
                        this.reportId = this.id;
                        this.reportType = this.reportType == 'performance-insights' ? 'performance-rollup' : this.reportType;
                        if (!(((_j = (_h = (_g = this.route) === null || _g === void 0 ? void 0 : _g.snapshot) === null || _h === void 0 ? void 0 : _h.url) === null || _j === void 0 ? void 0 : _j.findIndex(function (segment) { return segment.toString() === 'shared'; })) !== -1 || this.auth.session.uid === undefined)) return [3 /*break*/, 3];
                        this.shared = true;
                        this.lockDates = ((_k = this.report) === null || _k === void 0 ? void 0 : _k.lockDates) || false;
                        if (!(!((_m = (_l = this.auth) === null || _l === void 0 ? void 0 : _l.session) === null || _m === void 0 ? void 0 : _m.email) || ((_p = (_o = this.auth) === null || _o === void 0 ? void 0 : _o.session) === null || _p === void 0 ? void 0 : _p.email) === MAIL_ANONYMOUS)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.auth.signInAnonymously(this.gid)];
                    case 1:
                        authenticated = _q.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this.showNotSupportedModal();
                        return [2 /*return*/];
                    case 3:
                        if (this.viewModeChecked == 'new') {
                            this.getReportMongo(this.gid, this.id, authenticated, automatedReport);
                        }
                        else {
                            this.getReport(this.gid, this.id, authenticated);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ReportsComponent.prototype.showNotSupportedModal = function () {
        this.modalService.openConfirmModal('Alert', 'Opening a shared report while logged-in is not supported. Please use an incognito session or log-out.', function () { }, AlertType.WARNING);
    };
    ReportsComponent.prototype.getAutomatedReport = function (gid, id, anonymous) {
        var _this = this;
        if (anonymous === void 0) { anonymous = null; }
        var _a;
        (_a = this.reportS.getAutomated(gid, id)) === null || _a === void 0 ? void 0 : _a.pipe(take(1)).subscribe(function (report) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.buildAutomatedReport(report, anonymous);
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.spinnerService.loading$.next(false);
            console.log(error);
        });
    };
    ReportsComponent.prototype.getReport = function (gid, id, anonymous) {
        var _this = this;
        if (anonymous === void 0) { anonymous = null; }
        var _a;
        (_a = this.reportS.get(gid, id)) === null || _a === void 0 ? void 0 : _a.pipe(take(1)).subscribe(function (report) {
            _this.report = report;
            _this.verfiyLocations();
            _this.buildReport(gid, id, anonymous, report);
        }, function (error) {
            console.log(error);
            _this.snackS.openError('There was an error while loading the data. Please try again or contact support', 4000);
            _this.spinnerService.loading$.next(false);
        });
    };
    ReportsComponent.prototype.verfiyLocations = function () {
        var _a;
        var hasLocations = (_a = this.report) === null || _a === void 0 ? void 0 : _a.accounts.find(function (el) { var _a, _b; return ((_b = (_a = el) === null || _a === void 0 ? void 0 : _a.locations) === null || _b === void 0 ? void 0 : _b.length) > 0; });
        if (!hasLocations) {
            var reportType = this.reportType == 'keyword' ? 'keywords' : (this.reportType == 'qanda' ? 'qanda' : this.reportType + "-report");
            this.router.navigateByUrl("" + reportType).then();
        }
    };
    ReportsComponent.prototype.buildAutomatedReport = function (report, anonymous) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var accounts, _c, waitingPopup, ReportTypeName;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        this.report = report;
                        accounts = report.accounts ? report.accounts : [
                            {
                                gid: this.gid,
                                accountId: (_b = (_a = report) === null || _a === void 0 ? void 0 : _a.locations[0]) === null || _b === void 0 ? void 0 : _b.accountId,
                                locations: report.locations
                            }
                        ];
                        _c = this.report;
                        return [4 /*yield*/, this.validateLocation.validateArrayLocations(accounts)];
                    case 1:
                        _c.accounts = _d.sent();
                        this.spinnerService.loading$.next(false);
                        waitingPopup = this.loadDataModal(this.report.accounts, report.reportType);
                        if (!anonymous) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.AlloweReport(report)];
                    case 2:
                        _d.sent();
                        _d.label = 3;
                    case 3:
                        if (!report)
                            return [2 /*return*/];
                        ReportTypeName = report.reportType.replace(/\b\w/g, function (l) { return l.toUpperCase(); });
                        ReportTypeName = ReportTypeName.toLowerCase().includes('qanda') ? 'Q & A' : ReportTypeName;
                        if (this.shared) {
                            this.wl.getDynamicWhiteLabel().then(function (data) {
                                var _a, _b;
                                var company_name = (_b = (_a = data) === null || _a === void 0 ? void 0 : _a.branding) === null || _b === void 0 ? void 0 : _b.company_name;
                                var title_pass = ReportTypeName + " Report | " + company_name;
                                _this.titleService.setTitle(title_pass);
                            });
                        }
                        else {
                            this.dataTrasnfer.setData(ReportTypeName + " Report | " + report.reportName);
                        }
                        this.processReport(anonymous, report, true);
                        return [2 /*return*/];
                }
            });
        });
    };
    ReportsComponent.prototype.getReportMongo = function (gid, id, anonymous, automatedReport) {
        var _this = this;
        if (anonymous === void 0) { anonymous = null; }
        if (automatedReport === void 0) { automatedReport = null; }
        var _a;
        (_a = this.reportS.getReportById(id, automatedReport)) === null || _a === void 0 ? void 0 : _a.pipe(take(1)).subscribe(function (report) { return __awaiter(_this, void 0, void 0, function () {
            var id_1, accountIds, locationIds_1, dateValidations, dates;
            var _a, _b, _c, _d, _e, _f, _g;
            return __generator(this, function (_h) {
                switch (_h.label) {
                    case 0:
                        if (!((_a = report) === null || _a === void 0 ? void 0 : _a.error)) return [3 /*break*/, 1];
                        console.log((_b = report) === null || _b === void 0 ? void 0 : _b.error);
                        this.snackS.openError('There was an error while loading the data. Please try again or contact support', 4000);
                        this.spinnerService.loading$.next(false);
                        return [3 /*break*/, 3];
                    case 1:
                        id_1 = ((_c = report) === null || _c === void 0 ? void 0 : _c.id) || ((_d = report) === null || _d === void 0 ? void 0 : _d._id);
                        //added it until the new version of back that handles the existence of locations and accounts is integrated
                        report.accounts = (_e = report) === null || _e === void 0 ? void 0 : _e.accounts.filter(function (r) { return r.locations.length; });
                        report.reportType = ((_f = report) === null || _f === void 0 ? void 0 : _f.reportType) == 'performance-insights' ? 'performance-rollup' : report.reportType;
                        (_g = report) === null || _g === void 0 ? void 0 : _g.multiChart.forEach(function (m) { return m.checked = true; });
                        this.report = report;
                        accountIds = report.accounts.map(function (a) { return a.accountId; });
                        locationIds_1 = [];
                        report.accounts.forEach(function (a) { return a.locations.forEach(function (l) { return locationIds_1.push(l.locationId); }); });
                        return [4 /*yield*/, this.locationService.getDateValidations(this.reportType, accountIds, [gid], locationIds_1).toPromise()];
                    case 2:
                        dateValidations = _h.sent();
                        dates = this.locationService.dateValidation(dateValidations);
                        this.minDate = dates.minDate;
                        this.maxDate = dates.maxDate;
                        this.verfiyLocations();
                        this.lockDates = this.report && this.report.lockDates ? this.report.lockDates : false;
                        this.reportId = automatedReport ? report.parentReport : id_1;
                        this.buildReport(gid, id_1, anonymous, report);
                        _h.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); }, function (error) {
            console.log(error);
            _this.snackS.openError('There was an error while loading the data. Please try again or contact support', 4000);
            _this.spinnerService.loading$.next(false);
        });
    };
    ReportsComponent.prototype.buildReport = function (gid, id, anonymous, report) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var accounts, waitingPopup, _c, ReportTypeName_1;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        accounts = ((_a = report) === null || _a === void 0 ? void 0 : _a.accounts) || [
                            {
                                gid: gid,
                                accountId: report.locations[0].accountId,
                                locations: report.locations
                            }
                        ];
                        this.report.accounts = accounts;
                        this.spinnerService.loading$.next(false);
                        waitingPopup = this.loadDataModal(this.report.accounts, report.reportType);
                        return [4 /*yield*/, this.existAllLocations(report, id)];
                    case 1:
                        if (!_d.sent()) return [3 /*break*/, 5];
                        _c = this.report;
                        return [4 /*yield*/, this.validateLocation.validateArrayLocations(accounts)];
                    case 2:
                        _c.accounts = _d.sent();
                        if (!anonymous) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.AlloweReport(report)];
                    case 3:
                        _d.sent();
                        _d.label = 4;
                    case 4:
                        if (!report)
                            return [2 /*return*/];
                        ReportTypeName_1 = report.reportType.replace(/\b\w/g, function (l) { return l.toUpperCase(); });
                        ReportTypeName_1 = ReportTypeName_1.toLowerCase().includes('qanda') ? 'Q & A' : ReportTypeName_1;
                        if (this.shared) {
                            this.wl.getDynamicWhiteLabel().then(function (data) {
                                var company_name = data.branding.company_name;
                                var title_pass = ReportTypeName_1 + " Report | " + company_name;
                                _this.titleService.setTitle(title_pass);
                            });
                        }
                        else {
                            this.dataTrasnfer.setData(ReportTypeName_1 + " Report | " + report.reportName);
                        }
                        this.processReport(anonymous, report);
                        return [3 /*break*/, 6];
                    case 5:
                        (_b = waitingPopup) === null || _b === void 0 ? void 0 : _b.close();
                        _d.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ReportsComponent.prototype.existAllLocations = function (report, reportId) {
        return __awaiter(this, void 0, void 0, function () {
            var locationsIds, _loop_2, _a, _b, account, state_1, AllLocations, DeletedLocations_1, nouns, textDescription, dialogRef_1;
            var e_2, _c;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        locationsIds = [];
                        _loop_2 = function (account) {
                            if (account.locations && account.locations.length > 0) {
                                account.locations.forEach(function (l) {
                                    locationsIds.push({
                                        'gid': account.gid,
                                        'accountId': account.accountId,
                                        'locationId': l.locationId
                                    });
                                });
                            }
                            else {
                                return { value: false };
                            }
                        };
                        try {
                            for (_a = __values(report.accounts), _b = _a.next(); !_b.done; _b = _a.next()) {
                                account = _b.value;
                                state_1 = _loop_2(account);
                                if (typeof state_1 === "object")
                                    return [2 /*return*/, state_1.value];
                            }
                        }
                        catch (e_2_1) { e_2 = { error: e_2_1 }; }
                        finally {
                            try {
                                if (_b && !_b.done && (_c = _a.return)) _c.call(_a);
                            }
                            finally { if (e_2) throw e_2.error; }
                        }
                        return [4 /*yield*/, this.locationService.fetchLocationsExistence(locationsIds)];
                    case 1:
                        AllLocations = _d.sent();
                        if (AllLocations.some(function (r) { return !r.exist; })) {
                            DeletedLocations_1 = AllLocations.filter(function (l) { return !l.exist; });
                            nouns = DeletedLocations_1.length > 1 ? ['were', 'locations have'] : ['was', 'location has'];
                            textDescription = "This report used to contain " + AllLocations.length + " locations but " + DeletedLocations_1.length + " " + nouns[0] + " removed from the system. The missing " + nouns[1] + " now been removed from this report.";
                            this.locationService.deleteReportLocation(DeletedLocations_1, DeletedLocations_1[0]['gid'], reportId);
                            dialogRef_1 = this.dialog.open(DialogDeleteLocationsComponent, {
                                width: '400px',
                                data: {
                                    title: 'Heads Up',
                                    description: textDescription,
                                    DeletedLocations: DeletedLocations_1,
                                }
                            });
                            dialogRef_1.disableClose = true;
                            dialogRef_1.afterClosed().subscribe(function () {
                                dialogRef_1.close();
                                _this.deleteLocations(DeletedLocations_1);
                            });
                            return [2 /*return*/, false];
                        }
                        return [2 /*return*/, true];
                }
            });
        });
    };
    ReportsComponent.prototype.deleteLocations = function (deletedLocations) {
        var _this = this;
        var _a, _b, _c;
        this.spinnerService.loading$.next(true);
        deletedLocations.forEach(function (el) {
            var _a, _b, _c, _d;
            var indexAccount = (_b = (_a = _this.report) === null || _a === void 0 ? void 0 : _a.accounts) === null || _b === void 0 ? void 0 : _b.findIndex(function (a) { return a.accountId == el.accountId; });
            var indexLoc = (_c = _this.report.accounts[indexAccount].locations) === null || _c === void 0 ? void 0 : _c.findIndex(function (l) { return l.locationId == el.locationId; });
            (_d = _this.report.accounts[indexAccount].locations) === null || _d === void 0 ? void 0 : _d.splice(indexLoc, 1);
        });
        this.report.accounts = this.locationService.deleteServiceArea(this.report.accounts);
        if (this.viewModeChecked != 'new') {
            if ((_a = this.report) === null || _a === void 0 ? void 0 : _a.id) {
                this.reportS.update(false, (_b = this.report) === null || _b === void 0 ? void 0 : _b.gid, (_c = this.report) === null || _c === void 0 ? void 0 : _c.id, this.report).then(function (res) {
                    location.reload();
                }, function (err) {
                    _this.handleDeleteError();
                });
            }
            else {
                location.reload();
            }
        }
        else {
            if (this.reportType == 'performance-comparison') {
                this.report.metrics = this.report.metricsName;
            }
            this.reportS.updateReportsInMongo(this.report['_id'], this.report).subscribe(function (res) {
                var _a;
                if ((_a = res) === null || _a === void 0 ? void 0 : _a['error']) {
                    _this.handleDeleteError();
                }
                else {
                    location.reload();
                }
            }, function (err) {
                _this.handleDeleteError();
            });
        }
    };
    ReportsComponent.prototype.handleDeleteError = function () {
        this.spinnerService.loading$.next(false);
        var dialogRef = this.dialog.open(AlertComponent, {
            width: '680px',
            data: {
                title: 'Heads up',
                content: "There was an error while removing the locations.",
                closeButtonLabel: 'Close',
                alertType: AlertType.ERROR
            }
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe(function () {
            dialogRef.close();
            location.reload();
        });
    };
    ReportsComponent.prototype.loadDataModal = function (accounts, type) {
        var _a;
        accounts = accounts.filter(Boolean);
        if (accounts.reduce(function (r, ac) { return r += ac.locations.length; }, 0) <= 9)
            return;
        var steps = 0;
        var reportType = type.includes('-report') ? type.replace('-report', '') : type;
        reportType = reportType.includes('performance') ? (_a = reportType) === null || _a === void 0 ? void 0 : _a.slice("performance-".length) : reportType;
        switch (reportType) {
            case 'rollup':
                steps = 5;
                break;
            case 'qanda':
                steps = 1;
                break;
            case 'revenue':
                steps = 1;
                break;
            case 'review':
                steps = 3;
                break;
            case 'comparison':
                steps = 2;
                break;
            case 'grade':
                steps = 3;
                break;
            case 'Review Assistant':
                steps = 6;
                break;
            case 'keyword':
                steps = 1;
                break;
        }
        return this.loadingService.open(steps);
    };
    ReportsComponent.prototype.processReport = function (anonymous, report, automatedReport) {
        if (automatedReport === void 0) { automatedReport = false; }
        var _a, _b, _c, _d, _e, _f;
        this.spinnerService.loading$.next(false);
        this.reportType = ((_a = this.reportType) === null || _a === void 0 ? void 0 : _a.includes('-location')) ? (_b = this.reportType) === null || _b === void 0 ? void 0 : _b.replace('-location', '') : this.reportType;
        this.dataPicker = this.reportS.reportToDataPicker(report, this.id, automatedReport, this.viewModeChecked);
        this.dataPicker.aggregation = ((_c = report) === null || _c === void 0 ? void 0 : _c.aggregation) || ((_d = this.dataPicker) === null || _d === void 0 ? void 0 : _d.aggregation);
        this.dataPicker.multiChart = ((_e = report) === null || _e === void 0 ? void 0 : _e.multiChart) || ((_f = this.dataPicker) === null || _f === void 0 ? void 0 : _f.multiChart);
        this.showMultilocations = _.has(report, 'dynamic');
        this.updateClass();
        this.isProcessed = true;
    };
    ReportsComponent.prototype.ngOnDestroy = function () {
        this.reportType = null;
        this.subscriptionManager$.next(true);
        this.subscriptionManager$.unsubscribe();
        this.spinnerService.loading$.next(false);
    };
    ReportsComponent.prototype.handleDatapicker = function ($event) {
        if ($event) {
            this.dataPicker = $event;
            this.refresh.next(true);
        }
    };
    ReportsComponent.prototype.updateClass = function () {
        if (this.shared) {
            if (this.reportType.includes('rollup') ||
                this.reportType.includes('review') ||
                this.reportType.includes('comparison') ||
                this.reportType.includes('revenue') ||
                this.reportType.includes('keyword')) {
                return;
            }
            this.contentClass = {
                'content content--padding': (this.reportType === 'grade'),
                'dashboard-content dashboard-content--sm': !(this.reportType === 'grade')
            };
        }
    };
    Object.defineProperty(ReportsComponent.prototype, "loadingSpinner", {
        get: function () {
            return this.spinnerService.loading$;
        },
        enumerable: true,
        configurable: true
    });
    return ReportsComponent;
}());
export { ReportsComponent };
