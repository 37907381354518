import {Injectable} from '@angular/core';
import {Protocol} from '../constants/firestore/protocol';
import {
  ACCOUNTS,
  GROUPS,
  LOCATIONS,
  POST_MANAGEMENT_GROUP,
  POST_MANAGEMENT_LOCATION
} from '../constants/firestore/collections';
import {FirestoreService} from './firestore.service';
import {PostManagement} from '../constants/post';
import * as firebase from 'firebase/app';
import { Pageable } from '../constants/pageable';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {environment} from '@environment';
import { ApiResponse } from '../constants/api-response';
@Injectable({
  providedIn: 'root'
})
export class PostManagementService {
  

  constructor(
    private fs: FirestoreService,
    private afs: AngularFirestore,
    private http: HttpClient,
  ) {
  }

  async save(gid, accountId, locationId, post: PostManagement) {
    post.createdAt = firebase.firestore.Timestamp.now();
    const post_id = this.afs.createId()
    delete post.lastUpdate;
    if (post.placeId) {
      return await this.fs.col(GROUPS).doc(gid).collection(ACCOUNTS).doc(accountId).collection(LOCATIONS).doc(locationId)
        .collection(POST_MANAGEMENT_LOCATION).add(post);
    } else {
      await this.fs.col(GROUPS).doc(gid).collection(POST_MANAGEMENT_GROUP).doc(post_id).set(post);
      return post_id;
    }

  }

  getPostManagement(gid, docId: string) {
    return this.afs.collection(GROUPS).doc(gid).collection(POST_MANAGEMENT_GROUP).doc<PostManagement>(docId);
  }

  sendScheduled(gid, post_id) {
    return this.http.get<ApiResponse>(`${environment.apiUrl}/v2/posts/check/gid/${gid}/post/${post_id}`)
      .pipe(
        map(response => response.data || null)
      );
  }

  async update(gid, accountId, locationId, post: Partial<PostManagement>, id) {
    post.lastUpdate = firebase.firestore.Timestamp.now();

    if (post.placeId) {
      return await this.fs.col(GROUPS).doc(post.gid).collection(ACCOUNTS).doc(accountId).collection(LOCATIONS).doc(locationId).collection(POST_MANAGEMENT_LOCATION).doc(id)
        .set(post, {merge: true});
    } else {
      return await this.fs.col(GROUPS).doc(post.gid).collection(POST_MANAGEMENT_GROUP).doc(id).set(post, {merge: true});
    }
  }

  getByGidPending(pageable: Pageable, gid: string, next, last) {
    return this.fs.paginate<Protocol>(`${GROUPS}/${gid}/${POST_MANAGEMENT_GROUP}`, 'createdAt', null
      , {
        size: pageable.size,
        page: pageable.page
      }, false, next, last, null, null, 'desc');
  }

  delete(gid, accountId, locationId, docId: any) {
    if (locationId) {
      return this.fs.col(GROUPS).doc(gid).collection(ACCOUNTS).doc(accountId).collection(LOCATIONS).doc(locationId)
        .collection(POST_MANAGEMENT_LOCATION).doc(docId).delete();
    } else {
      return this.fs.col<Protocol>(GROUPS).doc(gid).collection(POST_MANAGEMENT_GROUP).doc(docId).delete();
    }

  }
}
