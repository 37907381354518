<!--Service Area-->
<div class="box__item box__item--editable"> 
  <div class="d-flex align-items-center w--100">
    <div class="icon icon--rounded icon--gray mr--20">
      <img src="/assets/images/icons/radar.png" alt="Upload photos here." class="w--20-px">
    </div>
    <div class="w--100" [class.txt--medium-gray]="isUnderReview">
      <h4 class="w--100"><strong>Service Area</strong></h4>
      <p class="mb--5" *ngIf="(!serviceAreaPlaces?.length) && isEdit">Add Service Area</p>
      <div class="d-flex" *ngFor="let place of serviceAreaPlaces">
          <span class="m--0 txt--pghtgray" [outerHTML]="place?.placeName"></span>
      </div>

      <span class="under-review" *ngIf="isUnderReview" role="alert">Under review</span>
    </div>
  </div>
  <button mat-button (click)="openAddressDialog()" class="btn btn-icon btn-icon--gray" *ngIf="isEdit"><em class="fas fa-pencil"></em></button>
</div>