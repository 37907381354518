/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./color-date-picker.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-color-picker";
import * as i3 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./color-date-picker.component";
var styles_ColorDatePickerComponent = [i0.styles];
var RenderType_ColorDatePickerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ColorDatePickerComponent, data: {} });
export { RenderType_ColorDatePickerComponent as RenderType_ColorDatePickerComponent };
export function View_ColorDatePickerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "row gutters-sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "input", [["class", "input-group__field input-group__field--sm"], ["placeholder", "Select Color"]], null, [[null, "colorPickerChange"], [null, "click"], [null, "focus"], [null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).handleClick() !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).handleFocus() !== false);
        ad = (pd_1 && ad);
    } if (("input" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4).handleInput($event) !== false);
        ad = (pd_2 && ad);
    } if (("colorPickerChange" === en)) {
        var pd_3 = ((_co.color = $event) !== false);
        ad = (pd_3 && ad);
    } if (("colorPickerChange" === en)) {
        var pd_4 = (_co.handleSelected($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i2.ColorPickerDirective, [i1.Injector, i1.ComponentFactoryResolver, i1.ApplicationRef, i1.ViewContainerRef, i1.ElementRef, i2.ColorPickerService], { colorPicker: [0, "colorPicker"] }, { colorPickerChange: "colorPickerChange" }), (_l()(), i1.ɵeld(5, 0, null, null, 0, "span", [["class", "input-color-picker"]], [[4, "background", null]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "col-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 16777216, null, null, 1, "input", [["class", "input-group__field input-group__field--sm"], ["readonly", ""]], [[8, "value", 0]], [[null, "colorPickerChange"], [null, "click"], [null, "focus"], [null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).handleClick() !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).handleFocus() !== false);
        ad = (pd_1 && ad);
    } if (("input" === en)) {
        var pd_2 = (i1.ɵnov(_v, 9).handleInput($event) !== false);
        ad = (pd_2 && ad);
    } if (("colorPickerChange" === en)) {
        var pd_3 = ((_co.color = $event) !== false);
        ad = (pd_3 && ad);
    } if (("colorPickerChange" === en)) {
        var pd_4 = (_co.handleSelected($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 671744, null, 0, i2.ColorPickerDirective, [i1.Injector, i1.ComponentFactoryResolver, i1.ApplicationRef, i1.ViewContainerRef, i1.ElementRef, i2.ColorPickerService], { colorPicker: [0, "colorPicker"] }, { colorPickerChange: "colorPickerChange" }), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "col-3 alternate-theme"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "button", [["class", "btn btn--action btn--full"], ["color", "primary"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleCleared() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(12, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Clear"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.color; _ck(_v, 4, 0, currVal_0); var currVal_3 = _co.color; _ck(_v, 9, 0, currVal_3); var currVal_6 = "primary"; _ck(_v, 12, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.color; _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.color, ""); _ck(_v, 8, 0, currVal_2); var currVal_4 = (i1.ɵnov(_v, 12).disabled || null); var currVal_5 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_4, currVal_5); }); }
export function View_ColorDatePickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-color-date-picker", [], null, null, null, View_ColorDatePickerComponent_0, RenderType_ColorDatePickerComponent)), i1.ɵdid(1, 638976, null, 0, i7.ColorDatePickerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ColorDatePickerComponentNgFactory = i1.ɵccf("app-color-date-picker", i7.ColorDatePickerComponent, View_ColorDatePickerComponent_Host_0, { color: "color", masterReset: "masterReset" }, { selected: "selected", cleared: "cleared" }, []);
export { ColorDatePickerComponentNgFactory as ColorDatePickerComponentNgFactory };
