import { __assign } from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormBuilder, Validators } from '@angular/forms';
var ModalServiceItemComponent = /** @class */ (function () {
    function ModalServiceItemComponent(dialogRef, data, _fb) {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        this.dialogRef = dialogRef;
        this.data = data;
        this._fb = _fb;
        this.structuredItem = null;
        this.isEdit = false;
        this._item = null;
        this._category = null;
        this._typeFormPrice = 'fixed';
        if (this.data.structuredService) {
            this.structuredItem = this.data.structuredService;
            this._item = __assign({}, this.data.item);
            this._typeFormPrice = ((_b = (_a = this.data.structuredService) === null || _a === void 0 ? void 0 : _a.price) === null || _b === void 0 ? void 0 : _b.type) || this.data.typeServiceItem || 'noPrice';
            this.isEdit = true;
            this._position = this.data.position;
        }
        else {
            this._item = __assign({}, this.data.item);
            this._category = this.data.category;
            if ((_c = this._item) === null || _c === void 0 ? void 0 : _c.freeFormServiceItem) {
                this.structuredItem = {
                    description: '',
                    displayName: '',
                    isOffered: (_d = this._item) === null || _d === void 0 ? void 0 : _d.isOffered,
                    price: (_e = this._item) === null || _e === void 0 ? void 0 : _e.price,
                    languageCode: 'en',
                    isFreeFormServiceItem: true,
                    serviceTypeId: '',
                    positionEdit: null
                };
            }
            else {
                this.structuredItem = {
                    description: '',
                    displayName: (_f = this._category) === null || _f === void 0 ? void 0 : _f.serviceTypes.find(function (x) { var _a; return x.serviceTypeId === ((_a = _this._item.structuredServiceItem) === null || _a === void 0 ? void 0 : _a.serviceTypeId); }).displayName,
                    isOffered: (_g = this._item) === null || _g === void 0 ? void 0 : _g.isOffered,
                    price: (_h = this._item) === null || _h === void 0 ? void 0 : _h.price,
                    languageCode: 'en',
                    isFreeFormServiceItem: false,
                    serviceTypeId: (_k = (_j = this._item) === null || _j === void 0 ? void 0 : _j.structuredServiceItem) === null || _k === void 0 ? void 0 : _k.serviceTypeId,
                    positionEdit: null
                };
            }
        }
        this.initForm();
    }
    ModalServiceItemComponent.prototype.ngOnInit = function () {
    };
    ModalServiceItemComponent.prototype.apply = function () {
        var _a, _b;
        var units = '0';
        var nanos = 0;
        if (this.form.valid) {
            if (this.description) {
                var value = this.description.value.trim();
                value = value.replace(/^\s+|\s+$|\s+(?=\s)/g, "");
                this.description.setValue(value);
            }
            if (this.typePrice.value !== 'free' && this.typePrice.value !== 'noPrice') {
                var amount = typeof (this.form.value.units) === 'string' ? this.form.value.units.split('.') : this.form.value.units;
                if (amount.length >= 2) {
                    nanos = Number(amount[amount.length - 1]);
                    if (nanos > 99) {
                        nanos = 0;
                        units = amount.join('').replace(/\,/gi, '');
                    }
                    else {
                        amount.splice(amount.length - 1, 1);
                        units = amount.join('').replace(/\,/gi, '');
                    }
                }
                else if (amount.length == 1) {
                    units = amount.join('').replace(/\,/gi, '');
                }
            }
            this._item = __assign(__assign({}, this._item), { price: {
                    type: (_a = this.typePrice) === null || _a === void 0 ? void 0 : _a.value,
                    currencyCode: this.form.value.currency,
                    units: units,
                    nanos: nanos
                }, isOffered: true });
            this.structuredItem = __assign(__assign({}, this.structuredItem), { description: this.form.value.description, isOffered: true, languageCode: 'en', price: {
                    type: (_b = this.typePrice) === null || _b === void 0 ? void 0 : _b.value,
                    currencyCode: this.form.value.currency,
                    units: units,
                    nanos: nanos
                } });
            if (this.typePrice.value === 'noPrice') {
                delete this._item['price'];
                delete this.structuredItem['price'];
            }
            if (this.isEdit) {
                this.update();
                return;
            }
            else {
                this.add();
                return;
            }
        }
        else {
            this.form.markAllAsTouched();
        }
        // this.update();
    };
    ModalServiceItemComponent.prototype.remove = function () {
        this.dialogRef.close({ action: 'removed', position: this._position, item: this._item });
    };
    ModalServiceItemComponent.prototype.add = function () {
        if (!this.structuredItem.isFreeFormServiceItem) {
            this._item.structuredServiceItem = __assign(__assign({}, this._item.structuredServiceItem), { description: this.form.value.description });
        }
        else {
            this._item.freeFormServiceItem = __assign(__assign({}, this._item.freeFormServiceItem), { label: {
                    description: this.form.value.description,
                    displayName: this.form.value.name,
                    languageCode: 'en'
                } });
            this.structuredItem.displayName = this.form.value.name;
        }
        this.dialogRef.close({ action: 'added', item: this._item, structuredItem: this.structuredItem, category: this._category.categoryId });
    };
    ModalServiceItemComponent.prototype.update = function () {
        if (!this.structuredItem.isFreeFormServiceItem) {
            this._item.structuredServiceItem = __assign(__assign({}, this._item.structuredServiceItem), { description: this.form.value.description });
        }
        else {
            this._item.freeFormServiceItem = __assign(__assign({}, this._item.freeFormServiceItem), { label: {
                    description: this.form.value.description,
                    displayName: this.form.value.name,
                    languageCode: 'en'
                } });
            this.structuredItem.displayName = this.form.value.name;
        }
        this.dialogRef.close({ action: 'updated', position: this._position, item: this._item, structuredItem: this.structuredItem });
    };
    ModalServiceItemComponent.prototype.initForm = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8;
        if (this.isEdit) {
            var units = 0.0;
            if (((_b = (_a = this.structuredItem) === null || _a === void 0 ? void 0 : _a.price) === null || _b === void 0 ? void 0 : _b.units) || ((_d = (_c = this.structuredItem) === null || _c === void 0 ? void 0 : _c.price) === null || _d === void 0 ? void 0 : _d.nanos)) {
                if ((_f = (_e = this.structuredItem) === null || _e === void 0 ? void 0 : _e.price) === null || _f === void 0 ? void 0 : _f.nanos)
                    units = Number((((_h = (_g = this.structuredItem) === null || _g === void 0 ? void 0 : _g.price) === null || _h === void 0 ? void 0 : _h.units) || 0) + "." + ((_k = (_j = this.structuredItem) === null || _j === void 0 ? void 0 : _j.price) === null || _k === void 0 ? void 0 : _k.nanos));
                else
                    units = Number((_m = (_l = this.structuredItem) === null || _l === void 0 ? void 0 : _l.price) === null || _m === void 0 ? void 0 : _m.units);
            }
            if (this.structuredItem.isFreeFormServiceItem) {
                this.form = this._fb.group({
                    name: [this.structuredItem.displayName, Validators.required],
                    units: [units, [Validators.required, Validators.pattern('^[+-]?[0-9]{1,5}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?'), Validators.min(0)]],
                    nanos: [((_p = (_o = this.structuredItem) === null || _o === void 0 ? void 0 : _o.price) === null || _p === void 0 ? void 0 : _p.nanos) ? (_r = (_q = this.structuredItem) === null || _q === void 0 ? void 0 : _q.price) === null || _r === void 0 ? void 0 : _r.nanos : 0, [Validators.pattern('^[0-9]*'), Validators.min(0)]],
                    description: [((_s = this.structuredItem) === null || _s === void 0 ? void 0 : _s.description) ? (_t = this.structuredItem) === null || _t === void 0 ? void 0 : _t.description : '', [Validators.maxLength(300)]],
                    currency: [((_v = (_u = this.structuredItem) === null || _u === void 0 ? void 0 : _u.price) === null || _v === void 0 ? void 0 : _v.currencyCode) ? (_x = (_w = this.structuredItem) === null || _w === void 0 ? void 0 : _w.price) === null || _x === void 0 ? void 0 : _x.currencyCode : 'USD', Validators.required],
                    type: [this._typeFormPrice, Validators.required],
                });
            }
            else {
                this.form = this._fb.group({
                    units: [units, [Validators.required, Validators.pattern('^[[+-]?[0-9]{1,5}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?'), Validators.min(0)]],
                    nanos: [((_z = (_y = this.structuredItem) === null || _y === void 0 ? void 0 : _y.price) === null || _z === void 0 ? void 0 : _z.nanos) ? (_1 = (_0 = this.structuredItem) === null || _0 === void 0 ? void 0 : _0.price) === null || _1 === void 0 ? void 0 : _1.nanos : 0, [Validators.pattern('^[0-9]*'), Validators.min(0)]],
                    description: [((_2 = this.structuredItem) === null || _2 === void 0 ? void 0 : _2.description) ? (_3 = this.structuredItem) === null || _3 === void 0 ? void 0 : _3.description : '', [Validators.maxLength(300)]],
                    currency: [((_5 = (_4 = this.structuredItem) === null || _4 === void 0 ? void 0 : _4.price) === null || _5 === void 0 ? void 0 : _5.currencyCode) ? (_7 = (_6 = this.structuredItem) === null || _6 === void 0 ? void 0 : _6.price) === null || _7 === void 0 ? void 0 : _7.currencyCode : 'USD', Validators.required],
                    type: [this._typeFormPrice, Validators.required],
                });
            }
        }
        else {
            if ((_8 = this._item) === null || _8 === void 0 ? void 0 : _8.freeFormServiceItem) {
                this.form = this._fb.group({
                    name: ['', Validators.required],
                    units: [0, [Validators.required, Validators.pattern('^[+-]?[0-9]{1,5}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?'), Validators.min(0)]],
                    nanos: [0, [Validators.pattern('^[0-9]*'), Validators.min(0)]],
                    description: ['', [Validators.maxLength(300)]],
                    currency: ['USD', Validators.required],
                    type: [this._typeFormPrice, Validators.required],
                });
            }
            else {
                this.form = this._fb.group({
                    units: [0, [Validators.required, Validators.pattern('^[+-]?[0-9]{1,5}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?'), Validators.min(0)]],
                    nanos: [0, [Validators.pattern('^[0-9]*'), Validators.min(0)]],
                    description: ['', [Validators.maxLength(300)]],
                    currency: ['USD', Validators.required],
                    type: [this._typeFormPrice, Validators.required],
                });
            }
        }
    };
    Object.defineProperty(ModalServiceItemComponent.prototype, "name", {
        get: function () {
            return this.form.get('name');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalServiceItemComponent.prototype, "units", {
        get: function () {
            return this.form.get('units');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalServiceItemComponent.prototype, "description", {
        get: function () {
            return this.form.get('description');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalServiceItemComponent.prototype, "currency", {
        get: function () {
            return this.form.get('currency');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalServiceItemComponent.prototype, "typePrice", {
        get: function () {
            return this.form.get('type');
        },
        enumerable: true,
        configurable: true
    });
    return ModalServiceItemComponent;
}());
export { ModalServiceItemComponent };
