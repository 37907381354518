<div class="carousel__item">
  <div class="box box--rounded box--shadow box--padding-xs bg--white txt--center">

    <ng-container [ngSwitch]="name">

      <div class="icon icon--rounded icon--xl icon--blue icon--center mb--25" *ngSwitchCase="'ACTIONS_PHONE'">
        <img src="/assets/images/icons/mobile-phone.png" alt="Mobile Phone Calls Icon">
      </div>
      <div class="icon icon--rounded icon--xl icon--orange icon--center mb--25" *ngSwitchCase="'ACTIONS_WEBSITE'">
        <img src="/assets/images/icons/website-visits.png" alt="Website Visits Icon">
      </div>

      <div class="icon icon--rounded icon--xl icon--limeyellow icon--center mb--25" *ngSwitchCase="'VIEWS_MAPS'">
        <img src="/assets/images/icons/pin-yellow.png" alt="Map Views Icon">
      </div>

      <div class="icon icon--rounded icon--xl icon--limegreen icon--center mb--25" *ngSwitchCase="'VIEWS_SEARCH'">
        <img src="/assets/images/icons/search-views.png" alt="Search Views Icon">
      </div>

      <div class="icon icon--rounded icon--xl icon--purple icon--center mb--25" *ngSwitchCase="'QUERIES_DIRECT'">
        <img src="/assets/images/icons/direct-impressions.png" alt="Direct Impressions Icon">
      </div>

      <div class="icon icon--rounded icon--xl icon--green icon--center mb--25"
           *ngSwitchCase="'ACTIONS_DRIVING_DIRECTIONS'">
        <img src="/assets/images/icons/direction-request.png" alt="Direction Request Icon">
      </div>

      <div class="icon icon--rounded icon--xl icon--red icon--center mb--25" *ngSwitchCase="'QUERIES_INDIRECT'">
        <img src="/assets/images/icons/discovery-impressions.png" alt="Discovery Impressions Icon">
      </div>
    </ng-container>


    <h6 class="txt--uppercase txt--black">
      <ng-container [ngSwitch]="name">
        <strong *ngSwitchCase="'ACTIONS_DRIVING_DIRECTIONS'">DIRECTION REQUESTS</strong>
        <strong *ngSwitchCase="'ACTIONS_PHONE'">MOBILE CALL ACTIONS</strong>
        <strong *ngSwitchCase="'ACTIONS_WEBSITE'">WEBSITE VISITS</strong>
        <strong *ngSwitchCase="'QUERIES_INDIRECT'">DISCOVERY IMPRESSIONS</strong>
        <strong *ngSwitchCase="'QUERIES_DIRECT'">DIRECT IMPRESSIONS</strong>
        <strong *ngSwitchCase="'VIEWS_MAPS'">MAPS VIEWS</strong>
        <strong *ngSwitchCase="'VIEWS_SEARCH'">SEARCH VIEWS</strong>
      </ng-container>
    </h6>

    <div class="last-no-margin">
      <p class="mb--10 txt--lightgray" *ngFor="let metric of arrayMetric">
        {{metric.date | date: 'EEEE MM/dd/yy'}}:
        <span class="txt--black">
          <strong> {{metric.value | number: '1.0-0'}}</strong>
        </span>
      </p>
    </div>

    <p class="txt--md mb--10 txt--lightgray" *ngIf="arrayMetric?.length==0">No Data</p>
  </div>
</div>
