// dep
import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router'
import { Observable } from 'rxjs'

// app
import { AuthService } from './auth.service'
import { ISubscription } from '../constants/subscription'
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class BulkActionsGuardService implements CanActivate {
  subscription$: Observable<ISubscription>
  subscription: ISubscription
  private _bulk_actions_enabled = false

  constructor(
    private _auth: AuthService,
    private _router: Router
  ) {
    this.subscription$ = this._auth.subscription$
    this.subscription$.subscribe((subscription) => {
      this.subscription = subscription,
      this._bulk_actions_enabled = (
          this.subscription?.pricingVersion < 3 || (
            this.subscription?.pricingVersion >= 3 &&
            this.subscription?.packages?.hasOwnProperty("pkg_bulk_actions")
        ) 
      )
      console.log('BulkActionsGuardService#constructor: _bulk_actions_enabled', this._bulk_actions_enabled)
    });
  }

  async canActivate(route: ActivatedRouteSnapshot) : Promise<boolean> {
    const subscription = await this._auth.subscription$.getValue()
    this._bulk_actions_enabled = (
        subscription?.pricingVersion < 3 || (
        subscription?.pricingVersion >= 3 &&
        subscription?.packages?.hasOwnProperty("pkg_bulk_actions")
    ))


    if (this._bulk_actions_enabled === false) {
      console.log('BulkActionsGuardService#canActivate: bulk actions not enabled')
      this._router.navigate(['/settings/billing'])
      return false;
    }
    console.log('BulkActionsGuardService#canActivate: bulk actions enabled')
    return true;
  }
}