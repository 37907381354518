import { OnInit } from '@angular/core';
import { BORDERS_CHART_DOUGHNUT, BORDERS_CHART_DOUGHNUT_REVENUE, COLORS_CHART_DOUGHNUT, COLORS_CHART_DOUGHNUT_REVENUE } from '../../../constants/chart-color';
import { tooltipLabelCallback } from '../../../constants/tooltip';
var ChartDonutsComponent = /** @class */ (function () {
    function ChartDonutsComponent() {
        this.colorChange = '';
        this.initData = [];
        this.initLabels = [];
        this.labels = [];
        this.hidden = [];
        // Charts Line Options
        this.barChartType = 'doughnut';
        this.barChartLegend = false;
        this.barChartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            cutoutPercentage: 50,
            tooltips: {
                enabled: true,
                callbacks: {
                    label: tooltipLabelCallback
                }
            },
        };
    }
    ChartDonutsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dataset.forEach(function (d) {
            var _a, _b, _c;
            _this.initData.push((_b = (_a = d) === null || _a === void 0 ? void 0 : _a.total) === null || _b === void 0 ? void 0 : _b.numeric);
            _this.initLabels.push((_c = d) === null || _c === void 0 ? void 0 : _c.label);
        });
        this.data = this.initData;
        this.labels = this.initLabels;
        if (this.colorChange !== 'switch-colors') {
            this.dataSets = [{ data: this.data, backgroundColor: COLORS_CHART_DOUGHNUT, borderColor: BORDERS_CHART_DOUGHNUT }];
        }
        else {
            this.dataSets = [{ data: this.data, backgroundColor: COLORS_CHART_DOUGHNUT_REVENUE, borderColor: BORDERS_CHART_DOUGHNUT_REVENUE }];
        }
    };
    ChartDonutsComponent.prototype.hideData = function (i, hide) {
        var _this = this;
        if (hide) {
            this.hidden.push(i);
        }
        else {
            this.hidden = this.hidden.filter(function (value) { return value !== i; });
        }
        this.data = [];
        this.initData.forEach(function (d, dataIndex) {
            var isHidden = false;
            _this.hidden.forEach(function (h, hiddenIndex) {
                if (dataIndex === hiddenIndex) {
                    isHidden = true;
                }
            });
            if (!isHidden) {
                _this.data.push(d);
            }
        });
    };
    ChartDonutsComponent.prototype.isDataHidden = function (i) {
        return this.hidden.includes(i);
    };
    return ChartDonutsComponent;
}());
export { ChartDonutsComponent };
