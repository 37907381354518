/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./snack-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "./snack-bar.component";
import * as i7 from "@angular/material/snack-bar";
var styles_SnackBarComponent = [i0.styles];
var RenderType_SnackBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SnackBarComponent, data: {} });
export { RenderType_SnackBarComponent as RenderType_SnackBarComponent };
export function View_SnackBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "snackbar__data"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["class", "btn btn-icon"], ["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.snackBarRef.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(4, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 0, "i", [["class", "fas fa-times txt--white txt--lg"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "d-flex justify-content-between align-items-center ", _co.panelClass, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.message; _ck(_v, 2, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 4).disabled || null); var currVal_3 = (i1.ɵnov(_v, 4)._animationMode === "NoopAnimations"); _ck(_v, 3, 0, currVal_2, currVal_3); }); }
export function View_SnackBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-snack-bar", [], null, null, null, View_SnackBarComponent_0, RenderType_SnackBarComponent)), i1.ɵdid(1, 49152, null, 0, i6.SnackBarComponent, [i7.MatSnackBarRef, i7.MAT_SNACK_BAR_DATA], null, null)], null, null); }
var SnackBarComponentNgFactory = i1.ɵccf("app-snack-bar", i6.SnackBarComponent, View_SnackBarComponent_Host_0, {}, {}, []);
export { SnackBarComponentNgFactory as SnackBarComponentNgFactory };
