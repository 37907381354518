<span [ngClass]="{'alternate-theme': alternative}">
  <button (click)="emitClick()" *ngIf="dialogClose" [color]="color" class="btn btn--action"
          mat-dialog-close mat-flat-button tabIndex="-1">
    <strong *ngIf="strong">
      <ng-content></ng-content>
    </strong>
    <ng-container *ngIf="!strong">
      <ng-content></ng-content>
    </ng-container>
  </button>

  <button (click)="emitClick()" *ngIf="!dialogClose" [color]="color" class="btn btn--action"
          mat-flat-button tabIndex="-1">
    <strong *ngIf="strong">
      <ng-content></ng-content>
    </strong>
    <ng-container *ngIf="!strong">
      <ng-content></ng-content>
    </ng-container>
  </button>
</span>
