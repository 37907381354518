<!--Company Name-->
<div class="box__item box__item--sm box__item--editable">
  <h2 class="heading--bigger m--0" [class.txt--medium-gray]="isUnderReview"><strong><span [outerHTML]="locationName"></span></strong></h2>
  <span class="under-review" *ngIf="isUnderReview" role="alert">
    <p>Under review</p>
  </span>

  <button mat-button (click)="openNameDialog()" class="btn btn-icon" *ngIf="isEdit">
    <i class="fas fa-pencil m--0"></i></button>
</div>
