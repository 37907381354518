import {Injectable} from '@angular/core';
import {SnackBarComponent} from '../modules/snack-bar/snack-bar.component';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material';

enum SnackType {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning'
}

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  private snack: any;

  constructor(
    private snackBar: MatSnackBar,
  ) {}

  openSuccess(message: string, duration: number) : void {
    this.open(message, duration, 'right', 'top', SnackType.SUCCESS);
  }

  openError(message: string, duration = 4000) : void {
    this.open(message, duration, 'right', 'top', SnackType.ERROR);
  }

  openInfo(message: string, duration: number) : void {
    this.open(message, duration, 'right', 'top', SnackType.INFO);
  }

  openWarning(message: string, duration: number) : void {
    this.open(message, duration, 'right', 'top', SnackType.WARNING);
  }

  open(message: string, duration: number, horizontalPosition: MatSnackBarHorizontalPosition,
       verticalPosition: MatSnackBarVerticalPosition, panelClass: SnackType) {
    this.snack = this.snackBar.openFromComponent(SnackBarComponent, {
      data: {
        message,
        panelClass
      },
      panelClass,
      duration,
      horizontalPosition,
      verticalPosition,
    });
  }
}
