
    <div class="dialog__header txt--center dialog__header--warn"
         [ngClass]="{'dialog__header--warn': data.alertType == 1, 'dialog__header--info': data.alertType == 0, 'dialog__header--error': data.alertType == 2}">
      <h2 mat-dialog-title class="m--0 txt--xl"><strong>Edit Location Meta Details</strong></h2>
    </div>

    <div class="dialog__content" mat-dialog-content>

      <div class="dialog-row pb--0">
        <mat-form-field class="input-field input-field--uppercase">
          <input [(ngModel)]="location.keywordReport" matInput
                 placeholder="Iframe Keyword report">
        </mat-form-field>
      </div>

    </div>
    <div mat-dialog-actions class="dialog__footer">
		  <span class="alternate-theme d-inline-block mr--10">
        <button tabIndex="-1" mat-button color="primary" [mat-dialog-close]="false" (click)="cancel()"
                class="btn btn-cancel">{{ data.closeButtonLabel }}</button>
      </span>
      <button tabIndex="-1" mat-flat-button color="accent" [mat-dialog-close]="true"
              (click)="save()" class="btn btn--action">{{ data.confirmButtonLabel }}</button>
    </div>
  