// TODO: Refactor against modal-bulk-location-info.component.ts

// dep
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatTableDataSource, Sort } from '@angular/material';

// app
import { BulkLocationInfo, COMPONENTS_CORE, COMPONENTS_MISC } from '../../constants/location-info';
import { Pageable } from '../../constants/pageable';
import { AuthService } from '../../services/auth.service';
import { BulkLocationsEditService } from '../../services/bulk-locations-edit.service';
import { GoogleService } from '../../services/google.service';
import { ReportService } from '../../services/report.service';
import { ModalBulkLocationInfoComponent } from '../modal-bulk-location-info/modal-bulk-location-info.component';
import { ModalViewBulkLocationsChangesComponent } from '../modal-view-bulk-locations-changes/modal-view-bulk-locations-changes.component';

@Component({
  selector: 'app-location-info-list',
  templateUrl: './location-info-list.component.html',
  styleUrls:  ['./location-info-list.component.scss']
})
export class LocationInfoListComponent implements OnInit, OnDestroy {
  public accountId: any;
  readonly locationId: string;
  public listCoreComponents = COMPONENTS_CORE;
  public listMiscComponents = COMPONENTS_MISC;
  public listActionLink = [];
  public displayedColumns = ['createDate', 'property', 'locations', 'changes', 'progress', 'status'] as const
  public dataSource = new MatTableDataSource<BulkLocationInfo>([]);
  public pendingBulkLocationExist = false;
  public direction: string;
  public isSpinner = false;
  public progressBulkLocation = true;
  public paginate: Pageable = { page: 1, size: 10 };
  public previousPageable: { size: any; page: any };
  public pagination: any;
  public sort: Sort;
  public selected = new FormControl(0);
  public dateNow = new Date();
  public refreshIntervalStatus: any;
  public last = null;
  public next = null;
  
  @Input() dashboardType: string;

  constructor(
    private _dialog: MatDialog,
    private _reportS: ReportService,
    private _bulkEditService: BulkLocationsEditService,
    private _auth: AuthService,
    private _bulkLocationService: BulkLocationsEditService,
    private _googleS: GoogleService,
  ) { }

  ngOnInit() {
    const day   = this.dateNow.getDate();
    const month = this.dateNow.getMonth();
    const year  = this.dateNow.getFullYear();

    this.dateNow = new Date(year, month, day - 1);

    if (this.previousPageable) {
      if (this.previousPageable.page < this.pagination.page) {
        if (this.dataSource.data) {
          this.last = this.dataSource.data[this.pagination.per_page - 1];
        }
      } else if (this.previousPageable.page > this.pagination.page) {
        if (this.dataSource.data) {
          this.next = this.dataSource.data[0];
        }
      }
    }

    this.getBulkEdits(this.last, this.next);

    this.refreshIntervalStatus = setInterval(() => {
      this.getBulkProgress(false);
    }, 10000)
  }

  ngOnDestroy(): void {
      clearInterval(this.refreshIntervalStatus);
  }

  getBulkEdits(last, next) {
    this._bulkEditService.getByGid(this.paginate, this._auth.session.gid, last, next).subscribe(
      result => {

        this.previousPageable = { size: result.per_page, page: result.page };
        this.pagination = result;
        console.log(this.dataSource.data)
        this.dataSource.data = (result.items || []) as BulkLocationInfo[]
        this.dataSource.data.forEach(el => {
          if (el['progress'] === undefined) {
            el['progress'] = {
              "data": {
                "failed_locations": 0,
                "total_locations": 0,
                "updated_locations": 0
              },
              "message": "get bulkedit status"
            };
          el['createdDate'] = new Date(el['createdAt']);
          }
        });

        this.getBulkProgress(true);

        this.pendingBulkLocationExist = !!(this.dataSource.data.length);
      },
      err => {
        this.progressBulkLocation = false;
      });
  }

  progressValue(element) {
    const d = element.progress.data
    const planned = (d.failed_locations + d.updated_locations)
    const value   = (planned / d.total_locations) * 100;

    return value;
  }


  getBulkProgress(force: boolean): void {
    let count = 0;
    if (this.dataSource.data.length > 0 )
    {
      this.dataSource.data.forEach(el => {
        if (force || el.status == 'PENDING') {
          this._bulkEditService.getBulkProgress(el._id).subscribe(
            res => {
              count ++;
              el['progress'] = res;
              el['progress']['value'] = this.progressValue(el);
              el['status'] = res['data']['bulk_status']['status'];
              if(this.dataSource.data.length == count) {
                this.progressBulkLocation = false;
              }
            },
            err => {
              this.progressBulkLocation = false;
              console.error(err)
            }
          );
        }
      })
    }
    else {
      this.progressBulkLocation = false;
    }
  }

  GetNameProperty(component ): string {
    const componentChanged = component?.component
    if(!componentChanged)
      return ''

    if (!componentChanged.includes("Secondary-")) {
      if (componentChanged !== 'placeActionLinks') {
        const name = this.listCoreComponents.find(l => l.key === componentChanged);
        if (name) 
          return name.value
        else 
          return this.listMiscComponents.find(l => l.key === componentChanged).value;
      } else {
        return `Place Action Links: ${component?.change?.displayName}`
      }
    } else {
      return "Secondary Website URLs"
    }

  }

  create(): void {
    const dialog = this._dialog.open(ModalBulkLocationInfoComponent, { width: '60%'})

    dialog.disableClose = true;

    dialog.afterClosed().take(1).subscribe((bulkSaved: { location_edit: BulkLocationInfo, updates: any }) => {
      if (!bulkSaved) 
        return

      this.getBulkEdits(this.last,this.next);
      switch (bulkSaved.location_edit.component) {
        case 'photos':
          this.proccesMediaData(bulkSaved);
          break;
        case 'service':
          this.proccesServiceList(bulkSaved);
          break;
        case 'menu':
          this.proccesMenuList(bulkSaved)
          break;
        default:
          this._bulkLocationService.processBulkEdit(bulkSaved).then();
          break;
      }
    })
  }

  // TODO: Unused, remove?
  // repeatRequest(element: BulkLocationInfo): void {
  //   switch (element.component) {
  //     case 'photos':
  //       break;
  //     case 'service':
  //       break;
  //     case 'menu':
  //       break;
  //     default:
  //       this.getBulkEdits(this.last,this.next);
  //       this.bulkLocationService.processBulkEdit({ location_edit: element, updates: element.change }).then();  // TODO: then() intention was to await?
  //       break;
  //   }
  // }

  // TODO: refactor proccessMediaData, proccesServiceList, proccesMenuList, too much code repetition
  proccesMediaData(bulkSaved: { location_edit: BulkLocationInfo, updates: any }) {
    const promises: Promise<any>[] = [];
    const bulk_id = bulkSaved.location_edit._id;
    bulkSaved.location_edit.accounts.forEach(account => {
      const gid = account.gid;
      const accountId = account.accountId;
      account.locations.forEach(location => {
        const locationId = location.locationId;
        const data = [];
        bulkSaved.updates.forEach((media) => {
          data.push({
            'media': media,
            'bulk_id': bulk_id['$oid'],
            'action': 'bulk edit'
          });
        });
        promises.push(
          this._googleS.saveBulkMedia(accountId, locationId, data).toPromise()
            .then(() => null)
            .catch(error => ({ gid, accountId, locationId }))
        );
      });
    });

    const updateBulk = { status: 'COMPLETED' };

    Promise.all(promises).then((results) => {
      results = results.filter(Boolean);
      if (results.length > 0) {
        if (bulkSaved.location_edit.accounts.reduce((n, item) => n += item.locations.length, 0) === results.length) {
          updateBulk.status = 'ERROR';
        } else {
          updateBulk.status = 'PARTIAL';
        }
        const ErrorsAccounts = bulkSaved.location_edit.accounts.map(account => {
          const group = results.find(e => e.gid === account.gid && e.accountId === account.accountId);
          if (group && group !== undefined) {
            const locationsErrors: any[] = [];
            const location = account.locations.find(l => l.locationId === group.locationId);
            account.locations = account.locations.filter(l => l.locationId !== group.locationId);
            location['error'] = true;
            locationsErrors.push(location);
            account.locations = [...account.locations, ...locationsErrors];
            return account;
          }
          return account;
        });
        updateBulk['accounts'] = ErrorsAccounts;
      }
      this._bulkEditService.editBulk(bulkSaved.location_edit.gid, bulkSaved.location_edit._id['$oid'], updateBulk).then();
    });


  }

  proccesServiceList(bulkSaved: { location_edit: BulkLocationInfo, updates: any }) {
    const promises: Promise<any>[] = []
    const bulk_id = bulkSaved.location_edit._id;
    bulkSaved.location_edit.accounts.forEach(account => {
      const gid       = account.gid;
      const accountId = account.accountId;
      account.locations.forEach(location => {
        const locationId = location.locationId;
        const data = {
          'serviceList': bulkSaved.updates,
          'bulk_id': bulk_id['$oid'],
          'action': 'bulk edit'
        }
        promises.push(this._googleS.updateBulkServices(accountId, locationId, data).toPromise().then((service) => {
          if (!service) return { gid, accountId, locationId }
          return null
        }).catch(error => ({ gid, accountId, locationId })));
      });
    });

    const updateBulk = { status: 'COMPLETED' }
    Promise.all(promises).then((results) => {
      results = results.filter(Boolean);
      if (results.length > 0) {
        if (bulkSaved.location_edit.accounts.reduce((n, item) => n += item.locations.length, 0) === results.length) {
          updateBulk.status = 'ERROR';
        } else {
          updateBulk.status = 'PARTIAL';
        }
        const ErrorsAccounts = bulkSaved.location_edit.accounts.map(account => {
          const group = results.find(e => e.gid === account.gid && e.accountId === account.accountId);
          if (group && group !== undefined) {
            const locationsErrors: any[] = [];
            const location = account.locations.find(l => l.locationId === group.locationId);
            account.locations = account.locations.filter(l => l.locationId !== group.locationId)
            location['error'] = true;
            locationsErrors.push(location)
            account.locations = [...account.locations, ...locationsErrors]
            return account;
          }
          return account;
        });
        updateBulk['accounts'] = ErrorsAccounts;
      }
      this._bulkEditService.editBulk(bulkSaved.location_edit.gid, bulkSaved.location_edit._id['$oid'], updateBulk).then()
    });

  }

  proccesMenuList(bulkSaved: { location_edit: BulkLocationInfo, updates: any }) {
    const promises: Promise<any>[] = []
    const bulk_id = bulkSaved.location_edit._id;
    bulkSaved.location_edit.accounts.forEach(account => {
      const gid = account.gid;
      const accountId = account.accountId;
      account.locations.forEach(location => {
        const locationId = location.locationId;
        const data = {
          'serviceList': bulkSaved.updates,
          'bulk_id': bulk_id['$oid'],
          'action': 'bulk edit'
        }
        promises.push(this._googleS.updateFoodMenu(accountId, locationId, data).toPromise().then((service) => {
          if (!service) return { gid, accountId, locationId }
          return null
        }).catch(error => ({ gid, accountId, locationId })));
      });
    });

    const updateBulk = { status: 'COMPLETED' }

    Promise.all(promises).then((results) => {
      results = results.filter(Boolean);
      if (results.length > 0) {
        if (bulkSaved.location_edit.accounts.reduce((n, item) => n += item.locations.length, 0) === results.length) {
          updateBulk.status = 'ERROR';
        } else {
          updateBulk.status = 'PARTIAL';
        }
        const ErrorsAccounts = bulkSaved.location_edit.accounts.map(account => {
          const group = results.find(e => e.gid === account.gid && e.accountId === account.accountId);
          if (group && group !== undefined) {
            const locationsErrors: any[] = [];
            const location = account.locations.find(l => l.locationId === group.locationId);
            account.locations = account.locations.filter(l => l.locationId !== group.locationId)
            location['error'] = true;
            locationsErrors.push(location)
            account.locations = [...account.locations, ...locationsErrors]
            return account;
          }
          return account;
        });
        updateBulk['accounts'] = ErrorsAccounts;
      }
      this._bulkEditService.editBulk(bulkSaved.location_edit.gid, bulkSaved.location_edit._id['$oid'], updateBulk).then()
    });
  }




  countLocation(element) {
    return this._reportS.countLocation(element);
  }

  handleReload($event) {
    this.paginate = $event;
    this.ngOnInit();

  }

  selectColor(element: string) {
    switch (element.toLowerCase()) {
      case 'pending':
        return 'primary';
      case 'completed':
        return 'accent';
      case 'partial':
        return 'warn';
      case 'error':
        return 'warn';
    }
  }

  openViewChanges(element: BulkLocationInfo, deleted: boolean = false) {
    const dialogRef = this._dialog.open(ModalViewBulkLocationsChangesComponent, {
      width: '640px',
      data: {
        status: element.status,
        _id: element._id,
        accounts: element.accounts,
        component: element.component,
        deleted: deleted
      }
    })
    dialogRef.disableClose = true;

    dialogRef.afterClosed().subscribe(result => {
        if(result) {
          this.progressBulkLocation = true;
          this.ngOnInit();
        }
    });
  }

  // TODO: Unused, remove?
  // getChangeLog(element) {
  //   this.bulkLocationService.getchangeslogs(element.bulk_id).subscribe(
  //     res => {
  //       this.modalService.openInfoModal(
  //         "Change Logs",
  //         "AGREGAR."
  //       )
  //     },
  //     err => {
  //       this.snackS.openError('There was an error while fetching the data', 2000);
  //     }
  //   )
  // }



}
