import { OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { GoogleService } from '../../services/google.service';
import { ReviewsService } from '../../services/reviews.service';
import { ModalService } from '../../services/modal.service';
var ModalReplyReviewComponent = /** @class */ (function () {
    function ModalReplyReviewComponent(config, googleS, reviewS, dialog, modalS) {
        var _this = this;
        var _a, _b, _c, _d;
        this.config = config;
        this.googleS = googleS;
        this.reviewS = reviewS;
        this.dialog = dialog;
        this.modalS = modalS;
        this.comment = new FormControl('', Validators.required);
        this.confirmDelete = false;
        this.apply = function () {
            if (_this.comment.invalid) {
                return;
            }
            _this.progress = true;
            _this.reviewS.reply(_this.data.accountId, _this.data.locationId, _this.data.reviewId, _this.comment.value).subscribe(function (result) {
                _this.progress = false;
                if (result) {
                    delete _this.data.error;
                    _this.dialog.close(_this.comment.value);
                    return;
                }
                _this.dialog.close(false);
            }, function (err) {
                _this.dialog.close(false);
            });
        };
        this.data = config.data;
        if (this.data.activeDelete) {
            this.activeDelete = true;
        }
        this.googleS.getProfilePhoto(this.data.accountId, this.data.locationId).subscribe(function (result) {
            _this.profilePhoto = result;
        });
        this.reviewer = this.data.reviewer;
        if ((_b = (_a = this.data) === null || _a === void 0 ? void 0 : _a.reviewReply) === null || _b === void 0 ? void 0 : _b.comment) {
            this.comment.setValue((_c = this.data) === null || _c === void 0 ? void 0 : _c.reviewReply.comment);
        }
        else {
            this.comment.setValue((_d = this.data) === null || _d === void 0 ? void 0 : _d.reviewReply);
        }
        if (this.data.reviewReply) {
            this.isEdit = true;
            this.title = 'edit reply';
            this.actionText = 'update';
            return;
        }
        this.title = 'reply review';
        this.actionText = 'reply';
    }
    ModalReplyReviewComponent.prototype.ngOnInit = function () { };
    ModalReplyReviewComponent.prototype.handleConfirmDelete = function () {
        var _this = this;
        this.modalS.openConfirmModal('Delete Reply', '', function (result) {
            if (result) {
                _this.progress = true;
                _this.reviewS.replyDelete(_this.data.accountId, _this.data.locationId, _this.data.reviewId).subscribe(function (res) {
                    _this.progress = false;
                    _this.dialog.close(true);
                }, function (err) {
                    _this.progress = false;
                    _this.dialog.close(false);
                });
            }
        });
    };
    return ModalReplyReviewComponent;
}());
export { ModalReplyReviewComponent };
