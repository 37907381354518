import { __awaiter, __generator } from "tslib";
// dep
import { OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
// app
import { AuthService } from 'src/app/services/auth.service';
import { DomainService } from 'src/app/services/domain.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { WhiteLabelService } from "src/app/services/white-label.service";
import { SessionTraceService } from 'src/app/services/session-trace.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { UserService } from 'src/app/services/user.service';
import { ModalService } from 'src/app/services/modal.service';
import { AlertType } from 'src/app/components/alert.component';
import { environment } from '@environment';
import { MAIL_EXTERNAL_GRADE } from 'src/app/constants/auth';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(domainService, wl, _auth, _router, _snack, _userService, _modalService, _apiSub, _sessionTrace, _route) {
        var _this = this;
        this.domainService = domainService;
        this.wl = wl;
        this._auth = _auth;
        this._router = _router;
        this._snack = _snack;
        this._userService = _userService;
        this._modalService = _modalService;
        this._apiSub = _apiSub;
        this._sessionTrace = _sessionTrace;
        this._route = _route;
        this.loginProgress = false;
        this.blockedDomain = false;
        this.wl.getDynamicWhiteLabel().then(function (data) {
            _this.blockedDomain = data.blockedDomain;
            if (_this.blockedDomain)
                _this._modalService.openAlertModal('Domain Blocked', "Contact your account administrator", AlertType.INFO);
        });
        this._auth.afAuth.authState.pipe(first()).toPromise().then(function (isSignedIn) {
            var _a;
            if (isSignedIn && ((_a = _this._auth.session) === null || _a === void 0 ? void 0 : _a.role) && _this._auth.session.email !== MAIL_EXTERNAL_GRADE) {
                _this._userService.domainValidation(location.hostname, _this._auth.session.gid, _this._auth.session.uid, _this._auth.session.domainSurfing).subscribe(function (res) { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!res['allowLogin']) return [3 /*break*/, 1];
                                this._userService.updateForLogin(this._auth.session.gid, this._auth.session.uid).take(1).subscribe();
                                this._auth.redirectAfterLogin(); // TODO: then() intention is to await?
                                this._snack.openSuccess('You are being automatically logged in using your existing session!', 3000);
                                return [3 /*break*/, 4];
                            case 1:
                                console.debug("Domain validation FAILED");
                                return [4 /*yield*/, this._modalService.openWarningModal('Heads up', "Sorry, we couldn't find your account. Please check your email and password or contact support.")];
                            case 2:
                                _a.sent();
                                return [4 /*yield*/, this._auth.signOut(true, true)];
                            case 3:
                                _a.sent();
                                _a.label = 4;
                            case 4: return [2 /*return*/];
                        }
                    });
                }); }, function (err) {
                    console.log(err);
                });
            }
            else {
                _this._auth.signOut();
            }
            if (!isSignedIn) {
                _this._route.queryParams.subscribe(function (params) { return __awaiter(_this, void 0, void 0, function () {
                    var impersonate_token, err_msg;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                impersonate_token = params.impersonate_token;
                                if (!impersonate_token)
                                    return [2 /*return*/];
                                console.debug("Impersonate login with token=" + impersonate_token);
                                return [4 /*yield*/, this._auth.signOut(false)];
                            case 1:
                                _a.sent();
                                _a.label = 2;
                            case 2:
                                _a.trys.push([2, , 9, 10]);
                                this.loginProgress = true;
                                return [4 /*yield*/, this._auth.signInWithImpersonateToken(impersonate_token)];
                            case 3:
                                err_msg = _a.sent();
                                if (!err_msg) return [3 /*break*/, 6];
                                console.error("Impersonate ERROR: ", err_msg);
                                return [4 /*yield*/, this._modalService.openWarningModal('Error', "Error impersonating: " + err_msg)];
                            case 4:
                                _a.sent();
                                return [4 /*yield*/, this._auth.signOut(true, true)];
                            case 5:
                                _a.sent();
                                return [3 /*break*/, 8];
                            case 6:
                                console.debug("Impersonate OK");
                                return [4 /*yield*/, this._auth.initSession()];
                            case 7:
                                _a.sent(); // TODO: redundant? already done in signInWithImpersonateToken?
                                _a.label = 8;
                            case 8: return [3 /*break*/, 10];
                            case 9:
                                this.loginProgress = false;
                                return [7 /*endfinally*/];
                            case 10: return [2 /*return*/];
                        }
                    });
                }); });
            }
        });
        this.loginForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
            password: new FormControl('', [Validators.required, Validators.minLength(8)])
        });
    }
    LoginComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.env = environment;
                this.hide = true;
                return [2 /*return*/];
            });
        });
    };
    LoginComponent.prototype.signInWithEmailAndPassword = function (event) {
        var _this = this;
        event.preventDefault();
        var _a = this.loginForm.value, email = _a.email, password = _a.password;
        this.loginProgress = true;
        this._auth.signInWithEmailAndPassword(email, password).then(function (res) {
            _this.loginProgress = false;
        }).catch(function (err) {
            _this.loginProgress = false;
            // TODO: Comparison against error string, code smell
            if (err.message === 'registration domain is not the current domain') {
                _this._modalService.openConfirmModal('You can’t login here…', 'You appear to have a valid account but you are logging-in to the wrong domain. Please visit the correct site and try again.', function (_) { return null; }, null, 'Ok');
                return;
            }
            if (err.code === "auth/user-not-found") {
                _this._snack.openError('The email you entered is incorrect or not registered!', 3000);
                return;
            }
            _this._snack.openError('The combination of email/password you entered is incorrect!', 3000);
        });
    };
    LoginComponent.prototype.signInWithGoogle = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        event.preventDefault();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        this.loginProgress = true;
                        return [4 /*yield*/, this._auth.googleLogin()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        err_1 = _a.sent();
                        this.loginProgress = false;
                        // TODO: Comparison against error string, code smell
                        if (err_1.message === 'registration domain is not the current domain') {
                            this._modalService.openConfirmModal('You can’t login here…', 'You appear to have a valid account but you are logging-in to the wrong domain. Please visit the correct site and try again.', function (_) { return null; }, null, 'Ok');
                        }
                        else
                            this._snack.openError('This account could not be found or the popup was closed. You need to register first', 3000);
                        return [3 /*break*/, 5];
                    case 4:
                        this.loginProgress = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    LoginComponent.prototype.goToRegister = function () {
        this._router.navigate(['register']);
    };
    LoginComponent.prototype.goToTermsOfService = function () {
        this.wl.goToTermsOfService();
    };
    LoginComponent.prototype.goToPrivacyPolicy = function () {
        this.wl.goToPrivacyPolicy();
    };
    LoginComponent.prototype.goToPaymentPolicy = function () {
        this.wl.goToPaymentPolicy();
    };
    return LoginComponent;
}());
export { LoginComponent };
