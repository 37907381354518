import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-insights',
  templateUrl: './skeleton-insights.component.html'
})
export class SkeletonInsightsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
