
<div class="spinner-wrapper spinner-wrapper--fixed position--absolute" *ngIf="updatingData">
  <mat-spinner></mat-spinner>
</div>

<ng-container *ngIf="!updatingData">
  <div class="dialog__content dialog__content--height pt--0" mat-dialog-content>
    <div class="filter__header d-flex justify-content-between align-items-center">
      <p class="txt--uppercase"><strong>metrics</strong></p>
      <mat-checkbox name="allChecked" [(ngModel)]="checkedAll" (click)="changeAllChecked(!checkedAll)">Select All
      </mat-checkbox>
    </div>
    <p class="txt--uppercase mt--20">Primary</p>
    <div class="row checkbox-list">
      <ng-container *ngFor="let m of metrics">
        <div class="col-12 col-md-6" *ngIf="!m?.isSecondary">
          <mat-checkbox [(ngModel)]="m.checked" (ngModelChange)="changeChecked()">{{m.name}}</mat-checkbox>
        </div>
      </ng-container>
    </div>

    <ng-container *ngIf="hasSecondary">
      <p class="txt--uppercase mt--20">Secondary</p>
      <div class="row checkbox-list">
        <ng-container *ngFor="let m of metrics">
          <div class="col-12 col-md-6" *ngIf="m?.isSecondary">
            <mat-checkbox [(ngModel)]="m.checked" (ngModelChange)="changeChecked()">{{m.name}}</mat-checkbox>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-container>
