export interface WeekDays {
  closeDay: string
  closeTime: string
  openDay: string
  openTime: string
  open: boolean
}




export const WEEK_DAYS = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
