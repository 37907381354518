/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./calculate-recurring.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./calculate-recurring.component";
var styles_CalculateRecurringComponent = [i0.styles];
var RenderType_CalculateRecurringComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CalculateRecurringComponent, data: {} });
export { RenderType_CalculateRecurringComponent as RenderType_CalculateRecurringComponent };
function View_CalculateRecurringComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " ", " - "])), i1.ɵppd(2, 1), i1.ɵppd(3, 2), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵppd(6, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _co.nextDatesEvent[_v.parent.context.index].start)); var currVal_1 = i1.ɵunv(_v, 1, 1, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), (_co.recurringModel.eventTimeStart ? _co.startTimeDate : _co.timeDate), "hh:mm a")); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent, 0), _co.nextDatesEvent[_v.parent.context.index].end, "MMM dd, yyyy hh:mm a")); _ck(_v, 5, 0, currVal_2); }); }
function View_CalculateRecurringComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" post "]))], null, null); }
function View_CalculateRecurringComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "li", [["class", "mb--0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "txt--underline"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", ": "])), i1.ɵppd(3, 1), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "ml--10 mb--5"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CalculateRecurringComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CalculateRecurringComponent_3)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.postType === "Event") || (_co.postType === "Offer")); _ck(_v, 7, 0, currVal_2); var currVal_3 = ((_co.postType !== "Event") && (_co.postType !== "Offer")); _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.start)); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.postType; _ck(_v, 5, 0, currVal_1); }); }
export function View_CalculateRecurringComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CalculateRecurringComponent_1)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.nextDates; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_CalculateRecurringComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-calculate-recurring", [], null, null, null, View_CalculateRecurringComponent_0, RenderType_CalculateRecurringComponent)), i1.ɵdid(1, 638976, null, 0, i3.CalculateRecurringComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CalculateRecurringComponentNgFactory = i1.ɵccf("app-calculate-recurring", i3.CalculateRecurringComponent, View_CalculateRecurringComponent_Host_0, { recurringModel: "recurringModel", publishNow: "publishNow", duplicateAccept: "duplicateAccept", isEdit: "isEdit", postType: "postType" }, { publishPlanDates: "publishPlanDates" }, []);
export { CalculateRecurringComponentNgFactory as CalculateRecurringComponentNgFactory };
