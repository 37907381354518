// dep
import { Injectable } from '@angular/core';
import { AngularFirestore /*, CollectionReference*/ } from '@angular/fire/firestore';
import { MatPaginator } from '@angular/material';
import firebase from 'firebase/app';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
// import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

// app
import User from '../constants/firestore/user';
import { WhiteLabelService } from './white-label.service';
import { FirestoreService } from './firestore.service';
import { GROUPS, USER } from '../constants/firestore/collections';
// import { Pageable } from '../constants/pageable';
import { SESSION, Session } from "../constants/session";
import { environment as ENV } from '@environment';
import { HEADERS_NO_AUTH } from '../constants/auth'
import { isRunningEmbedded } from '../helpers/utils.helpers';
import { UserFeatures } from '../constants/user-features';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private dataStore: {
    accounts: User[]
  };
  private _users: BehaviorSubject<User[]>;
  public loading = false;
  paginator: MatPaginator;
  // ref: CollectionReference;

  private sessionSource: BehaviorSubject<Session>;
  session: any;

  constructor(
    private http: HttpClient,
    private afs: AngularFirestore,
    private wl: WhiteLabelService,
    private fs: FirestoreService) {
      
    if (!isRunningEmbedded()) {
      const session_json = localStorage.getItem(SESSION) 
      if (!session_json || session_json === 'undefined') {
        localStorage.removeItem(SESSION);
        this.sessionSource = new BehaviorSubject<Session>(null);
      } else {
        this.sessionSource = new BehaviorSubject<Session>(JSON.parse(session_json));
      }
      this.session = this.sessionSource.asObservable();
    }

    this.dataStore = { accounts: [] };
    this._users = new BehaviorSubject<User[]>([]);
  }

  deleteUser(user: any): Observable<Object> {
    return this.http.delete(`${ENV.apiUrl}/v2/groups/${user.gid}/user/${user.uid    }`)
  }

  addUserToGroup(gid, body): Observable<Object> {
    return this.http.post(`${ENV.apiUrl}/v2/groups/${gid}/user`, body)
  }


  updateSession(session: Session) {
    this.sessionSource.next(session);
  }

  get users(): Observable<User[]> {
    return this._users.asObservable();
  }


  setUsers(users: User[]) {
    this._users.next(users);
  }


  // TODO: Unused, remove
  // getByGid(gid) {
  //   return this.afs.collection(GROUPS).doc(gid).collection<User>(USER);
  // }


  getByGidDocId(gid, docId: string) {
    return this.afs.collection(GROUPS).doc(gid).collection(USER).doc<User>(docId);
  }


  // TODO: Unused, remove.
  // getByDocId(docId: string) {
  //   return this.afs.collectionGroup<User>(USER, ref => ref.where('uid', '==', docId)).valueChanges();
  // }

  getUserByUid(uid: string) {
    return this.afs.collectionGroup<User>(USER, ref => ref.where('uid', '==', uid)).get();
  }


  // TODO: Unused, remove
  // getByEmail(email: string) {
  //   return this.afs.collectionGroup<User>(USER, ref => ref.where('email', '==', email)).valueChanges();
  // }


  /**
   * Save in firestore entity User
   * @param user User.ts
   */
  // TODO: Direct FS mutation, this must be replaced with a backend endpoint
  async save(user: User) : Promise<void> {
    await this.afs.collection(GROUPS).doc(user.gid).collection(USER).doc<User>(user.uid).set(user);
  }

  /**
   * Update user entity in firestore
   * @param user User.ts
   */
  // TODO: Direct FS mutation, this must be replaced with a backend endpoint
  altUpdate(user: User) {
    return this.afs.collection(GROUPS).doc<User>(user.gid).collection(USER).doc<User>(user.uid).set(user, { merge: true });
  }

  updateForLogin(gid, uid) {
    if (!gid) {
      return;
    }


    return this.getByGidDocId(gid, uid).get().map(doc => doc.data()).switchMap(user => {
      if (!user) {
        return of(null);
      } else {
        user.lastLogin = firebase.firestore.Timestamp.fromDate(new Date());
        user.lastLoginDomain = this.wl.slugDomain;
        this.altUpdate(user as User).then();  // TODO: then intention is to await? bad
        return this.getByGidDocId(gid, uid).valueChanges();
      }
    });
  }

  /**
   * this method delete user entity in firestore
   * @param docId  this doc is key in firestore
   */
  // TODO: Direct FS mutation, this must be replaced with a backend endpoint
  delete(gid, docId) {
    return this.afs.collection(GROUPS).doc(gid).collection(USER).doc(docId).delete();
  }

  /**
   * this method transform parameters entry in a User Type
   *
   * @param uid             :firestore user id
   * @param email           :firestore email
   * @param displayName     :firestore displayName,
   * @param company         :firestore company
   * @param photoURL        :firestore photo
   * @param gid             :firestore group$ id
   * @param authToken       :jwt Token firebase
   * @param domainSurfing   :default false
   */
  transformUser(uid: string, email: string, displayName: string, company: string, photoURL: string, authToken?: string, gid?: string,
                domainSurfing?: boolean) : User {
    const user: User = {
      uid,
      email,
      displayName,
      company,
      photoURL,
      lastLogin: firebase.firestore.Timestamp.fromDate(new Date()),
      timezone: new Date().getTimezoneOffset(),
      registrationDomain: this.wl.domainUser,
      domainSurfing: false,
      isAI: false,
      isActive: true

    };

    if (gid !== undefined) {
      user.gid = gid;
    }

    if (domainSurfing !== undefined) {
      user.domainSurfing = domainSurfing;
    }

    if (authToken !== undefined) {
      user.authToken = authToken;
    }
    return user;
  }

  loadAll(user: User) {
    this.loading = true;
    const query = this.afs.collection(GROUPS).doc(user.gid).collection(USER, ref => ref.where('gid', '==', user.gid)
      .orderBy('role', 'asc')
      .orderBy('displayName', 'asc')
      .limit(this.paginator.pageSize)).ref;

    this.paginator.pageIndex = 1;

    this.fs.colWithIds$<User>(USER, ref => query).subscribe(data => {
      this.dataStore.accounts = data;
      this._users.next(Object.assign({}, this.dataStore).accounts);
      this.loading = false;
    });
  }

  // TODO: Unused?
  filter(user: User, filter?: any) {
    this.loading = true;
    let query; 
    if (_.has(filter, 'sortBy')) {
      query = this.afs.collectionGroup(USER, ref => ref.where('registrationDomain', 'in', [filter.domain, filter.domain + ":"])
        .orderBy(filter.sortBy, filter.direction));
    } else {
      query = this.afs.collectionGroup(USER, ref => ref.where('registrationDomain', 'in', [filter.domain, filter.domain + ":"])
        .orderBy('email', 'asc'));
    }

    this.afs.collectionGroup(USER, ref => query).get().subscribe(users => {
      const result = [];
      users.docs.forEach(u => {
        result.push(u.data());
      });
      this.dataStore.accounts = result;
      this.paginator.length = this.dataStore.accounts.length;
      this._users.next(Object.assign({}, this.dataStore).accounts);
      this.loading = false;
    });
  }

// TODO: Unused, remove
//
//   getUsersByGroup(groupId: string) {
//     const query = this.afs.collection(GROUPS).doc(groupId).collection(USER, ref => ref.where('gid', '==', groupId)
//       .orderBy('email', 'asc')).ref;
// 
//     return this.fs.colWithIds$<User>(USER, ref => query);
//   }


  // TODO: Direct FS mutation, this must be replaced with a backend endpoint
  updateUser(gid, docId, data: object) {
    return this.afs.collection(GROUPS).doc(gid).collection(USER).doc(docId).update(data);
  }

  get(gid, uid) {
    return this.afs.collection(GROUPS).doc(gid).collection(USER).doc(uid).valueChanges();
  }

  // TODO: Unused, remove
  //
  // countUsers() {
  //   return this.afs.collectionGroup(USER).valueChanges().pipe(map(r => r.length));
  // }
  //
  // getUsers(pageable: Pageable = {
  //   size: 10,
  //   page: 1
  // }, domain, next, prev, order = 'createdAt', direction = 'desc'): Observable<any> {
  //   return this.fs.paginateUsers(USER, domain, order, direction, pageable, next, prev);
  // }
  // 
  // getAllUsers() {
  //   return this.afs.collectionGroup(USER).get().map(query => query.docs.map(doc => doc.data()));
  // }
  // 
  // getUsersPaginate(count, pageable, actions) {
  //   return this.fs.formatPagination(count, pageable, actions);
  // }

  async getEmailIsVerified(user: User) : Promise<boolean> {
    const r = (await this.afs.collection(GROUPS).doc(user.gid).collection(USER).doc(user.uid).get().toPromise()).data()
    return (r.emailVerificationHash === null) || (r.emailVerified !== null)
  }

  getUserFeature(uid : string): Observable <UserFeatures> {
    return this.http.get<UserFeatures>(`${ENV.apiUrl}/v2/users/${uid}/enabled-features`)
  }

  domainValidation(domain : string, gid : string, uid : string, domainSurfing : boolean) : Observable<{allowLogin : boolean}> {
    const body = {
      domain,
      gid,
      uid,
      domainSurfing
    }

    return this.http.post<any>(`${ENV.apiUrl}/v2/auth/domain-validation`, body, HEADERS_NO_AUTH)
  }


}
