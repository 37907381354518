import { __assign, __read, __spread } from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { MatDialog } from "@angular/material/dialog";
import { LocationService } from '../../../services/location.service';
import { LocationEditService } from '../../../services/location-edit.service';
import { ModalRegularHoursComponent } from '../../modal-regular-hours/modal-regular-hours.component';
import { DatesService } from 'src/app/services/dates.service';
var ModalMoreHoursComponent = /** @class */ (function () {
    function ModalMoreHoursComponent(dialogRef, data, locationService, locationEditS, dialog, dateS) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.locationService = locationService;
        this.locationEditS = locationEditS;
        this.dialog = dialog;
        this.dateS = dateS;
        this.moreHours = [];
        this.loading = true;
        this.openPeriods = [];
        this.bulk = false;
        this.validResult = new EventEmitter();
        this.originalOrder = function (a, b) { return 0; };
    }
    ModalMoreHoursComponent.prototype.ngOnInit = function () {
        var _a;
        if (this.bulk) {
            this.getLabels();
        }
        else {
            this.moreHours = JSON.parse(JSON.stringify(((_a = this.data) === null || _a === void 0 ? void 0 : _a.moreHours) || []));
            this.openPeriods = JSON.parse(JSON.stringify(this.moreHours));
            this.labels = this.buildLabels();
            this.loading = false;
        }
    };
    ModalMoreHoursComponent.prototype.getLabels = function () {
        var _this = this;
        var _a = this.bulkLocationSelected[0], gid = _a.gid, accountId = _a.accountId, locationId = _a.locationId;
        this.locationService.getRef(gid, accountId, locationId).subscribe(function (res) {
            var _a, _b, _c;
            _this.labels = ((_a = res.location) === null || _a === void 0 ? void 0 : _a.primaryCategory.moreHoursTypes) || [];
            (_c = (_b = res.location) === null || _b === void 0 ? void 0 : _b.additionalCategories) === null || _c === void 0 ? void 0 : _c.map(function (el) {
                el.moreHoursTypes.forEach(function (h) {
                    var hasLabel = _this.labels.find(function (l) { return l.hoursTypeId == h.hoursTypeId; }) ? true : false;
                    if (!hasLabel) {
                        _this.labels.push(h);
                    }
                });
            });
            _this.loading = false;
        });
    };
    ModalMoreHoursComponent.prototype.buildLabels = function () {
        var _a, _b;
        var labels = ((_a = this.data) === null || _a === void 0 ? void 0 : _a.labels) ? __spread((_b = this.data) === null || _b === void 0 ? void 0 : _b.labels) : __spread(this.labels);
        var usedLabels = this.moreHours.map(function (el) { return el.hoursTypeId; });
        usedLabels.forEach(function (el) {
            var index = labels.findIndex(function (i) { return (i.hoursTypeId).toLowerCase() == el.toLowerCase(); });
            if (index > -1) {
                labels.splice(index, 1);
            }
        });
        return labels;
    };
    ModalMoreHoursComponent.prototype.apply = function () {
        var _this = this;
        var data = this.buildData();
        var hasChanges = this.hasChanges(data);
        if (hasChanges) {
            this.locationEditS.locationEdit.moreHours = data;
            this.locationEditS.setAndUpdate().toPromise().then(function () {
                _this.dialogRef.close(data);
            });
        }
        else {
            this.dialogRef.close(data);
        }
    };
    ModalMoreHoursComponent.prototype.hasChanges = function (data) {
        return JSON.stringify(this.openPeriods) != JSON.stringify(this.moreHours);
    };
    ModalMoreHoursComponent.prototype.buildData = function () {
        var _this = this;
        var data = [];
        this.moreHours.forEach(function (el) {
            var periods = JSON.parse(JSON.stringify(el.formattedPeriods));
            periods = periods.filter(function (period) { return period.open === true; });
            periods.map(function (period) {
                period.openTime = _this.dateS.hours12To24(period.openTime);
                period.closeTime = _this.dateS.hours12To24(period.closeTime);
                delete period.open;
                return period;
            });
            data.push({
                hoursTypeId: el.hoursTypeId,
                periods: periods
            });
        });
        return data;
    };
    ModalMoreHoursComponent.prototype.selectLabel = function (label) {
        var item = __assign({}, label);
        item.periodsByDay = [];
        this.buildPeriods(item);
        this.openHoursDialog(item);
    };
    ModalMoreHoursComponent.prototype.buildPeriods = function (item) {
        item.formattedPeriods = this.locationService.verifyOpen(item.formattedPeriods);
        item.periodsByDay = this.locationService.sortPeriodsByDay(item.formattedPeriods);
    };
    ModalMoreHoursComponent.prototype.validate = function (e) {
        this.validResult.emit(e !== '');
    };
    ModalMoreHoursComponent.prototype.getResult = function () {
        var data = this.buildData();
        if (this.moreHours.length == 0) {
            this.validResult.emit(false);
            return;
        }
        this.validResult.emit(true);
        return { moreHours: data };
    };
    ModalMoreHoursComponent.prototype.openHoursDialog = function (item) {
        var _this = this;
        var dialogRef = this.dialog.open(ModalRegularHoursComponent, {
            width: '680px',
            data: {
                title: item.displayName,
                weekDays: item.formattedPeriods,
                save: false
            }
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                item.formattedPeriods = result;
                _this.buildPeriods(item);
                var isClosed = item.formattedPeriods.find(function (i) { return i.open; }) ? false : true;
                var indexItems = _this.moreHours.findIndex(function (el) { return el.hoursTypeId == item.hoursTypeId; });
                if (isClosed && indexItems > -1) {
                    _this.moreHours.splice(indexItems, 1);
                    _this.moreHours = _this.sortMoreHours();
                    _this.labels = _this.buildLabels();
                }
                if (!isClosed && indexItems == -1) {
                    _this.moreHours.push(item);
                    _this.moreHours = _this.sortMoreHours();
                    _this.labels = _this.buildLabels();
                }
            }
        });
    };
    ModalMoreHoursComponent.prototype.sortMoreHours = function () {
        return this.moreHours.sort(function (a, b) { return a.hoursTypeId < b.hoursTypeId ? -1 : 1; });
    };
    return ModalMoreHoursComponent;
}());
export { ModalMoreHoursComponent };
