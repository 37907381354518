import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-top-metric-carrousel-item',
  templateUrl: './top-metric-carrousel-item.component.html',
  styleUrls: ['./top-metric-carrousel-item.component.scss']
})
export class TopMetricCarrouselItemComponent implements OnInit {
  @Input() metrics;

  name: string;
  arrayMetric: MetricDTO[];

  constructor() {}

  ngOnInit() {
    this.name = this.metrics?.key;
    this.arrayMetric = this.metrics?.value;
  }
}

export interface MetricDTO {
  date: string;
  value: number;
}
