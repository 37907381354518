import { BehaviorSubject } from 'rxjs';
import { LocationService } from './location.service';
import * as i0 from "@angular/core";
import * as i1 from "./location.service";
var LocationEditService = /** @class */ (function () {
    function LocationEditService(locationS) {
        this.locationS = locationS;
        this.locationSubject = new BehaviorSubject(null);
    }
    LocationEditService.prototype.setLocationEdit = function (value, gid, accountId, locationId) {
        this.locationSubject.next(value);
        if (gid) {
            this.gid = gid;
        }
        if (accountId) {
            this.accountId = accountId;
        }
        if (locationId) {
            this.locationId = locationId;
        }
    };
    Object.defineProperty(LocationEditService.prototype, "locationEdit", {
        get: function () {
            return this.locationSubject.getValue();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LocationEditService.prototype, "locationEdit$", {
        get: function () {
            return this.locationSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LocationEditService.prototype, "placeId", {
        get: function () {
            return this.locationSubject.getValue().locationKey.placeId;
        },
        enumerable: true,
        configurable: true
    });
    LocationEditService.prototype.setAndUpdate = function () {
        this.setLocationEdit(this.locationEdit);
        return this.locationS.update(this.gid, this.accountId, this.locationId, { locationEdit: this.locationEdit });
    };
    LocationEditService.prototype.update = function (gid, accountId, locationId, value) {
        return this.locationS.update(gid, accountId, locationId, { locationEdit: value });
    };
    LocationEditService.prototype.isSocialMedia = function (item) {
        return (item.attributeId == 'url_facebook' ||
            item.attributeId == 'url_instagram' ||
            item.attributeId == 'url_linkedin' ||
            item.attributeId == 'url_pinterest' ||
            item.attributeId == 'url_tiktok' ||
            item.attributeId == 'url_twitter' ||
            item.attributeId == 'url_youtube' ?
            true :
            false);
    };
    Object.defineProperty(LocationEditService.prototype, "allSocialMediaOptions", {
        get: function () {
            return [
                { value: 'url_twitter', label: 'Twitter', icon: '/assets/images/social-media/twitter.svg' },
                { value: 'url_facebook', label: 'Facebook', icon: '/assets/images/social-media/facebook.svg' },
                { value: 'url_instagram', label: 'Instagram', icon: '/assets/images/social-media/instagram.svg' },
                { value: 'url_linkedin', label: 'LinkedIn', icon: '/assets/images/social-media/linkedin.svg' },
                { value: 'url_pinterest', label: 'Pinterest', icon: '/assets/images/social-media/pinterest.svg' },
                { value: 'url_tiktok', label: 'TikTok', icon: '/assets/images/social-media/tiktok.svg' },
                { value: 'url_youtube', label: 'YouTube', icon: '/assets/images/social-media/youtube.svg' }
            ];
        },
        enumerable: true,
        configurable: true
    });
    LocationEditService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocationEditService_Factory() { return new LocationEditService(i0.ɵɵinject(i1.LocationService)); }, token: LocationEditService, providedIn: "root" });
    return LocationEditService;
}());
export { LocationEditService };
