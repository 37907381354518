import { __assign, __awaiter, __generator, __read, __spread, __values } from "tslib";
import { HttpClient, HttpParams } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, combineLatest, zip } from 'rxjs';
import { map, take } from 'rxjs/operators';
import moment from 'moment';
import { environment as ENV } from '@environment';
// app
import { LOCATION_SUBSCRIPTION_TYPE } from '../constants/firestore/account-location';
import { ACCOUNTS, GROUPS, LOCATIONS, POST_MANAGEMENT_GROUP, REPORTS, PROTOCOLS, WIDGET_INFO } from '../constants/firestore/collections';
import { Queue } from '../constants/firestore/enqueue';
import { WEEK_DAYS } from '../constants/google/week-days';
import { HoursAmPmPipe } from '../pipes/hours-am-pm.pipe';
import { AuthService } from './auth.service';
import { InsightsService } from './insights.service';
import { ReviewsService } from './reviews.service';
import { PostService } from './post.service';
import { ObservationService } from './observation.service';
import { GoogleService } from './google.service';
import { DatesService } from "../services/dates.service";
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "@angular/common/http";
import * as i4 from "./google.service";
import * as i5 from "./reviews.service";
import * as i6 from "./insights.service";
import * as i7 from "./post.service";
import * as i8 from "./observation.service";
import * as i9 from "./dates.service";
var LocationService = /** @class */ (function () {
    function LocationService(_auth, _afs, _http, _googleS, _reviewsService, _insightsService, _postService, _observationS, _dateS) {
        this._auth = _auth;
        this._afs = _afs;
        this._http = _http;
        this._googleS = _googleS;
        this._reviewsService = _reviewsService;
        this._insightsService = _insightsService;
        this._postService = _postService;
        this._observationS = _observationS;
        this._dateS = _dateS;
        this.loading = false;
        this._locations = null;
        this._location = null;
        this._paginate = new BehaviorSubject({ size: 10, page: 1 });
        /**
         * Will trigger when some location changed (e.g. by an upgrade), to signal
         * that locations should be refreshed.
         */
        this.someLocationChanged = new BehaviorSubject(null);
        this._dataStore = { locations: [], location: null };
        this.reset();
    }
    Object.defineProperty(LocationService.prototype, "locations", {
        get: function () {
            return this._locations.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LocationService.prototype, "location", {
        get: function () {
            return this._location.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    LocationService.prototype.getRegionCode = function (result) {
        var _a, _b, _c, _d, _e, _f;
        return (((_c = (_b = (_a = result) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.address) === null || _c === void 0 ? void 0 : _c.regionCode) || ((_f = (_e = (_d = result) === null || _d === void 0 ? void 0 : _d.location) === null || _e === void 0 ? void 0 : _e.serviceArea) === null || _f === void 0 ? void 0 : _f.regionCode) ||
            navigator.language.split('-')[1]);
    };
    LocationService.prototype.getLocationPaginate = function (gid, accountId, pageable, locationIds) {
        // deprecated
        //
        // const params = new HttpParams()
        //   .set('page', pageable.page.toString())
        //   .set('pageSize', pageable.size.toString())
        //   .set('locationIds', locationIds.join(','));
        // return this._http.get(`${ENV.apiUrl}/v2/locations/${gid}/${accountId}/all`, { params });
        var body = {
            page: pageable.page,
            pageSize: pageable.size,
            locationIds: locationIds
        };
        return this._http.post(ENV.apiUrl + "/v2/locations/" + gid + "/" + accountId + "/all", body);
    };
    LocationService.prototype.getLocationsIdsByStringQuery = function (gid, queryString) {
        return this._http.post(ENV.apiUrl + "/v2/search/gid/" + gid + "/account-locations?query=" + queryString, { 'query': queryString });
    };
    LocationService.prototype.fetchLocation = function (gid, accountId, locationId) {
        return this.getRef(gid, accountId, locationId).toPromise();
    };
    // TODO: move all .getRef(...).toPromise() calls to .fetchLocation(), also change multiple calls to getRef 
    // to a single getMultipleLocations
    LocationService.prototype.getRef = function (gid, accountId, locationId) {
        return this._http.get(ENV.apiUrl + "/v2/locations/" + gid + "/" + accountId + "/" + locationId);
    };
    /**
     * Fetchs all gid locations or only a subset of them (if locationRefs is specified)
     * If fields is specified, only those fields will be fetched, if not, all fields should be.
     */
    // async fetchMultipleLocations<R extends SavedLocation[]>(gid : string, locationRefs? : LocationRef[]) : Promise<R>
    LocationService.prototype.fetchMultipleLocations = function (gid, locationRefs, fields) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._http.post(ENV.apiUrl + "/v2/locations/by-gid/" + gid +
                            (fields ? '?fields=' + fields.join(',') : ''), (locationRefs ? { 'locations': locationRefs } : undefined)).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    LocationService.prototype.getPendingMask = function (gid, accountId, locationId) {
        return this._http.get(ENV.apiUrl + "/v2/locations/" + gid + "/" + accountId + "/" + locationId + "/pendingMask").toPromise();
    };
    LocationService.prototype.getObservations = function (gid, accountId, locationId) {
        return this.getRef(gid, accountId, locationId).pipe(map(function (loc) { var _a; return ((_a = loc) === null || _a === void 0 ? void 0 : _a.googleObservations) || null; }));
    };
    LocationService.prototype.isLocked = function (locationId) {
        return this._http.get(ENV.apiUrl + "/v2/locations/" + locationId + "/locked");
    };
    LocationService.prototype.reset = function () {
        this._locations = new BehaviorSubject([]);
        this._location = new BehaviorSubject(null);
    };
    LocationService.prototype.loadAll = function (user, accountObjectId) {
        var _this = this;
        this.loading = true;
        this.byAccount(user.gid, accountObjectId).subscribe(function (locations) {
            _this._dataStore.locations = locations;
            _this._locations.next(Object.assign({}, _this._dataStore).locations);
            _this.loading = false;
        });
    };
    LocationService.prototype.fetchLocationsExistence = function (locations) {
        return this._http.post(ENV.apiUrl + "/v2/locations/byIds", locations).toPromise();
    };
    LocationService.prototype.getPaginate = function () {
        return this._paginate.asObservable();
    };
    LocationService.prototype.setPaginate = function (paginate) {
        return this._paginate.next(paginate);
    };
    LocationService.prototype.get = function (gid, accountId, locationId) {
        var _this = this;
        this.loading = true;
        var location$ = this.getRef(gid, accountId, locationId);
        location$.subscribe(function (data) {
            _this._dataStore.location = data;
            _this._location.next(Object.assign({}, _this._dataStore).location);
            _this.loading = false;
        });
    };
    LocationService.prototype.saveAll = function (locations, accountObject) {
        return __awaiter(this, void 0, void 0, function () {
            var accountId, requests;
            var _this = this;
            return __generator(this, function (_a) {
                accountId = (typeof accountObject === 'string') ? accountObject : accountObject.accountId;
                requests = locations.map(function (locationObject) { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.save(locationObject, accountId)];
                            case 1: return [2 /*return*/, _a.sent()];
                        }
                    });
                }); });
                return [2 /*return*/, Promise.all(requests)];
            });
        });
    };
    /**
     * Deletes a Location
     * TODO: Integrate with deleteReferencesToLocation
     */
    LocationService.prototype.deleteLocation = function (gid, locationId, accountId) {
        return this._http.delete(ENV.apiUrl + "/v2/locations/" + gid + "/" + accountId + "/" + locationId).toPromise();
    };
    LocationService.prototype.deleteReportLocation = function (locationsToRemove, gid, reportId) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var reportRef, reportDocument, reportData, accountIds, notDuplicatedAccountIds, accountsCopy, _loop_1, notDuplicatedAccountIds_1, notDuplicatedAccountIds_1_1, acc;
            var e_1, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        reportRef = this._afs.collection(GROUPS).doc(gid).collection(REPORTS).doc(reportId);
                        return [4 /*yield*/, reportRef.get().toPromise()];
                    case 1:
                        reportDocument = _c.sent();
                        reportData = reportDocument.data();
                        accountIds = locationsToRemove.map(function (loc) { return loc['accountId']; });
                        notDuplicatedAccountIds = __spread(new Set(accountIds));
                        accountsCopy = __spread((_a = reportData) === null || _a === void 0 ? void 0 : _a.accounts);
                        _loop_1 = function (acc) {
                            var e_2, _a;
                            var reportAccount = accountsCopy.find(function (account) { return account.accountId == acc; });
                            var reportAccountIndex = accountsCopy.indexOf(reportAccount);
                            var locationsAccount = locationsToRemove.filter(function (loc) { return loc['accountId'] == acc; });
                            var locationsIdRemove = locationsAccount.map(function (loc) { return loc['locationId']; });
                            var newReportLocations = [];
                            try {
                                for (var _b = (e_2 = void 0, __values(reportAccount.locations)), _c = _b.next(); !_c.done; _c = _b.next()) {
                                    var loc = _c.value;
                                    var isLocationForRemove = !locationsIdRemove.includes(loc['locationId']);
                                    if (isLocationForRemove)
                                        newReportLocations.push(loc);
                                }
                            }
                            catch (e_2_1) { e_2 = { error: e_2_1 }; }
                            finally {
                                try {
                                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                                }
                                finally { if (e_2) throw e_2.error; }
                            }
                            if (newReportLocations.length > 0) {
                                accountsCopy[reportAccountIndex].locations = newReportLocations;
                            }
                            else {
                                accountsCopy.splice(reportAccountIndex, 1);
                            }
                        };
                        try {
                            for (notDuplicatedAccountIds_1 = __values(notDuplicatedAccountIds), notDuplicatedAccountIds_1_1 = notDuplicatedAccountIds_1.next(); !notDuplicatedAccountIds_1_1.done; notDuplicatedAccountIds_1_1 = notDuplicatedAccountIds_1.next()) {
                                acc = notDuplicatedAccountIds_1_1.value;
                                _loop_1(acc);
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (notDuplicatedAccountIds_1_1 && !notDuplicatedAccountIds_1_1.done && (_b = notDuplicatedAccountIds_1.return)) _b.call(notDuplicatedAccountIds_1);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        reportRef.update({ 'accounts': accountsCopy });
                        return [2 /*return*/];
                }
            });
        });
    };
    // TODO: This should be done on backend side using a single endpoint. 
    // TODO: Some code awaits an observable emission from there but
    // it will resolve immediately without awaiting for real resolution,
    // probably not the intention.
    // TODO: accountId is not part of the args
    // TODO: Usually called AFTER delete() location, should be done before,
    // integrate with deleteLocation()
    LocationService.prototype.deleteReferencesToLocation = function (gid, locationId) {
        var e_3, _a;
        var _this = this;
        var _loop_2 = function (coll) {
            var ref = this_1._afs.collection(GROUPS).doc(gid).collection(coll);
            var obs = ref.snapshotChanges();
            obs.subscribe(function (items) {
                items.map(function (item) {
                    var _a;
                    var data = item.payload.doc.data();
                    var id = item.payload.doc.id;
                    var newData = Object.assign({}, data);
                    _this._analyzeAccounts(newData, locationId);
                    if ((_a = newData.accounts) === null || _a === void 0 ? void 0 : _a.length) {
                        if (newData.hasOwnProperty('accounts') && newData.accounts.length !== data.accounts.length) {
                            ref.doc(id).update(newData);
                        }
                    }
                    else {
                        ref.doc(id).delete();
                    }
                });
            });
        };
        var this_1 = this;
        try {
            for (var _b = __values([POST_MANAGEMENT_GROUP,
                REPORTS,
                PROTOCOLS]), _c = _b.next(); !_c.done; _c = _b.next()) {
                var coll = _c.value;
                _loop_2(coll);
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_3) throw e_3.error; }
        }
    };
    LocationService.prototype._analyzeAccounts = function (item, locationId) {
        if (item.hasOwnProperty('accounts')) {
            var accounts = item.accounts;
            var newAccounts = accounts.filter(function (account) {
                if (account.hasOwnProperty('locations')) {
                    var locations = account.locations;
                    var newLocations = locations.filter(function (location) { return location.locationId !== locationId; });
                    if (newLocations.length !== locations.length) {
                        account.locations = newLocations;
                    }
                    if (newLocations.length !== 0) {
                        return account;
                    }
                }
            });
            if (newAccounts.length !== accounts.length) {
                item.accounts = newAccounts;
            }
            if (newAccounts.length !== 0) {
                return item;
            }
        }
        else {
            return item;
        }
    };
    LocationService.prototype.save = function (locationObject, accountId, gidExternalGrade) {
        return __awaiter(this, void 0, void 0, function () {
            var gid, isExternal, locationId, location, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        gid = gidExternalGrade || this._auth.session.gid;
                        isExternal = !!gidExternalGrade;
                        locationId = locationObject.name.split('/')[3];
                        location = {
                            'locationName': locationObject.locationName,
                            'lockedOn': null,
                            'location': locationObject,
                            'locationId': locationId,
                            'subscriptionType': LOCATION_SUBSCRIPTION_TYPE.FREE,
                            'accountId': accountId,
                            'gid': gid
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this._http.post(ENV.apiUrl + "/v2/locations/add", location).toPromise()];
                    case 2:
                        _a.sent();
                        this._locations.next(Object.assign({}, this._dataStore).locations);
                        this.refreshAll(accountId, locationId, gid, isExternal);
                        return [2 /*return*/, true];
                    case 3:
                        err_1 = _a.sent();
                        console.error('Error saving location', err_1);
                        return [2 /*return*/, false];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    LocationService.prototype.checkLocation = function (gid, accountId, locationId) {
        return this._http.get(ENV.apiUrl + "/v2/locations/check/gid/" + gid + "/account/" + accountId + "/location/" + locationId);
    };
    LocationService.prototype.checkService = function (gid, accountId, locationId) {
        return this._http.get(ENV.apiUrl + "/v2/google/account/" + accountId + "/location/" + locationId + "/service_list");
    };
    LocationService.prototype.saveInsights = function (accountId, locationId) {
        return this._insightsService.saveInsights(accountId, locationId);
    };
    LocationService.prototype.saveReviews = function (accountId, locationId, isExternal) {
        if (isExternal === void 0) { isExternal = false; }
        return this._reviewsService.saveReviews(accountId, locationId, isExternal);
    };
    LocationService.prototype.saveV3Posts = function (gid, accountId, locationId) {
        return this._postService.saveV3All(gid, accountId, locationId);
    };
    LocationService.prototype.saveObservations = function (accountId, locationId) {
        return this._observationS.save(accountId, locationId);
    };
    LocationService.prototype.saveServices = function (accountId, locationId) {
        return this._googleS.saveServices(accountId, locationId);
    };
    LocationService.prototype.saveMenu = function (accountId, locationId) {
        return this._googleS.saveMenu(accountId, locationId);
    };
    LocationService.prototype.update = function (gid, accountId, locationId, data) {
        return this._http.post(ENV.apiUrl + "/v2/locations/" + gid + "/" + accountId + "/" + locationId + "/save", data);
    };
    LocationService.prototype.initLocationEdit = function (gid, accountId, locationId, locationEdit) {
        return this.update(gid, accountId, locationId, { locationEdit: locationEdit });
    };
    LocationService.prototype.organizeServiceList = function (serviceList, categories) {
        var dataSource = categories.map(function (ac) {
            return {
                categoryId: ac.categoryId,
                displayName: ac.displayName,
                principal: ac.primary,
                services: serviceList.reduce(function (result, sl, i) {
                    var _a;
                    if (sl.freeFormServiceItem) {
                        sl.freeFormServiceItem.categoryId = sl.freeFormServiceItem.category;
                        if (sl.freeFormServiceItem.categoryId === ac.categoryId) {
                            result.push({
                                isFreeFormServiceItem: true,
                                positionEdit: i,
                                isOffered: sl.isOffered,
                                serviceTypeId: null,
                                description: sl.freeFormServiceItem.label.description,
                                displayName: sl.freeFormServiceItem.label.displayName,
                                languageCode: sl.freeFormServiceItem.label.displayName,
                                price: sl.price
                            });
                        }
                    }
                    else if (sl.structuredServiceItem) {
                        var serviceType = (_a = ac.serviceTypes) === null || _a === void 0 ? void 0 : _a.find(function (st) { return st.serviceTypeId === sl.structuredServiceItem.serviceTypeId; });
                        if (serviceType) {
                            result.push({
                                isFreeFormServiceItem: false,
                                positionEdit: i,
                                isOffered: sl.isOffered,
                                serviceTypeId: serviceType.serviceTypeId,
                                description: sl.structuredServiceItem.description,
                                displayName: serviceType.displayName,
                                languageCode: null,
                                price: sl.price
                            });
                        }
                    }
                    return result;
                }, [])
            };
        });
        return dataSource;
    };
    LocationService.prototype.convertPriceListType = function (type) {
        type = type.toLowerCase();
        if (type === 'jobs') {
            type = 'Jobs';
        }
        if (type === 'services') {
            type = 'Services';
        }
        if (type === 'menu') {
            type = 'Menu';
        }
        return type;
    };
    LocationService.prototype.getReviewSummary = function (gid, accountsId, locationsIs) {
        var data = {
            "gid": [gid],
            "accountId": accountsId,
            "locationId": locationsIs
        };
        return this._http.post(ENV.apiUrl + "/v2/locations/global-review-summary", data);
    };
    LocationService.prototype.review_summary = function (gid, locations) {
        var _this = this;
        if (!gid) {
            gid = this._auth.session.gid;
        }
        var normalizeSummary = function (rs) {
            if (!rs || !('googleResume' in rs) || !('difference' in rs))
                // missing or broken location review_summary
                return null;
            if ('totalReviewCount' in rs)
                // New format after MAP-172
                return rs;
            // Convert old format to new format
            return {
                difference: rs.difference,
                googleResume: rs.googleResume,
                answered: rs.googleResume.answered,
                notAnswered: rs.googleResume.notAnswered,
                totalReviewCount: rs.googleResume.answered + rs.googleResume.notAnswered,
                averageRating: rs.googleResume.averageRating
            };
        };
        if (locations.length === 1) {
            return this.getRef(gid, locations[0].accountId, locations[0].locationId)
                .pipe(map(function (location) { var _a; return normalizeSummary((_a = location) === null || _a === void 0 ? void 0 : _a.review_summary); }));
        }
        else if (locations.length > 1) {
            var $resumes_1 = [];
            locations.forEach(function (p) {
                $resumes_1.push(_this.getRef(gid, p.accountId, p.locationId)
                    .pipe(map(function (location) { var _a; return normalizeSummary((_a = location) === null || _a === void 0 ? void 0 : _a.review_summary); })));
            });
            return combineLatest($resumes_1);
        }
    };
    LocationService.prototype.refreshAll = function (accountId, locationId, gid, isExternal) {
        if (isExternal === void 0) { isExternal = false; }
        return __awaiter(this, void 0, void 0, function () {
            var call, e_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (ENV.saveLocationInChain) {
                            call = zip(this._saveInChain(accountId, locationId));
                        }
                        else {
                            call = zip(
                            // TODO: All of this must be collapsed to a single backend endpoint
                            this.checkLocation(gid, accountId, locationId), this.checkService(gid, accountId, locationId), this.saveInsights(accountId, locationId), this.saveReviews(accountId, locationId, isExternal), this.saveObservations(accountId, locationId), this.saveV3Posts(gid, accountId, locationId));
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, call.toPromise()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, true];
                    case 3:
                        e_4 = _a.sent();
                        console.error("Error refreshing location locationId=" + locationId, e_4);
                        return [2 /*return*/, false];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    LocationService.prototype.formatAddress = function (address) {
        if (!address)
            return;
        return (address.addressLines !== undefined ? address.addressLines[0] : '--') + " " + (address.locality || '') + ", " + (address.administrativeArea || '') + " " + (address.postalCode || '');
    };
    LocationService.prototype.formatServiceArea = function (serviceArea) {
        var _a, _b;
        if (!serviceArea) {
            return null;
        }
        var placeNames = [];
        (_b = (_a = serviceArea.places) === null || _a === void 0 ? void 0 : _a.placeInfos) === null || _b === void 0 ? void 0 : _b.forEach(function (place) {
            placeNames.push(place.placeName || '--');
        });
        return placeNames.join(' | ');
    };
    LocationService.prototype.verifyOpen = function (periods) {
        var _this = this;
        if (!periods) {
            periods = [];
        }
        periods = JSON.parse(JSON.stringify(periods));
        var periodsResult = [];
        WEEK_DAYS.forEach(function (day) {
            var e_5, _a;
            var currentPeriods = periods.filter(function (period) { return period.openDay === day; });
            try {
                for (var currentPeriods_1 = __values(currentPeriods), currentPeriods_1_1 = currentPeriods_1.next(); !currentPeriods_1_1.done; currentPeriods_1_1 = currentPeriods_1.next()) {
                    var period = currentPeriods_1_1.value;
                    if (period) {
                        var hoursPipe = new HoursAmPmPipe();
                        var openTime = _this._dateS.getStringHours(period.openTime);
                        var closeTime = _this._dateS.getStringHours(period.closeTime);
                        period.openTime = hoursPipe.transform(openTime);
                        period.closeTime = hoursPipe.transform(closeTime);
                        if (period.open !== false) {
                            period.open = true;
                        }
                        periodsResult.push(period);
                    }
                    if (period.open === false) {
                        return;
                    }
                }
            }
            catch (e_5_1) { e_5 = { error: e_5_1 }; }
            finally {
                try {
                    if (currentPeriods_1_1 && !currentPeriods_1_1.done && (_a = currentPeriods_1.return)) _a.call(currentPeriods_1);
                }
                finally { if (e_5) throw e_5.error; }
            }
            if (currentPeriods.length === 0) {
                periodsResult.push({
                    openDay: day,
                    closeDay: day,
                    closeTime: '',
                    openTime: '',
                    open: false
                });
            }
        });
        return periodsResult;
    };
    LocationService.prototype.sortPeriodsByDay = function (periods) {
        return periods.reduce(function (r, a) {
            r[a.openDay] = r[a.openDay] || [];
            r[a.openDay].push(a);
            return r;
        }, {});
    };
    LocationService.prototype.joinByDay = function (periods) {
        var data = {};
        periods.forEach(function (el) {
            var date = el.startDate.day + "/" + el.startDate.month + "/" + el.startDate.year;
            if (Object.keys(data).includes(date)) {
                data[date].push(el);
            }
            else {
                data[date] = [el];
            }
        });
        return this._getContinuousSpecialHours(data);
    };
    LocationService.prototype.getContinuousHours = function (periods) {
        var _this = this;
        var data = [];
        periods.forEach(function (p, i) {
            var _a, _b, _c;
            var day = __assign({}, p);
            var isOpen24 = (day.openTime === '12:00 AM' && day.closeTime === '12:00 AM');
            i = (day.openDay == 'SATURDAY' ? -1 : i);
            var nextDay = periods[i + 1];
            var nextDayIsOpen24 = (nextDay.openTime === '12:00 AM' && nextDay.closeTime === '12:00 AM');
            if (day.closeTime != '' &&
                p.closeTime == ((_a = nextDay) === null || _a === void 0 ? void 0 : _a.openTime) &&
                !isOpen24 &&
                !nextDayIsOpen24 &&
                _this.isBefore1230PM(nextDay.closeTime)) {
                day.closeTime = (_b = nextDay) === null || _b === void 0 ? void 0 : _b.closeTime;
                if (day.openDay == nextDay.openDay || nextDay.openDay == ((_c = periods[i + 2]) === null || _c === void 0 ? void 0 : _c.openDay) || day.openDay == 'SATURDAY') {
                    periods.splice(periods[i + 1], 1);
                }
                else {
                    nextDay.openTime = '';
                    nextDay.closeTime = '';
                    nextDay.open = false;
                }
                if (day.openDay == 'SATURDAY' && p.closeTime == nextDay.openTime) {
                    if (data[1].openDay == 'SUNDAY') {
                        data.splice(0, 1);
                    }
                    else {
                        data[0].openTime = '';
                        data[0].closeTime = '';
                        data[0].open = false;
                    }
                }
            }
            data.push(day);
        });
        return data;
    };
    LocationService.prototype.isBefore1230PM = function (time) {
        return time && (time === '12:00 PM' || time.includes('AM'));
    };
    LocationService.prototype.byAccount = function (gid, accountId) {
        return this._http.get(ENV.apiUrl + "/v2/locations/" + gid + "/" + accountId);
    };
    LocationService.prototype.byAccountGetLocations = function (gid, accountId, data) {
        if (data === void 0) { data = {}; }
        return this._http.post(ENV.apiUrl + "/v2/locations/" + gid + "/" + accountId + "/getLocations", data);
    };
    LocationService.prototype.isAllLocationsUltimate = function (locations) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this._http.post(ENV.apiUrl + "/v2/locations/is-all-ultimate", { locationPaths: locations }).toPromise()];
                    case 1:
                        response = _c.sent();
                        return [2 /*return*/, (_b = (_a = response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.isAllLocationsUltimate];
                }
            });
        });
    };
    LocationService.prototype.basicLocations = function (list) {
        return __awaiter(this, void 0, void 0, function () {
            var accountsLocations;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        accountsLocations = [];
                        return [4 /*yield*/, Promise.all(list.map(function (i) { return __awaiter(_this, void 0, void 0, function () {
                                var location_1, asyncData_1;
                                var _this = this;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!i.accounts) return [3 /*break*/, 6];
                                            if (!(i.accounts.length > 0)) return [3 /*break*/, 2];
                                            return [4 /*yield*/, Promise.all(i.accounts.map(function (account) { return __awaiter(_this, void 0, void 0, function () {
                                                    var exists, locations, asyncData_2;
                                                    return __generator(this, function (_a) {
                                                        switch (_a.label) {
                                                            case 0:
                                                                account.locationsBasics = [];
                                                                exists = accountsLocations.filter(function (al) { return al.accountId === account.accountId; });
                                                                if (!(exists.length === 0)) return [3 /*break*/, 4];
                                                                locations = this.getBasicLocations(i.gid || this._auth.session.gid, account.accountId);
                                                                return [4 /*yield*/, Promise.all([locations])];
                                                            case 1:
                                                                asyncData_2 = _a.sent();
                                                                if (!asyncData_2[0]) return [3 /*break*/, 3];
                                                                return [4 /*yield*/, new Promise(function (resolve) {
                                                                        return asyncData_2[0]
                                                                            .subscribe(function (basicLocations) {
                                                                            var _a;
                                                                            accountsLocations.push((_a = {}, _a[account.accountId] = basicLocations, _a));
                                                                            account.locations.forEach(function (location) {
                                                                                basicLocations.forEach(function (bl) {
                                                                                    if (bl.locationId === location.locationId) {
                                                                                        account.locationsBasics.push(bl);
                                                                                    }
                                                                                });
                                                                            });
                                                                            resolve(basicLocations);
                                                                        });
                                                                    })];
                                                            case 2:
                                                                _a.sent();
                                                                _a.label = 3;
                                                            case 3: return [3 /*break*/, 5];
                                                            case 4: return [2 /*return*/];
                                                            case 5: return [2 /*return*/];
                                                        }
                                                    });
                                                }); }))];
                                        case 1:
                                            _a.sent();
                                            return [3 /*break*/, 6];
                                        case 2:
                                            if (!(i.gid && i.accountId && i.placeId)) return [3 /*break*/, 6];
                                            return [4 /*yield*/, this.getRef(i.gid, i.accountId, i.placeId)];
                                        case 3:
                                            location_1 = _a.sent();
                                            return [4 /*yield*/, Promise.all([location_1])];
                                        case 4:
                                            asyncData_1 = _a.sent();
                                            if (!asyncData_1[0]) return [3 /*break*/, 6];
                                            return [4 /*yield*/, new Promise(function (resolve) {
                                                    return asyncData_1[0]
                                                        .subscribe(function (loc) { return __awaiter(_this, void 0, void 0, function () {
                                                        var asyncAccount;
                                                        return __generator(this, function (_a) {
                                                            switch (_a.label) {
                                                                case 0:
                                                                    i.location = loc.data();
                                                                    return [4 /*yield*/, loc.ref.parent.parent.get()];
                                                                case 1:
                                                                    asyncAccount = _a.sent();
                                                                    i.account = asyncAccount.data();
                                                                    resolve(loc);
                                                                    return [2 /*return*/];
                                                            }
                                                        });
                                                    }); });
                                                })];
                                        case 5:
                                            _a.sent();
                                            _a.label = 6;
                                        case 6: return [2 /*return*/];
                                    }
                                });
                            }); }))];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    LocationService.prototype.getBasicLocations = function (gid, accountId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.byAccount(gid, accountId)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    LocationService.prototype.getByPrimaryCategory = function (gid, accountId, locationId) {
        return this._http.get(ENV.apiUrl + "/v2/locations/" + gid + "/" + accountId + "/" + locationId + "/category");
    };
    LocationService.prototype.saveWidget = function (gid, accountId, locationId, data) {
        return this._afs.collection(GROUPS).doc(gid).collection(ACCOUNTS).doc(accountId)
            .collection(LOCATIONS).doc(locationId).collection(WIDGET_INFO).doc(locationId)
            .set(__assign({}, data), { merge: true });
    };
    LocationService.prototype.deleteWidget = function (gid, accountId, locationId) {
        return this._afs.collection(GROUPS).doc(gid).collection(ACCOUNTS).doc(accountId)
            .collection(LOCATIONS).doc(locationId).collection(WIDGET_INFO).doc(locationId).ref.delete();
    };
    LocationService.prototype.getAccountLocation = function (groupId, account) {
        return __awaiter(this, void 0, void 0, function () {
            var locations, asyncData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        locations = this.byAccount(groupId, account.accountId).pipe(take(1));
                        return [4 /*yield*/, Promise.all([locations])];
                    case 1:
                        asyncData = _a.sent();
                        if (!asyncData[0]) return [3 /*break*/, 3];
                        return [4 /*yield*/, new Promise(function (resolve) {
                                return asyncData[0]
                                    .pipe(take(1))
                                    .subscribe(function (result) {
                                    account.locations = result;
                                    resolve(result);
                                });
                            })];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    LocationService.prototype.getLocationsPaginate = function (count, pageable, actions) {
        return this.formatPagination(count, pageable, actions);
    };
    LocationService.prototype.formatPagination = function (count, pageable, actions) {
        var pages = Math.ceil(count / pageable.size);
        var hasPrev = true;
        var hasNext = true;
        if (pages === pageable.page && pages > 1) {
            hasNext = false;
            hasPrev = true;
        }
        else if (pages === pageable.page && pages === 1) {
            hasNext = false;
            hasPrev = false;
        }
        else if (pageable.page === 1 && pages !== 0) {
            hasPrev = false;
            hasNext = true;
        }
        else if (pageable.page > 1 && pageable.page < pages) {
            hasPrev = true;
            hasNext = true;
        }
        else {
            hasPrev = false;
            hasNext = false;
        }
        return {
            items: actions,
            total: count,
            per_page: pageable.size,
            page: pageable.page,
            pages: pages,
            hasPrev: hasPrev,
            hasNext: hasNext,
        };
    };
    LocationService.prototype.formatDates = function (date) {
        var _a, _b, _c;
        return ((_a = date) === null || _a === void 0 ? void 0 : _a.includes('T')) ? (_b = date) === null || _b === void 0 ? void 0 : _b.split('T')[0] : (_c = date) === null || _c === void 0 ? void 0 : _c.split(' ')[0];
    };
    LocationService.prototype.getDateValidations = function (reportType, accountIds, gids, locationIds) {
        var data = {
            "locationIds": locationIds,
            "accountIds": accountIds,
            "gids": gids,
            "type": reportType
        };
        return this._http.post(ENV.apiUrl + "/v2/locations/date-validation", data);
    };
    LocationService.prototype.dateValidation = function (dates) {
        if (dates === void 0) { dates = {}; }
        var _a, _b;
        var data = { minDate: null, maxDate: null };
        if ((_a = dates) === null || _a === void 0 ? void 0 : _a.maxDate) {
            dates.maxDate = dates.maxDate.split(' ')[0] + "T23:59:59";
            data.maxDate = moment(dates.maxDate);
        }
        if ((_b = dates) === null || _b === void 0 ? void 0 : _b.minDate) {
            dates.minDate = dates.minDate.split(' ')[0] + "T23:59:59";
            data.minDate = moment(dates.minDate);
        }
        return data;
    };
    LocationService.prototype.buildDatepickerDate = function (reportType, maxDate) {
        if (maxDate === void 0) { maxDate = null; }
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var today = moment().subtract(7, 'days');
        var todayStr = today.format('YYYY-MM-DD 23:59:59');
        var endOfMonth = moment().endOf('month').format('YYYY-MM-DD 23:59:59');
        var isFullMonth = todayStr == endOfMonth;
        //Case 1: when the moth is complete
        var start = isFullMonth ? today.clone().subtract(1, 'year') : today.clone().subtract({ months: 1, years: 1 });
        var end = isFullMonth ? today.clone() : today.clone().subtract({ months: 1 });
        //Case 2: if the moth is incomplete
        if (!isFullMonth && (((_a = reportType) === null || _a === void 0 ? void 0 : _a.includes('rollup')) || reportType == 'performance-comparison') && maxDate) {
            // we create a clone of maxDate to prevent mutating the original date which caused SO many issues...
            var maxDateClone = maxDate.clone();
            var maxDateString = (_b = maxDateClone) === null || _b === void 0 ? void 0 : _b.format('YYYY-MM-DD 23:59:59');
            endOfMonth = (_c = maxDateClone) === null || _c === void 0 ? void 0 : _c.endOf('month').format('YYYY-MM-DD 23:59:59');
            isFullMonth = maxDateString == endOfMonth;
            start = isFullMonth ? (_d = maxDateClone) === null || _d === void 0 ? void 0 : _d.clone().subtract(1, 'year') : (_e = maxDateClone) === null || _e === void 0 ? void 0 : _e.clone().subtract({ months: 1, years: 1 });
            end = isFullMonth ? maxDateClone : (_f = maxDateClone) === null || _f === void 0 ? void 0 : _f.clone().subtract({ months: 1 });
        }
        return {
            start: (_g = start) === null || _g === void 0 ? void 0 : _g.startOf('month'),
            end: (_h = end) === null || _h === void 0 ? void 0 : _h.endOf('month')
        };
    };
    LocationService.prototype.deleteServiceArea = function (accounts) {
        var _a;
        (_a = accounts) === null || _a === void 0 ? void 0 : _a.forEach(function (acc) {
            var _a;
            (_a = acc) === null || _a === void 0 ? void 0 : _a.locations.forEach(function (l) {
                var _a;
                if ((_a = Object.keys(l)) === null || _a === void 0 ? void 0 : _a.includes('serviceArea')) {
                    delete l.serviceArea;
                }
            });
        });
        return accounts;
    };
    LocationService.prototype.deleteAddress = function (accounts) {
        var _a;
        (_a = accounts) === null || _a === void 0 ? void 0 : _a.forEach(function (acc) {
            var _a;
            (_a = acc) === null || _a === void 0 ? void 0 : _a.locations.forEach(function (l) {
                var _a;
                if ((_a = Object.keys(l)) === null || _a === void 0 ? void 0 : _a.includes('address')) {
                    delete l.address;
                }
            });
        });
        return accounts;
    };
    LocationService.prototype._getContinuousSpecialHours = function (hours) {
        var keys = Object.keys(hours);
        keys.forEach(function (d) {
            hours[d].forEach(function (h, i) {
                var _a, _b;
                var nextHour = hours[d][i + 1];
                if (h.closeTime == ((_a = nextHour) === null || _a === void 0 ? void 0 : _a.openTime)) {
                    h.closeTime = (_b = nextHour) === null || _b === void 0 ? void 0 : _b.closeTime;
                    hours[d].splice(i + 1, 1);
                }
            });
        });
        return hours;
    };
    LocationService.prototype._saveInChain = function (accountId, locationId) {
        var params = new HttpParams();
        if (ENV.queuesEnabled) {
            params = params.append('enqueue', Queue.COMBINED_EXPRESS);
        }
        return this._http.post(ENV.apiUrl + "/v2/locations/" + accountId + "/" + locationId + "/save", {}, { params: params });
    };
    LocationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocationService_Factory() { return new LocationService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.HttpClient), i0.ɵɵinject(i4.GoogleService), i0.ɵɵinject(i5.ReviewsService), i0.ɵɵinject(i6.InsightsService), i0.ɵɵinject(i7.PostService), i0.ɵɵinject(i8.ObservationService), i0.ɵɵinject(i9.DatesService)); }, token: LocationService, providedIn: "root" });
    return LocationService;
}());
export { LocationService };
