import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dashboard-photos',
  templateUrl: './dashboard-photos.component.html',
  styleUrls: ['./dashboard-photos.component.scss']
})
export class DashboardPhotosComponent implements OnInit {
  accountId: string = '';
  locationId: string = '';
  constructor(private  activateRoute: ActivatedRoute) { }

  ngOnInit() {
    this.locationId = this.activateRoute.snapshot.parent.params.locationId;
    this.accountId = this.activateRoute.snapshot.parent.params.accountId;
  }

}
