<div class="dialog--padding">
    <div class="dialog__header dialog__header--white dialog__header--upgrade">
        <div class="progress-bar">
            <mat-progress-bar
                    color="accent"
                    mode="determinate"
                    [value]="value">
            </mat-progress-bar>
            <div class="steps">
                <div class="steps__item steps__item--complete"><strong>credit card details</strong></div>
                <div class="steps__item" [class.steps__item--complete]="stepReached"><strong>confirm upgrade</strong></div>
            </div>
        </div>
    </div>
    <form [formGroup]="upgradeForm" action="">
        <div class="dialog__content" mat-dialog-content>
            <div class="box box--padding-sm box--border box--upgrade">
                <div [ngClass]="stepComplete ? 'd-none' : ''">
                    <div class="d-flex justify-content-between align-items-center mb--20">
                        <h3 class="txt--uppercase txt--gray"><strong>credit card details</strong></h3>
                        <img src="/assets/images/credit-cards.png" alt="Credit Cards">
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field class="input-group" floatLabel="always" hideRequiredMarker="true">
                                <mat-label class="input-group__label">Name on Card</mat-label>
                                <input matInput class="input-group__field" [formControl]="upgradeForm.controls['name']" required>
                                <mat-error *ngIf="upgradeForm.controls['name'].invalid && upgradeForm.controls['name'].touched">{{getErrorMessage(upgradeForm.controls['name'])}}</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <mat-form-field class="input-group" floatLabel="always" hideRequiredMarker="true">
                                <mat-label class="input-group__label">Card Number</mat-label>
                                <input  matInput class="input-group__field" [formControl]="upgradeForm.controls['cardNumber']" required>
                                <mat-error *ngIf="upgradeForm.controls['cardNumber'].invalid && upgradeForm.controls['cardNumber'].touched">{{getErrorMessage(upgradeForm.controls['cardNumber'])}}</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-4">
                            <mat-form-field class="input-group" floatLabel="always" hideRequiredMarker="true">
                                <mat-label class="input-group__label">Expiration Date</mat-label>
                                <input matInput class="input-group__field" placeholder="MM/YY" [formControl]="upgradeForm.controls['expDate']" required>
                                <mat-error *ngIf="upgradeForm.controls['expDate'].invalid && upgradeForm.controls['expDate'].touched">{{getErrorMessage(upgradeForm.controls['expDate'])}}</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">

                            <mat-form-field class="input-group" floatLabel="always" hideRequiredMarker="true">
                                <mat-label class="input-group__label">CVV</mat-label>
                                <input matInput class="input-group__field" [formControl]="upgradeForm.controls['cvv']" required>
                                <mat-error *ngIf="upgradeForm.controls['cvv'].invalid && upgradeForm.controls['cvv'].touched">{{getErrorMessage(upgradeForm.controls['cvv'])}}</mat-error>
                            </mat-form-field>
                            <div class="tooltip-wrapper">
                                <button mat-icon-button matTooltip="CVV is the 3 digit number located on the back of your card" matTooltipPosition="right" matTooltipClass="tooltip tooltip--white"><i class="fas fa-question-circle txt--gray"></i></button>
                            </div>
                        </div>

                    </div>
                </div>
                <div [ngClass]="!stepComplete ? 'd-none' : ''" class="txt--center">
                    <div class="mb--20">
                        <h3 class="txt--uppercase txt--gray"><strong>click the button below to confirm</strong></h3>
                    </div>
                    <div class="box--preview">
                        <p class="box--preview__row"><span class="info-type"><strong>Card Holder</strong></span><span class="info-details">{{ upgradeForm.controls['name'].value }}</span></p>
                        <p class="box--preview__row"><span class="info-type"><strong>Card number</strong></span><span class="info-details">{{ upgradeForm.controls['cardNumber'].value | slice: -4 }}</span></p>
                        <p class="box--preview__row"><span class="info-type"><strong>Card Expiration</strong></span><span class="info-details">{{ upgradeForm.controls['expDate'].value}}</span></p>
                    </div>
                </div>
            </div>
        </div>
        <div mat-dialog-actions class="dialog__footer">
            <ng-container *ngIf="!stepComplete; else stepTwo">
            <span class="alternate-theme">
                <button mat-button mat-dialog-close tabIndex="-1" class="btn btn-cancel">Cancel</button>
            </span>
                <button mat-flat-button color="accent" tabIndex="-1" (click)="changeStep()" class="btn btn--md">Next</button>
            </ng-container>
            <ng-template #stepTwo>
            <span class="alternate-theme">
                <button mat-button tabIndex="-1" (click)="goBack()" class="btn btn-cancel">Back</button>
            </span>
                <button mat-flat-button color="accent" type="submit" tabIndex="-1" (click)="confirm()" class="btn btn--md">Confirm</button>
            </ng-template>
        </div>
    </form>
</div>

