import { AfterViewChecked, ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ReportService } from '../../services/report.service';
import { AuthService } from '../../services/auth.service';
import { COMPARISON_LIST, COMPARISON_LIST_DEFAULT, DATAPICKER_REPORT_DEFAULT } from '../../constants/firestore/report';
import { DatesService } from '../../services/dates.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { DEFAULT_CHART_DATE_SELECT } from '../../constants/datePicker';
import { WhiteLabelService } from '../../services/white-label.service';
var ModalCreateReportComponent = /** @class */ (function () {
    function ModalCreateReportComponent(reportS, wl, auth, fb, dateS, changeDetector, router, dialogRef, data) {
        var _a;
        this.reportS = reportS;
        this.wl = wl;
        this.auth = auth;
        this.fb = fb;
        this.dateS = dateS;
        this.changeDetector = changeDetector;
        this.router = router;
        this.dialogRef = dialogRef;
        this.data = data;
        this.step = 0;
        this.stepTotal = 0;
        this.rangeBDisabled = true;
        this.dataPicker = DATAPICKER_REPORT_DEFAULT;
        this.compareList = COMPARISON_LIST;
        this.defaultSelected = COMPARISON_LIST_DEFAULT;
        this.metricsChecked = [];
        this.DynamicRangeControl = new FormControl('LastMonth', [Validators.required]);
        this.FrecuencyControl = new FormControl('WeeklyOn', [Validators.required]);
        this.RecipentsControl = new FormControl('', [Validators.email]);
        this.RecipentsEmails = [];
        this.repeatOn = '0';
        this.accountsReport = [];
        if ((_a = this.data) === null || _a === void 0 ? void 0 : _a.data) {
            this.reportType = this.data.data.reportType;
            this.isEdit = !!this.data.data.edit;
            this.stepTotal = 2;
        }
        else {
            this.reportType = this.data.reportType;
            this.isEdit = !!this.data.edit;
            this.stepTotal = 2;
        }
        if (this.data.stepLocations) {
            this.step++;
        }
        if (this.reportType === 'grade') {
            this.dataPicker.rangeB = { start: new Date().toISOString(), end: new Date().toISOString() };
        }
        if (this.isEdit) {
            if (this.data.data) {
                this.reportEdit = this.data.data.edit;
            }
            else {
                this.reportEdit = this.data.edit;
            }
            this.reportEdit = this.reportS.convertReportDate(this.reportEdit);
            this.selectDate = this.reportS.reportSelectDatesFromReport(this.reportEdit);
        }
        else {
            this.selectDate = DEFAULT_CHART_DATE_SELECT;
        }
        this.initForm(this.reportEdit);
    }
    ModalCreateReportComponent.prototype.ngOnInit = function () { };
    ModalCreateReportComponent.prototype.handleDateSelected = function ($event) {
        var dateStart = moment($event.range.start);
        var dateEnd = moment($event.range.end);
        this.startDateTime.setValue(dateStart);
        this.endDateTime.setValue(dateEnd);
    };
    ModalCreateReportComponent.prototype.handleDateBSelected = function ($event) {
        if (this.reportType === 'comparison') {
            this.startDateTimeB.setValue($event.rangeB.start);
            this.endDateTimeB.setValue($event.rangeB.end);
        }
    };
    ModalCreateReportComponent.prototype.changeLockDates = function ($event) {
        this.lockDates.setValue($event.checked);
    };
    ModalCreateReportComponent.prototype.changeDynamicDates = function ($event) {
        this.dynamic.setValue($event.checked);
    };
    ModalCreateReportComponent.prototype.next = function () {
        this.step++;
    };
    ModalCreateReportComponent.prototype.periodChange = function ($event) {
        var startDate = moment(this.startDateTime.value).utc();
        var endDate = moment(this.endDateTime.value).utc();
        switch ($event.value) {
            case 'period':
                this.difference = endDate.diff(startDate, 'days');
                var periodStart = void 0;
                var periodEnd = void 0;
                var years = endDate.diff(startDate, 'years');
                var months = endDate.diff(startDate, 'months') - (years * 12);
                var days = endDate.diff(startDate, 'days');
                if (months && (months + 1) === 12) {
                    periodStart = this.dateS.momentSubtractYearReport(startDate, 1);
                    periodEnd = this.dateS.momentSubtractYearReport(endDate, 1);
                }
                else if (months && months > 0) {
                    if (months === 1) {
                        periodStart = this.dateS.momentSubtractMonthOfReport(startDate, months, true);
                        periodEnd = this.dateS.momentSubtractMonthOfReport(endDate, months);
                    }
                    else {
                        periodStart = this.dateS.momentSubtractMonthReport(startDate, months + 1);
                        periodEnd = this.dateS.momentSubtractMonthReport(endDate, months + 1);
                    }
                }
                else {
                    if (days >= 29) {
                        periodStart = this.dateS.momentSubtractMonthOfReport(startDate, 1, true);
                        periodEnd = this.dateS.momentSubtractMonthOfReport(endDate, 1);
                    }
                    else {
                        periodStart = this.dateS.momentSubtractReport(startDate, days, 'days');
                        periodEnd = this.dateS.momentSubtractReport(endDate, days, 'days');
                    }
                }
                this.dataPicker.rangeB.start = periodStart;
                this.dataPicker.rangeB.end = periodEnd;
                if (this.startDateTimeB) {
                    this.startDateTimeB.setValue(periodStart);
                    this.endDateTimeB.setValue(periodEnd);
                }
                this.period.setValue($event.value);
                break;
            case 'year':
                var yearStart = this.dateS.momentSubtractYearReport(startDate, 1);
                var yearEnd = this.dateS.momentSubtractYearReport(endDate, 1);
                this.dataPicker.rangeB.start = yearStart;
                this.dataPicker.rangeB.end = yearEnd;
                this.startDateTimeB.setValue(yearStart);
                this.endDateTimeB.setValue(yearEnd);
                this.period.setValue($event.value);
                break;
            case 'month':
                this.difference = endDate.diff(startDate, 'days');
                var monthStart = void 0;
                var monthEnd = void 0;
                if (this.difference > 31) {
                    monthStart = this.dateS.momentSubtractMonthReport(startDate, 1);
                    monthEnd = this.dateS.momentSubtractMonthReport(endDate, 1);
                }
                else {
                    monthStart = this.dateS.momentSubtractMonthOfReport(startDate, 1, true);
                    monthEnd = this.dateS.momentSubtractMonthOfReport(endDate, 1, false);
                }
                this.dataPicker.rangeB.start = monthStart;
                this.dataPicker.rangeB.end = monthEnd;
                this.startDateTimeB.setValue(monthStart);
                this.endDateTimeB.setValue(monthEnd);
                this.period.setValue($event.value);
                break;
            default:
                this.rangeBDisabled = false;
                break;
        }
        this.period.setValue($event.value);
    };
    ModalCreateReportComponent.prototype.selectedLocations = function ($event) {
        this.accountsReport = $event;
    };
    ModalCreateReportComponent.prototype.save = function () {
        var _this = this;
        if (this.accountsReport.length == 0) {
            return;
        }
        this.showLoader = true;
        this.formReport = this.form.value;
        var startDatetime = moment(this.startDateTime.value).utc().toISOString();
        var endDatetime = moment(this.endDateTime.value).utc().toISOString();
        if (this.isEdit) {
            this.reportEdit.accounts = this.accountsReport;
            this.reportEdit.startDatetime = startDatetime;
            this.reportEdit.endDatetime = endDatetime;
            this.reportEdit.reportName = this.formReport.reportName;
            this.reportEdit.lockDates = this.formReport.lockDates;
            this.reportEdit.dynamic = this.formReport.dynamic;
            if (this.scheduleReport.value) {
                this.reportEdit = this.configAutomatedReport(this.reportEdit);
                if (!this.reportEdit)
                    return;
                this.reportEdit.scheduleReport = this.formReport.scheduleReport;
            }
            if (this.reportEdit.dynamic) {
                this.reportEdit.dynamicRange = this.DynamicRangeControl.value;
                var dateDynamic = this.reportS.converDynamicDateComparison(this.reportEdit.dynamicRange);
                this.reportEdit.startDatetime = dateDynamic.startDatetime;
                this.reportEdit.endDatetime = dateDynamic.endDatetime;
            }
            delete this.reportEdit['needToUpgrade'];
            delete this.reportEdit['invalidLocations'];
            delete this.reportEdit['duplicatedLocations'];
            this.reportS.update(false, this.reportEdit.gid, this.reportEdit.id, this.reportEdit).then(function () {
                _this.showLoader = false;
                _this.dialogRef.close();
            });
        }
        else {
            this.reportS.getHash().subscribe(function (hashToken) {
                var report = null;
                if (_this.reportType !== 'Review Assistant') {
                    report = {
                        reportName: _this.formReport.reportName,
                        lockDates: _this.formReport.lockDates,
                        dynamic: _this.formReport.dynamic,
                        accounts: _this.accountsReport,
                        reportType: _this.reportType,
                        startDatetime: startDatetime,
                        endDatetime: endDatetime,
                        createdAt: _this.dateS.nowTimestamp,
                        token: hashToken.token,
                        hash: hashToken.hash,
                        uid: _this.auth.session.uid,
                        gid: _this.auth.session.gid,
                        shared: false,
                        sharedOnly: false,
                        scheduleReport: _this.formReport.scheduleReport,
                    };
                    if (_this.scheduleReport.value) {
                        report = _this.configAutomatedReport(report);
                        if (!report)
                            return;
                    }
                    if (_this.dynamic.value) {
                        report.dynamicRange = _this.DynamicRangeControl.value;
                        var dateDynamic = _this.reportS.converDynamicDateComparison(_this.DynamicRangeControl.value);
                        report.startDatetime = dateDynamic.startDatetime;
                        report.endDatetime = dateDynamic.endDatetime;
                    }
                }
                if (_this.reportType === 'Review Assistant') {
                    report = {
                        accounts: _this.accountsReport,
                        reportType: _this.reportType,
                        reportName: _this.formReport.reportName,
                        createdAt: _this.dateS.nowTimestamp,
                        token: hashToken.token,
                        hash: hashToken.hash,
                        uid: _this.auth.session.uid,
                        gid: _this.auth.session.gid,
                        shared: false,
                        sharedOnly: false,
                        scheduleReport: false
                    };
                }
                _this.reportS.save(report.gid, report).then(function (reportSaved) {
                    _this.showLoader = false;
                    _this.dialogRef.close();
                    _this.router.navigateByUrl("/report/" + report.gid + "/" + reportSaved.id).then();
                });
            });
        }
    };
    ModalCreateReportComponent.prototype.configAutomatedReport = function (report) {
        if (!this.FrecuencyControl.valid && this.repeatOn === '' || !this.DynamicRangeControl.valid || this.RecipentsEmails.length === 0) {
            return;
        }
        report.frecuencySelector = this.FrecuencyControl.value;
        report.repeatOn = this.repeatOn;
        report.dynamicRange = this.DynamicRangeControl.value;
        report.recipentsEmails = this.RecipentsEmails;
        report.shared = true;
        report.domain = this.wl.slugDomain;
        var date = moment().utc().toDate(), year = date.getFullYear(), month = date.getMonth(), day = date.getDate(), dayOfWeek = date.getDay();
        if (report.frecuencySelector === 'WeeklyOn') {
            if (dayOfWeek >= Number(report.repeatOn)) {
                var daysToDate = 7 - (dayOfWeek - Number(report.repeatOn));
                report.scheduleDate = moment(new Date(year, month, day + daysToDate)).utc().toISOString();
            }
            else {
                var daysToDate = Number(report.repeatOn) - dayOfWeek;
                report.scheduleDate = moment(new Date(year, month, day + daysToDate)).utc().toISOString();
            }
        }
        else {
            if (day >= Number(report.repeatOn)) {
                report.scheduleDate = moment(new Date(year, month + 1, Number(report.repeatOn))).utc().toISOString();
            }
            else {
                report.scheduleDate = moment(new Date(year, month, Number(report.repeatOn))).utc().toISOString();
            }
        }
        return report;
    };
    ModalCreateReportComponent.prototype.initForm = function (report) {
        if (report) {
            this.accountsReport = report.accounts;
            this.form = this.fb.group({
                reportName: [report.reportName, Validators.required],
                scheduleReport: [report.scheduleReport ? report.scheduleReport : false],
                startDate: [''],
                endDate: [''],
                lockDates: [false],
                dynamic: [false],
            });
            if (report.startDatetime && report.endDatetime) {
                this.dataPicker = {
                    range: { start: report.startDatetime, end: report.endDatetime },
                };
            }
        }
        else {
            this.form = this.fb.group({
                reportName: ['', Validators.required],
                scheduleReport: [false],
                startDate: [''],
                endDate: [''],
                lockDates: [false],
                dynamic: [false],
            });
        }
    };
    ModalCreateReportComponent.prototype.addNewRecipent = function () {
        if (this.RecipentsControl.valid) {
            this.RecipentsEmails.push(this.RecipentsControl.value);
            this.RecipentsControl.setValue('');
        }
    };
    ModalCreateReportComponent.prototype.deleteRecipent = function (email) {
        this.RecipentsEmails = this.RecipentsEmails.filter(function (e) { return e !== email; });
    };
    Object.defineProperty(ModalCreateReportComponent.prototype, "reportName", {
        get: function () {
            return this.form.get('reportName');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalCreateReportComponent.prototype, "scheduleReport", {
        get: function () {
            return this.form.get('scheduleReport');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalCreateReportComponent.prototype, "startDateTime", {
        get: function () {
            return this.form.get('startDate');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalCreateReportComponent.prototype, "endDateTime", {
        get: function () {
            return this.form.get('endDate');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalCreateReportComponent.prototype, "startDateTimeB", {
        get: function () {
            return this.form.get('startDateB');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalCreateReportComponent.prototype, "period", {
        get: function () {
            return this.form.get('period');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalCreateReportComponent.prototype, "endDateTimeB", {
        get: function () {
            return this.form.get('endDateB');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalCreateReportComponent.prototype, "lockDates", {
        get: function () {
            return this.form.get('lockDates');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalCreateReportComponent.prototype, "dynamic", {
        get: function () {
            return this.form.get('dynamic');
        },
        enumerable: true,
        configurable: true
    });
    ModalCreateReportComponent.prototype.selectedMetrics = function ($event) {
        this.metricsChecked = $event;
    };
    ModalCreateReportComponent.prototype.completeSteeper = function () {
        if (this.reportType !== 'comparison') {
            if (this.completeStep1) {
                this.save();
            }
        }
    };
    Object.defineProperty(ModalCreateReportComponent.prototype, "completeStep1", {
        get: function () {
            if (this.scheduleReport.value) {
                if (this.FrecuencyControl.valid && this.repeatOn !== '' && this.DynamicRangeControl.valid && this.RecipentsEmails.length > 0) {
                    // const repeatOnNumber = Number(this.repeatOn);
                    return true;
                }
            }
            else if (this.form.valid) {
                return true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalCreateReportComponent.prototype, "completeStep2", {
        get: function () {
            var _a;
            return ((_a = this.accountsReport) === null || _a === void 0 ? void 0 : _a.length) > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalCreateReportComponent.prototype, "completeStep3", {
        get: function () {
            var _a;
            return ((_a = this.metricsChecked) === null || _a === void 0 ? void 0 : _a.length) > 0;
        },
        enumerable: true,
        configurable: true
    });
    ModalCreateReportComponent.prototype.ngOnDestroy = function () {
        this.accountsReport = [];
        this.metricsChecked = [];
        this.reportEdit = null;
    };
    ModalCreateReportComponent.prototype.ngAfterViewChecked = function () {
        this.changeDetector.detectChanges();
    };
    return ModalCreateReportComponent;
}());
export { ModalCreateReportComponent };
