<!--Company Address-->
<div class="box__item box__item--editable" [class.align-items-start]="address?.addressLines">
  <div class="d-flex align-items-center w--100">
    <div class="icon icon--rounded icon--gray mr--20"><em class="fas fa-map-marker-alt"></em></div>
    <div class="w--100" [class.txt--medium-gray]="isUnderReview">
      <div class="d-flex">
        <gmb-address-outer [address]="address" *ngIf="address?.addressLines?.length > 0" [class.mb--5]="isUnderReview"></gmb-address-outer>
        <span *ngIf="lenguageCode !== ''">, {{lenguageCode}}</span>
      </div>

      <p class="mb--5" *ngIf="(!address || address?.addressLines?.length == 0) && isEdit">Add Address</p>

      <div *ngIf="address?.addressLines" class="w--100 mb--5">
        <ng-container *ngFor="let item of address?.addressLines; let i = index;">
          <p class="m--0 d--block"><span *ngIf="i > 0" [outerHTML]="item"></span></p>
        </ng-container>
      </div>

      <span class="under-review" *ngIf="isUnderReview" role="alert">Under review</span>
    </div>
  </div>
  <button mat-button (click)="openAddressDialog()" class="btn btn-icon btn-icon--gray" *ngIf="isEdit"><em class="fas fa-pencil"></em></button>
</div>
