<table class="table table--sm table--last-row-border bg--white">
  <thead>
  <tr class="mat-row">
    <th class="mat-header-cell txt--left"><strong>Name</strong></th>
    <th class="mat-header-cell txt--left"><strong>Locations</strong></th>
    <th class="mat-header-cell"><strong>Authenticate</strong></th>
    <th class="mat-header-cell txt--right"><strong>Actions</strong></th>
  </tr>
  </thead>
  <tbody>
  <tr class="mat-row" *ngFor="let i of [0,1,2]">

    <td class="mat-cell">
      <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '25px', height: '25px', 'margin': '0 20px 0 0' }"></ngx-skeleton-loader>
      <ngx-skeleton-loader count="1" [theme]="{ width: '150px', height: '25px', 'border-radius': '3px', 'margin': '0' }"></ngx-skeleton-loader>
    </td>

    <td class="mat-cell">
      <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px' }"></ngx-skeleton-loader>
    </td>

    <td class="mat-cell mat-column-authenticate txt--center">
      <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '30px', height: '30px', 'border-radius': '4px' }"></ngx-skeleton-loader>
    </td>

    <td class="mat-cell mat-column-actions txt--right">
      <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '30px', height: '30px', 'margin': '0 15px 0 0', 'border-radius': '5px' }"></ngx-skeleton-loader>
      <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '30px', height: '30px', 'margin-bottom': '0', 'border-radius': '5px' }"></ngx-skeleton-loader>
    </td>
  </tr>
  </tbody>
</table>
