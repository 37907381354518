import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() strong:boolean;
  @Input() color : 'primary'| 'accent' ;
  @Input() alternative: boolean;
  @Input() dialogClose: boolean;


  constructor() { }

  ngOnInit() {
    if (!this.color){
      this.color = "primary";
    }
  }


  emitClick(){
    // this.click.emit()
  }
}
