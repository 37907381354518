<div class="dialog__header">
    <h2 mat-dialog-title class="txt--xl m--0"><strong>{{data.title}}</strong></h2>
</div>
<div class="dialog__content" mat-dialog-content>
    <div>
        <p class="txt--lg">Businesses that use Map Labs:</p>
        <div class="row">
            <div class="col-1 dialog__content__icon">
                <i class="fas fa-map-marker-alt"></i>
            </div>
            <div class="col-11">
                <p class="txt--lg">
                    <strong>Get more customers - </strong>
                    1 billion active monthly Google Maps users are ready to choose your business over the competition.
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-1 dialog__content__icon">
                <i class="fas fa-chart-bar"></i>
            </div>
            <div class="col-11">
                <p class="txt--lg">
                    <strong>Simplify reporting -  </strong>
                    Easily view your Google Business Profile performance. Straightforward, real-world results only.
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-1 dialog__content__icon">
                <i class="fas fa-business-time"></i>
            </div>
            <div class="col-11">
                <p class="txt--lg">
                    <strong>Save time - </strong>
                    Google Maps' native platform is difficult to use. Optimize hundreds of business listings with Map Labs!
                </p>
            </div>
        </div>
    </div>
    <div>
        <div class="dialog__content__green">
            <p class="txt--lg m--0">{{data.message}}</p>
<!--             
                <div class="row">
                <div class="col-6">
                    <span>{{locations_qty}}</span>
                    <span>profiles</span>
                </div>
                <div class="col-6">
                    <strong>${{price}}/mo</strong>
                    <span>per location</span>
                </div>
            </div>
-->
        </div>
    </div>    
</div>
<div mat-dialog-actions class="dialog__footer m--0">
    <span class="alternate-theme d-inline-block mr--10">
        <button mat-button tabIndex="-1" color="primary" class="btn btn-cancel" (click)="close()" >{{data.buttons['cancel_text']}}</button>
    </span>
    <button mat-flat-button tabIndex="-1" color="accent" class="btn btn--action" (click)="accept_cta()" ><i [ngClass]="data.buttons['accept_icon']"></i>{{data.buttons['accept_text']}}</button>
</div>