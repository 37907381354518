import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-grade-explain-section',
  templateUrl: './grade-explain-section.component.html',
  styleUrls: ['./grade-explain-section.component.scss']
})
export class GradeExplainSectionComponent implements OnInit {
  @Input() title: any;

  constructor() { }

  ngOnInit() {
  }

}
