
<div class="box box--padding-sm box--shadow box--rounded bg--white" *ngIf="gradeSection && gradeSection?.items; else noGradeSection">
  <div class="d-flex justify-content-between align-items-center">
    <h2 class="txt--black d-flex align-items-center"><strong>{{gradeSection?.name}}</strong>
      <span matTooltip="{{information}}"
            matTooltipPosition="above"
            matTooltipClass="mat-tooltip--override"
            class="mat-tooltip tooltip tooltip--info">
        <i class="fas fa-info"></i>
      </span>
    </h2>
    <div class="box-statistics">
      <p class="txt--gray fw--medium">{{ countPercent(gradeSection?.total, gradeSection?.max) }}% <span class="txt--black txt--md"><strong>({{ gradeSection?.total || 0 }}/{{ gradeSection?.max || 0 }})</strong></span></p>
    </div>
  </div>
  <mat-progress-bar color="accent" mode="determinate" value="{{ countPercent(gradeSection?.total, gradeSection?.max) }}"></mat-progress-bar>

    <!--TODO: if order column show them in two separate columns (need to be divided to Photos and Posts-->
    <div *ngIf="order !== 'column'; else reorder">
        <div class="tag-wrapper">
            <app-grade-item [gradeGoogleItem]="item"  *ngFor="let item of gradeSection?.items"></app-grade-item>
        </div>
    </div>
    <ng-template #reorder>
        <div class="row tag-wrapper">
            <div class="col-12 col-md-5 tag-divider">
                <p class="txt--sm fw--medium txt--gray txt--uppercase">Photos</p>
                <app-grade-item [gradeGoogleItem]="item"  *ngFor="let item of gradeSection?.items"></app-grade-item>
            </div>
            <div class="col-12 col-md-7">
                <p class="txt--sm fw--medium txt--gray txt--uppercase">Content</p>
                <app-grade-item [gradeGoogleItem]="item"  *ngFor="let item of gradeSection?.items"></app-grade-item>
            </div>
        </div>
    </ng-template>
</div>

<ng-template #noGradeSection>
  <div class="box box--padding-sm box--shadow box--rounded bg--white">
    <div class="d-flex justify-content-between align-items-center">
      <h2 class="txt--black d-flex align-items-center"><strong>{{gradeSection?.name}}</strong></h2>
      <div class="box-statistics">
        <p class="txt--gray fw--medium">{{ countPercent(gradeSection?.total, gradeSection?.max) }}% <span class="txt--black txt--md"><strong>({{ gradeSection?.total || 0 }}/{{ gradeSection?.max || 0 }})</strong></span></p>
      </div>
    </div>
    <mat-progress-bar color="accent" mode="determinate" value="0"></mat-progress-bar>
    <div>
      <div class="tag-wrapper justify-content-center align-items-center">
        <span class="tag__content"> No Data Available </span>
      </div>
    </div>
  </div>
</ng-template>
