import { __assign, __awaiter, __generator, __read, __spread, __values } from "tslib";
import { OnInit, OnDestroy, EventEmitter, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { SAVE_COMPLETE_SCAN, SAVE_COMPLETE_EDIT_SCAN, SAVE_COMPLETE_PROTOCOL, SAVE_COMPLETE_EDIT_PROTOCOL } from '../../constants/firestore/protocol';
import { Subject, BehaviorSubject, of } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { ProtocolService } from '../../services/protocol.service';
import { LocationService } from '../../services/location.service';
import { AuthService } from '../../services/auth.service';
import { ReportService } from '../../services/report.service';
import { ModalService } from '../../services/modal.service';
import { SnackbarService } from '../../services/snackbar.service';
import { filter, takeUntil, take } from 'rxjs/operators';
import { AlertType } from '../../components/alert.component';
import { ModalDeleteComponent } from '../modal-delete/modal-delete.component';
import { ModalCreateProtocolComponent } from '../modal-create-protocol/modal-create-protocol.component';
import * as _ from 'lodash';
var ProtocolListComponent = /** @class */ (function () {
    function ProtocolListComponent(protocolS, locationS, auth, reportS, modalService, changeDetector, snack) {
        this.protocolS = protocolS;
        this.locationS = locationS;
        this.auth = auth;
        this.reportS = reportS;
        this.modalService = modalService;
        this.changeDetector = changeDetector;
        this.snack = snack;
        this.displayedColumns = ['id', 'name', 'status', 'triggers', 'enable', 'retroactive', 'action-btn'];
        this.ColumnsSize = [5, 30, 15, 15, 10, 15, 10];
        this.isScan = false;
        this.dataSource = new MatTableDataSource([]);
        this.destroyCoreSubscriber$ = new Subject();
        this.NotFoundSearch$ = new BehaviorSubject(false);
        this.protocolsExist = false;
        this.progress = new BehaviorSubject(false);
        this.last = null;
        this.next = null;
        this.filtered = false;
        this.searchText = '';
        this.loadingEnabled = false;
        // custom pagination with page numbers
        this.page = 1;
        this.selectionProtocol = new SelectionModel(true, []);
        this.size = 10;
        this.paginate = { size: this.size, page: this.page };
        this.previousPageable = { page: 1, size: 10 };
        this.tmpPaginate = { size: this.size, page: this.page };
        this.tmpPreviousPageable = { page: 1, size: 10 };
        this.ElementChecks = of({ enable: false, retroactive: false });
        this.pagination = {
            items: [],
            per_page: this.paginate.size,
            page: 1,
            hasNext: false,
            hasPrev: false,
            pages: 0,
            total: 0
        };
        this.selectedLogs = 0;
        this.selectedLogsEmit = new EventEmitter();
        // TimeOut search
        this.timeout = null;
    }
    ProtocolListComponent.prototype.ngOnDestroy = function () {
        this.destroyCoreSubscriber$.next(true);
        this.destroyCoreSubscriber$.unsubscribe();
    };
    ProtocolListComponent.prototype.isDisplayField = function (name) {
        return this.displayedColumns.some(function (d) { return d === name; });
    };
    ProtocolListComponent.prototype.getSizeColumn = function (name) {
        var index = this.displayedColumns.indexOf(name);
        if (index >= 0) {
            return this.ColumnsSize[index] + "%";
        }
    };
    ProtocolListComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.progress.next(true);
                        return [4 /*yield*/, this.refresh()];
                    case 1:
                        _a.sent();
                        this.getData(this.paginate);
                        this.manualPage = 1;
                        this.errorMessage = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    ProtocolListComponent.prototype.refresh = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.protocolS.get_all_flex(this.auth.session.gid, this.isScan).then(function (res) {
                            _this.searchArrayProtocols = res;
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProtocolListComponent.prototype.getData = function (paginate, refresh) {
        var _this = this;
        if (refresh === void 0) { refresh = false; }
        this.progress.next(true);
        if (this.previousPageable) {
            if (this.previousPageable.page === paginate.page) {
                this.last = null;
            }
            else if (this.previousPageable.page < paginate.page) {
                if (this.dataSource.data) {
                    var selector = (paginate.size * (paginate.page - 1)) - 1;
                    this.next = this.searchArrayProtocols[selector];
                    this.last = null;
                }
            }
            else if (this.previousPageable.page > paginate.page) {
                if (this.searchArrayProtocols) {
                    var selector = (paginate.size * paginate.page);
                    this.last = this.searchArrayProtocols[selector];
                    this.next = null;
                }
            }
        }
        else {
            this.next = null;
            this.last = null;
        }
        this.protocolS.paginate(this.auth.session.gid, paginate, this.next, this.last, true, { field: "scan", operator: "==", value: this.isScan })
            .pipe(takeUntil(this.destroyCoreSubscriber$), take(3))
            .subscribe(function (result) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            var _this = this;
            return __generator(this, function (_a) {
                if (!this.filtered && !refresh) {
                    if (!this.auth.isAdmin) {
                        result.items = this.filterByLocationMember(result.items);
                    }
                    this.protocols = result.items;
                    this.pagination = result;
                    this.previousPageable = { size: result.per_page, page: result.page };
                    this.protocols = this.protocols.map(function (p) {
                        if (!p.enabled) {
                            if (p.retroactive) {
                                p.retroactive = false;
                                _this.protocolS.update(p, p.protocolId);
                            }
                        }
                        return p;
                    });
                    data = this.protocols.filter(function (el) { var _a, _b; return _this.selectedLogs == 3 ? (_a = el) === null || _a === void 0 ? void 0 : _a.scan : !((_b = el) === null || _b === void 0 ? void 0 : _b.scan); });
                    this.dataSource = new MatTableDataSource(data);
                    this.protocolsExist = this.dataSource.data.length > 0;
                    this.progress.next(false);
                }
                return [2 /*return*/];
            });
        }); }, function (err) { return _this.progress.next(false); });
    };
    ProtocolListComponent.prototype.filterByLocationMember = function (items) {
        var e_1, _a;
        var _b, _c;
        var user = this.auth.session;
        var memberLocations = [];
        (_c = (_b = user) === null || _b === void 0 ? void 0 : _b.accounts) === null || _c === void 0 ? void 0 : _c.forEach(function (account) {
            account.locations.forEach(function (location) {
                memberLocations.push(location.locationId);
            });
        });
        var validReviewAssistance = [];
        var _loop_1 = function (item) {
            var e_2, _a;
            var reportLocations = [];
            if (!_.isEmpty(item.accounts)) {
                try {
                    for (var _b = (e_2 = void 0, __values(item.accounts)), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var account = _c.value;
                        account.locations.forEach(function (location) {
                            reportLocations.push(location.locationId);
                        });
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
                if (reportLocations.every(function (locationId) { return memberLocations.includes(locationId); })) {
                    validReviewAssistance.push(item);
                }
            }
        };
        try {
            for (var items_1 = __values(items), items_1_1 = items_1.next(); !items_1_1.done; items_1_1 = items_1.next()) {
                var item = items_1_1.value;
                _loop_1(item);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (items_1_1 && !items_1_1.done && (_a = items_1.return)) _a.call(items_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return validReviewAssistance;
    };
    // apply filter from search
    ProtocolListComponent.prototype.applyFilter = function ($event, key) {
        var _this = this;
        this.NotFoundSearch$.next(false);
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
            if (!$event || $event === '') {
                _this.filtered = false;
                _this.progress.next(true);
                _this.paginate = _this.tmpPaginate;
                _this.previousPageable = _this.tmpPreviousPageable;
                _this.getData(_this.paginate);
                _this.searchText = '';
            }
            else {
                if ($event[$event.length - 1] !== ' ') {
                    if (!_this.filtered)
                        _this.initPaginate();
                    _this.filtered = true;
                    _this.progress.next(true);
                    var text_1 = $event.toLowerCase().trim();
                    _this.searchText = text_1;
                    var search = _this.searchArrayProtocols.filter(function (s) { return s.name.trim().toLowerCase().indexOf(text_1) > -1; });
                    _this.setData(search);
                }
            }
        }, 350);
    };
    // TODO: Unused, remove.
    // deleteFromSearchArray(id): void {
    //   this.searchArrayProtocols= this.searchArrayProtocols.filter( p => {
    //     return p.protocolId !== id
    //   });
    // }
    // check for nested objects
    ProtocolListComponent.prototype.nestedFilterCheck = function (search, data, key) {
        if (typeof data[key] === 'object') {
            for (var k in data[key]) {
                if (data[key][k] !== null) {
                    search = this.nestedFilterCheck(search, data[key], k);
                }
            }
        }
        else {
            search += data[key];
        }
        return search;
    };
    ProtocolListComponent.prototype.isAllSelectedProtocol = function () {
        var numSelected = this.selectionProtocol.selected.length;
        var numRows = this.dataSource.data.length;
        return numSelected === numRows;
    };
    ProtocolListComponent.prototype.masterToggleProtocol = function () {
        var _this = this;
        this.isAllSelectedProtocol() ?
            this.selectionProtocol.clear() :
            this.dataSource.data.forEach(function (row) { return _this.selectionProtocol.select(row); });
    };
    ProtocolListComponent.prototype.checkboxLabelProtocol = function (row) {
        if (!row) {
            return (this.isAllSelectedProtocol() ? 'select' : 'deselect') + " all";
        }
        return (this.selectionProtocol.isSelected(row) ? 'deselect' : 'select') + " row " + (row.protocolId + 1);
    };
    ProtocolListComponent.prototype.progressValue = function (element) {
        return (element.status.replied / element.status.total) * 100;
    };
    ProtocolListComponent.prototype.toggleValue = function (element) {
        return __awaiter(this, void 0, void 0, function () {
            var title, message, accounts, protocol_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!element.enabled) return [3 /*break*/, 4];
                        this.loadingEnabled = true;
                        if (!!element.retroactive) return [3 /*break*/, 1];
                        title = 'Activate Retroactively';
                        message = 'You are about to run this protocol retroactively. This will schedule a reply for every matching review will begin replying within the next few hours.';
                        this.modalService.openConfirmModal(title, message, function (confirm) { return __awaiter(_this, void 0, void 0, function () {
                            var accounts, protocol, check_protocols;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!confirm) return [3 /*break*/, 3];
                                        element.retroactive = !element.retroactive;
                                        accounts = element.accounts.map(function (account) {
                                            if ('locationsBasics' in account)
                                                delete account.locationsBasics;
                                            return account;
                                        });
                                        protocol = __assign(__assign({}, element), { accounts: accounts });
                                        return [4 /*yield*/, this.protocolS.update(protocol, protocol.protocolId)];
                                    case 1:
                                        _a.sent();
                                        this.protocols = __spread(this.dataSource.data).map(function (dt) {
                                            if (dt.protocolId === element.protocolId) {
                                                dt.retroactive = element.retroactive;
                                            }
                                            return dt;
                                        });
                                        this.dataSource = new MatTableDataSource(this.protocols);
                                        return [4 /*yield*/, this.protocolS.check_protocol(element.gid, element.protocolId)];
                                    case 2:
                                        check_protocols = _a.sent();
                                        if (!check_protocols) {
                                            this.snack.openError('Failed to activate retroactive', 3500);
                                        }
                                        if (this.filtered)
                                            this.reloadFilter();
                                        _a.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); }, AlertType.INFO);
                        return [3 /*break*/, 3];
                    case 1:
                        element.retroactive = !element.retroactive;
                        accounts = element.accounts.map(function (account) {
                            if ('locationsBasics' in account)
                                delete account.locationsBasics;
                            return account;
                        });
                        protocol_1 = __assign(__assign({}, element), { accounts: accounts });
                        return [4 /*yield*/, this.protocolS.update(protocol_1, protocol_1.protocolId)];
                    case 2:
                        _a.sent();
                        this.dataSource.data = __spread(this.dataSource.data).map(function (dt) {
                            if (protocol_1.protocolId == dt.protocolId) {
                                dt.retroactive = element.retroactive;
                            }
                            return dt;
                        });
                        if (this.filtered)
                            this.reloadFilter();
                        _a.label = 3;
                    case 3:
                        this.loadingEnabled = false;
                        return [3 /*break*/, 5];
                    case 4:
                        this.snack.openInfo('The protocol must be turned on before activating the retroactive feature.', 3000);
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ProtocolListComponent.prototype.newProtocol = function (isEdit, element) {
        var _this = this;
        if (element === void 0) { element = null; }
        var config = {
            width: '540px',
            data: {}
        };
        config.data.scan = this.isScan;
        if (isEdit) {
            config.data.edit = true;
            // element.enabled = false;
            var accounts = element.accounts.map(function (account) {
                if ('locationsBasics' in account)
                    delete account.locationsBasics;
                return account;
            });
            var protocol = __assign(__assign({}, element), { accounts: accounts });
            protocol.retroactive = false;
            config.data.protocol = protocol;
        }
        var modal = this.modalService.openGenericModal(ModalCreateProtocolComponent, config.data, function () { return null; }, config.width);
        modal.disableClose = true;
        var subscriptionManager$ = new Subject();
        modal.componentInstance.completeAll
            .pipe(takeUntil(subscriptionManager$))
            .subscribe(function (complete) {
            if (complete === SAVE_COMPLETE_SCAN || complete === SAVE_COMPLETE_EDIT_SCAN) {
                if (_this.selectedLogs !== 3)
                    _this.selectedLogs = 3;
            }
            else if (complete === SAVE_COMPLETE_PROTOCOL || complete === SAVE_COMPLETE_EDIT_PROTOCOL) {
                if (_this.selectedLogs !== 0)
                    _this.selectedLogs = 0;
            }
            _this.selectedLogsEmit.emit(_this.selectedLogs);
            if (_this.filtered && isEdit) {
                _this.reloadFilter();
            }
            else {
                _this.handleReload(_this.paginate);
            }
            subscriptionManager$.next(true);
            subscriptionManager$.unsubscribe();
        });
    };
    ProtocolListComponent.prototype.toggleEnable = function (event, i, element) {
        return __awaiter(this, void 0, void 0, function () {
            var accounts, protocol;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // this.progress.next(true)
                        /*element.enabled = event.checked;
                        if (!element.enabled) {
                          element.retroactive = false;
                        }*/
                        this.loadingEnabled = true;
                        accounts = element.accounts.map(function (account) {
                            if ('locationsBasics' in account)
                                delete account.locationsBasics;
                            return account;
                        });
                        protocol = __assign(__assign({}, element), { enabled: event.checked, retroactive: element.enabled ? element.retroactive : false, accounts: accounts });
                        return [4 /*yield*/, this.protocolS.update(protocol, element.protocolId)];
                    case 1:
                        _a.sent();
                        this.protocols = __spread(this.dataSource.data).map(function (dt) {
                            if (dt.protocolId === element.protocolId) {
                                dt.enabled = event.checked;
                                dt.retroactive = element.enabled ? element.retroactive : false;
                            }
                            return dt;
                        });
                        if (this.filtered)
                            this.reloadFilter();
                        this.dataSource = new MatTableDataSource(this.protocols);
                        if (protocol.enabled) {
                            this.protocolS.check_protocol(element.gid, element.protocolId).then(function (check_protocols) {
                                if (!check_protocols) {
                                    _this.snack.openError('Failed to activate the protocol', 3500);
                                }
                                _this.loadingEnabled = false;
                            });
                        }
                        else {
                            this.protocolS.delete_reviews_to_reply(element.gid, element.protocolId).then();
                            this.loadingEnabled = false;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ProtocolListComponent.prototype.delete = function (element) {
        var _this = this;
        var data = { name: element.name };
        var dialog = this.modalService.openGenericModal(ModalDeleteComponent, data, function () { }, 680);
        dialog.disableClose = true;
        var destoyModal$ = new Subject();
        dialog.componentInstance.deleted.pipe(filter(function (r) { return r; }), takeUntil(destoyModal$)).subscribe(function () {
            _this.protocolS.delete(element.protocolId).then(function () {
                dialog.componentInstance.complete();
                if (_this.filtered) {
                    _this.reloadFilter();
                }
                else {
                    _this.handleReload(_this.paginate);
                }
                setTimeout(function () {
                    destoyModal$.next(true);
                    destoyModal$.unsubscribe();
                    dialog.close();
                }, 450);
            });
        });
    };
    ProtocolListComponent.prototype.countLocations = function (element) {
        return this.reportS.countLocation(element);
    };
    ProtocolListComponent.prototype.reloadFilter = function () {
        return __awaiter(this, void 0, void 0, function () {
            var search;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.refresh()];
                    case 1:
                        _a.sent();
                        search = this.searchArrayProtocols.filter(function (s) { return s.name.trim().toLowerCase().indexOf(_this.searchText) > -1; });
                        this.setData(search);
                        return [2 /*return*/];
                }
            });
        });
    };
    ProtocolListComponent.prototype.handleReload = function ($event) {
        if ($event === void 0) { $event = this.paginate; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.progress.next(true);
                        this.paginate = $event;
                        if (!this.filtered) return [3 /*break*/, 1];
                        this.applyFilter(this.searchText, 'Enter');
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.refresh()];
                    case 2:
                        _a.sent();
                        this.getData($event);
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProtocolListComponent.prototype.initPaginate = function () {
        this.tmpPaginate = this.paginate;
        this.tmpPreviousPageable = this.previousPageable;
        this.paginate = { page: 1, size: 10 };
        this.previousPageable = null;
        this.dataSource = new MatTableDataSource([]);
    };
    ProtocolListComponent.prototype.setData = function (results) {
        return __awaiter(this, void 0, void 0, function () {
            var data, items, source;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(results.length > 0)) return [3 /*break*/, 2];
                        data = _.chunk(results, this.paginate.size);
                        items = data[this.paginate.page - 1];
                        source = this.protocolS.getProtocolsPaginate(results.length, this.paginate, items);
                        if (!this.auth.isAdmin) {
                            source.items = this.filterByLocationMember(source.items);
                        }
                        return [4 /*yield*/, this.locationS.basicLocations(source.items)];
                    case 1:
                        _a.sent();
                        this.previousPageable = { size: source.per_page, page: source.page };
                        this.pagination = source;
                        this.dataSource = new MatTableDataSource(source.items);
                        this.protocolsExist = true;
                        this.progress.next(false);
                        return [3 /*break*/, 3];
                    case 2:
                        this.protocolsExist = false;
                        this.progress.next(false);
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProtocolListComponent.prototype.ngAfterViewChecked = function () {
        this.changeDetector.detectChanges();
    };
    return ProtocolListComponent;
}());
export { ProtocolListComponent };
