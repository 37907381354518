import { __awaiter, __generator, __read, __spread } from "tslib";
import { AngularFireStorage } from '@angular/fire/storage';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable, forkJoin, Subject } from 'rxjs';
import * as _ from 'lodash';
import { Messages, string_message } from '../constants/messages';
import { finalize, map } from 'rxjs/operators';
import { PostService } from './post.service';
import { SnackbarService } from './snackbar.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/storage";
import * as i2 from "./snackbar.service";
import * as i3 from "@angular/material/snack-bar";
import * as i4 from "./post.service";
var StorageImageService = /** @class */ (function () {
    function StorageImageService(_afstorage, _snackService, _snackBar, _postS) {
        this._afstorage = _afstorage;
        this._snackService = _snackService;
        this._snackBar = _snackBar;
        this._postS = _postS;
        this._url = new BehaviorSubject(null);
        this._ref = new BehaviorSubject(null);
        this._percent = new BehaviorSubject(0);
        this._singleFile = true;
        this._imageUrl$ = new BehaviorSubject(null);
        this._multipleMediaUrl = [];
        this._multipleMediaUrl$ = new BehaviorSubject(null);
        this._errorsArray = [];
        this._fileDeletedSource$ = new Subject();
        this.fileDeleted$ = this._fileDeletedSource$.asObservable();
    }
    Object.defineProperty(StorageImageService.prototype, "url$", {
        get: function () {
            return this._url.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageImageService.prototype, "url", {
        get: function () {
            return this._url.getValue();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageImageService.prototype, "ref$", {
        get: function () {
            return this._ref.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageImageService.prototype, "percent$", {
        get: function () {
            return this._percent.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageImageService.prototype, "multipleMediaUrl$", {
        get: function () {
            return this._multipleMediaUrl$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StorageImageService.prototype.setMultipleMediaUrl = function (newArray) {
        this._multipleMediaUrl = newArray;
        this._multipleMediaUrl$.next(newArray);
    };
    StorageImageService.prototype.setUrl = function (value) {
        this._url.next(value);
    };
    StorageImageService.prototype.getImageUrl = function () {
        return this._imageUrl$.asObservable();
    };
    StorageImageService.prototype.setImageUrl = function (value) {
        this._imageUrl$.next(value);
    };
    StorageImageService.prototype.filesValidation = function (files, requirements) {
        return __awaiter(this, void 0, void 0, function () {
            var validatedFiles, _loop_1, this_1, i;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        validatedFiles = [];
                        if (!files[0]) {
                            if (this._singleFile) {
                                this._url.next(null);
                            }
                            else {
                                this._multipleMediaUrl = [];
                                this._multipleMediaUrl$.next(this._multipleMediaUrl);
                            }
                            return [2 /*return*/, validatedFiles]; // Return an empty array if no files
                        }
                        _loop_1 = function (i) {
                            var type, img_1, imgOnLoad, error_1, r, blob, url_1, video_1, timeupdate_1, snapImage_1, error_2;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        type = files[i].type === 'video/mp4' ? 'VIDEO' : 'PHOTO';
                                        if (!this_1._validateTypeSize(requirements, files[i])) return [3 /*break*/, 1];
                                        if (this_1._singleFile) {
                                            this_1._url.next({ error: true });
                                        }
                                        return [3 /*break*/, 9];
                                    case 1:
                                        if (!(type === 'PHOTO')) return [3 /*break*/, 6];
                                        img_1 = new Image();
                                        img_1.src = window.URL.createObjectURL(files[i]);
                                        imgOnLoad = new Promise(function (resolve, reject) {
                                            img_1.onload = function () {
                                                if (_this._validateFormat(requirements, img_1, files[i])) {
                                                    if (_this._singleFile) {
                                                        _this._url.next({ error: true });
                                                    }
                                                    reject();
                                                }
                                                else if (_this._validateType(type, files[i].name)) {
                                                    if (_this._singleFile) {
                                                        _this._url.next({ error: true });
                                                    }
                                                    reject();
                                                }
                                                else {
                                                    validatedFiles.push({ file: files[i], preview: null });
                                                    resolve(true);
                                                }
                                            };
                                        });
                                        _a.label = 2;
                                    case 2:
                                        _a.trys.push([2, 4, , 5]);
                                        return [4 /*yield*/, imgOnLoad];
                                    case 3:
                                        _a.sent();
                                        return [3 /*break*/, 5];
                                    case 4:
                                        error_1 = _a.sent();
                                        // Handle any errors
                                        console.error('Error occurred during photo validation:', error_1);
                                        return [3 /*break*/, 5];
                                    case 5: return [3 /*break*/, 9];
                                    case 6:
                                        _a.trys.push([6, 8, , 9]);
                                        return [4 /*yield*/, this_1._getFileBase64(files[i])];
                                    case 7:
                                        r = _a.sent();
                                        if (this_1._validateType(type, files[i].name)) {
                                            if (this_1._singleFile) {
                                                this_1._url.next({ error: true });
                                            }
                                        }
                                        else {
                                            blob = new Blob([r], { type: files[i].type });
                                            url_1 = URL.createObjectURL(blob);
                                            video_1 = document.createElement('video');
                                            timeupdate_1 = function () {
                                                if (snapImage_1()) {
                                                    video_1.removeEventListener('timeupdate', timeupdate_1);
                                                    video_1.pause();
                                                }
                                            };
                                            video_1.addEventListener('loadeddata', function () {
                                                if (snapImage_1()) {
                                                    video_1.removeEventListener('timeupdate', timeupdate_1);
                                                }
                                            });
                                            snapImage_1 = function () {
                                                var canvas = document.createElement('canvas');
                                                canvas.width = video_1.videoWidth;
                                                canvas.height = video_1.videoHeight;
                                                canvas.getContext('2d').drawImage(video_1, 0, 0, canvas.width, canvas.height);
                                                var dataURI = canvas.toDataURL();
                                                var success = dataURI.length > 100000;
                                                if (success) {
                                                    validatedFiles.push({ file: files[i], preview: dataURI });
                                                    URL.revokeObjectURL(url_1);
                                                }
                                                return success;
                                            };
                                            video_1.addEventListener('timeupdate', timeupdate_1);
                                            video_1.preload = 'metadata';
                                            video_1.src = url_1;
                                            video_1.muted = true;
                                            video_1.playsInline = true;
                                            video_1.play();
                                        }
                                        return [3 /*break*/, 9];
                                    case 8:
                                        error_2 = _a.sent();
                                        console.error('Error occurred during video validation:', error_2);
                                        return [3 /*break*/, 9];
                                    case 9: return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        i = 0;
                        _a.label = 1;
                    case 1:
                        if (!(i < files.length)) return [3 /*break*/, 4];
                        return [5 /*yield**/, _loop_1(i)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/, validatedFiles];
                }
            });
        });
    };
    StorageImageService.prototype.fileChanged = function (event, singleFile, requirements, placeId, maxNumberPhotos) {
        var _this = this;
        if (!placeId) {
            placeId = 'media';
        }
        if (!singleFile && maxNumberPhotos === 1) {
            this.setMultipleMediaUrl([]);
        }
        this._errorsArray = []; // reset errors
        this._singleFile = singleFile; // set the flag for single file
        var files = event.files;
        var formattedFilesArray = [];
        // Call filesValidation asynchronously and handle the result
        this.filesValidation(files, requirements)
            .then(function (validatedFiles) {
            _this._showErrors();
            for (var i = 0; i < validatedFiles.length; i++) {
                var file = validatedFiles[i].file;
                var type = file.type;
                var preview = validatedFiles[i].preview;
                var dir = "public/" + placeId + "/" + +new Date() + "-" + file.name;
                formattedFilesArray.push({ file: file, dir: dir, type: type, preview: preview });
            }
            // finished processing all files
            return formattedFilesArray;
        })
            .then(function (files) {
            _this._uploadFile(files);
        })
            .catch(function (error) {
            _this._showErrors();
            console.error('Error occurred during file validation:', error);
        });
    };
    StorageImageService.prototype.removeFileFromArray = function (files, file) {
        // gets a file and an array, emits the new array without the file
        var newArray = _.filter(files, function (f) {
            return f !== file;
        });
        this._multipleMediaUrl = newArray;
        this._multipleMediaUrl$.next(newArray);
    };
    StorageImageService.prototype.notifyFileDeleted = function () {
        this._fileDeletedSource$.next();
    };
    StorageImageService.prototype.reset = function () {
        this._url.next(null);
        this._multipleMediaUrl = [];
        this._multipleMediaUrl$.next([]);
    };
    StorageImageService.prototype._uploadWebpFile = function (file, dir, url) {
        var _this = this;
        this._postS.transformImage(url)
            .subscribe(function (res) {
            var name = _.head(_.split(file.name, '.'));
            var base64Image = 'data:image/jpg;base64,' + res.data;
            var newFile = _this._dataURLtoFile(base64Image, name);
            var placeId = dir.split('/')[1];
            var newDir = "public/" + placeId + "/" + newFile.name;
            _this.isWebpImage = false;
            var fileInArray = [];
            fileInArray.push({ file: newFile, dir: newDir, type: 'PHOTO', preview: null });
            _this._uploadFile(fileInArray);
        });
    };
    StorageImageService.prototype._uploadFile = function (files) {
        var _this = this;
        var observables = [];
        files.forEach(function (element) {
            observables.push(new Observable(function (observer) {
                var _a, _b, _c, _d, _e, _f;
                var fileName = (_b = (_a = element) === null || _a === void 0 ? void 0 : _a.file) === null || _b === void 0 ? void 0 : _b.name;
                var file = (_c = element) === null || _c === void 0 ? void 0 : _c.file;
                var dir = (_d = element) === null || _d === void 0 ? void 0 : _d.dir;
                var type = (_e = element) === null || _e === void 0 ? void 0 : _e.type;
                var preview = (_f = element) === null || _f === void 0 ? void 0 : _f.preview;
                _this._afstorage.upload(dir, file).snapshotChanges().pipe(map(function (actions) {
                    actions.task.catch(function (error) {
                        if (_this._singleFile) {
                            _this._url.next(null); // single file error
                        }
                        else {
                            _this._multipleMediaUrl.push({ url: '', category: type, error: true });
                        }
                        var msg = "An error occurred uploading the file " + (fileName ? (' ' + fileName + ',') : '') + " please try again later.";
                        _this._errorsArray.push(msg);
                        observer.error(error);
                    });
                }), finalize(function () {
                    _this.fileRef = _this._afstorage.ref(dir);
                    _this._ref.next(_this.fileRef);
                    _this.fileRef.getDownloadURL().subscribe(function (url) {
                        var element = {
                            url: url,
                            preview: preview,
                            type: type,
                            fileName: fileName,
                            category: type
                        };
                        if (type === 'PHOTO') {
                            _this.isWebpImage = _this._verifyImageWebp(file.name);
                            if (_this._singleFile) {
                                !_this.isWebpImage ? _this._url.next(element) : _this._uploadWebpFile(file, dir, url);
                            }
                            else {
                                !_this.isWebpImage ? _this._multipleMediaUrl.push(element) : _this._uploadWebpFile(file, dir, url);
                            }
                        }
                        else {
                            if (_this._singleFile) {
                                _this._url.next(element); // single file success
                            }
                            else {
                                _this._multipleMediaUrl.push(element); // multiple file success
                            }
                        }
                        _this._multipleMediaUrl$.next(__spread(_this._multipleMediaUrl));
                    });
                    observer.complete();
                })).subscribe();
            }));
        });
        forkJoin(observables).subscribe();
    };
    StorageImageService.prototype._verifyVideoFile = function (fileName) {
        return (fileName.includes('.mp4') || fileName.includes('.MP4')
            || fileName.includes('.avi') || fileName.includes('.AVI'));
    };
    StorageImageService.prototype._verifyImageFile = function (fileName) {
        return (fileName.includes('.png') || fileName.includes('.PNG')
            || fileName.includes('.jpg') || fileName.includes('.JPG')
            || fileName.includes('.jpeg') || fileName.includes('.JPEG')
            || fileName.includes('.webp') || fileName.includes('.WEBP')
            || fileName.includes('.ico') || fileName.includes('.ICO'));
    };
    StorageImageService.prototype._verifyImageWebp = function (fileName) {
        return (fileName.includes('.webp') || fileName.includes('.WEBP'));
    };
    StorageImageService.prototype._getFileBase64 = function (file) {
        return __awaiter(this, void 0, void 0, function () {
            var reader;
            return __generator(this, function (_a) {
                reader = new FileReader();
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        reader.onerror = function () {
                            reader.abort();
                            reject(new DOMException("Problem parsing input file."));
                        };
                        reader.onload = function () {
                            resolve(reader.result);
                        };
                        reader.readAsArrayBuffer(file);
                    })];
            });
        });
    };
    StorageImageService.prototype._dataURLtoFile = function (dataurl, filename) {
        var arr = dataurl.split(',');
        var mime = arr[0].match(/:(.*?);/)[1];
        var type = _.last(mime.split('/'));
        var bstr = atob(arr[1]);
        var n = bstr.length;
        var u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename + "." + type, { type: mime });
    };
    StorageImageService.prototype._validateTypeSize = function (requirements, file) {
        var _a, _b, _c, _d;
        if (requirements && _.isArray((_a = requirements) === null || _a === void 0 ? void 0 : _a.type) && !_.includes((_b = requirements) === null || _b === void 0 ? void 0 : _b.type, file.type)) {
            var types = requirements.type.map(function (t) { return _.last(_.split(_.kebabCase(t), '-')); });
            var msg = file.name + ": " + Messages.upload.BAD_TYPES + " " + _.toString(types);
            this._errorsArray.push(msg);
            // this.snackService.openWarning(msg, 5000); // modify to work with multiple files
            return true;
        }
        else if (requirements && !_.isArray((_c = requirements) === null || _c === void 0 ? void 0 : _c.type) && file.type !== ((_d = requirements) === null || _d === void 0 ? void 0 : _d.type)) {
            var msg = file.name + ": " + Messages.upload.BAD_TYPE;
            this._errorsArray.push(msg);
            // this.snackService.openWarning(msg, 4000); // modify to work with multiple files
            return true;
        }
        if (requirements && requirements.min_size && file.size < requirements.min_size) {
            var msg = file.name + ": " + string_message(Messages.upload.BAD_SIZE_CUSTOM, [requirements.min_size]);
            this._errorsArray.push(msg);
            // this.snackService.openWarning(msg, 4000); // modify to work with multiple files
            return true;
        }
        if (requirements && requirements.max_size && file.size > requirements.max_size) {
            var msg = file.name + ": " + string_message(Messages.upload.BAD_MAX_SIZE_CUSTOM, [requirements.max_size]);
            this._errorsArray.push(msg);
            // this.snackService.openWarning(msg, 5000); // modify to work with multiple files
            return true;
        }
        return false;
    };
    StorageImageService.prototype._validateFormat = function (requirements, img, file) {
        var _a, _b, _c, _d, _e, _f;
        var ratio = img.width / img.height;
        if (requirements && ((_a = requirements) === null || _a === void 0 ? void 0 : _a.min_height) && ((_b = requirements) === null || _b === void 0 ? void 0 : _b.min_width) && file.type !== 'video/mp4') {
            if (img.height < requirements.min_height || img.width < requirements.min_width) {
                var msg = file.name + ": " + string_message(Messages.upload.BAD_DIMENSION_MINIMUM_CUSTOM, [requirements.min_width, requirements.min_height]);
                this._errorsArray.push(msg);
                // this.snackService.openWarning(msg, 4000); // modify to work with multiple files
                return true;
            }
        }
        if (requirements && ((_c = requirements) === null || _c === void 0 ? void 0 : _c.height) && ((_d = requirements) === null || _d === void 0 ? void 0 : _d.width) && file.type !== 'video/mp4') {
            if (img.height > requirements.height || img.width > requirements.width) {
                var msg = file.name + ": " + string_message(Messages.upload.BAD_DIMENSION_MINIMUM_CUSTOM, // <-- might be the wrong constant, we need to check
                [requirements.width, requirements.height]);
                this._errorsArray.push(msg);
                // this.snackService.openWarning(msg, 4000); // modify to work with multiple files
                return true;
            }
        }
        else if (img.height > 1192 || img.width > 2120) {
            var msg = file.name + ": " + Messages.upload.BAD_DIMENSION;
            this._errorsArray.push(msg);
            // this.snackService.openWarning(Messages.upload.BAD_DIMENSION,  4000); // modify to work with multiple files
            return true;
        }
        else if (requirements && ((_e = requirements) === null || _e === void 0 ? void 0 : _e.minRatio) && ratio < ((_f = requirements) === null || _f === void 0 ? void 0 : _f.minRatio)) {
            var msg = file.name + ": Image ratio must be " + (requirements.minRatio == 1.3 ? '4:3' : '16:9') + ". Please edit and retry or upload a new image.";
            this._errorsArray.push(msg);
            // this.snackService.openWarning(msg,  4000); // modify to work with multiple files
            return true;
        }
        return false;
    };
    StorageImageService.prototype._validateType = function (type, name) {
        if (type === 'VIDEO') {
            // its a video
            if (!this._verifyVideoFile(name)) {
                var msg = name + ": Incorrect format for a video.";
                this._errorsArray.push(msg);
                // this.snackService.openWarning(msg, 4000); // modify to work with multiple files
                return true;
            }
        }
        else {
            // its a photo
            if (!this._verifyImageFile(name)) {
                var msg = name + ": Incorrect format for an image.";
                this._errorsArray.push(msg);
                // this.snackService.openWarning(msg, 4000); // modify to work with multiple files
                return true;
            }
        }
        return false;
    };
    StorageImageService.prototype._showErrors = function () {
        var combinedErrors = this._errorsArray.join('\n');
        if (combinedErrors) {
            this._snackService.openWarning(combinedErrors, 6000);
        }
    };
    StorageImageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StorageImageService_Factory() { return new StorageImageService(i0.ɵɵinject(i1.AngularFireStorage), i0.ɵɵinject(i2.SnackbarService), i0.ɵɵinject(i3.MatSnackBar), i0.ɵɵinject(i4.PostService)); }, token: StorageImageService, providedIn: "root" });
    return StorageImageService;
}());
export { StorageImageService };
