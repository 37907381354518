import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-skeleton-reports',
  templateUrl: './skeleton-reports.component.html'
})
export class SkeletonReportsComponent implements OnInit {
  @Input() type: string;

  constructor() { }

  ngOnInit() {
  }

}
