import { EventEmitter, OnInit, AfterViewInit, Renderer2, ElementRef } from '@angular/core';
import { DATE_PICKER_RANGES } from '../../constants/datePicker';
import * as moment from 'moment-timezone';
import { EventMessageService } from "../../services/event-message.service";
import { ReportService } from 'src/app/services/report.service';
import { DatesService } from 'src/app/services/dates.service';
var DatePickerComponent = /** @class */ (function () {
    function DatePickerComponent(em, reportS, renderer, el, dateS) {
        this.em = em;
        this.reportS = reportS;
        this.renderer = renderer;
        this.el = el;
        this.dateS = dateS;
        this.id = '';
        this.dateSelected = new EventEmitter();
        this.subscribeToDatepicker = false;
        this.showTooltipsOnDisabledDates = false;
        this.isReadOnly = false;
        this.hasDateLimitsInfo = false;
        this.ranges = DATE_PICKER_RANGES;
        this.opens = 'left';
        this.drops = 'down';
        this.alwaysShowCalendars = true;
        this.keepCalendarOpeningWithRange = true;
        this.locale = {
            applyLabel: 'Apply',
            format: 'MM/DD/YYYY',
            cancelLabel: 'Cancel',
            customRangeLabel: 'Custom Range',
            daysOfWeek: moment.weekdaysMin(),
            monthNames: moment.monthsShort(),
            firstDay: 0,
        };
        this.isClosed = true;
        this.events = [];
        this.tooltipsApplied = false;
        this.isOpened = false;
    }
    DatePickerComponent.prototype.ngOnInit = function () {
        if (this.rangeB)
            this.id = 'B';
        this.drops = (this.dropsChange) ? this.dropsChange : 'down';
        if (this.minRange) {
            var months = this.reportType == 'performance-rollup' || this.reportType == 'review' ? 13 : 12;
            var minDate = moment().subtract(moment.duration({ 'months': months }));
            this.minDateRange = this.minDateRange && this.minDateRange.isAfter(minDate) ? this.minDateRange : minDate;
        }
        if (this.disabled) {
            this.dateSelected.emit(this.dataPicker);
        }
        this.setRanges();
    };
    DatePickerComponent.prototype.applyTooltips = function () {
        var _a, _b;
        if (!this.tooltipsApplied) {
            var elementsWithTooltip = document.querySelectorAll('.off.disabled');
            if (((_a = elementsWithTooltip) === null || _a === void 0 ? void 0 : _a.length) && this.showTooltipsOnDisabledDates) {
                var date_1 = (_b = this.maxDate) === null || _b === void 0 ? void 0 : _b.set({ 'hours': 23, 'minute': 59, 'second': 59 });
                elementsWithTooltip.forEach(function (element) {
                    element.setAttribute('title', "Google Business Profile performance data is latent for up to 7 days from today's date. Please choose a date before " + date_1.format('MM/DD/YYYY') + " to see the most recent performance data for your business profile.");
                });
                this.tooltipsApplied = true;
            }
        }
    };
    DatePickerComponent.prototype.addDateLimitsInfo = function () {
        var elemento = document.getElementsByClassName('limits-info-txt')[0];
        var buttonsInput = this.el.nativeElement.querySelector('.buttons_input');
        if (elemento) {
            this.renderer.removeChild(elemento.parentNode, elemento);
        }
        if (this.minDateRange && this.maxDate && buttonsInput) {
            var minDate = this.dateS.getStringDate(this.minDateRange) || '';
            var maxDate = this.dateS.getStringDate(this.maxDate) || '';
            var txt = this.renderer.createText("Please select a date from " + minDate + " to " + maxDate);
            var span = this.renderer.createElement('span');
            this.renderer.addClass(span, 'limits-info-txt');
            this.renderer.appendChild(span, txt);
            this.renderer.insertBefore(buttonsInput, span, buttonsInput.children[1]);
        }
    };
    DatePickerComponent.prototype.ngAfterViewInit = function () {
        this.applyTooltips();
    };
    DatePickerComponent.prototype.rangeClicked = function (range) {
        if (this.rangeB) {
            this.dataPicker.rangeB = { start: range.startDate, end: range.endDate };
            return;
        }
        this.dataPicker.range = { start: range.startDate, end: range.endDate };
    };
    DatePickerComponent.prototype.handleClick = function ($event) {
        var _this = this;
        this.isClosed = false;
        if (this.hasDateLimitsInfo) {
            this.addDateLimitsInfo();
        }
        if (this.minRange) {
            this.em.emitMessageEvent("DATEPICKER SUBSCRIPTION", true);
        }
        if (!this.minRange && this.subscribeToDatepicker) {
            this.em.emitMessageEvent("DATEPICKER FIRST TIME SUBSCRIPTION", true);
        }
        if (!this.isOpened) {
            this.isOpened = true;
            this.setRanges();
            if (!this.tooltipsApplied) {
                setTimeout(function () {
                    _this.applyTooltips();
                }, 500);
            }
        }
    };
    DatePickerComponent.prototype.setRanges = function () {
        if (this.reportType == 'performance-rollup' || this.reportType == 'performance-comparison') {
            this.ranges = {
                'Last 7 Days': [this.reportS.getDates('Last7', 'performance-rollup').startDatetime, this.reportS.getDates('Last7', 'performance-rollup').endDatetime],
                'Last 30 Days': [this.reportS.getDates('Last30', 'performance-rollup').startDatetime, this.reportS.getDates('Last30', 'performance-rollup').endDatetime],
                'Last 90 Days': [this.reportS.getDates('Last90', 'performance-rollup').startDatetime, this.reportS.getDates('Last90', 'performance-rollup').endDatetime],
                'This Month': [this.reportS.getDates('ThisMonth', 'performance-rollup').startDatetime, this.reportS.getDates('ThisMonth', 'performance-rollup').endDatetime],
                'Last Month': [this.reportS.getDates('LastMonth', 'performance-rollup').startDatetime, this.reportS.getDates('LastMonth', 'performance-rollup').endDatetime],
                'Last Year': [this.reportS.getDates('LastYear', 'performance-rollup').startDatetime, this.reportS.getDates('LastYear', 'performance-rollup').endDatetime],
                YTD: [this.reportS.getDates('YTD', 'performance-rollup').startDatetime, this.reportS.getDates('YTD', 'performance-rollup').endDatetime]
            };
        }
        ;
    };
    DatePickerComponent.prototype.datesUpdated = function ($event) {
        if ($event.startDate) {
            if (this.rangeB) {
                this.dataPicker.rangeB = {
                    start: $event.startDate.utc(true).toISOString(),
                    end: $event.endDate.utc(true).toISOString()
                };
            }
            else {
                this.dataPicker.range = {
                    start: $event.startDate.utc(true).toISOString(),
                    end: $event.endDate.utc(true).toISOString()
                };
            }
        }
        this.dateSelected.emit(this.dataPicker);
    };
    DatePickerComponent.prototype.datesChange = function ($event) {
        if ($event.start) {
            if (this.rangeB) {
                this.dataPicker.rangeB = {
                    start: $event.start.utc(true).toISOString(),
                    end: $event.end.utc(true).toISOString()
                };
            }
            else {
                this.dataPicker.range = {
                    start: $event.start.utc(true).toISOString(),
                    end: $event.end.utc(true).toISOString()
                };
            }
        }
        this.dateSelected.emit(this.dataPicker);
    };
    DatePickerComponent.prototype.changeDateWrite = function (data, $event) {
        if ($event.keyCode === 13) {
            var arraydate = data.split('-');
            if (arraydate.length > 1) {
                var dateStart = moment(arraydate[0].trim());
                var dateEnd = moment(arraydate[1].trim());
                if (!dateStart && !dateEnd)
                    return;
                var start = !this.minDateRange ? dateStart : dateStart.isAfter(this.minDateRange) ? dateStart : this.minDateRange;
                var end = !this.maxDate ? dateEnd : dateEnd.isBefore(this.maxDate) ? dateEnd : this.maxDate;
                this.select = {
                    start: start || dateStart,
                    end: end || dateEnd
                };
                this.datesUpdated({
                    startDate: start || dateStart,
                    endDate: end || dateEnd
                });
            }
        }
    };
    DatePickerComponent.prototype.getDates = function (d1, d2) {
        d1 = moment(d1).utc().format(this.locale.format);
        d2 = moment(d2).utc().format(this.locale.format);
        return d1 + " - " + d2;
    };
    return DatePickerComponent;
}());
export { DatePickerComponent };
