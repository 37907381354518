// dep
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// app
import { environment as ENV} from '@environment';
import { LOCATION_SUBSCRIPTION_TYPE } from '../constants/firestore/account-location';

const URL_CARD = (gid : string, cardId? : string) => 
                   `${ENV.billingApiUrl}/card/by-gid/${gid}` + (cardId ? '/' + cardId : '')

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(private http: HttpClient) {} 
  
  async addNewCard(gid: string, token: string) {
    return await this.http.post(URL_CARD(gid), {source: token}).toPromise();
  }

  deleteCard(gid : string, cardId: string) : Promise<any> {
    return this.http.delete(URL_CARD(gid, cardId)).toPromise()
  }

  async getCards(gid : string) : Promise<any[]> {
    return (await this.http.get<{data: any[]}>(URL_CARD(gid)).toPromise()).data
  }

  async hasPaymentMethods(gid : string) : Promise<boolean> {
    try { 
      return !!(await this.getCards(gid)).length
    } catch {
      return false
    }
  }

  // TODO: Change to "fetchPlan" and return a promise, not Observable (is single-shot)
  getPlan(planId : LOCATION_SUBSCRIPTION_TYPE) {
    return this.http.get(`${ENV.billingApiUrl}/product/${planId.toLowerCase()}`)
  }

  setDefaultSource(gid : string, cardId : string) {
    return this.http.post(URL_CARD(gid, cardId)+'/default', {}).toPromise()
  }
}
