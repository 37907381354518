import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-skeleton-comparison',
  templateUrl: './skeleton-comparison.component.html'
})
export class SkeletonComparisonComponent implements OnInit {

  @Input() type: string;
  @Input() columns: number;

  constructor() { }

  ngOnInit() {
  }

}
