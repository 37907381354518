import { Component, Inject } from '@angular/core'
import { MatDialogRef } from '@angular/material'
import { Router } from '@angular/router'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Observable } from 'rxjs'
import { ISubscription } from '../../constants/subscription'
import Group from 'src/app/constants/firestore/group'
import { AuthService } from '../../services/auth.service'

@Component({
  selector: 'app-modal-trial-ended',
  templateUrl: './modal-trial-ended.component.html',
  styleUrls: ['./modal-trial-ended.component.scss']
})
export class ModalTrialEnded {
    subscription$: Observable<ISubscription>
    subscription: ISubscription
    group$: Observable<Group>
    group: Group

    public price: number
    public locations_qty: number
    private _data
    constructor(
        public dialogRef: MatDialogRef<ModalTrialEnded>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _auth: AuthService,
    ){
        this._data = data
        this.subscription$ = this._auth.subscription$
        this.subscription$.subscribe((subscription) => {
            this.subscription = subscription
            this.price = this.getPrice()
        })

        this.group$ = this._auth.groupOut$
        this.group$.subscribe((group) => {
            this.group = group
            this.price = this.getPrice()
        })
     }

    private getPrice() : number {
        let price = 0
        if (!this.group || !this.subscription) return price 

        this.locations_qty = this.group.totalLocationsCount
        for (let i = 0; i < this.subscription.pricing?.pkg_bulk_actions?.tiers.length; i++) {
            if (this.locations_qty >= this.subscription.pricing?.pkg_bulk_actions?.tiers[i]?.locationsQtyMin && 
                this.locations_qty <= this.subscription.pricing?.pkg_bulk_actions?.tiers[i]?.locationsQtyMax) {
                price = this.subscription.pricing?.pkg_bulk_actions?.tiers[i]?.price
                return price
            }
        }
    }

    public accept_cta() : void {
        window.open(this.data.buttons['accept_cta'], "_blank")
    }

    public close() : void {
        this.dialogRef.close()
        this._auth.signOut()
    }
}