<div class="dialog__header" *ngIf="!bulk">
  <h2 mat-dialog-title class="m--0 txt--xl">
    <strong>From the business</strong>
  </h2>
</div>
<div class="dialog__content" mat-dialog-content>

  <div class="dialog-row p--0">
    <mat-form-field class="input-field">
      <textarea matInput placeholder="Write a brief description of your business." [(ngModel)]="description"
                [value]="description" name="url" rows="5" maxlength="750" required>
      </textarea>
      <mat-hint align="end">{{description?.length ? description?.length : 0}} / 750</mat-hint>
    </mat-form-field>
    <p *ngIf="isValidValue()" class="txt--red">Description is required</p>
    <p *ngIf="hasAllCharacters()" class="txt--red">You exceeded the number of characters</p>
  </div>


</div>
<div mat-dialog-actions class="dialog__footer" *ngIf="!bulk">
    <span class="alternate-theme d-inline-block mr--10">
        <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
    </span>
  <button mat-flat-button (click)="apply()" tabIndex="-1" color="accent" class="btn btn--action">Apply</button>
</div>
