import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-accounts',
  templateUrl: './skeleton-accounts.component.html'
})
export class SkeletonAccountsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
