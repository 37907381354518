// TODO: Refactor against admin-frontend/http-auth.interceptor.ts

// dep
//import { HTTP_INTERCEPTORS } from "@angular/common/http"
import { HttpInterceptor, HttpRequest, HttpHandler } from "@angular/common/http";
import { /*Injector,*/ Injectable } from "@angular/core"
import { Observable, from } from "rxjs"
import { single } from "rxjs/operators";

// app
import { AuthProxyService } from "../services/auth.proxy.service"

@Injectable() 
export class HttpAuthInterceptor implements HttpInterceptor {

    currentRefresh: null | Promise<string> = null

    constructor(private authProxyService: AuthProxyService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        //return defer(() => this.doRequest(request, next))
        return from(this.doRequest(request, next)).pipe(
            single(),
            // catchError(err => {
            //     console.log('Handling error locally and rethrowing it...', err);
            //     return throwError(err);
            // })
            )
    }

    
    private async doRequest(request: HttpRequest<any>, next: HttpHandler) {
        
        let manageAuth = true
        if (request.headers.has('X-Token-Type')) { 
            manageAuth = request.headers.get('X-Token-Type') !== 'NO_AUTH'
            // X-Token-Type is only for client-side bookeeping, don't send it to the wire
            request = request.clone({ headers: request.headers.delete('X-Token-Type') })
        }
                
        let refreshDone = false;

        const headersOrig = request.headers
        
        for(;;) {
            try {
                // console.log('manageAuth', manageAuth, request.urlWithParams)                
                if(manageAuth) {
                    // Keep the previous request headers (e.g., maybe 'gid' is overrided
                    // for a particular request) but always put the latest Authorization token

                    let headers = headersOrig
                    const authHeaders = await this.authProxyService.authHeaders()
                    // console.debug('authHeaders', authHeaders)
                    for(const k of authHeaders.headers.keys())
                        if(!headers.has(k) || k === 'authorization')
                            headers = headers.set(k, authHeaders.headers.get(k))

                    // console.debug('requestHeaders', headers)
                    request = request.clone({ headers })
                }
                // console.debug('request', request)
                // NOTE: request is sent using withCredentials=false, so Authorization
                // headers and cookies won't be sent to non-origin domains
                return await next.handle(request).toPromise()
            } catch (error) {
                if (error.error instanceof ErrorEvent) {
                    // client-side error
                    // TODO: recheck if this can happen here
                    throw {
                        status: 901,
                        message: `Error: ${error.error.message}`
                    }
                } else if (error.status === 401 && manageAuth) {
                    // Try to refresh the token and retry the request.. if that doesn't work, then logout.                    
                    try {  
                        if (refreshDone)
                            throw 'Refresh already tried'

                        // Coalesce multiple concurrent refresh tries to max single one
                        if (!this.currentRefresh)
                            this.currentRefresh = this.authProxyService.forceAuthRefresh()

                        await this.currentRefresh
                        this.currentRefresh = null
                    } catch (refreshError) {
                        console.log('Error refreshing token', refreshError)
                        this.currentRefresh = null

                        // Can't refresh the token, logout
                        await this.authProxyService.signOut()
                        throw {
                            status: error.status,
                            message: `Error Code: ${error.status}\nMessage: ${error.message}`,
                            error: error.error
                        }
                    }

                    // The request will be updated with the refreshed accsss token and retried
                    // one time again.
                    refreshDone = true
                } else {
                    throw {
                        status: error.status,
                        message: `Error Code: ${error.status}\nMessage: ${error.message}`,
                        error: error.error
                    }
                }
            }
        }
    }
}


// export const AuthInterceptorProvider = {
//     provide: HTTP_INTERCEPTORS,
//     useClass: HttpAuthInterceptor,
//     multi: true
// };
