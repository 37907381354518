import { getLocaleNumberFormat } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'buildPercentageFormat'
})
export class BuildPercentageFormatPipe implements PipeTransform {

  transform(value: number | string, round: boolean = true): any {
    if(value === '-') {
      return '-';
    }

    if(value){
      value = value?.toString().split(',').join('');
      value = Number(value)
    }else {
      value = 0;
    }
    if (round) {
      value = Math.round(value);
    }
    value = value < 0 ? Math.abs(value) : value;
    
    if(value){
      value = Number(value).toLocaleString();
    }

    return `${value}%`;
  }

}
