import { __awaiter, __generator } from "tslib";
// dep
import { ElementRef, OnInit } from '@angular/core';
// app
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
var UserFeatureDirective = /** @class */ (function () {
    function UserFeatureDirective(_authS, _userS, _element) {
        this._authS = _authS;
        this._userS = _userS;
        this._element = _element;
    }
    UserFeatureDirective.prototype.ngOnInit = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        this.uid = this._authS.session.uid;
                        _c = this;
                        return [4 /*yield*/, this._authS.userFeatures];
                    case 1:
                        _c.userFeaturesStorage = _d.sent();
                        if (((_a = Object.keys(this.userFeaturesStorage)) === null || _a === void 0 ? void 0 : _a.length) && ((_b = this.userFeaturesStorage) === null || _b === void 0 ? void 0 : _b.uid) != 'undefined') {
                            this.buildFeature();
                        }
                        else {
                            this.getUserFeatures();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    UserFeatureDirective.prototype.buildFeature = function () {
        var _a, _b, _c, _d, _e, _f, _g;
        this.userFeatures = (_a = this.userFeaturesStorage) === null || _a === void 0 ? void 0 : _a.userFeatures;
        this.generalFeatures = (_b = this.userFeaturesStorage) === null || _b === void 0 ? void 0 : _b.generalFeatures;
        this.newFeature = (_d = (_c = this.userFeaturesStorage) === null || _c === void 0 ? void 0 : _c.generalFeatures) === null || _d === void 0 ? void 0 : _d.isNewFeature;
        var hasFeature = (_e = this.userFeatures) === null || _e === void 0 ? void 0 : _e[this.appUserFeature];
        var feature = (_f = this.generalFeatures) === null || _f === void 0 ? void 0 : _f[this.appUserFeature];
        var newFeature = (_g = this.newFeature) === null || _g === void 0 ? void 0 : _g[this.appUserFeature];
        if (feature || hasFeature) {
            var htmlEl = this._element.nativeElement;
            htmlEl.classList.remove('d-none'); // Show the element
            if (newFeature || !feature) {
                var itemsToAddBeta_1 = htmlEl.getElementsByClassName('title-dark-launch');
                var message_1 = newFeature ? 'New' : 'Beta';
                Object.keys(itemsToAddBeta_1).forEach(function (el) {
                    itemsToAddBeta_1[el].innerHTML += "<span class=\"beta-mesage\">" + message_1 + "</span>";
                });
            }
        }
    };
    UserFeatureDirective.prototype.getUserFeatures = function () {
        var _this = this;
        this._userS.getUserFeature(this.uid).subscribe(function (res) {
            _this.userFeaturesStorage = res;
            _this.buildFeature();
        }, function (err) { return console.log(err); });
    };
    return UserFeatureDirective;
}());
export { UserFeatureDirective };
