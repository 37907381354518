<form [formGroup]="form">
  <ng-container *ngIf="postType">
    <div class="d-flex align-items-center mb--10">
      <h5 class="fw--medium txt--black mr--10">
        Setup as a Recurring Post (Optional)
        <span matTooltip="Enabling this feature will automatically post, delete and repost the same content on a fixed schedule. Use this feature only for content that does not have a fixed expiration date and will be perpetually relevant for your business. Examples include weekly specials, monthly discounts/incentives as well as repeating events and offers."
        matTooltipPosition="above"
        matTooltipClass="mat-tooltip--override">
          <i class="fas fa-info-circle txt--lightgray txt--sm"></i>
        </span>
      </h5>
      <!--switch-->
      <span matTooltip="To enable/disable recurring post, you must clone this post first."
        [matTooltipDisabled]="!isEditing || (isEditing && post?.status && post.status === 'DRAFT')"
        matTooltipPosition="above"
        matTooltipClass="mat-tooltip--override"
        >
        <mat-slide-toggle [disabled]="isEditing && (post?.status && post.status !== 'DRAFT')" [checked]="!!recurring" (change)="changeRecurring($event)" color="primary"></mat-slide-toggle>
      </span>
    </div>

    <ng-container *ngIf="recurring">
      <app-recurring
        #formRecurring
        style="width: 100%;"
        [postDateAvaible]="postDateAvaible"
        [postTypeName]="postTypeName"
        [recurring]="recurring"
        (recurringChange)="handleRecurringChange($event)"
        [publishRecurringNow]="publishRecurringNow"
        (publishRecurringNowChange)="handlepublishRecurringNowChange($event)"
        (isRecurringValid)="handleIsRecurringValid($event)"
      ></app-recurring>
    </ng-container>
    <div class="row align-items-start">
      <div class="col-md-5 mb--30" *ngIf="postType!='ALERT'">
        <app-form-input-upload #inputUpload
                               [urlInput]="imageSelectedUrl"
                               [placeId]="placeId"
                               [requirements]="imageRequirements"
                               (url)="handleUrl($event)"
                               (percentUpload)="handlePercent($event)"
                               (ref)="handleRef($event)"></app-form-input-upload>
        <div class="d-inline-flex" style="margin-top: 5px;" *ngIf="false" >
          <div [id]="i" class="thumbs" style="cursor: pointer;"  (click)="selectImage(i)" *ngFor="let image of imageUrl; let i = index">
            <img class="img-thumbnail thumbs" alt="image post" [ngClass]="{'selected': imageSelectedUrl === image}" [src]="image">
          </div>
          <div id="add_new" class="thumbs text-center" style="cursor: pointer;" [ngClass]="{'selected': imageSelectedUrl === null}" (click)="addNewImage()">
            <i class="fas fa-plus my-auto fa-2x" style="line-height: 60px; color: #bbbbbb;"></i>
          </div>
        </div>
        <mat-error class="mat-error" *ngIf="image.errors">
          <p *ngIf="image.errors['required']">Image is required</p>
        </mat-error>
        <div class="mt--5">
          <div class="txt--lightgray txt--sm ml--5 d-flex align-items-start">
            <i class="fas fa-info-circle mr--5"></i>
            <p class="mb--10 txt--sm">
              <!--TODO: instead of tooltip, the requirements can be printed here-->
              <span class="d-block">Minimum Size: 400 x 300</span>
              <span class="d-block">Maximum Size: 2120 x 1192</span>
              <span class="d-block">Formats: JPG, PNG or WEBP</span>
              <span class="d-block">Aspect ratio: 4:3</span>
              <span class="d-block">File Size min/max: 10KB/5MB</span>
            </p>
          </div>
        </div>

        <button *ngIf="imageSelectedUrl" mat-flat-button color="warn" class="btn btn-icon btn-icon--remove" matTooltip="Remove" matTooltipPosition="above" matTooltipClass="tooltip tooltip--white" (click)="clearImagen()">
          <i class="far fa-trash-alt"></i>
        </button>
      </div>
      <div class="col-md-7 mb--15" *ngIf="postType!='ALERT'">
        <div class="dialog-row p--0">

          <mat-form-field floatLabel="never"
                          class="input-group input-group--override input-group--override-label no-margin"
                          *ngIf="postType!='STANDARD'">
            <mat-label>Title</mat-label>
            <input matInput placeholder="Title" [formControl]="title" class="input-group__field" maxlength="58">
            <mat-hint align="end">{{title.value.length}} / 58</mat-hint>
            <mat-error *ngIf="title.errors">
              <p *ngIf="title.errors['required']">Title is required</p>
            </mat-error>
          </mat-form-field>

          <mat-form-field floatLabel="never"
                          class="input-group input-group--override input-group--override-label no-margin">
            <mat-label>Enter your post description here</mat-label>
            <textarea #Description matInput class="input-group__field" rows="6" maxlength="1500"
                      placeholder="Enter your post description here" [formControl]="description" (focusout)="index_focus_out = 'description'" ></textarea>
            <mat-hint align="end">{{description.value.length}} / 1500</mat-hint>
            <mat-error *ngIf="description.errors">
              <p *ngIf="description.errors['required']">Description is required</p>
            </mat-error>
          </mat-form-field>

          <ng-container *ngIf="postType=='STANDARD'">
            <mat-form-field class="input-group input-group--select no-margin">
              <mat-select [formControl]="type" class="input-group__field">
                <mat-option value="">Add a button(optional)</mat-option>
                <mat-option [value]="type.key" *ngFor="let type of types | keyvalue">{{type.value}}</mat-option>
              </mat-select>
            </mat-form-field>

            <div class="notice" *ngIf="type.value && type.value == 'CALL'">
              <p>Customers will call location number</p>
            </div>
            <div *ngIf="type.value && type.value != 'CALL'">
              <mat-form-field floatLabel="never"
                              class="input-group input-group--override input-group--override-label no-margin">
                <mat-label>Link for your button</mat-label>
                <input matInput placeholder="Link for your button" [formControl]="url" class="input-group__field" (focusout)="index_focus_out = 'url'">
                <mat-error *ngIf="url.errors">
                  <p *ngIf="url.errors['required']">Url is required</p>
                  <p *ngIf="url.errors['pattern']">Url is not valid</p>
                </mat-error>
              </mat-form-field>
            </div>

          </ng-container>
        </div>
      </div>

      <div class="col-md-12 mb--15" *ngIf="postType=='ALERT'">
        <div class="dialog-row p--0">

          <mat-form-field floatLabel="never"
                          class="input-group input-group--override input-group--override-label no-margin">
            <mat-label>Enter your post description here</mat-label>
            <textarea matInput class="input-group__field" rows="6" maxlength="1500"
                      placeholder="Enter your post description here" [formControl]="description"></textarea>
            <mat-hint align="end">{{description.value.length}} / 1500</mat-hint>
            <mat-error *ngIf="description.errors">
              <p *ngIf="description.errors['required']">Description is required</p>
            </mat-error>
          </mat-form-field>

          <ng-container>
            <mat-form-field class="input-group input-group--select no-margin">
              <mat-select [formControl]="type" class="input-group__field">
                <mat-option value="">Add a button(optional)</mat-option>
                <mat-option [value]="type.key" *ngFor="let type of types | keyvalue">{{type.value}}</mat-option>
              </mat-select>
            </mat-form-field>

            <div class="notice" *ngIf="type.value && type.value == 'CALL'">
              <p>Customers will call location number</p>
            </div>
            <div *ngIf="type.value && type.value != 'CALL'">
              <mat-form-field floatLabel="never"
                              class="input-group input-group--override input-group--override-label no-margin">
                <mat-label>Link for your button</mat-label>
                <input matInput placeholder="Link for your button" [formControl]="url" class="input-group__field" (focusout)="index_focus_out = 'url'">
                <mat-error *ngIf="url.errors">
                  <p *ngIf="url.errors['required']">Url is required</p>
                  <p *ngIf="url.errors['pattern']">Url is not valid</p>
                </mat-error>
              </mat-form-field>
            </div>

          </ng-container>
        </div>
      </div>

      <ng-container *ngIf="postType=='EVENT'">
        <div class="col-12">
          <div class="dialog-row p--0">
            <mat-form-field class="input-group input-group--select no-margin">
              <mat-select [formControl]="type" class="input-group__field">
                <mat-option value="">Add a button(optional)</mat-option>
                <mat-option [value]="type.key" *ngFor="let type of types | keyvalue">{{type.value}}</mat-option>
              </mat-select>
              <mat-error *ngIf="type.errors">
                <p *ngIf="type.errors['required']">Description is required</p>
              </mat-error>
            </mat-form-field>

            <div *ngIf="type.value && type.value == 'CALL'">
              <p>Customers will call location number</p>
            </div>
            <div *ngIf="type.value && type.value != 'CALL'">
              <mat-form-field floatLabel="never"
                              class="input-group input-group--override input-group--override-label no-margin">
                <mat-label>Link for your button</mat-label>
                <input matInput placeholder="Link for your button" [formControl]="url" class="input-group__field" (focusout)="index_focus_out = 'url'">
                <mat-error *ngIf="url.errors">
                  <p *ngIf="url.errors['required']">Url is required</p>
                  <p *ngIf="url.errors['pattern']">Url is not valid</p>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="placeholders-section">
        <mat-accordion [ngClass]="postType != 'STANDARD' ? 'col-12' : 'col-md-7 mb--15 col align-self-end'"*ngIf="isBulk && postType !='OFFER' && form.controls.type.value">
          <mat-expansion-panel class="placeholders-panel" style="margin: 15px 0;" hideToggle>
            <mat-expansion-panel-header style="max-height: 48px !important; padding: 0;">
              <div  class="box box--padding-xxxs box--rounded d-flex justify-content-between align-items-center" style="background-color: #f7f7f7; color: #000;">
                <h6 class="fw--medium m--0">Placeholders</h6>
                <mat-icon class="mat-icon notranslate material-icons mat-icon-no-color" role="img" aria-hidden="true"><span class="mat-expansion-panel-icon" data-html2canvas-ignore="true"></span></mat-icon>
              </div>
            </mat-expansion-panel-header>
            <button mat-flat-button color="primary" style="margin: 5px;" (click)="addPlaceHolder(placeHolder.key)" *ngFor="let placeHolder of placeHolders">{{placeHolder.value}}</button>
            <p style="margin-top: 10px; color: #8c91a2;"><i class="fas fa-info-circle mr--5"></i> Use any of these placeholders to dynamically insert data from your business listing, into your post descriptions and or website links.</p>

          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>

    <ng-container *ngIf="postType!='STANDARD' && postType!='ALERT'">
      <h5 class="fw--medium txt--black mb--10">Add {{postType|titlecase}} Date
        <span 
          *ngIf="postTypeName === 'Event' && recurring?.frecuency !== null"
          matTooltip="Once published, recurring post dates are not editable."
          matTooltipPosition="above"
          matTooltipClass="mat-tooltip--override"
        >
          <i class="fas fa-info-circle txt--lightgray txt--sm"></i>
        </span>
      </h5>
      <div class="row">
        <div class="col-md-6">
          <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
            <input 
              (click)="myDatepicker.open()" 
              class="input-group__field" 
              matInput  
              [matDatepicker]="myDatepicker"
              placeholder="Start" 
              [formControl]="startDate"
              [disabled]="isRecurringEventOrOffer()"
              [readonly]="isRecurringEventOrOffer()"
              [ngClass]="{'text-disabled':isRecurringEventOrOffer()}"
            >
            <mat-datepicker #myDatepicker ></mat-datepicker>
            <mat-error *ngIf="startDate.errors">
              <p *ngIf="startDate.errors['required']">The start date is required</p>
              <p *ngIf="startDate.errors?.beforeEnd">Start date must occur before end date</p>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
            <input (click)="endPicker.open()" 
              class="input-group__field" 
              matInput 
              [min]="date" 
              [matDatepicker]="endPicker"
              placeholder="End" 
              [formControl]="endDate"
              [disabled]="isRecurringEventOrOffer()"
              [readonly]="isRecurringEventOrOffer()"
              [ngClass]="{'text-disabled':isRecurringEventOrOffer()}"
            >
            <mat-datepicker #endPicker></mat-datepicker>
            <mat-error *ngIf="endDate.errors">
              <p *ngIf="endDate.errors['required']">The end date is required</p>
            </mat-error>
          </mat-form-field>
          <mat-error *ngIf="endDate.errors?.sameDate && (endTime && startTime)">
            <p>The event date cannot be in the past. Select a later date and/or time</p>
          </mat-error>
          <mat-error *ngIf="endDate.value < nowDate.setHours(0, 0, 0, 0)">
            <p>The end date must be in the future</p>
          </mat-error>

        </div>

      </div>

      <div class="d-flex align-items-center mb--10" *ngIf="!recurring">
        <h5 class="fw--medium txt--black">Add {{postType|titlecase}} Time</h5>
        <span matTooltip="Google Requires a start and end time for {{postType.toLowerCase()}}s that occur for less than 24 hours. By default this will show from 12:01 am to 11:59 pm but you can customize it as needed"
          matTooltipPosition="above"
          matTooltipClass="mat-tooltip--override"
          *ngIf="isDisabledTime"
          class="ml--5"
        >
          <em class="fas fa-info-circle txt--lightgray txt--sm"></em>
        </span>
        <!--switch-->
        <mat-slide-toggle [disabled]="isDisabledTime" [checked]="timeChecked" (change)="handleCheckTime($event)" color="primary" class="ml--10"></mat-slide-toggle>
      </div>

      <div class="row" *ngIf="timeChecked && !recurring">
        <div class="col-md-6">
          <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
            <mat-label>Start time</mat-label>
            <input class="input-group__field" matInput type="time" [formControl]="startTime">
            <mat-error *ngIf="startTime.errors">
              <p *ngIf="startTime.errors['required']">Start time is required</p>
              <p *ngIf="startTime.errors['minlength']">Min 3 characters</p>
              <p *ngIf="startTime.errors['pattern']">Bad format</p>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
            <mat-label>End time</mat-label>
            <input class="input-group__field" matInput type="time" [formControl]="endTime" (input)="validateEqualDate()">
            <mat-error *ngIf="endTime.errors">
              <p *ngIf="endTime.errors['required']">End time is required</p>
              <p *ngIf="endTime.errors['minorDate']">Event time end must be after the start time</p>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="postType=='OFFER'" class="row" [formGroup]="offer">

        <div class="col-12">
          <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
            <mat-label>Coupon code name (optional)</mat-label>
            <input class="input-group__field" matInput placeholder="Coupon code name (optional)"
                   [formControl]="couponCode">
            <mat-error *ngIf="couponCode?.errors">
              <p *ngIf="couponCode.errors['required']">Name is required</p>
              <p *ngIf="couponCode.errors['minlength']">Min 3 characters</p>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-12">
          <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
            <mat-label>Link to Redeem Offer (optional)</mat-label>
            <input class="input-group__field" matInput placeholder="Link to Redeem Offer (optional)"
                   [formControl]="redeemOnlineUrl">
            <mat-error *ngIf="redeemOnlineUrl?.errors">
              <p *ngIf="redeemOnlineUrl.errors['required']">Name is required</p>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-12">
          <div class="d-flex flex-column">
            <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
              <mat-label>Terms and Conditions (optional)</mat-label>
              <input class="input-group__field" matInput placeholder="Terms and Conditions (optional)"
                     [formControl]="termsConditions">
              <mat-error *ngIf="termsConditions?.errors">
                <p *ngIf="termsConditions.errors['required']">Terms is required</p>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</form>
