// TODO: Refactor against admin-frontend/auth.state.ts

/* 
This service was created to avoid a cyclic dependency between
AuthService and HTTPAuthInterceptor because:
  - AuthService uses HTTPClient which depends on HTTPAuthInterceptor directly
    or thru services like UserService

This is common Angular problem, see:
  - https://github.com/angular/angular/issues/18224
    - note, the solution of using  interceptor.get(AuthService) at runtime doesn't
      really works.

We solve it by creating the following services dependency subgraph:

AuthService  --------depends_on-> AuthServiceProxy
   |                                 ^
  depends_on                         |
   |                                 |
   v                                 |
HTTPAuthInterceptor -depends_on------'
 
HTTPAuthInterceptor will have a soft and deferrable dependency on AuthService, 
as AuthServiceProxy will wait to be initialized by AuthService before starting 
returning requests to HTTPAuthInterceptor
*/ 

// dep
import { Injectable }  from '@angular/core'
import { HttpHeaders } from '@angular/common/http'


@Injectable({
    providedIn: 'root'
  })
export class AuthProxyService {
    
    private callQueue : [()=>void, Promise<unknown>][] = []

    // Only the methods needed by HttpAuthInterceptor are proxied
    private authService_signOut          : (() => void) 
    private authService_headers          : (() => { headers : HttpHeaders } ) 
    private authService_forceAuthRefresh : (() => Promise<string>) 
   
    public async initialize(authService_signOut : () => void,
                            authService_headers : () => { headers : HttpHeaders } ,
                            authService_forceAuthRefresh : () => Promise<string>) : Promise<void> {

        if(this.initialized)
          return

        this.authService_signOut          = authService_signOut 
        this.authService_headers          = authService_headers
        this.authService_forceAuthRefresh = authService_forceAuthRefresh

        const queue = this.callQueue
        this.callQueue = null

        // Serialize delayed calls
        for(const [resume, ended] of queue) {
            // console.debug('de-queued 1')
            resume()
            // console.debug('de-queued 2', ended)

            await ended
            // console.debug('de-queued 3')
          }
    }

    public get initialized() : boolean {
      return this.callQueue === null
    }

    public async forceAuthRefresh() : Promise<string> {
      return await (await this.runOrQueue(() => this.authService_forceAuthRefresh))
    }

    public async authHeaders() : Promise<{headers : HttpHeaders}> {
      return await this.runOrQueue(() => this.authService_headers) 
    }

    public async signOut() : Promise<void> {
      return await this.runOrQueue(() => this.authService_signOut) 
    }

    private async runOrQueue<T>(f : () => (() => T)) : Promise<T> {
      if(this.initialized) {
        return f()()
      } else {
        // console.debug('queued', f)
        let p_ini_resolve
        let p_end_resolve

        const p_ini = new Promise((resolve, _reject) => { p_ini_resolve = resolve })
        const p_end = new Promise((resolve, _reject) => { p_end_resolve = resolve })

        this.callQueue.push([p_ini_resolve, p_end])
        await p_ini        
        const r = f()()
        p_end_resolve()  
        return r
      }
  }

    


}
