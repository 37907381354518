import { __read, __spread } from "tslib";
import { OnInit, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
var OptionsFilterComponent = /** @class */ (function () {
    function OptionsFilterComponent() {
        this.filterControl = new FormControl('');
        this.filteredOptions = [];
        this.options = [];
        this.allOptions = [];
        this.labelFiltered = "All";
        this.isMultiple = true;
        this.hasClear = true;
        this.hasApply = true;
        this.filterChanged = new EventEmitter();
    }
    OptionsFilterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.options = __spread(this.allOptions);
        this.filteredOptionsObs = this.filterControl.valueChanges.pipe(startWith(''), map(function (value) { return _this._filter(value || ''); }));
    };
    OptionsFilterComponent.prototype.getLocationsFiltered = function (event, account) {
        var _this = this;
        this.filteredOptions = [];
        this.labelFiltered = null;
        this.options.forEach(function (acc) {
            acc.checked = acc.accountId == account.account ? event.checked : acc.checked;
            if (acc.checked) {
                _this.filteredOptions.push(acc);
                _this.labelFiltered = _this.labelFiltered ? _this.labelFiltered + " - " + acc.accountName : acc.accountName;
            }
        });
    };
    OptionsFilterComponent.prototype.filterAccountChanged = function (menu, clear, field) {
        if (field === void 0) { field = null; }
        if (clear) {
            this.options.forEach(function (option) { return option.checked = false; });
            this.labelFiltered = 'All';
            this.filteredOptions = [];
        }
        else {
            this.filteredOptions = [];
            this.labelFiltered = null;
            this.filteredOptions.push(field);
            this.labelFiltered = field.accountName;
        }
        this.filterControl.setValue(null);
        menu.close.next(false);
        this.filterChanged.emit(this.filteredOptions);
    };
    OptionsFilterComponent.prototype._filter = function (value) {
        var _this = this;
        var _a, _b;
        this.options = [];
        var filterValue = (_a = value) === null || _a === void 0 ? void 0 : _a.toLowerCase();
        if (value != '') {
            (_b = this.allOptions) === null || _b === void 0 ? void 0 : _b.forEach(function (acc) {
                var name = acc.accountName.toLowerCase();
                if (name.includes(filterValue)) {
                    _this.options.push(acc);
                }
            });
        }
        else {
            this.options = __spread(this.allOptions);
        }
    };
    OptionsFilterComponent.prototype.ngOnChanges = function (changes) {
        var _a;
        if ((_a = changes) === null || _a === void 0 ? void 0 : _a.allOptions) {
            this.options = __spread(this.allOptions);
        }
    };
    return OptionsFilterComponent;
}());
export { OptionsFilterComponent };
