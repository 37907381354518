import { OnInit, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { QuestionsAnswerService } from 'src/app/services/questions-answer.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
var SliderQuestionsComponent = /** @class */ (function () {
    function SliderQuestionsComponent(questionAnswerS, snackS) {
        this.questionAnswerS = questionAnswerS;
        this.snackS = snackS;
        this.question = {};
        this.slider = {};
        this.isImpersonating = false;
        this.isReport = false;
        this.closedSlider = new EventEmitter();
        this.updatingData = false;
        this.newAnswersValue = null;
    }
    SliderQuestionsComponent.prototype.ngOnInit = function () { };
    SliderQuestionsComponent.prototype.ngOnChanges = function (changes) {
        var _a;
        this.newAnswersValue = (_a = this.getOwnerAnswer(this.question)) === null || _a === void 0 ? void 0 : _a.text;
    };
    SliderQuestionsComponent.prototype.closeSlider = function () {
        this.slider.close();
    };
    SliderQuestionsComponent.prototype.sendNewAnswer = function (item, text) {
        var _this = this;
        if (text === void 0) { text = null; }
        var _a;
        var answer = !text ? this.newAnswersValue : text;
        answer = answer.trim();
        var accountId = this.accountId || item.accountId;
        var locationId = this.locationId || item.locationId;
        var hasError = document.getElementById('errorContainer').textContent ? true : false;
        if (!answer || hasError) {
            return;
        }
        this.updatingData = true;
        this.questionAnswerS.addAnswer(this.gid, accountId, locationId, (_a = item) === null || _a === void 0 ? void 0 : _a.questionId, this.displayName, answer).subscribe(function (res) {
            _this.closeSlider();
            _this.closedSlider.emit();
            _this.updatingData = false;
            _this.snackS.openSuccess("The answer was added successfully", 4000);
        }, function (err) {
            _this.closeSlider();
            _this.closedSlider.emit();
            _this.updatingData = false;
            _this.snackS.openError("There was an error while adding the answer", 4000);
        });
    };
    SliderQuestionsComponent.prototype.deleteQuestions = function (question) {
        var _this = this;
        var _a, _b;
        this.updatingData = true;
        var accountId = this.accountId || question.accountId;
        var locationId = this.locationId || question.locationId;
        var answer = this.getOwnerAnswer(question);
        this.questionAnswerS.deleteAnswer(this.gid, accountId, locationId, (_a = question) === null || _a === void 0 ? void 0 : _a.questionId, (_b = answer) === null || _b === void 0 ? void 0 : _b.name).subscribe(function (res) {
            _this.closeSlider();
            _this.closedSlider.emit();
            _this.updatingData = false;
            _this.snackS.openSuccess("The request has been sent to google please check again in some minutes", 4000);
        }, function (err) {
            _this.closeSlider();
            _this.closedSlider.emit();
            _this.updatingData = false;
            _this.snackS.openError("There was an error while deleting the answer", 4000);
        });
    };
    SliderQuestionsComponent.prototype.getOwnerAnswer = function (item) {
        var _a, _b, _c, _d;
        return ((_a = item) === null || _a === void 0 ? void 0 : _a.answers) && ((_b = item) === null || _b === void 0 ? void 0 : _b.hasOwner) ? (_d = (_c = item) === null || _c === void 0 ? void 0 : _c.answers) === null || _d === void 0 ? void 0 : _d.find(function (el) { return (el.author.type).toLowerCase() == 'merchant'; }) : {};
    };
    return SliderQuestionsComponent;
}());
export { SliderQuestionsComponent };
