<div class="dialog__header" *ngIf="!bulk">
    <h2 mat-dialog-title class="m--0 txt--xl"><strong>Name</strong></h2>
</div>
<div class="dialog__content" mat-dialog-content>
    <div class="dialog-row pb--0">
        <mat-form-field class="input-field">
            <input matInput placeholder="Location Name" [(ngModel)]="name" maxlength="125" (ngModelChange)="nameChange()" required>
            <mat-hint align="end">{{name?.length ? name?.length : 0}} / 125</mat-hint>
            <mat-error *ngIf="nameInvalid"><p>Location Name is required</p></mat-error>
            <mat-error *ngIf="hasAllCharacters()" class="txt--red">You exceeded the number of characters</mat-error>
        </mat-form-field>
    </div>

</div>
<div mat-dialog-actions class="dialog__footer" *ngIf="!bulk">
    <span class="alternate-theme d-inline-block mr--10">
        <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
    </span>
    <button mat-flat-button (click)="apply()"  tabIndex="-1" color="accent" class="btn btn--action">Apply</button>
</div>
