

<ng-container *ngIf="type === 'OVERALL-RATING'">

  <div class="d-flex align-items-center">
    <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ height: '55px', width: '70px', 'border-radius': '5px', 'margin-bottom': '0px'}"> </ngx-skeleton-loader>
    <ngx-skeleton-loader count="1" [theme]="{height: '20px',width: '95px', 'margin-left': '15px'}"></ngx-skeleton-loader>
  </div>
</ng-container>

<ng-container *ngIf="type === 'TOTAL-REVIEWS'">
  <ngx-skeleton-loader count="1" [theme]="{height: '65px',width: '100px','border-radius': '5px'}"> </ngx-skeleton-loader>
</ng-container>

<ng-container *ngIf="type === 'RATING-DISTRIBUTION'">
  <ngx-skeleton-loader count="5" [theme]="{height: '20px','margin-bottom': '5px'}"> </ngx-skeleton-loader>
</ng-container>

<ng-container *ngIf="type === 'REVIEW-RESPONSES'">
  <ngx-skeleton-loader count="2" [theme]="{height: '50px','margin-bottom': '15px'}"> </ngx-skeleton-loader>
</ng-container>

<ng-container *ngIf="type === 'AVERAGE-STAR-RATING'">
  <ngx-skeleton-loader count="1" [theme]="{height: '340px','margin-bottom': '0'}"> </ngx-skeleton-loader>
</ng-container>

<ng-container *ngIf="type === 'NUMBER-NEW-REVIEWS'">
  <ngx-skeleton-loader count="1" [theme]="{height: '340px','margin-bottom': '0'}"> </ngx-skeleton-loader>
</ng-container>

<!--filter table without location column-->
<ng-container *ngIf="type === 'KEYWORDS'">

  <div class="row no-gutters">
    <div class="col-12 col-md-6 col-lg-6" *ngFor="let i of [0,1]">

      <div class="box box--flex box--padding">
        <div class="row align-items-center w--100">
          <div class="col-12 col-md-4">
            <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ height: '140px', width: '100%', 'border-radius': '5px', 'margin-bottom': '0px'}"> </ngx-skeleton-loader>
          </div>
          <div class="col-12 col-md-8">
            <ngx-skeleton-loader count="1" [theme]="{ height: '20px', width: '70px', display: 'block'}"> </ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" [theme]="{ height: '15px', width: '100px', 'margin-top': '20px', display: 'block'}"> </ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" [theme]="{ height: '70px', width: '200px', 'margin-top': '20px'}"> </ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-container>

<!--filter table with location column-->
<ng-container *ngIf="type === 'FILTERS' && isSingle">

  <table class="table table--bordered">
    <thead>
    <tr class="mat-row">
      <th class="mat-header-cell txt--left"><strong>Location</strong></th>
      <th class="mat-header-cell txt--center"><strong>Star Rating</strong></th>
      <th class="mat-header-cell txt--center"><strong>Date</strong></th>
      <th class="mat-header-cell txt--center"><strong>Response</strong></th>
      <th class="mat-header-cell txt--center"><strong>Status</strong></th>
      <th class="mat-header-cell txt--center"><strong>Reviewer</strong></th>
      <th class="mat-header-cell txt--center"><strong>Actions</strong></th>
    </tr>
    </thead>
    <tbody>
    <tr class="mat-row no-hover border--full" *ngFor="let i of [0,1,2]">

      <td class="mat-cell cdk-column-location" style="width: 20%;">
        <div class="cell__content flex-column align-items-start">
          <ngx-skeleton-loader count="1" [theme]="{ width: '80px', height: '20px', 'border-radius': '4px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" [theme]="{ width: '140px', height: '20px', 'border-radius': '4px', 'display':'block', 'margin-top': '5px' }"></ngx-skeleton-loader>

          <ngx-skeleton-loader style="display: flex;" count="3" [theme]="{ display: 'block', width: '30px', height: '30px', 'border-radius': '4px', 'margin-top': '10px', 'margin-right': '5px', 'margin-bottom': '0' }"></ngx-skeleton-loader>
        </div>
      </td>
      <td class="mat-cell cdk-column-rating txt--center" style="width: 12%;">
        <div class="d-flex flex-column align-items-center">
          <ngx-skeleton-loader count="1" [theme]="{ width: '20px', height: '20px', 'border-radius': '4px', 'display':'block' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px', 'border-radius': '4px', 'display':'block', 'margin-top': '10px' }"></ngx-skeleton-loader>
        </div>
      </td>

      <td class="mat-cell cdk-column-date--resp txt--center">
        <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px'}"></ngx-skeleton-loader>
      </td>

      <td class="mat-cell mat-column-status txt--center" style="width: 15%;">
        <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '25px', height: '25px' }"></ngx-skeleton-loader>
      </td>

      <td class="mat-cell cdk-column-reviewer txt--center">
        <div class="p--lr-30">
          <ngx-skeleton-loader count="1" [theme]="{ width: '110px', height: '20px' }"></ngx-skeleton-loader>
        </div>
      </td>

      <td class="mat-cell cdk-column-reviewer">
        <div class="p--lr-30">
          <ngx-skeleton-loader count="1" [theme]="{ width: '110px', height: '20px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '40px', 'margin-top': '5px'}"></ngx-skeleton-loader>
        </div>
      </td>

      <td class="mat-cell cdk-column-actions column--sm txt--center">
        <div class="cell__content justify-content-center">
          <ngx-skeleton-loader count="1" [theme]="{ width: '140px', height: '30px' }"></ngx-skeleton-loader>
        </div>
      </td>
    </tr>
    </tbody>
  </table>

</ng-container>

<ng-container *ngIf="type === 'FILTERS' && !isSingle">

  <table class="table table--bordered">
    <thead>
    <tr class="mat-row">
      <th class="mat-header-cell txt--center"><strong>Star Rating</strong></th>
      <th class="mat-header-cell txt--center"><strong>Date</strong></th>
      <th class="mat-header-cell txt--center"><strong>Response</strong></th>
      <th class="mat-header-cell txt--center"><strong>Status</strong></th>
      <th class="mat-header-cell txt--center"><strong>Reviewer</strong></th>
      <th class="mat-header-cell txt--center"><strong>Actions</strong></th>
    </tr>
    </thead>
    <tbody>
    <tr class="mat-row no-hover border--full" *ngFor="let i of [0,1,2]">

      <td class="mat-cell cdk-column-rating txt--center" style="width: 20%;">
        <div class="d-flex flex-column align-items-center">
          <ngx-skeleton-loader count="1" [theme]="{ width: '20px', height: '20px', 'border-radius': '4px', 'display':'block' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px', 'border-radius': '4px', 'display':'block', 'margin-top': '10px' }"></ngx-skeleton-loader>
        </div>
      </td>

      <td class="mat-cell cdk-column-date--resp txt--center">
        <ngx-skeleton-loader count="1" [theme]="{ width: '80px', height: '20px'}"></ngx-skeleton-loader>
      </td>

      <td class="mat-cell mat-column-status txt--center">
        <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '25px', height: '25px' }"></ngx-skeleton-loader>
      </td>

      <td class="mat-cell cdk-column-reviewer txt--center">
        <div class="p--lr-30">
          <ngx-skeleton-loader count="1" [theme]="{ width: '30px', height: '20px' }"></ngx-skeleton-loader>
        </div>
      </td>

      <td class="mat-cell cdk-column-reviewer">
        <div class="p--lr-30">
          <ngx-skeleton-loader count="1" [theme]="{ width: '110px', height: '20px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '40px', 'margin-top': '5px'}"></ngx-skeleton-loader>
        </div>
      </td>

      <td class="mat-cell cdk-column-actions column--sm txt--center">
        <div class="cell__content justify-content-center">
          <ngx-skeleton-loader count="1" [theme]="{ width: '140px', height: '30px' }"></ngx-skeleton-loader>
        </div>
      </td>
    </tr>
    </tbody>
  </table>

</ng-container>
