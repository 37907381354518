import { __awaiter, __generator } from "tslib";
// dep
import { ElementRef, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
// app
import { ModalService } from '../services/modal.service';
import { AuthService } from '../services/auth.service';
import { LocationService } from '../services/location.service';
import { SubscriptionService } from '../services/subscription.service';
import { GROUP_SUBSCRIPTION_TYPE, LOCATION_SUBSCRIPTION_TYPE } from '../constants/firestore/account-location';
import { FeaturedComponent } from '../components/featured.component';
var LocationGatedFreeDirective = /** @class */ (function () {
    function LocationGatedFreeDirective(route, modalService, locationService, auth, subscriptionService, element) {
        var _this = this;
        this.route = route;
        this.modalService = modalService;
        this.locationService = locationService;
        this.auth = auth;
        this.subscriptionService = subscriptionService;
        this.element = element;
        this.denyAccess = new EventEmitter();
        this.subscription$ = this.auth.subscription$;
        this.subscription$.subscribe(function (subscription) { return _this.subscription = subscription; });
    }
    LocationGatedFreeDirective.prototype.ngOnInit = function () {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var locationLGF, placeId, account, checked, subscriptionType;
            return __generator(this, function (_d) {
                this.user = this.auth.session;
                locationLGF = this.gmbLGF.location;
                placeId = _.get(locationLGF, 'locationId') || this.route.snapshot.parent.params.locationId;
                account = this.gmbLGF.account || this.route.snapshot.parent.params.accountId;
                checked = _.get(locationLGF, 'checked');
                if (!placeId || !account || checked) {
                    return [2 /*return*/];
                }
                this.location = this.gmbLGF.location;
                subscriptionType = (_a = this.location) === null || _a === void 0 ? void 0 : _a.subscriptionType;
                if (((_b = this.subscription) === null || _b === void 0 ? void 0 : _b.pricingVersion) < 3 &&
                    ((_c = this.subscription) === null || _c === void 0 ? void 0 : _c.status) !== GROUP_SUBSCRIPTION_TYPE.TRIAL &&
                    (subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE ||
                        subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL)) {
                    this.element.nativeElement.removeAllListeners('click');
                    this.element.nativeElement.addEventListener('click', this.handler.bind(this));
                    this.denyAccess.emit(true);
                    return [2 /*return*/];
                }
                this.denyAccess.emit(false);
                return [2 /*return*/];
            });
        });
    };
    LocationGatedFreeDirective.prototype.ngOnChanges = function (changes) {
        if (!changes.gmbLGF.firstChange) {
            this.ngOnInit();
        }
    };
    // TODO: Search #TODO-refactor-featured-model to find code to refactor against this 
    // code block. 
    LocationGatedFreeDirective.prototype.handler = function () {
        return __awaiter(this, void 0, void 0, function () {
            var locationLGF, locationId, accountId, _a, subscriptionType, locationRef;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        console.log('LocationGatedFreeDirective handler');
                        locationLGF = this.gmbLGF.location;
                        locationId = _.get(locationLGF, 'locationId') || this.route.snapshot.parent.params.locationId;
                        accountId = this.gmbLGF.account || this.route.snapshot.parent.params.accountId;
                        _a = this;
                        return [4 /*yield*/, this.locationService.fetchLocation(this.user.gid, accountId, locationId)];
                    case 1:
                        _a.location = _b.sent();
                        subscriptionType = this.location.subscriptionType;
                        if (!(this.subscription.status !== GROUP_SUBSCRIPTION_TYPE.TRIAL && (subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE ||
                            subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL))) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.modalService.openModal(FeaturedComponent, null)];
                    case 2:
                        if (!(_b.sent()))
                            return [2 /*return*/];
                        locationRef = { locationId: locationId, accountId: accountId };
                        return [4 /*yield*/, this.subscriptionService.flowChangeLocationsPlan(this.subscription, [locationRef])];
                    case 3:
                        if (_b.sent()) {
                            this.denyAccess.emit(false);
                            this.element.nativeElement.removeAllListeners('click');
                        }
                        _b.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return LocationGatedFreeDirective;
}());
export { LocationGatedFreeDirective };
var LocationGatedUltimateTrialDirective = /** @class */ (function () {
    function LocationGatedUltimateTrialDirective(route, modalService, locationService, auth, subscriptionService, element) {
        var _this = this;
        this.route = route;
        this.modalService = modalService;
        this.locationService = locationService;
        this.auth = auth;
        this.subscriptionService = subscriptionService;
        this.element = element;
        this.gmbLGUF = function () { };
        this.subscription$ = this.auth.subscription$;
        this.subscription$.subscribe(function (subscription) { return _this.subscription = subscription; });
    }
    LocationGatedUltimateTrialDirective.prototype.ngOnInit = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var locationId, accountId, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        this.user = this.auth.session;
                        locationId = this.route.snapshot.parent.params.locationId;
                        accountId = this.route.snapshot.parent.params.accountId;
                        if (!locationId || !accountId) {
                            return [2 /*return*/];
                        }
                        _c = this;
                        return [4 /*yield*/, this.locationService.fetchLocation(this.user.gid, accountId, locationId)];
                    case 1:
                        _c.location = _d.sent();
                        if (((_a = this.subscription) === null || _a === void 0 ? void 0 : _a.pricingVersion) < 3 &&
                            ((_b = this.subscription) === null || _b === void 0 ? void 0 : _b.status) === GROUP_SUBSCRIPTION_TYPE.TRIAL &&
                            (this.location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE ||
                                this.location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL)) {
                            this.element.nativeElement.removeAllListeners('click');
                            this.element.nativeElement.addEventListener('click', this.handler.bind(this));
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    // TODO: Search #TODO-refactor-featured-model to find code to refactor against this 
    // code block. 
    LocationGatedUltimateTrialDirective.prototype.handler = function () {
        return __awaiter(this, void 0, void 0, function () {
            var locRef;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('LocationGatedUltimateTrialDirective handler');
                        return [4 /*yield*/, this.modalService.openModal(FeaturedComponent, null)];
                    case 1:
                        if (!(_a.sent()))
                            return [2 /*return*/];
                        locRef = { locationId: this.location.locationId,
                            accountId: this.route.snapshot.parent.params.accountId };
                        return [4 /*yield*/, this.subscriptionService.flowChangeLocationsPlan(this.subscription, [locRef])];
                    case 2:
                        if (_a.sent())
                            console.log("upgrade successful - location-gated.directive");
                        return [2 /*return*/];
                }
            });
        });
    };
    return LocationGatedUltimateTrialDirective;
}());
export { LocationGatedUltimateTrialDirective };
var LocationGatedBasicDirective = /** @class */ (function () {
    function LocationGatedBasicDirective(route, modalService, locationService, auth, subscriptionService, element) {
        var _this = this;
        this.route = route;
        this.modalService = modalService;
        this.locationService = locationService;
        this.auth = auth;
        this.subscriptionService = subscriptionService;
        this.element = element;
        this.denyAccess = new EventEmitter();
        this.subscription$ = this.auth.subscription$;
        this.subscription$.subscribe(function (subscription) { return _this.subscription = subscription; });
    }
    LocationGatedBasicDirective.prototype.ngOnInit = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var locationLGB, placeId, account, checked, subscriptionType;
            return __generator(this, function (_c) {
                this.user = this.auth.session;
                locationLGB = this.gmbLGB.location;
                placeId = _.get(locationLGB, 'locationId') || this.route.snapshot.parent.params.locationId;
                account = this.gmbLGB.account || this.route.snapshot.parent.params.accountId;
                checked = _.get(locationLGB, 'checked');
                if (!placeId || !account || checked) {
                    return [2 /*return*/];
                }
                this.location = this.gmbLGB.location;
                subscriptionType = (_a = this.location) === null || _a === void 0 ? void 0 : _a.subscriptionType;
                if (((_b = this.subscription) === null || _b === void 0 ? void 0 : _b.pricingVersion) < 3 &&
                    subscriptionType === LOCATION_SUBSCRIPTION_TYPE.BASIC && !this.gmbLGB.user) {
                    this.element.nativeElement.removeAllListeners('click');
                    this.element.nativeElement.addEventListener('click', this.handler.bind(this));
                    this.denyAccess.emit(true);
                }
                return [2 /*return*/];
            });
        });
    };
    LocationGatedBasicDirective.prototype.ngOnChanges = function (changes) {
        if (!changes.gmbLGB.firstChange) {
            this.ngOnInit();
        }
    };
    // TODO: Search #TODO-refactor-featured-model to find code to refactor against this 
    // code block. 
    LocationGatedBasicDirective.prototype.handler = function () {
        return __awaiter(this, void 0, void 0, function () {
            var locationLGB, locationId, accountId, _a, locationRef, r;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        console.log('LocationGatedBasicDirective handler');
                        if (!this.auth.isMember) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.modalService.openWarningModal('Contact your administrator', 'Your user doesn’t have permissions to do this. Contact your account administrator to change your role.')];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                    case 2:
                        locationLGB = this.gmbLGB.location;
                        locationId = _.get(locationLGB, 'locationId') || this.route.snapshot.parent.params.locationId;
                        accountId = this.gmbLGB.account || this.route.snapshot.parent.params.accountId;
                        _a = this;
                        return [4 /*yield*/, this.locationService.fetchLocation(this.user.gid, accountId, locationId)];
                    case 3:
                        _a.location = _b.sent();
                        if (!(this.location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.BASIC)) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.modalService.openModal(FeaturedComponent, null)];
                    case 4:
                        if (!(_b.sent()))
                            return [2 /*return*/];
                        locationRef = { locationId: locationId, accountId: accountId };
                        return [4 /*yield*/, this.subscriptionService.flowChangeLocationsPlan(this.subscription, [locationRef])];
                    case 5:
                        r = _b.sent();
                        if (r) {
                            // FIXME: redundant comparison against ULTIMATE, bug?
                            if (this.subscription.status !== GROUP_SUBSCRIPTION_TYPE.TRIAL && (r.nextPlan === LOCATION_SUBSCRIPTION_TYPE.ULTIMATE // ||
                            //r.nextPlan === LOCATION_SUBSCRIPTION_TYPE.ULTIMATE
                            )) {
                                this.denyAccess.emit(false);
                            }
                            else {
                                this.ngOnInit();
                            }
                        }
                        _b.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    return LocationGatedBasicDirective;
}());
export { LocationGatedBasicDirective };
