import { __awaiter, __generator } from "tslib";
// dep
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, share } from 'rxjs/operators';
// app
import { SubscriptionService } from '../../services/subscription.service';
import { GROUP_SUBSCRIPTION_TYPE, LOCATION_SUBSCRIPTION_TYPE } from '../../constants/firestore/account-location';
import { LocationService } from '../../services/location.service';
import { AuthService } from '../../services/auth.service';
var TrialMessageComponent = /** @class */ (function () {
    function TrialMessageComponent(_route, _subscriptionService, _authService, _locationService) {
        this._route = _route;
        this._subscriptionService = _subscriptionService;
        this._authService = _authService;
        this._locationService = _locationService;
        // isOnMasterAdmin = false; // TODO: Unused, remove.
        this.LOCATION_SUBSCRIPTION_TYPE = LOCATION_SUBSCRIPTION_TYPE;
        this.GROUP_SUBSCRIPTION_TYPE = GROUP_SUBSCRIPTION_TYPE;
        this.subscription$ = this._authService.subscription$;
    }
    TrialMessageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscription$ = this._authService.subscription$;
        this.subscription$.subscribe(function (subscription) {
            _this.subscription = subscription;
            _this._getLocation();
        });
    };
    TrialMessageComponent.prototype._getLocation = function () {
        var _this = this;
        this._route.params.subscribe(function (_a) {
            var accountId = _a.accountId, locationId = _a.locationId;
            _this.locationId = locationId;
            _this.accountId = accountId;
            if (locationId && accountId) {
                _this.location$ = _this._locationService.getRef(_this._authService.session.gid, accountId, locationId).pipe(share(), map(function (res) {
                    return res;
                }));
                _this.isOnLocation = true;
            }
        });
    };
    TrialMessageComponent.prototype.isMember = function () {
        return this._authService.isMember;
    };
    TrialMessageComponent.prototype.openUpgrade = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        console.log('TrialMessageComponent upgrade suscription');
                        if (!this.isOnLocation) return [3 /*break*/, 3];
                        _a = this;
                        return [4 /*yield*/, this._locationService.getRef(this._authService.session.gid, this.accountId, this.locationId).toPromise()];
                    case 1:
                        _a.location = _b.sent();
                        return [4 /*yield*/, this._subscriptionService.flowChangeLocationsPlan(this.subscription, [{ locationId: this.location.locationId,
                                    accountId: this.accountId }])];
                    case 2:
                        if (_b.sent())
                            this.ngOnInit();
                        return [3 /*break*/, 6];
                    case 3: return [4 /*yield*/, this._subscriptionService.stopTrial(this.subscription)];
                    case 4:
                        if (!_b.sent()) return [3 /*break*/, 6];
                        return [4 /*yield*/, this._authService.processSubscription()
                            // Locations are changed from FREE to ESSENTIAL
                        ];
                    case 5:
                        _b.sent();
                        // Locations are changed from FREE to ESSENTIAL
                        this._locationService.someLocationChanged.next(null);
                        this.ngOnInit();
                        _b.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    return TrialMessageComponent;
}());
export { TrialMessageComponent };
