import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as firebase from 'firebase/app';
import { WhiteLabelService } from 'src/app/services/white-label.service';
import {environment} from '@environment';
import { VerificationEmailService } from 'src/app/services/verification-email.service';
import * as _ from "lodash";
//import { UserService } from 'src/app/services/user.service';
// import User from 'src/app/constants/firestore/user';


@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  verified: boolean;
  env: any;
  loginProgress;
  
  constructor(
    private route: ActivatedRoute,
    private verificationService: VerificationEmailService,
    public wl: WhiteLabelService,
    // private userService: UserService
  ) {
  }

  async ngOnInit() {
    this.loginProgress = true;
    this.env = environment;
    const {hash} = this.route.snapshot.queryParams;
    await this.verificationService.getVerificationToHash(hash).toPromise().then( ver => {
      ver.forEach( v => {
        if (!v) {
          this.verified = false;
          return;
        }
        const data = v.data()
        if ( data.emailVerified == null ) {
          this.verificationService.update(v.id, { 'emailVerified': firebase.firestore.Timestamp.now()})
        }
        this.verified = true;
      })
    })
    this.loginProgress = false;
  }

  goToTermsOfService(): void {
    this.wl.goToTermsOfService()
  }

  goToPrivacyPolicy(): void {
    this.wl.goToPrivacyPolicy()
  }

  goToPaymentPolicy(): void {
    this.wl.goToPaymentPolicy()
  }

  handleContactUs(): void {
    this.wl.goToContactSupport()
  }

}
