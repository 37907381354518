
<div class="dialog__header">
    <div class="dialog__close p--25">
        <button mat-flat-button class="btn btn-icon btn-icon--lg" (click)="closeSlider()">
            <em class="fas fa-long-arrow-right txt--blue"></em>
        </button>
    </div>
    <h2 mat-dialog-title class="txt--xl p--25 m--0 pb--0"><strong>Question and Answers</strong></h2>
</div>

<div class="row box--height">
    <div class="spinner-wrapper spinner-wrapper--fixed" *ngIf="updatingData">
        <mat-spinner></mat-spinner>
    </div>
    
    <div class="col-12">
        <div class="box box--padding-xxs">
        <!-- HEADER -->
        <section class="header-card">
            <div class="col-12 row p--0 m--0">
                <span *ngIf="!question?.question?.author?.profilePhotoUri" class="object-fit-contain mr--25 bg--dark--gray border--radius-50 txt--center">
                    <em class="fa fa-user txt--dark--white txt--52 p--10 pb--5"></em>
                </span> 
                <img *ngIf="question?.question?.author?.profilePhotoUri" class="object-fit-contain w--8 h--8  mr--25" src="{{question?.question?.author?.profilePhotoUri}}" alt="image profile"/>

                <div class="row w--90">
                    <div class="col-11 p--0 header-card-data">
                        <p class="txt--md fw--semibold m--0 min--height--20">{{question?.question?.author?.displayName}}</p>
                        <p class="txt--sm mt--5 mb--5">{{question?.question?.createTime | dateAgo}}</p>
                        <span *ngIf="question?.answers?.totalSize > 0" class="txt--xs bg--gray txt--dark--gray p--tb-2 p--lr-5 border--radius-3">
                            {{question?.answers?.totalSize}} Answers
                        </span>
                        <span class="border--radius-3 txt--white p--tb-2 p--lr-5 txt--xs"
                        [ngClass]="{
                            'bg--red': question?.status == 'No Answer',
                            'bg--yellow': question?.status == 'No Owner Answer',
                            'bg--green': question?.status == 'Answered By Owner'
                        }">{{question?.status}}</span>
                    </div>
                </div>
                <div>
                    <p class="col-12 txt--md mt--10 ml--5 p--0">{{question?.question?.text}}</p>
                    <div *ngIf="isReport" class="bg---gray-200 w--100 mt--10 mb--15 p--10 border--radius-3">
                        <p class="m--0 txt--blue">{{question?.locationName}}</p>
                        <gmb-address class="txt--lightgray m--0" [address]="question?.address" *ngIf="question?.address"></gmb-address>
                    </div>
                </div>
            </div>
            
        </section>

        <mat-divider></mat-divider>

        <!-- ANSWERS -->
        <section class="vh--46 answer-card mt--15" [class.is-impersonating]="isImpersonating">
            <p *ngIf="!question?.answers?.length" class="txt--medium-gray">This question has no answers yet.</p>
            <div *ngFor="let answer of question?.answers" class="col-12 row p--0 m--0 answer-card-info mb--15">
                
                <span *ngIf="!answer?.author?.profilePhotoUri" class="img-answers-profile-default  mr--12 bg--dark--gray border--radius-50 txt--center">
                    <em class="fa fa-user txt--dark--white"></em>
                </span>
                <img *ngIf="answer?.author?.profilePhotoUri" class="object-fit-contain w--4 h--4  mr--10" src="{{answer?.author?.profilePhotoUri}}" alt="image profile"/>
                
                <div class="p--0 pl--15 w--94">
                    <p class="col-10 p--0 display-in-bl txt--md fw--semibold m--0 min--height--20">{{answer?.author?.displayName}}</p>
                    <section>
                    <p class="txt--14 txt--dark--gray m--0 pr--5 display-in-bl" [class.w--85]="answer?.status == 'FAIL' || answer?.status == 'PENDING'">{{answer?.text}}</p>  
                    <p *ngIf="answer?.status == 'FAIL'" class="d--inl-block ml--20 position--absolute txt--red" matTooltip="There was an error while deleting the answer." matTooltipPosition="below" matTooltipClass="tooltip tooltip--black">
                        <em class="fas fa-exclamation-triangle txt--22"></em>
                      </p>
                    <p *ngIf="answer?.status == 'PENDING'" class="d--inl-block ml--30 position--absolute txt--yellow" matTooltip="The request is being processed by Google and changes may take a few moments to be updated on your business profile." matTooltipPosition="below" matTooltipClass="tooltip tooltip--black">
                        <em class="fas fa-exclamation-triangle txt--22"></em>
                      </p>
                    </section>
                    <p class="txt--xs txt--lightgray m--0 mt--5">{{answer?.createTime | dateAgo}}</p>
                  </div>
            </div>
        </section>

        <mat-divider></mat-divider>

        <!-- SEND MESSAGE -->
        <section class="send-card">
            <div class="col-12 row m--0 mt--15 mb--15">
            <p *ngIf="question?.hasOwner" class="col-12 p--0">Answer from the business owner</p>
            <span class="object-fit-contain w--4 mr--18 txt--center">
                <em class="fa fa-store txt--lightgray txt--18 pt--5"></em>
            </span>
            <textarea matInput placeholder="Post your answer publicly..." name="answer" [class.textarea-hasOwner]="question?.hasOwner" [(ngModel)]="newAnswersValue" [appEmailPhoneValidation]="'errorContainer'"></textarea>
            <button mat-flat-button *ngIf="!question?.hasOwner" class="btn btn-icon buttons-actions btn-send ml--10 bg--green txt--white" (click)="sendNewAnswer(question)">
                <em class="fa fa-location-arrow mr--5"></em>
            </button>
            <button mat-flat-button color="primary" class="btn btn-icon buttons-actions ml--10" *ngIf="question?.hasOwner" (click)="sendNewAnswer(question)" matTooltip="Edit your answer" matTooltipPosition="below" matTooltipClass="tooltip tooltip--black">
                <em class="fas fa-pencil"></em>
            </button>
            <button mat-flat-button color="warn" class="btn btn-icon buttons-actions ml--10" *ngIf="question?.hasOwner" (click)="deleteQuestions(question)" matTooltip="Delete your answer" matTooltipPosition="below" matTooltipClass="tooltip tooltip--black">
                <em class="far fa-trash-alt"></em>
            </button>
            <div id="errorContainer" class="txt--red txt--sm mt--5 ml--45"></div>
            </div>
        </section>
        </div>
    </div>
</div>
