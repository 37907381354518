import {Component, Input, OnInit} from '@angular/core';
import {Observations} from '../../../constants/firestore/observation';

@Component({
  selector: 'app-workshop-observation',
  templateUrl: './workshop-observation.component.html',
  styleUrls: ['./workshop-observation.component.scss']
})
export class WorkshopObservationComponent implements OnInit {

  @Input() observation: Observations;
  @Input() lastUpdate: any;

  constructor() {
  }

  ngOnInit() {
  }

}
