// dep
import {Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// app
import {REPORT_TYPE} from "../../constants/firestore/report";

@Component({
  selector: 'app-comparison-reports',
  templateUrl: './comparison-reports.component.html',
  styleUrls: ['./comparison-reports.component.scss']
})
export class ComparisonReportsComponent implements OnInit {

  reportType: REPORT_TYPE = 'performance-comparison';

  constructor(
    private _route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.reportType = this._route.snapshot?.routeConfig?.path?.replace('-report','') as REPORT_TYPE;
  }
}
