// dep
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// app
import { CreateProfileRequest } from 'src/app/lib/api-requests/create-profile-request';
import { CreateProfileResponse } from 'src/app/lib/api-responses/create-profile-response';
import { GetProfilesRequest } from 'src/app/lib/api-requests/get-profiles-request';
import { GetProfilesResponse } from 'src/app/lib/api-responses/get-profiles-response';
import { environment as ENV} from '@environment'

@Injectable({
  providedIn: 'root'
})
export class ProfilesService {

  constructor(
    private http: HttpClient,
  ) { }

  createProfile(request: CreateProfileRequest): Observable<CreateProfileResponse> {
    return this.http.post<CreateProfileResponse>(`${ENV.coreApiUrl}/profiles`, request)
  }

  getProfiles(request: GetProfilesRequest): Observable<GetProfilesResponse> {
    return this.http.post<GetProfilesResponse>(`${ENV.coreApiUrl}/profiles/list`, request)
  }
}
