import { __awaiter, __generator, __values } from "tslib";
import { EventEmitter, OnDestroy, OnInit, OnChanges } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as _ from 'lodash';
import { LocationService } from '../../services/location.service';
import { AuthService } from '../../services/auth.service';
import { GROUP_SUBSCRIPTION_TYPE, LOCATION_SUBSCRIPTION_TYPE } from '../../constants/firestore/account-location';
import { GroupService } from '../../services/group.service';
import { ProtocolService } from '../../services/protocol.service';
import { MatAccordion } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { Router } from '@angular/router';
var FlexSearch = require('flexsearch');
var ToggleLocationsInAccountComponent = /** @class */ (function () {
    function ToggleLocationsInAccountComponent(_locationS, _protocolService, _authService, _groupService, _http, _router) {
        var _this = this;
        this._locationS = _locationS;
        this._protocolService = _protocolService;
        this._authService = _authService;
        this._groupService = _groupService;
        this._http = _http;
        this._router = _router;
        this._destroySubs$ = new Subject();
        this._isEdit = false;
        this.loadingSearch$ = new BehaviorSubject(false);
        this.toogleIcon = true;
        this.noAccounts = false;
        this.flexSearch = new FlexSearch({
            encode: "advanced",
            tokenize: "reverse",
            suggest: true,
            cache: true,
            doc: {
                id: 'id',
                field: [
                    'accountName',
                    'locationName',
                    'locName',
                    'address',
                    'serviceArea',
                    'reportName',
                    'labels'
                ]
            }
        });
        this.accounts = [];
        this.isFilter = false;
        this.listEventSelect = [];
        this.hasInfoNotification = false;
        // TimeOut search
        this.timeout = null;
        this.selectByUser = false;
        this.checkUpgrade = false;
        this.isPost = false;
        this.safeNumberLocations = true;
        this.filterProtocolLocations = false;
        this.isSliderOpened = false;
        this.isSlider = false;
        this.txtInfoNotification = null;
        this.hasGoToLocation = false;
        this.selected = new EventEmitter();
        this.subscription$ = this._authService.subscription$;
        this.subscription$.subscribe(function (subscription) { return _this.subscription = subscription; });
    }
    ToggleLocationsInAccountComponent.prototype.disableAccounts = function (accounts) {
        return accounts.map(function (account) {
            account.checked = false;
            return account;
        });
    };
    ToggleLocationsInAccountComponent.prototype.ngOnInit = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                this.search = null;
                this.onTrial = ((_a = this.subscription) === null || _a === void 0 ? void 0 : _a.status) === GROUP_SUBSCRIPTION_TYPE.TRIAL;
                if (this.reportEdit && this.reportEdit.length > 0) {
                    this.listEventSelect = JSON.parse(JSON.stringify(this.reportEdit));
                }
                this.setInfoNotification();
                this.getAccounts();
                return [2 /*return*/];
            });
        });
    };
    ToggleLocationsInAccountComponent.prototype.ngOnChanges = function () {
        var _a;
        if (this.isSlider) {
            if (!this.isSliderOpened) {
                this.removeAllChecked();
                this.ngOnDestroy();
                (_a = this.accordion) === null || _a === void 0 ? void 0 : _a.closeAll();
            }
            else {
                this.ngOnInit();
            }
        }
    };
    ToggleLocationsInAccountComponent.prototype.removeAllChecked = function () {
        var _this = this;
        var _a;
        (_a = this.listEventSelect) === null || _a === void 0 ? void 0 : _a.forEach(function (el) {
            var _a, _b, _c;
            var account = _this.accounts.find(function (a) { return a.accountId === el.accountId; });
            if ((_a = account) === null || _a === void 0 ? void 0 : _a.selectAll) {
                _this.changeAccountSelected(account);
            }
            else {
                (_c = (_b = account) === null || _b === void 0 ? void 0 : _b.locations) === null || _c === void 0 ? void 0 : _c.forEach(function (l) {
                    var _a;
                    if ((_a = l) === null || _a === void 0 ? void 0 : _a.checked) {
                        _this.changeLocationsSelected(l, account);
                    }
                });
            }
        });
    };
    ToggleLocationsInAccountComponent.prototype.getAccounts = function () {
        var _this = this;
        if (this.filterProtocolLocations) {
            this._protocolService.getAccountsProtocols()
                .subscribe(function (accounts) {
                if (accounts.length > 0) {
                    _this.accounts = _this._accountsCopy = _this.disableAccounts(Object.assign(accounts, []));
                }
                else {
                    _this.noAccounts = true;
                }
            });
        }
        else {
            this._groupService.get_accounts(this._authService.session.gid).subscribe(function (accounts) {
                if (accounts) {
                    _this.accounts = _this._accountsCopy = _this.disableAccounts(Object.assign(accounts, []));
                }
            });
        }
    };
    ToggleLocationsInAccountComponent.prototype.getAccountsLocations = function (query) {
        var url;
        if (this.filterProtocolLocations === true) {
            url = environment.apiUrl + "/v2/search/gid/" + this._authService.session.gid + "/account-locations/protocols";
        }
        else {
            url = environment.apiUrl + "/v2/search/gid/" + this._authService.session.gid + "/account-locations";
        }
        return this._http.post(url, { 'query': query });
    };
    ToggleLocationsInAccountComponent.prototype.changeAccountSelected = function (a) {
        var _this = this;
        if (a.checked && a.selectAll) {
            a.checked = false;
            a.selectAll = false;
            a.locations.forEach(function (l) {
                l.checked = false;
                _this.setChange(l, a);
                _this.removeToList(l, a);
            });
        }
        else if (!a.selectAll) {
            a.selectAll = true;
            a.checked = true;
            a.locations.forEach(function (l) {
                l.checked = !l.deny;
                _this.setChange(l, a);
                if (!l.deny) {
                    _this.AddToList(l, a);
                }
            });
        }
        this.setInfoNotification();
        this.selected.emit(this.listEventSelect);
    };
    ToggleLocationsInAccountComponent.prototype.removeToList = function (l, a) {
        var Search_Account = this.listEventSelect.find(function (r) { return r.accountId === a.accountId; });
        if (Search_Account) {
            var search_locations = Search_Account.locations.find(function (locationId) { return locationId.locationId === l.locationId; });
            if (search_locations) {
                var locationsFilter_1 = Search_Account.locations.filter(function (locationId) { return locationId.locationId !== l.locationId; });
                if (locationsFilter_1.length === 0) {
                    this.listEventSelect = this.listEventSelect.filter(function (acc) { return acc.accountId !== a.accountId; });
                }
                else {
                    this.listEventSelect = this.listEventSelect.map(function (acc) {
                        if (acc.accountId === a.accountId) {
                            acc.locations = locationsFilter_1;
                        }
                        return acc;
                    });
                }
            }
        }
    };
    ToggleLocationsInAccountComponent.prototype.AddToList = function (l, a) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        if (this.listEventSelect.length === 0) {
            var element = {
                accountId: a.accountId,
                locationId: l.locationId,
                accountName: a.accountName,
                locationName: l.locationName
            };
            if (l.location) {
                if (l.location.labels)
                    element['labels'] = l.location.labels;
                if (l.location.address)
                    element['address'] = l.location.address;
                if (l.location.serviceArea)
                    element['serviceArea'] = l.location.serviceArea;
            }
            else if ((_a = l) === null || _a === void 0 ? void 0 : _a.labels) {
                element['labels'] = l.labels;
                if ((_b = l) === null || _b === void 0 ? void 0 : _b.address)
                    element['address'] = l.address;
            }
            else if ((_c = l) === null || _c === void 0 ? void 0 : _c.address) {
                element['address'] = l.address;
            }
            else if ((_d = l) === null || _d === void 0 ? void 0 : _d.serviceArea) {
                element['serviceArea'] = l.serviceArea;
            }
            this.listEventSelect.push({
                accountId: a.accountId,
                gid: a.gid,
                locations: [element]
            });
        }
        else {
            var Search_Account = this.listEventSelect.find(function (r) { return r.accountId === a.accountId; });
            if (Search_Account) {
                var search_locations = Search_Account.locations.find(function (locationId) { return locationId.locationId === l.locationId; });
                if (search_locations) {
                    var locationsFilter_2 = Search_Account.locations.filter(function (locationId) { return locationId.locationId !== l.locationId; });
                    if (locationsFilter_2.length === 0 && !l.checked) {
                        if ((_e = a) === null || _e === void 0 ? void 0 : _e.selectAll)
                            a.selectAll = false;
                        this.listEventSelect = this.listEventSelect.filter(function (acc) { return acc.accountId !== a.accountId; });
                    }
                    else {
                        this.listEventSelect = this.listEventSelect.map(function (acc) {
                            if (acc.accountId === a.accountId && !l.checked) {
                                acc.locations = locationsFilter_2;
                            }
                            return acc;
                        });
                    }
                }
                else {
                    this.listEventSelect = this.listEventSelect.map(function (acc) {
                        var _a, _b, _c, _d;
                        if (acc.accountId === a.accountId) {
                            var element = {
                                accountId: a.accountId,
                                locationId: l.locationId,
                                accountName: a.accountName,
                                locationName: l.locationName
                            };
                            if (l.location) {
                                if (l.location.labels)
                                    element['labels'] = l.location.labels;
                                if (l.location.address)
                                    element['address'] = l.location.address;
                                if (l.location.serviceArea)
                                    element['serviceArea'] = l.location.serviceArea;
                            }
                            else if ((_a = l) === null || _a === void 0 ? void 0 : _a.labels) {
                                element['labels'] = l.labels;
                                if ((_b = l) === null || _b === void 0 ? void 0 : _b.address)
                                    element['address'] = l.address;
                            }
                            else if ((_c = l) === null || _c === void 0 ? void 0 : _c.address) {
                                element['address'] = l.address;
                            }
                            else if ((_d = l) === null || _d === void 0 ? void 0 : _d.serviceArea) {
                                element['serviceArea'] = l.serviceArea;
                            }
                            acc.locations.push(element);
                        }
                        return acc;
                    });
                }
            }
            else {
                var element = {
                    accountId: a.accountId,
                    locationId: l.locationId,
                    accountName: a.accountName,
                    locationName: l.locationName
                };
                if (l.location) {
                    if (l.location.labels)
                        element['labels'] = l.location.labels;
                    if (l.location.address)
                        element['address'] = l.location.address;
                    if (l.location.serviceArea)
                        element['serviceArea'] = l.location.serviceArea;
                }
                else if ((_f = l) === null || _f === void 0 ? void 0 : _f.labels) {
                    element['labels'] = l.labels;
                    if ((_g = l) === null || _g === void 0 ? void 0 : _g.address)
                        element['address'] = l.address;
                }
                else if ((_h = l) === null || _h === void 0 ? void 0 : _h.serviceArea) {
                    element['serviceArea'] = l.serviceArea;
                }
                this.listEventSelect.push({
                    accountId: a.accountId,
                    gid: a.gid,
                    locations: [element]
                });
            }
        }
        var locationChecked = (_k = (_j = a) === null || _j === void 0 ? void 0 : _j.locations) === null || _k === void 0 ? void 0 : _k.filter(function (l) { return l.checked; });
        var possibleLocations = (_m = (_l = a) === null || _l === void 0 ? void 0 : _l.locations) === null || _m === void 0 ? void 0 : _m.filter(function (l) { return !l.deny; });
        a.selectAll = locationChecked.length === possibleLocations.length;
        if (this.listEventSelect.length > 0) {
            this.removeBasics();
        }
    };
    ToggleLocationsInAccountComponent.prototype.removeBasics = function () {
        this.listEventSelect.forEach(function (location) {
            if (Object.prototype.hasOwnProperty.call(location, 'locationsBasics')) {
                delete location['locationsBasics'];
            }
        });
    };
    ToggleLocationsInAccountComponent.prototype.changeLocationsSelected = function (l, a) {
        l.checked = !l.checked;
        var locationActive = a.locations.filter(function (l) { return l.checked; });
        a.checked = locationActive.length !== 0;
        this.setChange(l, a);
        this.AddToList(l, a);
        this.setInfoNotification();
        this.selected.emit(this.listEventSelect);
    };
    ToggleLocationsInAccountComponent.prototype.setChange = function (l, a) {
        var account = _.find(this._accountsCopy, { 'accountId': a.accountId });
        var location = _.find(account.locations, { 'locationId': l.locationId });
        location.checked = l.checked;
        // if toggle uncheck and it depends on reporEdit, delete it.
        if (l.checked === false && this.search) {
            var accountReportEdit = _.find(this.reportEdit, { 'accountId': a.accountId });
            if (accountReportEdit) {
                var locationIndex = _.findIndex(accountReportEdit.locations, { 'locationId': l.locationId });
                if (locationIndex !== -1) {
                    accountReportEdit.locations.splice(locationIndex, 1);
                }
            }
        }
    };
    ToggleLocationsInAccountComponent.prototype.isActiveAccount = function (account, report, isFromFilterSearch) {
        var _this = this;
        if (isFromFilterSearch === void 0) { isFromFilterSearch = false; }
        Promise.all(report.map(function (activeAccount) {
            var _a;
            if (activeAccount.gid + activeAccount.accountId === account.gid + account.accountId) {
                account.selectAll = false;
                account.checked = true;
                (_a = account.locations) === null || _a === void 0 ? void 0 : _a.forEach(function (location) {
                    var locationId = location.locationId;
                    activeAccount.locations.forEach(function (l) {
                        if (locationId === l.locationId) {
                            location.checked = true;
                        }
                    });
                    if ((!_this.onTrial && location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE) || (location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.BASIC && !_this.selectByUser)) {
                        location.deny = true;
                    }
                });
            }
        }));
        if (!isFromFilterSearch) {
            this._accountsCopy = this.accounts;
        }
    };
    ToggleLocationsInAccountComponent.prototype.filterLocation = function ($event, key) {
        var _this = this;
        if ($event[$event.length - 1] === ' ') {
            return;
        }
        clearTimeout(this.timeout);
        this.loadingSearch$.next(true);
        this.timeout = setTimeout(function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, text;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(!$event || $event === '')) return [3 /*break*/, 2];
                        this.isFilter = false;
                        _a = this;
                        return [4 /*yield*/, this.checkSelected(this._accountsCopy, this.accounts)];
                    case 1:
                        _a.accounts = _b.sent();
                        if (this.reportEdit && this.reportEdit.length > 0) {
                            this.accounts.forEach(function (account) {
                                _this.isActiveAccount(account, _this.reportEdit);
                            });
                        }
                        this.loadingSearch$.next(false);
                        return [3 /*break*/, 3];
                    case 2:
                        text = $event.toLowerCase();
                        this.getAccountsLocations(text).pipe(switchMap(function (result) { return _this.checkSelected(result["accounts"], _this._accountsCopy); })).subscribe(function (accounts) {
                            _this.accounts = accounts;
                            if (_this.reportEdit && _this.reportEdit.length > 0) {
                                _this.accounts.forEach(function (account) {
                                    _this.isActiveAccount(account, _this.reportEdit, true);
                                });
                            }
                            _this.isFilter = true;
                            _this.loadingSearch$.next(false);
                        });
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); }, 350);
    };
    ToggleLocationsInAccountComponent.prototype.checkSelected = function (source, otherSource) {
        return __awaiter(this, void 0, void 0, function () {
            var _loop_1, this_1, source_1, source_1_1, acc, e_1_1;
            var e_1, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _loop_1 = function (acc) {
                            var selectAcc;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        selectAcc = _.find(otherSource, { 'accountId': acc.accountId });
                                        if (!(selectAcc && selectAcc.locations)) return [3 /*break*/, 1];
                                        selectAcc.locations.forEach(function (l) {
                                            var _a, _b, _c;
                                            var currentloc = _.find(acc.locations, { 'locationId': l.locationId });
                                            if (currentloc) {
                                                currentloc['checked'] = l.checked || false;
                                                currentloc['deny'] = l.deny || l.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE || false;
                                                currentloc['subscriptionType'] = l.subscriptionType;
                                                currentloc['serviceArea'] = ((_b = (_a = l) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.serviceArea) || ((_c = l) === null || _c === void 0 ? void 0 : _c.serviceArea);
                                            }
                                        });
                                        return [3 /*break*/, 3];
                                    case 1:
                                        if (!(selectAcc && !selectAcc.locations && acc.locations)) return [3 /*break*/, 3];
                                        return [4 /*yield*/, this_1._locationS.getAccountLocation(this_1._authService.session.gid, selectAcc)];
                                    case 2:
                                        _a.sent();
                                        selectAcc.locations.forEach(function (l) {
                                            var _a, _b, _c;
                                            var currentloc = _.find(acc.locations, { 'locationId': l.locationId });
                                            if (currentloc) {
                                                currentloc['checked'] = l.checked || false;
                                                currentloc['deny'] = l.deny || l.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE || false;
                                                currentloc['subscriptionType'] = l.subscriptionType;
                                                currentloc['serviceArea'] = ((_b = (_a = l) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.serviceArea) || ((_c = l) === null || _c === void 0 ? void 0 : _c.serviceArea);
                                            }
                                        });
                                        _a.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 6, 7, 8]);
                        source_1 = __values(source), source_1_1 = source_1.next();
                        _b.label = 2;
                    case 2:
                        if (!!source_1_1.done) return [3 /*break*/, 5];
                        acc = source_1_1.value;
                        return [5 /*yield**/, _loop_1(acc)];
                    case 3:
                        _b.sent();
                        _b.label = 4;
                    case 4:
                        source_1_1 = source_1.next();
                        return [3 /*break*/, 2];
                    case 5: return [3 /*break*/, 8];
                    case 6:
                        e_1_1 = _b.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 8];
                    case 7:
                        try {
                            if (source_1_1 && !source_1_1.done && (_a = source_1.return)) _a.call(source_1);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 8: return [2 /*return*/, source];
                }
            });
        });
    };
    ToggleLocationsInAccountComponent.prototype.setInfoNotification = function () {
        if (!this.txtInfoNotification) {
            return;
        }
        var totalLocations = 0;
        this.listEventSelect.forEach(function (account) {
            totalLocations += account.locations.length;
        });
        this.hasInfoNotification = totalLocations < 2;
    };
    ToggleLocationsInAccountComponent.prototype.ngOnDestroy = function () {
        this.accounts = this.disableAccounts(this.accounts);
        this._destroySubs$.next(true);
        this._destroySubs$.complete();
    };
    ToggleLocationsInAccountComponent.prototype.openAccount = function (account) {
        var _this = this;
        var user = this._authService.session;
        if (this.accounts.length === 1 && this._isEdit) {
            return;
        }
        if (!_.has(account, 'locations')) {
            if (this.filterProtocolLocations) {
                this._protocolService.getLocationsProtocols(account.accountId)
                    .toPromise().then(function (locations) {
                    account.locations = locations;
                    if (_this.reportEdit && _this.reportEdit.length > 0) {
                        _this.accounts.forEach(function (account) {
                            _this.isActiveAccount(account, _this.reportEdit);
                        });
                        _this.setInfoNotification();
                        _this.selected.emit(_this.listEventSelect);
                        _this._isEdit = true;
                    }
                    account['loadAllLocations'] = true;
                });
            }
            else {
                this._groupService.get_locations(this._authService.session.gid, account.accountId)
                    .toPromise().then(function (locations) {
                    if (_this.isMember()) {
                        var memberLocations_1 = [];
                        user.accounts.forEach(function (account) {
                            account.locations.forEach(function (location) {
                                memberLocations_1.push(location.locationId);
                            });
                        });
                        var memberLocation_1 = [];
                        locations.forEach(function (location) {
                            if (memberLocations_1.includes(location['locationId'])) {
                                memberLocation_1.push(location);
                            }
                        });
                        account.locations = memberLocation_1;
                    }
                    else {
                        account.locations = locations;
                    }
                    if (_this.reportEdit && _this.reportEdit.length > 0) {
                        _this.accounts.forEach(function (account) {
                            _this.isActiveAccount(account, _this.reportEdit);
                        });
                        _this.setInfoNotification();
                        _this.selected.emit(_this.listEventSelect);
                        _this._isEdit = true;
                    }
                    account['loadAllLocations'] = true;
                });
            }
        }
        else if (!_.get(account, 'loadAllLocations') && !this.isFilter) {
            var prevAccounts_1 = _.cloneDeep(this._accountsCopy);
            account.locations = null;
            if (this.filterProtocolLocations) {
                this._protocolService.getLocationsProtocols(account.accountId)
                    .toPromise().then(function (locations) {
                    account.locations = locations;
                    if (_this.reportEdit && _this.reportEdit.length > 0) {
                        _this.accounts.forEach(function (account) {
                            _this.isActiveAccount(account, _this.reportEdit);
                        });
                        _this.setInfoNotification();
                        _this.selected.emit(_this.listEventSelect);
                        _this._isEdit = true;
                    }
                    account['loadAllLocations'] = true;
                    account = _.head(_this.checkSelected([account], prevAccounts_1));
                });
            }
            else {
                if (_.has(account, 'locations'))
                    account.locations = null;
                this._groupService.get_locations(this._authService.session.gid, account.accountId)
                    .toPromise().then(function (locations) {
                    account.locations = locations;
                    if (_this.reportEdit && _this.reportEdit.length > 0) {
                        _this.accounts.forEach(function (account) {
                            _this.isActiveAccount(account, _this.reportEdit);
                        });
                        _this.setInfoNotification();
                        _this.selected.emit(_this.listEventSelect);
                        _this._isEdit = true;
                    }
                    account['loadAllLocations'] = true;
                    account = _.head(_this.checkSelected([account], prevAccounts_1));
                });
            }
        }
        if (this.checkUpgrade) {
            account.locations = account.locations.filter(function (location) { return location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE || location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.BASIC; });
        }
    };
    ToggleLocationsInAccountComponent.prototype.changeIconToggle = function (e) {
        var icon = document.getElementById("Icon" + e);
        if (icon.classList.contains('fa-plus')) {
            icon.classList.remove('fa-plus');
            icon.classList.add('fa-minus');
        }
        else {
            icon.classList.remove('fa-minus');
            icon.classList.add('fa-plus');
        }
    };
    ToggleLocationsInAccountComponent.prototype.isMember = function () {
        var _a;
        return ((_a = this._authService.session.role) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === 'member';
    };
    ToggleLocationsInAccountComponent.prototype.goToLocation = function (account, location) {
        var _a, _b;
        this._router.navigate(['/account', (_a = account) === null || _a === void 0 ? void 0 : _a.accountId, 'location', (_b = location) === null || _b === void 0 ? void 0 : _b.locationId, 'post-management']);
    };
    return ToggleLocationsInAccountComponent;
}());
export { ToggleLocationsInAccountComponent };
