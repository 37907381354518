import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
var BulkPostComponent = /** @class */ (function () {
    function BulkPostComponent(_router) {
        this._router = _router;
        this.isNewPost = false;
    }
    BulkPostComponent.prototype.ngOnInit = function () {
        var _a;
        this.isNewPost = (_a = this._router.url) === null || _a === void 0 ? void 0 : _a.includes('posts-management');
    };
    return BulkPostComponent;
}());
export { BulkPostComponent };
