import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { LocationEditService } from 'src/app/services/location-edit.service';
var ModalServiceAreaComponent = /** @class */ (function () {
    function ModalServiceAreaComponent(dialogRef, locationEditS, data) {
        this.dialogRef = dialogRef;
        this.locationEditS = locationEditS;
        this.data = data;
        this.serviceArea = {};
        this.regionCode = "US";
    }
    ModalServiceAreaComponent.prototype.ngOnInit = function () {
        var _a, _b, _c;
        this.serviceArea = Object.keys((_a = this.data) === null || _a === void 0 ? void 0 : _a.serviceArea).length ? JSON.parse(JSON.stringify((_b = this.data) === null || _b === void 0 ? void 0 : _b.serviceArea)) : { places: { placeInfos: [] } };
        this.regionCode = (_c = this.data) === null || _c === void 0 ? void 0 : _c.regionCode;
        this.optionsGoogle = {
            types: [],
            componentRestrictions: { country: this.regionCode }
        };
    };
    ModalServiceAreaComponent.prototype.removePlace = function (place, index) {
        this.placeInfos.splice(index, 1);
    };
    ModalServiceAreaComponent.prototype.handleAddressChange = function (place) {
        var _a, _b;
        this.placeInfos.push({
            placeId: (_a = place) === null || _a === void 0 ? void 0 : _a.place_id,
            placeName: (_b = place) === null || _b === void 0 ? void 0 : _b.formatted_address
        });
        this.newServiceArea = null;
    };
    ModalServiceAreaComponent.prototype.apply = function () {
        var _this = this;
        this.locationEditS.locationEdit.serviceArea = this.serviceArea;
        this.locationEditS.setAndUpdate().toPromise().then(function () {
            _this.dialogRef.close();
        });
    };
    Object.defineProperty(ModalServiceAreaComponent.prototype, "placeInfos", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.serviceArea) === null || _a === void 0 ? void 0 : _a.places) === null || _b === void 0 ? void 0 : _b.placeInfos;
        },
        enumerable: true,
        configurable: true
    });
    return ModalServiceAreaComponent;
}());
export { ModalServiceAreaComponent };
