import { HttpClient } from "@angular/common/http";
// app
import { HEADERS_NO_AUTH } from '../constants/auth';
import { environment } from "@environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var EmailerService = /** @class */ (function () {
    function EmailerService(http) {
        this.http = http;
    }
    // FIXME: Security issue, no arbitrary mails should be sent from frontend / 
    // is bad to have a backend endpoint open for that.
    EmailerService.prototype.send = function (email, subject, message) {
        var body = {
            'to': email,
            'subject': subject,
            'message': message
        };
        return this.http.post(environment.apiUrl + "/v2/emailer/send", body);
    };
    EmailerService.prototype.sendResetPasswordMail = function (data) {
        return this.http.post(environment.apiUrl + "/v2/auth/reset", data, HEADERS_NO_AUTH).toPromise();
    };
    EmailerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EmailerService_Factory() { return new EmailerService(i0.ɵɵinject(i1.HttpClient)); }, token: EmailerService, providedIn: "root" });
    return EmailerService;
}());
export { EmailerService };
