import {Pipe, PipeTransform} from '@angular/core';
import * as firebase from 'firebase/app';
import {DatePipe} from '@angular/common';

@Pipe({
  name: 'fireTimestamp'
})
export class FireTimestampPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (!value) {
      return;
    }

    const  datePipe = new DatePipe('EN-US');

    if(value?.seconds){
      return datePipe.transform(new Date(value?.seconds * 1000),'MM/dd/yyyy' );
    }
    
    if(value instanceof firebase.firestore.Timestamp){
      return datePipe.transform(value.toDate(),'MM/dd/yyyy');
    }

    return datePipe.transform(new Date(value), 'MM/dd/yyyy');
  }

}
