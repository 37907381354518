import { AfterViewChecked, Component, OnInit, ChangeDetectorRef, ViewRef } from '@angular/core';
import { SpinnerService } from 'src/app/services/spinner.service';

import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-layout',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit, AfterViewChecked {

  /* FIXME */
  constructor(
    public authService: AuthService,
    public spinnerService: SpinnerService,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.checkForTrialMessage();
  }

  ngAfterViewChecked() {
    this.checkForTrialMessage();
    this.detectChanges();
  }

  detectChanges () : void{
    if ( this.cdRef !== null &&
        this.cdRef !== undefined &&
        ! (this.cdRef as ViewRef).destroyed
    ) {
      this.cdRef.detectChanges();
    }
  }

  checkForTrialMessage() {
    const trialMessageLength = document.querySelectorAll('.js-trial-message').length;
    if (trialMessageLength) {
      document.querySelector('body').classList.add('trial-message-wrapper');
    } else {
      document.querySelector('body').classList.remove('trial-message-wrapper');
    }
  }
}
