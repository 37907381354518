<div class="dialog__header" *ngIf="!bulk">
  <h2 mat-dialog-title class="m--0 txt--xl" *ngIf="attribute.displayName"><strong>{{attribute.displayName}}</strong></h2>
  <h2 mat-dialog-title class="m--0 txt--xl" *ngIf="title"><strong>{{title}}</strong></h2>
</div>
<div class="spinner-wrapper spinner-wrapper--fixed" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>
<div class="dialog__content" mat-dialog-content>
  <div class="dialog-row pb--0">
    <form [formGroup]="form">
      <ng-container formArrayName="urls" *ngFor="let url of UrlsArray.controls;let i = index">
        <div [formGroupName]="i" class="form-group">
          <div class="row">
            <div class="col-12">
              <mat-form-field class="input-field w--96">
                <input matInput placeholder="URL" formControlName="url" autocomplete="off">
                <mat-error><span *ngIf="getUrlArray(i).get('url').hasError('pattern')">Looks like your URL has an incorrect format. Please check your formatting, no spaces or special characters are allowed.</span></mat-error>
              </mat-form-field>
              <button mat-flat-button class="btn btn-icon btn-icon--gray" (click)="removeUrl(i)" matTooltip="Remove Url">
                <em class="far fa-trash-alt"></em>
              </button>
            </div>
          </div>
        </div>
      </ng-container>
      <button mat-button class="p--0" color="primary" *ngIf="addBtnShown()" (click)="addNewUrl()">
        <em class="far fa-plus"></em>
        Add Url
      </button>
    </form>
  </div>
</div>
<div mat-dialog-actions class="dialog__footer" *ngIf="!bulk">
  <span class="alternate-theme d-inline-block mr--10">
    <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
  </span>
  <button mat-flat-button tabIndex="-1" color="accent" class="btn btn--action" (click)="apply()">Apply</button>
</div>
