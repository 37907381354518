import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import AccountObject from '../../constants/firestore/account-object';
import { GoogleLocationService } from '../../services/google-location.service';
import User from '../../constants/firestore/user';
import { DataPicker } from '../../constants/data-picker';
// import AccountLocation from '../../constants/firestore/account-location';

@Component({
  selector: 'app-account-loc-selector',
  templateUrl: './account-loc-selector.component.html',
  styleUrls: ['./account-loc-selector.component.scss']
})
export class AccountLocSelectorComponent implements OnInit {
  googleAccounts: AccountObject[];
  user: User;
  selectedAccount: any;
  selectedLocation: any;
  dataPicker: DataPicker;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AccountLocSelectorComponent>,
    public googleLocationService: GoogleLocationService,
  ) {
    this.user = this.data.user;
    this.googleAccounts = this.data.accounts;
    this.googleLocationService.locations.subscribe(locations =>  this.verificateLocations( locations ) )
  }

  ngOnInit() {
  }

  backAccount() {
    this.googleLocationService.reset();
  }

  selectAccount(): void {
    this.googleLocationService.loadAllGrade(this.user, this.selectedAccount.name);
  }


  selectLocation() {
    this.dialogRef.close({location: this.selectedLocation, account: this.selectedAccount});
    this.googleLocationService.reset();
  }

  verificateLocations(locations: any[]) {
    locations.forEach( location => {
      if ( location && location.locationState ) {
        if ( location.locationState.hasPendingVerification ) {
          location.locationState.description = 'Pending verification';
          location.locationState.fill = 'review-response--error';
          location.locationState.txt = 'txt--orange';
        } else if ( location.locationState.isDisabled ) {
          location.locationState.description = 'Disabled';
          location.locationState.fill = 'review-response--info';
          location.locationState.txt = 'txt--blue';
        } else if ( location.locationState.needsReverification ) {
          location.locationState.description = 'Needs Reverification';
          location.locationState.fill = 'review-response--error';
          location.locationState.txt = 'txt--orange';
        } else if ( location.locationState.isSuspended ) {
          location.locationState.description = 'Suspended';
          location.locationState.fill = 'review-response--info';
          location.locationState.txt = 'txt--blue';
        } else if ( location.locationState.isDuplicate ) {
          location.locationState.description = 'Duplicate';
          location.locationState.fill = 'review-response--error';
          location.locationState.txt = 'txt--orange';
        }
      }
    })
  }

}
