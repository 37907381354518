<form [formGroup]="form">
    <div class="dialog__header">
      <h2 mat-dialog-title class="m--0 txt--xl"><strong>{{!isEdit ? 'Add ' : 'Edit '}}  Item</strong></h2>
    </div>
    <div class="dialog__content" mat-dialog-content>
      <div class="dialog-row pb--0">
        <div class="row">
          <div class="col-12 col-md-8" *ngIf="item">
            <mat-form-field class="input-field">
              <input matInput placeholder="Item Name" [formControl]="name" name="itemName">
              <mat-error *ngIf="name.hasError('required')"><p>Name is required</p></mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-4">
            <mat-form-field class="input-field">
              <input matInput [placeholder]="'Item Price '+ '('+currency.value+')'" [formControl]="units" name="itemPrice">
              <mat-error *ngIf="units.errors">
                <p *ngIf="units.hasError('required')">Units is required.</p>
                <p *ngIf="units.hasError('pattern')">Error format i.e: 2, 2.50, 100.20</p>
                <p *ngIf="units.hasError('min')">The minimum price is 0.</p>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-4">
            <mat-form-field class="input-field">
              <mat-select [formControl]="currency" value="USD" >
                <mat-option value="USD">USD</mat-option>
                <mat-option value="EUR">EUR</mat-option>
                <mat-option value="GBP">GBP</mat-option>
                <mat-option value="AUD">AUD</mat-option>
                <mat-option value="CAD">CAD</mat-option>
              </mat-select>
              <mat-error *ngIf="units.errors">
                <p *ngIf="units.hasError('required')">Currency is required.</p>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-form-field class="input-field">
              <input matInput placeholder="Item description" [formControl]="description" name="itemDescription">
              <mat-error *ngIf="description.hasError('required')"><p>Description is required.</p></mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div mat-dialog-actions class="dialog__footer">
      <span class="alternate-theme d-inline-block mr--10">
          <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
      </span>
      <div>
        <button mat-flat-button (click)="remove()" tabIndex="-1" color="primary" class="btn btn--action mr--10" *ngIf="isEdit">Remove</button>
        <button mat-flat-button (click)="apply()" tabIndex="-1" color="accent" class="btn btn--action">Apply</button>
      </div>
    </div>
  </form>
  