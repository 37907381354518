<div>
  <button mat-flat-button [color]="'warn'"class="btn--badge btn--badge--sm">
    <em class="fas fa-exclamation-triangle"></em>
    <span>Error details:</span>
  </button>
  <ng-container *ngIf="error?.description === null; else normalStructure">
    <read-more [text]="error?.message" [maxLength]="300"></read-more>
  </ng-container>
  <ng-template #normalStructure>
    <div class="m--10" *ngIf="error_details?.length > 0; else noDetail">
      <div *ngFor="let detail of error_details">
        <ng-container *ngIf="detail.message != null; else noReason">
          - {{detail?.message}}
          {{detail?.field && " | Field: "+detail?.field}}
          {{detail?.value && " | Value: "+detail?.value}}
          {{detail?.min_length && " | Minimum length: "+detail?.min_length}}
          {{detail?.max_length && " | Miximum length: "+detail?.max_length}}
        </ng-container>
        <ng-template #noReason>
          - {{myBusinessErrors['businessInformation'][detail.code]}}
            {{detail?.field && " | Field: "+detail?.field}}
            {{detail?.value && " | Value: "+detail?.value}}
            {{detail?.min_length && " | Minimum length: "+detail?.min_length}}
            {{detail?.max_length && " | Miximum length: "+detail?.max_length}}
        </ng-template>
      </div>
    </div>
    <ng-template #noDetail>
      <div>
        - {{error?.message || 'Unknown error'}}
      </div>
    </ng-template>
  </ng-template>
</div>
