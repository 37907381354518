import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var SpinnerService = /** @class */ (function () {
    function SpinnerService() {
        this.loading$ = new BehaviorSubject(false);
        //
    }
    SpinnerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SpinnerService_Factory() { return new SpinnerService(); }, token: SpinnerService, providedIn: "root" });
    return SpinnerService;
}());
export { SpinnerService };
