import { __assign, __read, __spread } from "tslib";
import { OnDestroy, OnInit, EventEmitter, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ModalServiceItemComponent } from '../modal-service-item/modal-service-item.component';
import { LocationService } from '../../services/location.service';
import { ModalAddServiceComponent } from '../modal-add-service/modal-add-service.component';
import { GoogleService } from '../../services/google.service';
import { SnackbarService } from '../../services/snackbar.service';
export var SECTION_DATA = [];
var ModalServiceComponent = /** @class */ (function () {
    function ModalServiceComponent(dialogRef, dialog, _locationS, _googleS, _snackS, data) {
        var _a, _b;
        this.dialogRef = dialogRef;
        this.dialog = dialog;
        this._locationS = _locationS;
        this._googleS = _googleS;
        this._snackS = _snackS;
        this.data = data;
        this.bulk = false;
        this.aditionalCategories = [];
        this.validResult = new EventEmitter(false);
        this.dataSource = [];
        this.loading = false;
        this.showMoreMessage = false;
        this.showOnly = false;
        this.error = {};
        this.error_details = [];
        this._combineCategories = [];
        this._locationId = '';
        this._accountId = '';
        if (this.data) {
            this.type = this.data.type;
            this.serviceList = this.data.serviceList;
            this.primaryCategory = __assign(__assign({}, this.data.primaryCategory), { primary: true });
            this.aditionalCategories = __spread(this.data.additionalCategories);
            this._combineCategories = __spread([this.primaryCategory], this.aditionalCategories);
            this.dataSource = this._locationS.organizeServiceList(this.serviceList, this._combineCategories);
            this._accountId = this.data.accountId;
            this._locationId = this.data.locationId;
            this.showOnly = this.data.showOnly;
            this.error = (_a = this.data) === null || _a === void 0 ? void 0 : _a.error;
            this.error_details = (_b = this.data) === null || _b === void 0 ? void 0 : _b.error_details;
            //TODO: When the getRef service returns 'type', remove this logyc 
            this.addTypeToItems();
            //
        }
    }
    ModalServiceComponent.prototype.addTypeToItems = function () {
        this.serviceList.forEach(function (el) {
            var _a;
            if ((_a = el) === null || _a === void 0 ? void 0 : _a.price) {
                el.price.type = 'fixed';
                if (Number(el.price.units) === 0 || (el.price.units == null && el.price.nanos == null)) {
                    el.price.type = 'free';
                }
            }
        });
    };
    ModalServiceComponent.prototype.toggleMessage = function (i) {
        this.showMoreMessage = !this.showMoreMessage;
        this.showMoreMessageIndex = i;
        return;
    };
    ModalServiceComponent.prototype.ngOnInit = function () {
        if (this.bulk) {
            if (this.aditionalCategories.length > 0)
                this._combineCategories = __spread([this.primaryCategory], this.aditionalCategories);
            else
                this._combineCategories = [this.primaryCategory];
            this.dataSource = this._locationS.organizeServiceList(this.serviceList, this._combineCategories);
        }
    };
    ModalServiceComponent.prototype.getResult = function () {
        if (this.serviceList.length === 0) {
            this.validResult.emit(false);
            return;
        }
        this.validResult.emit(true);
        return this.serviceList;
    };
    ModalServiceComponent.prototype.setDataService = function (service, primaryCategory, aditionalCategories) {
        this.serviceList = service;
        this.primaryCategory = __assign(__assign({}, primaryCategory), { primary: true });
        this.aditionalCategories = __spread(aditionalCategories);
        this._combineCategories = __spread([this.primaryCategory], this.aditionalCategories);
        this.dataSource = this._locationS.organizeServiceList(this.serviceList, this._combineCategories);
    };
    ModalServiceComponent.prototype.addItem = function (position) {
        var _this = this;
        var dialogRef = this.dialog.open(ModalAddServiceComponent, {
            width: '480px',
            panelClass: 'overflow--visible',
            data: {
                category: this._combineCategories[position]
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.serviceList.push(result.item);
                var data = _this.dataSource.find(function (x) { return x.categoryId === result.category; });
                if (data) {
                    data.services.push(result.structuredItem);
                }
            }
        });
    };
    ModalServiceComponent.prototype.editItem = function (service, position, sectionIndex, serviceMaskIndex) {
        var _this = this;
        if (!this.showOnly) {
            var dialogRef = this.dialog.open(ModalServiceItemComponent, {
                width: '480px',
                panelClass: 'overflow--visible',
                data: {
                    structuredService: service,
                    item: this.serviceList[position],
                    position: position
                }
            });
            dialogRef.afterClosed().subscribe(function (result) {
                if (result) {
                    if (result.action === 'removed') {
                        _this.serviceList.splice(position, 1);
                        _this.dataSource[sectionIndex].services.splice(serviceMaskIndex, 1);
                    }
                    else if (result.action === 'updated') {
                        _this.serviceList[position] = result.item;
                        _this.dataSource[sectionIndex].services[serviceMaskIndex] = result.structuredItem;
                    }
                }
            });
        }
    };
    ModalServiceComponent.prototype.apply = function () {
        var _this = this;
        this.loading = true;
        this._googleS.updateServices(this._accountId, this._locationId, this.serviceList).toPromise().then(function (data) {
            if (data) {
                _this.loading = false;
                _this._snackS.openSuccess('The services was uploaded successfully', 2000);
                _this.serviceList = data;
                _this.dataSource = _this._locationS.organizeServiceList(_this.serviceList, _this._combineCategories);
            }
        });
    };
    ModalServiceComponent.prototype.ngOnDestroy = function () { };
    return ModalServiceComponent;
}());
export { ModalServiceComponent };
