import { __assign, __awaiter, __generator, __read, __spread } from "tslib";
// dep
import { EventEmitter, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material';
import { of } from 'rxjs';
import { take } from 'rxjs/operators';
// app
import { ModalOpenDateComponent } from '../modal-date/modal-open-date.component';
import { ModalStoreCodeComponent } from '../modal-store-code/modal-store-code.component';
import { ModalLabelsComponent } from '../modal-labels/modal-labels.component';
import { ModalGoogleAdsComponent } from '../modal-google-ads/modal-google-ads.component';
import { ModalPhotosComponent } from '../modal-photos/modal-photos.component';
import { LocationService } from '../../services/location.service';
import { GoogleService } from '../../services/google.service';
import { LocationEditService } from '../../services/location-edit.service';
import { DatesService } from '../../services/dates.service';
import { ModalFetchComponent } from '../modal-fetch/modal-fetch.component';
import { ModalService } from '../../services/modal.service';
import { SnackbarService } from '../../services/snackbar.service';
import { AuthService } from '../../services/auth.service';
import { WhiteLabelService } from 'src/app/services/white-label.service';
var DashboardLocationComponent = /** @class */ (function () {
    function DashboardLocationComponent(_router, _dialog, _locationS, _auth, _route, _googleS, _locationEditS, _dateS, _modal, _snack, _changeDetector, _WhiteLabelS) {
        this._router = _router;
        this._dialog = _dialog;
        this._locationS = _locationS;
        this._auth = _auth;
        this._route = _route;
        this._googleS = _googleS;
        this._locationEditS = _locationEditS;
        this._dateS = _dateS;
        this._modal = _modal;
        this._snack = _snack;
        this._changeDetector = _changeDetector;
        this._WhiteLabelS = _WhiteLabelS;
        // events for custom pagination
        this.goPrev = new EventEmitter();
        this.goNext = new EventEmitter();
        this.goPage = new EventEmitter();
        this.locationInfoTabs = ['General'];
        this.isProgressPush = false;
        this.countSpecialHours = 0;
        this.countRegularHours = 0;
        this.countMoreHours = 0;
        this.socialMediaAttributes = [];
        this.actionLinks = [];
        this.serviceList = [];
        this.selected = new FormControl(0);
        this.pageable = { page: 1, size: 10 };
        this.progress = true;
        this.showPendingChangesMsg = false;
        this.location$ = of(null);
        this.regionCode = 'US';
        this.unexpectedError = "Unexpected Error. Please try again or contact Map Labs for support.";
        this.pendingMask = [];
        this.diffMask = [];
        this.locationDiffMask = {};
        this.locationId = this._route.snapshot.parent.params.locationId;
        this.accountId = this._route.snapshot.parent.params.accountId;
    }
    DashboardLocationComponent.prototype.ngOnInit = function () {
        this.initData();
    };
    DashboardLocationComponent.prototype.initData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._WhiteLabelS.branding.then(function (res) { return _this.companyName = res['company_name']; });
                        this.location$ = this._locationS.location;
                        this._locationS.get(this._auth.session.gid, this.accountId, this.locationId);
                        return [4 /*yield*/, this.getPendingMask()];
                    case 1:
                        _a.sent();
                        this.getData();
                        this._updateTable();
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardLocationComponent.prototype.getData = function () {
        var _this = this;
        this._getByPlaceId$ = this._locationS.getRef(this._auth.session.gid, this.accountId, this.locationId).subscribe(function (result) {
            if (result === undefined) {
                _this.noData = true;
                _this.progress = false;
                return;
            }
            if ('serviceList' in result) {
                _this.serviceList = result.serviceList;
            }
            if (!result.location.priceLists)
                result.location.priceLists = [];
            if (!Object.keys(result).includes('locationEdit')) {
                _this._locationS.initLocationEdit(_this._auth.session.gid, _this.accountId, _this.locationId, result.location).subscribe(function (resultEdit) {
                    _this._setLocationProperties(resultEdit);
                });
            }
            else {
                _this._setLocationProperties(result);
            }
            _this.progress = false;
        }, function (error) {
            _this.progress = false;
            if (error.status === 0) {
                _this.noData = true;
            }
        });
    };
    DashboardLocationComponent.prototype._setLocationProperties = function (result) {
        var _this = this;
        var _a;
        this.progress = false;
        if (!result) {
            return;
        }
        this.regionCode = this._locationS.getRegionCode(result);
        this.location = result.locationEdit;
        this.location.search_url = (_a = result) === null || _a === void 0 ? void 0 : _a.search_url;
        this.location = __assign(__assign({}, this.location), { locationState: result.location.locationState ? result.location.locationState : {} });
        this.primaryCategory = this.location.primaryCategory;
        if (!this.locationInfoTabs.some(function (l) { return l === 'Media'; })) {
            this.locationInfoTabs.push('Media');
        }
        this._locationS.getByPrimaryCategory(this._auth.session.gid, this.accountId, this.locationId).subscribe(function (res) {
            _this.locationType = res;
        });
        this._locationEditS.setLocationEdit(result.locationEdit, this._auth.session.gid, this.accountId, this.locationId);
        this.locked = result.lockedOn;
        this.noData = false;
        this.locationName = this.location.locationName;
        this.additionalCategories = this.location.additionalCategories;
        this.address = this.location.address ? this.location.address : null;
        this.serviceArea = this.location.serviceArea ? this.location.serviceArea : {};
        this.regularHours = this.location.regularHours;
        this.dateConvert = false;
        this.specialHours = this.location.specialHours;
        this.primaryPhone = this.location.primaryPhone;
        this.additionalPhones = this.location.additionalPhones;
        this.storeCode = this.location.storeCode;
        this.url = this.location.websiteUrl;
        this.buildAttributes();
        this.actionLinks = this.location.placeActionLinks;
        this.moreHours = this.location.moreHours || [];
        this.countSpecialHours = this.specialHours ? this.specialHours['specialHourPeriods'].length : 0;
        this.countRegularHours = this.regularHours ? this.regularHours['periods'].length : 0;
        this.countMoreHours = this.moreHours ? this.moreHours.length : 0;
        this.getMoreHoursLabels();
        if (this.location.openInfo) {
            if (this.location.openInfo.openingDate) {
                this.openingDate = this._dateS.dateJsonToDate(this.location.openInfo.openingDate);
            }
        }
        if (typeof this.location.priceLists === 'undefined') {
            this._locationEditS.update(this._auth.session.gid, this.accountId, this.locationId, __assign(__assign({}, this.location), { priceLists: [] }));
        }
        this.priceList = this.location.priceLists ? this.location.priceLists : [];
        this.labels = this.location.labels;
        if (this.location.adWordsLocationExtensions) {
            this.adWordsPhone = this.location.adWordsLocationExtensions.adPhone;
        }
    };
    DashboardLocationComponent.prototype.buildAttributes = function () {
        var _this = this;
        if (!this.location || !this.location.attributes) {
            this.attributes = [];
            this.socialMediaAttributes = [];
            return;
        }
        var clonedAttributes = __spread(this.location.attributes);
        this.attributes = clonedAttributes.filter(function (el) { return !_this._locationEditS.isSocialMedia(el); });
        this.socialMediaAttributes = clonedAttributes.filter(function (el) { return _this._locationEditS.isSocialMedia(el); });
    };
    DashboardLocationComponent.prototype.getPendingMask = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._locationS.getPendingMask(this._auth.session.gid, this.accountId, this.locationId).then(function (res) {
                            var _a, _b, _c, _d, _e;
                            if ((_a = res) === null || _a === void 0 ? void 0 : _a.pendingMask) {
                                var pendingMaskData = typeof (res.pendingMask) != 'string' ? [] : res.pendingMask.split(',');
                                (_b = pendingMaskData) === null || _b === void 0 ? void 0 : _b.forEach(function (el) {
                                    var field = _this.getFields(el);
                                    _this.pendingMask.push(field);
                                });
                            }
                            if ((_c = res) === null || _c === void 0 ? void 0 : _c.diffMask) {
                                var diffMaskData = (_d = res.diffMask) === null || _d === void 0 ? void 0 : _d.split(',');
                                (_e = diffMaskData) === null || _e === void 0 ? void 0 : _e.forEach(function (el) {
                                    var _a, _b;
                                    el = el.replaceAll(' ', '');
                                    var levels = el.includes('.') ? el.split('.') : [el];
                                    var displayName = el == 'title' ? 'location Name' : levels[((_a = levels) === null || _a === void 0 ? void 0 : _a.length) - 1].replace(/([a-z])([A-Z])/g, '$1 $2').trim().replace(/\bUri\b/, 'url');
                                    var value = res.location;
                                    (_b = levels) === null || _b === void 0 ? void 0 : _b.forEach(function (n) {
                                        var _a;
                                        value = ((_a = value) === null || _a === void 0 ? void 0 : _a[n]) ? value[n] : null;
                                    });
                                    var fields = {
                                        key: el,
                                        displayName: displayName,
                                        value: value || null
                                    };
                                    _this.diffMask.push(fields);
                                });
                                _this.locationDiffMask = res.location;
                            }
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardLocationComponent.prototype.showDiffMaskChanges = function () {
        /*
        const differences = [];
        let existInLocation = false;
    
        this.diffMask?.forEach(el => {
    
          const key = el.key;
          const levels = key.includes('.') ? key.split('.') : [key];
          let newData = this.locationDiffMask;
          let dataLoc = this.location;
    
          let data = {
            key: this.getKey(el.key),
            googleLocation: null,
            location: null
          };
    
          levels?.forEach(n => {
            existInLocation = dataLoc[n] && newData[n] ? true : false;
            dataLoc = dataLoc[n];
            newData = newData[n];
          });
    
          if (existInLocation) {
            data.googleLocation = newData;
            data.location = dataLoc;
            differences.push(data);
          }
        });
         */
        var result = {
            difference: [],
            action: 'Review Updates from Google',
            isDiffMask: true
        };
        this.openFetchDialog(result, false);
    };
    /*getKey(level):string {
      let key;
      
      switch(level) {
        case 'phoneNumbers.primaryPhone':
          key = 'primaryPhone';
          break;
        case 'phoneNumbers.additionalPhones':
          key = 'additionalPhones';
          break;
        case 'openInfo.openingDate':
          key = 'openInfo';
          break;
        case 'storefrontAddress':
          key = 'address';
          break;
        default:
          key = key;
          break;
      }
      return key;
    }*/
    DashboardLocationComponent.prototype.getFields = function (field) {
        var value;
        switch (field) {
            case 'title':
                value = 'location name';
                break;
            case 'storefrontAddress':
                value = 'address';
                break;
            case 'regularHours':
                value = 'regular hours';
                break;
            case 'moreHours':
                value = 'more hours';
                break;
            case 'specialHours':
                value = 'special hours';
                break;
            case 'phoneNumbers':
                value = 'phone numbers';
                break;
            case 'websiteUri':
                value = 'Website';
                break;
            case 'openInfo.openingDate':
                value = 'opening date & status';
                break;
            case 'storeCode':
                value = 'store code';
                break;
            case 'adWordsLocationExtensions':
                value = 'google ads location extensions phone';
                break;
            case 'labels':
                value = 'labels';
                break;
            case 'categories':
                value = 'categories';
                break;
        }
        return value;
    };
    DashboardLocationComponent.prototype.getPartialError = function (row) {
        var _a, _b, _c, _d;
        var msg;
        var errorType = (_b = (_a = row) === null || _a === void 0 ? void 0 : _a.error) === null || _b === void 0 ? void 0 : _b.error;
        if (errorType && typeof errorType === 'object') {
            if ("STALE_DATA" in errorType) {
                var field = this.getFields((_c = errorType) === null || _c === void 0 ? void 0 : _c.STALE_DATA);
                msg = "We removed the field '" + field + "' from the update because Google is temporarily blocking the push. This happens from time to time due to Google's API limitations. Based on our experience and metrics, this issue will resolve itself within 48 hours. If you require this data on your Google Business Profile sooner, please update it natively and then Fetch it into Map Labs.";
            }
            else if ("LAT_LNG" in errorType) {
                msg = "We removed the field 'address' because Google rejected it. Google said that the location (pin location) has changed. You can try updating the pin location in the native Google Business Profile or contact Map Labs for support.";
            }
            else if ("THROTTLED" in errorType) {
                var field = this.getFields((_d = errorType) === null || _d === void 0 ? void 0 : _d.THROTTLED);
                msg = "We removed the field '" + field + "' from the update because Google is temporarily blocking the push due to making too many updates. This happens from time to time due to Google's API limitations. Based on our experience and metrics, this issue will resolve itself within 48 hours. If you require this data on your Google Business Profile sooner, please update it natively and then Fetch it into Map Labs.";
            }
            else if ("PLACE_ACTION_LINKS" in errorType) {
                msg = "Some links couldn\u2019t be updated due to similarities or incorrect formatting, which resulted in an error. This issue occurs because the links may be too similar to each other or may contain errors. We recommend trying to fetch the links again. Please note that this limitation is due to constraints within Google\u2019s API, which may prevent certain updates from being processed successfully. If you require this data on your Google Business Profile sooner, please update it natively and then Fetch it into Map Labs.";
            }
        }
        return msg;
    };
    DashboardLocationComponent.prototype.getMoreHoursLabels = function () {
        var _this = this;
        var _a, _b, _c;
        this.moreHoursLabels = ((_a = this.location.primaryCategory) === null || _a === void 0 ? void 0 : _a.moreHoursTypes) || [];
        (_c = (_b = this.location) === null || _b === void 0 ? void 0 : _b.additionalCategories) === null || _c === void 0 ? void 0 : _c.map(function (el) {
            var _a, _b;
            (_b = (_a = el) === null || _a === void 0 ? void 0 : _a.moreHoursTypes) === null || _b === void 0 ? void 0 : _b.forEach(function (h) {
                var hasLabel = _this.moreHoursLabels.find(function (l) { return l.hoursTypeId == h.hoursTypeId; }) ? true : false;
                if (!hasLabel) {
                    _this.moreHoursLabels.push(h);
                }
            });
        });
    };
    DashboardLocationComponent.prototype.updateLocation = function (event) {
        if (event) {
            this.progress = true;
            this.getData();
            this._updateTable();
        }
    };
    DashboardLocationComponent.prototype.GoBack = function () {
        this._router.navigate(['/accounts', this.accountId, 'locations']);
    };
    // edit opening date
    DashboardLocationComponent.prototype.openDateDialog = function () {
        var _this = this;
        var dialogRef = this._dialog.open(ModalOpenDateComponent, {
            width: '680px',
            data: {
                status: this.location.openInfo.status,
                openingDate: this.location.openInfo.openingDate
            }
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe(function (res) {
            if (res != '')
                _this.updateLocation(true);
        });
    };
    // edit photos
    DashboardLocationComponent.prototype.openPhotosDialog = function () {
        var _this = this;
        var dialogRef = this._dialog.open(ModalPhotosComponent, {
            width: '60%',
            data: {
                locationId: this.locationId,
                accountId: this.accountId,
                media: this._media,
                typeLocation: this.locationType
            }
        });
        dialogRef.afterClosed().subscribe(function () {
            _this.updateLocation(true);
        });
    };
    // edit store code
    DashboardLocationComponent.prototype.openStoreCodeDialog = function () {
        var _this = this;
        var dialogRef = this._dialog.open(ModalStoreCodeComponent, {
            width: '680px',
            data: {
                storeCode: this.storeCode
            }
        });
        dialogRef.afterClosed().subscribe(function (res) {
            if (res != '')
                _this.updateLocation(true);
        });
    };
    // edit labels
    DashboardLocationComponent.prototype.openLabelsDialog = function () {
        var _this = this;
        var dialogRef = this._dialog.open(ModalLabelsComponent, {
            width: '680px',
            data: {
                list: this.labels
            }
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe(function (result) {
            var _a;
            if (result != '') {
                _this.labels = (_a = result) === null || _a === void 0 ? void 0 : _a.labelList;
                _this.updateLocation(true);
            }
        });
    };
    // edit google ads phone
    DashboardLocationComponent.prototype.openGoogleAdsDialog = function () {
        var _this = this;
        var dialogRef = this._dialog.open(ModalGoogleAdsComponent, {
            width: '680px',
            panelClass: 'overflow--visible',
            data: {
                googleAds: this.adWordsPhone
            }
        });
        dialogRef.afterClosed().subscribe(function (res) {
            if (res != '')
                _this.updateLocation(true);
        });
    };
    // TABLE
    DashboardLocationComponent.prototype._updateTable = function () {
        var _this = this;
        // data for table
        this.lockProgress = true;
        this.dataSource = null;
        this.displayedColumns = ['date', 'action', 'changes', 'status'];
        this._listLockHistory$ = this._googleS.listLockHistory(this.accountId, this.locationId, this.pageable).subscribe(function (result) {
            _this.lockProgress = false;
            _this.pagination = result;
            _this.dataSource = new MatTableDataSource(result.items);
        }, function (error) {
            _this.lockProgress = false;
        });
    };
    DashboardLocationComponent.prototype.fetchDifference = function (history) {
        var _this = this;
        this.fetchProgress = true;
        this._fetch$ = this._googleS.fetchDifference(this.accountId, this.locationId).subscribe(function (result) {
            _this.fetchProgress = false;
            _this.openFetchDialog(result, history);
        }, function (error) {
            console.log(error);
            _this.fetchProgress = false;
            _this._snack.openError('failed to fetch', 4000);
        });
    };
    DashboardLocationComponent.prototype.openFetchDialog = function (result, history, notifyErrors) {
        var _this = this;
        if (notifyErrors === void 0) { notifyErrors = false; }
        var _a, _b, _c, _d, _e, _f;
        var dialogRef = this._dialog.open(ModalFetchComponent, {
            width: '1000px',
            data: {
                pendingMask: ((_a = result) === null || _a === void 0 ? void 0 : _a.isDiffMask) ? [] : this.pendingMask,
                diffMask: ((_b = result) === null || _b === void 0 ? void 0 : _b.isDiffMask) ? this.diffMask : [],
                isDiffMask: ((_c = result) === null || _c === void 0 ? void 0 : _c.isDiffMask) ? (_d = result) === null || _d === void 0 ? void 0 : _d.isDiffMask : false,
                title: ((_e = result) === null || _e === void 0 ? void 0 : _e.action) == 'pull' ? 'fetch' : result.action,
                difference: (_f = result) === null || _f === void 0 ? void 0 : _f.difference,
                placeId: this.locationId,
                history: history,
                notifyErrors: notifyErrors,
                moreHoursLabels: this.moreHoursLabels
            }
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe(function (refresh) {
            if (refresh) {
                _this.updateLocation(true);
                _this._updateTable();
            }
        });
    };
    DashboardLocationComponent.prototype.lock = function () {
        var _this = this;
        this.progress = true;
        this._modal.openConfirmModal('Are you sure you want to lock this location?', 'Locking a location will periodically update GBP with the locked data. Any changes you make directly in GBP will be overwritten.', function (res) {
            if (!res) {
                return;
            }
            _this._locationS.update(_this._auth.session.gid, _this.accountId, _this.locationId, { lockedOn: new Date() }).toPromise().then(function (loc) {
                _this._googleS.saveLockHistory(_this.accountId, _this.locationId, 'locked', 'success').subscribe(function (result) {
                    _this.initData();
                });
                _this._snack.openSuccess('Location successfully locked!', 2000);
            }, function (err) {
                _this._snack.openError('There was a problem locking this location. Please try again later or contact support.', 2000);
                console.error('Error locking a location', err);
            });
        }, 2);
    };
    DashboardLocationComponent.prototype.unlock = function () {
        var _this = this;
        this.progress = true;
        this._locationS.update(this._auth.session.gid, this.accountId, this.locationId, { lockedOn: null }).toPromise().then(function (loc) {
            _this._snack.openSuccess('Location successfully unlocked!', 2000);
            _this._googleS.saveLockHistory(_this.accountId, _this.locationId, 'unlock', 'success').subscribe(function (result) {
                _this.initData();
            });
        }, function (err) {
            _this._snack.openError('There was a problem unlocking this location. Please try again later or contact support.', 2000);
            console.error('Error unlocking a location', err);
        });
    };
    DashboardLocationComponent.prototype.reloadPaginator = function ($event) {
        this.pageable = $event;
        this._updateTable();
    };
    DashboardLocationComponent.prototype.ngOnDestroy = function () {
        var _a, _b, _c, _d;
        (_a = this._getByPlaceId$) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        (_b = this._push$) === null || _b === void 0 ? void 0 : _b.unsubscribe();
        (_c = this._fetch$) === null || _c === void 0 ? void 0 : _c.unsubscribe();
        (_d = this._listLockHistory$) === null || _d === void 0 ? void 0 : _d.unsubscribe();
    };
    DashboardLocationComponent.prototype.pushGoogle = function () {
        var _this = this;
        this.isProgressPush = true;
        this._push$ = this._googleS.push(this.accountId, this.locationId).pipe(take(1)).subscribe(function (data) {
            var _a, _b, _c, _d;
            if (!data)
                return;
            else if (data.data.success == 200) {
                _this.isProgressPush = false;
                if (data.message)
                    _this._snack.openSuccess(data.message, 4000);
                else
                    _this._snack.openSuccess(data.data.message, 4000);
            }
            else if (data.data.success == 400 && data.message === 'attributes') {
                _this.fetchProgress = true;
                _this._fetch$ = _this._googleS.fetchDifference(_this.accountId, _this.locationId).take(1).subscribe(function (result) {
                    _this.isProgressPush = false;
                    _this.fetchProgress = false;
                    _this.openFetchDialog(result, undefined, true);
                }, function (error) {
                    var _a, _b, _c, _d;
                    _this.isProgressPush = false;
                    _this.fetchProgress = false;
                    var msg = ((_b = (_a = error) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) || _this.unexpectedError;
                    _this._snack.openError(msg, (_d = (_c = data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.timeout);
                });
            }
            else {
                _this.isProgressPush = false;
                var msg = ((_b = (_a = data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) ? data.data.message : _this.unexpectedError;
                _this._snack.openError(msg, (_d = (_c = data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.timeout);
            }
            _this.initData();
        }, function (error) {
            var _a, _b, _c, _d;
            _this.isProgressPush = false;
            var msg = ((_b = (_a = error) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) ? error.data.message : _this.unexpectedError;
            _this._snack.openError(msg, (_d = (_c = error) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.timeout);
            console.error(error);
        });
    };
    DashboardLocationComponent.prototype.handleUrls = function ($event) {
        this.urlAttributes = $event;
    };
    DashboardLocationComponent.prototype.showChanges = function (element) {
        this.openFetchDialog(element, true);
    };
    DashboardLocationComponent.prototype.dispatchAction = function (action) {
        switch (action) {
            case 'lock':
                this.lock();
                break;
            case 'unlock':
                this.unlock();
                break;
            case 'pushGoogle':
                this.pushGoogle();
                break;
            case 'fetchDifference':
                this.fetchDifference();
                break;
            default:
                break;
        }
    };
    DashboardLocationComponent.prototype.hasPendingMask = function (section) {
        if (this.pendingMask.length == 0) {
            return false;
        }
        return this.pendingMask.find(function (el) { return el == section; }) ? true : false;
    };
    DashboardLocationComponent.prototype.ngAfterViewChecked = function () {
        this._changeDetector.detectChanges();
    };
    DashboardLocationComponent.prototype.formatStatus = function (status) {
        if (status != 'OPEN') {
            status = status.split('_');
            status = status.join(' ');
        }
        return status;
    };
    DashboardLocationComponent.prototype.hasOpeningData = function () {
        return (Object.keys(this.location.openInfo.openingDate).length > 0);
    };
    return DashboardLocationComponent;
}());
export { DashboardLocationComponent };
