import { __awaiter, __generator } from "tslib";
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { AngularFireStorage } from '@angular/fire/storage';
import { SnackbarService } from './snackbar.service';
import { InsightsService } from './insights.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/fire/storage";
import * as i3 from "./insights.service";
import * as i4 from "./snackbar.service";
var SearchKeywordsService = /** @class */ (function () {
    function SearchKeywordsService(http, afsStorage, insightS, snackS) {
        this.http = http;
        this.afsStorage = afsStorage;
        this.insightS = insightS;
        this.snackS = snackS;
        this.sortByMap = {
            'impressions': 'numberOfImpressions',
            'prevPeriodComparison': 'comparison_percentage',
            'prevYearComparison': 'comparison_percentage',
            'prevMonthComparison': 'comparison_percentage',
            'default': 'atlas_search_score'
        };
    }
    SearchKeywordsService.prototype.getSearchKeywords = function (gid, reportId, locationId, accountId, period, startDate, endDate, sort, paginate) {
        if (gid === void 0) { gid = null; }
        if (reportId === void 0) { reportId = null; }
        if (locationId === void 0) { locationId = null; }
        if (accountId === void 0) { accountId = null; }
        var _a;
        period = period ? (_a = period) === null || _a === void 0 ? void 0 : _a.replace('Comparison', '') : null;
        var data = {
            "gid": gid,
            "reportId": reportId,
            "accountId": accountId,
            "locationId": locationId,
            "startDate": startDate,
            "endDate": endDate,
            "sortOrder": sort.sortOrder,
            "sortBy": sort.sortBy,
            "page": paginate.page,
            "size": paginate.size,
            "period": period,
        };
        return this.http.post(environment.apiUrl + "/v2/locations/search-keywords", data);
    };
    SearchKeywordsService.prototype.getSearchKeywordsAtlas = function (keyword, paginate, startDate, endDate, period, location_ids, sort) {
        var _a;
        var sortBy = {
            'impressions': 'numberOfImpressions',
            'prevPeriodComparison': 'comparison_percentage',
            'prevYearComparison': 'comparison_percentage',
            'prevMonthComparison': 'comparison_percentage',
            'default': 'atlas_search_score'
        };
        period = period === null ? 'prevYear' : period;
        period = (_a = period) === null || _a === void 0 ? void 0 : _a.replace('Comparison', '');
        var data = {
            "size": paginate.size,
            "page": paginate.page,
            startDate: startDate,
            endDate: endDate,
            location_ids: location_ids,
            keyword: keyword,
            period: period,
            sort_order: sort.sortDirection || 'desc',
            sort_by: sortBy[sort.sortBy] || sortBy['default'],
        };
        return this.http.post(environment.apiUrl + "/v2/search/keyword-search", data);
    };
    SearchKeywordsService.prototype.getViews = function (locationId, gid, accountId, startDate, endDate, viewBy) {
        if (locationId === void 0) { locationId = null; }
        if (gid === void 0) { gid = null; }
        if (accountId === void 0) { accountId = null; }
        if (viewBy === void 0) { viewBy = null; }
        var data = {
            "accountId": accountId,
            "locationId": locationId,
            "gid": gid,
            "startDate": startDate,
            "endDate": endDate,
        };
        if (viewBy) {
            data['viewBy'] = viewBy.toLowerCase();
        }
        return this.http.post(environment.apiUrl + "/v2/locations/views", data);
    };
    SearchKeywordsService.prototype.getSearchImpressions = function (locationId, gid, accountId, startDate, endDate) {
        if (locationId === void 0) { locationId = null; }
        if (gid === void 0) { gid = null; }
        if (accountId === void 0) { accountId = null; }
        var data = {
            "accountId": accountId,
            "locationId": locationId,
            "gid": gid,
            "startDate": startDate,
            "endDate": endDate,
        };
        return this.http.post(environment.apiUrl + "/v2/locations/search-impressions", data);
    };
    SearchKeywordsService.prototype.getTopicsData = function (startDate, endDate, paginate, gid, reportId, locationId, accountId) {
        if (gid === void 0) { gid = null; }
        if (reportId === void 0) { reportId = null; }
        if (locationId === void 0) { locationId = null; }
        if (accountId === void 0) { accountId = null; }
        var _a, _b;
        var data = {
            "accountId": accountId,
            "locationId": locationId,
            "reportId": reportId,
            "gid": gid,
            "startDate": startDate,
            "endDate": endDate,
            "size": (_a = paginate) === null || _a === void 0 ? void 0 : _a.size,
            "page": (_b = paginate) === null || _b === void 0 ? void 0 : _b.page,
        };
        return this.http.post(environment.apiUrl + "/v2/locations/keyword-trends", data);
    };
    SearchKeywordsService.prototype.handleExportCsvTopic = function (gid, reportId, accountId, locationId, startDate, endDate, topic) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.downloadTopicCsv(gid, reportId, accountId, locationId, startDate, endDate, topic).subscribe(function (res) {
                            var filename = res;
                            _this.afsStorage.ref(filename).getDownloadURL().toPromise().then(function (filename) {
                                _this.insightS.downloadFromGS(filename);
                                _this.snackS.openSuccess("The file exported successfully", 2000);
                            });
                        }, function (err) { return _this.snackS.openError("There was an error in the export", 2000); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SearchKeywordsService.prototype.handleExportCsvKeywordsSearch = function (startDate, endDate, locationIds, keyword, period, sort) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        period = period ? (_a = period) === null || _a === void 0 ? void 0 : _a.replace('Comparison', '') : null;
                        return [4 /*yield*/, this.createFileAndGetName(startDate, endDate, locationIds, keyword, period, sort).subscribe(function (res) {
                                var fileName = res["fileName"];
                                _this.afsStorage.ref(fileName).getDownloadURL().toPromise().then(function (fileName) {
                                    _this.insightS.downloadFromGS(fileName);
                                    _this.snackS.openSuccess("The file exported successfully", 2000);
                                });
                            }, function (err) { return _this.snackS.openError("There was an error in the export", 2000); })];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SearchKeywordsService.prototype.downloadTopicCsv = function (gid, reportId, accountId, locationId, startDate, endDate, topic) {
        var data = {
            "gid": gid,
            "accountId": accountId,
            "locationId": locationId,
            "reportId": reportId,
            "startDate": startDate,
            "endDate": endDate,
            "type": 'keyword-topic',
            "topic": topic,
        };
        return this.http.post(environment.apiUrl + "/v3/export/keyword-topic", data);
    };
    SearchKeywordsService.prototype.createFileAndGetName = function (startDate, endDate, locationIds, keyword, comparisonType, sort) {
        var data = {
            startDate: startDate,
            endDate: endDate,
            locationIds: locationIds,
            keyword: keyword,
            period: comparisonType,
            sortOrder: sort.sortDirection || 'desc',
            sortBy: this.sortByMap[sort.sortBy] || this.sortByMap['default']
        };
        return this.http.post(environment.apiUrl + "/v3/export/keyword-search", data);
    };
    SearchKeywordsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SearchKeywordsService_Factory() { return new SearchKeywordsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AngularFireStorage), i0.ɵɵinject(i3.InsightsService), i0.ɵɵinject(i4.SnackbarService)); }, token: SearchKeywordsService, providedIn: "root" });
    return SearchKeywordsService;
}());
export { SearchKeywordsService };
