/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error-message.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/read-more.component.ngfactory";
import * as i3 from "../../components/read-more.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "./error-message.component";
var styles_ErrorMessageComponent = [i0.styles];
var RenderType_ErrorMessageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorMessageComponent, data: {} });
export { RenderType_ErrorMessageComponent as RenderType_ErrorMessageComponent };
function View_ErrorMessageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "read-more", [], null, null, null, i2.View_ReadMoreComponent_0, i2.RenderType_ReadMoreComponent)), i1.ɵdid(2, 573440, null, 0, i3.ReadMoreComponent, [], { text: [0, "text"], maxLength: [1, "maxLength"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.error == null) ? null : _co.error.message); var currVal_1 = 300; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ErrorMessageComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" - ", " ", " ", " ", " ", " "]))], null, function (_ck, _v) { var currVal_0 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.message); var currVal_1 = (((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.field) && (" | Field: " + ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.field))); var currVal_2 = (((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.value) && (" | Value: " + ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.value))); var currVal_3 = (((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.min_length) && (" | Minimum length: " + ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.min_length))); var currVal_4 = (((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.max_length) && (" | Miximum length: " + ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.max_length))); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
function View_ErrorMessageComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" - ", " ", " ", " ", " ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.myBusinessErrors["businessInformation"][_v.parent.context.$implicit.code]; var currVal_1 = (((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.field) && (" | Field: " + ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.field))); var currVal_2 = (((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.value) && (" | Value: " + ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.value))); var currVal_3 = (((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.min_length) && (" | Minimum length: " + ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.min_length))); var currVal_4 = (((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.max_length) && (" | Miximum length: " + ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.max_length))); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
function View_ErrorMessageComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorMessageComponent_5)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noReason", 2]], null, 0, null, View_ErrorMessageComponent_6))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.message != null); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ErrorMessageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "m--10"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorMessageComponent_4)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error_details; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ErrorMessageComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" - ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.error == null) ? null : _co.error.message) || "Unknown error"); _ck(_v, 1, 0, currVal_0); }); }
function View_ErrorMessageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorMessageComponent_3)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noDetail", 2]], null, 0, null, View_ErrorMessageComponent_7))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.error_details == null) ? null : _co.error_details.length) > 0); var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ErrorMessageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "button", [["class", "btn--badge btn--badge--sm"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], null, null, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 0, "em", [["class", "fas fa-exclamation-triangle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Error details:"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorMessageComponent_1)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["normalStructure", 2]], null, 0, null, View_ErrorMessageComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "warn"; _ck(_v, 2, 0, currVal_2); var currVal_3 = (((_co.error == null) ? null : _co.error.description) === null); var currVal_4 = i1.ɵnov(_v, 8); _ck(_v, 7, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_ErrorMessageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-error-message", [], null, null, null, View_ErrorMessageComponent_0, RenderType_ErrorMessageComponent)), i1.ɵdid(1, 114688, null, 0, i9.ErrorMessageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorMessageComponentNgFactory = i1.ɵccf("app-error-message", i9.ErrorMessageComponent, View_ErrorMessageComponent_Host_0, { error: "error", error_details: "error_details" }, {}, []);
export { ErrorMessageComponentNgFactory as ErrorMessageComponentNgFactory };
