import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-grade-item',
  templateUrl: './grade-item.component.html',
  styleUrls: ['./grade-item.component.scss']
})
export class GradeItemComponent implements OnInit {
  @Input() gradeGoogleItem: { name: string, active: boolean, points: number, max: number };  

  constructor() {
  }

  ngOnInit() {
  }

  tooltipContent(gradeGoogleItem: { name: string; active: boolean; points: number; max: number }) {
    if (gradeGoogleItem.points && gradeGoogleItem.max)
      return `${gradeGoogleItem.points} / ${gradeGoogleItem.max}`;


    if (gradeGoogleItem.points && !gradeGoogleItem.max)
      return `${gradeGoogleItem.points}`;


    return false;


  }
}
