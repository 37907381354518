import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { EXTERNAL_GRADES, GROUPS } from '../constants/firestore/collections';
import { environment } from '@environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "@angular/common/http";
var ExternalGradeService = /** @class */ (function () {
    function ExternalGradeService(afs, http) {
        this.afs = afs;
        this.http = http;
    }
    ExternalGradeService.prototype.getExternalGradeToken = function (gid, externalGradeId) {
        return this.afs.collection(GROUPS).doc(gid).collection(EXTERNAL_GRADES).doc(externalGradeId).get().pipe(map(function (value) {
            var _a;
            return (_a = value.data()) === null || _a === void 0 ? void 0 : _a.googleAuth;
        }));
    };
    ExternalGradeService.prototype.uploadExternalGradePDF = function (gid, loc_id, acc_id, pdf, grade_lead_id, branding) {
        var data = {
            gid: gid,
            loc_id: loc_id,
            acc_id: acc_id,
            pdf: pdf,
            grade_lead_id: grade_lead_id,
            branding: branding
        };
        return this.http.post(environment.apiUrl + "/v2/pdf/save", data);
    };
    ExternalGradeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ExternalGradeService_Factory() { return new ExternalGradeService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.HttpClient)); }, token: ExternalGradeService, providedIn: "root" });
    return ExternalGradeService;
}());
export { ExternalGradeService };
