import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-frecuency-selector-report',
  templateUrl: './frecuency-selector.component.html',
  styleUrls: ['./frecuency-selector.component.scss']
})
export class FrecuencySelectorReportComponent implements OnInit {
  @Input() FrecuencySelect: 'WeeklyOn' | 'MonthlyOn' = 'WeeklyOn';
  @Input() frecuencyEdit: string = null;
  @Output() FrecuencyOutput: EventEmitter<string> = new EventEmitter();
  @Input() disabled: boolean = false;
  daySelected: string = null;
  daySelectedControl: FormControl = new FormControl(0, [Validators.min(1), Validators.max(28), Validators.required]);

  constructor() { }

  ngOnInit(): void {
    if (this.frecuencyEdit) {
      if (this.FrecuencySelect === 'WeeklyOn') this.daySelected = this.frecuencyEdit;
      else this.daySelectedControl.setValue(this.frecuencyEdit);
    }
  }

  selectDay(day: string): void {
    if (this.disabled) { return; }
    this.daySelected = day;
    this.FrecuencyOutput.emit(day);
  }

  SelectCalendar(): void {
    this.FrecuencyOutput.emit(this.daySelectedControl.value);
  }

}
