import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Cusines, FoodMenu } from '../../../../constants/google/menuList';

@Component({
  selector: 'app-modal-form-menu',
  templateUrl: './modal-form-menu.component.html',
  styleUrls: ['./modal-form-menu.component.scss']
})
export class ModalFormMenuComponent implements OnInit {

  isEdit: boolean = false;
  Cusines: Array<{key: string, description}> = Cusines;
  public form: FormGroup;
  sectionFoodMenuIndex: number = null;
  modelResponse: FoodMenu = null;

  constructor(
    public dialogRef: MatDialogRef<ModalFormMenuComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.isEdit = this.data.edit;
    this.initForm();

    if (this.data.foodMenu) {
      this.isEdit = true;
      this.sectionFoodMenuIndex = this.data.foodMenuIndex;
      this.modelResponse = this.data.foodMenu;
      this.form.setValue({
        labels: {
          displayName: this.modelResponse.labels[0].displayName || '',
          description: this.modelResponse.labels[0].description || '',
          languageCode: 'en'
        },
        cuisines: this.modelResponse.cuisines ? this.modelResponse.cuisines : []
      })
    }
  }

  initForm() {
    this.form = new FormGroup({
      labels: new FormGroup({
        displayName: new FormControl('', [Validators.required, Validators.maxLength(140)]),
        description: new FormControl(''),
        languageCode: new FormControl('en'),
      }),
      cuisines: new FormControl(''),
    });
  
  }

  apply() { 
    const FormResult = this.form.value;

    this.modelResponse.labels =[
      {
        displayName: FormResult.labels.displayName,
        description: FormResult.labels.description,
        languageCode: FormResult.labels.languageCode
      }
    ];

    this.modelResponse.cuisines = FormResult.cuisines
    

    this.dialogRef.close({action: 'updated',item: this.modelResponse });
  }

}
