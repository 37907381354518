import { __awaiter, __generator, __read } from "tslib";
// dep
import { OnInit, isDevMode } from '@angular/core';
import { MatDialogRef } from "@angular/material";
import { FormBuilder, Validators } from '@angular/forms';
import { StripeService } from 'ngx-stripe';
// app
import { PaymentsService } from '../../../services/payments.service';
import { WhiteLabelService } from "../../../services/white-label.service";
import { ModalService } from "../../../services/modal.service";
import { SnackbarService } from '../../../services/snackbar.service';
import { AuthService } from '../../../services/auth.service';
// TODO: Rename to AddCardComponent, this component shows a form to add a new Credit Card, 
// NOT to update an existing one. Misleading name.
// Also see MAP-2234
var UpdateCardComponent = /** @class */ (function () {
    function UpdateCardComponent(data, dialogRef, formBuilder, stripeService, paymentService, snack, wl, modalService, authService) {
        this.data = data;
        this.dialogRef = dialogRef;
        this.formBuilder = formBuilder;
        this.stripeService = stripeService;
        this.paymentService = paymentService;
        this.snack = snack;
        this.wl = wl;
        this.modalService = modalService;
        this.authService = authService;
        this.elementOptions = {
            locale: 'en'
        };
        this.loading = false;
    }
    Object.defineProperty(UpdateCardComponent.prototype, "form", {
        get: function () {
            return this.stripe;
        },
        enumerable: true,
        configurable: true
    });
    UpdateCardComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            function setOutcome(result) {
                var successElement = document.querySelector('.success');
                var errorElement = document.querySelector('.error');
                successElement.classList.remove('visible');
                errorElement.classList.remove('visible');
                if (result.token) {
                    successElement.querySelector('.token').textContent = result.token.id;
                    successElement.classList.add('visible');
                }
                else if (result.error) {
                    errorElement.textContent = result.error.message;
                    errorElement.classList.add('visible');
                }
            }
            var _a, stripeDomainAccountId, stripePubKey, inputs;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.stripe = this.formBuilder.group({ name: ['', Validators.required] });
                        return [4 /*yield*/, Promise.all([
                                this.wl.getStripeConnectAccountId(),
                                isDevMode() ? this.wl.getStripeTestMaplabsPublicKey() :
                                    this.wl.getStripePublicKey()
                            ])];
                    case 1:
                        _a = __read.apply(void 0, [_b.sent(), 2]), stripeDomainAccountId = _a[0], stripePubKey = _a[1];
                        this.stripeService.changeKey(stripePubKey, { 'stripeAccount': stripeDomainAccountId });
                        this.stripeService.elements(this.elementOptions).subscribe(function (elements) {
                            _this.elements = elements;
                            if (!_this.card) {
                                _this.card = _this.elements.create('card', {
                                    iconStyle: 'solid',
                                    style: {
                                        base: {
                                            iconColor: '#8c91a2',
                                            lineHeight: '1.2',
                                            fontWeight: 300,
                                            fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
                                            fontSize: '16px',
                                            '::placeholder': { color: '#8c91a2' },
                                        },
                                        invalid: {
                                            iconColor: '#e85746',
                                            color: '#e85746',
                                        }
                                    },
                                    // @ts-ignore
                                    classes: {
                                        focus: 'is-focused',
                                        empty: 'is-empty',
                                    },
                                });
                            }
                        });
                        this.card.mount('#card-element');
                        inputs = document.querySelectorAll('input.field');
                        Array.prototype.forEach.call(inputs, function (input) {
                            input.addEventListener('focus', function () { input.classList.add('is-focused'); });
                            input.addEventListener('blur', function () { input.classList.remove('is-focused'); });
                            input.addEventListener('keyup', function () {
                                if (input.value.length === 0) {
                                    input.classList.add('is-empty');
                                }
                                else {
                                    input.classList.remove('is-empty');
                                }
                            });
                        });
                        this.card.on('change', function (event) {
                            setOutcome(event);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * "Save" button handler
     */
    UpdateCardComponent.prototype.save = function () {
        var _a, _b, _c, _d, _e;
        return __awaiter(this, void 0, void 0, function () {
            var token_id, name_1, res, e_1, user_msg;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        _f.trys.push([0, 3, 4, 5]);
                        this.loading = true;
                        name_1 = this.stripe.get('name').value;
                        return [4 /*yield*/, this.stripeService.createToken(this.card, { name: name_1 }).toPromise()];
                    case 1:
                        res = _f.sent();
                        if (res.error)
                            throw new Error((_a = res.error) === null || _a === void 0 ? void 0 : _a.message);
                        // "token.id" has the pattern "tok_XXXXX"
                        token_id = res.token.id;
                        // 2- Pass the token to the backend
                        // It will call https://docs.stripe.com/api/cards/create
                        // Stripe will do more card validation on that endpoint.
                        return [4 /*yield*/, this.paymentService.addNewCard(this.authService.session.gid, token_id)];
                    case 2:
                        // 2- Pass the token to the backend
                        // It will call https://docs.stripe.com/api/cards/create
                        // Stripe will do more card validation on that endpoint.
                        _f.sent();
                        this.snack.openSuccess('New card added succesfully', 3000);
                        this.dialogRef.close(true);
                        return [3 /*break*/, 5];
                    case 3:
                        e_1 = _f.sent();
                        user_msg = (!token_id && ((_b = e_1) === null || _b === void 0 ? void 0 : _b.message)) ? e_1.message : (_e = (_d = (_c = e_1) === null || _c === void 0 ? void 0 : _c.error) === null || _d === void 0 ? void 0 : _d.detail) === null || _e === void 0 ? void 0 : _e.user_message;
                        if (token_id && !user_msg)
                            // Log backend non-Stripe error to Datadog
                            console.error("Error adding new Card (backend)", e_1);
                        else
                            console.log("Error adding new Card", e_1);
                        // Show the error we get from Stripe or a fixed one if it's a backend non-Stripe error
                        this.modalService.openErrorModal('Credit Card Failed', user_msg || 'Please try with a different credit card');
                        this.dialogRef.close(false);
                        return [3 /*break*/, 5];
                    case 4:
                        this.loading = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    UpdateCardComponent.prototype.goToPaymentPolicy = function () {
        this.wl.goToPaymentPolicy();
    };
    UpdateCardComponent.prototype.goToPrivacyPolicy = function () {
        this.wl.goToPrivacyPolicy();
    };
    return UpdateCardComponent;
}());
export { UpdateCardComponent };
