
import {Component, OnDestroy, isDevMode} from '@angular/core';
import { NavigationEnd, Router /*, ActivatedRoute*/ } from '@angular/router';
import * as _ from 'lodash';
import {Subject} from 'rxjs';
import {WhiteLabelService} from './services/white-label.service';
import {AuthService} from './services/auth.service';
import {UserService} from './services/user.service';

import {environment} from '@environment';
// import {Title} from "@angular/platform-browser";
import {filter, first} from "rxjs/operators";


import { Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ModalService } from './services/modal.service';
import { HEADERS_NO_AUTH, MAIL_EXTERNAL_GRADE } from './constants/auth';
import { STORAGE_CUSTOM_SCRIPT } from "./constants/session"
import { AlertType } from './components/alert.component';
import { isRunningEmbedded } from './helpers/utils.helpers';

// Global set from Google Analytics Library
declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  title = 'map labs';
  subsManager$: Subject<boolean> = new Subject();

  constructor(
    private router: Router,
    private wl: WhiteLabelService,
    private renderer: Renderer2,
    private httpClient: HttpClient,
    private modalService: ModalService,
    private auth: AuthService,
    private userService: UserService,

    @Inject(DOCUMENT) private document: Document
  ) {

    this.wl.getDynamicWhiteLabel().then((data: any) => {

      //this.titleService.setTitle(data.branding.company_name);

      console.debug('Branding loaded', data.branding);

      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
      ).subscribe((event: NavigationEnd) => {
        const isShared = _.endsWith(this.router.url, 'shared');
          if(!isShared) {
            if (data.branding.podium) {
              this.loadPodiumScript(data.branding.podium);
            } else if(data.branding.beacon) {
              this.loadBeaconScript(data.branding.beacon);
            }
          }

          if (typeof gtag === 'function') { // Check if gtag is defined
            gtag('config', _.get(environment, 'branding.analytics') || 'UA-138537135-2', {
              'page_path': event.urlAfterRedirects
            });
          } else {
            console.error('gtag is not defined');
          }
          
      });

      if (!isRunningEmbedded()) {
        localStorage.setItem(STORAGE_CUSTOM_SCRIPT, data.branding.customScript);
      }

    });

    let savedVersion; 
    const URL = '/assets/version.json';
    setInterval(() => {  
      this.httpClient.get(URL, HEADERS_NO_AUTH).subscribe(
      (version: any) =>{
        const currentVersion = version?.version;
        if(savedVersion && savedVersion !== currentVersion){
          this.modalService.openAlertModal(
            'New Version Available',
            'A new version is available, please refresh the page to continue.',
            AlertType.WARNING,
            null,
            'Reload Page',
            () => {
              window.location.reload();
            } 
            
          )
        }
        savedVersion = currentVersion;
      },
      error => {}
      );
      
    }, 1000 * 60 * 2);

    const interval = setInterval(() => {  
      this.auth.afAuth.authState.pipe(first()).toPromise().then(isSignedIn => {
        const isShared = _.endsWith(this.router.url, 'shared');
        const isExternalUrlGrade = _.endsWith(this.router.url, 'grade')
        if (isSignedIn && !isShared  && !isExternalUrlGrade && this.auth.session.email !== MAIL_EXTERNAL_GRADE) {
          this.userService.domainValidation(location.hostname, this.auth.session.gid, this.auth.session.uid, this.auth.session.domainSurfing).subscribe(
            async (res) => {
              // The isDevMode flag should be present here to enable impersonation during development.
              if (!res['allowLogin'] && !isDevMode()) {
                console.debug(`Domain validation FAILED: Domain [${location.hostname}], registration domain [${res['domain']}`);
                
                // Prevents interval from running again
                clearInterval(interval);
                
                // Show alert modal
                this.modalService.openAlertModal(
                  'Heads up',
                  `Sorry, we couldn't find your account. Please check your username and password or contact support.`,
                  AlertType.ERROR,
                  null,
                  'OK',
                  () => {
                    // log user out
                    this.auth.signOut(true, true);
                  }
                )
              } else if (isDevMode()) {
                console.debug(`Domain validation OK: DevMode [${isDevMode()}], Domain [${location.hostname}], registration domain [${res['domain']}]`);
              }
            },
            err => {
              console.log(err);
            }
          );
        }
      })
    }, 1000 * 60 * 1);
  }

  loadPodiumScript(token): HTMLScriptElement {
    const script = this.renderer.createElement('script');
    script['defer'] = true;
    script['type'] = 'text/javascript';
    script['src'] = `https://connect.podium.com/widget.js#API_TOKEN=${token}`;
    script['id']="podium-widget";
    script['data-api-token'] = token;
    this.renderer.appendChild(this.document.body, script);
    return script;
  }

  loadBeaconScript(id) {
    const container = this.renderer.createElement('section');

    const script = this.renderer.createElement('script');
    script['type'] = 'text/javascript';
    script.innerText = function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e['Beacon']=n=function(t,n,a){e['Beacon'].readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e['attachEvent']?e['attachEvent']("onload",a):e.addEventListener("load",a,!1)}(window,document,window['Beacon']||function(){});
    container.appendChild(script);

    const scriptId = this.renderer.createElement('script');
    scriptId['type'] = 'text/javascript';
    scriptId.innerText = window['Beacon']('init', id);
    container.appendChild(scriptId);

    this.renderer.appendChild(this.document.head, container);
    return container;
  }

  ngOnDestroy(): void {
    this.subsManager$.next(true);
    this.subsManager$.complete();
  }

}
