import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DatesService } from 'src/app/services/dates.service';
import { PostService } from 'src/app/services/post.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-post-management-delete-date-modal',
  templateUrl: './post-management-delete-date-modal.component.html',
  styleUrls: ['./post-management-delete-date-modal.component.scss']
})
export class PostManagementDeleteDateModalComponent implements OnInit {
  public isProgress = false;
  public postIds = [];
  public isMultiplePost: boolean;
  public irRecurring: boolean;
  public deleteButtonLabel = 'Delete Now';
  public deleteWholeSeries = false;
  public whenToDelete = 'deleteNow';
  public minDate = new Date();
  public hoursList = [];
  public deleteDate;
  public deleteTime;
  public hoursListOptions: Observable<string[]>;

  constructor(
    public dialogRef: MatDialogRef<PostManagementDeleteDateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    
    private _dateS: DatesService,
    private _postS: PostService,
    private _snackS: SnackbarService
  ) {
    if (this.data) {
      this.postIds = this.data?.postIds;
      this.isMultiplePost = this.data?.isMultiplePost;
      this.irRecurring = this.data?.irRecurring;
    }
  }

  ngOnInit() {
    this.hoursList  = this._dateS.loadHoursDropdown(true);
    this.hoursListOptions = new FormControl('').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  whenDeletedChanged(): void {
    if(this.whenToDelete === 'deleteNow') {
      this.deleteDate = null;
      this.deleteTime = null;
      this.deleteButtonLabel = 'Delete Now';
    } else {
      this.deleteButtonLabel = 'Schedule delete';
    }
  }

  deletePosts(): void {   
    this.isProgress = true; 
    const date = this.whenToDelete === 'deleteNow' ? null : moment(this.deleteDate).format('YYYY-MM-DD');
    const time = this.whenToDelete === 'deleteNow' ? null : this.deleteTime.split(' ')?.[0];

    this._postS.deletePost(this.postIds, date, time, this.deleteWholeSeries).subscribe(
      res => {
        this.dialogRef.close(true);
        
      },
      err => {
        this._snackS.openError('There was an error while deleting the post', 2000);
        this.dialogRef.close(false);
      }
    )
  }

  validateInput(event: KeyboardEvent) {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ':', 'A', 'P', 'M', ':', 'BACKSPACE', ' ', 'TAB', 'ARROWLEFT', 'ARROWRIGHT', 'DELETE'];
    const key = event.key.toUpperCase();

    if (!allowedKeys.includes(key)) {
      event.preventDefault();
    }
  }

  // validateTime(value: string) {
  //   this.timeFormatError = !moment(value, 'hh:mm A', true).isValid();
  // }


  get scheduleDateTime(): string {
    let formattedDate = null;

    if (this.deleteDate && this.deleteTime) {
      const moment = require('moment-timezone');
      const offset =  moment(this.parsedDate).format('Z');

      formattedDate = `${moment(this.parsedDate).format('MM/DD/YYYY')} ${this.deleteTime} GMT${offset}`
    }
    
    return formattedDate;
  }

  get parsedDate(): string {
    const date = moment(this.deleteDate).format('YYYY-MM-DD');
    const deleteTime = this.deleteTime ? this.deleteTime : moment().format('hh:mm A');
    const time = moment(deleteTime, "hh:mm A").format("HH:mm:ss")

    return `${date}T${time}Z`;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.hoursList.filter(option => option.toLowerCase().includes(filterValue));
  }

}
