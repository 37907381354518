import { __assign, __awaiter, __generator, __read, __spread, __values } from "tslib";
// dep
import { AfterViewInit, ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTable } from '@angular/material';
import { FormControl } from '@angular/forms';
import { takeUntil, take, switchMap, map } from 'rxjs/operators';
import { BehaviorSubject, Subject, of, merge, timer } from 'rxjs';
import * as _ from 'lodash';
// app
import { AuthService } from '../../services/auth.service';
import { LocationService } from '../../services/location.service';
import { SubscriptionService } from '../../services/subscription.service';
import { AccountService } from '../../services/account.service';
import { SnackbarService } from '../../services/snackbar.service';
import { GROUP_SUBSCRIPTION_TYPE, LOCATION_SUBSCRIPTION_TYPE } from '../../constants/firestore/account-location';
import { ObservationService } from '../../services/observation.service';
import { ModalService } from '../../services/modal.service';
import * as constants_locations from '../../constants/firestore/account-location';
import * as constants_plans from '../../constants/firestore/plan';
import { WhiteLabelService } from "../../services/white-label.service";
import { PaginatorComponent } from '../charts/paginator/paginator.component';
import { NotificationService } from 'src/app/services/notification.service';
import { DELETE_DATA, NOTIFICATION_GENERAL, TYPE_LOG_LOCATION } from 'src/app/constants/notifications';
import { Messages, string_message } from 'src/app/constants/messages';
import { SpinnerService } from 'src/app/services/spinner.service';
import { AlertType } from 'src/app/components/alert.component';
var LocationsComponent = /** @class */ (function () {
    // private _previousPageable: Pageable = null;
    function LocationsComponent(auth, _router, _route, _detectStrategy, _spinnerService, _snack, _locationService, _accountService, _apiSubscription, _obsService, _modalService, _location, _notificationService, _wl) {
        var _this = this;
        this.auth = auth;
        this._router = _router;
        this._route = _route;
        this._detectStrategy = _detectStrategy;
        this._spinnerService = _spinnerService;
        this._snack = _snack;
        this._locationService = _locationService;
        this._accountService = _accountService;
        this._apiSubscription = _apiSubscription;
        this._obsService = _obsService;
        this._modalService = _modalService;
        this._location = _location;
        this._notificationService = _notificationService;
        this._wl = _wl;
        this.STATUS_CHANGE_PLAN = constants_plans.STATUS_CHANGE_PLAN;
        this.LOCATION_SUBSCRIPTION_TYPE = LOCATION_SUBSCRIPTION_TYPE;
        this.GROUP_SUBSCRIPTION_TYPE = GROUP_SUBSCRIPTION_TYPE;
        // public authOk           = constants_locations.VERIFICATION_OK;
        this.authUnauthorized = constants_locations.VERIFICATION_UNAUTHORIZED;
        this.authUnauthorized_2 = constants_locations.VERIFICATION_UNAUTHORIZED_2;
        this.authNotFound = constants_locations.VERIFICATION_NOT_FOUND;
        this.authNotFound_2 = constants_locations.VERIFICATION_NOT_FOUND_2;
        this.accountError = constants_locations.VERIFICATION_ACCOUNT_ERROR;
        this.verifRequired = constants_locations.VERIFICATION_REQUIRED;
        this.totalLocations = [];
        this.filteredLocationIds = [];
        this.locations = [];
        this.accountsOptions = [];
        this.filteredAccounts = [];
        this.filterAccountsControl = new FormControl('');
        this.allLocationCheck = false;
        this.listCheckLocations = [];
        this.upgradeCheckSelect = false;
        this.downgradeCheckSelect = false;
        this.locationsUser = [];
        this.textSearch = null;
        this.size = 10;
        this.page = 1;
        this.pagination = {
            items: [],
            per_page: this.size,
            page: this.page,
            hasNext: false,
            hasPrev: false,
            pages: 0,
            total: 0
        };
        this.selectedSearch = new FormControl();
        this.filtered = false;
        this.loadingTable$ = new BehaviorSubject(true);
        this.NotFoundSearch$ = new BehaviorSubject(false);
        this.timeout = null;
        this.errorAccount = false;
        this.companyName = '';
        this._hasAccountsFilter = false;
        this._refreshTimerStarted = false;
        this._destroy$ = new Subject();
        this.user = this.auth.session;
        this._untilDestroy(this.auth.subscription$).subscribe(function (sub) {
            _this.subscription = sub;
            _this._updateTable();
        });
        // Reload the locations table when we trigger a location plan change from this
        // component or the plan change is externally started by TrialMessageComponent
        var is_first_value = true;
        this._untilDestroy(this._locationService.someLocationChanged).subscribe(function (_) {
            if (is_first_value)
                is_first_value = false;
            else
                _this._init();
        });
    }
    LocationsComponent.prototype._untilDestroy = function (o) {
        return o.pipe(takeUntil(this._destroy$));
    };
    LocationsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._untilDestroy(this._locationService.getPaginate()).subscribe(function (res) { return _this._paginate = res; });
        this._init();
        this._untilDestroy(this._router.events).subscribe(function (event) {
            if (event instanceof NavigationEnd) {
                _this.loadingTable$.next(true);
                _this.accountObjectId = event.url.split('/')[2];
                _this.filterAccountsByAccountId();
                _this._init();
            }
        });
    };
    LocationsComponent.prototype.ngAfterViewInit = function () {
        this.manualPage = 1;
        this.errorMessage = false;
        this._detectStrategy.detectChanges();
    };
    LocationsComponent.prototype.ngOnDestroy = function () {
        this._locationService.reset();
        this._destroy$.next(true);
        // Now let's also unsubscribe from the subject itself:
        this._destroy$.unsubscribe();
    };
    LocationsComponent.prototype.resetListSelect = function () {
        this.listCheckLocations = [];
    };
    LocationsComponent.prototype.onAddAccount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._router.navigate(['accounts'])];
                    case 1:
                        _a.sent();
                        this._obsService.sendAddAccount();
                        return [2 /*return*/];
                }
            });
        });
    };
    LocationsComponent.prototype.openDeleteDialog = function (location) {
        var _this = this;
        var _a;
        this._modalService.openConfirmModal("Are you sure you want to disconnect " + ((_a = location) === null || _a === void 0 ? void 0 : _a.locationName) + " from " + this.companyName + "?", "Note: this only disconnects the profile from " + this.companyName + "; it does not delete your business profile from Google Maps. You can always re-add your business profile to " + this.companyName + ".", function (res) {
            if (!res) {
                return;
            }
            //Notification informative delete location
            _this._spinnerService.loading$.next(true);
            location.formatAddress = !location.address ? '' : _this._locationService.formatAddress(location.address);
            var delete$ = of(_this._locationService.deleteReferencesToLocation(_this.user.gid, location.locationId));
            delete$.pipe(switchMap(function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._locationService.deleteLocation(this.user.gid, location.locationId, this.accountObjectId)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            }); }); })).subscribe(function () {
            }, function (err) {
                _this._spinnerService.loading$.next(false);
                console.error('Error deleting locations', err);
            }, function () {
                location = __assign({ accountId: _this.accountObjectId, address: location.formatAddress }, location);
                var meta = _this._notificationService.getMetaTypeLog(TYPE_LOG_LOCATION, location);
                var notify$ = merge(_this._notificationService.saveNotification(_this.auth.session.gid, _this._wl.baseDomain, string_message(Messages.notifications.LOCATION_TOGGLE, [location.locationName, location.address, DELETE_DATA]), NOTIFICATION_GENERAL, TYPE_LOG_LOCATION, meta));
                notify$.subscribe();
                var locations = _this.locations.filter(function (l) { return l.locationId !== location.locationId; });
                if (locations.length !== 0 || _this.filtered) {
                    _this.listCheckLocations = [];
                    _this._init();
                    return;
                }
                // Delete the account itself and navigate to /accounts
                _this._accountService.delete(_this.user.gid, _this.accountObjectId).then(function () {
                    _this._router.navigate(['/accounts']).then();
                }, function (err) { return console.error("Error deleting master AOID: " + _this.accountObjectId, err); });
                _this._spinnerService.loading$.next(false);
            });
        }, 2);
    };
    LocationsComponent.prototype._initPaginator = function () {
        this._paginate = { page: 1, size: 10 };
        this.paginatorComponent.reset();
        // this._previousPageable = null;
    };
    // apply filter from search
    LocationsComponent.prototype.applyFilter = function ($event) {
        var _this = this;
        this.NotFoundSearch$.next(false);
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
            if (!$event) {
                _this.filtered = false;
                _this.filteredLocationIds = [];
                _this.loadingTable$.next(true);
                _this._initPaginator();
                _this._getData(_this._paginate);
            }
            else {
                if ($event[$event.length - 1] !== ' ') {
                    if (!_this.filtered)
                        _this._initPaginator();
                    _this.filtered = true;
                    _this.loadingTable$.next(true);
                    var text = $event.toLowerCase();
                    _this.searchText = text;
                    _this.updateData(_this._paginate, text);
                }
            }
        }, 350);
    };
    LocationsComponent.prototype._getData = function ($event, locationsIds) {
        var _this = this;
        if (locationsIds === void 0) { locationsIds = []; }
        if (!this.auth.isAdmin) {
            locationsIds = this.locationsUser;
        }
        // FIXME: Subscription leak? multiple handlers will be run in paralell as they are all
        // subscribed?
        this._untilDestroy(this._locationService.getLocationPaginate(this.user.gid, this.accountObjectId, $event, locationsIds))
            .subscribe(function (r) {
            // this._previousPageable = { size: r['perPage'], 
            //                            page: r['currentPage'] };
            _this.pagination = {
                items: r['items'],
                per_page: r['perPage'],
                page: r['currentPage'],
                hasNext: r['currentPage'] < r['totalPages'],
                hasPrev: r['currentPage'] > 1,
                pages: r['totalPages'],
                total: r['totalItems']
            };
            _this.locations = _this._formatData(r['items'], _this.subscription);
            _this.countLocations = r['totalItems'];
            _this._updateTable();
            _this._spinnerService.loading$.next(false);
        }, function (err) {
            _this._spinnerService.loading$.next(false);
            console.log(err);
        });
    };
    LocationsComponent.prototype.updateData = function ($event, text) {
        var _this = this;
        this._locationService.getLocationsIdsByStringQuery(this.user.gid, text)
            .pipe(map(function (response) {
            var _a, _b, _c, _d;
            var filteredAccount = ((_b = (_a = response) === null || _a === void 0 ? void 0 : _a.accounts) === null || _b === void 0 ? void 0 : _b.find(function (account) { return account.accountId === _this.accountObjectId; })) || null;
            _this.filteredLocationIds = ((_d = (_c = filteredAccount) === null || _c === void 0 ? void 0 : _c.locations) === null || _d === void 0 ? void 0 : _d.map(function (location) { return location.locationId; })) || [];
            return _this.filteredLocationIds;
        }))
            .subscribe(function (locationIds) {
            if (locationIds.length > 0) {
                _this._getData($event, locationIds);
            }
            else {
                _this.NotFoundSearch$.next(true);
                _this.loadingTable$.next(false);
            }
        });
    };
    LocationsComponent.prototype.setElementSwitch = function (element) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var returnStatus = '';
        if (((_c = (_b = (_a = element) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.locationState) === null || _c === void 0 ? void 0 : _c.isVerified) === false) {
            returnStatus = this.verifRequired;
        }
        else if (((_e = (_d = element) === null || _d === void 0 ? void 0 : _d.lastCheck) === null || _e === void 0 ? void 0 : _e.status) && element.lastCheck.status !== 'OK') {
            returnStatus = (element === 'NOT AUTHORIZED' || element === 'NOT_AUTHORIZED' ? 'NOT_AUTHORIZED' :
                element === 'NOT FOUND' || element === 'NOT_FOUND' ? 'NOT_FOUND' : element.lastCheck.status);
        }
        else if ((_f = element) === null || _f === void 0 ? void 0 : _f.errorLog) {
            returnStatus = 'errorLog';
        }
        console.log((_h = (_g = element) === null || _g === void 0 ? void 0 : _g.location) === null || _h === void 0 ? void 0 : _h.locationName, returnStatus);
        return returnStatus;
    };
    // check for nested objects
    LocationsComponent.prototype._nestedFilterCheck = function (search, data, key) {
        if (typeof data[key] === 'object') {
            for (var k in data[key]) {
                if (data[key][k] !== null) {
                    search = this._nestedFilterCheck(search, data[key], k);
                }
            }
        }
        else {
            search += data[key];
        }
        return search;
    };
    LocationsComponent.prototype.toggleCheckAll = function (check) {
        var _this = this;
        this.allChecked = check.checked;
        this._getTotalLocations();
        this.locations.forEach(function (location) {
            if (_this.allChecked) {
                var result = _this.listCheckLocations.find(function (l) { return l.locationId == location.locationId; });
                if (!result) {
                    _this.listCheckLocations.push({
                        locationId: location.locationId,
                        subscriptionType: location.subscriptionType,
                        pendingChange: location.pendingChange,
                        locationName: location.locationName,
                        location: location.location,
                        checked: _this.allChecked
                    });
                }
            }
            else {
                _this.listCheckLocations = [];
                _this.allLocationCheck = false;
                var result = _this.listCheckLocations.find(function (l) { return l.locationId == location.locationId; });
                if (result) {
                    _this.listCheckLocations = _this.listCheckLocations.filter(function (l) { return l.locationId !== location.locationId; });
                }
            }
            location.isChecked = _this.allChecked;
        });
        this.isUpgradeDowngradeActions();
    };
    LocationsComponent.prototype._getTotalLocations = function () {
        var _this = this;
        this._locationService.locations.pipe(take(1)).subscribe(function (locations) {
            if (_this.filteredLocationIds.length > 0)
                _this.totalLocations = locations.filter(function (l) { return _this.filteredLocationIds.includes(l.locationId); });
            else
                _this.totalLocations = locations;
        });
    };
    LocationsComponent.prototype.selectLocation = function (location, check) {
        if (this.listCheckLocations.length > 0) {
            var result = this.listCheckLocations.find(function (l) { return l.locationId == location.locationId; });
            if (result) {
                this.listCheckLocations = this.listCheckLocations.filter(function (l) { return l.locationId !== location.locationId; });
            }
            else {
                this.listCheckLocations.push({
                    locationId: location.locationId,
                    pendingChange: location.pendingChange,
                    subscriptionType: location.subscriptionType,
                    locationName: location.locationName,
                    location: location.location,
                    checked: check.checked
                });
            }
        }
        else {
            this.listCheckLocations.push({
                locationId: location.locationId,
                pendingChange: location.pendingChange,
                subscriptionType: location.subscriptionType,
                locationName: location.locationName,
                location: location.location,
                checked: check.checked
            });
        }
        this.isUpgradeDowngradeActions();
    };
    LocationsComponent.prototype.isUpgradeDowngradeActions = function () {
        if (this.listCheckLocations.length > 0) {
            this.upgradeCheckSelect = !!this.listCheckLocations.find(function (l) { return l.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE; });
            this.downgradeCheckSelect = !!this.listCheckLocations.find(function (l) { return l.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.BASIC ||
                l.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ULTIMATE; });
        }
        else {
            this.upgradeCheckSelect = false;
            this.downgradeCheckSelect = false;
        }
    };
    LocationsComponent.prototype._generateBulkSubscriptionErrorMessage = function (unverifiedLocations, locationsChange) {
        var _this = this;
        var message = "<div class='txt--left'>\n    The following location(s) need to be verified in your Google Business Profile before you can update the subscription(s).\n      <div>\n      <br>\n        <ul>";
        unverifiedLocations.forEach(function (location) {
            var _a, _b, _c;
            message += "<div>" + ((_a = location) === null || _a === void 0 ? void 0 : _a.locationName) + "</div><li>" + _this._locationService.formatAddress((_c = (_b = location) === null || _b === void 0 ? void 0 : _b.location) === null || _c === void 0 ? void 0 : _c.address) + "</li>";
        });
        message += "</ul>\n      </div>";
        if (locationsChange.length >= 1) {
            message += "Would you like to proceed with updating the other locations?";
        }
        message += "</div>";
        return message;
    };
    // Function change to plan locations
    LocationsComponent.prototype.changePlan = function (locations) {
        return __awaiter(this, void 0, void 0, function () {
            var locationsChange, unverifiedLocations, ret;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.auth.isMember) {
                            this._modalService.openWarningModal('Contact your administrator', 'Your user doesn’t have permissions to do this. Contact your account administrator to change your role.');
                            return [2 /*return*/];
                        }
                        locationsChange = [];
                        unverifiedLocations = [];
                        if (!_.isArray(locations)) {
                            if (!this._validateVerifiedStatus(locations, true))
                                return [2 /*return*/];
                            locationsChange = [{ locationId: locations.locationId,
                                    accountId: this.accountObjectId }];
                        }
                        else {
                            locations.forEach(function (location) {
                                var _a, _b, _c;
                                if (((_c = (_b = (_a = location) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.locationState) === null || _c === void 0 ? void 0 : _c.isVerified) == false) {
                                    unverifiedLocations.push(location);
                                }
                                else {
                                    locationsChange.push({ locationId: location,
                                        accountId: _this.accountObjectId });
                                }
                            });
                        }
                        if (!(unverifiedLocations.length >= 1)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._modalService.openAlertModal("Verification required in Google Business Profile", this._generateBulkSubscriptionErrorMessage(unverifiedLocations, locationsChange), AlertType.ERROR, null, 'Ok', function () { return true; })];
                    case 1:
                        ret = _a.sent();
                        if (!ret || locationsChange.length === 0) {
                            return [2 /*return*/];
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this._apiSubscription.flowChangeLocationsPlan(this.subscription, locationsChange)
                        // locationService.someLocationsChanged will fire if a plan change was made
                    ];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    LocationsComponent.prototype.refreshLocation = function (locationId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.filtered)
                            this.applyFilter(this.searchText);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        return [4 /*yield*/, this._locationService.refreshAll(this.accountObjectId, locationId, this.user.gid)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        if (this.filtered)
                            this.applyFilter(this.searchText);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    LocationsComponent.prototype.refreshSelected = function () {
        var _this = this;
        this._snack.openInfo("The selected locations are being refreshed. Please wait while this is completed.", 1800);
        var count_refreshed = 0;
        this.listCheckLocations.forEach(function (l) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._locationService.refreshAll(this.accountObjectId, l.locationId, this.user.gid)];
                    case 1:
                        _a.sent();
                        count_refreshed++;
                        return [2 /*return*/];
                }
            });
        }); });
        if (count_refreshed === this.listCheckLocations.length) {
            this._snack.openSuccess("The selected locations was updated", 1800);
            this.resetListSelect();
        }
        if (this.filtered)
            this.applyFilter(this.searchText);
    };
    LocationsComponent.prototype.deleteSelected = function () {
        var _this = this;
        var locationsDeletedCount = 0;
        if (this.listCheckLocations.length === 1) {
            this.openDeleteDialog(_.head(this.listCheckLocations));
        }
        else {
            this._modalService.openConfirmModal("Are you sure you want to disconnect these business profiles from " + this.companyName + "?", "Note: this only disconnects the profiles from " + this.companyName + "; it does not delete your business profile from Google Maps. You can always re-add your business profiles to " + this.companyName + ".", function (res) {
                if (!res) {
                    return;
                }
                _this._spinnerService.loading$.next(true);
                _this.listCheckLocations.forEach(function (location) { return __awaiter(_this, void 0, void 0, function () {
                    var locationAddress, meta;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                locationAddress = location.location.address ? this._locationService.formatAddress(location.location.address) : '';
                                location = __assign({ gid: this.auth.session.gid, accountId: this.accountObjectId, address: locationAddress }, location);
                                meta = this._notificationService.getMetaTypeLog(TYPE_LOG_LOCATION, location);
                                this._notificationService.saveNotification(this.auth.session.gid, this._wl.baseDomain, string_message(Messages.notifications.LOCATION_TOGGLE, [location.locationName, location.address, DELETE_DATA]), NOTIFICATION_GENERAL, TYPE_LOG_LOCATION, meta);
                                return [4 /*yield*/, this._locationService.deleteLocation(this.user.gid, location.locationId, this.accountObjectId).then(function () {
                                        _this.countLocations = _this.countLocations - 1;
                                        _this.locations = _.reject(_this.locations, { locationId: location.locationId });
                                        locationsDeletedCount += 1;
                                        // Delete the account itself and navigate to /accounts
                                        if (_this.listCheckLocations.length === locationsDeletedCount) {
                                            _this.listCheckLocations = [];
                                            _this.allChecked = false;
                                            if (_this.countLocations == 0) {
                                                _this._accountService.delete(_this.user.gid, _this.accountObjectId).then(function () {
                                                    _this._router.navigate(['/accounts']).then();
                                                    _this._spinnerService.loading$.next(false);
                                                }, function (err) {
                                                    console.error("Error deleting master AOID: " + _this.accountObjectId, err);
                                                    _this._spinnerService.loading$.next(false);
                                                    if (!_this.filtered || _this.allChecked) {
                                                        _this._router.navigate(['/accounts']).then();
                                                    }
                                                });
                                            }
                                            else {
                                                // this.filtered = false;
                                                // this.getData(this.paginate);
                                                _this._init();
                                            }
                                        }
                                    })];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); });
            }, 2);
        }
    };
    LocationsComponent.prototype.getAccountsFilter = function () {
        return __awaiter(this, void 0, void 0, function () {
            var r, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        return [4 /*yield*/, this._accountService.getAccountPaginate(this.user.gid, { page: 1, size: 1000 }, [])];
                    case 1:
                        r = _a.sent();
                        this.accountsOptions = __spread(r.items);
                        this.filterAccountsByAccountId();
                        this._hasAccountsFilter = true;
                        return [3 /*break*/, 4];
                    case 2:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 4];
                    case 3:
                        this._spinnerService.loading$.next(false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    LocationsComponent.prototype.selectAll = function () {
        var _this = this;
        if (!this.allLocationCheck) {
            this.allLocationCheck = true;
            this.totalLocations.forEach(function (location) {
                var result = _this.listCheckLocations.find(function (l) { return l.locationId == location.locationId; });
                if (!result) {
                    _this.listCheckLocations.push({
                        locationId: location.locationId,
                        pendingChange: location.pendingChange,
                        subscriptionType: location.subscriptionType,
                        locationName: location.locationName,
                        checked: _this.allChecked,
                        location: location.location,
                    });
                }
            });
            this.locations.forEach(function (location) {
                _this.allChecked = true;
                location.isChecked = _this.allChecked;
            });
        }
        else {
            this.listCheckLocations = [];
            this.allLocationCheck = false;
            this.locations.forEach(function (location) {
                _this.allChecked = false;
                location.isChecked = _this.allChecked;
            });
        }
    };
    LocationsComponent.prototype._formatData = function (locations, subscription) {
        var e_2, _a;
        var existSelected = this.listCheckLocations.length > 0;
        locations = locations.filter(Boolean);
        var _loop_1 = function (location_1) {
            if (location_1 && subscription) {
                var status_1 = '';
                if (location_1.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE) {
                    status_1 = (subscription.status === GROUP_SUBSCRIPTION_TYPE.TRIAL ?
                        LOCATION_SUBSCRIPTION_TYPE.ULTIMATE_TRIAL :
                        LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL);
                }
                else {
                    status_1 = location_1.subscriptionType;
                }
                location_1['subscriptionStatus'] = status_1;
                if (existSelected)
                    location_1.isChecked = !!this_1.listCheckLocations.find(function (l) { return l.locationId === location_1.locationId; });
            }
        };
        var this_1 = this;
        try {
            for (var locations_1 = __values(locations), locations_1_1 = locations_1.next(); !locations_1_1.done; locations_1_1 = locations_1.next()) {
                var location_1 = locations_1_1.value;
                _loop_1(location_1);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (locations_1_1 && !locations_1_1.done && (_a = locations_1.return)) _a.call(locations_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        this.loadingTable$.next(false);
        return locations;
    };
    LocationsComponent.prototype.handleReload = function ($event) {
        this.loadingTable$.next(true);
        this._locationService.setPaginate($event);
        this._paginate = $event;
        if (this.filtered) {
            this.applyFilter(this.searchText);
        }
        else {
            this._getData($event);
        }
    };
    LocationsComponent.prototype.goLocation = function (accountId, location) {
        if (!this._validateVerifiedStatus(location))
            return;
        this._router.navigate(['/account', accountId, 'location', location.locationId, 'insights']);
    };
    LocationsComponent.prototype._validateVerifiedStatus = function (location, typeIsSubscription) {
        if (typeIsSubscription === void 0) { typeIsSubscription = false; }
        var _a, _b, _c;
        if (((_c = (_b = (_a = location) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.locationState) === null || _c === void 0 ? void 0 : _c.isVerified) == false) {
            this._modalService.openErrorModal("Verification required in Google Business Profile", "<div class='txt--left'>\n        " + (typeIsSubscription ?
                'In order to update your profile subscription, you need to verify your location.' :
                'This location requires verification, which prevents access to your dashboard.') + "\n        <div>\n          <br>Step 1: Log in to your Google Business Profile and verify your location.\n          <br>Step 2: Once verified, return and click the refresh button.\n        </div>\n      </div>");
            return false;
        }
        return true;
    };
    LocationsComponent.prototype.goNewTab = function (accountId, location, event) {
        if (event)
            event.stopPropagation();
        if (!this._validateVerifiedStatus(location))
            return;
        var url = this._router.serializeUrl(this._router.createUrlTree(['/account', accountId, 'location', location.locationId, 'insights']));
        window.open(url, '_blank');
    };
    LocationsComponent.prototype.goNewTabSelected = function () {
        var e_3, _a;
        try {
            for (var _b = __values(this.listCheckLocations), _c = _b.next(); !_c.done; _c = _b.next()) {
                var location_2 = _c.value;
                this.goNewTab(this.accountObjectId, location_2);
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_3) throw e_3.error; }
        }
    };
    LocationsComponent.prototype.getNextDueDate = function () {
        var _a;
        var data = '-';
        if ((_a = this.subscription) === null || _a === void 0 ? void 0 : _a.nextDueDate) {
            var nextDueDate = this._locationService.formatDates(this.subscription.nextDueDate);
            var nextDueDateArray = nextDueDate.split('-');
            data = nextDueDateArray[1] + "/" + nextDueDateArray[2] + "/" + nextDueDateArray[0];
        }
        return data;
    };
    LocationsComponent.prototype.cancelChangePlan = function (location) {
        var _this = this;
        this._modalService.openConfirmModal('Cancel Listing Plan Change', "This location is scheduled to change subscription plan to " + location.pendingChange.nextPlan + " " +
            ("on " + this.getNextDueDate() + ". ") +
            ("Do you want to cancel this scheduled change and keep the location as " + location.subscriptionType + "?"), function (res) {
            if (!res) {
                return;
            }
            var locationCancel = { locationId: location.locationId,
                accountId: _this.accountObjectId };
            _this._spinnerService.loading$.next(true);
            _this._apiSubscription.applyChangePlan([locationCancel], location.subscriptionType).then(function (res) {
                if (!res) {
                    return;
                }
                _this._getData(_this._paginate);
                _this._locationService.loadAll(_this.user, _this.accountObjectId);
            }).catch(function (err) {
                console.error(err);
                _this._spinnerService.loading$.next(false);
            });
        });
    };
    LocationsComponent.prototype.filterChanged = function (account) {
        this.listCheckLocations = [];
        this.allLocationCheck = true;
        this.selectAll();
        this.loadingTable$.next(true);
        this.accountObjectId = account[0].accountId;
        this._router.navigate(["accounts/" + this.accountObjectId + "/locations"]);
    };
    LocationsComponent.prototype.filterAccountsByAccountId = function () {
        var _this = this;
        var _a, _b, _c;
        var matchingAccount = (_a = this.accountsOptions) === null || _a === void 0 ? void 0 : _a.find(function (account) { var _a; return ((_a = account) === null || _a === void 0 ? void 0 : _a.accountId) === _this.accountObjectId; });
        this.selectedAccount = matchingAccount || null;
        this.labelAccountsFiltered = ((_c = (_b = matchingAccount) === null || _b === void 0 ? void 0 : _b.account) === null || _c === void 0 ? void 0 : _c.accountName) || null;
    };
    LocationsComponent.prototype._updateTable = function () {
        var _this = this;
        var _a;
        // data for table
        this.displayedColumns = ['id', 'company'];
        if (((_a = this.subscription) === null || _a === void 0 ? void 0 : _a.pricingVersion) < 3) {
            this.displayedColumns.push('subscription');
        }
        if (this.auth.isAdmin) {
            this.displayedColumns.push('actions');
        }
        // if (this.auth.isAdmin) {
        //   this.displayedColumns = ['id', 'company', 'subscription', 'actions'];
        // } else {
        //   this.displayedColumns = ['id', 'company', 'subscription'];
        // }
        this.dataSource = new MatTableDataSource(this.locations);
        this.dataSource.filterPredicate = function (data, filter) {
            var accumulator = function (currentTerm, key) {
                return _this._nestedFilterCheck(currentTerm, data, key);
            };
            var dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
            var transformedFilter = filter.trim().toLowerCase();
            return dataStr.indexOf(transformedFilter) !== -1;
        };
    };
    LocationsComponent.prototype._init = function () {
        var _this = this;
        this._wl.branding.then(function (res) {
            console.log('branding', res);
            ;
            _this.companyName = res['company_name'];
        });
        this.filtered = false;
        this.textSearch = '';
        var errorAccount = this._route.snapshot.queryParamMap.get("errorAccount");
        this.errorAccount = errorAccount ? errorAccount.toLowerCase() == "true" : false;
        if (this._route.snapshot.queryParamMap.get("errorAccount") !== null) {
            var url = this._router.url.split('?')[0];
            this._location.go(url);
        }
        this.accountObjectId = this._route.snapshot.paramMap.get('accountObjectId');
        this.locationsUser = ((this.user.accounts || []).length > 0 ?
            this.user.accounts.find(function (it) { return it.accountId === _this.accountObjectId; }) ?
                this.user.accounts.find(function (it) { return it.accountId === _this.accountObjectId; }).locations.map(function (it) { return it.locationId; }) : [] : []);
        // TODO: 5s forced delay, why? (single shot)
        if (!this._refreshTimerStarted) {
            this._refreshTimerStarted = true;
            this._untilDestroy(timer(5000)).subscribe(function () {
                try {
                    _this._getData(_this._paginate);
                    _this._locationService.loadAll(_this.user, _this.accountObjectId);
                    _this._updateTable();
                }
                finally {
                    _this._refreshTimerStarted = false;
                }
            });
        }
        if (!this._hasAccountsFilter)
            this.getAccountsFilter();
    };
    return LocationsComponent;
}());
export { LocationsComponent };
