// dep
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';

// app
import { AuthService } from './auth.service';
import { LocationService } from './location.service';
import { ModalService } from './modal.service';
import { SubscriptionService } from './subscription.service';
import { GROUP_SUBSCRIPTION_TYPE, LOCATION_SUBSCRIPTION_TYPE } from '../constants/firestore/account-location';
import User from '../constants/firestore/user';
import { ISubscription } from '../constants/subscription';
import { FeaturedComponent } from '../components/featured.component';
import SavedLocation from '../constants/firestore/saved-location';
import { LocationRef } from '../constants/firestore/location-object';

@Injectable({
  providedIn: 'root'
})
export class LocationFreeGuardService implements CanActivate {
  user: User;
  location: SavedLocation
  subscription$: Observable<ISubscription>;
  subscription: ISubscription;

  constructor(
    private auth: AuthService,
    private locationService: LocationService,
    private modalService: ModalService,
    private subscriptionService: SubscriptionService,
  ) {
    this.subscription$ = this.auth.subscription$;
    this.subscription$.subscribe(subscription => this.subscription = subscription);
  }

  // TODO: Search #TODO-refactor-featured-model to find code to refactor against this 
  // code block. 
  async canActivate(route: ActivatedRouteSnapshot) : Promise<boolean> {
    this.user = this.auth.session;
    const locationId = route.parent.params.locationId;
    const accountId  = route.parent.params.accountId;

    this.location = await this.locationService.fetchLocation(this.user.gid, accountId, locationId)

    const subscriptionType = this.location.subscriptionType

    if (this.subscription &&
        this.subscription.pricingVersion < 3 &&
        this.subscription.status !== GROUP_SUBSCRIPTION_TYPE.TRIAL && 
        (subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE || subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL)
    ) {
      this.modalService.openModal(FeaturedComponent, null, { onClose : async res => {
        if (!res) {
          return;
        }
        const location : LocationRef = { locationId: this.location.locationId, 
                                        accountId:  route.parent.params.accountId }
        console.log('LocationFreeGuardService upgrade suscription');
        if(await this.subscriptionService.flowChangeLocationsPlan(this.subscription, [location]))
          console.log("upgrade successful - location-guard")
      }})

      // TODO: returns false without awaiting the previous code, evaluate if that asynchronity is really needed
      return false;
    }
    return true;
  }

}

@Injectable({
  providedIn: 'root'
})
export class LocationUltimateTrialGuardService implements CanActivate {
  user: User;
  location: any;
  subscription$: Observable<ISubscription>;
  subscription: ISubscription;

  constructor(
    private auth: AuthService,
    private locationService: LocationService,
    private modalService: ModalService,
    private subscriptionService: SubscriptionService,
  ) {
    this.subscription$ = this.auth.subscription$;
    this.subscription$.subscribe(subscription => this.subscription = subscription);
  }

  // TODO: Search #TODO-refactor-featured-model to find code to refactor against this 
  // code block. 
  async canActivate(route: ActivatedRouteSnapshot) : Promise<boolean> {
    this.user = this.auth.session;
    const locationId = route.parent.params.locationId;
    const accountId  = route.parent.params.accountId;

    this.location = await this.locationService.fetchLocation(this.user.gid, accountId, locationId)

    const subscriptionType = this.location.subscriptionType

    if (this.subscription?.pricingVersion < 3 &&
        this.subscription?.status === GROUP_SUBSCRIPTION_TYPE.TRIAL && 
        (subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE || 
        subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL)
      ) {
      this.modalService.openModal(FeaturedComponent, null, { onClose : async res => {
        if (!res) {
          return;
        }
        console.log('LocationUltimateTrialGuardService upgrade suscription');
        const location : LocationRef = { locationId: this.location, 
                                          accountId: route.parent.params.accountId }

        if(await this.subscriptionService.flowChangeLocationsPlan(this.subscription, [location]))
          console.log("upgrade successful - location-guard")
      }})

      // TODO: returns false without awaiting the previous code, evaluate if that asynchronity is really needed
      return false;
    }
    return true;
  }

}
