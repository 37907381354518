<div class="pt--0 pb--0">
  <div class="box box--shadow box--rounded bg--white">
    <div class="box__header d-flex justify-content-between align-items-center alternate-theme">
      <h2 class="txt--capitalize"><strong>multi locations table</strong></h2>
      <button mat-flat-button color="primary" class="btn btn--xs">Reset Sort</button>
    </div>
    <div class="box__content">
      <div class="table-wrapper">

        <table class="table table--bordered table--overflow">
          <thead>
          <tr class="mat-row">
            <ng-container *ngFor="let metric of metrics">
              <th class="mat-header-cell txt--center" *ngIf="metric.id == 'location' else default" style="padding-left: 20px; width: 300px"><strong>{{ metric.name }}</strong></th>
              <ng-template #default>
                <th class="mat-header-cell txt--center"><strong>{{ metric.name }}</strong></th>
              </ng-template>
            </ng-container>
          </tr>
          </thead>
          <tbody>
          <tr class="mat-row no-hover border--full" *ngFor="let i of [0,1]">

            <td class="mat-cell cdk-column-location">
              <div class="cell__content flex-column align-items-start">
                <ngx-skeleton-loader count="1" [theme]="{ width: '80px', height: '20px', display: 'block' }"></ngx-skeleton-loader>
                <ngx-skeleton-loader count="1" [theme]="{ width: '150px', height: '20px', display: 'block', 'margin-top': '5px' }"></ngx-skeleton-loader>
                <ngx-skeleton-loader count="3" [theme]="{ width: '30px', height: '30px','margin':'5px 5px 0 0' }"></ngx-skeleton-loader>
              </div>
            </td>

            <td class="mat-cell" *ngFor="let i of [0,1,2,3,4,5,6,7,8,9,10,11,12,13]">
              <div class="cell__content justify-content-center">
                <ngx-skeleton-loader count="1" [theme]="{ width: '30px', height: '15px' }"></ngx-skeleton-loader>
              </div>
            </td>

          </tr>
          </tbody>
        </table>

      </div>
    </div>
  </div>
</div>

