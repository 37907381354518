import { __awaiter, __generator, __read, __spread, __values } from "tslib";
// dep
import { EventEmitter, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
// app
import { AuthService } from '../../services/auth.service';
import { LocationService } from '../../services/location.service';
import { MULTIKEY_METRIC, MULTIKEY_METRIC_V2, MULTIKEY_METRIC_V2_NEW } from "../../constants/google/location-stats";
var CheckMetricsComponent = /** @class */ (function () {
    function CheckMetricsComponent(locationS, authS) {
        this.locationS = locationS;
        this.authS = authS;
        this.metrics = MULTIKEY_METRIC;
        this.updatingData = true;
        this.hasSecondary = false;
        this.viewMode = 'legacy';
        this.accountsSelected = [];
        this.metricVerification = false;
        this.isEdit = false;
        this.isSliderOpened = false;
        this.changedMetricsValidation = new EventEmitter();
        this.selected = new EventEmitter();
        this.checkedAll = false;
    }
    CheckMetricsComponent.prototype.ngOnInit = function () {
        MULTIKEY_METRIC_V2_NEW.map(function (el) { return el.checked = false; });
        this.initMetrics();
        this.buildMetrics();
        this.checkMetrics(this.external);
    };
    CheckMetricsComponent.prototype.checkMetrics = function (external) {
        var _this = this;
        if (external) {
            external.forEach(function (element) {
                _this.metrics.forEach(function (m) {
                    if (element.name === m.name) {
                        m.checked = true;
                    }
                });
            });
        }
    };
    CheckMetricsComponent.prototype.initMetrics = function () {
        if (this.reportType === 'performance-comparison') {
            if (this.viewMode == 'new') {
                this.metrics = [];
                this.buildMetrics();
            }
            else {
                this.metrics = MULTIKEY_METRIC_V2;
            }
        }
    };
    CheckMetricsComponent.prototype.buildMetrics = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var metrics, menuActive, locationsRefs, _b, _c, acc, _d, _e, l, _f, _g, l, value, canMenu, e_1_1, _h;
            var e_2, _j, e_3, _k, e_1, _l;
            return __generator(this, function (_m) {
                switch (_m.label) {
                    case 0:
                        this.updatingData = true;
                        metrics = __spread(MULTIKEY_METRIC_V2_NEW);
                        menuActive = [];
                        locationsRefs = [];
                        try {
                            for (_b = __values(this.accountsSelected), _c = _b.next(); !_c.done; _c = _b.next()) {
                                acc = _c.value;
                                try {
                                    for (_d = (e_3 = void 0, __values(acc.locations)), _e = _d.next(); !_e.done; _e = _d.next()) {
                                        l = _e.value;
                                        locationsRefs.push({ accountId: acc.accountId, locationId: l.locationId });
                                    }
                                }
                                catch (e_3_1) { e_3 = { error: e_3_1 }; }
                                finally {
                                    try {
                                        if (_e && !_e.done && (_k = _d.return)) _k.call(_d);
                                    }
                                    finally { if (e_3) throw e_3.error; }
                                }
                            }
                        }
                        catch (e_2_1) { e_2 = { error: e_2_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_j = _b.return)) _j.call(_b);
                            }
                            finally { if (e_2) throw e_2.error; }
                        }
                        _m.label = 1;
                    case 1:
                        _m.trys.push([1, 10, , 11]);
                        _m.label = 2;
                    case 2:
                        _m.trys.push([2, 7, 8, 9]);
                        return [4 /*yield*/, this.locationS.fetchMultipleLocations(this.authS.session.gid, locationsRefs, ['location', 'locationState'])];
                    case 3:
                        _f = __values.apply(void 0, [_m.sent()]), _g = _f.next();
                        _m.label = 4;
                    case 4:
                        if (!!_g.done) return [3 /*break*/, 6];
                        l = _g.value;
                        value = l.locationState || l.location['locationState'];
                        canMenu = ((_a = value) === null || _a === void 0 ? void 0 : _a.canHaveFoodMenus) || false;
                        menuActive.push(canMenu);
                        _m.label = 5;
                    case 5:
                        _g = _f.next();
                        return [3 /*break*/, 4];
                    case 6: return [3 /*break*/, 9];
                    case 7:
                        e_1_1 = _m.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 9];
                    case 8:
                        try {
                            if (_g && !_g.done && (_l = _f.return)) _l.call(_f);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 9: return [3 /*break*/, 11];
                    case 10:
                        _h = _m.sent();
                        this.updatingData = false;
                        return [3 /*break*/, 11];
                    case 11:
                        if (menuActive.filter(function (el) { return el == false; }).length > 0) {
                            metrics.splice(9, 2);
                        }
                        this.metrics = metrics;
                        this.hasSecondary = !!this.metrics.find(function (el) { return el.isSecondary; });
                        this.updatingData = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    CheckMetricsComponent.prototype.ngOnChanges = function (changes) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var metricValues, changedLocations;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        metricValues = (_a = changes) === null || _a === void 0 ? void 0 : _a.external;
                        changedLocations = this.metricVerification || Object.keys(changes).includes('accountsSelected');
                        if (!changedLocations) return [3 /*break*/, 2];
                        this.metricVerification = false;
                        return [4 /*yield*/, this.buildMetrics()];
                    case 1:
                        _c.sent();
                        this.selectedEmit(this.metrics.filter(function (m) { return m.checked; }));
                        this.changedMetricsValidation.emit(this.metricVerification);
                        _c.label = 2;
                    case 2:
                        if (metricValues) {
                            this.external = metricValues.currentValue;
                            if (((_b = metricValues.previousValue) === null || _b === void 0 ? void 0 : _b.length) === 0) {
                                this.uncheckAll();
                            }
                            this.checkMetrics(this.external);
                        }
                        if (!this.isSliderOpened) {
                            this.changeAllChecked(false);
                            this.checkedAll = false;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    CheckMetricsComponent.prototype.uncheckAll = function () {
        this.metrics.forEach(function (m) {
            m.checked = false;
        });
    };
    CheckMetricsComponent.prototype.selectedEmit = function (value, isAll) {
        if (isAll === void 0) { isAll = false; }
        this.selected.emit(value.filter(function (m) { return m.checked; }));
        if (value.length && !isAll) {
            this.checkedAll = value.find(function (m) { return !m.checked; }) ? false : true;
        }
    };
    CheckMetricsComponent.prototype.changeChecked = function () {
        this.selectedEmit(this.metrics);
    };
    CheckMetricsComponent.prototype.externalChecked = function () {
        this.selectedEmit(this.external);
    };
    CheckMetricsComponent.prototype.changeAllChecked = function (checked) {
        this.metrics.forEach(function (m) { return m.checked = checked; });
        this.selectedEmit(this.metrics, true);
    };
    CheckMetricsComponent.prototype.ngOnDestroy = function () {
        this.metrics = [];
        this.metricVerification = false;
    };
    return CheckMetricsComponent;
}());
export { CheckMetricsComponent };
