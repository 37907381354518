<div class="box__item box__item--editable" [class.align-items-start]="description">
  <div class="d-flex w--100"
       [ngClass]="description ? 'align-items-start' : 'align-items-center'">
    <div class="icon icon--rounded icon--gray mr--20 d-block"><i class="fas fa-globe-americas"></i></div>

    <div class="d-block">
      <p class="w--100 m--0"><strong>Business Description</strong></p>
      <p class="w--100"><span [outerHTML]="description ? description : 'Add Description'"></span></p>
    </div>

  </div>
  <button mat-button (click)="openDialog()" class="btn btn-icon" *ngIf="isEdit">
    <i class="fas fa-pencil m--0"></i></button>
</div>
