import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import * as _ from 'lodash';

import { GoogleService } from '../../services/google.service';
import { SnackbarService } from '../../services/snackbar.service';
import { LocationService } from '../../services/location.service';
import { AuthService } from '../../services/auth.service';
import { LoadingService } from '../../services/loading.service';
import { WhiteLabelService } from 'src/app/services/white-label.service';
import { environment } from 'src/environments/environment';
import { GradeLoadedService } from 'src/app/services/grade-loaded.service';

@Component({
  selector: 'app-grade-metrics',
  templateUrl: './grade-metrics.component.html',
  styleUrls: ['./grade-metrics.component.scss']
})
export class GradeMetricsComponent implements OnInit {
  metrics: any = false;
  progress = true;
  hasError = false;
  @Input() gid: any;
  @Input() accountId: any;
  @Input() locationId: any;
  @Input() dataPicker: any;
  @Input() isReport: boolean;
  @Input() isExternal = false;
  @Output() finish = new EventEmitter();
  @Output() grade = new EventEmitter();
  locationType: string;

  constructor(
    private auth: AuthService,
    private googleS: GoogleService,
    private locationS: LocationService,
    private loadingService: LoadingService,
    public snackService: SnackbarService,
    private wl: WhiteLabelService,
    private gradeLoaded: GradeLoadedService
    ) {
  }

  ngOnInit() {
    this.getMetrics();
  }

  getMetrics() {
    this.progress = true;
    this.hasError = false;
    this.googleS.metrics(this.accountId, this.locationId, this.dataPicker).take(2).subscribe(async (result) => {
      const data = await this.wl.getDynamicWhiteLabel();
      environment.hideComparisonInGrader = data.hideComparisonInGrader;

      const metrics = _.omitBy(result, _.isNil);
      this.metrics = this.calculateGrade(metrics);
      this.progress = false;
      this.finish.emit(true);
      this.loadingService.reportAdvanced(1, "Metrics")
      this.gradeLoaded.loaded$.next(true);
    }, error => {
      this.hasError = true;
      this.progress = false;
      this.finish.emit(true);
      this.snackService.openError(error.error.message, 2000);
    });
  }

  calculateGrade(metrics) {
    const totals = Object.keys(metrics).reduce((total, prop) => {
      return total + metrics[prop].total;
    }, 0);

    const maxs = Object.keys(metrics).reduce((max, prop) => {
      const value = metrics[prop].max || 0;
      return max + value;
    }, 0);
    this.grade.emit({ maxs, totals });
    
    return metrics;
  }

}
