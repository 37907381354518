import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSlideToggleChange} from '@angular/material';
import { AlertType } from 'src/app/components/alert.component';
import { ModalService } from 'src/app/services/modal.service';
import {WeekDays, WEEK_DAYS} from '../../constants/google/week-days';
import {DatesService} from '../../services/dates.service';
import {LocationEditService} from '../../services/location-edit.service';
import {LocationService} from '../../services/location.service';

@Component({
  selector: 'app-modal-regular-hours',
  templateUrl: './modal-regular-hours.component.html',
  styleUrls: ['./modal-regular-hours.component.scss']
})
export class ModalRegularHoursComponent implements OnInit, OnDestroy {

  isChecked: boolean;
  checkedList: any;
  regularHoursList: any;
  hours: string;
  days: any;
  periods: WeekDays[];
  formattedPeriods: any;
  title = 'Set Regular Hours';
  save = true;
  clickApply = false;
  hasSpecialHours = false;
  hasMoreHours = false;
  hasErrorSpecialHours = false;
  openPeriods = []

  @Input() bulk = false;
  @Output() validResult: EventEmitter<boolean> = new EventEmitter();


  constructor(public dialogRef: MatDialogRef<ModalRegularHoursComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dateS: DatesService,
              private locationEditS: LocationEditService,
              private locationS: LocationService,
              private modalService: ModalService,
  ) {
    this.checkedList = [];
    if (data) {
      if (data.weekDays) {
        this.periods = JSON.parse(JSON.stringify(data.weekDays));
        if (this.periods.length === 0) {
          this.periods = this.locationS.verifyOpen(this.periods);
        }
      }else{
        this.periods = this.locationS.verifyOpen(this.periods);
      }
    } else this.periods = this.locationS.verifyOpen(this.periods);
    this.formatPeriodArray();
    this.openPeriods = JSON.parse(JSON.stringify(this.periods)).filter(el => el.open);
    this.regularHoursList = this.dateS.loadHoursDropdown();
  }

  ngOnInit() {
    this.title = this.data?.title ? this.data?.title : this.title;
    this.save = this.data?.save != null ? this.data?.save : this.save;
    this.hasSpecialHours = this.data?.hasSpecialHours != null ? this.data?.hasSpecialHours : false;
    this.hasMoreHours = this.data?.hasMoreHours != null ? this.data?.hasMoreHours : false;
  }

  apply(): void {
    this.clickApply = true;
    this.hasErrorSpecialHours = false;

    if(this.isValidForm()){
      const hasChanges = this.hasChanges(this.periods.filter(el => el.open));
      let periodsOpen = JSON.parse(JSON.stringify(this.periods));
      periodsOpen = periodsOpen.filter(period => period.open === true);
      periodsOpen.map(period => {
        period.openTime = this.getOpenTime(period);
        period.openTime = this.dateS.hours12To24(period?.openTime);
        period.closeTime = this.dateS.hours12To24(period?.closeTime);
        if (period?.open) delete period.open;
        return period;
      });
      if (this.save && hasChanges) {
        if (!this.hasSpecialHours && !this.hasMoreHours || periodsOpen.length > 0) {
          this.locationEditS.locationEdit.regularHours = {periods: periodsOpen};
          if(this.hasConflictingHours(this.periods)){
            this.modalService.openAlertModal(
              'Please verify your input',
              'The hours you\'ve entered overlap and conflict with one another. Please review your entry and try again.',
              AlertType.ERROR,
            )
            return;
          }

          if(this.hasOverlaps(this.periods)){
            this.modalService.openConfirmModal(
              'Are you sure?',
              'The hours you\'ve selected overlap from one day to the next. If this was intentional, click Confirm, otherwise click Cancel to review.',
              (result) => {
                if(result){
                  this.locationEditS.setAndUpdate().toPromise().then(() => {
                    this.dialogRef.close(this.periods);
                  });
                }
              }
            )
          } else {
            this.locationEditS.setAndUpdate().toPromise().then(() => {
              this.dialogRef.close(this.periods);
            });
          }
        } else {
          this.hasErrorSpecialHours = true;
        }
      } else {
        const data = hasChanges ? periodsOpen : null;
        this.dialogRef.close(data);
      }
    }
  }

  hasConflictingHours(periods: any) : boolean{
    const dummyDate = '1/1/2020'
    let result = false;
    let previousPeriod: any;
    if(periods && periods.length > 1){
      periods.forEach(period => {
        if(period &&
          previousPeriod &&
          period?.closeTime &&
          previousPeriod?.openTime &&
          previousPeriod?.closeTime &&
          period?.openTime !== previousPeriod?.closeTime &&
          new Date(dummyDate + ' ' + previousPeriod?.openTime).getTime() > new Date(dummyDate + ' ' +  previousPeriod?.closeTime).getTime() &&
          new Date(dummyDate + ' ' +  previousPeriod?.closeTime).getTime() > new Date(dummyDate + ' ' +  period?.openTime).getTime() &&
          this.areContiguousDays(period, previousPeriod)
        ){
          result = true;
        }
        
        previousPeriod = period;
      });
    }

    return result;
  }

  areContiguousDays(period, previousPeriod){
    if(period?.openDay === WEEK_DAYS[0] && previousPeriod?.openDay === WEEK_DAYS[6]) return true;
    if(period?.openDay === WEEK_DAYS[1] && previousPeriod?.openDay === WEEK_DAYS[0]) return true;
    if(period?.openDay === WEEK_DAYS[2] && previousPeriod?.openDay === WEEK_DAYS[1]) return true;
    if(period?.openDay === WEEK_DAYS[3] && previousPeriod?.openDay === WEEK_DAYS[2]) return true;
    if(period?.openDay === WEEK_DAYS[4] && previousPeriod?.openDay === WEEK_DAYS[3]) return true;
    if(period?.openDay === WEEK_DAYS[5] && previousPeriod?.openDay === WEEK_DAYS[4]) return true;
    if(period?.openDay === WEEK_DAYS[6] && previousPeriod?.openDay === WEEK_DAYS[5]) return true;

    return false;
  }

  hasOverlaps(periods: any) : boolean{
    let result = false;
    let previousPeriod: any;
    if(periods && periods.length > 1){
      periods.forEach(period => {
        if(period &&
          previousPeriod &&
          period?.closeTime &&
          previousPeriod?.openTime &&
          previousPeriod?.closeTime === period?.openTime
        ){
          result = true;
        }
        
        previousPeriod = period;
      });
    }

    return result;
  }

  hasChanges(periodsOpen) {
    return JSON.stringify(this.openPeriods) != JSON.stringify(periodsOpen);
  }

  getOpenTime(hour) {
    return hour.openTime == '12:00 AM' && hour.closeTime == '12:00 AM' ? '24:00 AM' : hour.openTime;
   }

  isValidForm() {
    const periodsOpen = JSON.parse(JSON.stringify(this.periods));
    let findError = periodsOpen.find(el => (el.openTime == '' || el.closeTime == '') && el.open);

    if(!findError) {
      findError = periodsOpen.map(el => this.areAcceptedHours(el)).find(f => f == true);
    }

    return findError ? false : true;
  }

  areAcceptedHours(hours) {
    let condition = false;

    if(hours.open) {
      condition = (
        hours.open && 
        (hours?.openTime == '' || hours?.closeTime == '' ||
        hours?.openTime == '12:00 AM' && hours?.closeTime == '12:00 AM' ||
        hours?.openTime != hours?.closeTime)
        ? false : true
      );
    }

    return condition;
  }

  ngOnDestroy(): void {
    this.data = {};
  }

  validate(e): void {
    this.validResult.emit(e !== '')
  }

  getResult(): { regularHours: any } {
    let periodsOpen = JSON.parse(JSON.stringify(this.periods));
    this.clickApply = true;

    if(this.isValidForm()){
      if (periodsOpen.length == 0) {
        this.validResult.emit(false);
        return
      }

      this.validResult.emit(true);

      periodsOpen = periodsOpen.filter(period => period.open === true);
      periodsOpen.map(period => {
        period.openTime = this.getOpenTime(period);
        period.openTime = this.dateS.hours12To24(period?.openTime);
        period.closeTime = this.dateS.hours12To24(period?.closeTime);
        if (period?.open) delete period.open;
        return period;
      });
      return {regularHours: {periods: periodsOpen} }
    }
  }

  addNewHour(period: WeekDays): void {
    const newPeriod: WeekDays = {...period};
    period.closeTime = '';
    period.openTime = '';
    const periodIndex = this.periods.findIndex((p) => p === period);
    this.periods.splice(periodIndex, 0, newPeriod);
    this.formatPeriodArray();
  }

  deleteHour(period: WeekDays): void {
    const periodIndex = this.periods.findIndex((p) => p === period);
    this.periods.splice(periodIndex, 1);
    this.formatPeriodArray();
  }

  formatPeriodArray(): void {
    this.formattedPeriods = this.locationS.sortPeriodsByDay(this.periods);
  }

  changeOpenState(event: MatSlideToggleChange, day) {
    for (const hours of day.value) {
      hours.open = event.checked;
    }
  }

  originalOrder = (a, b) => 0;
}
