/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./location-products.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./location-products.component";
var styles_LocationProductsComponent = [i0.styles];
var RenderType_LocationProductsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LocationProductsComponent, data: {} });
export { RenderType_LocationProductsComponent as RenderType_LocationProductsComponent };
function View_LocationProductsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "d-block txt--sm txt--lightgray"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = ((_v.parent.context.$implicit == null) ? null : ((_v.parent.context.$implicit.labels[0] == null) ? null : _v.parent.context.$implicit.labels[0].description)); _ck(_v, 1, 0, currVal_0); }); }
function View_LocationProductsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵppd(3, 2)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent, 0), ((_v.parent.context.$implicit == null) ? null : ((_v.parent.context.$implicit.price == null) ? null : _v.parent.context.$implicit.price.units)), ((_v.parent.context.$implicit == null) ? null : ((_v.parent.context.$implicit.price == null) ? null : _v.parent.context.$implicit.price.currencyCode)))); _ck(_v, 2, 0, currVal_0); }); }
function View_LocationProductsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent, 0), ((_v.parent.context.$implicit == null) ? null : ((_v.parent.context.$implicit.price == null) ? null : _v.parent.context.$implicit.price.units)))); _ck(_v, 1, 0, currVal_0); }); }
function View_LocationProductsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "container-fluid "]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "row"], ["style", "margin-top: 10px;margin-bottom: 10px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "col-8 mx-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LocationProductsComponent_5)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "col-4 mx-auto"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LocationProductsComponent_6)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noCurrencyCode", 2]], null, 0, null, View_LocationProductsComponent_7))], function (_ck, _v) { var currVal_1 = ((_v.context.$implicit == null) ? null : ((_v.context.$implicit.labels[0] == null) ? null : _v.context.$implicit.labels[0].description)); _ck(_v, 6, 0, currVal_1); var currVal_2 = ((_v.context.$implicit == null) ? null : ((_v.context.$implicit.price == null) ? null : _v.context.$implicit.price.currencyCode)); var currVal_3 = i1.ɵnov(_v, 10); _ck(_v, 9, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : ((_v.context.$implicit.labels[0] == null) ? null : _v.context.$implicit.labels[0].displayName)); _ck(_v, 4, 0, currVal_0); }); }
function View_LocationProductsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["- ", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LocationProductsComponent_4)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.items; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : ((_v.context.$implicit.labels[0] == null) ? null : _v.context.$implicit.labels[0].displayName)); _ck(_v, 4, 0, currVal_0); }); }
function View_LocationProductsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h5", [["class", "txt--uppercase"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "col-10 mx-auto"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LocationProductsComponent_3)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.sections; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.labels[0].displayName); _ck(_v, 4, 0, currVal_0); }); }
function View_LocationProductsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "w--100 row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LocationProductsComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.priceList; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_LocationProductsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.CurrencyPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "box__item box__item--editable"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "d-flex w--100"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "icon icon--rounded icon--gray mr--20"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "far fa-shopping-basket"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "w--100"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LocationProductsComponent_1)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "d-flex w--100"; var currVal_1 = "align-items-start"; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = _co.priceList; _ck(_v, 9, 0, currVal_2); }, null); }
export function View_LocationProductsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-location-products", [], null, null, null, View_LocationProductsComponent_0, RenderType_LocationProductsComponent)), i1.ɵdid(1, 114688, null, 0, i3.LocationProductsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LocationProductsComponentNgFactory = i1.ɵccf("app-location-products", i3.LocationProductsComponent, View_LocationProductsComponent_Host_0, { priceList: "priceList" }, {}, []);
export { LocationProductsComponentNgFactory as LocationProductsComponentNgFactory };
