import { __awaiter, __generator } from "tslib";
// dep
import { AfterViewChecked, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatSidenav } from '@angular/material';
// app
import { WhiteLabelService } from '../../services/white-label.service';
import { DataTransferService } from '../../services/data-transfer.service';
import { SpinnerService } from '../../services/spinner.service';
var DashboardLayoutComponent = /** @class */ (function () {
    function DashboardLayoutComponent(spinnerService, router, activatedRoute, titleService, wl, renderer2, dataTransfer) {
        var _this = this;
        this.spinnerService = spinnerService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.titleService = titleService;
        this.wl = wl;
        this.renderer2 = renderer2;
        this.dataTransfer = dataTransfer;
        this.destroySubs$ = new Subject();
        var appTitle = this.titleService.getTitle();
        router.events.pipe(takeUntil(this.destroySubs$))
            .filter(function (event) { return event instanceof NavigationEnd; })
            .map(function () {
            var child = _this.activatedRoute.firstChild;
            if (child.snapshot.data['title']) {
                return child.snapshot.data['title'];
            }
            return appTitle;
        }).subscribe(function (title) { return __awaiter(_this, void 0, void 0, function () {
            var url;
            var _this = this;
            return __generator(this, function (_a) {
                this.dataTransfer.getData().pipe(takeUntil(this.destroySubs$)).subscribe(function (locationName) { return __awaiter(_this, void 0, void 0, function () {
                    var company_name, title_pass;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.wl.getDynamicWhiteLabel()];
                            case 1:
                                company_name = (_a.sent()).branding.company_name;
                                title_pass = title + " | " + locationName + " | " + company_name;
                                this.titleService.setTitle(title_pass);
                                return [2 /*return*/];
                        }
                    });
                }); });
                url = this.router.url;
                this.isFullWidth = (url.includes('insights') || url.includes('reviews'));
                this.updateClass();
                return [2 /*return*/];
            });
        }); });
        this.opened = true;
    }
    DashboardLayoutComponent.prototype.toggleSideNav = function () {
        this.sidenav.toggle();
        var elementId = document.getElementById('dashboard-filter');
        if (elementId) {
            if (!this.sidenav.opened) {
                this.renderer2.addClass(elementId, 'remove-padding');
            }
            else {
                this.renderer2.removeClass(elementId, 'remove-padding');
            }
        }
    };
    DashboardLayoutComponent.prototype.ngOnDestroy = function () {
        this.destroySubs$.next(true);
        this.destroySubs$.unsubscribe();
    };
    DashboardLayoutComponent.prototype.ngOnInit = function () {
        var url = this.router.url;
        this.isFullWidth = (url.includes('insights') || url.includes('reviews'));
        this.updateClass();
    };
    DashboardLayoutComponent.prototype.ngAfterViewChecked = function () {
        this.checkForTrialMessage();
    };
    DashboardLayoutComponent.prototype.updateClass = function () {
        this.contentClass = {
            'content content--padding': !this.isFullWidth,
            'dashboard-content': this.isFullWidth
        };
    };
    DashboardLayoutComponent.prototype.checkForTrialMessage = function () {
        var trialMessageLength = document.querySelectorAll('.js-trial-message').length;
        if (trialMessageLength) {
            document.querySelector('body').classList.add('trial-message-wrapper');
        }
        else {
            document.querySelector('body').classList.remove('trial-message-wrapper');
        }
    };
    return DashboardLayoutComponent;
}());
export { DashboardLayoutComponent };
