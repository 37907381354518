import { IMediaUrl } from 'src/app/constants/media-url';
import { Injectable } from '@angular/core';
import { AngularFireStorage, AngularFireStorageReference } from '@angular/fire/storage';
import { MatSnackBar } from '@angular/material/snack-bar';

import { BehaviorSubject, Observable, Observer, forkJoin, Subject } from 'rxjs';
import * as _ from 'lodash';

import { Messages, string_message } from '../constants/messages';
import { finalize, map, filter, take } from 'rxjs/operators';
import { PostService } from './post.service';
import { SnackbarService } from './snackbar.service';

export type TypeImages = 'PHOTO' | 'VIDEO';

export interface IMediaFile {
  file: any;
  dir: string;
  type: TypeImages;
  preview: string | null
}

export interface IUrl {
  url?: string;
  preview?: string;
  type?: TypeImages;
  fileName?: string;
  error?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class StorageImageService {
  public fileRef: AngularFireStorageReference;
  public isWebpImage: boolean;
  
  private _url: BehaviorSubject<IUrl> = new BehaviorSubject(null);
  private _ref = new BehaviorSubject<AngularFireStorageReference>(null);
  private _percent = new BehaviorSubject<number>(0);
  private _singleFile = true;
  private _imageUrl$ = new BehaviorSubject(null);
  private _multipleMediaUrl: IMediaUrl[] = [];
  private _multipleMediaUrl$ = new BehaviorSubject<IMediaUrl[]>(null);
  private _errorsArray: string[] = [];

  private _fileDeletedSource$ = new Subject<void>();
  public fileDeleted$ = this._fileDeletedSource$.asObservable();

  constructor(
    private _afstorage: AngularFireStorage,
    private _snackService: SnackbarService,
    private _snackBar: MatSnackBar,
    private _postS: PostService,
  ) {}

  get url$(): Observable<IUrl> {
    return this._url.asObservable();
  }

  get url(): IUrl {
    return this._url.getValue();
  }

  get ref$(): Observable<AngularFireStorageReference> {
    return this._ref.asObservable();
  }

  get percent$(): Observable<number> {
    return this._percent.asObservable();
  }
  get multipleMediaUrl$(): Observable<IMediaUrl[]> { // not working as intended
    return this._multipleMediaUrl$.asObservable();
  }

  setMultipleMediaUrl(newArray): void {
    this._multipleMediaUrl = newArray;
    this._multipleMediaUrl$.next(newArray);
  }

  setUrl(value): void {
    this._url.next(value);
  }
  
  getImageUrl(): Observable<any> {
    return this._imageUrl$.asObservable();
  }
  
  setImageUrl(value): void {
    this._imageUrl$.next(value);
  }

  async filesValidation(files: FileList, requirements): Promise<any[]> {
    const validatedFiles = [];
    
    if (!files[0]) {
      if (this._singleFile) {
        this._url.next(null);
      } else {
        this._multipleMediaUrl = [];
        this._multipleMediaUrl$.next(this._multipleMediaUrl);
      }
      return validatedFiles; // Return an empty array if no files
    }
    
    for (let i = 0; i < files.length; i++) {
      const type = files[i].type === 'video/mp4' ? 'VIDEO' : 'PHOTO';

      if (this._validateTypeSize(requirements, files[i])) {
        if (this._singleFile) {
          this._url.next({error: true});
        } 
      } else {
        if (type === 'PHOTO' ) {
          const img = new Image();
          img.src = window.URL.createObjectURL(files[i]);
          
          // Wrap img.onload in a Promise
          const imgOnLoad = new Promise((resolve, reject) => {
            img.onload = () => {
              if (this._validateFormat(requirements, img, files[i])) {
                if (this._singleFile) {
                  this._url.next({error: true});
                } 
                reject();
              } else if (this._validateType(type, files[i].name)) {
                if (this._singleFile) {
                  this._url.next({error: true});
                } 
                reject();
              } else {
                validatedFiles.push({file: files[i], preview: null});
                resolve(true);
              }
            };
          });
          
          // Wait for img.onload to complete
          try {
            await imgOnLoad;
          } catch (error) {
            // Handle any errors
            console.error('Error occurred during photo validation:', error);
          }
          
        } else {
          try {
            const r = await this._getFileBase64(files[i]);
            if (this._validateType(type, files[i].name)) {
              if (this._singleFile) {
                this._url.next({error: true});
              } 
            } else {
              let blob = new Blob([r], {type: files[i].type});
              let url = URL.createObjectURL(blob);
              let video = document.createElement('video') as any;
              let timeupdate = () => {
                if (snapImage()) {
                  video.removeEventListener('timeupdate', timeupdate);
                  video.pause();
                }
              };
              video.addEventListener('loadeddata', () => {
                if (snapImage()) {
                  video.removeEventListener('timeupdate', timeupdate);
                }
              });
              const snapImage = () => {
                let canvas = document.createElement('canvas');
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
                let dataURI = canvas.toDataURL();
                let success = dataURI.length > 100000;
                if (success) {
                  validatedFiles.push({file: files[i], preview: dataURI});
                  URL.revokeObjectURL(url);
                }
                return success;
              };
              video.addEventListener('timeupdate', timeupdate);
              video.preload = 'metadata';
              video.src = url;
              video.muted = true;
              video.playsInline = true;
              video.play();
            }
          } catch (error) {
            console.error('Error occurred during video validation:', error);
          }
        }
      }

    }
    return validatedFiles;
  }

  fileChanged(event: HTMLInputElement, singleFile: boolean, requirements?, placeId?: string, maxNumberPhotos?) {
    if (!placeId) {
      placeId = 'media';
    }
    
    if(!singleFile && maxNumberPhotos === 1) {
      this.setMultipleMediaUrl([]);
    }

    this._errorsArray = []; // reset errors
    this._singleFile = singleFile; // set the flag for single file
    
    const files = event.files;
    const formattedFilesArray: IMediaFile[] = [];

    // Call filesValidation asynchronously and handle the result
    this.filesValidation(files, requirements)
    .then((validatedFiles) => {
      this._showErrors();
      for (let i = 0; i < validatedFiles.length; i++) {
        const file = validatedFiles[i].file;
        const type = file.type;
        const preview = validatedFiles[i].preview;
        const dir = `public/${placeId}/${+new Date()}-${file.name}`;

        formattedFilesArray.push({file: file, dir: dir, type: type, preview: preview});
      }
      // finished processing all files
      return formattedFilesArray;
    })
    .then((files) => {
      this._uploadFile(files);
    })
    .catch((error) => {
      this._showErrors();
      console.error('Error occurred during file validation:', error);
    });
  }

  removeFileFromArray(files: IMediaUrl[], file: IMediaUrl): void {
    // gets a file and an array, emits the new array without the file
    const newArray = _.filter(files, (f) => {
      return f !== file;
    })
    this._multipleMediaUrl = newArray;
    this._multipleMediaUrl$.next(newArray);
  }

  notifyFileDeleted() {
    this._fileDeletedSource$.next();
  }

  reset(): void {
    this._url.next(null)
    this._multipleMediaUrl = [];
    this._multipleMediaUrl$.next([]);
  }

  private _uploadWebpFile(file, dir, url): void {
    this._postS.transformImage(url)
      .subscribe(res => {
        const name = _.head(_.split(file.name, '.'));
        const base64Image = 'data:image/jpg;base64,' + res.data;
        const newFile  = this._dataURLtoFile(base64Image, name);
        const placeId = dir.split('/')[1];
        const newDir = `public/${placeId}/${newFile.name}`;
        this.isWebpImage = false;
        const fileInArray: IMediaFile[] = [];
        fileInArray.push({file: newFile, dir: newDir, type: 'PHOTO', preview: null});
        this._uploadFile(fileInArray);
      });
  }

  private _uploadFile(files: IMediaFile[]): void { // review this method as it appears to be emitting the URL after the upload
    const observables: Observable<any>[] = [];

    files.forEach(element => {
      observables.push(
        new Observable(observer => {

          const fileName = element?.file?.name;
          const file = element?.file;
          const dir = element?.dir;
          const type = element?.type;
          const preview = element?.preview;

          this._afstorage.upload(dir, file).snapshotChanges().pipe(
            map(actions => {
              actions.task.catch((error) => {
                if (this._singleFile) {
                  this._url.next(null) // single file error
                } else {
                  this._multipleMediaUrl.push({url: '', category: type, error: true})
                }
                const msg = `An error occurred uploading the file ${fileName ? (' ' + fileName + ',') : ''} please try again later.`;
                this._errorsArray.push(msg);
                observer.error(error);
              })
            }),
            finalize(() => {
              this.fileRef = this._afstorage.ref(dir);
              this._ref.next(this.fileRef);
              this.fileRef.getDownloadURL().subscribe(url => {
                const element = {
                  url,
                  preview,
                  type,
                  fileName,
                  category: type
                }
                if(type === 'PHOTO') {
                  this.isWebpImage = this._verifyImageWebp(file.name);
                  if (this._singleFile) { 
                    !this.isWebpImage ? this._url.next(element) : this._uploadWebpFile(file, dir, url);
                  } else {
                    !this.isWebpImage ? this._multipleMediaUrl.push(element) : this._uploadWebpFile(file, dir, url);
                  }
                } else {
                  if (this._singleFile) {
                    this._url.next(element) // single file success
                  } else {
                    this._multipleMediaUrl.push(element) // multiple file success
                  }
                }
                this._multipleMediaUrl$.next([...this._multipleMediaUrl]);
              });
              observer.complete();
              
            })
          ).subscribe();
        })
      )
    });

    forkJoin(observables).subscribe();
  }


  private _verifyVideoFile(fileName: string): boolean {
    return (fileName.includes('.mp4') || fileName.includes('.MP4')
      || fileName.includes('.avi') || fileName.includes('.AVI'));
  }

  private _verifyImageFile(fileName: string): boolean {
    return (fileName.includes('.png') || fileName.includes('.PNG')
      || fileName.includes('.jpg') || fileName.includes('.JPG')
      || fileName.includes('.jpeg') || fileName.includes('.JPEG')
      || fileName.includes('.webp') || fileName.includes('.WEBP')
      || fileName.includes('.ico') || fileName.includes('.ICO'));
  }

  private _verifyImageWebp(fileName: string): boolean {
    return (fileName.includes('.webp') || fileName.includes('.WEBP'));
  }

  private async _getFileBase64(file: File): Promise<string | ArrayBuffer> {
    const reader: FileReader = new FileReader();

    return new Promise( (resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.readAsArrayBuffer(file);
    });
  }

  private _dataURLtoFile(dataurl, filename): File {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const type = _.last(mime.split('/'));
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], `${filename}.${type}`, {type: mime});
  }

  private _validateTypeSize(requirements, file: File): boolean {
    if (requirements && _.isArray(requirements?.type) && !_.includes(requirements?.type, file.type)) {
      const types = requirements.type.map(t => _.last(_.split(_.kebabCase(t), '-')));
      const msg = `${file.name}: ${Messages.upload.BAD_TYPES} ${_.toString(types)}`;
      this._errorsArray.push(msg);
      // this.snackService.openWarning(msg, 5000); // modify to work with multiple files
      return true;
    } else if (requirements && !_.isArray(requirements?.type) && file.type !== requirements?.type) {
      const msg = `${file.name}: ${Messages.upload.BAD_TYPE}`;
      this._errorsArray.push(msg);
      // this.snackService.openWarning(msg, 4000); // modify to work with multiple files
      return true;
    }

    if (requirements && requirements.min_size && file.size < requirements.min_size) {
      const msg = `${file.name}: ${string_message(
        Messages.upload.BAD_SIZE_CUSTOM,
        [ requirements.min_size ]
      )}`;
      this._errorsArray.push(msg);
      // this.snackService.openWarning(msg, 4000); // modify to work with multiple files
      return true;
    }

    if (requirements && requirements.max_size && file.size > requirements.max_size) {
      const msg = `${file.name}: ${string_message(
        Messages.upload.BAD_MAX_SIZE_CUSTOM,
        [ requirements.max_size ]
      )}`;
      this._errorsArray.push(msg);
      // this.snackService.openWarning(msg, 5000); // modify to work with multiple files
      return true;
    }

    return false
  }

  private _validateFormat(requirements, img: HTMLImageElement, file: File): boolean {
    const ratio = img.width / img.height;

    if (requirements && requirements?.min_height && requirements?.min_width && file.type !== 'video/mp4') {
      if (img.height < requirements.min_height || img.width < requirements.min_width) {
        const msg = `${file.name}: ${string_message(
          Messages.upload.BAD_DIMENSION_MINIMUM_CUSTOM,
          [ requirements.min_width, requirements.min_height]
        )}`;
        this._errorsArray.push(msg);
        // this.snackService.openWarning(msg, 4000); // modify to work with multiple files
        return true;
      }
    }

    if (requirements && requirements?.height && requirements?.width && file.type !== 'video/mp4') {
      if (img.height > requirements.height || img.width > requirements.width) {
        const msg = `${file.name}: ${string_message(
          Messages.upload.BAD_DIMENSION_MINIMUM_CUSTOM, // <-- might be the wrong constant, we need to check
          [ requirements.width, requirements.height]
        )}`;
        this._errorsArray.push(msg); 
        // this.snackService.openWarning(msg, 4000); // modify to work with multiple files
        return true;
      }
    } else if (img.height > 1192 || img.width > 2120) {
      const msg = `${file.name}: ${Messages.upload.BAD_DIMENSION}`;
      this._errorsArray.push(msg);
      // this.snackService.openWarning(Messages.upload.BAD_DIMENSION,  4000); // modify to work with multiple files
      return true;
    }
    else if (requirements && requirements?.minRatio && ratio < requirements?.minRatio) {
      const msg = `${file.name}: Image ratio must be ${requirements.minRatio == 1.3 ? '4:3' : '16:9'}. Please edit and retry or upload a new image.`
      this._errorsArray.push(msg);
      // this.snackService.openWarning(msg,  4000); // modify to work with multiple files
      return true;
    }
    return false;
  }

  private _validateType(type: string, name: string): boolean {
    if (type === 'VIDEO') {
      // its a video
      if (!this._verifyVideoFile(name)) {
        const msg = `${name}: Incorrect format for a video.`;
        this._errorsArray.push(msg);
        // this.snackService.openWarning(msg, 4000); // modify to work with multiple files
        return true;
      }
    } else {
      // its a photo
      if (!this._verifyImageFile(name)) {
        const msg = `${name}: Incorrect format for an image.`;
        this._errorsArray.push(msg);
        // this.snackService.openWarning(msg, 4000); // modify to work with multiple files
        return true;
      }
    }
    return false;
  }

  private _showErrors(): void {
    const combinedErrors = this._errorsArray.join('\n');
    if (combinedErrors) {
      this._snackService.openWarning(combinedErrors, 6000);
    }
  }

}
