import {ACCOUNTS, GROUPS, LOCATIONS, WIDGET_INFO} from '../../constants/firestore/collections';
import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {WhiteLabelService} from '../../services/white-label.service';
import {AuthService} from '../../services/auth.service';
import {LocationService} from '../../services/location.service';
import {ActivatedRoute} from '@angular/router';
import {GoogleService} from '../../services/google.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {AngularFirestore} from '@angular/fire/firestore';
import {SnackbarService} from '../../services/snackbar.service';

@Component({
  selector: 'app-dashboard-widget',
  templateUrl: './dashboard-widget.component.html',
  styleUrls: ['./dashboard-widget.component.scss']
})
export class DashboardWidgetComponent implements OnInit, OnDestroy {

  accountId;
  location = null;
  frameSource;
  locationId: any;
  reviews: any[];
  url: string;
  color: any;
  backgroundColor: any;
  buttonColor: any;
  textColor: any;
  buttonTextColor: any;
  form: FormGroup;
  selectedStarts: number[] = [];
  average: number;
  eventsSubject: Subject<void> = new Subject<void>();
  subWidget;
  masterReset = 0;
  locations: { accountId: any; locationId: any }[];

  constructor(
    private wl: WhiteLabelService,
    private auth: AuthService,
    private locationS: LocationService,
    private route: ActivatedRoute,
    private googleS: GoogleService,
    private fb: FormBuilder,
    private snack: SnackbarService,
    private afs: AngularFirestore,
    private cdr: ChangeDetectorRef,
  ) {
    this.locationId = this.route.snapshot.parent.params.locationId;
    this.accountId = this.route.parent.snapshot.params.accountId;
    this.initForm();
  }

  ngOnInit() {
    this.googleS.dynamicUrl(this.locationId).subscribe(url => {
      this.url = url.shortLink;
    });
    this.cdr.detectChanges();
  }

  get generateFrameSource() {
    const uid = this.auth.session.uid;
    let domain = this.wl.domain;
    const order = this.order.value;

    if (domain === 'localhost') {
      domain = domain + ':4200';
    }

    let source = `https://${domain}/widget/${this.auth.session.gid}/${this.accountId}/${this.locationId}/review?&uid=${uid}`;

    if (order) {
      source += `&order=${order}`;
    }

    if (this.backgroundColor) {
      source += `&back_color=${this.backgroundColor.replace('#', '')}`;
    }

    if (this.buttonColor) {
      source += `&btn_color=${this.buttonColor.replace('#', '')}`;
    }

    if (this.buttonTextColor) {
      source += `&btn_txt_color=${this.buttonTextColor.replace('#', '')}`;
    }

    if (this.textColor) {
      source += `&text_color=${this.textColor.replace('#', '')}`;
    }

    if (this.selectedStarts) {
      source += `&filter=${this.joinStars}`;
    }

    let newWidth;
    if (this.width) {
      newWidth = this.width.value + this.measure.value;
    }
    return `<iframe width="${newWidth}" height="100%" id="gmb-container-1" src="${source}" ></iframe>`;
  }

  get joinStars() {
    if (this.selectedStarts) {
      return this.selectedStarts.join();
    }
  }


  /**
   * handle method submit form and generate source url by iframe html
   */
  submit() {
    if (this.width.invalid) {
      return;
    }
    this.frameSource = this.generateFrameSource;
    this.eventsSubject.next();
  }

  getDataWidget() {
    const data: any = {};
    const order = this.order.value;
    if (order) {
      data.order = order;
    }

    data.responses = this.responses.value;

    if (this.backgroundColor) {
      data.backgroundColor = this.backgroundColor;
    }

    if (this.buttonColor) {
      data.buttonColor = this.buttonColor;
    }

    if (this.buttonTextColor) {
      data.buttonTextColor = this.buttonTextColor;
    }

    if (this.textColor) {
      data.textColor = this.textColor;
    }

    if (this.selectedStarts) {
      data.selectedStarts = this.selectedStarts;
    }
    if (this.width) {
      data.measure = this.measure.value;
      data.width = this.width.value;
    }
    return data;
  }


  /**
   * manage change selected  starts
   */
  handleStarts($event, num: number) {
    if (!this.selectedStarts) {
      this.selectedStarts = [];
    }

    if ($event.checked === true) {
      this.selectedStarts.push(num);
    }

    if ($event.checked === false) {
      const index = this.selectedStarts.findIndex(value => value === num);
      this.selectedStarts.splice(index, 1);
    }

    if (this.selectedStarts.length > 0) {
      this.selectedStarts.sort();
    }
  }

  selectedBackground($event) {
    this.backgroundColor = $event;
  }

  selectedButton($event: any) {
    this.buttonColor = $event;
  }

  selectedButtonText($event: any) {
    this.buttonTextColor = $event;
  }

  selectedText($event: any) {
    this.textColor = $event;
  }

  /**
   * this method set backgroundColor to undefined
   */
  clearedBackground() {
    this.backgroundColor = undefined;
  }


  /**
   * this method set buttonColor to undefined
   */
  clearedButtonColor() {
    this.buttonColor = undefined;
  }


  /**
   * this method set buttonTextColor to undefined
   */
  clearedButtonText() {
    this.buttonTextColor = undefined;
  }


  /**
   * this method clear text color
   */
  clearedText() {
    this.textColor = undefined;
  }


  // form initialize and getters
  initForm() {
    this.subWidget = this.afs.collection(GROUPS).doc(this.auth.session.gid).collection(ACCOUNTS).doc(this.accountId)
      .collection(LOCATIONS).doc(this.locationId).collection(WIDGET_INFO).doc(this.locationId)
      .valueChanges().subscribe((res: any) => {
        if (res) {
          this.selectedStarts = [...res.selectedStarts];
          this.backgroundColor = res.backgroundColor;
          this.textColor = res.textColor;
          this.buttonTextColor = res.buttonTextColor;
          this.buttonColor = res.buttonColor;
          this.form = this.fb.group({
            width: [res.width],
            measure: [res.measure],
            order: [res.order],
            responses: [res.responses],
          });
          this.submit();
        } else {
          this.form = this.fb.group({
            width: ['100'],
            measure: ['%'],
            order: ['createTime'],
            responses: [true],
          });
          this.submit();
        }
        this.frameSource = this.generateFrameSource;
      });
  }

  get width() {
    return this.form?.get('width');
  }

  get order() {
    return this.form?.get('order');
  }

  get measure() {
    return this.form?.get('measure');
  }

  get responses() {
    return this.form?.get('responses');
  }

  /**
   * this method copies the iframe content to clipboard
   */

  copyIframe(content) {
    content.select();
    document.execCommand('copy');
    content.setSelectionRange(0, 0);
    this.snack.openSuccess('The widget code has been copied to your clipboard!', 2000);
  }

  /**
   * this method reset the filters
   */
  resetOptions() {

    this.masterReset += 1;
    this.selectedStarts = [];
    this.clearedBackground();
    this.clearedButtonColor();
    this.clearedButtonText();
    this.clearedText();
    this.initForm();

  }

  save() {
    this.locationS.deleteWidget(this.auth.session.gid, this.accountId, this.locationId).then();
    this.locationS.saveWidget(this.auth.session.gid, this.accountId, this.locationId, this.getDataWidget()).then(res => {
      this.snack.openSuccess('The widget has been saved', 3000);
    }, err => {
      console.error(err);
      this.snack.openError('There was an error saving the widget', 3000);
    });
  }


  ngOnDestroy(): void {
    if (this.subWidget) {
      this.subWidget.unsubscribe();
    }
  }
}
