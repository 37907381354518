// dep
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';

// app
import { DataPicker } from '../../constants/data-picker';
import { DatesService } from '../../services/dates.service';
import User from '../../constants/firestore/user';
import { ModalService } from '../../services/modal.service';
import { environment } from '../../../environments/environment';
import { LoadComponent } from '../../components/load.component';

@Component({
  selector: 'app-dashboard-grade',
  templateUrl: './dashboard-grade.component.html',
  styleUrls:  ['./dashboard-grade.component.scss'],
})
export class DashboardGradeComponent implements OnInit {
  @Input('dataPicker') dataPickerReport: DataPicker;
  @Input('gradeReport') gradeReport;
  @Input() user: User;
  @Input('locations') locations: any[];
  @Input() externalAccountId: string;
  @Input() externalLocationId: string;
  @Input() userExternal: any = null;
  @Input() lockDates: boolean;
  @Input() isShared = false;
  dataPicker: DataPicker;
  location;
  labels: any;
  isReport = false;
  public content: any; // Not used but build fails?

  locationsEmpty: boolean;
  metricsSuccess: boolean;
  comparisonSuccess: any;
  headerSuccess: boolean;
  show = false;
  noData: boolean;
  locationId: any;
  accountId: any;
  gid: string;
  overallGrade: number;
  isExternal = false;

  @ViewChild("content",{static:true}) modalContent: ModalService;

  constructor(
    private route: ActivatedRoute,
    private dateS: DatesService,
    private modalService: ModalService

  ) {
    this.accountId  = this.route.parent.snapshot.params.accountId;
    this.locationId = this.route.parent.snapshot.params.locationId;
    this.locations = [{ accountId: this.accountId, 
                       locationId: this.locationId }]
  }

  ngOnInit() {
    this.modalService.openModal(LoadComponent, 
                                { title  : 'The Grader is loading', 
                                  content: 'Please wait a moment while we scan your business listing'},
                                {  autoCloseMS: 12000 })

    if (this.userExternal) {
      this.gid = this.userExternal.gid;
    }
    else if (this.user) {
      this.gid = this.user.gid;
    }

    if (this.externalAccountId && this.externalLocationId) {
      this.accountId = this.externalAccountId;
      this.locationId = this.externalLocationId;
      this.isExternal = true;
    }

    if (this.dataPickerReport) {

      this.dataPicker = this.dataPickerReport;

      if (this.gradeReport) {
        this.isReport = false;
        this.accountId = this.dataPickerReport.locations[0].accountId;
        this.locationId = this.dataPickerReport.locations[0].locationId;
        this.locations = this.dataPickerReport.locations;
      } else {
        this.isReport = true;
      }
      this.locationsEmpty = true;
      if (!this.locations) {
        this.locations = this.dataPickerReport.locations;
      }
    } else if (this.locations) {
      this.getDataPicker();
    } else {
      this.getDataPicker();
    }
  }

  getDataPicker() {
    const lastMonth = this.dateS.dateLessMonth(new Date(Date.now()), 1);
    const start = new Date(lastMonth);

    start.setDate(1);
    const end = new Date(lastMonth);
    end.setDate(this.dateS.lastDayOfMonthDate(end));

    this.dataPicker = {
      range: {
        start: moment(start).format('YYYY-MM-DD'),
        end:   moment(end).format('YYYY-MM-DD')
      },
      locations: this.locations,
      aggregation: 'week'
    };
  }

  handleHeaderSuccess($event: any) {
    this.headerSuccess = $event;
    this.show = this.validateIfShow;

  }

  handleMetricsSuccess($event: any) {
    this.metricsSuccess = $event;
    this.show = this.validateIfShow;
  }


  handleComparisonSuccess($event: any) {
    this.comparisonSuccess = $event;
    this.show = this.validateIfShow;
  }

  get validateIfShow() {
    if (this.metricsSuccess === false && this.comparisonSuccess === false && !this.isReport) {
      this.noData = true;
      return false;
    } else if (this.headerSuccess && this.metricsSuccess && this.comparisonSuccess && !this.isReport) {
      this.noData = false;
      return true;
    } else if (this.metricsSuccess && this.comparisonSuccess && this.isReport) {
      this.noData = false;
      return true;
    } else if (this.metricsSuccess && this.comparisonSuccess && !this.isReport) {
      this.noData = false;
      return true;
    }
  }


  get validateIfprogress() {
    if (!this.isReport) {
      return !(this.headerSuccess !== undefined && this.metricsSuccess !== undefined && this.comparisonSuccess !== undefined);
    } else {
      return !(this.metricsSuccess !== undefined && this.comparisonSuccess !== undefined);
    }
  }

  qualification(event) {
    const total = (environment.hideComparisonInGrader) ? event.totals : (event.totals + this.comparisonSuccess.data.points);
    const max   = (environment.hideComparisonInGrader) ? event.maxs   : (event.maxs   + this.comparisonSuccess.data.max);
    const totalPercent = ((total / max) * 100).toFixed(0);
    this.overallGrade = parseFloat(totalPercent);
  }
}
