import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { AccountService } from '../../services/account.service';
import { AuthService } from '../../services/auth.service';
import { GoogleService } from '../../services/google.service';
import { GoogleAccountService } from '../../services/google-account.service';
import GoogleAccount from '../../constants/firestore/google-account';
// import { GoogleLocationService } from '../../services/google-location.service';
import { LocationService } from '../../services/location.service';
import SavedAccount from '../../constants/firestore/saved-account';
import { SnackbarService } from '../../services/snackbar.service';
import { ObservationService } from '../../services/observation.service';
import { Pageable } from '../../constants/pageable';
import { Pagination } from '../../constants/api-response';
import { WhiteLabelService } from "../../services/white-label.service";
import { ISubscription } from '../../constants/subscription';
import { NotificationService } from '../../services/notification.service';
import { DELETE_DATA, NOTIFICATION_GENERAL, TYPE_LOG_LOCATION } from '../../constants/notifications';
import { Messages, string_message } from '../../constants/messages';
import { SpinnerService } from '../../services/spinner.service';
import { ModalService } from '../../services/modal.service';
import { DialogLocationsComponent } from './dialog-locations.component';
import { AlertType } from '../../components/alert.component';
import { GroupService } from '../../services/group.service';
import Group from '../../constants/firestore/group';
import { UserService } from '../../services/user.service';


@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnDestroy, OnInit {
  subscription$: Observable<ISubscription> = this.auth.subscription$;
  subscription: ISubscription;
  search: any;
  user;
  accounts: Observable<SavedAccount[]> = null;
  googleAccounts: Observable<any[]>;
  displayedColumns: string[] = ['name', 'locations', 'actions'];
  dl;
  da;
  subsManager$ = new Subject<boolean>();
  loading_refresh_account$ = new BehaviorSubject(false);
  size = 25;
  page = 1;

  pagination: Pagination = {
    items: [],
    per_page: this.size,
    page: this.page,
    hasNext: false,
    hasPrev: false,
    pages: 0,
    total: 0
  };
  public accountVerified = false;
  private _paginate: Pageable = { size: this.size, page: this.page };
  private _previousPageable: Pageable;
  private _group: Group
  private _userFeatures: Promise<any>

  constructor(
    public router: Router,
    public accountService: AccountService,
    public auth: AuthService,
    public dialog: MatDialog,
    private _googleAccountService: GoogleAccountService,
    private _locationService: LocationService,
    private _groupService: GroupService,
    private _google: GoogleService,
    private _cdr: ChangeDetectorRef,
    private _snack: SnackbarService,
    private _obsService: ObservationService,
    private _modalService: ModalService,
    private _userService: UserService,
  ) {
    this.user = this.auth.session;
    this.subscription$ = this.auth.subscription$;
    this.subscription$.subscribe(subscription => {
      this.subscription = subscription;
      // if (this.subscription?.status == GROUP_SUBSCRIPTION_TYPE.TRIAL && !this.accountVerified) {
      //   this.showAccountsMessage();
      // }
    });

    if (!this.auth.isAdmin) {
      this.displayedColumns = ['name'];
    }
    this._obsService.getAddAccount()
      .pipe(takeUntil(this.subsManager$))
      .subscribe(() => this.onAddNewAccount());
  }

  dataSource = new MatTableDataSource<SavedAccount[]>(null);
  // custom pagination elements
  manualPage: number;
  errorMessage: boolean;

  @ViewChild(MatSort, { static: true }) sort: MatSort;


  ngOnInit() {
    this._locationService.setPaginate({ size: 10, page: 1 });
    this._userFeatures = this._userService.getUserFeature(this.user.uid).toPromise() 

    this._cdr.detectChanges();
    this._groupService.getByDocId(this.user.gid).subscribe(group => {
      this._group = group;
      this.getData(this._paginate);
    });
    this.accounts = this.accountService.accounts;
    this.accountService.pagination = this.pagination;
    this.accountService.previousPageable = this._previousPageable;
    this.dataSource.sort = this.sort;

    this.accounts
      .pipe(
        takeUntil(this.subsManager$)
      )
      .subscribe(() => {
        this.pagination = this.accountService.pagination;
        this._previousPageable = this.accountService.previousPageable;
      });

    this.dataSource.filterPredicate = (data, filter: string) => {
      const accumulator = (currentTerm, key) => {
        return this.nestedFilterCheck(currentTerm, data, key);
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };
    this.manualPage = 1;
    this.errorMessage = false;

  }

  showAccountsMessage() {
    // const paginate: Pageable = { size: 5, page: 1 };
    this.accountVerified = true;
    this.accounts?.subscribe(acc => {
      // check if no accounts message should be seen
      this._cdr.detectChanges();
    })
  }

  enableNotifications(element): void {
    this._modalService.openConfirmModal(
      'Enable Notifications',
      'Are you sure want you enable notifications?',
      ((response) => {
        if (response) {
          this.accountService.enableNotifications(element.accountId).subscribe(
            res => {
              this._snack.openSuccess('The notifications was successfully enabled.', 4000);
            },
            err => {
              this._snack.openError(err.message, 4000);
            }
          );
        }
      }));
  }

  ngOnDestroy(): void {
    this.subsManager$.next(true);
    this.subsManager$.complete();
  }

  async openReauth(account) {
    if (account.hasOwnProperty('googleAuthEmailAddress')) {
      const dialogRef = this.dialog.open(DialogAccountReauthComponent, {
        width: '680px',
        data: {
          account
        }
      });

      dialogRef.componentInstance.onAccept
        .pipe(take(2))
        .subscribe(async r => {
          if (r) {
            await this.onAddNewAccount(account.accountId);
          }
        });
    } else {
      await this.onAddNewAccount(account.accountId);
    }
  }

  async getData(_event: Pageable): Promise<void> {
    try {
      const f = await this._userFeatures;
      const result = await this.accountService.loadAll(this.user, this._paginate)
      if (result['items'].length === 0 &&
          !this._group?.autoAddLocationsTriggered &&
          (f.userFeatures.autoAddLocationsTrigger || f.generalFeatures.autoAddLocationsTrigger)) {
        const response = await this._modalService.openConfirmModal(
          'Attention: Redirecting you to Google Business Profile Login',
          'After clicking Continue, please ensure you select the account you use for managing your Google Business Profiles',
          null,
          AlertType.INFO,
          'Continue')

        if (response)
          this.onAddNewAccount();
        this._groupService.updateByDocId(this.user.gid, { 'autoAddLocationsTriggered': true })
      }
    } catch (e) {
      console.error(e)
      this._snack.openError('There was an error while loading the data. Please try again or contact support')
    }
  }

  async onAddNewAccount(accountId = null) {
    this.dialog.closeAll();
    this.da = null;
    this.dl = null;

    this._googleAccountService.onLoadAll
      .pipe(takeUntil(this.subsManager$))
      .subscribe((e: any) => {
        if (e.success !== true) {
          let msg = '';
          switch (e.details.status) {
            case 901:
              msg = 'Oops! There was an error in the application ';
              break;
            case 401:
              msg = 'Google said you are not authorized! - Try again';
              break;
            default:
              msg = 'Some odd error happened. Try again.';
          }
          return this._snack.openError(msg, 4000);
        }

        // Everything looks good...
        // Prevent multiple dialogs from being opened
        !this.da && this.openAddAccountsDialog(accountId);
      });


    try {
      this._snack.openInfo(`A tab to authenticate with Google will open. If you don't see it, check your pop-up blocker settings`, 2000);
      const data = await this._google.authenticate(this.user.gid, this.user.uid, null);
      const oauth = window.open(data, '_blank');
      // This popup ends up being a redirection so we cannot detect the real close event.
      // So we use an interval trick to overcome this.
      const popupTick = setInterval(() => {
        if (oauth?.closed) {
          clearInterval(popupTick);
          this._googleAccountService.loadAll();
        }
      }, 1000);
    } catch (e) {

      // This popup ends up being a redirection so we cannot detect the real close event.
      // So we use an interval trick to overcome this, with an event
      const message = 'There was an error with the GBP Authentication service';
      console.error(message, e);
      return this._snack.openError(message, 4000);
    }
  }

  onDeleteAccount(account): void {
    const dialogRef = this.dialog.open(DialogDeleteAccountComponent, {
      width: '680px',
      data: {
        account
      }
    });

    dialogRef.disableClose = true;

    dialogRef.componentInstance.onDelete
      .pipe(takeUntil(this.subsManager$))
      .subscribe(() => {
        this._snack.openSuccess('The account was successfully deleted.', 4000);
        this.ngOnInit();
      });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.subsManager$))
      .subscribe(() => null);
  }

  // apply filter from search
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  // check for nested objects
  nestedFilterCheck(search, data, key) {
    if (typeof data[key] === 'object') {
      for (const k in data[key]) {
        if (data[key][k] !== null) {
          search = this.nestedFilterCheck(search, data[key], k);
        }
      }
    } else {
      search += data[key];
    }
    return search;
  }

  openAddAccountsDialog(accountId = null): void {
    // TODO: Replace for modal service
    this.da = this.dialog.open(DialogAccountsComponent, {
      width: '680px',
      data: { googleAccounts: this._googleAccountService.accounts, accountId: accountId },
      autoFocus: false
    });

    this.da.backdropClick().subscribe(() => {
      this.dialog.closeAll();
      this.da = null;
    });

    this.da.afterClosed()
      .pipe(takeUntil(this.subsManager$))
      .subscribe(selectedAccountId => {
        this.dialog.closeAll();
        //this.getData(this.paginate);
        if (selectedAccountId === '' || selectedAccountId === undefined) {
          this.da = null;
          return;
        }

        if (accountId) {
          this.da = null;
          this.dialog.closeAll();
          return;
        }

        this.dl = this.dialog.open(DialogLocationsComponent, {
          width: '680px',
          data: { selectedAccountId, googleAccounts: this._googleAccountService.accounts }
        });
        // Destroy the dialogs on close to get clean data.
        this.dl.afterClosed()
          .pipe(takeUntil(this.subsManager$))
          .subscribe(data => {
            this.dl = null;
            this.da = null;
            if (data === 'back') {
              this.openAddAccountsDialog(null);
            } else {
              if (data !== '') {
                const accountId = selectedAccountId?.split('/')[1];
                this.router.navigate(['accounts', accountId, 'locations']); //, 'locations'
              }
            }
            this.getData(this._paginate);
          });
      });

  }

  handleReload($event: Pageable) {
    this._paginate = $event;
    this.getData($event);
  }


  goLocation(element) {
    let errorAccount = true;
    if (element?.gauthStatus?.isValid) {
      errorAccount = !element?.gauthStatus?.isValid
    }
    this.router.navigate(['/accounts/', element.id || element.accountId, 'locations'], { queryParams: { errorAccount } });
  }

  refresh(element) {
    if (!this.loading_refresh_account$.getValue()) {
      this.loading_refresh_account$.next(true);
      const elementHtml = document.getElementById(`spin-${element.accountId}`);
      elementHtml.classList.add('active-spin');
      this._snack.openInfo("Account locations are being refreshed. This may take several minutes", 1800);
      setTimeout(() => {
        elementHtml.classList.remove('active-spin');
      }, 2500);
      const destroy_refresh_account$ = new Subject<boolean>();
      this.accountService.refreshAccountLocations(element).pipe(
        takeUntil(destroy_refresh_account$)
      )
        .subscribe(r => {
          if (r.data.status === 'FAIL') {
            this._snack.openError('The operation has been failed', 2500);
            destroy_refresh_account$.next(true);
            destroy_refresh_account$.unsubscribe();
          } else if (r.data.status === 'COMPLETE') {
            this._snack.openSuccess(r.data.response, 2500);
            destroy_refresh_account$.next(true);
            destroy_refresh_account$.unsubscribe();
          }
          this.loading_refresh_account$.next(false);
        });
    } else {
      this._snack.openInfo("Wait, We are update your account", 1800);
    }

  }

}

// modal accounts
@Component({
  selector: 'app-dialog-accounts',
  templateUrl: 'dialog-accounts.html',
})
export class DialogAccountsComponent {
  user;
  accountId;
  googleAccounts: GoogleAccount[];
  selectedAccount: string;
  onAccountSelected = new EventEmitter();
  search: any;
  resultSearchAccount: any;
  subsManager$: Subject<boolean> = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogAccountsComponent>,
    private _router: Router,
    private _accountService: AccountService,
    private _auth: AuthService,
    private _snack: SnackbarService
  ) {
    this.user = this._auth.session;
    this.selectedAccount = null;
    this.data.googleAccounts.pipe(takeUntil(this.subsManager$)).subscribe(result => {
      this.googleAccounts = result;
      this.resultSearchAccount = result;

      if (this.data.accountId != null) {
        this.close();
        this._accountService.updateGauth(this._auth.session.gid, this.data.accountId).then(res => {
          this._router.navigate(['accounts', this.data.accountId, 'locations']);
          this._snack.openSuccess('Your account has been re-authenticated!', 2000);
        });
      }
    });


  }

  close() {
    this.subsManager$.next(true);
    this.subsManager$.unsubscribe();
    this.dialogRef.close();
  }

  addAccount(): void {
    this.subsManager$.next(true);
    this.subsManager$.unsubscribe();
    this.dialogRef.close(this.selectedAccount);
  }

  filterAccount() {
    this.resultSearchAccount = this.googleAccounts.filter(account => account.accountName.toLowerCase().includes(this.search.toLowerCase()));
  }
}

// Delete Dialog
@Component({
  selector: 'app-modal-delete',
  templateUrl: 'dialog-delete-account.html',
})
export class DialogDeleteAccountComponent implements OnInit{
  public account;
  public onDelete = new EventEmitter<boolean>();
  public user;
  public companyName = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: any,

    public dialogRef: MatDialogRef<DialogDeleteAccountComponent>,
    private _accountService: AccountService,
    private _locationService: LocationService,
    private _auth: AuthService,
    private _spinnerService: SpinnerService,
    private _notificationService: NotificationService,
    private _wl: WhiteLabelService,
  ) {
    this.account = data.account;
    this.user = this._auth.session;
  }

  ngOnInit(): void {
    this._wl.branding.then(res => this.companyName = res['company_name']);
  }

  async handleDeleteAccount() : Promise<void> {
    const gid       = this.user.gid
    const accountId = this.account.accountId

    try {
      this._spinnerService.loading$.next(true)
            
      const locations = await this._locationService.byAccount(gid, accountId).toPromise()

      // TODO: Removing account locations should be backend responsibility
      for(const loc of locations) {
        await this._locationService.deleteLocation(gid, loc.locationId, accountId)
        await this._locationService.deleteReferencesToLocation(gid, loc.locationId)

        //Notification informative delete location
        const locationAddress = loc.location.address ? this._locationService.formatAddress(loc.location.address) : '';
        const meta = this._notificationService.getMetaTypeLog(TYPE_LOG_LOCATION, { accountId, 
                                                                                    address: locationAddress, 
                                                                                  ...loc })
        await this._notificationService.saveNotification(gid, this._wl.baseDomain, 
                                                         string_message(Messages.notifications.LOCATION_TOGGLE, [loc.locationName, locationAddress, DELETE_DATA]),
                                                         NOTIFICATION_GENERAL, TYPE_LOG_LOCATION, meta)
      }
      // All Account locations deleted, delete account
      await this._accountService.delete(gid, accountId);

    } catch(err) {
      console.error('Error deleting account', err)
    } finally {
      await this._auth.processSubscription();
      this.onDelete.emit(true);
      this.dialogRef.close();
      this._spinnerService.loading$.next(false)
    }
  }

}

// Delete Dialog
@Component({
  selector: 'app-modal-reauth',
  templateUrl: 'dialog-account-reauth.html',
})
export class DialogAccountReauthComponent {
  public account;
  onAccept = new EventEmitter<boolean>(false);
  user;

  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: any,
    public router: Router,
    public dialogRef: MatDialogRef<DialogDeleteAccountComponent>,
    private _auth: AuthService,
  ) {
    this.account = data.account;
    this.user = this._auth.session;
  }

  async ReAuth() {
    this.onAccept.emit(true);
  }

}
