import { PipeTransform } from '@angular/core';
import * as firebase from 'firebase/app';
import { DatePipe } from '@angular/common';
var FireTimestampPipe = /** @class */ (function () {
    function FireTimestampPipe() {
    }
    FireTimestampPipe.prototype.transform = function (value) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        var _a, _b;
        if (!value) {
            return;
        }
        var datePipe = new DatePipe('EN-US');
        if ((_a = value) === null || _a === void 0 ? void 0 : _a.seconds) {
            return datePipe.transform(new Date(((_b = value) === null || _b === void 0 ? void 0 : _b.seconds) * 1000), 'MM/dd/yyyy');
        }
        if (value instanceof firebase.firestore.Timestamp) {
            return datePipe.transform(value.toDate(), 'MM/dd/yyyy');
        }
        return datePipe.transform(new Date(value), 'MM/dd/yyyy');
    };
    return FireTimestampPipe;
}());
export { FireTimestampPipe };
