import { __awaiter, __generator } from "tslib";
// app
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "@angular/router";
var BulkActionsGuardService = /** @class */ (function () {
    function BulkActionsGuardService(_auth, _router) {
        var _this = this;
        this._auth = _auth;
        this._router = _router;
        this._bulk_actions_enabled = false;
        this.subscription$ = this._auth.subscription$;
        this.subscription$.subscribe(function (subscription) {
            var _a, _b, _c, _d;
            _this.subscription = subscription,
                _this._bulk_actions_enabled = (((_a = _this.subscription) === null || _a === void 0 ? void 0 : _a.pricingVersion) < 3 || (((_b = _this.subscription) === null || _b === void 0 ? void 0 : _b.pricingVersion) >= 3 && ((_d = (_c = _this.subscription) === null || _c === void 0 ? void 0 : _c.packages) === null || _d === void 0 ? void 0 : _d.hasOwnProperty("pkg_bulk_actions"))));
            console.log('BulkActionsGuardService#constructor: _bulk_actions_enabled', _this._bulk_actions_enabled);
        });
    }
    BulkActionsGuardService.prototype.canActivate = function (route) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            var subscription;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0: return [4 /*yield*/, this._auth.subscription$.getValue()];
                    case 1:
                        subscription = _e.sent();
                        this._bulk_actions_enabled = (((_a = subscription) === null || _a === void 0 ? void 0 : _a.pricingVersion) < 3 || (((_b = subscription) === null || _b === void 0 ? void 0 : _b.pricingVersion) >= 3 && ((_d = (_c = subscription) === null || _c === void 0 ? void 0 : _c.packages) === null || _d === void 0 ? void 0 : _d.hasOwnProperty("pkg_bulk_actions"))));
                        if (this._bulk_actions_enabled === false) {
                            console.log('BulkActionsGuardService#canActivate: bulk actions not enabled');
                            this._router.navigate(['/settings/billing']);
                            return [2 /*return*/, false];
                        }
                        console.log('BulkActionsGuardService#canActivate: bulk actions enabled');
                        return [2 /*return*/, true];
                }
            });
        });
    };
    BulkActionsGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BulkActionsGuardService_Factory() { return new BulkActionsGuardService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router)); }, token: BulkActionsGuardService, providedIn: "root" });
    return BulkActionsGuardService;
}());
export { BulkActionsGuardService };
