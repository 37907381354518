import { __awaiter, __generator, __read, __spread } from "tslib";
import * as _ from 'lodash';
import { EMPTY, timer } from 'rxjs';
import 'rxjs/add/operator/retry';
import { debounce, expand, map, reduce } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
// app
import { HEADERS_NO_AUTH } from '../constants/auth';
import { WhiteLabelService } from './white-label.service';
import { environment as ENV } from '@environment';
import * as i0 from "@angular/core";
import * as i1 from "./white-label.service";
import * as i2 from "@angular/common/http";
var DYNAMIC_URL = 'https://search.google.com/local/writereview?placeid=';
var GoogleService = /** @class */ (function () {
    function GoogleService(_wl, _http) {
        this._wl = _wl;
        this._http = _http;
        this.googleApiUrl = ENV.googleApi;
        this.googleApiUrLocations = ENV.googleApiLocations;
        this.googleApiUrverifications = ENV.googleApiVerifications;
        this.googleAccountManagementApiUrl = ENV.googleAccountManagementApi;
        this.domain = this._wl.apiUrlForGoogle;
    }
    GoogleService.prototype.authenticate = function (gid, uid, accountId) {
        return __awaiter(this, void 0, void 0, function () {
            var apiUrl, domain, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        apiUrl = ENV.apiUrl;
                        domain = apiUrl.endsWith('/api') ? apiUrl.substring(0, apiUrl.length - 4) : apiUrl;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this._http.get(apiUrl + "/google/auth_url?uid=" + uid + "&gid=" + gid + "&accountId=" + accountId + "&domain=" + domain, HEADERS_NO_AUTH).toPromise()];
                    case 2: return [2 /*return*/, _a.sent()];
                    case 3:
                        err_1 = _a.sent();
                        throw new Error(err_1);
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    //--------------------------------------------------------------------------
    // Requests to Google
    //--------------------------------------------------------------------------
    // TODO: Unused, remove. 
    //   async updateProductList(accountId, locationId, priceList) : Promise<void> {
    //     this.auth.getGmbToken().subscribe(token => {
    //       this._http.patch(`${this.googleApiUrl}/accounts/${accountId}/locations/${locationId}?updateMask=priceLists`, 
    //                       {"priceLists": priceList}, this.headersGoogle(token)).toPromise();
    //     });
    // 
    //   }
    GoogleService.prototype.getUserProfile = function (googleAuth) {
        return this._http.get("https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=" + googleAuth.access_token, HEADERS_NO_AUTH).toPromise();
    };
    GoogleService.prototype.headersGoogle = function (token) {
        var _a;
        return { headers: HEADERS_NO_AUTH.headers.set('Authorization', "Bearer " + (((_a = token) === null || _a === void 0 ? void 0 : _a.access_token) || '')) };
    };
    GoogleService.prototype.getAccounts = function (token) {
        var _this = this;
        var url = this.googleAccountManagementApiUrl + '/accounts';
        return this._http.get(url, this.headersGoogle(token))
            .pipe(expand(function (res) {
            if (res.nextPageToken) {
                url += "" + (res.nextPageToken ? "&pageToken=" + res.nextPageToken : '');
                return _this._http.get(_this.googleAccountManagementApiUrl + ("/accounts?pageToken=" + res.nextPageToken), _this.headersGoogle(token));
            }
            else {
                return EMPTY;
            }
        }), reduce(function (acc, x) {
            return acc.concat(x.accounts);
        }, []), map(function (res) {
            return { accounts: res };
        }));
    };
    GoogleService.prototype.getLocations = function (token, accountId, nextPageToken) {
        var _this = this;
        if (nextPageToken === void 0) { nextPageToken = null; }
        var readMask = "storeCode,regularHours,name,languageCode,title,phoneNumbers,categories,storefrontAddress,websiteUri,regularHours,specialHours,serviceArea,labels,adWordsLocationExtensions,latlng,openInfo,metadata,profile,relationshipData,moreHours,metadata";
        var url = this.googleApiUrLocations + "/" + accountId + "/locations?readMask=" + readMask + "&pageSize=100" + (nextPageToken ? "&pageToken=" + nextPageToken : '');
        return this._http.get(url, this.headersGoogle(token))
            .pipe(expand(function (res) {
            if (res.nextPageToken) {
                url += "" + (res.nextPageToken ? "&pageToken=" + res.nextPageToken : '');
                return _this._http.get(url, _this.headersGoogle(token));
            }
            else {
                return EMPTY;
            }
        }), reduce(function (acc, x) { return acc.concat(x.locations); }, []), map(function (res) {
            res.forEach(function (el) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (el) {
                        el.locationName = el.title;
                        el.name = accountId + '/' + el.name;
                        el.address = el.storefrontAddress;
                    }
                    return [2 /*return*/];
                });
            }); });
            return { locations: res };
        }));
    };
    //--------------------------------------------------------------------------
    GoogleService.prototype.updateMenuList = function (accountId, locationId, priceList) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._http.post(ENV.apiUrl + "/v2/google/account/" + accountId + "/location/" + locationId + "/menu_list", { "priceList": priceList })
                            .pipe(map(function (value) { return value ? value.data : {}; })).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    GoogleService.prototype.saveMedia = function (accountId, locationId, data) {
        var url = ENV.apiUrl + "/v2/google/" + accountId + "/" + locationId + "/media";
        return this._http.post(url, data);
    };
    GoogleService.prototype.saveBulkMedia = function (accountId, locationId, data) {
        var url = ENV.apiUrl + "/v2/google/" + accountId + "/" + locationId + "/bulk_media";
        return this._http.post(url, data);
    };
    GoogleService.prototype.deleteMedia = function (accountId, locationId, name) {
        var params = new HttpParams().set('name', name);
        var url = ENV.apiUrl + "/v2/google/" + accountId + "/" + locationId + "/media";
        return this._http.delete(url, { params: params }).pipe(map(function (value) { return value.data || []; }));
    };
    GoogleService.prototype.getMedia = function (accountId, locationId) {
        var url = ENV.apiUrl + "/v2/google/" + accountId + "/" + locationId + "/media";
        return this._http.get(url).pipe(map(function (value) { return value.data || []; }));
    };
    GoogleService.prototype.getProfilePhoto = function (accountId, locationId) {
        var url = ENV.apiUrl + "/v2/google/" + accountId + "/" + locationId + "/media/profile";
        return this._http.get(url).pipe(map(function (value) { return value.data || []; }));
    };
    GoogleService.prototype.getCategories = function (displayName, usedCategories, region_code) {
        var params = new HttpParams().set('q', displayName).set('region_code', region_code);
        return this._http.get(ENV.apiUrl + "/v2/categories/search", {
            params: params
        }).pipe(map(function (value) {
            var _a;
            if ((_a = value) === null || _a === void 0 ? void 0 : _a.data) {
                var data_1 = __spread(value.data);
                usedCategories.forEach(function (cat) {
                    var _a, _b, _c, _d, _e;
                    var categoryId = ((_b = (_a = cat) === null || _a === void 0 ? void 0 : _a.categoryId) === null || _b === void 0 ? void 0 : _b.includes('categories')) ? (_d = (_c = cat) === null || _c === void 0 ? void 0 : _c.categoryId) === null || _d === void 0 ? void 0 : _d.split('/')[1] : (_e = cat) === null || _e === void 0 ? void 0 : _e.categoryId;
                    var index = data_1.findIndex(function (d) { return d.categoryId == categoryId; });
                    if (index > -1) {
                        data_1.splice(index, 1);
                    }
                });
                return data_1;
            }
            return null;
        }), debounce(function () { return timer(1000); }));
    };
    // ATTRIBUTES SERVICES, FOOD MENU, PRODUCTS
    GoogleService.prototype.saveMenu = function (accountId, locationId) {
        return this._http.get(ENV.apiUrl + "/v2/google/account/" + accountId + "/location/" + locationId + "/menu").pipe(map(function (value) { return value.data || []; }));
    };
    GoogleService.prototype.saveServices = function (accountId, locationId) {
        return this._http.get(ENV.apiUrl + "/v2/google/account/" + accountId + "/location/" + locationId + "/service_list").pipe(map(function (value) { return value.data || []; }));
    };
    GoogleService.prototype.updateServices = function (accountId, locationId, serviceList) {
        return this._http.post(ENV.apiUrl + "/v2/google/account/" + accountId + "/location/" + locationId + "/service_list", { serviceList: serviceList }).pipe(map(function (value) { return value.data || []; }));
    };
    GoogleService.prototype.updateBulkServices = function (accountId, locationId, data) {
        return this._http.post(ENV.apiUrl + "/v2/google/account/" + accountId + "/location/" + locationId + "/service_list", data).pipe(map(function (value) { return value.data || []; }));
    };
    GoogleService.prototype.updateFoodMenu = function (accountId, locationId, data) {
        return this._http.post(ENV.apiUrl + "/v2/google/account/" + accountId + "/location/" + locationId + "/menu_list", data).pipe(map(function (value) { return value.data || []; }));
    };
    GoogleService.prototype.getAttributes = function (gids, accountIds, locationIds) {
        // const params = new HttpParams().set('categoryId', categoryId);
        var data = {
            gids: gids,
            accountIds: accountIds,
            locationIds: locationIds
        };
        return this._http.post(ENV.apiUrl + "/v2/google/attributes", data).pipe(map(function (value) { var _a, _b; return value.data ? (_b = (_a = value) === null || _a === void 0 ? void 0 : _a.data[0]) === null || _b === void 0 ? void 0 : _b.attributes : []; }));
    };
    // TODO: Unused, remove
    //
    // getGoogleData(accountId, locationId) {
    //   return this._http.get<ApiResponse>(`${ENV.apiUrl}/v2/google/${accountId}/${locationId}/getGoogleData`)
    //     .pipe(map(value => value.data || null));
    // }
    GoogleService.prototype.fetchDifference = function (accountId, locationId) {
        return this._http.get(ENV.apiUrl + "/v2/google/" + accountId + "/" + locationId + "/fetchDifferences")
            .pipe(map(function (value) { return value.data || null; }));
    };
    GoogleService.prototype.diffMaskPush = function (gid, accountId, locationId, differences) {
        if (differences === void 0) { differences = null; }
        var data = {
            gid: gid,
            differences: differences
        };
        return this._http.post(ENV.apiUrl + "/v2/google/" + accountId + "/" + locationId + "/accept_google_updates", data);
    };
    GoogleService.prototype.fetch = function (select, accountId, locationId) {
        var params = new HttpParams().set('select', select);
        return this._http.get(ENV.apiUrl + "/v2/google/" + accountId + "/" + locationId + "/fetch", {
            params: params,
        }).pipe(map(function (value) { return value || null; })).toPromise();
    };
    GoogleService.prototype.listLockHistory = function (accountId, placeId, pageable) {
        var params = new HttpParams()
            .set('size', pageable.size.toString())
            .set('accountId', accountId)
            .set('page', pageable.page.toString());
        return this._http.get(ENV.apiUrl + "/v2/google/" + placeId + "/lockHistory", {
            params: params,
        }).pipe(map(function (value) { return value ? value.data : null; }));
    };
    GoogleService.prototype.saveLockHistory = function (accountId, locationId, action, status) {
        return this._http.post(ENV.apiUrl + "/v2/google/" + accountId + "/" + locationId + "/lockHistory", {
            status: status,
            action: action
        }).pipe(map(function (value) { return value ? value.data : null; }));
    };
    GoogleService.prototype.push = function (accountId, locationId) {
        return this._http.post(ENV.apiUrl + "/v2/google/" + accountId + "/" + locationId + "/push", {})
            .pipe(map(function (value) { return value || null; }));
    };
    GoogleService.prototype.metrics = function (accountId, locationId, dataPicker) {
        var _a;
        var loc = (_a = dataPicker) === null || _a === void 0 ? void 0 : _a.locations[0];
        if (loc && loc.accountId && loc.locationId) {
            accountId = loc.accountId;
            locationId = loc.locationId;
        }
        return this._http.get(ENV.apiUrl + "/v2/google/" + accountId + "/" + locationId + "/grade").retry(3)
            .pipe(map(function (value) { return value ? value.data : []; }));
    };
    GoogleService.prototype.dynamicUrl = function (locationId) {
        var url = DYNAMIC_URL + locationId;
        return this._http.post(ENV.fbDynamicLinkApi, {
            longDynamicLink: ENV.fbDynamicLinkDomain + "/?link=" + url,
            suffix: {
                option: 'SHORT'
            }
        }, HEADERS_NO_AUTH);
    };
    GoogleService.prototype.attributesToGMB = function (groups) {
        var itemsActive = [];
        groups.forEach(function (group) {
            var active = group.items.filter(function (item) {
                return item.active === true || item.active === false || item.attributeId.startsWith('url');
            }).filter(function (item) {
                if (item.valueType === 'URL' && item.urlValues === undefined) {
                    return false;
                }
                return true;
            });
            itemsActive = itemsActive.concat(active);
        });
        var attributes = this.toLocationAttribute(itemsActive);
        return attributes;
    };
    GoogleService.prototype.toLocationAttribute = function (items) {
        var _this = this;
        var result = [];
        items.forEach(function (item) {
            result.push(_this.toLocationAttributes(item));
        });
        return result;
    };
    GoogleService.prototype.toLocationAttributes = function (item) {
        var result = {};
        switch (item.valueType) {
            case 'BOOL':
                result = { valueType: item.valueType, attributeId: item.attributeId, values: [item.active] };
                break;
            case 'ENUM':
                result = { valueType: item.valueType, attributeId: item.attributeId, values: [item.valueMetadata] };
                break;
            case 'URL':
                result = { valueType: item.valueType, attributeId: item.attributeId, urlValues: item.urlValues };
                break;
            case 'REPEATED_ENUM':
                var valuesSet = _.reject(item.valueMetadata.map(function (v) { return v.active && v.value; }), function (o) { return !o; });
                var valuesUnSet = _.reject(item.valueMetadata.map(function (v) { return v.active === false && v.value; }), function (o) { return !o; });
                var enumValues = {};
                !_.isEmpty(valuesSet) && (enumValues['setValues'] = valuesSet);
                !_.isEmpty(valuesUnSet) && (enumValues['unsetValues'] = valuesUnSet);
                result = { valueType: item.valueType, attributeId: item.attributeId, repeatedEnumValue: enumValues };
                break;
            default:
                break;
        }
        return result;
    };
    /**
     * organize the items in google attributes object
     * @return  this.groups organized
     */
    GoogleService.prototype.toAttributeGroups = function (googleAttributes) {
        var attributes = _.cloneDeep(googleAttributes);
        var groups = [];
        if (!googleAttributes) {
            return [];
        }
        attributes.forEach(function (value) {
            var groupName = value.groupDisplayName;
            var exist = false;
            var index = null;
            groups.forEach(function (group, i) {
                if (groupName === group.groupName) {
                    exist = true;
                    index = i;
                }
            });
            value.active = null;
            if (exist) {
                groups[index].items.push(value);
            }
            else {
                groups.push({
                    groupName: groupName,
                    items: [value],
                });
            }
        });
        groups.forEach(function (group) {
            group.items = _.orderBy(group.items, ['valueType'], ['asc']);
        });
        return groups;
    };
    /*
      FIXME: smell bad
      attribute onlyActive never used
    */
    GoogleService.prototype.activeSavedAttributes = function (groups, locationAttributes, onlyActive) {
        if (onlyActive === void 0) { onlyActive = false; }
        if (!locationAttributes) {
            return;
        }
        if (locationAttributes.length === 0) {
            return groups;
        }
        groups.forEach(function (group) {
            group.items.forEach(function (item) {
                if (item.valueType === 'URL')
                    group.active = true;
                locationAttributes.forEach(function (locAttribute) {
                    var _a, _b;
                    if (item.attributeId === locAttribute.attributeId) {
                        if (item.valueType === 'URL') {
                            item.urlValues = locAttribute.urlValues;
                            group.active = true;
                        }
                        if (item.valueType === 'BOOL') {
                            item.active = locAttribute.values[0];
                            group.active = true;
                        }
                        if (item.valueType === 'REPEATED_ENUM' || item.valueType === 'ENUM') {
                            (_b = (_a = item) === null || _a === void 0 ? void 0 : _a.valueMetadata) === null || _b === void 0 ? void 0 : _b.map(function (metadata) {
                                var _a, _b, _c, _d, _e, _f;
                                metadata.active = null;
                                if (((_c = (_b = (_a = locAttribute) === null || _a === void 0 ? void 0 : _a.repeatedEnumValue) === null || _b === void 0 ? void 0 : _b.setValues) === null || _c === void 0 ? void 0 : _c.findIndex(function (value) { return value === metadata.value; })) > -1) {
                                    group.active = true;
                                    item.active = true;
                                    metadata.active = true;
                                }
                                else if (((_f = (_e = (_d = locAttribute) === null || _d === void 0 ? void 0 : _d.repeatedEnumValue) === null || _e === void 0 ? void 0 : _e.unsetValues) === null || _f === void 0 ? void 0 : _f.findIndex(function (value) { return value === metadata.value; })) > -1) {
                                    group.active = true;
                                    item.active = true;
                                    metadata.active = false;
                                }
                            });
                        }
                    }
                });
            });
        });
        return groups;
    };
    GoogleService.prototype.getExcludeAtributes = function (googleAttributes, locationAttributes) {
        if (locationAttributes === void 0) { locationAttributes = []; }
        var result = [];
        locationAttributes.forEach(function (locAttribute) {
            var findAttribute = _.find(googleAttributes, { 'attributeId': locAttribute.attributeId });
            if (!findAttribute || _.isEqual(locAttribute.valueType, 'URL')) {
                result.push(locAttribute);
            }
        });
        return result;
    };
    GoogleService.prototype.groupsWithAttributeSaved = function (gid, accountId, locationId, primaryCategory, locationAttributes, googleAttributes) {
        if (googleAttributes === void 0) { googleAttributes = []; }
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var excludedAtributes, groups;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(((_a = googleAttributes) === null || _a === void 0 ? void 0 : _a.length) === 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getAttributes([gid], [accountId], [locationId]).toPromise()];
                    case 1:
                        googleAttributes = _b.sent();
                        _b.label = 2;
                    case 2:
                        excludedAtributes = this.getExcludeAtributes(googleAttributes, locationAttributes);
                        groups = this.toAttributeGroups(googleAttributes);
                        this.activeSavedAttributes(groups, locationAttributes);
                        return [2 /*return*/, { groups: groups, googleAttributes: googleAttributes, excludedAtributes: excludedAtributes }];
                }
            });
        });
    };
    GoogleService.prototype.groupsWithAttribute = function (accountId, gid, locationIds) {
        return __awaiter(this, void 0, void 0, function () {
            var googleAttributes, excludedAtributes, groups;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getAttributes(gid, accountId, locationIds).toPromise()];
                    case 1:
                        googleAttributes = _a.sent();
                        excludedAtributes = [];
                        groups = this.toAttributeGroups(googleAttributes);
                        return [2 /*return*/, { groups: groups, googleAttributes: googleAttributes, excludedAtributes: excludedAtributes }];
                }
            });
        });
    };
    GoogleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GoogleService_Factory() { return new GoogleService(i0.ɵɵinject(i1.WhiteLabelService), i0.ɵɵinject(i2.HttpClient)); }, token: GoogleService, providedIn: "root" });
    return GoogleService;
}());
export { GoogleService };
