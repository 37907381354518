import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class QuestionsAnswerService {

  constructor(
    private http: HttpClient,
  ) { }

  getReportData(reportId, paginate, startDate, endDate, accounts, questionType, isAllTime = true) {
    const data = {
        reportId: reportId,
        page: paginate.page,
        size: paginate.size,
        startDate: startDate,
        endDate: endDate,
        reportAccounts: accounts,
        questionType: questionType,
        isAllTime: isAllTime
    }

    return this.http.post(`${environment.apiUrl}/v3/reports/qanda`, data);
  }

  buildPeriodDate(period) {
    const today = moment();
    const dates = {
      startDatetime: null,
      endDatetime: this.getDate(today)
    }
    
    switch(period) {
      case 'Last30':
        dates.startDatetime = this.getDate(today.subtract('day', 30));
        break;
      case 'ThisMonth':
        dates.startDatetime = this.getDate(today.startOf('month'));
        dates.endDatetime = this.getDate(today.endOf('month'));
        break;
      case 'LastMonth':
        const month = today.subtract('month', 1)
        dates.startDatetime = this.getDate(month.startOf('month'));
        dates.endDatetime = this.getDate(month.endOf('month'));
        break;
      default:
        dates.startDatetime = null;
        dates.endDatetime = null;
        break;
    }

    return dates;
  }

  getDate(date) {
    return `${date['year']()}-${date['month']() +1}-${date['date']()}`;
  }

  getTotals(gid = null, accountId = null, locationId = null, questionType = null, startDate = null, endDate = null): Observable<any> {
    const data = {
      questionType: questionType,
      startDate: startDate,
      endDate: endDate
    };

    return this.http.post(`${environment.apiUrl}/v3/qanda/${gid}/${accountId}/${locationId}/get_questions_metrics`, data);
  }

  getQuestions(gid = null, accountId = null, locationId = null, paginate: any, questionType = null, startDate = null, endDate = null): Observable<any> {
    const data = {
      page: paginate.page,
      size: paginate.size,
      questionType: questionType,
      startDate: startDate,
      endDate: endDate
    }
    return this.http.post(`${environment.apiUrl}/v3/qanda/${gid}/${accountId}/${locationId}/get_questions`, data);
  }

  getQuestionsAtlasSearch(accounts, search_text, search_type, initial_date, final_date, limit): Observable<any> {
    
    const data = {
      accounts,
      search_text,
      search_type,
      initial_date, 
      final_date,
      limit
    }
    
    return this.http.post(`${environment.apiUrl}/v3/qanda/get_filtered_qnas`, data);
  }

  addAnswer(gid = null, accountId = null, locationId = null, questionId, displayName, answer) {
    const data = {
      data : {
        questionId: questionId,
        answer: answer,
        displayName: displayName
      }
    }
    return this.http.post(`${environment.apiUrl}/v3/qanda/${gid}/${accountId}/${locationId}/add_answer`, data);
  }

  deleteAnswer(gid = null, accountId = null, locationId = null, questionId, name) {
    const data = {
      data : {
        questionId: questionId,
        name: name
      }
    }
    return this.http.post(`${environment.apiUrl}/v3/qanda/${gid}/${accountId}/${locationId}/delete_answer`, data);
  }
}
