import { __awaiter, __generator, __read, __spread, __values } from "tslib";
import { OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ObservationService } from '../../services/observation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDrawer, MatTableDataSource } from '@angular/material';
import { forkJoin, from, Observable, of, Subject, throwError } from 'rxjs';
import { FormControl } from '@angular/forms';
import { catchError, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { AccountService } from '../../services/account.service';
import { LOCATION_SUBSCRIPTION_TYPE } from '../../constants/firestore/account-location';
import { ModalService } from '../../services/modal.service';
import { LocationService } from '../../services/location.service';
import { SpinnerService } from '../../services/spinner.service';
import { GroupService } from '../../services/group.service';
import { ProfilesService } from '../../services/profiles/profiles.service';
import { AccountContainer, LocationModel, ProfileModel } from '../../lib/api-requests/create-profile-request';
import { GoogleAccountService } from '../../services/google-account.service';
import { GoogleService } from '../../services/google.service';
import { SnackbarService } from '../../services/snackbar.service';
import { GoogleLocationService } from '../../services/google-location.service';
import { DELETE_DATA, NOTIFICATION_GENERAL, TYPE_LOG_LOCATION } from '../../constants/notifications';
import { Messages, string_message } from '../../constants/messages';
import { NotificationService } from '../../services/notification.service';
import { WhiteLabelService } from '../../services/white-label.service';
var ProfilesComponent = /** @class */ (function () {
    function ProfilesComponent(router, authService, obsService, accountService, modalService, profilesService, locationService, spinnerService, notificationService, wl, groupService, googleAccountService, snackbarService, googleService, googleLocationService, route) {
        this.router = router;
        this.authService = authService;
        this.obsService = obsService;
        this.accountService = accountService;
        this.modalService = modalService;
        this.profilesService = profilesService;
        this.locationService = locationService;
        this.spinnerService = spinnerService;
        this.notificationService = notificationService;
        this.wl = wl;
        this.groupService = groupService;
        this.googleAccountService = googleAccountService;
        this.snackbarService = snackbarService;
        this.googleService = googleService;
        this.googleLocationService = googleLocationService;
        this.route = route;
        this.DEFAULT_PAGE_SIZE = 10;
        this.loadingTable = true;
        this.allChecked = false;
        this.displayedColumns = [];
        this.locations = new MatTableDataSource([]);
        this.paginate = { size: this.DEFAULT_PAGE_SIZE, page: 1 };
        this.pagination = {
            items: [],
            per_page: this.paginate.size,
            page: this.paginate.page,
            hasNext: false,
            hasPrev: false,
            pages: 0,
            total: 0
        };
        this.sort = {
            sortBy: 'locationName',
            sortOrder: 'desc'
        };
        this.filterAccountsControl = new FormControl('');
        this.addProfileFilterAccountsControl = new FormControl('');
        this.accountsOptions = [];
        this.labelAccountsFiltered = 'All';
        this.filteredAccounts = [];
        this.allLocationCheck = false;
        this.selectedLocations = [];
        this.drawerOpened = false;
        this.subsManager$ = new Subject();
        this.googleAccounts = [];
        this.profilesOptions = [];
        this.accountProfilesOptions = [];
        this.addingProfile = false;
        this.profileStatus = {
            'verified': 'verified',
            'pending_edits': 'pending edits',
            'not_verified': 'not verified',
            'verified_locked': 'verified & locked',
        };
    }
    ProfilesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.user = this.authService.session;
        this.subscription$ = this.authService.subscription$;
        this.subscription$.subscribe(function (subscription) { return _this.subscription = subscription; });
        this.formatAdminColumns();
        this.loadAccounts();
        this.filteredOptions = this.filterAccountsControl.valueChanges.pipe(startWith(''), map(function (value) { return _this._filter(value || ''); }));
        this.addProfileFilterSelected = this.addProfileFilterAccountsControl.valueChanges.pipe(startWith(''), map(function (value) { return _this._filter(value || ''); }));
    };
    ProfilesComponent.prototype.formatAdminColumns = function () {
        if (this.authService.isAdmin) {
            this.displayedColumns = ['check', 'company', 'storeCode', 'status', 'actions', 'subscription'];
        }
        else {
            this.displayedColumns = ['check', 'company', 'storeCode', 'status', 'subscription'];
        }
    };
    ProfilesComponent.prototype.loadAccounts = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var _c, _d, _e, acc;
            var e_1, _f;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        _c = this;
                        return [4 /*yield*/, this.accountService.getAccountPaginate((_b = (_a = this.authService) === null || _a === void 0 ? void 0 : _a.session) === null || _b === void 0 ? void 0 : _b.gid, { page: 1, size: 1000 }, [])];
                    case 1:
                        _c.accounts = (_g.sent()).items;
                        try {
                            for (_d = __values(this.accounts), _e = _d.next(); !_e.done; _e = _d.next()) {
                                acc = _e.value;
                                acc.checked = false;
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (_e && !_e.done && (_f = _d.return)) _f.call(_d);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        this.accountsOptions = __spread(this.accounts);
                        this.loadProfilesTable(true);
                        return [2 /*return*/];
                }
            });
        });
    };
    ProfilesComponent.prototype.loadProfilesTable = function (resetPaginator) {
        var _this = this;
        if (resetPaginator === void 0) { resetPaginator = false; }
        this.loadingTable = true;
        if (resetPaginator) {
            this.pagination = {
                items: [],
                per_page: this.DEFAULT_PAGE_SIZE,
                page: 1,
                hasNext: false,
                hasPrev: false,
                pages: 0,
                total: 0
            };
        }
        var accountsForSearch = this.filteredAccounts.length > 0 ? this.filteredAccounts : this.accounts;
        var profileRequest = {
            gids: __spread(new Set(accountsForSearch.map(function (item) { return item.gid; }))),
            accountIds: __spread(new Set(accountsForSearch.map(function (item) { return item.accountId; }))),
            locationIds: [],
            page: this.pagination.page,
            pageSize: this.pagination.per_page,
            sortKey: "locationName",
            reverse: false
        };
        if (accountsForSearch.length > 0) {
            this.profilesService.getProfiles(profileRequest)
                .subscribe(function (result) {
                _this.locations = new MatTableDataSource(result.data.items);
                _this.pagination.items = result.data.items;
                _this.pagination.total = result.data.total;
                _this.pagination.hasNext = result.data.hasNext;
                _this.pagination.hasPrev = result.data.hasPrev;
                _this.pagination.page = result.data.page;
                _this.pagination.per_page = result.data.pageSize;
                _this.pagination.pages = result.data.totalPages;
                _this.loadingTable = false;
            });
        }
    };
    ProfilesComponent.prototype.onAddAccount = function () {
        var _this = this;
        this.router.navigate(['profiles']).then(function () { return _this.obsService.sendAddAccount(); });
    };
    ProfilesComponent.prototype.actionSelected = function (event) {
        console.log(event);
    };
    ProfilesComponent.prototype.goLocation = function (location) {
        this.router.navigate(['/account', location.accountId, 'location', location.locationId, 'insights']);
    };
    ProfilesComponent.prototype.toggleCheckAll = function (event) {
        if (event.checked) {
            this.selectAll();
        }
        else {
            this.clearAll();
        }
    };
    ProfilesComponent.prototype.selectLocation = function (element, event) {
        if (event.checked) {
            this.selectedLocations.push(element);
        }
        else {
            this.selectedLocations = this.selectedLocations.filter(function (item) { return item.locationId !== element.locationId; });
        }
    };
    ProfilesComponent.prototype.selectAll = function () {
        var _this = this;
        this.locations.data.forEach(function (element) {
            element.isChecked = true;
            _this.selectedLocations.push(element);
        });
    };
    ProfilesComponent.prototype.clearAll = function () {
        this.locations.data.forEach(function (element) {
            element.isChecked = false;
        });
        this.selectedLocations = [];
    };
    ProfilesComponent.prototype.refresh = function (profile) {
        this.locationService.refreshAll(profile.accountId, profile.locationId, this.user.gid)
            .finally(function () {
        });
    };
    ProfilesComponent.prototype.lockSelectedLocations = function () {
        var _this = this;
        var anyIsAlreadyLocked = false;
        var observables = this.selectedLocations.map(function (profile) {
            return _this.locationService.isLocked(profile.locationId);
        });
        forkJoin(observables).subscribe(function (results) {
            for (var i = 0; i < results.length; i++) {
                var res = results[i];
                var profile = _this.selectedLocations[i];
                if (res && res.locked) {
                    anyIsAlreadyLocked = true;
                }
            }
            if (anyIsAlreadyLocked) {
                var dialogRef = _this.modalService.openInfoModal("Heads Up", "There is identical location in our network is already locked. Try a different selection or contact support.");
            }
            else {
                _this.modalService.openConfirmModal('Are you sure you want to lock these locations?', 'Locking a location will periodically update GBP with the locked data. Any changes you make directly in GBP will be overwritten.', function (res) {
                    if (!res) {
                        return;
                    }
                    var observables = _this.selectedLocations.map(function (profile) {
                        return _this.locationService.update(_this.authService.session.gid, profile.accountId, profile.locationId, { lockedOn: new Date() }).pipe(switchMap(function (loc) {
                            return _this.googleService.saveLockHistory(profile.accountId, profile.locationId, 'locked', 'success');
                        }));
                    });
                    forkJoin(observables).subscribe(function () {
                        _this.loadProfilesTable();
                        _this.snackbarService.openSuccess('Locations successfully locked!', 2000);
                    }, function (err) {
                        _this.loadProfilesTable();
                        _this.snackbarService.openError('There was a problem locking these locations. Please try again later or contact support.', 2000);
                        console.error('Error locking a location', err);
                    });
                }, 2);
            }
        });
    };
    ProfilesComponent.prototype.lockLocation = function (profile) {
        var _this = this;
        this.locationService.isLocked(profile.locationId)
            .subscribe(function (res) {
            if (res && res.locked) {
                var dialogRef = _this.modalService.openInfoModal("Heads Up", "An identical location in our network is already locked. Please contact support.");
            }
            if (res && !res.locked) {
                // this.progress = true;
                _this.modalService.openConfirmModal('Are you sure you want to lock this location?', 'Locking a location will periodically update GBP with the locked data. Any changes you make directly in GBP will be overwritten.', function (res) {
                    if (!res) {
                        return;
                    }
                    _this.locationService.update(_this.authService.session.gid, profile.accountId, profile.locationId, { lockedOn: new Date() }).toPromise().then(function (loc) {
                        _this.googleService.saveLockHistory(profile.accountId, profile.locationId, 'locked', 'success').subscribe(function (result) {
                            _this.loadProfilesTable();
                            _this.snackbarService.openSuccess('Location successfully locked!', 2000);
                        });
                    }, function (err) {
                        _this.snackbarService.openError('There was a problem locking this location. Please try again later or contact support.', 2000);
                        console.error('Error locking a location', err);
                    });
                }, 2);
            }
        });
    };
    ProfilesComponent.prototype.unlockLocation = function (profile) {
        var _this = this;
        this.locationService.update(this.authService.session.gid, profile.accountId, profile.locationId, { lockedOn: null })
            .toPromise()
            .then(function (loc) {
            _this.googleService.saveLockHistory(profile.accountId, profile.locationId, 'unlock', 'success')
                .subscribe(function (result) {
                _this.loadProfilesTable();
                _this.snackbarService.openSuccess('Location successfully unlocked!', 2000);
            });
        }, function (err) {
            _this.snackbarService.openError('There was a problem unlocking this location. Please try again later or contact support.', 2000);
            console.error('Error unlocking a location', err);
        });
    };
    ProfilesComponent.prototype.deleteBulk = function () {
        var _this = this;
        if (this.selectedLocations.length === 0) {
            return;
        }
        this.spinnerService.loading$.next(true);
        var deleteObservables = this.selectedLocations.map(function (location) {
            return _this.deleteLocation(location);
        });
        forkJoin(deleteObservables).subscribe(function () {
            var completedNotifications$ = _this.selectedLocations.map(function (location) {
                var meta = _this.notificationService.getMetaTypeLog(TYPE_LOG_LOCATION, location);
                var notification = _this.notificationService.saveNotification(_this.authService.session.gid, _this.wl.baseDomain, string_message(Messages.notifications.LOCATION_TOGGLE, [location.locationName, location.address, DELETE_DATA]), NOTIFICATION_GENERAL, TYPE_LOG_LOCATION, meta);
                return notification;
            });
            forkJoin(completedNotifications$).subscribe(function () {
                _this.spinnerService.loading$.next(false);
                _this.loadAccounts();
            });
        }, function (err) {
            _this.spinnerService.loading$.next(false);
            console.error('Error deleting locations', err);
        });
    };
    ProfilesComponent.prototype.deleteLocation = function (location) {
        if (location.address) {
            location.formatAddress = this.locationService.formatAddress(location.address);
        }
        else {
            location.formatAddress = '';
        }
        var deleteReferences$ = of(this.locationService.deleteReferencesToLocation(this.user.gid, location.locationId));
        var deleteLocation$ = from(this.locationService.deleteLocation(this.user.gid, location.locationId, location.accountId));
        return deleteReferences$.pipe(switchMap(function () { return deleteLocation$; }), catchError(function (error) {
            console.error('Error deleting location', error);
            return throwError(error);
        }));
    };
    ProfilesComponent.prototype.deleteLocationModal = function (location) {
        var _this = this;
        if (location) {
            this.modalService.openConfirmModal("Are you sure you want to delete " + location.locationName + " ?", 'This action cannot be reverted.', function (res) {
                if (!res) {
                    return;
                }
                _this.deleteLocation(location).subscribe(function () {
                    _this.spinnerService.loading$.next(false);
                    _this.loadAccounts();
                });
            }, 2);
        }
    };
    ProfilesComponent.prototype.onMatDrawerOpenedChange = function (event) {
        this.drawerOpened = event;
    };
    ProfilesComponent.prototype.toggleDrawer = function () {
        this.drawer.toggle();
        this.drawerOpened = this.drawer.opened;
    };
    ProfilesComponent.prototype.addPhotos = function () {
        this.router.navigate(['local-bulk']);
    };
    ProfilesComponent.prototype.goToLocation = function (location) {
        this.router.navigate(['/account', location.accountId, 'location', location.locationId, 'insights']);
    };
    ProfilesComponent.prototype.goToLocationNewTab = function (location) {
        var url = this.router.serializeUrl(this.router.createUrlTree(['/account', location.accountId, 'location', location.locationId, 'insights']));
        window.open(url, '_blank');
    };
    ProfilesComponent.prototype.editLocation = function (element) {
    };
    ProfilesComponent.prototype.handleReload = function ($event) {
        this.paginate = $event;
        this.loadProfilesTable();
    };
    ProfilesComponent.prototype.filterAccountChanged = function (menu, clear) {
        if (clear || this.filteredAccounts.length === 0) {
            this.accounts.forEach(function (acc) { return acc.checked = false; });
            this.labelAccountsFiltered = 'All';
            this.filteredAccounts = [];
        }
        this.filterAccountsControl.setValue(null);
        menu.close.next(false);
        var resetPaginator = true;
        this.loadProfilesTable(resetPaginator);
    };
    ProfilesComponent.prototype._filter = function (value) {
        var _this = this;
        var _a, _b;
        this.accountsOptions = [];
        var filterValue = (_a = value) === null || _a === void 0 ? void 0 : _a.toLowerCase();
        if (value != '') {
            (_b = this.accounts) === null || _b === void 0 ? void 0 : _b.forEach(function (acc) {
                var name = acc.accountName.toLowerCase();
                if (name.includes(filterValue)) {
                    _this.accountsOptions.push(acc);
                }
            });
        }
        else {
            this.accountsOptions = __spread(this.accounts);
        }
    };
    ProfilesComponent.prototype.getLocationsFiltered = function (event, account) {
        var _this = this;
        this.filteredAccounts = [];
        this.labelAccountsFiltered = null;
        this.accounts.forEach(function (acc) {
            acc.checked = acc.accountId == account.account ? event.checked : acc.checked;
            if (acc.checked) {
                _this.filteredAccounts.push(acc);
                _this.labelAccountsFiltered = _this.labelAccountsFiltered ? _this.labelAccountsFiltered + " - " + acc.accountName : acc.accountName;
            }
        });
    };
    ProfilesComponent.prototype.checkAllAccounts = function (event) {
        this.googleAccounts.forEach(function (element) {
            element.checked = event.checked;
        });
    };
    ProfilesComponent.prototype.checkProfiles = function (event, locations) {
        locations.forEach(function (element) {
            element.checked = event.checked;
        });
    };
    ProfilesComponent.prototype.hasAnySelectedAccount = function () {
        var result = false;
        this.googleAccounts.forEach(function (element) {
            if (element.checked) {
                result = true;
            }
        });
        return result;
    };
    ProfilesComponent.prototype.hasAnySelectedProfile = function () {
        var result = false;
        this.googleAccounts.forEach(function (element) {
            var _a, _b;
            (_b = (_a = element) === null || _a === void 0 ? void 0 : _a.profiles) === null || _b === void 0 ? void 0 : _b.forEach(function (profile) {
                if (profile.checked) {
                    result = true;
                }
            });
        });
        return result;
    };
    ProfilesComponent.prototype.openAccountExpansionPanel = function (account) {
        var _this = this;
        this.groupService.get_locations(this.authService.session.gid, account.accountId)
            .subscribe(function (response) {
            var _a;
            if (response) {
                (_a = _this.googleAccounts) === null || _a === void 0 ? void 0 : _a.forEach(function (option) {
                    if (!option.locations) {
                        option.locations = [];
                    }
                    if (option.locations) {
                        response.forEach(function (r) {
                            if (option.accountId === r.accountId) {
                                option.locations.push(r);
                            }
                        });
                    }
                });
            }
        });
    };
    ProfilesComponent.prototype.addNewProfiles = function (accountId) {
        if (accountId === void 0) { accountId = null; }
        return __awaiter(this, void 0, void 0, function () {
            var data, oauth_1, popupTick_1, e_2, message;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.da = null;
                        this.dl = null;
                        this.googleAccountService.onLoadAll
                            .pipe(takeUntil(this.subsManager$))
                            .subscribe(function (e) {
                            if (e.success !== true) {
                                var msg = '';
                                switch (e.details.status) {
                                    case 901:
                                        msg = 'Oops! There was an error in the application ';
                                        break;
                                    case 401:
                                        msg = 'Google said you are not authorized! - Try again';
                                        break;
                                    default:
                                        msg = 'Some odd error happened. Try again.';
                                }
                                return _this.snackbarService.openError(msg, 4000);
                            }
                            // Everything looks good...
                            // Prevent multiple dialogs from being opened
                            !_this.da && _this.toggleDrawer();
                            _this.googleAccountService.accounts.subscribe(function (accounts) {
                                accounts.forEach(function (element) {
                                    element.checked = false;
                                });
                                _this.googleAccounts = accounts;
                            });
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        this.snackbarService.openInfo("A tab to authenticate with Google will open. If you don't see it, check your pop-up blocker settings", 2000);
                        return [4 /*yield*/, this.googleService.authenticate(this.user.gid, this.user.uid, null)];
                    case 2:
                        data = _a.sent();
                        oauth_1 = window.open(data, '_blank');
                        popupTick_1 = setInterval(function () {
                            var _a;
                            if ((_a = oauth_1) === null || _a === void 0 ? void 0 : _a.closed) {
                                clearInterval(popupTick_1);
                                _this.googleAccountService.loadAll();
                            }
                        }, 1000);
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _a.sent();
                        message = 'There was an error with the GBP Authentication service';
                        console.error(message, e_2);
                        return [2 /*return*/, this.snackbarService.openError(message, 4000)];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ProfilesComponent.prototype.loadStep2Profiles = function () {
        var _this = this;
        this.profilesOptions = [];
        this.googleAccounts.forEach(function (account) {
            if (account.checked) {
                _this.googleLocationService.loadAll(_this.user, account.name);
            }
        });
        this.googleLocationService.locations.subscribe(function (locations) {
            var accountName = '';
            if (locations && locations.length > 0 && locations[0] && locations[0].name) {
                var _a = __read(locations[0].name.split('/'), 2), firstPart = _a[0], secondPart = _a[1];
                if (firstPart && secondPart) {
                    accountName = firstPart + "/" + secondPart;
                }
            }
            var account = _this.googleAccounts.find(function (_) { return _.name === accountName; });
            if (account) {
                account.profiles = locations;
            }
        });
    };
    ProfilesComponent.prototype.checkedGoogleAccounts = function () {
        if (this.googleAccounts && this.googleAccounts.length) {
            return this.googleAccounts.filter(function (_) { return _.checked; });
        }
        return [];
    };
    ProfilesComponent.prototype.addProfiles = function () {
        var _this = this;
        this.addingProfile = true;
        var request = {
            profiles: []
        };
        this.googleAccounts.forEach(function (account) {
            var _a, _b;
            if (account.checked) {
                var accountId_1 = account.name.split('/')[1];
                var profileModel_1 = new ProfileModel();
                var accountContainer = new AccountContainer();
                accountContainer.gid = _this.authService.session.gid;
                accountContainer.accountId = accountId_1;
                accountContainer.accountName = account.accountName;
                accountContainer.account =
                    {
                        name: account.name,
                        accountName: account.accountName,
                        type: account.type,
                        role: account.role,
                        verificationState: account.verificationState,
                        vettedState: account.vettedState,
                        accountNumber: account.accountNumber,
                        permissionLevel: account.permissionLevel,
                    };
                accountContainer.googleAuthEmailAddress = _this.authService.session.email;
                accountContainer.gauthStatus = { isValid: true };
                accountContainer.googleAuth = _this.authService.session.googleAuth;
                profileModel_1.account = accountContainer;
                profileModel_1.locations = [];
                (_b = (_a = account) === null || _a === void 0 ? void 0 : _a.profiles) === null || _b === void 0 ? void 0 : _b.forEach(function (profile) {
                    if (profile.checked) {
                        var locationContainer = new LocationModel();
                        locationContainer.accountId = accountId_1;
                        locationContainer.gid = _this.authService.session.gid;
                        locationContainer.locationId = profile.name.split('/')[3];
                        locationContainer.locationName = profile.locationName;
                        locationContainer.lockedOn = null;
                        locationContainer.subscriptionType = LOCATION_SUBSCRIPTION_TYPE.FREE;
                        profileModel_1.locations.push(locationContainer);
                    }
                });
                request.profiles.push(profileModel_1);
            }
        });
        this.profilesService.createProfile(request)
            .subscribe(function (response) {
            _this.addingProfile = false;
            _this.toggleDrawer();
            _this.loadAccounts();
        });
    };
    return ProfilesComponent;
}());
export { ProfilesComponent };
