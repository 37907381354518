<div class="box box--rounded box--shadow bg--white box--padding-sm mb--30">
  <div class="row align-items-center">
    <div class="col-12 col-md-6 pt--20 mt--15">
      <ngx-skeleton-loader count="1" [theme]="{ width: '150px', height: '50px' }"></ngx-skeleton-loader>
      <ngx-skeleton-loader count="1" [theme]="{ width: '350px', height: '15px', display: 'block', 'margin-top': '5px' }"></ngx-skeleton-loader>
      <ngx-skeleton-loader count="1" [theme]="{ width: '155px', height: '15px', display: 'block', 'margin-top': '30px' }"></ngx-skeleton-loader>

      <div class="d-flex justify-content-start align-items-center mb--20">
        <ngx-skeleton-loader count="1" [theme]="{ width: '180px', height: '46px', 'margin-top': '10px', display: 'block' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" [theme]="{ width: '180px', height: '46px', 'margin-left': '10px', 'margin-top': '10px', display: 'block' }"></ngx-skeleton-loader>
      </div>
    </div>
    <div class="col-12 col-md-6 txt--center">
      <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '235px', height: '235px' }"></ngx-skeleton-loader>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12 col-md-6 box--height mb--30" *ngFor="let i of [0,1,2,3]">

    <div class="box box--padding-sm box--shadow box--rounded bg--white">
      <div class="d-flex justify-content-between align-items-center mb--10">
        <ngx-skeleton-loader count="1" [theme]="{ width: '150px', height: '30px' }"></ngx-skeleton-loader>
        <div class="box-statistics">
          <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '30px' }"></ngx-skeleton-loader>
        </div>
      </div>

      <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '5px' }"></ngx-skeleton-loader>

      <div class="tag-wrapper">
        <ngx-skeleton-loader count="1" [theme]="{ width: '200px', height: '30px', 'margin-right': '7px' }" *ngFor="let j of [0,1,2,3,4,5]"></ngx-skeleton-loader>
      </div>
    </div>

  </div>
</div>

