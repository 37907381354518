/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard-services.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../dashboard-filter/dashboard-filter.component.ngfactory";
import * as i3 from "../dashboard-filter/dashboard-filter.component";
import * as i4 from "@angular/router";
import * as i5 from "../../services/location.service";
import * as i6 from "../../services/auth.service";
import * as i7 from "../../services/modal.service";
import * as i8 from "../dashboard-location/services-location/services-location.component.ngfactory";
import * as i9 from "../dashboard-location/services-location/services-location.component";
import * as i10 from "@angular/material/dialog";
import * as i11 from "../../services/google.service";
import * as i12 from "../../services/snackbar.service";
import * as i13 from "./dashboard-services.component";
var styles_DashboardServicesComponent = [i0.styles];
var RenderType_DashboardServicesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardServicesComponent, data: {} });
export { RenderType_DashboardServicesComponent as RenderType_DashboardServicesComponent };
export function View_DashboardServicesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { ServicesComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-dashboard-filter", [["typeDashboard", "services"]], null, [[null, "ClickEvent"], [null, "UpdateServices"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ClickEvent" === en)) {
        var pd_0 = (_co.dispatchAction($event) !== false);
        ad = (pd_0 && ad);
    } if (("UpdateServices" === en)) {
        var pd_1 = (_co.updateServicesList($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_DashboardFilterComponent_0, i2.RenderType_DashboardFilterComponent)), i1.ɵdid(2, 114688, null, 0, i3.DashboardFilterComponent, [i4.Router, i4.ActivatedRoute, i5.LocationService, i6.AuthService, i7.ModalService], { typeDashboard: [0, "typeDashboard"], accountId: [1, "accountId"], progress: [2, "progress"], locationId: [3, "locationId"], isProgressSave: [4, "isProgressSave"] }, { ClickEvent: "ClickEvent", UpdateServices: "UpdateServices" }), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "container--full"], ["style", "margin-top: 65px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-services-location", [], null, null, null, i8.View_ServicesLocationComponent_0, i8.RenderType_ServicesLocationComponent)), i1.ɵdid(5, 114688, [[1, 4], ["services", 4]], 0, i9.ServicesLocationComponent, [i10.MatDialog, i5.LocationService, i11.GoogleService, i12.SnackbarService, i4.ActivatedRoute, i6.AuthService], { serviceList: [0, "serviceList"], primaryCategory: [1, "primaryCategory"], aditionalCategories: [2, "aditionalCategories"], accountId: [3, "accountId"], locationId: [4, "locationId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "services"; var currVal_1 = _co.accountId; var currVal_2 = _co.progress; var currVal_3 = _co.locationId; var currVal_4 = _co.isUpdatedSave; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.serviceList; var currVal_6 = _co.primaryCategory; var currVal_7 = _co.aditionalCategories; var currVal_8 = _co.accountId; var currVal_9 = _co.locationId; _ck(_v, 5, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, null); }
export function View_DashboardServicesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dashboard-services", [], null, null, null, View_DashboardServicesComponent_0, RenderType_DashboardServicesComponent)), i1.ɵdid(1, 114688, null, 0, i13.DashboardServicesComponent, [i4.ActivatedRoute, i11.GoogleService, i12.SnackbarService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardServicesComponentNgFactory = i1.ɵccf("app-dashboard-services", i13.DashboardServicesComponent, View_DashboardServicesComponent_Host_0, {}, {}, []);
export { DashboardServicesComponentNgFactory as DashboardServicesComponentNgFactory };
