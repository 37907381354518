import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {MatDialogRef} from '@angular/material/dialog';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import User from '../../../constants/firestore/user';
import {UserService} from '../../../services/user.service';
import AccountReport from '../../../services/account-report';

@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-setting-dialog.component.html',
  styleUrls: []
})
export class UserSettingDialogComponent implements OnDestroy, OnInit {

  user: User;
  users: User[];
  accountsSelected;
  subsManager$ = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: any,
    public dialogRef: MatDialogRef<UserSettingDialogComponent>,
    public usersService: UserService,
  ) {
    this.user = this.data.user;
    if (this.user.accounts) {
      this.accountsSelected = this.user.accounts;
    }
  }

  ngOnInit() {
    this.usersService.users
      .pipe(takeUntil(this.subsManager$))
      .subscribe(response => {
        this.users = response;
      });
  }

  ngOnDestroy(): void {
    this.subsManager$.next(true);
    this.subsManager$.complete();
  }

  async save() {
    if (this.accountsSelected) {
      await this.usersService.updateUser(this.user.gid, this.user.uid, {accounts: this.accountsSelected});
      this.dialogRef.close({
        accounts: this.accountsSelected
      });
    }
  }

  selectedLocations($event: AccountReport[]) {
    this.accountsSelected = $event;
  }

}
