import { __awaiter, __generator, __read, __spread, __values } from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { /*ActivatedRoute,*/ Router } from '@angular/router';
import * as _ from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { GroupService } from '../../services/group.service';
import { DomainService } from '../../services/domain.service';
import { NotificationService } from '../../services/notification.service';
// import {SnackbarService} from '../../services/snackbar.service';
import { LocationService } from '../../services/location.service';
import { ModalService } from '../../services/modal.service';
import { environment } from '@environment';
import { NOTIFICATION_ERROR_FETCH_DATA, NOTIFICATION_ERROR_LOCATION, NOTIFICATION_WARNING, NOTIFICATION_GENERAL, NOTIFICATION_PROTOCOLS } from '../../constants/notifications';
// import { GoogleService } from '../../services/google.service';
import { MatDialog } from '@angular/material';
import { ModalFetchComponent } from '../modal-fetch/modal-fetch.component';
import { GlobalSearchService } from '../../services/global-search.service';
import { AccountService } from '../../services/account.service';
import { takeUntil } from 'rxjs/operators';
var FlexSearch = require('flexsearch');
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(
    // TODO: commented out services are not used, remove if not needed
    // private googleS: GoogleService,
    // private snack: SnackbarService,
    // private route: ActivatedRoute,
    // Public attributes for services
    auth, domainService, dialog, 
    // Pivate attributes for services
    _router, _groupS, _notificationS, _locationS, _globalSearchService, _accountS, _modalService) {
        var _this = this;
        var _a;
        this.auth = auth;
        this.domainService = domainService;
        this.dialog = dialog;
        this._router = _router;
        this._groupS = _groupS;
        this._notificationS = _notificationS;
        this._locationS = _locationS;
        this._globalSearchService = _globalSearchService;
        this._accountS = _accountS;
        this._modalService = _modalService;
        this.fetchProgress = false;
        // NOTIFICATIONS TYPES
        this.fetchLocationInfoData = NOTIFICATION_ERROR_FETCH_DATA;
        this.authFail = NOTIFICATION_ERROR_LOCATION;
        this.oldAuthFail = NOTIFICATION_WARNING;
        this.informative = NOTIFICATION_GENERAL;
        this.type_protocol = NOTIFICATION_PROTOCOLS;
        this.page = 1;
        this.size = 5;
        this.loadingNotifications = new BehaviorSubject(false);
        this.paginate = { size: this.size, page: this.page };
        this.previousPageable = { page: 1, size: 5 };
        this.loadingNoti = false;
        this.pagination = {
            items: [],
            per_page: this.size,
            page: this.page,
            hasNext: false,
            hasPrev: false,
            pages: 0,
            total: 0
        };
        this.sidenavToggle = new EventEmitter();
        this.flexSearch = new FlexSearch({
            encode: "advanced",
            tokenize: "reverse",
            suggest: true,
            cache: true,
            doc: {
                id: 'id',
                field: [
                    'accountName',
                    'locationName',
                    'locName',
                    'address',
                    'reportName',
                    'labels'
                ]
            }
        });
        this.selectedSearch = new FormControl();
        this.isUser = false;
        this.countNotification = 0;
        this.notifications = [];
        this.searchSubject = new Subject();
        this.isLoading = true;
        this.showChromeWarning = false;
        this.bulkActionsEnabled = false;
        this._destroy$ = new Subject();
        // TODO: referenced from a commented-out section on header.component.html, obsolete? if yes remove.
        // viewed(n): void {
        //   switch (n.type) {
        //     case 'protocols':
        //       this.router.navigate(['/review-assistant']).then();
        //       break;
        //     case 'warning':
        //       this.router.navigate([`/accounts/${n.accountId}/locations`]).then();
        //       break;
        //     case NOTIFICATION_ERROR_LOCATION:
        //       this.router.navigate([`/accounts/${n.accountId}/locations`]).then();
        //       break;
        //     case NOTIFICATION_ERROR_FETCH_DATA:
        //       this.fetchProgress = true;
        //       this.googleS.fetchDifference(n.accountId, n.location.locationId).take(1).subscribe(result => {
        //         this.fetchProgress = false;
        //         this.openFetchDialog(result, n.accountId, n.location.locationId, true);
        //       }, error => {
        //         this.fetchProgress = false;
        //         this.snack.openError('failed to fetch', 4000);
        //       });
        //       break;
        //     case NOTIFICATION_GENERAL:
        //       break;
        //     default:
        //       this.router.navigate(['/account', n.accountId, 'location', n.location.locationId, 'workshop']).then();
        //       break;
        //   }
        //   // this.notificationS.merge(this.auth.session.gid, n.id, {status: 'viewed'}).then(() => {
        //   //
        //   // });
        // }
        this.onToggleSidenav = function () {
            _this.sidenavToggle.emit();
        };
        this.user = this.auth.session;
        this.env = environment;
        this.isShared = _.endsWith(this._router.url, 'shared');
        this._untilDestroy(this.auth.subscription$).subscribe(function (sub) {
            var _a, _b, _c, _d;
            _this.subscription = sub;
            _this.bulkActionsEnabled = ((((_a = _this.subscription) === null || _a === void 0 ? void 0 : _a.pricingVersion) < 3) || (((_b = _this.subscription) === null || _b === void 0 ? void 0 : _b.pricingVersion) >= 3 && ((_d = (_c = _this.subscription) === null || _c === void 0 ? void 0 : _c.packages) === null || _d === void 0 ? void 0 : _d.hasOwnProperty('pkg_bulk_actions'))));
            console.log('Subscription in header:', _this.subscription);
            console.log('Bulk actions: ', _this.bulkActionsEnabled ? "enabled" : "disabled");
        });
        if (!((_a = this.auth.session) === null || _a === void 0 ? void 0 : _a.gid)) {
            return;
        }
        this._groupS.getIndexes(this.auth.session.gid).subscribe(function (result) {
            var e_1, _a;
            if (!result || result.length === 0) {
                return;
            }
            var _loop_1 = function (index) {
                index.id = JSON.stringify(index);
                _.has(index, 'address') && (index.address = _this._locationS.formatAddress(index.address));
                if (_this.auth.session.role && !_this.auth.isAdmin) {
                    if (index.type !== 'location' && index.type !== 'account') {
                        return "continue";
                    }
                    if (index.type === 'account') {
                        var accounts = _this.auth.session.accounts;
                        var findAccount = accounts.find(function (it) { return it.accountId === index.source; });
                        if (!findAccount) {
                            return "continue";
                        }
                    }
                    else {
                        var dataLoc = index.source.split('/');
                        var accountId_1 = dataLoc[0];
                        var locationId_1 = dataLoc[1];
                        var accounts = _this.auth.session.accounts;
                        var findAccount = accounts.find(function (it) { return it.accountId === accountId_1; });
                        if (!findAccount) {
                            return "continue";
                        }
                        var findLoc = findAccount.locations.find(function (it) { return it.locationId === locationId_1; });
                        if (!findLoc) {
                            return "continue";
                        }
                    }
                }
                _this.addDoc(index);
            };
            try {
                for (var result_1 = __values(result), result_1_1 = result_1.next(); !result_1_1.done; result_1_1 = result_1.next()) {
                    var index = result_1_1.value;
                    _loop_1(index);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (result_1_1 && !result_1_1.done && (_a = result_1.return)) _a.call(result_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            _this.isUser = true;
        });
    }
    HeaderComponent.prototype._untilDestroy = function (o) {
        return o.pipe(takeUntil(this._destroy$));
    };
    HeaderComponent.prototype.capitalize = function (str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    HeaderComponent.prototype.ngOnDestroy = function () {
        this._destroy$.next(true);
        this._destroy$.unsubscribe();
    };
    HeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        var _a;
        if (!((_a = this.auth.session) === null || _a === void 0 ? void 0 : _a.gid)) {
            return;
        }
        this.searchSubject.pipe(debounceTime(500)).subscribe(function (result) {
            _this.search(result);
        });
        if (!this.auth.session) {
            return;
        }
        /*this.notificationS.countNotifications(this.auth.session.gid).subscribe(count => {
          this.countNotification = count;
        });*/
        //this.getNotifications(this.paginate);
        if (this.isReport) {
            return;
        }
    };
    HeaderComponent.prototype.getNotifications = function (paginate) {
        var _this = this;
        var last = null;
        var next = null;
        if (this.previousPageable) {
            if (this.previousPageable.page < paginate.page) {
                if (this.notifications) {
                    last = this.notifications[(paginate.size * (paginate.page - 1)) - 1];
                }
            }
            else if (this.previousPageable.page > paginate.page) {
                if (this.notifications) {
                    next = this.notifications[0];
                }
            }
        }
        this._notificationS.paginateNotifications(this.auth.session.gid, paginate, last, next).subscribe(function (result) {
            var _a;
            _this.loadingNoti = false;
            (_a = _this.notifications).push.apply(_a, __spread(result.items));
            _this.countNotification = result.total;
            _this.pagination = result;
            _this.previousPageable = { size: result.per_page, page: result.page };
            _this.loadingNotifications.next(false);
        });
    };
    HeaderComponent.prototype.getReportTypeString = function (input) {
        var data = {
            'keyword': 'Keyword',
            'qanda': 'Questions and Answers',
            'review': 'Review',
            'rollup': 'Performand Rollup',
            'revenue': 'Revenue',
            'performance-revenue': 'Performance Revenue',
            'performance-rollup': 'Performance Rollup',
            'performance-comparison': 'Performance Comparison',
            'comparison': 'Comparison',
            'grade': 'Grade',
        };
        return data[input] ? data[input] : input;
    };
    HeaderComponent.prototype.clearAllNotifications = function () {
        this._notificationS.deleteAll(this.auth.session.gid);
    };
    HeaderComponent.prototype.deleteAll = function () {
        this._notificationS.deleteAll(this.auth.session.gid);
    };
    HeaderComponent.prototype.deleteNotification = function (n) {
        this._notificationS.deleteNotification(this.auth.session.gid, n.id);
    };
    HeaderComponent.prototype.openFetchDialog = function (result, accountId, locationId, notifyErrors) {
        var _this = this;
        if (notifyErrors === void 0) { notifyErrors = false; }
        var dialogRef = this.dialog.open(ModalFetchComponent, {
            width: '1000px',
            data: {
                difference: result.difference,
                placeId: locationId,
                history: null,
                notifyErrors: notifyErrors
            }
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe(function (refresh) {
            if (refresh) {
                _this._router.navigate(['/account', accountId, 'location', locationId, 'location-info']).then();
            }
        });
    };
    HeaderComponent.prototype.handleReload = function (e) {
        e.preventDefault();
        if (!this.loadingNoti) {
            var $event = {
                page: (this.paginate.page + 1),
                size: 5
            };
            this.loadingNoti = true;
            this.paginate = $event;
            //this.getNotifications($event);
        }
    };
    HeaderComponent.prototype.handleLogout = function () {
        this.auth.signOut(true, true);
    };
    HeaderComponent.prototype.addDoc = function (doc) {
        this.flexSearch.add(doc);
    };
    HeaderComponent.prototype.handleChangeSearch = function ($event) {
        this.searchSubject.next($event);
    };
    HeaderComponent.prototype.search = function ($event) {
        var _this = this;
        var text = $event.target.value.toLowerCase();
        if (!text) {
            this.results = [];
            return;
        }
        this._globalSearchService.search(text, this.auth.session.gid).subscribe(function (result) {
            _this.results = result;
        });
    };
    HeaderComponent.prototype.display = function (value) {
        if (!value) {
            return null;
        }
        switch (value.type) {
            case 'account':
                return "" + value.accountName;
            case 'location':
                return value.locationName + " ";
            case 'report':
                return value.type + ": " + value.reportName;
        }
    };
    HeaderComponent.prototype.validateVerifiedStatus = function (location) {
        var _a, _b;
        if (((_b = (_a = location) === null || _a === void 0 ? void 0 : _a.locationState) === null || _b === void 0 ? void 0 : _b.isVerified) == false) {
            this._modalService.openErrorModal("Verification required in Google Business Profile", "<div class='txt--left'>\n          This location requires verification, which prevents access to your dashboard.\n          <div>\n            <br>Step 1: Login to your Google Business Profile and verify your location.\n            <br>Step 2: Once verified, return to Map Labs and click the refresh button.\n          </div>\n        </div>");
            return false;
        }
        return true;
    };
    HeaderComponent.prototype.selected = function () {
        var _a;
        var value = this.selectedSearch.value;
        if (((_a = value) === null || _a === void 0 ? void 0 : _a.type) == "location" && !this.validateVerifiedStatus(value)) {
            return;
        }
        var url = '';
        switch (value.type) {
            case 'account':
                url = "/accounts/" + value.accountId + "/locations";
                break;
            case 'location':
                url = "/account/" + value.accountId + "/location/" + value.locationId + "/insights";
                break;
            case 'report':
                url = "/report/" + value.gid + "/" + value.reportId + "/" + value.reportType;
                break;
        }
        this._router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
        this._router.navigate([url]);
    };
    HeaderComponent.prototype.goToAdmin = function () {
        window.open('https://admin.maplabs.com/', '_blank', 'noopener');
    };
    HeaderComponent.prototype.goToProfiles = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var accounts;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this._router.url.includes('share')) return [3 /*break*/, 1];
                        this._router.navigate(['/']);
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this._accountS.getAccountPaginate(this.user.gid, { page: 1, size: 5 }, [])];
                    case 2:
                        accounts = (_b.sent()).items;
                        this._router.navigate([accounts.length > 0 ?
                                "accounts/" + ((_a = accounts[0]) === null || _a === void 0 ? void 0 : _a.accountId) + "/locations" :
                                'accounts'
                        ]);
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return HeaderComponent;
}());
export { HeaderComponent };
