import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-location-info-list',
  templateUrl: './skeleton-location-info-list.component.html',
  styleUrls: ['./skeleton-location-info-list.component.scss']
})
export class SkeletonLocationInfoListComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

}
