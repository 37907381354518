/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./grade-explain-article.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./grade-explain-article.component";
var styles_GradeExplainArticleComponent = [i0.styles];
var RenderType_GradeExplainArticleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GradeExplainArticleComponent, data: {} });
export { RenderType_GradeExplainArticleComponent as RenderType_GradeExplainArticleComponent };
function View_GradeExplainArticleComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "li", [["class", "tag-wrapper m--0 pt--0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "span", [["class", "tag tag--error"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "tag__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fas fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "tag__content p--10 p--5 fw--regular"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", " Points"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sub; _ck(_v, 5, 0, currVal_0); }); }
export function View_GradeExplainArticleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "article", [["class", "mb--15 mt--15"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "fw--medium mb--15"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 8, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "li", [["class", "tag-wrapper m--0 pt--0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "span", [["class", "tag tag--success"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "tag__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "fas fa-check"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "tag__content p--10 fw--regular"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", " Points"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GradeExplainArticleComponent_1)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.customLabel == "Yes"); _ck(_v, 11, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.add; _ck(_v, 9, 0, currVal_1); }); }
export function View_GradeExplainArticleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-grade-explain-article", [], null, null, null, View_GradeExplainArticleComponent_0, RenderType_GradeExplainArticleComponent)), i1.ɵdid(1, 114688, null, 0, i3.GradeExplainArticleComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GradeExplainArticleComponentNgFactory = i1.ɵccf("app-grade-explain-article", i3.GradeExplainArticleComponent, View_GradeExplainArticleComponent_Host_0, { title: "title", add: "add", sub: "sub", customLabel: "customLabel" }, {}, []);
export { GradeExplainArticleComponentNgFactory as GradeExplainArticleComponentNgFactory };
