import { ChangeDetectorRef } from '@angular/core';
// dep
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { forkJoin, Observable, Subject, Subscription as subs } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// app
import { SelectedDatePicker } from '../date-picker/date-picker.component';
import { ISubscription } from '../../constants/subscription';
import { DataPicker } from '../../constants/data-picker';
import Report, { REPORT_TYPE } from '../../constants/firestore/report';
import { AuthService } from '../../services/auth.service';
import { EventMessageService } from '../../services/event-message.service';
import { ReportService } from '../../services/report.service';
import { SubscriptionService } from '../../services/subscription.service';
import { LocationService } from '../../services/location.service';
import { ModalService } from "../../services/modal.service";
import { DatesService } from '../../services/dates.service';
import { InsightsService } from '../../services/insights.service';
import { LoadingService } from '../../services/loading.service';
import { SnackbarService } from '../../services/snackbar.service';
import { ChartService } from '../../services/chart.service';
import { ReviewsService } from '../../services/reviews.service';
import { SearchKeywordsService } from '../../services/search-keywords.service';
import { FeaturedDatePickerComponent } from "../../components/featured-datepicker.component";

@Component({
  selector: 'app-dashboard-performance-insight',
  templateUrl: './dashboard-performance-insight.component.html',
  styleUrls:  ['./dashboard-performance-insight.component.scss']
})
export class DashboardPerformanceInsightComponent implements OnInit, OnDestroy {
  public locations: { accountId: string; locationId: any }[];
  public accountId: string;
  public locationId: string | string[];
  public subscription$: Observable<ISubscription>;
  public subscription: ISubscription;
  public isReport: boolean;
  public typeReport: REPORT_TYPE = null;
  public dataPicker: DataPicker;
  public selectDate: SelectedDatePicker;
  public modalSubscription = new Subject<boolean>();
  public locationUpgraded: { accountId: string; locationId: any }[];
  private first = true;
  public multiChartDataSet: any[] = [];
  private insights$: subs;
  public popularStats: any;
  public isProgress = true;
  public stats: any[] = [];
  public viewsStats = {
    title: null,
    totals: 0,
    labels: null,
    items: []
  };
  public labels: string[];
  public isNoData: boolean;
  public isProgressCSV: boolean;
  public reviewsData = {
    data: null,
    labels: null,
    total: 0
  };
  public averageData = {
    data: null,
    labels: null,
    total: 0
  }; 
  public viewModeChecked = 'new';
  public refresh = new Subject();
  public showMultilocations = true;
  public hasFoodOrdering = true;
  public isDatePickerFirstOpened = true;
  public showTooltipsOnDisabledDates = true;

  @Input() reportName: string;
  @Input('dataPicker') reportDataPicker: DataPicker;
  @Input() lockDates = false;
  @Input() checks: boolean;
  @Input() shared = false;
  @Input() reportId: string;
  @Input() minDate = null;
  @Input() maxDate = null;
  @Input() report = null;

  @Output() changeDataPicker = new EventEmitter();

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _auth: AuthService,
    private _reportS: ReportService,
    private _eventMessageS: EventMessageService,
    private _modalS: ModalService,
    private _subscriptionS: SubscriptionService,
    private _locationS: LocationService,
    private _dateS: DatesService,
    private _insightS: InsightsService,
    private _loadingS: LoadingService,
    private _snackS: SnackbarService,
    private _chartS: ChartService,
    private _reviewS: ReviewsService,
    private _searchKeywordsS: SearchKeywordsService,
    private _changeRef: ChangeDetectorRef
  ) {
    this.accountId  = this._route.parent.snapshot.params.accountId;
    this.locationId = this._route.parent.snapshot.params.locationId;
    this.locations = [{ accountId: this.accountId, 
                       locationId: this.locationId }];
    this.subscription$ = this._auth.subscription$;
    this.subscription$.subscribe(subscription => {
      this.subscription = subscription
    });
  }

  async ngOnInit() {
    this.refresh.next(true);

    if (this.reportDataPicker) { // if this exists, then this component has been initialized by ReportsComponent, otherwise it was accessed by router
      this.typeReport = 'rollup-report';
      this.isReport = true;
      this.locationId = this.reportDataPicker.locations; // TODO: Is this correct? Or locationId must be string instead of string[]?
      this.reportDataPicker.aggregation = this._reportS.correctDateAggregate(this.reportDataPicker);
      this.dataPicker = this.reportDataPicker;
      this.selectDate = this._reportS.selectDatesFromDataPicker(this.dataPicker);
      this.locations = this.dataPicker.locations;
      this.reportId = this.reportId || this._route.snapshot.params?.id;
      
      if (this.report?.dynamic) {
        this._modalS.openDynamicReportReminderModal();
      }
      return;

    } else { // accessed by router
      this.typeReport = 'rollup';
      const dateValidations = await this._locationS.getDateValidations(this.typeReport, [this.accountId], [this._auth.session.gid], [this.locationId as string]).toPromise();
      const dates = this._locationS.dateValidation(dateValidations);
      this.minDate = dates.minDate;
      this.maxDate = dates.maxDate;
      const datesFormatted = this._locationS.buildDatepickerDate(this.typeReport, this.maxDate);
      this.selectDate = {
        start: datesFormatted.start.isBefore(this.minDate) ? this.minDate : datesFormatted.start,
        end: datesFormatted.end.isAfter(this.maxDate) ? this.maxDate : datesFormatted.end
      }
    }
    
    this._eventMessageS.getEmitter().pipe(takeUntil(this.modalSubscription)).subscribe(res => {
      if (res.name != "DATEPICKER SUBSCRIPTION") {
        return;
      }

      let dateDismiss = null;
      if(this.subscription.dismissModalDatePicker != undefined){
        if(this.subscription.dismissModalDatePicker instanceof moment){
          dateDismiss = this.subscription.dismissModalDatePicker.toDate() 
        }else{
          dateDismiss = new Date(this.subscription.dismissModalDatePicker as unknown as string)
        }
      } else {
        dateDismiss = new Date();
      }
      
      if (new Date() >= dateDismiss) {
        this._modalS.openModal(FeaturedDatePickerComponent, 
          { 
            accountId: this.accountId,
            locationId: this.locationId
          },
          { onClose : res => {
          if (!res)
            return;
          console.log('DashboardInsightsComponent upgrade suscription');
          this._subscriptionS.flowChangeLocationsPlan(this.subscription, [this.locationUpgraded[0]])

        }});
      } else {
        this.showDatepickerModal();
      }
    });

    // TODO: Here locationId is always a single element array? Note `${[1]}` == "1"
    this._locationS.getRef(this._auth.session.gid, this.accountId, `${this.locationId}`).toPromise().then(
      ref => this.locationUpgraded = [{ accountId: this.accountId, locationId: ref }]);
  
  }

  datepickerSubscription() {
    this._eventMessageS.getEmitter().pipe(takeUntil(this.modalSubscription)).subscribe(res => {
      if (res.name == "DATEPICKER FIRST TIME SUBSCRIPTION" && this.isDatePickerFirstOpened) {
        this.showDatepickerModal();
      }
    });
  }

  handleDataPicker(event: DataPicker) {
    this._changeRef.detectChanges();
    this.refresh.next(true);
    // Init get data
    if (this.isReport && this.first) {
      this.first = false;
      this.getInsightDataReport(event, false);
      return;
    }
    
    if (!this.dataPicker) {
      this.dataPicker = { ...event };
      this.getInsightData(this.dataPicker);
      return;
    }

    // when multiChart Change
    this.dataPicker.multiChart = event.multiChart;
    this.multiChartDataSet = this.handleMultiChart(this.dataPicker);

    // ranges or aggregation changes
    if (this.dataPicker?.range !== event.range || this.dataPicker?.aggregation !== event.aggregation) {

      if (this.dataPicker?.aggregation === 'month') {
        const endMonth   = this._dateS.stringToDate(this.dataPicker?.range?.end).getMonth();
        const startMonth = this._dateS.stringToDate(this.dataPicker?.range?.start).getMonth();
        if (startMonth === endMonth) {
          this.dataPicker.aggregation = 'day';
        }
      }

      this.dataPicker = { ...event };

      if (this.isReport) {
        this.getInsightDataReport(this.dataPicker, true);
      } else {
        this.getInsightData(this.dataPicker);
      }
    }

    this.dataPicker.locations = this.locations;
    this.changeDataPicker.emit(this.dataPicker);
  }

  getInsightData(dataPicker: DataPicker, refresh?: boolean): void {
    if(this.isReport){ 
      return;
    }

    this.resetData();

    const startDateView = moment(dataPicker.range.start, 'YYYY-MM-DD').format('YYYY-MM-DD');
    const endDateView = moment(dataPicker.range.end).format('YYYY-MM-DD');

    forkJoin([
      this._insightS.getPerformanceInsights(this.accountId, this._auth.session.gid, this.locationId, dataPicker).take(2),
      this._reviewS.getRatingsAndReviewsData(this.accountId, this._auth.session.gid, this.locationId, dataPicker).take(2),
      this._searchKeywordsS.getViews(this.locationId, this._auth.session.gid, this.accountId, startDateView, endDateView, this.dataPicker?.aggregation),
    ]).subscribe(
      async res => {
        this.isProgress = false;
        if (res[0]?.labels?.length > 0) {
          this.labels = res[0].labels;
          this.stats = this.orderingStats(res[0].stats);
          this.stats?.forEach(el => el.labels = res[0].labels);
          const foodOrdering = this.stats[1]?.FOOD_ORDERING || null;
          this.hasFoodOrdering = foodOrdering?.hasOwnProperty('display') ? foodOrdering['display'] : false;
          this.buildStats();
          this.buildViewsGraph(res[2]);
          this.multiChartDataSet = this.handleMultiChart(this.dataPicker);
        } else {
          this.isNoData = true;
        }
        this.reviewsData = {
          data: res[1].reviews,
          labels: res[1].dates,
          total: res[1].total_reviews
        };
        this.averageData = {
          data: res[1].avg_rating,
          labels: res[1].dates,
          total: res[1].total_avg
        };
        this._loadingS.reportAdvanced(1, 'Insights')
        refresh && this._snackS.openSuccess('Finished refreshing! ', 2000);
      }, err => {
        this.isNoData = true;
        this.isProgress = false;
      }
    );
  }

  getInsightDataReport(dataPicker, refresh?: boolean){
    this.resetData();
    const startDateView = moment(this._locationS.formatDates(dataPicker.range.start), 'YYYY-MM-DD').format('YYYY-MM-DD');
    const endDateView = moment(this._locationS.formatDates(dataPicker.range.end)).format('YYYY-MM-DD');

    forkJoin([
      this._reportS.getPerformanceRollupReports(this.reportId, dataPicker.aggregation, startDateView, endDateView),
      this._reviewS.getRatingsAndReviewsReportData(this.reportId, dataPicker).take(2),
      this._reportS.getReportsByType(this._auth.session.gid, this.reportId, 'views', startDateView, endDateView, dataPicker?.aggregation),
      this._reportS.getReportDetails(this.reportId)
    ])
    .subscribe( res => {
      const reportParam = {
        isScheduledReport: false, 
        startDatetime: moment(startDateView).utc().toISOString(), 
        endDatetime: moment(endDateView).utc().toISOString(),
        locations: res[3],
        dynamicRange: this.report.dynamicRange,
        reportType: this.typeReport
      } as unknown as Report;
      this.reportDataPicker = this._reportS.reportToDataPicker(reportParam, this.reportId, false, this.viewModeChecked, refresh);
      this.isProgress = false;
        if (res[0]?.labels?.length > 0) {
          this.labels = res[0]?.labels;
          this.stats = this.orderingStats(
            [
              res[0]?.primaryActions, 
              res[0]?.secondaryActions, 
              res[0]?.biewByDevice
            ]
          );
          const foodOrdering = this.stats?.[1]['FOOD_ORDERING'];
          this.hasFoodOrdering = foodOrdering?.hasOwnProperty('display') ? foodOrdering['display'] : false;
          this.stats?.forEach(el => el.labels = res[0].labels);
          this.buildStats();
          this.buildViewsGraph(res[2]);
          if(this.dataPicker){
            this.multiChartDataSet = this.handleMultiChart(this.dataPicker);
          }
        } else {
          this.isNoData = true;
        }
        this.reviewsData = {
          data: res[1]?.reviews,
          labels: res[1]?.dates,
          total: res[1].total_reviews
        };
        this.averageData = {
          data: res[1]?.avg_rating,
          labels: res[1]?.dates,
          total: res[1].total_avg
        };
        this._loadingS.reportAdvanced(1, 'Insights')
    },
    err => {
        this.isNoData = true;
        this.isProgress = false;
      }
    )
  }

  buildStats() {
    this.stats?.forEach(el => {
      let keys = Object.keys(el);
      keys = keys.filter(k => k != 'title' && k != 'totals');
      keys?.forEach( k => {
        const display = Object.keys(el[k]).includes('display') ? el[k].display : true;
        if (display == false)
        delete el[k]
      })
    })
    this.stats = this.stats.filter(s => Object.keys(s).length > 2);
    this._changeRef.detectChanges();
  }

  buildViewsGraph(views){
    const { title, totals, labels, items } = views;
    items?.forEach(el => {el.labels = labels});
    this.viewsStats = { title, totals, labels, items };

    this._changeRef.detectChanges();
  }

  orderingStats(stats) {
    const orderedData = [];

    if(!stats){
      return orderedData
    }

    const order = [
      "Primary Actions",
      "Secondary Actions",
    ];
    order?.forEach(el => {
      const item = stats.find(s => s?.title == el);
      if (item) {
        orderedData.push(item);
      }
    });
    return orderedData;
  }

  private handleMultiChart(dataPicker: DataPicker) {
    return this._chartS.multiCharset(this.stats, dataPicker);
  }

  clearChecked(event) {
    this.multiChartDataSet = [];
    this.checks = event;
  }

  handleExport($event) {
    if ($event === 'csv') {
      this.exportCSV();
    }
  }

  handleRefresh(): void {
    this.getInsightData(this.dataPicker, true);
  }

  async exportCSV() {
    this.isProgressCSV = true;
    let gid, locationId = null;

    if(!this.reportId) {
      gid = this._auth.session.gid;
      locationId = this.locationId;
    }

    await this._reportS.handleExportCsvMongo(this.reportId, gid, this.accountId, locationId, this.dataPicker.range.start, this.dataPicker.range.end, this.dataPicker.aggregation, this.typeReport);
    this.isProgressCSV = false;
  }

  statusChecks(event) {
    this.checks = event;
  }

  hasNoDate() {
    const createdData = this.locationUpgraded ? moment(this.locationUpgraded[0]?.locationId?.createdAt?.$date) : null;
    const hasMoreThan24hs =  createdData ? this._insightS.hasMoreThan24hs(createdData) : true;

    return hasMoreThan24hs ? 'No performance insights data' : 'The performance insights for this business profile are still being imported, please refresh and check again in 10 minutes';
  }
  
  private resetData() {
    this.labels = [];
    this.stats = [];
    this.isProgress = true;
  }

  ngOnDestroy(): void {
    this.insights$?.unsubscribe();
    this.modalSubscription.next(true);
    this.modalSubscription.unsubscribe();
  }


  // changedViewMode(value) {
  //   this._router.navigateByUrl(`/account/${this.accountId}/location/${this.locationId}/legacy-insights`);
  // }

  showDatepickerModal() {
    if (this.isDatePickerFirstOpened) {
      this.isDatePickerFirstOpened = false;
      this._modalS.openDatepickerdates();
    }
  }
}
