<div class="dialog__header">
    <h2 mat-dialog-title class="m--0 txt--xl"><strong>Add Product Collection</strong></h2>
</div>
<div class="dialog__content" mat-dialog-content>
    <div class="row">
        <div class="col-9">
            <div class="dialog-row pb--0">
                <mat-form-field class="input-field">
                    <input matInput placeholder="Product Collection" [(ngModel)]="productCollectionName" name="productCollectionName">
                    <mat-error *ngIf="productCollectionNameInvalid"><p>Collection Name is required</p></mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-9">
            <div class="dialog-row pb--0">
                <mat-form-field class="input-field">
                    <input matInput placeholder="Product Description Collection (optional)" [(ngModel)]="productCollectionDesc" name="productCollectionDesc">
                </mat-form-field>
            </div>
        </div>
    </div>

</div>
<div mat-dialog-actions class="dialog__footer">
    <span class="alternate-theme d-inline-block mr--10">
        <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
    </span>
    <button mat-flat-button (click)="apply()"  tabIndex="-1" color="accent" class="btn btn--action">Apply</button>
</div>
