import { __assign, __awaiter, __generator } from "tslib";
import { FormBuilder } from '@angular/forms';
import { OnInit } from '@angular/core';
import { InsightsService } from '../../services/insights.service';
import { ReportService } from 'src/app/services/report.service';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from '../../services/loading.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
var DashboardRevenueComponent = /** @class */ (function () {
    function DashboardRevenueComponent(_insightS, _fb, _reportS, _route, _loadingService, _snackS) {
        var _a, _b, _c, _d, _e, _f;
        this._insightS = _insightS;
        this._fb = _fb;
        this._reportS = _reportS;
        this._route = _route;
        this._loadingService = _loadingService;
        this._snackS = _snackS;
        this.direction = 0;
        this.mobile = 0;
        this.website = 0;
        this.ctr = 0;
        this.potentialStore = 0;
        this.potentialSales = 0;
        this.potentialRevenue = 0;
        this.progress = true;
        this.isLoading = false;
        this.shared = false;
        this.lockDates = false;
        this.viewModeChecked = 'legacy';
        this._id = (_c = (_b = (_a = this._route) === null || _a === void 0 ? void 0 : _a.snapshot) === null || _b === void 0 ? void 0 : _b.params) === null || _c === void 0 ? void 0 : _c.id;
        this._gid = (_f = (_e = (_d = this._route) === null || _d === void 0 ? void 0 : _d.snapshot) === null || _e === void 0 ? void 0 : _e.params) === null || _f === void 0 ? void 0 : _f.gid;
    }
    DashboardRevenueComponent.prototype.ngOnInit = function () {
        this.locations = this.dataPicker.locations;
        this.initForm();
        this.getData();
    };
    DashboardRevenueComponent.prototype.getData = function () {
        var _this = this;
        this.selectDate = this._reportS.selectDatesFromDataPicker(this.dataPicker);
        this.insights$ = this._insightS.getInsights(this.locations, this.dataPicker, this.viewModeChecked).take(2).subscribe(function (res) {
            var _a;
            _this.progress = false;
            _this.actions = res.stats[0];
            _this.labels = res.labels;
            var totalActions = res.stats[0].totals || res.stats[0].total;
            var totalViews = res.stats[1].totals || res.stats[1].total;
            _this.direction = _this.actions.ACTIONS_DRIVING_DIRECTIONS.total.numeric;
            _this.mobile = _this.actions.ACTIONS_PHONE.total.numeric;
            _this.website = _this.actions.ACTIONS_WEBSITE.total.numeric;
            (_a = res.stats) === null || _a === void 0 ? void 0 : _a.forEach(function (s) {
                var _a, _b;
                if (((_a = s) === null || _a === void 0 ? void 0 : _a.title) === 'Search impressions') {
                    _this.searchStat = s;
                }
                if (((_b = s) === null || _b === void 0 ? void 0 : _b.title) === 'total views on search') {
                    _this.views = s;
                }
                if (totalViews > 0) {
                    _this.ctr = (totalActions / totalViews) * 100;
                }
            });
            // if (this.report.revenueInfo) {
            //   this.calculateValues();
            // }
            _this.calculateValues();
            _this._loadingService.reportAdvanced(1, 'Revenue');
        }, function (error) {
            console.error(error);
        });
    };
    DashboardRevenueComponent.prototype.initForm = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        this.form = this._fb.group({
            directionControl: [((_b = (_a = this.report) === null || _a === void 0 ? void 0 : _a.metadata) === null || _b === void 0 ? void 0 : _b.directionControl) || 0],
            mobileControl: [((_d = (_c = this.report) === null || _c === void 0 ? void 0 : _c.metadata) === null || _d === void 0 ? void 0 : _d.mobileControl) || 0],
            websiteControl: [((_f = (_e = this.report) === null || _e === void 0 ? void 0 : _e.metadata) === null || _f === void 0 ? void 0 : _f.websiteControl) || 0],
            potentialStoreControl: [((_h = (_g = this.report) === null || _g === void 0 ? void 0 : _g.metadata) === null || _h === void 0 ? void 0 : _h.potentialStoreControl) || 0],
            potentialSalesControl: [((_k = (_j = this.report) === null || _j === void 0 ? void 0 : _j.metadata) === null || _k === void 0 ? void 0 : _k.potentialSalesControl) || 0],
        });
        this.form.valueChanges.pipe(map(function (next) {
            return _this.calculateValues();
        })).subscribe(function (next) {
            _this._reportS.setMerge(_this._gid, _this._id, next).then();
        });
        this.potentialRevenue = ((_l = this.report.revenueInfo) === null || _l === void 0 ? void 0 : _l.potentialRevenue) ? (_o = (_m = this.report) === null || _m === void 0 ? void 0 : _m.revenueInfo) === null || _o === void 0 ? void 0 : _o.potentialRevenue : 0;
    };
    DashboardRevenueComponent.prototype.calculateValues = function () {
        this.potentialStore = 0;
        this.potentialSales = 0;
        this.potentialRevenue = 0;
        this.potentialStore += this.direction * (parseInt(this.directionControl.value, 0) / 100);
        this.potentialStore += this.website * (parseInt(this.websiteControl.value, 0) / 100);
        this.potentialStore += this.mobile * (parseInt(this.mobileControl.value, 0) / 100);
        this.potentialSales += this.potentialStore * (parseInt(this.potentialStoreControl.value, 0) / 100);
        this.potentialRevenue += this.potentialSales * (parseInt(this.potentialSalesControl.value, 0));
        return {
            revenueInfo: {
                potentialStore: this.potentialStoreControl.value,
                potentialSales: this.potentialSalesControl.value,
                potentialRevenue: this.potentialRevenue,
                actions: {
                    mobile: this.mobileControl.value,
                    website: this.websiteControl.value,
                    direction: this.directionControl.value,
                }
            }
        };
    };
    Object.defineProperty(DashboardRevenueComponent.prototype, "totalAction", {
        get: function () {
            return this.direction + this.website + this.mobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardRevenueComponent.prototype, "directionControl", {
        get: function () {
            return this.form.get('directionControl');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardRevenueComponent.prototype, "mobileControl", {
        get: function () {
            return this.form.get('mobileControl');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardRevenueComponent.prototype, "websiteControl", {
        get: function () {
            return this.form.get('websiteControl');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardRevenueComponent.prototype, "potentialStoreControl", {
        get: function () {
            return this.form.get('potentialStoreControl');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardRevenueComponent.prototype, "potentialSalesControl", {
        get: function () {
            return this.form.get('potentialSalesControl');
        },
        enumerable: true,
        configurable: true
    });
    DashboardRevenueComponent.prototype.handleDataPicker = function (event) {
        this.dataPicker = __assign({}, event);
        if (!this.dataPicker.hasOwnProperty('locations')) {
            this.dataPicker.locations = this.locations;
        }
    };
    DashboardRevenueComponent.prototype.exportCSV = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (event != 'csv')
                            return [2 /*return*/];
                        this.isProgressCSV = true;
                        if (!(this.viewModeChecked == 'legacy')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._reportS.handleExportCsvFire(this.locations, this.dataPicker.range.start, this.dataPicker.range.end, this.dataPicker.aggregation.toLowerCase(), this.reportName)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this._reportS.handleExportCsvMongo(this.reportId, null, null, null, this.dataPicker.range.start, this.dataPicker.range.end, this.dataPicker.aggregation)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        this.isProgressCSV = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardRevenueComponent.prototype.triggerNumberUp = function (control) {
        (control.value + 1 < 0) ? control.setValue(0) : control.setValue(control.value + 1);
    };
    DashboardRevenueComponent.prototype.triggerNumberDown = function (control) {
        (control.value - 1 < 0) ? control.setValue(0) : control.setValue(control.value - 1);
    };
    DashboardRevenueComponent.prototype.clearValues = function () {
        var _this = this;
        Object.keys(this.form.controls).forEach(function (key) {
            _this.form.get(key).reset(0);
        });
    };
    DashboardRevenueComponent.prototype.saveValues = function () {
        var _this = this;
        var _a, _b, _c, _d, _e;
        this.isLoading = true;
        var data = __assign({}, this.report);
        data.metadata = {
            directionControl: (_a = this.directionControl) === null || _a === void 0 ? void 0 : _a.value,
            mobileControl: (_b = this.mobileControl) === null || _b === void 0 ? void 0 : _b.value,
            websiteControl: (_c = this.websiteControl) === null || _c === void 0 ? void 0 : _c.value,
            potentialStoreControl: (_d = this.potentialStoreControl) === null || _d === void 0 ? void 0 : _d.value,
            potentialSalesControl: (_e = this.potentialSalesControl) === null || _e === void 0 ? void 0 : _e.value
        };
        this._reportS.updateReportsInMongo(this._id, data).subscribe(function (res) {
            var _a;
            if ((_a = res) === null || _a === void 0 ? void 0 : _a.error) {
                _this._snackS.openError('There was an error while saving the data. Please try again or contact support', 4000);
            }
            else {
                _this._snackS.openSuccess('The data was saved successfully', 2000);
            }
            _this.isLoading = false;
        }, function (err) {
            _this._snackS.openError('There was an error while saving the data. Please try again or contact support', 4000);
            _this.isLoading = false;
        });
    };
    return DashboardRevenueComponent;
}());
export { DashboardRevenueComponent };
