// dep
import { Directive, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

// app
import { ModalService } from '../services/modal.service';
import { AuthService } from '../services/auth.service';
import { LocationService } from '../services/location.service';
import { SubscriptionService } from '../services/subscription.service';
import { GROUP_SUBSCRIPTION_TYPE, LOCATION_SUBSCRIPTION_TYPE } from '../constants/firestore/account-location';
import User from '../constants/firestore/user';
import { ISubscription } from '../constants/subscription';
import SavedLocation from '../constants/firestore/saved-location';
import { FeaturedComponent } from '../components/featured.component';
import { LocationRef } from '../constants/firestore/location-object';

@Directive({
  exportAs: 'gmbLGF',
  selector: '[gmbLGF]'
})
export class LocationGatedFreeDirective implements OnChanges, OnInit {
  user: User;
  location: SavedLocation

  subscription$: Observable<ISubscription>;
  subscription: ISubscription;

  @Input() gmbLGF: { location: SavedLocation, account: string };
  @Output() denyAccess = new EventEmitter<boolean>();

  constructor(
    private route: ActivatedRoute,
    private modalService: ModalService,
    private locationService: LocationService,
    private auth: AuthService,
    private subscriptionService: SubscriptionService,
    private element: ElementRef,
  ) {
    this.subscription$ = this.auth.subscription$;
    this.subscription$.subscribe(subscription => this.subscription = subscription);
  }

  async ngOnInit() {
    this.user = this.auth.session;
    const locationLGF = this.gmbLGF.location;
    const placeId = _.get(locationLGF, 'locationId') || this.route.snapshot.parent.params.locationId;
    const account = this.gmbLGF.account || this.route.snapshot.parent.params.accountId;
    const checked = _.get(locationLGF, 'checked');
    if (!placeId || !account || checked) {
      return;
    }

    this.location = this.gmbLGF.location;
    const subscriptionType = this.location?.subscriptionType;
    if (this.subscription?.pricingVersion < 3 &&
        this.subscription?.status !== GROUP_SUBSCRIPTION_TYPE.TRIAL && 
        (subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE || 
        subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL)
    ) {
      this.element.nativeElement.removeAllListeners('click');
      this.element.nativeElement.addEventListener('click', this.handler.bind(this));
      this.denyAccess.emit(true);
      return;
    }
    this.denyAccess.emit(false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.gmbLGF.firstChange) {
      this.ngOnInit();
    }
  }

  // TODO: Search #TODO-refactor-featured-model to find code to refactor against this 
  // code block. 
  async handler(): Promise<void> {
    console.log('LocationGatedFreeDirective handler');
    const locationLGF = this.gmbLGF.location;

    const locationId = _.get(locationLGF, 'locationId') || this.route.snapshot.parent.params.locationId;
    const accountId  = this.gmbLGF.account              || this.route.snapshot.parent.params.accountId;

    this.location = await this.locationService.fetchLocation(this.user.gid, accountId, locationId)

    const subscriptionType = this.location.subscriptionType

    if (this.subscription.status !== GROUP_SUBSCRIPTION_TYPE.TRIAL && (subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE ||
                                                                       subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL)) {

      if (!await this.modalService.openModal(FeaturedComponent, null))
        return

      const locationRef = { locationId, accountId }

      if (await this.subscriptionService.flowChangeLocationsPlan(this.subscription, [locationRef])) {
        this.denyAccess.emit(false);
        this.element.nativeElement.removeAllListeners('click');
      }
    }
  }
}

@Directive({
  selector: '[gmbLGUT]'
})
export class LocationGatedUltimateTrialDirective implements OnInit {
  user: User;
  location: SavedLocation
  subscription$: Observable<ISubscription>;
  subscription: ISubscription;
  @Input() gmbLGUF = () => { };

  constructor(
    private route: ActivatedRoute,
    private modalService: ModalService,
    private locationService: LocationService,
    private auth: AuthService,
    private subscriptionService: SubscriptionService,
    private element: ElementRef,
  ) {
    this.subscription$ = this.auth.subscription$;
    this.subscription$.subscribe(subscription => this.subscription = subscription);
  }

  async ngOnInit() {
    this.user = this.auth.session;
    const locationId : string = this.route.snapshot.parent.params.locationId
    const accountId  : string = this.route.snapshot.parent.params.accountId

    if (!locationId || !accountId) {
      return;
    }

    this.location = await this.locationService.fetchLocation(this.user.gid, accountId, locationId)
    
    if (this.subscription?.pricingVersion < 3 &&
        this.subscription?.status === GROUP_SUBSCRIPTION_TYPE.TRIAL && 
        (this.location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE || 
        this.location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL)) {
      this.element.nativeElement.removeAllListeners('click');
      this.element.nativeElement.addEventListener('click', this.handler.bind(this));
    }
  }

  // TODO: Search #TODO-refactor-featured-model to find code to refactor against this 
  // code block. 
  async handler() : Promise<void> {
    console.log('LocationGatedUltimateTrialDirective handler');

    if(!(await this.modalService.openModal(FeaturedComponent, null)))
      return

    const locRef : LocationRef = { locationId: this.location.locationId, 
                                    accountId: this.route.snapshot.parent.params.accountId }

    if(await this.subscriptionService.flowChangeLocationsPlan(this.subscription, [locRef]))
        console.log("upgrade successful - location-gated.directive");
    }
}

@Directive({
  exportAs: 'gmbLGB',
  selector: '[gmbLGB]'
})
export class LocationGatedBasicDirective implements OnChanges, OnInit {
  user: User;
  location: any;
  subscription$: Observable<ISubscription>;
  subscription: ISubscription;
  @Input() gmbLGB: { location: SavedLocation, account: string, user?: boolean };
  @Output() denyAccess = new EventEmitter<boolean>();

  constructor(
    private route: ActivatedRoute,
    private modalService: ModalService,
    private locationService: LocationService,
    private auth: AuthService,
    private subscriptionService: SubscriptionService,
    private element: ElementRef,
  ) {
    this.subscription$ = this.auth.subscription$;
    this.subscription$.subscribe(subscription => this.subscription = subscription);
  }

  async ngOnInit() {
    this.user = this.auth.session;
    const locationLGB = this.gmbLGB.location;
    const placeId = _.get(locationLGB, 'locationId') || this.route.snapshot.parent.params.locationId;
    const account = this.gmbLGB.account || this.route.snapshot.parent.params.accountId;
    const checked = _.get(locationLGB, 'checked');
    if (!placeId || !account || checked) {
      return;
    }
    this.location = this.gmbLGB.location;
    const subscriptionType = this.location?.subscriptionType;
    if (this.subscription?.pricingVersion < 3 &&
        subscriptionType === LOCATION_SUBSCRIPTION_TYPE.BASIC && !this.gmbLGB.user) {
      this.element.nativeElement.removeAllListeners('click');
      this.element.nativeElement.addEventListener('click', this.handler.bind(this));
      this.denyAccess.emit(true);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.gmbLGB.firstChange) {
      this.ngOnInit();
    }
  }

  // TODO: Search #TODO-refactor-featured-model to find code to refactor against this 
  // code block. 
  async handler(): Promise<void> {
    console.log('LocationGatedBasicDirective handler');
    if (this.auth.isMember) {
      await this.modalService.openWarningModal('Contact your administrator',
                                               'Your user doesn’t have permissions to do this. Contact your account administrator to change your role.')
      return
    }

    const locationLGB = this.gmbLGB.location;

    const locationId = _.get(locationLGB, 'locationId') || this.route.snapshot.parent.params.locationId;
    const accountId  = this.gmbLGB.account              || this.route.snapshot.parent.params.accountId;

    this.location = await this.locationService.fetchLocation(this.user.gid, accountId, locationId)

    if (this.location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.BASIC) {
      if (!await this.modalService.openModal(FeaturedComponent, null))
        return

      //const locationId = _.get(this.gmbLGB, 'location.locationId') || this.route.snapshot.parent.params.locationId;
      const locationRef : LocationRef = { locationId, accountId }
      const r = await this.subscriptionService.flowChangeLocationsPlan(this.subscription, [locationRef])

      if (r) {
        // FIXME: redundant comparison against ULTIMATE, bug?
        if (this.subscription.status !== GROUP_SUBSCRIPTION_TYPE.TRIAL && (r.nextPlan === LOCATION_SUBSCRIPTION_TYPE.ULTIMATE // ||
                                                                           //r.nextPlan === LOCATION_SUBSCRIPTION_TYPE.ULTIMATE
                                                                           )) {
          this.denyAccess.emit(false);
        } else {
          this.ngOnInit();
        }
      }
    }
  }

}