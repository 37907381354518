import { Component, Input, OnInit, OnChanges, ChangeDetectorRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-chart-progress',
  templateUrl: './chart-progress.component.html',
  styleUrls: ['./chart-progress.component.scss']
})
export class ChartProgressComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() total;
  @Input() label: string;
  @Input() bgColor;
  @Input() index;
  @Input() type: string;
  @Input() showPercent = true;
  @Input() tooltip: string = null;
  @Input() showRightProgressLabel = false;
  
  public progressColorClass: string;
  public progressBgColorClass: string;


  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.showPercent = !this.label?.toLowerCase().includes('post');
    this.progressColorClass = (this.total?.percent > 95) ? 'txt--white' : 'txt--black';
  }

  ngOnChanges() {
    this.setBackgroundColor(this.bgColor);
  }

  setBackgroundColor(color: string): void {
    
    const css = `
      #progress-bar-${this.index} .mat-progress-bar-fill::after {
        background-color: ${color};
      }
    `;

    const style = document.createElement('style');
    style.append(document.createTextNode(css));

    document.head.appendChild(style);
  }

}
