import { __awaiter, __generator } from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '../../../services/user.service';
var UserSettingDialogComponent = /** @class */ (function () {
    function UserSettingDialogComponent(data, dialogRef, usersService) {
        this.data = data;
        this.dialogRef = dialogRef;
        this.usersService = usersService;
        this.subsManager$ = new Subject();
        this.user = this.data.user;
        if (this.user.accounts) {
            this.accountsSelected = this.user.accounts;
        }
    }
    UserSettingDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.usersService.users
            .pipe(takeUntil(this.subsManager$))
            .subscribe(function (response) {
            _this.users = response;
        });
    };
    UserSettingDialogComponent.prototype.ngOnDestroy = function () {
        this.subsManager$.next(true);
        this.subsManager$.complete();
    };
    UserSettingDialogComponent.prototype.save = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.accountsSelected) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.usersService.updateUser(this.user.gid, this.user.uid, { accounts: this.accountsSelected })];
                    case 1:
                        _a.sent();
                        this.dialogRef.close({
                            accounts: this.accountsSelected
                        });
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    UserSettingDialogComponent.prototype.selectedLocations = function ($event) {
        this.accountsSelected = $event;
    };
    return UserSettingDialogComponent;
}());
export { UserSettingDialogComponent };
