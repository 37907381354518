<div *ngIf="dataset">
  <div *ngIf="dataSets && data">
    <canvas
      baseChart
      [datasets]="dataSets"
      [labels]="labels"
      [chartType]="barChartType"
      [options]="barChartOptions"
      [legend]="false">
    </canvas>
  </div>
</div>
