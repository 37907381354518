import { __awaiter, __generator } from "tslib";
import { AngularFirestore /*, CollectionReference*/ } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import * as _ from 'lodash';
import { BehaviorSubject, of } from 'rxjs';
// import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { WhiteLabelService } from './white-label.service';
import { FirestoreService } from './firestore.service';
import { GROUPS, USER } from '../constants/firestore/collections';
// import { Pageable } from '../constants/pageable';
import { SESSION } from "../constants/session";
import { environment as ENV } from '@environment';
import { HEADERS_NO_AUTH } from '../constants/auth';
import { isRunningEmbedded } from '../helpers/utils.helpers';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "./white-label.service";
import * as i4 from "./firestore.service";
var UserService = /** @class */ (function () {
    function UserService(http, afs, wl, fs) {
        this.http = http;
        this.afs = afs;
        this.wl = wl;
        this.fs = fs;
        this.loading = false;
        if (!isRunningEmbedded()) {
            var session_json = localStorage.getItem(SESSION);
            if (!session_json || session_json === 'undefined') {
                localStorage.removeItem(SESSION);
                this.sessionSource = new BehaviorSubject(null);
            }
            else {
                this.sessionSource = new BehaviorSubject(JSON.parse(session_json));
            }
            this.session = this.sessionSource.asObservable();
        }
        this.dataStore = { accounts: [] };
        this._users = new BehaviorSubject([]);
    }
    UserService.prototype.deleteUser = function (user) {
        return this.http.delete(ENV.apiUrl + "/v2/groups/" + user.gid + "/user/" + user.uid);
    };
    UserService.prototype.addUserToGroup = function (gid, body) {
        return this.http.post(ENV.apiUrl + "/v2/groups/" + gid + "/user", body);
    };
    UserService.prototype.updateSession = function (session) {
        this.sessionSource.next(session);
    };
    Object.defineProperty(UserService.prototype, "users", {
        get: function () {
            return this._users.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    UserService.prototype.setUsers = function (users) {
        this._users.next(users);
    };
    // TODO: Unused, remove
    // getByGid(gid) {
    //   return this.afs.collection(GROUPS).doc(gid).collection<User>(USER);
    // }
    UserService.prototype.getByGidDocId = function (gid, docId) {
        return this.afs.collection(GROUPS).doc(gid).collection(USER).doc(docId);
    };
    // TODO: Unused, remove.
    // getByDocId(docId: string) {
    //   return this.afs.collectionGroup<User>(USER, ref => ref.where('uid', '==', docId)).valueChanges();
    // }
    UserService.prototype.getUserByUid = function (uid) {
        return this.afs.collectionGroup(USER, function (ref) { return ref.where('uid', '==', uid); }).get();
    };
    // TODO: Unused, remove
    // getByEmail(email: string) {
    //   return this.afs.collectionGroup<User>(USER, ref => ref.where('email', '==', email)).valueChanges();
    // }
    /**
     * Save in firestore entity User
     * @param user User.ts
     */
    // TODO: Direct FS mutation, this must be replaced with a backend endpoint
    UserService.prototype.save = function (user) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.afs.collection(GROUPS).doc(user.gid).collection(USER).doc(user.uid).set(user)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Update user entity in firestore
     * @param user User.ts
     */
    // TODO: Direct FS mutation, this must be replaced with a backend endpoint
    UserService.prototype.altUpdate = function (user) {
        return this.afs.collection(GROUPS).doc(user.gid).collection(USER).doc(user.uid).set(user, { merge: true });
    };
    UserService.prototype.updateForLogin = function (gid, uid) {
        var _this = this;
        if (!gid) {
            return;
        }
        return this.getByGidDocId(gid, uid).get().map(function (doc) { return doc.data(); }).switchMap(function (user) {
            if (!user) {
                return of(null);
            }
            else {
                user.lastLogin = firebase.firestore.Timestamp.fromDate(new Date());
                user.lastLoginDomain = _this.wl.slugDomain;
                _this.altUpdate(user).then(); // TODO: then intention is to await? bad
                return _this.getByGidDocId(gid, uid).valueChanges();
            }
        });
    };
    /**
     * this method delete user entity in firestore
     * @param docId  this doc is key in firestore
     */
    // TODO: Direct FS mutation, this must be replaced with a backend endpoint
    UserService.prototype.delete = function (gid, docId) {
        return this.afs.collection(GROUPS).doc(gid).collection(USER).doc(docId).delete();
    };
    /**
     * this method transform parameters entry in a User Type
     *
     * @param uid             :firestore user id
     * @param email           :firestore email
     * @param displayName     :firestore displayName,
     * @param company         :firestore company
     * @param photoURL        :firestore photo
     * @param gid             :firestore group$ id
     * @param authToken       :jwt Token firebase
     * @param domainSurfing   :default false
     */
    UserService.prototype.transformUser = function (uid, email, displayName, company, photoURL, authToken, gid, domainSurfing) {
        var user = {
            uid: uid,
            email: email,
            displayName: displayName,
            company: company,
            photoURL: photoURL,
            lastLogin: firebase.firestore.Timestamp.fromDate(new Date()),
            timezone: new Date().getTimezoneOffset(),
            registrationDomain: this.wl.domainUser,
            domainSurfing: false,
            isAI: false,
            isActive: true
        };
        if (gid !== undefined) {
            user.gid = gid;
        }
        if (domainSurfing !== undefined) {
            user.domainSurfing = domainSurfing;
        }
        if (authToken !== undefined) {
            user.authToken = authToken;
        }
        return user;
    };
    UserService.prototype.loadAll = function (user) {
        var _this = this;
        this.loading = true;
        var query = this.afs.collection(GROUPS).doc(user.gid).collection(USER, function (ref) { return ref.where('gid', '==', user.gid)
            .orderBy('role', 'asc')
            .orderBy('displayName', 'asc')
            .limit(_this.paginator.pageSize); }).ref;
        this.paginator.pageIndex = 1;
        this.fs.colWithIds$(USER, function (ref) { return query; }).subscribe(function (data) {
            _this.dataStore.accounts = data;
            _this._users.next(Object.assign({}, _this.dataStore).accounts);
            _this.loading = false;
        });
    };
    // TODO: Unused?
    UserService.prototype.filter = function (user, filter) {
        var _this = this;
        this.loading = true;
        var query;
        if (_.has(filter, 'sortBy')) {
            query = this.afs.collectionGroup(USER, function (ref) { return ref.where('registrationDomain', 'in', [filter.domain, filter.domain + ":"])
                .orderBy(filter.sortBy, filter.direction); });
        }
        else {
            query = this.afs.collectionGroup(USER, function (ref) { return ref.where('registrationDomain', 'in', [filter.domain, filter.domain + ":"])
                .orderBy('email', 'asc'); });
        }
        this.afs.collectionGroup(USER, function (ref) { return query; }).get().subscribe(function (users) {
            var result = [];
            users.docs.forEach(function (u) {
                result.push(u.data());
            });
            _this.dataStore.accounts = result;
            _this.paginator.length = _this.dataStore.accounts.length;
            _this._users.next(Object.assign({}, _this.dataStore).accounts);
            _this.loading = false;
        });
    };
    // TODO: Unused, remove
    //
    //   getUsersByGroup(groupId: string) {
    //     const query = this.afs.collection(GROUPS).doc(groupId).collection(USER, ref => ref.where('gid', '==', groupId)
    //       .orderBy('email', 'asc')).ref;
    // 
    //     return this.fs.colWithIds$<User>(USER, ref => query);
    //   }
    // TODO: Direct FS mutation, this must be replaced with a backend endpoint
    UserService.prototype.updateUser = function (gid, docId, data) {
        return this.afs.collection(GROUPS).doc(gid).collection(USER).doc(docId).update(data);
    };
    UserService.prototype.get = function (gid, uid) {
        return this.afs.collection(GROUPS).doc(gid).collection(USER).doc(uid).valueChanges();
    };
    // TODO: Unused, remove
    //
    // countUsers() {
    //   return this.afs.collectionGroup(USER).valueChanges().pipe(map(r => r.length));
    // }
    //
    // getUsers(pageable: Pageable = {
    //   size: 10,
    //   page: 1
    // }, domain, next, prev, order = 'createdAt', direction = 'desc'): Observable<any> {
    //   return this.fs.paginateUsers(USER, domain, order, direction, pageable, next, prev);
    // }
    // 
    // getAllUsers() {
    //   return this.afs.collectionGroup(USER).get().map(query => query.docs.map(doc => doc.data()));
    // }
    // 
    // getUsersPaginate(count, pageable, actions) {
    //   return this.fs.formatPagination(count, pageable, actions);
    // }
    UserService.prototype.getEmailIsVerified = function (user) {
        return __awaiter(this, void 0, void 0, function () {
            var r;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.afs.collection(GROUPS).doc(user.gid).collection(USER).doc(user.uid).get().toPromise()];
                    case 1:
                        r = (_a.sent()).data();
                        return [2 /*return*/, (r.emailVerificationHash === null) || (r.emailVerified !== null)];
                }
            });
        });
    };
    UserService.prototype.getUserFeature = function (uid) {
        return this.http.get(ENV.apiUrl + "/v2/users/" + uid + "/enabled-features");
    };
    UserService.prototype.domainValidation = function (domain, gid, uid, domainSurfing) {
        var body = {
            domain: domain,
            gid: gid,
            uid: uid,
            domainSurfing: domainSurfing
        };
        return this.http.post(ENV.apiUrl + "/v2/auth/domain-validation", body, HEADERS_NO_AUTH);
    };
    UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.WhiteLabelService), i0.ɵɵinject(i4.FirestoreService)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
