import { __assign, __awaiter, __generator } from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import * as firebase from 'firebase/app';
import { GoogleAccountService } from '../../services/google-account.service';
import { SnackbarService } from '../../services/snackbar.service';
import { GoogleService } from '../../services/google.service';
import { AuthService } from '../../services/auth.service';
import { AccountService } from '../../services/account.service';
import { LocationService } from '../../services/location.service';
import { AccountLocSelectorComponent } from '../account-loc-selector/account-loc-selector.component';
import { environment } from '@environment';
import { GradeLeadService } from 'src/app/services/grade-lead.service';
import { WhiteLabelService } from 'src/app/services/white-label.service';
import { Title } from '@angular/platform-browser';
import { LogsService } from 'src/app/services/logs.service';
import { EXTERNAL_GRADE_ID, SESSION_EXTERNAL, STORAGE_GRADE_LEAD } from '../../constants/session';
import { ExternalGradeService } from 'src/app/services/external-grade.service';
var ExternalGradeComponent = /** @class */ (function () {
    function ExternalGradeComponent(dialog, snack, googleS, googleAccountS, accountService, locationService, gradeLeadService, auth, wl, titleService, logService, externalGradeService) {
        var _this = this;
        this.dialog = dialog;
        this.snack = snack;
        this.googleS = googleS;
        this.googleAccountS = googleAccountS;
        this.accountService = accountService;
        this.locationService = locationService;
        this.gradeLeadService = gradeLeadService;
        this.auth = auth;
        this.wl = wl;
        this.titleService = titleService;
        this.logService = logService;
        this.externalGradeService = externalGradeService;
        this.accounts = [];
        this.loading = false;
        this.showGradePopup = true;
        this.companyName = '';
        this.wl.getDynamicWhiteLabel().then(function (data) {
            _this.companyName = data.branding.company_name;
            var titlePass = "Grader | " + _this.companyName;
            _this.titleService.setTitle(titlePass);
        });
    }
    ExternalGradeComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var now, randomNum, gradeLead;
            return __generator(this, function (_a) {
                now = Date.now();
                randomNum = Math.round(Math.random() * 1000);
                this.externalGradeId = "" + now + randomNum;
                localStorage.setItem(EXTERNAL_GRADE_ID, this.externalGradeId);
                gradeLead = localStorage.getItem(STORAGE_GRADE_LEAD);
                if (gradeLead && this.auth.session)
                    this.loadGradeLead(JSON.parse(gradeLead));
                return [2 /*return*/];
            });
        });
    };
    ExternalGradeComponent.prototype.setExternalSession = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.env = environment;
                        this.loading = true;
                        _a = this;
                        return [4 /*yield*/, this.auth.createExternalGradeUser()];
                    case 1:
                        _a.user = _c.sent();
                        localStorage.setItem(SESSION_EXTERNAL, JSON.stringify(this.user));
                        this.auth.gradeExternalUser = this.user;
                        _c.label = 2;
                    case 2:
                        _c.trys.push([2, 4, , 5]);
                        _b = this.user;
                        return [4 /*yield*/, this.auth.afAuth.auth.currentUser.getIdToken(true)];
                    case 3:
                        _b.authToken = _c.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _c.sent();
                        return [3 /*break*/, 5];
                    case 5:
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    ExternalGradeComponent.prototype.ngOnDestroy = function () {
        localStorage.removeItem(STORAGE_GRADE_LEAD);
    };
    ExternalGradeComponent.prototype.openAuth = function () {
        return __awaiter(this, void 0, void 0, function () {
            var gradeId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.setExternalSession()];
                    case 1:
                        _a.sent();
                        this.subscribeToGoogleResponse();
                        this.snack.openInfo("A tab to authenticate with Google will open. If you don't see it, check your pop-up blocker settings", 5000);
                        gradeId = localStorage.getItem(EXTERNAL_GRADE_ID);
                        return [2 /*return*/, this.googleAccountS.google_oauth(this.user, gradeId)];
                }
            });
        });
    };
    ExternalGradeComponent.prototype.handleSelected = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            var location_1, account, token, exist, accountSave, saved, user, parsedUrl, registrationDomain, gradeLead, data, companyName, domain, titlePass, metaData, newLog, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 11, , 12]);
                        location_1 = $event.location;
                        account = $event.account;
                        this.accountId = location_1.name.split('/')[1];
                        this.locationId = location_1.name.split('/')[3];
                        this.locations = { locationId: this.locationId, accountId: this.accountId };
                        return [4 /*yield*/, this.externalGradeService.getExternalGradeToken(this.auth.externalSession.gid, this.externalGradeId).toPromise()];
                    case 1:
                        token = _a.sent();
                        return [4 /*yield*/, this.accountService.get(this.auth.externalSession.gid, this.accountId).toPromise()];
                    case 2:
                        exist = _a.sent();
                        if (!!exist) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.accountService.save(__assign(__assign({}, account), { gid: this.auth.externalSession.gid, accountId: this.accountId, googleAuth: token }), true)];
                    case 3:
                        accountSave = _a.sent();
                        this.accountId = accountSave.accountId;
                        _a.label = 4;
                    case 4: return [4 /*yield*/, this.locationService.save($event.location, this.accountId, this.auth.externalSession.gid)];
                    case 5:
                        saved = _a.sent();
                        if (!saved) return [3 /*break*/, 9];
                        return [4 /*yield*/, this.googleS.getUserProfile(token)];
                    case 6:
                        user = _a.sent();
                        parsedUrl = new URL(window.location.href);
                        registrationDomain = parsedUrl.host;
                        gradeLead = {
                            locationId: $event.location.name.split('/')[3],
                            locationName: $event.location.locationName,
                            accountId: $event.account.name.split('/')[1],
                            accountName: $event.account.accountName,
                            createdAt: firebase.firestore.Timestamp.now(),
                            name: user.name,
                            email: user.email,
                            registrationDomain: registrationDomain,
                            address: $event.location.address,
                            viewed: false
                        };
                        return [4 /*yield*/, this.wl.getDynamicWhiteLabel()];
                    case 7:
                        data = _a.sent();
                        companyName = data.branding.company_name;
                        domain = data.branding.domain;
                        titlePass = "Grade | " + gradeLead.locationName + " | " + companyName;
                        this.titleService.setTitle(titlePass);
                        metaData = {
                            accountId: this.accountId,
                            locationId: this.locationId,
                            locationName: gradeLead.locationName,
                            email: user.email,
                            displayName: user.name
                        };
                        newLog = {
                            create_at: new Date(),
                            domain: domain,
                            gid: this.user.gid,
                            message: "A new public grade was created for " + gradeLead.locationName,
                            type_log: 'Grade',
                            meta_data: metaData
                        };
                        return [4 /*yield*/, this.logService.saveLog(newLog)];
                    case 8:
                        _a.sent();
                        this.gradeLeadService.save(gradeLead);
                        this.grade = true;
                        this.loading = false;
                        return [3 /*break*/, 10];
                    case 9:
                        this.loading = false;
                        _a.label = 10;
                    case 10: return [3 /*break*/, 12];
                    case 11:
                        e_2 = _a.sent();
                        console.log(e_2);
                        this.snack.openError('There was a problem accessing your account. Please try again later.', 2000);
                        this.loading = false;
                        return [3 /*break*/, 12];
                    case 12: return [2 /*return*/];
                }
            });
        });
    };
    ExternalGradeComponent.prototype.loadGradeLead = function (gradeLead) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, data, company_name, titlePass;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.loading = true;
                        this.accountId = gradeLead.accountId;
                        this.user = this.auth.session;
                        _a = this;
                        return [4 /*yield*/, this.auth.createExternalGradeUser()];
                    case 1:
                        _a.userExternal = _b.sent();
                        this.auth.gradeExternalUser = this.userExternal;
                        this.locationId = gradeLead.locationId;
                        this.locations = { locationId: this.locationId, accountId: this.accountId };
                        this.grade = true;
                        this.loading = false;
                        return [4 /*yield*/, this.wl.getDynamicWhiteLabel()];
                    case 2:
                        data = _b.sent();
                        company_name = data.branding.company_name;
                        titlePass = "Grader | " + gradeLead.locationName + " | " + company_name;
                        this.titleService.setTitle(titlePass);
                        return [2 /*return*/];
                }
            });
        });
    };
    ExternalGradeComponent.prototype.closePopup = function (event) {
        event.preventDefault();
        this.showGradePopup = false;
    };
    ExternalGradeComponent.prototype.subscribeToGoogleResponse = function () {
        var _this = this;
        var sub = this.googleAccountS.accounts.subscribe(function (accounts) {
            _this.accounts = accounts;
            if (!_.isNil(accounts) && !_.isEmpty(accounts) && !_this.addAccountDialog) {
                _this.addAccountDialog = _this.dialog.open(AccountLocSelectorComponent, {
                    width: '680px',
                    data: { accounts: _this.accounts, user: _this.user },
                    autoFocus: false
                });
                _this.addAccountDialog.backdropClick().subscribe(function () {
                    _this.addAccountDialog = null;
                    _this.dialog.closeAll();
                });
                _this.addAccountDialog.afterClosed()
                    .subscribe(function (response) {
                    sub.unsubscribe();
                    _this.googleAccountS.resetAccounts();
                    _this.addAccountDialog = null;
                    _this.dialog.closeAll();
                    if (response) {
                        _this.loading = true;
                        _this.handleSelected(response);
                    }
                });
            }
        });
    };
    ExternalGradeComponent.prototype.redirectTo = function () {
        var _a;
        window.location.href = (((_a = this.env.branding) === null || _a === void 0 ? void 0 : _a.signUp_url) || '/register');
    };
    return ExternalGradeComponent;
}());
export { ExternalGradeComponent };
