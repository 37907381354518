import { __assign, __awaiter, __generator } from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject, BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import { PostService } from '../../services/post.service';
import { validatePlaceholder } from '../../constants/post';
import { DatesService } from '../../services/dates.service';
import { SnackbarService } from '../../services/snackbar.service';
import { AuthService } from '../../services/auth.service';
import { ModalService } from '../../services/modal.service';
import { ModalSchedulePostComponent } from '../modal-schedule-post/modal-schedule-post.component';
import { FormPostComponent } from '../form-post/form-post.component';
import * as moment from 'moment';
import * as constants from '../../constants/post';
import { getNextReccurringDate } from 'src/app/helpers/functions.helpers';
import { AlertType } from 'src/app/components/alert.component';
var ModalLocalPostsComponent = /** @class */ (function () {
    function ModalLocalPostsComponent(postService, dateS, auth, snackS, modalS, dialogRef, data) {
        this.postService = postService;
        this.dateS = dateS;
        this.auth = auth;
        this.snackS = snackS;
        this.modalS = modalS;
        this.dialogRef = dialogRef;
        this.data = data;
        this.selectedIndex = 0;
        this.edit = false;
        this.locationShow = false; // Show location toogle
        this.accountsSelected = [];
        this.validFormPost = false;
        this.publishNow = false;
        this.duplicatePostAcceptance = false;
        this.type = ""; // status of the post ('DRATF', 'SCHEDULED')
        this.progress = false;
        this.eventStartDate = null;
        this.eventEndDate = null;
        this.touched = false;
        this.validatePlaceholder = false;
        this.locationsWithoutValidate = [];
        this.subsManager$ = new Subject();
        this.formData = {};
        this.disablePostingButton = false;
        this.planningDates$ = new BehaviorSubject([]);
        this.planningDates$.asObservable();
        dialogRef.disableClose = true;
        if (this.data.posts && this.data.posts !== undefined) {
            this.bulkPost = true; // Indicate if a bulk post
            this.accountsSelected = this.data.accounts;
        }
        if (this.data.post && this.data.posts !== undefined) {
            this.type = this.data.type ? this.data.type : null;
            this.isPosted = (this.data.post.status === 'PUBLISH');
            this.edit = true;
            if (_.has(this.data.post, 'status') && _.isEqual(_.get(this.data.post, 'status'), 'DRAFT')) {
                this.data.post = _.omit(_.merge(this.data.post, __assign({}, _.get(this.data.post, 'post'))), ['post']);
            }
            if (this.data.post.postInfo.topicType) {
                this.setView(this.data.post.postInfo.topicType);
            }
            if (data.post.isScheduled)
                this.schedule = data.post.scheduled;
            if (data.post.isRecurring)
                this.recurring = data.post.recurring;
        }
    }
    ModalLocalPostsComponent.prototype.ngOnInit = function () {
        this.locationId = this.data.locationId;
        this.accountId = this.data.accountId;
    };
    ModalLocalPostsComponent.prototype.handleTabOneValid = function ($event) {
        this.oneIsValid = $event;
        //this.validate();
    };
    ModalLocalPostsComponent.prototype.handleTabTwoValid = function ($event) {
        this.twoIsValid = $event;
        //this.validate();
    };
    ModalLocalPostsComponent.prototype.handleTabThreeValid = function ($event) {
        this.threeIsValid = $event;
        //this.validate();
    };
    ModalLocalPostsComponent.prototype.handleTabFourValid = function ($event) {
        this.fourIsValid = $event;
        //this.validate();
    };
    ModalLocalPostsComponent.prototype.apply = function (type, ignorePlaceholder) {
        if (type === void 0) { type = null; }
        if (ignorePlaceholder === void 0) { ignorePlaceholder = false; }
        return __awaiter(this, void 0, void 0, function () {
            var form, post, placeholders, validate_entries, placeholders_call, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.progress = true;
                        this.touched = true;
                        this.type = type;
                        if (!this.locationShow) {
                            form = this.form.getData();
                            this.validate(form.valid);
                            this.topic === 'ALERT' && (this.validFormPost = this.validateForm(this.validFormPost, this.postFormValue, true)) || (this.validFormPost = this.validateForm(this.validFormPost, this.postFormValue, true));
                            this.postFormValue = form.value;
                        }
                        if (!this.postFormValue || !this.validFormPost) {
                            this.touched = true;
                            return [2 /*return*/];
                        }
                        post = this.prepareToSave(this.postFormValue);
                        if (!post) {
                            this.progress = false;
                            return [2 /*return*/];
                        }
                        if (!(_.isNil(this.type) && !this.schedule && !this.recurring)) return [3 /*break*/, 4];
                        placeholders = post.summary.match(/%(\w+)%/gi);
                        validate_entries = "" + post.summary;
                        if ('callToAction' in post) {
                            if ('url' in post.callToAction) {
                                placeholders_call = post.callToAction.url.match(/%(\w+)%/gi);
                                if (placeholders_call) {
                                    if (placeholders) {
                                        placeholders = Object.assign(placeholders_call, placeholders);
                                    }
                                    else {
                                        placeholders = Object.assign([], placeholders_call);
                                    }
                                    validate_entries += "" + post.callToAction.url;
                                }
                            }
                            if (placeholders) {
                                placeholders = validatePlaceholder(placeholders);
                            }
                        }
                        if (!(placeholders && placeholders.length > 0 && !ignorePlaceholder)) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.postService.validate_placeholders(this.accountsSelected, validate_entries).toPromise().then(function (data) { return data; })];
                    case 1:
                        _a.locationsWithoutValidate = _b.sent();
                        if (this.locationsWithoutValidate && this.locationsWithoutValidate.length > 0) {
                            this.invalidPlaceHolder();
                        }
                        else {
                            this.locationsWithoutValidate = [];
                            this.save(post);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        this.save(post);
                        _b.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        if (this.type || this.schedule || this.recurring) {
                            post.status = "DRAFT";
                            this.savePostManagement(this.auth.session.gid, this.accountId, this.locationId, post, this.type, this.schedule);
                        }
                        _b.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ModalLocalPostsComponent.prototype.save = function (data) {
        var _this = this;
        if (this.disablePostingButton)
            return;
        this.disablePostingButton = true;
        if (this.bulkPost) {
            data['gid'] = this.auth.session.gid;
            data['accounts'] = this.accountsSelected;
        }
        var redirectTo = 'Pending';
        var type = this.bulkPost ? 'BULK' : 'LOCAL';
        if (this.edit) {
            if (this.data.post.id) {
                var postId = this.data.post.id;
                data.status = this.isPosted ? constants.POST_STATUS_PENDING_UPDATE : constants.POST_STATUS_PENDING;
                this.postService.updateV3(postId, data, type, redirectTo);
                this.progress = false;
                this.modalS.openConfirmModal('Heads up', 'This will take a few moments to complete. See the Pending tab for status updates', function () { return _this.dialogRef.close(); });
            }
        }
        else {
            if (data) {
                data.status = constants.POST_STATUS_PENDING;
                this.postService.saveV3(this.accountId, this.locationId, data, type, redirectTo).then();
                this.progress = false;
                this.modalS.openConfirmModal('Heads up', 'This will take a few moments to complete. See the Pending tab for status updates', function () { return _this.dialogRef.close(); });
            }
        }
    };
    ModalLocalPostsComponent.prototype.invalidPlaceHolder = function () {
        this.validatePlaceholder = true;
        this.back();
        this.back();
        this.modalS.openAlertModal('Invalid placeholder', "The placeholder that was used does not exist on one of your locations", AlertType.ERROR);
        this.progress = false;
    };
    ModalLocalPostsComponent.prototype.savePostManagement = function (gid, accountId, locationId, post, status, schedule) {
        var _this = this;
        if (status === void 0) { status = null; }
        if (this.disablePostingButton)
            return;
        this.disablePostingButton = true;
        if (!status) {
            status = 'PENDING';
        }
        post.status = status;
        post.gid = this.auth.session.gid;
        if (this.locationId) {
            post.locationId = this.locationId;
            post.accountId = this.locationId;
        }
        if (this.accountsSelected) {
            post.accounts = this.accountsSelected;
        }
        else {
            post.accounts = [];
        }
        if (schedule) {
            if (status === constants.POST_STATUS_PENDING) {
                post.status = constants.POST_STATUS_SCHEDULED;
            }
            post.scheduled = schedule;
            post.recurring = null;
        }
        else if (this.recurring) {
            if (status === constants.POST_STATUS_PENDING) {
                post.status = constants.POST_STATUS_RECURRING;
            }
            post.recurring = __assign({}, this.recurring);
            post.scheduled = null;
        }
        else if (this.data && this.data.post && this.data.post.scheduled) {
            post.scheduled = schedule;
            if (status !== constants.POST_STATUS_SCHEDULED)
                post.scheduled = null;
        }
        else if (this.data && this.data.post && this.data.post.recurring) {
            post.recurring = __assign({}, this.recurring);
            if (status !== constants.POST_STATUS_RECURRING)
                post.recurring = null;
        }
        if (this.publishNow) {
            post.recurring.nextDate = this.nextRecurringDate;
        }
        var type = this.bulkPost ? 'BULK' : 'LOCAL';
        var redirectTo = status === 'DRAFT' ? 'Drafts' : 'Pending';
        if (this.edit) {
            //this.data.post.hasError && (post.las = null);
            if (this.data.post.id) {
                this.postService.updateV3(this.data.post.id, post, type, redirectTo, this.publishNow).then();
            }
            this.modalS.openConfirmModal('Heads up', 'This will take a few moments to complete. See the Pending tab for schedules or recurring post and the Drafts tab for your draft post', function () { return _this.dialogRef.close(); });
        }
        else {
            this.postService.saveV3(accountId, locationId, post, type, redirectTo, this.publishNow).then();
            this.modalS.openConfirmModal('Heads up', 'This will take a few moments to complete. See the Pending tab for schedules or recurring post and the Drafts tab for your draft post', function () { return _this.dialogRef.close(); });
        }
    };
    ModalLocalPostsComponent.prototype.getWeeklyNextDate = function (currentDate, recurring) {
        var year = currentDate.getFullYear();
        var month = currentDate.getMonth();
        var day = currentDate.getDate();
        var dayOfWeek = currentDate.getDay();
        if (dayOfWeek > Number(recurring.repeatOn)) {
            var daysToDate = 7 - (dayOfWeek - Number(recurring.repeatOn));
            return moment(new Date(year, month, day + daysToDate)).utc().toDate();
        }
        else {
            var daysToDate = Number(recurring.repeatOn) - dayOfWeek;
            return moment(new Date(year, month, day + daysToDate)).utc().toDate();
        }
    };
    ModalLocalPostsComponent.prototype.cleanRecurring = function () {
        this.recurring = null;
    };
    ModalLocalPostsComponent.prototype.validate = function (validForm) {
        if (validForm === void 0) { validForm = false; }
        var data = null;
        this.validFormPost = false;
        switch (this.selectedIndex) {
            case 0:
                this.topic = 'STANDARD';
                if (this.validateForm(validForm, this.oneData, false)) {
                    data = this.oneData;
                    this.validFormPost = validForm;
                }
                break;
            case 1:
                this.topic = 'EVENT';
                if (this.validateForm(validForm, this.twoData, false)) {
                    data = this.twoData;
                    this.validFormPost = validForm;
                }
                break;
            case 2:
                this.topic = 'OFFER';
                if (this.validateForm(validForm, this.threeData, false)) {
                    data = this.threeData;
                    this.validFormPost = validForm;
                }
                break;
            case 3:
                this.topic = 'ALERT';
                if (this.validateForm(validForm, this.fourData, false)) {
                    data = this.fourData;
                    this.validFormPost = validForm;
                }
                break;
        }
        if (data) {
            this.postFormValue = data;
        }
    };
    ModalLocalPostsComponent.prototype.prepareToSave = function (form) {
        var post = {
            media: [],
            languageCode: 'en-US',
            summary: form.description,
            topicType: this.topic,
            offer: form.offer,
            state: 'PROCESSING'
        };
        if (form.image) {
            post.media = form.image.map(function (image) { return ({ mediaFormat: 'PHOTO', sourceUrl: image }); });
        }
        if (this.topic === 'EVENT' || this.topic === 'OFFER') {
            post.event = {
                title: form.title,
                schedule: {
                    startDate: this.dateS.dateToJsonDate(form.startDate),
                    endDate: this.dateS.dateToJsonDate(typeof (form.endDate) == 'number' ? form.startDate : form.endDate),
                    endTime: form.endTime && this.dateS.timeToJsonTime(form.endTime) || {},
                    startTime: form.startTime && this.dateS.timeToJsonTime(form.startTime) || {}
                }
            };
            if (_.isEmpty(post.event.schedule.startTime) || _.isEmpty(post.event.schedule.endTime)) {
                delete post.event.schedule.startTime;
                delete post.event.schedule.endTime;
            }
        }
        if (this.topic !== 'OFFER') {
            if (form.type) {
                post.callToAction = { actionType: form.type, url: form.url };
            }
            if (form.type === 'CALL' && this.locationId) {
                delete post.callToAction.url;
            }
            delete post.offer;
        }
        if (this.topic === 'ALERT') {
            post.alertType = 'COVID_19';
            delete post.media;
            delete post.offer;
        }
        return post;
    };
    ModalLocalPostsComponent.prototype.validateForm = function (isValid, form, snack) {
        if (isValid == false) {
            if (snack) {
                this.snackS.openError('Invalid post', 4000);
            }
            this.progress = false;
            return false;
        }
        if (!form) {
            return;
        }
        /*if (!form.image && !isAlert) {
          if (snack) {
            this.snackS.openError('Image post is required', 4000);
          }
    
          return false;
        }*/
        /* if (this.topic === 'OFFER' || this.topic === 'EVENT') {
          if (form.startDate > form.endDate) {
            return false;
          }
        } */
        return true;
    };
    /**
     * this method select topic type event
     * @param $event
     */
    ModalLocalPostsComponent.prototype.handleData = function ($event) {
        this.touched = false;
        switch (this.selectedIndex) {
            case 0:
                this.oneData = $event.value;
                break;
            case 1:
                this.twoData = $event.value;
                this.eventStartDate = this.twoData.startDate && this.twoData.startDate !== "" ? this.twoData.startDate : null;
                this.eventEndDate = this.twoData.endDate && this.twoData.endDate !== "" ? this.twoData.endDate : null;
                break;
            case 2:
                this.threeData = $event.value;
                this.eventStartDate = this.threeData.startDate && this.threeData.startDate !== "" ? this.threeData.startDate : null;
                this.eventEndDate = this.threeData.endDate && this.threeData.endDate !== "" ? this.threeData.endDate : null;
                break;
            case 3:
                this.fourData = $event.value;
                break;
        }
        this.formData = $event.value;
        this.validate($event.valid);
    };
    ModalLocalPostsComponent.prototype.chooseLocations = function () {
        var form = this.form.getData();
        this.validate(form.valid);
        if (this.topic === 'ALERT') {
            this.validFormPost = this.validateForm(this.validFormPost, this.postFormValue, true);
        }
        this.postFormValue = form.value;
        this.locationShow = !this.locationShow;
    };
    ModalLocalPostsComponent.prototype.handleLocations = function ($event) {
        this.accountsSelected = $event;
    };
    ModalLocalPostsComponent.prototype.openSchedule = function () {
        var _this = this;
        var dialogRef = this.modalS.openGenericModal(ModalSchedulePostComponent, { schedule: this.schedule, recurring: this.recurring, postType: this.selectedIndex, publishNow: this.publishNow }, function (result) {
            if (result) {
                if (result.recurring) {
                    _this.schedule = null;
                    _this.recurring = result.data;
                    _this.type = 'RECURRING';
                    _this.publishNow = result.publishNow;
                    _this.duplicatePostAcceptance = result.duplicate;
                }
                else {
                    _this.recurring = null;
                    _this.schedule = result.data;
                    _this.type = 'SCHEDULE';
                }
            }
        }, 470);
        dialogRef.disableClose = true;
    };
    /**
     * close subscriptions
     */
    ModalLocalPostsComponent.prototype.ngOnDestroy = function () {
        this.subsManager$.next(true);
        this.subsManager$.complete();
        this.data = null;
        this.accountsSelected = null;
        this.validFormPost = false;
    };
    ModalLocalPostsComponent.prototype.changeTab = function ($event) {
        var _a;
        this.validFormPost = false;
        this.touched = false;
        var form = (_a = this.form) === null || _a === void 0 ? void 0 : _a.getData();
        this.validate(form.valid);
        this.validFormPost = form.valid;
    };
    ModalLocalPostsComponent.prototype.setView = function (type) {
        switch (type) {
            case 'STANDARD':
                this.selectedIndex = 0;
                break;
            case 'EVENT':
                this.selectedIndex = 1;
                break;
            case 'OFFER':
                this.selectedIndex = 2;
                break;
            case 'ALERT':
                this.selectedIndex = 3;
                break;
        }
    };
    ModalLocalPostsComponent.prototype.back = function () {
        if (!this.validatePlaceholder) {
            this.locationShow = !this.locationShow;
        }
        else {
            this.validatePlaceholder = false;
            this.locationShow = true;
            this.locationsWithoutValidate = [];
        }
    };
    ModalLocalPostsComponent.prototype.checkForErrors = function (data) {
        var hasError = _.get(data, 'lastError', null);
        if (hasError) {
            this.snackS.openInfo('There was a error during the post, check the pending tab', 4000);
        }
    };
    ModalLocalPostsComponent.prototype.changeRecurring = function (checked) {
        if (!checked) {
            this.recurring = null;
            return;
        }
        this.schedule = null;
        this.recurring = {
            time: "00:01",
            frecuency: 'WeeklyOn',
            isAllMonth: false,
            repeatOn: "1",
            nextDate: new Date(),
            duration: 1
        };
        this.nextRecurringDate = this.recurring.nextDate;
    };
    ModalLocalPostsComponent.prototype.handleRecurring = function (recurring) {
        this.recurring = recurring;
        this.calculateNextDate();
    };
    ModalLocalPostsComponent.prototype.handlePublishNow = function (publishNow) {
        this.publishNow = publishNow;
    };
    ModalLocalPostsComponent.prototype.calculateNextDate = function () {
        if (!this.publishNow) {
            this.nextRecurringDate = this.recurring.nextDate;
            return;
        }
        var currentNextDate = moment(this.recurring.nextDate).add(1, 'days').toDate();
        var currentNextDateMoment = getNextReccurringDate(currentNextDate, this.recurring.repeatOn, this.recurring.frecuency, this.recurring.time);
        this.nextRecurringDate = currentNextDateMoment;
    };
    return ModalLocalPostsComponent;
}());
export { ModalLocalPostsComponent };
