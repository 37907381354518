import { Component, Input, OnInit, ViewChild, OnDestroy, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { MatDrawer, MatTabChangeEvent } from '@angular/material';
import { PostManagementListComponent } from './post-management-list/post-management-list.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { SnackbarService } from '../../services/snackbar.service';
import { ISubscription } from 'src/app/constants/subscription';
import { GROUP_SUBSCRIPTION_TYPE } from '../../constants/firestore/account-location';
import { IS_IMPERSONATING } from 'src/app/constants/session';
import { PostService } from 'src/app/services/post.service';

@Component({
  selector: 'app-post-management',
  templateUrl: './post-management.component.html',
  styleUrls: ['./post-management.component.scss']
})
export class PostManagementComponent implements OnInit, OnDestroy {
  @ViewChild('tabPosted', {static: false}) tabPosted: PostManagementListComponent;
  @ViewChild('tabArchive', {static: false}) tabArchive: PostManagementListComponent;

  @Input() dashboardType: string;
  @Input() location = {};
  
  public isSpinner = false;
  public isPostSliderOpened = false;
  public isImpersonating = false;
  public selected = new FormControl(0);
  public accountId: string;
  public locationId: string;
  public GROUP_SUBSCRIPTION_TYPE = GROUP_SUBSCRIPTION_TYPE;
  public subscription$: Observable<ISubscription> = this._authS.subscription$;
  public postToEdit = {};
  public updateList = false;
  public isEdit = false;
  public isBulkPost: boolean;
  public focusTab$ = new BehaviorSubject(0);
  
  constructor(
    private _route: ActivatedRoute,
    private _authS: AuthService,
    private _snack: SnackbarService,
    private _cdRef: ChangeDetectorRef,
    private _postS: PostService,
    private _renderer2: Renderer2,
  ) {}
  
  ngOnDestroy(): void {
  }
  
  ngOnInit(): void {
    this.isImpersonating = !!localStorage.getItem(IS_IMPERSONATING);
    this.isBulkPost = this.dashboardType === 'BULK_POST';
    
    if(!this.isBulkPost) {
      this.accountId = this._route?.parent?.snapshot?.params?.accountId;
      this.locationId = this._route?.parent?.snapshot?.params?.locationId;
    }
  }
  
  create(): void {}
  
  openSlider(drawer: MatDrawer, element = {}): void {
    const body = document.getElementsByTagName('body')?.[0];
    this._renderer2.addClass(body, 'overflow--hidden');

    this.isPostSliderOpened = true;
    this.updateList = null;
    this.postToEdit = element;
    drawer.toggle();
  }
  
  closeSlider(update?: boolean): void {
    this.postToEdit = {};
    this.isPostSliderOpened = false;
    
    if(update) {
      this.updateList = update;
    }
    const body = document.getElementsByTagName('body')?.[0];
    this._renderer2.removeClass(body, 'overflow--hidden');

    this._cdRef.detectChanges();
  }

  editElement(data, drawer): void {
    this.openSlider(drawer, data);
  }
  
  tabChange($event: MatTabChangeEvent): void {
    this.refreshPostsByIndex($event.index);
  }
  
  indexChange($event: number): void {
    this.focusTab$.next($event)
    this.selected.setValue($event)
  }
  
  refreshPostsByIndex(index:number){
    switch (index) {
      case 0:
        this.dashboardType = this.isBulkPost ? 'BULK_POST' : 'LOCAL_POST';
        this.tabPosted.refresh();
        this._cdRef.detectChanges();
        break;
      default:
        this.dashboardType = 'ARCHIVE';
        this.tabArchive.refresh();
        this._cdRef.detectChanges();
        break;
    }
  }
  
  isMember() {
    return this._authS.session.role?.toLowerCase() === 'member'
  }

  postFetch(): void {
    this.isSpinner = true
    this._postS.retrievePost(this._authS?.session?.gid, this.accountId, this.locationId).subscribe(
      res => {
        this._snack.openSuccess('the recovery was successful', 2000);
        this.updateList = true;
        this.isSpinner = false;
      },
      err => {
        this.isSpinner = false;
        this.updateList = false;
        this._snack.openError(`There was a problem while trying to recover the posts`, 2000);
      }
    )
  }

  get tooltip(): string {
    return this.isBulkPost ? 'Create, publish and view the status of bulk posts.' : "Create, publish and view the status of this location's posts.";
  }

}