import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule, VERSION, isDevMode } from '@angular/core';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_DATE_FORMATS,
  MAT_SNACK_BAR_DATA,
  MatAutocompleteModule,
  MatBadgeModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorIntl,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatGridListModule
} from '@angular/material';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireModule, FirebaseOptionsToken } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { SWIPER_CONFIG, SwiperConfigInterface, SwiperModule } from 'ngx-swiper-wrapper';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxStripeModule, StripeService } from 'ngx-stripe';
import { TimeAgoPipe } from 'time-ago-pipe';
import { ChartsModule } from 'ng2-charts';
import { appRoutes } from './app.router';
import { AppComponent } from './app.component';
import { MainComponent } from './modules/main/main.component';
import { HeaderComponent } from './modules/header/header.component';
import { AppConfigService } from './app.config';
import {
  AccountsComponent,
  DialogAccountsComponent,
  DialogDeleteAccountComponent,
  DialogAccountReauthComponent
} from './modules/accounts/accounts.component';
import {
  LocationsComponent
} from './modules/locations/locations.component';
import { LocalPostsComponent } from './modules/local-posts/local-posts.component';
import {
  ReviewAutoResponderComponent,
  TruncatePipe
} from './modules/review-auto-responder/review-auto-responder.component';
import { RollupReportComponent } from './modules/rollup-report/rollup-report.component';
import { ReviewReportsComponent } from './modules/review-reports/review-reports.component';
import { ComparisonReportsComponent } from './modules/comparison-reports/comparison-reports.component';
import { RevenueReportsComponent } from './modules/revenue-reports/revenue-reports.component';
import { SnackBarComponent } from './modules/snack-bar/snack-bar.component';
import { UpgradeComponent } from './modules/upgrade/upgrade.component';
import { BlankLayoutComponent } from './layouts/blank-layout';
import { DefaultLayoutComponent } from './layouts/default-layout';
import { LoginComponent } from './modules/login/login.component';
import { AuthService } from './services/auth.service';
import { AuthProxyService } from './services/auth.proxy.service';
import { AccountService } from './services/account.service';
import { GoogleService } from './services/google.service';
import { WhiteLabelService } from './services/white-label.service';
import { RegisterComponent } from './modules/register/register.component';
import { LocationService } from './services/location.service';
import { SpinnerComponent } from './components/spinner.component';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { SubscriptionService } from './services/subscription.service';
import { FirestoreService } from './services/firestore.service';
import {
  ReviewReportInsightComponent
} from './modules/review-report-insight/review-report-insight.component';
import { ReportsFilterComponent } from './modules/reports-filter/reports-filter.component';
import {
  ReportsFilterTableComponent
} from './modules/reports-filter-table/reports-filter-table.component';
import {
  RevenueReportInsightComponent
} from './modules/revenue-report-insight/revenue-report-insight.component';
import { ReviewReportTableComponent } from './modules/review-report-list/review-report-list.component';
import { ComparisonReportInsightComponent } from './modules/comparison-report-insight/comparison-report-insight.component';
import { TableMultilocationsComponent } from './modules/table-multilocations/table-multilocations.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { SidebarComponent } from './modules/sidebar/sidebar.component';
import { DashboardInsightsComponent } from './modules/dashboard-insights/dashboard-insights.component';
import { DashboardReviewsComponent } from './modules/dashboard-reviews/dashboard-reviews.component';
import { DashboardPostsComponent } from './modules/dashboard-posts/dashboard-posts.component';
import { DashboardLocationComponent } from './modules/dashboard-location/dashboard-location.component';
import { DashboardGradeComponent } from './modules/dashboard-grade/dashboard-grade.component';
import { DashboardWidgetComponent } from './modules/dashboard-widget/dashboard-widget.component';
import { DashboardReviewLinkComponent } from './modules/dashboard-review-link/dashboard-review-link.component';
import { DashboardSearchComponent } from './modules/dashboard-search/dashboard-search.component';
import { ModalCompanyNameComponent } from './modules/modal-company-name/modal-company-name.component';
import { ModalCompanyAddressComponent } from './modules/modal-company-address/modal-company-address.component';
import { ModalServiceComponent } from './modules/modal-service/modal-service.component';
import { ModalRegularHoursComponent } from './modules/modal-regular-hours/modal-regular-hours.component';
import { ModalSpecialHoursComponent } from './modules/modal-special-hours/modal-special-hours.component';
import { ModalPhoneComponent } from './modules/modal-phone/modal-phone.component';
import { ModalUrlComponent } from './modules/modal-url/modal-url.component';
import { ModalProductsComponent } from './modules/modal-products/modal-products.component';
import { ModalAttributesComponent } from './modules/modal-attributes/modal-attributes.component';
import { ModalOpenDateComponent } from './modules/modal-date/modal-open-date.component';
import { ModalPhotosComponent } from './modules/modal-photos/modal-photos.component';
import { ModalStoreCodeComponent } from './modules/modal-store-code/modal-store-code.component';
import { ModalLabelsComponent } from './modules/modal-labels/modal-labels.component';
import { ModalGoogleAdsComponent } from './modules/modal-google-ads/modal-google-ads.component';
import { ModalDeleteComponent } from './modules/modal-delete/modal-delete.component';
import { ModalEditComponent } from './modules/modal-edit/modal-edit.component';
import { ModalUpgradeComponent } from './modules/modal-upgrade/modal-upgrade.component';
import { ModalCategoryComponent } from './modules/modal-category/modal-category.component';
import { ModalServiceAreaComponent } from './modules/modal-service-area/modal-service-area.component';
import { ModalServiceItemComponent } from './modules/modal-service-item/modal-service-item.component';
import { ModalProductsCollectionComponent } from './modules/modal-products-collection/modal-products-collection.component';
import { ModalProductsCollectionEditComponent } from './modules/modal-products-collection-edit/modal-products-collection-edit.component';
import { ModalProductsItemComponent } from './modules/modal-products-item/modal-products-item.component';
import { ModalProductsItemEditComponent } from './modules/modal-products-item-edit/modal-products-item-edit.component';
import { DashboardWorkshopComponent } from './modules/dashboard-workshop/dashboard-workshop.component';
import { WorkshopObservationComponent } from './modules/dashboard-workshop/workshop-observation/workshop-observation.component';
import { TopMetricCarrouselItemComponent } from './modules/dashboard-workshop/workshop-top-metric/top-metric-carrousel-item.component';
import { ChartProgressComponent } from './modules/charts/chart-progress/chart-progress.component';
import { ForgotPasswordComponent } from './modules/forgot-password/forgot-password.component';
import { UserService } from './services/user.service';
import { ChartLineComponent } from './modules/charts/chart-line/chart-line.component';
import { ChartDonutsComponent } from './modules/charts/chart-donut/chart-donut.component';
import { ChartMultiComponent } from './modules/charts/chart-multi/chart-multi.component';
import { ChartStatsComponent } from './modules/charts/chart-stats/chart-stats.component';
import { ReportsComponent } from './modules/reports/reports.component';
import { DateChartPipe } from './pipes/date-chart.pipe';
import { ModalFetchComponent } from './modules/modal-fetch/modal-fetch.component';
import { ModalCreateReportComponent } from './modules/modal-create-report/modal-create-report.component';
import { ReportListComponent } from './modules/report-table/report-list.component';
import { DatePickerComponent } from './modules/date-picker/date-picker.component';
import { ComparisonExpansionComponent } from './modules/comparison-expansion/comparison-expansion.component';
import { GradeItemComponent } from './modules/grade-item/grade-item.component';
import { GradeCardComponent } from './modules/grade-card/grade-card.component';
import { ToggleLocationsInAccountComponent } from './modules/toggle-locations-in-account/toggle-locations-in-account.component';
import { ModalCreateProtocolComponent } from './modules/modal-create-protocol/modal-create-protocol.component';
import { ModalStepperComponent } from './modules/modal-stepper/modal-stepper.component';
import { CheckMetricsComponent } from './modules/check-metrics/check-metrics.component';
import { FormCreateProtocolComponent } from './modules/form-create-protocol/form-create-protocol.component';
import { ImageFailbackDirective } from './directives/image-failback.directive';
import { ButtonComponent } from './modules/button/button.component';
import { ModalServiceSectionComponent } from './modules/modal-service-section/modal-service-section.component';
import { HttpAuthInterceptor } from './interceptors/http-auth.interceptor';
import { HttpDomainInterceptor } from './interceptors/http-domain.interceptor';
import { LocationNameComponent } from './modules/location-info/location-name/location-name.component';
import { LocationPrimaryCategComponent } from './modules/location-info/location-primary-categ/location-primary-categ.component';
import { LocationPhoneComponent } from './modules/location-info/location-phone/location-phone.component';
import { LocationAddressComponent } from './modules/location-info/location-address/location-address.component';
import { LocationRegularHoursComponent } from './modules/location-info/location-regular-hours/location-regular-hours.component';
import { LocationSpecialHoursComponent } from './modules/location-info/location-special-hours/location-special-hours.component';
import { LocationUrlComponent } from './modules/location-info/location-url/location-url.component';
import { TopicTypePipe } from './pipes/topic-type.pipe';
import { ModalLocalPostsComponent } from './modules/modal-local-posts/modal-local-posts.component';
import { FormPostComponent } from './modules/form-post/form-post.component';
import { DateJsonPipe } from './pipes/date-json.pipe';
import { LocationAttributesComponent } from './modules/location-info/location-attributes/location-attributes.component';
import { SharedModule } from './shared/shared-modules';
import { EventMessageService } from './services/event-message.service';
import { ModalService } from './services/modal.service';
import { AlertComponent } from './components/alert.component';
import {LoadComponent} from './components/load.component';
import { ConfirmComponent } from './components/confirm.component';
import { FeaturedComponent } from './components/featured.component';
import { FeaturedDatePickerComponent } from './components/featured-datepicker.component';
import { FeaturedReportComponent } from './components/featured-report.component';
import { GoogleLocationInfoComponent } from './components/google-location-info.component';
import { UpgradeLocationsComponent } from './components/upgrade-locations';
// import { UltimateComponent } from './components/ultimate.component';
import { EditLocationMetaComponent } from './components/edit-location-meta.component';
import { PostsErrorsComponent } from './components/posts-errors';
import { ModalSchedulePostComponent } from './modules/modal-schedule-post/modal-schedule-post.component';
import { BulkPostComponent } from './modules/bulk-post/bulk-post.component';
import { ModalAttributeUrlComponent } from './modules/modal-attribute-url/modal-attribute-url.component';
import { ModalShareComponent } from './modules/modal-share/modal-share.component';
import { UserDialogComponent } from './settings/users-management/user-dialog/user-dialog.component';
import { DeleteUserDialogComponent } from './settings/users-management/delete-user-dialog/delete-user-dialog.component';
import { UpdateCardComponent } from './settings/billing/update-card/update-card.component';
// import { EditCardComponent } from './settings/billing/edit-card/edit-card.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { environment } from '@environment';
import { PopularTimeChartComponent } from './modules/charts/chart-bar/popular-time-chart.component';
import { GoogleLocationDirective } from './directives/google-location-info.directive';
import { GroupGatedPaidDirective, GroupGatedTrialDirective, GroupGatedBulkUpdateAddOnDirective } from './directives/group-gated.directive';
import { LocationGatedBasicDirective, LocationGatedFreeDirective, LocationGatedUltimateTrialDirective } from './directives/location-gated.directive';
import { HourAmPmSchedulePipe } from './pipes/hour-am-pm-schedule.pipe';
import { RefreshLockCheckDirective } from './directives/refresh-lock-check.directive';
import { GradeHeaderLocationComponent } from './modules/grade-header-location/grade-header-location.component';
import { GradeComparisonComponent } from './modules/grade-comparison/grade-comparison.component';
import { GradeMetricsComponent } from './modules/grade-metrics/grade-metrics.component';
import { NoDataComponent } from './modules/no-data/no-data.component';
import { ProtocolLogComponent } from './modules/protocol-log/protocol-log.component';
import { TableComponent } from './modules/table/table.component';
import { ModalReplyReviewComponent } from './modules/reply-edit/reply-edit.component';
import { DialogTemplateComponent } from './modules/dialog-template/dialog-template.component';
import { SkeletonLocationComponent } from './modules/skeletons/skeleton-location/skeleton-location.component';
import { SkeletonAccountsComponent } from './modules/skeletons/skeleton-accounts/skeleton-accounts.component';
import { SkeletonReportsComponent } from './modules/skeletons/skeleton-reports/skeleton-reports.component';
import { SkeletonPostsComponent } from './modules/skeletons/skeleton-posts/skeleton-posts.component';
import { SkeletonProtocolComponent } from './modules/skeletons/skeleton-protocol/skeleton-protocol.component';
import { SkeletonProtocolLogComponent } from './modules/skeletons/skeleton-protocol-log/skeleton-protocol-log.component';
import { SkeletonWorkshopComponent } from './modules/skeletons/skeleton-workshop/skeleton-workshop.component';
import { SkeletonReviewsComponent } from './modules/skeletons/skeleton-reviews/skeleton-reviews.component';
import { SkeletonReviewReportComponent } from './modules/skeletons/skeleton-review-report/skeleton-review-report.component';
import { SkeletonInsightsComponent } from './modules/skeletons/skeleton-insights/skeleton-insights.component';
import { SkeletonLocationInfoComponent } from './modules/skeletons/skeleton-location-info/skeleton-location-info.component';
import { SkeletonGradeComponent } from './modules/skeletons/skeleton-grade/skeleton-grade.component';
import { SkeletonComparisonComponent } from './modules/skeletons/skeleton-comparison/skeleton-comparison.component';
import { SkeletonTableMultilocationsComponent } from './modules/skeletons/skeleton-table-multilocations/skeleton-table-multilocations.component';
import { DashboardRevenueComponent } from './modules/dashboard-revenue/dashboard-revenue.component';
import { BoxComponent } from './modules/box/box.component';
import { SkeletonRevenueComponent } from './modules/skeletons/skeleton-revenue/skeleton-revenue.component';
import { ExternalGradeComponent } from './modules/external-grade/external-grade.component';
import { AccountLocSelectorComponent } from './modules/account-loc-selector/account-loc-selector.component';
import { SkeletonLocationInfoBoxComponent } from './modules/skeletons/skeleton-location-info/skeleton-location-info-box/skeleton-location-info-box.component';
import { LocationUrlAttributesComponent } from './modules/location-info/location-url-attributes/location-url-attributes.component';
import { LocationDescriptionComponent } from './modules/location-info/location-description/location-description.component';
import { ModalDescriptionComponent } from './modules/modal-description/modal-description.component';
import { ReviewLinkGeneratorComponent } from './components/review-link-generator.component';
import { GradeExplainSectionComponent } from './modules/grade-explain-section/grade-explain-section.component';
import { GradeExplainArticleComponent } from './modules/grade-explain-article/grade-explain-article.component';
import { GradeExplainComponent } from './modules/grade-explain/grade-explain.component';
import { TextReaderComponent } from './modules/text-reader/text-reader.component';
import { ModalDeleteMediaComponent } from './modules/modal-delete-media/modal-delete-media.component';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { ZuluToDatePipe } from './pipes/zulu-to-date.pipe';
import { ReviewsAverageRatingComponent } from './modules/reviews-average-rating/reviews-average-rating.component';
import { ReviewsNumberComponent } from './modules/reviews-number/reviews-number.component';
import { ModalShowMediaComponent } from './modules/modal-show-media/modal-show-media.component';
import { AttributtesFetchPipe } from './pipes/attributtes-fetch.pipe';
import { AppPaginationFirestoreComponent } from './modules/app-pagination-firestore/app-pagination-firestore.component';
import { UserSettingDialogComponent } from './settings/users-management/user-setting-dialog/user-setting-dialog.component';
import { CurrencyPipe, DecimalPipe, DOCUMENT } from '@angular/common';
import { GroupService } from "./services/group.service";
import { OTimeAgoPipe } from './pipes/o-time-ago.pipe';
import { AutoReplyReviewErrorComponent } from './components/auto-reply-review-error.component';
import { ChangePlanLocationComponent } from './modules/change-plan-location/change-plan-location.component';
import { ProtocolReportComponent } from './modules/protocol-report/protocol-report.component';
import { ReviewsNumberProtocolsComponent } from './modules/reviews-number-protocols/reviews-number-protocols.component';
import { ResumeCreateProtocolComponent } from './modules/resume-create-protocol/resume-create-protocol.component';
import { ProtocolScanComponent } from './modules/protocol-scan/protocol-scan.component';
import { ProtocolListComponent } from './modules/protocol-list/protocol-list.component';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { LocationProductsComponent } from './modules/location-info/location-products/location-products.component';
import { FooterComponent } from './modules/footer/footer.component';
import { PostListComponent } from './modules/local-posts/post-list/post-list.component';
import { VerifyEmailComponent } from './modules/verify-email/verify-email.component';
import { LocationInfoListComponent } from './modules/location-info-list/location-info-list.component';
import { ModalBulkLocationInfoComponent } from './modules/modal-bulk-location-info/modal-bulk-location-info.component';
import { ModalViewBulkLocationsChangesComponent } from './modules/modal-view-bulk-locations-changes/modal-view-bulk-locations-changes.component';
import { SkeletonLocationInfoListComponent } from './modules/skeletons/skeleton-location-info-list/skeleton-location-info-list.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ScrollableDirective } from './directives/scrollable.directive';
import { ModalAddServiceComponent } from './modules/modal-add-service/modal-add-service.component';
import { ModalMenuComponent } from './modules/modal-menu/modal-menu.component';
import { ModalMenuItemComponent } from './modules/modal-menu-item/modal-menu-item.component';
import { LocationStateComponent } from './modules/location-info/location-state/location-state.component';
import { DashboardFilterComponent } from './modules/dashboard-filter/dashboard-filter.component';
import { ServicesLocationComponent } from './modules/dashboard-location/services-location/services-location.component';
import { MenuLocationComponent } from './modules/dashboard-location/menu-location/menu-location.component';
import { PhotosLocationComponent } from './modules/dashboard-location/photos-location/photos-location.component';
import { FrecuencySelectorComponent } from './components/frecuency-selector/frecuency-selector.component';
import { FrecuencySelectorReportComponent } from './components/frecuency-selector-report/frecuency-selector.component';
import { DashboardServicesComponent } from './modules/dashboard-services/dashboard-services.component';
import { DashboardPhotosComponent } from './modules/dashboard-photos/dashboard-photos.component';
import { DashboardMenuComponent } from './modules/dashboard-menu/dashboard-menu.component';
import { AcceptFormatImagePipe } from './pipes/accept-format-image.pipe';
import { ModalFormSectionComponent } from './modules/dashboard-location/menu-location/modal-form-section/modal-form-section.component';
import { ModalFormMenuComponent } from './modules/dashboard-location/menu-location/modal-form-menu/modal-form-menu.component';
import { ModalFormItemComponent } from './modules/dashboard-location/menu-location/modal-form-item/modal-form-item.component';
import { DialogDeleteLocationsComponent } from './components/dialog-delete-locations.component';
import { LoadModalComponent } from './components/load-modal/load-modal.component';
import { CalculateRecurringComponent } from './components/calculate-recurring/calculate-recurring.component';
import { MatTreeModule } from '@angular/material/tree';
import { RecurringComponent } from './modules/modal-schedule-post/recurring/recurring.component';
import { SpinnerService } from './services/spinner.service';
import { ImpersonateMessageComponent } from './modules/impersonate-message/impersonate-message.component';
import { LocationMoreHoursComponent } from './modules/location-info/location-more-hours/location-more-hours.component';
import { ModalMoreHoursComponent } from './modules/location-info/modal-more-hours/modal-more-hours.component';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { ErrorMessageComponent } from './modules/error-message/error-message.component';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { DashboardPerformanceInsightComponent } from './modules/dashboard-performance-insight/dashboard-performance-insight.component';
import { SearchKeywordsComponent } from './modules/search-keywords/search-keywords-report/search-keywords.component';
import { SkeletonSearchKeywordsComponent } from './modules/skeletons/skeleton-search-keywords/skeleton-search-keywords.component';
import { SkeletonQuestionsAnswerComponent } from './modules/skeletons/skeleton-questions-answer/skeleton-questions-answer.component';
import { SearchKeywordsViewComponent } from './modules/search-keywords/search-keywords-view/search-keywords-view.component';
import { UserFeatureDirective } from './directives/user-feature.directive';
import { SlideCreateReportComponent } from './modules/slide-create-report/slide-create-report.component';
import { MultilocationsInfoTableComponent } from './modules/multilocations-info-table/multilocations-info-table.component';
import { ReplacePipe } from './pipes/replace.pipe';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { DateRangePickerModule } from '@syncfusion/ej2-angular-calendars';
import { BuildPercentageFormatPipe } from './pipes/build-percentage-format.pipe';
import { QuestionsAnswerComponent } from './modules/questions-answer/questions-answer.component';
import { SliderQuestionsComponent } from './modules/questions-answer/slider-questions/slider-questions.component';
import { DialogLocationsComponent } from './modules/accounts/dialog-locations.component';
import { QuestionsAnswersViewComponent } from './modules/questions-answer/questions-answers-view/questions-answers-view.component';
import { ProfilesComponent } from './modules/profiles/profiles.component';
import { EmailPhoneValidationDirective } from './directives/email-phone-validation.directive';
import { LocationServiceAreaComponent } from './modules/location-info/location-service-area/location-service-area.component';
import { OptionsFilterComponent } from './modules/options-filter/options-filter.component';
import { PostManagementComponent } from './modules/post-management/post-management.component';
import { PostManagementListComponent } from './modules/post-management/post-management-list/post-management-list.component';
import { PostManegementSliderComponent } from './modules/post-management/post-manegement-slider/post-manegement-slider.component';
import { PostManagementStatusComponent } from './modules/post-management/post-management-status/post-management-status.component';
import { GlobalErrorHandler } from './services/error-handle.service';
import { ErrorHandler } from '@angular/core';
import { ModalTrialEnded } from './modules/modal-trial-ended/modal-trial-ended.component';
import { PostManagementDeleteDateModalComponent } from './modules/post-management/post-management-delete-date-modal/post-management-delete-date-modal.component';
import { InfoTooltipComponent } from './modules/info-tooltip/info-tooltip.component';

import { ModalGetAddOn } from './modules/modal-get-addon/modal-get-addon.component';
import { LocationActionLinksComponent } from './modules/location-info/location-action-links/location-action-links.component';
import { ModalActionLinksComponent } from './modules/modal-action-links/modal-action-links.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

const DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'MM/DD/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

// To avoid dependencies issues, we need to create a factory for the GlobalErrorHandler
export function errorHandlerFactory(authService: AuthService) : GlobalErrorHandler {
  return new GlobalErrorHandler(authService);
}

@NgModule({
  declarations: [
    EditLocationMetaComponent,
    ReviewLinkGeneratorComponent,
    ModalLocalPostsComponent,
    TruncatePipe,
    AppComponent,
    MainComponent,
    HeaderComponent,
    AccountsComponent,
    LocationsComponent,
    DashboardWorkshopComponent,
    LocalPostsComponent,
    ReviewAutoResponderComponent,
    RollupReportComponent,
    ReviewReportsComponent,
    ComparisonReportsComponent,
    RevenueReportsComponent,
    DialogLocationsComponent,
    DialogAccountsComponent,
    DialogDeleteLocationsComponent,
    DialogDeleteAccountComponent,
    DialogAccountReauthComponent,
    AccountLocSelectorComponent,
    AlertComponent,
    LoadComponent,
    ConfirmComponent,
    FeaturedComponent,
    FeaturedReportComponent,
    FeaturedDatePickerComponent,
    GoogleLocationInfoComponent,
    UpgradeLocationsComponent,
    // UltimateComponent,
    PostsErrorsComponent,
    SnackBarComponent,
    UpgradeComponent,
    LoginComponent,
    ForgotPasswordComponent,
    DefaultLayoutComponent,
    BlankLayoutComponent,
    ReviewReportInsightComponent,
    RegisterComponent,
    SpinnerComponent,
    EllipsisPipe,
    ReportsFilterComponent,
    ReportsFilterTableComponent,
    RevenueReportInsightComponent,
    ComparisonReportInsightComponent,
    TableMultilocationsComponent,
    DashboardLayoutComponent,
    SidebarComponent,
    DashboardInsightsComponent,
    DashboardReviewsComponent,
    DashboardPostsComponent,
    DashboardLocationComponent,
    DashboardGradeComponent,
    DashboardWidgetComponent,
    DashboardReviewLinkComponent,
    DashboardSearchComponent,
    ModalCompanyNameComponent,
    ModalCompanyAddressComponent,
    ModalServiceComponent,
    ModalRegularHoursComponent,
    ModalSpecialHoursComponent,
    ModalMoreHoursComponent,
    ModalPhoneComponent,
    ModalUrlComponent,
    ModalProductsComponent,
    ModalAttributesComponent,
    ModalOpenDateComponent,
    ModalPhotosComponent,
    ModalStoreCodeComponent,
    ModalLabelsComponent,
    ModalGoogleAdsComponent,
    ModalDeleteComponent,
    ModalEditComponent,
    ModalUpgradeComponent,
    ModalCategoryComponent,
    ModalServiceAreaComponent,
    ModalServiceItemComponent,
    ModalServiceItemComponent,
    ModalProductsCollectionComponent,
    ModalProductsCollectionEditComponent,
    ModalProductsItemComponent,
    ModalProductsItemEditComponent,
    WorkshopObservationComponent,
    TopMetricCarrouselItemComponent,
    ChartProgressComponent,
    ChartLineComponent,
    ChartDonutsComponent,
    ChartMultiComponent,
    ChartStatsComponent,
    ReportsComponent,
    DateChartPipe,
    ModalFetchComponent,
    ModalCreateReportComponent,
    ReportListComponent,
    DatePickerComponent,
    ComparisonExpansionComponent,
    ImageFailbackDirective,
    GradeItemComponent,
    GradeCardComponent,
    ToggleLocationsInAccountComponent,
    ModalCreateProtocolComponent,
    ModalStepperComponent,
    CheckMetricsComponent,
    FormCreateProtocolComponent,
    ButtonComponent,
    ReviewAutoResponderComponent,
    ModalServiceSectionComponent,
    LocationNameComponent,
    LocationPrimaryCategComponent,
    LocationPhoneComponent,
    LocationAddressComponent,
    LocationRegularHoursComponent,
    LocationSpecialHoursComponent,
    LocationUrlComponent,
    TopicTypePipe,
    TimeAgoPipe,
    FormPostComponent,
    DateJsonPipe,
    LocationAttributesComponent,
    BulkPostComponent,
    ModalAttributeUrlComponent,
    ModalShareComponent,
    ModalSchedulePostComponent,
    UserDialogComponent,
    UserSettingDialogComponent,
    DeleteUserDialogComponent,
    UpdateCardComponent,
    // EditCardComponent,
    PopularTimeChartComponent,
    GoogleLocationDirective,
    GroupGatedPaidDirective,
    GroupGatedTrialDirective,
    LocationGatedFreeDirective,
    LocationGatedBasicDirective,
    LocationGatedUltimateTrialDirective,
    HourAmPmSchedulePipe,
    RefreshLockCheckDirective,
    GradeHeaderLocationComponent,
    GradeComparisonComponent,
    GradeMetricsComponent,
    GradeExplainSectionComponent,
    GradeExplainArticleComponent,
    GradeCardComponent,
    GradeExplainComponent,
    NoDataComponent,
    ProtocolLogComponent,
    TableComponent,
    ModalReplyReviewComponent,
    DialogTemplateComponent,
    DashboardRevenueComponent,
    BoxComponent,
    SkeletonLocationComponent,
    SkeletonAccountsComponent,
    SkeletonReportsComponent,
    SkeletonPostsComponent,
    SkeletonProtocolComponent,
    SkeletonProtocolLogComponent,
    SkeletonWorkshopComponent,
    SkeletonReviewsComponent,
    SkeletonReviewReportComponent,
    SkeletonInsightsComponent,
    SkeletonLocationInfoComponent,
    SkeletonGradeComponent,
    SkeletonComparisonComponent,
    SkeletonTableMultilocationsComponent,
    SkeletonRevenueComponent,
    ExternalGradeComponent,
    AccountLocSelectorComponent,
    SkeletonLocationInfoBoxComponent,
    LocationUrlAttributesComponent,
    LocationDescriptionComponent,
    ModalDescriptionComponent,
    GradeExplainComponent,
    GradeExplainArticleComponent,
    GradeExplainSectionComponent,
    ModalDeleteMediaComponent,
    TextReaderComponent,
    VerifyEmailComponent,
    ShortNumberPipe,
    ZuluToDatePipe,
    ReviewsAverageRatingComponent,
    ReviewsNumberComponent,
    ReviewReportTableComponent,
    ModalShowMediaComponent,
    AttributtesFetchPipe,
    AppPaginationFirestoreComponent,
    OTimeAgoPipe,
    AutoReplyReviewErrorComponent,
    ChangePlanLocationComponent,
    ProtocolReportComponent,
    ReviewsNumberProtocolsComponent,
    ResumeCreateProtocolComponent,
    ProtocolScanComponent,
    ProtocolListComponent,
    LocationProductsComponent,
    FooterComponent,
    PostListComponent,
    LocationInfoListComponent,
    ModalBulkLocationInfoComponent,
    ModalViewBulkLocationsChangesComponent,
    SkeletonLocationInfoListComponent,
    ScrollableDirective,
    ModalAddServiceComponent,
    ModalMenuComponent,
    ModalMenuItemComponent,
    LocationStateComponent,
    DashboardFilterComponent,
    ServicesLocationComponent,
    MenuLocationComponent,
    PhotosLocationComponent,
    FrecuencySelectorComponent,
    FrecuencySelectorReportComponent,
    DashboardServicesComponent,
    DashboardPhotosComponent,
    DashboardMenuComponent,
    AcceptFormatImagePipe,
    ModalFormSectionComponent,
    ModalFormMenuComponent,
    ModalFormItemComponent,
    LoadModalComponent,
    CalculateRecurringComponent,
    RecurringComponent,
    ImpersonateMessageComponent,
    LocationMoreHoursComponent,
    ErrorMessageComponent,
    DashboardPerformanceInsightComponent,
    SearchKeywordsComponent,
    SkeletonSearchKeywordsComponent,
    SkeletonQuestionsAnswerComponent,
    SearchKeywordsViewComponent,
    UserFeatureDirective,
    SlideCreateReportComponent,
    MultilocationsInfoTableComponent,
    ReplacePipe,
    BuildPercentageFormatPipe,
    QuestionsAnswerComponent,
    SliderQuestionsComponent,
    QuestionsAnswersViewComponent,
    ProfilesComponent,
    EmailPhoneValidationDirective,
    LocationServiceAreaComponent,
    OptionsFilterComponent,
    PostManagementComponent,
    PostManagementListComponent,
    PostManegementSliderComponent,
    PostManagementStatusComponent,
    ModalTrialEnded,
    PostManagementDeleteDateModalComponent,
    InfoTooltipComponent,
    ModalGetAddOn,
    GroupGatedBulkUpdateAddOnDirective,
    LocationActionLinksComponent,
    ModalActionLinksComponent
  ],
  imports: [
    NgxIntlTelInputModule,
    GooglePlaceModule,
    NgxSkeletonLoaderModule,
    MatTreeModule,
    RouterModule.forRoot(appRoutes, {
      useHash: false,
      onSameUrlNavigation: 'reload',
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled'
    }),
    NgCircleProgressModule.forRoot({
      radius: 100,
      innerStrokeWidth: 0,
    }),
    InfiniteScrollModule,
    SharedModule,
    AngularFireModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    ReactiveFormsModule,
    BrowserModule,
    NgxJsonViewerModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularSvgIconModule,
    MatStepperModule,
    MatToolbarModule,
    MatButtonModule,
    MatCheckboxModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatRippleModule,
    MatMenuModule,
    MatBadgeModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    FormsModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatSelectModule,
    NgxDaterangepickerMd.forRoot(),
    MatChipsModule,
    MatExpansionModule,
    MatSidenavModule,
    SwiperModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDividerModule,
    MatTabsModule,
    ChartsModule,
    CdkStepperModule,
    MatButtonToggleModule,
    MatListModule,
    MatCardModule,
    NgxStripeModule.forRoot(environment.defaultStripePublicKey),
    QRCodeModule,
    DateRangePickerModule,
    DropDownListModule,
  ],
  exports: [
    RouterModule,
  ],
  entryComponents: [
    LoadModalComponent,
    ModalFormSectionComponent,
    ModalFormMenuComponent,
    ModalFormItemComponent,
    ModalViewBulkLocationsChangesComponent,
    ModalAddServiceComponent,
    ModalShowMediaComponent,
    ModalMenuComponent,
    ModalMenuItemComponent,
    ModalReplyReviewComponent,
    ModalDescriptionComponent,
    ModalBulkLocationInfoComponent,
    EditLocationMetaComponent,
    ReviewLinkGeneratorComponent,
    ModalLocalPostsComponent,
    ModalSchedulePostComponent,
    DialogLocationsComponent,
    DialogAccountsComponent,
    DialogDeleteAccountComponent,
    DialogAccountReauthComponent,
    AccountLocSelectorComponent,
    AlertComponent,
    LoadComponent,
    ConfirmComponent,
    AutoReplyReviewErrorComponent,
    FeaturedComponent,
    FeaturedReportComponent,
    FeaturedDatePickerComponent,
    GoogleLocationInfoComponent,
    UpgradeLocationsComponent,
    // UltimateComponent,
    PostsErrorsComponent,
    ModalEditComponent,
    ModalDeleteComponent,
    ModalUpgradeComponent,
    ModalCompanyNameComponent,
    ModalCompanyAddressComponent,
    ModalCategoryComponent,
    ModalServiceAreaComponent,
    ModalServiceComponent,
    ModalServiceSectionComponent,
    ModalServiceItemComponent,
    ModalRegularHoursComponent,
    ModalSpecialHoursComponent,
    ModalMoreHoursComponent,
    ModalPhoneComponent,
    ModalUrlComponent,
    ModalProductsComponent,
    ModalProductsCollectionComponent,
    ModalProductsCollectionEditComponent,
    ModalProductsItemComponent,
    ModalProductsItemEditComponent,
    ModalAttributesComponent,
    ModalOpenDateComponent,
    ModalPhotosComponent,
    ModalStoreCodeComponent,
    ModalLabelsComponent,
    ModalFetchComponent,
    ModalGoogleAdsComponent,
    ModalShareComponent,
    ModalCreateReportComponent,
    ModalAttributeUrlComponent,
    VerifyEmailComponent,
    SnackBarComponent,
    ReportsFilterComponent,
    ReportsFilterTableComponent,
    TableMultilocationsComponent,
    ModalCreateProtocolComponent,
    UserDialogComponent,
    UserSettingDialogComponent,
    DeleteUserDialogComponent,
    UpdateCardComponent,
    // EditCardComponent,
    GradeExplainComponent,
    ModalDeleteMediaComponent,
    AccountLocSelectorComponent,
    ChangePlanLocationComponent,
    DialogDeleteLocationsComponent,
    ModalTrialEnded,
    PostManagementDeleteDateModalComponent,
    ModalGetAddOn,
    ModalActionLinksComponent
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: WhiteLabelDomainInitializerFn,
      multi: true,
      deps: [AppConfigService, WhiteLabelService, Title, DOCUMENT]
    },
    AuthProxyService,
    GroupService,
    AccountService,
    LocationService,
    GoogleService,
    WhiteLabelService,
    AppConfigService,
    FirestoreService,
    SubscriptionService,
    UserService,
    ModalService,
    SpinnerService,
    EventMessageService,
    StripeService,
    CurrencyPipe,
    { provide: FirebaseOptionsToken, deps: [AppConfigService], useFactory: initializeApp },
    { provide: MAT_SNACK_BAR_DATA, useValue: {} },
    { provide: SWIPER_CONFIG, useValue: DEFAULT_SWIPER_CONFIG },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
    { provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpDomainInterceptor, multi: true },
    DecimalPipe,
    { provide: BsDropdownConfig, useValue: { autoClose: true } },
    { provide: ErrorHandler, useFactory: errorHandlerFactory, deps: [AuthService] }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

export function initializeApp(appConfig: AppConfigService) {
  console.log(`Angular version: ${VERSION.full}`);
  console.log('Mode: ' + (isDevMode() ? 'DEVELOPMENT' : 'PRODUCTION'));

  return appConfig.fireConfig();
}


export function WhiteLabelDomainInitializerFn(appConfig: AppConfigService, wl: WhiteLabelService, titleService: Title, html) {
  return () => {
    wl.getDynamicWhiteLabel().then((data: any) => {
      if (environment.apiUrlOverwriteWithWL) {
          environment.apiUrl = data.apiUrl;
      }

      environment.branding               = data.branding;
      environment.hideComparisonInGrader = data.hideComparisonInGrader;
      environment.hideSignUpButton       = data.hideSignUpButton;

      html.getElementById('favicon').setAttribute('href', data.branding.favicon || '/assets/images/favicon-wl/favicon.ico')
    });
  };
}

