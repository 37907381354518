import { EventEmitter, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { DatesService } from "../../services/dates.service";
import { LocationEditService } from "../../services/location-edit.service";
import { FormArray, FormBuilder } from '@angular/forms';
import { HoursAmPmPipe } from "../../pipes/hours-am-pm.pipe";
import * as moment from 'moment';
var ModalSpecialHoursComponent = /** @class */ (function () {
    function ModalSpecialHoursComponent(dialogRef, data, dateS, locationEditService, fb) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.dateS = dateS;
        this.locationEditService = locationEditService;
        this.fb = fb;
        this.previousDay = [];
        this.bulk = false;
        this.validResult = new EventEmitter(false);
        if (data) {
            this.specialHoursItems = this.data.specialDays ? JSON.parse(JSON.stringify(this.data.specialDays)) : [];
            this.previousDay = this.data.previousDay;
        }
        else
            this.specialHoursItems = [];
        this.specialHoursDropdown = this.dateS.loadHoursDropdown();
        this.initForm();
    }
    ModalSpecialHoursComponent.prototype.ngOnInit = function () {
    };
    ModalSpecialHoursComponent.prototype.initForm = function () {
        var _this = this;
        var _a;
        var keys = Object.keys(this.specialHoursItems);
        this.form = this.fb.group({
            days: new FormArray([]),
        });
        if (((_a = keys) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            keys.forEach(function (key) {
                var items = _this.specialHoursItems[key];
                items.forEach(function (item, i) {
                    var isNew = i == 0 ? true : false;
                    _this.newSpecialHours(item.startDate, item.isClosed, item.openTime, item.closeTime, isNew);
                });
            });
        }
    };
    ModalSpecialHoursComponent.prototype.newSpecialHours = function (startDate, isClosed, openTime, closeTime, isNew) {
        var _this = this;
        if (isNew === void 0) { isNew = true; }
        var date;
        if (startDate instanceof Date) {
            date = startDate;
        }
        else {
            date = this.dateS.dateJsonToDate(startDate);
        }
        var hours12 = new HoursAmPmPipe();
        if (isNew) {
            var form = this.fb.group({
                day: [new Date(date)],
                hours: new FormArray([
                    this.fb.group({
                        startDate: [new Date(date)],
                        isOpen: [!isClosed],
                        openTime: [openTime ? hours12.transform(openTime) : ''],
                        closeTime: [closeTime ? hours12.transform(closeTime) : '']
                    })
                ])
            });
            this.days.push(form);
        }
        else {
            this.days.controls.forEach(function (el, i) {
                if (moment(el.value.day).isSame(date)) {
                    var form = _this.fb.group({
                        startDate: new Date(date),
                        isOpen: !isClosed,
                        openTime: openTime ? hours12.transform(openTime) : '',
                        closeTime: closeTime ? hours12.transform(closeTime) : ''
                    });
                    el['controls'].hours.push(form);
                }
            });
        }
    };
    ModalSpecialHoursComponent.prototype.addNewDate = function () {
        this.newSpecialHours(new Date(), true, '', '');
    };
    ModalSpecialHoursComponent.prototype.addNewHour = function (date, f) {
        var form = this.fb.group({
            startDate: new Date(date.value),
            isOpen: true,
            openTime: '',
            closeTime: ''
        });
        f['controls'].hours.push(form);
    };
    ModalSpecialHoursComponent.prototype.deleteDate = function (dayIndex, hoursIndex) {
        var hours = this.days.controls[dayIndex]['controls'].hours;
        if (hours.length > 1) {
            hours.removeAt(hoursIndex);
        }
        else {
            this.days.removeAt(dayIndex);
        }
    };
    ModalSpecialHoursComponent.prototype.apply = function () {
        var _this = this;
        var specialHourPeriods = [];
        this.days.updateValueAndValidity();
        var acceptedDays = this.areAcceptedDays();
        this.isValidForm();
        if (!this.days.valid || !acceptedDays) {
            return;
        }
        this.form.value.days.filter(function (day) {
            day.hours.forEach(function (hour) {
                var _a, _b, _c, _d, _e, _f, _g, _h;
                if (!hour.isOpen) {
                    specialHourPeriods.push({
                        isClosed: !hour.isOpen,
                        startDate: _this.dateS.dateToJsonDate(day.day)
                    });
                }
                else {
                    var open_1 = parseInt((_b = (_a = hour) === null || _a === void 0 ? void 0 : _a.openTime) === null || _b === void 0 ? void 0 : _b.split(':')[0]);
                    var openMeridian = (_d = (_c = hour) === null || _c === void 0 ? void 0 : _c.openTime) === null || _d === void 0 ? void 0 : _d.split(' ')[1];
                    open_1 = open_1 === 12 && openMeridian === 'AM' ? 0 : open_1;
                    var close_1 = parseInt((_f = (_e = hour) === null || _e === void 0 ? void 0 : _e.closeTime) === null || _f === void 0 ? void 0 : _f.split(':')[0]);
                    var closeMeridian = (_h = (_g = hour) === null || _g === void 0 ? void 0 : _g.closeTime) === null || _h === void 0 ? void 0 : _h.split(' ')[1];
                    var endDate = ((openMeridian == 'PM' && closeMeridian == 'AM') ||
                        ((openMeridian == 'AM' && closeMeridian == 'AM') && open_1 > close_1) ||
                        ((openMeridian == 'PM' && closeMeridian == 'PM') && open_1 > close_1 && open_1 != 12) ?
                        (moment(day.day).add(1, 'days')).toDate() :
                        day.day);
                    specialHourPeriods.push({
                        closeTime: _this.dateS.hours12To24(hour.closeTime),
                        endDate: _this.dateS.dateToJsonDate(endDate),
                        openTime: _this.dateS.hours12To24(hour.openTime),
                        startDate: _this.dateS.dateToJsonDate(day.day)
                    });
                }
            });
        });
        if (this.hasChanges(specialHourPeriods)) {
            this.locationEditService.locationEdit.specialHours = { specialHourPeriods: specialHourPeriods };
            this.locationEditService.setAndUpdate().toPromise().then(function () {
                _this.dialogRef.close();
            });
        }
        else {
            this.dialogRef.close();
        }
    };
    ModalSpecialHoursComponent.prototype.hasChanges = function (specialHourPeriods) {
        return JSON.stringify(this.previousDay) != JSON.stringify(specialHourPeriods);
    };
    ModalSpecialHoursComponent.prototype.getResult = function () {
        var _this = this;
        var specialHourPeriods = [];
        this.isValidForm();
        if (!this.days.valid || !this.areAcceptedDays()) {
            this.validResult.emit(false);
            return;
        }
        var specialHours = this.form.value.days;
        if (specialHours.length == 0) {
            this.validResult.emit(false);
            return;
        }
        this.validResult.emit(true);
        this.form.value.days.filter(function (day) {
            day.hours.forEach(function (hour) {
                var _a, _b, _c, _d, _e, _f, _g, _h;
                hour.startDate = _this.dateS.dateToJsonDate(day.day);
                if (!hour.isOpen) {
                    delete hour.openTime;
                    delete hour.closeTime;
                    hour.isClosed = !hour.isOpen;
                }
                else {
                    var open_2 = parseInt((_b = (_a = hour) === null || _a === void 0 ? void 0 : _a.openTime) === null || _b === void 0 ? void 0 : _b.split(':')[0]);
                    var openMeridian = (_d = (_c = hour) === null || _c === void 0 ? void 0 : _c.openTime) === null || _d === void 0 ? void 0 : _d.split(' ')[1];
                    open_2 = open_2 === 12 && openMeridian === 'AM' ? 0 : open_2;
                    var close_2 = parseInt((_f = (_e = hour) === null || _e === void 0 ? void 0 : _e.closeTime) === null || _f === void 0 ? void 0 : _f.split(':')[0]);
                    var closeMeridian = (_h = (_g = hour) === null || _g === void 0 ? void 0 : _g.closeTime) === null || _h === void 0 ? void 0 : _h.split(' ')[1];
                    var endDate = ((openMeridian == 'PM' && closeMeridian == 'AM') ||
                        ((openMeridian == 'AM' && closeMeridian == 'AM') && open_2 > close_2) ||
                        ((openMeridian == 'PM' && closeMeridian == 'PM') && open_2 > close_2 && open_2 != 12) ?
                        (moment(day.day).add(1, 'days')).toDate() :
                        day.day);
                    delete hour.isClosed;
                    hour.endDate = _this.dateS.dateToJsonDate(endDate);
                    hour.openTime = _this.dateS.hours12To24(hour.openTime);
                    hour.closeTime = _this.dateS.hours12To24(hour.closeTime);
                }
                delete hour.isOpen;
                specialHourPeriods.push(hour);
            });
        });
        return { specialHours: { specialHourPeriods: specialHourPeriods } };
    };
    ModalSpecialHoursComponent.prototype.areAcceptedDays = function () {
        var _this = this;
        var condition = true;
        var days = this.days.controls.map(function (el) { return el.value.day; });
        days.forEach(function (el, i) {
            var date = moment(el).format('MMMM/DD/YYYY');
            var items = days.filter(function (d) { return moment(d).format('MMMM/DD/YYYY').toString() == date.toString(); });
            if (items.length > 1) {
                var hours = _this.days.controls[i].value.hours;
                if (hours.length == 1 && !hours[0].isOpen) {
                    condition = false;
                }
            }
        });
        return condition;
    };
    ModalSpecialHoursComponent.prototype.areAcceptedHours = function (hours) {
        var condition = false;
        if (hours.get('isOpen').value) {
            condition = (hours.get('openTime').value == '' || hours.get('closeTime').value == '' ||
                hours.get('openTime').value == '12:00 AM' && hours.get('closeTime').value == '12:00 AM' ||
                hours.get('openTime').value != hours.get('closeTime').value
                ? false : true);
        }
        if (condition) {
            hours.setErrors({ errorHours: true });
        }
        else {
            hours.setErrors(null);
        }
        return condition;
    };
    ModalSpecialHoursComponent.prototype.isValidForm = function () {
        this.days.controls.forEach(function (d) {
            d['controls'].hours.controls.forEach(function (el) {
                if (el.get('openTime').value == '' && el.get('closeTime').value == '' && (el.get('isOpen').value == '' || !el.get('isOpen').value)) {
                    el.get('openTime').setErrors(null);
                    el.get('closeTime').setErrors(null);
                }
            });
        });
    };
    ModalSpecialHoursComponent.prototype.toggleChanged = function (item, action) {
        if (!action.checked) {
            var length_1 = item.controls.hours.value.length;
            for (var i = length_1; i >= 1; i--) {
                item.controls.hours.removeAt(i);
            }
        }
    };
    ModalSpecialHoursComponent.prototype.toggle = function ($event, form) {
        form.setValue(!$event.checked);
    };
    Object.defineProperty(ModalSpecialHoursComponent.prototype, "days", {
        get: function () {
            return this.form.get('days');
        },
        enumerable: true,
        configurable: true
    });
    return ModalSpecialHoursComponent;
}());
export { ModalSpecialHoursComponent };
