import { Component, Input, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTabChangeEvent, MatDialog, MatTabGroup } from '@angular/material';
import { AngularFireStorageReference } from '@angular/fire/storage';
import { take } from 'rxjs/operators';

import { 
  GoogleMediaItem, 
  ModalCategories, 
  CATEGORIES_DATA, 
  imageRequirement, 
  Categories 
} from 'src/app/constants/google-media';
import { TypeImages } from 'src/app/constants/types';
import { Messages } from 'src/app/constants/messages';
import { ModalConfirmData } from 'src/app/classes/modal-confirm-data';
import AccountReport from 'src/app/services/account-report';
import { GoogleService } from 'src/app/services/google.service';
import { StorageImageService } from 'src/app/services/storage-image.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { AuthService } from 'src/app/services/auth.service';
import { LocationService } from 'src/app/services/location.service';
import { FormInputUploadComponent } from '../../form-input-upload/form-input-upload.component';
import { ModalShowMediaComponent } from '../../modal-show-media/modal-show-media.component';

@Component({
  selector: 'app-photos-location',
  templateUrl: './photos-location.component.html',
  styleUrls: ['./photos-location.component.scss']
})
export class PhotosLocationComponent implements OnInit {
  imageUrl = [];
  imageSelectedUrl: string = null;
  previewImage: string = null;
  thumbNailImageUrl: string = null;
  categories: Categories[] = [];
  fileUrl: string;
  description: string;
  selectedFile: any;
  selectedFileName: any;
  listFiles = new ModalCategories();
  fileType: any;
  category: { name: string };
  isUploading: boolean;
  private media: GoogleMediaItem[];
  public percentUpload: number;
  private fileRef: AngularFireStorageReference;
  isProgress = true;
  isProgressPublish: boolean = false;
  selectedCategory: {name: string, value: string} =  null;
  imageRequirements: imageRequirement = {
    accepts: '.png, .jpg, .jpeg, .webp',
    type: ['image/png', 'image/jpg', 'image/jpeg', 'image/webp'],
    min_width: 480,
    min_height: 270,
    max_width: 2120,
    max_height: 1192,
    min_size: 10240,
    minRatio: 1.3
  };
  @Input() accountId: string = null;
  @Input() locationId: string = null;
  @ViewChild('inputUpload', {static: false}) inputUpload: FormInputUploadComponent;
  @ViewChild('tabGroup', { static: true }) tabGroup: MatTabGroup;
  @Input() locationType = '';
  @Input() showOnlyMedia = false;
  @Input() accounts: AccountReport[] = null;
  @Input() mediaFormat: TypeImages;
  @Input() bulk: false;
  @Input() error:any = {};
  @Input() error_details:any = [];

  locationCategories: { restaurant: boolean, hotel: boolean, normal: boolean} = {
    restaurant: false,
    hotel: false,
    normal: false
  };

  imageEmmiter: EventEmitter<string> = new EventEmitter()

  public swiperConfig: any = {
    slidesPerView: 4,
    initialSlide: 0,
    spaceBetween: 30,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    simulateTouch: true,
    slideToClickedSlide: true
  };
  public refSubscribe;
  public deleteSubscribe;

  constructor(
    public dialog: MatDialog,
    private googleS: GoogleService,
    private storageImageS: StorageImageService,
    private snackS: SnackbarService,
    private  activateRoute: ActivatedRoute,
    private auth: AuthService,
    private locationS: LocationService,
  ) {
    this.categories = CATEGORIES_DATA;
    this.category = {name: 'CATEGORY_UNSPECIFIED'};
  }

  ngOnInit() {
    if (!this.selectedCategory) this.selectedCategory = { name: 'COVER', value: 'COVER' };
    if (!this.bulk) {
      if (this.locationType === '') {
        this.locationId = this.activateRoute.snapshot.parent.params.locationId;
        this.accountId = this.activateRoute.snapshot.parent.params.accountId;
        this.locationS.getRef(this.auth.session.gid, this.accountId, this.locationId).pipe(
          take(1)
        ).subscribe( (data) => {
          let tmpCategory = CATEGORIES_DATA;
          const lodging = data.location.locationState?.canOperateLodgingData ? data.location.locationState.canOperateLodgingData : false
          const restaurant = data.location.locationState.canHaveFoodMenus ? data.location.locationState.canHaveFoodMenus : false
          if (!restaurant && !lodging) {
            tmpCategory = tmpCategory.filter( category => category.value !== 'MENU' && category.value !== 'FOOD_AND_DRINK' && category.value !== 'ROOMS' && category.value !== 'COMMON_AREA')
          } else if (!restaurant) {
            tmpCategory = tmpCategory.filter( category => category.value !== 'MENU' && category.value !== 'FOOD_AND_DRINK')
          } else if (!lodging ) {
            tmpCategory = tmpCategory.filter( category => category.value !== 'ROOMS' && category.value !== 'COMMON_AREA')
          }
          this.categories = tmpCategory;
          this.getMedia();
        });
      } else {
        this.getMedia();
      }
    } else {
      if (this.accountId && this.locationId) {
        this.getMedia();
      } else {
        this.isProgress = false;
      }
    }

    this.getRatio();
  }

  ngOnDestroy(): void {
    /* FIXME: smell bad */ 
    if (this.refSubscribe) {
      this.refSubscribe.unsubscribe();
    }
    if (this.deleteSubscribe) {
      this.deleteSubscribe.unsubscribe();
    }
  }

  get VideoList() {
    return this.listFiles['ADDITIONAL'].filter(l => l.mediaFormat === 'VIDEO')
  }

  getMedia() {
    this.listFiles = new ModalCategories();
    this.googleS.getMedia(this.accountId, this.locationId).subscribe(media => {
      this.media = media;
      this.isProgress = false;
      this.media.forEach(x => {
        this.listFiles[x.locationAssociation.category]?.push(x);
      });
    });
  }


  tabChange($event: MatTabChangeEvent, input ) {
    if (this.refSubscribe) {
      this.refSubscribe.unsubscribe();
      this.deleteSubscribe.unsubscribe();
    }
    if (!this.showOnlyMedia) {
      const changeCategory  = $event.tab.textLabel;
      const selectedCategory: {name: string, value: string} = this.categories.find( (a: {name: string, value: string}) => a.name === changeCategory);
      if (selectedCategory.name === 'VIDEO') {
        this.imageRequirements.accepts = '.mp4';
        this.imageRequirements.type = ['video/mp4'];
      } else if (selectedCategory.name === 'COVER' || selectedCategory.name === 'PROFILE') {
        this.imageRequirements.accepts = '.png, .jpg, .jpeg, .webpm';
        this.imageRequirements.type = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp'];
      } else {
        this.imageRequirements.accepts = '.png, .jpg, .jpeg, .webpm, .mp4';
        this.imageRequirements.type = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp', 'video/mp4'];
      }

      this.selectedCategory = selectedCategory;
      this.fileUrl = '';
      this.description = '';
      this.selectedFile = '';
      this.selectedFileName = '';
      this.fileType = '';
      this.clearAll();
    }

    this.getRatio();
  }

  getRatio() {
    this.imageRequirements.minRatio = this.selectedCategory.value === 'COVER' ? 1.7 : 1.3;
  }

  publish(category): void {

    if (!this.imageUrl.length) {
      return;
    }

    this.imageUrl.forEach(el => {
      if (el.fileName === undefined) {
        return;
      }
      this.isProgressPublish = true;
      this.category = category;
      const mediaItem: Partial<GoogleMediaItem> = {
        mediaFormat: this.mediaFormat,
        locationAssociation: {category},
        sourceUrl: el.url,
        description: el.description
      };
      this.saveMedia(mediaItem, category);
    });
  }

  saveMedia(mediaItem, category) {
    this.googleS.saveMedia(this.accountId, this.locationId, mediaItem).subscribe(
      () => {
        this.snackS.openSuccess(Messages.upload.SEND_SUCCESS, 4000);
        this.removeRefs(category);
      },
      (e)=>{
        console.error(e);
        this.snackS.openError('Error publishing the image, try again', 4000);
      }
    );
  }

  removeRefs(category) {
    this.refSubscribe = this.storageImageS.ref$.subscribe(fileRef => {
      this.description = "";
      this.isProgressPublish = false;
      this.deleteSubscribe = fileRef.delete().subscribe(() => {
        this.getMedia();
        this.clearAll();
      });
    });
  }

  fileChanged(target: EventTarget) {
    this.storageImageS.fileChanged(target as HTMLInputElement, true, null, this.locationId);

    this.storageImageS.url$.subscribe(url => {
      if (url) {
        this.imageSelectedUrl = url.url;
        this.thumbNailImageUrl = url.preview;
        this.mediaFormat = url.type;
        this.fileUrl = url.url;
      }

    });
    this.storageImageS.ref$.subscribe(fileRef => {
      this.fileRef = fileRef;
    });
  }


  handleUrl($event, added: boolean = false) {
    if (!$event) return;
    const url = $event.url;
    this.mediaFormat = $event.mediaFormat;
    this.isUploading = true;
    const indexImage = this.getimage(url);

    if (this.imageSelectedUrl && (indexImage !== -1)) {
      this.imageSelectedUrl = url;
      this.previewImage = url;
      if ($event.preview) this.previewImage = $event.preview
      else this.previewImage = url
    } else {
      if (indexImage === -1) {
        const index = this.imageUrl.length > 0 ? this.imageUrl.length : 0;
        this.imageUrl.push({
          url: url,
          preview: $event.mediaFormat == "VIDEO" ? $event.preview : url,
          description: null,
          fileName: $event.fileName
        });
        if (!added) {
          this.imageSelectedUrl = this.imageUrl[index].url;
        }
      }
    }

    this.fileUrl = url;
    if (this.fileUrl) {
      this.isUploading = false;
    }
  }

  getimage(data) {
    return this.imageUrl.findIndex(i => i.url === data);
  }

  changeDescription() {
    const index = this.getimage(this.imageSelectedUrl);
    if (index > -1) { this.imageUrl[index].description = this.description; } 
  }

  changePreview(url) {
    if (!url) { return }
    const index = this.getimage(url);
    this.description = (index != -1 ? this.imageUrl[index].description : null);
    this.imageSelectedUrl = url;
    this.storageImageS.setImageUrl(url);
  }

  clearImagen() {
    const indexImage = this.getimage(this.imageSelectedUrl);
    this.imageUrl.splice(indexImage, 1);
  }

  removeImagen() {
    this.clearImagen();
    this.changePreview(this.imageUrl[0]?.url || null);
    if (!this.imageUrl.length) { this.inputUpload.reset(); }
  }

  clearAll() {
    this.imageSelectedUrl = null;
    this.imageUrl = [];
    this.inputUpload.reset();
  }

  addNewImage() {
    this.imageSelectedUrl = null;
    this.imageUrl.push({url: '/assets/images/camera.svg', preview: '/assets/images/camera.svg', fileName: null, description: null});
    this.changePreview('/assets/images/camera.svg');
  }

  showMedia(filelist, file, category, index) {
    const dialogRef = this.dialog.open(ModalShowMediaComponent,
      {
        width: '680px',
        data: new ModalConfirmData({
          data: {filelist: filelist, media: file, category, index, locationId: this.locationId, accountId: this.accountId},
          title: null,
          content: null,
          confirmButtonLabel: 'Save',
          closeButtonLabel: 'Cancel'
        })
    });
    dialogRef.disableClose = true;
    const Subs = dialogRef.componentInstance.onDelete.filter( item => item !== null).subscribe( item => {
      this.getMedia();
    })

    dialogRef.afterClosed().take(2).subscribe(() => {
      // unsubscribe onAdd
      Subs.unsubscribe();
    });
  }

  getResult(): Partial<GoogleMediaItem>[]  {
    if (!this.selectedCategory?.value) {
      return;
    }
    return this.imageUrl.map((el) => {
      const mediaItem: Partial<GoogleMediaItem> = {
        mediaFormat: this.mediaFormat,
        locationAssociation: {category: this.selectedCategory.value},
        sourceUrl: el.url,
        description: el.description
      };
      return mediaItem;
    });
  }
}
