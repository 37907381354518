import { SnackBarComponent } from '../modules/snack-bar/snack-bar.component';
import { MatSnackBar } from '@angular/material';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
var SnackType;
(function (SnackType) {
    SnackType["SUCCESS"] = "success";
    SnackType["ERROR"] = "error";
    SnackType["INFO"] = "info";
    SnackType["WARNING"] = "warning";
})(SnackType || (SnackType = {}));
var SnackbarService = /** @class */ (function () {
    function SnackbarService(snackBar) {
        this.snackBar = snackBar;
    }
    SnackbarService.prototype.openSuccess = function (message, duration) {
        this.open(message, duration, 'right', 'top', SnackType.SUCCESS);
    };
    SnackbarService.prototype.openError = function (message, duration) {
        if (duration === void 0) { duration = 4000; }
        this.open(message, duration, 'right', 'top', SnackType.ERROR);
    };
    SnackbarService.prototype.openInfo = function (message, duration) {
        this.open(message, duration, 'right', 'top', SnackType.INFO);
    };
    SnackbarService.prototype.openWarning = function (message, duration) {
        this.open(message, duration, 'right', 'top', SnackType.WARNING);
    };
    SnackbarService.prototype.open = function (message, duration, horizontalPosition, verticalPosition, panelClass) {
        this.snack = this.snackBar.openFromComponent(SnackBarComponent, {
            data: {
                message: message,
                panelClass: panelClass
            },
            panelClass: panelClass,
            duration: duration,
            horizontalPosition: horizontalPosition,
            verticalPosition: verticalPosition,
        });
    };
    SnackbarService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SnackbarService_Factory() { return new SnackbarService(i0.ɵɵinject(i1.MatSnackBar)); }, token: SnackbarService, providedIn: "root" });
    return SnackbarService;
}());
export { SnackbarService };
