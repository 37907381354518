import { Component, Input, OnDestroy, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import { Subscription } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';

import { ReviewsService } from '../../services/reviews.service';
import { ChartDataSets } from 'chart.js';
import { DataPicker } from '../../constants/data-picker';
import { LoadingService } from 'src/app/services/loading.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-reviews-average-rating',
  templateUrl: './reviews-average-rating.component.html',
  styleUrls: ['./reviews-average-rating.component.scss'],

})
export class ReviewsAverageRatingComponent implements OnInit, OnDestroy, OnChanges {


  @Input() locations;
  @Input() dataPicker: DataPicker;
  @Input() refreshFilter;
  @Input() averageData;
  @Input() tooltip = null;

  public aggregationRating$: Subscription;
  public datasetLineRating: ChartDataSets[];
  public labelsLineRating: any;
  public avgRating: number;
  public isProgressRating = true;

  constructor(
    private _reviewsS: ReviewsService,
    private _loadingService: LoadingService,
    private _router: Router,
    private _auth: AuthService
  ) {
  }

  ngOnInit() {
    this.refreshFilter?.subscribe(result => {
      this.dataPicker = result;
      this.getData();
    });
    this.getData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.averageData) {
      this.getData();
    }
  }

  getData() {
    const isReport = this._router.url.includes('report');

    this.isProgressRating = true;
    if (!this.locations || !this.dataPicker) {
      return;
    }

    if (this.averageData) {
      this.buildData(this.averageData);
    } else {
      if (isReport) {
        this.aggregationRating$ = this._reviewsS.aggregationRating(this.locations, this.dataPicker).take(2).subscribe(result => {
          this.buildData(result);
        }, () => {
          this.isProgressRating = false;
        });
      } else {
        this.aggregationRating$ = this._reviewsS.getRatingsAndReviewsData(this.locations[0].accountId, this._auth.session.gid, this.locations[0].locationId, this.dataPicker).take(2).subscribe(result => {
          this.buildData({
            data: result.avg_rating,
            labels: result.dates,
            total: result.total_avg
          });
        }, () => {
          this.isProgressRating = false;
        });
      }
    }
  }

  buildData(result) {
    // Remove extra days
    if (this.dataPicker.aggregation === 'Day') {
      const start = moment(this.dataPicker.range.start);
      const end = moment(this.dataPicker.range.end);
      const days = end.diff(start, 'days') + 1;
      const dropValues = result.labels?.length - days;
      result['data'] = _.dropRight(result.data, dropValues);
      result['labels'] = _.dropRight(result.labels, dropValues);
    }
    
    this.datasetLineRating = [{data: result.data, label: 'Rating'}];
    
    if (result?.total) {
      this.avgRating = result.total;
    } else {
      this.avgRating = 0;
      const totalRating = _.isEmpty(result.data) ? 0 : result.data.reduce((accumulator, currentValue) => accumulator + currentValue);
      this.avgRating = totalRating / this.datasetLineRating[0]?.data?.length;
    }
    this.labelsLineRating = result.labels;
    this.isProgressRating = false;
    this._loadingService.reportAdvanced(1, 'Rating Reviews');
  }

  ngOnDestroy(): void {
    this.aggregationRating$?.unsubscribe();
  }

}
