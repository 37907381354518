import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GROUP_SUBSCRIPTION_TYPE, LOCATION_SUBSCRIPTION_TYPE } from '../../constants/firestore/account-location';
import { AuthService } from '../../services/auth.service';
import { LocationService } from '../../services/location.service';
import SavedLocation from '../../constants/firestore/saved-location';
import { Observable } from 'rxjs';
import { ISubscription } from '../../constants/subscription';
import { ServiceList } from '../../constants/google/service-list';
import { FoodMenu } from '../../constants/google/menuList';
import { IS_IMPERSONATING } from '../../constants/session';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-dashboard-filter',
  templateUrl: './dashboard-filter.component.html',
  styleUrls: ['./dashboard-filter.component.scss']
})
export class DashboardFilterComponent implements OnInit {
  isImpersonating = false;

  //---General
  @Input() typeDashboard: string;
  @Input() accountId: string = null;
  @Input() progress = false;
  @Output() ClickEvent: EventEmitter<string> = new EventEmitter();
  //---Location Info type
  @Input() location: SavedLocation = null;
  @Input() locationId: string = null;
  @Input() isProgressPush = false;
  @Input() isProgressFetch = false;
  topBanner = false;
  public GROUP_SUBSCRIPTION_TYPE = GROUP_SUBSCRIPTION_TYPE;
  public location$;

  subscription$: Observable<ISubscription>;
  subscription: ISubscription;

  // --- Services
  updateService = false;
  @Output() UpdateServices: EventEmitter<ServiceList[]> = new EventEmitter();
  @Output() UpdateMenuList: EventEmitter<FoodMenu[]> = new EventEmitter();
  @Output() RefreshButtonPressed: EventEmitter<boolean> = new EventEmitter();
  @Output() PurgeButtonPressed: EventEmitter<boolean> = new EventEmitter();

  @Input() isRefreshing = false;
  @Input() isPurging = false;
  @Input() isProgressSave = false;
  @Input() isShared = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private locationService: LocationService,
    private authService: AuthService,
    private modalService: ModalService
  ) {
    this.isImpersonating = !!localStorage.getItem(IS_IMPERSONATING);
    this.subscription$ = this.authService.subscription$;
    this.subscription$.subscribe(subscription => this.subscription = subscription);
  }

  async ngOnInit() {
    if (this.route.snapshot.parent.params.locationId) {
      if (this.locationId === null) {
        this.locationId = this.route.snapshot.parent.params.locationId;
      }
      await this.locationService.fetchLocation(this.authService.session.gid, this.accountId, this.locationId).then(loc => {
        if (this.subscription?.status === GROUP_SUBSCRIPTION_TYPE.TRIAL || (loc.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE || 
                                                                            loc.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL)) {
          this.topBanner = true;
        }
      })
    }
    else {
      this.topBanner = false;
    }

  }

  updateServices() {
    this.updateService = true;
    this.locationService.saveServices(this.accountId, this.locationId).toPromise().then(services => {
      this.getServices();
    })
  }

  getServices() {
    this.locationService.getRef(this.authService.session.gid, this.accountId, this.locationId).toPromise().then(services => {
      this.UpdateServices.emit(services.serviceList ?? []);
      this.updateService = false;
    })
  }

  updateMenu() {
    this.updateService = true;
    this.locationService.saveMenu(this.accountId, this.locationId).toPromise().then(menu => {
      this.getMenu();
    })
  }

  getMenu() {
    this.locationService.getRef(this.authService.session.gid, this.accountId, this.locationId).toPromise().then(services => {
      this.UpdateMenuList.emit(services.menuList);
      this.updateService = false;
    })
  }

  launcAction(Action: string) {
    if (Action == 'lock') {
      this.locationService.isLocked(this.locationId).subscribe(res => {
        if(res && res.locked) {
          const dialogRef = this.modalService.openInfoModal(
            "Heads Up",
            "An identical location in our network is already locked. Please contact support.")
        } else {
          this.ClickEvent.emit(Action);
        }
      })
    } else {
      this.ClickEvent.emit(Action);
    }
  }

  GoBack() {
    this.router.navigate(['/accounts', this.accountId, 'locations'])
  }

  refreshButtonPressed() {
    this.RefreshButtonPressed.emit();
  }

  purgeButtonPressed() {
    this.PurgeButtonPressed.emit();
  }

  handleShared() {}

  handleRefresh() {}

}
