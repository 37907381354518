<div class="dialog__header txt--center">
  <h2 mat-dialog-title class="m--0 txt--xl">
    <strong>Edit User Settings</strong>
  </h2>
</div>
<div class="dialog__content" mat-dialog-content>
  <app-toggle-locations-in-account
    class="locations-no-padding"
    (selected)="selectedLocations($event)"
    [selectByUser]="true"
    [reportEdit]="accountsSelected"
  ></app-toggle-locations-in-account>
</div>
<div mat-dialog-actions class="dialog__footer">
    <span class="alternate-theme d-inline-block mr--10">
        <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
    </span>
  <button mat-flat-button tabIndex="-1" color="accent" (click)="save()" class="btn btn--action">Save</button>
</div>
