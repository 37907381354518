// dep
import { Directive, ElementRef, Input, OnInit } from '@angular/core';

// app
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { UserFeatures } from '../constants/user-features'


@Directive({
  selector: '[appUserFeature]'
})
export class UserFeatureDirective implements OnInit {
  @Input() appUserFeature: keyof UserFeatures['userFeatures'];

  public uid: string;
  public userFeatures: UserFeatures['userFeatures'];
  public generalFeatures: UserFeatures["generalFeatures"];
  public newFeature: boolean;
  public userFeaturesStorage: any;

  constructor(
    private _authS: AuthService,
    private _userS: UserService,
    private _element: ElementRef,
  ) { }

  async ngOnInit(): Promise<void> {
    this.uid = this._authS.session.uid;
    this.userFeaturesStorage = await this._authS.userFeatures;

    if(Object.keys(this.userFeaturesStorage)?.length && this.userFeaturesStorage?.uid != 'undefined') {
      this.buildFeature();
    } else {
      this.getUserFeatures()
    }
    
  }

  buildFeature(): void {
    this.userFeatures = this.userFeaturesStorage?.userFeatures;
    this.generalFeatures = this.userFeaturesStorage?.generalFeatures;
    this.newFeature = this.userFeaturesStorage?.generalFeatures?.isNewFeature;

    const hasFeature = this.userFeatures?.[this.appUserFeature];
    const feature = this.generalFeatures?.[this.appUserFeature];
    const newFeature = this.newFeature?.[this.appUserFeature];

    if (feature || hasFeature) {
      const htmlEl = this._element.nativeElement; 
      htmlEl.classList.remove('d-none'); // Show the element

        if (newFeature || !feature) {
          const itemsToAddBeta = htmlEl.getElementsByClassName('title-dark-launch');
          const message = newFeature ? 'New' : 'Beta'
          
          Object.keys(itemsToAddBeta).forEach(el => {
            itemsToAddBeta[el].innerHTML += `<span class="beta-mesage">${message}</span>`
          })
        }
    }
  }

  getUserFeatures(): void {
    this._userS.getUserFeature(this.uid).subscribe(
      res => {
        this.userFeaturesStorage = res;
        this.buildFeature();
      },
      err => console.log(err) 
    )
  }

}