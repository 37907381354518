<div class="dialog__main__container">
  <div class="dialog__header" [class.dialog__header--white]="!drawer" [class.slider-drawer]="drawer">
    <div class="dialog__close">
      <button mat-icon-button mat-dialog-close *ngIf="!drawer" tabIndex="-1" class="btn btn-icon btn-icon--txt pull--right">
        <em class="fal fa-times"></em>
      </button>

      <button mat-button *ngIf="!drawer"class="btn btn-cancel--white pt--20 link--white txt--underline" tabIndex="-1" color="primary" mat-dialog-close>Cancel</button>
      <button mat-button *ngIf="drawer" class="btn btn-cancel--white pt--20 link--white txt--underline" color="primary" (click)="dialogClose()">{{closeLabel}}</button>

    </div>
    <p mat-dialog-title class="mt--15 mb--5 txt--14 fw--700" [ngClass]="drawer ? 'txt--white' : 'txt--lightgray'">
      <span *ngIf="!stepTitle?.length">Step {{index}}/{{steps.length}}</span>
      
      <span *ngIf="stepTitle?.length" class="mt--10">{{stepTitle[step] || stepTitle[0]}}: Step {{index}}/{{steps.length}}</span>
    </p>
    <h2 mat-dialog-title class="txt--lg mb--25"><strong>{{title[step] || title[0] | titlecase}}</strong></h2>
    <mat-progress-bar color="accent" mode="determinate" [value]="percent"></mat-progress-bar>
  </div>

  <div class="stepper__body" [class.stepper__body__slider]="isSliderOpened" [style.display]="selected ? 'block' : 'none'">
    <!-- Content from the CdkStep is projected here -->
    <ng-container [ngTemplateOutlet]="selected.content"></ng-container>
  </div>

  <mat-divider class="mt--20"></mat-divider>

  <div class="step-navigation-bar">
    <div mat-dialog-actions class="dialog__footer">
      <span class="alternate-theme d-inline-block mr--10">
        <button mat-button tabIndex="-1" color="primary" class="btn btn-cancel" cdkStepperPrevious (click)="stepperPrevious()" *ngIf="selectedIndex>0">Back</button>
      </span>
      
      <div class="d-inline-block" *ngIf="index === steps.length && finalAction; else actionDefault"> 
        <span class="alternate-theme mr--10" *ngIf="hasDraft && (isDraft || !isEdit)">
          <button mat-flat-button color="primary" class="btn btn--action txt--dark--gray padding-buttons" [disabled]="disabledStep" (click)="postDraft()">
            <em class="far fa-save mr--10"></em>Save Draft
          </button>
        </span>

        <button *ngIf="!dataIsBeingVerified" mat-flat-button tabIndex="-1" color="accent" class="btn btn--action padding-buttons" [disabled]="disabledStep" (click)="complete()" cdkStepperNext>
          <span *ngIf="!nextButtonLabels?.length">{{finalAction | titlecase}}</span>
          <span *ngIf="nextButtonLabels?.length">{{finalAction | titlecase}}: <strong>{{nextButtonLabels[step] | titlecase}}</strong></span>
        </button>

        <p *ngIf="dataIsBeingVerified" class="txt--14 m--0 p--10 pb--5 txt--gray spinner-button display-in-bl">
          <mat-progress-spinner mode="indeterminate" diameter="20" class="mr--10 display-in-bl"></mat-progress-spinner>
          <span class="vertical--align--super ">Validating {{verifiedDataLabel}}</span>
        </p>
      </div>


      <ng-template #actionDefault>
        <div class="d-inline-block">
          <button mat-flat-button color="primary" class="btn btn--action" *ngIf="index===3 && scan_action" (click)="create_scan()">Save Scan</button>

          <button mat-flat-button tabIndex="-1" color="accent" class="btn btn--action padding-buttons" *ngIf="buttonNextVisible()" [disabled]="disabledStep" cdkStepperNext (click)="get_step()">
            <span *ngIf="!nextButtonLabels?.length">Step {{index + 1}}/{{steps.length}}</span>
            <span *ngIf="nextButtonLabels?.length">Next: <strong>{{nextButtonLabels[step] | titlecase}}</strong></span>
          </button>
        </div>
      </ng-template>
    </div>
  </div>
</div>