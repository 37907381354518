<div class="dialog__header dialog__header--info txt--center">
    <h2 mat-dialog-title class="m--0 txt--xl"><strong>Attention</strong></h2>
  </div>
  <div class="dialog__content" mat-dialog-content>
    <div class="dialog-row">
      <p class="txt--lg txt--center m--0">
        <strong>After pressing Continue, the Google popup will appear. Be sure to choose the email address 
                associated with this account which is {{account.googleAuthEmailAddress}}
        </strong>
      </p>
    </div> 
  </div>
  
  <div mat-dialog-actions class="dialog__footer">
      <span class="alternate-theme d-inline-block mr--10">
          <button tabIndex="-1" mat-button color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
      </span>
    <button tabIndex="-1" mat-flat-button color="primary" (click)="ReAuth()" class="btn btn--action">Continue</button>
  </div>
  